import { ReactNode, forwardRef, useRef } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, usePopover } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

import composeRefs from '@seznam/compose-react-refs';

import { useActiveTheme } from '../../scales';

type Props = {
  children: ReactNode;
  placement: Parameters<typeof useOverlayPosition>[0]['placement'];
  triggerRef: Parameters<typeof useOverlayPosition>[0]['targetRef'];
  state: OverlayTriggerState;
};
export default forwardRef<HTMLDivElement, Props>(function NavPopover(
  { state, ...props },
  forwardedRef
) {
  const ref = useRef<HTMLDivElement>(null);

  const { popoverProps } = usePopover(
    {
      popoverRef: ref,
      triggerRef: props.triggerRef,
    },
    state
  );

  const { overlayProps } = useOverlayPosition({
    placement: props.placement,
    overlayRef: ref,
    targetRef: props.triggerRef,
    containerPadding: 0,
    shouldFlip: false,
  });

  const activeTheme = useActiveTheme(props.triggerRef);

  return (
    <OverlayContainer data-theme={activeTheme}>
      <div
        // We want a visual gap between the trigger and the tooltip, while still ensuring we can move
        // the cursor into the tooltip. As a result, we pad out the tooltip with transparency before
        // rendering the real tooltip.
        className="z-[999] bg-transparent px-1"
        ref={composeRefs(ref, forwardedRef)}
        {...mergeProps(popoverProps, overlayProps)}
      >
        <div className="rounded border border-border-muted bg-background-1 p-2 text-type-primary shadow type-body1">
          {props.children}
        </div>
      </div>
    </OverlayContainer>
  );
});
