import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    inner: {
      minWidth: '100%',
      width: 'max-content',
    },
    root: {
      position: 'relative',
      minHeight: 350,
    },
    divider: {
      margin: '0',
      backgroundColor: theme.palette.joinGrey400,
    },
    pageBreakLine: {
      '@media not print': {
        borderTop: '1px dotted grey',
      },
      width: 'calc(100% + 68px)',
      left: -38,
      position: 'relative',
    },
    paddingLeft: {
      paddingLeft: 5,
    },
    nameColumnTitle: {
      fontWeight: 700,
      fontSize: 16,
      paddingLeft: 20,
    },
    drawnColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    dateAcceptedColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    expandRow: {
      alignItems: 'end',
    },
    acceptedByColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    pendingColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    overdrawnColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    notesColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    createdByColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    dateCreatedColumnTitle: {
      paddingRight: 25,
      fontWeight: 700,
      minWidth: 100,
      fontSize: 16,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'baseline',
      borderBottom: '2px solid black',
      width: '100%',
      justifyContent: 'flex-start',
    },
    parentContainer: {
      display: 'flex',
      alignItems: 'baseline',
      borderTop: '1px solid grey',
      width: '100%',
      justifyContent: 'flex-start',
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'baseline',
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '2px solid black',
    },
    parentRow: {
      display: 'flex',
      flexDirection: 'row',
      '& $parentCell': {
        // exlude last child to prevent shading Notes column cell
        '&:nth-child(even):not(:last-child)': {
          backgroundColor: theme.palette.nestingGrey,
        },
        '&:nth-child(2):before': {
          content: '" "',
          borderTop: '1px dotted black',
          flex: 1,
          height: 0,
          marginRight: 10,
          marginLeft: 2,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.backgroundGreen,
        cursor: 'pointer',
        '& $parentCell': {
          '&:nth-child(even):not(:last-child)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    itemRow: {
      display: 'flex',
      flexDirection: 'row',
      '& $cell': {
        // exlude last child to prevent shading Notes column cell
        '&:nth-child(even):not(:last-child)': {
          backgroundColor: theme.palette.nestingGrey,
        },
        '&:nth-child(2):before': {
          content: '" "',
          borderTop: '1px dotted black',
          flex: 1,
          height: 0,
          marginRight: 10,
          marginLeft: 2,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.backgroundGreen,
        cursor: 'pointer',
        '& $cell': {
          '&:nth-child(even):not(:last-child)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    cell: {
      fontFamily: 'Roboto',
      fontSize: 14.4,
      fontWeight: 400,
      letterSpacing: 0.171,
      flex: '1 1 100px',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
    },
    parentCell: {
      fontFamily: 'Roboto',
      fontSize: 14.4,
      fontWeight: 700,
      letterSpacing: 0.171,
      flex: '1 1 100px',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
    },
    wideHeaderCell: {
      display: 'flex',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      lineHeight: '144%',
      alignItems: 'end',
      marginBottom: 6,
      justifyContent: 'start',
      columnGap: 8,
      flex: '1 1 700px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 36,
    },
    wideHeaderText: {
      fontWeight: 700,
    },
    headerCell: {
      display: 'flex',
      flex: '1 1 100px',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      alignItems: 'end',
      marginBottom: 6,
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    notesCellContainer: {
      display: 'flex',
      flex: '1 1 100px',
    },
    parentWideCell: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      lineHeight: '144%',
      fontFamily: 'Larsseit',
      alignItems: 'center',
      flex: '1 1 700px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      '&:after': {
        content: '" "',
        borderTop: '1px dotted black',
        flex: '1 1 200px',
        position: 'relative',
        marginLeft: 4,
      },
      paddingLeft: 8,
    },
    itemWideCell: {
      fontSize: 14.4,
      fontWeight: 300,
      letterSpacing: 0.171,
      lineHeight: '144%',
      fontFamily: 'Larsseit',
      textDecorationLine: 'underline',
      alignItems: 'center',
      flex: '1 1 700px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      '&:after': {
        content: '" "',
        borderTop: '1px dotted black',
        flex: '1 1 200px',
        position: 'relative',
        marginLeft: 4,
      },
    },
    cardHeader: {
      display: 'flex',
      flex: '0 0 auto',
      '@media print': {
        display: 'none',
      },
    },
    cardTitle: {
      display: 'flex',
      flex: '1 0 315px',
      flexDirection: 'column',
      gap: '8px',
    },
    icon: {
      height: 24,
      width: 24,
      paddingTop: 2,
    },
    itemIndent: {
      display: 'flex',
      paddingLeft: 55,
      alignItems: 'center',
    },
    rowBorder: {
      borderBottomColor: theme.palette.notapplicable,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
    collapseAllButton: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.143,
      paddingTop: 1,
      color: theme.palette.disabledGrey,
      cursor: 'pointer',
    },
    collapseNotesButton: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.143,
      paddingTop: 1,
      paddingLeft: 4,
      color: theme.palette.disabledGrey,
      marginRight: 8,
    },
    reportTypeSelect: {
      width: 200,
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    reportMenuContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '@media print': {
        display: 'none',
      },
    },
    reportHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media print': {
        height: 60,
      },
    },
    reportTypeSelectLabel: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.143,
      color: theme.palette.shadedGrey,
    },
    expandNotesCircle: {
      ...theme.expandNotesCircle,
    },
    expandNotesCircleDisabled: {
      ...theme.expandNotesCircleDisabled,
    },
    expandNotesArrow: {
      ...theme.arrowIconExpandedRight,
    },
    summaryReportCellText: {
      ...theme.typography.webHeading3,
      display: 'flex',
      alignItems: 'center',
      minHeight: 40,
    },
    percentOfEstimateLabel: {
      fontWeight: 400,
      fontSize: 14,
      marginLeft: 4,
    },
    headerCellPaddingLeft: {
      paddingLeft: 16,
    },
    headerCellSpacerLeft: {
      width: 10,
    },
    expandedHeaderNoteCell: {
      display: 'flex',
      flex: '1 1 200px',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      alignItems: 'center',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: 10,
    },
    collapsedHeaderNoteCell: {
      display: 'flex',
      flex: '0 1 70px',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      alignItems: 'center',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    expandedNoteCell: {
      display: 'flex',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      alignItems: 'center',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      alignContent: 'space-between',
      paddingLeft: 6,
      minWidth: 320,
      maxWidth: 320,
    },
    collapsedNoteCell: {
      display: 'flex',
      flex: '0 0 70px',
      fontFamily: 'Larsseit',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.19,
      alignItems: 'center',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 6,
    },
    expandIconRow: {
      minHeight: 36,
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    selectMenu: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minHeight: 32,
      padding: '0px 32px 0px 0px',
      textDecoration: 'none',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    bgnWhite: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: 3,
      overflow: 'hidden',
    },
    hideBorder: {
      border: 'none',
      borderStyle: 'none',
      fontSize: 22,
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
    },
    ellipsisOverflow: {
      ...theme.typography.body1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingLeft: 6,
    },
    option: {
      ...theme.typography.h3CardHeader,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: 18,
      padding: '0px',
      cursor: 'pointer',
    },
    noPadding: {
      // removes default padding on MUI components
      padding: '0px',
      ...theme.typography.h3CardHeader,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: 18,
      cursor: 'pointer',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    exportButtton: {
      display: 'flex',
      marginTop: 16,
      marginRight: 24,
    },
    print: {
      '@media print': {
        zoom: '70%',
      },
      backgroundColor: theme.palette.backgroundWhite,
    },
    emptyStateParent: {
      display: 'flex',
      height: '100vh',
      textAlign: 'center',
    },
    emptyState: {
      display: 'flex',
      backgroundColor: theme.palette.backgroundGrey,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '60vh',
      margin: 10,
    },
    emptyRowInfoContainer: {
      width: 320,
      flexShrink: 0,
      borderRight: theme.border.darkGrey,
      background: theme.palette.backgroundWhite,
      height: '70vh',
    },
    link: {
      ...theme.typography.webLink,
      cursor: 'pointer',
    },
    text: {
      ...theme.typography.webParagraph,
    },
  });

export default styles;
