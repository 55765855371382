import { FC, useState } from 'react';

import { Collapse, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import { OPTION } from '../../constants';
import theme from '../../theme/komodo-mui-theme';
import {
  computeItemCostImpact,
  getItemCost,
  getItemMilestoneMappings,
} from '../../utilities/items';
import { renderCostString } from '../CostReport/CostReportUtils';
import ItemsIcons from '../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../ItemsList/ItemsIcons/ItemsIconsMap';
import useMilestonesQuery from '../Milestones/hooks/useMilestonesQuery';
import ItemMilestoneReportLink from '../Milestones/ItemMilestoneReportLink';
import CollapseIcon from '../shared-widgets/CollapseIcon';
import useMemoWrapper from '../useMemoWrapper';

import ConfirmDeleteMilestoneDialog from './ConfirmDeleteMilestoneDialog';
import { getPreviousMilestoneLabel } from './ItemDetailsUtils';
import styles from './ItemMilestoneMapStyles';

export const ITEM_SUMMARY = 'ITEM_SUMMARY';

type ItemMilestoneMapProps = {
  classes: Classes<typeof styles>;
  canEditItemMilestone: boolean;
  shouldDisplayCosts: boolean;
  currentItem: ItemLike | null;
  projectID: UUID;
  onChange: (milestoneToDelete: Milestone | null) => void;
  variant?: string;
};

const ItemMilestoneMap: FC<ItemMilestoneMapProps> = ({
  classes,
  canEditItemMilestone,
  shouldDisplayCosts,
  currentItem,
  projectID,
  onChange,
  variant,
}) => {
  const { data: { milestones = [] } = {} } = useMilestonesQuery(projectID, false);

  const item = useMemoWrapper(computeItemCostImpact, currentItem);
  const [confirmDeleteDialogVisible, setConfirmDeleteMilestoneVisible] = useState(false);
  const [milestoneToDelete, setMilestoneToDelete] = useState<Milestone | null>(null);
  const [previousMilestonesVisible, setPreviousMilestonesVisible] = useState(false);

  const itemMilestoneId = item && item.milestone && item.milestone.id;
  const milestoneMappings = getItemMilestoneMappings(item, milestones);
  const previousMilestoneLabel = getPreviousMilestoneLabel(milestoneMappings);
  const isOption = currentItem?.itemType === OPTION;
  const showItemMilestoneMapCollapse = variant === ITEM_SUMMARY;
  const showDeletePlaceholder = canEditItemMilestone && milestoneMappings.length > 1 && !isOption;
  const count = milestoneMappings && milestoneMappings.length;
  const isItemSummary = variant === ITEM_SUMMARY;

  // FUNCTIONS
  const openConfirmDeleteMilestoneVisible = (milestone: Milestone) => {
    if (milestone) {
      setMilestoneToDelete(milestone);
    }
    setConfirmDeleteMilestoneVisible(true);
  };

  // Open / close
  const togglePreviousMilestones = () => {
    setPreviousMilestonesVisible(!previousMilestonesVisible);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const generateLine = (milestoneState: any) => {
    const { milestone, status } = milestoneState;
    const isItemMilestone = itemMilestoneId === milestone.id;
    return (
      <div data-cy="div-itemMilestoneMapLine" className={classes.mappedMilestoneLine}>
        <div
          data-cy="milestoneName-itemMilestoneMapLine"
          className={`${classes.milestoneNameTextLabel} ${
            isItemMilestone && canEditItemMilestone ? classes.currentMilestone : ''
          }`}
        >
          {canEditItemMilestone ? (
            <ItemMilestoneReportLink
              isItemMilestone={isItemMilestone}
              milestoneId={milestone.id}
              milestoneName={milestone.name}
            />
          ) : (
            milestone.name
          )}
        </div>
        <div className={classes.itemMlestoneData}>
          <div className={classes.costText} data-cy="costImpact-itemMilestoneMapLine">
            {shouldDisplayCosts && renderCostString({ cost: getItemCost(milestoneState) })}
          </div>
          <div className={classes.milestoneStatus}>
            <ItemsIcons variant={SMALL} status={status} />
          </div>
          {!isItemMilestone && canEditItemMilestone && !isOption ? (
            <IconButton
              aria-label="Remove"
              className={classes.removeButton}
              data-cy="iconButtonRemove-itemMilestoneMapLine"
              onClick={() => openConfirmDeleteMilestoneVisible(milestone)}
              title={`Remove item from ${milestone.name}: this will update the running totals in ${milestone.name} to not include this item.`}
            >
              <Close color="inherit" className={classes.removeIcon} />
            </IconButton>
          ) : (
            showDeletePlaceholder && <div className={classes.removeButton} />
          )}
        </div>
      </div>
    );
  };

  const closeConfirmDeleteMilestoneVisible = () => {
    setConfirmDeleteMilestoneVisible(false);
  };

  const onSubmit = () => {
    setConfirmDeleteMilestoneVisible(false);
    onChange(milestoneToDelete);
  };

  const milestoneMappingsComponent = milestoneMappings.map((milestoneState) => (
    <div key={milestoneState.milestoneID + item.id}>{generateLine(milestoneState)}</div>
  ));

  return (
    <div>
      <div
        className={previousMilestoneLabel ? classes.previousMilestoneList : classes.notDisplayed}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            togglePreviousMilestones();
          }
        }}
        onClick={togglePreviousMilestones}
        role="button"
        tabIndex={-1}
      >
        {showItemMilestoneMapCollapse && (
          <CollapseIcon
            className={classes.expander}
            dataCy="expandMore-milestonesItemSummary"
            isCollapsed={!previousMilestonesVisible}
          />
        )}
        <Typography
          variant="caption"
          className={classes.cardHeaderTypography}
          style={{
            color: isItemSummary ? theme.palette.shadedGrey : '',
            cursor: showItemMilestoneMapCollapse ? 'pointer' : '',
          }}
        >
          {previousMilestoneLabel}
        </Typography>
      </div>
      {count ? (
        <div className={classes.root}>
          {canEditItemMilestone && (
            <ConfirmDeleteMilestoneDialog
              open={confirmDeleteDialogVisible}
              onSubmit={onSubmit}
              onClose={closeConfirmDeleteMilestoneVisible}
              onCancel={closeConfirmDeleteMilestoneVisible}
              milestone={milestoneToDelete}
            />
          )}
          <div className={classes.milestoneTimelineLeft} />
          {showItemMilestoneMapCollapse ? (
            <Collapse in={previousMilestonesVisible} unmountOnExit>
              <div className={classes.milestoneTimelineRight}>{milestoneMappingsComponent}</div>
            </Collapse>
          ) : (
            <div className={classes.milestoneTimelineRight}>{milestoneMappingsComponent}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(ItemMilestoneMap);
