import { FC, useCallback, useContext, useEffect } from 'react';
import { trim } from 'validator';

import { useReactiveVar } from '@apollo/client';
import { Divider } from '@material-ui/core';

import {
  addToMilestoneEventTypes,
  reportAddToMilestone,
} from '../../../../../analytics/analyticsEventProperties';
import { addToMilestoneVar, projectCompsSetInputVar } from '../../../../../api/apollo/reactiveVars';
import { AddToMilestoneStates, TermKey } from '../../../../../api/gqlEnums';
import {
  CATEGORIZATION_NAME_ALREADY_EXISTS_ERROR,
  MASTERFORMAT_CATEGORIZATION_ID,
  NULL_ID,
  UNIFORMAT_CATEGORIZATION_ID,
} from '../../../../../constants';
import { CostReportColumnType, DesignPhaseType } from '../../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../../hooks/useAnalyticsEventHook';
import { useMilestoneCostReportsQuery } from '../../../../../hooks/useMilestoneCostReportsQuery';
import { useMilestonesCostReportsQuery } from '../../../../../hooks/useMilestonesCostReportsQuery';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import {
  computeColumnInputs,
  computeMilestonesCostEntries,
  computeSelectCostReport,
} from '../../../../../utilities/milestones';
import { getCostReportByType, renderCostString } from '../../../../CostReport/CostReportUtils';
import { useDesignPhaseTypes } from '../../../../Milestone/hooks/useDesignPhaseTypesQuery';
import useMilestonesQuery from '../../../../Milestones/hooks/useMilestonesQuery';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';
import MilestoneSelect from '../../../../Select/MilestoneSelect';
import TypeCostSelect from '../../../../Select/TypeCostSelect/TypeCostSelect';
import useMemoWrapper from '../../../../useMemoWrapper';

import styles from './AddToAMilestoneSelectionStyles';
import AddToMilestoneCategorizationName from './AddToMilestoneCategorizationName';
import AddToMilestoneNewMilestone from './AddToMilestoneNewMilestone';
import ConfirmFollowing from './ConfirmFollowing';

type AddToMilestoneMilestoneSelectionProps = {
  classes: Classes<typeof styles>;
  projectID: string;
};

export const addToEstimateCostOptions = { isExact: true, isWide: true, showCents: false };
const addToEstimateCostFormatter = (cost: Cost) =>
  renderCostString({ cost, ...addToEstimateCostOptions });

const AddToMilestoneMilestoneSelection: FC<AddToMilestoneMilestoneSelectionProps> = ({
  classes,
  projectID,
}) => {
  const t = useContext(ProjectTermStore);
  const sendAnalytics = useAnalyticsEventHook();

  const { data: { milestones = [] } = {}, loading: loadingMilestones } = useMilestonesQuery(
    projectID,
    true
  );

  const {
    averageCost,
    categorizationName,
    date,
    milestoneID,
    milestoneDesignPhase,
    milestoneName: selectedMilestoneName,
    type = CostReportColumnType.ESTIMATE_REPORT,
  } = useReactiveVar(addToMilestoneVar);

  const { projectCompInputs } = useReactiveVar(projectCompsSetInputVar);
  const isBuiltIn =
    projectCompInputs.every((pc) => pc.categorizationID === UNIFORMAT_CATEGORIZATION_ID) ||
    projectCompInputs.every((pc) => pc.categorizationID === MASTERFORMAT_CATEGORIZATION_ID);

  // Milestones Costs
  const milestonesColumnInputs = useMemoWrapper(
    computeColumnInputs,
    [CostReportColumnType.ESTIMATE_REPORT, CostReportColumnType.TARGET_REPORT],
    null
  );
  const {
    data: { milestoneCostReports: milestonesCostReports } = { milestoneCostReports: [] },
    loading: loadingCostReports,
  } = useMilestonesCostReportsQuery(
    milestones.map(({ id }) => ({ milestoneID: id })),
    projectID,
    {},
    milestonesColumnInputs
  );
  const milestoneEntries = useMemoWrapper(
    computeMilestonesCostEntries,
    milestones,
    milestonesCostReports,
    type
  );
  const designPhaseTypes = useDesignPhaseTypes();

  const { data, loading: loadingCategorizations } = useProjectCategorizationsQuery(projectID, true);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const categorizationNameAlreadyExists = categorizations.some(
    (categorization) => categorization.name === trim(categorizationName || '')
  );
  const categorizationNameError = categorizationNameAlreadyExists
    ? CATEGORIZATION_NAME_ALREADY_EXISTS_ERROR
    : undefined;

  const setErrorMessage = (categorizationNameError: string | undefined) =>
    addToMilestoneVar({ ...addToMilestoneVar(), categorizationNameError });
  useEffect(() => {
    setErrorMessage(categorizationNameError);
  }, [categorizationNameError]);

  const getMilestoneName = (milestones: Milestone[], milestoneID?: string) =>
    milestones.find(({ id }) => id === milestoneID)?.name ?? '';

  const isNewMilestone = milestoneID === NULL_ID;
  const milestoneName = getMilestoneName(milestones, milestoneID);

  const onChangeMilestone = useCallback(
    (newMilestoneID: string | null) => {
      if (!newMilestoneID) return;
      addToMilestoneVar({
        ...addToMilestoneVar(),
        type,
        milestoneID: newMilestoneID,
        milestoneName: getMilestoneName(milestones, newMilestoneID),
      });

      sendAnalytics(
        reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_SELECT_MILESTONE, {
          milestoneID: newMilestoneID,
          type,
        })
      );
    },
    [milestones, sendAnalytics, type]
  );

  const onChangeType = (newType: CostReportColumnType) => {
    addToMilestoneVar({ ...addToMilestoneVar(), type: newType });
    sendAnalytics(
      reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_SELECT_MILESTONE, {
        milestoneID,
        type: newType,
      })
    );
  };

  const onChangeDate = (newDate: string | null) => {
    addToMilestoneVar({ ...addToMilestoneVar(), date: newDate ?? '' });
    if (!newDate) return;
    sendAnalytics(
      reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_SELECT_MILESTONE, {
        milestoneID,
        date: newDate,
      })
    );
  };

  const onChangeDesignPhase = (designPhase: DesignPhaseType | undefined) => {
    if (!designPhase) return;
    addToMilestoneVar({ ...addToMilestoneVar(), milestoneDesignPhase: designPhase });
  };

  const onChangeMilestoneName = (newName: string) => {
    addToMilestoneVar({ ...addToMilestoneVar(), milestoneName: newName });
    sendAnalytics(
      reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_SELECT_MILESTONE, {
        milestoneID,
        name: newName,
      })
    );
  };

  const onChangeCategorizationName = (newName: string) => {
    addToMilestoneVar({ ...addToMilestoneVar(), categorizationName: newName });
    sendAnalytics(
      reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_SELECT_MILESTONE, {
        milestoneID,
        categorizationName: newName,
      })
    );
  };

  useEffect(() => {
    if (!milestoneID && milestones && milestones[0]?.id) onChangeMilestone(milestones[0].id);
  }, [milestoneID, milestones, loadingMilestones, onChangeMilestone]);

  // Costs
  const quantity: Quantity | null = null;
  const sidebarColumnTypes: CostReportColumnType[] = [
    CostReportColumnType.ESTIMATE_REPORT,
    CostReportColumnType.TARGET_REPORT,
  ];
  const columnInputs = useMemoWrapper(computeColumnInputs, sidebarColumnTypes, quantity);
  const mID = isNewMilestone || !milestoneID ? null : milestoneID;
  const { data: { milestoneCostReports } = { milestoneCostReports: [] } } =
    useMilestoneCostReportsQuery(mID, projectID, {}, columnInputs);
  const costReport = useMemoWrapper(
    computeSelectCostReport,
    milestoneCostReports,
    quantity ?? undefined
  );
  const estimate = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);
  const target = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);
  const estimateTotal = estimate?.range;
  const targetTotal = target?.range;
  const costString = (cost: CostScalar, type: string) =>
    !cost || cost?.value === '0' ? `No ${type}` : `Currently ${addToEstimateCostFormatter(cost)}`;
  const estimateCost = costString(estimateTotal as CostScalar, t.lowerCase(TermKey.ESTIMATE));
  const targetCost = costString(targetTotal as CostScalar, t.lowerCase(TermKey.TARGET));
  const currentTotal = type === CostReportColumnType.ESTIMATE_REPORT ? estimateTotal : targetTotal;
  const isNoCurrentEstimate = (currentTotal as CostScalar)?.value === '0';
  const currentCost = addToEstimateCostFormatter(currentTotal);

  useEffect(() => {
    let state: AddToMilestoneStates | undefined;
    if (isNewMilestone) {
      state = AddToMilestoneStates.NEW_MILESTONE;
    } else if (isNoCurrentEstimate) {
      state = AddToMilestoneStates.EXISTING_MILESTONE_NO_ESTIMATE;
    } else {
      state = AddToMilestoneStates.EXISTING_MILESTONE_WITH_ESTIMATE;
    }
    addToMilestoneVar({ ...addToMilestoneVar(), state });
  }, [isNoCurrentEstimate, isNewMilestone, loadingMilestones]);

  const selectedMilestoneTypes = [
    CostReportColumnType.ESTIMATE_REPORT,
    CostReportColumnType.TARGET_REPORT,
  ];

  const typeEntries = selectedMilestoneTypes.map((availableType) => {
    switch (availableType) {
      case CostReportColumnType.ESTIMATE_REPORT:
        return { id: availableType, name: t.titleCase(TermKey.ESTIMATE), nameOption: estimateCost };
      case CostReportColumnType.TARGET_REPORT:
        return { id: availableType, name: t.titleCase(TermKey.TARGET), nameOption: targetCost };
      default:
        return { id: availableType, name: availableType };
    }
  });

  if (loadingMilestones || loadingCostReports || loadingCategorizations) return null;

  return (
    <div className={classes.innerContainer}>
      <div className="type-label">Milestone</div>
      <div className={classes.vertical}>
        <MilestoneSelect
          isCreateNew
          milestones={milestoneEntries}
          nameOptionClassName={classes.nameOption}
          selectedMilestone={milestoneID}
          onChangeMilestone={onChangeMilestone}
        />
      </div>
      {isNewMilestone && (
        <AddToMilestoneNewMilestone
          categorizationName={categorizationName}
          date={date}
          errorMessage={categorizationNameError}
          isBuiltIn={isBuiltIn}
          milestoneDesignPhase={milestoneDesignPhase}
          milestoneDesignPhaseValues={designPhaseTypes}
          milestoneName={selectedMilestoneName}
          setCategorizationName={onChangeCategorizationName}
          setDate={onChangeDate}
          setDesignPhase={onChangeDesignPhase}
          setErrorMessage={setErrorMessage}
          setMilestoneName={onChangeMilestoneName}
        />
      )}
      <div className={`${classes.vertical} ${classes.topPadding}`}>
        <div className="type-label">{`Send to ${t.lowerCase(TermKey.ESTIMATE)} or ${t.lowerCase(
          TermKey.TARGET
        )}`}</div>
        <TypeCostSelect entries={typeEntries} selected={type} onChange={onChangeType} />
      </div>
      {!isNewMilestone && !isBuiltIn && (
        <AddToMilestoneCategorizationName
          errorMessage={categorizationNameError}
          name={categorizationName}
          onChange={onChangeCategorizationName}
          setErrorMessage={setErrorMessage}
        />
      )}
      {!isNewMilestone && <Divider className={classes.divider} />}
      {!isNewMilestone && (
        <ConfirmFollowing
          averageCost={averageCost}
          currentCost={currentCost}
          isNoCurrentEstimate={isNoCurrentEstimate}
          milestoneName={milestoneName}
          type={type}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(AddToMilestoneMilestoneSelection);
