import { FC } from 'react';

import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

import NormalTooltip from '../../../NormalTooltip/NormalTooltip';

import { loadTimeDateString } from './IntegrationsUtils';

type Props = {
  onClick: () => void;
  pageLoadTime?: Date;
};

const ProcoreChangeEventLastUpdated: FC<Props> = ({ onClick, pageLoadTime }) => {
  const time = pageLoadTime ?? new Date();
  return (
    <div className="h-6 w-6">
      {/* ToDo: Replace with BabyButton and scales Tooltip */}
      <NormalTooltip title={`Change Event last synced from Procore ${loadTimeDateString(time)}`}>
        {/* Forgive me for the sin of using a material-ui button, but our current scales
            either don't have the correct styling or do not work with the material-ui tooltip.
            Once we have a react-aria tooltip we should be able to use the BabyButton scale
        */}
        <IconButton style={{ height: 24, width: 24, bottom: 2 }} onClick={onClick}>
          <Refresh style={{ width: 20, height: 20 }} />
        </IconButton>
      </NormalTooltip>
    </div>
  );
};

export default ProcoreChangeEventLastUpdated;
