import { AssigneeByIdQuery, AssigneeByIdQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { assigneeByIdQuery } from './queries';

/** assigneeByIdQuery is called in batches.
 *  Improves performance of the items list.
 *  Since the item list is virtualized, only the displayed assignees will be fetched.
 */
export default function useAssigneeByIdQuery(ID: UUID | null | undefined, skip?: boolean) {
  return useQuery<AssigneeByIdQuery, AssigneeByIdQueryVariables>(assigneeByIdQuery, {
    variables: { ID },
    skip: skip || !ID,
    context: { batch: true },
  });
}
