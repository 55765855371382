import { Scale } from '../../../../enums';
import { formatCost } from '../../../../utilities/currency';
import { formatPercent } from '../../../../utilities/string';
import ContingencyReportBarGraph from '../BarGraph/ContingencyReportBarGraph';
import { styles } from '../ContingencyReportStyles';
import { ContingencyReportType } from '../ContingencyReportUtils';

import Legend from './ContingencySummaryReportLegend';

export const processContingencySummary = (
  contingency: ContingencyReport[number],
  selectedReportType: ContingencyReportType
) => {
  const {
    name,
    startingCost,
    usedCost,
    totalPendingCost,
    totalDrawnCost,
    percentOfMilestoneEstimate,
  } = contingency;
  const starting = Number(startingCost);
  const used = -1 * Number(usedCost);
  const remaining = starting + Number(usedCost) + Number(totalDrawnCost); // usedCost and totalDrawnCost are negative coming from the backend
  const drawn =
    selectedReportType !== ContingencyReportType.PENDING ? -1 * Number(totalDrawnCost) : 0;
  const pending =
    selectedReportType !== ContingencyReportType.ACCEPTED ? -1 * Number(totalPendingCost) : 0;
  const isOverdrawn = drawn + pending > starting;

  return {
    name,
    starting,
    used,
    remaining,
    drawn,
    pending,
    isOverdrawn,
    percentOfMilestoneEstimate,
  };
};

export const getContingencySummaryHeaderRow = (
  classes: Classes<typeof styles>,
  selectedReportType: ContingencyReportType,
  hasOverdrawnContingency: boolean,
  estimateTerm: string
) => {
  return [
    {
      component: <div className={classes.headerCellSpacerLeft} />, // add padding to the left of the first column
      copy: `Name and % of ${estimateTerm}`,
      key: 'name',
    },
    {
      copy: 'Used',
      key: 'used',
    },
    ...(selectedReportType !== ContingencyReportType.ACCEPTED
      ? [
          {
            copy: 'Pending',
            key: 'pending',
          },
        ]
      : []),
    ...(selectedReportType !== ContingencyReportType.PENDING
      ? [
          {
            copy: 'Drawn this Milestone',
            key: 'drawnThisMilestone',
          },
        ]
      : []),
    {
      copy: 'Remaining',
      key: 'remaining',
    },
    {
      component: <Legend hasOverdrawnContingency={hasOverdrawnContingency} />,
      key: 'summaryBar',
      copy: '', // copy is a required property, but we don't want it in this entry
    },
  ];
};

export const getContingencySummaryRow = (
  classes: Classes<typeof styles>,
  name: string,
  starting: number,
  used: number,
  drawn: number,
  remaining: number,
  pending: number,
  selectedReportType: ContingencyReportType,
  percentOfMilestoneEstimate: number | undefined
) => [
  {
    component: (
      <div className={`${classes.summaryReportCellText} ${classes.headerCellPaddingLeft}`}>
        {name}
        {percentOfMilestoneEstimate && percentOfMilestoneEstimate > 0 && (
          <span className={classes.percentOfEstimateLabel}>
            ({formatPercent(percentOfMilestoneEstimate, Scale.PERCENT_4)})
          </span>
        )}
      </div>
    ),
    key: name,
  },
  {
    component: (
      <div className={classes.summaryReportCellText}>{formatCost(used, { rounded: true })}</div>
    ),
    key: used,
  },
  ...(selectedReportType !== ContingencyReportType.ACCEPTED
    ? [
        {
          component: (
            <div className={classes.summaryReportCellText}>
              {formatCost(pending, { rounded: true })}
            </div>
          ),
          key: pending,
        },
      ]
    : []),
  ...(selectedReportType !== ContingencyReportType.PENDING
    ? [
        {
          component: (
            <div className={classes.summaryReportCellText}>
              {formatCost(drawn, { rounded: true })}
            </div>
          ),
          key: drawn,
        },
      ]
    : []),
  {
    component: (
      <div className={classes.summaryReportCellText}>
        {formatCost(remaining, { rounded: true })}
      </div>
    ),
    key: remaining,
  },
  {
    component: (
      <ContingencyReportBarGraph starting={starting} drawn={drawn} pending={pending} used={used} />
    ),
    key: 'summaryBar',
  },
];
