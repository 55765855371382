import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  inAppNotificationsPanelAnchorVar,
  inAppNotificationsProjectIdVar,
} from '../../../api/apollo/reactiveVars';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useActiveOverlayState } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import useSetInAppNotificationReadMutation from '../hooks/useSetInAppNotificationReadMutation';

import InAppNotificationEntry from './InAppNotificationEntry';
import { getNotificationDescription, getNotificationIcon } from './InAppNotificationEntryUtils';

type Props = {
  notification: InAppNotificationWithTime;
};

export default function InAppNotificationEntryData({ notification }: Props) {
  const { id, readAt } = notification;
  const projectId = getProjectIdFromUrl();

  // Data
  const allProjectsTabSelected = !inAppNotificationsProjectIdVar();

  const description = useMemoWrapper(getNotificationDescription, notification);

  const itemId = description.item?.id;
  const icon = getNotificationIcon(notification);

  const sendAnalytics = useAnalyticsEventHook();

  const activeOverlayState = useActiveOverlayState();

  const isRead = !!readAt;
  const setInAppNotificationRead = useSetInAppNotificationReadMutation(id);

  const toggleCheck = () => {
    setInAppNotificationRead(!isRead);

    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_MARK_READ_CLICK,
        {
          action: !isRead ? 'Read' : 'Unread',
          itemId,
          notificationId: id,
          projectId,
        }
      )
    );
  };

  const onLinkClick = () => {
    activeOverlayState?.close();
    inAppNotificationsPanelAnchorVar(null);
    setInAppNotificationRead(true);

    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_LINK_VIEW_CLICK,
        {
          itemId,
          notificationId: id,
          notificationType: notification.notificationType,
          projectId,
        }
      )
    );
  };

  if (!description) return null;

  return (
    <InAppNotificationEntry
      description={description}
      icon={icon}
      isRead={isRead}
      onLinkClick={onLinkClick}
      onToggleIsRead={toggleCheck}
      showProjectDetails={allProjectsTabSelected}
    />
  );
}
