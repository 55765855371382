import { DD_NEW_NAV } from '../../../features';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { useCostMode } from '../../../utilities/costMode';

import CostModePopover from './CostModePopover';

type Props = { shouldDisplayCosts: boolean; projectID?: UUID };

export default function CostMode(props: Props) {
  const costMode = useCostMode(props.projectID);
  const displayText = props.shouldDisplayCosts ? costMode : 'Costs Hidden';

  const hasNewNav = useHasFeature(DD_NEW_NAV);
  const textColor = hasNewNav ? 'text-type-muted' : '';

  return (
    <div className="flex h-6 items-center">
      <div className={`grow ${textColor} type-body3`}>{displayText}</div>
      <CostModePopover />
    </div>
  );
}
