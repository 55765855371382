import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { makeVar, useReactiveVar } from '@apollo/client';

import { getReactiveLocal, setReactiveLocal } from '../../api/apollo/reactiveVars';
import { DASHBOARD, SCENARIOS } from '../../constants';
import { DD_WT_SILVER_BULLET_TOGGLE } from '../../features';
import { PermissionResource } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import { useProjectID } from '../routes/params';

import usePermissions from './usePermissions';

const DISPLAY_COSTS = 'shouldDisplayCosts';

type ProjectsDisplayCosts = Record<UUID, boolean>;
const fetchProjectLocalShouldDisplayCosts = () =>
  getReactiveLocal<ProjectsDisplayCosts>(DISPLAY_COSTS, {}, false);

export const getShouldDisplayCosts = (
  canViewCosts: boolean,
  projectsDisplayCosts: ProjectsDisplayCosts,
  projectID: UUID | undefined
) => {
  if (!canViewCosts) {
    return false;
  }
  if (projectID) {
    return projectsDisplayCosts[projectID] ?? true;
  }
  return true;
};

const projectsDisplayCostsVar = makeVar<ProjectsDisplayCosts>(
  fetchProjectLocalShouldDisplayCosts()
);

/**
 * useSouldDisplayCosts - Hook to get the shouldDisplayCosts and setDisplayCostsToggle function
 * @param projectID The optional project ID to check the permissions, as an override to URL;
 */
export const useShouldDisplayCosts = (chosenProjectID?: UUID) => {
  const projectIDFromURL = useProjectID();
  const projectID = chosenProjectID || projectIDFromURL;
  const location = useLocation();

  // Get the shouldDisplayCosts for the project by combining the permissions and the local state
  const { canView } = usePermissions({ projectID });
  const canViewCosts = canView(PermissionResource.DISPLAY_COSTS);
  const hasToggleFeature = useHasFeature(DD_WT_SILVER_BULLET_TOGGLE);
  const projectsShouldDisplayCosts = useReactiveVar(projectsDisplayCostsVar);
  const shouldDisplayCosts = getShouldDisplayCosts(
    canViewCosts,
    projectsShouldDisplayCosts,
    projectID
  );

  // Build up the setshouldDisplayCosts function
  const setProjectShouldDisplayCosts = useCallback(
    (checked: boolean) => {
      if (projectID) {
        const newProjectsShouldDisplayCosts = {
          ...projectsShouldDisplayCosts,
          [projectID]: checked,
        };
        setReactiveLocal(
          projectsDisplayCostsVar,
          DISPLAY_COSTS,
          newProjectsShouldDisplayCosts,
          false
        );
      }
    },
    [projectID, projectsShouldDisplayCosts]
  );

  const setDisplayCostsToggle =
    canViewCosts && hasToggleFeature ? setProjectShouldDisplayCosts : null;

  const isDashboard = location.pathname.indexOf(`/${DASHBOARD}`) >= 0;
  const isScenarios = location.pathname.indexOf(`/${SCENARIOS}`) >= 0;
  const isToggleDisabled = isDashboard || isScenarios;

  return {
    /**
     * shouldDisplayCosts: Whether project costs should be displayed or not
     * based on your permissions and if you choose to display them
     */
    shouldDisplayCosts,
    /**
     * setDisplayCostsToggle: Function to toggle the display of project costs
     * the function is null if the user does not have the permission to view costs
     */
    setDisplayCostsToggle,
    /**
     * isToggleDisabled: Whether the toggle should be disabled or not
     * based on the current page
     */
    isToggleDisabled,
  };
};
