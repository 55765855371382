import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      minHeight: 32,
      paddingRight: 8,
    },
    cell: {
      borderRight: '0px',
      padding: 8,
      width: '30%',
    },
    disabled: {
      opacity: 0.5,
    },
    disableCell: {
      borderLeft: '0px',
      borderRight: '0px',
      opacity: 0.5,
    },
    editCell: {
      borderLeft: '0px',
      borderRight: '0px',
      padding: 0,
    },
    leftCell: {
      padding: 8,
      width: 175,
    },
    list: {
      padding: 0,
      overflow: 'visible',
    },
    na: {
      marginLeft: 28,
    },
    rightCell: {
      borderLeft: '0px',
      borderRight: '0px',
      padding: 0,
      width: '30%',
    },
    root: {
      backgroundColor: 'white',
      paddingBottom: 24,
    },
    row: {
      backgroundColor: 'white !important',
      borderRight: theme.border.line,
      borderTop: theme.border.line,
      '&:last-child': {
        borderBottom: theme.border.line,
        borderTop: theme.border.line,
        paddingLeft: 24,
      },
      '&:nth-child(even)': {
        backgroundColor: 'white',
      },
    },
    smallRadio: {
      height: 20,
      margin: '2px 8px',
      width: 20,
    },
    tableText: {
      fontSize: 12,
      textAlign: 'left',
    },
    title: {
      fontWeight: 300,
      padding: '24px',
    },
    wideCell: {
      borderLeft: '0px',
      padding: 8,
      width: '40%',
    },
  });

export default styles;
