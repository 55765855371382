type IconProps = {
  className?: string;
};

export const SearchIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 17.8C11.325 17.8 9.52498 17.1 8.22498 15.8C6.92498 14.5 6.22498 12.8 6.22498 10.9C6.22498 9.1 6.92498 7.3 8.22498 6C9.52498 4.7 11.225 4 13.125 4C15.025 4 16.725 4.7 18.025 6C19.325 7.3 20.025 9 20.025 10.9C20.025 12.7 19.325 14.5 18.025 15.8C16.725 17.1 15.025 17.8 13.125 17.8ZM13.125 5C11.525 5 10.125 5.6 8.92498 6.7C7.82498 7.8 7.22498 9.3 7.22498 10.9C7.22498 12.5 7.82498 14 8.92498 15.1C10.025 16.2 11.525 16.8 13.125 16.8C14.725 16.8 16.225 16.2 17.325 15.1C18.425 13.9 19.025 12.5 19.025 10.9C19.025 9.3 18.425 7.8 17.325 6.7C16.225 5.6 14.725 5 13.125 5Z"
      fill="currentColor"
    />
    <path
      d="M4.52498 20C4.42498 20 4.22498 20 4.12498 19.9C3.92498 19.7 3.92498 19.4 4.12498 19.2L8.22498 15.1C8.42498 14.9 8.72498 14.9 8.92498 15.1C9.12498 15.3 9.12498 15.6 8.92498 15.8L4.82498 19.9C4.82498 20 4.62498 20 4.52498 20Z"
      fill="currentColor"
    />
  </svg>
);

export const NotificationsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0847 19.7501C10.9847 19.7501 9.98473 19.1501 9.48473 18.1501C9.38473 17.9501 9.48473 17.6501 9.68473 17.4501C9.88473 17.3501 10.1847 17.4501 10.3847 17.6501C10.7847 18.3501 11.3847 18.7501 12.0847 18.7501C12.7847 18.7501 13.3847 18.3501 13.7847 17.6501C13.8847 17.4501 14.1847 17.3501 14.4847 17.4501C14.6847 17.5501 14.7847 17.8501 14.6847 18.1501C14.1847 19.1501 13.1847 19.7501 12.0847 19.7501Z"
      fill="currentColor"
    />
    <path
      d="M18.9847 16.35H4.98473C4.78473 16.35 4.58473 16.25 4.58473 16.05C4.58473 15.85 4.48473 15.65 4.58473 15.55L5.18473 14.75C5.88473 13.85 6.18473 12.85 6.18473 11.75V10.05C6.18473 6.85 8.78473 4.25 11.9847 4.25C15.1847 4.25 17.7847 6.85 17.7847 10.05V11.75C17.7847 12.85 18.1847 13.95 18.7847 14.75L19.3847 15.55C19.4847 15.75 19.4847 15.95 19.3847 16.05C19.2847 16.15 19.1847 16.35 18.9847 16.35ZM5.98473 15.35H17.9847C17.1847 14.35 16.7847 13.05 16.7847 11.75V10.05C16.7847 7.35 14.5847 5.25 11.9847 5.25C9.28473 5.25 7.18473 7.45 7.18473 10.05V11.75C7.18473 13.05 6.78473 14.35 5.98473 15.35Z"
      fill="currentColor"
    />
  </svg>
);

export const DashboardIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 16.25C19.2 16.25 19 16.05 19 15.75C19 14.65 18.8 13.65 18.3 12.75C18.2 12.55 18.3 12.25 18.5 12.05C18.7 11.95 19 12.05 19.2 12.25C19.7 13.35 20 14.45 20 15.65C20 16.05 19.8 16.25 19.5 16.25Z"
      fill="currentColor"
    />
    <path
      d="M4.5 16.25C4.2 16.25 4 16.05 4 15.75C4 11.35 7.6 7.75 12 7.75C14.1 7.75 16.1 8.55 17.7 10.05C17.9 10.25 17.9 10.55 17.7 10.75C17.5 10.95 17.1 11.05 17 10.85C15.7 9.55 13.9 8.75 12 8.75C8.1 8.75 5 11.85 5 15.75C5 16.05 4.8 16.25 4.5 16.25Z"
      fill="currentColor"
    />
    <path
      d="M12 16.25C11.9 16.25 11.7 16.25 11.6 16.15C11.4 15.95 11.4 15.65 11.6 15.45L18.1 8.95005C18.3 8.75005 18.6 8.75005 18.8 8.95005C19 9.15005 19 9.45005 18.8 9.65005L12.3 16.15C12.3 16.25 12.1 16.25 12 16.25Z"
      fill="currentColor"
    />
  </svg>
);

export const ItemsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3 15.1001C10.1 15.1001 10 15.0001 9.9 15.0001L7.9 13.0001C7.7 12.8001 7.7 12.5001 7.9 12.3001C8.1 12.1001 8.4 12.1001 8.6 12.3001L10.3 14.0001L15.3 9.0001C15.5 8.8001 15.8 8.8001 16 9.0001C16.2 9.2001 16.2 9.5001 16 9.7001L10.6 15.0001C10.5 15.0001 10.4 15.1001 10.3 15.1001Z"
      fill="currentColor"
    />
    <path
      d="M12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20ZM12 5C8.1 5 5 8.1 5 12C5 15.9 8.1 19 12 19C15.9 19 19 15.9 19 12C19 8.1 15.9 5 12 5Z"
      fill="currentColor"
    />
  </svg>
);

export const MilestonesIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9 15H11.4C10.9 15 10.4 14.6 10.4 14V12.7H7.09998C6.59998 12.7 6.09998 12.3 6.09998 11.7V5C6.09998 4.5 6.49998 4 7.09998 4H12.6C13.1 4 13.6 4.4 13.6 5V6.3H16.9C17.4 6.3 17.9 6.7 17.9 7.3V14C17.9 14.5 17.5 15 16.9 15ZM10.4 11.7C10.9 11.7 11.4 12.1 11.4 12.7V14H16.9V7.2H13.6C13.1 7.2 12.6 6.8 12.6 6.2V5H7.09998V11.8L10.4 11.7Z"
      fill="currentColor"
    />
    <path
      d="M6.59998 20C6.29998 20 6.09998 19.8 6.09998 19.5V12.2C6.09998 11.9 6.29998 11.7 6.59998 11.7C6.89998 11.7 7.09998 12 7.09998 12.2V19.5C7.09998 19.8 6.89998 20 6.59998 20Z"
      fill="currentColor"
    />
  </svg>
);

export const ReportsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 19.75H4.5C4.2 19.75 4 19.55 4 19.25C4 18.95 4.2 18.75 4.5 18.75H19.5C19.8 18.75 20 18.95 20 19.25C20 19.55 19.8 19.75 19.5 19.75Z"
      fill="currentColor"
    />
    <path
      d="M8.19999 19.6499H5.29999C4.99999 19.6499 4.79999 19.4499 4.79999 19.1499V9.9499C4.79999 9.5499 5.19999 9.1499 5.59999 9.1499H7.79999C8.19999 9.1499 8.59999 9.5499 8.59999 9.9499V19.1499C8.69999 19.4499 8.39999 19.6499 8.19999 19.6499ZM5.79999 18.6499H7.59999V10.1499H5.79999V18.6499Z"
      fill="currentColor"
    />
    <path
      d="M7.80001 8.0498H5.70001C5.40001 8.0498 5.20001 7.8498 5.20001 7.5498C5.20001 7.2498 5.40001 7.0498 5.70001 7.0498H7.80001C8.10001 7.0498 8.30001 7.2498 8.30001 7.5498C8.30001 7.8498 8.10001 8.0498 7.80001 8.0498Z"
      fill="currentColor"
    />
    <path
      d="M13.3 19.6498H10.5C10.2 19.6498 10 19.4498 10 19.1498V15.8498C10 15.4498 10.4 15.0498 10.8 15.0498H13C13.4 15.0498 13.8 15.4498 13.8 15.8498V19.1498C13.8 19.4498 13.6 19.6498 13.3 19.6498ZM11 18.6498H12.8V16.0498H11V18.6498Z"
      fill="currentColor"
    />
    <path
      d="M13 13.95H10.9C10.6 13.95 10.4 13.75 10.4 13.45C10.4 13.15 10.6 12.95 10.9 12.95H13C13.3 12.95 13.5 13.15 13.5 13.45C13.5 13.75 13.2 13.95 13 13.95Z"
      fill="currentColor"
    />
    <path
      d="M18.5 19.8498H15.7C15.4 19.8498 15.2 19.6498 15.2 19.3498V6.8498C15.2 6.4498 15.6 6.0498 16 6.0498H18.2C18.6 6.0498 19 6.4498 19 6.8498V19.3498C19 19.6498 18.8 19.8498 18.5 19.8498ZM16.2 18.8498H18V7.0498H16.2V18.8498Z"
      fill="currentColor"
    />
    <path
      d="M18.1 5.1499H16C15.7 5.1499 15.5 4.9499 15.5 4.6499C15.5 4.3499 15.7 4.1499 16 4.1499H18.1C18.4 4.1499 18.6 4.3499 18.6 4.6499C18.6 4.9499 18.4 5.1499 18.1 5.1499Z"
      fill="currentColor"
    />
  </svg>
);

export const TimelineIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1 20.0001H5.90001C5.00001 20.0001 4.20001 19.2001 4.20001 18.3001V7.8001C4.20001 6.9001 4.90001 6.1001 5.90001 6.1001H7.70001C8.00001 6.1001 8.20001 6.4001 8.20001 6.6001C8.20001 6.8001 8.00001 7.1001 7.70001 7.1001H5.90001C5.50001 7.1001 5.20001 7.4001 5.20001 7.8001V18.3001C5.20001 18.7001 5.50001 19.0001 5.90001 19.0001H18.2C18.6 19.0001 18.9 18.7001 18.9 18.3001V7.8001C18.9 7.4001 18.6 7.1001 18.2 7.1001H17.8C17.5 7.1001 17.3 6.9001 17.3 6.6001C17.3 6.3001 17.5 6.1001 17.7 6.1001H18.1C19 6.1001 19.8 6.9001 19.8 7.8001V18.3001C19.8 19.3001 19.1 20.0001 18.1 20.0001Z"
      fill="currentColor"
    />
    <path
      d="M15.7 7.1001H10.3C9.99999 7.1001 9.79999 6.9001 9.79999 6.6001C9.79999 6.3001 9.99999 6.1001 10.3 6.1001H15.7C16 6.1001 16.2 6.3001 16.2 6.6001C16.2 6.9001 16 7.1001 15.7 7.1001Z"
      fill="currentColor"
    />
    <path
      d="M15.8 8C15.5 8 15.3 7.8 15.3 7.5V4.5C15.3 4.2 15.5 4 15.8 4C16.1 4 16.3 4.2 16.3 4.5V7.5C16.3 7.8 16.1 8 15.8 8Z"
      fill="currentColor"
    />
    <path
      d="M8.20001 8C7.90001 8 7.70001 7.8 7.70001 7.5V4.5C7.70001 4.2 7.90001 4 8.20001 4C8.50001 4 8.70001 4.2 8.70001 4.5V7.5C8.70001 7.8 8.40001 8 8.20001 8Z"
      fill="currentColor"
    />
    <path
      d="M18.8 10.5H5.20001C4.90001 10.5 4.70001 10.3 4.70001 10C4.70001 9.7 4.90001 9.5 5.20001 9.5H18.8C19.1 9.5 19.3 9.7 19.3 10C19.3 10.3 19.1 10.5 18.8 10.5Z"
      fill="currentColor"
    />
    <path d="M16.7 14.8999H14.6V16.9999H16.7V14.8999Z" fill="currentColor" />
    <path
      d="M16.8 17.6H14.7C14.4 17.6 14.2 17.4 14.2 17.1V15C14.2 14.7 14.4 14.5 14.7 14.5H16.8C17.1 14.5 17.3 14.7 17.3 15V17.1C17.3 17.4 17 17.6 16.8 17.6ZM15.1 16.6H16.2V15.5H15.1V16.6Z"
      fill="currentColor"
    />
  </svg>
);

export const ScenariosIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.85 12.3H9.54999C9.24999 12.3 9.04999 12.1 9.04999 11.8C9.04999 11.5 9.24999 11.3 9.54999 11.3H14.85C15.15 11.3 15.35 11.5 15.35 11.8C15.35 12.1 15.15 12.3 14.85 12.3Z"
      fill="currentColor"
    />
    <path
      d="M11.25 16.8H6.04998C5.34998 16.8 4.84998 16.3 4.84998 15.6V5.2C4.84998 4.5 5.34998 4 6.04998 4H11.25C11.95 4 12.45 4.5 12.45 5.2V11.7C12.45 12 12.25 12.3 11.95 12.3C11.65 12.3 11.45 12 11.45 11.8V5.2C11.45 5.1 11.35 5 11.25 5H6.04998C5.94998 5 5.84998 5.1 5.84998 5.2V15.6C5.84998 15.7 5.94998 15.8 6.04998 15.8H11.25C11.35 15.8 11.45 15.7 11.45 15.6V14.5C11.45 14.2 11.65 14 11.95 14C12.25 14 12.45 14.2 12.45 14.5V15.6C12.45 16.3 11.95 16.8 11.25 16.8Z"
      fill="currentColor"
    />
    <path
      d="M17.95 20H12.75C12.05 20 11.55 19.5 11.55 18.8C11.55 18.5 11.75 18.3 12.05 18.3C12.35 18.3 12.55 18.5 12.55 18.8C12.55 18.9 12.65 19 12.75 19H17.95C18.05 19 18.15 18.9 18.15 18.8V8.39995C18.15 8.29995 18.05 8.19995 17.95 8.19995H14.45C14.15 8.19995 13.95 7.99995 13.95 7.69995C13.95 7.39995 14.15 7.19995 14.45 7.19995H17.95C18.65 7.19995 19.15 7.69995 19.15 8.39995V18.8C19.15 19.5 18.65 20 17.95 20Z"
      fill="currentColor"
    />
    <path
      d="M14.45 14.0999L17.05 12.0999C17.25 11.8999 17.25 11.5999 17.05 11.4999L14.45 9.49994C14.15 9.29994 13.85 9.49994 13.85 9.79994V13.6999C13.75 14.0999 14.15 14.2999 14.45 14.0999Z"
      fill="currentColor"
    />
  </svg>
);

export const SettingsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9 10.9001V6.9001C14.9 6.4001 15.3 6.1001 15.7 6.1001H16.2C16.7 6.1001 17 6.5001 17 6.9001V10.9001C17 11.4001 16.6 11.7001 16.2 11.7001H15.7C15.3 11.7001 14.9 11.4001 14.9 10.9001Z"
      fill="currentColor"
    />
    <path
      d="M16.3 12.2001H15.8C15.1 12.2001 14.5 11.6001 14.5 10.9001V6.9001C14.5 6.2001 15.1 5.6001 15.8 5.6001H16.3C17 5.6001 17.6 6.2001 17.6 6.9001V10.9001C17.6 11.6001 17 12.2001 16.3 12.2001ZM15.8 6.6001C15.6 6.6001 15.5 6.7001 15.5 6.9001V10.9001C15.5 11.1001 15.6 11.2001 15.8 11.2001H16.3C16.5 11.2001 16.6 11.1001 16.6 10.9001V6.9001C16.6 6.7001 16.5 6.6001 16.3 6.6001H15.8Z"
      fill="currentColor"
    />
    <path
      d="M12.4 9.7002H4.5C4.2 9.7002 4 9.4002 4 9.2002C4 9.0002 4.2 8.7002 4.5 8.7002H12.4C12.7 8.7002 12.9 8.9002 12.9 9.2002C12.9 9.5002 12.7 9.7002 12.4 9.7002Z"
      fill="currentColor"
    />
    <path
      d="M19.5 9.7002H17.7C17.4 9.7002 17.2 9.5002 17.2 9.2002C17.2 8.9002 17.4 8.7002 17.7 8.7002H19.5C19.8 8.7002 20 8.9002 20 9.2002C20 9.5002 19.8 9.7002 19.5 9.7002Z"
      fill="currentColor"
    />
    <path
      d="M10.7 13.1V17.1C10.7 17.6 10.3 17.9 9.89998 17.9H9.39998C8.89998 17.9 8.59998 17.5 8.59998 17.1V13.1C8.59998 12.6 8.99998 12.3 9.39998 12.3H9.89998C10.4 12.3 10.7 12.6 10.7 13.1Z"
      fill="currentColor"
    />
    <path
      d="M9.89998 18.4H9.39998C8.69998 18.4 8.09998 17.8 8.09998 17.1V13.1C8.09998 12.4 8.69998 11.8 9.39998 11.8H9.89998C10.6 11.8 11.2 12.4 11.2 13.1V17.1C11.2 17.8 10.6 18.4 9.89998 18.4ZM9.39998 12.8C9.19998 12.8 9.09998 12.9 9.09998 13.1V17.1C9.09998 17.3 9.19998 17.4 9.39998 17.4H9.89998C10.1 17.4 10.2 17.3 10.2 17.1V13.1C10.2 12.9 10.1 12.8 9.89998 12.8H9.39998Z"
      fill="currentColor"
    />
    <path
      d="M19.5 15.3H11.6C11.3 15.3 11.1 15.1 11.1 14.8C11.1 14.5 11.3 14.3 11.6 14.3H19.5C19.8 14.3 20 14.5 20 14.8C20 15.1 19.8 15.3 19.5 15.3Z"
      fill="currentColor"
    />
    <path
      d="M6.3 15.3H4.5C4.2 15.3 4 15.1 4 14.8C4 14.5 4.2 14.3 4.5 14.3H6.3C6.6 14.3 6.8 14.5 6.8 14.8C6.8 15.1 6.6 15.3 6.3 15.3Z"
      fill="currentColor"
    />
  </svg>
);

export const GearIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2266_22365)">
      <path
        d="M6.7 15.5C6.1 15.5 5.6 15.1 5.5 14.5L5.4 13.8C5.4 13.7 5.3 13.7 5.3 13.6C5.1 13.5 4.9 13.4 4.7 13.2C4.6 13.2 4.6 13.1 4.5 13.2L3.8 13.4C3.2 13.6 2.6 13.4 2.3 12.9L1 10.6C0.7 10.1 0.8 9.4 1.3 9.1L2 8.5C2 8.3 2 8.2 2 8C2 7.8 2 7.7 2 7.5L1.1 6.9C0.7 6.5 0.5 5.9 0.8 5.4L2.1 3.1C2.4 2.6 3 2.4 3.6 2.5L4.3 2.7C4.4 2.7 4.4 2.7 4.5 2.7C4.8 2.7 5 2.5 5.2 2.4C5.3 2.4 5.3 2.3 5.3 2.2L5.4 1.5C5.5 0.9 6 0.5 6.6 0.5H9.2C9.8 0.5 10.3 0.9 10.4 1.5L10.5 2.2C10.5 2.3 10.6 2.3 10.6 2.4C10.8 2.5 11 2.6 11.2 2.8C11.3 2.8 11.4 2.9 11.4 2.8L12.1 2.6C12.7 2.4 13.3 2.6 13.6 3.1L14.9 5.4C15.2 5.9 15.1 6.6 14.6 6.9L13.9 7.5C13.9 7.7 13.9 7.8 13.9 8C13.9 8.2 13.9 8.3 13.9 8.5L14.6 9.1C15 9.5 15.2 10.1 14.9 10.6L13.6 12.9C13.3 13.4 12.7 13.6 12.1 13.5L11.4 13.3C11.3 13.3 11.3 13.3 11.2 13.3C11 13.4 10.8 13.6 10.6 13.7C10.6 13.7 10.5 13.8 10.5 13.9L10.4 14.6C10.3 15.2 9.8 15.6 9.2 15.6L6.7 15.5ZM4.4 12.2C4.6 12.2 4.9 12.3 5.1 12.4C5.3 12.5 5.4 12.6 5.6 12.7C6 12.9 6.2 13.2 6.3 13.6L6.4 14.3C6.4 14.4 6.5 14.5 6.6 14.5H9.2C9.3 14.5 9.4 14.4 9.4 14.3L9.5 13.6C9.6 13.2 9.8 12.9 10.2 12.7C10.4 12.6 10.6 12.5 10.7 12.4C11 12.2 11.4 12.1 11.8 12.2L12.5 12.4C12.6 12.4 12.7 12.4 12.8 12.3L14.1 10C14.2 9.9 14.1 9.8 14.1 9.7L13.2 9C13.1 8.9 13 8.7 13 8.6C13 8.4 13 8.2 13 8C13 7.8 13 7.6 13 7.3C13 7.1 13 7 13.2 6.9L14.1 6.2C14.2 6.1 14.2 6 14.1 5.9L13 3.6C12.9 3.5 12.8 3.5 12.7 3.5L12 3.8C11.6 3.9 11.2 3.9 10.9 3.7C10.7 3.6 10.6 3.5 10.4 3.4C10 3.1 9.7 2.8 9.7 2.4L9.5 1.7C9.5 1.6 9.4 1.5 9.3 1.5H6.7C6.6 1.5 6.5 1.6 6.5 1.7L6.3 2.4C6.2 2.8 6 3.1 5.6 3.3C5.5 3.4 5.3 3.5 5.1 3.6C4.8 3.8 4.4 3.9 4 3.8L3.3 3.5C3.2 3.5 3.1 3.5 3 3.6L1.7 5.9C1.6 6 1.7 6.1 1.7 6.2L2.6 6.9C2.8 7 2.8 7.2 2.8 7.4C2.8 7.6 2.8 7.8 2.8 8C2.8 8.2 2.8 8.4 2.8 8.7C2.8 8.9 2.8 9 2.6 9.1L1.8 9.8C1.7 9.9 1.7 10 1.8 10.1L3 12.4C3.1 12.5 3.2 12.5 3.3 12.5L4 12.2C4.2 12.2 4.3 12.2 4.4 12.2Z"
        fill="currentColor"
      />
      <path
        d="M8 11.1998C6.2 11.1998 4.8 9.7998 4.8 7.9998C4.8 6.1998 6.2 4.7998 8 4.7998C9.8 4.7998 11.2 6.1998 11.2 7.9998C11.2 9.7998 9.8 11.1998 8 11.1998ZM8 5.7998C6.8 5.7998 5.8 6.7998 5.8 7.9998C5.8 9.1998 6.8 10.1998 8 10.1998C9.2 10.1998 10.2 9.1998 10.2 7.9998C10.2 6.7998 9.2 5.7998 8 5.7998Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2266_22365">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TeamIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4293 10.8001C13.4293 11.7001 12.8293 12.4001 12.0293 12.4001C11.2293 12.4001 10.6293 11.7001 10.6293 10.8001V10.5001C10.6293 9.60015 11.2293 8.90015 12.0293 8.90015C12.8293 8.90015 13.4293 9.60015 13.4293 10.5001V10.8001Z"
      fill="currentColor"
    />
    <path
      d="M12.0293 12.9001C11.0293 12.9001 10.1293 12.0001 10.1293 10.8001V10.5001C10.1293 9.30015 11.0293 8.40015 12.0293 8.40015C13.0293 8.40015 13.9293 9.30015 13.9293 10.5001V10.8001C13.9293 12.0001 13.0293 12.9001 12.0293 12.9001ZM12.0293 9.40015C11.5293 9.40015 11.1293 9.90015 11.1293 10.5001V10.8001C11.1293 11.4001 11.5293 11.9001 12.0293 11.9001C12.5293 11.9001 12.9293 11.4001 12.9293 10.8001V10.5001C12.9293 9.90015 12.5293 9.40015 12.0293 9.40015Z"
      fill="currentColor"
    />
    <path
      d="M14.8292 16.2C14.6292 15.6 13.9292 14.8 13.1292 14.7C12.4292 14.6 11.7292 14.6 10.9292 14.7C10.2292 14.8 9.42925 15.6 9.22925 16.2H14.8292Z"
      fill="currentColor"
    />
    <path
      d="M14.8292 16.7H9.22925C9.02925 16.7 8.92925 16.6 8.82925 16.5C8.72925 16.4 8.72925 16.2 8.72925 16C9.02925 15.3 9.82925 14.4 10.8292 14.2C11.6292 14.1 12.4292 14.1 13.1292 14.2C14.0292 14.4 14.9292 15.3 15.2292 16C15.3292 16.2 15.2292 16.3 15.1292 16.5C15.1292 16.6 14.9292 16.7 14.8292 16.7ZM10.2292 15.7H13.9292C13.7292 15.5 13.3292 15.3 13.1292 15.2C12.4292 15.1 11.8292 15.1 11.1292 15.2C10.7292 15.2 10.4292 15.4 10.2292 15.7Z"
      fill="currentColor"
    />
    <path
      d="M18.0292 9.80005C18.0292 10.7 17.4292 11.4 16.6292 11.4C15.8292 11.4 15.2292 10.6 15.2292 9.80005V9.40005C15.2292 8.50005 15.8292 7.80005 16.6292 7.80005C17.4292 7.80005 18.0292 8.60005 18.0292 9.40005V9.80005Z"
      fill="currentColor"
    />
    <path
      d="M16.6292 11.8C15.6292 11.8 14.7292 10.9 14.7292 9.70005V9.40005C14.7292 8.30005 15.6292 7.30005 16.6292 7.30005C17.6292 7.30005 18.5292 8.20005 18.5292 9.40005V9.80005C18.5292 10.9 17.6292 11.8 16.6292 11.8ZM16.6292 8.30005C16.1292 8.30005 15.7292 8.80005 15.7292 9.40005V9.80005C15.7292 10.4 16.1292 10.9 16.6292 10.9C17.1292 10.9 17.5292 10.4 17.5292 9.80005V9.40005C17.5292 8.80005 17.0292 8.30005 16.6292 8.30005Z"
      fill="currentColor"
    />
    <path
      d="M16.9292 15.1001H19.3292C19.1292 14.5001 18.4292 13.7001 17.6292 13.6001C16.9292 13.5001 16.1292 13.5001 15.4292 13.6001C15.3292 13.6001 15.3292 13.6001 15.2292 13.7001C15.9292 14.0001 16.5292 14.6001 16.9292 15.1001Z"
      fill="currentColor"
    />
    <path
      d="M19.3292 15.6001H16.9292C16.7292 15.6001 16.6292 15.5001 16.5292 15.4001C16.1292 14.9001 15.6292 14.4001 15.1292 14.1001C14.9292 14.0001 14.8292 13.8001 14.8292 13.6001C14.8292 13.4001 14.9292 13.2001 15.1292 13.2001C15.2292 13.2001 15.3292 13.1001 15.4292 13.1001C16.2292 13.0001 17.0292 13.0001 17.7292 13.1001C18.6292 13.3001 19.5292 14.2001 19.8292 14.9001C19.9292 15.1001 19.8292 15.2001 19.7292 15.4001C19.6292 15.5001 19.5292 15.6001 19.3292 15.6001ZM17.1292 14.6001H18.4292C18.2292 14.4001 17.8292 14.2001 17.6292 14.1001C17.3292 14.0001 17.0292 14.0001 16.7292 14.0001C16.8292 14.2001 17.0292 14.4001 17.1292 14.6001Z"
      fill="currentColor"
    />
    <path
      d="M6.0293 9.80005C6.0293 10.7 6.6293 11.4 7.4293 11.4C8.2293 11.4 8.8293 10.6 8.8293 9.80005V9.40005C8.8293 8.60005 8.2293 7.80005 7.4293 7.80005C6.6293 7.80005 6.0293 8.60005 6.0293 9.40005V9.80005Z"
      fill="currentColor"
    />
    <path
      d="M7.4293 11.8C6.4293 11.8 5.5293 10.9 5.5293 9.80005V9.40005C5.5293 8.30005 6.4293 7.30005 7.4293 7.30005C8.4293 7.30005 9.3293 8.20005 9.3293 9.40005V9.80005C9.3293 10.9 8.5293 11.8 7.4293 11.8ZM7.4293 8.30005C6.9293 8.30005 6.5293 8.80005 6.5293 9.40005V9.80005C6.5293 10.3 6.9293 10.8 7.4293 10.8C7.9293 10.8 8.3293 10.3 8.3293 9.80005V9.40005C8.3293 8.80005 7.9293 8.30005 7.4293 8.30005Z"
      fill="currentColor"
    />
    <path
      d="M7.12927 15.1001H4.62927C4.82927 14.5001 5.52927 13.7001 6.32927 13.6001C7.02927 13.5001 7.82927 13.5001 8.52927 13.6001C8.62927 13.6001 8.62927 13.6001 8.72927 13.7001C8.02927 14.0001 7.52927 14.6001 7.12927 15.1001Z"
      fill="currentColor"
    />
    <path
      d="M7.12927 15.6001H4.62927C4.42927 15.6001 4.32927 15.5001 4.22927 15.4001C4.12927 15.3001 4.12927 15.1001 4.12927 14.9001C4.42927 14.2001 5.22927 13.3001 6.22927 13.1001C7.02927 13.0001 7.82927 13.0001 8.52927 13.1001C8.62927 13.1001 8.72927 13.1001 8.82927 13.2001C9.02927 13.3001 9.22927 13.5001 9.22927 13.7001C9.22927 13.9001 9.12927 14.1001 8.92927 14.2001C8.42927 14.5001 7.82927 14.9001 7.52927 15.5001C7.42927 15.5001 7.32927 15.6001 7.12927 15.6001ZM5.62927 14.6001H6.92927C7.12927 14.4001 7.22927 14.2001 7.42927 14.0001C7.12927 14.0001 6.82927 14.0001 6.52927 14.1001C6.12927 14.2001 5.82927 14.4001 5.62927 14.6001Z"
      fill="currentColor"
    />
  </svg>
);

export const HistoricalItemsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20C9.9 20 7.9 19.2 6.3 17.7C4.8 16.2 4 14.1 4 12C4 11.7 4.2 11.5 4.5 11.5C4.8 11.5 5 11.7 5 12C5 13.9 5.7 15.6 7.1 16.9C8.4 18.2 10.2 18.9 12 18.9C13.9 18.9 15.6 18.2 16.9 16.8C18.2 15.5 18.9 13.7 18.9 11.8C18.9 9.9 18.3 8.4 16.9 7C15.6 5.7 13.9 5 12 5C10.1 5 8.4 5.7 7 7.1C6.8 7.3 6.5 7.3 6.3 7.1C6.1 6.9 6.1 6.6 6.3 6.4C7.8 4.8 9.9 4 12 4C14.1 4 16.1 4.8 17.7 6.3C19.2 7.8 20 9.9 20 12C20 16.4 16.4 20 12 20Z"
      fill="currentColor"
    />
    <path
      d="M5.89998 4.5001L5.09998 7.7001C5.09998 8.0001 5.29998 8.2001 5.59998 8.2001L8.89998 8.0001C9.19998 8.0001 9.39998 7.6001 9.19998 7.3001L6.69998 4.3001C6.39998 4.1001 5.99998 4.2001 5.89998 4.5001Z"
      fill="currentColor"
    />
    <path
      d="M10.3 15.4C10.2 15.4 10 15.4 9.9 15.3L7.9 13.3C7.7 13 7.7 12.7 7.9 12.5C8.1 12.3 8.4 12.3 8.6 12.5L10.3 14.2L15.3 9.20005C15.5 9.00005 15.8 9.00005 16 9.20005C16.2 9.40005 16.2 9.70005 16 9.90005L10.6 15.3C10.6 15.3 10.4 15.4 10.3 15.4Z"
      fill="currentColor"
    />
  </svg>
);

export const ForecastingIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.2 11.4C5.1 11.4 5 11.4 4.9 11.3C4.7 11.1 4.7 10.8 4.8 10.6L6.8 8.1C7.5 7.2 8.7 6.8 9.8 7.1C10 7.2 10.3 7.3 10.5 7.4C11 7.7 11.3 8 11.5 8.3H12.4C12.9 7.5 13.8 7 14.7 7C15.6 7 16.4 7.4 16.9 8.1L18.7 10.2L18 10.9L16.2 8.8C15.8 8.3 15.3 8 14.7 8C14 8 13.4 8.4 13.1 9C13 9.2 12.9 9.3 12.7 9.3H11.2C11 9.3 10.8 9.2 10.7 9C10.6 8.7 10.4 8.5 10.1 8.3C9.9 8.2 9.8 8.1 9.6 8.1C8.9 7.9 8.1 8.1 7.6 8.7L5.6 11.2C5.5 11.3 5.4 11.4 5.2 11.4Z"
      fill="currentColor"
    />
    <path
      d="M16.1 14.9C17.0389 14.9 17.8 14.1389 17.8 13.2C17.8 12.2611 17.0389 11.5 16.1 11.5C15.1611 11.5 14.4 12.2611 14.4 13.2C14.4 14.1389 15.1611 14.9 16.1 14.9Z"
      fill="currentColor"
    />
    <path
      d="M7.79998 14.9C8.73886 14.9 9.49998 14.1389 9.49998 13.2C9.49998 12.2611 8.73886 11.5 7.79998 11.5C6.86109 11.5 6.09998 12.2611 6.09998 13.2C6.09998 14.1389 6.86109 14.9 7.79998 14.9Z"
      fill="currentColor"
    />
    <path
      d="M16.1 17C14.8 17 13.5 16.3 12.8 15.2H11.1C10.4 16.3 9.2 17 7.9 17C5.7 17 4 15.2 4 13.1C4 11 5.7 9.30005 7.9 9.30005C9.1 9.30005 10.3 9.90005 11 11H13C13.7 9.90005 14.9 9.30005 16.2 9.30005C18.3 9.30005 20 11 20 13.1C20 15.2 18.3 17 16.1 17ZM10.8 14.1H13.2C13.4 14.1 13.6 14.2 13.6 14.4C14.1 15.4 15.1 16 16.1 16C17.7 16 19 14.7 19 13.1C19 11.5 17.7 10.2 16.1 10.2C15.1 10.2 14.1 10.8 13.6 11.7C13.6 11.9 13.4 12 13.2 12H10.8C10.6 12 10.4 11.9 10.3 11.7C9.8 10.8 8.9 10.3 7.9 10.3C6.3 10.3 5 11.6 5 13.1C5 14.7 6.3 16 7.9 16C9 16 9.9 15.4 10.4 14.4C10.5 14.2 10.6 14.1 10.8 14.1Z"
      fill="currentColor"
    />
  </svg>
);

export const InsightsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3625 18.1001C6.2625 18.1001 6.0625 18.0001 5.9625 18.0001C3.2625 15.3001 3.2625 10.8001 5.9625 8.1001C7.3625 6.7001 8.9625 6.1001 10.8625 6.1001C11.1625 6.1001 11.3625 6.3001 11.3625 6.6001V13.1001C11.3625 13.2001 11.2625 13.4001 11.2625 13.5001L6.6625 18.1001C6.6625 18.0001 6.5625 18.1001 6.3625 18.1001ZM10.4625 7.0001C9.0625 7.1001 7.8625 7.7001 6.7625 8.7001C4.5625 10.9001 4.4625 14.5001 6.4625 16.8001L10.5625 12.7001V7.0001H10.4625Z"
      fill="currentColor"
    />
    <path
      d="M10.9625 20C9.06252 20 7.36252 19.3 6.06252 18C5.96252 17.9 5.96252 17.8 5.96252 17.6C5.96252 17.4 6.06252 17.3 6.06252 17.2L10.6625 12.6C10.7625 12.5 10.8625 12.5 10.9625 12.5H17.4625C17.7625 12.5 17.9625 12.7 17.9625 13C17.9625 16.8 14.8625 20 10.9625 20ZM7.16252 17.6C8.26252 18.6 9.56252 19 11.0625 19C14.1625 19 16.7625 16.6 17.0625 13.5H11.1625L7.16252 17.6Z"
      fill="currentColor"
    />
    <path d="M13.0625 11V4.5C16.6625 4.5 19.5625 7.4 19.5625 11H13.0625Z" fill="currentColor" />
    <path
      d="M19.4625 11.5H13.0625C12.7625 11.5 12.5625 11.3 12.5625 11V4.5C12.5625 4.2 12.7625 4 13.0625 4C16.9625 4 20.0625 7.1 20.0625 11C19.9625 11.3 19.7625 11.5 19.4625 11.5ZM13.5625 10.5H18.9625C18.7625 7.6 16.4625 5.3 13.5625 5.1V10.5Z"
      fill="currentColor"
    />
  </svg>
);

export const ConfigureIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1884_345)">
      <path
        d="M8.01294 11.7C7.91294 11.7 7.81294 11.7 7.71294 11.7C6.71294 11.6 5.81294 11.2 5.21294 10.4C4.51294 9.59998 4.21294 8.69998 4.31294 7.69998C4.41294 6.69998 4.81294 5.79998 5.61294 5.19998C6.41294 4.59998 7.31294 4.19998 8.31294 4.29998C9.31294 4.39998 10.2129 4.79998 10.8129 5.59998C11.4129 6.39998 11.8129 7.29998 11.7129 8.29998C11.6129 9.29998 11.2129 10.2 10.4129 10.8C9.71294 11.4 8.91294 11.7 8.01294 11.7ZM8.01294 5.29998C7.31294 5.29998 6.71294 5.49998 6.21294 5.99998C5.61294 6.39998 5.31294 7.09998 5.21294 7.79998C5.11294 8.49998 5.41294 9.19998 5.91294 9.79998C6.41294 10.4 7.01294 10.7 7.81294 10.7C8.51294 10.8 9.21294 10.5 9.81294 9.99998C10.9129 8.99998 11.1129 7.29998 10.1129 6.19998C9.51294 5.69998 8.91294 5.29998 8.21294 5.29998C8.11294 5.29998 8.01294 5.29998 8.01294 5.29998Z"
        fill="currentColor"
      />
      <path
        d="M14.413 11.4999C14.313 11.4999 14.313 11.4999 14.213 11.4999L13.213 10.9999C12.913 10.8999 12.813 10.5999 13.013 10.2999C13.113 9.9999 13.413 9.8999 13.713 10.0999L14.713 10.5999C15.013 10.6999 15.113 10.9999 14.913 11.2999C14.813 11.3999 14.613 11.4999 14.413 11.4999Z"
        fill="currentColor"
      />
      <path
        d="M15.1129 8.59985H14.0129C13.7129 8.59985 13.5129 8.39985 13.5129 8.09985C13.5129 7.79985 13.7129 7.59985 14.0129 7.59985H15.1129C15.4129 7.59985 15.6129 7.79985 15.6129 8.09985C15.6129 8.39985 15.4129 8.59985 15.1129 8.59985Z"
        fill="currentColor"
      />
      <path
        d="M13.513 6.09985C13.313 6.09985 13.113 5.99985 13.013 5.79985C12.913 5.49985 13.013 5.29985 13.313 5.09985L14.313 4.69985C14.613 4.59985 14.813 4.69985 15.013 4.99985C15.113 5.29985 15.013 5.49985 14.713 5.69985L13.713 6.09985C13.613 6.09985 13.613 6.09985 13.513 6.09985Z"
        fill="currentColor"
      />
      <path
        d="M12.013 4C11.913 4 11.813 4 11.713 3.9C11.513 3.7 11.513 3.4 11.713 3.2L12.413 2.4C12.613 2.2 12.913 2.2 13.113 2.4C13.313 2.6 13.313 2.9 13.113 3.1L12.413 3.9C12.313 4 12.213 4 12.013 4Z"
        fill="currentColor"
      />
      <path
        d="M9.91299 2.79995C9.81299 2.79995 9.81299 2.79995 9.71299 2.79995C9.41299 2.69995 9.31299 2.39995 9.41299 2.09995L9.71299 1.09995C9.81299 0.799951 10.113 0.699951 10.313 0.799951C10.613 0.899951 10.713 1.19995 10.613 1.39995L10.313 2.39995C10.313 2.59995 10.113 2.79995 9.91299 2.79995Z"
        fill="currentColor"
      />
      <path
        d="M7.31301 2.4999C7.11301 2.4999 6.91301 2.2999 6.81301 1.9999L6.71301 0.899902C6.71301 0.599902 6.91301 0.399902 7.21301 0.399902C7.51301 0.399902 7.71301 0.599902 7.71301 0.899902L7.81301 1.9999C7.91301 2.1999 7.71301 2.3999 7.31301 2.4999C7.41301 2.4999 7.41301 2.4999 7.31301 2.4999Z"
        fill="currentColor"
      />
      <path
        d="M4.91299 3.1998C4.81299 3.1998 4.61299 3.0998 4.51299 2.9998L4.01299 1.9998C3.81299 1.7998 3.91299 1.4998 4.21299 1.2998C4.41299 1.1998 4.71299 1.1998 4.91299 1.4998L5.41299 2.4998C5.51299 2.6998 5.41299 2.9998 5.21299 3.1998C5.11299 3.1998 5.01299 3.1998 4.91299 3.1998Z"
        fill="currentColor"
      />
      <path
        d="M3.013 4.89979C2.913 4.89979 2.813 4.89979 2.713 4.79979L1.913 4.19979C1.713 3.99979 1.613 3.69979 1.813 3.49979C2.013 3.29979 2.313 3.19979 2.513 3.39979L3.313 3.99979C3.513 4.19979 3.613 4.49979 3.413 4.69979C3.413 4.79979 3.213 4.89979 3.013 4.89979Z"
        fill="currentColor"
      />
      <path
        d="M2.01296 7.19999H1.91296L0.812964 6.89999C0.612964 6.89999 0.412964 6.59999 0.412964 6.29999C0.512964 6.09999 0.812964 5.89999 1.01296 5.99999L2.11296 6.19999C2.41296 6.29999 2.51296 6.49999 2.51296 6.79999C2.41296 6.99999 2.21296 7.19999 2.01296 7.19999Z"
        fill="currentColor"
      />
      <path
        d="M2.11302 12.6998C1.91302 12.6998 1.81302 12.5998 1.71302 12.4998C1.51302 12.2998 1.61302 11.9998 1.81302 11.7998L2.71302 11.1998C2.91302 10.9998 3.21302 10.9998 3.41302 11.1998C3.61302 11.3998 3.51302 11.6998 3.31302 11.8998L2.41302 12.4998C2.31302 12.5998 2.21302 12.6998 2.11302 12.6998Z"
        fill="currentColor"
      />
      <path
        d="M4.31291 14.7C4.21291 14.7 4.11291 14.7 4.01291 14.6C3.81291 14.5 3.71291 14.2 3.81291 13.9L4.41291 13C4.51291 12.8 4.81291 12.7 5.11291 12.8C5.31291 12.9 5.41291 13.2 5.31291 13.5L4.71291 14.4C4.61291 14.6 4.51291 14.7 4.31291 14.7Z"
        fill="currentColor"
      />
      <path
        d="M7.11292 15.6C6.81292 15.6 6.61292 15.3 6.61292 15L6.71291 14C6.71291 13.7 7.01292 13.5 7.31292 13.6C7.61292 13.6 7.81291 13.9 7.71291 14.2L7.61292 15.3C7.61292 15.4 7.41292 15.6 7.11292 15.6Z"
        fill="currentColor"
      />
      <path
        d="M10.113 15.2999C9.91298 15.2999 9.71298 15.1999 9.61298 14.9999L9.31298 13.9999C9.21298 13.6999 9.41298 13.4999 9.61298 13.3999C9.91298 13.2999 10.113 13.4999 10.213 13.6999L10.513 14.6999C10.613 14.9999 10.413 15.1999 10.213 15.2999H10.113Z"
        fill="currentColor"
      />
      <path
        d="M12.613 13.9C12.513 13.9 12.313 13.8 12.213 13.7L9.71299 10.8C9.51299 10.6 9.51299 10.3 9.71299 10.1C9.91299 9.89995 10.213 9.89995 10.413 10.1L12.913 13C13.113 13.2 13.113 13.5 12.913 13.7C12.813 13.9 12.713 13.9 12.613 13.9Z"
        fill="currentColor"
      />
      <path
        d="M8.91295 5.39995C8.81295 5.39995 8.81295 5.39995 8.71295 5.39995C8.51295 5.29995 8.31295 4.99995 8.41295 4.79995L9.71295 1.09995C9.81295 0.799951 10.113 0.699951 10.313 0.799951C10.613 0.899951 10.713 1.19995 10.613 1.39995L9.41295 5.09995C9.31295 5.29995 9.11295 5.39995 8.91295 5.39995Z"
        fill="currentColor"
      />
      <path
        d="M0.912976 9.89999C0.712976 9.89999 0.512976 9.69999 0.412976 9.49999C0.312976 9.29999 0.512976 8.99999 0.812976 8.89999L4.71298 8.09999C5.01298 7.99999 5.21298 8.19999 5.31298 8.49999C5.41298 8.79999 5.21298 8.99999 4.91298 9.09999L0.912976 9.89999Z"
        fill="currentColor"
      />
      <path
        d="M2.11303 9.59986C1.91303 9.59986 1.71303 9.39986 1.61303 9.19986C1.21303 6.99986 2.01303 4.69986 3.71303 3.19986C5.41303 1.69986 7.71303 1.29986 9.91303 1.99986C10.213 2.09986 10.313 2.39986 10.213 2.59986C10.113 2.79986 9.91303 2.99986 9.61303 2.89986C7.81303 2.29986 5.81303 2.69986 4.41303 3.89986C2.91303 5.19986 2.31303 7.09986 2.61303 8.99986C2.71303 9.29986 2.51303 9.59986 2.11303 9.59986Z"
        fill="currentColor"
      />
      <path
        d="M13.013 11.4998C12.913 11.4998 12.813 11.4998 12.713 11.3998C12.513 11.2998 12.413 10.9998 12.513 10.6998C13.713 8.69984 13.513 6.19984 11.913 4.39984C11.613 4.09984 11.313 3.79984 10.913 3.49984C10.713 3.29984 10.613 2.99984 10.813 2.79984C11.013 2.59984 11.313 2.49984 11.513 2.69984C11.913 2.99984 12.313 3.29984 12.613 3.69984C14.413 5.79984 14.713 8.79984 13.313 11.1998C13.313 11.4998 13.213 11.4998 13.013 11.4998Z"
        fill="currentColor"
      />
      <path
        d="M7.91294 14.3998C7.71294 14.3998 7.61294 14.3998 7.41294 14.3998C5.71294 14.2998 4.11294 13.4998 3.01294 12.1998C2.81294 11.9998 2.61294 11.6998 2.51294 11.4998C2.41294 11.3998 2.31294 11.1998 2.21294 11.0998C2.11294 10.8998 2.21294 10.5998 2.41294 10.3998C2.61294 10.2998 2.91294 10.3998 3.11294 10.5998C3.21294 10.6998 3.21294 10.7998 3.31294 10.9998C3.41294 11.1998 3.61294 11.3998 3.71294 11.5998C4.61294 12.6998 6.01294 13.3998 7.41294 13.4998C8.81294 13.5998 10.2129 13.0998 11.3129 12.1998C11.5129 11.9998 11.8129 11.9998 12.0129 12.2998C12.2129 12.5998 12.2129 12.7998 11.9129 12.9998C10.9129 13.8998 9.51294 14.3998 7.91294 14.3998Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1884_345">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ProjectsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    height="1em"
    width="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.65 17.65H7.15002C6.35002 17.65 5.65002 16.95 5.65002 16.15V5.44995C5.65002 4.64995 6.35002 3.94995 7.15002 3.94995H17.65C18.45 3.94995 19.15 4.64995 19.15 5.44995V16.15C19.05 16.95 18.45 17.65 17.65 17.65ZM7.15002 5.04995C6.95002 5.04995 6.75002 5.24995 6.75002 5.44995V16.15C6.75002 16.45 6.95002 16.65 7.25002 16.65H17.75C18.05 16.65 18.25 16.45 18.25 16.15V5.44995C18.25 5.14995 18.05 4.94995 17.75 4.94995H7.15002V5.04995Z"
      fill="currentColor"
    />
    <path
      d="M15.25 17.35C14.95 17.35 14.75 17.15 14.75 16.85V4.75C14.75 4.45 14.95 4.25 15.25 4.25C15.55 4.25 15.75 4.45 15.75 4.75V16.85C15.75 17.15 15.55 17.35 15.25 17.35Z"
      fill="currentColor"
    />
    <path
      d="M8.04993 17.3499V19.5499L9.54993 18.9499L11.1499 19.5499V17.3499"
      fill="currentColor"
    />
    <path
      d="M8.04993 20.05C7.94993 20.05 7.84993 20.05 7.74993 19.95C7.64993 19.85 7.54993 19.75 7.54993 19.55V17.45C7.54993 17.15 7.74993 16.95 8.04993 16.95C8.34993 16.95 8.54993 17.15 8.54993 17.45V18.85L9.44993 18.55C9.54993 18.55 9.64993 18.55 9.74993 18.55L10.6499 18.85V17.45C10.6499 17.15 10.8499 16.95 11.1499 16.95C11.4499 16.95 11.6499 17.15 11.6499 17.45V19.55C11.6499 19.75 11.5499 19.85 11.4499 19.95C11.2499 20.05 11.1499 20.05 10.9499 19.95L9.54993 19.45L8.14993 19.95L8.04993 20.05Z"
      fill="currentColor"
    />
    <path
      d="M5.84998 6.55005H5.34998C5.04998 6.55005 4.84998 6.35005 4.84998 6.05005C4.84998 5.75005 5.04998 5.55005 5.34998 5.55005H5.84998C6.14998 5.55005 6.34998 5.75005 6.34998 6.05005C6.34998 6.35005 6.14998 6.55005 5.84998 6.55005Z"
      fill="currentColor"
    />
    <path
      d="M5.84998 8.94995H5.34998C5.14998 8.94995 4.84998 8.74995 4.84998 8.44995C4.84998 8.14995 5.14998 7.94995 5.34998 7.94995H5.84998C6.14998 7.94995 6.34998 8.14995 6.34998 8.44995C6.34998 8.74995 6.14998 8.94995 5.84998 8.94995Z"
      fill="currentColor"
    />
    <path
      d="M5.84998 11.3501H5.34998C5.04998 11.3501 4.84998 11.1501 4.84998 10.8501C4.84998 10.5501 5.04998 10.3501 5.34998 10.3501H5.84998C6.14998 10.3501 6.34998 10.5501 6.34998 10.8501C6.34998 11.1501 6.14998 11.3501 5.84998 11.3501Z"
      fill="currentColor"
    />
    <path
      d="M5.84998 13.75H5.34998C5.04998 13.75 4.84998 13.55 4.84998 13.25C4.84998 12.95 5.04998 12.75 5.34998 12.75H5.84998C6.14998 12.75 6.34998 12.95 6.34998 13.25C6.34998 13.55 6.14998 13.75 5.84998 13.75Z"
      fill="currentColor"
    />
    <path
      d="M5.84998 16.1501H5.34998C5.04998 16.1501 4.84998 15.9501 4.84998 15.6501C4.84998 15.3501 5.04998 15.1501 5.34998 15.1501H5.84998C6.14998 15.1501 6.34998 15.3501 6.34998 15.6501C6.34998 15.9501 6.14998 16.1501 5.84998 16.1501Z"
      fill="currentColor"
    />
  </svg>
);

export const CollapseSidebarIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.2 9.8999L13.1 11.9999L15.2 14.0999V9.8999Z" fill="currentColor" />
    <path
      d="M15.2 14.5999C15.1 14.5999 14.9 14.4999 14.8 14.4999L12.8 12.3999C12.6 12.1999 12.6 11.8999 12.8 11.6999L14.9 9.59992C15 9.49992 15.3 9.39992 15.4 9.49992C15.6 9.59992 15.7 9.79992 15.7 9.99992V14.1999C15.7 14.3999 15.6 14.5999 15.4 14.6999C15.4 14.5999 15.3 14.5999 15.2 14.5999ZM13.8 11.9999L14.7 12.8999V11.0999L13.8 11.9999Z"
      fill="currentColor"
    />
    <path
      d="M18.2 20H5.8C4.8 20 4 19.2 4 18.2V5.8C4 4.8 4.8 4 5.8 4H18.2C19.2 4 20 4.8 20 5.8V18.2C20 19.2 19.2 20 18.2 20ZM5.8 5C5.4 5 5 5.4 5 5.8V18.2C5 18.6 5.4 19 5.8 19H18.2C18.6 19 19 18.6 19 18.2V5.8C19 5.4 18.6 5 18.2 5H5.8Z"
      fill="currentColor"
    />
    <path
      d="M5.69999 4.5C4.89999 4.5 4.29999 5.1 4.29999 5.8V18.2C4.29999 18.9 4.89999 19.5 5.69999 19.5H10.5V4.5H5.69999ZM7.69999 11.4H6.39999C6.09999 11.4 5.89999 11.2 5.89999 10.9C5.89999 10.6 6.09999 10.4 6.39999 10.4H7.69999C7.99999 10.4 8.19999 10.6 8.19999 10.9C8.19999 11.2 7.99999 11.4 7.69999 11.4ZM7.69999 9.3H6.39999C6.09999 9.3 5.89999 9.1 5.89999 8.8C5.89999 8.5 6.09999 8.3 6.39999 8.3H7.69999C7.99999 8.3 8.19999 8.5 8.19999 8.8C8.19999 9.1 7.99999 9.3 7.69999 9.3ZM7.69999 7.3H6.39999C6.09999 7.3 5.89999 7.1 5.89999 6.8C5.89999 6.5 6.09999 6.3 6.39999 6.3H7.69999C7.99999 6.3 8.19999 6.5 8.19999 6.8C8.19999 7.1 7.99999 7.3 7.69999 7.3Z"
      fill="currentColor"
    />
  </svg>
);

export const ExpandSidebarIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1199_5567)">
      <path d="M10.2 14.9593L12.3 12.8593L10.2 10.7593V14.9593Z" fill="currentColor" />
      <path
        d="M10.2 15.4592C10.1 15.4592 10.1 15.4592 10 15.4592C9.80001 15.3592 9.70001 15.1592 9.70001 14.9592V10.7592C9.70001 10.5592 9.80001 10.3592 10 10.2592C10.2 10.1592 10.4 10.2592 10.5 10.3592L12.6 12.4592C12.8 12.6592 12.8 12.9592 12.6 13.1592L10.5 15.2592C10.5 15.4592 10.4 15.4592 10.2 15.4592ZM10.7 11.9592V13.7592L11.6 12.8592L10.7 11.9592Z"
        fill="currentColor"
      />
      <path
        d="M18.7 20.8591H6.3C5.3 20.8591 4.5 20.0591 4.5 19.0591V6.65913C4.5 5.65913 5.3 4.85913 6.3 4.85913H18.7C19.7 4.85913 20.5 5.65913 20.5 6.65913V19.0591C20.5 20.0591 19.7 20.8591 18.7 20.8591ZM6.3 5.85913C5.9 5.85913 5.5 6.25913 5.5 6.65913V19.0591C5.5 19.4591 5.9 19.8591 6.3 19.8591H18.7C19.1 19.8591 19.5 19.4591 19.5 19.0591V6.65913C19.5 6.25913 19.1 5.85913 18.7 5.85913H6.3Z"
        fill="currentColor"
      />
      <path
        d="M6.8 5.35913H6.3C5.6 5.35913 5 5.95913 5 6.65913V19.0591C5 19.7591 5.6 20.3591 6.3 20.3591H6.8V5.35913Z"
        fill="currentColor"
      />
      <path
        d="M6.8 20.8591H6.3C5.3 20.8591 4.5 20.0591 4.5 19.0591V6.65913C4.5 5.65913 5.3 4.85913 6.3 4.85913H6.8C7.1 4.85913 7.3 5.05913 7.3 5.35913V20.3591C7.3 20.6591 7.1 20.8591 6.8 20.8591ZM6.3 5.85913C5.9 5.85913 5.5 6.25913 5.5 6.65913V19.0591C5.5 19.4591 5.9 19.8591 6.3 19.8591V5.85913Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1199_5567">
        <rect width="16" height="16" fill="white" transform="translate(4.5 4.85913)" />
      </clipPath>
    </defs>
  </svg>
);

export const ShowIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5326 12.5125C19.4326 12.5125 19.2326 12.5125 19.1326 12.4125C15.2326 8.51245 8.73256 8.51245 4.83256 12.4125C4.63256 12.6125 4.33256 12.6125 4.13256 12.4125C3.93256 12.2125 4.03256 11.8125 4.13256 11.6125C8.43256 7.31245 15.5326 7.31245 19.8326 11.6125C20.0326 11.8125 20.0326 12.1125 19.8326 12.3125C19.8326 12.4125 19.6326 12.5125 19.5326 12.5125Z"
      fill="currentColor"
    />
    <path
      d="M12.0326 15.6124C10.0326 15.6124 8.43262 14.0124 8.43262 12.0124C8.43262 10.0124 10.0326 8.41235 12.0326 8.41235C14.0326 8.41235 15.6326 10.0124 15.6326 12.0124C15.6326 14.0124 14.0326 15.6124 12.0326 15.6124ZM12.0326 9.41235C10.6326 9.41235 9.43262 10.6124 9.43262 12.0124C9.43262 13.4124 10.6326 14.6124 12.0326 14.6124C13.4326 14.6124 14.6326 13.4124 14.6326 12.0124C14.6326 10.6124 13.4326 9.41235 12.0326 9.41235Z"
      fill="currentColor"
    />
  </svg>
);

export const HubSpotChatIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2273_16918)">
      <path
        d="M16.8 20.0002C16.7 20.0002 16.7 20.0002 16.6 20.0002L10.4 17.3002H6.5C5.2 17.3002 4.2 16.3002 4.2 15.0002V6.5002C4.2 5.2002 5.2 4.2002 6.5 4.2002H17.4C18.7 4.2002 19.7 5.2002 19.7 6.5002V14.9002C19.7 16.2002 18.7 17.2002 17.4 17.2002H17.2V19.4002C17.2 19.6002 17.1 19.7002 17 19.8002C17 19.9002 16.9 20.0002 16.8 20.0002ZM6.5 5.2002C5.8 5.2002 5.2 5.8002 5.2 6.5002V14.9002C5.2 15.6002 5.8 16.2002 6.5 16.2002H10.5C10.6 16.2002 10.6 16.2002 10.7 16.2002L16.3 18.6002V16.6002C16.3 16.3002 16.5 16.1002 16.8 16.1002H17.5C18.2 16.1002 18.8 15.5002 18.8 14.8002V6.5002C18.8 5.8002 18.2 5.2002 17.5 5.2002H6.5Z"
        fill="currentColor"
      />
      <path
        d="M12.1 13.3004C11.8 13.3004 11.6 13.1004 11.6 12.8004C11.5 11.4004 12.4 10.7004 12.4 10.6004C13.5 9.60039 13.5 9.00039 13.5 9.00039C13.5 8.40039 13.4 8.00039 13.2 7.70039C12.8 7.40039 12.3 7.40039 12.3 7.40039C11.8 7.40039 11.4 7.50039 11.2 7.70039C10.7 8.10039 10.7 8.80039 10.7 8.80039C10.7 9.10039 10.5 9.30039 10.2 9.30039C10 9.30039 9.7 9.10039 9.7 8.80039C9.7 8.80039 9.7 7.70039 10.4 7.00039C10.8 6.50039 11.5 6.40039 12.2 6.40039C12.2 6.40039 13.2 6.40039 13.8 7.00039C14.3 7.50039 14.5 8.20039 14.4 9.00039C14.4 9.10039 14.4 10.0004 12.9 11.4004C13 11.4004 12.5 11.9004 12.6 12.7004C12.6 13.0004 12.4 13.2004 12.1 13.3004Z"
        fill="currentColor"
      />
      <path
        d="M12.7 14.6C12.7 14.9 12.4 15.2 12.1 15.2C11.8 15.2 11.5 14.9 11.5 14.6C11.5 14.3 11.8 14 12.1 14C12.4 14 12.7 14.3 12.7 14.6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2273_16918">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export const SpinnerIcon = (props: IconProps) => (
  <svg
    className={`animate-spin ${props.className ?? ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM5.6 12C5.6 15.5346 8.46538 18.4 12 18.4C15.5346 18.4 18.4 15.5346 18.4 12C18.4 8.46538 15.5346 5.6 12 5.6C8.46538 5.6 5.6 8.46538 5.6 12Z"
      fill="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.60896 8.93853C4.20693 9.90914 4 10.9494 4 12H5.6C5.6 11.1595 5.76554 10.3273 6.08717 9.55083C6.4088 8.77434 6.88022 8.06881 7.47452 7.47452C8.06881 6.88022 8.77434 6.4088 9.55083 6.08717C10.3273 5.76554 11.1595 5.6 12 5.6C12.8405 5.6 13.6727 5.76554 14.4492 6.08717C15.2257 6.4088 15.9312 6.88022 16.5255 7.47452C17.1198 8.06881 17.5912 8.77434 17.9128 9.55083C18.1322 10.0803 18.2789 10.6358 18.3501 11.2021C18.4052 11.6405 18.7582 12 19.2 12C19.6418 12 20.0041 11.6409 19.96 11.2013C19.8821 10.4247 19.6908 9.66226 19.391 8.93853C18.989 7.96793 18.3997 7.08601 17.6569 6.34315C16.914 5.60028 16.0321 5.011 15.0615 4.60896C14.0909 4.20693 13.0506 4 12 4C10.9494 4 9.90914 4.20693 8.93853 4.60896C7.96793 5.011 7.08602 5.60028 6.34315 6.34315C5.60028 7.08601 5.011 7.96793 4.60896 8.93853Z"
      fill="url(#paint0_linear_811_23472)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_811_23472"
        x1="12"
        y1="4"
        x2="6.5"
        y2="12.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.22999" stopColor="currentColor" />
        <stop offset="0.78499" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const EditProfileIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.15 19.5503H6.64999C5.54999 19.5503 4.64999 18.6503 4.64999 17.5503V7.05029C4.64999 5.95029 5.54999 5.05029 6.64999 5.05029H15.95C16.25 5.05029 16.45 5.25029 16.45 5.55029C16.45 5.85029 16.25 6.05029 15.95 6.05029H6.64999C6.14999 6.05029 5.64999 6.45029 5.64999 7.05029V17.5503C5.64999 18.0503 6.04999 18.5503 6.64999 18.5503H17.15C17.65 18.5503 18.15 18.1503 18.15 17.5503V11.3503C18.15 11.0503 18.35 10.8503 18.65 10.8503C18.95 10.8503 19.15 11.0503 19.15 11.3503V17.5503C19.15 18.6503 18.25 19.5503 17.15 19.5503Z"
      fill="currentColor"
    />
    <path
      d="M8.24998 16.0502C8.14998 16.0502 8.04998 16.0502 7.94998 15.9502C7.84998 15.8502 7.74998 15.6502 7.84998 15.5502L8.54998 12.7502C8.54998 12.6502 8.64998 12.5502 8.64998 12.5502L16.25 4.9502C16.55 4.6502 16.85 4.5502 17.15 4.4502C17.55 4.4502 17.85 4.5502 18.15 4.7502L19.05 5.6502C19.25 5.8502 19.35 6.1502 19.35 6.4502C19.35 6.8502 19.15 7.2502 18.85 7.5502L11.25 15.1502C11.15 15.2502 11.15 15.2502 11.05 15.2502L8.24998 15.9502C8.34998 15.9502 8.24998 16.0502 8.24998 16.0502ZM9.34998 12.9502L8.84998 14.9502L10.75 14.4502L18.25 6.9502C18.35 6.8502 18.45 6.6502 18.45 6.5502C18.45 6.4502 18.45 6.4502 18.35 6.3502L17.45 5.4502C17.35 5.3502 17.25 5.3502 17.25 5.3502C17.15 5.3502 16.95 5.4502 16.85 5.5502L9.34998 12.9502Z"
      fill="currentColor"
    />
  </svg>
);

export const WhatsNewIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9 19.4496H7.09999C6.19999 19.4496 5.39999 18.6496 5.39999 17.7496V13.3496C5.39999 13.0496 5.59999 12.8496 5.89999 12.8496C6.19999 12.8496 6.39999 13.0496 6.39999 13.3496V17.7496C6.39999 18.1496 6.69999 18.4496 7.09999 18.4496H16.9C17.3 18.4496 17.6 18.1496 17.6 17.7496V13.3496C17.6 13.0496 17.8 12.8496 18.1 12.8496C18.4 12.8496 18.6 13.0496 18.6 13.3496V17.7496C18.7 18.6496 17.9 19.4496 16.9 19.4496Z"
      fill="currentColor"
    />
    <path
      d="M18.9 11.3497H5.10001C4.80001 11.3497 4.60001 11.0497 4.60001 10.8497V8.44971C4.60001 8.14971 4.80001 7.94971 5.10001 7.94971H18.9C19.2 7.94971 19.4 8.14971 19.4 8.44971V10.8497C19.4 11.0497 19.2 11.3497 18.9 11.3497ZM5.60001 10.3497H18.4V8.94971H5.60001V10.3497Z"
      fill="currentColor"
    />
    <path
      d="M12 18.7498C11.7 18.7498 11.5 18.5498 11.5 18.2498V8.5498C11.5 8.2498 11.7 8.0498 12 8.0498C12.3 8.0498 12.5 8.2498 12.5 8.5498V18.2498C12.5 18.5498 12.3 18.7498 12 18.7498Z"
      fill="currentColor"
    />
    <path
      d="M14.4 8.3498H12C11.7 8.3498 11.5 8.1498 11.5 7.8498V6.4498C11.5 5.3498 12.4 4.5498 13.4 4.5498H14.3C15.4 4.5498 16.2 5.4498 16.2 6.4498C16.3 7.5498 15.4 8.3498 14.4 8.3498ZM12.5 7.3498H14.4C14.9 7.3498 15.3 6.9498 15.3 6.4498C15.3 5.9498 14.9 5.5498 14.4 5.5498H13.4C12.9 5.5498 12.5 5.9498 12.5 6.4498V7.3498Z"
      fill="currentColor"
    />
    <path
      d="M12 8.3498H9.60001C8.50001 8.3498 7.70001 7.4498 7.70001 6.4498C7.70001 5.3498 8.60001 4.5498 9.60001 4.5498H10.5C11.6 4.5498 12.4 5.4498 12.4 6.4498V7.8498C12.5 8.1498 12.3 8.3498 12 8.3498ZM9.60001 5.5498C9.10001 5.5498 8.70001 5.9498 8.70001 6.4498C8.70001 6.9498 9.10001 7.3498 9.60001 7.3498H11.5V6.4498C11.5 5.9498 11.1 5.5498 10.6 5.5498H9.60001Z"
      fill="currentColor"
    />
  </svg>
);

export const HelpCenterIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 13.6998C11.7 13.6998 11.5 13.4998 11.5 13.1998C11.4 11.7998 12.2 11.0998 12.3 10.9998C13.4 9.9998 13.4 9.3998 13.4 9.3998C13.4 8.7998 13.3 8.3998 13.1 8.0998C12.7 7.7998 12.1 7.7998 12.1 7.7998C11.6 7.6998 11.2 7.8998 11 8.0998C10.6 8.4998 10.6 9.1998 10.6 9.1998C10.6 9.4998 10.4 9.6998 10.1 9.6998C9.80001 9.6998 9.60001 9.4998 9.60001 9.1998C9.60001 9.1998 9.60001 8.0998 10.3 7.3998C10.7 6.9998 11.4 6.7998 12.1 6.7998C12.1 6.7998 13.1 6.7998 13.7 7.3998C14.2 7.8998 14.4 8.5998 14.3 9.3998C14.3 9.4998 14.3 10.3998 12.8 11.7998C12.7 11.7998 12.3 12.2998 12.3 13.0998C12.5 13.3998 12.3 13.5998 12 13.6998Z"
      fill="currentColor"
    />
    <path
      d="M12.6 14.9999C12.6 15.2999 12.3 15.5999 12 15.5999C11.7 15.5999 11.4 15.2999 11.4 14.9999C11.4 14.6999 11.7 14.3999 12 14.3999C12.3 14.3999 12.6 14.6999 12.6 14.9999Z"
      fill="currentColor"
    />
    <path
      d="M11 19.6998C10.9 19.6998 10.7 19.6998 10.7 19.5998C10.6 19.4998 10.5 19.3998 10.5 19.1998V17.6998C7.40001 16.9998 5.20001 14.2998 5.20001 11.0998C5.20001 7.3998 8.20001 4.2998 12 4.2998C15.8 4.2998 18.8 7.2998 18.8 11.0998C18.8 13.5998 17.5 15.7998 15.4 16.9998C14.8 18.1998 13.6 19.5998 11 19.6998ZM12 5.2998C8.80001 5.2998 6.20001 7.8998 6.20001 11.0998C6.20001 13.9998 8.20001 16.2998 11.1 16.7998C11.3 16.7998 11.5 16.9998 11.5 17.2998V18.6998C13 18.4998 14 17.7998 14.5 16.4998C14.5 16.3998 14.6 16.2998 14.7 16.1998C16.6 15.1998 17.8 13.1998 17.8 11.0998C17.8 7.8998 15.2 5.2998 12 5.2998Z"
      fill="currentColor"
    />
  </svg>
);

export const ContactSupportIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6452 11.3001C17.4452 11.3001 17.1452 11.1001 17.1452 10.9001C16.7452 8.2001 14.5452 6.1001 12.0452 6.1001C9.54523 6.1001 7.34523 8.2001 6.94523 10.9001C6.94523 11.2001 6.64523 11.4001 6.34523 11.3001C6.04523 11.3001 5.84523 11.0001 5.94523 10.7001C6.44523 7.5001 9.04523 5.1001 12.0452 5.1001C15.0452 5.1001 17.6452 7.5001 18.1452 10.8001C18.2452 11.0001 18.0452 11.3001 17.6452 11.3001Z"
      fill="currentColor"
    />
    <path
      d="M17.6453 17.2002C16.7453 17.2002 16.0453 16.5002 16.0453 15.7002V12.2002C16.0453 11.4002 16.7453 10.7002 17.5453 10.7002C18.4453 10.7002 19.1453 11.4002 19.1453 12.2002V15.6002C19.2453 16.5002 18.5453 17.2002 17.6453 17.2002ZM17.6453 11.7002C17.3453 11.7002 17.0453 11.9002 17.0453 12.2002V15.6002C17.0453 15.9002 17.2453 16.1002 17.5453 16.1002C17.8453 16.1002 18.0453 15.9002 18.0453 15.6002V12.2002C18.2453 11.9002 17.9453 11.7002 17.6453 11.7002Z"
      fill="currentColor"
    />
    <path
      d="M6.44525 17.2002C5.54525 17.2002 4.84525 16.5002 4.84525 15.7002V12.2002C4.84525 11.4002 5.54525 10.7002 6.34525 10.7002C7.34525 10.7002 8.04525 11.3002 8.04525 12.2002V15.6002C8.04525 16.5002 7.34525 17.2002 6.44525 17.2002ZM6.44525 11.7002C6.14525 11.7002 5.84525 11.9002 5.84525 12.2002V15.6002C5.84525 15.9002 6.04525 16.1002 6.34525 16.1002C6.64525 16.1002 6.84525 15.9002 6.84525 15.6002V12.2002C7.04525 11.9002 6.74525 11.7002 6.44525 11.7002Z"
      fill="currentColor"
    />
    <path
      d="M12.7452 18.9003C12.6452 18.9003 12.4452 18.9003 12.3452 18.9003C12.0452 18.9003 11.8452 18.7003 11.8452 18.4003C11.8452 18.1003 12.1452 17.9003 12.3452 17.9003C16.0452 18.0003 17.2452 16.4003 17.2452 16.3003C17.4452 16.1003 17.7452 16.0003 17.9452 16.2003C18.1452 16.4003 18.2452 16.7003 18.0452 16.9003C18.0452 17.0003 16.6452 18.9003 12.7452 18.9003Z"
      fill="currentColor"
    />
  </svg>
);

export const KBShortcutsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.85 17.4001H6.05001C5.15001 17.4001 4.35001 16.6001 4.35001 15.7001V8.3001C4.35001 7.4001 5.15001 6.6001 6.05001 6.6001H17.95C18.85 6.6001 19.65 7.4001 19.65 8.3001V15.6001C19.55 16.6001 18.85 17.4001 17.85 17.4001ZM6.05001 7.6001C5.65001 7.6001 5.35001 7.9001 5.35001 8.3001V15.6001C5.35001 16.0001 5.65001 16.3001 6.05001 16.3001H17.95C18.35 16.3001 18.65 16.0001 18.65 15.6001V8.3001C18.65 7.9001 18.35 7.6001 17.95 7.6001H6.05001Z"
      fill="currentColor"
    />
    <path
      d="M15.05 14.7002H8.85001C8.55001 14.7002 8.35001 14.5002 8.35001 14.2002C8.35001 13.9002 8.55001 13.7002 8.85001 13.7002H15.05C15.35 13.7002 15.55 13.9002 15.55 14.2002C15.55 14.5002 15.35 14.7002 15.05 14.7002Z"
      fill="currentColor"
    />
    <path
      d="M17.05 10.1001H6.75C6.45 10.1001 6.25 9.9001 6.25 9.6001C6.25 9.3001 6.45 9.1001 6.75 9.1001H17.05C17.35 9.1001 17.55 9.3001 17.55 9.6001C17.55 9.9001 17.35 10.1001 17.05 10.1001Z"
      fill="currentColor"
    />
    <path
      d="M17.05 12H6.75C6.45 12 6.25 11.8 6.25 11.5C6.25 11.2 6.45 11 6.75 11H17.05C17.35 11 17.55 11.2 17.55 11.5C17.55 11.8 17.35 12 17.05 12Z"
      fill="currentColor"
    />
  </svg>
);

export const LogoutIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.525 12.4502H9.125C8.825 12.4502 8.625 12.1502 8.625 11.9502C8.625 11.7502 8.825 11.4502 9.125 11.4502H17.525C17.825 11.4502 18.025 11.6502 18.025 11.9502C18.025 12.2502 17.825 12.4502 17.525 12.4502Z"
      fill="currentColor"
    />
    <path
      d="M16.725 14.2503L19.325 12.2503C19.525 12.0503 19.525 11.7503 19.325 11.6503L16.725 9.65033C16.425 9.45033 16.125 9.65033 16.125 9.95033V13.8503C16.025 14.2503 16.425 14.4503 16.725 14.2503Z"
      fill="currentColor"
    />
    <path
      d="M12.825 19.6501H6.32499C5.32499 19.6501 4.52499 18.8501 4.52499 17.8501V6.1501C4.52499 5.1501 5.32499 4.3501 6.32499 4.3501H12.725C13.725 4.3501 14.525 5.1501 14.525 6.1501V11.8501C14.525 12.1501 14.325 12.3501 14.025 12.3501C13.725 12.3501 13.625 12.1501 13.625 11.8501V6.1501C13.625 5.7501 13.225 5.3501 12.825 5.3501H6.32499C5.92499 5.3501 5.52499 5.6501 5.52499 6.1501V17.8501C5.52499 18.2501 5.92499 18.6501 6.32499 18.6501H12.725C13.125 18.6501 13.525 18.2501 13.525 17.8501V15.7501C13.525 15.4501 13.725 15.2501 14.025 15.2501C14.325 15.2501 14.525 15.4501 14.525 15.7501V17.8501C14.625 18.7501 13.825 19.6501 12.825 19.6501Z"
      fill="currentColor"
    />
  </svg>
);

export const CostModeAllocatedIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.61538 12.7387C9.24615 12.7387 9 12.4925 9 12.1233V7.81558C9 7.44635 9.24615 7.2002 9.61538 7.2002C9.98462 7.2002 10.2308 7.44635 10.2308 7.81558V12.1233C10.2308 12.4925 9.98462 12.7387 9.61538 12.7387Z"
      fill="currentColor"
    />
    <path
      d="M11.7077 10.6461H7.5231C7.15387 10.6461 6.90771 10.3999 6.90771 10.0307C6.90771 9.66144 7.15387 9.41528 7.5231 9.41528H11.8308C12.2 9.41528 12.4462 9.66144 12.4462 10.0307C12.4462 10.3999 12.0769 10.6461 11.7077 10.6461Z"
      fill="currentColor"
    />
    <path
      d="M18.4769 20H7.76924C6.53847 20 5.43078 19.2615 4.93847 18.1538L2.72309 13.3538C2.35386 12.4923 2.35386 11.3846 2.72309 10.5231L4.93847 5.84615C5.43078 4.73846 6.53847 4 7.76924 4H18.4769C20.2 4 21.5539 5.47692 21.5539 7.32308V16.8C21.5539 18.5231 20.2 20 18.4769 20ZM7.76924 5.23077C7.03078 5.23077 6.4154 5.6 6.16924 6.33846L3.83078 11.0154C3.58463 11.6308 3.58463 12.2462 3.83078 12.8615L6.04617 17.6615C6.4154 18.4 7.03078 18.7692 7.64617 18.7692H18.3539C19.3385 18.7692 20.2 17.9077 20.2 16.8V7.2C20.2 6.09231 19.3385 5.23077 18.3539 5.23077H7.76924Z"
      fill="currentColor"
    />
    <path
      d="M9.73854 16.6767C9.61546 16.6767 9.36931 16.5537 9.36931 16.5537C9.12315 16.3075 9.12315 15.9383 9.36931 15.6921L15.1539 9.90752C15.4001 9.66136 15.7693 9.66136 16.0155 9.90752C16.2616 10.1537 16.2616 10.5229 16.0155 10.7691L10.2308 16.5537C10.1078 16.5537 9.98469 16.6767 9.73854 16.6767Z"
      fill="currentColor"
    />
    <path
      d="M17.6152 11.877L18.1076 8.18469C18.1076 7.93854 17.8614 7.69238 17.6152 7.69238L14.046 8.30777C13.5537 8.30777 13.4306 8.80008 13.6768 9.04623L16.7537 12.1232C17.1229 12.3693 17.4922 12.2462 17.6152 11.877Z"
      fill="currentColor"
    />
  </svg>
);

export const CostModeSeparatedIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0462 14.9537H9.98465C9.61542 14.9537 9.36926 14.7075 9.36926 14.3383C9.36926 13.9691 9.61542 13.7229 9.98465 13.7229H14.0462C14.4154 13.7229 14.6616 13.9691 14.6616 14.3383C14.6616 14.7075 14.4154 14.9537 14.0462 14.9537Z"
      fill="currentColor"
    />
    <path
      d="M14.2922 16.9231L17.246 14.7077C17.4922 14.5846 17.4922 14.2154 17.246 13.9692L14.2922 11.8769C14.046 11.6308 13.5537 11.8769 13.5537 12.2461V16.6769C13.5537 16.9231 13.9229 17.1692 14.2922 16.9231Z"
      fill="currentColor"
    />
    <path
      d="M15.7691 10.2772H11.7075C11.3383 10.2772 11.0922 10.031 11.0922 9.66177C11.0922 9.29254 11.3383 9.04639 11.7075 9.04639H15.7691C16.1383 9.04639 16.3845 9.29254 16.3845 9.66177C16.3845 10.031 16.1383 10.2772 15.7691 10.2772Z"
      fill="currentColor"
    />
    <path
      d="M11.4615 7.07687L8.50761 9.29225C8.26146 9.53841 8.26146 9.78456 8.50761 10.0307L11.4615 12.2461C11.7076 12.4923 12.1999 12.2461 12.1999 11.8769V7.4461C12.1999 7.07687 11.7076 6.83071 11.4615 7.07687Z"
      fill="currentColor"
    />
    <path
      d="M18.4769 20H7.76924C6.53847 20 5.43078 19.2615 4.93847 18.1538L2.72309 13.3538C2.35386 12.4923 2.35386 11.3846 2.72309 10.5231L4.93847 5.84615C5.43078 4.73846 6.53847 4 7.76924 4H18.4769C20.2 4 21.5539 5.47692 21.5539 7.32308V16.8C21.5539 18.5231 20.2 20 18.4769 20ZM7.76924 5.23077C7.03078 5.23077 6.4154 5.6 6.16924 6.33846L3.83078 11.0154C3.58463 11.6308 3.58463 12.2462 3.83078 12.8615L6.04617 17.6615C6.4154 18.4 7.03078 18.7692 7.64617 18.7692H18.3539C19.3385 18.7692 20.2 17.9077 20.2 16.8V7.2C20.2 6.09231 19.3385 5.23077 18.3539 5.23077H7.76924Z"
      fill="currentColor"
    />
  </svg>
);

export const CostModeNoMarkupsIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3232 15.6922C11.5847 15.6922 10.3539 15.323 10.1078 13.8461C10.1078 13.4769 10.3539 13.2307 10.6001 13.1076C10.9693 13.1076 11.2155 13.2307 11.3386 13.5999C11.5847 14.4615 12.3232 14.4615 12.4462 14.4615C13.1847 14.4615 13.5539 14.0922 13.5539 13.723C13.677 13.4769 13.5539 13.2307 13.5539 13.2307C13.3078 12.8615 12.4463 12.4922 11.9539 12.3692C10.477 12.123 10.2309 11.0153 10.2309 10.523C10.2309 9.04609 11.5847 8.43071 12.2001 8.43071C13.9232 8.30763 14.5386 9.5384 14.5386 10.1538C14.5386 10.523 14.2924 10.7692 14.0463 10.8922C13.8001 10.7692 13.4309 10.523 13.4309 10.2769C13.4309 10.0307 13.3078 9.66147 12.3232 9.66147C12.2001 9.66147 11.4616 9.78455 11.4616 10.523C11.4616 10.7692 11.4616 11.0153 12.2001 11.2615C12.5693 11.3846 14.0462 11.7538 14.5386 12.7384C14.5386 12.7384 14.9078 13.3538 14.7847 14.0922C14.5386 14.8307 13.8001 15.6922 12.3232 15.6922C12.4462 15.6922 12.4462 15.6922 12.3232 15.6922Z"
      fill="currentColor"
    />
    <path
      d="M12.5694 9.41552C12.2001 9.41552 11.954 9.16937 11.954 8.80014V8.06167C11.954 7.69244 12.2001 7.44629 12.5694 7.44629C12.9386 7.44629 13.1847 7.69244 13.1847 8.06167V8.80014C13.1847 9.16937 12.9386 9.41552 12.5694 9.41552Z"
      fill="currentColor"
    />
    <path
      d="M12.5694 16.5539C12.2001 16.5539 11.954 16.3078 11.954 15.9386V15.2001C11.954 14.8309 12.2001 14.5847 12.5694 14.5847C12.9386 14.5847 13.1847 14.8309 13.1847 15.2001V15.9386C13.1847 16.3078 12.9386 16.5539 12.5694 16.5539Z"
      fill="currentColor"
    />
    <path
      d="M18.4769 20H7.76924C6.53847 20 5.43078 19.2615 4.93847 18.1538L2.72309 13.3538C2.35386 12.4923 2.35386 11.3846 2.72309 10.5231L4.93847 5.84615C5.43078 4.73846 6.53847 4 7.76924 4H18.4769C20.2 4 21.5539 5.47692 21.5539 7.32308V16.8C21.5539 18.5231 20.2 20 18.4769 20ZM7.76924 5.23077C7.03078 5.23077 6.4154 5.6 6.16924 6.33846L3.83078 11.0154C3.58463 11.6308 3.58463 12.2462 3.83078 12.8615L6.04617 17.6615C6.4154 18.4 7.03078 18.7692 7.64617 18.7692H18.3539C19.3385 18.7692 20.2 17.9077 20.2 16.8V7.2C20.2 6.09231 19.3385 5.23077 18.3539 5.23077H7.76924Z"
      fill="currentColor"
    />
  </svg>
);
