import { FC } from 'react';

import { LinearProgress } from '@material-ui/core';

import { useCostMode } from '../../../utilities/costMode';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';

import PrintItemsListBody from './PrintItemsListBody';
import PrintItemsListSubheader from './PrintItemsListSubheader';

type PrintItemsListProps = {
  shouldDisplayCosts: boolean;
  categorizations: Categorization[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  creators: any[];
  itemsAssigneeMap: Map<string, UserLink | undefined | null>;
  itemsListItemsMap: Map<UUID, ItemsListItem>;
  itemsTree?: ItemsTree; // undefined if still loading
  loading: boolean;
  milestoneText: string;
  settings: Settings;
  triggerOnComplete?: () => void;
};

const PrintItemsList: FC<PrintItemsListProps> = ({
  shouldDisplayCosts,
  categorizations,
  creators,
  itemsAssigneeMap,
  itemsListItemsMap,
  itemsTree,
  loading,
  milestoneText,
  settings,
  triggerOnComplete,
}) => {
  const projectId = getProjectIdFromUrl();
  useCostMode(); // ensure cost mode is up-to-date if a user navigates directly here
  return (
    <>
      <PrintPageHeaderWrapper
        projectId={projectId}
        reportName="Items"
        milestoneName={milestoneText}
        triggerOnComplete={triggerOnComplete}
      />
      <PrintItemsListSubheader
        shouldDisplayCosts={shouldDisplayCosts}
        categorizations={categorizations}
        creators={creators}
        itemCount={itemsTree?.totalItems ?? 0}
        settings={settings}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <PrintItemsListBody
          shouldDisplayCosts={shouldDisplayCosts}
          itemsAssigneeMap={itemsAssigneeMap}
          itemsTree={itemsTree}
          itemsListItemsMap={itemsListItemsMap}
        />
      )}
    </>
  );
};

export default PrintItemsList;
