import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { LINK_REMOTE_ITEM_ASSET, UPLOAD_ATTACH_ITEM_ASSET } from '../../../../actions/actionTypes';
import { getItemAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { Selected, selectedAssetsVar } from '../../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { useAttachToItem } from '../../../assets/hooks/mutations/useAttachToItem';
import { filterSelectedAssets } from '../FilesTable/FilesTableUseUtils';

import FilesAttachButton from './FilesAttachButton';

type FilesAttachItemProps = {
  itemID: UUID;
  onClick: () => void;
  selectedMap: Selected;
  sourceSystem?: SourceSystem;
  onItemMutated?: () => void;
};

const FilesAttachItem: FC<FilesAttachItemProps> = ({
  itemID,
  onClick,
  selectedMap,
  sourceSystem,
  onItemMutated,
}) => {
  // Props
  const { hubID, sourceID, sourceProjectID } = useReactiveVar(selectedAssetsVar);
  // Hooks
  const sendAnalytics = useAnalyticsEventHook();

  const attachToItem = useAttachToItem();

  const analyticsType = sourceSystem ? LINK_REMOTE_ITEM_ASSET : UPLOAD_ATTACH_ITEM_ASSET;

  // Actions
  const onClickAttachToItem = () => {
    filterSelectedAssets(selectedMap).forEach((assetID) => {
      attachToItem({
        input: { assetID, itemID, sourceSystem, sourceID, sourceProjectID, hubID },
        onSuccess: onItemMutated,
      });
      sendAnalytics(getItemAssetAnalytics(analyticsType, itemID, assetID));
    });
    onClick();
  };

  return <FilesAttachButton onClick={onClickAttachToItem} selectedMap={selectedMap} />;
};

export default FilesAttachItem;
