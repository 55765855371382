/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { useMutation } from '@apollo/client';

import {
  AttachToItemMutation,
  AttachToItemMutationVariables,
  ConvertItemToItemWithOptionsMutation,
  ConvertItemToItemWithOptionsMutationVariables,
  CreateItemMutation,
  CreateItemMutationVariables,
  CreateOptionMutation,
  CreateOptionMutationVariables,
  Visibility,
} from '../../generated/graphql';
import { costModeVar } from '../../utilities/costMode';
import { useUploadAssets } from '../assets/hooks/AssetsMutationHook';
import { attachToItemMutation } from '../assets/hooks/queries';
import { createOptionMutation } from '../Dialogs/DialogsNewItem/hooks/queries';
import {
  convertItemToItemWithOptionsMutation,
  createItemMutation,
} from '../Items/hooks/itemMutation';

import { Suggestion } from './types';

export default function useCreateItemFromSuggestion(
  projectID: UUID,
  milestoneID: UUID,
  suggestion?: Suggestion
) {
  const costMode = costModeVar();
  let item: CreateItemMutationVariables | undefined;

  if (suggestion) {
    item = {
      categories: [],
      // if there's a costImpact, the BE automatically creates an option when converting to IWO
      costImpact: suggestion.options.length === 1 ? 0 : undefined,
      costMode,
      descriptionStyled:
        suggestion.options.length === 1 ? getOptionDescription(suggestion.options[0]) : '',
      milestoneID,
      name: suggestion.options.length === 1 ? suggestion.options[0].name : suggestion.title,
      projectID,
      visibility: Visibility.PRIVATE_DRAFT,
    };
  }

  const [onCreateItem] = useMutation<CreateItemMutation, CreateItemMutationVariables>(
    createItemMutation,
    { variables: item }
  );

  const [onConvertItemToIWO] = useMutation<
    ConvertItemToItemWithOptionsMutation,
    ConvertItemToItemWithOptionsMutationVariables
  >(convertItemToItemWithOptionsMutation);

  const [onCreateOption] = useMutation<CreateOptionMutation, CreateOptionMutationVariables>(
    createOptionMutation
  );

  const [uploadAssets] = useUploadAssets();

  const [attachToItem] = useMutation<AttachToItemMutation, AttachToItemMutationVariables>(
    attachToItemMutation,
    {}
  );

  const attachImage = (idToAttachTo: UUID, url: string) => {
    return new Promise((resolve, reject) => {
      const cacheFreeURL = `${url}?c=f`;
      console.log('fetching', cacheFreeURL);
      fetch(cacheFreeURL)
        .then((response) => response.blob())
        .then((blob) => {
          let filename = url.split('/').pop() ?? '';
          filename = filename.split('?')[0];

          const file = new File([blob], filename, {
            type: blob.type,
            lastModified: new Date().getTime(),
          });

          uploadAssets({ file, itemID: idToAttachTo }, () => {}, {
            attachAssetToItemFunc: async (args) => {
              console.log('attachAssetToItemFunc', args);
              resolve(
                await attachToItem({
                  variables: {
                    itemID: idToAttachTo,
                    assetInput: {
                      AssetID: args.input.assetID,
                    },
                  },
                })
              );
            },
          });
        })
        .catch((err) => reject(err));
    });
  };

  const onAttachOptions =
    suggestion?.options.length === 1
      ? (itemID: UUID) =>
          Promise.all(suggestion.options[0].images.map((i) => attachImage(itemID, i.url)))
      : async (itemID: UUID) => {
          const convertItemResponse = await onConvertItemToIWO({
            variables: { projectID, item: itemID, costMode },
          });

          if (!convertItemResponse.data?.convertItemToItemWithOptions?.id) return;

          for (let i = 0; i < (suggestion?.options.length ?? 0); i += 1) {
            const option = suggestion?.options[i];
            if (option) {
              const createOptionResponse = await onCreateOption({
                variables: {
                  projectID,
                  parent: itemID,
                  costMode,
                  item: {
                    categories: [],
                    costImpact: 0,
                    descriptionStyled: getOptionDescription(option),
                    milestoneID,
                    name: option.name,
                    projectID,
                    visibility: Visibility.PRIVATE_DRAFT,
                  },
                },
              });

              const optionID =
                createOptionResponse.data?.createOption?.options.find((o) => o.name === option.name)
                  ?.id ?? '';

              if (option.images.length) {
                for (let j = 0; j < option.images.length; j += 1) {
                  await attachImage(optionID, option.images[j].url);
                }
              }
            }
          }
        };

  if (!suggestion) {
    return () => Promise.resolve(undefined);
  }

  return async () => {
    const itemID = (await onCreateItem()).data?.createItem?.id;

    if (itemID) {
      await onAttachOptions(itemID);
    }

    return itemID;
  };
}

function getOptionDescription(option: Suggestion['options'][number]) {
  let description = `<p>${option.description}</p>`;

  if (option.pros.length) {
    description += '<h3>Pros</h3>';
    description += '<ul>';
    option.pros.forEach((p) => {
      description += `<li>${p}</li>`;
    });
    description += '</ul>';
  }

  if (option.cons.length) {
    description += '<h3>Cons</h3>';
    description += '<ul>';
    option.cons.forEach((c) => {
      description += `<li>${c}</li>`;
    });
    description += '</ul>';
  }

  return description;
}
