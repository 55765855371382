import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';

import { ChangeEvent } from '../../../../generated/graphql';
import { Button } from '../../../scales';

import IntegrationsChangeEventContent from './IntegrationsChangeEventContent';
import IntegrationsLoggedInEmptyState from './IntegrationsLoggedInEmptyState';
import IntegrationsLoggedOutEmptyState from './IntegrationsLoggedOutEmptyState';

const LoadingContent: FC = () => (
  <div className="flex h-36 w-full items-center justify-items-center">
    <div className="ml-auto mr-auto">
      <CircularProgress style={{ height: 20, width: 20 }} />
    </div>
  </div>
);

type IntegrationsContentProps = {
  canDeleteItemIntegration: boolean;
  changeEvents: ChangeEvent[] | undefined;
  onClickLinkProcoreAccount: () => void;
  editProcoreLogin: () => void;
  openCreateChangeEventModal: () => void;
  isLoggedIntoProcore: boolean;
  isLoadingChangeEvents: boolean;
  isItemSidebar: boolean;
  isPrinting: boolean;
  canCreateChangeEvent: boolean;
  pageLoadTime?: Date;
  sourceID: UUID;
  refetchChangeEvents: () => void;
  setSubmitted: (value: boolean) => void;
  linkedProjectID?: string;
  sourceCompanyID?: string;
};

const IntegrationsContent: FC<IntegrationsContentProps> = ({
  canDeleteItemIntegration,
  onClickLinkProcoreAccount,
  changeEvents,
  isLoggedIntoProcore,
  isLoadingChangeEvents,
  editProcoreLogin,
  openCreateChangeEventModal,
  isItemSidebar,
  isPrinting,
  canCreateChangeEvent,
  pageLoadTime,
  sourceID,
  refetchChangeEvents,
  setSubmitted,
  linkedProjectID,
  sourceCompanyID,
}) => {
  let content = !isPrinting ? (
    <IntegrationsLoggedOutEmptyState
      onClickLinkProcoreAccount={onClickLinkProcoreAccount}
      isItemSidebar={isItemSidebar}
    />
  ) : null;

  if (isLoggedIntoProcore && linkedProjectID && sourceCompanyID) {
    content = (
      <div className="flex flex-col gap-1">
        {isLoadingChangeEvents && <LoadingContent />}
        {!isLoadingChangeEvents && !!changeEvents?.length && (
          <IntegrationsChangeEventContent
            canDeleteItemIntegration={canDeleteItemIntegration}
            changeEvents={changeEvents ?? []}
            pageLoadTime={pageLoadTime}
            sourceID={sourceID}
            refetchChangeEvents={refetchChangeEvents}
            setSubmitted={setSubmitted}
            linkedProjectID={linkedProjectID}
          />
        )}
        {!isLoadingChangeEvents && !changeEvents?.length && !isPrinting && (
          <IntegrationsLoggedInEmptyState
            canCreateChangeEvent={canCreateChangeEvent}
            openCreateChangeEventModal={openCreateChangeEventModal}
          />
        )}
        {!isPrinting && (
          <div className="mr-auto">
            <Button
              label="Edit Procore Linked Account"
              type="tertiary"
              onClick={editProcoreLogin}
            />
          </div>
        )}
      </div>
    );
  }

  return <div className="flex flex-col bg-background-primary p-3">{content}</div>;
};

export default IntegrationsContent;
