import { useMutation } from '@apollo/client';

import { addCollaboratorTradeAnalytics } from '../../../analytics/analyticsEventProperties';
import {
  AddCollaboratorTradesMutation,
  AddCollaboratorTradesMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { addCollaboratorTradesMutation } from './queries';

function useAddTradesCollaborator() {
  const [addTradeCollaboratorFunc, mutationResult] = useMutation<
    AddCollaboratorTradesMutation,
    AddCollaboratorTradesMutationVariables
  >(addCollaboratorTradesMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectID: UUID,
    collaboratorID: UUID,
    categoryIDs: UUID[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure: any
  ) =>
    addTradeCollaboratorFunc({
      variables: { projectID, collaboratorID, categoryIDs },
    })
      .then((result) => {
        const addTrades = result.data?.addTrades;
        if (onSuccess) onSuccess(addTrades);
        sendAnalytics(addCollaboratorTradeAnalytics(collaboratorID, categoryIDs));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [submitFunc, mutationResult];
}

export default useAddTradesCollaborator;
