import { FC } from 'react';

import { CATEGORY_CELL, L, M, P, S1, S2, TOTAL_REF } from '../../../constants';
import { MarkupType } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { EMPTY_COST, formatPercent } from '../../../utilities/string';
import FilterIcon from '../../Icons/FilterIcon';
import { getMarkupDisplayTypeString } from '../../JoinGrid/editors/utils';

import { getEstimatePrintRowCellValues, isMarkup } from './PrintItemDetailsUtils';

type TableRow = {
  content: string | string[];
  costText?: boolean;
  displayFilter?: boolean;
  isCategory?: boolean;
  rightAlign?: boolean;
};

type PrintItemDetailsEstimateRowProps = {
  estimate: Estimate;
  gridRow: PrintGridRow | PrintViewCell[];
  rowNum: number;
  tableVariant: 'estimate' | 'markup' | 'inherited';
  columnWidths: string[];
  isLEMSOU: boolean;
};

const PrintItemDetailsEstimateRow: FC<PrintItemDetailsEstimateRowProps> = ({
  estimate,
  gridRow,
  rowNum,
  tableVariant,
  columnWidths,
  isLEMSOU,
}) => {
  if (!estimate || !gridRow) return <></>;
  const gridRowCells = gridRow as PrintViewCell[];

  let colRowNum = '';
  const colCategories: string[] = [];
  let colReferences: string[] = [];
  const tableRowArray: TableRow[] = [];
  let hasCategories = false;

  if (tableVariant === 'estimate') {
    // #
    tableRowArray.push({ content: `${L}${rowNum}` });

    // Categories
    const gridFields = estimate.fields;
    let numCategorizationColumns = 0;
    for (let i = 0; i < gridFields.length; i += 1) {
      if (gridFields[i].type === CATEGORY_CELL) {
        hasCategories = true;
        let categoryText = '';
        const categoryValue = gridRowCells[i].value.category;
        if (categoryValue) {
          categoryText = categoryValue.number;
          categoryText += `${categoryValue.number && categoryValue.name ? ` - ` : ''} 
          ${categoryValue.name}`;
        }
        colCategories.push(categoryText);
        // variable to keep track of how many category columns added in grid
        numCategorizationColumns += 1;
      }
    }
    if (hasCategories) {
      tableRowArray.push({
        content: colCategories,
        isCategory: true,
      });
    }

    const {
      colDescription,
      colQTY,
      colUM,
      colUnitPrice,
      colTotal,
      subcontractorUnitPrice,
      subcontractorTotal,
      userUnitPrice,
      userTotal,
      laborUnitPrice,
      laborTotal,
      materialUnitPrice,
      materialTotal,
      equipmentUnitPrice,
      equipmentTotal,
      otherUnitPrice,
      otherTotal,
    } = getEstimatePrintRowCellValues(gridRowCells, gridFields, numCategorizationColumns);

    tableRowArray.push(
      { content: colDescription },
      { content: colQTY, rightAlign: true, costText: true },
      { content: colUM },
      {
        content: colUnitPrice,
        rightAlign: true,
        costText: true,
      },
      { content: colTotal, rightAlign: true, costText: true }
    );
    if (isLEMSOU) {
      tableRowArray.splice(
        tableRowArray.length - 1,
        0,
        { content: laborUnitPrice, rightAlign: true, costText: true },
        { content: laborTotal, rightAlign: true, costText: true },
        { content: equipmentUnitPrice, rightAlign: true, costText: true },
        { content: equipmentTotal, rightAlign: true, costText: true },
        { content: materialUnitPrice, rightAlign: true, costText: true },
        { content: materialTotal, rightAlign: true, costText: true },
        { content: subcontractorUnitPrice, rightAlign: true, costText: true },
        { content: subcontractorTotal, rightAlign: true, costText: true },
        { content: otherUnitPrice, rightAlign: true, costText: true },
        { content: otherTotal, rightAlign: true, costText: true },
        { content: userUnitPrice, rightAlign: true, costText: true },
        { content: userTotal, rightAlign: true, costText: true }
      );
    }

    return (
      <>
        <div className="print-table-row print-avoid-page-break">
          <div className="print-table-row-details">
            {tableRowArray.map((tableRow, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`${
                  isLEMSOU ? 'print-table-row-details-lemsou-text' : 'print-table-row-details-text'
                } ${tableRow.rightAlign && 'print-table-align-right'} ${
                  tableRow.costText && 'print-table-cost-text'
                }`}
                style={{ width: columnWidths[i] }}
              >
                {tableRow.isCategory &&
                  // eslint-disable-next-line react/no-array-index-key
                  colCategories.map((category, i) => <div key={`${category}${i}`}>{category}</div>)}
                {!tableRow.isCategory && tableRow.content}
              </div>
            ))}
          </div>
        </div>
        <div>
          <hr className="print-sub-divider" />
        </div>
      </>
    );
  }

  let markupArray: Markup[];
  let rowPrefix = '';
  if (tableVariant === 'markup') {
    rowPrefix = M;
    markupArray = estimate.markups;
  } else {
    rowPrefix = P;
    markupArray = estimate.inheritedMarkups;
  }

  // #
  colRowNum = `${rowPrefix}${rowNum}`;
  tableRowArray.push({
    content: colRowNum,
  });

  if (isMarkup(gridRow) && tableVariant === 'inherited') {
    tableRowArray.push({
      content: getMarkupDisplayTypeString(gridRow.displayType || undefined, false) ?? '',
    });
  }

  // Description
  tableRowArray.push({
    content: (gridRow as Markup).name,
  });

  // Type and Value
  if (isMarkup(gridRow)) {
    if (gridRow.type === MarkupType.FIXED) {
      tableRowArray.push({
        content: 'Lump Sum',
      });
      tableRowArray.push({
        content: formatCost(gridRow.value),
        rightAlign: true,
        costText: true,
      });
    } else {
      const { value, percentScale } = gridRow;
      tableRowArray.push({
        content: 'Percent',
      });
      tableRowArray.push({
        content: formatPercent(value, percentScale ?? undefined),
        rightAlign: true,
        costText: true,
      });
    }
  }

  // References
  colReferences = [];
  if (
    'markupReference' in gridRow &&
    gridRow.markupReference?.appliesTo &&
    gridRow.markupReference?.appliesTo.length > 0
  ) {
    gridRow.markupReference?.appliesTo.forEach((reference: string) => {
      let referenceText = '';
      if (markupArray.some((r) => r.id === reference)) {
        const index = markupArray.findIndex((r) => r.id === reference) + 1;
        referenceText = `${rowPrefix}${index}`;
      } else if (reference === S1) {
        referenceText = S1;
      } else if (reference === TOTAL_REF) {
        referenceText = TOTAL_REF;
      }
      if (referenceText) colReferences.push(referenceText);
    });

    if (tableVariant === 'inherited') {
      const s1Index = colReferences.findIndex((v) => v === S1);
      if (s1Index !== -1 && colReferences[s1Index + 1] !== S2) {
        colReferences.splice(s1Index + 1, 0, S2);
      }
    }
  } else {
    colReferences.push(EMPTY_COST);
  }
  const colReferencesDisplay = colReferences.join(', ');
  tableRowArray.push({
    content: colReferencesDisplay,
    displayFilter: (gridRow as Markup).categoryFilters.length > 0,
  });

  // Total
  tableRowArray.push({
    content: (gridRow as Markup).disabled ? 'Not Applied' : formatCost((gridRow as Markup).total),
    rightAlign: true,
    costText: true,
  });

  return (
    <>
      <div className="print-table-row">
        <div className="print-table-row-details">
          {tableRowArray.map((tableRow, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={`print-table-row-details-text ${
                tableRow.rightAlign && 'print-table-align-right'
              } ${tableRow.costText && 'print-table-cost-text'} ${
                (gridRow as Markup).disabled && i > 0 && 'print-table-row-disabled-text'
              }`}
              style={{
                width: columnWidths[i],
              }}
            >
              <div>
                {tableRow.displayFilter && (
                  <span className="print-filter-icon">
                    <FilterIcon isFilled isError={false} />
                  </span>
                )}
                {tableRow.content}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <hr className="print-sub-divider" />
      </div>
    </>
  );
};

export default PrintItemDetailsEstimateRow;
