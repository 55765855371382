import { FC, ReactNode } from 'react';

import JoinSelect, { SelectEntry, getEntryObj } from '../JoinSelect/JoinSelect';

type ValueSelectProps = {
  cySelect?: string;
  disabled?: boolean;
  groupingKey?: 'type';
  hidePrint?: boolean;
  isCompact?: boolean;
  makeEntry?: (v: string, i: number) => SelectEntry;
  nameFunc?: (name: string, i: number) => ReactNode;
  onChange: (v: string) => void;
  onClose?: () => void;
  onOpen?: () => void;
  showRightIcon?: boolean;
  subName?: string[];
  types?: string[];
  value: string;
  valueToolTip?: (entry: SelectEntry) => ReactNode;
  values: string[];
};

const SelectValue: FC<ValueSelectProps> = ({
  cySelect = undefined,
  disabled = false,
  groupingKey,
  hidePrint = true,
  isCompact = true,
  makeEntry,
  nameFunc = undefined,
  onChange,
  onClose,
  onOpen,
  showRightIcon,
  subName,
  types,
  value,
  valueToolTip,
  values,
}) => {
  const entries = values.map((v, i) => {
    if (makeEntry) {
      return makeEntry(v, i);
    }
    return getEntryObj({
      id: v,
      name: v,
      subName: subName && i < subName.length ? subName[i] : undefined,
      type: types ? types[i] : undefined,
    });
  });

  return (
    <JoinSelect
      cySelect={cySelect}
      disabled={disabled}
      entries={entries}
      entryToolTip={valueToolTip}
      groupingKey={groupingKey}
      hidePrint={hidePrint}
      isCompact={isCompact}
      nameFunc={nameFunc}
      onChange={(type: string | null) => onChange(type ?? '')}
      showRightIcon={showRightIcon}
      onClose={onClose}
      onOpen={onOpen}
      value={value}
    />
  );
};

export default SelectValue;
