import { FC } from 'react';

import { Reorder, ViewList } from '@material-ui/icons';

import { ViewTypeAnalytics, setViewTypeAnalytics } from '../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import InputsToggle, { ToggleOption } from '../Inputs/InputsToggle/InputsToggle';

export const LIST = 'List';
export const COMPACT_LIST = 'Compact List';
const COZY_VIEW = 'Cozy view';
const COMPACT_VIEW = 'Compact view';
const optionText = [LIST, COMPACT_LIST];
const options = [
  { text: LIST, icon: <ViewList />, title: COZY_VIEW },
  { text: COMPACT_LIST, icon: <Reorder />, title: COMPACT_VIEW },
];

type ItemsListViewToggleProps = {
  listView: string;
  setListView: (view: string) => void;
};

const getToggleOption = (text: string) => {
  const { icon, title } = options.find((o) => o.text === text) || {};
  return { key: text, text, icon, title };
};

const ItemsListViewToggle: FC<ItemsListViewToggleProps> = ({ setListView, listView }) => {
  const sendAnalytics = useAnalyticsEventHook();

  return (
    <InputsToggle
      isMarginless
      options={optionText.map((text: string) => getToggleOption(text))}
      selected={[getToggleOption(listView)]}
      setSelected={(toggleOption: ToggleOption) => {
        setListView(toggleOption.text);
        sendAnalytics(setViewTypeAnalytics(ViewTypeAnalytics.ITEMS_SET_VIEW, toggleOption.text));
      }}
    />
  );
};

export default ItemsListViewToggle;
