import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { refetchMilestone } from '../../../api/refetchSets';
import {
  CreateEstimateFromProjectCompMutation,
  CreateEstimateFromProjectCompMutationVariables,
} from '../../../generated/graphql';

import { createEstimateFromProjectComp, projectCompsSetQuery } from './queries';

export const useCreateEstimateFromProjectComp = () => {
  const [createEstimateFromProjectCompFunc] = useMutation<
    CreateEstimateFromProjectCompMutation,
    CreateEstimateFromProjectCompMutationVariables
  >(createEstimateFromProjectComp);
  const mutation = (
    projectID: UUID,
    estimateCreationInput: EstimateCreationInput,
    onSuccess: (r: CreateEstimateFromProjectCompMutation['createEstimateFromProjectComp']) => void,
    onFailure: () => void
  ) =>
    createEstimateFromProjectCompFunc({
      variables: {
        projectID,
        projectCompsSetInput: projectCompsSetInputVar(),
        estimateCreationInput,
      },
      refetchQueries: ({ data }) => {
        const queries = [];
        const setQuery = getOperationName(projectCompsSetQuery);
        if (setQuery) queries.push(setQuery);
        const id = data?.createEstimateFromProjectComp.id;
        if (id && id !== null) queries.push(refetchMilestone(id));
        return queries;
      },
    })
      .then(({ data }) => {
        if (data?.createEstimateFromProjectComp) onSuccess(data.createEstimateFromProjectComp);
      })
      .catch(() => {
        onFailure();
      });
  return [mutation];
};
