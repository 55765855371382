import { FC, useContext, useMemo, useState } from 'react';

import { isSecondPopupVar } from '../../api/apollo/reactiveVars';
import { CostReportColumnKey } from '../../generated/graphql';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import MultiGroupOrder from '../shared-widgets/MultiGroup/MultiGroupOrder';
import {
  GroupByOption,
  MultiGroupOrderOption,
} from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';

import {
  ColumnDescription,
  getColumnTermFromColumnKey,
} from './CostReportColumns/CostReportColumns';
import {
  getNonCategorizedUnitText,
  isNonUnitColumnKey,
} from './CostReportList/CostReportList/CostReportListUtils';

type CostReportColumnProps = {
  columnDescriptions: ColumnDescription[];
  displayColumnKeys: CostReportColumnKey[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose: (evt: any) => void;
};

const CostReportColumnSelect: FC<CostReportColumnProps> = ({
  columnDescriptions,
  displayColumnKeys = [],
  onClose,
}) => {
  const [open, setOpen] = useState(false);

  const filteredDescriptions = columnDescriptions.filter((description) => !description?.unitID);
  const filteredColumnKeys = displayColumnKeys.filter((key) => isNonUnitColumnKey(key));

  const [selectedColumns, setSelectedColumns] = useState(filteredColumnKeys);

  const getColumnsFromOptions = (options: MultiGroupOrderOption[]) => {
    const columns: CostReportColumnKey[] = [];
    (options as GroupByOption[]).forEach((o: GroupByOption) => {
      if (o?.id) {
        columns.push(getNonCategorizedUnitText(o.id) as CostReportColumnKey);
      }
    });
    return columns;
  };

  const handleSet = (options: MultiGroupOrderOption[]) => {
    setSelectedColumns(getColumnsFromOptions(options));
  };

  const handleAdd = (options: MultiGroupOrderOption[]) => {
    setSelectedColumns(selectedColumns.concat(getColumnsFromOptions(options)));
  };

  const t = useContext(ProjectTermStore);
  const handleDelete = (options: MultiGroupOrderOption[]) => {
    setSelectedColumns(
      selectedColumns.filter((s) =>
        options.every((o) => getColumnTermFromColumnKey(t, s) !== o.name)
      )
    );
  };

  const handleSort = (a: number, b: number) => {
    const newSelectedColumns = [...selectedColumns];
    if (a !== null && b !== null) {
      newSelectedColumns[a] = selectedColumns[b];
      newSelectedColumns[b] = selectedColumns[a];
      setSelectedColumns(newSelectedColumns);
    }
  };

  // Transform our description format into MGO option inputs
  const options = useMemo(() => {
    const options: GroupByOption[] = [];
    filteredDescriptions.forEach((columnDescription: ColumnDescription) => {
      if (columnDescription) {
        const { columnKey, label, title } = columnDescription;
        if (label) {
          // label is only on nonMenu header labels
          options.push({ id: label, label });
        } else if (columnKey) {
          // id and name are used to define and display the column in the stack
          options.push({ id: columnKey, name: getColumnTermFromColumnKey(t, columnKey), title });
        }
      }
    });
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [filteredDescriptions]);

  const selected: MultiGroupOrderOption[] = selectedColumns.map((key) => ({
    id: key,
    name: getColumnTermFromColumnKey(t, key),
  }));

  return (
    <MultiGroupOrder
      options={options}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleSet={handleSet}
      handleSort={handleSort}
      setOpen={(isOpen: boolean) => {
        if (!isOpen && onClose) onClose(selectedColumns);
        setOpen(!!isOpen);
        isSecondPopupVar(!!isOpen);
      }}
      open={open}
      selected={selected}
    />
  );
};

export default CostReportColumnSelect;
