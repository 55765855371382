import { FC } from 'react';

import { ACTIVE_ITEMS_SUMMARY } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useDecisionSummaryQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  ACTIVE_ITEMS_SUMMARY_TOOLTIP,
  CHART_LARGE,
  DECISION_SUMMARY_HEIGHT,
  ExecutiveDashboardDataProps,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardDecisionSummary from './ExecutiveDashboardDecisionSummary';

const ExecutiveDashboardDecisionSummaryData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
}) => {
  const { data: { decisionSummary } = { decisionSummary: null }, loading } =
    useDecisionSummaryQuery(projectIDs);

  return (
    <div onMouseOver={onHover} onFocus={onHover}>
      <ExecutiveDashboardChartFrame
        component={
          loading || dashboardLoading ? (
            <ExecutiveDashboardLoadingState />
          ) : (
            <ExecutiveDashboardDecisionSummary decisionSummary={decisionSummary} />
          )
        }
        title={ACTIVE_ITEMS_SUMMARY}
        height={DECISION_SUMMARY_HEIGHT}
        variant={CHART_LARGE}
        tooltip={ACTIVE_ITEMS_SUMMARY_TOOLTIP}
      />
    </div>
  );
};

export default ExecutiveDashboardDecisionSummaryData;
