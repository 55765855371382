import { ReactNode } from 'react';

import { ProjectType } from '../../../generated/graphql';
import { Select, SelectEntry } from '../../scales';

export type Props = {
  editable?: boolean;
  name: string;
  label: string;
  labelEl?: ReactNode;
  search?: boolean;
  selectedValue?: string | null;
  setProjectProp: (p: ProjectType | null) => void;
  values: ProjectType[];
};

export const getEntry = (id: string, label: string, parentID?: string | null): SelectEntry => {
  return {
    id,
    label,
    parentID,
  };
};

export default function ProjectTypeSelector({
  editable,
  name,
  label,
  labelEl: labelElOuter,
  search,
  selectedValue,
  setProjectProp,
  values,
}: Props) {
  const entries: SelectEntry[] = values.map(({ id, name, parentID }) => ({
    id,
    label: name,
    parentID,
  }));

  const labelEl = labelElOuter || <div className="type-label">{label}</div>;

  const selector = (
    <div className="flex flex-col gap-0.5">
      {labelEl}
      <Select
        aria-label={`Set project ${name}`}
        data-cy={`dropdown-project${name.split(' ')[0]}`}
        isDisabled={!editable}
        entries={entries}
        onChange={(newID: UUID | null) => {
          const newProp = (values as ProjectType[]).find((p) => p.id === newID) || null;
          setProjectProp(newProp);
        }}
        isSearchable={search}
        value={selectedValue}
        placeholder={`Set project ${name}`}
      />
    </div>
  );

  return selector;
}
