import { LoadSourceHubProjectsQuery, LoadSourceHubRootQuery } from '../../../../generated/graphql';
import { Select } from '../../../scales';

export type ProcoreCompany = Pick<
  LoadSourceHubRootQuery['sourceHubRoot']['hubs'][number],
  'id' | 'name'
>;

export type ProcoreProject = Pick<
  LoadSourceHubProjectsQuery['sourceHubProjects']['projects'][number],
  'id' | 'name'
>;

type ProcoreCompanyProjectSelectProps = {
  companies: ProcoreCompany[];
  projects?: ProcoreProject[];
  companyID?: string;
  projectID?: string;
  setCompanyID: (value: string) => void;
  setProjectID: (value: string) => void;
};

const ProcoreCompanyProjectSelect: React.FC<ProcoreCompanyProjectSelectProps> = ({
  companies,
  projects,
  setCompanyID,
  setProjectID,
  companyID,
  projectID,
}) => {
  const selectEntries = companies.map(({ name, id }) => ({
    id,
    label: name,
  }));

  const projectSelectEntries = projects?.map(({ name, id }) => ({
    id,
    label: name,
  }));
  return (
    <div className="flex w-full max-w-sm flex-col items-center gap-2">
      <Select
        entries={selectEntries}
        label="Procore Company"
        placeholder="Select a company..."
        onChange={(value) => setCompanyID(value)}
        value={companyID}
      />
      <Select
        entries={projectSelectEntries}
        label="Procore Project"
        placeholder="Select a project..."
        isDisabled={!projectSelectEntries}
        onChange={(value) => setProjectID(value)}
        value={projectID}
      />
    </div>
  );
};

export default ProcoreCompanyProjectSelect;
