import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';

import { JoinRoutes } from '../../../../../api/gqlEnums';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import useCoreSearchPermissions from '../../hooks/useCoreSearchPermissions';
import { SearchToggleValue } from '../../SearchToggle';
import { GlobalSearchMode, addToRecentSearches } from '../GlobalSearchUtils';

import styles from './SearchBarStyles';

interface SearchBarProps {
  classes: Classes<typeof styles>;
  onChangeSearch: (search: string) => void;
  project?: string;
  search: string;
  searchMode: GlobalSearchMode;
}

const SearchBar = (props: SearchBarProps) => {
  const { classes } = props;

  const navigate = useNavigate();

  const { hasAllItemsAccess, hasAllProjectsAccess } = useCoreSearchPermissions();

  // Maintain a second store for the search string. We use this local state
  // which is updated immediately for displaying the value to the user.
  const [search, setSearch] = useState(props.search);

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => inputRef.current?.focus(), [props.searchMode]);

  // At the same time, we have a debounced function which calls the event handler
  // we received in props. The debouncing is to prevent excessive backend searches.
  // We need to memoize the callback for referential equality not to prevent
  // excessive React re-renders (we're not super worried about that here since
  // it's a small component tree). We memoize it so that the debounce function
  // always has the same timer reference internally and works properly.
  const debouncedOnChangeSearch = useMemo(
    () =>
      debounce(props.onChangeSearch, 200, {
        leading: false,
        trailing: true,
      }),
    [props.onChangeSearch]
  );

  // Always use this function locally to update the search value so that the local
  // and parent `search` values stay synced.
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    debouncedOnChangeSearch(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const routeKey =
        props.searchMode === GlobalSearchMode.ALL_PROJECTS ? JoinRoutes.PROJECTS : JoinRoutes.ITEMS;

      addToRecentSearches(search, props.searchMode, props.project);
      navigate(
        generateSharedPath(routeKey, {
          search: `?search=${search}&toggle=${
            (props.searchMode === GlobalSearchMode.ALL_PROJECTS && hasAllProjectsAccess) ||
            (props.searchMode === GlobalSearchMode.ALL_ITEMS && hasAllItemsAccess)
              ? SearchToggleValue.ALL
              : SearchToggleValue.MY
          }${props.project ? `&project=${props.project}` : ''}`,
        })
      );
    }
  };

  const onClear = () => {
    setSearch('');
    debouncedOnChangeSearch('');
  };

  const placeholder = useMemo(() => {
    if (props.searchMode === GlobalSearchMode.PROJECT_ITEMS && !!props.project)
      return `Search items in '${props.project}'`;
    return `Search ${props.searchMode.toLowerCase()}`;
  }, [props.project, props.searchMode]);

  return (
    <div className={classes.container}>
      <span>
        <TextField
          className={classes.searchBox}
          data-cy="SearchBar"
          onChange={handleChangeSearch}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          inputRef={inputRef}
          InputProps={{
            classes: {
              focused: classes.focused,
              root: classes.root,
            },
            disableUnderline: true,
            startAdornment: <Search className={classes.searchIcon} />,
            endAdornment:
              search.length > 0 ? (
                <IconButton classes={{ root: classes.iconButton }} disableRipple onClick={onClear}>
                  <Clear />
                </IconButton>
              ) : undefined,
          }}
          value={search}
        />
      </span>
    </div>
  );
};

export default withStyles(styles)(SearchBar);
