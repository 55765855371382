import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './SelectMilestoneButtonStyles';

type SelectMilestoneButtonProps = {
  canEditItemMilestone: boolean;
  classes: Classes<typeof styles>;
  disabled: boolean;
  name: string;
  onClick: () => void;
};

const SelectMilestoneButton: FC<SelectMilestoneButtonProps> = ({
  canEditItemMilestone,
  classes,
  disabled,
  name,
  onClick,
}) => (
  <button
    className={`${classes.currentMilestoneSelector} ${
      disabled ? classes.currentMilestoneSelectorDisabled : ''
    }`}
    data-cy="buttonBase-milestoneSelector"
    disabled={disabled}
    aria-label="Open Milestone Dialog"
    onClick={onClick}
    type="button"
  >
    <Typography className={disabled ? classes.currentMilestoneViewOnly : classes.currentMilestone}>
      {name}
    </Typography>
    {canEditItemMilestone && !disabled && (
      <div className={classes.currentMilestoneEdit}>
        <Edit className={classes.currentMilestoneEditIcon} />
      </div>
    )}
  </button>
);

export default withStyles(styles)(SelectMilestoneButton);
