import { useMutation } from '@apollo/client';

import {
  AcceptTermsOfServiceMutation,
  AcceptTermsOfServiceMutationVariables,
  AcceptedTermsOfServiceQuery,
  AcceptedTermsOfServiceQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';

import { acceptTermsOfServiceMutation, acceptedTermsOfServiceQuery } from './queries';

interface TermsOfServiceCheckResult {
  accepted: boolean;
  dateEffective: string;
}

export const useAcceptedTOSQuery = (): TermsOfServiceCheckResult | undefined => {
  const { loading, error, data } = useQuery<
    AcceptedTermsOfServiceQuery,
    AcceptedTermsOfServiceQueryVariables
  >(acceptedTermsOfServiceQuery, {}, MountPolicy.SKIP_ON_MOUNT);

  if (!data || loading || error) {
    return undefined;
  }
  const { accepted, dateEffective } = data.acceptedTermsOfService;
  return { accepted, dateEffective };
};

export const useAcceptTOSMutation = () =>
  useMutation<AcceptTermsOfServiceMutation, AcceptTermsOfServiceMutationVariables>(
    acceptTermsOfServiceMutation
  );
