import { useRef, useState } from 'react';
import { DragPreview, useDrag } from 'react-aria';

import { Add, DeleteOutline, MoreVert } from '@material-ui/icons';

import { ScenarioShareKey } from '../../../analytics/analyticsEventProperties';
import { YC_SCENARIO_SHARING } from '../../../features';
import { ImageDimension, LoadScenariosSummaryByMilestoneIdQuery } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import theme from '../../../theme/komodo-mui-theme';
import { BabyMenuButton, Chip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import { costFormatter } from '../common/CostSummary';
import { DeleteScenarioDialog } from '../common/DeleteScenarioDialog';
import { DROP_OP_MOVE } from '../common/DropTargetUtils';
import { getScenarioName } from '../common/ScenariosUtils';
import { SCENARIO_ID_DROP_TYPE } from '../Sandbox/DropTargetScenarios';
import SharedScenarioIcon from '../Share/SharedScenarioIcon';

import DragTile from './DragTile';

// TODO - Use the partial type from the complete query when we add ItemCount and CostImpact
// We do not want to reference the generated type here
type ScenarioColumn =
  LoadScenariosSummaryByMilestoneIdQuery['loadScenariosSummaryByMilestoneID'][0];

type Props = {
  inSandbox: boolean;
  milestoneName: string;
  scenario: ScenarioColumn;
  searchText: string;
  onAddScenario: (scenarioID: UUID) => void;
  onDeleteScenario: (scenarioID: UUID) => void;
};

enum MenuEntry {
  ADD = 'Add to Sandbox',
  DELETE = 'Delete',
}

const THUMBNAIL_SIZE = 52;

const ScenarioTile = (props: Props) => {
  const { scenario } = props;

  const preview = useRef(null);

  // Hooks
  const { dragProps, isDragging } = useDrag({
    preview,
    getItems() {
      return [{ [SCENARIO_ID_DROP_TYPE]: scenario.scenarioID }];
    },
    getAllowedDropOperations() {
      return [DROP_OP_MOVE];
    },
  });

  if (props.inSandbox) return <ScenarioTileContents {...props} selected={isDragging} />;

  return (
    <>
      <div
        {...dragProps}
        className={`rounded focus:bg-selection-selected ${
          isDragging ? 'outline-none' : 'focus:outline'
        }`}
        role="button"
        tabIndex={0}
      >
        <ScenarioTileContents {...props} selected={isDragging} />
      </div>
      <DragPreview ref={preview}>
        {() => (
          <div className="w-80">
            <ScenarioTileContents {...props} selected />
          </div>
        )}
      </DragPreview>
    </>
  );
};

const ScenarioTileContents = (props: Props & { selected?: boolean }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const scenarioDeltaTotal = props.scenario.costImpact.report?.range;
  const scenarioID = props.scenario.scenarioID;
  const hasScenarioDelta = !!scenarioDeltaTotal;
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);

  return (
    <>
      <DragTile selected={props.selected} disabled={props.inSandbox}>
        <div className="flex flex-grow items-start gap-2 overflow-hidden">
          <div
            className="flex flex-shrink-0 self-stretch overflow-hidden"
            style={{
              maxWidth: THUMBNAIL_SIZE,
            }}
          >
            <div
              className="flex w-1 flex-shrink-0 self-stretch"
              data-cy="scenario-tile-color"
              style={{
                backgroundColor: props.scenario.color ?? undefined,
              }}
            />
            {props.scenario.thumbnailAssetID && (
              <Thumbnail
                thumbnail={props.scenario.thumbnailAssetID}
                dimension={ImageDimension._92}
                padding={0}
                size={THUMBNAIL_SIZE} // kinda fussy height, based on the above field heights
              />
            )}
          </div>
          <div className="flex flex-grow flex-col overflow-hidden">
            <div className="truncate type-body2" data-cy="scenario-tile-name">
              <SearchText searchTerm={props.searchText} text={getScenarioName(props.scenario)} />
            </div>
            {hasScenarioDelta && (
              <div>Cost Impact:&nbsp;{costFormatter(scenarioDeltaTotal, true)}</div>
            )}
            <div>Items:&nbsp;{props.scenario.itemsCount}</div>
          </div>
          {props.inSandbox ? (
            <div className="flex flex-col items-end">
              <Chip text="Added" backgroundColor={theme.palette.fillGreen} />
              {isScenarioSharingFeature && (
                <div className="pt-2">
                  <SharedScenarioIcon
                    analyticsKey={ScenarioShareKey.SIDEBAR}
                    scenarioID={scenarioID}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-end gap-1">
              <BabyMenuButton
                aria-label="add scenario to sandbox"
                data-cy="scenario-tile-more-button"
                entries={[
                  {
                    id: MenuEntry.ADD,
                    label: MenuEntry.ADD,
                    onClick: () => props.onAddScenario(props.scenario.scenarioID),
                    startAdornment: <Add />,
                  },
                  {
                    id: MenuEntry.DELETE,
                    label: MenuEntry.DELETE,
                    onClick: () => setDeleteDialogOpen(true),
                    startAdornment: <DeleteOutline />,
                    type: 'destructive',
                  },
                ]}
                icon={<MoreVert />}
              />
              <SharedScenarioIcon analyticsKey={ScenarioShareKey.SIDEBAR} scenarioID={scenarioID} />
            </div>
          )}
        </div>
      </DragTile>
      <DeleteScenarioDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => props.onDeleteScenario(props.scenario.scenarioID)}
        milestoneName={props.milestoneName}
        scenarioName={props.scenario.name}
      />
    </>
  );
};

export default ScenarioTile;
