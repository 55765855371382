import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const InputsSelectReferenceCategorizationMenuItemStyles = (theme: KomodoTheme) =>
  createStyles({
    categorizationContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
    },
    categorizationDetails: {
      overflow: 'hidden',
      marginTop: '2px',
      flexGrow: 1,
    },
    filterList: {
      ...theme.typography.caption,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      direction: 'ltr',
      justifyContent: 'flex-start',
    },
    menuItem: {
      height: '24px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
      whiteSpace: 'normal',
      maxWidth: 'calc(100% - 28px)',
      width: '100%',
      ...theme.typography.h3,
      '&:hover': { backgroundColor: theme.palette.backgroundGreen },
    },
    menuItemSelected: {
      fontWeight: 500,
    },
    menuItemWrapper: {
      paddingInlineStart: 0,
    },
    icon: {
      paddingTop: 8,
    },
  });

export default InputsSelectReferenceCategorizationMenuItemStyles;
