import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import CollaboratorsListHeader from '../../Collaborators/CollaboratorsList/CollaboratorsListHeader';
import styles from '../../Team/TeamLists/TeamListStyles';

import CompaniesListItem from './CompaniesListItem';
import {
  CompaniesSortDataType,
  ProjectCompanyLocal,
  headerTitleContent,
} from './CompaniesListUtils';

type CompaniesListProps = {
  classes: Classes<typeof styles>;
  companies: ProjectCompanyLocal[];
  searchTerm: string;
  setSortData: (sortData: CompaniesSortDataType) => void;
  sortData: CompaniesSortDataType;
};

const CompaniesList: FC<CompaniesListProps> = ({
  classes,
  companies,
  searchTerm,
  setSortData,
  sortData,
}) => {
  // UI GENERATORS
  const list = companies.map((pc: ProjectCompanyLocal) => (
    <CompaniesListItem projectCompany={pc} key={pc.company.id} searchTerm={searchTerm} />
  ));

  const listHeader = (
    <>
      <div className={`${classes.cell} ${classes.image}`} />
      {Object.entries(headerTitleContent).map(([copy, title]) => (
        <CollaboratorsListHeader
          header={{ copy, title }}
          hasMultiple={companies.length > 1}
          key={copy}
          setSortData={setSortData}
          sortData={sortData}
        />
      ))}
    </>
  );

  return (
    <div>
      <div className={`${classes.companyGridRow} ${classes.containerRowHeader}`}>{listHeader}</div>
      {list}
    </div>
  );
};
export default withStyles(styles)(CompaniesList);
