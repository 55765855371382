import {
  LoadCurrentUserStatusQuery,
  LoadCurrentUserStatusQueryVariables,
} from '../generated/graphql';

import { loadCurrentUserStatusQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

function useLoadCurrentUserStatusQuery(email?: string) {
  return useQuery<LoadCurrentUserStatusQuery, LoadCurrentUserStatusQueryVariables>(
    loadCurrentUserStatusQuery,
    {
      variables: { email },
      skip: !email,
    }
  );
}

export default useLoadCurrentUserStatusQuery;
