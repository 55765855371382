import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardHeader, Divider, TextField, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  SettingsEvent,
  setEditRolePermissionsAnalytics,
  settingsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { ADMINISTRATOR_ROLE, EDIT_TEMPLATE_ROLE, TEAM, VIEW_ONLY_ROLE } from '../../../constants';
import { DD_PERMISSION_ROLES_CREATE_DELETE } from '../../../features';
import {
  PermissionDescription,
  PermissionLevel,
  PermissionResource,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { capitalizeString } from '../../../utilities/string';
import { getProjectIdFromUrl, getRoleIdFromPath } from '../../../utilities/url';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button, Select } from '../../scales';
import { getEntry } from '../../Select/JoinSelect/JoinSelect';
import OnOffSelect, { FALSE, TRUE } from '../../Select/OnOffSelect';
import RoleEditConfirm from '../RoleEditConfirm';

import useSyncPermissionsWithBackend from './PermissionsHooks';
import PermissionsRoleStyles from './PermissionsRoleStyles';
import PermissionsTable from './PermissionsTable';

type PermissionsRoleProps = {
  classes: Classes<typeof PermissionsRoleStyles>;
};

const PermissionsRole: FC<PermissionsRoleProps> = ({ classes }) => {
  // SET UP
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const roleId = getRoleIdFromPath();
  const sendAnalytics = useAnalyticsEventHook();

  const hasPermissionRolesCreateDeleteFeature = useHasFeature(DD_PERMISSION_ROLES_CREATE_DELETE);

  // PERMISSION TO PERMIT
  const { canView, canEdit } = usePermissions();
  const canViewPermissionTemplates = canView(PermissionResource.PERMISSION_TEMPLATES);
  const canEditPermissionTemplates = canEdit(PermissionResource.PERMISSION_TEMPLATES);

  // DATA FETCH
  const roles = useRolesQuery(projectId).data?.projectRoles;
  const [inputName, setInputName] = useState<string>();
  const role = useMemo(() => {
    const matchedRole = roles?.find((r: Role) => roleId === r.id);
    if (matchedRole) setInputName(matchedRole.name); // set input name whenever role changes
    return canViewPermissionTemplates ? matchedRole : undefined;
  }, [roles, canViewPermissionTemplates, roleId]);

  // STATE
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  // Mutation over-ride variables

  const [inputHasTrades, setInputHasTrades] = useState<boolean>(Boolean(role?.hasTrade));
  const [permissionMap, setPermissionMap] = useState(new Map<UUID, PermissionLevel>());

  const syncPermissionsWithBackend = useSyncPermissionsWithBackend();

  // groom the permissionMap for matches with the current backend role
  const setMap = (oldMap: Map<UUID, PermissionLevel>) => setPermissionMap(new Map(oldMap));
  useEffect(() => {
    if (!role?.permissionGroups) return;

    const permissionUpdates = Array.from(permissionMap).map(([id, level]) => ({ id, level }));
    let hasChanged = false;
    permissionUpdates.forEach(({ id, level }) => {
      const permissionMatch = role.permissionGroups
        .map((group) => group.permissions)
        .flat()
        .find((permission: Permission) => permission.id === id);

      if (!permissionMatch || permissionMatch.level === level) {
        permissionMap.delete(id);
        hasChanged = true;
      }
    });
    if (hasChanged) setMap(permissionMap);
  }, [permissionMap, role?.permissionGroups]);

  if (!role || !roles) return null;

  // LOCAL CONSTS
  const localPermissionGroups =
    (role &&
      role.permissionGroups &&
      role.permissionGroups.map((group) => {
        const permissions =
          group &&
          group.permissions.map((p: Permission) => {
            let permission = p;
            const level = permissionMap.get(permission.id);
            if (level) return { ...permission, level };

            if (
              p.resource === PermissionResource.PERMISSION_TEMPLATES &&
              p.level === PermissionLevel.ADMIN
            ) {
              if (!hasPermissionRolesCreateDeleteFeature) {
                permission = {
                  ...permission,
                  description: PermissionDescription.CAN_VIEW_EDIT_PREVIEW,
                };
              }
            }

            return permission;
          });
        return { ...group, permissions };
      })) ||
    []; // permissions;
  const hasChanges =
    inputName !== role.name || inputHasTrades !== role.hasTrade || permissionMap.size > 0;
  // TODO DD-843: Create role type to avoid need for special names
  const isSaveDisabled =
    !inputName?.trim() ||
    inputName?.trim() === ADMINISTRATOR_ROLE ||
    inputName?.trim() === VIEW_ONLY_ROLE ||
    inputName?.trim() === EDIT_TEMPLATE_ROLE;
  let disabledSaveMessage = '';
  if (isSaveDisabled) {
    disabledSaveMessage = inputName?.trim()
      ? `Role cannot be named '${inputName?.trim()}'`
      : 'Please enter a name for this role';
  }

  // the admin role is immutable
  const isEditingAdmin = role?.name === ADMINISTRATOR_ROLE;

  // ACTIONS
  const onClickEditCancel = () => {
    if (hasChanges && editable) {
      setOpen(true); // open the confirm dialog
    } else {
      setEditable(!editable);
      sendAnalytics(setEditRolePermissionsAnalytics(!editable, role.name));
    }
  };

  // NAME changes: if our local value is the same as the backend, its no longer a change.
  const onChangeName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputName(event.target.value);
  };

  // HAS TRADE
  const onChangeTrade = (newHasTrades: boolean) => {
    setInputHasTrades(newHasTrades);
  };

  // PERMISSION settings change
  // this function will handle if a setting is the same as before
  const onChangePermission = (
    id: UUID,
    { level }: { level: PermissionLevel },
    permission?: string
  ) => {
    setMap(permissionMap.set(id, level));
    sendAnalytics(
      settingsEvent(SettingsEvent.PERMISSION_CHANGE, {
        projectId,
        role: role.name,
        permission,
        level,
      })
    );
  };

  const onReset = () => {
    setInputName(role.name);
    setInputHasTrades(role.hasTrade);
    permissionMap.clear();
    setMap(permissionMap);
  };

  const onClickSave = () => {
    if (hasChanges) {
      const permissionUpdates = Array.from(permissionMap).map(([id, level]) => ({ id, level }));
      syncPermissionsWithBackend({
        roleID: role.id,
        name: inputName !== role.name ? inputName : undefined,
        hasTrade: inputHasTrades !== role.hasTrade ? inputHasTrades : undefined,
        permissionUpdates,
      });
    }
    setEditable(false);
    sendAnalytics(setEditRolePermissionsAnalytics(false, inputName));
  };

  const breadcrumb = capitalizeString(TEAM);
  const route = JoinProjectRoutes.TEAM;
  const roleRoute = JoinProjectRoutes.TEAM_ROLES;

  // COMPONENTS
  const cardTitle = (
    <div data-cy="div-rolePermissionHeader" className={classes.cardTitle}>
      <Breadcrumbs
        links={[
          {
            display: breadcrumb,
            destination: generateSharedPath(route, { projectId }),
            tooltip: `Back to ${breadcrumb}`,
          },
          {
            display: 'Roles',
            destination: generateSharedPath(roleRoute, { projectId }),
            tooltip: 'Back to Roles',
          },
        ]}
      />
      {`${inputName} Role Permissions`}
    </div>
  );

  const cardActions = (
    <div className="flex gap-2 pr-4">
      {role && canViewPermissionTemplates && !editable && (
        <div className="w-75">
          <Select
            data-cy="add-collaborators-select-role"
            entries={roles.map((r) => ({ id: r.id, label: r.name }))}
            onChange={(value) =>
              navigate(
                generateSharedPath(JoinProjectRoutes.TEAM_ROLE_PERMISSIONS, {
                  projectId,
                  roleId: value,
                })
              )
            }
            placeholder="Select role"
            value={role.id}
          />
        </div>
      )}
      {editable && (
        <Button
          data-cy="button-role-save"
          disabled={isSaveDisabled}
          label="Save"
          onClick={onClickSave}
          type="primary"
        />
      )}
      {canEditPermissionTemplates && (
        <span title={isEditingAdmin ? 'Administrator role is not editable' : undefined}>
          <Button
            data-cy="button-role-edit"
            disabled={isEditingAdmin}
            label={editable ? 'Cancel' : 'Edit Role'}
            onClick={onClickEditCancel}
            type={editable ? 'secondary' : 'primary'}
          />
        </span>
      )}
    </div>
  );
  const tradesTooltip =
    'Do you need certain teammates in a specific Role to only see information in a category like Sitework or a set of categories like Electrical and Plumbing? Set this to "yes" to control different levels of permissions depending on whether someone has access to some specific categories. Set this to "no" if you want all users in this role to have the same level of permissions for items and information in all categories.';

  return (
    <div className={classes.root}>
      <Card square className={classes.card} elevation={0}>
        <CardHeader
          classes={{ root: classes.cardRoot }}
          title={cardTitle}
          subheader="All teammates with this role will inherit the permissions below"
          action={cardActions}
        />
        <Divider />
        <div className={classes.options}>
          <div className={classes.horizontal}>
            <div className={classes.roleNameBlock}>
              <div className={classes.middleAlign}>
                <Typography variant="caption">Role Name</Typography>
              </div>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  style: {
                    height: 33,
                  },
                  endAdornment: isSaveDisabled && !isEditingAdmin && (
                    <ErrorTooltip title={disabledSaveMessage}>
                      <ErrorOutlineIcon className={classes.errorIcon} />
                    </ErrorTooltip>
                  ),
                }}
                className={classes.field}
                onChange={onChangeName}
                disabled={!editable}
                value={inputName}
                placeholder=""
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                onKeyDown={(event: any) => {
                  if (event.key !== 'Enter') return;
                  event.target.blur();
                }}
              />
            </div>
            <div>
              <div className={classes.middleAlign}>
                <Typography variant="caption">Limit Access by Category</Typography>
                <NormalTooltip title={tradesTooltip}>
                  <Help className={classes.info} />
                </NormalTooltip>
              </div>
              <OnOffSelect
                entries={[getEntry(FALSE, 'No'), getEntry(TRUE, 'Yes')]}
                value={inputHasTrades}
                onChange={onChangeTrade}
                disabled={!editable}
              />
            </div>
          </div>
        </div>
        {localPermissionGroups
          .filter((group) => group.permissions.length > 0)
          .map((group) => (
            <PermissionsTable
              editable={editable}
              group={group as PermissionGroup}
              hasTrade={inputHasTrades}
              key={group.type}
              onChange={onChangePermission}
            />
          ))}
      </Card>
      <RoleEditConfirm
        name={inputName}
        open={open}
        setConfirmModalOpen={(isOpen: boolean) => setOpen(isOpen)}
        setEditable={(edit: boolean) => setEditable(edit)}
        onReset={onReset}
      />
    </div>
  );
};

export default withStyles(PermissionsRoleStyles)(PermissionsRole);
