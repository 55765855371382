import { FC, useState } from 'react';

import { Button, Card, CardHeader } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import { getCurrencySymbol } from '../../utilities/currency';
import DialogUnits from '../Dialogs/DialogsUnits/DialogsUnits';

import styles from './ProjectUnitsStyles';

type UnitsProps = {
  classes: Classes<typeof styles>;
};

const ProjectUnits: FC<UnitsProps> = ({ classes }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const currencySymbol = getCurrencySymbol();

  return (
    <>
      <Card elevation={0} className={classes.card} square>
        <CardHeader
          classes={{
            action: classes.action,
          }}
          id="units" // anchor
          title="Units of Measure"
          subheader={`Choose which units of measure to track and use to display unit costs (e.g. ${currencySymbol}/GSF, ${currencySymbol}/key)`}
          action={
            <Button
              data-cy="button-manageUoM"
              className={classes.button}
              onClick={() => setOpen(true)}
            >
              Manage Units of Measure
            </Button>
          }
        >
          Categorizations
        </CardHeader>
      </Card>
      {isOpen && <DialogUnits open={isOpen} onClose={() => setOpen(false)} />}
    </>
  );
};

export default withStyles(styles)(ProjectUnits);
