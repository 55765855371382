import { MenuItem, Typography } from '@material-ui/core';

import { CLEAR_FILTERS } from '../../../constants';

import InputsSelectReferenceCategoryFilters from './InputsSelectReferenceCategoryFilters';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

export const generateCategoryFiltersMenuItems = (
  classes: Classes<typeof InputsSelectReferenceStyles>,
  categorizations: Categorization[],
  selectedCategorizationFilters: Category[],
  setSelectedCategorizationFilters: (value: Category[]) => void,
  toggleFilter: (value: Category) => void,
  isFixedMarkup: boolean,
  hasFilters: boolean
) => {
  return [
    <MenuItem
      className={`${classes.selectHeader} ${classes.dividerTop} ${classes.dividerBottom}`}
      value={CLEAR_FILTERS}
      key={CLEAR_FILTERS}
    >
      <div className={classes.selectHeaderOverflow}>{`Filter categories${
        isFixedMarkup ? '' : ' for S1' // for percent markups, specify that filters only apply to direct costs
      }:`}</div>
      {hasFilters && (
        <Typography
          variant="body2"
          className={classes.clearOrSelectAll}
          onClick={() => {
            setSelectedCategorizationFilters([]);
          }}
        >
          Clear
        </Typography>
      )}
    </MenuItem>,
    categorizations.map((categorization) => (
      <InputsSelectReferenceCategoryFilters
        key={categorization.id}
        categorization={categorization}
        selectedFilters={selectedCategorizationFilters}
        toggleCategory={toggleFilter}
        setSelectedFilters={setSelectedCategorizationFilters}
      />
    )),
  ];
};
