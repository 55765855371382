import { useMutation } from '@apollo/client';

import { REFETCH_IN_APP_NOTIFICATIONS } from '../../../api/refetchSets';
import {
  SetAllInAppNotificationsReadMutation,
  SetAllInAppNotificationsReadMutationVariables,
} from '../../../generated/graphql';

import { setAllInAppNotificationsReadMutation } from './queries';

const useSetAllInAppNotificationsReadMutation = (
  onSuccess?: (setAllInAppNotificationsRead: UUID[]) => void,
  onFailure?: () => void
) => {
  const [setAllInAppNotificationsReadFunc] = useMutation<
    SetAllInAppNotificationsReadMutation,
    SetAllInAppNotificationsReadMutationVariables
  >(setAllInAppNotificationsReadMutation);

  const submitFunc = (projectID: UUID | null, allProjects: boolean) => {
    setAllInAppNotificationsReadFunc({
      variables: { projectID, allProjects },
      refetchQueries: REFETCH_IN_APP_NOTIFICATIONS,
      update: (cache, { data }) => {
        const IDs = data?.setAllInAppNotificationsRead;
        if (!IDs || !IDs.length) return;
        IDs.forEach((id) =>
          cache.modify({
            id: `InAppNotification:${id}`,
            fields: {
              readAt(readAt) {
                if (readAt !== null) return readAt;
                return Date.now();
              },
            },
          })
        );
      },
    })
      .then((result) => {
        const setAllInAppNotificationsRead = result.data?.setAllInAppNotificationsRead;
        if (onSuccess && setAllInAppNotificationsRead) onSuccess(setAllInAppNotificationsRead);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
  return submitFunc;
};

export default useSetAllInAppNotificationsReadMutation;
