import { ComponentProps, useCallback, useState } from 'react';

import { ScenarioItemCreationInput } from '../../../../generated/graphql';
import { isTextValid } from '../../../../utilities/string';
import DialogsNewItemContent from '../../../Dialogs/DialogsNewItem/DialogsNewItemContent';
import {
  filterCategoriesForSubmit,
  formatCategoriesForItemCreation,
  getGenerateNewItemFromParent,
} from '../../../Dialogs/DialogsNewItem/DialogsNewItemUtils';
import useMemoWrapper from '../../../useMemoWrapper';

type Props = {
  milestoneID: UUID;
  onCreateScenarioItem: (itemInput: ScenarioItemCreationInput) => void;
  open: boolean;
  scenarioProps: ComponentProps<typeof DialogsNewItemContent>['scenarioProps'];
  setOpen: (open: boolean) => void;
  onCloseNoChange: () => void;
};

const DialogsNewScenarioItem = (props: Props) => {
  // State
  const generateNewItemFromParent = getGenerateNewItemFromParent(props.milestoneID);
  const [item, setItem] = useState<DraftItem>(generateNewItemFromParent);

  // Functions
  const { onCreateScenarioItem, setOpen, onCloseNoChange } = props;

  const onSubmit = useCallback(
    (onClose?: () => void) => {
      const itemInput: ScenarioItemCreationInput = {
        categories: formatCategoriesForItemCreation(
          filterCategoriesForSubmit(item.categories) ?? []
        ),
        costImpact: item.costImpact,
        description: item.description,
        descriptionStyled: item.descriptionStyled,
        dueDate: item.dueDate,
        name: item.name || '',
      };
      onCreateScenarioItem(itemInput);
      setItem(generateNewItemFromParent());
      if (onClose) onClose();
    },
    [
      generateNewItemFromParent,
      item.categories,
      item.costImpact,
      item.description,
      item.descriptionStyled,
      item.dueDate,
      item.name,
      onCreateScenarioItem,
    ]
  );

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const isValidName = useMemoWrapper(isTextValid, item.name);

  return (
    <DialogsNewItemContent
      item={item}
      isValidName={isValidName}
      onClose={onClose}
      onCloseNoChange={onCloseNoChange}
      open={props.open}
      onSubmit={onSubmit}
      scenarioProps={props.scenarioProps}
      setItem={setItem}
    />
  );
};

export default DialogsNewScenarioItem;
