import { useCallback } from 'react';

import { navAnalyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

export default function useSendNavAnalytics() {
  const sendAnalytics = useSendAnalyticsEventHook();

  return useCallback(
    (
      type: Parameters<typeof navAnalyticsEvent>[0],
      eventProperties?: Parameters<typeof navAnalyticsEvent>[1]
    ) => {
      sendAnalytics(navAnalyticsEvent(type, eventProperties));
    },
    [sendAnalytics]
  );
}
