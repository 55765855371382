export default [
  {
    id: 'c2595198-0f91-4098-82e4-8a82f3d48f14',
    title: 'Alternate Flooring Options for Terrazzo',
    suggestionReason: 'Suggested because 09 - Finishes is 37% over budget',
    suggestionsInsights: ['3 options'],
    baselineCost: 12815100,
    categories: ['09 - Finishes'],
    options: [
      {
        id: '5faa2375-8adb-4079-86b9-fb5380a46360',
        name: 'Polished Concrete',
        images: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/polished_concrete_1.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/polished_concrete_2.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/polished_concrete_3.jpeg',
          },
        ],
        attachments: [],
        description: `<strong>55-90% cheaper than Terrazzo on average</strong>
        <p></p>
        <p>Polished Concrete is a method of processing concrete floors to achieve a hardwearing, reflective surface. This process involves mechanical grinding and polishing with the application of penetrating hardeners. Polished concrete can vary in finish, from satin to high-gloss, depending on the level of polishing.</p>
        `,
        pros: [
          '<strong>Cost-Effective:</strong> Polished concrete tends to be more affordable than terrazzo, both in terms of installation and long-term maintenance.',
          '<strong>Durability:</strong> It is extremely durable and capable of withstanding heavy foot traffic, making it suitable for commercial spaces.',
          '<strong>Ease of Maintenance:</strong> Requires minimal upkeep beyond regular cleaning and occasional re-polishing.',
        ],
        cons: [
          '<strong>Aesthetic Limitations:</strong> While polished concrete has a modern appeal, it lacks the color and pattern variety that terrazzo offers.',
          '<strong>Comfort:</strong> Concrete is harder underfoot, which might not be ideal for all commercial applications, particularly those requiring standing for prolonged periods.',
          '<strong>Cracking Over Time:</strong> Concrete can develop cracks over time, which can affect the aesthetic and require filling or maintenance.',
        ],
        optionInsights: [],
      },
      {
        id: 'a31fcab5-20b0-4d3d-b640-5fe668956d83',
        name: 'LVT',
        images: [
          { url: 'https://static-assets.join.build/images/item-suggestions-demo/lvt_1.jpeg' },
          { url: 'https://static-assets.join.build/images/item-suggestions-demo/lvt_2.jpeg' },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/lvt_3.jpeg',
          },
        ],
        attachments: [],
        description: `<strong>65-80% cheaper than Terrazzo on average</strong>
        <p></p>
        <p>Luxury Vinyl Tile (LVT) is a high-end vinyl flooring product that simulates the appearance of natural materials, such as wood or stone, through realistic photographic layers encapsulated by a clear protective layer. LVT is known for its durability, comfort underfoot, and ease of maintenance. It's particularly appealing in situations where budget, installation time, and ease of maintenance are key considerations</p>
        `,
        pros: [
          '<strong>Cost-Effective:</strong> LVT generally offers a lower upfront cost compared to terrazzo, making it a budget-friendly alternative.',
          '<strong>Ease of Installation:</strong> It is easier and faster to install LVT than Terrazzo',
          '<strong>Design Flexibility:</strong> LVT comes in a wide range of designs, patterns, and textures, closely mimicking natural materials like hardwood and stone.',
          "<strong>Low Maintenance:</strong> Requires less upkeep than terrazzo, with no need for polishing; it's easily cleaned with regular sweeping and mopping.",
          '<strong>Comfort and Warmth:</strong> LVT is softer underfoot and can provide a warmer surface, enhancing comfort in residential or commercial settings.',
        ],
        cons: [
          '<strong>Less Durable Than Terrazzo:</strong> While LVT is durable, it may not withstand heavy foot traffic as well as terrazzo over many years.',
          "<strong>Not as Unique or Prestigious:</strong> LVT, while beautiful, doesn't offer the same uniqueness or sense of prestige associated with a custom terrazzo floor.",
          '<strong>Potential for Damage:</strong> LVT can be punctured or scratched by sharp or heavy objects, requiring replacement of the damaged tiles.',
        ],
        optionInsights: [],
      },
      {
        id: '62f488ab-f07f-40ce-84db-e066b28c4261',
        name: 'Carpet',
        images: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/carpet_1.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/carpet_2.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/carpet_3.jpeg',
          },
        ],
        attachments: [],
        description: `<strong>60-85% cheaper than Terrazzo on average</strong>
        <p></p>  
        <p>Choosing carpet over terrazzo can offer immediate benefits in terms of cost, comfort, and noise reduction, making it an attractive option for many commercial and residential applications. However, it's important to consider the long-term maintenance requirements and the lifespan of carpeting compared to the more durable and permanent solution that terrazzo provides.</p>
        `,
        pros: [
          '<strong>Cost-Effective:</strong> Generally, carpet has a lower upfront cost compared to terrazzo and can be installed more quickly.',
          '<strong>Comfort:</strong> Carpet is softer and warmer underfoot, which can enhance the comfort of a space, particularly in colder climates.',
          '<strong>Sound Absorption:</strong> Offers superior sound-dampening qualities, reducing echo and noise transmission between floors.',
          '<strong>Design Versatility:</strong> Available in a wide range of colors, patterns, and textures, carpet can complement a variety of interior design themes.',
          '<strong>Improved Air Quality:</strong> Modern carpets can trap allergens and dust, which are then removed through vacuuming, potentially improving indoor air quality.',
        ],
        cons: [
          '<strong>Maintenance:</strong> Requires more maintenance than terrazzo, including regular vacuuming and professional cleaning to remove stains and allergens.',
          "<strong>Durability:</strong> While high-quality carpets can last many years, they typically don't have the lifespan of terrazzo floors and can show signs of wear in high-traffic areas.",
          '<strong>Moisture Sensitivity:</strong> Carpets can develop mold and mildew if exposed to moisture, making them less suitable for areas like bathrooms or kitchens.',
          '<strong>Aesthetic Longevity:</strong> Carpet might not have the timeless appeal of terrazzo and may need to be replaced to update the look of a space.',
        ],
        optionInsights: [],
      },
    ],
    baselineEstimateLines: [
      {
        lineNumber: 'L5', // Should probably reference the line ID directly when doing this for real.
        description: 'Terrazzo base',
        quantity: 1473,
        unit: 'SF',
        unitPrice: 15,
        total: 22095,
      },
      {
        lineNumber: 'L6', // Should probably reference the line ID directly when doing this for real.
        description: 'Poured Terrazzo',
        quantity: 1473,
        unit: 'SF',
        unitPrice: 72,
        total: 106056,
      },
    ],
  },
  {
    id: '122e91f6-54f3-4eab-8099-d2b1314a6003',
    title: 'Evaluate Aluminum feeders instead of Copper',
    suggestionReason: 'Common cost saving measure',
    suggestionsInsights: ['Used on previous projects'],
    baselineCost: 10764900,
    categories: ['26 - Electrical'],
    options: [
      {
        id: 'af989f7c-0e01-4485-9b71-1a2de5717f23',
        name: 'Aluminum Feeders',
        images: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/aluminum_1.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/aluminum_2.jpeg',
          },
        ],
        attachments: [],
        description: `<p>The shift from copper to aluminum for feeders and electrical wiring in commercial construction projects represents an innovative step towards cost-effectiveness and sustainability. Aluminum, once overlooked due to early technical challenges, has seen significant advancements in technology that enhance its performance and safety. This transition not only promises economic benefits but also aligns with global sustainability goals by offering a material that is abundant, recyclable, and requires less energy for production compared to copper.</p>
        <p></p><p></p>
        <strong>Similar items used on 2 previous projects</strong>
        <ul><li><p>Northampton School Science Academy: <a href="https://app.join.build/c3426034-8c2f-441f-a79a-88d2111b9cba/items/03c4badb-37ce-4139-93dc-755d5887bd90">Aluminum wiring in lieu of copper for main feed into building</a></p>
        </li><li><p>Springs Health Clinic: <a href="https://app.join.build/e7ee87d5-f338-4340-8ce9-08805bfe7c86/items/d03ac093-9f55-4b58-9bb2-c0cd30dab7ae">Aluminum Feeders ILO Copper</a></p>`,
        pros: [
          '<strong>Cost-Effectiveness:</strong> Aluminum is significantly cheaper than copper, making it a cost-efficient option for large-scale projects where the budget is a primary concern.',
          '<strong>Lightweight:</strong> Aluminum wiring is lighter than copper, which reduces the overall weight of the electrical system and eases the installation process.',
          "<strong>Electrical Conductivity:</strong> While copper has higher conductivity, aluminum's conductivity is approximately 61% that of copper, making it sufficient for many applications when properly installed and maintained.",
        ],
        cons: [
          '<strong>Expansion and Contraction:</strong> Aluminum expands and contracts more than copper when exposed to temperature fluctuations, which can lead to loose connections if not correctly managed.',
          '<strong>Oxidation:</strong> Aluminum wires can oxidize more easily than copper, potentially leading to resistance at connections. However, this can be mitigated with the use of appropriate connectors and techniques.',
          '<strong>Special Handling Requirements:</strong> Aluminum wiring requires specific connectors, switches, and outlets designed for aluminum, as well as expert installation to ensure safety and performance.',
        ],
        optionInsights: [],
      },
    ],
    baselineEstimateLines: [
      {
        lineNumber: 'L5', // Should probably reference the line ID directly when doing this for real.
        description: 'Copper Feeders',
        quantity: 11961,
        unit: 'LF',
        unitPrice: 9,
        total: 107649,
        optionInsights: [],
      },
    ],
  },
  {
    id: 'f2c1c11f-f1f2-446a-8418-b8d6c0f77162',
    title: 'Evaluate Orange Outlets for EV Chargers',
    suggestionReason: 'Significantly lower installation cost',
    suggestionsInsights: [],
    baselineCost: 3795500,
    categories: ['26 - Electrical'],
    options: [
      {
        id: 'd5351f68-e92d-4bd3-bd58-4138ab58c207',
        name: 'Orange Outlets',
        images: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/orange_outlet_1.png',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/orange_outlet_2.jpeg',
          },
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/orange_outlet_3.png',
          },
        ],
        attachments: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/Orange_Outlet_Datasheet.pdf',
            label: 'Orange Outlet Datasheet',
          },
        ],
        description:
          '<a href="https://www.orangecharger.com/oro-products/orange-outlet">Orange Outlets</a> provide a cordless alternative to Level 2 EV Chargers that can be significantly cheaper to install and operate while meeting all regulatory requirements and being easy to manage and operate. The manufacturer states that total installation costs vary between $900-$1500 per outlet.',
        pros: [
          'Can be up to 80% cheaper to install than other level 2 networked EV chargers.',
          'Lower Maintenance costs. Removing the cable removes many of the typical maintenance requirements.',
        ],
        cons: ['Requires users to use their own charging cables.'],
        optionInsights: [],
      },
    ],
    baselineEstimateLines: [
      {
        lineNumber: 'L21',
        description: 'Level 2 EV Chargers',
        quantity: 5,
        unit: 'EA',
        unitPrice: 7591,
        total: 37955,
        optionInsights: [],
      },
    ],
  },
  {
    id: '37734c9b-d11c-42df-8ab6-f59191b59a68',
    title: 'Evaluate reducing tile coverage on bathroom walls',
    suggestionReason: 'Suggested because 09-30 Tile is 25% over budget.',
    suggestionsInsights: ['Easy change'],
    baselineCost: 6954000,
    categories: ['09-30 - Tile'],
    options: [
      {
        id: 'd436ceb5-fe52-4fc7-b57b-bef50f201a5f',
        name: 'Reduce Tile coverage to wet walls only',
        images: [
          {
            url: 'https://static-assets.join.build/images/item-suggestions-demo/tile_coverage_1.jpeg',
          },
        ],
        attachments: [],
        description:
          'Often tile will be specified for all walls in a bathroom when it is only necessary for the wet walls, i.e. walls that are directly impacted by water from showers, baths, or sinks. Reducing tile coverage from these walls can be a significant cost savings. This reduction can be done by wainscotting or by removing tile from some walls entirely.',
        pros: [
          '<strong>Cost savings:</strong> Painting non wet walls can be significantly cheaper on a per square foot basis.',
          '<strong>Ease of Maintenance:</strong> Tiled surfaces require more upkeep than painted walls due to grout lines where mold and mildew can accumulate',
        ],
        cons: [
          '<strong>Potential for Water Damage:</strong> Areas not covered by tiles might be more susceptible to water damage, especially in bathrooms with poor ventilation or where water splashes beyond the wet areas. This can lead to peeling paint, damp walls, and the growth of mold and mildew.',
          '<strong>Long-Term Maintenance and Upkeep:</strong> While initial maintenance may be reduced, long-term upkeep might increase if non-tiled walls deteriorate faster due to humidity and water exposure. This could necessitate more frequent painting or repairs compared to fully tiled bathrooms.',
        ],
        optionInsights: [],
      },
    ],
    baselineEstimateLines: [
      {
        lineNumber: 'L58',
        description: 'Ceramic Tile',
        quantity: 5795,
        unit: 'SF',
        unitPrice: 12,
        total: 69540,
        optionInsights: [],
      },
    ],
  },
];
