import { gql } from '../../../../../api/graphqlFragments';

export const forecastingItems = gql`
  query forecastingItems(
    $filters: ForecastingExploreItemsFilters!
    $projectsFilters: ForecastingProjectsFilters!
    $search: String!
    $sortBy: ForecastingExploreItemsSortBy!
    $pagination: Pagination!
  ) {
    forecastingItems(
      filters: $filters
      projectsFilters: $projectsFilters
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      aggregations {
        name
        values {
          name
          count
        }
      }
      data {
        categories {
          id
          name
          number
          categorization {
            id
            name
          }
        }
        cost {
          min
          max
        }
        markupsCost {
          min
          max
        }
        createdBy {
          name
        }
        estimateLines {
          description
          lineID
          quantity
          total
          unit
          unitPrice
        }
        hasAccess
        id
        milestoneDate
        name
        number
        numOptions
        parentID
        parentName
        project {
          budget
          estimate
          id
          location
          milestoneDate
          milestoneID
          milestoneName
          name
          runningTotal
          status
          thumbnail
          type
          updatedAt
          updatedBy
          hasAccess
        }
        status
        type
        updatedAt
      }
      total
    }
  }
`;
