import { FC, useState } from 'react';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { MetricsInput, UnitCount } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { StrictUnion } from '../../../utilities/types';
import Pin from '../../Icons/Pin';
import Settings from '../../Icons/Settings';
import IconMenuIcon from '../../Select/SelectMenu/IconMenuIcon';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import { ProjectCompSectionVariant } from '../constants/projectCompTypes';
import MetricsSelectionDialog from '../DialogsProjectComps/MetricsSelectionDialog/MetricsSelectionDialog';
import ProjectCompInformationField from '../ProjectCompInformationField';
import ProjectCompsInputsQuantity from '../ProjectCompsInputs/ProjectCompsInputsQuantity';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { getSelectedUnitLabel, useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import SidebarLabel from '../SidebarLabel';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { METRICS_LABEL } from './ProjectCompsSectionLabels';

const formatCommasLocal = (value: string) => (value ? formatCommas(value) : '');

type CommonProjectCompSectionMetricsProps = {
  classes: Classes<typeof styles>;
};

type ProjectCompSidebarSectionMetricsProps = CommonProjectCompSectionMetricsProps & {
  hasError: boolean;
  selectedUnits: Unit[];
  unit: Unit;
  unitCounts: UnitCount[];
  units: Unit[];
  variant: ProjectCompSectionVariant.SIDEBAR;
};

// The same props are used in average column and project comp column
type ProjectCompCompSectionMetricsProps = CommonProjectCompSectionMetricsProps & {
  metrics: MetricsInput[];
  selectedUnits: Unit[];
  setMetrics: (unitID: UUID, quantityMagnitude: string | null) => void;
  unit: Unit;
  variant: ProjectCompSectionVariant.PROJECT_COMP | ProjectCompSectionVariant.AVERAGE_COMP;
};

type Props = StrictUnion<
  ProjectCompSidebarSectionMetricsProps | ProjectCompCompSectionMetricsProps
>;

const ProjectCompSectionMetrics: FC<Props> = (props: Props) => {
  const sendAnalytics = useAnalyticsEventHook();
  const { setPinnedUnitID, setSelectedUnitIDs } = useProjectCompsSetInputUpdateFunctions();

  const [openMetricsSettingsDialog, setOpenMetricsSettingsDialog] = useState(false);

  const isLabels = props.variant === ProjectCompSectionVariant.SIDEBAR;

  // Renders unit labels
  const unitLabelsSidebar = () => {
    return (
      isLabels &&
      props.selectedUnits.map((u: Unit) => {
        const setPinnedUnit = () => {
          setPinnedUnitID(u.id);
          sendAnalytics(
            projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_DISPLAY_SETTINGS_UNIT, {
              selectedUnit: props.unit.name,
            })
          );
        };

        return (
          <SidebarLabel
            dataCy={`unit${u.abbreviationPlural}-quantityText`}
            endAdornment={
              <IconMenuIcon
                cy={`${METRICS_LABEL}UnitPin-Label`}
                icon={<Pin className={props.classes.pinMargin} />}
                iconButtonClassName={props.classes.mediumIcon}
                handleClick={setPinnedUnit}
                variant={SelectVariants.UNSTYLED}
              />
            }
            key={u.id}
            showEndAdornment={props.unit.id === u.id ? true : undefined}
          >
            {u.name} ({u.abbreviationSingular})
          </SidebarLabel>
        );
      })
    );
  };

  // Renders metrics rows
  const metricsRows = () => {
    return (
      (props.variant === ProjectCompSectionVariant.PROJECT_COMP ||
        props.variant === ProjectCompSectionVariant.AVERAGE_COMP) &&
      props.metrics.map((m: MetricsInput, i: number) => {
        const currentUnit = props.selectedUnits[i];
        if (m.hasMilestoneQuantity) {
          const displayValue = formatCommasLocal(m?.quantityMagnitude ?? '');

          return (
            <ProjectCompInformationField
              className={props.classes.field}
              dataCy={`unit${currentUnit.abbreviationPlural}-quantityText`}
              endAdornment={
                <div
                  className={`${props.classes.unitAbbreviationText} ${props.classes.titleHeading} `}
                >
                  {currentUnit.abbreviationPlural}
                </div>
              }
              key={m.unitID}
              showEndAdornment
              value={displayValue}
            />
          );
        }

        return (
          <ProjectCompsInputsQuantity
            isPinnedUnit={props.unit.id === currentUnit?.id}
            key={m.unitID}
            quantity={m.quantityMagnitude ?? ''}
            setMetrics={props.setMetrics}
            unit={currentUnit}
          />
        );
      })
    );
  };

  return (
    <div className={props.classes.fieldsContainer}>
      {isLabels && openMetricsSettingsDialog && (
        <MetricsSelectionDialog
          onClose={() => setOpenMetricsSettingsDialog(false)}
          onNext={setSelectedUnitIDs}
          onOpen={() => setOpenMetricsSettingsDialog(true)}
          open={openMetricsSettingsDialog}
          selectedUnits={props.selectedUnits}
          unitCounts={props.unitCounts}
          units={props.units}
        />
      )}

      <ProjectCompSectionHeader
        actionButton={
          isLabels ? (
            <IconMenuIcon
              cy={`${METRICS_LABEL}Settings-CollapseSection`}
              icon={<Settings />}
              iconButtonClassName={props.classes.actionButton}
              handleClick={() => setOpenMetricsSettingsDialog(true)}
              variant={SelectVariants.UNSTYLED}
            />
          ) : undefined
        }
        collapseContentSummary={getSelectedUnitLabel(props.unit, props.metrics)}
        collapseSection={ProjectCompSectionType.SECTION_METRICS}
        isHighlightable={isLabels}
        isTogglable={isLabels}
        label={METRICS_LABEL}
        showErrorIcon={props.hasError}
      />

      {!useIsProjectCompSectionCollapsed(ProjectCompSectionType.SECTION_METRICS) && (
        <div
          className={`${props.classes.fields} ${
            isLabels ? props.classes.labelFields : props.classes.fieldsGroup
          }`}
        >
          {isLabels ? unitLabelsSidebar() : metricsRows()}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionMetrics);
