import { useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import { LocationOnOutlined } from '@material-ui/icons';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { ImageDimension, LocationInput } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import NormalTooltipInfoIcon from '../../NormalTooltip/NormalTooltipInfoIcon';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';

import styles from './EditLocationDialogStyles';
import { useCityCostIndicesQuery } from './hooks/useCityCostIndicesQuery';
import { useLocationEscalationQuery } from './hooks/useLocationEscalationQuery';
import Warning from './Warning';

const EditLocationSourceDialog = (props: {
  classes: Classes<typeof styles>;
  isFuzzyMatch: boolean;
  locationFrom?: LocationInput;
  locationTo: LocationInput;
  onApply: (location: LocationInput) => void;
  onClose: () => void;
  project: Pick<ProjectProps, 'location' | 'name' | 'thumbnail'>;
}) => {
  const { classes } = props;

  const sendAnalytics = useSendAnalyticsEventHook();

  // Fetch all the City Cost Indices
  const { data: cityCostIndicesData } = useCityCostIndicesQuery();
  const cityCostIndices = useMemo(() => {
    return (
      cityCostIndicesData?.cityCostIndices?.cityCostIndices?.map((cci) => ({
        ...cci,
        id: cci.name,
        nameOption: `${cci.index}`,
      })) ?? []
    );
  }, [cityCostIndicesData]);

  // Locally store the currently-selected location.
  const [location, setLocation] = useState<LocationInput | undefined>(props.locationFrom);

  const { data: { locationEscalation } = {} } = useLocationEscalationQuery({
    variables: {
      from: location!, // Will be defined if `skip` is false
      to: props.locationTo,
    },
    skip: !location?.name,
  });

  const percentage = locationEscalation?.percentage;
  const fromIndex = locationEscalation?.from.index;
  let escalateTo = '...';
  if (locationEscalation?.to) {
    escalateTo = locationEscalation.to.name;
  }

  return (
    <JoinDialog
      dialog={{
        actionButtons: [
          {
            color: 'secondary',
            dataCy: 'cancel-btn',
            onClick: props.onClose,
            text: 'Cancel',
            variant: 'flat',
          },
          {
            color: 'primary',
            dataCy: 'apply-btn',
            disabled: !location || percentage === undefined,
            onClick: () => {
              if (location) {
                props.onApply(location);
              }
            },
            text: 'Apply',
            variant: 'contained',
          },
        ],
        contentComponent: (
          <div className={classes.container}>
            {props.isFuzzyMatch ? (
              <Warning dataCy="fuzzy-location-warning">
                <div>
                  No exact match found in RSMeans Index for project location. Closest city selected.
                  To change, select relevant city from the drop down below.
                </div>
              </Warning>
            ) : null}
            <div className={classes.locationTo}>
              All projects escalating to:{' '}
              <strong data-cy="autoescalation-escalating-to-loc">{escalateTo}</strong>
            </div>
            {props.project && (
              <ProjectInfo
                locationName={props.project.location}
                name={props.project.name}
                thumbnail={props.project.thumbnail}
              />
            )}
            <div className={classes.locationDetails}>
              <div className={classes.locationDetails_sourceLocation}>
                <Typography variant="caption">Project location index</Typography>
                <JoinSelect
                  classNameSelectText={classes.locationSelect_selectedValue}
                  cySelect="location-select"
                  entries={cityCostIndices}
                  menuProps={{
                    PaperProps: { className: classes.locationSelect_paper },
                  }}
                  onChange={(id: string) => {
                    const newLocation = cityCostIndices.find((cci) => cci.id === id);
                    if (!newLocation) return;
                    setLocation(newLocation);
                    sendAnalytics(
                      projectCompsAnalyticsEvent(
                        projectCompsEventTypes.PROJECT_COMPS_ESCALATION_CHANGE_SOURCE_LOCATION,
                        {
                          locationPrev: props.locationFrom?.name,
                          locationNew: newLocation.name,
                        }
                      )
                    );
                  }}
                  search
                  value={location?.name || 'None'}
                />
              </div>
              <div>
                <div className={classes.locationFactor}>
                  <Typography variant="caption">RSMeans factor</Typography>
                  <NormalTooltipInfoIcon title="Costs are updated by applying Gordian’s RSMeans City Cost Index to the original project cost." />
                </div>
                <div className={classes.indexInfo} data-cy="escalation-factor">
                  {fromIndex}
                </div>
              </div>
              <div>
                <Typography variant="caption">% escalation</Typography>
                <div className={classes.indexInfo} data-cy="escalation-percentage">
                  {location && percentage !== undefined
                    ? `${formatCommas(String(percentage))}%`
                    : '--'}
                </div>
              </div>
            </div>
            {locationEscalation && percentage === undefined ? (
              <div className={classes.error}>Failed to fetch escalation for this location.</div>
            ) : null}
          </div>
        ),
        headerText: 'Edit location factor for this project',
        onClose: props.onClose,
        open: true,
      }}
      dynamicHeight
    />
  );
};

const ProjectInfo = withStyles(styles)(
  (props: {
    classes: Classes<typeof styles>;
    locationName: string;
    name: string;
    thumbnail: string;
  }) => (
    <div className={props.classes.projectInfo} data-cy="project-info">
      <div>
        <div>{props.name}</div>
        <div className={props.classes.projectInfo_pickerField}>
          <div>
            <LocationOnOutlined />
          </div>
          <div>{props.locationName || 'Project location unknown'}</div>
        </div>
      </div>
      <Thumbnail dimension={ImageDimension._50} padding={0} thumbnail={props.thumbnail} />
    </div>
  )
);

export default withStyles(styles)(EditLocationSourceDialog);
