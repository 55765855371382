import { useState } from 'react';

import { copyItemEventTypes } from '../../../analytics/analyticsEventProperties';
import CopyItemDialogData from '../../CopyItem/CopyItemDialog/CopyItemDialogData';
import useSendCopyItemAnalytics from '../../CopyItem/hooks/useSendCopyItemAnalytics';
import { Button } from '../../scales';

type Props = {
  item: ItemLike;
};

const ItemSidebarFooter = (props: Props) => {
  const sendAnalytics = useSendCopyItemAnalytics();
  const [openDialogCopyItem, setOpenDialogCopyItem] = useState(false);

  return (
    <>
      <Button
        label="Copy item"
        type="primary"
        fullWidth
        onClick={() => {
          sendAnalytics(copyItemEventTypes.OPEN_DIALOG_CTA, { location: 'sidebar' });
          setOpenDialogCopyItem(true);
        }}
      />
      {openDialogCopyItem && (
        <CopyItemDialogData item={props.item} onClose={() => setOpenDialogCopyItem(false)} open />
      )}
    </>
  );
};

export default ItemSidebarFooter;
