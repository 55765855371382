import { FC } from 'react';

import { MenuItem } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import FilterIcon from '../../Icons/FilterIcon';

import InputsSelectReferenceCategorizationMenuItemStyles from './InputsSelectReferenceCategorizationMenuItemStyles';

type InputsSelectReferenceCategorizationMenuItemProps = {
  classes: Classes<typeof InputsSelectReferenceCategorizationMenuItemStyles>;
  categorization: Categorization;
  categorizationFilters: Category[];
};

const InputsSelectReferenceCategorizationMenuItem: FC<
  InputsSelectReferenceCategorizationMenuItemProps
> = ({ classes, categorization, categorizationFilters }) => {
  const hasFilters = categorizationFilters && categorizationFilters.length > 0;
  const categorizationTitle = `${removeYear(categorization.name)} (${
    hasFilters ? categorizationFilters.length : 'any'
  })`;
  const categorizationFilterList =
    categorizationFilters && categorizationFilters.map((f) => f.number || f.name).join(', ');

  return (
    <ul className={classes.menuItemWrapper}>
      <MenuItem
        className={`${classes.menuItem} ${hasFilters ? classes.menuItemSelected : ''}`}
        key={categorization.id}
      >
        <div className={classes.categorizationContainer}>
          <div className={classes.categorizationDetails}>
            <div>{categorizationTitle}</div>
            <div className={classes.filterList}>{categorizationFilterList}</div>
          </div>
          {hasFilters && (
            <div className={classes.icon}>
              <FilterIcon isFilled={hasFilters} />
            </div>
          )}
        </div>
      </MenuItem>
    </ul>
  );
};

export default withStyles(InputsSelectReferenceCategorizationMenuItemStyles)(
  InputsSelectReferenceCategorizationMenuItem
);
