import { gql } from '../../../api/graphqlFragments';
import { commonCompanyUserFieldsQuery, listCompanyUserFieldsQuery } from '../../login/queries';

export const getProjectCompaniesQuery = gql`
  query projectCompanies($projectID: UUID!) {
    projectCompanies(projectID: $projectID) {
      company {
        id
        domain
        name
        type
        asset {
          id
          blobUrl @client
          location
          name
        }
      }
      role
    }
  }
`;

export const getCompanyUsersQuery = gql`
  query companyUsers($companyID: UUID!, $filters: CompanyUserFilter, $sortBy: CompanyUserSortBy) {
    companyUsers(companyID: $companyID, filters: $filters, sortBy: $sortBy) {
      ...commonCompanyUserFieldsQuery
    }
  }
  ${commonCompanyUserFieldsQuery}
`;

export const getCompanyCollaboratorsQuery = gql`
  query companyCollaborators(
    $companyID: UUID!
    $filters: CompanyCollaboratorFilter
    $userSortBy: CompanyUserSortBy
  ) {
    companyCollaborators(companyID: $companyID, filters: $filters, userSortBy: $userSortBy) {
      companyUser {
        ...listCompanyUserFieldsQuery
      }
    }
  }
  ${listCompanyUserFieldsQuery}
`;

export const upsertProjectCompanyRole = gql`
  mutation upsertProjectCompanyRole($projectID: UUID!, $companyID: UUID!, $role: String!) {
    upsertProjectCompanyRole(projectID: $projectID, companyID: $companyID, role: $role) {
      company {
        id
        domain
        name
        type
        asset {
          id
          blobUrl @client
          location
          name
        }
      }
      role
    }
  }
`;
