import { FC, useState } from 'react';

import { ItemSidebarLocation } from '../../../../analytics/analyticsEventProperties';
import { itemSidebarForecastingOpenVar } from '../../../../api/apollo/reactiveVars';
import { GENERAL_INFO } from '../../../../constants';
import { useCostMode } from '../../../../utilities/costMode';
import ItemSidebar from '../../../frame/ItemSidebar/ItemSidebar';
import ItemSidebarFooterCopyButton from '../../../frame/ItemSidebar/ItemSidebarFooterCopyButton';
import { useCalculateItemPermissions } from '../../../frame/ItemSidebar/ItemSidebarUtils';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';

type ForecastingItemSidebarWrapperProps = {
  items: ForecastingItem[];
  itemID: UUID | null;
};

const ForecastingItemSidebarWrapper: FC<ForecastingItemSidebarWrapperProps> = ({
  itemID,
  items,
}) => {
  const costMode = useCostMode();
  const [expanded, setExpanded] = useState<string[]>([GENERAL_INFO]);
  const projectID = items.find((i: ForecastingItem) => i.id === itemID)?.project.id;

  // QUERIES
  const { data, loading, previousData, refetch: refetchItem } = useItemQuery(itemID ?? undefined);
  // TODO: Remove this cast - CT-481
  const currentItem = (data?.item ?? previousData?.item) as ItemLike;

  // TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct
  const itemSidebarPermissions = useCalculateItemPermissions(currentItem, projectID, true);

  const openSidebar = (id?: string | undefined) => {
    if (id) itemSidebarForecastingOpenVar(id);
  };

  const closeSidebar = () => {
    itemSidebarForecastingOpenVar(null);
  };

  return (
    <ItemSidebar
      closeSidebar={closeSidebar}
      costMode={costMode}
      expanded={expanded}
      footer={<ItemSidebarFooterCopyButton item={currentItem} />}
      item={currentItem}
      items={items}
      loading={loading}
      milestones={currentItem?.project.milestones ?? []}
      openSidebar={openSidebar}
      permissions={itemSidebarPermissions}
      projectId={currentItem?.project.id}
      setExpanded={setExpanded}
      setOpenDialogAddOption={() => {}}
      sidebarLocation={ItemSidebarLocation.FORECASTING}
      onItemMutated={refetchItem}
    />
  );
};

export default ForecastingItemSidebarWrapper;
