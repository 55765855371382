import { FC, useMemo, useState } from 'react';

import { TextField } from '@material-ui/core';

import { UserStatus } from '../../../api/gqlEnumsBe';
import { ALL_TRADES } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { getIsDeactivated } from '../../CompanyTab/CompanyTabUtils';
import {
  CategorizationCategoryMap,
  formatFilterSummary,
  getCategorizationsNameMap,
  getHeaderFilterString,
} from '../../FilterPanel/filterUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import SearchText from '../../Search/SearchText/SearchText';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import {
  SelectCategoryValue,
  isSelectCategoryInteraction,
} from '../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryPermissions from '../../Select/SelectCategoryPermissions/SelectCategoryPermissions';
import useMemoWrapper from '../../useMemoWrapper';
import styles from '../CollaboratorsPage/CollaboratorsPageStyles';
import UserAvatar from '../UserAvatar';

import CollaboratorsIconMenu from './CollaboratorsIconMenu';
import {
  useCollaboratorCategoryUpdates,
  useCollaboratorsListItem,
} from './CollaboratorsListItemUtils';

type CollaboratorsListItemProps = {
  classes: Classes<typeof styles>;
  collaborator: Collaborator;
  searchTerm: string;
};

const CollaboratorsListItem: FC<CollaboratorsListItemProps> = ({
  classes,
  collaborator,
  searchTerm,
}) => {
  const projectID = getProjectIdFromUrl();

  const { allTrades, user, role, responsibility, id, trades } = collaborator;
  const { name, email, status } = collaborator.user;

  const [responsibilitiesInputValue, setResponsibilitiesInputValue] = useState<string>(
    responsibility || ''
  );
  const { addTradesCollaborator, removeTradesCollaborator, updateCollaboratorAllTrades } =
    useCollaboratorCategoryUpdates();

  const { canEdit } = usePermissions();
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);
  const canEditPermissions = canEdit(PermissionResource.PERMISSION_TEMPLATES);
  const { onUpdateCollaboratorRole, updateCollaboratorResponsibility, roles } =
    useCollaboratorsListItem(collaborator);

  const { data } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const statusClass = status === UserStatus.ACTIVE ? '' : classes.notActiveState;
  const statusTitle = (status: UserStatus) => {
    switch (status) {
      case UserStatus.PENDING:
        return `INVITED: User is invited but has not yet joined the project`;
      case UserStatus.DEACTIVATED:
        return `DEACTIVATED: User no longer has access to Join`;
      default:
        return '';
    }
  };

  const textStatusAppend = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return name;
      case UserStatus.DEACTIVATED:
        return `${name} (Deactivated)`;
      case UserStatus.PENDING:
        return `${name} (Invited)`;
      default:
        return '';
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const filters: CategorizationCategoryMap = {};
  trades.forEach((trade) => {
    if (trade.categorization) {
      const categorizationId = trade.categorization.id;
      if (!filters[categorizationId] || !filters[categorizationId].length) {
        filters[categorizationId] = [trade as Category];
      } else {
        filters[categorizationId].push(trade as Category);
      }
    }
  });

  const categorizationsNameMap = useMemoWrapper(getCategorizationsNameMap, categorizations);

  const categoryList = useMemo(() => {
    const lines = formatFilterSummary(filters, categorizationsNameMap, 25, 0).map(
      getHeaderFilterString
    );
    if (allTrades) return 'All Categories';
    if (lines.length === 0) return '-';
    return lines.map((line) => (
      <>
        <span>{line}</span>
        <br />
      </>
    ));
  }, [filters, categorizationsNameMap, allTrades]);

  const fullCategoryList = useMemo(() => {
    const summaryLines = formatFilterSummary(filters, categorizationsNameMap).map(
      getHeaderFilterString
    );
    if (allTrades || summaryLines.length === 0) return '';
    return summaryLines.map((line: string) => <div key={line}>{line}</div>);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [filters, categorizations, allTrades]);

  // UI GENERATORS
  const selectRole = !canEditPermissions ? (
    <p>{role.name}</p>
  ) : (
    <div className={classes.roleSelect}>
      <JoinSelect
        cySelect="SelectRole"
        disabled={!canEditCollaborators}
        value={(role || roles[0]).id}
        entries={roles}
        onChange={(roleID: string | null) => {
          const roleValue = roles.filter((r: Role) => r.id === roleID)[0];
          onUpdateCollaboratorRole(roleValue);
        }}
      />
    </div>
  );

  const tradeSelect = !canEditPermissions ? (
    <NormalTooltip title={fullCategoryList}>
      <p data-cy="CollaboratorsListItem-categoryList">{categoryList}</p>
    </NormalTooltip>
  ) : (
    <SelectCategoryPermissions
      addCategories={(newCategories: SelectCategoryValue[]) => {
        const added = newCategories.filter((category) => !isSelectCategoryInteraction(category)); // remove the bulk toggle option!
        const addedIDs = added.map((t) => t.id);
        addTradesCollaborator(projectID, id, addedIDs);
      }}
      hasAllCategoryPermissions={allTrades}
      key={`Collaborator-SelectCategoryPermissions-${user.id}`}
      id={id || ''}
      onClose={() => {
        updateCollaboratorAllTrades(projectID, id, false);
      }}
      onSelectAll={() => {
        updateCollaboratorAllTrades(projectID, id, true);
      }}
      removeCategories={(newCategories: Category[]) => {
        const categoryIDs = newCategories.map((category) => category.id);
        const filteredCategories = (trades as Category[]).filter(
          (category: { id: string }) => !categoryIDs.includes(category.id)
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        const newCat = filteredCategories.map((nt: { number: any }) => nt.number);
        const oldCategories = (trades as Category[]).filter(
          (ot: { number: string }) => ot.number !== ALL_TRADES
        );
        const nl = (newCategories && newCategories.length) || 0;
        const removed = nl
          ? oldCategories.filter((ot: { number: string }) => newCat.indexOf(ot.number) < 0)
          : oldCategories;
        const removedIDs = removed.map((r: { id: UUID }) => r.id);
        removeTradesCollaborator(projectID, id, removedIDs);
      }}
      selectedCategories={trades}
    />
  );

  return (
    <div className={classes.containerRow} data-cy="CollaboratorsListItem-row">
      <div
        className={`${classes.cell} ${statusClass}`}
        data-cy="CollaboratorsListItem-AssigneeChip"
        title={statusTitle(status)}
      >
        <div className={classes.avatar}>
          <UserAvatar assignee={user} deactivated={getIsDeactivated(user)} />
        </div>
      </div>
      <div className={`${classes.cell} ${statusClass}`} title={statusTitle(status)}>
        <p className={classes.text} data-cy="CollaboratorsListItem-name">
          <SearchText searchTerm={searchTerm} text={textStatusAppend(status)} />
        </p>
      </div>
      <div className={`${classes.cell} ${statusClass}`} title={statusTitle(status)}>
        <p className={classes.text} data-cy="CollaboratorsListItem-email">
          <SearchText searchTerm={searchTerm} text={email} />
        </p>
      </div>
      <div className={classes.cell}>
        <TextField
          data-cy="CollaboratorsListItem-responsibility"
          InputProps={{
            disableUnderline: true,
          }}
          disabled={!canEditCollaborators}
          multiline
          value={responsibilitiesInputValue}
          placeholder=""
          onChange={(evt) => {
            setResponsibilitiesInputValue(evt.target.value);
          }}
          onBlur={() => updateCollaboratorResponsibility(responsibilitiesInputValue)}
        />
      </div>
      <div className={classes.cell} data-cy="CollaboratorsListItem-role">
        {selectRole}
      </div>
      <div className={`${classes.cell} ${classes.cellTradeAndDelete}`}>
        <div className={classes.categoryColumn}>
          {role?.hasTrade ? tradeSelect : <p>All Categories</p>}
        </div>
        {canEditCollaborators && (
          <CollaboratorsIconMenu
            collaborator={collaborator}
            canEditPermissions={canEditPermissions}
            deactivated={getIsDeactivated(user)}
          />
        )}
      </div>
    </div>
  );
};

const CollaboratorsListItemStyled = withStyles(styles)(CollaboratorsListItem);

export default CollaboratorsListItemStyled;
