import { FC } from 'react';

import { Button, Dialog, DialogActions, Divider, Typography } from '@material-ui/core';

import { Status } from '../../generated/graphql';
import { withStyles } from '../../theme/komodo-mui-theme';
import { getItemStatusLabel } from '../../utilities/item-status';
import { getItemCost } from '../../utilities/items';
import { generateCostImpactInfo } from '../Items/ItemsUtils';
import ItemsIconsStatusSelect from '../ItemsList/ItemsIcons/ItemsIconsStatusSelect/ItemsIconsStatusSelect';
import MilestoneSelect from '../Select/MilestoneSelect';

import styles from './AddToMilestoneModalStyles';

type AddToMilestoneModalProps = {
  classes: Classes<typeof styles>;
  open: boolean;
  item: ItemLike;
  milestones?: Milestone[];
  onChangeStatus: (status: Status) => void;
  selectedMilestone?: string;
  selectedStatus: Status;
  onChangeMilestone: (milestone: string | null) => void;
  onSubmit: () => void;
  onCancel: () => void;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onEscape?: (e: any, closeDialog: () => void) => void;
};

const AddToMilestoneModal: FC<AddToMilestoneModalProps> = ({
  classes,
  open = false,
  item,
  milestones,
  selectedMilestone,
  selectedStatus,
  onChangeMilestone,
  onChangeStatus,
  onSubmit,
  onCancel,
  onClose,
  onEscape,
}) => {
  const number = item ? item.number : '';
  const name = item ? item.name : '';
  const availableStates = item ? item.availableStates : [];

  return (
    <Dialog
      maxWidth={false}
      classes={{
        paper: classes.dialogPaper,
      }}
      open={open}
      onClose={onClose}
      onKeyDown={onEscape ? (e) => onEscape(e, onClose) : () => {}}
    >
      <div className={classes.root}>
        <div className={classes.modalHeader}>
          <Typography variant="title">Move Item to Another Milestone</Typography>
        </div>
        <Divider />
        <div className={classes.modalBody}>
          <Typography className={classes.promptText}>
            {'Move '}
            <span className={classes.itemName}>{`#${number} ${name} `}</span>
            {' to'}
          </Typography>

          <div className={classes.milestoneStatusSelect}>
            <MilestoneSelect
              isCompact={false}
              milestones={milestones}
              selectedMilestone={selectedMilestone}
              onChangeMilestone={onChangeMilestone}
            />
            <Typography className={classes.withStatus}>with status</Typography>
            <ItemsIconsStatusSelect
              availableStates={availableStates}
              editable
              item={item}
              options={item && (item as Item).hasOptions ? (item as Item).options : []}
              status={selectedStatus}
              updateStatus={(newStatus: Status) => onChangeStatus(newStatus)}
            />
          </div>
          {item && item.milestone && item.milestone && selectedMilestone !== item.milestone.id && (
            <Typography className={classes.promptText}>
              {'This item will still continue to impact '}
              <span className={classes.itemName}>{item.milestone.name}</span>
              {' with status '}
              <span className={classes.itemName}>{getItemStatusLabel(item.status)}</span>
              {' and cost impact '}
              <span className={`${classes.itemName} ${classes.costImpact}`}>
                {generateCostImpactInfo(getItemCost(item))}
              </span>
              .
            </Typography>
          )}
        </div>
        <Divider />
        <div>
          <DialogActions>
            <Button
              size="medium"
              variant="text"
              color="secondary"
              aria-label="Cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              data-cy="button-addToMilestone-OK"
              size="medium"
              color="primary"
              variant="contained"
              aria-label="OK"
              onClick={() => {
                onSubmit();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(AddToMilestoneModal);
