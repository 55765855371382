import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const ProjectDetailsStyles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      margin: '8px auto',
      width: 'calc(100% - 16px)',
      maxWidth: '800px',
    },
    menu: {
      width: 200,
    },
    dateWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateInput: {
      width: '49%',
      paddingTop: '8px',
      height: '64px', // set height to handle error messaging on input field
    },
    errorIcon: {
      color: theme.palette.red,
      fontSize: '19px',
      marginTop: '6px',
      marginRight: '4px',
    },
    inputDeliveryType: {
      fontSize: '16px',
      fontWeight: 400,
      height: '20px',
    },
    multiline: {
      overflow: 'hidden',
      padding: '5px 0',
      'white-space': 'normal',
    },
    tab: {
      minWidth: '60px',
      flexGrow: 1,
    },
    textFieldStyles: {
      height: '34px',
      ...theme.typography.body1,
      fontWeight: 400,
    },
    title: {
      fontWeight: 400,
      padding: '24px',
    },
    bottomLabel: {
      paddingTop: '8px',
    },
  });

export default ProjectDetailsStyles;
