import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import SourceFilterMenuItem from './InputsSelectReferenceSourceFilterMenuItem';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import SourceFilterSubMenuHeader from './SourceFilterSubMenuHeader';
import { toggleReferenceCheckbox } from './utils';

export const getSourceFilterMenuItems = (
  classes: Classes<typeof InputsSelectReferenceStyles>,
  selectedSourceFilters: ItemLink[],
  sourceFilterMenuOptions: ItemLink[],
  setSelectedSourceFilters: (selectedSourceFilters: ItemLink[]) => void,
  markupLine: string
): React.ReactNode[] => {
  const menuItems = [
    <SourceFilterSubMenuHeader key={markupLine} selectedMarkup={markupLine} />,
    <MenuItem
      className={`${classes.filterMenuLabel} ${classes.dividerBottom} ${classes.dividerTop}`}
      key={selectedSourceFilters.length}
    >
      <Checkbox
        onClick={() => {
          const values =
            selectedSourceFilters.length < sourceFilterMenuOptions.length
              ? sourceFilterMenuOptions
              : [];
          setSelectedSourceFilters(values);
        }}
        indeterminate
        disabled={false}
      />
      <Typography
        className={classes.checkboxLabel}
      >{`${selectedSourceFilters.length} sources selected`}</Typography>
    </MenuItem>,
  ];

  sourceFilterMenuOptions.forEach((source: ItemLink) => {
    const isChecked = selectedSourceFilters.some((s) => s.id === source.id);
    menuItems.push(
      <SourceFilterMenuItem
        key={source.id}
        isChecked={isChecked}
        onClick={() =>
          toggleReferenceCheckbox(
            isChecked,
            source,
            selectedSourceFilters,
            setSelectedSourceFilters
          )
        }
        {...source}
      />
    );
  });
  return menuItems;
};
