import { FC } from 'react';

import { Button } from '../../../scales';
import ShadedHelpTip from '../../../shared-widgets/ShadedHelpTip/ShadedHelpTip';

type IntegrationsLoggedOutEmptyStateProps = {
  onClickLinkProcoreAccount: () => void;
  isItemSidebar: boolean;
};

const IntegrationsLoggedOutEmptyState: FC<IntegrationsLoggedOutEmptyStateProps> = ({
  onClickLinkProcoreAccount,
  isItemSidebar,
}) => {
  const helpTip = (
    <ShadedHelpTip tip="Create a Change Event from an Accepted Item by linking a Procore account" />
  );

  const linkProcoreAccountButton = (
    <Button
      label="Link Procore Account"
      type="primary"
      onClick={() => onClickLinkProcoreAccount()}
    />
  );

  return (
    <div className="flex flex-col items-center justify-center gap-1">
      {helpTip}
      <div
        className={`flex h-[200px] w-[400px] items-center justify-center ${
          isItemSidebar && 'hidden'
        }`}
      >
        <img src="/img/integrationProcore.svg" alt="Link your procore account" />
      </div>
      <div className="p-4">{linkProcoreAccountButton}</div>
    </div>
  );
};

export default IntegrationsLoggedOutEmptyState;
