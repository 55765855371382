import { useMutation } from '@apollo/client';

import { createQuantity } from '../../../analytics/analyticsEventProperties';
import { REFETCH_QUANTITY } from '../../../api/refetchSets';
import {
  AddQuantityBreakdownMutation,
  AddQuantityBreakdownMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { addQuantityBreakdownMutation } from './queries';

export default () => {
  const [addQuantityBreakdownFunc] = useMutation<
    AddQuantityBreakdownMutation,
    AddQuantityBreakdownMutationVariables
  >(addQuantityBreakdownMutation);

  const sendAnalytics = useAnalyticsEventHook();

  return (
    projectID: UUID,
    milestoneID: UUID,
    quantityID: UUID | undefined,
    unitID: UUID,
    fields: FieldInput[]
  ) =>
    addQuantityBreakdownFunc({
      refetchQueries: REFETCH_QUANTITY,
      variables: {
        projectID,
        milestoneID,
        quantityID,
        unitID,
        fields,
      },
    }).then((response) => {
      const addQuantityBreakdown = response.data?.addQuantityBreakdown;
      if (addQuantityBreakdown) {
        const { id, unit, magnitude: quantityMagnitude } = addQuantityBreakdown;
        sendAnalytics(createQuantity(id, quantityMagnitude.toString(), unit.id, milestoneID)); // TODO - update to correct analytics
      }
    });
};
