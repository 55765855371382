import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export const FORECASTING_HEADER_HEIGHT = 47;
export const FORECASTING_FILTERS_HEIGHT = 72;

const styles = (theme: KomodoTheme) =>
  createStyles({
    container: {
      background: theme.palette.brand.white,
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.disabledGrey}`, // match current app navbar
    },
    button: {
      ...theme.typography.webLabel,
      fontWeight: 300,
      borderRadius: '0 !important', // override the too-broad `a:focus` style in index.css
      padding: '16px 24px 12px',
      textDecoration: 'none',
      color: theme.palette.primaryGrey,
      cursor: 'pointer',
      background: theme.palette.brand.white,

      '&:focus': {
        outline: 'none',
      },

      '&:active': {
        background: theme.palette.brand.white,
      },

      '&[aria-selected=true]': {
        fontWeight: 500,
        borderBottom: `2px solid ${theme.palette.brand.black}`,
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  });

export default styles;
