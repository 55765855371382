import { FC, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { forecastingEventTypes } from '../../../analytics/analyticsEventProperties';
import {
  itemSidebarForecastingOpenVar,
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../api/apollo/reactiveVars';
import { FORECASTING_TAB_NAME } from '../../../constants';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useSendForecastingAnalytics from '../hooks/useSendForecastingAnalytics';

import ForecastingItemsListData from './items/ForecastingItemsListData';
import ForecastingProjectsListData from './projects/ForecastingProjectsListData';
import { SearchMode } from './types';

const ForecastingExplore: FC = () => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();

  useSetDocumentTitle();
  const [isFilterMenuOpen, setFilterMenuOpen] = useLocalStorage(
    'FORECASTING_EXPLORE_FILTERS_OPEN',
    true
  );

  const searchMode = useCurrentSearchMode();
  const navigate = useNavigate();
  const onChangeSearchMode = useCallback(
    (mode: SearchMode) => {
      sendForecastingAnalytics(forecastingEventTypes.EXPLORE_MODE_CTA, { mode });
      const params = new URLSearchParams(document.location.search);
      params.set('mode', mode);
      navigate(`?${params.toString()}`);
    },
    [navigate, sendForecastingAnalytics]
  );

  const onToggleFilterMenu = useCallback(() => {
    itemSidebarForecastingOpenVar(null);
    setFilterMenuOpen((prevState) => !prevState);
  }, [setFilterMenuOpen]);

  useEffect(() => {
    // avoid issues with persisted data on navigation by resetting reactive var
    projectCompsSetInputVar(projectCompsSettingsInputDefault);
  }, []);

  return searchMode === SearchMode.PROJECTS ? (
    <ForecastingProjectsListData
      isFilterMenuOpen={isFilterMenuOpen}
      onChangeSearchMode={onChangeSearchMode}
      onToggleFilterMenu={onToggleFilterMenu}
    />
  ) : (
    <ForecastingItemsListData
      isFilterMenuOpen={isFilterMenuOpen}
      onChangeSearchMode={onChangeSearchMode}
      onToggleFilterMenu={onToggleFilterMenu}
    />
  );
};

const useSetDocumentTitle = () => {
  useEffect(() => {
    document.title = `${FORECASTING_TAB_NAME} - Explore`;
  }, []);
};

const useCurrentSearchMode = (): SearchMode => {
  const location = useLocation();
  const searchMode = new URLSearchParams(location.search).get('mode');

  switch (searchMode) {
    case SearchMode.PROJECTS:
    case SearchMode.ITEMS:
      return searchMode;
    default:
      return SearchMode.PROJECTS;
  }
};

export default ForecastingExplore;
