import { FC, useEffect, useRef, useState } from 'react';
import { useScroll } from 'react-use';

import { ApolloError, useReactiveVar } from '@apollo/client';
import { Card, Divider } from '@material-ui/core';

import { EXPORT_MILESTONE_SUMMARY_REPORT } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  isDownloadingCostReportToExcelVar,
  itemSidebarOpenVar,
} from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { COSTS, DISPLAY } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import usePageHeight from '../../../hooks/usePageHeight';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  checkCostModeIncludesMarkups,
  costModeVar,
  useCostMode,
} from '../../../utilities/costMode';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import ItemSidebarWrapper from '../../frame/ItemSidebar/ItemSidebarWrapper';
import { useListKeyPressListener } from '../../frame/SidebarUtils';
import CostReportHeader from '../CostReportHeader/CostReportHeader';
import { NodeData } from '../CostReportList/CostReportCategoriesTree';
import { getMSRExportColumns } from '../CostReportUtils';
import MilestoneCostReport from '../MilestoneCostReport';

import styles from './CostReportStyles';
import useCostReportParams from './useCostReportParams';

type CostReportProps = {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  defaultCategorizations: Categorization[];
  error?: ApolloError;
  enabledUnits: Unit[];
  milestones: Milestone[];
  projectName: string;
  setIsLoading?: (loading: boolean) => void;
};

const CostReport: FC<CostReportProps> = ({
  categorizations,
  defaultCategorizations,
  classes,
  error,
  enabledUnits,
  milestones,
  projectName,
  setIsLoading = () => {},
}) => {
  // Read our project and milestone ids from the URL to populate queries
  const milestoneId = getMilestoneIdFromUrl();
  const projectId = getProjectIdFromUrl();

  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const [itemNodes, setNavigationData] = useState<NodeData[] | null>(null);

  const milestone = milestones.find((milestone) => milestone.id === milestoneId);
  const milestoneName = milestone?.name ?? '';

  const {
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    headerDescriptions,
    filterManager,
    setSetting,
    setSettings,
    settings,
  } = useCostReportParams(
    categorizations,
    defaultCategorizations,
    milestoneName,
    COSTS,
    enabledUnits
  );
  const { status, viewMode } = settings;
  const { search } = window.location;
  // Print click and key press
  const printOnClick = (route: JoinProjectRoutes) => {
    window.open(generateSharedPath(route, { projectId, milestoneId, search }), '_blank');
  };

  // Key Events
  useListKeyPressListener(() => printOnClick(JoinProjectRoutes.PRINT_MSR));

  // Scrollling
  // We are gonna watch the x scrolling and pass along a prop to turn on the border
  // we throttle the listening and give it a threshold on to overwhelm us
  const [scroll, setScroll] = useState(false);
  const scrollRef = useRef(null);
  const { x } = useScroll(scrollRef);
  useEffect(() => {
    if (x > 2) {
      if (!scroll) setScroll(true);
    } else if (scroll) {
      setScroll(false);
    }
  }, [x, scroll]);

  const height = usePageHeight();

  const costMode = useReactiveVar(costModeVar);
  const sendAnalytics = useAnalyticsEventHook();
  const { filterQueryInput: viewFilter } = filterManager;
  const filenameTokens = [projectName, milestoneName];
  const costModeString = useCostMode();

  const columns = getMSRExportColumns(settings, enabledUnits);
  const columnSets = [{ milestoneID: milestoneId, columns }];

  const exportCostReport = () => {
    if (isDownloadingCostReportToExcelVar()) return;
    sendAnalytics(analyticsEvent(EXPORT_MILESTONE_SUMMARY_REPORT));
    JoinAPI.exportCostReport(
      projectId,
      displayGroupBy,
      columnSets,
      viewFilter,
      costMode,
      checkCostModeIncludesMarkups(costModeString),
      false,
      filenameTokens,
      {
        groupBys: displayGroupBy.map((g) => g.id),
        milestoneEstimates: [],
      },
      settings?.estimateLines === DISPLAY
    );
  };

  return (
    <div className={classes.componentWithSidebar} style={{ height }}>
      <div className={classes.root} style={{ height }}>
        <Card square className={classes.card} elevation={0}>
          <div ref={scrollRef} className={classes.scrollBody}>
            <div className={classes.sticky}>
              {milestone && (
                <CostReportHeader
                  categorizations={categorizations}
                  columnDescriptions={columnDescriptions}
                  displayColumnDescriptions={displayColumnDescriptions}
                  displayGroupBy={displayGroupBy}
                  exportCostReport={exportCostReport}
                  filterManager={filterManager}
                  milestone={milestone}
                  printOnClick={printOnClick}
                  projectId={projectId}
                  settings={settings}
                  setSettings={setSettings}
                  setSetting={setSetting}
                  status={status}
                  viewMode={viewMode}
                />
              )}
              <Divider />
            </div>
            <MilestoneCostReport
              categorizations={categorizations}
              displayGroupBy={displayGroupBy}
              error={error}
              filterManager={filterManager}
              headerDescriptions={headerDescriptions}
              milestoneDescriptions={displayColumnDescriptions}
              settings={settings}
              scroll={scroll}
              setIsLoading={setIsLoading}
              setNavigationData={setNavigationData}
              setSettings={setSettings}
            />
          </div>
        </Card>
      </div>
      {isItemSidebarOpen && itemNodes ? (
        <ItemSidebarWrapper items={itemNodes} milestones={milestones} />
      ) : (
        <div />
      )}
    </div>
  );
};

export default withStyles(styles)(CostReport);
