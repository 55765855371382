import { useMutation } from '@apollo/client';

import { importEstimatesManual } from '../../../../analytics/analyticsEventProperties';
import { EstimateType } from '../../../../api/gqlEnums';
import {
  CreateMilestoneDraftEstimateFromPreviousMilestoneMutation,
  CreateMilestoneDraftEstimateFromPreviousMilestoneMutationVariables,
} from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { createMilestoneDraftEstimateFromPreviousMilestoneMutation } from '../queries';

function useCreateMilestoneDraftEstimateFromPreviousMilestone() {
  const [createMilestoneDraftEstimateFromPreviousMilestoneFunc] = useMutation<
    CreateMilestoneDraftEstimateFromPreviousMilestoneMutation,
    CreateMilestoneDraftEstimateFromPreviousMilestoneMutationVariables
  >(createMilestoneDraftEstimateFromPreviousMilestoneMutation);

  const sendAnalytics = useAnalyticsEventHook();
  const submitFunc = (
    projectID: UUID,
    currentMilestoneID: UUID,
    currentEstimateType: EstimateType,
    previousMilestoneID: UUID,
    previousEstimateType: EstimateType,
    isRunningTotal = false,
    incorporateAccepted = false,
    onSuccess?: (estimateID: UUID) => void,
    onFailure?: () => void
  ) => {
    createMilestoneDraftEstimateFromPreviousMilestoneFunc({
      variables: {
        projectID,
        currentMilestoneID,
        currentEstimateType,
        previousMilestoneID,
        previousEstimateType,
        isRunningTotal,
        incorporateAccepted,
      },
    })
      .then(({ data }) => {
        const milestone = data?.createMilestoneDraftEstimateFromPreviousMilestone;
        const estimateID =
          currentEstimateType === EstimateType.ACTIVE_ESTIMATE
            ? milestone?.activeEstimateDraftID
            : milestone?.budgetDraftID;
        if (estimateID) {
          if (onSuccess) onSuccess(estimateID);
          sendAnalytics(importEstimatesManual(currentEstimateType, estimateID));
        }
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
  return submitFunc;
}

export default useCreateMilestoneDraftEstimateFromPreviousMilestone;
