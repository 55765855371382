import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { createStyles } from '@material-ui/core';

import { KomodoTheme, withStyles } from '../../../../theme/komodo-mui-theme';
import { removeYear } from '../../../../utilities/string';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import SelectValue from '../../../Select/SelectValue/SelectValue';
import {
  checkMfBuiltInToBuiltInMapping,
  checkUfBuiltInToBuiltInMapping,
  hasBuiltInMfName,
  hasBuiltInUfName,
  numCategorizationsMappedToMultilevel,
} from '../../utils';
import { SELECTOR_WIDTH } from '../CategorizationMatching/CategorizationMatching';

import Levels from './Levels.svg';

const styles = (theme: KomodoTheme) =>
  createStyles({
    greenDot: {
      marginLeft: 8,
      fontSize: 6,
      color: theme.palette.brightGreen,
    },
    inlineFlex: { display: 'inline-flex' },
    selectCategorization: {
      width: SELECTOR_WIDTH,
    },
  });

type Categorization = ProjectCategorizationMetadata['categorization'];

type CategorizationSelectProps = {
  classes: Classes<typeof styles>;
  categorizationsProject: Categorization[];
  draftCategorizations: DraftCategorization[];
  disabled?: boolean; // for disabled MF / UF
  isBuiltIn: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  mapping: any;
  name: string;
  nameNew: string;
  newCategorizationMap: Map<string, boolean>;
  updateMap: (name: string | undefined) => void;
};

export const isCategorization = (
  c: Categorization | { id: UUID; name: string }
): c is Categorization => {
  return 'id' in c && 'levels' in c;
};

const CategorizationSelect: FC<CategorizationSelectProps> = ({
  classes,
  categorizationsProject: categorizationsProjectUni,
  draftCategorizations,
  disabled = false,
  mapping,
  isBuiltIn = false,
  name,
  nameNew: nameNewCat,
  newCategorizationMap,
  updateMap,
}) => {
  const [builtInUsedTooltipOpen, setBuiltInUsedTooltipOpen] = useState<string | undefined>(
    undefined
  );
  const ref = useRef(null);
  useClickAway(ref, () => setBuiltInUsedTooltipOpen(undefined));

  const nameNew = newCategorizationMap.get(nameNewCat) ? `${nameNewCat} (new)` : nameNewCat;
  const categorizationsProject: (Categorization | { id: UUID; name: string; levels: number })[] = [
    ...categorizationsProjectUni,
  ];

  if (
    // Only create a new categorization if this isn't a built-in categorization, unless the built-in is disabled
    ((!isBuiltIn &&
      !disabled &&
      !categorizationsProject.find(({ name: nameP }) => nameP === nameNewCat)) ||
      !name) &&
    // Don't display default categorization if more than 1 column is mapped to the same categorization
    !categorizationsProject.find(
      ({ name: nameP, levels }) =>
        levels > 1 &&
        mapping.get(nameNewCat) === nameP &&
        numCategorizationsMappedToMultilevel(nameP, mapping, draftCategorizations) > 1
    )
  )
    categorizationsProject.unshift({ name: nameNew, id: 'none', levels: 1 });

  const onChangeCategorization = (value: string, isDisabledBuiltIn = false) => {
    const isCurrentToCurrentMapping = value === name;
    if (
      !isCurrentToCurrentMapping &&
      hasBuiltInMfName(value) &&
      !isDisabledBuiltIn &&
      checkMfBuiltInToBuiltInMapping(mapping)
    ) {
      setBuiltInUsedTooltipOpen('MasterFormat');
      return;
    }
    if (
      !isCurrentToCurrentMapping &&
      hasBuiltInUfName(value) &&
      !isDisabledBuiltIn &&
      checkUfBuiltInToBuiltInMapping(mapping)
    ) {
      setBuiltInUsedTooltipOpen('UniFormat');
      return;
    }

    updateMap(value === nameNew ? undefined : value);
  };
  // this map tells us if each categorization option in the selector is built-in or not
  const isBuiltInCategorization = Object.fromEntries(
    categorizationsProject.map((v) => [v.name, 'builtin' in v && v.builtin])
  );
  const categorizationMap = new Map<string, Categorization | { id: UUID; name: string }>(
    categorizationsProject.map((c) => [c.name, c])
  );

  const formatExampleCategories = (
    categories: NonNullable<Categorization['exampleCategories']>
  ) => {
    return (
      <>
        {categories.map((c, index) => {
          return (
            <div
              key={c.id}
              style={{
                marginLeft: 8 * index,
              }}
            >
              {c.number} - {c.name}
            </div>
          );
        })}
      </>
    );
  };

  const formatColumnMapping = (importMetadata: NonNullable<Categorization['importMetadata']>) => {
    const columns = [];
    const {
      columnLevel1: c1,
      columnLevel2: c2,
      columnLevel3: c3,
      columnLevel4: c4,
    } = importMetadata;
    if (c1) columns.push(c1);
    if (c2) columns.push(c2);
    if (c3) columns.push(c3);
    if (c4) columns.push(c4);
    return columns.join(' > ');
  };

  const select = (
    <div className={classes.selectCategorization} data-cy={`categorization-select-${nameNewCat}`}>
      <SelectValue
        hidePrint={false}
        isCompact={false}
        showRightIcon
        makeEntry={(v) => {
          const c = categorizationMap.get(v);
          return {
            id: v,
            name: v,
            showRightIcon: true,
            rightIcon:
              c && isCategorization(c) && c.levels > 1 ? <img alt="" src={Levels} /> : undefined,
          };
        }}
        nameFunc={(v: string) => (
          <span className={classes.inlineFlex}>
            {removeYear(v, isBuiltInCategorization[v], disabled)}
            {v === `${nameNewCat} (new)` && <span className={classes.greenDot}>{'\u2B24'}</span>}
          </span>
        )}
        onChange={(v: string | null) => onChangeCategorization(v || '', disabled)}
        value={name || nameNew}
        values={categorizationsProject.map(({ name: n }) => n)}
        valueToolTip={(entry) => {
          const c = categorizationMap.get(entry.id);
          if (!c || !isCategorization(c)) return undefined;
          return (
            <div>
              <div>{c.name}</div>
              <div>{c.description}</div>
              <div>{c.levels > 1 ? `(${c.levels} Levels)` : `(Single Level)`}</div>
              {c.exampleCategories && c?.exampleCategories?.length !== 0 && (
                <>
                  <br />
                  <div>Example:</div>
                  <div>{formatExampleCategories(c.exampleCategories)}</div>
                </>
              )}
              {c.importMetadata && c.importMetadata.columnLevel1 && (
                <>
                  <br />
                  <div>Mapped to:</div>
                  <div>{formatColumnMapping(c.importMetadata)}</div>
                </>
              )}
            </div>
          );
        }}
      />
    </div>
  );

  return (
    <NormalTooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={() => setBuiltInUsedTooltipOpen(undefined)}
      open={!!builtInUsedTooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<p>{`First you will need to unassign ${builtInUsedTooltipOpen}`}</p>}
    >
      <div ref={ref}>{select}</div>
    </NormalTooltip>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const StyledCategorizationSelect: any = withStyles(styles)(CategorizationSelect);

export default StyledCategorizationSelect;
