import { useReactiveVar } from '@apollo/client';

import {
  IntervalType,
  MilestonesCostTrendsDataQuery,
  MilestonesCostTrendsDataQueryVariables,
} from '../../../../../generated/graphql';
import { columns as defaultColumns } from '../../../../../hooks/constants';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../../../utilities/costMode';

import { milestonesCostTrendsQuery } from './queries';

export const useMilestonesCostTrendsQuery = (
  projectID: UUID | null,
  columns: CostReportColumnInput[] = defaultColumns,
  intervalType: IntervalType,
  viewFilter: ViewFilterInput,
  summary: boolean
) =>
  useQuery<MilestonesCostTrendsDataQuery, MilestonesCostTrendsDataQueryVariables>(
    milestonesCostTrendsQuery,
    {
      variables: {
        projectID,
        columns,
        intervalType,
        costMode: useReactiveVar(costModeVar),
        viewFilter,
        summary,
      },
      skip: !projectID,
    }
  );
