import { CategorizationDialogType } from '../../../api/gqlEnums';
import { Category, EstimateTotalType } from '../../../generated/graphql';
import costTypesIllustration from '../../ImportEstimate/Modals/EstimateTotalTypeSelect/CostTypesIllustration.svg';
import totalIllustration from '../../ImportEstimate/Modals/EstimateTotalTypeSelect/TotalIllustration.svg';
import unitIllustration from '../../ImportEstimate/Modals/EstimateTotalTypeSelect/UnitIllustration.svg';
import { Column } from '../../JoinGrid/types';

export type MenuOptionsProps = {
  addColumn?: (n: number) => void;
  canEditCategory: boolean;
  canEditColumns: boolean;
  categorization?: CategorizationMetadata;
  column: Column;
  deleteColumn?: (id: UUID) => void;
  errorsMap: Map<string, number[]>;
  estimateTotalType?: EstimateTotalType;
  hasGroups: boolean;
  id: UUID;
  index: number;
  isErrorsMode: boolean | undefined;
  isFirstCategoryColumn: boolean;
  isItem: boolean;
  isLastAddableColumn: boolean;
  isLastCategoryColumn: boolean;
  isQuantity: boolean;
  isTotalColumn: boolean;
  moveColumn: (c: number, f: number) => void;
  onAddAllErrors: () => void;
  onCategoryError: (categoryError: string) => void;
  openCategorizationDialog: (type: CategorizationDialogType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  sendAnalytics: (data: any) => void;
  setDeleteId: (id: UUID) => void;
  setEditedCellType: (type: string) => void;
  setPendingTotalType: (type: EstimateTotalType) => void;
  totalErrors: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  updateColumnCells: (value: any, indices: number[], issuedCategory: string) => void;
};

export const getLines = (count: number) => {
  // when importing an estimate we do not know how many lines there are
  if (count < 1) return ``;
  if (count === 1) return `${count} line`;
  return `${count} lines`;
};
export const getCategories = (count: number) =>
  count === 1 ? `${count} category` : `${count} categories`;
export const getCategoriesMissingLabel = (count: number) => `${getCategories(count)} not found:`;
export const getErrors = (count: number) => (count === 1 ? `${count} error` : `${count} errors`);

export const alignments = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const updateCategories = (
  categories: Pick<Category, 'name' | 'number'>[],
  key: string,
  errorsMap: Map<string, number[]>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  updateColumnCells: (value: any, indices: number[], issuedCategory: string) => void
) => {
  const newCategory = categories[0];
  // "Uncategorized" categorization doesn't have a number
  // Uniformat "Introduction" categorization doesn't have a name
  // All other categorizations have both name and number
  const isUncategorized = !newCategory.name && !newCategory.number;
  if (isUncategorized) {
    updateColumnCells({ search: '' }, errorsMap.get(key) || [], key);
  } else {
    // Some categories do not have a number but do have a name (ex: Uniformat Introduction)
    const searchValue = newCategory.number === '' ? newCategory.name : newCategory.number;
    updateColumnCells(
      {
        category: newCategory,
        search: searchValue,
      },
      errorsMap.get(key) || [],
      key
    );
  }
};

export const getTextTitleSVGs = (fromType: EstimateTotalType, toType: EstimateTotalType) => {
  if (fromType === EstimateTotalType.TOTAL_TYPE_TOTAL) {
    if (toType === EstimateTotalType.TOTAL_TYPE_UNIT) {
      return {
        title: 'Enter Quantity, Unit, Unit Price',
        fromSVG: totalIllustration,
        toSVG: unitIllustration,
        text: 'The estimate will have Quantity, Unit, and Unit Price columns.',
      };
    }
    if (toType === EstimateTotalType.TOTAL_TYPE_COST_TYPES) {
      return {
        title: 'Break down by cost type',
        svg: costTypesIllustration,
        fromSVG: totalIllustration,
        toSVG: costTypesIllustration,
        text: 'The estimate will have Quantity, Unit, and Unit Price columns for each cost type.',
      };
    }
  }
  if (fromType === EstimateTotalType.TOTAL_TYPE_COST_TYPES) {
    if (toType === EstimateTotalType.TOTAL_TYPE_UNIT) {
      return {
        title: 'Enter Quantity, Unit, Unit Price',
        fromSVG: costTypesIllustration,
        toSVG: unitIllustration,
        text: 'The estimate will have Quantity, Unit, and Unit Price columns. Cost type columns will be removed from this estimate and replaced with a single Unit Price column, and any markups that are applied to cost types will no longer be applied to anything. The data in these columns will be deleted, but totals will remain the same.',
      };
    }
    if (toType === EstimateTotalType.TOTAL_TYPE_TOTAL) {
      return {
        title: 'Delete Quantity, Unit, and Unit Price columns',
        svg: totalIllustration,
        fromSVG: costTypesIllustration,
        toSVG: totalIllustration,
        text: 'Quantity, Unit, and Unit Price columns will be removed from this estimate, and any markups that are applied to cost types will no longer be applied to anything. The data in these columns will be deleted, but totals will remain the same.',
      };
    }
  }
  // EstimateTotalType.TOTAL_TYPE_UNIT are the default
  if (toType === EstimateTotalType.TOTAL_TYPE_COST_TYPES) {
    return {
      title: 'Break down by cost type',
      fromSVG: unitIllustration,
      toSVG: costTypesIllustration,
      text: 'The estimate will have Quantity, Unit, and Unit Price columns for each cost type.',
    };
  }
  // The last option is changing to  EstimateTotalType.TOTAL_TYPE_TOTAL
  return {
    title: 'Delete Quantity, Unit, and Unit Price columns',
    fromSVG: unitIllustration,
    toSVG: totalIllustration,
    text: 'Quantity, Unit, and Unit Price columns will be removed from this estimate. The data in these columns will be deleted, but totals will remain the same.',
  };
};

export const getTotalTypeDialogCopy = (
  estimateTotalType: EstimateTotalType = EstimateTotalType.TOTAL_TYPE_UNIT, // This is the default total type for the estimate
  pendingTotalType: EstimateTotalType
) => {
  const { text, title, fromSVG, toSVG } = getTextTitleSVGs(estimateTotalType, pendingTotalType);
  const body = (
    <div className="flex flex-col gap-8">
      <div className="type-body1">{text}</div>
      <div className="flex flex-col gap-2">
        <div className="type-label">From</div>
        <img className="w-full" alt="Illustration of existing type" src={fromSVG} />
      </div>
      <div className="flex flex-col gap-2">
        <div className="type-label">To</div>
        <img className="w-full" alt="Illustration of pending new type" src={toSVG} />
      </div>
    </div>
  );
  return {
    title,
    body,
  };
};
