import { useQuery } from '@apollo/client';

import { NULL_ID } from '../constants';
import { FeatureFlagQuery, FeatureFlagQueryVariables } from '../generated/graphql';
import { getProjectIdFromUrl } from '../utilities/url';
import { useUserProfile } from '../utilities/userProfile';

import { CACHE_FIRST } from './constants';
import { featureFlagQuery } from './queries';

// See https://github.com/JoinCAD/komodo/wiki/Feature-Flags for a usage example.

// The incoming data here is an array of only the feature flags that are
// currently set to 'true' in the backend. Use the constants from
// `src/features.js` to ensure this function will match the name you're looking
// for. Alternative methods might involve constructing a different query for an
// object containing all available options. Here we filter just for one.
// Look at hasFeatureOption method
export default function useFeatureQuery() {
  const projectID = getProjectIdFromUrl() || NULL_ID;
  const userEmail = useUserProfile()?.email ?? '';

  return useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(featureFlagQuery, {
    variables: { projectID, userEmail },
    fetchPolicy: CACHE_FIRST,
  });
}

export function hasFeatureOption(loadFeatureFlags: string[] | undefined, option: string) {
  return (loadFeatureFlags || []).some((value) => value === option);
}

export function useHasFeature(key: string) {
  const result = useFeatureQuery();
  const flagValues = result.data?.loadFeatureFlags ?? result.previousData?.loadFeatureFlags;
  return hasFeatureOption(flagValues, key);
}
