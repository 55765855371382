import { useRef } from 'react';
import { useButton } from 'react-aria';

type Props = {
  setColor: (color: string) => void;
  color: string;
  isChosen: boolean;
  text?: string;
};

const ColorDialogButton = (props: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: () => props.setColor(props.color),
    },
    buttonRef
  );
  return (
    <button
      {...buttonProps}
      className={`flex h-36 w-36 items-center justify-center rounded-lg border transition-all ${
        props.isChosen
          ? 'border-4 !outline-4 !-outline-offset-4 hover:border-transparent hover:outline focus-visible:border-transparent focus-visible:outline'
          : 'hover:border-transparent hover:outline focus-visible:border-transparent focus-visible:outline'
      } `}
      ref={buttonRef}
      key={props.color}
      data-cy={props.color}
    >
      <div style={{ backgroundColor: props.color }} className="h-6 w-3/4">
        {props.text}
      </div>
    </button>
  );
};

export default ColorDialogButton;
