import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

export type PieChartSize = {
  diameter: number;
  insideDiameter?: number;
};

export type PieChartData = { name: string; share: number };

export const DEFAULT_EMPTY_VALUE = 'DEFAULT_EMPTY_VALUE';

export const generateChart = (
  svg: d3.Selection<SVGGElement, null, SVGSVGElement | null, unknown>,
  colors: string[],
  data: PieChartData[],
  chartSize: PieChartSize,
  centerLabel?: string,
  includeGradiant?: boolean
) => {
  const { diameter, insideDiameter } = chartSize;
  const radius = Math.min(diameter, diameter) / 2;

  // set the colors based on the data
  // note that the length of data, and colors should be the same
  const scale = d3.scaleOrdinal(data.map((d) => d.name)).range(colors);

  svg.attr('width', diameter).attr('height', diameter);

  const group = svg.append('g').attr('transform', `translate(${radius},${radius})`);

  // this adds a radial gradient that is dark on the inside, and light on the outside
  if (includeGradiant) {
    const defs = svg.append('svg:defs');
    const radialGradient = defs
      .append('radialGradient')
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('fx', '50%')
      .attr('fy', '50%')
      .attr('id', 'gradient');
    radialGradient.append('stop').attr('offset', '70%').style('stop-color', 'grey');
    radialGradient.append('stop').attr('offset', '90%').style('stop-color', 'white');
    radialGradient.append('stop').attr('offset', '100%').style('stop-color', 'white');
  }

  // create the chart from the data
  const pieData = d3
    .pie<PieChartData>()
    .value((d) => d.share)
    .sort(() => {
      // Disable the default sorting, and just use the order of the input data
      return 0;
    })(data);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  let gradient: d3.Arc<any, d3.PieArcDatum<PieChartData>> | undefined;
  if (includeGradiant) {
    gradient = d3
      .arc<PieArcDatum<PieChartData>>()
      .innerRadius(insideDiameter ? insideDiameter / 2 : 0) // Note: Inner Radius is for donut
      .outerRadius(0);
  }

  // create Arc with radius
  const createArc = d3
    .arc<PieArcDatum<PieChartData>>()
    .innerRadius(insideDiameter ? insideDiameter / 2 : 0) // Note: Inner Radius is for donut
    .outerRadius(radius);

  // Add groups for arcs
  const arcs = group.selectAll('arc').data(pieData).enter().append('g');

  arcs
    .append('path')
    .attr('class', 'arc')
    .attr('d', createArc)
    .attr('fill', (d) => scale(d.index.toString()));

  if (gradient) {
    // add a circle with a gradient
    svg
      .append('circle')
      .attr('cx', radius)
      .attr('cy', radius)
      .attr('r', radius)
      .style('opacity', 0.25)
      .style('fill', 'url(#gradient)');

    // add a white circle to block out the center of the gradient
    svg
      .append('circle')
      .attr('cx', radius)
      .attr('cy', radius)
      .attr('r', (insideDiameter ?? 0) / 2)
      .style('fill', 'white');
  }

  // add a label in the middle
  if (gradient)
    if (centerLabel)
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('transform', `translate(${diameter / 2},${diameter / 2 + 4})`)
        .style('font-size', 16)
        .style('font-weight', 700)
        .text(centerLabel);
};
