import { useState } from 'react';

import { ErrorOutline } from '@material-ui/icons';

import { EDIT_COMP_DESCRIPTION, ENTER } from '../../../constants';
import {
  PROJECT_COMP_DIALOG_COST_DESCRIPTION,
  PROJECT_COMP_DIALOG_COST_DESCRIPTION_ERROR,
} from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import Textarea from '../../Textarea/Textarea';
import styles from '../ProjectCompsStyles';

type ProjectCompsCostDescriptionDialogProps = {
  classes: Classes<typeof styles>;
  description?: string | null;
  isAverageComp: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
};

const ProjectCompsCostDescriptionDialog = ({
  classes,
  description: initialDescriptionValue,
  isAverageComp,
  onClose,
  onSave,
}: ProjectCompsCostDescriptionDialogProps) => {
  const [description, setDescription] = useState(initialDescriptionValue ?? '');

  const [showUnsavedChangesError, setShowUnsavedChangesError] = useState(false);

  const descriptionDialog = (
    <DialogsConfirm
      acceptCtaCopy="Update"
      body={
        <div data-cy={PROJECT_COMP_DIALOG_COST_DESCRIPTION}>
          <div className={classes.dialogParagraph}>
            {`Edit your description below. ${
              isAverageComp
                ? 'It will appear in your estimate or budget description column after you add it to a milestone.'
                : ''
            }`}
          </div>
          <Textarea
            onChange={(value) => setDescription(value)}
            placeholder={`Write a note to explain what’s included in this value. ${
              isAverageComp ? 'It will appear in the “description” column of your estimate.' : ''
            }`}
            value={description}
            onKeyPress={(e) => {
              if (e.key === ENTER && e.shiftKey && e.target instanceof HTMLTextAreaElement) {
                e.preventDefault();
                onSave(description);
                onClose();
              }
            }}
          />
        </div>
      }
      open={!showUnsavedChangesError}
      onClose={() => {
        if (!initialDescriptionValue || initialDescriptionValue === description) {
          onClose();
        } else {
          setShowUnsavedChangesError(true);
        }
      }}
      onConfirm={() => {
        onSave(description);
        onClose();
      }}
      title={EDIT_COMP_DESCRIPTION}
    />
  );

  const unsavedChangesDialog = (
    <DialogsConfirm
      acceptCtaCopy="Yes, cancel"
      cancelCtaCopy="Back"
      body={
        <div className={classes.dialogError} data-cy={PROJECT_COMP_DIALOG_COST_DESCRIPTION_ERROR}>
          <ErrorOutline color="error" />
          <div>You made changes to your description. Are you sure you want to cancel?</div>
        </div>
      }
      open={showUnsavedChangesError}
      onClose={() => setShowUnsavedChangesError(false)}
      onConfirm={() => {
        setShowUnsavedChangesError(false);
        onClose();
      }}
      title={EDIT_COMP_DESCRIPTION}
    />
  );

  return (
    <>
      {descriptionDialog}
      {unsavedChangesDialog}
    </>
  );
};

export default withStyles(styles)(ProjectCompsCostDescriptionDialog);
