import { FC } from 'react';

// @ts-ignore TODO CT-545
import { GradientDefs } from 'react-vis/dist';

import theme from '../../../theme/komodo-mui-theme';

const CostTrendGradientDefinition: FC = () => (
  <svg height={0} width={0}>
    <GradientDefs>
      <pattern id="stripes" width="4" height="4" patternUnits="userSpaceOnUse">
        <path d="M 0, 0 l 5, 5" stroke={`${theme.palette.pending}95`} strokeLinecap="square" />
      </pattern>
    </GradientDefs>
    <defs>
      <filter id="blur" x="0" y="0" width="100%" height="100%">
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
          result="matrix"
        />
        <feMorphology operator="dilate" radius="2" in="matrix" result="offOut" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="0.6" />
        <feBlend in="SourceGraphic" in2="blurOut" />
      </filter>
    </defs>
  </svg>
);

export default CostTrendGradientDefinition;
