import { FC, memo, useContext, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  GridVariant,
  isAccordionVariant,
  isItemEstimateVariant,
  makeGridAnalytics,
} from '../../actions/gridAnalytics';
import { gridSortDataVar } from '../../api/apollo/reactiveVars';
import {
  REFETCH_CHANGE_ITEM,
  REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  refetchItem,
} from '../../api/refetchSets';
import { PermissionResource } from '../../generated/graphql';
import { useDebounceFn } from '../../hooks';
import { SendAnalyticsFn } from '../../hooks/useAnalyticsEventHook';
import {
  getEnabledCategorizationsIDsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import { useRefetch } from '../../hooks/useRefetch';
import { withStyles } from '../../theme/komodo-mui-theme';
import { getGridType } from '../../utilities/analytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../utilities/costMode';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../utilities/permissions/usePermissions';
import JoinGridWrapper from '../JoinGrid/JoinGridWrapper';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';

import styles from './CostEstimateStyles';

type CostEstimateProps = {
  activeEstimateID?: UUID;
  canViewDetails: boolean;
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  errors?: ImportEstimateError[];
  isExpanded?: boolean;
  isItemEstimateView?: boolean;
  isOption?: boolean;
  itemId?: UUID;
  itemTrade?: Category[];
  hasOwnerCostEstimate?: boolean;
  onItemMutated?: () => void;
  projectID: UUID;
  refetchOuter?: () => void;
  sendAnalytics: SendAnalyticsFn;
  sendRefetch?: boolean;
  variant: GridVariant;
  viewFilter?: ViewFilterInput;
};

export const areEqual = (prevProps: CostEstimateProps, nextProps: CostEstimateProps) => {
  if (!prevProps.activeEstimateID || !nextProps.activeEstimateID) return false;
  if (
    prevProps.activeEstimateID !== nextProps.activeEstimateID ||
    prevProps.variant !== nextProps.variant ||
    prevProps.viewFilter !== nextProps.viewFilter ||
    prevProps.sendRefetch !== nextProps.sendRefetch
  ) {
    return false;
  }
  return true;
};

const CostEstimate: FC<CostEstimateProps> = ({
  activeEstimateID,
  canViewDetails,
  classes,
  clearFilters,
  errors,
  isExpanded,
  isItemEstimateView,
  isOption = false,
  itemId,
  itemTrade,
  hasOwnerCostEstimate = false,
  refetchOuter = () => {},
  onItemMutated,
  projectID,
  sendAnalytics = () => {},
  sendRefetch = false,
  variant,
  viewFilter,
}) => {
  // ANALYTICS
  const analytics = makeGridAnalytics(isOption, sendAnalytics, variant);

  // DATA + VARS
  const t = useContext(ProjectTermStore);
  const costMode = useCostMode();
  const sortData = useReactiveVar(gridSortDataVar);
  const { data, loading } = useProjectCategorizationsQuery(projectID, true); // needs to include all to refresh with mutation return
  const enabledCategorizationsIDs = getEnabledCategorizationsIDsForProjectFromQueryData(data);

  // REFETCHES
  const refetchSidebar = useRefetch(REFETCH_CHANGE_ITEM);
  const refetchSidebarWithNoItem = useRefetch(REFETCH_CHANGE_MILESTONE_EVENTS_LIST);
  const refetchCombined = () => {
    if (itemId) {
      refetchSidebar([refetchItem(itemId)]);
    }
    refetchSidebarWithNoItem();
    refetchOuter();
  };
  const refetchDebounced = useDebounceFn(refetchCombined, 1000);

  // PERMISSIONS
  const { canView, canEdit, inTrade } = usePermissions({ trades: itemTrade });
  let canEditLines: boolean;
  if (isItemEstimateVariant(variant) || !!itemId) {
    // item estimate
    canEditLines = canEdit(getItemLinesPermissionResource(inTrade));
  } else if (variant !== GridVariant.ITEM_TEMPLATE) {
    // milestone estimate
    canEditLines = canEdit(PermissionResource.MILESTONE_LINES);
  } else {
    // item template estimate
    canEditLines = false; // you can never edit item template lines
  }
  const canEditItemTemplate = canEdit(PermissionResource.ITEM_TEMPLATE);
  const canEditColumns = variant === GridVariant.ITEM_TEMPLATE ? canEditItemTemplate : canEditLines;
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const canEditMarkups =
    variant === GridVariant.ITEM_TEMPLATE
      ? canEditItemTemplate
      : canEdit(PermissionResource.MARKUPS);

  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);

  const collapseSizeRef = useRef(null);

  const gridType = getGridType(isOption);
  const isAccordion = isAccordionVariant(variant);

  if (loading) return null;

  if (isAccordion && activeEstimateID)
    return (
      <>
        <JoinGridWrapper
          t={t}
          sendRefetch={sendRefetch}
          gridType={gridType}
          estimateID={activeEstimateID}
          sortData={sortData}
          errors={errors}
          refetchOuter={refetchOuter}
          projectID={projectID}
          updateCostReports={() => {
            onItemMutated?.();
            refetchDebounced();
          }}
          variant={variant}
          collapseSizeRef={collapseSizeRef}
          permissions={{
            viewEstimate: canViewDetails,
            editLines: canEditLines,
            editColumns: canEditColumns,
            viewMarkups: checkCostModeIncludesMarkups(costMode) && canViewMarkups,
            summaryMarkups: checkCostModeIncludesMarkups(costMode) && !canViewMarkups,
            editMarkups: checkCostModeIncludesMarkups(costMode) && canEditMarkups,
            viewEstimateCostSubtotals:
              checkCostModeIncludesMarkups(costMode) && canViewEstimateCostSubtotals,
          }}
          clearFilters={clearFilters}
          viewFilter={viewFilter}
          analytics={analytics}
          enabledCategorizationsIDs={enabledCategorizationsIDs}
          isItemEstimateView={isItemEstimateView}
          isExpanded={isExpanded}
          hasOwnerCostEstimate={hasOwnerCostEstimate}
        />
        <div ref={collapseSizeRef} />
      </>
    );

  return (
    <div className={classes.root}>
      <div ref={collapseSizeRef}>
        {activeEstimateID && (
          <JoinGridWrapper
            t={t}
            sendRefetch={loading}
            gridType={gridType}
            enabledCategorizationsIDs={enabledCategorizationsIDs}
            estimateID={activeEstimateID}
            sortData={sortData}
            errors={errors}
            refetchOuter={refetchOuter}
            projectID={projectID}
            updateCostReports={() => {
              refetchDebounced();
            }}
            variant={variant}
            collapseSizeRef={collapseSizeRef}
            permissions={{
              viewEstimate: canViewDetails,
              editLines: canEditLines,
              editColumns: canEditColumns,
              viewMarkups: checkCostModeIncludesMarkups(costMode) && canViewMarkups,
              summaryMarkups: checkCostModeIncludesMarkups(costMode) && !canViewMarkups,
              editMarkups: checkCostModeIncludesMarkups(costMode) && canEditMarkups,
              viewEstimateCostSubtotals:
                checkCostModeIncludesMarkups(costMode) && canViewEstimateCostSubtotals,
            }}
            clearFilters={clearFilters}
            viewFilter={viewFilter}
            analytics={analytics}
            hasOwnerCostEstimate={hasOwnerCostEstimate}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(memo(CostEstimate, areEqual));
