import {
  FC,
  LegacyRef,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { GridVariant, isItemEstimateVariant } from '../../actions/gridAnalytics';
import { TermKey } from '../../api/gqlEnums';
import { RESIZE } from '../../constants';
import { NS_OWNER_COSTS } from '../../features';
import { ItemDrawInfo } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import { BUTTON_ESTIMATE_TABLE_ADD_LINE, BUTTON_MARKUP_TABLE_ADD_LINE } from '../../tagConstants';
import { ITEM_DETAILS_MAX_HEIGHT } from '../estimate/EstimateAccordion/EstimateTotalStyles';
import SectionCollapse from '../estimate/EstimateAccordion/SectionCollapse';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';

import { MarkupGridController } from './GridController';
import { useGetDoMilestoneMarkupsHaveContingencyDrawsLazyQuery } from './hooks/markupMutation';
import useUpdate from './hooks/useUpdate';
import { Grid, GridProps } from './JoinGrid';
import { ItemGridDisplayProps } from './JoinGridVirtualTable';
import MarkupDeletionConfirmationDialog from './MarkupDeletionConfirmationDialog';
import { GridController } from './types';
import { DEFAULT_COLLAPSE_HEIGHT, DEFAULT_ROW_HEIGHT } from './utilities/size';
import { getMarkupHeaderName } from './utils';

const calcNumTrue = (openBools: boolean[]) => openBools.filter((bool) => bool).length;
const markupHasRows = (grid: GridController | undefined, isItemEstimateView: boolean) =>
  isItemEstimateView ? grid && grid.numRows() > 0 : false;

export type SectionHeightProps = {
  numGrids: number;
  numOpen: number;
  startHeight?: number;
};

export const calcMaxSectionHeight = (
  props: SectionHeightProps | null | undefined,
  variant: GridVariant,
  max?: number
) => {
  const windowHeight = max || window.innerHeight;
  if (variant === GridVariant.ITEM_TEMPLATE) return windowHeight;
  if (!props) return undefined;
  const { numGrids, numOpen, startHeight = 0 } = props;
  const borderHeights = 3 * 2 + 1;
  const totalHeight =
    windowHeight -
    startHeight -
    numGrids * DEFAULT_COLLAPSE_HEIGHT -
    numOpen * DEFAULT_ROW_HEIGHT -
    borderHeights;
  return numOpen > 0 ? totalHeight / numOpen : undefined;
};

const calcDifference = (grid: GridController, maxSectionHeight?: number, isOpen = false) => {
  const totalSectionHeight = grid && grid.numRows() * DEFAULT_ROW_HEIGHT + DEFAULT_ROW_HEIGHT;
  return maxSectionHeight && maxSectionHeight - totalSectionHeight > DEFAULT_ROW_HEIGHT && isOpen
    ? maxSectionHeight - totalSectionHeight
    : 0;
};

type JoinGridAccordionProps = {
  canViewDetails: boolean;
  canViewMarkups: boolean;
  canViewMarkupsDetails: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  directRef: MutableRefObject<any>;
  editLines: boolean;
  editMarkups: boolean;
  gridData: {
    estimate: GridController;
    markup: MarkupGridController;
    incorporatedMarkups?: MarkupGridController;
    inheritedMarkups?: MarkupGridController;
    ownerCosts?: MarkupGridController;
    contingencyDraws?: Pick<ItemDrawInfo, 'id' | 'draw' | 'name' | 'error'>[];
    incorporatedDraws?: MarkupGridController;
  };
  gridType: GridProps['gridType'];
  hasWidth: boolean;
  isExpanded?: boolean;
  isFiltering: boolean;
  isItemEstimateView?: boolean;
  markupRef: LegacyRef<HTMLDivElement> | undefined;
  incorporatedMarkupRef: LegacyRef<HTMLDivElement> | undefined;
  incorporatedDrawRef: LegacyRef<HTMLDivElement> | undefined;
  hasOwnerCostEstimate: boolean;
  noDataPlaceholder?: ReactNode;
  variant: GridVariant;
};

const JoinGridAccordion: FC<JoinGridAccordionProps> = ({
  canViewMarkups,
  canViewMarkupsDetails,
  canViewDetails,
  directRef,
  editLines,
  editMarkups,
  gridData,
  gridType,
  hasWidth,
  isFiltering,
  isItemEstimateView = false,
  markupRef,
  incorporatedMarkupRef,
  incorporatedDrawRef,
  noDataPlaceholder,
  hasOwnerCostEstimate = false,
  isExpanded = false,
  variant,
}) => {
  const hasOwnerCostsAccess = useHasFeature(NS_OWNER_COSTS);
  const t = useContext(ProjectTermStore);
  const startRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useUpdate();

  const { markup, estimate, inheritedMarkups, incorporatedMarkups, incorporatedDraws, ownerCosts } =
    gridData;

  const isItem = isItemEstimateVariant(variant);

  // STATE
  const [directCostOpen, setDirectCostOpen] = useState(canViewDetails || isExpanded);
  const [markupOpen, setMarkupOpen] = useState(
    markupHasRows(markup, isItemEstimateView) || isExpanded
  );
  const [incorporatedMarkupOpen, setIncorporatedMarkupOpen] = useState(
    markupHasRows(incorporatedMarkups, isItemEstimateView) || isExpanded
  );
  const [incorporatedDrawsOpen, setIncorporatedDrawsOpen] = useState(
    markupHasRows(incorporatedDraws, isItemEstimateView) || isExpanded
  );
  const [inheritedMarkupOpen, setInheritedMarkupOpen] = useState(
    markupHasRows(inheritedMarkups, isItemEstimateView) || isExpanded
  );
  const [ownerCostsOpen, setOwnerCostsOpen] = useState(true);
  const currentStartFromTop = startRef.current?.offsetTop;
  const [isMarkupDeletionDialogOpen, setIsMarkupDeletionDialogOpen] = useState(false);

  // MARKUP DELETION VARIABLES
  const markupDeletionFunc = () => {
    markup.numSelectedRows = 0;
    markup.previouslySelectedRow = -1;
    markup.deleteLines();
    forceUpdate();
  };
  const [getDoMilestoneMarkupsHaveContingencyDraws] =
    useGetDoMilestoneMarkupsHaveContingencyDrawsLazyQuery({
      onCompleted: (res) => {
        if (res.getDoMilestoneMarkupsHaveContingencyDraws) {
          // we have to show the dialog to confirm that they want to delete this contingency with draws
          setIsMarkupDeletionDialogOpen(true);
        } else {
          // this isn't a contingency/there are no draws, so we're fine
          markupDeletionFunc();
        }
      },
    });

  // DISPLAY
  const startHeight = useMemo(() => currentStartFromTop, [currentStartFromTop]);
  // Once we drop the divider at Y, we set the height of the estimate section as
  // the height, minus the headers

  const numOpen = calcNumTrue([
    directCostOpen,
    markupOpen,
    incorporatedMarkupOpen,
    inheritedMarkupOpen,
  ]);
  const numGrids = calcNumTrue([!!markup, !!estimate, !!inheritedMarkups, !!incorporatedMarkups]);
  const sectionHeightProps: SectionHeightProps = {
    numGrids,
    numOpen,
    startHeight,
  };
  const maxSectionHeight = calcMaxSectionHeight(
    sectionHeightProps,
    variant,
    variant === GridVariant.ITEM_DETAILS ? ITEM_DETAILS_MAX_HEIGHT : undefined
  );

  const totalDifference =
    calcDifference(estimate, maxSectionHeight, directCostOpen) +
    calcDifference(markup, maxSectionHeight, markupOpen) +
    (inheritedMarkups
      ? calcDifference(inheritedMarkups, maxSectionHeight, inheritedMarkupOpen)
      : 0) +
    (incorporatedMarkups
      ? calcDifference(incorporatedMarkups, maxSectionHeight, incorporatedMarkupOpen)
      : 0);

  const displayProps: ItemGridDisplayProps = {
    maxSectionHeight,
    totalDifference,
  };

  // ACTIONS
  const resize = () => {
    window.dispatchEvent(new Event(RESIZE));
  };
  useEffect(resize, [directCostOpen, markupOpen, inheritedMarkupOpen]);

  // COMPONENTRY
  const estimateComps = (
    <>
      <SectionCollapse
        collapsed={!canViewDetails || !directCostOpen}
        disabled={!canViewDetails}
        setCollapse={() => {
          setDirectCostOpen(!directCostOpen);
        }}
        grid={estimate}
        term={t.titleCase(TermKey.DIRECT_COST)}
      />
      <div ref={startRef} />
      <div
        ref={directRef}
        className={`${'join-grid-fullscreen'} ${isItem ? '' : 'join-grid-fullscreen-flex'}`}
        data-cy="grid-component-direct-costs"
        style={{ display: !directCostOpen ? 'none' : undefined }}
      >
        {noDataPlaceholder ||
          (canViewDetails && hasWidth && (
            <Grid
              grid={estimate}
              gridType={gridType}
              buttons={
                editLines
                  ? {
                      id: BUTTON_ESTIMATE_TABLE_ADD_LINE,
                      isAddDisabled: isFiltering,
                      onAddClick: () => {
                        estimate.addLine('Button', estimate.scrollToBottom);
                        forceUpdate();
                      },
                      onDeleteClick: () => {
                        estimate.numSelectedRows = 0;
                        estimate.previouslySelectedRow = -1;
                        estimate.deleteLines();
                        forceUpdate();
                      },
                      tooltip: isFiltering
                        ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                        : '',
                    }
                  : undefined
              }
              itemGridDisplayProps={{ ...displayProps, isDirectCost: true }}
            />
          ))}
      </div>
    </>
  );

  const markupsComps = (
    <>
      <SectionCollapse
        collapsed={!canViewMarkupsDetails || !markupOpen}
        disabled={!canViewMarkupsDetails}
        grid={markup}
        setCollapse={() => {
          setMarkupOpen(!markupOpen);
        }}
        term={getMarkupHeaderName(t, !isItem)}
      />
      <div
        ref={markupRef}
        style={{ display: !markupOpen ? 'none' : undefined }}
        className="join-grid-fullscreen"
        data-cy="grid-component-markups"
      >
        {canViewMarkupsDetails && hasWidth && (
          <Grid
            grid={markup}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
            buttons={
              editMarkups
                ? {
                    id: BUTTON_MARKUP_TABLE_ADD_LINE,
                    isAddDisabled: isFiltering,
                    onAddClick: () => {
                      markup.addLine('Button', markup.scrollToBottom);
                      forceUpdate();
                    },
                    onDeleteClick: () => {
                      const selectedMarkupIDs = markup.data.lines
                        .filter((_, i) => markup.isRowSelectedArr[i])
                        .map((row) => row.id);

                      if (markup.estimateID && markup.projectID && selectedMarkupIDs.length) {
                        getDoMilestoneMarkupsHaveContingencyDraws({
                          variables: {
                            estimateID: markup.estimateID,
                            projectID: markup.projectID,
                            markupIDs: selectedMarkupIDs,
                          },
                        });
                      }
                    },
                    tooltip: isFiltering
                      ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                      : '',
                  }
                : undefined
            }
          />
        )}
      </div>
    </>
  );

  const inheritedMarkupsComps = inheritedMarkups && (
    <>
      <SectionCollapse
        collapsed={!inheritedMarkupOpen}
        disabled={!canViewMarkupsDetails}
        grid={inheritedMarkups}
        setCollapse={() => {
          setInheritedMarkupOpen(!inheritedMarkupOpen);
        }}
        term={getMarkupHeaderName(t, true)}
        termSuffix={` - [${inheritedMarkups.milestoneName}]`}
      />
      <div
        style={{ display: !inheritedMarkupOpen ? 'none' : undefined }}
        className="join-grid-fullscreen"
        data-cy="grid-component-markups"
      >
        {hasWidth && (
          <Grid grid={inheritedMarkups} gridType={gridType} itemGridDisplayProps={displayProps} />
        )}
      </div>
    </>
  );

  const incorporatedMarkupsComps = incorporatedMarkups && (
    <>
      <SectionCollapse
        collapsed={!incorporatedMarkupOpen}
        disabled={!canViewMarkupsDetails}
        grid={incorporatedMarkups}
        setCollapse={() => {
          setIncorporatedMarkupOpen(!incorporatedMarkupOpen);
        }}
        term={`Incorporated Item ${t.titleCase(TermKey.MARKUP)}`}
      />
      <div
        ref={incorporatedMarkupRef}
        style={{ display: !incorporatedMarkupOpen ? 'none' : undefined }}
        className="join-grid-fullscreen"
        data-cy="grid-component-incorporated-markups"
      >
        {hasWidth && (
          <Grid
            grid={incorporatedMarkups}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
            buttons={
              editMarkups
                ? {
                    onDeleteClick: () => {
                      incorporatedMarkups.numSelectedRows = 0;
                      incorporatedMarkups.previouslySelectedRow = -1;
                      incorporatedMarkups.deleteLines();
                      forceUpdate();
                    },
                  }
                : undefined
            }
          />
        )}
      </div>
    </>
  );
  const incorporatedDrawsComps = incorporatedDraws && (
    <>
      <SectionCollapse
        collapsed={!incorporatedDrawsOpen}
        disabled={!canViewMarkupsDetails}
        grid={incorporatedDraws}
        setCollapse={() => {
          setIncorporatedDrawsOpen(!incorporatedDrawsOpen);
        }}
        term="Incorporated Item Draws"
      />
      <div
        ref={incorporatedDrawRef}
        style={{ display: !incorporatedDrawsOpen ? 'none' : undefined }}
        className="join-grid-fullscreen"
        data-cy="grid-component-incorporated-markups"
      >
        {hasWidth && (
          <Grid
            grid={incorporatedDraws}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
            buttons={
              editMarkups
                ? {
                    onDeleteClick: () => {
                      incorporatedDraws.numSelectedRows = 0;
                      incorporatedDraws.previouslySelectedRow = -1;
                      incorporatedDraws.deleteLines();
                      forceUpdate();
                    },
                  }
                : undefined
            }
          />
        )}
      </div>
    </>
  );

  const ownerCostsComp = hasOwnerCostsAccess && ownerCosts && (
    <>
      <SectionCollapse
        collapsed={!ownerCostsOpen}
        setCollapse={() => {
          setOwnerCostsOpen(!ownerCostsOpen);
        }}
        grid={ownerCosts}
        disabled={false}
        term="Owner Costs"
      />
      <div style={{ display: !ownerCostsOpen ? 'none' : undefined }}>
        {hasWidth && (
          <Grid
            grid={ownerCosts}
            gridType={gridType}
            buttons={
              editMarkups
                ? {
                    id: BUTTON_MARKUP_TABLE_ADD_LINE,
                    isAddDisabled: isFiltering,
                    onAddClick: () => {
                      if (hasOwnerCostEstimate) {
                        ownerCosts.addLine('Button', ownerCosts.scrollToBottom);
                      } else if (ownerCosts.createOwnerCostEstimate) {
                        ownerCosts.createOwnerCostEstimate();
                      }
                      forceUpdate();
                    },
                    onDeleteClick: () => {
                      ownerCosts.numSelectedRows = 0;
                      ownerCosts.previouslySelectedRow = -1;
                      ownerCosts.deleteLines();
                      forceUpdate();
                    },
                    tooltip: isFiltering
                      ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                      : '',
                  }
                : undefined
            }
          />
        )}
      </div>
    </>
  );

  const markupsSection = (
    // generically, we want to use the content height
    <>
      {incorporatedMarkupsComps}
      {markupsComps}
      {inheritedMarkupsComps}
      {incorporatedDrawsComps}
      <MarkupDeletionConfirmationDialog
        onClose={() => setIsMarkupDeletionDialogOpen(false)}
        isOpen={isMarkupDeletionDialogOpen}
        onConfirm={markupDeletionFunc}
      />
    </>
  );

  return (
    <div>
      {estimateComps}
      {canViewMarkups && markupsSection}
      {ownerCostsComp}
    </div>
  );
};

export default JoinGridAccordion;
