import { useMutation } from '@apollo/client';

import {
  UpsertProjectCompanyRoleMutation,
  UpsertProjectCompanyRoleMutationVariables,
} from '../../../generated/graphql';

import { upsertProjectCompanyRole } from './queries';

export const useUpsertProjectCompanyRole = () => {
  const [upsertFunc] = useMutation<
    UpsertProjectCompanyRoleMutation,
    UpsertProjectCompanyRoleMutationVariables
  >(upsertProjectCompanyRole);
  return (projectID: UUID, companyID: UUID, role: string) => {
    upsertFunc({
      variables: { projectID, companyID, role },
    });
  };
};
