import { FC } from 'react';

import { getOperationName } from '@apollo/client/utilities';
import { Typography } from '@material-ui/core';
import { Add, Help } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { CollaboratorInput } from '../../generated/graphql';
import { currentCollaborator } from '../../hooks/queries';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import useIsJoinSupportQuery from '../../hooks/useIsJoinSupportQuery';
import theme, { withStyles } from '../../theme/komodo-mui-theme';
import { isNonNullable } from '../../utilities/types';
import { getProjectIdFromUrl } from '../../utilities/url';
import useCreateCollaborators from '../Collaborators/hooks/CreateCollaboratorsHook';
import { viewOnlyTooltip } from '../CompanyTab/CompanyTabUtils';
import { useCurrentUserQuery } from '../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import CTAIconButton from '../shared-widgets/CTAIconButton';

import styles from './FrameTitleBar/FrameTitlePreviewBarStyles';

type AdminViewOnlyBarProps = {
  classes: Classes<typeof styles>;
};

const AdminViewOnlyBar: FC<AdminViewOnlyBarProps> = ({ classes }) => {
  const [createCollaborators] = useCreateCollaborators(
    [getOperationName(currentCollaborator)].filter(isNonNullable) // only include if operation name exists
  );

  const projectId = getProjectIdFromUrl();

  const isJoinSupport = useIsJoinSupportQuery();
  const { data } = useCurrentUserQuery();
  const sendAnalytics = useAnalyticsEventHook();

  const newCollaboratorInput: CollaboratorInput | undefined = data?.currentUser.email
    ? {
        userEmail: data?.currentUser.email,
        allTrades: true,
        roleName: 'Administrator',
        responsibility: '',
        trades: [],
      }
    : undefined;

  return (
    <div className={classes.bar}>
      <Typography className={classes.mode} data-cy="view-only-bar">
        VIEW-ONLY MODE
      </Typography>
      <div className={classes.info}>
        <NormalTooltip title={viewOnlyTooltip}>
          <Help />
        </NormalTooltip>
      </div>

      {newCollaboratorInput && (
        <div className={classes.addAdminButton}>
          <NormalTooltip
            title={
              isJoinSupport
                ? 'You are part of the Join Support company and cannot add yourself to the project'
                : ''
            }
          >
            <div>
              <CTAIconButton
                buttonText="Add yourself as Project Administrator"
                color={theme.palette.pending}
                disabled={isJoinSupport}
                icon={<Add />}
                isSmaller
                colorVariant
                onClick={() => {
                  createCollaborators(projectId, [newCollaboratorInput], '');
                  sendAnalytics(
                    companyAdminAnalyticsEvent(CompanyAdminEventType.ADD_PROJECT_ADMIN)
                  );
                }}
              />
            </div>
          </NormalTooltip>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(AdminViewOnlyBar);
