import { FC, useEffect, useState } from 'react';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { LOCALSTORAGE_COMPANY_USERS_SORT } from '../../../constants';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { useCompanyUsersQuery } from '../../Companies/hooks/GetCompaniesUsersQuery';
import { useCompanyQuery } from '../../login/Signup/SignupPages/CreateCompanyHooks';
import { useCompanyProjectRolesQuery, useCompanyRolesQuery } from '../CompanyHooks';
import {
  filterCompanyUsers,
  getFilteredUserCount,
  useCompanyRoutesData,
  useSort,
} from '../CompanyTabUtils';

import CompanyTabMembers from './CompanyTabMembers';

const CompanyMembersTableData: FC = () => {
  const { companyID, filterManager, searchManager } = useCompanyRoutesData();
  const { filterStatus, setFilterStatus } = filterManager;

  const sortManager = useSort(LOCALSTORAGE_COMPANY_USERS_SORT, CompanyAdminView.MEMBERS_LIST);

  const { data: { company } = { company: undefined } } = useCompanyQuery(companyID);
  const { data: { companyProjectRoles } = { companyProjectRoles: [] } } =
    useCompanyProjectRolesQuery(companyID);
  const { data: { companyUser } = { companyUser: undefined }, loading: companyLoading } =
    useCompanyUserQuery();

  const {
    data: { companyUsers: rawCompanyUsers } = { companyUsers: [] },
    loading: companyUsersLoading,
  } = useCompanyUsersQuery(companyID, sortManager.sortState as CompanyUserSortBy);
  const [companyUsers, setCompanyUsers] = useState(rawCompanyUsers);
  useEffect(() => {
    if (!companyUsersLoading && rawCompanyUsers) setCompanyUsers(rawCompanyUsers);
  }, [rawCompanyUsers, companyUsersLoading]);

  const { data: { companyRoles } = { roles: undefined }, loading: companyRolesLoading } =
    useCompanyRolesQuery(companyID);

  if (companyLoading || companyRolesLoading || !companyUser || !companyRoles) return null;

  const countTotalMembers = companyUsers?.length ?? 0;

  const filteredCompanyUsers = filterCompanyUsers(companyUsers, filterStatus);

  const counts = getFilteredUserCount(companyUsers);

  const userCounts = [counts.all, counts.active, counts.deactivated, counts.pending];

  if (!companyID || !company) return null;
  return (
    <CompanyTabMembers
      countTotalMembers={countTotalMembers}
      companyRoles={companyRoles}
      company={company}
      companyProjectRoles={companyProjectRoles}
      companyUsers={filteredCompanyUsers}
      loading={companyUsersLoading}
      sortManager={sortManager}
      filterStatus={filterStatus}
      setFilterStatus={setFilterStatus}
      userCounts={userCounts}
      searchManager={searchManager}
    />
  );
};

export default CompanyMembersTableData;
