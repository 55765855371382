import { parseAndValidateDate } from '../../../utilities/dates';
import { Select, SelectEntry } from '../../scales';

type MilestoneTimelinePhaseSelectorProps = {
  disabled?: boolean;
  selectedValue?: string;
  milestoneID: UUID;
  startDate?: string | null;
  setSelection: (id: UUID) => void;
  phases: TimelineActivity[];
};

export default function MilestoneTimelinePhaseSelector({
  disabled,
  selectedValue,
  milestoneID,
  startDate,
  phases,
  setSelection,
}: MilestoneTimelinePhaseSelectorProps) {
  const start = parseAndValidateDate(startDate);

  const isBelongsToValid = (p: TimelineActivity) => {
    const phaseStart = parseAndValidateDate(p.startDate);
    const phaseEnd = parseAndValidateDate(p.endDate);
    return start && phaseStart && phaseEnd && start >= phaseStart && start <= phaseEnd;
  };

  const entries: SelectEntry[] = [
    { id: 'none', label: 'None' },
    ...(phases
      .map((p) => {
        if (p.milestone?.id === milestoneID) return undefined;
        return {
          id: p.id,
          label: isBelongsToValid(p) ? p.name : `${p.name} (Date outside of this phase)`,
          disabled: !isBelongsToValid(p),
        };
      })
      .filter((a) => a !== undefined) as SelectEntry[]),
  ];

  return (
    <Select
      data-cy="dropdown-timelinePhase"
      entries={entries}
      isDisabled={disabled}
      onChange={(id) => setSelection(id)}
      value={selectedValue}
    />
  );
}
