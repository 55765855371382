import { useMutation } from '@apollo/client';

import {
  MutationUpdateMarkupsArgs,
  UpdateMarkupsMutationVariables,
} from '../../../generated/graphql';
import { updateMarkupsMutation } from '../../JoinGrid/hooks/queries';

export function useUpdateMarkups() {
  const [updateMarkups] = useMutation<MutationUpdateMarkupsArgs, UpdateMarkupsMutationVariables>(
    updateMarkupsMutation
  );
  return (
    projectID: UUID,
    estimate: UUID,
    markups: MarkupInput[],
    viewFilter?: ViewFilter,
    onSuccess?: () => void,
    analytics?: () => void
  ) => {
    return updateMarkups({
      variables: { projectID, estimate, markups, viewFilter },
    }).then(() => {
      if (onSuccess) onSuccess();
      if (analytics) analytics();
    });
  };
}
