import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { tableStyles } from '../../../theme/table-styles';

const styles = (theme: KomodoTheme) => {
  const { cell } = tableStyles(theme);
  return createStyles({
    avatar: {
      marginLeft: 8,
    },
    button: {
      cursor: 'pointer',
      '& > svg': {
        cursor: 'pointer',
      },
    },
    cell: {
      ...cell,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      maxWidth: 'none',
      wordBreak: 'break-all',
      padding: `${theme.spacing.generic[3]}px`,
      textAlign: 'left',
      '& > button': {
        background: 'none',
        border: 'none',
      },
    },
    cellTradeAndDelete: {
      ...theme.typography.chip,
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'space-between',
    },
    companyGridRow: {
      borderBottom: theme.border.line,
      display: 'grid',
      gridTemplateColumns: '1fr 3fr 3fr 1fr 3fr', // THIS IS THE LAYOUT FOR THE ENTIRE COMPANIES TABLE}
    },
    containerFilters: {
      '@media screen and (max-width: 900px)': {
        width: '100%',
      },
    },
    containerInputs: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },
    containerOuter: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderBottom: '0px',
      minWidth: 700, // setting a min width because the original design wasn't built for mobile
    },
    containerRow: {
      borderBottom: theme.border.line,
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 2fr 3fr 3fr 4fr', // THIS IS THE LAYOUT FOR THE ENTIRE COLLABORATORS TABLE
    },
    containerRowHeader: {
      borderBottom: theme.border.line,
      backgroundColor: theme.palette.ds.background.primary,
    },
    containerSearchBar: {
      flex: '1 1 auto',
      '@media screen and (max-width: 900px)': {
        paddingTop: theme.spacing.generic[3],
        width: '100%',
      },
    },
    containerTextarea: {
      display: 'inline-flex',
    },
    containerTop: {
      padding: theme.spacing.generic[3],
      minHeight: 42,
    },
    disabled: {
      pointerEvents: 'none',
    },
    headerCell: {
      background: 'none',
      cursor: 'pointer',
      fontWeight: 500,
    },
    iconSortArrow: {
      fill: theme.palette.joinGrey400,
      position: 'relative',
      top: '-1px',
      width: '16px',
    },
    iconSortArrowActive: {
      fill: theme.palette.primaryBlue,
      width: '16px',
    },
    iconSortArrowFlipped: {
      transform: 'rotate(180deg)',
    },
    image: {
      maxHeight: 50,
      width: 144,
      objectFit: 'contain',
    },
    link: {
      color: theme.palette.link,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    pendingState: {
      opacity: 0.5,
    },
    roleSelect: {
      width: 166,
    },
    text: { width: '100%' },
    textarea: {
      border: theme.border.line,
      borderRadius: '2px',
      padding: '9px',
      resize: 'none',
    },
  });
};

export default styles;
