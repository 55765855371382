import { Label } from '../common/Label';

import RelevantProject from './RelevantProject';

interface RelevantProjectsProps {
  onClick: () => void;
  projects: ForecastingProject[];
}

const RelevantProjects = (props: RelevantProjectsProps) => (
  <>
    <Label>Relevant projects</Label>
    {!props.projects.length && (
      <div style={{ padding: 4 }}>No results found. Try adjusting your search.</div>
    )}
    {props.projects.slice(0, 5).map((project) => (
      <RelevantProject key={project.id} onClick={props.onClick} project={project} />
    ))}
  </>
);

export default RelevantProjects;
