import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    style={{ transform: 'scale(0.8) translate(0px, -2px)' }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8576 6.45818L16.0382 2.63873L12.2188 6.45818H15.4132V15.3471C15.4132 15.6943 15.1354 15.9721 14.7882 15.9721H4.16319C3.74653 15.9721 3.46875 16.2498 3.46875 16.6665C3.46875 17.0832 3.74653 17.361 4.16319 17.361H14.7882C15.8993 17.361 16.8021 16.4582 16.8021 15.3471V6.45818H19.8576Z"
      fill="black"
    />
    <path
      d="M3.53906 13.6111C3.53906 14.0277 3.81684 14.3055 4.23351 14.3055H12.9141C13.3307 14.3055 13.6085 14.0277 13.6085 13.6111C13.6085 13.1944 13.3307 12.9166 12.9141 12.9166H4.23351C3.88628 12.9166 3.53906 13.1944 3.53906 13.6111Z"
      fill="black"
    />
    <path
      d="M4.16319 11.5276H9.71875C10.1354 11.5276 10.4132 11.2498 10.4132 10.8332C10.4132 10.4165 10.1354 10.1387 9.71875 10.1387H4.16319C3.74653 10.1387 3.46875 10.4165 3.46875 10.8332C3.46875 11.2498 3.81597 11.5276 4.16319 11.5276Z"
      fill="black"
    />
    <path
      d="M4.16319 8.81931H8.74653C9.16319 8.81931 9.44097 8.54153 9.44097 8.12486C9.44097 7.7082 9.16319 7.43042 8.74653 7.43042H4.16319C3.74653 7.43042 3.46875 7.7082 3.46875 8.12486C3.46875 8.54153 3.81597 8.81931 4.16319 8.81931Z"
      fill="black"
    />
  </SvgIcon>
);
