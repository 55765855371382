import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { Add, Bookmark, Loop } from '@material-ui/icons';

import { userReportEvent } from '../../../analytics/analyticsEventProperties';
import { currentUserReportVar, setReactiveLocal } from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { PermissionResource, UserReportType } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeDefaultLevelNames } from '../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import usePermissions from '../../../utilities/permissions/usePermissions';
import {
  getMilestoneIdFromUrl,
  getProjectIdFromUrl,
  isBreakdownsReport,
} from '../../../utilities/url';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import DialogsReportsData from '../../Dialogs/DialogsReports/DialogsReportsData';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { DefaultReports, reportForAnalytics } from '../config';
import { useListProjectUserReports } from '../ReportHooks';

import CurrentReportBar from './CurrentReportBar';
import styles from './ReportManagerMenuStyles';
import { MapReportManagerPagesToValidReports, ReportManagerPages } from './utils';

// these are the pages that are valid for the reports manager menu

type ReportManagerMenu = {
  classes: Classes<typeof styles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  setSettings: SetSettingsFunctionType;
  variant: ReportManagerPages;
  isViewOnly?: boolean;
};

// used to set or find the anchor element for the report bar
export const ReportManagerAnchors = (key: 'breakdowns' | 'app'): string => `${key}-anchor`;

// TODO - type of set of anchors...

const ReportsManagerMenu: FC<ReportManagerMenu> = ({
  classes,
  settings,
  setSettings,
  variant,
  isViewOnly,
}) => {
  const t = useContext(ProjectTermStore);
  const projectID = getProjectIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const currentReport = useReactiveVar(currentUserReportVar);
  const path = window.location.pathname;
  const anchorBarElID = useMemo(
    () =>
      isBreakdownsReport(path) ? ReportManagerAnchors('breakdowns') : ReportManagerAnchors('app'),
    [path]
  );
  const anchorBarEl = document.getElementById(anchorBarElID);

  const [reportModalOpen, setReportModalOpen] = useState(false);
  let validReportTypes = MapReportManagerPagesToValidReports[variant];
  if (
    currentReport &&
    validReportTypes.some((reportType) => reportType === currentReport.reportType)
  ) {
    validReportTypes = [currentReport.reportType];
  }
  const getNewReport = () => {
    const newReport: UserReportInput = {
      reportType: validReportTypes[0],
      shared: false,
      name: '',
      description: '',
      settings: JSON.stringify(settings),
    };
    if (milestoneID) newReport.milestoneID = milestoneID;
    return newReport;
  };
  const [inputReport, setInputReport] = useState<UserReportInput>(getNewReport());

  useEffect(() => {
    setInputReport((iR) => ({ ...iR, settings: JSON.stringify(settings) }));
  }, [settings]);

  const {
    data: { project },
    loading: loadingProjectProps,
  } = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT);
  const name = project?.activeMilestone.name ?? '';

  const {
    data: { userReports } = {
      userReports: [],
    },
    loading: loadingUserReports,
  } = useListProjectUserReports(projectID);
  const loading = loadingProjectProps || loadingUserReports;

  const filteredCustomReports: UserReport[] = useMemo(() => {
    const isValidMSR = (r: UserReport) => {
      if (r.reportType !== UserReportType.USER_REPORT_MSR) return true;
      if (r.milestoneID && milestoneID && r.milestoneID === milestoneID) return true;
      return false;
    };
    if (!loading) {
      return userReports.filter((r) => validReportTypes.includes(r.reportType) && isValidMSR(r));
    }
    return [];
  }, [loading, userReports, validReportTypes, milestoneID]);

  const { canView } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);

  const { data: catData, loading: catzLoading } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);

  const defaultReports = useMemo(
    () => DefaultReports(name, t.titleCase, canViewMarkups, defaultGroupBy),
    [name, t, canViewMarkups, defaultGroupBy]
  );

  if (loading || catzLoading) return <LinearProgress hidden={!loading} />;

  const viewSavedSuboptions: MenuOption[] = [];

  const switchReport = (r: UserReport): void => {
    setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r);
    setSettings(JSON.parse(r.settings));
  };

  const sendSwitchEvent = (r: UserReport) =>
    sendAnalytics(
      userReportEvent('userReportMenu_applySwitchReport', {
        page: variant,
        report: reportForAnalytics(r),
      })
    );
  filteredCustomReports.forEach((r) =>
    viewSavedSuboptions.push({
      key: r.id,
      name: r.name,
      callback: () => switchReport(r),
      cy: `button-report-${r.name}`,
      onClick: () => sendSwitchEvent(r),
    })
  );

  defaultReports
    .filter(
      (r) =>
        validReportTypes.includes(r.reportType) &&
        r.reportType !== UserReportType.USER_REPORT_ITEMS_LIST_DETAILS
    )
    .forEach((r) => {
      viewSavedSuboptions.push({
        key: r.id,
        name: r.name,
        callback: () => switchReport(r),
        onClick: () => sendSwitchEvent(r),
        cy: `button-report-${r.name}`,
      });
    });

  const options: MenuOption[] = [];
  if (viewSavedSuboptions.length > 0)
    options.push({
      icon: <Loop />,
      name: 'Switch to saved report',
      callback: () => undefined,
      subOptions: viewSavedSuboptions,
      onClick: () =>
        sendAnalytics(userReportEvent('userReportMenu_switchReport', { page: variant })),
    });
  if (!isViewOnly)
    options.push({
      icon: <Add />,
      name: 'Create new report',
      callback: () => {
        setInputReport(getNewReport());
        setReportModalOpen(true);
      },
      onClick: () => sendAnalytics(userReportEvent('userReportMenu_saveReport', { page: variant })),
      cy: 'button-saveReport',
    });
  return (
    <div className={classes.containerOuter}>
      {(viewSavedSuboptions.length > 0 || !isViewOnly) && (
        <IconMenu
          isBottomOriented
          options={options}
          onClick={() => sendAnalytics(userReportEvent('userReportMenu_open', { page: variant }))}
          icon={<Bookmark />}
          cy="button-reportsManager"
        />
      )}
      <DialogsReportsData
        allowableReportTypes={validReportTypes}
        inputReport={inputReport}
        setInputReport={setInputReport}
        open={reportModalOpen}
        onClose={() => {
          setInputReport(getNewReport());
          setReportModalOpen(false);
        }}
        onSuccess={(r) => setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r)}
        variant="reports-manager"
      />
      {anchorBarEl && (
        <CurrentReportBar
          anchorEl={anchorBarEl}
          onCreate={() => setReportModalOpen(true)}
          settings={settings}
          setSettings={setSettings}
          variant={variant}
          isViewOnly={isViewOnly}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ReportsManagerMenu);
