import { useQuery } from '@apollo/client';

import { ModuleEntitlementsQuery, ModuleEntitlementsQueryVariables } from '../generated/graphql';

import { CACHE_FIRST } from './constants';
import { moduleEntitlementsQuery } from './queries';

export default function useModuleEntitlementsQuery(companyID?: UUID) {
  return useQuery<ModuleEntitlementsQuery, ModuleEntitlementsQueryVariables>(
    moduleEntitlementsQuery,
    {
      variables: { companyID },
      fetchPolicy: CACHE_FIRST,
    }
  );
}

export function hasModuleEntitlement(
  loadModuleEntitlementFlags: string[] | undefined,
  option: string
) {
  return (loadModuleEntitlementFlags || []).some((value) => value === option);
}
