import { ReactiveVar } from '@apollo/client';

import {
  NULL_ID,
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_PLUS_SIGN_AFTER,
} from '../constants';

export const onNextPage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  paginationVar: ReactiveVar<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  offsetIdsVar: ReactiveVar<any>,
  currentOffsetID: UUID,
  newOffsetID: UUID
) => {
  if (newOffsetID === null || newOffsetID === NULL_ID) return;
  const previousOffsetIds = [...offsetIdsVar()];
  previousOffsetIds.push(currentOffsetID);
  offsetIdsVar(previousOffsetIds);
  paginationVar({
    ...paginationVar(),
    offsetID: newOffsetID,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const onPrevPage = (paginationVar: ReactiveVar<any>, offsetIdsVar: ReactiveVar<any>) => {
  const previousOffsetIds = [...offsetIdsVar()];
  const offsetID = previousOffsetIds.pop();
  offsetIdsVar(previousOffsetIds);
  paginationVar({
    ...paginationVar(),
    offsetID,
  });
};

export const getTotalPlusText = (total: number, plusSignAfter: number) =>
  total >= plusSignAfter ? `${plusSignAfter}+` : total;

export const getPageCounterText = (
  index: number,
  total: number,
  perPage: number = PAGINATION_DEFAULT_LIMIT,
  plusSignAfter: number = PAGINATION_DEFAULT_PLUS_SIGN_AFTER
) => {
  const totalPlus = getTotalPlusText(total, plusSignAfter);
  if (total === 0) return '0';
  if (total < perPage) return `${total} of ${total}`;
  const pageNumber = index + 1;
  const from = pageNumber * perPage - (perPage - 1);
  const to = Math.min(from + perPage - 1, total);
  if (from === to) return `${from} of ${totalPlus}`;
  return `${from} - ${to} of ${totalPlus}`;
};
