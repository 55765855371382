import { ComponentProps, useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import MilestonePresentationColumn from '../../Scenarios/Sandbox/Presentation/MilestonePresentationColumn';
import SandboxPresentationColumn from '../../Scenarios/Sandbox/Presentation/SandboxPresentationColumn';
import { getShouldGrow } from '../../Scenarios/Sandbox/Presentation/SandboxPresentationViewUtils';
import ScenariosChartData from '../../Scenarios/Sandbox/Presentation/ScenariosChart/ScenariosChartData';
import SandboxView from '../../Scenarios/Sandbox/SandboxView';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import { LandscapeOrientation } from '../PrintUtils';

import PrintScenariosSandboxSubheader from './PrintScenariosSandboxSubheader';
import { getColumnGroups, printScenarioWidth } from './PrintViewScenariosSandboxUtils';

type Props = Pick<
  ComponentProps<typeof SandboxView>,
  'milestoneName' | 'sandbox' | 'showMilestone' | 'milestone'
> & {
  hooksLoading: boolean;
  triggerOnComplete: () => void;
};

export default function PrintViewScenariosSandbox(props: Props) {
  const projectID = getProjectIdFromUrl();
  const shouldGrow = getShouldGrow(props.showMilestone, props.sandbox.scenarios.length);
  const columnGroups = getColumnGroups(
    props.sandbox.scenarios,
    props.milestone,
    props.showMilestone
  );
  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectID);
  const projectName = project?.name;
  const { milestoneName } = props;

  const [headerLoaded, setHeaderLoaded] = useState(false);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [milestoneLoaded, setMilestoneLoaded] = useState(!props.showMilestone);
  const [thumbsToLoad, setThumbsToLoad] = useState(
    props.sandbox.scenarios.filter((s) => !!s.thumbnailAssetID).length
  );
  const thumbsLoaded = thumbsToLoad === 0;
  const { triggerOnComplete } = props;
  useEffect(() => {
    if (thumbsLoaded && headerLoaded && milestoneLoaded && chartLoaded && !projectLoading) {
      triggerOnComplete();
    }
  }, [headerLoaded, thumbsLoaded, triggerOnComplete, milestoneLoaded, chartLoaded, projectLoading]);

  // Title the page for better clarity and print headers
  useEffect(() => {
    if (projectName) document.title = `${projectName} - Scenarios - ${milestoneName}`;
  }, [milestoneName, projectName]);

  const header = (
    <div className="flex w-full flex-col">
      <PrintPageHeaderWrapper
        projectId={projectID}
        reportName="Scenarios"
        milestoneName={milestoneName}
        triggerOnComplete={() => {
          setHeaderLoaded(true);
        }}
      />
      <PrintScenariosSandboxSubheader />
    </div>
  );

  const onLoad = () => {
    setThumbsToLoad((prev) => prev - 1);
  };

  return (
    <div style={{ width: printScenarioWidth }}>
      {props.hooksLoading && <LinearProgress />}
      {header}
      <LandscapeOrientation />
      <div className="flex flex-col pt-16">
        <ScenariosChartData
          height={700}
          onLoad={() => {
            setChartLoaded(true);
          }}
          sandbox={props.sandbox}
          milestoneName={props.milestoneName}
          showMilestone={props.showMilestone}
        />
        <div className="flex flex-col gap-5">
          {columnGroups.map((g) => (
            <>
              <div>
                <PrintPageBreak pageWidth={printScenarioWidth} />
              </div>
              {header}
              <div className="flex w-full gap-5">
                {g.map((c) => {
                  if (c.__typename === 'MilestoneColumn') {
                    return (
                      <MilestonePresentationColumn
                        key={c.milestoneID}
                        milestone={c}
                        milestoneName={props.milestoneName}
                        onLoad={() => {
                          setMilestoneLoaded(true);
                        }}
                        shouldGrow={shouldGrow}
                      />
                    );
                  }
                  if (c.__typename === 'ScenarioColumn') {
                    return (
                      <SandboxPresentationColumn
                        key={c.scenarioID}
                        onLoad={onLoad}
                        scenario={c}
                        shouldGrow={shouldGrow}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
