import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Divider, Drawer, MenuItem, MenuList } from '@material-ui/core';

import { drawerOpenVar } from '../../api/apollo/reactiveVars';
import { withStyles } from '../../theme/komodo-mui-theme';
import { NamedLink } from '../../utilities/routes/types';
import JoinHidden from '../shared-widgets/JoinHidden';

import FrameTitleBar from './FrameTitleBar/FrameTitleBar';
import styles from './SidebarStyles';

type CompanySidebarProps = {
  classes: Classes<typeof styles>;
  links: NamedLink[];
};

const CompanySidebar: FC<CompanySidebarProps> = ({ classes, links }) => {
  const navigate = useNavigate();
  const drawerOpen = useReactiveVar(drawerOpenVar);

  return (
    <div className={classes.noPrint}>
      <JoinHidden mdUp>
        <Drawer
          classes={{ paper: classes.drawer }}
          open={drawerOpen}
          onClose={() => drawerOpenVar(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <FrameTitleBar hasSidebar hasDrawer hideProfile />
          <div className={classes.root}>
            <div className={classes.fixSidebarPosition}>
              <div>
                <MenuList>
                  {links.map((link: NamedLink) => {
                    const {
                      route: { path },
                      name,
                    } = link || {};
                    const sel = path === window.location.pathname;
                    return (
                      <MenuItem
                        key={path}
                        className={`${classes.menu} ${sel ? classes.selectedMenu : ''}`}
                        onClick={() => {
                          drawerOpenVar(false);
                          navigate(path);
                        }}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
                <Divider className={classes.divider} />
              </div>
            </div>
          </div>
        </Drawer>
      </JoinHidden>
    </div>
  );
};

export default withStyles(styles)(CompanySidebar);
