import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { SIGN_UP_ABOUT_YOU_CTA, SIGN_UP_ABOUT_YOU_VIEW } from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { JOIN_SUPPORT_EMAIL } from '../../../../constants';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import {
  useCurrentUserQuery,
  useUpdateUserJobTitleMutation,
  useUpdateUserNameMutation,
  useUpdateUserPictureMutation,
  useUserDidSetupMutation,
} from '../../../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import SignupContainer from '../SignupContainer';
import SignupContentBox from '../SignupContentBox';

import SetupUser from './SetupUser';

// This is where we define blocked types...
export const blockedTypes = ['svg'];

const SetupUserData = () => {
  document.title = `Join - Sign Up`;
  const sendAnalytics = useAnalyticsEventHook();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(SIGN_UP_ABOUT_YOU_VIEW));
  });

  const [inputName, setInputName] = useState<string>('');
  const [inputJobTitle, setInputJobTitle] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { data: { currentUser } = {} } = useCurrentUserQuery();
  const { jobTitle, name, picture, pictureAsset, email } = currentUser || {
    name: '',
    jobTitle: '',
  };

  const isJoinSupport = email === JOIN_SUPPORT_EMAIL;

  useEffect(() => {
    //  the local state will be set once, when currentUser loads

    if (email) {
      if (inputName === '' && name !== '' && name !== email.split('@')[0]) {
        setInputName(name);
      }
      if (inputJobTitle === '' && jobTitle !== '') {
        setInputJobTitle(jobTitle);
      }
      // Only change when name or jobTitle load
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [currentUser, email, inputJobTitle, inputName, name, jobTitle]);

  const updateUserName = useUpdateUserNameMutation();
  const updateUserJobTitle = useUpdateUserJobTitleMutation();
  const updateUserPicture = useUpdateUserPictureMutation();
  const uploadImage = useUploadImage(blockedTypes);
  const confirmDidSetup = useUserDidSetupMutation();

  const onSubmit = async () => {
    sendAnalytics(analyticsEvent(SIGN_UP_ABOUT_YOU_CTA));

    const onComplete = () => {
      confirmDidSetup();
    };
    const trimmedName = inputName?.trim();
    const trimmedJobTitle = inputJobTitle?.trim();
    if (trimmedName !== name) {
      await updateUserName(trimmedName);
    }
    if (trimmedJobTitle !== jobTitle) {
      await updateUserJobTitle(trimmedJobTitle);
    }
    onComplete();
  };

  const addFile = (file: File) => {
    setLoading(true);
    const onSuccess = (asset: Asset) => {
      updateUserPicture(asset.location, () => {
        setLoading(false);
      });
    };
    const onFailure = (message: string) => {
      setLoading(false);
      setToast({ message });
    };
    uploadImage(file, null, onSuccess, onFailure);
    // sendAnalytics(initUploadAnalytics(file.name, 'User Thumbnail'));
  };

  const disableNext = loading || !inputName?.trim();

  return (
    <SignupContainer
      content={
        <SignupContentBox
          content={
            <SetupUser
              disabled={isJoinSupport}
              jobTitle={inputJobTitle}
              name={inputName}
              setJobTitle={setInputJobTitle}
              setName={setInputName}
              setThumbnail={addFile}
              thumbnail={pictureAsset?.thumbnailURL || picture}
              thumbnailLoading={loading}
            />
          }
          disableNext={disableNext}
          onNext={onSubmit}
          title="A little more about you..."
        />
      }
    />
  );
};

export default SetupUserData;
