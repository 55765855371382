import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'unset',
      border: theme.border.line,
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
      '@media print': {
        marginTop: 0,
      },
    },
    cardHeader: {
      ...theme.cardHeader,
      ...theme.typography.cardHeader,
      padding: '8px 12px',
      backgroundColor: 'white',
      justifyContent: 'left',
      width: '100%',
      '&:focus': {
        outline: 'unset',
      },
    },
    cardBody: {
      padding: '12px',
    },
  });

export default styles;
