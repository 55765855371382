import { useReactiveVar } from '@apollo/client';

import {
  ItemDataQuery,
  ItemDataQueryVariables,
  SourceItemQuery,
  SourceItemQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { costModeVar } from '../../../utilities/costMode';

import { itemQuery, sourceItemQuery } from './itemQuery';

export const useItemQuery = (id?: UUID, mountPolicy?: MountPolicy, customCostMode?: CostMode) => {
  let costMode = useReactiveVar(costModeVar);
  if (customCostMode) costMode = customCostMode;
  return useQuery<ItemDataQuery, ItemDataQueryVariables>(
    itemQuery,
    {
      variables: {
        id,
        costMode,
      },
      skip: !id,
    },
    mountPolicy
  );
};

export const useSourceItemQuery = (id?: UUID) =>
  useQuery<SourceItemQuery, SourceItemQueryVariables>(sourceItemQuery, {
    variables: {
      id,
      costMode: useReactiveVar(costModeVar),
    },
    skip: !id,
  });
