import { useCallback } from 'react';

import { useProjectCategorizationsQuery } from '../../../../hooks/useProjectCategorizationsQuery';
import { Button, Dialog, DialogContent } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';
import { NEXT_ESTIMATE_CATEGORIZATIONS, NEXT_ITEM_ATTACHMENTS } from '../CopyItemDialogUtils';

import CategorizationInput from './inputs/CategorizationInput';

type GeneralInfoCategorizationDialogProps = {
  state: ItemCopyState;
  onBack: () => void;
  onChange: (input: Partial<ItemCopyState>) => void;
  onClose: () => void;
  onNext: () => void;
  progressPercent?: number;
};

const GeneralInfoCategorizationDialog = (props: GeneralInfoCategorizationDialogProps) => {
  const { data } = useProjectCategorizationsQuery(props.state.projectID ?? '');
  const projectCategorizations = data?.projectCategorizations ?? [];

  const { onChange } = props;
  const onChangeCategory = useCallback(
    (categorizationID: UUID) => (category?: Category) => {
      const categories = props.state.categories.filter(
        (c) => c.categorization?.id !== categorizationID
      );
      if (category) categories.push(category);
      onChange({ categories });
    },
    [onChange, props.state.categories]
  );

  let nextButtonLabel = NEXT_ESTIMATE_CATEGORIZATIONS;
  if (props.state.skipCategorizationMap) nextButtonLabel = NEXT_ITEM_ATTACHMENTS;

  return (
    <Dialog
      footerLeft={<Button type="secondary" label="Back" onClick={props.onBack} />}
      footerRight={
        <Button
          disabled={!props.state.name}
          label={nextButtonLabel}
          onClick={props.onNext}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Edit Item Categorizations"
    >
      <DialogContent>
        <div className="grid grid-cols-3 gap-x-6 gap-y-4">
          {projectCategorizations.map(({ categorization }) => (
            <div key={categorization.id} className="col-span-1 flex flex-col">
              <CategorizationInput
                categorization={categorization}
                onChange={onChangeCategory(categorization.id)}
                category={props.state.categories.find(
                  (c) => c.categorization?.id === categorization.id
                )}
              />
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GeneralInfoCategorizationDialog;
