import { useMemo, useState } from 'react';
import { trim } from 'validator';

import { Add } from '@material-ui/icons';

import {
  CreateEstimateFromProjectCompsEventTypes,
  createEstimateFromProjectCompsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { EstimateType } from '../../../../api/gqlEnums';
import { NULL_ID } from '../../../../constants';
import {
  CategorizationMatches,
  CostReportColumnType,
  DesignPhaseType,
  ExportAverageCompEstimateInput,
} from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { useProjectCategorizationsQuery } from '../../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../../hooks/useProjectPropsQuery';
import { computeColumnInputs, computeSelectCostReport } from '../../../../utilities/milestones';
import { getReportIdFromUrl } from '../../../../utilities/url';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';
import useMilestonesQuery from '../../../Milestones/hooks/useMilestonesQuery';
import { SelectEntry } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { useProjectCompsSetQuery } from '../../hooks/useProjectCompsSetQuery';

import {
  addToEstimateCostFormatter,
  getReportTypeCost,
  getTermKey,
} from './Contents/ContentsUtils';
import { useExportAverageCompToExistingMilestone } from './hooks/useExportAverageCompToExistingMilestoneMutation';
import { useExportAverageCompToNewMilestone } from './hooks/useExportAverageCompToNewMilestoneMutation';
import { useProjectCategorizationsCategoryMatchesQuery } from './hooks/useProjectCategorizationsCategoryMatchesQuery';

// Types
const validReportTypes = [CostReportColumnType.ESTIMATE_REPORT, CostReportColumnType.TARGET_REPORT];
type ReportType = (typeof validReportTypes)[number];
export function isReportType(value: CostReportColumnType): value is ReportType {
  return validReportTypes.indexOf(value) !== -1;
}
type CreateEstimateVariables = {
  categorizationID: UUID | undefined;
  categorizationName: string;
  categorizationNameError?: boolean;
  date: string | null;
  milestoneDesignPhase: DesignPhaseType | undefined;
  milestoneID?: UUID;
  milestoneName: string;
  projectID: UUID;
  type: ReportType;
};

type ButtonLabelArgs = { t: TermStore; isNoCurrentEstimate: boolean; type: CostReportColumnType };

export const getButtonLabel = ({ t, isNoCurrentEstimate, type }: ButtonLabelArgs) => {
  if (isNoCurrentEstimate) {
    return 'Publish';
  }
  return `Replace ${t.lowerCase(getTermKey(type))}`;
};

export const getCategorizationMatchString = (
  matchingCategories: number | undefined,
  totalCategories: number
) =>
  matchingCategories
    ? `${matchingCategories}/${totalCategories} categories match`
    : 'no categories match';

type NextButtonArgs = {
  date: string | null;
  isNewMilestone: boolean;
  milestoneName: string;
  milestoneDesignPhase?: DesignPhaseType;
  milestoneID?: UUID;
};

export const getNextButtonDisabled = ({
  date,
  milestoneName,
  milestoneDesignPhase,
  isNewMilestone,
  milestoneID,
}: NextButtonArgs) =>
  !milestoneID || (isNewMilestone && (!date || !milestoneName || !milestoneDesignPhase));

type CurrentCostArgs = {
  projectID: UUID;
  milestoneID?: UUID;
  type: CostReportColumnType;
  isNewMilestone: boolean;
};
export const useCurrentCost = (args: CurrentCostArgs) => {
  const { projectID, milestoneID, type, isNewMilestone } = args;
  const sidebarColumnTypes: CostReportColumnType[] = [
    CostReportColumnType.ESTIMATE_REPORT,
    CostReportColumnType.TARGET_REPORT,
  ];
  const columnInputs = useMemoWrapper(computeColumnInputs, sidebarColumnTypes, null);
  const { data: { milestoneCostReports } = { milestoneCostReports: [] } } =
    useMilestoneCostReportsQuery(
      isNewMilestone ? undefined : milestoneID,
      projectID,
      {},
      columnInputs
    );
  const costReport = useMemoWrapper(computeSelectCostReport, milestoneCostReports, undefined);
  const { cost, isNoCurrentEstimate } = getReportTypeCost(type, costReport);
  const currentCost = addToEstimateCostFormatter(cost);
  return { currentCost, isNoCurrentEstimate };
};

const defaultType: ReportType = CostReportColumnType.ESTIMATE_REPORT;
const getDefaults = (projectID: UUID) => ({
  categorizationID: undefined,
  categorizationName: '',
  date: null,
  milestoneDesignPhase: undefined,
  milestoneID: undefined, // Start without choosing a milestone
  milestoneName: '', // This is ONLY the input value for new milestone name
  projectID,
  type: defaultType,
});

const getCategorizationEntries = (
  categorizations: ProjectCategorization['categorization'][],
  projectCategorizationsCategoryMatches: CategorizationMatches[],
  totalCategories: number
) => {
  const categorizationMatches = new Map<UUID, number>(
    projectCategorizationsCategoryMatches.map(({ categorizationID, matchingCategories }) => [
      categorizationID,
      matchingCategories,
    ])
  );

  const sortCategorizations = (
    a: ProjectCategorization['categorization'],
    b: ProjectCategorization['categorization']
  ) => {
    // sort by number of category matches
    const aMatches = categorizationMatches.get(a.id) || 0;
    const bMatches = categorizationMatches.get(b.id) || 0;
    if (aMatches !== bMatches) return aMatches > bMatches ? -1 : 1;
    // sort by categorization type
    if (a.builtin || b.builtin) return a.builtin ? -1 : 1;
    if (a.createdFrom || b.createdFrom) return a.createdFrom ? -1 : 1;
    // otherwise maintain existing order
    return 0;
  };

  const entries: SelectEntry[] = [
    {
      id: NULL_ID,
      label: 'Create a new categorization',
      startAdornment: <Add />,
    },
    ...categorizations.sort(sortCategorizations).map((categorization) => {
      let badge: string | undefined;
      if (categorization.builtin) badge = 'Built-in';
      else if (categorization.createdFrom) badge = 'Standard';
      return {
        id: categorization.id,
        label: categorization.name,
        badge,
        endAdornment: (
          <div className="flex flex-shrink-0 type-body1">
            {getCategorizationMatchString(
              categorizationMatches.get(categorization.id),
              totalCategories
            )}
          </div>
        ),
      };
    }),
  ];
  return entries;
};

export const useCreateEstimateParams = (projectID?: UUID) => {
  // State
  const [variables, setVariables] = useState<CreateEstimateVariables>(getDefaults(projectID || ''));

  // Data
  const { data: { milestones = [] } = { milestones: [] } } = useMilestonesQuery(
    variables.projectID,
    true
  );
  const selectedMilestone = milestones.find(({ id }) => id === variables.milestoneID);
  const { data: { projectCategorizations = [] } = { projectCategorizations: [] } } =
    useProjectCategorizationsQuery(variables.projectID);
  const categorizations = useMemo(
    () => projectCategorizations.map(({ categorization }) => categorization) ?? [],
    [projectCategorizations]
  );
  const selectedCategorization = categorizations.find(
    ({ id }) => id === variables.categorizationID
  );
  const reportID = getReportIdFromUrl();
  const categoryNumbers =
    useProjectCompsSetQuery(reportID)
      .data?.projectCompsSet.categories.filter(({ id }) => id && id !== NULL_ID)
      .map((category) => category.number) ?? [];
  const {
    data: { projectCategorizationsCategoryMatches = [] } = {
      projectCategorizationsCategoryMatches: [],
    },
  } = useProjectCategorizationsCategoryMatchesQuery(variables.projectID, categoryNumbers);
  const projectName = useProjectPropsQuery(variables.projectID).data.project?.name || '';

  // Derived props
  const derivedProps = useMemo(() => {
    // milestone
    const isNewMilestone = variables.milestoneID === NULL_ID;
    const displayMilestoneName = isNewMilestone
      ? variables.milestoneName
      : selectedMilestone?.name || 'existing milestone';
    // categorization
    const isNewCategorization = variables.categorizationID === NULL_ID;
    const displayCategorizationName = isNewCategorization
      ? variables.categorizationName
      : selectedCategorization?.name || 'existing categorization';
    const categorizationEntries = getCategorizationEntries(
      categorizations,
      projectCategorizationsCategoryMatches,
      categoryNumbers.length
    );

    return {
      categorizationEntries,
      displayCategorizationName,
      displayMilestoneName,
      isNewCategorization,
      isNewMilestone,
    };
  }, [
    variables.milestoneID,
    variables.milestoneName,
    variables.categorizationID,
    variables.categorizationName,
    selectedMilestone?.name,
    selectedCategorization?.name,
    categorizations,
    projectCategorizationsCategoryMatches,
    categoryNumbers.length,
  ]);

  // Setters-and-forgetters
  const setters = useMemo(() => {
    const onSetProjectID = (projectID: UUID) =>
      setVariables((prev) => ({
        ...prev,
        projectID,
      }));
    const onSetMilestoneID = (milestoneID: UUID) =>
      setVariables((prev) => ({
        ...prev,
        milestoneID,
      }));
    const onSetMilestoneName = (milestoneName: string) =>
      setVariables((prev) => ({
        ...prev,
        milestoneName,
      }));
    const onSetMilestoneDate = (date: string | null) =>
      setVariables((prev) => ({
        ...prev,
        date,
      }));
    const onSetMilestoneDesignPhase = (milestoneDesignPhase: DesignPhaseType | undefined) =>
      setVariables((prev) => ({
        ...prev,
        milestoneDesignPhase,
      }));
    const onSetType = (type: CreateEstimateVariables['type']) =>
      setVariables((prev) => ({
        ...prev,
        type,
      }));
    const onSetCategorizationID = (categorizationID: string | undefined) =>
      setVariables((prev) => ({
        ...prev,
        categorizationID,
      }));
    const onSetCategorizationName = (categorizationName: string) =>
      setVariables((prev) => ({
        ...prev,
        categorizationName,
      }));
    return {
      onSetCategorizationID,
      onSetCategorizationName,
      onSetMilestoneDesignPhase,
      onSetMilestoneDate,
      onSetMilestoneID,
      onSetMilestoneName,
      onSetProjectID,
      onSetType,
    };
  }, [setVariables]);

  return {
    ...derivedProps,
    ...setters,
    milestones,
    projectName,
    variables,
  };
};

export type CreateEstimateParameters = ReturnType<typeof useCreateEstimateParams>;

export const useFinalizeCreateEstimate = (params: CreateEstimateParameters) => {
  const sendAnalytics = useAnalyticsEventHook();
  const [exportAverageCompToExistingMilestone] = useExportAverageCompToExistingMilestone();
  const [exportAverageCompToNewMilestone] = useExportAverageCompToNewMilestone();
  const {
    categorizationID,
    categorizationName,
    date,
    milestoneDesignPhase,
    milestoneID,
    milestoneName,
    projectID,
    type,
  } = params.variables;
  const { isNewMilestone, isNewCategorization } = params;

  const onFinalize = (
    isDraft: boolean,
    analyticsEvent: CreateEstimateFromProjectCompsEventTypes,
    selectedType: CostReportColumnType,
    onSuccessNext: () => void,
    setSuccessMilestoneID: (id: UUID) => void
  ) => {
    // Inputs
    const milestoneInput: MilestoneInput = {
      projectID,
      name: milestoneName,
      date: date || undefined,
      isDraft,
      designPhaseID: milestoneDesignPhase?.id,
      id: isNewMilestone ? undefined : milestoneID,
    };

    // On Success Callbacks
    const onSuccess = (successfulMilestoneID: UUID) => {
      setSuccessMilestoneID(successfulMilestoneID);
      sendAnalytics(
        createEstimateFromProjectCompsEvent(CreateEstimateFromProjectCompsEventTypes.FINISH_MENU)
      );
      onSuccessNext();
    };

    const estimateType =
      type === CostReportColumnType.ESTIMATE_REPORT
        ? EstimateType.ACTIVE_ESTIMATE
        : EstimateType.BUDGET;
    // TODO - take categorization name from modal input
    const exportAverageCompEstimateInput: ExportAverageCompEstimateInput = {
      categorizationID,
      categorizationName: trim(categorizationName || ''),
      estimateType,
    };
    if (!milestoneID) return;
    if (isNewMilestone)
      exportAverageCompToNewMilestone(
        projectID,
        milestoneInput,
        exportAverageCompEstimateInput,
        onSuccess
      );
    else
      exportAverageCompToExistingMilestone(
        projectID,
        milestoneID,
        exportAverageCompEstimateInput,
        onSuccess
      );
    sendAnalytics(
      createEstimateFromProjectCompsEvent(analyticsEvent, {
        selectedType,
        isDraft,
        isNewMilestone,
        isNewCategorization,
      })
    );
  };

  return onFinalize;
};
