import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { filesExplorerSortStateVar } from '../../../api/apollo/reactiveVars';
import { UserSource } from '../../../generated/graphql';
import useMemoWrapper from '../../useMemoWrapper';
import {
  getAssetIDsforDisabling,
  useEntityAttachments,
} from '../Files/FilesAssets/FilesAssetsUtils';
import FilesTable from '../Files/FilesTable/FilesTable';
import { useFilesTableSelection } from '../Files/FilesTable/FilesTableUseUtils';
import { FileTableCell } from '../Files/FilesTable/FilesTableUtils';

import FileExplorerFolderContents from './FileExplorerFolderContents';
import FilesExplorerError from './FilesExplorerError';
import { getErrorMessage, getFileTableEntriesForEntities } from './FilesExplorerUtils';
import { useLoadSourceFolderRootQuery } from './hooks/FileExplorerHooks';

type FileExplorerFolderRootProps = {
  userSource: UserSource;
};

// FileExplorerFolderRoot is a component that renders the root folder of a user source for Egynte
const FileExplorerFolderRoot: FC<FileExplorerFolderRootProps> = ({ userSource }) => {
  const sortBy = useReactiveVar(filesExplorerSortStateVar);
  const { data, loading, error } = useLoadSourceFolderRootQuery(
    userSource.sourceSystem,
    userSource.id,
    sortBy
  );
  const entityAssets = useEntityAttachments();
  const entityAssetIDs = getAssetIDsforDisabling(entityAssets);
  const foldersRoot = data?.sourceFolderRoot;
  const fileTableEntries = useMemoWrapper(
    getFileTableEntriesForEntities,
    foldersRoot,
    entityAssetIDs
  );
  const { onEntry, onToggleEntry, onSelectAll, onUnselectAll, selectedIds } =
    useFilesTableSelection();

  const errorMessage = getErrorMessage(foldersRoot, error);
  if (errorMessage) return <FilesExplorerError error={errorMessage} userSource={userSource} />;

  const onTableCell = (entry: FileTableCell) => {
    onEntry(entry, <FileExplorerFolderContents userSource={userSource} folderID={entry.id} />);
  };

  return (
    <FilesTable
      entries={fileTableEntries}
      loading={loading}
      onEntry={onTableCell}
      onToggleEntry={onToggleEntry}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      selectedIds={selectedIds}
    />
  );
};

export default FileExplorerFolderRoot;
