import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { SIDEBAR_ICON_BUTTON_HEIGHT_SMALL } from '../../Icons/ItemSidebarIcon/ItemSidebarIconStyles';

const FOOTER_MARGIN = 10;
export const SUMMARY_HEADER_HEIGHT = 62;
export const HEADER_HEIGHT = 53;
export const FOOTER_HEIGHT = 40 + 2 * FOOTER_MARGIN;
export const SEARCH_FILTERS_HEIGHT = 72;
const PADDING = 8;

// TODO: Break up file into sub-file styles

const styles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      pageBreakInside: 'avoid',
      width: 414,
      flexShrink: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      right: 0,
      zIndex: 1002,
      boxShadow: theme.borderShadow,
    },
    paddingRight: {
      paddingRight: PADDING,
    },
    collapse: {
      minHeight: 52,
    },
    cost: theme.typography.number,
    // milestone selector
    currentMilestoneSelector: {
      alignItems: 'center',
      border: `1px solid ${theme.palette.backgroundGrey}`,
      borderRadius: theme.border.radius,
      '@media print': {
        border: 'none',
        padding: 0,
      },
      display: 'flex',
      margin: 0,
      padding: '0 0 0 0',
      width: '100%',
      alignSelf: 'flex-start',
      background: 'none',
      backgroundColor: theme.palette.backgroundWhite,
      cursor: 'pointer',
    },
    currentMilestoneSelectorDisabled: {
      border: 0,
      padding: PADDING,
      cursor: 'unset',
      backgroundColor: 'unset',
    },
    currentMilestoneViewOnly: {
      color: theme.palette.primaryGrey,
      flexWrap: 'nowrap',
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    currentMilestone: {
      textAlign: 'left',
      wordBreak: 'break-word',
      padding: `9px 6px 8px ${theme.spacing.selectPaddingLeft}px`,
    },
    currentMilestoneEdit: {
      height: 24,
      width: 24,
      margin: `0px ${theme.spacing.selectIconRight}px 0px auto`,
    },
    currentMilestoneEditIcon: {
      fontSize: 16,
      '@media print': {
        display: 'none',
      },
    },
    notDisplayed: {
      display: 'none',
    },
    previousMilestoneList: {
      cursor: 'pointer',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '&:focus': {
        outline: 'none',
      },
    },
    containerPadding: {
      padding: '8px 18px',
      flexGrow: 1,
    },
    // Category selectors
    categoryPadding: { paddingBottom: 8 },
    // Cost link
    link: {
      ...theme.typography.link,
      ...theme.typography.h3CardHeader,
    },
    estimateContentContainer: {
      backgroundColor: theme.palette.backgroundWhite,
      paddingLeft: '24px',
      flexGrow: 1,
    },
    estimateLineContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 24,
    },
    estimateContent: {
      minHeight: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    estimateTitle: {
      paddingTop: 2,
      ...theme.typography.h3CardHeader,
    },
    estimateSubtext: {
      ...theme.typography.h4newMedium,
    },
    estimateDivider: {
      borderTop: theme.border.lineDashed,
    },
    estimateLineInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 150,
    },
    estimateLineInfoHover: {
      height: SIDEBAR_ICON_BUTTON_HEIGHT_SMALL,
      width: SIDEBAR_ICON_BUTTON_HEIGHT_SMALL,
    },
    estimateSectionDivider: {
      borderTop: theme.border.line,
    },
    estimateTotalDivider: {
      borderTop: theme.border.lineBorderBlack,
    },
    estimateHeaderFooter: {
      paddingRight: 24,
      width: 'unset',
    },
    estimateTooltipIcon: {
      height: 18,
    },
    linesContainer: {
      paddingLeft: 25,
    },
    // Body
    bodyContainer: {
      background: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      overflow: 'hidden',
    },
    collapseHeight: {
      overflowY: 'auto',
      background: theme.palette.backgroundWhite,
      minHeight: '75px',
    },
  });

export default styles;
