import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const IN_APP_NOTIFICATIONS_PAPER_VERTICAL_PADDING = 100;
const IN_APP_NOTIFICATIONS_PAPER_WIDTH = 416;

const styles = (theme: KomodoTheme) =>
  createStyles({
    paper: {
      background: theme.palette.backgroundWhite,
      maxHeight: `calc(100vh - ${IN_APP_NOTIFICATIONS_PAPER_VERTICAL_PADDING}px)`,
      width: IN_APP_NOTIFICATIONS_PAPER_WIDTH,
      display: 'flex',
      flexDirection: 'column',
    },
  });

export default styles;
