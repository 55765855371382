import { FC } from 'react';

import { CompanyCategorization } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import CompanyTabStyles from '../../CompanyTabStyles';
import { CompanyCategorizationsSearchManager } from '../../CompanyTabUtils';

import ListEntry from './ListEntry';

type ListProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyCategorizations: CompanyCategorization[];
  searchManager: CompanyCategorizationsSearchManager;
  companyID: UUID;
};

const List: FC<ListProps> = ({ classes, companyCategorizations, searchManager, companyID }) => {
  const { searchTerm } = searchManager;

  return (
    <div className={classes.overflowAuto}>
      {companyCategorizations
        .filter((c) => {
          const { name, description } = c.categorization;
          const search = searchTerm.toLowerCase();
          return name.toLowerCase().includes(search) || description.toLowerCase().includes(search);
        })
        .map((c: CompanyCategorization) => (
          <ListEntry
            companyCategorization={c}
            key={c.categorization.id}
            searchTerm={searchTerm}
            companyID={companyID}
          />
        ))}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(List);
