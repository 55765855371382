import { FC } from 'react';

import { Button, Dialog, DialogContent } from '../../../scales';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onClose: () => void;
  name: string;
};

const ProcoreChangeEventComponent: FC<Props> = ({ isOpen, setIsOpen, onClose, name }) => {
  return (
    <Dialog
      title="Are you sure you want to unlink this Change Event?"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      footerLeft={<Button label="Close" type="secondary" onClick={() => setIsOpen(false)} />}
      footerRight={
        <Button
          label="Unlink"
          type="primary"
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
        />
      }
    >
      <DialogContent>
        <div>
          You are about to unlink {name} from this Item. The link to the Change Event will be
          removed, but the Procore Change Event will not be affected.
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProcoreChangeEventComponent;
