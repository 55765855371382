import useLocalStorage from '../../../hooks/useLocalStorage';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';

import HUD from './HUD/HUD';
import ProjectTitle from './ProjectTitle';

type Props = {
  projectID?: UUID;
  /** @default true */
  showThumbnail?: boolean;
};

export default function ProjectDetails(props: Props) {
  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(props.projectID);

  const [showHUD, setShowHUD] = useLocalStorage('NAV_SHOW_HUD', true);

  const projectQueryResult = useProjectPropsQuery(props.projectID);
  const project =
    projectQueryResult.data.project ?? projectQueryResult.previousData?.project ?? undefined;

  if (!props.projectID) {
    return null;
  }

  return (
    <div data-cy="project-details" className="flex flex-col gap-2 px-2">
      <ProjectTitle
        isShowingMilestoneInfo={showHUD}
        onShowMilestoneInfo={shouldDisplayCosts || setDisplayCostsToggle ? setShowHUD : undefined}
        project={project}
        showThumbnail={props.showThumbnail}
      />
      {showHUD && <HUD milestoneID={project?.activeMilestone.id} projectID={props.projectID} />}
    </div>
  );
}

ProjectDetails.defaultProps = {
  showThumbnail: true,
};
