import { MilestoneAssetsQuery, MilestoneAssetsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { milestoneAssetsQuery } from './queries';

function useMilestoneAssetsQuery(id?: UUID, canView?: boolean) {
  return useQuery<MilestoneAssetsQuery, MilestoneAssetsQueryVariables>(milestoneAssetsQuery, {
    variables: { id },
    skip: !id || !canView,
  });
}

export default useMilestoneAssetsQuery;
