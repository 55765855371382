import { commonProjectCompsSetFields, gql } from '../../../api/graphqlFragments';

export const projectCompsSetQuery = gql`
  query projectCompsSet($projectCompsSetInput: ProjectCompsSetInput, $reportID: UUID) {
    projectCompSettingsReactive @client @export(as: "projectCompsSetInput")

    projectCompsSet(projectCompsSetInput: $projectCompsSetInput, reportID: $reportID) {
      ...commonProjectCompsSetFields
    }
  }
  ${commonProjectCompsSetFields}
`;

export const createEstimateFromProjectComp = gql`
  mutation createEstimateFromProjectComp(
    $projectID: UUID!
    $projectCompsSetInput: ProjectCompsSetInput!
    $estimateCreationInput: EstimateCreationInput!
  ) {
    createEstimateFromProjectComp(
      projectID: $projectID
      projectCompsSetInput: $projectCompsSetInput
      estimateCreationInput: $estimateCreationInput
    ) {
      id
    }
  }
`;

export const locationEscalationMultiple = gql`
  query locationEscalationMultiple($locations: [LocationEscalationInput!]!) {
    locationEscalationMultiple(locations: $locations) {
      percentage
      fuzzyMatch
      targetIsFuzzyMatch
      sourceIsFuzzyMatch
      from {
        index
        lat
        lon
        name
      }
      to {
        index
        lat
        lon
        name
      }
    }
  }
`;

export const timeEscalationMultiple = gql`
  query timeEscalationMultiple($dates: [TimeEscalationInput!]!) {
    timeEscalationMultiple(dates: $dates) {
      from {
        year
        quarter
        index
      }
      fuzzyMatch
      percentage
      to {
        year
        quarter
        index
      }
    }
  }
`;
