import { useContext } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import { CostMode } from '../../../api/gqlEnumsBe';
import { ALLOCATED_MARKUPS, NO_MARKUPS, SEPARATED_MARKUPS } from '../../../constants';
import { getCostModeString } from '../../../utilities/costMode';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Select, SelectEntry } from '../../scales';

type Props = {
  availableCostModes: CostMode[];
  isDisabled?: boolean;
  label?: string;
  onChange: (costMode: string) => void;
  value: string;
};

export default function SelectCostMode(props: Props) {
  const t = useContext(ProjectTermStore);

  const entries: SelectEntry[] = [];
  if (props.availableCostModes.includes(CostMode.AllocatedMarkups))
    entries.push({
      'data-cy': ALLOCATED_MARKUPS,
      id: ALLOCATED_MARKUPS,
      label: getCostModeString(CostMode.AllocatedMarkups, t),
      description: `Allocate selected ${t.lowerCase(TermKey.MARKUP)} pro-rata`,
    });
  if (props.availableCostModes.includes(CostMode.SeparatedMarkups))
    entries.push({
      'data-cy': SEPARATED_MARKUPS,
      id: SEPARATED_MARKUPS,
      label: getCostModeString(CostMode.SeparatedMarkups, t),
      description: `Do not allocate ${t.lowerCase(TermKey.MARKUP)}`,
    });
  if (props.availableCostModes.includes(CostMode.NoMarkups))
    entries.push({
      'data-cy': NO_MARKUPS,
      id: NO_MARKUPS,
      label: getCostModeString(CostMode.NoMarkups, t),
      description: `${t.sentenceCase(TermKey.MARKUP)} are not displayed or included in totals`,
    });
  if (!entries.length) return null;

  return (
    <Select
      aria-label={props.label ? undefined : 'select the cost mode'}
      data-cy="SelectCostMode"
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={props.onChange}
      placeholder="No Cost Mode Selected"
      value={props.value}
    />
  );
}
