import { ChangeEvent, FC, useRef, useState } from 'react';
import * as React from 'react';

import { TextField } from '@material-ui/core';

import { ENTER, RESET_COMP_NAME } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import styles, { NAME_HEIGHT } from '../ProjectCompsStyles';
import { useStateLocalUpdater } from '../ProjectCompUtils';

import ProjectCompsInputsModifiedDot from './ProjectCompsInputsModifiedDot';

type ProjectCompsInputsProjectNameProps = {
  classes: Classes<typeof styles>;
  disabled?: boolean;
  name: string;
  nameDefault: string;
  resetName?: () => void;
  setName?: (name: string) => void;
};

const ProjectCompsInputsProjectName: FC<ProjectCompsInputsProjectNameProps> = ({
  classes,
  disabled,
  name,
  nameDefault,
  resetName = () => {},
  setName = () => {},
}) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const isEdited = name && nameDefault && nameDefault.normalize() !== name.normalize();
  const [localValue, setLocalValue] = useStateLocalUpdater(name);
  const [isFocused, setIsFocused] = useState(false);

  const inputIconMenuOptions: MenuOption[] = [
    {
      name: RESET_COMP_NAME,
      callback: resetName,
    },
  ];
  const inputIconMenu = isEdited && (
    <ProjectCompsInputsModifiedDot addPaddingRight options={inputIconMenuOptions} />
  );

  // Styles
  const input = disabled ? `${classes.greyBG}` : 'name-input';
  const placeholder = disabled ? '' : 'Set the Comp Name';

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const onExit = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLocalValue(e.target.value);
    setName(e.target.value);
    setIsFocused(false);

    // Scroll the input back to the top in case it overflows past two lines.
    if (inputRef.current) {
      inputRef.current.scrollTop = 0;
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  // Don't add ellipses if we are editing the field.
  const inputPropStyles = isFocused
    ? {}
    : {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-word',
      };
  const textField = (
    <TextField
      className={classes.nameField}
      data-cy="textField-projectCompName"
      disabled={disabled}
      InputProps={{
        className: input,
        disableUnderline: true,
        name: 'name-input',
        startAdornment: inputIconMenu,
        inputProps: {
          style: inputPropStyles,
        },
        style: {
          minHeight: NAME_HEIGHT,
          alignItems: 'center',
        },
        multiline: true,
        rowsMax: 2,
      }}
      inputRef={inputRef}
      placeholder={placeholder}
      value={localValue}
      onFocus={onFocus}
      onBlur={onExit}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.key === ENTER) {
          inputRef.current?.blur();
        }
      }}
      fullWidth
    />
  );
  // Only show a tooltip if the project name is likely to be larger than the field.
  const showTooltip =
    !isFocused && ((isEdited && localValue.length > 50) || localValue.length > 75);
  const tooltipTitle = showTooltip ? localValue : '';
  return (
    <NormalTooltip title={tooltipTitle} disableFocusListener>
      {textField}
    </NormalTooltip>
  );
};

export default withStyles(styles)(ProjectCompsInputsProjectName);
