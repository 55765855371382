import { createStyles } from '@material-ui/core/styles';

import theme, { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const mentionStyle = {
  color: theme.palette.badgeColor,
  background: 'transparent',
  fontWeight: 400,
};

const MIN_HEIGHT = 45;

const styles = (theme: KomodoTheme) =>
  createStyles({
    avatarSimplified: {
      width: theme.iconSizes.compact,
      height: theme.iconSizes.compact,
    },
    contentSimplified: {
      borderLeft: `2px solid ${theme.palette.joinGrey400}`,
      marginLeft: theme.iconSizes.compact / 2 - 1,
      minHeight: MIN_HEIGHT,
    },
    deactivated: {
      ...theme.grayscaleFilter,
    },
    descriptionSimplified: {
      padding: '0 8px',
    },
    loading: {
      marginLeft: 12,
      paddingTop: 12,
    },
    mentionStyle,
    slugLineSimplified: {
      display: 'flex',
      pageBreakInside: 'avoid',
      flexDirection: 'row',
      alignItems: 'center',
    },
    textSimplified: {
      maxWidth: '50em',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      padding: '12px 20px',
    },
  });

export default styles;
