import { PROJECT_NAME } from '../../../constants';
import { ForecastingProjectsSortKey, SortDirection } from '../../../generated/graphql';
import { Select, SortManager } from '../../scales';

type Props = {
  sortManager: SortManager;
};

const entries = [
  { id: ForecastingProjectsSortKey.NAME, label: PROJECT_NAME },
  {
    id: ForecastingProjectsSortKey.STATUS,
    label: 'Project Status',
  },
  {
    id: ForecastingProjectsSortKey.TYPE,
    label: 'Project Type',
  },
  {
    id: ForecastingProjectsSortKey.LOCATION,
    label: 'Project Location',
  },
  { id: ForecastingProjectsSortKey.UPDATED_AT, label: 'Last Updated' },
];

export default function ProjectSelectionSortSelect(props: Props) {
  const sortDirection = (sortKey: string) => {
    return sortKey === ForecastingProjectsSortKey.UPDATED_AT
      ? SortDirection.SORT_DESCENDING
      : SortDirection.SORT_ASCENDING;
  };
  return (
    <Select
      data-cy="select-sort-projects"
      entries={entries}
      label="Sort By"
      onChange={(sortKey) =>
        props.sortManager.setSort({ sortKey, sortDirection: sortDirection(sortKey) })
      }
      value={props.sortManager.sortState.sortKey}
    />
  );
}
