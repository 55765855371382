import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import {
  ReportImportEstimateTimeMutation,
  ReportImportEstimateTimeMutationVariables,
  ReportPageLoadMutation,
  ReportPageLoadMutationVariables,
  UserEventType,
} from '../../generated/graphql';
import { getProjectIdFromUrl } from '../../utilities/url';

import { reportImportEstimateTime, reportPageLoad } from './queries';

type Pages =
  | 'CostReport'
  | 'Dashboard'
  | 'ExportItemsList'
  | 'ImportEstimate'
  | 'ItemsList'
  | 'PrintCostReport'
  | 'PrintDashboard'
  | 'PrintVarianceReport'
  | 'PrintViewItemDetails'
  | 'PrintViewItemsList'
  | 'PrintViewItemsListAndItemDetailsList'
  | 'VarianceReport';

export const useLoadTimer = (page: Pages, loading: boolean) => {
  const projectID = getProjectIdFromUrl();
  const [sendPageLoad] = useMutation<ReportPageLoadMutation, ReportPageLoadMutationVariables>(
    reportPageLoad
  );
  const [timer, setTimer] = useState<number | null>(window.performance.now());

  useEffect(() => {
    if (!loading && timer) {
      const loadTime = window.performance.now() - timer;
      sendPageLoad({
        variables: { project: projectID, type: UserEventType.PAGE_LOAD, page, loadTime },
      });
      setTimer(null);
    }
  }, [loading, page, timer, projectID, sendPageLoad]);
};

export const useImportEstimateTimer = (
  id: UUID | null,
  type: UserEventType,
  loadTime: number | null,
  setUploadTime: (time: number | null) => void
) => {
  const [sendPageLoad] = useMutation<
    ReportImportEstimateTimeMutation,
    ReportImportEstimateTimeMutationVariables
  >(reportImportEstimateTime);

  useEffect(() => {
    if (loadTime && id) {
      sendPageLoad({
        variables: { milestoneEstimateID: id, loadTime, type },
      }).then(() => setUploadTime(null));
    }
  }, [id, loadTime, sendPageLoad, setUploadTime, type]);
};
