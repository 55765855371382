import { FC, useEffect, useState } from 'react';

import { createProcoreChangeEvent } from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes, ToastType } from '../../../../api/gqlEnums';
import {
  CreateChangeEventInput,
  CreateLinkedChangeEventMutation,
  SourceSystem,
} from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { useCreateLinkedChangeEventMutation } from '../../../../hooks/useCreateChangeEvent';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { getCostValue, renderCostString } from '../../../CostReport/CostReportUtils';
import InputsTextAreaStyled from '../../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Select,
  StatusBanner,
  TextInput,
} from '../../../scales';

import {
  ChangeReasons,
  EventStatuses,
  EventTypes,
  ItemInfo,
  changeEventChangeReasons,
  changeEventStatuses,
  changeEventTypes,
} from './IntegrationsUtils';
import ProcoreSVGLoggedIn from './ProcoreSVGLoggedIn';

type IntegrationsCreateChangeEventModalProps = {
  isCreatingChangeEvent: boolean;
  setIsCreatingChangeEvent: (value: boolean) => void;
  setSubmitted: (value: boolean) => void;
  itemInfo: ItemInfo;
  loggedInProcoreSourceID: string;
  savedSourceProjectID: string;
  savedSourceCompanyID: string;
};

const IntegrationsCreateChangeEventModal: FC<IntegrationsCreateChangeEventModalProps> = ({
  isCreatingChangeEvent,
  setIsCreatingChangeEvent,
  setSubmitted,
  itemInfo,
  loggedInProcoreSourceID,
  savedSourceProjectID,
  savedSourceCompanyID,
}) => {
  const projectId = getProjectIdFromUrl();
  const itemURL = generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
    projectId,
    itemId: itemInfo.id,
  });
  const initialChangeEventInput: CreateChangeEventInput = {
    title: itemInfo.name,
    description: itemInfo.descriptionStyled,
    status: EventStatuses.OPEN,
    eventType: EventTypes.ALLOWANCE,
    changeReason: ChangeReasons.ALLOWANCE,
    cost: 0,
    shouldIncludeAttachments: false,
    itemURL,
  };
  const [changeEventInput, setChangeEventInput] =
    useState<CreateChangeEventInput>(initialChangeEventInput);
  const [includeCost, setIncludeCost] = useState(false);
  // if the user updates the item description or name after opening the integration section
  // then we should also update the change event title / description accordingly
  useEffect(() => {
    setChangeEventInput({
      ...changeEventInput,
      title: itemInfo.name,
      description: itemInfo.descriptionStyled,
    });
    // We only want to update changeEventInput if the user changes the item, we don't care if the changeEventInput updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo]);

  const sendAnalytics = useAnalyticsEventHook();

  // TODO: add attachment checkbox and subtotal area
  const closeModalAndSetToast = (message: string, toastType: ToastType) => {
    setIsCreatingChangeEvent(false);
    setToast({ message }, toastType);
  };

  const onCompleted = (data: CreateLinkedChangeEventMutation) => {
    if (data?.createLinkedChangeEvent?.error) {
      setToast({ message: data?.createLinkedChangeEvent?.error }, ToastType.SERVER_ERROR);
      return;
    }
    closeModalAndSetToast(
      'Procore Change Event successfully created and linked to this Item',
      ToastType.SUCCESS
    );
    sendAnalytics(
      createProcoreChangeEvent(!!changeEventInput.cost, !!changeEventInput.shouldIncludeAttachments)
    );
    setSubmitted(false);
  };

  const onError = () => {
    closeModalAndSetToast(
      `Something went wrong! Try again, and if it continues, please contact support@join.build`,
      ToastType.SERVER_ERROR
    );
  };

  const [createChangeEvent] = useCreateLinkedChangeEventMutation({
    onCompleted,
    onError,
  });

  const handleSetCreateChangeEvent = (
    field: keyof CreateChangeEventInput,
    value: string | number | boolean
  ) => {
    setChangeEventInput((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleCreateChangeEvent = () => {
    createChangeEvent(
      SourceSystem.PROCORE,
      loggedInProcoreSourceID,
      savedSourceProjectID,
      savedSourceCompanyID,
      itemInfo.id,
      changeEventInput
    );
    setIsCreatingChangeEvent(false);
    setSubmitted(true);
  };

  const title = (
    <div className="flex items-center justify-center gap-2">
      <div className="h-5 w-5">
        <ProcoreSVGLoggedIn />
      </div>
      <div className="type-heading2">Create Procore Change Event from Item</div>
    </div>
  );

  const estimateCost =
    itemInfo.estimateCost && 'value' in itemInfo.estimateCost ? itemInfo.estimateCost : 0;
  const estimateCostDisplay = renderCostString({
    cost: estimateCost,
    isExact: true,
    isWide: true,
    isSigned: true,
  });

  const costValue = getCostValue(estimateCost ?? 0);
  const assetCount = itemInfo?.assetCount;
  const hasAssets = assetCount > 0;

  return (
    <Dialog
      isOpen={isCreatingChangeEvent}
      onClose={() => setIsCreatingChangeEvent(false)}
      title={title}
      footerRight={
        <Button disabled={false} label="Create" onClick={handleCreateChangeEvent} type="primary" />
      }
      size="lg"
    >
      <DialogContent>
        <div className="flex flex-col items-center gap-6">
          <div className="mr-auto type-body1">
            Fill out the fields to create a Change Event within Procore that will then be linked
            with this Item.
          </div>
          <div className="flex w-full gap-2">
            <div className="w-24">
              <TextInput label="Number" placeholder="--" disabled />
            </div>
            <TextInput
              label="Title"
              placeholder="Enter title..."
              defaultValue={itemInfo.name}
              onChange={(newTitle) => handleSetCreateChangeEvent('title', newTitle)}
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="min-w-fit">
              <Select
                label="Status"
                entries={changeEventStatuses}
                defaultValue={EventStatuses.OPEN}
                onChange={(newStatus) => handleSetCreateChangeEvent('status', newStatus)}
              />
            </div>
            <div className="w-full">
              <Select
                label="Type"
                entries={changeEventTypes}
                defaultValue={EventTypes.ALLOWANCE}
                onChange={(newType) => handleSetCreateChangeEvent('eventType', newType)}
              />
            </div>
            <div className="w-min-fit">
              <Select
                label="Change Reason"
                entries={changeEventChangeReasons}
                defaultValue={ChangeReasons.ALLOWANCE}
                onChange={(newReason) => handleSetCreateChangeEvent('changeReason', newReason)}
              />
            </div>
          </div>
          <div className="flex w-full flex-col">
            <div className="pb-1 type-label">Description</div>
            <InputsTextAreaStyled
              editable
              onChangeComplete={(_, newDescriptionStyled) =>
                handleSetCreateChangeEvent('description', newDescriptionStyled)
              }
              placeholder="Enter a description..."
              textStyled={itemInfo.descriptionStyled}
            />
          </div>
          {hasAssets && (
            <div className="mr-auto flex w-full">
              <Checkbox
                onChange={(event: boolean) => {
                  if (event) {
                    handleSetCreateChangeEvent('shouldIncludeAttachments', true);
                  } else {
                    handleSetCreateChangeEvent('shouldIncludeAttachments', false);
                  }
                }}
              >
                <div className="type-body2">{`Include ${assetCount} Attachments`}</div>
              </Checkbox>
            </div>
          )}
          <div className="flex w-full flex-col gap-3">
            <div className="mr-auto flex w-full">
              <Checkbox
                onChange={(event: boolean) => {
                  if (event) {
                    handleSetCreateChangeEvent('cost', costValue);
                    setIncludeCost(true);
                  } else {
                    handleSetCreateChangeEvent('cost', 0);
                    setIncludeCost(false);
                  }
                }}
              >
                <div className="type-body2">Include Item Estimate Subtotal as Change Event ROM</div>
              </Checkbox>
            </div>
            <div
              className={`flex h-8 items-center justify-between ${
                includeCost ? 'bg-background-2' : 'bg-background-1'
              } pl-2 pr-2 text-type-${includeCost ? 'primary' : 'inactive'}`}
            >
              <div>Item Estimate Subtotal: Direct Costs + Markups</div>
              <div>{estimateCostDisplay}</div>
            </div>
          </div>
          {itemInfo.contingencyDrawCost && (
            <div className="flex w-full justify-start">
              <StatusBanner type="warning">
                Contingency and Allowance draws are not calculated in Change Event ROM Subtotal
              </StatusBanner>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default IntegrationsCreateChangeEventModal;
