import { Chip } from '../../scales';

type Props = {
  color?: string;
  width: number;
  text: string;
};

// Unfortunately, the truncation and sizing container require different behavior here
// than our standard Chip. That said, we are trying to match the styles

const ProjectLabelChip = (props: Props) => (
  <div
    className="flex justify-center px-1"
    style={{
      minWidth: props.width,
    }}
  >
    <Chip text={props.text} backgroundColor={props.color} />
  </div>
);

export default ProjectLabelChip;
