import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { EMPTY_COST } from '../../../../../utilities/string';

import styles from './MilestoneDateCellStyles';

export default withStyles(styles)(
  ({ classes, item }: { classes: Classes<typeof styles>; item: ForecastingItem }) => (
    <div className={classes.container}>
      {item.milestoneDate ? new Date(item.milestoneDate).toLocaleDateString() : EMPTY_COST}
    </div>
  )
);
