import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../../utilities/currency';
import { getNumberString } from '../../../ProjectComps/ProjectCompsSetUtils';

import styles from './ScheduleImpactInputStyles';

const INTEGER_DIGIT_LIMIT = 9;
const FRACTIONAL_DIGIT_LIMIT = 0;

const DIGIT_LIMIT = 1 + INTEGER_DIGIT_LIMIT + FRACTIONAL_DIGIT_LIMIT;

interface ScheduleImpactInputProps {
  classes: Classes<typeof styles>;
  disabled: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange: (value: string) => void;
  value: string;
}

const ScheduleImpactInput = (props: ScheduleImpactInputProps) => {
  const { classes } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(props.value);

  // update the local value if the one passed in changes
  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  // format the local value according to what input mode we're in
  let displayValue = localValue;
  if (!isEditing) {
    const hasValue = localValue && getNumberString(localValue);
    if (hasValue && !Number.isNaN(Number(localValue))) {
      const formattedValue = formatCommas(localValue);
      if (Number(localValue) > 0) {
        displayValue = `+${formattedValue}`;
      } else if (Number(localValue) < 0) {
        displayValue = formattedValue;
      } else {
        displayValue = '';
      }
    } else {
      displayValue = '';
    }
  }

  // <input> handlers
  // Not currently concerned about onCallback'ing these since we're applying them
  // directly to a DOM element and not a deep component chain.
  const handleBlur = () => {
    setIsEditing(false);
    props.onChange(localValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value !== '-' && Number.isNaN(Number(value))) return;

    const [integerPart = '', fractionalPart = ''] = value.split('.');
    // No day fractions
    if (integerPart.length > INTEGER_DIGIT_LIMIT || fractionalPart.length > FRACTIONAL_DIGIT_LIMIT)
      return;

    setLocalValue(value);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(true);
    if (displayValue === '') setLocalValue('');

    // For a better UX, we move the cursor to the end of the input.
    // We need to do a setTimeout due to browser quirks (Chrome),
    // and because we need to setTimeout, we need to persist
    // the event variable so that React doesn't garbage collect it.
    e.persist();
    setTimeout(() => {
      e.target.selectionStart = DIGIT_LIMIT * 2;
      e.target.selectionEnd = DIGIT_LIMIT * 2;
    }, 0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.target.blur();
    }
  };

  return (
    <div className={`${classes.container}`} ref={containerRef}>
      <input
        className={classes.input}
        data-cy="ScheduleImpact-Input"
        disabled={props.disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder="+/- 0"
        ref={props.inputRef}
        value={displayValue}
      />
    </div>
  );
};

export default withStyles(styles)(ScheduleImpactInput);
