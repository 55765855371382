import { FC } from 'react';

import { DesignPhaseType } from '../../../generated/graphql';
import { Select } from '../../scales';

type MilestoneDesignPhaseSelectorProps = {
  disabled?: boolean;
  selectedValue?: string;
  setSelection: (designPhase: DesignPhaseType) => void;
  values: DesignPhaseType[];
};

const MilestoneDesignPhaseSelector: FC<MilestoneDesignPhaseSelectorProps> = ({
  disabled = false,
  selectedValue,
  setSelection,
  values,
}) => {
  const entries = values.map((designPhase: DesignPhaseType) => {
    const label = designPhase.abbreviation
      ? `${designPhase.name} (${designPhase.abbreviation})`
      : designPhase.name;
    const { id } = designPhase;
    return { id, label };
  });

  return (
    <Select
      data-cy="dropdown-designPhase"
      aria-label="Select a design phase"
      isDisabled={disabled}
      onChange={(id: string) => {
        const newProp = values.find((p: DesignPhaseType) => p.id === id);
        if (newProp) setSelection(newProp);
      }}
      entries={entries}
      value={selectedValue}
      placeholder="Select a design phase"
    />
  );
};

export default MilestoneDesignPhaseSelector;
