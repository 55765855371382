import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { generateSignupPath } from '../../SignUpLayerUtils';

import { useCreateCompanyUserMutation } from './ConfirmCompanyHooks';

const ConfirmCompanyData: FC = () => {
  const projectID = getProjectIdFromUrl();
  const createCompanyUser = useCreateCompanyUserMutation();
  const navigate = useNavigate();

  useCompanyUserQuery(undefined, (data) => {
    const companyID = data?.companyUser?.matchedCompany?.id;
    const path = generateSignupPath(JoinProjectRoutes.SIGNUP_SETUP_USER, projectID);
    if (companyID && path) {
      createCompanyUser(companyID, false, null);
      navigate(path);
    }
  });

  return null;
};

export default ConfirmCompanyData;
