import { UserSource } from '../../../../generated/graphql';
import { isNonNullable } from '../../../../utilities/types';
import useItemAssetsQuery from '../../../assets/hooks/useItemAssetsQuery';
import useMilestoneAssetsQuery from '../../../assets/hooks/useMilestoneAssetsQuery';

export const returnAssets = (
  itemAssets: UploadedAsset[] = [],
  milestoneAssets: UploadedAsset[] = []
) => {
  if (itemAssets.length > 0) {
    return itemAssets;
  }
  if (milestoneAssets.length > 0) {
    return milestoneAssets;
  }
  return [];
};

export const useEntityAssets = (itemID?: UUID, milestoneID?: UUID) => {
  const itemAssets = useItemAssetsQuery(itemID);
  const milestoneAssets = useMilestoneAssetsQuery(milestoneID);
  return returnAssets(
    itemAssets.data?.itemAssets?.filter(isNonNullable) ?? undefined,
    milestoneAssets.data?.milestoneAssets?.filter(isNonNullable)
  );
};

export const getSignInLabelText = (text: string, userSource?: UserSource) =>
  userSource ? `Signed in as ${userSource.email}` : `Link ${text}`;

export const getSignInButtonText = (text: string, userSource?: UserSource) =>
  userSource ? `Sign out` : `Sign into ${text}`;
