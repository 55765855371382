import Menu, { SubMenu } from 'rc-menu';
import { FC } from 'react';

import { MenuItem, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type ReferencesMenuProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  subMenuItems: React.ReactNode;
  icon?: React.ReactNode;
  title: string;
  subTitle: string;
};

const ReferencesMenu: FC<ReferencesMenuProps> = ({
  classes,
  subMenuItems,
  icon,
  title,
  subTitle,
}) => {
  const titleComponent = (
    <div className={classes.outerMenuTitleContainer}>
      {icon}
      <div className={classes.innerMenuTitleContainer}>
        <Typography className={classes.menuMainText}>{title}</Typography>
        <Typography className={classes.subText}>{subTitle}</Typography>
      </div>
    </div>
  );
  return (
    <MenuItem
      data-cy={`menu-${title}`}
      className={classes.menuMainOuter}
      classes={{ root: classes.menuMainOuterRoot }}
    >
      <Menu className={classes.menuMainInner} expandIcon={<></>}>
        {!subMenuItems && titleComponent}
        {subMenuItems && (
          <SubMenu popupOffset={[8, -10]} title={titleComponent}>
            {subMenuItems}
          </SubMenu>
        )}
      </Menu>
    </MenuItem>
  );
};

export default withStyles(InputsSelectReferenceStyles)(ReferencesMenu);
