import { useMutation } from '@apollo/client';

import { setActiveMilestoneAnalytics } from '../../../analytics/analyticsEventProperties';
import { UpdateProjectMutation, UpdateProjectMutationVariables } from '../../../generated/graphql';
import { updateProjectMutation } from '../../../hooks/queries';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

function useSetMilestoneStatus() {
  const [setMilestoneStatusFunc, mutationResult] = useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(updateProjectMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (projectId: UUID, milestoneId: UUID) =>
    setMilestoneStatusFunc({
      variables: { projectID: projectId, activeMilestoneID: milestoneId },
    }).then(() => {
      sendAnalytics(setActiveMilestoneAnalytics(milestoneId));
    });

  return [submitFunc, mutationResult] as const;
}

export default useSetMilestoneStatus;
