import { SvgIcon } from '@material-ui/core';

const FullHeightIcon = () => (
  <SvgIcon style={{ transform: 'translate(4px, 2px)' }}>
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2857 20H1.42857C0.642857 20 0 19.3571 0 18.5714V1.42857C0 0.642857 0.642857 0 1.42857 0H11.2643C11.85 0 12.5786 0.3 12.9857 0.714286L15 2.74286C15.4143 3.15714 15.7143 3.88571 15.7143 4.46429V18.5714C15.7143 19.3571 15.0714 20 14.2857 20ZM1.42857 1.42857V18.5714H14.2857V4.46429C14.2857 4.25714 14.1357 3.89286 13.9929 3.75L11.9786 1.72143C11.8357 1.57857 11.4714 1.42857 11.2643 1.42857H1.42857Z"
        fill="black"
      />
      <path
        d="M7.84219 17.2285C7.44933 17.2285 7.0779 17.0785 6.79933 16.7999L5.26362 15.2642C4.98504 14.9856 4.98504 14.5356 5.26362 14.257C5.54219 13.9785 5.99219 13.9785 6.27076 14.257L7.80647 15.7927L9.40647 14.257C9.68504 13.9785 10.135 13.9785 10.4136 14.257C10.6922 14.5356 10.6922 14.9856 10.4136 15.2642L8.8779 16.7999C8.59933 17.0785 8.2279 17.2285 7.83504 17.2285H7.84219Z"
        fill="black"
      />
      <path
        d="M5.76392 6.04283C5.57821 6.04283 5.39964 5.9714 5.25678 5.83569C4.97821 5.55711 4.97821 5.10711 5.25678 4.82854L6.79249 3.29283C7.36392 2.7214 8.29964 2.7214 8.87821 3.29283L10.4139 4.82854C10.6925 5.10711 10.6925 5.55711 10.4139 5.83569C10.1354 6.11426 9.68535 6.11426 9.40678 5.83569L7.87107 4.29997L6.27107 5.83569C6.12821 5.97854 5.94964 6.04283 5.76392 6.04283Z"
        fill="black"
      />
      <path
        d="M7.85686 16.3142C7.46401 16.3142 7.14258 15.9927 7.14258 15.5999V3.69988C7.14258 3.30702 7.46401 2.9856 7.85686 2.9856C8.24972 2.9856 8.57115 3.30702 8.57115 3.69988V15.5999C8.57115 15.9927 8.24972 16.3142 7.85686 16.3142Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);

export default FullHeightIcon;
