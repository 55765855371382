import queryString from 'query-string';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import { useVerifyEmailMutation } from './Signup/SignupPages/EmailVerification/EmailVerificationHooks';
import EmailVerificationLayer from './Signup/SignupPages/EmailVerification/EmailVerificationLayer';
import { useAcceptedTOSQuery } from './useTOSHooks';

export default function VerificationLayersData() {
  // There are 2 calls we make to verify user status before we let them use the app.
  // 1. Verify email - we check if they are trying to verify their email with a url token, and complete verification if so.
  // 2. Terms of Service (TOS) - Check if they have accepted the TOS - otherwise, present them with the TOS page

  // Data Loading - Company User in the Signup Layer must wait until after the Email is verified

  // VERIFY EMAIL
  // Get params from url
  const { search } = window.location;
  const emailVerificationToken = useMemo(() => {
    const params = queryString.parse(decodeURIComponent(search));
    const { emailVerify = '' } = params || {};
    return emailVerify as string;
  }, [search]);

  // Check if user is verified, and verify if you have the magic email token
  // Mutation
  const { verifyEmail, mutationResult } = useVerifyEmailMutation();
  useEffectOnce(() => {
    verifyEmail(emailVerificationToken);
  });

  // TOS QUERY
  const acceptedTOSQuery = useAcceptedTOSQuery();

  // Components - we render the layers in order that they need to be enforced
  return (
    <EmailVerificationLayer acceptedTOSQuery={acceptedTOSQuery} mutationResult={mutationResult} />
  );
}
