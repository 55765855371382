import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import Script from 'react-load-script';

import './AssetsModelForgeWrapper.css';
import { Vector4 } from 'three/src/math/Vector4';

import theme from '../../../../../theme/komodo-mui-theme';
import { tokenUtil } from '../../../../../utilities/authUtils';

// CONSTANTS
const version = '7.12';

// COMPONENTS
const loadingMsg = (
  <div className="message">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
    </svg>
    <div>Starting Viewer...</div>
  </div>
);

const errorMsg = (
  <div className="message">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="8" x2="12" y2="12" />
      <line x1="12" y1="16" x2="12" y2="16" />
    </svg>
    <div>Viewer Error</div>
  </div>
);

const placeholder = (enable, error) =>
  enable ? null : <div className="scrim">{error ? errorMsg : loadingMsg}</div>;

export default class ForgeViewerWrapper extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    isLoaded: PropTypes.func.isRequired,
    returnThumbnail: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.markup = null;
    this.viewer = null;
    this.viewerDiv = createRef();

    this.state = {
      error: false,
      accessToken: undefined,
    };
    // CLASS FUNCTIONS
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handleViewerInit = this.handleViewerInit.bind(this);
    this.handleModelLoaded = this.handleModelLoaded.bind(this);
    this.enterMarkupMode = this.enterMarkupMode.bind(this);
    this.handleTokenRequest = this.handleTokenRequest.bind(this);
  }

  componentDidMount() {
    const getToken = async () => {
      const token = localStorage.getItem('skip_signup_flow')
        ? localStorage.getItem('access_token')
        : await tokenUtil.getAccessTokenSilently();
      this.setState({ accessToken: token });
    };
    getToken();
  }

  handleTokenRequest = () => {
    return this.state.accessToken;
  };

  getThumbnail(i) {
    const { returnThumbnail } = this.props;
    const screenshot = new Image();
    const canvas = document.getElementById('svgThumbnail');
    let w = this.viewer.container.clientWidth;
    let h = this.viewer.container.clientHeight;
    let dx = 0;
    let dy = 0;
    if (w > h) {
      dx = (w - h) / 2;
      w = h;
    } else {
      dy = (h - w) / 2;
      h = w;
    }
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 120, 120);
    screenshot.onload = () => {
      ctx.drawImage(screenshot, dx, dy, w, h, 0, 0, 120, 120);
      const svgImage = new Image();
      svgImage.onload = () => {
        ctx.drawImage(svgImage, dx, dy, w, h, 0, 0, 120, 120);
        returnThumbnail(i, canvas.toDataURL());
      };
      if (this.markup && Object.keys(this.markup.svgLayersMap).length > 0) {
        svgImage.src = `data:image/svg+xml,${this.markup.generateData()}`;
      } else {
        returnThumbnail(i, canvas.toDataURL());
      }
    };
    this.viewer.getScreenShot(
      this.viewer.container.clientWidth,
      this.viewer.container.clientHeight,
      (blobURL) => {
        screenshot.src = blobURL;
      }
    );
  }

  // SECTION CALLBACKS
  getSection() {
    const state = this.viewer.getState();
    return state.cutplanes;
  }

  setSection(sections) {
    if (sections) {
      const planes = [];
      for (let p = 0; p < sections.length; p += 1) {
        const vector4 = new Vector4(...sections[p]);
        planes.push(vector4);
      }
      if (this.viewer) {
        this.viewer.restoreState(null);
        this.viewer.setCutPlanes(planes);
      }
    }
  }

  // CAMERA CALLBACKS
  getCamera() {
    const nav = this.viewer.navigation;
    return { pos: nav.getPosition(), tar: nav.getTarget(), upv: nav.getCameraUpVector() };
  }

  setCamera(tar, pos, upv) {
    const nav = this.viewer.navigation;
    if (tar && pos && upv) {
      nav.setCameraUpVector(upv);
      nav.setTarget(tar);
      nav.setPosition(pos);
    }
  }

  // MARKUP CALLBACKS
  setMarkup(markupData) {
    if (this.markup) {
      // make sure we are in mark view, not markup edit
      if (!this.markup.duringViewMode) {
        this.markup.enterEditMode();
        this.markup.leaveEditMode();
      }
      // remove any old markup layer
      if (this.markup.svgLayersMap && this.markup.svgLayersMap.markupLayer)
        this.markup.unloadMarkups('markupLayer');
      // draw this markup layer (its our stored SVG)
      this.viewer.toolController.setIsLocked(true);
      this.markup.loadMarkups(markupData, 'markupLayer');
    }
  }

  changeMarkupMode(selectMode) {
    const core = window.Autodesk.Extensions.Markup.Core;
    const types = [
      new core.EditModeArrow(this.markup),
      new core.EditModeCircle(this.markup),
      new core.EditModeCloud(this.markup),
      new core.EditModeFreehand(this.markup),
      new core.EditModeRectangle(this.markup),
      new core.EditModeText(this.markup),
    ];
    const mode = types.find((typeMode) => typeMode.type === selectMode);
    if (mode) {
      this.markup.changeEditMode(mode);
      const stroke = 5 * this.markup.initialStrokeWidth;
      const fontSize = 3 * this.markup.initialFontWidth;
      const styleObject = {
        'font-family': theme.typography.fontFamily,
        'font-size': fontSize,
        'stroke-color': theme.modelViewer.selectColor,
        'stroke-width': stroke,
        'stroke-opacity': 1,
      };
      this.markup.setStyle(styleObject);
    }
  }

  enterMarkupMode(mode) {
    if (this.markup.svgLayersMap.markupLayer) this.markup.unloadMarkups('markupLayer');
    this.markup.enterEditMode();
    if (mode) this.changeMarkupMode(mode);
  }

  saveAndExitMarkup() {
    const markupData = this.markup.generateData();
    // exiting markup mode
    this.markup.clear();
    this.markup.leaveEditMode();
    // markups are now an SVG layer
    this.markup.loadMarkups(markupData, 'markupLayer');
    return markupData;
  }

  removeMarkups() {
    if (this.markup) {
      if (this.markup.svgLayersMap && this.markup.svgLayersMap.markupLayer) {
        this.markup.unloadMarkups('markupLayer');
      }
      this.markup.hide();
    }
    this.viewer.toolController.setIsLocked(false);
    this.viewer.showAll();
  }

  // MODEL LOADING
  // eslint-disable-next-line react/sort-comp
  handleModelLoaded() {
    const { isLoaded } = this.props;
    this.viewer.setLightPreset(1);
    isLoaded(true);
  }

  // VIEWER UP AND DOWN
  async handleScriptLoad() {
    const options = {
      env: 'AutodeskProduction',
      getAccessToken: this.handleTokenRequest,
      useADP: false,
    };
    if (this.state.accessToken) {
      window.Autodesk.Viewing.theExtensionManager.unregisterExtension('Autodesk.BimWalk');
      window.Autodesk.Viewing.theExtensionManager.unregisterExtension('Autodesk.Explode');
      window.Autodesk.Viewing.theExtensionManager.unregisterExtension('Autodesk.ViewCubeUi');
      window.Autodesk.Viewing.Initializer(options, this.handleViewerInit.bind(this));
    }
  }

  handleViewerInit() {
    const { url } = this.props;
    this.viewer = new window.Autodesk.Viewing.Private.GuiViewer3D(this.viewerDiv.current);
    const errorCode = this.viewer.start();
    if (!errorCode) {
      this.viewer.loadExtension('Autodesk.Viewing.MarkupsCore').then((markup) => {
        this.markup = markup;
      });
      this.setState({ error: false });
      this.viewer.loadModel(url, [], this.handleModelLoaded, () => this.setState({ error: true }));
    }
  }

  closeViewer() {
    if (this.viewer && this.viewer.running) {
      this.viewer.tearDown(); // unloads model
    }
    this.viewer = null;
    this.markup = null;
  }

  render() {
    const { error } = this.state;

    return (
      <div className="ForgeViewer">
        <Script
          url={`https://developer.api.autodesk.com/modelderivative/v2/viewers/viewer3D.min.js?v=v${version}`}
          onLoad={this.handleScriptLoad}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`https://developer.api.autodesk.com/modelderivative/v2/viewers/style.min.css?v=v${version}`}
        />
        <div ref={this.viewerDiv} />
        {placeholder(!!this.viewer, error)}
      </div>
    );
  }
}
