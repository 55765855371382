import { FC, ReactNode } from 'react';
import * as React from 'react';

import { Dialog } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsStyles from '../DialogsStyles';

import DialogsConfirmActions from './DialogsConfirmActions';
import DialogsConfirmContent from './DialogsConfirmContent';

type Props = {
  acceptCtaCopy?: string;
  body: ReactNode;
  cancelCtaCopy?: string;
  classes: Classes<typeof DialogsStyles>;
  destructiveConfirm?: boolean;
  onCancel?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose?: () => void;
  onConfirm?: () => void;
  onDisable?: () => void;
  open: boolean;
  title: string;
};

const DialogsConfirm: FC<Props> = ({
  acceptCtaCopy,
  body,
  cancelCtaCopy,
  classes,
  destructiveConfirm,
  onCancel,
  onClose,
  onConfirm,
  onDisable,
  open,
  title,
}) => (
  <Dialog
    maxWidth="sm"
    classes={{
      paper: classes.dialogPaperSmall,
    }}
    open={open}
    onClose={onClose}
  >
    <DialogsConfirmContent body={body} onCancel={onCancel} onClose={onClose} title={title} />
    <DialogsConfirmActions
      acceptCtaCopy={acceptCtaCopy}
      cancelCtaCopy={cancelCtaCopy}
      destructiveConfirm={destructiveConfirm}
      onClose={onClose}
      onConfirm={onConfirm}
      onDisable={onDisable}
    />
  </Dialog>
);

export default withStyles(DialogsStyles)(DialogsConfirm);
