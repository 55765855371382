import { useCallback } from 'react';

import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { ProjectTermProvider } from '../../ProjectDisplaySettings/TerminologyProvider';

import useLastViewedProjectIDs from './hooks/useLastViewedProjectIDs';
import SidebarCollapsed from './SidebarCollapsed';
import SidebarExpanded from './SidebarExpanded';

export default function Sidebar() {
  const [expanded, setExpanded] = useLocalStorage('NAV_SIDEBAR_EXPANDED', true);
  const onToggleExpanded = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, [setExpanded]);

  const companyUserResult = useCompanyUserQuery();
  const company =
    companyUserResult.data?.companyUser?.company ??
    companyUserResult.previousData?.companyUser?.company ??
    undefined;
  const user =
    companyUserResult.data?.companyUser?.user ??
    companyUserResult.previousData?.companyUser?.user ??
    undefined;

  const lastViewedProjectIDs = useLastViewedProjectIDs();

  const content = expanded ? (
    <SidebarExpanded
      company={company}
      lastViewedProjectIDs={lastViewedProjectIDs}
      onCollapse={onToggleExpanded}
      user={user}
    />
  ) : (
    <SidebarCollapsed
      company={company}
      lastViewedProjectIDs={lastViewedProjectIDs}
      onExpand={onToggleExpanded}
      user={user}
    />
  );

  return (
    <ProjectTermProvider projectID={lastViewedProjectIDs[0]}>
      <nav className="h-screen shrink-0 bg-background-primary text-type-primary">{content}</nav>
    </ProjectTermProvider>
  );
}
