import { ButtonBase, createStyles } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';

import { KomodoTheme, withStyles } from '../../../../theme/komodo-mui-theme';

import { HiddenCostsLine } from './HiddenCostsDialogUtils';

const HiddenCostsLineSelection = (props: {
  classes: Classes<typeof styles>;
  lines: HiddenCostsLine[];
  onIncludeAllLines: () => void;
  onToggleLine: (line: string) => void;
}) => {
  const { classes } = props;
  return (
    <div>
      <div className={classes.row}>
        <div className={classes.text}>
          Add rows back to your report by clicking the &quot;+&quot;
        </div>
        <ButtonBase
          data-cy="hiddenCostsLineSelection-addAll"
          onClick={props.onIncludeAllLines}
          className={classes.text_link}
        >
          Add all
        </ButtonBase>
      </div>
      {props.lines.map((line) => {
        return (
          <div className={classes.row_indented} key={line.displayName}>
            <div className={`${classes.text} ${line.isExcluded ? classes.excluded : ''}`}>
              {line.displayName}
            </div>
            <ButtonBase
              data-cy="hiddenCostsLineSelection-icon"
              onClick={() => props.onToggleLine(line.displayName)}
            >
              {line.isExcluded ? <Add /> : <Check />}
            </ButtonBase>
          </div>
        );
      })}
    </div>
  );
};
const styles = (theme: KomodoTheme) =>
  createStyles({
    excluded: {
      color: theme.palette.disabledGrey,
    },
    row: {
      alignSelf: 'center',
      display: 'flex',
      height: 32,
      padding: '2px 8px 2px 8px',
    },
    row_indented: {
      alignSelf: 'center',
      display: 'flex',
      height: 32,
      padding: '2px 16px 2px 16px',
      '&:hover': {
        backgroundColor: theme.palette.backgroundGreen,
      },
    },
    text: {
      alignSelf: 'center',
      flexGrow: 1,
      fontFamily: theme.fontFamily,
      fontSize: 13,
      fontWeight: 500,
      letterSpacing: '0.0119em',
      lineHeight: '18px',
      paddingLeft: 8,
    },
    text_link: {
      ...theme.typography.webSmallLink,
    },
  });

export default withStyles(styles)(HiddenCostsLineSelection);
