import { Edit } from '@material-ui/icons';

import { getProjectIdFromUrl } from '../../../utilities/url';
import ItemsButtonAddEstimate from '../../Items/ItemsButtonAddEstimate/ItemsButtonAddEstimate';

type Props = {
  itemID: UUID;
  onItemMutated?: () => void;
  canEditEstimate: boolean;
};

const ItemSidebarNoEstimate = (props: Props) => {
  const { canEditEstimate, itemID } = props;
  const projectID = getProjectIdFromUrl();

  return canEditEstimate ? (
    <div className="bg-background-primary px-5 py-2">
      <ItemsButtonAddEstimate
        icon={<Edit />}
        itemId={itemID}
        projectId={projectID}
        setIsAllExpanded={() => {}}
        onItemMutated={props.onItemMutated}
      />
    </div>
  ) : null;
};

export default ItemSidebarNoEstimate;
