import { ERROR, NEW } from '../../constants';
import { removeYear } from '../../utilities/string';

import { Status } from './types';

type Cat = {
  name: string;
  id: UUID;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const computeIssue = (e: any, status: string) => {
  if (status === NEW) {
    return {
      id: e.id,
      status,
      title: `${e.content.categorizations.length} new categorizations from columns in your estimate`,
      detailsTitle: 'Categorizations',
      details: e.content.categorizations.map(({ name, id }: Cat) => ({ name, id })),
      actionText: null,
    };
  }
  if (status === ERROR) {
    return {
      id: e.id,
      status,
      title: `${e.unresolvedCount} unrecognized categories in `,
      lines: e.content.lines || null,
      categorizationName: removeYear(
        e.content.categorization.name,
        e.content.categorization.builtin
      ),
      categorizationId: e.content.categorization.id,
      detailsTitle: null,
      details: null,
      actionText: 'Go to column',
    };
  }
  return {};
};

export const isUploadError = (error: string | undefined, isUploading: boolean) =>
  error && !isUploading;
export const isUploadProgress = (isUploading: boolean, error: string | undefined) =>
  isUploading && !error;
export const isImportInProgress = (
  isUploading: boolean,
  error: string | undefined,
  hasLastUploadedEstimate: boolean,
  isImportProgress: boolean
) => !isUploading && !error && hasLastUploadedEstimate && isImportProgress;
export const isDraftEstimate = (
  isUploading: boolean,
  error: string | undefined,
  estimateId: UUID | undefined,
  hideCurrentUpload: boolean
) => !isUploading && !error && estimateId && !hideCurrentUpload;

export const isUploadingStatusDisplayed = (
  importEstimate: ImportEstimateParameters,
  fileStatus: Status,
  hasLastUploadedEstimate: boolean,
  hideCurrentUpload: boolean
) => {
  const isImportProgress = !!importEstimate.id;
  const { isUploading, estimateId, error } = fileStatus;
  const hasUploadError = isUploadError(error, isUploading);
  const hasUploadProgress = isUploadProgress(isUploading, error);
  const hasImportInProgress = isImportInProgress(
    isUploading,
    error,
    hasLastUploadedEstimate,
    isImportProgress
  );
  const hasDraftEstimate = isDraftEstimate(isUploading, error, estimateId, hideCurrentUpload);
  return hasUploadError || hasImportInProgress || hasDraftEstimate || hasUploadProgress;
};

const isValidEstimateFile = (file: File): boolean => {
  return /\.(xlsx|xml|zip)$/i.test(file.name);
};

export const getEstimateFileError = (file: File) => {
  if (/\.(xls)$/i.test(file.name))
    return 'File format .xls is not accepted. Save the excel file as .xlsx and try again.';
  if (/\.(est)$/i.test(file.name))
    return '.est file is invalid, if this was exported from WinEst, please ensure you have exported the file correctly.';
  if (!isValidEstimateFile(file))
    return 'Selected file is not an accepted file type, please provide an .xlsx, .xml, or .zip file.';
  return undefined;
};
