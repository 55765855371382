import StatusBanner from '../StatusBanner/StatusBanner';

export default function UploadError(props: { label?: string; description?: string | string[] }) {
  return (
    <div className="w-full text-left">
      <StatusBanner type="error" data-cy="import-error-banner">
        <strong data-cy="import-text-error">{props.label}</strong>
        {Array.isArray(props.description)
          ? props.description.map((text) => (
              <div className="type-body2" key={text}>
                {text}
              </div>
            ))
          : props.description}
      </StatusBanner>
    </div>
  );
}
