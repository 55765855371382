import { FC, KeyboardEvent } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Typography, withStyles } from '@material-ui/core';

import { useTitleHeight } from '../../api/apollo/reactiveVars';

import DashboardAnchorStyles from './DashboardAnchorStyles';
import { DASH_STICKY_HEIGHT } from './DashboardStyles';

type DashboardAnchorProps = {
  anchor: string;
  classes: Classes<typeof DashboardAnchorStyles>;
};

// HELPER
const cleanAnchor = (str: string) => str.replace(/\s/g, '').toLowerCase();

const onAction = (navigate: NavigateFunction, anchor: string) => {
  const idAnchor = `#${anchor}`;
  const el = document.querySelector(idAnchor);
  if (el) {
    el.scrollIntoView();
  }
  if (navigate) {
    navigate({ hash: idAnchor });
  } else {
    window.location.hash = idAnchor;
  }
};

const DashboardAnchor: FC<DashboardAnchorProps> = ({ anchor, classes }) => {
  const navigate = useNavigate();
  const titleHeight = useTitleHeight();
  const scrollMarginTop = DASH_STICKY_HEIGHT + titleHeight + 8; // classy padding

  return (
    <div
      className={classes.link}
      onClick={() => {
        onAction(navigate, cleanAnchor(anchor));
      }}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onAction(navigate, cleanAnchor(anchor));
        }
      }}
      role="button"
      tabIndex={-1}
    >
      <Typography style={{ scrollMarginTop }} id={cleanAnchor(anchor)} variant="headline">
        {anchor}
      </Typography>
    </div>
  );
};

export default withStyles(DashboardAnchorStyles)(DashboardAnchor);
