import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useScroll } from 'react-use';

import { ApolloError, useReactiveVar } from '@apollo/client';
import { Card, Divider } from '@material-ui/core';

import { reportVariance, varianceEventTypes } from '../../../analytics/analyticsEventProperties';
import {
  isDownloadingCostReportToExcelVar,
  useTitleHeight,
} from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { DISPLAY } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  checkCostModeIncludesMarkups,
  costModeVar,
  useCostMode,
} from '../../../utilities/costMode';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { GetGroupBysInput } from '../../CostReport/hooks/useMilestoneCostReportQuery';
import { isPrintKeys } from '../../Print/PrintUtils';
import { useLoadUserReportComments } from '../../ReportsTab/ReportHooks';
import { useVarianceReportsQuery } from '../hooks/useVarianceReportsQuery';
import useVarianceReportParams from '../useVarianceReportParams';
import VarianceCostReport from '../VarianceCostReport';
import VarianceReportHeader from '../VarianceReportHeader/VarianceReportHeader';
import {
  getUserReportCommentQueryVariables,
  getVarianceColumnInputs,
  getVarianceReportCommentCount,
  useCurrentUserReportID,
  useIncludedColumnExpressions,
  useVarianceDescriptions,
} from '../VarianceReportUtils';

import styles from './VarianceReportStyles';

type VarianceReportProps = {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  error?: ApolloError;
  milestoneID: UUID;
  setIsLoading?: (loading: boolean) => void;
};

const VarianceReport: FC<VarianceReportProps> = ({
  categorizations,
  classes,
  error,
  milestoneID,
  setIsLoading = () => {},
}) => {
  // Read our project id from the URL to populate queries
  const projectId = getProjectIdFromUrl();
  const costMode = useReactiveVar(costModeVar);
  const reportId = useCurrentUserReportID();
  const costModeString = useCostMode();
  const sendAnalytics = useAnalyticsEventHook();
  const { displayGroupBy, filterManager, setSettings, settings } = useVarianceReportParams(
    categorizations,
    projectId
  );

  useEffect(() => {
    // clear filters when there is no reportId
    if (!reportId) setSettings({ viewFilter: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, []);

  const { data: projectData, loading: loadingProjectProps } = useProjectPropsQuery(projectId);
  const projectName = projectData?.project?.name || '';

  const { milestoneIDs, types, dates } = settings as VarianceSettings;

  // Scrolling
  // We are gonna watch the x scrolling and pass along a prop to turn on the border
  // we throttle the listening and give it a threshold on to overwhelm us
  const [scroll, setScroll] = useState(false);
  const scrollRef = useRef(null);
  const { x } = useScroll(scrollRef);
  useEffect(() => {
    if (x > 2) {
      if (!scroll) setScroll(true);
    } else if (scroll) {
      setScroll(false);
    }
  }, [x, scroll]);

  const offsetHeight = useTitleHeight() + 4; // height and padding
  const height = `calc(100vh - ${offsetHeight}px`;

  // Data needed
  const { includedExpressions, expressionCount } = useIncludedColumnExpressions(settings);

  const { filterQueryInput: viewFilter } = filterManager;
  const varianceColumnInputs = getVarianceColumnInputs(settings, includedExpressions);

  const { data, loading } = useVarianceReportsQuery(
    projectId,
    displayGroupBy,
    varianceColumnInputs,
    viewFilter
  );
  const varianceReports = data?.varianceReports || [];

  const viewParams = getUserReportCommentQueryVariables(displayGroupBy, varianceColumnInputs);
  const varianceReportComments =
    useLoadUserReportComments(reportId, viewParams, costMode)?.data?.varianceReportComments ??
    undefined;
  const numberOfComments = getVarianceReportCommentCount(varianceReportComments);

  // Columns for Variance Report
  const { varianceDescriptions, headerDescriptions } = useVarianceDescriptions(
    varianceReports,
    settings,
    includedExpressions,
    expressionCount
  );

  const groupByItems = GetGroupBysInput(displayGroupBy);
  const fileNameTokens = [projectName];

  const exportCostReport = () => {
    if (isDownloadingCostReportToExcelVar()) return;
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_EXPORT_REPORT, {
        milestoneIDs,
        types,
        dates,
        numberOfComments,
      })
    );
    JoinAPI.exportCostReport(
      projectId,
      groupByItems,
      varianceColumnInputs,
      viewFilter,
      costMode,
      checkCostModeIncludesMarkups(costModeString),
      true,
      fileNameTokens,
      viewParams,
      settings?.estimateLines === DISPLAY
    );
  };

  const { search } = window.location;
  // Print click and key press
  const printOnClick = useCallback(() => {
    window.open(
      generateSharedPath(JoinProjectRoutes.PRINT_VARIANCE, { projectId, search, reportId }),
      '_blank'
    );
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_PRINT, {
        milestoneIDs,
        types,
        dates,
        numberOfComments,
      })
    );
  }, [projectId, search, sendAnalytics, reportId, numberOfComments, dates, milestoneIDs, types]);
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        printOnClick();
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [printOnClick]);

  return (
    <div className={classes.root} style={{ height }}>
      <Card square className={classes.card} elevation={0}>
        <div ref={scrollRef} className={classes.scrollBody}>
          <div className={classes.sticky}>
            <VarianceReportHeader
              exportCostReport={exportCostReport}
              projectId={projectId}
              filterManager={filterManager}
              printOnClick={printOnClick}
              settings={settings}
              setSettings={setSettings}
              varianceDescriptions={headerDescriptions}
            />
            <Divider />
          </div>
          <VarianceCostReport
            activeMilestoneID={milestoneID}
            categorizations={categorizations}
            displayGroupBy={displayGroupBy}
            error={error}
            filterManager={filterManager}
            headerDescriptions={headerDescriptions}
            loading={loading || loadingProjectProps}
            milestoneEstimates={viewParams.milestoneEstimates}
            settings={settings}
            scroll={scroll}
            setIsLoading={setIsLoading}
            setSettings={setSettings}
            varianceReports={varianceReports}
            varianceReportComments={varianceReportComments}
            varianceDescriptions={varianceDescriptions}
          />
        </div>
      </Card>
    </div>
  );
};

export default withStyles(styles)(VarianceReport);
