import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  UserSourceSystemEventType,
  getUserSourceSystemAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import DialogsConfirm from '../../../Dialogs/DialogsConfirm/DialogsConfirm';
import { Button } from '../../../scales';
import { useRemoveUserSource, useUserSourcesQuery } from '../../hooks';
import {
  getSourceAuthURL,
  getSourceNameText,
  getSourcesInfo,
} from '../FilesDialog/FilesDialogUtils';

import styles from './FilesSourcesStyles';
import { getSignInButtonText, getSignInLabelText } from './FilesSourcesUtils';

export interface Selected {
  [key: string]: boolean;
}

type FilesSourcesProps = {
  classes: Classes<typeof styles>;
};

const FilesSources: FC<FilesSourcesProps> = ({ classes }) => {
  const { data } = useUserSourcesQuery();
  const sendAnalytics = useSendAnalyticsEventHook();
  const availableSources = data?.userSources.sources || [];
  const clients = data?.userSources.clients || [];
  const sourcesInfo = getSourcesInfo(clients);
  const [userSourceToRemove, setUserSourceToRemove] = useState<UserSource | null>(null);
  const [removeUserSource] = useRemoveUserSource();
  const removeSource = () => {
    if (userSourceToRemove) {
      removeUserSource(userSourceToRemove.id, () => {
        sendAnalytics(
          getUserSourceSystemAnalyticsEvent(
            UserSourceSystemEventType.REMOVE,
            userSourceToRemove.sourceSystem
          )
        );

        setUserSourceToRemove(null);
      });
    }
  };

  const sourceComps = sourcesInfo.map((sourceInfo) => {
    const text = getSourceNameText(sourceInfo.sourceSystem);
    const userSource = availableSources.find((s) => s.sourceSystem === sourceInfo.sourceSystem);
    const onClick = () =>
      userSource
        ? setUserSourceToRemove(userSource)
        : window.open(getSourceAuthURL(sourceInfo), '_blank');
    return (
      <div className={classes.source} key={sourceInfo.sourceSystem}>
        <img className={classes.logo} src={sourceInfo.img} alt={text} />
        <Typography className={classes.padded}>{getSignInLabelText(text, userSource)}</Typography>
        <Button
          data-cy={`sign-in-${sourceInfo.sourceSystem}`}
          label={getSignInButtonText(text, userSource)}
          type="secondary"
          onClick={onClick}
        />
      </div>
    );
  });

  return (
    <div className={classes.contentRoot}>
      <div className={classes.column}>
        <Typography className={classes.padded} variant="title">
          Link cloud storage accounts
        </Typography>
        <Typography className={classes.padded} variant="subheading">
          Connect online storage accounts to work with your files within Join.
        </Typography>
        <div className={classes.sources}>{sourceComps}</div>
      </div>
      <DialogsConfirm
        open={!!userSourceToRemove}
        title={`Remove ${
          userSourceToRemove ? `${getSourceNameText(userSourceToRemove.sourceSystem)} ` : ''
        }Connection`}
        body="Would you like to disconnect this file connection? This will prevent you from navigating this cloud storage to attach linked assets."
        onConfirm={removeSource}
        onClose={() => setUserSourceToRemove(null)}
      />
    </div>
  );
};

export default withStyles(styles)(FilesSources);
