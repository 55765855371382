import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AnalyticsEvent,
  EventProperties,
  SendAnalyticsFn,
  analyticsEvent,
} from '../analytics/analyticsEventProperties';
import { usePreviewSettingsVar } from '../api/apollo/reactiveVars';
import { AnalyticsProperties, AnalyticsPropertiesQuery } from '../generated/graphql';
import { getPageView } from '../utilities/getPageView';
import { getProjectIdFromUrl } from '../utilities/url';
import { useAuth0Properties } from '../utilities/userProfile';

import useAnalyticsPropertiesQuery from './useAnalyticsPropertiesQuery';

const CLEAR_TYPENAME = { __typename: undefined } as const;

export type { EventProperties };
export type { AnalyticsEvent };
export type { SendAnalyticsFn };

// useAnalyticsProperties - Hook that queries Analytics Properties
const useAnalyticsProperties = () => {
  const projectId = getProjectIdFromUrl();
  const { previewRoleId, previewUserId } = usePreviewSettingsVar();
  // if lazy, use lazy query with onCompleted
  return useAnalyticsPropertiesQuery(projectId || undefined, previewRoleId, previewUserId);
};

//  accumulateProperties - Helper for gathering all analytics event properties
const accumulateProperties = (
  eventProperties: EventProperties | undefined,
  analyticsProperties: AnalyticsPropertiesQuery['analyticsProperties']
) => {
  const { projectProperties, permissionsProperties, groupProperties } = analyticsProperties;

  return {
    ...eventProperties,
    ...permissionsProperties,
    ...projectProperties,
    ...groupProperties,
    ...CLEAR_TYPENAME,
    screenWidth: window?.innerWidth,
    screenHeight: window?.innerHeight,
  };
};

const track = (analyticsEvent: AnalyticsEvent, analyticsProperties: AnalyticsProperties) => {
  const { type, eventProperties } = analyticsEvent;

  const properties = accumulateProperties(eventProperties, analyticsProperties);

  window?.analytics?.track(type, properties);
};

// useSendAnalyticsEventHook - Hook for our most common sendAnalytics Call + Properties
const useSendAnalyticsEventHook: () => SendAnalyticsFn = () => {
  const { analytics } = window || {};
  // ID user first ...
  const { email } = useAuth0Properties();
  if (analytics && typeof analytics.user === 'function') analytics.user().id(email);

  // Then, return the callback with analytics props
  const { data, refetch } = useAnalyticsProperties();

  const sendAnalytics = useCallback(
    (analyticsEvent: AnalyticsEvent) => {
      if (data) {
        track(analyticsEvent, data.analyticsProperties);
      } else if (refetch) {
        refetch().then((refetchedData) =>
          track(analyticsEvent, refetchedData.data.analyticsProperties)
        );
      }
    },
    [data, refetch]
  );
  return sendAnalytics;
};

// EXPORTS
export default useSendAnalyticsEventHook;

// useAnalyticsIdentifyUser - Hook for User Identify on Changes
export const useAnalyticsIdentifyUser = () => {
  const [isIdentified, setIsIdentified] = useState(false);
  const { analytics } = window || {};
  const auth0Properties = useAuth0Properties();
  const { data } = useAnalyticsProperties();
  const { analyticsProperties } = data || {};
  const { companyProperties, userProperties, projectProperties } = analyticsProperties || {};
  const { projectId } = projectProperties || {};
  const { companyId } = companyProperties || {};

  useEffect(() => {
    if (analytics && projectId) analytics.group(projectId);
  }, [analytics, projectId]);

  useEffect(() => {
    if (analytics && companyId) analytics.group(companyId);
  }, [analytics, companyId]);

  useEffect(() => {
    if (!isIdentified && analytics && userProperties && companyProperties && auth0Properties) {
      const userTraits = {
        ...auth0Properties,
        ...companyProperties,
        ...userProperties,
        ...CLEAR_TYPENAME,
      };
      analytics.identify(userTraits);
      setIsIdentified(true);
    }
  }, [analytics, auth0Properties, userProperties, companyProperties, isIdentified]);
};

// useAnalyticsLocationViewEvent - Hook for reacting to location change with an event
export const useAnalyticsLocationViewEvent = () => {
  const location = useLocation();
  const { pathname } = location;
  const sendAnalytics = useSendAnalyticsEventHook();
  useEffect(() => {
    sendAnalytics(analyticsEvent(getPageView(pathname)));
  }, [pathname, sendAnalytics]);
};
