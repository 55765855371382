import { GridVariant } from '../../../actions/gridAnalytics';
import { ItemDataQuery } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { getItemCost } from '../../../utilities/items';
import useMemoWrapper from '../../useMemoWrapper';
import CostEstimate from '../CostEstimate';

import EstimateTotal from './EstimateTotal';

type Props = {
  estimateHandle: string;
  item: ItemDataQuery['item'];
  onItemMutated?: () => void;
  projectID: UUID;
};
export default function EstimateAccordionItemContent(props: Props) {
  const { activeEstimate, categories, id } = props.item || {};
  const costMode = useCostMode(props.projectID);
  const sendAnalytics = useAnalyticsEventHook();
  const { parent } = (props.item as Option) || {};
  const isOption = !!parent;
  const cost = useMemoWrapper(getItemCost, props.item);

  const { estimateHandle } = props;
  return (
    <>
      <CostEstimate
        activeEstimateID={activeEstimate?.id}
        canViewDetails
        isOption={isOption}
        itemId={id}
        itemTrade={categories}
        onItemMutated={props.onItemMutated}
        projectID={props.projectID}
        variant={GridVariant.ITEM_FULLSCREEN}
        sendAnalytics={sendAnalytics}
      />
      <EstimateTotal
        isEstimate
        header={`${estimateHandle} (${
          checkCostModeIncludesMarkups(costMode) ? 'Including Markups' : 'Excluding Markups'
        })`}
        cost={cost as Cost}
      />
    </>
  );
}
