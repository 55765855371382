import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { selectedAssetsVar } from '../../../api/apollo/reactiveVars';
import { SourceSystem, UserSource } from '../../../generated/graphql';
import useMemoWrapper from '../../useMemoWrapper';
import FilesTable from '../Files/FilesTable/FilesTable';
import { useFilesTableSelection } from '../Files/FilesTable/FilesTableUseUtils';
import { FileTableCell } from '../Files/FilesTable/FilesTableUtils';

import FileExplorerFolderContents from './FileExplorerFolderContents';
import FileExplorerProjectRoot from './FileExplorerProjectRoot';
import FilesExplorerError from './FilesExplorerError';
import { getErrorMessage, getFileTableProjectsEntries } from './FilesExplorerUtils';
import { useLoadSourceHubProjects } from './hooks/FileExplorerHooks';

type FileExplorerHubProjectsProps = {
  hubID: string;
  userSource: UserSource;
};

const FileExplorerHubProjects: FC<FileExplorerHubProjectsProps> = ({ hubID, userSource }) => {
  const { data, loading, error } = useLoadSourceHubProjects(
    userSource.sourceSystem,
    userSource.id,
    hubID
  );
  const { selectedMap } = useReactiveVar(selectedAssetsVar);
  const projects = data?.sourceHubProjects;
  const fileTableEntries = useMemoWrapper(getFileTableProjectsEntries, projects);
  const { onEntry, onToggleEntry, selectedIds, onSelectAll, onUnselectAll } =
    useFilesTableSelection();

  const errorMessage = getErrorMessage(projects, error);
  if (errorMessage) return <FilesExplorerError error={errorMessage} userSource={userSource} />;

  const getNextElement = (entry: FileTableCell) => {
    switch (userSource.sourceSystem) {
      case SourceSystem.PROCORE: {
        return (
          <FileExplorerFolderContents
            hubID={hubID}
            userSource={userSource}
            sourceProjectID={entry.id}
          />
        );
      }
      case SourceSystem.AUTODESK: {
        return (
          <FileExplorerProjectRoot
            userSource={userSource}
            sourceProjectID={entry.id}
            hubID={hubID}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const onTableCell = (entry: FileTableCell) => {
    onEntry(entry, getNextElement(entry));
    selectedAssetsVar({
      selectedMap,
      hubID,
      sourceID: userSource.id,
      sourceProjectID: entry.id || '',
    });
  };

  return (
    <FilesTable
      entries={fileTableEntries}
      loading={loading}
      onEntry={onTableCell}
      onToggleEntry={onToggleEntry}
      selectedIds={selectedIds}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
    />
  );
};

export default FileExplorerHubProjects;
