import { useReactiveVar } from '@apollo/client';

import {
  inAppNotificationsPaginationVar,
  inAppNotificationsProjectIdVar,
  inAppNotificationsShowUnreadOnlyVar,
} from '../../../api/apollo/reactiveVars';
import {
  LoadInAppNotificationsQuery,
  LoadInAppNotificationsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../utilities/costMode';

import { loadInAppNotificationsQuery } from './queries';

const useLoadInAppNotificationsQuery = () =>
  useQuery<LoadInAppNotificationsQuery, LoadInAppNotificationsQueryVariables>(
    loadInAppNotificationsQuery,
    {
      variables: {
        projectID: inAppNotificationsProjectIdVar(),
        showUnreadOnly: inAppNotificationsShowUnreadOnlyVar(),
        pagination: inAppNotificationsPaginationVar(),
        costMode: useReactiveVar(costModeVar),
      },
    }
  );

export default useLoadInAppNotificationsQuery;
