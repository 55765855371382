import { useMemo, useState } from 'react';

import { usePreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { useCurrentUserQuery } from '../../components/Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import useMemoWrapper from '../../components/useMemoWrapper';
import { ADMINISTRATOR_ROLE, EDIT_TEMPLATE_ROLE, VIEW_ONLY_ROLE } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import useCurrentCollaborator from '../../hooks/useCurrentCollaboratorQuery';
import useCurrentCompanyRole from '../../hooks/useCurrentCompanyRoleQuery';
import { useProjectID } from '../routes/params';

import usePreviewProjectCollaboratorQuery from './hooks/usePreviewProjectCollaboratorQuery';
import usePreviewProjectRoleQuery from './hooks/usePreviewProjectRoleQuery';
import { PartialCategory, combineCurrentAndPreviewPermissions, getPermissions } from './utils';

/** usePermissions is a hook for interfacing with our permissions. It returns
 * four different functions that indicate whether you are able to view, edit,
 * add, or delete for a specific permission resource, and provides a couple key
 * pieces of information that are used in combination with the returned
 * functions to determine exactly how certain permissions are applied.
 *
 * @param projectID for providing a specific project you want permissions for.
 * You only need to use this arg if you want project-specific permissions
 * outside of a project or from within a different project.
 * @param trades for restricting item permissions based on trades. The functions
 * returned by this hook have a way to configure trades later, but that should
 * only be used when you need to get item permissions for multiple items. This
 * should be the primary way to access item-specific permissions.
 * */
export default function usePermissions(args?: { projectID?: UUID; trades?: PartialCategory[] }) {
  const currentProjectID = useProjectID();
  const projectID = args?.projectID ?? currentProjectID;

  const user = useCurrentUserQuery().data?.currentUser;

  const {
    isAdmin,
    isEditTemplate,
    isInitialLoadComplete,
    isViewOnly,
    loading,
    permissions: currentPermissions,
  } = useCurrentPermissions(projectID, args);
  const {
    inPreviewMode,
    loading: loadingPreviewPermissions,
    permissions: previewPermissions,
  } = usePreviewPermissions(projectID, args);

  const combinedPermissions = useMemoWrapper(
    combineCurrentAndPreviewPermissions,
    currentPermissions,
    previewPermissions
  );

  if (!inPreviewMode) {
    return {
      ...currentPermissions,
      inPreviewMode: false,
      isAdmin,
      isEditTemplate,
      isInitialLoadComplete,
      isViewOnly,
      loading,
      userEmail: user?.email,
      userID: user?.id,
    };
  }

  return {
    ...combinedPermissions,
    inPreviewMode,
    isAdmin: false,
    isEditTemplate: false,
    isInitialLoadComplete,
    isViewOnly: false,
    loading: loading || loadingPreviewPermissions,
    userEmail: user?.email,
    userID: user?.id,
  };
}

/** useCurrentPermissions builds up the permissions for the current user. It
 * relies on the CurrentCollaborator query for project-specific permissions and
 * the CurrentCompanyRole query for company-specific permissions.
 * */
const useCurrentPermissions = (
  projectID: UUID | undefined,
  args?: { trades?: PartialCategory[] }
) => {
  const { companyRole, loading: loadingCompanyRole } = useCurrentCompanyRole();
  const { collaborator, loading: loadingCollaborator } = useCurrentCollaborator(projectID);

  const isCompanyRoleInitialLoadComplete = Boolean(companyRole || !loadingCompanyRole);
  const isCollaboratorInitialLoadComplete = Boolean(collaborator);
  const isInitialLoadComplete = projectID
    ? isCollaboratorInitialLoadComplete && isCompanyRoleInitialLoadComplete
    : isCompanyRoleInitialLoadComplete;
  const loading = projectID ? loadingCompanyRole || loadingCollaborator : loadingCompanyRole;

  // permissions are memoized to defensively prevent unnecessary re-renders downstream
  const memoizedPermissions = useMemo(() => {
    // Company Permissions Only
    const companyPermissionGroups = companyRole?.permissionGroups ?? [];
    if (!projectID) {
      return getPermissions(
        {
          permissionGroups: companyPermissionGroups,
        },
        args?.trades
      );
    }
    // Project and Company Permissions
    const projectPermissionGroups = collaborator?.role.permissionGroups ?? [];
    const allTrades = collaborator?.allTrades;
    const hasTrade = collaborator?.role.hasTrade;
    const trades = collaborator?.trades;
    return getPermissions(
      {
        permissionGroups: [...projectPermissionGroups, ...companyPermissionGroups],
        allTrades,
        hasTrade,
        trades,
      },
      args?.trades
    );
  }, [companyRole, projectID, collaborator, args?.trades]);

  return {
    isInitialLoadComplete,
    loading,
    permissions: memoizedPermissions,
    // TODO DD-843: Create role type to avoid need for special names
    isViewOnly: !!projectID && collaborator?.role.name === VIEW_ONLY_ROLE,
    isAdmin: !!projectID && collaborator?.role.name === ADMINISTRATOR_ROLE,
    isEditTemplate: !!projectID && collaborator?.role.name === EDIT_TEMPLATE_ROLE,
  };
};

/** usePreviewPermissions builds up the permissions for the previewed role or
 * user. It relies on the PreviewCollaborator and PreivewProjectRole queries for
 * project-specific permissions. It will not do anything with company-specific
 * permissions as only project-specific permissions are part of collaborators
 * and project roles.
 * */
const usePreviewPermissions = (
  projectID: UUID | undefined,
  args?: { trades?: PartialCategory[] }
) => {
  const { previewUserId, previewRoleId, previewTrades, previewAllTrades } = usePreviewSettingsVar();
  const inPreviewMode = Boolean(previewUserId || previewRoleId);
  // PreviewSettingsVar can have both a previewUserID and previewRoleID
  // In this case we prioritize previewUserID > previewRoleID
  const shouldPreviewCollaborator = Boolean(previewUserId);
  const shouldPreviewRole = Boolean(previewRoleId && !shouldPreviewCollaborator);

  const [previewRole, setPreviewRole] = useState<Parameters<typeof getPermissions>[0]>();
  const [previewCollaborator, setPreviewCollaborator] =
    useState<Parameters<typeof getPermissions>[0]>();

  const { loading: loadingCollaborator } = usePreviewProjectCollaboratorQuery(
    projectID,
    previewUserId,
    {
      onCompleted: (data) => {
        const collaborator = data.previewProjectCollaborator;
        const permissionGroups = collaborator?.role.permissionGroups;
        const allTrades = collaborator?.allTrades;
        const hasTrade = collaborator?.role.hasTrade;
        const trades = collaborator?.trades;

        setPreviewCollaborator({ permissionGroups, allTrades, hasTrade, trades });
      },
    }
  );
  const { loading: loadingRole } = usePreviewProjectRoleQuery(projectID, previewRoleId, {
    onCompleted: (data) => {
      const role = data.previewProjectRole;
      const permissionGroups = role?.permissionGroups;
      const allTrades = !role?.hasTrade || previewAllTrades;
      const hasTrade = role?.hasTrade;
      const trades = previewTrades?.map(
        (t) =>
          ({
            id: t.id,
            categorization: {
              id: 'categorizationID' in t ? t.categorizationID : t.categorization?.id,
            },
          } as Category)
      );
      setPreviewRole({ permissionGroups, allTrades, hasTrade, trades });
    },
  });

  // permissions are memoized to defensively prevent unnecessary re-renders downstream
  const memoizedEmptyPermissions = useMemo(() => getPermissions({}), []);
  const memoizedPreviewRolePermissions = useMemo(
    () => getPermissions(previewRole ?? {}, args?.trades),
    [args?.trades, previewRole]
  );
  const memoizedPreviewCollaboratorPermissions = useMemo(
    () => getPermissions(previewCollaborator ?? {}, args?.trades),
    [args?.trades, previewCollaborator]
  );

  // Preview User
  if (shouldPreviewCollaborator) {
    return {
      inPreviewMode,
      loading: loadingCollaborator,
      permissions: memoizedPreviewCollaboratorPermissions,
    };
  }
  // Preview Role
  if (shouldPreviewRole) {
    return {
      inPreviewMode,
      loading: loadingRole,
      permissions: memoizedPreviewRolePermissions,
    };
  }
  return { inPreviewMode, loading: false, permissions: memoizedEmptyPermissions };
};

/** getItemLinesPermissionResource returns the permission resource needed to
 * determine if you have access to an item's lines or not, based on whether that
 * item is in trade or not. For determining in trade versus out of trade
 * permissions, we mostly represent these permsisions using a single permission
 * resource in two different permission groups: ITEM_OUT_OF_TRADE and
 * ITEM_IN_TRADE. However, the one exception to this is ITEM_OUT_OF_TRADE_LINES
 * and ITEM_IN_TRADE_LINES, which are two separate permission resources within a
 * same permission group: COST.
 *
 * TODO: CT-742
 * */
export const getItemLinesPermissionResource = (inTrade?: boolean): PermissionResource =>
  inTrade ? PermissionResource.ITEM_IN_TRADE_LINES : PermissionResource.ITEM_OUT_OF_TRADE_LINES;
