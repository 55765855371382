import { FC } from 'react';

import ProcoreSVGLoggedOut from './ProcoreSVGLoggedOut';

type IntegrationsHeaderProps = {
  isLoggedIntoProcore: boolean;
  name: string;
};

const IntegrationsHeader: FC<IntegrationsHeaderProps> = ({ isLoggedIntoProcore, name }) => {
  return (
    <div className="flex flex-grow  justify-between">
      <div
        className="pt-0.5 text-base font-medium leading-none text-type-primary"
        data-cy="item-details-integrations-panel"
      >
        {name}
      </div>
      <div className={`${isLoggedIntoProcore ? '' : 'grayscale'}`}>
        <ProcoreSVGLoggedOut />
      </div>
    </div>
  );
};

export default IntegrationsHeader;
