import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes, JoinRoutes } from '../../../api/gqlEnums';
import { ProjectType } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { computeProjectTypes } from '../../Dialogs/DialogsNewProject/DialogsNewProjectUtils';
import { Select, SortManager, Table } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import { useUpdateCompanyProjectTypeMutation } from '../CompanyHooks';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  gridRowHeight,
  projectStatsGridWidth,
  projectStatsHeaderContent,
} from '../CompanyTabUtils';

type CompanyTabProjectStatsTableProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyID: UUID;
  companyProjectStats: CompanyProjectStats[];
  sortManager: SortManager;
  searchTerm: string;
  loading: boolean;
};

const CompanyTabProjectStatsTable: FC<CompanyTabProjectStatsTableProps> = ({
  classes,
  companyID,
  companyProjectStats,
  sortManager,
  searchTerm,
  loading,
}) => {
  const sendAnalytics = useAnalyticsEventHook();

  // Project Types
  const { data: typesData } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes = typesData?.companyProjectTypes || [];
  const setProjectType = useUpdateCompanyProjectTypeMutation(companyID);

  const entries = useMemo(
    () =>
      companyProjectStats.map((companyProject) => {
        if (companyProject) {
          const formatCostSettings = {
            showCents: false,
            settingsOverride: {
              // TODO: add the project's currency to the query
              // CURRENCY: companyProject.currency,
              CURRENCY: 'USD', // temporary - always USD until above is updated
              ROUNDING_PRECISION: 3,
            },
          };
          const companyPath = generateSharedPath(JoinRoutes.PROJECT, {
            projectId: companyProject.id,
          });

          const userPath = (companyID: UUID) => {
            if (companyID === companyProject.createdBy?.company?.id) {
              return generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE, {
                userId: companyProject.createdBy?.user?.id,
              });
            }
            return generateSharedPath(JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE, {
              userId: companyProject.createdBy?.user?.id,
            });
          };

          const projectName = (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              to={companyPath}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_PROJECT_LINK, {
                    view: CompanyAdminView.PROJECT_STATS_LIST,
                  })
                );
              }}
            >
              <SearchText searchTerm={searchTerm} text={companyProject.name} />
            </Link>
          );
          const types: ProjectType[] = computeProjectTypes(
            projectTypes,
            companyProject.projectType
          );

          const type = companyProject.type ? (
            <div className={classes.text}>
              <Select
                aria-label="project type"
                label=""
                isSearchable
                onChange={(id) => {
                  if (!id) return;
                  setProjectType(companyProject.id, id);
                }}
                entries={types
                  .filter((t) => !t.hidden)
                  .map((t) => ({
                    id: t.id,
                    label: t.name,
                    parentID: t.parentID,
                  }))}
                value={companyProject.projectType.id}
              />
            </div>
          ) : undefined;
          const location = companyProject.location ? (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={companyProject.location} />
            </div>
          ) : undefined;

          const createdAt = companyProject.createdAt ? (
            <div className={classes.text}>
              {new Date(companyProject.createdAt).toLocaleDateString()}
            </div>
          ) : undefined;
          const estimate = (
            <div className="type-number">
              {formatCost(companyProject.estimate, formatCostSettings)}
            </div>
          );
          const runningTotal = (
            <div className="type-number">
              {formatCost(companyProject.runningTotal, formatCostSettings)}
            </div>
          );
          const budget = (
            <div className="type-number">
              {formatCost(companyProject.budget, formatCostSettings)}
            </div>
          );
          const createdBy = companyProject.createdBy?.user ? (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              to={userPath(companyID)}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_CREATOR_LINK, {
                    view: CompanyAdminView.PROJECT_STATS_LIST,
                  })
                );
              }}
            >
              <SearchText searchTerm={searchTerm} text={companyProject.createdBy?.user.name} />
            </Link>
          ) : undefined;

          return [
            { component: projectName, key: companyProject.name },
            { component: type, key: companyProject.projectType?.name ?? companyProject.type },
            { component: location, key: companyProject.location },
            { component: createdAt, key: companyProject.createdAt },
            { text: companyProject.status ?? undefined, key: companyProject.status },
            { component: estimate, key: companyProject.estimate },
            { component: runningTotal, key: companyProject.runningTotal },
            { component: budget, key: companyProject.budget },
            { component: createdBy, key: companyProject.createdBy?.user?.name },
            {
              text: companyProject.milestoneCount,
              key: companyProject.milestoneCount,
              isNumber: true,
            },
            {
              text: companyProject.itemCount || '0',
              key: companyProject.itemCount,
              isNumber: true,
            },
            { text: companyProject.teamCount, key: companyProject.teamCount, isNumber: true },
          ];
        }
        return [];
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [companyProjectStats, classes, sendAnalytics, companyID, searchTerm]
  );

  return (
    <Table
      columnWidths={projectStatsGridWidth}
      entries={entries}
      headerContent={projectStatsHeaderContent}
      loading={loading}
      rowHeight={gridRowHeight}
      sortManager={sortManager}
    />
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabProjectStatsTable);
