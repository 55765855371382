import { MutationHookOptions, useMutation } from '@apollo/client';

import { ScheduleImpactType } from '../../api/gqlEnumsBe';
import {
  REFETCH_CHANGE_ITEM,
  REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  REFETCH_ITEM_HISTORY,
  refetchItem,
  refetchItemEstimate,
} from '../../api/refetchSets';
import {
  RemoveItemCategoryMutation,
  RemoveItemCategoryMutationVariables,
  RemoveItemMilestoneHistoryMutation,
  RemoveItemMilestoneHistoryMutationVariables,
  SetItemActivityMutation,
  SetItemActivityMutationVariables,
  SetItemAssigneeMutation,
  SetItemAssigneeMutationVariables,
  SetItemCategoryMutation,
  SetItemCategoryMutationVariables,
  SetItemDescriptionMutation,
  SetItemDescriptionMutationVariables,
  SetItemDueDateMutation,
  SetItemDueDateMutationVariables,
  SetItemMilestoneMutation,
  SetItemMilestoneMutationVariables,
  SetItemNameMutation,
  SetItemNameMutationVariables,
  SetItemNumberMutation,
  SetItemNumberMutationVariables,
  SetItemPublishedMutation,
  SetItemPublishedMutationVariables,
  UnsetItemActivityMutation,
  UnsetItemActivityMutationVariables,
  UpdateScheduleImpactMutation,
  UpdateScheduleImpactMutationVariables,
} from '../../generated/graphql';
import { costModeVar } from '../../utilities/costMode';
import { replaceQueries } from '../../utilities/utilities';
import { getAssigneeEmail } from '../Dialogs/DialogsNewItem/DialogsNewItemUtils';
import {
  removeItemCategoryMutation,
  removeItemMilestoneHistoryMutation,
  setItemActivityMutation,
  setItemAssigneeMutation,
  setItemCategoryMutation,
  setItemDescriptionMutation,
  setItemDueDateMutation,
  setItemMilestoneMutation,
  setItemNameMutation,
  setItemNumberMutation,
  setItemPublishedMutation,
  unsetItemActivityMutation,
  updateScheduleImpactMutation,
} from '../Items/hooks/itemMutation';

export function useItemAssigneeUpdate(
  options: MutationHookOptions<SetItemAssigneeMutation, SetItemAssigneeMutationVariables> = {}
) {
  const [setItemAssignee] = useMutation<SetItemAssigneeMutation, SetItemAssigneeMutationVariables>(
    setItemAssigneeMutation,
    options
  );
  return (projectID: UUID, id: UUID, email?: string) => {
    const assigneeEmail = getAssigneeEmail(email);
    return setItemAssignee({
      variables: { projectID, id, assigneeEmail, costMode: costModeVar() },
      refetchQueries: () => [...REFETCH_CHANGE_MILESTONE_EVENTS_LIST, refetchItem(id)],
    });
  };
}

export function useItemActivityAdd(
  options: MutationHookOptions<SetItemActivityMutation, SetItemActivityMutationVariables> = {}
) {
  const [setItemActivity] = useMutation<SetItemActivityMutation, SetItemActivityMutationVariables>(
    setItemActivityMutation,
    options
  );

  return (projectID: UUID, id: UUID, activityID: UUID, item: ItemLike) =>
    setItemActivity({
      variables: { projectID, id, activityID, costMode: costModeVar() },
      refetchQueries: () => [...REFETCH_CHANGE_MILESTONE_EVENTS_LIST, refetchItem(id)],
      optimisticResponse: {
        setItemActivity: {
          ...item,
          activityIDs: [...(item?.activityIDs ?? []), activityID],
        },
      },
    });
}

export function useItemActivityRemove(
  options: MutationHookOptions<UnsetItemActivityMutation, UnsetItemActivityMutationVariables> = {}
) {
  const [unsetItemActivity] = useMutation<
    UnsetItemActivityMutation,
    UnsetItemActivityMutationVariables
  >(unsetItemActivityMutation, options);

  return (projectID: UUID, id: UUID, activityID: UUID, item: ItemLike) =>
    unsetItemActivity({
      variables: { projectID, id, activityID, costMode: costModeVar() },
      refetchQueries: () => [...REFETCH_CHANGE_MILESTONE_EVENTS_LIST, refetchItem(id)],
      optimisticResponse: {
        unsetItemActivity: {
          ...item,
          activityIDs: [...(item?.activityIDs ?? []).filter((id) => id !== activityID)],
        },
      },
    });
}

export function useItemNameUpdate(
  options: MutationHookOptions<SetItemNameMutation, SetItemNameMutationVariables> = {}
) {
  const [setItemName] = useMutation<SetItemNameMutation, SetItemNameMutationVariables>(
    setItemNameMutation,
    options
  );

  return (projectID: UUID, itemId: UUID, itemName: string) =>
    setItemName({
      variables: { projectID, id: itemId, name: itemName, costMode: costModeVar() },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemId)]),
    });
}

export function useItemDescriptionUpdate(
  options: MutationHookOptions<SetItemDescriptionMutation, SetItemDescriptionMutationVariables> = {}
) {
  const [setItemDescription] = useMutation<
    SetItemDescriptionMutation,
    SetItemDescriptionMutationVariables
  >(setItemDescriptionMutation, options);

  return (projectID: UUID, itemId: UUID, itemDescription: string, itemDescriptionStyled: string) =>
    setItemDescription({
      variables: {
        projectID,
        id: itemId,
        description: itemDescription,
        descriptionStyled: itemDescriptionStyled,
        costMode: costModeVar(),
      },
      refetchQueries: () => [...REFETCH_ITEM_HISTORY, refetchItem(itemId)],
    });
}

export function useItemNumberUpdate(
  options: MutationHookOptions<SetItemNumberMutation, SetItemNumberMutationVariables> = {}
) {
  const [setItemNumber] = useMutation<SetItemNumberMutation, SetItemNumberMutationVariables>(
    setItemNumberMutation,
    options
  );

  return (projectID: UUID, itemId: UUID, itemNumber: string) =>
    setItemNumber({
      variables: { projectID, id: itemId, number: itemNumber, costMode: costModeVar() },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemId)]),
    });
}

export function useSetItemPublished(
  options: MutationHookOptions<SetItemPublishedMutation, SetItemPublishedMutationVariables> = {}
) {
  const [setItemPublished] = useMutation<
    SetItemPublishedMutation,
    SetItemPublishedMutationVariables
  >(setItemPublishedMutation, options);

  return (projectID: UUID, itemId: UUID, createdBy?: UUID, activeMilestoneID?: UUID) =>
    setItemPublished({
      variables: {
        projectID,
        id: itemId,
        createdBy,
        costMode: costModeVar(),
        activeMilestoneID,
      },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemId)]),
    });
}

export function useRemoveItemCategoryUpdate(
  options: MutationHookOptions<RemoveItemCategoryMutation, RemoveItemCategoryMutationVariables> = {}
) {
  const [removeItemCategory] = useMutation<
    RemoveItemCategoryMutation,
    RemoveItemCategoryMutationVariables
  >(removeItemCategoryMutation, options);

  return (projectID: UUID, itemID: UUID, categoryID = '') =>
    removeItemCategory({
      variables: { projectID, id: itemID, category: categoryID, costMode: costModeVar() },
      refetchQueries: () => replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemID)]),
    });
}

export function useSetItemCategoryUpdate(
  options: MutationHookOptions<SetItemCategoryMutation, SetItemCategoryMutationVariables> = {}
) {
  const [setItemCategory] = useMutation<SetItemCategoryMutation, SetItemCategoryMutationVariables>(
    setItemCategoryMutation,
    options
  );

  return (projectID: UUID, itemID: UUID, categoryID: string) =>
    setItemCategory({
      variables: { projectID, id: itemID, category: categoryID, costMode: costModeVar() },
      refetchQueries: () => replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemID)]),
    });
}

export function useItemDueDateUpdate(
  options: MutationHookOptions<SetItemDueDateMutation, SetItemDueDateMutationVariables> = {}
) {
  const [setItemDueDate] = useMutation<SetItemDueDateMutation, SetItemDueDateMutationVariables>(
    setItemDueDateMutation,
    options
  );

  return (projectID: UUID, id: UUID, dueDate: string) =>
    setItemDueDate({
      variables: { projectID, id, dueDate, costMode: costModeVar() },
      refetchQueries: () => [...REFETCH_CHANGE_MILESTONE_EVENTS_LIST, refetchItem(id)],
    });
}

export function useRemoveItemMilestoneHistory(
  options: MutationHookOptions<
    RemoveItemMilestoneHistoryMutation,
    RemoveItemMilestoneHistoryMutationVariables
  > = {}
) {
  const [removeItemMilestoneHistory] = useMutation<
    RemoveItemMilestoneHistoryMutation,
    RemoveItemMilestoneHistoryMutationVariables
  >(removeItemMilestoneHistoryMutation, options);

  return (item: ItemLike, milestone: UUID) =>
    removeItemMilestoneHistory({
      variables: { projectID: item.project.id, id: item.id, milestone },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(item.id)]),
      optimisticResponse: {
        // @ts-ignore TODO CT-545
        __typename: 'mutation_root',
        setStatus: {
          __typename: 'removeItemMilestoneHistory_mutation_response',
          affected_rows: 1,
          returning: {
            ...item,
            previousMilestoneStatus: item.previousMilestoneStatus.filter(
              (i) => i.milestoneID !== milestone
            ),
          },
        },
      },
    });
}

export function useItemMilestoneUpdate(
  options: MutationHookOptions<SetItemMilestoneMutation, SetItemMilestoneMutationVariables> = {}
) {
  const [setItemMilestone] = useMutation<
    SetItemMilestoneMutation,
    SetItemMilestoneMutationVariables
  >(setItemMilestoneMutation, options);
  return (projectID: UUID, item: ItemLike, milestone: string, status: Status) => {
    let refetchQueries = item.activeEstimate
      ? [...REFETCH_CHANGE_ITEM, ...refetchItemEstimate(item.activeEstimate.id)]
      : REFETCH_CHANGE_ITEM;
    refetchQueries = replaceQueries(refetchQueries, [refetchItem(item.id)]);
    return setItemMilestone({
      variables: { projectID, id: item.id, milestone, status, costMode: costModeVar() },
      refetchQueries,
      optimisticResponse: {
        __typename: 'Mutation',
        setItemMilestone: {
          // @ts-ignore TODO CT-545
          __typename: 'setItemMilestone_mutation_response',
          affected_rows: 1,
          returning: {
            ...item,
            milestone: {
              ...item.milestone,
              id: milestone,
            },
            status,
            filteredMilestone: milestone,
          },
        },
      },
    });
  };
}

export function useUpdateScheduleImpact(
  projectID: UUID,
  itemID: UUID,
  options: MutationHookOptions<
    UpdateScheduleImpactMutation,
    UpdateScheduleImpactMutationVariables
  > = {}
) {
  const [mutationFunc] = useMutation<
    UpdateScheduleImpactMutation,
    UpdateScheduleImpactMutationVariables
  >(updateScheduleImpactMutation, options);
  return (impact: { type: ScheduleImpactType; criticalPath: boolean; days: number }) =>
    mutationFunc({
      variables: { projectID, itemID, ...impact },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemID)]),
    });
}
