import { FC } from 'react';

import { cloneDeep } from '@apollo/client/utilities';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  analyticsEvent,
  setProjectStatsFilterAnalytics,
} from '../../../analytics/analyticsEventProperties';
import {
  ACTIVE_STATUS,
  IN_PURSUIT_STATUS,
  LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT,
  LOCALSTORAGE_COMPANY_SEARCH_USER,
} from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { isNonNullable } from '../../../utilities/types';
import { usePersistentStates } from '../../../utilities/urlState';
import useDeprecatedProjectsFilterOptionsQuery from '../../ProjectsList/hooks/useDeprecatedProjectsFilterOptionsQuery';
import { ProjectFilter, useFilterProjects } from '../../ProjectsList/ProjectsListUtils';
import { useCompanyProjectStatsQuery } from '../CompanyHooks';
import { useCompanyRoutesData, useCompanyUserSearchManager, useSort } from '../CompanyTabUtils';

import CompanyTabProjectStats from './CompanyTabProjectStats';

const CompanyTabProjectStatsData: FC = () => {
  const { companyID } = useCompanyRoutesData();
  const sortManagerProjectStats = useSort(
    LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT,
    CompanyAdminView.PROJECT_STATS_LIST
  );

  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    {
      companies: [],
      locations: [],
      statuses: [ACTIVE_STATUS, IN_PURSUIT_STATUS],
      types: [],
    },
    'Company Project Stats -'
  );

  const sendAnalytics = useAnalyticsEventHook();

  const filterManager = useFilterProjects(
    settings,
    (input: ProjectFilter) => {
      sendAnalytics(setProjectStatsFilterAnalytics(input.type));
    },
    () => {
      sendAnalytics(analyticsEvent(CompanyAdminEventType.PROJECT_STATS_CLEAR_FILTER));
    },
    () => {
      sendAnalytics(analyticsEvent(CompanyAdminEventType.PROJECT_STATS_REMOVE_FILTER));
    }
  );

  const {
    data: { projectsFilterOptions } = {
      projectsFilterOptions: {
        locations: [] as string[],
        statuses: [] as string[],
        companies: [] as string[],
        types: [] as string[],
        projectTypes: [],
      },
    },
  } = useDeprecatedProjectsFilterOptionsQuery(true, false, companyID);
  filterManager.filterOptions = cloneDeep(projectsFilterOptions);
  filterManager.filterOptions.types = projectsFilterOptions.projectTypes || [];

  const {
    data: { companyProjectStats } = { companyProjectStats: [] },
    loading: companyProjectsLoading,
  } = useCompanyProjectStatsQuery(
    companyID,
    filterManager.filterState as ProjectsFilterBy,
    sortManagerProjectStats.sortState as ProjectsSortBy
  );

  const { data: countData, loading: countLoading } = useCompanyProjectStatsQuery(
    companyID,
    { locations: [], statuses: [], companies: [], types: [] },
    { sortKey: 'SORT_PROJECT_NAME', sortDirection: 'SORT_DESCENDING' }
  );

  const projectCount = countData?.companyProjectStats?.length;

  const searchManager = useCompanyUserSearchManager(LOCALSTORAGE_COMPANY_SEARCH_USER);

  if (!companyID) return null;
  return (
    <CompanyTabProjectStats
      companyID={companyID}
      searchManager={searchManager}
      companyProjectStats={companyProjectStats.filter(isNonNullable)}
      loading={companyProjectsLoading}
      sortManager={sortManagerProjectStats}
      filterManager={filterManager}
      setSettings={setSettings}
      projectCount={projectCount}
      projectCountLoading={countLoading}
    />
  );
};

export default CompanyTabProjectStatsData;
