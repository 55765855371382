import { ComponentProps, ReactNode, useRef } from 'react';
import { FocusScope, OverlayContainer, useDialog, useModalOverlay } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { Close } from '@material-ui/icons';

import NavigationTabs from '../../Navigation/NavigationTabs';
import { ActiveOverlayStateContext } from '../hooks/useActiveOverlayState';
import IconButton from '../IconButton/IconButton';

type Props = {
  /** Hex color code to use when rendering an accent border */
  accentColor?: string;
  children: ReactNode;
  'data-cy'?: string;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerCenter?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerLeft?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerRight?: ReactNode;
  image?: ReactNode;
  isFullHeight?: boolean;
  isNotDismissable?: boolean;
  isOpen?: boolean;
  navigationTabs?: ComponentProps<typeof NavigationTabs>;
  onClose?: () => void;
  /** A decimal number between 0 and 1 */
  progressPercent?: number;
  size?: 'md' | 'lg';
  title: ReactNode;
};

export default function Dialog(props: Props) {
  const state = useOverlayTriggerState({
    isOpen: props.isOpen,
    onOpenChange: (isOpen) => !isOpen && props.onClose?.(),
  });

  const ref = useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog({}, ref);

  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable: !props.isNotDismissable,
      isKeyboardDismissDisabled: props.isNotDismissable,
      // @ts-expect-error https://github.com/adobe/react-spectrum/pull/5116
      shouldCloseOnInteractOutside: (e: Element) => {
        // Check if the triggering element was in the root tree or not.
        const elementIsInRootTree = e.closest('#root');
        if (elementIsInRootTree) return true;

        // If it isn't, that means it's rendered in a Portal somewhere.
        // If the element contains this dialog, the element is the underlay so
        // close the dialog.
        if (e.contains(ref.current)) return true;

        return false;
      },
    },
    state,
    ref
  );

  if (!state.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      {/**
       * TODO: We've intentionally not turned on `contain` for the FocusScope because of
       * incompatibilities with MUI. If `contain` is on, then things like a MUI Select
       * or Combobox may not gain focus when necessary. Eg, a MUI Select with a search input
       * will not be focusable.
       *
       * When we're no longer rendering those kinds of elements, we can enable `contain` to
       * properly handle a11y and tab navigation.
       * */}
      <FocusScope autoFocus restoreFocus>
        <ActiveOverlayStateContext.Provider value={state}>
          <div
            className="fixed bottom-0 left-0 right-0 top-0 z-[200] flex items-center justify-center bg-background-backdrop"
            data-cy="dialog-underlay"
            {...underlayProps}
          >
            <div
              className={[
                'flex flex-col bg-background-primary text-type-primary shadow outline-none',
                props.accentColor ? 'border-t-8' : '',
                props.isFullHeight ? 'h-[90vh]' : 'max-h-[90vh]',
                props.size === 'lg' ? 'w-[800px] lg:w-[1000px]' : 'w-[600px]',
              ].join(' ')}
              data-cy={props['data-cy']}
              {...modalProps}
              {...dialogProps}
              ref={ref}
              style={{
                borderColor: props.accentColor,
              }}
            >
              <Header
                image={props.image}
                isNotDismissable={props.isNotDismissable}
                onClose={state.close}
                title={props.title}
              />
              {props.navigationTabs && <Tabs navigationTabs={props.navigationTabs} />}
              {props.progressPercent ? (
                <ProgressBar percent={props.progressPercent} />
              ) : (
                <Separator />
              )}
              <div className="flex-grow overflow-auto">{props.children}</div>
              <Separator />
              <Footer
                center={props.footerCenter}
                left={props.footerLeft}
                right={props.footerRight}
              />
            </div>
          </div>
        </ActiveOverlayStateContext.Provider>
      </FocusScope>
    </OverlayContainer>
  );
}

export function DialogContent(props: { children: ReactNode; className?: string }) {
  return (
    <div className={`p-6 text-type-primary type-body1 ${props.className}`} data-cy="dialog-content">
      {props.children}
    </div>
  );
}

function Header(props: {
  image?: Props['image'];
  title?: Props['title'];
  isNotDismissable?: Props['isNotDismissable'];
  onClose: () => void;
}) {
  return (
    <header className="flex h-20 items-center gap-2 px-6 py-5" data-cy="dialog-header">
      {props.image && <div className="object-scale-down [&>*]:h-10 [&>*]:w-10">{props.image}</div>}
      {props.title && <h3 className="type-heading2">{props.title}</h3>}
      {props.isNotDismissable !== true && (
        <div className="ml-auto">
          <IconButton
            aria-label="close dialog"
            data-cy="dialog-close-btn"
            icon={<Close />}
            onClick={props.onClose}
            type="secondary"
          />
        </div>
      )}
    </header>
  );
}

function Tabs(props: { navigationTabs: ComponentProps<typeof NavigationTabs> }) {
  return (
    <div className="flex-shrink-0">
      <NavigationTabs
        options={props.navigationTabs.options}
        view={props.navigationTabs.view}
        setView={props.navigationTabs.setView}
      />
    </div>
  );
}

function ProgressBar(props: { percent: number }) {
  return (
    <div className="h-2 w-full flex-shrink-0 bg-border-default">
      <div className="h-full bg-type-primary" style={{ width: `${props.percent * 100}%` }} />
    </div>
  );
}

function Separator() {
  return <div className="h-px w-full flex-shrink-0 bg-border-default" />;
}

function Footer(props: { center?: ReactNode; left?: ReactNode; right?: ReactNode }) {
  const footerSectionClassName = 'h-10 flex items-center';

  return (
    <footer className="flex items-center justify-between p-6" data-cy="dialog-footer">
      <div className={footerSectionClassName} data-cy="dialog-footer-left">
        {props.left}
      </div>
      <div className={footerSectionClassName} data-cy="dialog-footer-center">
        {props.center}
      </div>
      <div className={footerSectionClassName} data-cy="dialog-footer-right">
        {props.right}
      </div>
    </footer>
  );
}
