import { FC, useState } from 'react';

import { Dialog } from '@material-ui/core';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../api/gqlEnums';
import {
  CategorizationImportMetadataInput,
  CreateCategorizationMutation,
} from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import DialogsRemoveCategorization from '../../Dialogs/DialogsRemoveCategorization/DialogsRemoveCategorization';
// eslint-disable-next-line import/no-cycle
import AddCategoriesDialog from '../../table/categorization/AddCategoriesDialog';
import CreateCategorizationDialog from '../../table/categorization/CreateCategorizationDialog';
import MultilevelCategorizationsDialog from '../../table/categorization/MultilevelCategorizationsDialog';
import { CategoryMapInput, CreateCategorizationInput } from '../../table/categorization/types';

type CategorizationsListDialogsProps = {
  categorization?: CategorizationMetadata;
  onClose?: () => void;
  onCreateSuccess?: (c: CreateCategorizationMutation['createCategorization']) => void;
  companyID?: UUID;
  projectId?: UUID;
  type: CategorizationDialogType;
  setType: (type: CategorizationDialogType) => void;
};

const CategorizationsListDialogs: FC<CategorizationsListDialogsProps> = ({
  categorization,
  onClose,
  onCreateSuccess,
  projectId,
  type,
  setType,
  companyID,
}) => {
  const sendAnalytics = useSendAnalyticsEventHook();

  const [categorizationInput, setCategorizationInput] = useState<CreateCategorizationInput>({
    name: categorization?.name,
    description: categorization?.description,
    categoryMaps: undefined,
    companyID: undefined,
    projectID: undefined,
    isReplacing: false,
  });

  const handleClose = () => {
    setType(CategorizationDialogType.NONE);
    setCategorizationInput({
      name: categorization?.name,
      description: categorization?.description,
      isReplacing: false,
    });
    onClose?.();
  };

  const handleManualEntry = (
    name?: string,
    description?: string,
    categoryMap?: CategoryMapInput[],
    companyID?: UUID,
    projectID?: UUID,
    metadata?: CategorizationImportMetadataInput
  ) => {
    setType(CategorizationDialogType.ADD);
    setCategorizationInput({
      name,
      description,
      categoryMaps: categoryMap,
      companyID,
      projectID,
      metadata,
      isReplacing: categorizationInput.isReplacing,
    });
    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_MANUAL_CTA));
  };

  if (!categorization) {
    if (type === CategorizationDialogType.NEW) {
      return (
        <CreateCategorizationDialog
          title="Create New Categorization"
          buttonText="Create"
          onClose={handleClose}
          onSuccess={(c) => {
            onCreateSuccess?.(c);
          }}
          onManualEntry={handleManualEntry}
          projectId={projectId}
          companyID={companyID}
        />
      );
    }
  }

  switch (type) {
    case CategorizationDialogType.NEW: // For replacing a categorization
      return (
        <CreateCategorizationDialog
          title="Edit Categorization"
          buttonText="Confirm"
          categorization={categorization}
          onBack={() => {
            setType(CategorizationDialogType.EDIT);
          }}
          onBackMultilevel={() => {
            setType(CategorizationDialogType.MULTILEVEL_EDIT);
          }}
          onClose={handleClose}
          onManualEntry={handleManualEntry}
          projectId={projectId}
          companyID={companyID}
        />
      );
    case CategorizationDialogType.REMOVE:
      if (!categorization) {
        return null;
      }
      return <DialogsRemoveCategorization categorization={categorization} onClose={handleClose} />;
    case CategorizationDialogType.ADD: // For editing both single level and multilevel categorizations
      return (
        <AddCategoriesDialog
          title={categorization ? 'Edit Categorization' : 'Add Categories'}
          buttonText={categorization ? 'Confirm' : 'Create'}
          onClose={handleClose}
          onClickBack={() => {
            setType(CategorizationDialogType.NEW);
          }}
          onClickReplace={() => {
            setType(CategorizationDialogType.NEW);
            sendAnalytics(
              categorizationEvent(CategorizationEvent.CREATE_NEW_SL_REPLACE, {
                projectID: projectId,
                companyID,
              })
            );
          }}
          onBackButton={() => {
            setType(CategorizationDialogType.NEW);
          }}
          onBackButtonMultilevel={() => {
            setType(CategorizationDialogType.MULTILEVEL_EDIT);
          }}
          onCreateSuccess={onCreateSuccess}
          projectId={projectId}
          categorization={categorization}
          categorizationInput={categorizationInput}
          setCategorizationInput={setCategorizationInput}
        />
      );
    case CategorizationDialogType.EDIT:
      return (
        <AddCategoriesDialog
          title="Edit Categorization"
          buttonText="Save"
          categorization={categorization}
          onClose={handleClose}
          onClickReplace={() => {
            setType(CategorizationDialogType.NEW);
            setCategorizationInput((input) => ({ ...input, isReplacing: true }));
            sendAnalytics(
              categorizationEvent(CategorizationEvent.EDIT_SL_REPLACE, {
                projectID: projectId,
                companyID,
              })
            );
          }}
          projectId={projectId}
        />
      );
    // this is for view-only
    case CategorizationDialogType.VIEW:
      return (
        <AddCategoriesDialog
          title="View Categories"
          buttonText="Done"
          categorization={categorization}
          onClose={handleClose}
          projectId={projectId}
          viewOnly
        />
      );
    case CategorizationDialogType.MULTILEVEL_VIEW:
      if (!categorization) {
        return null;
      }
      return (
        <Dialog disableBackdropClick onClose={handleClose} open>
          <MultilevelCategorizationsDialog
            categorization={categorization}
            projectId={projectId}
            onClose={handleClose}
            viewOnly
          />
        </Dialog>
      );
    case CategorizationDialogType.MULTILEVEL_EDIT:
      if (!categorization) {
        return null;
      }
      return (
        <Dialog disableBackdropClick onClose={handleClose} open>
          <MultilevelCategorizationsDialog
            categorization={categorization}
            projectId={projectId}
            onClose={handleClose}
            onClickReplace={() => {
              setType(CategorizationDialogType.NEW);
              setCategorizationInput((input) => ({ ...input, isReplacing: true }));
              sendAnalytics(
                categorizationEvent(CategorizationEvent.EDIT_ML_REPLACE, {
                  projectID: projectId,
                  companyID,
                })
              );
            }}
          />
        </Dialog>
      );
    default:
      return null;
  }
};

export default CategorizationsListDialogs;
