import { FC } from 'react';
import { useEffectOnce } from 'react-use';

import { IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT } from '../../../constants';
import useMemoWrapper from '../../useMemoWrapper';
import useLoadInAppNotificationsCountQuery from '../hooks/useLoadInAppNotificationsCountQuery';
import useLoadInAppNotificationsQuery from '../hooks/useLoadInAppNotificationsQuery';
import { groupNotificationsByDate } from '../InAppNotificationUtils/InAppNotificationUtils';

import InAppNotificationsPanel from './InAppNotificationsPanel';

const InAppNotificationsPanelData: FC = () => {
  const {
    data: { loadInAppNotifications: inAppNotifications } = {},
    startPolling,
    stopPolling,
  } = useLoadInAppNotificationsQuery();

  const {
    data: { loadInAppNotificationsCount } = {},
    startPolling: startPollingCount,
    stopPolling: stopPollingCount,
  } = useLoadInAppNotificationsCountQuery();

  useEffectOnce(() => {
    startPolling(IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT);
    startPollingCount(IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT);
    return () => {
      stopPolling();
      stopPollingCount();
    };
  });

  const notifications = inAppNotifications?.inAppNotifications ?? [];
  const paginationData = inAppNotifications?.paginationData;
  const notificationsData = useMemoWrapper(groupNotificationsByDate, notifications);

  return (
    <InAppNotificationsPanel
      loadInAppNotificationsCount={loadInAppNotificationsCount}
      notificationsData={notificationsData}
      paginationData={paginationData}
    />
  );
};

export default InAppNotificationsPanelData;
