import { FC } from 'react';
import { Link } from 'react-router-dom';
import 'react-vis/dist/style.css';

import { Typography } from '@material-ui/core';

import { CURRENT_MILESTONE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { getItemListLink } from '../../ItemsList/ItemsListUtils';

import styles from './MeetingQuickLinkStyles';

type Props = {
  classes: Classes<typeof styles>;
  meeting: Meeting;
  isExpanded: boolean;
};

const MeetingQuickLink: FC<Props> = ({ classes, meeting, isExpanded }) => {
  const { id, name, milestone } = meeting;

  // Link
  const projectId = getProjectIdFromUrl();

  // Variables
  const disabled = !(milestone?.id && id);

  // Styles
  const linkStyle = isExpanded ? classes.meetingLinkExpanded : classes.meetingLink;
  const expandedDisabled = isExpanded ? classes.deletedMeetingExpanded : classes.deletedMeeting;

  return !disabled ? (
    <Link
      to={getItemListLink(projectId, {
        [CURRENT_MILESTONE]: [milestone.id, id],
      })}
    >
      <Typography title="Go to items list" className={linkStyle}>
        {name}
      </Typography>
    </Link>
  ) : (
    <Typography className={expandedDisabled}>{`${name} (deleted)`}</Typography>
  );
};

const MeetingQuickLinkStyled = withStyles(styles)(MeetingQuickLink);

export default MeetingQuickLinkStyled;
