import { FC } from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';

import { EstimateTotalType } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getCurrencySymbol } from '../../../../utilities/currency';

import costTypesIllustration from './CostTypesIllustration.svg';
import styles from './EstimateTotalTypeSelectStyles';
import totalIllustration from './TotalIllustration.svg';
import unitIllustration from './UnitIllustration.svg';

type EstimateTotalTypeRadioGroupProps = {
  classes: Classes<typeof styles>;
  selectedValue: EstimateTotalType;
  onChange: (newValue: string) => void;
};

const EstimateTotalTypeRadioGroup: FC<EstimateTotalTypeRadioGroupProps> = ({
  classes,
  selectedValue,
  onChange,
}) => {
  const formControlLabel = (
    value: EstimateTotalType,
    label: string,
    description: string,
    src: string,
    alt: string
  ) => (
    <FormControlLabel
      classes={{
        root: classes.formLabelRoot,
      }}
      value={value}
      control={<Radio className={classes.smallButton} />}
      label={
        <div>
          <Typography className={`${classes.bold} ${classes.marginTop}`}>{label}</Typography>
          <div className={classes.marginTop}>
            {selectedValue === value && (
              <>
                <Typography>{description}</Typography>
                <img alt={alt} src={src} />
              </>
            )}
          </div>
        </div>
      }
    />
  );

  return (
    <div>
      <RadioGroup
        value={selectedValue || EstimateTotalType.TOTAL_TYPE_COST_TYPES}
        onChange={(_, newValue: string) => {
          onChange(newValue);
        }}
      >
        {formControlLabel(
          EstimateTotalType.TOTAL_TYPE_TOTAL,
          'Just use line totals',
          `Don't pull in cost types. Your estimate will have all the categorizations you selected, a 
          Description, and a Total column with the total of each line item.`,
          totalIllustration,
          'Illustration of lines with totals only'
        )}
        {formControlLabel(
          EstimateTotalType.TOTAL_TYPE_UNIT,
          'Add Quantity and Unit Price for each line',
          `Show Quantity and Unit Price for each line. Don't pull in cost type columns, but do include 
          the takeoff quantity and aggregated unit price on each line in addition to a line total.`,
          unitIllustration,
          'Illustration of lines with quantity and unit price'
        )}
        {formControlLabel(
          EstimateTotalType.TOTAL_TYPE_COST_TYPES,
          'Add Quantity, Unit Price, and cost type breakdowns summary info',
          `Your estimate will have columns showing the Quantity and Unit for each line, and a pair of
          columns showing the ${getCurrencySymbol()}/Unit and subtotal for each of Labor, Equipment, Material, 
          Subcontractor, Other, and User Costs as well as a grand total for each line item.`,
          costTypesIllustration,
          'Illustration of lines broken down by cost type'
        )}
      </RadioGroup>
    </div>
  );
};

export default withStyles(styles)(EstimateTotalTypeRadioGroup);
