import { commonUserFields, gql } from '../../../api/graphqlFragments';

export const currentUserQuery = gql`
  query currentUser {
    currentUser {
      ...commonUserFields
      status
    }
  }
  ${commonUserFields}
`;

export const isJoinSupportQuery = gql`
  query isJoinSupport {
    isJoinSupport
  }
`;

export const updateUserNameMutation = gql`
  mutation updateUserName($name: String!) {
    updateUserName(name: $name) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;

export const updateUserJobTitleMutation = gql`
  mutation updateUserJobTitle($jobTitle: String!) {
    updateUserJobTitle(jobTitle: $jobTitle) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;

export const updateUserPictureMutation = gql`
  mutation updateUserPicture($picture: String!) {
    updateUserPicture(picture: $picture) {
      ...commonUserFields
      status
    }
  }
  ${commonUserFields}
`;
