import { FC } from 'react';

import { useCompanyRoutesData } from '../../CompanyTabUtils';

import CompanyCategorizations from './CompanyCategorizations';
import { useCompanyCategorizationsQuery } from './hooks';

const CompanyCategorizationsData: FC = () => {
  const { companyID, searchManager } = useCompanyRoutesData();

  const { data } = useCompanyCategorizationsQuery(companyID);
  const companyCategorizations = data?.companyCategorizations ?? [];

  return (
    <CompanyCategorizations
      companyCategorizations={companyCategorizations}
      searchManager={searchManager}
      companyID={companyID}
    />
  );
};

export default CompanyCategorizationsData;
