import { FC } from 'react';

import './MenuItem.scss';

export type MenuItemProps = {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  isDisabled: () => boolean;
  isActive?: () => boolean;
};

const MenuItem: FC<MenuItemProps> = ({
  icon,
  title,
  onClick,
  isDisabled,
  isActive = () => false,
}) => (
  <button
    className={`menu-item${isActive() && !isDisabled() ? ' is-active' : ''}${
      isDisabled() ? ' is-disabled' : ''
    }`}
    onClick={onClick}
    type="button"
    tabIndex={-1}
    title={title}
  >
    {icon}
  </button>
);

export default MenuItem;
