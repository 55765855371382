import { useDebounce, useScroll } from 'react-use';

import { analyticsEvent } from '../analytics/analyticsEventProperties';

import useAnalyticsEventHook from './useAnalyticsEventHook';

const useOnScrollFinishAnalytics = (ref: React.RefObject<HTMLDivElement>, action: string) => {
  const sendAnalytics = useAnalyticsEventHook();
  const { y } = useScroll(ref);

  const onScrollFinish = () => {
    sendAnalytics(analyticsEvent(action));
  };

  useDebounce(() => onScrollFinish(), 2000, [y]);
};

export default useOnScrollFinishAnalytics;
