import { useState } from 'react';

import ExpandableFiltersIcon from './ExpandableFiltersIcon';
import { ResetButton } from './ResetButton';

type Props = {
  'data-cy'?: string;
  canReset: boolean;
  children: React.ReactNode;
  count?: number;
  description?: string;
  onReset: () => void;
  title: string;
};

export default function ExpandableFilterContainer(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col rounded-2xl bg-background-2" data-cy={props['data-cy']}>
      <button
        aria-label={`toggle filter ${props.title} visibility`}
        className={[
          'flex w-full items-center gap-2 px-4 py-3 text-left text-type-primary focus-visible:outline',
          isExpanded ? 'rounded-t-2xl' : 'rounded-2xl',
        ].join(' ')}
        onClick={onClick}
      >
        {props.description ? (
          <div className="flex-grow">
            <div className="flex-grow type-heading3">{props.title}</div>
            <div className="type-label">{props.description}</div>
          </div>
        ) : (
          <div className="flex-grow type-heading3">{props.title}</div>
        )}
        <div className="flex items-center gap-1">
          {props.canReset && <ResetButton onClick={props.onReset} />}
          {props.count && props.count > 0 ? (
            <div className="type-number">({props.count})</div>
          ) : null}
          <ExpandableFiltersIcon isExpanded={isExpanded} />
        </div>
      </button>
      {isExpanded && <div className="px-3 pb-6">{props.children}</div>}
    </div>
  );
}
