import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACCEPTED,
  INCORPORATED,
  NOT_APPLICABLE,
  NOT_CHOSEN,
  PENDING,
  REJECTED,
} from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getItemStatusLabel } from '../../../utilities/item-status';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { generateTitle } from '../ItemsListItem/ItemsListItemUtils';

import ItemsOptionChipSquareStyles from './ItemsOptionChipSquareStyles';

type ItemsOptionChipSquareProps = {
  classes: Classes<typeof ItemsOptionChipSquareStyles>;
  name: string;
  number: string;
  status: string;
  url: string;
};

const getColorClass = (classes: Classes<typeof ItemsOptionChipSquareStyles>, status: string) => {
  switch (status) {
    case ACCEPTED:
      return classes.accepted;
    case REJECTED:
      return classes.rejected;
    case PENDING:
      return classes.pending;
    case INCORPORATED:
      return classes.incorporated;
    case NOT_APPLICABLE:
      return classes.notapplicable;
    case NOT_CHOSEN:
      return classes.notchosen;
    default:
      return '';
  }
};

const ItemsOptionChipSquare: FC<ItemsOptionChipSquareProps> = ({
  classes,
  name,
  number,
  status,
  url,
}) => {
  const navigate = useNavigate();
  const title = `${generateTitle({ number, name })} (${getItemStatusLabel(status)})`;

  return (
    <NormalTooltip title={title}>
      <button
        aria-label={`view option ${name}`}
        className={classes.button}
        data-cy="itemsOptionsChipSquare"
        type="button"
        onClick={() => {
          // go to the item's link if it has one
          if (url !== '') {
            navigate(url);
          }
        }}
      >
        <div className={`${classes.outerContainer} ${getColorClass(classes, status)}`} />
      </button>
    </NormalTooltip>
  );
};

export default withStyles(ItemsOptionChipSquareStyles)(ItemsOptionChipSquare);
