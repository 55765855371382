import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      margin: '8px 0px 8px 16px',
    },
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      padding: 24,
      overflow: 'visible',
    },
    cardTitle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    cardRoot: {
      paddingLeft: 0,
      paddingTop: 0,
    },
    errorIcon: {
      color: theme.palette.red,
      fontSize: '20px',
      paddingTop: '6px',
      paddingRight: '4px',
    },
    field: {
      width: '100%',
    },
    horizontal: {
      display: 'flex',
    },
    info: {
      color: theme.palette.backgroundGrey,
      marginLeft: 8,
    },
    middleAlign: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: 20,
    },
    options: {
      display: 'inline-block',
      margin: '24px 0px 0px 0px',
    },
    roleNameBlock: {
      marginRight: 10,
      marginBottom: 24,
    },
    roleSelect: {
      display: 'inline-flex',
      maxWidth: 162,
    },
    root: {
      flexGrow: 1,
      paddingBottom: 180,
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
  });

export default styles;
