import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';
import { IconButton } from '@material-ui/core';

import { itemSidebarMenuOpen } from '../../../analytics/analyticsEventProperties';
import { itemSidebarOpenVar } from '../../../api/apollo/reactiveVars';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { OpenSidebar } from '../OpenSidebar';

import ItemSidebarIconStyles from './ItemSidebarIconStyles';

type ItemSidebarIconProps = {
  classes: Classes<typeof ItemSidebarIconStyles>;
  isHovering: boolean;
  itemID: UUID;
  variant?: typeof SMALL;
};

const ItemSidebarIcon: FC<ItemSidebarIconProps> = ({ classes, isHovering, itemID, variant }) => {
  const sendAnalytics = useAnalyticsEventHook();
  const itemIdReactiveVar = useReactiveVar(itemSidebarOpenVar);

  const isCurrentItem = itemIdReactiveVar === itemID;
  const showIcon = isHovering || isCurrentItem;
  const flipIcon = !!itemIdReactiveVar && itemIdReactiveVar === itemID;
  const isSmallIcon = variant === SMALL;

  const onClick = () => {
    sendAnalytics(itemSidebarMenuOpen(itemID));
    if (itemIdReactiveVar === itemID) {
      itemSidebarOpenVar(null);
    } else {
      itemSidebarOpenVar(itemID);
    }
  };

  let iconSizeClass = '';
  if (isSmallIcon) {
    iconSizeClass = classes.smallIconButton;
  } else {
    iconSizeClass = classes.sidebarIconBackground;
  }

  return (
    <div className={isSmallIcon ? '' : classes.iconContainer}>
      {showIcon && (
        <IconButton
          data-cy="itemSidebar-icon"
          className={`${classes.sidebarIcon} ${
            flipIcon ? classes.sidebarIconFlipped : ''
          } ${iconSizeClass}`}
          onClick={() => onClick()}
        >
          <OpenSidebar className={`${isSmallIcon ? classes.smallIcon : ''}`} />
        </IconButton>
      )}
    </div>
  );
};

export default withStyles(ItemSidebarIconStyles)(ItemSidebarIcon);
