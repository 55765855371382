import { commonCostReportColumnFields, gql } from '../../../../api/graphqlFragments';

export const loadScenariosMetadataQuery = gql`
  query loadScenariosMetadataQuery($milestoneID: UUID!) {
    loadScenariosMetadata(milestoneID: $milestoneID) {
      scenarioID
      name
      description
      color
    }
  }
`;

export const loadScenariosSummaryByMilestoneIDQuery = gql`
  query loadScenariosSummaryByMilestoneID($milestoneID: UUID!, $costMode: CostMode!) {
    loadScenariosSummaryByMilestoneID(milestoneID: $milestoneID, costMode: $costMode) {
      scenarioID
      name
      description
      color
      thumbnailAssetID
      itemsCount
      costImpact {
        ...commonCostReportColumnFields
      }
    }
  }
  ${commonCostReportColumnFields}
`;

export const updateScenarioItemOverlaysMutation = gql`
  mutation updateScenarioItemOverlays(
    $scenarioID: UUID!
    $itemOverlays: [ItemOverlay!]!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    updateScenarioItemOverlays(
      scenarioID: $scenarioID
      itemOverlays: $itemOverlays
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      id
    }
  }
`;

export const addItemToScenariosMutation = gql`
  mutation addItemToScenarios($scenarioIDs: [UUID!]!, $itemID: UUID!) {
    addItemToScenarios(scenarioIDs: $scenarioIDs, itemID: $itemID)
  }
`;
