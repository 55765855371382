import { FC } from 'react';

import { formatCost } from '../../../utilities/currency';

type PrintItemDetailsContingencyDrawsProps = {
  draws: Estimate['contingencyDraws'];
};

const PrintItemDetailsContingencyDraws: FC<PrintItemDetailsContingencyDrawsProps> = ({ draws }) => {
  const activeDraws = draws?.filter((draw) => !!draw.drawFromID) ?? [];
  if (!activeDraws.length) return null;
  return (
    <>
      <div className="print-table-title print-bold-text print-avoid-page-break">
        Contingency &amp; Allowance Draws
      </div>
      {activeDraws.map((draw, i) => (
        <div className="print-table-header-footer print-avoid-page-break" key={draw.name}>
          <div className="print-table-subtotal-details">
            <div className="print-table-subtotal-details-text print-subtotal-index-column">
              C{i + 1}
            </div>
            <div className="print-table-subtotal-details-text print-subtotal-column">
              {draw.name} Draw
            </div>
            <div className="print-table-subtotal-details-text print-table-subtotal-cost-text print-table-align-right">
              {formatCost(draw.draw)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default PrintItemDetailsContingencyDraws;
