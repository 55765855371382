import { GetMilestonesQuery, GetMilestonesQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getMilestonesQuery } from './queries';

export default function useMilestonesQuery(projectId: UUID, showDrafts: boolean) {
  return useQuery<GetMilestonesQuery, GetMilestonesQueryVariables>(getMilestonesQuery, {
    variables: { projectID: projectId, showDrafts },
    skip: !projectId,
  });
}
