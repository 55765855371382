import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import { styles } from './LearnMoreStyles';

type LearnMoreProps = {
  classes: Classes<typeof styles>;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  text: string;
};

const LearnMore: FC<LearnMoreProps> = ({ classes, href, onClick, text }) => {
  return (
    <a className={classes.link} rel="noreferrer" target="_blank" href={href} onClick={onClick}>
      <Typography>{text}</Typography>
      <Launch className={classes.linkIcon} />
    </a>
  );
};

export default withStyles(styles)(LearnMore);
