import { FC } from 'react';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import InputsToggle, { ToggleOption } from '../../Inputs/InputsToggle/InputsToggle';
import { COST_PROJECTS, ChartView, NUM_PROJECTS } from '../ExecutiveDashboardUtils';

const optionText = [COST_PROJECTS, NUM_PROJECTS];

type ExecutiveDashboardChartViewToggleProps = {
  chartView: string;
  setChartView: (view: ChartView) => void;
};

const getToggleOption = (text: string) => ({
  key: text,
  text,
});

const ExecutiveDashboardChartViewToggle: FC<ExecutiveDashboardChartViewToggleProps> = ({
  chartView,
  setChartView,
}) => {
  const sendAnalytics = useAnalyticsEventHook();

  return (
    <InputsToggle
      isCompact={false}
      isWide
      options={optionText.map((text: string) => getToggleOption(text))}
      selected={[getToggleOption(chartView)]}
      setSelected={(toggleOption: ToggleOption) => {
        sendAnalytics(
          execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_CHART_VIEW, {
            chart: {
              toggleOption: toggleOption.text,
            },
          })
        );
        setChartView(toggleOption.text as ChartView);
      }}
    />
  );
};

export default ExecutiveDashboardChartViewToggle;
