import { useReactiveVar } from '@apollo/client';

import {
  MilestoneCostReportsQuery,
  MilestoneCostReportsQueryVariables,
} from '../generated/graphql';
import { costModeVar } from '../utilities/costMode';

import { columns as defaultColumns } from './constants';
import { milestoneCostReportsQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

export function useMilestonesCostReportsQuery(
  milestoneIDs: CostReportMilestoneInput[],
  projectID?: UUID | null | undefined,
  viewFilter: ViewFilterInput = {},
  columns: CostReportColumnInput[] = defaultColumns,
  skip?: boolean
) {
  return useQuery<MilestoneCostReportsQuery, MilestoneCostReportsQueryVariables>(
    milestoneCostReportsQuery,
    {
      variables: {
        milestones: milestoneIDs,
        columns,
        groupByItems: [],
        costMode: useReactiveVar(costModeVar),
        projectID,
        viewFilter,
      },
      skip: skip || !milestoneIDs?.length || !projectID,
    }
  );
}
