import { getCostReportByType, getCostValue } from '../../components/CostReport/CostReportUtils';
import { ACTIVE_ESTIMATE, BUDGET } from '../../constants';
import { CostReportColumnType, EstimateType } from '../../generated/graphql';

export const computeDraftEstimateTotal = (
  costReports: MilestoneCostReports,
  type?: EstimateType | string
) => {
  if (costReports && costReports.length === 1) {
    const report = costReports[0];
    if (type === ACTIVE_ESTIMATE) {
      const estimate = getCostReportByType(report, CostReportColumnType.ESTIMATE_REPORT);
      if (estimate) {
        return Number(getCostValue(estimate.range));
      }
    }
    if (type === BUDGET) {
      const target = getCostReportByType(report, CostReportColumnType.TARGET_REPORT);
      if (target) {
        return Number(getCostValue(target.range));
      }
    }
  }
  return 0;
};
