import { TimelineEvent, timelineEvent } from '../../analytics/analyticsEventProperties';
import { TimelineGroups } from '../../api/gqlEnums';
import { COLLAPSE } from '../../constants';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { getProjectIdFromUrl } from '../../utilities/url';
import { CollapseSettings, usePersistentStates, useSetCollapse } from '../../utilities/urlState';
import CollapseIcon from '../shared-widgets/CollapseIcon';

import { getTimelineUrlConfig } from './TimelineUtils';

export const setCollapseAnalytics = (collapse: boolean, IDs: string[], node: string) =>
  timelineEvent(
    node === TimelineGroups.TIMELINE
      ? TimelineEvent.TIMELINE_EXPAND_COLLAPSE
      : TimelineEvent.DUE_DATE_EXPAND_COLLAPSE,
    {
      collapse,
    }
  );

export default function TimelineCollapse({ nodeId }: { nodeId: string }) {
  const projectID = getProjectIdFromUrl();
  const [settings, setSettings] = usePersistentStates(...getTimelineUrlConfig(projectID));
  const sendAnalytics = useAnalyticsEventHook();
  timelineEvent(TimelineEvent.TIMELINE_EXPAND_COLLAPSE);

  const setCollapse = useSetCollapse(
    settings as CollapseSettings,
    setSettings,
    (collapse: boolean, IDs: string[], node: string) =>
      sendAnalytics(setCollapseAnalytics(collapse, IDs, node))
  );

  return (
    <CollapseIcon
      isCollapsed={settings[COLLAPSE].includes(nodeId)}
      onClick={(collapsed: boolean) => setCollapse(collapsed, [nodeId], nodeId)}
    />
  );
}
