import { FC } from 'react';

import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';
import IntegrationsDataWrapper from '../../Items/ItemsCollapse/Integrations/IntegrationsDataWrapper';

import { ITEM_HISTORY_ZOOM, useHeightOnLoad } from './PrintItemDetailsUtils';

type Props = {
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
  triggerOnComplete?: () => void;
};

const PrintItemDetailsIntegrations: FC<Props> = ({ item, permissions, triggerOnComplete }) => {
  const canViewItemIntegrations = permissions.canView(PermissionResource.ITEM_INTEGRATIONS, {
    trades: item?.categories || [],
  });
  const userSources = useUserSourcesQuery().data?.userSources;

  const { onLoadComplete, ref, height } = useHeightOnLoad(triggerOnComplete, 1 / ITEM_HISTORY_ZOOM);

  if (!canViewItemIntegrations) return null;

  return (
    <div
      ref={ref}
      style={{ height, transform: `scale(${ITEM_HISTORY_ZOOM})`, transformOrigin: 'top left' }}
      className="mt-5"
    >
      <div className="float-left w-full type-heading3">Integrations</div>
      <IntegrationsDataWrapper
        isItemSidebar={false}
        canCreateChangeEvent={false}
        itemInfo={item}
        isPrinting
        canDeleteItemIntegration={false}
        userSources={userSources}
        onLoadComplete={onLoadComplete}
      />
    </div>
  );
};
export default PrintItemDetailsIntegrations;
