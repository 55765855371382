import { NavigateFunction } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useReactiveVar } from '@apollo/client';

import {
  projectCompsLocalSettingsVar,
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
  setReactiveLocal,
} from '../../api/apollo/reactiveVars';
import { JoinForecastingRoutes, ProjectCompSectionType } from '../../api/gqlEnums';
import { NEW_COLUMN_FROM_AVERAGES, PROJECT_COMPS_LOCAL_SETTINGS_VAR } from '../../constants';
import { CostTableColumnInputKey, MetricsInput } from '../../generated/graphql';
import {
  formatCommas,
  getCurrencySymbol,
  isNegative,
  scrubNumberString,
} from '../../utilities/currency';
import { generateSharedPath } from '../../utilities/routes/links';
import { EMPTY_COST } from '../../utilities/string';
import { renderValueString } from '../CostReport/CostReportUtils';

export const useIsProjectCompSectionCollapsed = (section: ProjectCompSectionType) => {
  const localSettings = useReactiveVar(projectCompsLocalSettingsVar);
  return localSettings.collapsed.includes(section);
};

export const toggleProjectCompSectionCollapsed = (section: ProjectCompSectionType) => {
  const localSettings = projectCompsLocalSettingsVar();
  const isCollapsed = localSettings.collapsed.includes(section);
  const collapsed = localSettings.collapsed.filter((c: ProjectCompSectionType) => c !== section);
  if (!isCollapsed) collapsed.push(section);
  setReactiveLocal(projectCompsLocalSettingsVar, PROJECT_COMPS_LOCAL_SETTINGS_VAR, {
    ...localSettings,
    collapsed,
  });
};

export const showMinMaxCosts = () => {
  return projectCompsLocalSettingsVar().showMinMaxCosts;
};

export const toggleShowMinMaxCosts = () => {
  const localSettings = projectCompsLocalSettingsVar();

  setReactiveLocal(projectCompsLocalSettingsVar, PROJECT_COMPS_LOCAL_SETTINGS_VAR, {
    ...localSettings,
    showMinMaxCosts: !localSettings.showMinMaxCosts,
  });
};

export const navigateToProjectComps = (
  navigate: NavigateFunction,
  selectedProjectsIDs: UUID[],
  projectId?: UUID
) => {
  projectCompsSetInputVar({
    ...projectCompsSettingsInputDefault,
    projectCompInputs: selectedProjectsIDs.map((projectID) => ({ projectID, id: uuidv4() })),
  });

  const path = generateSharedPath(JoinForecastingRoutes.FORECASTING_UNSAVED_PROJECT_COMPARISON, {});
  navigate({ pathname: path }, { state: { projectId } }); // State parsed in `ProjectCompsSetData` to get parent project reference when creating a new Forecasting PC report from a project.
};

// PERCENT STRINGS AND VALUES
// NOTE: We manage the percent character here for display
const getNumberValue = (inputString: string) => {
  const numberValue = scrubNumberString(inputString, false, { removeSign: true });
  return isNegative(inputString) ? -numberValue : numberValue;
};
export const getNumberString = (inputString: string) => String(getNumberValue(inputString));
const getCommaNumberString = (inputString: string) => formatCommas(getNumberString(inputString));
export const displayCommaPercentString = (value: string) => {
  const hasValue = value && !!getNumberValue(value);
  return `${hasValue ? getCommaNumberString(value) : EMPTY_COST}%`;
};

const getSelectedMetric = (unit: Unit, metrics: MetricsInput[]) =>
  metrics.find((metric: MetricsInput) => metric.unitID === unit.id);

export const getHasMetricValue = (unit: Unit, metrics: MetricsInput[]) => {
  const selectedMetric = getSelectedMetric(unit, metrics);
  return (
    selectedMetric &&
    selectedMetric.quantityMagnitude &&
    getNumberValue(selectedMetric.quantityMagnitude) >= 0
  );
};

export const getSelectedUnitLabel = (unit: Unit, metrics: MetricsInput[] = []) => {
  const selectedMetric = getSelectedMetric(unit, metrics);
  return getUnitLabel(unit.abbreviationSingular, selectedMetric?.quantityMagnitude ?? '');
};

export const getUnitLabel = (unit: string, quantityMagnitude: string): string => {
  const quantityHasValue = quantityMagnitude && !!getNumberValue(quantityMagnitude);
  return `${quantityHasValue ? getCommaNumberString(quantityMagnitude) : EMPTY_COST} ${unit}`;
};

export const getDisplayValueString = (
  value?: string | number,
  config: {
    unit?: string;
    isWide?: boolean;
    isExact?: boolean;
  } = {}
): string => {
  const isValidNumber = !!value && !!Number(value) && value !== Infinity && value !== -Infinity;
  const { unit, isWide = false, isExact = false } = config;
  let valueString = '';
  if (isValidNumber) {
    valueString = renderValueString({ value, isWide, isExact });
  } else {
    valueString = `$${EMPTY_COST}`;
  }
  if (unit) {
    valueString += `/${unit}`;
  }
  return valueString;
};

// Turn zero / empty escalations to undefined
export const scrubEmptyNumberString = (numberString: string) => {
  const numberFloat = parseFloat(numberString);
  if (!numberFloat || Number.isNaN(numberFloat)) {
    return null;
  }

  return numberString;
};

export const extractAverageCompValues = (averageComp: AverageComp) => {
  const {
    input: { description, name: inputName, color, thumbnailAssetID, location, metrics },
    isHidden,
    isResettable,
    projectCompsCostTable,
  } = averageComp;

  return {
    color: color ?? undefined,
    description,
    isHidden,
    isResettable,
    location: location ?? undefined,
    metrics,
    name: inputName || NEW_COLUMN_FROM_AVERAGES,
    projectCompsCostTable: projectCompsCostTable as ProjectCompsCostTable,
    thumbnailAssetID: thumbnailAssetID ?? undefined,
  };
};

export const extractProjectCompValues = (projectComp: ProjectComp) => {
  const {
    input: { description, escalation, id, isExcluded, name: inputName, metrics },
    isResettable,
    milestone,
    project: {
      activeMilestone: { id: activeMilestoneID },
      hasAccess,
      id: projectID,
      location,
      lat,
      lon,
      milestones,
      name: projectName,
      status: { name: projectStatus },
      type: { name: projectTypeName },
      thumbnail,
    },
    projectCompsCostTable,
  } = projectComp;

  return {
    activeMilestoneID,
    description,
    escalation: escalation ?? undefined,
    hasAccess,
    id,
    isExcluded: Boolean(isExcluded),
    isResettable,
    lat: lat ?? undefined,
    location,
    lon: lon ?? undefined,
    metrics,
    milestone,
    milestones,
    name: inputName || projectName,
    projectCompsCostTable: projectCompsCostTable as ProjectCompsCostTable,
    projectType: projectTypeName,
    projectID,
    projectName,
    projectStatus,
    thumbnail,
  };
};

/**
 * getColumnInputDisplayString formats the string to display based on column key and unit information
 */
export const getColumnInputDisplayString = (
  key: CostTableColumnInputKey,
  unitAbbreviation?: string,
  displayTotalString?: boolean,
  displayTotalCostSymbol = false
) => {
  const costSymbol = getCurrencySymbol() || '';
  switch (key) {
    case CostTableColumnInputKey.TOTAL:
      if (displayTotalCostSymbol) return displayTotalString ? `Total ${costSymbol}` : costSymbol;
      return 'Total';
    case CostTableColumnInputKey.TOTAL_PER_METRIC:
      return unitAbbreviation ? `${costSymbol}/${unitAbbreviation}` : '';
    case CostTableColumnInputKey.PERCENT:
      return 'Percent';
    case CostTableColumnInputKey.METRIC:
      return unitAbbreviation ?? '';
    default:
      return '';
  }
};
