import { FC, ReactNode } from 'react';

import { Typography } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button } from '../../scales';

import styles from './SignupPageStyles';

type OnboardingCustomActionsProps = {
  classes: Classes<typeof styles>;
  navBackText?: string;
  navNextText?: string;
  onNext?: (() => void) | undefined;
  onBack?: (() => void) | undefined;
};

const OnboardingCustomActions: FC<OnboardingCustomActionsProps> = ({
  classes,
  navBackText = 'Back',
  navNextText = 'Continue',
  onBack = undefined,
  onNext = undefined,
}) => (
  <div className={`${classes.actions} ${classes.spacedText}`}>
    {onBack && (
      <Button type="secondary" onClick={onBack} startIcon={<Clear />} label={navBackText} />
    )}
    {onNext && <Button type="primary" onClick={onNext} startIcon={<Done />} label={navNextText} />}
  </div>
);

export const CustomActions = withStyles(styles)(OnboardingCustomActions);

type OnboardingContentBoxProps = {
  actions?: ReactNode;
  classes: Classes<typeof styles>;
  content: ReactNode;
  disableBack?: boolean;
  disableNext?: boolean;
  navBackText?: string;
  navNextText?: string;
  onNext?: (() => void) | undefined;
  onBack?: (() => void) | undefined;
  title: string;
};

const OnboardingContentBox: FC<OnboardingContentBoxProps> = ({
  actions = undefined,
  classes,
  content,
  disableBack = false,
  disableNext = false,
  navBackText = 'Back',
  navNextText = 'Continue',
  onBack = undefined,
  onNext = undefined,
  title,
}) => (
  <div className={classes.contentBoxOnboarding}>
    <div className={classes.content}>
      <Typography className={classes.title} data-cy="onBoardingContentBox-title" variant="headline">
        {title}
      </Typography>
      {content}
      {actions || (
        <div className={`${classes.actions} ${classes.spacedText}`}>
          {onBack && (
            <Button
              data-cy="onboardingContentBox-buttonBack"
              disabled={disableBack}
              label={navBackText}
              onClick={onBack}
              type="primary"
            />
          )}
          {onNext && (
            <Button
              data-cy="onboardingContentBox-buttonNext"
              disabled={disableNext}
              label={navNextText}
              onClick={onNext}
              type="primary"
            />
          )}
        </div>
      )}
    </div>
  </div>
);

export default withStyles(styles)(OnboardingContentBox);
