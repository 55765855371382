import { FC, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import {
  createItemAnalytics,
  createOptionAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import { REFETCH_CHANGE_ITEM, refetchItem } from '../../../api/refetchSets';
import { YC_ITEM_SHARING } from '../../../features';
import {
  ConvertItemToItemWithOptionsMutation,
  ConvertItemToItemWithOptionsMutationVariables,
  Visibility,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { useRefetch } from '../../../hooks/useRefetch';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { costModeVar } from '../../../utilities/costMode';
import { isTextValid } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { convertItemToItemWithOptionsMutation } from '../../Items/hooks/itemMutation';
import { useCurrentUserQuery } from '../../login/Signup/SignupPages/EmailVerification/EmailVerificationHooks';
import useMemoWrapper from '../../useMemoWrapper';

import DialogsNewItemContent from './DialogsNewItemContent';
import {
  analyticPropsForItem,
  filterCategoriesForSubmit,
  getGenerateNewItemFromParent,
  getNewOptions,
  useCreateItemMutation,
  useCreateOptionMutation,
} from './DialogsNewItemUtils';

type DialogsNewItemProps = {
  milestoneID: string;
  onClose: () => void;
  expandEstimateContainer?: () => void;
  open: boolean;
  parentItem?: Item;
  onCreateAnother?: () => void;
};

const DialogsNewItem: FC<DialogsNewItemProps> = ({
  milestoneID,
  onClose,
  open,
  parentItem,
  onCreateAnother,
  expandEstimateContainer,
}) => {
  const projectId = getProjectIdFromUrl();
  const { data: currentUserData } = useCurrentUserQuery();
  const hasItemSharing = useHasFeature(YC_ITEM_SHARING);
  // OPTION CONSTANTS
  const isOption = !!parentItem;
  const isConvertingToIWO = isOption && !(parentItem || {}).hasOptions;

  const defaultVisibility = parentItem?.visibility || Visibility.PUBLISHED;

  // HOOKS
  const [visibilitySetting, setVisibilitySetting] = useState(defaultVisibility);
  const generateNewItemFromParent = getGenerateNewItemFromParent(milestoneID, visibilitySetting);

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<DraftItem>(generateNewItemFromParent(parentItem));
  const [count, setCount] = useState(0);
  const sendAnalytics = useAnalyticsEventHook();
  const refetch = useRefetch(REFETCH_CHANGE_ITEM);

  // when parent categories change after first load, new item changes -- reset default item to have those categories
  useEffect(() => {
    if (parentItem || milestoneID !== item.milestoneID) {
      setItem(generateNewItemFromParent(parentItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOption, milestoneID, parentItem, setItem]);

  const onCloseDialog = () => {
    onClose();
    setItem({ ...generateNewItemFromParent(), visibility: defaultVisibility });
    setVisibilitySetting(defaultVisibility);
  };

  // ITEM + OPTION MUTATIONS
  const [convertItemToItemWithOption] = useMutation<
    ConvertItemToItemWithOptionsMutation,
    ConvertItemToItemWithOptionsMutationVariables
  >(convertItemToItemWithOptionsMutation);

  const onConvertItem = isOption
    ? () =>
        convertItemToItemWithOption({
          variables: {
            projectID: projectId,
            item: parentItem.id,
            costMode: costModeVar(),
          },
        }).then((result) => {
          // let analytics know if the item has no estimate
          if (parentItem.activeEstimate) {
            // open the estimate / option panel if it isn't already open
            if (expandEstimateContainer) expandEstimateContainer();

            // send analytics
            sendAnalytics(createOptionAnalytics({ howCreated: 'CONVERTED ITEM' }));
          }
          const { convertItemToItemWithOptions } = result.data || {};
          if (convertItemToItemWithOptions?.milestone) {
            refetch([refetchItem(convertItemToItemWithOptions.id)]);
          }
        })
    : () => Promise.resolve();

  const createItem = useCreateItemMutation({
    projectId,
  });
  const createOption = useCreateOptionMutation({
    parentItem,
    projectId,
  });
  const create = isOption ? createOption : createItem;
  const { categories, name } = item;

  const isValidName = useMemoWrapper(isTextValid, name);

  const onSubmit = (onCloseAction?: () => void, createAsDraft?: boolean) => {
    // don't create an item without a valid name
    // when users press enter to create an item
    // they can create an item with an invalid name
    // even if the submit button is disabled
    // also don't create an item if we've already submitted
    // the current item
    if (!isValidName || loading) {
      return;
    }
    if (hasItemSharing) {
      const visibility = createAsDraft ? Visibility.PRIVATE_DRAFT : Visibility.PUBLISHED;
      item.visibility = visibility;
    }
    if (createAsDraft && item.assigneeEmail !== currentUserData?.currentUser?.email)
      delete item.assigneeEmail;
    // bundle response activities
    setLoading(true);
    const onSuccess: Parameters<typeof create>[1] = (createdItem) => {
      if (isOption) {
        // notify analytics
        sendAnalytics(createOptionAnalytics({ howCreated: 'NEW OPTION ON ITEM WITH OPTIONS' }));
        const newOptions = getNewOptions(createdItem, parentItem) || [];
        setToast(null, ToastType.NEW_ITEM, newOptions[0]);
        // update event data for the parent item
        if (onCreateAnother) onCreateAnother();
      } else {
        setToast(null, ToastType.NEW_ITEM, createdItem);
      }
      // we need to update the item...
      if (createdItem.milestone) {
        if (createdItem.id) {
          refetch([refetchItem(createdItem.id)]);
        }
      }
      setItem(generateNewItemFromParent(parentItem));
      if (onCloseAction) {
        onCloseAction();
      } else {
        setCount(count + 1);
      }
      setLoading(false);
    };
    const onFailure: Parameters<typeof create>[2] = () => {
      setLoading(false);
    };
    if (isConvertingToIWO) {
      onConvertItem().then(() => {
        create(
          {
            ...item,
            categories: filterCategoriesForSubmit(categories) ?? [],
          },
          onSuccess,
          onFailure
        );
      });
    } else {
      if (!isOption) {
        sendAnalytics(createItemAnalytics(analyticPropsForItem(item)));
      }
      create(
        {
          ...item,
          categories: filterCategoriesForSubmit(categories) ?? [],
        },
        onSuccess,
        onFailure
      );
    }
  };

  return (
    <DialogsNewItemContent
      item={item}
      isValidName={isValidName}
      count={count}
      convertItem={onConvertItem}
      loading={loading}
      onClose={onCloseDialog}
      open={open}
      parentItem={parentItem}
      onSubmit={onSubmit}
      setItem={setItem}
      visibilitySetting={visibilitySetting}
      setVisibilitySetting={setVisibilitySetting}
    />
  );
};

export default DialogsNewItem;
