import { FC } from 'react';

import { BUDGET_GAP_BY_PROJECT } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useProjectBudgetGapsQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  BUDGET_GAPS_HEIGHT,
  BUDGET_GAP_BY_PROJECT_TOOLTIP,
  CHART_LARGE,
  ExecutiveDashboardDataProps,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardBudgetGaps from './ExecutiveDashboardBudgetGaps';

const ExecutiveDashboardBudgetGapsData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
  projectMap,
}) => {
  const { data: { projectBudgetGaps } = { projectBudgetGaps: [] }, loading } =
    useProjectBudgetGapsQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <div onMouseOver={onHover} onFocus={onHover} data-cy="budget-gap-chart">
            <ExecutiveDashboardBudgetGaps
              projectBudgetGaps={projectBudgetGaps}
              projectMap={projectMap}
            />
          </div>
        )
      }
      height={BUDGET_GAPS_HEIGHT}
      title={BUDGET_GAP_BY_PROJECT}
      variant={CHART_LARGE}
      tooltip={BUDGET_GAP_BY_PROJECT_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardBudgetGapsData;
