import { ReactNode, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';

import { TooltipOverlay } from '../../../scales';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  opacity: number;
  tooltip: ReactNode;
};

export default function Bar(props: Props) {
  const overlayTriggerRef = useRef<SVGRectElement>(null);
  const overlayTriggerState = useOverlayTriggerState({});

  const onMouseOver = () => {
    overlayTriggerState.open();
    if (props.onMouseOver) props.onMouseOver();
  };

  const onMouseLeave = () => {
    overlayTriggerState.close();
    if (props.onMouseLeave) props.onMouseLeave();
  };

  return (
    <g>
      <title>{props.onClick ? 'Click to filter by category' : ''}</title>
      <rect
        data-cy="bar"
        ref={overlayTriggerRef}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={props.onClick}
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        fill={props.fill}
        opacity={props.opacity}
        className={`transition-all duration-500 ease-in-out ${
          props.onClick ? 'cursor-pointer' : ''
        } ${overlayTriggerState.isOpen ? 'drop-shadow-xl' : ''}`}
      />
      {overlayTriggerState.isOpen && (
        <TooltipOverlay
          placement="right top"
          state={overlayTriggerState}
          triggerRef={overlayTriggerRef}
        >
          {props.tooltip}
        </TooltipOverlay>
      )}
    </g>
  );
}
