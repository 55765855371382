import { useMutation } from '@apollo/client';

import { gql } from '../api/graphqlFragments';
import { REFETCH_ITEM_INTEGRATIONS } from '../api/refetchSets';
import {
  SourceSystem,
  SourceType,
  UnlinkChangeEventInput,
  UnlinkChangeEventMutation,
  UnlinkChangeEventMutationOptions,
  UnlinkChangeEventMutationVariables,
} from '../generated/graphql';

const unlinkChangeEventMutation = gql`
  mutation UnlinkChangeEvent(
    $projectID: UUID!
    $itemID: UUID!
    $sourceSystem: SourceSystem!
    $sourceType: SourceType!
    $sourceID: UUID!
    $input: UnlinkChangeEventInput!
  ) {
    unlinkChangeEvent(
      projectID: $projectID
      itemID: $itemID
      sourceSystem: $sourceSystem
      sourceType: $sourceType
      sourceID: $sourceID
      input: $input
    )
  }
`;

export default function useUnlinkChangeEventMutation(options?: UnlinkChangeEventMutationOptions) {
  const [unLinkChangeEvent, mutationResult] = useMutation<
    UnlinkChangeEventMutation,
    UnlinkChangeEventMutationVariables
  >(unlinkChangeEventMutation, options);

  const submitFunc = (
    projectID: UUID,
    itemID: UUID,
    sourceSystem: SourceSystem,
    sourceType: SourceType,
    sourceID: UUID,
    input: UnlinkChangeEventInput
  ) => {
    return unLinkChangeEvent({
      variables: { projectID, sourceSystem, sourceType, itemID, sourceID, input },
      refetchQueries: REFETCH_ITEM_INTEGRATIONS,
    });
  };

  return [submitFunc, mutationResult] as const;
}
