import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';

import theme from '../../../../theme/komodo-mui-theme';

import { MAX_NUM_COMBINATIONS } from './MilestoneDetailsQuantitiesUtils';

type MilestoneDetailsQuantitiesCateogorizationDiagramProps = {
  categoryCount: number;
  categorizationCount: number;
  loading?: boolean;
};

const numberColor = 'black';
const EMPTY_COUNT = '--';

const {
  backgroundWhite,
  chartMediumGreen,
  chartBlue,
  notapplicable,
  pending,
  chartBlack,
  chartGreen,
  pinkBadge,
  chartsThemes: { orange },
} = theme.palette;

const colorRects: JSX.Element[][] = [
  [
    <rect key="blue-1" x="198" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="blue-2" x="198" y="17" width="85" height="41" fill={chartBlue} opacity="0.25" />,
    <rect key="blue-3" x="198" width="85" height="16" fill={chartBlue} />,
  ],
  [
    <rect key="pink-1" x="206" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="pink-2" x="206" y="17" width="85" height="41" fill={pinkBadge} opacity="0.25" />,
    <rect key="pink-3" x="206" width="85" height="16" fill={pinkBadge} />,
  ],
  [
    <rect key="orange-1" x="213" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="orange-2" x="213" y="17" width="85" height="41" fill={orange} opacity="0.25" />,
    <rect key="orange-3" x="213" width="85" height="16" fill={orange} />,
  ],
  [
    <rect key="pending-1" x="220" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="pending-2" x="220" y="17" width="85" height="41" fill={pending} opacity="0.25" />,
    <rect key="pending-3" x="220" width="85" height="16" fill={pending} />,
  ],
  [
    <rect key="green-1" x="227" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="green-2" x="227" y="17" width="85" height="41" fill={chartGreen} opacity="0.25" />,
    <rect key="green-3" x="227" width="85" height="16" fill={chartGreen} />,
  ],
  [
    <rect key="NA-1" x="234" y="17" width="85" height="41" fill={backgroundWhite} />,
    <rect key="NA-2" x="234" y="17" width="85" height="41" fill={notapplicable} opacity="0.25" />,
    <rect key="NA-3" x="234" width="85" height="16" fill={notapplicable} />,
  ],
];

const getColorBars = (count: number) => {
  const index = count - 1;
  const rectangleArrays: JSX.Element[][] = colorRects.slice(0, index);
  // We reverse to restack the first ones on top (order matters for SVG layers)
  // then flatten to have an array of Elements
  return rectangleArrays.reverse().flat();
};

// These are fine-tuned to display well in the space
const getFontSize = (count: number) => {
  if (count >= MAX_NUM_COMBINATIONS) return 24;
  if (count > 99) return 30;
  if (count > 9) return 36;
  return 42;
};

const getCountString = (count: number, hasCategorizations: boolean) => {
  if (!hasCategorizations) return EMPTY_COUNT;
  if (count >= MAX_NUM_COMBINATIONS) return '1000+';
  return String(count);
};

const CAT_1_CENTER = 42;
const COMBO_CENTER = 142;
const CAT_2_CENTER = 242;
const CAT_NAME_HEIGHT = 9;
const RECT_WIDTH = 76;
const PROGRESS_WIDTH = 32;

const MilestoneDetailsQuantitiesCategorizationDiagram: FC<
  MilestoneDetailsQuantitiesCateogorizationDiagramProps
> = ({ categoryCount, categorizationCount, loading = false }) => {
  const fontSize = getFontSize(categoryCount);
  const hasCategorizations = categorizationCount > 0;
  const hasManyCategorizations = categorizationCount > 1;
  const numberXposition = hasManyCategorizations ? COMBO_CENTER : CAT_1_CENTER;
  const firstCatzColor = hasCategorizations ? chartMediumGreen : notapplicable;
  return (
    <svg
      width="319"
      height="58"
      viewBox="0 0 319 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="85" height="16" fill={firstCatzColor} />
      <rect opacity="0.25" y="17" width="85" height="41" fill={firstCatzColor} />
      {hasManyCategorizations && (
        <>
          {getColorBars(categorizationCount)}
          <line x1="85" y1="23.5" x2="198" y2="23.5" stroke={notapplicable} />
          <line x1="85.0615" y1="23.5038" x2="198.061" y2="37.5038" stroke={notapplicable} />
          <line x1="85.1203" y1="23.5147" x2="198.12" y2="51.5147" stroke={notapplicable} />
          <line x1="85" y1="37.5" x2="198" y2="37.5" stroke={notapplicable} />
          <line x1="84.9385" y1="37.5038" x2="197.939" y2="23.5038" stroke={notapplicable} />
          <line x1="85.0615" y1="37.5038" x2="198.061" y2="51.5038" stroke={notapplicable} />
          <line x1="85" y1="51.5" x2="198" y2="51.5" stroke={notapplicable} />
          <line x1="84.9385" y1="51.5038" x2="197.939" y2="37.5038" stroke={notapplicable} />
          <line x1="84.8797" y1="51.5147" x2="197.88" y2="23.5147" stroke={notapplicable} />
          <circle cx="198" cy="23.5" r="4" fill={chartBlue} />
          <circle cx="198" cy="37.5" r="4" fill={chartBlue} />
          <circle cx="198" cy="51.5" r="4" fill={chartBlue} />
          <circle cx="84" cy="23.5" r="4" fill={chartMediumGreen} />
          <circle cx="84" cy="37.5" r="4" fill={chartMediumGreen} />
          <circle cx="84" cy="51.5" r="4" fill={chartMediumGreen} />
          <text // the location is correlated to the center of the box
            x={CAT_2_CENTER}
            y={CAT_NAME_HEIGHT}
            fontSize={10}
            fontFamily={theme.typography.fontFamily}
            fill={backgroundWhite}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            Categorization 2
          </text>
          <rect opacity="0.7" x="103" width={RECT_WIDTH} height="58" fill={backgroundWhite} />
          <text // the location is correlated to the center of the box
            x={COMBO_CENTER}
            y={CAT_NAME_HEIGHT}
            fontSize={10}
            fontFamily={theme.typography.fontFamily}
            fill={chartBlack}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            Total Combos
          </text>
        </>
      )}
      <text // the location is correlated to the center of the box
        x={CAT_1_CENTER}
        y={CAT_NAME_HEIGHT}
        fontSize={10}
        fontFamily={theme.typography.fontFamily}
        fill={backgroundWhite}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {hasCategorizations ? 'Categorization 1' : 'Categorization'}
      </text>
      {loading ? (
        <foreignObject
          x={numberXposition - PROGRESS_WIDTH / 2}
          y={38 - PROGRESS_WIDTH / 2}
          width={RECT_WIDTH}
          height="58"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <CircularProgress size={PROGRESS_WIDTH} />
        </foreignObject>
      ) : (
        <text // the location is correlated to the center of the box
          fontSize={fontSize}
          x={numberXposition}
          y="41"
          fill={numberColor}
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {getCountString(categoryCount, hasCategorizations)}
        </text>
      )}
    </svg>
  );
};

export default MilestoneDetailsQuantitiesCategorizationDiagram;
