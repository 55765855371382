import { DeleteOutline } from '@material-ui/icons';

import { Button, Dialog, DialogContent } from '../../scales';

type props = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

export default function DeleteDialog(props: props) {
  return (
    <Dialog
      isOpen={props.isOpen}
      title="Delete Scheduled Distribution"
      onClose={props.onCancel}
      footerRight={
        <div className="flex gap-3">
          <Button type="secondary" onClick={props.onCancel} label="Cancel" />
          <Button
            type="destructive"
            startIcon={<DeleteOutline />}
            onClick={props.onDelete}
            label="Delete"
          />
        </div>
      }
    >
      <DialogContent>
        <div className="p-6">
          Are you sure you want to delete the Scheduled Distribution from this report?
        </div>
      </DialogContent>
    </Dialog>
  );
}
