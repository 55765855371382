// used to get all of the data required for CollaboratorsListItem

import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import {
  useAddTradesCollaborator,
  useCollaboratorResponsibility,
  useRemoveTradesCollaborator,
  useUpdateCollaboratorAllTrades,
  useUpdateCollaboratorRole,
} from '../hooks';

export const useCollaboratorsListItem = (
  collaborator: Collaborator
): {
  roles: Role[];
  onUpdateCollaboratorRole: (r: Role) => void;
  updateCollaboratorResponsibility: (responsibility: string) => void;
} => {
  // generic variables
  const projectID = getProjectIdFromUrl();
  const { id: collaboratorID = '' } = collaborator;

  // ROLES
  const { data: { projectRoles: roles = [] } = {} } = useRolesQuery(
    projectID,
    MountPolicy.SKIP_ON_MOUNT
  );

  // UPDATING COLLABORATOR STUFF
  const updateCollaboratorRole = useUpdateCollaboratorRole();
  const [collaboratorResponsibility] = useCollaboratorResponsibility();
  const updateCollaboratorResponsibility = (responsibility: string) => {
    collaboratorResponsibility(projectID, collaboratorID, responsibility);
  };

  const onUpdateCollaboratorRole = (role: Role) => {
    updateCollaboratorRole(projectID, collaboratorID, role.id);
  };

  return {
    roles,
    onUpdateCollaboratorRole,
    updateCollaboratorResponsibility,
  };
};

export const useCollaboratorCategoryUpdates = (): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  addTradesCollaborator: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  removeTradesCollaborator: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  updateCollaboratorAllTrades: any;
} => {
  const [addTradesCollaborator] = useAddTradesCollaborator();
  const [removeTradesCollaborator] = useRemoveTradesCollaborator();
  const [updateCollaboratorAllTrades] = useUpdateCollaboratorAllTrades();

  return {
    addTradesCollaborator,
    removeTradesCollaborator,
    updateCollaboratorAllTrades,
  };
};
