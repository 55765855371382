import { useQuery } from '@apollo/client';

import { gql } from '../api/graphqlFragments';
import {
  ItemLinkedChangeEventsCountQuery,
  ItemLinkedChangeEventsCountQueryVariables,
} from '../generated/graphql';

export const itemLinkedChangeEventsCountQuery = gql`
  query ItemLinkedChangeEventsCount($itemID: UUID!) {
    itemLinkedChangeEventsCount(itemID: $itemID)
  }
`;

export default function useItemLinkedChangeEventsCountQuery(
  variables: ItemLinkedChangeEventsCountQueryVariables
) {
  return useQuery<ItemLinkedChangeEventsCountQuery, ItemLinkedChangeEventsCountQueryVariables>(
    itemLinkedChangeEventsCountQuery,
    {
      variables: {
        ...variables,
      },
      skip: !variables.itemID,
    }
  );
}
