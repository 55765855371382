import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { setItemEstimateExpanded } from '../../../analytics/analyticsEventProperties';
import { ESTIMATE } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { costModeVar } from '../../../utilities/costMode';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { getItemIdFromUrl } from '../../../utilities/url';
import { NAV_HEIGHT } from '../../frame/FrameTitleBar/FrameTitleBarStyles';
import { LIST } from '../../ItemsList/ItemsListViewToggle';
import useMemoWrapper from '../../useMemoWrapper';
import { useItemQuery } from '../hooks/useItemQuery';
import ItemsButtonDetachOption from '../ItemsListItem/ItemsListItemCta/ItemsButtonDetachOption';
import ItemsOptionsList from '../ItemsOptionsList/ItemsOptionsList';

import EstimateContentHeader from './EstimateContentHeader';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemsCollapseStyles, { SCROLL_MARGIN } from './ItemsCollapseStyles';
import { getContingencyEstimateCosts } from './utils';

type Props = {
  classes: Classes<typeof ItemsCollapseStyles>;
  isExpanded: boolean;
  projectID: UUID;
  setOpenDialogAddOption: (option: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
};

const ItemsCollapse: FC<Props> = ({
  classes,
  isExpanded,
  projectID,
  setIsExpanded,
  setOpenDialogAddOption,
  sharedUsersMap,
}) => {
  const [previousCostMode, setPreviousCostMode] = useState(costModeVar());

  const itemID = getItemIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const { data, refetch } = useItemQuery(itemID, MountPolicy.SKIP_ON_MOUNT);
  const item = data?.item || undefined;

  const costMode = useReactiveVar(costModeVar);
  useEffect(() => {
    if (previousCostMode !== costMode) {
      refetch({ id: itemID, costMode });
      setPreviousCostMode(costMode);
    }
  }, [costMode, itemID, previousCostMode, refetch]);

  // PERMISSIONS
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // Options
  const options: DetailedOption[] = useMemo(() => {
    if (item && 'options' in item) return item.options.filter((o) => !!o);
    return [];
  }, [item]);

  // Costs
  const estimateCost: Cost = useMemoWrapper(getContingencyEstimateCosts, item);

  // HELPER FUNCTIONS
  const activeEstimate = useMemo(() => (item && item.activeEstimate) || null, [item]); // Is this useMemo necessary?

  const scrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const id = window.location.hash.split('?')[0].slice(1);
    if (id === ESTIMATE || id === 'schedule-impact') {
      setIsExpanded(true);
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  if (!item) return null;

  return (
    <div className={classes.card} ref={scrollRef} style={{ scrollMarginTop: NAV_HEIGHT + 1 }}>
      <ItemDetailsCollapse
        analyticsEvent={setItemEstimateExpanded(!isExpanded)} // update?
        canExpand={options?.length > 0}
        hasGrayBackground
        headerContent={
          <EstimateContentHeader
            canViewContent
            shouldDisplayCosts={shouldDisplayCosts}
            estimateCost={estimateCost}
            hasActiveEstimate={!!activeEstimate}
            hasOptions
            itemID={itemID}
            options={options}
            projectID={projectID}
            sendAnalytics={sendAnalytics}
            setOpenDialogAddOption={setOpenDialogAddOption}
            scheduleImpact={undefined}
          />
        }
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        style={{ scrollMarginTop: SCROLL_MARGIN }}
      >
        <ItemsOptionsList
          getCtaButton={(option) => <ItemsButtonDetachOption option={option} />}
          isNewItemsList={false}
          item={item}
          itemsLikeSelected={[]}
          options={options}
          projectId={projectID}
          sharedUsersMap={sharedUsersMap}
          showCheckbox={false}
          view={LIST}
        />
      </ItemDetailsCollapse>
    </div>
  );
};

export default withStyles(ItemsCollapseStyles)(ItemsCollapse);
