import { createStyles } from '@material-ui/core/styles';

export const ITEM_NUMBER_DEFAULT_WIDTH = 130;
const DISABLED_PADDING = 6;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const fieldClass = (theme: any) => ({
  backgroundColor: theme.palette.backgroundWhite,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const inputClass = (theme: any) => ({
  height: 40,
  ...theme.typography.h3,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const ItemNumberTextFieldStyles = (theme: any) =>
  createStyles({
    disabledField: {
      ...fieldClass(theme),
      maxWidth: ITEM_NUMBER_DEFAULT_WIDTH,
    },
    field: {
      ...fieldClass(theme),
    },
    disabledInput: {
      ...inputClass(theme),
      paddingLeft: `${DISABLED_PADDING}px !important`,
    },
    input: {
      ...inputClass(theme),
    },
  });

export default ItemNumberTextFieldStyles;
