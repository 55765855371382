import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { pluralizeCountString } from '../../../../utilities/string';
import ContingencyBar from '../BarGraph/ContingencyReportBarGraph';

import { styles } from './AllMilestoneReportRowStyles';

type AllMilestoneReportRowCellProps = {
  classes: Classes<typeof styles>;
  contingency: MultiMilestoneContingencyReport['contingencies'][number]['report'][number] | null;
};

const AllMilestoneReportRowCell: FC<AllMilestoneReportRowCellProps> = ({
  classes,
  contingency,
}) => {
  const existsInMilestone = !!contingency?.existsInAllMilestonesReportMilestone;
  const isOverdrawn =
    !!contingency &&
    Number(contingency.startingCost) <
      Number(contingency.totalDrawnCost) + Number(contingency.usedCost);
  return (
    <div
      className={classes.rowContingencyCell}
      style={
        !existsInMilestone
          ? {
              color: 'gray',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}
      }
    >
      {existsInMilestone ? (
        <>
          <ContingencyBar
            starting={Number(contingency.startingCost)}
            used={-Number(contingency.usedCost)}
            pending={-Number(contingency.totalPendingCost)}
            drawn={-Number(contingency.totalDrawnCost)}
            isVertical
            overdrawLimit={0.15}
          />
          <div className={classes.rowContingencyCellData}>
            <div>{pluralizeCountString('item', contingency.acceptedItemCount)}</div>
            <div className={`${classes.rowContingencyCellDataTable} ${classes.tableSpacing}`}>
              <div>{formatCost(contingency.startingCost, { rounded: true })}</div>
              <div>{formatCost(contingency.totalDrawnCost, { rounded: true })}</div>
              <div className={isOverdrawn ? classes.overdrawnText : undefined}>
                {formatCost(
                  Number(contingency.startingCost) +
                    Number(contingency.usedCost) +
                    Number(contingency.totalDrawnCost),
                  {
                    rounded: true,
                  }
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        "Doesn't exist in Milestone"
      )}
    </div>
  );
};

export default withStyles(styles)(AllMilestoneReportRowCell);
