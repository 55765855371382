import { useState } from 'react';

import { ToastType } from '../../../api/gqlEnums';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { useCurrentUserQuery } from '../../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import { Button, Checkbox, Chip, Dialog, DialogContent, TextArea } from '../../scales';

import useReportItemAssistExperimentRequest from './useReportItemAssistExperimentRequest';

type Props = {
  projectID: UUID;
  itemID: UUID;
  onSubmit: () => void;
  onDismiss: () => void;
};

export default function SuggestionDialog(props: Props) {
  const submitFunc = useReportItemAssistExperimentRequest();
  const { data } = useCurrentUserQuery();
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [wantBPM, setWantBPM] = useState<boolean>(false);

  const userEmail = data?.currentUser.email;

  const onClick = () => {
    submitFunc(props.projectID, props.itemID, additionalInfo, wantBPM);
    props.onSubmit();
    setToast(
      {
        message:
          'Request submitted! After we collect info, you will receive an email from item-assist@join.build',
      },
      ToastType.SUCCESS
    );
  };
  return (
    <Dialog
      footerLeft={<Button label="Dismiss" onClick={props.onDismiss} type="secondary" />}
      footerRight={<Button label="Submit" onClick={onClick} type="primary" />}
      isOpen
      onClose={props.onDismiss}
      title={
        <div className="flex items-center gap-4">
          <div>Item Assist</div>
          <div className="flex gap-2 truncate">
            <Chip icon={<img src="/img/science.svg" width={16} alt="" />} text="Experimental" />
          </div>
        </div>
      }
      size="md"
    >
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="flex type-body1">
            <strong>What is this?</strong>
          </div>
          <div className="flex type-body1">
            Item Assist is an experimental tool that helps you develop an Item by retrieving
            relevant information to support your team making informed decisions.
          </div>
          <div className="flex">
            <TextArea
              onChange={setAdditionalInfo}
              placeholder="What information are you looking for?"
              value={additionalInfo}
            />
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              aria-label="Toggle building product manufacturers"
              isSelected={wantBPM}
              onChange={(checked: boolean) => {
                setWantBPM(checked);
              }}
            >
              <div className="type-body2">
                I am interested in contact information for product manufacturers / distributors
              </div>
            </Checkbox>
          </div>
          <div className="flex text-type-muted type-body1">
            Results will be emailed to {userEmail} within 24 hours
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
