import { Print } from '@material-ui/icons';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { YC_SCENARIO_SHARING } from '../../../features';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { Export } from '../../Icons/Export';
import { IconMenuButton, MenuEntry } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../hooks/useSendScenariosAnalytics';

type Props = {
  milestoneID: UUID;
};

const SandboxExportPanel = ({ milestoneID }: Props) => {
  const projectId = getProjectIdFromUrl();
  const sendScenariosAnalytics = useSendScenariosAnalytics();
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);
  const options = [
    {
      callback: () => {
        sendScenariosAnalytics(ScenariosAnalyticsEvents.PRINT_SANDBOX_EXPORT_PANEL, {
          milestoneID,
        });
        window.open(generateSharedPath(JoinProjectRoutes.PRINT_SCENARIOS, { projectId }), '_blank');
      },
      icon: <Print />,
      name: 'Print Scenarios Presentation',
    },
  ];

  const iconMenuOptions: MenuEntry[] = [
    {
      id: 'print-scenarios-presentation',
      label: 'Print Scenarios Presentation',
      onClick: () => {
        sendScenariosAnalytics(ScenariosAnalyticsEvents.PRINT_SANDBOX_EXPORT_PANEL, {
          milestoneID,
        });
        window.open(generateSharedPath(JoinProjectRoutes.PRINT_SCENARIOS, { projectId }), '_blank');
      },
      startAdornment: <Print />,
    },
  ];

  return (
    <div>
      {isScenarioSharingFeature ? (
        <IconMenuButton
          aria-label="Open export options"
          data-cy="scenarios_export"
          entries={iconMenuOptions}
          icon={<Export />}
          type="secondary"
        />
      ) : (
        <IconMenu cy="scenarios_export" icon={<Export />} isBottomOriented options={options} />
      )}
    </div>
  );
};

export default SandboxExportPanel;
