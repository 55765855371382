import { FC } from 'react';

import { Divider, Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../../utilities/currency';
import { EMPTY_COST } from '../../../../utilities/string';
import { PART_DIGIT_LIMIT } from '../../../ProjectComps/ProjectCompsEscalation/EscalationInput';
import {
  getHeaderDisplayData,
  getHeaderDisplaySettings,
  isAverageCompType,
} from '../PrintProjectCompsUtils';

import FilterSpan from './FilterSpan';
import PrintProjectCompStyles from './PrintProjectCompStyles';

type PrintColumnHeaderDataProps = {
  classes: Classes<typeof PrintProjectCompStyles>;
  compData: ProjectComp | AverageComp;
  parentProject?: ProjectProps;
  projectCompsSet: ProjectCompsSet;
  showNotes: boolean;
};

const PrintColumnHeaderData: FC<PrintColumnHeaderDataProps> = ({
  classes,
  compData,
  parentProject,
  projectCompsSet,
  showNotes,
}) => {
  // Get Filter categories mapped to their categorization
  const isAverageComp = isAverageCompType(compData);
  const filterCategories = isAverageComp ? [] : compData.input.viewFilter?.categories;
  const categorizationCategoryMap: { [categorizationID: UUID]: UUID[] } = {};
  filterCategories?.forEach((fc) => {
    categorizationCategoryMap[fc.categorizationID] = [
      ...(categorizationCategoryMap[fc.categorizationID] ?? []),
      fc.id,
    ];
  });
  const filters = Object.entries(categorizationCategoryMap);

  const { showLocation, showTime, showFutureTime, showEscalationSection, units } =
    getHeaderDisplaySettings(projectCompsSet);
  const {
    description,
    escalation,
    location,
    metrics,
    milestone,
    projectDeliveryType,
    projectStatus,
  } = getHeaderDisplayData(compData, parentProject);

  const formatEscalation = (escalation?: string | null, isAutoEscalated?: boolean) => {
    if (!escalation) return undefined;
    const percentString = formatCommas(escalation, undefined, {
      maximumFractionDigits: PART_DIGIT_LIMIT,
    });
    return `${percentString}% ${isAutoEscalated ? '(RSMeans)' : ''}`;
  };

  const getSectionTitle = (title: string) => (
    <div className={classes.columnHeaderData_row} key={title}>
      <Typography className={classes.titleText}>{title}</Typography>
    </div>
  );
  const getDataLine = (label: string, text: string = EMPTY_COST) => (
    <div className={classes.columnHeaderData_row} key={label}>
      <Typography className={classes.bodyTextBold}>{label}:</Typography>
      <Typography
        className={label === 'Description' ? classes.bodyTextDrescription : classes.bodyText}
      >
        {text}
      </Typography>
    </div>
  );
  const getEmptyDataLine = (label: string) => (
    <div className={classes.columnHeaderData_row} key={label} />
  );
  const divider = (
    <Divider
      className={`${classes.columnHeaderData_divider} ${
        isAverageComp ? classes.columnHeaderData_averageCompDivider : ''
      }`}
    />
  );

  const projectInformationSection = (
    <>
      {getDataLine('Location', location)}
      {getDataLine('Project Type', projectDeliveryType)}
      {getDataLine('Project Status', projectStatus)}
      {isAverageComp ? getEmptyDataLine('Milestone') : getDataLine('Milestone', milestone?.name)}
    </>
  );
  const metricsSection = (
    <>
      {divider}
      {getSectionTitle('Metrics')}
      {units.map((unit) => {
        const quantityMagnitude =
          metrics.find((metric) => metric.unitID === unit.id)?.quantityMagnitude || undefined;
        return getDataLine(unit.abbreviationSingular, quantityMagnitude);
      })}
    </>
  );
  const filterSection = (
    <>
      {divider}
      {filters.length ? getSectionTitle('Filtered by') : ''}
      {filters.map(([categorizationID, categoryIDs]) => (
        <FilterSpan
          categorizationID={categorizationID}
          categoryIDs={categoryIDs}
          getDataLine={getDataLine}
          key={categorizationID}
        />
      ))}
    </>
  );

  const escalationSection = showEscalationSection && (
    <>
      {divider}
      {getSectionTitle('Escalation')}
      {showLocation &&
        getDataLine(
          'Location',
          formatEscalation(escalation?.location, escalation?.locationMeta?.isAutoEscalated)
        )}
      {showTime &&
        getDataLine(
          'Time',
          formatEscalation(escalation?.time, escalation?.timeMeta?.isAutoEscalated)
        )}
      {showFutureTime && getDataLine('Future Time', formatEscalation(escalation?.timeFuture))}
    </>
  );

  const descriptionSection = (
    <>
      {divider}
      {getDataLine('Note', description)}
    </>
  );

  return (
    <div className={classes.columnHeaderData_container}>
      {projectInformationSection}
      {metricsSection}
      {filters.length ? filterSection : null}
      {!isAverageComp && escalationSection}
      {showNotes && descriptionSection}
    </div>
  );
};

export default withStyles(PrintProjectCompStyles)(PrintColumnHeaderData);
