import { isUUID } from 'validator';

import {
  COMPANY_DASHBOARD,
  COMPANY_TAB,
  COMPANY_TAB_PATH,
  FORECASTING_TAB_NAME,
  FORECASTING_TAB_PATH,
  ITEMS,
  PROJECTS,
} from '../../../constants';

export const getTitleBarSelectedFromURL = () => {
  const URL2 = window.location.pathname.split('/')[2]; // the selected tab on the project page (if there is one)
  const URL1 = window.location.pathname.split('/')[1]; // the projectId (if there is one) or 'company-dashboard'

  // Company Tabs
  if (URL1 === COMPANY_TAB_PATH) {
    return COMPANY_TAB;
  }
  // Forecasting
  if (URL1 === FORECASTING_TAB_PATH) {
    return FORECASTING_TAB_NAME;
  }
  // Items Search should show up as projects until we have rolled out DD_NEW_NAV.
  if (URL1 === ITEMS) {
    return PROJECTS;
  }

  // Project Tabs
  if (URL2) {
    return URL2;
  }
  if (isUUID(URL1)) {
    // If URL1 is a UUID, then we are on the JoinRoutes.PROJECT route and its
    // only a matter of time before we get rerouted to Dashboard or Items List.
    // I'm returning Items as the selected tab as all users will have access to,
    // but it doesn't really matter what we return as this is only temporary.
    return ITEMS;
  }

  // Insights Tab
  if (URL1) {
    return COMPANY_DASHBOARD;
  }

  return PROJECTS;
};
