import { createStyles } from '@material-ui/core';

import CompanyTabStyles from '../../CompanyTab/CompanyTabStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    ...CompanyTabStyles(theme),
    close: {
      width: 32,
      height: 32,
    },
    content: {
      padding: '16px 24px',
      '@media screen and (min-height: 600px)': {
        overflowY: 'visible',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: '11px',
    },
    messageField: {
      width: '100%',
    },
    paper: {
      overflow: 'visible',
    },
    root: {
      color: theme.palette.primaryGrey,
    },
    subHeader: {
      fontFamily: 'Larsseit',
      fontWeight: 400,
      fontSize: '12px',
      color: theme.palette.disabledGrey,
    },
    title: {
      padding: '12px 12px 8px 12px',
      fontWeight: 500,
      fontSize: 21,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
    },
    userDetails: {
      fontFamily: 'Larsseit',
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.primaryGrey,
      marginLeft: 8,
    },
  });
