import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { DEFAULT_COLLAPSE_HEIGHT } from '../../JoinGrid/utilities/size';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      border: `2px solid ${theme.palette.backgroundGrey}`,
      width: '100%',
      '@media print': {
        marginTop: 0,
      },
    },
    cardHeader: {
      padding: '4px 30px 5px 21px',
    },
    cardHeaderBackground: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    collapse: {
      minHeight: DEFAULT_COLLAPSE_HEIGHT,
    },
    expander: {
      color: theme.palette.joinPrimary,
      marginLeft: -8,
      marginRight: 5,
      '@media print': {
        display: 'none',
      },
    },
    rowContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: DEFAULT_COLLAPSE_HEIGHT,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 28px 8px 18px',
      backgroundColor: theme.palette.backgroundGrey,
      borderTop: theme.border.darkGrey,
    },
  });
