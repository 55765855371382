import { commonUserNotificationSettingFields, gql } from '../../../api/graphqlFragments';

export const getProjectThumbnailQuery = gql`
  query getProjectThumbnail($projectID: UUID!) {
    projectThumbnail(projectID: $projectID) {
      id
      name
      location
      blobUrl @client
    }
  }
`;

export const updateUserNotificationSettingMutation = gql`
  mutation updateUserNotificationSetting(
    $projectID: UUID!
    $isGlobalSubscribed: Boolean
    $comments: CommentNotificationSetting
    $assignments: Boolean
    $newItems: Boolean
    $mentions: Boolean
    $shareScenarios: Boolean
  ) {
    updateUserNotificationSetting(
      projectID: $projectID
      userNotificationSetting: {
        isGlobalSubscribed: $isGlobalSubscribed
        comments: $comments
        assignments: $assignments
        newItems: $newItems
        mentions: $mentions
        shareScenarios: $shareScenarios
      }
    ) {
      ...commonUserNotificationSettingFields
    }
  }
  ${commonUserNotificationSettingFields}
`;

export const updateUserInAppNotificationSettingMutation = gql`
  mutation updateUserInAppNotificationSetting(
    $projectID: UUID!
    $isGlobalSubscribed: Boolean
    $comments: CommentNotificationSetting
    $assignments: Boolean
    $newItems: Boolean
    $mentions: Boolean
    $shareScenarios: Boolean
  ) {
    updateUserInAppNotificationSetting(
      projectID: $projectID
      userNotificationSetting: {
        isGlobalSubscribed: $isGlobalSubscribed
        comments: $comments
        assignments: $assignments
        newItems: $newItems
        mentions: $mentions
        shareScenarios: $shareScenarios
      }
    ) {
      ...commonUserNotificationSettingFields
    }
  }
  ${commonUserNotificationSettingFields}
`;
