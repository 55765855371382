import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { TrendingDown, TrendingUp } from '@material-ui/icons';

import { GetProjectMovementsQuery } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  COST_CHANGE,
  PERCENT_CHANGE,
  PROJECT,
  PROJECT_DECREASES,
  PROJECT_INCREASES,
  ProjectMap,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectMovementRow from './ExecutiveDashboardProjectMovementRow';
import ExecutiveDashboardProjectsMovementStyles from './ExecutiveDashboardProjectsMovementStyles';

type Movement =
  | GetProjectMovementsQuery['projectMovements']['increases'][number]
  | GetProjectMovementsQuery['projectMovements']['decreases'][number]
  | null;

type ExecutiveDashboardProjectMovementProps = {
  classes: Classes<typeof ExecutiveDashboardProjectsMovementStyles>;
  count?: number;
  projectMovements: GetProjectMovementsQuery['projectMovements'] | null;
  projectMap?: ProjectMap;
};

const ExecutiveDashboardProjectMovement: FC<ExecutiveDashboardProjectMovementProps> = ({
  classes,
  count = 3,
  projectMovements,
  projectMap,
}) => {
  const { increases, decreases } =
    projectMovements ||
    ({ increases: [], decreases: [] } as GetProjectMovementsQuery['projectMovements']);
  const increasesFiltered = Array<Movement>(count).fill(null);
  const decreasesFiltered = Array<Movement>(count).fill(null);
  increasesFiltered.splice(0, increases.length, ...increases.slice(0, count));
  decreasesFiltered.splice(0, decreases.length, ...decreases.slice(-count).reverse());

  return (
    <div className={classes.flexContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th aria-label="icon-header" />
            <th className={classes.nameHeader}>
              <Typography>{PROJECT}</Typography>
            </th>
            <th className={classes.dataHeader}>
              <Typography>{COST_CHANGE}</Typography>
            </th>
            <th className={classes.dataHeader}>
              <Typography>{PERCENT_CHANGE}</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} className={classes.sectionCell}>
              <Typography>{PROJECT_INCREASES}</Typography>
            </td>
          </tr>
          {increasesFiltered.map((row: Movement, index: number) => (
            <ExecutiveDashboardProjectMovementRow
              key={`${row ? row.projectID : index}`}
              icon={<TrendingUp />}
              row={row}
              projectMap={projectMap}
            />
          ))}
          <tr>
            <td colSpan={4} className={classes.sectionCell}>
              <Typography>{PROJECT_DECREASES}</Typography>
            </td>
          </tr>
          {decreasesFiltered.map((row: Movement, index: number) => (
            <ExecutiveDashboardProjectMovementRow
              key={`${row ? row.projectID : index}`}
              icon={<TrendingDown />}
              row={row}
              projectMap={projectMap}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(ExecutiveDashboardProjectsMovementStyles)(
  ExecutiveDashboardProjectMovement
);
