import { FC } from 'react';

type PrintPageHeaderProps = {
  banner: ActiveProjectBanner | null;
  bannersNum: number;
  images: string[];
  milestoneName?: string;
  projectName: string;
  reportName: string;
};

export const PrintPageHeader: FC<PrintPageHeaderProps> = ({
  banner,
  bannersNum,
  images,
  milestoneName = '',
  projectName,
  reportName,
}) => {
  const bannerComponent = (
    <>
      {bannersNum === 0 &&
        !banner &&
        images
          .filter((i: string) => !!i && i !== 'null')
          .map((i: string) => (
            <div key={i} className="print-header-organization-picture">
              <img className="print-header-organization-picture-img" src={i} alt="" />
            </div>
          ))}
      {banner && banner.asset && banner.asset.blobUrl && (
        <div key={banner.asset.name} className="print-header-organization-picture">
          <img
            className="print-header-organization-picture-img"
            src={banner.asset.blobUrl}
            alt=""
          />
        </div>
      )}
      {!banner && !images && <div />}
    </>
  );

  return (
    <>
      <div className="print-report-header">
        <div className="print-header-title-container">
          <div>{projectName}</div>
          <div className="print-report-header-milestone">
            {milestoneName}
            {milestoneName && ': '}
            {reportName}
          </div>
        </div>
        <div className="print-right-align print-header-organization-pictures">
          {bannerComponent}
        </div>
      </div>
      <hr className="print-main-divider" />
    </>
  );
};
