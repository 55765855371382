import { FC, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { SelectVariant } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import JoinSlide from '../../shared-widgets/JoinSlide';

import styles from './SelectCategoryStyles';
import { generateSpacer, isHighlightedCategory } from './SelectCategoryUtils';

interface SelectCategoryOptionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  category: any;
  canViewSubcategories: boolean;
  classes: Classes<typeof styles>;
  handleClick: (target: 'category' | 'parent' | 'subCategories' | 'bulkToggle') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleHover: (category: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  highlightedCategory: any;
  index: number;
  isSelected: boolean;
  name: string;
  number: string;
  selectVariant: SelectVariant;
  slideDirection: 'left' | 'right';
}

const SelectCategoryOption: FC<SelectCategoryOptionProps> = ({
  category,
  canViewSubcategories,
  classes,
  handleClick,
  handleHover,
  highlightedCategory,
  index,
  isSelected,
  name,
  number,
  selectVariant,
  slideDirection,
}) => {
  // STATE
  const [isHoveringArrowButton, setIsHoveringArrowButton] = useState(false);

  // DISPLAY LOGIC
  // highlighted = hover, tab-ing through
  // selected = is checked
  const isMultiSelect = selectVariant === SelectVariant.MULTI;
  const { hasSubCategories } = category;
  const isHighlighted = isHighlightedCategory(category, highlightedCategory);

  // UI GENERATORS
  const highlightedClass = isHighlighted && !isHoveringArrowButton && classes.categoryHighlighted;
  const spacer = generateSpacer(name, number);
  const title = `${number}${spacer}${name}`;
  return (
    <JoinSlide direction={slideDirection}>
      <li
        aria-selected={isSelected}
        className={` ${highlightedClass} ${classes.categoryOption}`}
        id={`result-item-${index}`}
        key={category.id}
        role="option"
        value={category}
      >
        <span
          aria-selected={isSelected}
          className={`${classes.categoryOptionTitle}`}
          data-cy={name.replace(/\s+/g, '-').toLowerCase()}
          title={title}
          onClick={() => {
            handleClick('category');
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onKeyDown={(event: any) => {
            const { key } = event;
            if (key === 'Enter') {
              handleClick('category');
            }
          }}
          onMouseEnter={() => {
            handleHover(category);
          }}
          role="option"
          tabIndex={0}
        >
          {isMultiSelect && (
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              checked={isSelected}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          <span>
            <span>{number}</span>
            {spacer}
            {name}
          </span>
        </span>

        {hasSubCategories && canViewSubcategories && (
          <span
            title={`view ${title} subcategories`}
            onMouseEnter={() => {
              handleHover(category);
              setIsHoveringArrowButton(true);
            }}
            onMouseLeave={() => {
              setIsHoveringArrowButton(false);
            }}
          >
            <IconButton classes={{ root: classes.iconButton }}>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (canViewSubcategories) {
                    handleClick('subCategories');
                  }
                }}
              />
            </IconButton>
          </span>
        )}
      </li>
    </JoinSlide>
  );
};

export default withStyles(styles)(SelectCategoryOption);
