import { FC, ReactNode } from 'react';
import * as React from 'react';

import { DialogContent, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsStyles from '../DialogsStyles';

type Props = {
  body: ReactNode;
  classes: Classes<typeof DialogsStyles>;
  onCancel?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
};

const DialogsConfirmContent: FC<Props> = ({ body, classes, onCancel, onClose, title }) => {
  return (
    <>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">{title}</Typography>
        </div>
        {onClose ? (
          <IconButton
            title="Close dialog"
            className={classes.close}
            onClick={(event) => {
              if (onCancel) {
                onCancel(event);
                return;
              }
              if (onClose) onClose(event);
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        {typeof body === 'string' ? <Typography variant="body1">{body}</Typography> : body}
      </DialogContent>
    </>
  );
};

export default withStyles(DialogsStyles)(DialogsConfirmContent);
