import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import { ChartLegendOrientation } from '../../../api/gqlEnums';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import ChartsLineGraph from '../../Charts/ChartsD3/ChartsLineGraph/ChartsLineGraph';
import ChartsLegend from '../../Charts/ChartsLegend/ChartsLegend';
import ChartsLegendElement from '../../Charts/ChartsLegend/ChartsLegendElement';
import { getLegendElementColorFunction } from '../../Charts/ChartsLegend/ChartsLegendUtils';
import {
  NA,
  ProjectActiveUsers,
  ProjectMap,
  formatProjectActiveUsersLegendData,
  formatProjectActiveUsersLineChartData,
  projectActiveUsersChartSize,
  projectActiveUsersColors,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectActiveUsersStyles from './ExecutiveDashboardProjectActiveUsersStyles';

type ExecutiveDashboardProjectActiveUsersProps = {
  classes: Classes<typeof ExecutiveDashboardProjectActiveUsersStyles>;
  projectActiveUsers: ProjectActiveUsers[];
  projectMap?: ProjectMap;
};

const ExecutiveDashboardProjectActiveUsers: FC<ExecutiveDashboardProjectActiveUsersProps> = ({
  classes,
  projectActiveUsers,
  projectMap,
}) => {
  const [selected, setSelected] = useState<UUID | undefined>(undefined);
  const { data, mapData } = formatProjectActiveUsersLineChartData(projectActiveUsers, projectMap);

  const projects = formatProjectActiveUsersLegendData(projectActiveUsers, mapData, projectMap);

  const getLegendElementColor = getLegendElementColorFunction(undefined, projectActiveUsersColors);

  const pointerEntered = (projectID: UUID) => {
    setSelected(projectID);
  };

  const pointerLeave = () => {
    setSelected(undefined);
  };
  return (
    <>
      {projectActiveUsers && projectActiveUsers.length ? (
        <>
          <ChartsLineGraph
            chartSize={projectActiveUsersChartSize}
            colors={projectActiveUsersColors}
            data={data}
            formatValue={formatCommas}
            mapData={mapData}
            pointerEntered={pointerEntered}
            pointerLeave={pointerLeave}
            selected={selected}
          />
          <ChartsLegend
            getLegendElementColor={getLegendElementColor}
            legendElements={projects}
            variant={ChartLegendOrientation.WRAP}
            pointerEntered={pointerEntered}
            pointerLeave={pointerLeave}
            selected={selected}
          />
        </>
      ) : (
        <>
          <div className={classes.placeholder}>
            <Typography className={classes.placeholderText}>No Data Available</Typography>
          </div>
          <div className={classes.placeholderLegend}>
            <ChartsLegendElement
              color={theme.palette.chartGrey}
              element={{ text: NA }}
              pointerEntered={undefined}
              pointerLeave={undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(ExecutiveDashboardProjectActiveUsersStyles)(
  ExecutiveDashboardProjectActiveUsers
);
