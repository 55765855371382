import { useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import useProjectRoleQuery from '../../../../hooks/useProjectRoleQuery';
import useRolesQuery from '../../../../hooks/useRolesQuery';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import PermissionsRoleNew from '../PermissionsRoleNew';

import RolesVerticalMenu from './RolesVerticalMenu';

const RolesListData = () => {
  const projectId = getProjectIdFromUrl();
  const rolesQueryResult = useRolesQuery(projectId);
  const roles =
    rolesQueryResult.data?.projectRoles ?? rolesQueryResult.previousData?.projectRoles ?? [];

  const [selectedRoleID, onSelectRole] = useState(roles[0]?.id);

  const roleQueryResult = useProjectRoleQuery(projectId, selectedRoleID);
  const roleWithPermissionData =
    roleQueryResult.data?.projectRole ?? roleQueryResult.previousData?.projectRole;

  return (
    <RolesVerticalMenu roles={roles} onSelectRole={onSelectRole} selectedRoleID={selectedRoleID}>
      {roleWithPermissionData ? <PermissionsRoleNew roleID={selectedRoleID} /> : <LinearProgress />}
    </RolesVerticalMenu>
  );
};

export default RolesListData;
