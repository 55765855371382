import { useEffect } from 'react';

import useLocalStorage from '../../../hooks/useLocalStorage';

export default function useGetExperimentArm() {
  const [experimentArm, setExperimentArm] = useLocalStorage<string | null>(
    'ITEM_ASSIST_EXP_ARM',
    null
  );
  useEffect(() => {
    if (experimentArm === null) {
      const r = Math.random();
      let arm = null;
      if (r < 0.5) {
        // 50% experiment.
        arm = 'E';
      } else {
        // 50% control.
        arm = 'C';
      }
      setExperimentArm(arm);
    }
  }, [experimentArm, setExperimentArm]);
  return experimentArm;
}
