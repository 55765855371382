import { FC, SyntheticEvent, useMemo, useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircle from '@material-ui/icons/AddCircle';
import Info from '@material-ui/icons/Info';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import joinAPI from '../../api/joinAPI';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { useUploadImage } from '../assets/hooks/AssetsMutationHook';
import useAssetBlobUrlQuery from '../assets/hooks/useAssetBlobUrlQuery';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import CompanyThumbnailStyles from './CompanyThumbnailStyles';

const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const showFileUpload = (fileUpload: any) => {
  if (fileUpload) {
    fileUpload.click();
  }
};

type CompanyThumbnailProps = {
  classes: Classes<typeof CompanyThumbnailStyles>;
  company: Company;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  updateCompany: (company: CompanyInput, onSuccess?: any, onFailure?: any) => Promise<void>;
};

const CompanyThumbnail: FC<CompanyThumbnailProps> = ({ classes, company, updateCompany }) => {
  let fileUpload = useRef(null);

  const { url: thumbnail, loading: assetLoading } = useAssetBlobUrlQuery(company?.assetID);

  const [uploading, setUploading] = useState(false);
  const loading = uploading || assetLoading;

  const sendAnalytics = useAnalyticsEventHook();

  const uploadImage = useUploadImage();
  const addFile = (file: File) => {
    setUploading(true);
    const onSuccess = async (a: Asset) => {
      const assetWithBlobUrl = a;
      assetWithBlobUrl.blobUrl = await joinAPI.requestAssetBlobURL(a.location, a.name);
      setUploading(false);
      updateCompany({ companyID: company.id, assetID: a.id });
      sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_LOGO_CHANGE));
    };
    const onFailure = () => {
      setUploading(false);
    };
    uploadImage(file, null, onSuccess, onFailure);
  };

  const addFiles = (files: FileList) => addFile(files[0]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;
    addFiles(files);
  };

  const image = useMemo(() => {
    if (thumbnail && !loading) {
      return <img alt="company thumbnail" className={classes.img} src={thumbnail} />;
    }
    if (!thumbnail && !loading) {
      return <div className={classes.uploadText}> No Company Logo </div>;
    }
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      );
    }
    return <AddCircle className={classes.hoverThumbnail} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [thumbnail, loading]);

  const thumbnailTooltip =
    'Images can be PNG, JPG, GIF, BMP, or SVG. If you need help, contact support below.';

  return (
    <div
      className={classes.hoverThumbnail}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
    >
      <div>
        <IconButton
          className={`${classes.thumbButton} ${classes.fullButton}`}
          aria-label="Thumbnail"
          aria-owns="thumbnail"
          aria-haspopup="true"
          onClick={() => showFileUpload(fileUpload)}
        >
          {image}
        </IconButton>
        <input
          type="file"
          accept=".png, .jpg, .gif, .svg"
          style={{ display: 'none' }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          ref={(input: any) => {
            fileUpload = input;
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onChange={(evt: any) => {
            addFiles(evt.target && evt.target.files);
          }}
        />
        <Typography className={classes.emptyMessage}>
          Drop image or click to choose file
          <NormalTooltip title={thumbnailTooltip}>
            <Info className={classes.icon} />
          </NormalTooltip>
          <br />
          Need help? &nbsp;
          <a className={classes.link} href="mailto:support@join.build" target="_top">
            Contact Join Support
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(CompanyThumbnailStyles)(CompanyThumbnail);
