import { createStyles } from '@material-ui/core';

import { SIDEBAR_MINWIDTH } from '../../constants';
import { ImageDimension } from '../../generated/graphql';
import { KomodoTheme } from '../../theme/komodo-mui-theme';

const PROJECT_IMAGE_DIMENSION = Number.parseInt(ImageDimension._92.slice(1), 10);

const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      color: theme.palette.primaryGrey,
      padding: 1,
    },
    costSummaryContainer: {
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      border: theme.border.invisible,
      outline: theme.border.line,
      cursor: 'pointer',
    },
    clickable: {
      cursor: 'pointer',
    },
    divider: {
      marginTop: 8,
      marginBottom: 8,
    },
    drawer: {
      backgroundColor: theme.palette.backgroundGrey,
      padding: 0,
      overflowX: 'hidden',
    },
    fixSidebarPosition: {
      position: 'fixed',
      width: SIDEBAR_MINWIDTH,
    },
    milestoneButton: {
      minHeight: 32,
      paddingLeft: 4,
      marginLeft: 4,
    },
    milestoneText: {
      color: theme.palette.joinPrimary,
    },
    root: {
      color: theme.palette.primaryGrey,
      wordWrap: 'break-word',
      width: parseInt(SIDEBAR_MINWIDTH, 10) + 2 * 8,
      padding: 8,
      '@media print': {
        padding: '8px 24px 8px 0px',
      },
    },
    navIcon: {
      width: theme.iconSizes.medium,
      height: theme.iconSizes.medium,
      margin: '0px',
      color: 'inherit',
      '@media print': {
        display: 'none',
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
    link: {
      color: 'inherit',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontWeight: 300,
      padding: '4px 16px',
      textTransform: 'capitalize',
      '&:hover': {
        background: 'transparent',
      },
    },
    selectedMenu: {
      fontWeight: 'bold',
    },
    avatar: {
      width: PROJECT_IMAGE_DIMENSION,
      height: PROJECT_IMAGE_DIMENSION,
    },
    loading: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
      marginBottom: 16,
    },
    projectName: {
      wordBreak: 'break-word',
      paddingLeft: 8,
      fontSize: '1.3125rem',
    },
    projectSummary: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    thumbnail: {
      height: PROJECT_IMAGE_DIMENSION,
    },
    empty: {
      textAlign: 'center',
      margin: 24,
    },
    newMilestoneCTA: {
      color: theme.palette.primaryBlue,
      cursor: 'pointer',
    },
  });

export default styles;
