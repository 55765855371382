import { FC, MouseEvent, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Popover } from '@material-ui/core';

import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  inAppNotificationsPanelAnchorVar,
  inAppNotificationsProjectIdVar,
} from '../../../api/apollo/reactiveVars';
import { REFETCH_IN_APP_NOTIFICATIONS_BADGE } from '../../../api/refetchSets';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useRefetch } from '../../../hooks/useRefetch';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import InAppNotificationsIconData from '../InAppNotificationsIcon/InAppNotificationsIconData';
import InAppNotificationsPanelData from '../InAppNotificationsPanel/InAppNotificationsPanelData';

import styles from './InAppNotificationsIconWithPanelStyles';

type InAppNotificationsIconWithPanelProps = {
  classes: Classes<typeof styles>;
};

const InAppNotificationsIconWithPanel: FC<InAppNotificationsIconWithPanelProps> = ({ classes }) => {
  const refetchBadge = useRefetch(REFETCH_IN_APP_NOTIFICATIONS_BADGE);
  const sendAnalytics = useAnalyticsEventHook();
  const projectId = getProjectIdFromUrl();

  const { isViewOnly } = usePermissions();

  useEffect(() => {
    inAppNotificationsProjectIdVar(projectId || null);
  }, [projectId]);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    inAppNotificationsPanelAnchorVar(event.currentTarget);
    refetchBadge();
    // Analytics
    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_OPEN_CLICK,
        {
          projectId,
        }
      )
    );
  };

  const handleClose = () => {
    inAppNotificationsPanelAnchorVar(null);
  };

  const notificationsPanelAnchor = useReactiveVar(inAppNotificationsPanelAnchorVar);

  return (
    <>
      {!isViewOnly && (
        <InAppNotificationsIconData isOpen={!!notificationsPanelAnchor} onClick={handleClick} />
      )}
      <Popover
        anchorEl={notificationsPanelAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!notificationsPanelAnchor}
        onClose={() => handleClose()}
      >
        <InAppNotificationsPanelData />
      </Popover>
    </>
  );
};

export default withStyles(styles)(InAppNotificationsIconWithPanel);
