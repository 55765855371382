import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';

import { JoinProjectRoutes } from '../../api/gqlEnums';
import { withStyles } from '../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import { styles } from './MilestonesStyles';

type MilestoneReportLinkProps = {
  classes: Classes<typeof styles>;
  linkCopy?: string;
  milestoneId: UUID;
  search?: string;
};

const MilestoneReportLink: FC<MilestoneReportLinkProps> = ({
  classes,
  linkCopy = 'Milestone Report',
  milestoneId,
  search = '',
}) => {
  const projectId = getProjectIdFromUrl(window.location.pathname);
  const path = generateSharedPath(JoinProjectRoutes.MSR, { projectId, milestoneId, search });

  return (
    <Link data-cy="milestone-report-link" to={path} tabIndex={-1}>
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`${classes.containerMilestoneReport} ${classes.alignRight} ${classes.link}`}
      >
        <BarChartIcon className={classes.iconBarChart} />
        <NormalTooltip title="Go to Milestone Summary Report">
          <Typography>{linkCopy}</Typography>
        </NormalTooltip>
      </div>
    </Link>
  );
};

export default withStyles(styles)(MilestoneReportLink);
