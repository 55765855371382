import { FC, useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { defaultPreviewSettings, setPreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { JoinCompanyRoutes } from '../../api/gqlEnums';
import { DRAFT } from '../../constants';
import { ProjectTemplateQuery } from '../../generated/graphql';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import theme, { withStyles } from '../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';
import {
  useProjectTemplateQuery,
  useSetProjectTemplatePublicationMutation,
  useUpdateProjectTemplateNameMutation,
} from '../CompanyTab/Standards/ProjectTemplates/hooks';
import CTAIconButton from '../shared-widgets/CTAIconButton';

import styles from './FrameTitleBar/FrameTitlePreviewBarStyles';

type EditTemplateBarProps = {
  classes: Classes<typeof styles>;
  showBottomBorder: boolean;
};

const EditTemplateBar: FC<EditTemplateBarProps> = ({ classes, showBottomBorder }) => {
  const navigate = useNavigate();
  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();

  const projectTemplate = useProjectTemplateQuery(projectID)?.data?.projectTemplate;
  const [projectTemplateChanges, setProjectTemplateChanges] = useState<
    Partial<ProjectTemplateQuery['projectTemplate']> | undefined
  >(projectTemplate);
  const updateName = useUpdateProjectTemplateNameMutation();
  const setProjectTemplatePublication = useSetProjectTemplatePublicationMutation(projectID);

  if (!projectTemplate) {
    return null;
  }

  const isUnpublished = projectTemplate?.isDraft;

  const draftIcon = <span className={classes.draftIcon}>{DRAFT}</span>;

  return (
    <div className={`${classes.bar} ${showBottomBorder ? '' : classes.borderBottomNone}`}>
      <Typography className={classes.mode}>EDIT TEMPLATE</Typography>
      <div className={classes.paddingLeft}>
        <TextField
          data-cy="template-name-input"
          fullWidth
          className={classes.textField}
          InputProps={{
            disableUnderline: true,
            endAdornment: isUnpublished ? draftIcon : null,
          }}
          value={projectTemplateChanges?.name ?? projectTemplate.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProjectTemplateChanges({
              ...(projectTemplateChanges || {}),
              name: event.target.value,
            });
          }}
          onBlur={() => {
            if (projectTemplateChanges?.name === '') {
              setProjectTemplateChanges({ ...projectTemplateChanges, name: projectTemplate.name });
            } else if (projectTemplateChanges?.name !== projectTemplate?.name) {
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_NAME)
              );
              if (projectTemplateChanges?.name) {
                updateName(projectTemplate?.id, projectTemplateChanges.name);
              }
            }
          }}
        />
      </div>
      <div className={classes.addAdminButton}>
        {isUnpublished && (
          <CTAIconButton
            buttonText="Publish Draft"
            color={theme.palette.pending}
            dataCy="template-publish-button"
            variant="outlined"
            icon={<Check />}
            isSmaller
            colorVariant
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(
                  CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_PUBLISH_DRAFT
                )
              );
              setProjectTemplatePublication(true);
            }}
          />
        )}
        <CTAIconButton
          buttonText="Done"
          color={theme.palette.pending}
          dataCy="template-done-button"
          isSmaller
          colorVariant
          onClick={() => {
            sendAnalytics(
              companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_DONE)
            );
            setPreviewSettingsVar(defaultPreviewSettings);
            navigate(generateSharedPath(JoinCompanyRoutes.COMPANY_STANDARDS, {}));
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(EditTemplateBar);
