import { ComponentProps, useMemo } from 'react';

import { UserStatus } from '../../api/gqlEnumsBe';
import useCollaboratorsQuery from '../../hooks/useCollaboratorsQuery';

import AssigneeSelect from './AssigneeSelect';

type AssigneeSelectDataProps = {
  includeUnassigned?: ComponentProps<typeof AssigneeSelect>['includeUnassigned'];
  isDisabled?: boolean;
  label?: ComponentProps<typeof AssigneeSelect>['label'];
  onChange: ComponentProps<typeof AssigneeSelect>['onChange'];
  projectID: UUID;
  value?: ComponentProps<typeof AssigneeSelect>['value'];
};

const AssigneeSelectData = ({ projectID, ...assigneeSelectProps }: AssigneeSelectDataProps) => {
  const { data } = useCollaboratorsQuery(projectID);
  const users = useMemo(
    () =>
      data?.collaborators
        .filter((collaborator) => collaborator.user.status !== UserStatus.DEACTIVATED)
        .map((collaborator) => collaborator.user) ?? [],
    [data?.collaborators]
  );

  return <AssigneeSelect availableUsers={users} {...assigneeSelectProps} />;
};

export default AssigneeSelectData;
