import { FC } from 'react';

import { SourceLinkedProject, SourceSystem } from '../../../../generated/graphql';
import { useRemoveUserSource } from '../../../DocumentMagic/hooks';

import IntegrationsCreateChangeEventModal from './IntegrationsCreateChangeEventModal';
import IntegrationsLinkAccountsModal from './IntegrationsLinkAccountsModal';
import { ItemInfo } from './IntegrationsUtils';

export enum LinkingAccountState {
  INITIAL_LINK,
  EDITING_LINK,
}

type IntegrationsModalManagerProps = {
  linkingAccountState: LinkingAccountState | null;
  setLinkingAccountState: (value: LinkingAccountState | null) => void;
  setSubmitted: (value: boolean) => void;
  isCreatingChangeEvent: boolean;
  setIsCreatingChangeEvent: (value: boolean) => void;
  loggedInProcoreSource?: {
    sourceSystem: SourceSystem;
    id: string;
    email?: string | null;
  };
  projectID: UUID;
  itemInfo: ItemInfo;
  linkedProjectQueryDataLoading: boolean;
  refetchLinkedProjectQuery: () => void;
  linkedProject?: SourceLinkedProject;
};

const IntegrationsModalManager: FC<IntegrationsModalManagerProps> = ({
  linkingAccountState,
  setLinkingAccountState,
  isCreatingChangeEvent,
  setIsCreatingChangeEvent,
  setSubmitted,
  loggedInProcoreSource,
  projectID,
  itemInfo,
  linkedProjectQueryDataLoading,
  refetchLinkedProjectQuery,
  linkedProject,
}) => {
  const [removeUserSource] = useRemoveUserSource();
  const unlinkProcoreUser = () => {
    if (loggedInProcoreSource) removeUserSource(loggedInProcoreSource?.id);
  };
  const { sourceCompanyID = undefined, sourceProjectID = undefined } = linkedProject ?? {};

  const isLinkAccountModalOpen =
    linkingAccountState === LinkingAccountState.EDITING_LINK ||
    (linkingAccountState === LinkingAccountState.INITIAL_LINK &&
      // if this is the initial login, we'll show the modal when you're still logging in to Procore or haven't selected a project yet
      !(loggedInProcoreSource && linkedProject));

  return (
    <>
      {sourceProjectID && loggedInProcoreSource?.id && sourceCompanyID && (
        <IntegrationsCreateChangeEventModal
          isCreatingChangeEvent={isCreatingChangeEvent}
          setIsCreatingChangeEvent={setIsCreatingChangeEvent}
          setSubmitted={setSubmitted}
          itemInfo={itemInfo}
          loggedInProcoreSourceID={loggedInProcoreSource.id}
          savedSourceProjectID={sourceProjectID}
          savedSourceCompanyID={sourceCompanyID}
        />
      )}

      {!linkedProjectQueryDataLoading && (
        <IntegrationsLinkAccountsModal
          isLinkingAccounts={isLinkAccountModalOpen}
          closeLinkAccountsModal={() => setLinkingAccountState(null)}
          loggedInProcoreSource={loggedInProcoreSource}
          unlinkProcoreUser={unlinkProcoreUser}
          savedSourceCompanyID={linkedProject?.sourceCompanyID ?? undefined}
          savedSourceProjectID={linkedProject?.sourceProjectID}
          refetchLinkedProjectQuery={refetchLinkedProjectQuery}
          joinProjectID={projectID}
        />
      )}
    </>
  );
};

export default IntegrationsModalManager;
