import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { useApolloClient } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  Typography,
} from '@material-ui/core';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../api/gqlEnums';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useProjectCategorizationsQuery } from '../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ManageCategorizationsDialog from '../../table/categorization/ManageCategorizationsDialog';
import CategorizationsListDialogs from '../CategorizationsListDialogs/CategorizationsListDialogs';
import CategorizationsListItem from '../CategorizationsListItem/CategorizationsListItem';
import { useReorderCategorization } from '../hooks';

import styles from './CategorizationsListStyles';

type CategorizationsListProps = {
  classes: Classes<typeof styles>;
  canCreate: boolean;
  canEdit: boolean;
};

const CategorizationsList: FC<CategorizationsListProps> = ({ canCreate, canEdit, classes }) => {
  const projectID = getProjectIdFromUrl();
  const apolloClient = useApolloClient();

  const sendAnalytics = useSendAnalyticsEventHook();

  const { loading, error, data, refetch } = useProjectCategorizationsQuery(projectID, true);
  const projectCategorizations = data?.projectCategorizations ?? [];

  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);

  const [dialogsManageCategorizationsOpen, setDialogsManageCategorizationsOpen] =
    useState<boolean>(false);

  const reorderCategorization = useReorderCategorization();
  const [draggedCategorization, setDraggedCategorization] = useState<ProjectCategorization | null>(
    null
  );
  const [startIndex, setStartIndex] = useState<number | null>(null);
  const [reorderIndex, setReorderIndex] = useState<number | null>(null);
  const [targetOrdering, setTargetOrdering] = useState<number | null>(null);
  const listRef = useRef(null);

  const handleDragEnd = () => {
    if (
      projectID &&
      startIndex !== reorderIndex &&
      draggedCategorization?.ordering &&
      targetOrdering &&
      draggedCategorization?.ordering !== targetOrdering
    ) {
      reorderCategorization(
        projectID,
        draggedCategorization.categorization.id,
        targetOrdering,
        () => {
          refetch();
        }
      );
    }
    setDraggedCategorization(null);
    setStartIndex(null);
    setReorderIndex(null);
    setTargetOrdering(null);
  };

  useClickAway(listRef, handleDragEnd, ['mouseup']);

  if (loading) return null;
  const categorizationButton = (
    <Button
      className={classes.button}
      data-cy="button-manageCategorizations"
      onClick={() => {
        setDialogsManageCategorizationsOpen(true);
        sendAnalytics(categorizationEvent(CategorizationEvent.MANAGE_CTA, { projectID }));
      }}
    >
      Manage Categorizations
    </Button>
  );

  const content =
    error || !data ? (
      <CardContent>Error Loading Categorizations</CardContent>
    ) : (
      <List className={draggedCategorization ? classes.dragging : ''}>
        {projectCategorizations.map(({ categorization, disabled, ordering }, index) => (
          <CategorizationsListItem
            canEdit={canEdit}
            categorization={categorization}
            disabled={disabled}
            dragging={!!draggedCategorization}
            key={categorization.id}
            projectId={projectID}
            onDragStart={() => {
              setDraggedCategorization({ projectID, categorization, disabled, ordering });
              setStartIndex(index);
              setReorderIndex(index);
              setTargetOrdering(null);
              sendAnalytics(categorizationEvent(CategorizationEvent.REORDER));
            }}
            onDragEnter={() => {
              if (
                draggedCategorization &&
                reorderIndex !== null &&
                index !== reorderIndex &&
                ordering
              ) {
                apolloClient.cache.modify({
                  fields: {
                    projectCategorizations: (existing) => {
                      const copy = existing.slice();
                      [copy[reorderIndex], copy[index]] = [copy[index], copy[reorderIndex]];
                      return copy;
                    },
                  },
                });
                setReorderIndex(index);
                setTargetOrdering(ordering);
              }
            }}
            onDragEnd={handleDragEnd}
          />
        ))}
      </List>
    );

  const noCategorizations = !data?.projectCategorizations?.length;

  return (
    <div id="Categorizations">
      <Card elevation={0} className={classes.card} square>
        <CardHeader
          classes={{
            action: classes.action,
          }}
          title="Categorizations"
          subheader="Available throughout your Join project to group, sort, and filter costs and items.  Order the categorizations the way you want to see them."
          action={canCreate && categorizationButton}
        >
          Categorizations
        </CardHeader>
        <div ref={listRef}>{content}</div>
        {noCategorizations && (
          <>
            <Divider />
            <CardContent>
              <Typography variant="body1" className={classes.greyText}>
                This project does not have categorizations.
              </Typography>
              {canCreate && (
                <Typography variant="body1" className={classes.greyText}>
                  Click the{' '}
                  <Typography
                    onClick={() => {
                      setDialogsManageCategorizationsOpen(true);
                      sendAnalytics(
                        categorizationEvent(CategorizationEvent.EMPTY_MANAGE_CTA, { projectID })
                      );
                    }}
                    className={`${classes.greyText} ${classes.link}`}
                  >
                    Manage Categorizations
                  </Typography>
                  button to add or create new categorizations.
                </Typography>
              )}
            </CardContent>
          </>
        )}
      </Card>

      {categorizationDialogType !== CategorizationDialogType.NONE && (
        <CategorizationsListDialogs
          projectId={projectID}
          setType={setCategorizationDialogType}
          type={categorizationDialogType}
        />
      )}
      <ManageCategorizationsDialog
        onCreateNew={() => {
          setDialogsManageCategorizationsOpen(false);
          setCategorizationDialogType(CategorizationDialogType.NEW);
        }}
        onClose={() => {
          setDialogsManageCategorizationsOpen(false);
        }}
        open={dialogsManageCategorizationsOpen}
      />
    </div>
  );
};

export default withStyles(styles)(CategorizationsList);
