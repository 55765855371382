import { FC, ReactElement, useRef } from 'react';

import useUpdate from './hooks/useUpdate';
import { renderCell } from './renderers';
import { ROW_FOOTER_CLASS, startCellWidth } from './style/styleConstants';
import { Footer, GridController, GridType } from './types';

const HORIZONTAL_MARGIN = 3;
const LAST_BUTTON_MARGIN = 1;

type GridFooterProps = {
  children?: ReactElement;
  grid: GridController;
  footer: Footer;
};

const GridFooter: FC<GridFooterProps> = ({ children, grid, footer }) => {
  const isInheritedGrid = grid.type === GridType.INHERITED_GRID;
  const hasCheckBoxes = !grid.linesReadOnly;
  const startCellWidthValue = startCellWidth(grid.numRows());
  let visibleWidth = grid.visibleWidth();
  const overallWidth = grid.overallWidth();
  const noHorizontalScrolling = visibleWidth === overallWidth;
  if (noHorizontalScrolling) {
    visibleWidth -= HORIZONTAL_MARGIN;
    visibleWidth -= LAST_BUTTON_MARGIN;
  }
  const {
    name,
    prefix,
    data: {
      data: { value, error },
    },
  } = footer;
  const update = useUpdate();
  // eslint-disable-next-line no-param-reassign
  footer.data.data.update = update;
  const domRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-param-reassign
  footer.data.dom = domRef;

  return (
    <div className="join-grid-footer-container">
      <div className={`tfoot ${!isInheritedGrid && noHorizontalScrolling ? 'tfoot-print' : ''}`}>
        <div className="join-grid-tr">
          {hasCheckBoxes && (
            <div
              className={`join-grid-td ${ROW_FOOTER_CLASS}`}
              style={{ minWidth: startCellWidthValue - 1, flexShrink: 0 }} // due to border on outside
            />
          )}
          <div
            className={`${ROW_FOOTER_CLASS} join-grid-line-number-cell join-grid-td join-grid-border-right`}
            style={{ minWidth: startCellWidthValue, flexShrink: 0 }} // Due to borders
          >
            <span>{prefix}</span>
          </div>
          <div className={`${ROW_FOOTER_CLASS} join-grid-td flex-grow`}>
            <div className="join-grid-footer-name left-align">{name}</div>
            <div className="join-grid-footer-buttons">{children}</div>
          </div>
          <div className={`join-grid-td ${ROW_FOOTER_CLASS}`}>
            <div
              ref={domRef}
              data-cy={`grid-total-${grid.type}`}
              className="join-grid-cell"
              style={{
                paddingLeft: '0px',
              }}
            >
              {renderCell(grid, footer, -1, value, error, () => {}, grid.linesReadOnly)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridFooter;
