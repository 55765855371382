import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const TeamSummaryStyles = (theme: any) =>
  createStyles({
    banner: {
      color: theme.palette.primaryGrey,
      objectFit: 'contain',
      '@media print': {
        padding: '0.2em',
      },
      padding: '0px 24px',
    },
    containerOuter: {
      display: 'flex',
      flex: '0 1 400',
      height: 'inherit',
      justifyContent: 'center',
      '@media print': {
        height: '3em',
        padding: '0.5em',
      },
    },
    imgColumn: {
      flex: '0 1 auto',
      display: 'flex',
      alignContent: 'center',
      padding: '8px 16px',
    },
    logo: {
      color: theme.palette.primaryGrey,
      objectFit: 'contain',
      '@media print': {
        padding: '0.2em',
      },
      maxWidth: '100%',
      height: 'auto',
    },
  });

export default TeamSummaryStyles;
