/* eslint-disable no-param-reassign */
import { gridWidthVar } from '../../../api/apollo/reactiveVars';
import { logErrorToSentry } from '../../../utilities/sentry';
import {
  CELL_BORDER,
  CELL_TEXT_PADDING,
  COLUMN_VERTICAL_BORDER,
  HEADER_START_CELL_WIDTH,
  startCellWidth,
} from '../style/styleConstants';
import { Column, GridData, Position, SizingState } from '../types';
import { boundedRectangle } from '../utilities/position';
import {
  DEFAULT_ROW_HEIGHT,
  adjustColumnWidths,
  calcPrefixSums,
  calcRowHeights,
  getColumnWidths,
  isVariableHeight,
  minColWidthByType,
} from '../utilities/size';

export const MIN_CELL_WIDTH = 180;

const WINDOWS_SCROLLBAR_MARGIN = 3;
// Executed only once, this will measure the scrollbar width.
// On windows, this is 14, but the scrollbar ALSO has some sort
// of margin on it, which seems to be (consistently) 3.
const DEFAULT_SCROLLBAR_SPACE = 14;
export const [SCROLLBAR_WIDTH, SCROLLBAR_MARGIN] = (() => {
  const div = document.createElement('div');
  div.className = 'scrollbar-measure';
  document.body.appendChild(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return [
    scrollbarWidth || DEFAULT_SCROLLBAR_SPACE,
    scrollbarWidth > 0 ? WINDOWS_SCROLLBAR_MARGIN : 0,
  ];
})();

type MeasureHeightState = Pick<SizingState, 'heightCache' | 'data' | 'measureDiv' | 'widths'>;

const calcMinOverallWidth = (columns: Column[]) => {
  const width = columns.reduce((a, column) => a + minColWidthByType(column), 0);
  return width;
};

export const newSizingState = (
  data: GridData,
  maxHeight: number,
  maxWidth?: number,
  state?: SizingState,
  isAccordionOuter?: boolean
): SizingState => {
  const reactiveWidth = gridWidthVar() || 800;
  const visibleWidth = maxWidth || reactiveWidth;
  const columns = data.columns || [];
  const minOverallWidth = calcMinOverallWidth(columns);
  const overallWidth = visibleWidth < minOverallWidth ? minOverallWidth : visibleWidth;
  // if we are provided a height, we will assume that we will see scroll bars in place
  const isAccordion = isAccordionOuter || (state ? state.isAccordion : false);
  const hasScrollbarSpace = hasScrollBar({
    isAccordion,
    rowTotals: data.lines.length,
    maxHeight,
  } as unknown as SizingState);
  const hasCheckBox = !data.readOnly;
  const measureHeightState = {
    data,
    heightCache: {},
    measureDiv: measureDiv(),
    widths: getColumnWidths(
      data,
      tableCellWidth(visibleWidth, data.lines.length, hasScrollbarSpace, hasCheckBox)
    ),
  };
  const heights = calcRowHeights(data, (s, i) => measureHeight(measureHeightState, s, i));
  const rowTotals = calcPrefixSums(heights);

  const updateRowHeights = false;
  const renderGeneration = 0;
  const rowUpdaters = data.lines.map(() => () => {});

  const sizingState = {
    ...measureHeightState,
    heights,
    isAccordion,
    maxHeight,
    rowTotals,
    updateRowHeights,
    renderGeneration,
    visibleWidth,
    overallWidth,
    isResizingColumn: false,
    updateTable: () => {},
    rowUpdaters,
    updateRow: (row: number) => {
      if (rowUpdaters[row] !== undefined) {
        rowUpdaters[row]();
      } else {
        const message = `Tried to update row ${row}. Grid has ${data.lines.length} rows.`;
        logErrorToSentry(new Error(message));
      }
    },
  };

  // update grid state with measured values...
  if (state) {
    state.heightCache = measureHeightState.heightCache;
    state.measureDiv = measureHeightState.measureDiv;
    state.widths = measureHeightState.widths;
    state.heights = sizingState.heights;
    state.maxHeight = sizingState.maxHeight;
    state.rowTotals = sizingState.rowTotals;
    state.updateRowHeights = sizingState.updateRowHeights;
    state.renderGeneration = sizingState.renderGeneration;
    state.visibleWidth = sizingState.visibleWidth;
    state.overallWidth = sizingState.overallWidth;
    state.isResizingColumn = sizingState.isResizingColumn;
    state.rowUpdaters = sizingState.rowUpdaters;
    state.updateRow = sizingState.updateRow;
  }
  return sizingState;
};

// This might need to be accounted for in the col-resize hdr adjustments
const extraContainerPadding = 7;

export const tableBodyMaxWidth = (width: number) => width - extraContainerPadding + 1;

export const tableCellWidth = (
  overallWidth: number,
  lines: number,
  hasScrollBar: boolean,
  hasCheckBox: boolean
) => {
  const scrollBarWidth = hasScrollBar ? SCROLLBAR_WIDTH + SCROLLBAR_MARGIN : 0;
  const numberCellWidth = startCellWidth(lines);
  const checkBoxWidth = hasCheckBox ? HEADER_START_CELL_WIDTH : 0;
  return overallWidth - (numberCellWidth + checkBoxWidth + scrollBarWidth) - extraContainerPadding;
};

// We can call these either with height state, or directly with a controller.
export const hasScrollBar = (state: SizingState) => {
  const { isAccordion, rowTotals, maxHeight } = state;
  if (isAccordion) return true;
  if (rowTotals.length === 0) return false;
  const totalHeight = rowTotals[rowTotals.length - 1];
  return totalHeight + DEFAULT_ROW_HEIGHT >= maxHeight;
};

export const horizontalScrollBarHeight = (
  widths: number[],
  visibleWidth: number,
  lineCount: number,
  hasScrollBar: boolean,
  hasCheckBox: boolean,
  height: number
) => {
  if (lineCount === 0) return 0;
  const maxWidth = tableCellWidth(visibleWidth, lineCount, hasScrollBar, hasCheckBox);
  const totalWidth = widths.reduce((sum: number, current: number) => {
    sum += current;
    return sum;
  });
  return totalWidth > maxWidth ? height : 0;
};

export const scrollBarWidth = (state: SizingState) => (hasScrollBar(state) ? SCROLLBAR_WIDTH : 0);

// Used to get the width of the table,
export const setOverallWidth = (
  state: SizingState,
  visibleWidthContainer: number,
  immediate = true
) => {
  const visibleWidth = visibleWidthContainer;
  const columns = state.data.columns || [];
  const minOverallWidth = calcMinOverallWidth(columns);
  state.visibleWidth = visibleWidth;
  state.overallWidth = visibleWidth < minOverallWidth ? minOverallWidth : visibleWidth;
  // if we are provided a height, we will assume that we will see scroll bars in place
  const hasScrollbarSpace = hasScrollBar(state);
  const { data, overallWidth, updateTable } = state;
  const hasCheckBox = !data.readOnly;
  const width = tableCellWidth(overallWidth, data.lines.length, hasScrollbarSpace, hasCheckBox);
  const newWidths = getColumnWidths(data, width);
  updateWidths(state, newWidths);
  if (immediate) {
    updateTable();
  }
};

export const updateWidths = (state: SizingState, newWidths: number[]) => {
  state.widths = newWidths;
  state.heights = calcRowHeights(state.data, (s, i) => measureHeight(state, s, i));
  state.rowTotals = calcPrefixSums(state.heights);
  state.renderGeneration += 1;
};

export const adjustToNewTableWidth = (state: SizingState) => {
  const {
    widths,
    overallWidth,
    data: { lines },
  } = state;
  const hasScrollbarSpace = hasScrollBar(state);
  const hasCheckBox = !state.data.readOnly;
  updateWidths(
    state,
    adjustColumnWidths(
      widths,
      tableCellWidth(overallWidth, lines.length, hasScrollbarSpace, hasCheckBox)
    )
  );
};

const measureDiv = (): HTMLDivElement => {
  const measureDiv = document.querySelector<HTMLInputElement>('.join-grid-text-measure');
  if (measureDiv) return measureDiv;

  const createdDiv = document.createElement('div');
  createdDiv.classList.add('join-grid-text-measure');
  document.body.appendChild(createdDiv);
  return createdDiv;
};

// We go with the "expensive" height calculation method from the start,
// which admittedly makes the table look much nicer.
//
// TODO: figure out how much the heightCache is hit on "real" data.
// TODO: optimize any string of length < 10 == the default row height.
// (or really, this number should be width / font-size)
// TODO: make this computation asynchronous, i.e. calculate the visible cells
// first, and then handle user input intermittently with processing the rest
// of the giant array of things.
export const measureHeight = (state: MeasureHeightState, txt: string, columnIndex: number) => {
  const { heightCache, data, measureDiv, widths } = state;
  if (!isVariableHeight(data.columns[columnIndex].type)) {
    return DEFAULT_ROW_HEIGHT;
  }
  const width = widths[columnIndex];
  // Cache each text string's height, but only for a given width.
  const key = `${txt}-width=${width}`;
  const cachedHeight = heightCache[key];
  if (cachedHeight !== undefined) {
    return cachedHeight;
  }
  const padding = CELL_TEXT_PADDING * 2 + CELL_BORDER; // Padding either side
  measureDiv.setAttribute('style', `width: ${width - padding - COLUMN_VERTICAL_BORDER}px`);
  measureDiv.innerText = txt;

  const height = measureDiv.getBoundingClientRect()?.height ?? 0;
  const result = Math.max(height + CELL_TEXT_PADDING, DEFAULT_ROW_HEIGHT);
  heightCache[key] = result;

  return result;
};

// TODO: Prevent other actions like editing happening when a column is resizing -
// currently the user can select a cell, start resizing, and then press enter,
// and weirdness will occur.
export const startResizingColumn = (state: SizingState, index: number) => {
  state.isResizingColumn = true;
  const { divider } = state.data.columns[index + 1];
  if (divider) divider.className = 'join-grid-col-resize';
};

// Propagate a re-render with new widths, albeit not new heights.
export const resizeColumns = (state: SizingState, newWidths: number[], scrollGrid?: boolean) => {
  // Explicitly set the widths INSTEAD of using the normal function,
  // which would recalculate height info for a resize.
  state.widths = newWidths;
  if (scrollGrid && state.bodyRef && state.bodyRef.current)
    state.bodyRef.current.scrollLeft = 10000;
  state.renderGeneration += 1;
  state.updateTable();
};

// Tell the grid we're done, and to perform all of its potentially
// expensive layout utilities if necessary.
export const stopResizingColumn = (state: SizingState, index: number) => {
  const { divider } = state.data.columns[index + 1];
  if (divider) divider.className = 'join-grid-col-resize';
  state.isResizingColumn = false;
  // Use the last set of widths to recalc height info.
  updateWidths(state, state.widths);
  state.updateTable();
};

export const setRowUpdater = (state: SizingState, i: number, updater: () => void) => {
  const { rowUpdaters } = state;
  while (rowUpdaters.length <= i) {
    rowUpdaters.push(() => {});
  }
  rowUpdaters[i] = updater;
};

export const normalizeRectangle = (state: SizingState, start: Position, end: Position) => {
  const {
    data: { lines, columns },
  } = state;
  return boundedRectangle(lines.length - 1, columns.length - 1, start, end);
};
