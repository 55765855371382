import { useEffect } from 'react';

import { SearchItemsQuery, SearchItemsQueryVariables } from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../../../hooks/useQueryPagination';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { searchItems } from './queries';

export const useSearchItemsQuery = (variablesOuter: SearchItemsQueryVariables, skip?: boolean) => {
  // Update project type names for project filters and item search filters
  const variables = useTypesIdsToNames(
    variablesOuter,
    'projectsFilters.types',
    'filters.projectTypes'
  );
  const query = useQuery<SearchItemsQuery, SearchItemsQueryVariables>(searchItems, {
    variables,
    skip,
  });

  // if you change SearchItemsQueryVariables, add them to /src/apollo/index.js
  const { fetchMore, resetPagination } = useQueryPagination(
    variables.pagination.limit,
    query.data?.searchItems?.data.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, variables]);

  return {
    ...query,
    fetchMore,
  };
};
