import { FC } from 'react';

import { Avatar, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { parseDate } from '../../../utilities/dates';
import { getUserPicture } from '../../Collaborators/UserUtils';
import CommentDisplay from '../../Comments/CommentDisplay/CommentDisplay';
import { getIsDeactivated } from '../../CompanyTab/CompanyTabUtils';
import { ItemActivityEvent } from '../utils';

import styles from './ItemHistoryListCommentStyles';

type Props = {
  classes: Classes<typeof styles>;
  itemEvent: ItemActivityEvent;
  commentText?: ItemHistoryComment['text'];
  hoveredID?: string | null;
  setHoveredID?: (id: string | null) => void;
  curHoverTimeout?: NodeJS.Timeout | null;
  setCurHoverTimeout?: (timeout: NodeJS.Timeout | null) => void;
  isActivityFeed?: boolean;
};

const ItemHistoryListComment: FC<Props> = ({
  classes,
  itemEvent,
  commentText,
  hoveredID,
  setHoveredID,
  curHoverTimeout,
  setCurHoverTimeout,
  isActivityFeed = false,
}) => {
  // Content
  const { created, user } = itemEvent;

  const { contents, mentions, itemReferences } = commentText || {};

  const deactivatedStyle = user && getIsDeactivated(user) ? classes.deactivated : '';

  return (
    <>
      {!isActivityFeed && ( // We should probably replace this with a feature flag check when use this component in the item details history
        <div className={`${classes.slugLineSimplified} ${deactivatedStyle}`}>
          <Avatar
            className={classes.avatarSimplified}
            src={(user && getUserPicture(user)) || undefined}
          />
          {created && (
            <Typography className={classes.descriptionSimplified}>
              {`${user?.name} commented on ${parseDate(created)}:`}
            </Typography>
          )}
        </div>
      )}
      <div
        className={!isActivityFeed ? classes.contentSimplified : undefined}
        data-cy="item-details-comment"
      >
        <Typography className={!isActivityFeed ? classes.textSimplified : undefined}>
          <CommentDisplay
            className={classes.mentionStyle}
            mentions={mentions ?? undefined}
            itemReferences={itemReferences ?? undefined}
            title={contents}
            hoveredID={hoveredID}
            setHoveredID={setHoveredID}
            curHoverTimeout={curHoverTimeout}
            setCurHoverTimeout={setCurHoverTimeout}
          />
        </Typography>
      </div>
    </>
  );
};

export default withStyles(styles)(ItemHistoryListComment);
