import { FC, useState } from 'react';

import { Button, Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import FullLogo from '../logo/FullLogo.svg';

import styles from './Signup/SignupPageStyles';
import SignUpLayer from './SignUpLayer';
import { useAcceptTOSMutation, useAcceptedTOSQuery } from './useTOSHooks';

type TOSPageProps = {
  classes: Classes<typeof styles>;
  setTOSState: (b: boolean) => void;
  date: string;
};

const TOSPage: FC<TOSPageProps> = ({ classes, setTOSState, date }) => {
  const [acceptTOS] = useAcceptTOSMutation();
  return (
    <div className={classes.contentBox}>
      <img alt="Join" src={FullLogo} className={classes.logoJoin} />
      <div className={classes.content}>
        <Typography variant="headline" className={classes.header}>
          We&apos;ve updated our Terms of Service and Privacy Policy.
        </Typography>
        <Typography variant="subheading">
          {(date && `These terms took effect on ${date}.`) || ''} Please take a moment to note the
          changes, and accept the updated terms to continue to the app. You can access our current
          Terms of Service at any time at{' '}
          <a href="https://join.build/terms" className={classes.linkText}>
            join.build/terms
          </a>
          .
        </Typography>
        <div className={classes.centered}>
          <Button
            aria-label="accept terms and continue"
            className={classes.acceptButton}
            color="primary"
            variant="contained"
            onClick={() => {
              acceptTOS();
              setTOSState(true);
            }}
          >
            Accept and Continue to Join
          </Button>
        </div>
      </div>
    </div>
  );
};

const StyledTOSPage = withStyles(styles)(TOSPage);

type Props = {
  acceptedTOSQuery: ReturnType<typeof useAcceptedTOSQuery>;
};

const TOSCheckLayer = (props: Props) => {
  const skip = localStorage.getItem('skip_signup_flow');
  const [tosCheckState, setTOSState] = useState(false);

  if (skip || tosCheckState) {
    return <SignUpLayer />;
  }

  if (!props.acceptedTOSQuery) {
    // Wait for the TOS check to fully load.
    // We want to see a result of "true" before we go anywhere.
    return <div />;
  }

  if (props.acceptedTOSQuery.accepted) {
    return <SignUpLayer />;
  }

  return <StyledTOSPage setTOSState={setTOSState} date={props.acceptedTOSQuery.dateEffective} />;
};

export default TOSCheckLayer;
