import { isEnumValue } from '../../../utilities/types';
import { ButtonBar, Select } from '../../scales';

import {
  DayOfWeekOptionsID,
  DistributionFrequencyInputs,
  FrequencyOptionsID,
  ScheduleOptions,
  StartingWeekOptionsID,
  WeekOfTheMonthOptionsID,
  dayOfWeekOptions,
  frequencyOptions,
  getStartingWeekEntries,
  scheduleOptions,
  timeOfDayOptions,
  weekOfTheMonthOptions,
} from './DistributionUtils';

type Props = {
  distribution: DistributionFrequencyInputs;
  onEdit: (distribution: DistributionFrequencyInputs) => void;
  isEditDialog: boolean;
};

export default function DistributionFrequency({ distribution, onEdit, isEditDialog }: Props) {
  return (
    <div className="flex h-24 w-full items-center justify-start pl-4">
      <div className="flex gap-4">
        {!isEditDialog && (
          <div className="flex flex-col type-label">
            <ButtonBar
              options={scheduleOptions}
              onChange={(value) =>
                isEnumValue(ScheduleOptions, value) &&
                onEdit({ ...distribution, selectedScheduleOption: value })
              }
              value={distribution.selectedScheduleOption}
              label="Send"
            />
          </div>
        )}
        {distribution.selectedScheduleOption === ScheduleOptions.SCHEDULED && (
          <>
            <div className="min-w-[200px]">
              <Select
                label="Frequency"
                entries={frequencyOptions}
                value={distribution.selectedFrequency}
                onChange={(value) =>
                  isEnumValue(FrequencyOptionsID, value) &&
                  onEdit({ ...distribution, selectedFrequency: value })
                }
              />
            </div>
            {distribution.selectedFrequency === 'Every_Other_Week' && (
              <div className="min-w-[200px]">
                <Select
                  label="Starting"
                  entries={getStartingWeekEntries(distribution)}
                  value={distribution.selectedStartingWeek}
                  onChange={(value) =>
                    isEnumValue(StartingWeekOptionsID, value) &&
                    onEdit({ ...distribution, selectedStartingWeek: value })
                  }
                />
              </div>
            )}
            {distribution.selectedFrequency === 'Monthly' && (
              <div className="min-w-[150px]">
                <Select
                  label="Week of the Month"
                  entries={weekOfTheMonthOptions}
                  value={distribution.selectedWeekOfTheMonth}
                  onChange={(value) =>
                    isEnumValue(WeekOfTheMonthOptionsID, value) &&
                    onEdit({ ...distribution, selectedWeekOfTheMonth: value })
                  }
                />
              </div>
            )}
            {distribution.selectedFrequency !== 'Daily' &&
              distribution.selectedFrequency !== 'Daily_Weekdays' && (
                <div className="min-w-[150px]">
                  <Select
                    label="Day of the Week"
                    entries={dayOfWeekOptions}
                    value={distribution.selectedDayOfWeek}
                    onChange={(value) =>
                      isEnumValue(DayOfWeekOptionsID, value) &&
                      onEdit({ ...distribution, selectedDayOfWeek: value })
                    }
                  />
                </div>
              )}
            <Select
              label="Time of Day"
              entries={timeOfDayOptions}
              value={distribution.selectedTimeOfDay}
              onChange={(value) => {
                onEdit({ ...distribution, selectedTimeOfDay: value });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
