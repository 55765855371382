import { FC } from 'react';

import { Checkbox, IconButton, ListItem, Typography } from '@material-ui/core';
import {
  ArrowForwardIos,
  CheckBoxRounded as CheckBoxChecked,
  CheckBoxOutlineBlankRounded as CheckBoxUnchecked,
} from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import Tag from '../../ItemsList/Tag';

import styles from './DialogsUnitsUnitStyles';

type UnitListItemProps = {
  canEdit: boolean;
  classes: Classes<typeof styles>;
  isEnabled: boolean;
  setEditing: (unit: Unit) => void;
  setIsEnabled: (unitID: UUID, isEnabled: boolean) => void;
  showBorder: boolean;
  unit: Unit;
};

const UnitListItem: FC<UnitListItemProps> = ({
  classes,
  canEdit,
  isEnabled,
  unit,
  setEditing,
  setIsEnabled,
  showBorder,
}) => (
  <ListItem className={`${classes.listItem} ${showBorder && classes.borderTop}`} key={unit.id}>
    <Checkbox
      data-cy={`checkbox-toggleUnit-${unit.abbreviationPlural}`}
      disabled={!canEdit}
      icon={<CheckBoxUnchecked />}
      checkedIcon={<CheckBoxChecked />}
      checked={isEnabled}
      onChange={(e) => {
        setIsEnabled(unit.id, e.target.checked);
      }}
    />
    <div data-cy="UnitListItem-name" className={classes.name}>
      <Typography
        className={classes.text}
      >{`${unit.name} (${unit.abbreviationSingular})`}</Typography>
      <Typography variant="caption">{unit.description}</Typography>
    </div>
    <div className={classes.unit}>
      {unit.isDefault ? (
        <Tag name="Built-in" />
      ) : (
        <IconButton
          data-cy={`button-editUnit-${unit.abbreviationPlural}`}
          onClick={() => setEditing(unit)}
        >
          <ArrowForwardIos />
        </IconButton>
      )}
    </div>
  </ListItem>
);

export default withStyles(styles)(UnitListItem);
