export default function JoinLogo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6526 29.2959C17.6526 30.3914 17.3709 31.2051 16.7762 31.7998C16.1815 32.3632 15.3678 32.6762 14.3662 32.6762C13.8028 32.6762 13.302 32.5823 12.8951 32.4258C12.4883 32.238 12.1127 32.0189 11.8623 31.7059C11.5806 31.3929 11.3615 31.0487 11.2363 30.6418C11.1111 30.2349 11.0172 29.7967 11.0172 29.3272H13.1142C13.1142 29.7654 13.2081 30.141 13.4272 30.454C13.615 30.7357 13.928 30.8922 14.3662 30.8922C14.7418 30.8922 15.0548 30.767 15.2739 30.4853C15.493 30.2036 15.5869 29.828 15.5869 29.3585V23.3491H17.6839V29.2959H17.6526Z"
        fill="currentColor"
      />
      <path
        d="M22.3161 32.6134C21.7841 32.6134 21.2833 32.5195 20.8451 32.3317C20.4069 32.1439 20.0626 31.9248 19.7496 31.6118C19.4366 31.2988 19.2176 30.9546 19.0611 30.579C18.9046 30.1721 18.8107 29.7652 18.8107 29.2957C18.8107 28.8262 18.9046 28.4193 19.0611 28.0125C19.2176 27.6056 19.4366 27.2613 19.7496 26.9796C20.0626 26.6979 20.4069 26.4475 20.8451 26.2597C21.2833 26.0719 21.7528 25.978 22.3161 25.978C22.8482 25.978 23.349 26.0719 23.7872 26.2597C24.2254 26.4475 24.5697 26.6666 24.8827 26.9796C25.1956 27.2926 25.4147 27.6369 25.5712 28.0125C25.7277 28.4193 25.7903 28.8262 25.7903 29.2957C25.7903 29.7652 25.7277 30.1721 25.5712 30.579C25.4147 30.9859 25.1956 31.3301 24.8827 31.6118C24.5697 31.9248 24.2254 32.1439 23.7872 32.3317C23.349 32.5195 22.8482 32.6134 22.3161 32.6134ZM22.2848 30.9233C22.5039 30.9233 22.723 30.892 22.9108 30.7981C23.0986 30.7042 23.2551 30.6103 23.3803 30.4538C23.5055 30.2973 23.5994 30.1408 23.6933 29.953C23.7872 29.7652 23.8185 29.5461 23.8185 29.327C23.8185 29.1079 23.7872 28.8888 23.6933 28.701C23.5994 28.5132 23.5055 28.3254 23.3803 28.2002C23.2551 28.0751 23.0986 27.9499 22.9108 27.856C22.723 27.7621 22.5039 27.7308 22.2848 27.7308C22.0658 27.7308 21.8467 27.7621 21.6589 27.856C21.4711 27.9499 21.3146 28.0438 21.1894 28.2002C21.0642 28.3567 20.9703 28.5132 20.8764 28.701C20.7825 28.8888 20.7825 29.1079 20.7825 29.327C20.7825 29.5461 20.8138 29.7652 20.8764 29.953C20.939 30.1408 21.0329 30.3286 21.1894 30.4538C21.3459 30.579 21.4711 30.7042 21.6589 30.7981C21.878 30.8607 22.0658 30.9233 22.2848 30.9233Z"
        fill="currentColor"
      />
      <path
        d="M26.7606 24.1001C26.7606 23.7559 26.8858 23.4429 27.1362 23.1925C27.3866 22.9421 27.6683 22.8169 28.0439 22.8169C28.2317 22.8169 28.3882 22.8482 28.5447 22.9108C28.7011 22.9734 28.8263 23.0673 28.9515 23.1925C29.0767 23.3177 29.1706 23.4429 29.2332 23.5994C29.2958 23.7559 29.3271 23.9124 29.3271 24.1001C29.3271 24.2879 29.2958 24.4444 29.2332 24.6009C29.1706 24.7574 29.0767 24.8826 28.9515 25.0078C28.8263 25.133 28.7011 25.2269 28.5447 25.2895C28.3882 25.3521 28.2317 25.3834 28.0439 25.3834C27.8561 25.3834 27.6996 25.3521 27.5431 25.2895C27.3866 25.2269 27.2614 25.133 27.1362 25.0078C27.011 24.8826 26.9171 24.7574 26.8545 24.6009C26.7919 24.4444 26.7606 24.2566 26.7606 24.1001ZM27.0423 26.1659H29.0454V32.457H27.0423V26.1659Z"
        fill="currentColor"
      />
      <path
        d="M30.7668 26.166H32.77V27.0737C32.9578 26.7294 33.2081 26.479 33.5524 26.2912C33.8967 26.1034 34.3349 26.0095 34.8357 26.0095C35.2739 26.0095 35.6182 26.0721 35.8999 26.1973C36.1815 26.3225 36.4006 26.5103 36.5884 26.6981C36.7762 26.9485 36.9327 27.2302 36.9953 27.5432C37.0579 27.8562 37.0892 28.2317 37.0892 28.7012V32.4258H35.0861V29.2333C35.0861 28.7951 34.9922 28.4195 34.8357 28.1378C34.6792 27.8562 34.3975 27.6997 33.9906 27.6997C33.5837 27.6997 33.2707 27.8562 33.0517 28.1378C32.8326 28.4195 32.7387 28.7951 32.7387 29.2959V32.4571H30.7355V26.166H30.7668Z"
        fill="currentColor"
      />
      <path d="M7.35524 32.6761L0 40V7.32394L7.35524 0V32.6761Z" fill="currentColor" />
    </svg>
  );
}
