import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.82 15.33L13.8075 16.3425L9.8925 12.27V7.2075H11.2425V11.73L14.82 15.33Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0125 20.2912C8.1075 20.7638 9.27 21 10.5 21C11.0944 21 11.673 20.9448 12.2358 20.8345C12.7987 20.9448 13.3773 21 13.9716 21C15.2016 21 16.3641 20.7638 17.4591 20.2912C18.5541 19.8187 19.5104 19.1737 20.3279 18.3562C21.1454 17.5387 21.7904 16.5825 22.2629 15.4875C22.7354 14.3925 22.9716 13.23 22.9716 12C22.9716 10.77 22.7354 9.6075 22.2629 8.5125C21.7904 7.4175 21.1454 6.46125 20.3279 5.64375C19.5104 4.82625 18.5541 4.18125 17.4591 3.70875C16.3641 3.23625 15.2016 3 13.9716 3C13.3773 3 12.7987 3.05516 12.2358 3.1655C11.673 3.05517 11.0944 3 10.5 3C9.27 3 8.1075 3.23625 7.0125 3.70875C5.9175 4.18125 4.96125 4.82625 4.14375 5.64375C3.32625 6.46125 2.68125 7.4175 2.20875 8.5125C1.73625 9.6075 1.5 10.77 1.5 12C1.5 13.23 1.73625 14.3925 2.20875 15.4875C2.68125 16.5825 3.32625 17.5387 4.14375 18.3562C4.96125 19.1737 5.9175 19.8187 7.0125 20.2912ZM15.422 4.47781C15.94 4.81689 16.418 5.20554 16.8562 5.64375C17.6737 6.46125 18.3187 7.4175 18.7912 8.5125C19.2638 9.6075 19.5 10.77 19.5 12C19.5 13.23 19.2638 14.3925 18.7912 15.4875C18.3187 16.5825 17.6737 17.5387 16.8562 18.3562C16.418 18.7945 15.94 19.1831 15.422 19.5222C16.9126 19.2499 18.2291 18.5425 19.3716 17.4C20.8716 15.9 21.6216 14.1 21.6216 12C21.6216 9.9 20.8716 8.1 19.3716 6.6C18.2291 5.4575 16.9126 4.7501 15.422 4.47781ZM15.9 17.4C14.4 18.9 12.6 19.65 10.5 19.65C8.4 19.65 6.6 18.9 5.1 17.4C3.6 15.9 2.85 14.1 2.85 12C2.85 9.9 3.6 8.1 5.1 6.6C6.6 5.1 8.4 4.35 10.5 4.35C12.6 4.35 14.4 5.1 15.9 6.6C17.4 8.1 18.15 9.9 18.15 12C18.15 14.1 17.4 15.9 15.9 17.4Z"
    />
  </SvgIcon>
);
