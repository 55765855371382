import { FC, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutline, Edit, Flag, FlagOutlined, Search } from '@material-ui/icons';

import { TimelineEvent, timelineEvent } from '../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import {
  ALL_MILESTONES,
  CURRENT_MILESTONE,
  DELETE,
  EDIT,
  TIMELINE_TYPE_NAMES,
} from '../../constants';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import theme from '../../theme/design-system-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';
import { getItemListLink } from '../ItemsList/ItemsListUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import IconMenu from '../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../Select/SelectMenu/SelectStyles';
import CollapseIcon from '../shared-widgets/CollapseIcon';

import { ActivityNode, TimelineExpandedMap } from './Timeline.types';

type TimelineTableProps = {
  activities: ActivityNode[];
  canEdit: boolean;
  expandedMap: TimelineExpandedMap;
  onExpand: (id: UUID) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setModal: (modal: any) => void;
  setZoomIn: (a: ActivityNode) => void;
};

const EventIcon = (disabled: boolean) => (
  <div
    className={`h-2.5 w-2.5 rotate-45 ${disabled ? 'bg-button-inactive' : 'bg-entities-event'}`}
  />
);
const PhaseIcon = (disabled: boolean) => (
  <div
    className={`h-2.5 w-2.5 rounded-md ${disabled ? 'bg-button-inactive' : 'bg-entities-phase'}`}
  />
);
const MilestoneIcon = (disabled: boolean) => (
  <FlagOutlined className={disabled ? 'text-button-inactive' : 'text-entities-milestone'} />
);
const ActiveMilestoneIcon = (disabled: boolean) => (
  <Flag className={disabled ? 'text-button-inactive' : 'text-entities-milestone'} />
);
const icons = [EventIcon, PhaseIcon, MilestoneIcon, ActiveMilestoneIcon] as const;

const TimelineTable: FC<TimelineTableProps> = ({
  activities,
  canEdit,
  expandedMap,
  onExpand,
  setModal,
  setZoomIn,
}) => {
  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();

  const now = new Date();
  const renderTodayMarker = () => (
    <div className="absolute top-0 z-20 mt-0.5 flex h-0.5 w-full items-center bg-entities-today">
      <div className="ml-4 rounded-sm bg-entities-today px-1 py-px text-type-secondary type-label">
        Today {now.toLocaleDateString()}
      </div>
    </div>
  );

  // Track values as we recursively traverse activities.
  let previousDate: Date | null = null;
  let todayExists = false;

  const renderActivity = (a: ActivityNode, i: number, siblings: ActivityNode[]): ReactNode => {
    const { id, name, startDate, endDate, type, milestone, itemCount } = a;
    const start = new Date(startDate);
    const isExpanded = expandedMap[id] === true;
    const renderActivityIcon = icons[a.type];

    const isPast = new Date(endDate ?? startDate) <= now;

    // Display the today marker above the current activity.
    let isAfterToday =
      !todayExists &&
      (start >= now ||
        (a.children.length === 0 && endDate && start <= now && now <= new Date(endDate))) &&
      (previousDate === null || previousDate < now);

    // Ensure we only display one today marker.
    if (isAfterToday) todayExists = true;

    previousDate = start;
    const before = todayExists;
    const childRows = a.children.map(renderActivity);

    // If the today marker should be displayed next to a collapsed child, display it next to the parent.
    if (todayExists !== before && !isExpanded) isAfterToday = true;
    const row = (
      <div
        className={`relative grid h-10 grid-cols-[minmax(0,4fr)_repeat(4,minmax(0,1fr))_40px] items-center type-body1 ${
          isPast ? 'text-type-inactive' : ''
        } ${isAfterToday ? 'pt-3' : ''}`}
      >
        <div className="relative flex items-center pl-5">
          {/* Line */}
          <div className="absolute flex w-8 justify-center">
            {activities.length > 0 &&
              id !== activities[0].id &&
              !activities[activities.length - 1].children.some((c) => c.id === id) && (
                <div className="absolute bottom-1/2 left-1/2 -ml-px h-10 w-0.5 bg-border-default" />
              )}
          </div>
          <div className="flex items-center" style={{ marginLeft: `${2 * a.depth}em` }}>
            {/* Icon */}
            <div className="relative flex w-8 justify-center">
              <div
                className={`z-10 rounded-full ${
                  type >= TimelineActivityType.MILESTONE ? 'bg-background-primary' : ''
                }`}
              >
                {renderActivityIcon(isPast)}
              </div>
              {i !== siblings.length - 1 && (
                <div className="absolute left-1/2 top-1/2 z-0 -ml-px h-10 w-0.5 bg-border-default" />
              )}
            </div>
            {/* Expand/collapse */}
            <div className="flex w-8 justify-center">
              {a.children.length > 0 && (
                <button
                  onClick={() => {
                    sendAnalytics(
                      timelineEvent(
                        isExpanded ? TimelineEvent.TABLE_COLLAPSE : TimelineEvent.TABLE_EXPAND
                      )
                    );
                    onExpand(id);
                  }}
                >
                  <CollapseIcon isCollapsed={!isExpanded} />
                </button>
              )}
            </div>
            {/* Name */}
            {milestone ? (
              <Link
                className="type-link"
                to={generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                  projectId: projectID,
                  milestoneId: milestone.id,
                })}
                style={isPast ? { color: theme.palette.ds.type.inactive } : {}}
                onClick={() => {
                  sendAnalytics(timelineEvent(TimelineEvent.TABLE_MILESTONE_CLICK));
                }}
              >
                <span className="ml-1.5">{name}</span>
              </Link>
            ) : (
              <div className="ml-1.5">{name}</div>
            )}
          </div>
        </div>
        {/* Type */}
        <div>
          {
            TIMELINE_TYPE_NAMES[
              type === TimelineActivityType.ACTIVE_MILESTONE ? TimelineActivityType.MILESTONE : type
            ]
          }
        </div>
        {/* Dates */}
        <div>{new Date(startDate).toLocaleDateString()}</div>
        <div>{endDate ? new Date(endDate).toLocaleDateString() : '-'}</div>
        <div>
          {itemCount > 0 && (
            <NormalTooltip title="Go to Items List">
              <Link
                className="type-link"
                to={getItemListLink(
                  projectID,
                  milestone
                    ? { [CURRENT_MILESTONE]: [a.id] }
                    : { [CURRENT_MILESTONE]: [ALL_MILESTONES], activityID: a.id }
                )}
                tabIndex={-1}
                style={isPast ? { color: theme.palette.ds.type.inactive } : {}}
                onClick={() => {
                  sendAnalytics(timelineEvent(TimelineEvent.TABLE_ITEMS_CLICK));
                }}
              >
                <span>Items ({itemCount})</span>
              </Link>
            </NormalTooltip>
          )}
        </div>
        <div className="text-type-primary">
          <IconMenu
            onClick={() => {
              sendAnalytics(timelineEvent(TimelineEvent.TABLE_MENU_CLICK));
            }}
            options={[
              {
                name: 'Zoom to',
                icon: <Search />,
                callback: () => {
                  setZoomIn(a);
                  sendAnalytics(timelineEvent(TimelineEvent.TABLE_ZOOM_CLICK));
                },
              },
              ...(canEdit &&
              (a.type === TimelineActivityType.EVENT || a.type === TimelineActivityType.PHASE)
                ? [
                    {
                      name: 'Edit',
                      icon: <Edit />,
                      callback: () => {
                        setModal({ activity: a, open: true, type: EDIT });
                        sendAnalytics(timelineEvent(TimelineEvent.TABLE_EDIT_CLICK));
                      },
                    },
                    {
                      color: theme.palette.ds.type.delete,
                      name: 'Delete',
                      icon: <DeleteOutline />,
                      callback: () => {
                        setModal({ activity: a, open: true, type: DELETE });
                        sendAnalytics(timelineEvent(TimelineEvent.TABLE_DELETE_CLICK));
                      },
                    },
                  ]
                : []),
            ]}
            variant={SelectVariants.COMPACT}
          />
        </div>
        {isAfterToday && renderTodayMarker()}
      </div>
    );

    return (
      <Fragment key={id}>
        {row}
        {isExpanded && childRows}
      </Fragment>
    );
  };

  return (
    <div className="min-w-max pb-20">
      <div className="sticky top-12 z-[100] grid h-8 w-full grid-cols-[minmax(0,4fr)_repeat(4,minmax(0,1fr))_40px] items-center border-b type-table-header">
        <div className="flex">
          <div className="w-16 pl-1.5" />
          <div className="ml-5">Name</div>
        </div>
        <div>Type</div>
        <div>Start Date</div>
        <div>End Date</div>
        <div>Items</div>
      </div>
      {activities.map(renderActivity)}
      {!todayExists && <div className="relative">{renderTodayMarker()} </div>}
    </div>
  );
};

export default TimelineTable;
