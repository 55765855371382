import { useEffect, useState } from 'react';

import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { useDontShowConfirm } from '../../Dialogs/DialogsConfirm/DialogsConfirmUtils';

const text = {
  manual: (escalationType: string) =>
    `This ${escalationType} is already escalated with RSMeans Index. Continuing to edit manually will erase the current value in this field. Do you wish to continue?`,
  remove: (escalationType: string) =>
    `This ${escalationType} is already escalated with RSMeans Index. Are you sure you want to remove?`,
} as const;

const RemoveAutoEscalationWarningDialog = (props: {
  action: 'manual' | 'remove';
  escalationType: 'location' | 'date';
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const confirmRemoval = useDontShowConfirm(
    `${props.escalationType}-${props.action}-autoescalation-warning-dialog`,
    () => setIsDialogOpen(true),
    () => setIsDialogOpen(false)
  );

  const [isDialogOpen, setIsDialogOpen] = useState(!confirmRemoval.disabled);

  // If the user has previously-disabled this dialog, fire the confirmation and close callbacks.
  useEffect(() => {
    if (confirmRemoval.disabled) {
      props.onConfirm();
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [confirmRemoval.disabled]);

  return (
    <DialogsConfirm
      acceptCtaCopy="Yes, continue"
      body={text[props.action](props.escalationType)}
      onClose={props.onClose}
      onConfirm={() => {
        props.onConfirm();
        props.onClose();
      }}
      onDisable={() => confirmRemoval.setDisabled(true)}
      open={isDialogOpen}
      title="Remove RSMeans Index escalation?"
    />
  );
};

export default RemoveAutoEscalationWarningDialog;
