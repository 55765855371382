import { FC } from 'react';

import { YC_ITEM_SHARING } from '../../features';
import { CommentNotificationSetting } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import JoinSelect, { getEntry } from '../Select/JoinSelect/JoinSelect';

type CommentSettingSelectProps = {
  disabled?: boolean;
  onChange: (type: CommentNotificationSetting) => void;
  value: string;
};

const CommentSettingSelect: FC<CommentSettingSelectProps> = ({
  onChange,
  value,
  disabled = false,
}) => {
  const isItemSharingFeature = useHasFeature(YC_ITEM_SHARING);
  const settings = [
    {
      name: CommentNotificationSetting.NONE,
      display: 'None',
      description: 'Never receive comment notifications.',
    },
    {
      name: CommentNotificationSetting.RELEVANT,
      display: 'Relevant only',
      description: isItemSharingFeature
        ? 'Receive comment notifications for the following: (1) items/options that you have created, commented on, or that have been assigned to you, and (2) draft items/options that have been shared with you.'
        : 'Receive comment notifications for items you have created, commented on, or that are assigned to you.',
    },
    {
      name: CommentNotificationSetting.ALL,
      display: 'All',
      description: 'Always receive comment notifications.',
    },
  ];

  const entries = settings.map(({ name, display, description }) =>
    getEntry(name, display, undefined, undefined, undefined, description)
  );

  return (
    <JoinSelect
      cySelect="select-notify-comments"
      disabled={disabled}
      entries={entries}
      hidePrint={false}
      onChange={(type) => onChange(type || '')}
      value={value}
    />
  );
};

export default CommentSettingSelect;
