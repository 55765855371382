import { FC } from 'react';

import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import { ResourceType } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { toIds, toSharedResourceUsers } from '../../../utilities/utilities';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useMemoWrapper from '../../useMemoWrapper';

import InputsSelectAssigneePopover from './InputsSelectAssigneePopover';
import { getAssignableCollaborators } from './InputsSelectAssigneeUtils';

type InputsSelectAssigneePopoverDataProps = {
  anchorEl: HTMLElement | null;
  handleSubmit: (collaborator: Collaborator | { user: null }) => void;
  isCompact?: boolean;
  isDraft?: boolean;
  isReset?: boolean;
  itemID?: UUID;
  label?: string;
  projectId: UUID;
  selectedCollaborator: Collaborator | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setAnchorEl: (r: any) => void;
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

const InputsSelectAssigneePopoverData: FC<InputsSelectAssigneePopoverDataProps> = ({
  anchorEl,
  handleSubmit,
  isCompact = false,
  isDraft = false,
  isReset = false,
  itemID,
  label,
  projectId,
  selectedCollaborator,
  setAnchorEl,
  variant,
}) => {
  const open = Boolean(anchorEl);

  const { data, loading } = useCollaboratorsQuery(open ? projectId : undefined);
  const collaborators = data?.collaborators ?? [];

  const { data: sharedResourceData, loading: loadingSharedResource } = useGetSharedResource(
    itemID,
    ResourceType.ITEM,
    !isDraft || !open
  );
  const sharedUsersIDs = toIds(toSharedResourceUsers(sharedResourceData));

  const assignableCollaborators = useMemoWrapper(
    getAssignableCollaborators,
    collaborators,
    selectedCollaborator,
    isReset,
    variant,
    isDraft,
    sharedUsersIDs
  );

  if (loading || loadingSharedResource) return null;

  return (
    <InputsSelectAssigneePopover
      anchorEl={anchorEl}
      assignableCollaborators={assignableCollaborators}
      handleSubmit={handleSubmit}
      isCompact={isCompact}
      isReset={isReset}
      label={label}
      open={open}
      selectedCollaborator={selectedCollaborator}
      setAnchorEl={setAnchorEl}
    />
  );
};

export default InputsSelectAssigneePopoverData;
