import { FC, MouseEvent, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/PersonOutline';

import { withStyles } from '../../theme/komodo-mui-theme';
import { getUserPicture } from '../Collaborators/UserUtils';
import { useCurrentUserQuery } from '../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';

import styles from './SupportMenuStyles';

type UserProfileProps = {
  classes: Classes<typeof styles>;
  deactivated: boolean;
  openEditProfile: () => void;
};

const UserProfile: FC<UserProfileProps> = ({ classes, deactivated, openEditProfile }) => {
  const { data } = useCurrentUserQuery();
  const { logout } = useAuth0();

  const { currentUser } = data || {};

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pictureUrl = currentUser ? getUserPicture(currentUser) : undefined;

  return (
    <div>
      <IconButton
        aria-label="Profile"
        aria-haspopup="true"
        data-cy="user-menu-button"
        onClick={handleClick}
      >
        {pictureUrl ? (
          <img className={classes.avatar} src={pictureUrl} alt="profile" />
        ) : (
          <Person />
        )}
      </IconButton>
      <Menu id="profile-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          {currentUser ? (
            <div>
              {`Logged in as `}
              <span className={classes.highlighted}>{currentUser.name}</span>
            </div>
          ) : (
            'Not logged in'
          )}
        </MenuItem>
        <Divider />
        {!deactivated && (
          <MenuItem
            data-cy="menu-edit-item-content-profile"
            onClick={() => {
              openEditProfile();
              handleClose();
            }}
          >
            Edit profile
          </MenuItem>
        )}
        {!deactivated && <Divider />}
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log out</MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(UserProfile);
