import { FC, MouseEvent, useEffect, useState } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import { MenuOption } from './SelectOption';
import SelectOptionList from './SelectOptionList';
import styles, { SelectVariants } from './SelectStyles';

interface SelectProps {
  classes: Classes<typeof styles>;
  variant?: SelectVariants;
  options: MenuOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose: (event?: any) => void;
}

const Select: FC<SelectProps> = ({ classes, options, variant, onClose }) => {
  // for animations
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
  // for menu -> component keyboard exchange
  const [isKeyboardDisabled, setIsKeyboardDisabled] = useState<boolean>(false);

  const [navigationHistory, setNavigationHistory] = useState<number[]>([]);
  const [currentOptions, setCurrentOptions] = useState<MenuOption[]>(options);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const highlightedOption = currentOptions[highlightedIndex];

  // the dropdown list area
  const dropdownArea = document!.getElementById('listbox-list')!;

  // handles the dynamic height of the dropdown list based on viewport height
  useEffect(() => {
    if (dropdownArea) {
      dropdownArea.style.maxHeight = `${(
        window.innerHeight - dropdownArea.getBoundingClientRect().top
      ).toString()}px`;
    }
  }, [dropdownArea]);

  const addBackHeader = (option: MenuOption) => {
    if (
      option &&
      option.subOptions &&
      option.subOptions[0] &&
      !option.subOptions[0].isRoot &&
      option.name
    ) {
      // eslint-disable-next-line no-param-reassign
      option.subOptions = [
        {
          name: option.name,
          color: option.color,
          disabled: false,
          subText: option.subText,
          isRoot: true,
          width: option.width,
          callback: () => {},
        },
        ...option.subOptions,
      ];
    }
  };

  const getOptionsFromIndex = (
    option: MenuOption,
    history: number[],
    index: number | undefined
  ): MenuOption => {
    if (history.length > 0 && index !== undefined) {
      return getOptionsFromIndex(
        index !== undefined && (option || {}).subOptions
          ? (option.subOptions || [])[index]
          : { name: '', callback: () => {} },
        history,
        history.shift()
      );
    }
    return index !== undefined && (option || {}).subOptions
      ? ((option || {}).subOptions || [])[index]
      : option;
  };

  useEffect(() => {
    const navigationHistoryLocal = [...navigationHistory];
    const currentOptionsLocal = getOptionsFromIndex(
      { subOptions: options, name: '', callback: () => {} },
      navigationHistoryLocal,
      navigationHistoryLocal.shift()
    );
    if (!currentOptionsLocal) return;
    addBackHeader(currentOptionsLocal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleSubOptions = () => {
    const { disabled } = highlightedOption || {};
    if (disabled) return;
    setSlideDirection('left');
    navigationHistory.push(highlightedIndex);
    const navigationHistoryLocal = [...navigationHistory];
    const currentOptionsLocal = getOptionsFromIndex(
      { subOptions: options, name: '', callback: () => {} },
      navigationHistoryLocal,
      navigationHistoryLocal.shift()
    );
    if (!currentOptionsLocal) return;
    addBackHeader(currentOptionsLocal);
    setHighlightedIndex(0);
    setCurrentOptions(
      currentOptionsLocal && currentOptionsLocal.subOptions ? currentOptionsLocal.subOptions : []
    );
    setNavigationHistory([...navigationHistory]);
  };

  const handleGoingBack = () => {
    if (navigationHistory.length < 1) {
      return;
    }
    if (isKeyboardDisabled) setIsKeyboardDisabled(false);
    setSlideDirection('right');
    const lastHighlighted = navigationHistory.pop();
    const navigationHistoryLocal = [...navigationHistory];
    const currentOptionsLocal = getOptionsFromIndex(
      { subOptions: options, name: '', callback: () => {} },
      navigationHistoryLocal,
      navigationHistoryLocal.shift()
    );
    if (!currentOptionsLocal) return;
    addBackHeader(currentOptionsLocal);
    setHighlightedIndex(lastHighlighted || 0);
    setCurrentOptions(currentOptionsLocal.subOptions ? currentOptionsLocal.subOptions : []);
    setNavigationHistory([...navigationHistory]);
  };

  // handles on option
  const handleOption = (option: MenuOption, e?: MouseEvent) => {
    const { callback, subOptions, isRoot, disabled, onClick } = option || {};
    if (disabled) return;
    if (onClick) onClick();
    if (subOptions) {
      // moving forward
      handleSubOptions();
    } else if (isRoot) {
      // moving backward
      handleGoingBack();
    } else if (callback) {
      // close menu on action chosen
      onClose();
      // option outer action
      callback(e);
    }
  };

  // handles adding a option (if it exists or not)
  const handleGoingForward = () => {
    const { subOptions } = highlightedOption || {};
    if (subOptions) handleSubOptions();
  };

  // used to figure out how far to scroll the dropdown area while arrowing through results
  const getcurrentListItemOffsetHeight = (): {
    currentListItemOffsetHeight: number;
  } => {
    const highlightedOptionIndex = currentOptions.indexOf(highlightedOption);
    const currentListItem =
      dropdownArea && dropdownArea.getElementsByTagName('li')
        ? dropdownArea.getElementsByTagName('li')[highlightedOptionIndex]
        : null;
    const currentListItemOffsetHeight = currentListItem ? currentListItem.offsetHeight - 0.5 : 0;

    return { currentListItemOffsetHeight };
  };

  // when a user hovers over an option
  const handleHover = (option: MenuOption): void => {
    const index = currentOptions.findIndex(({ name, key }: MenuOption) =>
      option.key ? key === option.key : name === option.name
    );
    setHighlightedIndex(index);
  };

  // handles all keyboard navigation interractions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleNavigation = (event: any) => {
    if (isKeyboardDisabled) return;
    const { key } = event;
    const hasMoreItems = highlightedIndex < currentOptions.length - 1;
    const hasLessItems = highlightedIndex > 0;
    const { currentListItemOffsetHeight } = getcurrentListItemOffsetHeight();
    // GOING DOWN THE LIST
    if (key === 'ArrowDown') {
      if (hasMoreItems) {
        setHighlightedIndex(highlightedIndex + 1);
        if (highlightedIndex > 2) {
          dropdownArea.scrollBy(0, currentListItemOffsetHeight);
        }
      }
    }
    // GOING UP THE LIST
    if (key === 'ArrowUp') {
      if (hasLessItems) {
        setHighlightedIndex(highlightedIndex - 1);
        dropdownArea.scrollBy(0, currentListItemOffsetHeight * -1);
      }
    }
    // GOING LEFT
    if (key === 'ArrowLeft') {
      handleGoingBack();
    }
    // GOING RIGHT
    if (key === 'ArrowRight') {
      handleGoingForward();
    }
    // APPLY
    if (key === 'Enter') {
      handleOption(highlightedOption);
    }
    // CLOSE
    if (key === 'Tab') {
      onClose(event);
    }
  };

  const disableKeyboard = () => {
    setIsKeyboardDisabled(true);
  };

  const enableKeyboard = () => {
    setIsKeyboardDisabled(false);
    handleOption(highlightedOption);
  };

  // watch for keyup events
  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  // Style classes
  let variantClass = '';
  if (variant && [SelectVariants.GRID, SelectVariants.ASSET].includes(variant)) {
    variantClass = classes.menuGrid;
  }

  return (
    <div className={`${classes.containerOuter} ${variantClass}`}>
      <SelectOptionList
        options={currentOptions}
        handleClick={handleOption}
        handleHover={handleHover}
        highlightedOption={highlightedOption}
        slideDirection={slideDirection}
        onClose={onClose}
        disableKeyboard={disableKeyboard}
        enableKeyboard={enableKeyboard}
      />
    </div>
  );
};

export default withStyles(styles)(Select);
