import { FC, useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { SIGN_UP } from '../../constants';
import { joinAuthContext } from '../JoinAuthProvider';
import { Button } from '../scales';

const LoginPage: FC = () => {
  const { loginWithRedirect, loginWithPopup } = useAuth0();
  const auth = useContext(joinAuthContext);

  const isIFramed = window.self !== window.top;

  // Login flow for app embedded in non join domains
  if (!auth.isAuthenticated && !auth.loggedIn && isIFramed) {
    return (
      <div className="bg-white-000 flex h-screen w-screen flex-col items-center justify-center space-y-4">
        <h3 className="type-heading2">You are trying to access Join</h3>
        <Button
          data-cy="login-popup-button"
          label="Log in to Join "
          onClick={() => loginWithPopup()}
          type="primary"
        />
      </div>
    );
  }

  // Normal login flow using redirect
  if (!auth.isAuthenticated && !auth.loggedIn) {
    const { search } = window.location;
    // Navigate to the signup page if the user does not have an auth0 account
    if (search.toLowerCase().includes(SIGN_UP)) {
      loginWithRedirect({ screen_hint: SIGN_UP });
    } else {
      loginWithRedirect();
    }
  }
  return <div className="bg-white-000 h-screen w-screen" />;
};
export default LoginPage;
