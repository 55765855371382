import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { isNonNullable } from '../../../utilities/types';
import useAssetsQuery from '../../assets/hooks/useAssetsQuery';
import useMemoWrapper from '../../useMemoWrapper';
import { getAssetForTeam, getOrderedTeam, getTeamAssetID, organizationLabels } from '../utilities';

export const useTeamSummaryImages = (
  team: Team | undefined,
  displayProjectID?: UUID
): TeamData[] => {
  // Getting permissions
  const { canView } = usePermissions({ projectID: displayProjectID });
  const canViewProjectDetails = canView(PermissionResource.PROJECT_DETAILS);
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);

  const teamGUIDs = useMemoWrapper(
    getTeamAssetID,
    team,
    canViewProjectDetails,
    canViewItemAttachments
  );
  const teamOrdered = useMemoWrapper(getOrderedTeam, team);

  const assets = useAssetsQuery(teamGUIDs).data?.assets?.filter(isNonNullable) ?? []; // TODO: Get thumbs!
  const organizationPictures = useMemoWrapper(getAssetForTeam, team, assets);

  const teamData = teamOrdered.map(
    (t: Organization, i: number) =>
      ({
        role: organizationLabels[i],
        image: organizationPictures[i],
        name: t.name,
      } as TeamData)
  );
  // don't return any organizations that are missing an image
  return teamData.filter((t: TeamData) => !!t.image);
};

export const emptyIsTeamLogos = (banners: (ProjectBanner | null)[] | null) =>
  banners && banners.length === 0;
