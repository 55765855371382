import { FC, useRef, useState } from 'react';

import { Button as MuiButton, Popover } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { reportItemsListEditItemsCTA } from '../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../theme/komodo-mui-theme';
import ItemsListClearBulkEditPanelHeader from '../ItemsList/BulkEditingPanel/BulkEditingPanelHeader/ItemsListBulkEditingPanelHeader';
import { Button } from '../scales';

import styles from './BulkEditPanelStyles';

interface BulkEditPanelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  categorySelectors: any;
  classes: Classes<typeof styles>;
  clearAllSelectedFields: () => void;
  disabled: boolean;
  itemsLikeIDsSelected: UUID[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  nonCategorySelectors: any;
  onSubmit: () => void;
}

const BulkEditPanel: FC<BulkEditPanelProps> = ({
  classes,
  categorySelectors,
  clearAllSelectedFields,
  disabled,
  itemsLikeIDsSelected,
  nonCategorySelectors,
  onSubmit,
}) => {
  const sendAnalytics = useAnalyticsEventHook();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleClick = () => {
    setAnchorEl(buttonRef.current);
    sendAnalytics(reportItemsListEditItemsCTA(itemsLikeIDsSelected.length));
  };

  return (
    <>
      <Button
        ref={buttonRef}
        label="Edit Items"
        data-cy="bulkEditPanel-editButton"
        startIcon={<Edit />}
        type="primary"
        onClick={handleClick}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <ItemsListClearBulkEditPanelHeader clearAllSelectedFields={clearAllSelectedFields} />
        <div className={classes.scroll}>
          {nonCategorySelectors && nonCategorySelectors}
          {categorySelectors && categorySelectors}
        </div>
        <div className={classes.applyChangesButtonContainer}>
          <MuiButton
            data-cy="bulkEditingPanel-ApplyChanges"
            title="Apply Changes"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={disabled}
            onClick={() => {
              onSubmit();
              setAnchorEl(null);
            }}
          >
            Apply Changes
          </MuiButton>
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles)(BulkEditPanel);
