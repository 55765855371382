import { FC, ReactElement } from 'react';

import usePermissions from '../../../utilities/permissions/usePermissions';
import ReportsListEntry, { ReportsListEntryType } from '../ReportsListEntry/ReportsListEntry';
import ReportsListEntrySettings, {
  ReportsListEntrySettingsType,
} from '../ReportsListEntry/ReportsListEntrySettings/ReportsListEntrySettings';
import ReportsListEntryStartReport, {
  ReportsListEntryStartReportType,
} from '../ReportsListEntry/ReportsListEntryStartReport/ReportsListEntryStartReport';

import { isReportsListEntryStartReportType } from './ReportsListUtils';

type ReportsListEntrySettingsVariationsType =
  | ReportsListEntrySettingsType
  | ReportsListEntryStartReportType;

type ReportsListEntrySettingsWrapType = {
  settings: ReportsListEntrySettingsVariationsType;
  hasDistributionScheduled?: boolean;
};

export interface ReportsListEntryDataType
  extends ReportsListEntryType,
    ReportsListEntrySettingsWrapType {}

type ReportsListProps = {
  placeholder?: ReactElement;
  reports: ReportsListEntryDataType[];
};

const ReportsList: FC<ReportsListProps> = ({ placeholder = null, reports }) => {
  const { isViewOnly } = usePermissions();

  if (!reports.length) return placeholder;

  const getSettingsComponent = (
    name: string,
    settings: ReportsListEntrySettingsVariationsType,
    hasDistributionScheduled: boolean
  ) =>
    isReportsListEntryStartReportType(settings) ? (
      <ReportsListEntryStartReport {...settings} isViewOnly={isViewOnly} />
    ) : (
      <ReportsListEntrySettings
        cy={`button-reportOptions-${name}`}
        {...settings}
        hasDistributionScheduled={hasDistributionScheduled}
      />
    );

  const reportsList = reports.map((report, i) => {
    const { settings } = report;
    const settingsComponent = getSettingsComponent(
      report.name,
      settings,
      report.hasDistributionScheduled ?? false
    );
    return <ReportsListEntry {...report} settings={settingsComponent} key={JSON.stringify(i)} />;
  });

  return <>{reportsList}</>;
};

export default ReportsList;
