import joinAPI from '../../../api/joinAPI';
import {
  DraftEstimateInfoQuery,
  DraftEstimateInfoQueryVariables,
  EstimateArmatureQuery,
  EstimateArmatureQueryVariables,
  EstimateLineIDsQuery,
  EstimateLineIDsQueryVariables,
  EstimateLinesQuery,
  EstimateLinesQueryVariables,
  GetEstimateSourceItemInfosQuery,
  GetEstimateSourceItemInfosQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../utilities/costMode';

import {
  draftEstimateInfoQuery,
  getEstimateSourceItemInfosQuery,
  gridEstimateArmatureQuery,
  gridEstimateLineIDsQuery,
  gridEstimateLinesQuery,
} from './queries';

export type EstimateArmature = EstimateArmatureQuery;

export type QueryResult<T> = {
  data?: T;
};

type GridEstimateLines = {
  data?: EstimateLinesQuery;
};

export const getEstimateLineIDs = (
  estimateID: UUID,
  estimateSortBy: EstimateSortBy | undefined,
  viewFilter: ViewFilterInput | undefined,
  onSuccess: (result: QueryResult<EstimateLineIDsQuery>) => void
) => {
  if (!estimateID) return;
  const variables: EstimateLineIDsQueryVariables = {
    estimateID,
    estimateSortBy,
    costMode: costModeVar(),
    viewFilter,
  };
  joinAPI
    .requestGraphQL(gridEstimateLineIDsQuery, variables)
    .then((r: QueryResult<EstimateLineIDsQuery>) => onSuccess(r));
};

export const getEstimateLines = (
  estimateID: UUID,
  estimateSortBy: EstimateSortBy | undefined,
  viewFilter: ViewFilterInput | undefined,
  pagination: Pagination | undefined,
  onSuccess: (result: GridEstimateLines) => void
) => {
  if (!estimateID) return;
  const variables: EstimateLinesQueryVariables = {
    estimateID,
    estimateSortBy,
    costMode: costModeVar(),
    viewFilter,
    pagination,
  };
  joinAPI
    .requestGraphQL(gridEstimateLinesQuery, variables)
    .then((r: GridEstimateLines) => onSuccess(r));
};

export const getEstimateArmature = (
  estimateID: UUID | undefined,
  estimateSortBy: EstimateSortBy | undefined,
  viewFilter: ViewFilterInput,
  pagination: Pagination | undefined,
  onSuccess: (result: EstimateArmature) => void
) => {
  if (!estimateID) return;
  const variables: EstimateArmatureQueryVariables = {
    estimateID,
    estimateSortBy,
    costMode: costModeVar(),
    viewFilter,
    pagination,
  };
  joinAPI
    .requestGraphQL(gridEstimateArmatureQuery, variables)
    .then((estimateQueryResult: QueryResult<EstimateArmatureQuery>) => {
      onSuccess({
        estimate: estimateQueryResult.data?.estimate,
      });
    });
};

export const useGetEstimateSourceItemInfos = (projectID?: UUID, estimateID?: UUID) =>
  useQuery<GetEstimateSourceItemInfosQuery, GetEstimateSourceItemInfosQueryVariables>(
    getEstimateSourceItemInfosQuery,
    {
      variables: { projectID, estimateID },
      skip: !projectID || !estimateID,
    }
  );

export const useGridEstimateArmatureQuery = (estimateID?: UUID, costMode?: CostMode) =>
  useQuery<EstimateArmatureQuery, EstimateArmatureQueryVariables>(gridEstimateArmatureQuery, {
    variables: { estimateID, costMode },
    skip: !estimateID || !costMode,
  });

export const useDraftEstimateInfoQuery = (estimateID?: UUID, costMode?: CostMode) =>
  useQuery<DraftEstimateInfoQuery, DraftEstimateInfoQueryVariables>(draftEstimateInfoQuery, {
    variables: { estimateID, costMode },
    skip: !estimateID || !costMode,
  });
