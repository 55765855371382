import { ProjectsCountsQuery, ProjectsCountsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { ProjectFilterState } from '../ProjectsListUtils';

import { projectsCountsQuery } from './queries';

function useProjectsCountsQuery(
  filterState: ProjectFilterState = {
    locations: [],
    statuses: [],
    companies: [],
    types: [],
  },
  searchTerm = ''
) {
  return useQuery<ProjectsCountsQuery, ProjectsCountsQueryVariables>(projectsCountsQuery, {
    variables: { projectsFilterBy: filterState, projectsSearch: searchTerm },
  });
}

export default useProjectsCountsQuery;
