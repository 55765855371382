import { useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { MILESTONE_OPTIONS, MILESTONE_OPTIONS_DELETE_MILESTONE } from '../../../tagConstants';
import theme from '../../../theme/design-system-mui-theme';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { MilestoneConfirmDelete } from '../../Dialogs';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { useDeleteMilestone } from '../hooks';

import MilestoneSettingsPanelStyles from './MilestoneSettingsPanelStyles';

type MilestoneSettingsPanelProps = {
  canDelete: boolean;
  classes: Classes<typeof MilestoneSettingsPanelStyles>;
  disabled: boolean;
  isActive: boolean;
  isDetails?: boolean;
  milestone: Milestone;
};

const MilestoneSettingsPanel: React.FC<MilestoneSettingsPanelProps> = ({
  canDelete,
  classes,
  disabled,
  isActive,
  isDetails,
  milestone,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const { name, id } = milestone;
  const [deleteMilestoneDialog, setDeleteMilestoneDialog] = useState(false);
  // ACTIONS
  const deleteMilestone = useDeleteMilestone();
  const onDeleteMilestoneApi = () => {
    deleteMilestone(projectId, id, true, () => {
      navigate(generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }));
    });
  };

  const dialogContent = `Are you sure you want to delete ${name}? Deleting a milestone is a permanent action and cannot be undone.`;
  const dialogTitle = `Confirm Delete Milestone: ${name}`;
  const onDeleteSubmit = () => onDeleteMilestoneApi();

  if (disabled) return null;
  const options: MenuOption[] = [];
  const deleteTooltip = isActive
    ? 'The milestone cannot be deleted while it is active. Please set a different milestone to active in order to deactivate it.'
    : 'The milestone cannot be deleted while it has items. Please transition them to a different milestone and try again.';

  options.push({
    color: canDelete ? theme.palette.ds.type.delete : undefined,
    name: 'Delete this Milestone',
    cy: MILESTONE_OPTIONS_DELETE_MILESTONE,
    disabled: !canDelete,
    callback: () => canDelete && setDeleteMilestoneDialog(true),
    tooltip: (!canDelete && deleteTooltip) || undefined,
  });

  return (
    <div className={isDetails ? classes.header : classes.optionsContainer}>
      <IconMenu id={MILESTONE_OPTIONS} data-cy={MILESTONE_OPTIONS} options={options} />
      <MilestoneConfirmDelete
        dialogContent={dialogContent}
        dialogTitle={dialogTitle}
        displayDialogsConfirmDelete={!!deleteMilestoneDialog}
        onClose={() => setDeleteMilestoneDialog(false)}
        onDeleteSubmit={onDeleteSubmit}
      />
    </div>
  );
};

export default withStyles(MilestoneSettingsPanelStyles)(MilestoneSettingsPanel);
