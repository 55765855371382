import { useCallback } from 'react';

import { searchAnalyticsEvent } from '../../../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';

const useSendSearchAnalytics = () => {
  const sendAnalytics = useSendAnalyticsEventHook();

  return useCallback(
    (
      type: Parameters<typeof searchAnalyticsEvent>[0],
      eventProperties?: Parameters<typeof searchAnalyticsEvent>[1]
    ) => {
      sendAnalytics(searchAnalyticsEvent(type, eventProperties));
    },
    [sendAnalytics]
  );
};

export default useSendSearchAnalytics;
