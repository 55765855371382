import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    padding: {
      paddingLeft: 8,
    },
    paddedOption: {
      paddingLeft: 4,
      minWidth: 120,
    },
    printMenu: { padding: 8 },
  });
