import { Formik } from 'formik';
import { FC, useState } from 'react';
import { isEmpty, trim } from 'validator';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  selectItemColumnCategorizationAnalytics,
  selectMilestoneColumnCategorizationAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { ITEM, NEW_CATEGORIZATION } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import CreateCategorization from '../categorization/CreateCategorization';
import ErrorMessage, { processError } from '../categorization/ErrorMessage';
import { useCreateCategorization } from '../tableUtils/CreateCategorizationHook';
import useUpdateCategorization from '../tableUtils/UpdateCategorizationHook';

import AddColumnDialogStyles from './AddColumnDialogStyles';

// eslint-disable-next-line
import JoinGridCategoryPanel from '../categorization/JoinGridCategoryPanel';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  addColumn: (categorizationID: UUID) => any;
  canCreate: boolean;
  classes: Classes<typeof AddColumnDialogStyles>;
  projectId: UUID;
  onClose: () => void;
  onNewCategorizationSelect: () => void;
  onSuccess: (c: Categorization) => void;
  estimateCategorizations: (UUID | undefined)[];
  variant: string;
};

const AddColumnDialog: FC<Props> = ({
  classes,
  addColumn,
  canCreate,
  projectId,
  onClose,
  onNewCategorizationSelect,
  onSuccess,
  estimateCategorizations,
  variant,
}) => {
  const [editingCategorizationID, setEditingCategorizationID] = useState<UUID | null>(null);
  const [selectedCategorizationID, setSelectedCategorizationID] = useState<UUID | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { data, loading, error } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const sendAnalytics = useAnalyticsEventHook();

  const [createCategorization, createCategorizationResult] = useCreateCategorization(
    projectId,
    (e: string) => setErrorMessage(e),
    (c: Categorization) => {
      if (onSuccess) onSuccess(c);
      setEditingCategorizationID(c.id);
    }
  );

  const dispatchSelectedColumnCategorizationAnalytics = (id: UUID) =>
    variant === ITEM
      ? sendAnalytics(selectItemColumnCategorizationAnalytics(id))
      : sendAnalytics(selectMilestoneColumnCategorizationAnalytics(id));

  const created =
    createCategorizationResult.data?.createCategorization &&
    'id' in createCategorizationResult.data.createCategorization
      ? createCategorizationResult.data?.createCategorization
      : {
          id: '',
          name: '',
          description: '',
        };

  const updateCategorization = useUpdateCategorization(
    created.id,
    setErrorMessage,
    (e) => setErrorMessage(processError(e)),
    projectId
  );

  if (loading) return null;
  if (error) return <div>Error Loading Categorizations</div>;
  // for dropdown
  const value = selectedCategorizationID || '';
  const categorizationID =
    selectedCategorizationID === NEW_CATEGORIZATION
      ? editingCategorizationID
      : selectedCategorizationID;
  const entries = categorizations.map((c: Categorization) =>
    getEntry(
      c.id,
      removeYear(c.name, (c as CategorizationFeDefined).builtin) || '',
      estimateCategorizations.includes(c.id)
    )
  );
  if (canCreate) entries.push(getEntry(NEW_CATEGORIZATION, 'New categorization...'));

  const onChangeCategorization = (cID: UUID | null) => {
    if (!cID) return;
    if (cID === NEW_CATEGORIZATION) {
      // Create new categorization
      onNewCategorizationSelect();
      onClose();
    } else {
      // Select existing categorization
      setSelectedCategorizationID(cID);
      dispatchSelectedColumnCategorizationAnalytics(cID);
      setEditingCategorizationID(null);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <IconButton className={classes.alignCloseButton}>
        <Close onClick={onClose} />
      </IconButton>
      <DialogTitle title="Add Column">Add Column</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="caption">Categorization for the new column</Typography>
        <JoinSelect
          cySelect="select-AddColumnDialog"
          entries={entries}
          onChange={onChangeCategorization}
          valuePlaceholder="Select a categorization..."
          value={value}
        />
      </DialogContent>
      {value === NEW_CATEGORIZATION && (
        <div className={classes.shrink}>
          <Divider />
          <DialogContent className={classes.content}>
            {!editingCategorizationID ? (
              <Formik
                initialValues={{
                  name: created.name,
                }}
                onSubmit={(values) => {
                  if (!isEmpty(values.name, { ignore_whitespace: true })) {
                    createCategorization(trim(values.name));
                  }
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <form>
                    <div className={classes.createCategorization}>
                      <CreateCategorization
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        setErrorMessage={setErrorMessage}
                        values={values}
                      />
                      <ErrorMessage message={errorMessage} />
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <>
                <JoinGridCategoryPanel
                  classes={classes}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                  categorization={categorizations.find((c: any) => c.id === created.id) || created}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  projectId={projectId}
                  permissions={{ canView: true, canEdit: true }}
                  updateCategorization={updateCategorization}
                />
              </>
            )}
          </DialogContent>
        </div>
      )}
      <Divider />
      <DialogActions>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          data-cy="buttonDone-AddColumnDialog"
          disabled={!categorizationID}
          onClick={() => {
            // if column is undefined than button is disabled, not possible to click
            if (categorizationID) {
              addColumn(categorizationID);
              setEditingCategorizationID(null);
            }
          }}
        >
          Add Column
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(AddColumnDialogStyles)(AddColumnDialog);
