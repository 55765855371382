import { IMPORT_ESTIMATE_HELP_CTA } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { importEstimateHelpDialogVar } from '../../../api/apollo/reactiveVars';
import { AnalyticsEvent } from '../../../hooks/useAnalyticsEventHook';
import truncateLabel from '../../../utilities/dashboard';
import { ItemDescriptors, generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';

export const getItemText = (item: ItemDescriptors, toastTitleLength: number) =>
  truncateLabel(generateTitle(item), toastTitleLength, true);

export const openImportHelp = (sendAnalytics: (analyticsEvent: AnalyticsEvent) => void) => {
  const helpDialog = importEstimateHelpDialogVar();
  importEstimateHelpDialogVar({ isOpen: true, platform: helpDialog.platform });
  sendAnalytics(analyticsEvent(IMPORT_ESTIMATE_HELP_CTA));
};
