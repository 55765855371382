import { FC } from 'react';

import { UserSource } from '../../../generated/graphql';
import useMemoWrapper from '../../useMemoWrapper';
import FilesTable from '../Files/FilesTable/FilesTable';
import { useFilesTableSelection } from '../Files/FilesTable/FilesTableUseUtils';
import { FileTableCell } from '../Files/FilesTable/FilesTableUtils';

import FileExplorerHubProjects from './FileExplorerHubProjects';
import FileExplorerZeroState from './FileExplorerZeroState';
import FilesExplorerError from './FilesExplorerError';
import { getErrorMessage, getFileTableHubRootEntries } from './FilesExplorerUtils';
import { useLoadSourceHubRootQuery } from './hooks/FileExplorerHooks';

type FileExplorerHubsRootProps = {
  userSource: UserSource;
};

const FileExplorerHubsRoot: FC<FileExplorerHubsRootProps> = ({ userSource }) => {
  const { data, error, loading } = useLoadSourceHubRootQuery(
    userSource.sourceSystem,
    userSource.id
  );
  const hubsRoot = data?.sourceHubRoot;
  const fileTableEntries = useMemoWrapper(getFileTableHubRootEntries, hubsRoot);
  const { onEntry, onToggleEntry, selectedIds, onSelectAll, onUnselectAll } =
    useFilesTableSelection();

  const errorMessage = getErrorMessage(hubsRoot, error);
  if (errorMessage) return <FilesExplorerError error={errorMessage} userSource={userSource} />;

  const onTableCell = (entry: FileTableCell) => {
    onEntry(entry, <FileExplorerHubProjects userSource={userSource} hubID={entry.id} />);
  };

  const isZeroState = !loading && fileTableEntries.length === 0;
  // const isZeroState = !loading;

  if (isZeroState) return <FileExplorerZeroState userSource={userSource} />;

  return (
    <FilesTable
      entries={fileTableEntries}
      loading={loading}
      onEntry={onTableCell}
      onToggleEntry={onToggleEntry}
      selectedIds={selectedIds}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
    />
  );
};

export default FileExplorerHubsRoot;
