import { ApolloClient } from '@apollo/client';

import {
  FilesSortBy,
  ProjectAssetsQuery,
  ProjectAssetsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { projectAssetsQuery } from './queries';

export const readProjectAssets = (
  client: ApolloClient<object>,
  id: UUID,
  sortBy?: FilesSortBy
): Asset[] => {
  const data = client.readQuery<ProjectAssetsQuery, ProjectAssetsQueryVariables>({
    query: projectAssetsQuery,
    variables: { id, sortBy },
  });
  return data?.projectAssets ?? [];
};

export const writeProjectAssets = (
  client: ApolloClient<object>,
  projectAssets: Asset[],
  id: UUID,
  sortBy?: FilesSortBy
) => {
  client.writeQuery<ProjectAssetsQuery, ProjectAssetsQueryVariables>({
    query: projectAssetsQuery,
    data: {
      projectAssets,
    },
    variables: { id, sortBy },
  });
};

function useProjectAssetsQuery(id: UUID, sortBy?: FilesSortBy) {
  return useQuery<ProjectAssetsQuery, ProjectAssetsQueryVariables>(projectAssetsQuery, {
    variables: { id, sortBy },
    skip: !id,
  });
}

export default useProjectAssetsQuery;
