import { useMutation } from '@apollo/client';

import { updateCollaboratorResponsibility } from '../../../analytics/analyticsEventProperties';
import {
  UpdateCollaboratorResponsibilityMutation,
  UpdateCollaboratorResponsibilityMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { updateCollaboratorResponsibilityMutation } from './queries';

function useCollaboratorResponsibility() {
  const [collaboratorResponsibilityFunc, mutationResult] = useMutation<
    UpdateCollaboratorResponsibilityMutation,
    UpdateCollaboratorResponsibilityMutationVariables
  >(updateCollaboratorResponsibilityMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (projectID: UUID, collaboratorID: UUID, responsibility: string) =>
    collaboratorResponsibilityFunc({
      variables: { projectID, collaboratorID, responsibility },
    }).then(() => {
      sendAnalytics(updateCollaboratorResponsibility(responsibility));
    });

  return [submitFunc, mutationResult] as const;
}

export default useCollaboratorResponsibility;
