import { useEffect } from 'react';

import {
  ForecastingItemsQuery,
  ForecastingItemsQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../../../hooks/useQueryPagination';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { forecastingItems } from './queries';

export const useForecastingItemsQuery = (
  variablesOuter: ForecastingItemsQueryVariables,
  skip?: boolean
) => {
  // Update project type names for project filters and item search filters
  const variables = useTypesIdsToNames(
    variablesOuter,
    'projectsFilters.types',
    'filters.projectTypes'
  );
  const query = useQuery<ForecastingItemsQuery, ForecastingItemsQueryVariables>(forecastingItems, {
    variables,
    skip,
  });

  const { fetchMore, resetPagination } = useQueryPagination(
    variables.pagination.limit,
    query.data?.forecastingItems?.data.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, variables]);

  return {
    ...query,
    fetchMore,
  };
};
