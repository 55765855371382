import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { AppBar, IconButton, Typography } from '@material-ui/core';
import { Add, Close, Menu as MenuIcon } from '@material-ui/icons';

import { setItemsListNewItemDialogOpen } from '../../../analytics/analyticsEventProperties';
import {
  collaboratorDialogOpenVar,
  drawerOpenVar,
  newItemDialogOpenVar,
  useTitleHeight,
} from '../../../api/apollo/reactiveVars';
import { JoinRoutes } from '../../../api/gqlEnums';
import { PermissionResource } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useHidePreviewBar from '../../../hooks/useHidePreviewBar';
import { hasModuleEntitlement } from '../../../hooks/useModuleEntitlementsQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { NamedLink } from '../../../utilities/routes/types';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import useAssetQuery from '../../assets/hooks/useAssetQuery';
import { DialogsAddCollaborator, DialogsEditProfile, DialogsNewItem } from '../../Dialogs';
import GlobalSearch from '../../HomeTab/Search/global/GlobalSearch';
import NotificationsIconWithPanel from '../../InAppNotifications/InAppNotificationsIconWithPanel/InAppNotificationsIconWithPanel';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import Wordmark from '../../logo/Wordmark.svg';
import { ModuleEntitlementsStore } from '../../ModuleEntitlementsProvider';
import useProjectsCountsQuery from '../../ProjectsList/hooks/useProjectsCountsQuery';
import { ReportManagerAnchors } from '../../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import CTAIconButton from '../../shared-widgets/CTAIconButton';
import NewProjectButton from '../../shared-widgets/NewProjectButton/NewProjectButton';
import TeamSummary from '../../Team/TeamSummary/TeamSummary';
import AdminViewOnlyBar from '../AdminViewOnlyBar';
import EditTemplateBar from '../EditTemplateBar';
import SupportMenu from '../SupportMenu';
import UserProfile from '../UserProfile';

import styles, { NAV_HEIGHT } from './FrameTitleBarStyles';
import { getTitleBarSelectedFromURL } from './FrameTitleBarUtils';
import FrameTitlePreviewBar from './FrameTitlePreviewBar';
import { PREVIEW_HEIGHT } from './FrameTitlePreviewBarStyles';
import NavigationTabsBar from './NavigationTabsBar';

type FrameTitleBarProps = {
  classes: Classes<typeof styles>;
  deactivated?: boolean;
  hasDrawer: boolean;
  hasSidebar: boolean;
  hideProfile?: boolean;
  links?: NamedLink[];
};

const FrameTitleBar: FC<FrameTitleBarProps> = ({
  classes,
  deactivated = false,
  hasDrawer,
  hasSidebar,
  hideProfile = false,
  links = [],
}) => {
  const projectId = getProjectIdFromUrl();
  const showAddCollaboratorDialog = useReactiveVar(collaboratorDialogOpenVar);
  const titleHeight = useTitleHeight();
  const hidePreviewBar = useHidePreviewBar();
  const isInDrawer = hasDrawer && hideProfile;
  const showPreviewBar = !hidePreviewBar && !isInDrawer;

  const drawerOpen = useReactiveVar(drawerOpenVar);
  const sendAnalytics = useAnalyticsEventHook();
  const {
    data: { project: projectProps },
    loading: propsLoading,
  } = useProjectPropsQuery(projectId, MountPolicy.SKIP_ON_MOUNT);
  const { data: { projectsCounts } = { projectsCounts: null }, loading: projectsLoading } =
    useProjectsCountsQuery();
  const noProjects =
    !!projectsCounts && !projectsCounts.allProjectsCount && !projectsCounts.myProjectsCount;
  const projectsAfterLoad = !propsLoading && !projectsLoading && !noProjects;

  const moduleEntitlementFlags = useContext(ModuleEntitlementsStore);
  const canCreateProject = hasModuleEntitlement(moduleEntitlementFlags, PROJECT_CREATION);

  const { canView, canAdd, isEditTemplate, isViewOnly } = usePermissions();
  const canViewProjectDetails = canView(PermissionResource.PROJECT_DETAILS);
  const canAddItem = canAdd(PermissionResource.ITEM_DETAILS);
  const showAdminViewOnlyBar = isViewOnly && !isInDrawer;
  const showEditTemplateBar = isEditTemplate && !isInDrawer;

  const { activeMilestone } = projectProps || {};
  const { id: activeMilestoneId } = activeMilestone || {};
  const newItemOpen = useReactiveVar(newItemDialogOpenVar);
  const { thumbnail: imageGUID } = projectProps || {};
  const queriedAsset = canViewProjectDetails ? imageGUID : null;
  const { data: { asset = null } = {} } = useAssetQuery(queriedAsset || null);
  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);

  const sidebarVarHeight = !hasSidebar ? NAV_HEIGHT : titleHeight;
  const height =
    showAdminViewOnlyBar || showEditTemplateBar
      ? sidebarVarHeight + PREVIEW_HEIGHT
      : sidebarVarHeight;

  // Variable Content
  const showNav = !hasDrawer;

  // Print header
  const itemId = getItemIdFromUrl();
  const { data: { item: currentItem = null } = {} } = useItemQuery(itemId);
  const milestoneName = itemId
    ? currentItem && currentItem.milestone && currentItem.milestone.name
    : '';

  // Selected Tab: Dashboard, Reports, Items ...
  const selected = getTitleBarSelectedFromURL();

  const logo = (
    <Link to={generateSharedPath(JoinRoutes.PROJECTS, {})}>
      <img alt="Join" src={Wordmark} className={classes.logo} data-cy="nav-logo-link" />
    </Link>
  );

  // hasDrawerSidebar is true if there is a sidebar and a drawer
  const hasDrawerSidebar = hasSidebar && hasDrawer;
  const drawerButton = hasDrawerSidebar && (
    <IconButton
      color="inherit"
      data-cy="FrameTitleBar-drawerButton"
      aria-label="Open drawer"
      onClick={() => drawerOpenVar(!drawerOpen)}
    >
      {drawerOpen ? <Close /> : <MenuIcon />}
    </IconButton>
  );

  return (
    <div style={{ height }}>
      <AppBar elevation={0} className={classes.appBar}>
        {showAdminViewOnlyBar && <AdminViewOnlyBar />}
        {showEditTemplateBar && <EditTemplateBar showBottomBorder={!showPreviewBar} />}
        {showPreviewBar && <FrameTitlePreviewBar />}
        <div className={classes.menuBar} id={ReportManagerAnchors('app')}>
          <div className={classes.leftContent}>
            {drawerButton}
            {(!hasDrawerSidebar || hideProfile) && logo}
            {!isInDrawer && <GlobalSearch />}
          </div>
          {showNav && !deactivated && <NavigationTabsBar links={links} />}
          {!hideProfile && (
            <div className={classes.buttons}>
              {projectId && !isInDrawer && (
                <CTAIconButton
                  dataCy="button-addNewItem"
                  disabled={newItemOpen || isViewOnly || !canAddItem}
                  icon={<Add />}
                  isSmaller
                  onClick={() => {
                    newItemDialogOpenVar(true);
                    sendAnalytics(setItemsListNewItemDialogOpen(true));
                  }}
                  buttonText="New Item"
                />
              )}
              {!projectId &&
                !isInDrawer &&
                projectsAfterLoad &&
                canCreateProject &&
                !deactivated && (
                  <div className={noProjects ? '' : classes.topBar} hidden={!!noProjects}>
                    <NewProjectButton />
                  </div>
                )}
              {activeMilestoneId && (
                <DialogsNewItem
                  onClose={() => {
                    newItemDialogOpenVar(false);
                    sendAnalytics(setItemsListNewItemDialogOpen(false));
                  }}
                  milestoneID={activeMilestoneId}
                  open={newItemOpen}
                />
              )}
              {showAddCollaboratorDialog && (
                <DialogsAddCollaborator onClose={() => collaboratorDialogOpenVar(false)} />
              )}
              {editProfileDialogOpen && (
                <DialogsEditProfile onClose={() => setEditProfileDialogOpen(false)} />
              )}
              {!deactivated && <NotificationsIconWithPanel />}
              <SupportMenu />
              <UserProfile
                deactivated={deactivated}
                openEditProfile={() => {
                  setEditProfileDialogOpen(true);
                }}
              />
            </div>
          )}
        </div>
      </AppBar>
      <div id="pageHeader" className={classes.printFrame}>
        <div style={{ width: '100%', marginLeft: 48 }}>
          <TeamSummary projectID={projectId} />
        </div>
        <div className={classes.noWrap}>
          <div className={classes.thumbnail}>
            {asset ? (
              <img alt="thumb" className={classes.avatar} src={asset.blobUrl ?? undefined} />
            ) : null}
          </div>
          <Typography
            className={classes.printTypography}
            style={{ flex: '0 0 auto' }}
            variant="headline"
          >
            {projectProps?.name}
          </Typography>
        </div>
        <Typography
          className={classes.printTypography}
          style={{ textAlign: 'right' }}
          variant="subheading"
        >
          {milestoneName}
          {!itemId && !activeMilestoneId && ' -'}
          {!activeMilestoneId && ` ${selected.charAt(0).toUpperCase() + selected.slice(1)}`}
        </Typography>
      </div>
    </div>
  );
};

export const TitleBarStyled = withStyles(styles)(FrameTitleBar);

export default TitleBarStyled;
