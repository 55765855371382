import { commonCollaboratorFields, commonUserFields, gql } from '../../../api/graphqlFragments';
import { userLinkFields, userLinkFieldsWithoutPicture } from '../../ItemsList/hooks/itemsListQuery';

export const usersByIDsQuery = gql`
  query usersByIDs($IDs: [UUID!]!) {
    usersByIDs(IDs: $IDs) {
      ...userLinkFieldsWithoutPicture
    }
  }
  ${userLinkFieldsWithoutPicture}
`;

export const assigneeByIdQuery = gql`
  query assigneeById($ID: UUID!) {
    userByID(ID: $ID) {
      ...userLinkFields
    }
  }
  ${userLinkFields}
`;

export const getProjectNameQuery = gql`
  query getProjectName($projectID: UUID!) {
    project(id: $projectID) {
      id
      name
    }
  }
`;

export const loadCollaboratorsQuery = gql`
  query loadCollaborators($projectId: UUID!) {
    collaborators(project: $projectId) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const createCollaboratorsMutation = gql`
  mutation createCollaborators(
    $projectID: UUID!
    $collaborators: [CollaboratorInput!]!
    $message: String
  ) {
    addCollaborators(projectID: $projectID, collaborators: $collaborators, message: $message) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

// Unused query
export const loadCollaboratorsInviterQuery = gql`
  query loadCollaboratorsInviter($projectId: UUID!) {
    collaborators(project: $projectId) {
      ...commonCollaboratorFields
      inviter {
        ...commonUserFields
        status
      }
    }
  }
  ${commonUserFields}
  ${commonCollaboratorFields}
`;

export const updateCollaboratorAllTradesMutation = gql`
  mutation updateCollaboratorAllTrades(
    $projectID: UUID!
    $collaboratorId: UUID!
    $tradeSetting: Boolean!
  ) {
    setCollaboratorAllTrades(
      projectID: $projectID
      collaboratorID: $collaboratorId
      allTrades: $tradeSetting
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const updateCollaboratorRoleMutation = gql`
  mutation updateCollaboratorRole(
    $projectID: UUID!
    $collaboratorID: UUID!
    $roleID: UUID!
    $tradeIDs: [UUID!]
  ) {
    setCollaboratorRole(
      projectID: $projectID
      collaboratorID: $collaboratorID
      roleID: $roleID
      tradeIDs: $tradeIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const updateCollaboratorResponsibilityMutation = gql`
  mutation updateCollaboratorResponsibility(
    $projectID: UUID!
    $collaboratorID: UUID!
    $responsibility: String!
  ) {
    setCollaboratorResponsibility(
      projectID: $projectID
      collaboratorID: $collaboratorID
      responsibility: $responsibility
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const deleteCollaboratorMutation = gql`
  mutation deleteCollaborator($projectID: UUID!, $collaboratorId: UUID!) {
    removeCollaborator(projectID: $projectID, collaborator: $collaboratorId)
  }
`;

export const addCollaboratorTradesMutation = gql`
  mutation addCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    addTrades(projectID: $projectID, collaboratorID: $collaboratorID, categoryIDs: $categoryIDs) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const removeCollaboratorTradesMutation = gql`
  mutation removeCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    removeTrades(
      projectID: $projectID
      collaboratorID: $collaboratorID
      categoryIDs: $categoryIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;
