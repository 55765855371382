import { DeleteOutline, Edit, LinkOutlined, OpenInNew } from '@material-ui/icons';

import { forecastingEventTypes } from '../../../../analytics/analyticsEventProperties';
import { FORECASTING_REPORTS_ICON_MENU } from '../../../../tagConstants';
import IconMenu from '../../../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../../../Select/SelectMenu/SelectStyles';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';

const IconMenuCell = ({
  onAddToProject,
  onDelete,
  onEdit,
  onLink,
  report,
}: {
  onAddToProject: (report: ForecastingReport) => void;
  onDelete: (report: ForecastingReport) => void;
  onEdit: (report: ForecastingReport) => void;
  onLink: (report: ForecastingReport) => void;
  report: ForecastingReport;
}) => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();
  const handleMenuEvent = (select: string) => {
    sendForecastingAnalytics(forecastingEventTypes.MENU, {
      location: 'reports',
      reportId: report.id,
      reportName: report.name,
      select,
    });
  };

  const menuOptions = [
    {
      icon: <OpenInNew />,
      name: 'Go to report',
      callback: () => {
        onLink(report);
        handleMenuEvent('view report');
      },
    },
    {
      icon: <LinkOutlined />,
      name: 'Add to a project',
      disabled: Boolean(report.attachedProject),
      callback: () => {
        onAddToProject(report);
        handleMenuEvent('add to project');
      },
    },
    {
      icon: <Edit />,
      name: 'Edit report',
      callback: () => {
        onEdit(report);
        handleMenuEvent('edit');
      },
    },
    {
      icon: <DeleteOutline />,
      name: 'Delete report',
      color: 'red',
      callback: () => {
        onDelete(report);
        handleMenuEvent('delete');
      },
    },
  ];

  return (
    <IconMenu
      cy={`${FORECASTING_REPORTS_ICON_MENU}-${report.name}`}
      variant={SelectVariants.UNSTYLED}
      options={menuOptions}
    />
  );
};

export default IconMenuCell;
