import { FC } from 'react';
import { useUpdate } from 'react-use';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { renderCostString } from '../../CostReport/CostReportUtils';
import { GridController } from '../../JoinGrid/types';
import styles from '../../Select/CollapseEntry/CollapseEntryStyles';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

type SectionCollapseProps = {
  classes: Classes<typeof styles>;
  collapsed: boolean;
  grid: GridController;
  setCollapse: (expanded: boolean) => void;
  term: string;
  disabled?: boolean;
  termSuffix?: string;
};

const getSubtotalValue = (grid: GridController) => {
  const { string } = grid.footer?.data.data.value as RegularCell;
  return { value: string };
};

const collapseDisplayString = (term: string, grid: GridController, termSuffix?: string) =>
  `${term} (${grid.numRows()})${termSuffix || ''}`;

const SectionCollapse: FC<SectionCollapseProps> = ({
  classes,
  disabled = false,
  collapsed,
  grid,
  setCollapse,
  term,
  termSuffix,
}) => {
  // This is the not-so-elegant way of hooking the grid up to this component to update it
  const update = useUpdate();
  grid.setUpdateHeader(update);
  const cost = getSubtotalValue(grid);

  return (
    <div className={classes.container}>
      <div className={`${classes.root} ${classes.entryHeader}`}>
        <div
          data-cy={`collapse-${term}`}
          className={classes.header}
          onClick={() => !disabled && setCollapse(!collapsed)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-1}
        >
          <div className={classes.rowTitle}>
            {!disabled && <CollapseIcon isCollapsed={collapsed} />}
            <div className={classes.rowTitleInner}>
              <Typography variant="subheading" className={classes.title}>
                {collapseDisplayString(term, grid, termSuffix)}
              </Typography>
              {cost && collapsed && (
                <div className={classes.costPadding}>
                  <Typography className={classes.number}>
                    {renderCostString({
                      cost,
                      isWide: true,
                      isExact: true,
                    })}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SectionCollapse);
