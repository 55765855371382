import { FC, ReactElement } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { isString } from '../../../utilities/string';

import styles from './HelpTipStyles';

type HelpTipProps = {
  classes: Classes<typeof styles>;
  tip: string | ReactElement;
};

const HelpTip: FC<HelpTipProps> = ({ classes, tip }) => (
  <div className={classes.tipContainer}>
    <Typography className={classes.tipHeader}>Tip:&nbsp;</Typography>
    {isString(tip) ? <Typography className={classes.tipText}>{tip}</Typography> : tip}
  </div>
);

const StyledHelpTip = withStyles(styles)(HelpTip);

export default StyledHelpTip;
