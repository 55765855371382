import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    editButton: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  });

export default styles;
