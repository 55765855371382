import { CSSProperties, DragEvent, FC, SyntheticEvent, useState } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import AssetsCardAddFile from '../AssetsCard/AssetsCardAddFile';

import AssetsCardsDropzoneStyles from './AssetsCardsDropzoneStyles';

type AssetsCardsDropzoneProps = {
  assetsLoading?: boolean; // "for new"
  children: JSX.Element | JSX.Element[];
  classes: Classes<typeof AssetsCardsDropzoneStyles>;
  disabled?: boolean;
  hasAddPermission?: boolean;
  onDropFile: (file: File) => void;
  openDialog?: () => void;
  styles?: CSSProperties;
};

const AssetsCardsDropzone: FC<AssetsCardsDropzoneProps> = ({
  assetsLoading,
  children,
  classes,
  disabled,
  hasAddPermission,
  onDropFile,
  openDialog,
  styles = {},
}) => {
  // State
  const [lastTarget, setLastTarget] = useState<EventTarget | null>(null);
  const [dragging, setDragging] = useState(false);

  // Functions
  function onDragEnter(evt: SyntheticEvent<HTMLDivElement>) {
    evt.preventDefault();
    setLastTarget(evt.target);
    setDragging(true);
  }

  function onDragLeave(evt: SyntheticEvent<HTMLDivElement>) {
    evt.preventDefault();
    if (evt.target === lastTarget) {
      setDragging(false);
    }
  }

  const onDrop = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;
    addFiles(files);
  };

  const addFiles = (files?: FileList | null) => {
    for (let i = 0; files && i < files.length; i += 1) {
      onDropFile(files[i]);
    }
    setDragging(false);
  };

  const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  // Content
  const getDraggingClass = () => {
    if (dragging) {
      return classes.targetDroppable;
    }
    return classes.target;
  };

  return (
    <div
      data-cy="assets-card-drop"
      className={`${classes.root} ${getDraggingClass()}`}
      onDragEnter={!disabled ? onDragEnter : undefined}
      onDrop={!disabled ? onDrop : undefined}
      onDragLeave={!disabled ? onDragLeave : undefined}
      onDragOver={!disabled ? onDragOver : undefined}
      style={styles}
    >
      {children}
      {hasAddPermission && !assetsLoading && (
        <AssetsCardAddFile onClick={openDialog} dragging={dragging} />
      )}
    </div>
  );
};

export default withStyles(AssetsCardsDropzoneStyles)(AssetsCardsDropzone);
