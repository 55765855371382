import { useMutation } from '@apollo/client';

import { UPDATE_IN_APP_NOTIFICATION_SETTING } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { REFETCH_IN_APP_NOTIFICATIONS_BADGE } from '../../../api/refetchSets';
import {
  UpdateUserInAppNotificationSettingMutation,
  UpdateUserInAppNotificationSettingMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { updateUserInAppNotificationSettingMutation } from './queries';

function useUpdateUserInAppNotificationSettingMutation() {
  const [updateUserInAppNotificationSettingFunc] = useMutation<
    UpdateUserInAppNotificationSettingMutation,
    UpdateUserInAppNotificationSettingMutationVariables
  >(updateUserInAppNotificationSettingMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectID: UUID,
    userNotificationSetting: UpdateUserInAppNotificationSettingMutation['updateUserInAppNotificationSetting'],
    onSuccess?: (
      userNotificationSetting: UpdateUserInAppNotificationSettingMutation['updateUserInAppNotificationSetting']
    ) => void,
    onFailure?: () => void
  ) =>
    updateUserInAppNotificationSettingFunc({
      variables: {
        projectID,
        ...userNotificationSetting,
      },
      refetchQueries: REFETCH_IN_APP_NOTIFICATIONS_BADGE,
    })
      .then((result) => {
        const updateUserInAppNotificationSetting = result.data?.updateUserInAppNotificationSetting;
        if (onSuccess && updateUserInAppNotificationSetting)
          onSuccess(updateUserInAppNotificationSetting);
        sendAnalytics(analyticsEvent(UPDATE_IN_APP_NOTIFICATION_SETTING));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  return submitFunc;
}

export default useUpdateUserInAppNotificationSettingMutation;
