import { FC } from 'react';

import { List } from '@material-ui/core';

import { ItemType } from '../../../generated/graphql';
import { useAttachItem } from '../ItemsHooks';
import ItemsListItemActions from '../ItemsListItem/ItemsListItemActions/ItemsListItemActions';
import ItemsListItemDataCta from '../ItemsListItem/ItemsListItemCta/ItemsListItemDataCta';
import { getStatusIsAcceptedIncorporated } from '../ItemsUtils';

type ItemsOptionsListAddExistingProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  convertItem: () => Promise<any>;
  items: ItemsListItem[];
  parent: Item;
  projectID: UUID;
  projectItems: ItemsListItem[];
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
};

export const getCanAttachItem = (parentStatus: string, itemStatus: string) =>
  !(getStatusIsAcceptedIncorporated(parentStatus) && getStatusIsAcceptedIncorporated(itemStatus));

export const ItemsOptionsListAddExisting: FC<ItemsOptionsListAddExistingProps> = ({
  convertItem,
  items,
  parent,
  projectID,
  projectItems,
  sharedUsersMap,
}) => {
  const [attachItem] = useAttachItem(projectID, parent);
  const onItemCtaClick = (child: ItemsListItem) => {
    if (parent.itemType === ItemType.ITEM_WITH_OPTIONS) {
      // is item with options
      attachItem(child);
    } else {
      // if the item hasn't been converted to "Item With Options" yet
      convertItem().then(() => {
        attachItem(child);
      });
    }
  };

  // COMPONENT
  return (
    <List>
      {items &&
        items.map((item) => {
          const canAttachItem = getCanAttachItem(parent.status, item.status);
          let onCtaClick;
          if (item && canAttachItem) {
            onCtaClick = () => onItemCtaClick(item);
          }
          // return an Option's parent
          const optionParentItem =
            item.parent && projectItems
              ? projectItems.find((i) => i.id === item.parent?.id)
              : undefined;

          const disabled =
            item.itemType === ItemType.ITEM_WITH_OPTIONS || !!item.parent || !canAttachItem; // Boolean to boolean
          return (
            <ItemsListItemDataCta
              hasCtaButton
              disabled={disabled}
              item={item}
              key={item.id}
              link=""
              sharedUsers={sharedUsersMap.get(item.id) ?? []}
            >
              <ItemsListItemActions
                item={item}
                onCtaClick={onCtaClick}
                parentItem={optionParentItem}
              />
            </ItemsListItemDataCta>
          );
        })}
    </List>
  );
};
