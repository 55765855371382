import { FC } from 'react';

import { ProjectCompSectionType } from '../../../../api/gqlEnums';
import {
  CostTableColumnInputKey,
  ProjectCompsCostTableColumnValue,
} from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import {
  formatGridTemplateColumns,
  getFormattedColumnValue,
} from '../../../ProjectComps/ProjectCompsCostTable/ProjectCompCostTableUtils';
import { useIsProjectCompSectionCollapsed } from '../../../ProjectComps/ProjectCompsSetUtils';
import { COLUMN_FONT_CLASSES, isAverageCompType } from '../PrintProjectCompsUtils';

import PrintProjectCompRow from './PrintProjectCompRow';
import styles from './PrintProjectCompStyles';

type CostCell = Cost | number;

const isZeroPercent = (value: CostCell, isCost: boolean) => !isCost && (value as number) === 0;

type PrintCompColumnProps = {
  classes: Classes<typeof styles>;
  compData: ProjectComp | AverageComp;
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  headerHeight: number;
  rowHasBreakpoint: (rowIndex: number) => boolean;
};

const PrintCompColumn: FC<PrintCompColumnProps> = ({
  classes,
  compData,
  costTableColumnInputs,
  hasMarkups,
  headerHeight,
  rowHasBreakpoint,
}) => {
  const isAverage = isAverageCompType(compData);
  const columnKey = isAverage ? 'Average' : compData.project.id;

  const {
    categoryLines,
    markupLines,
    summaryLines: { subtotalLine, markupsTotalLine, totalLine },
  } = compData.projectCompsCostTable;
  const isMarkupsCollapsed = useIsProjectCompSectionCollapsed(
    ProjectCompSectionType.SUBSECTION_MARKUPS
  );

  const subtotalIndex = categoryLines.length;
  const markupsTotalIndex = categoryLines.length + 1;
  const individualMarkupsTotalIndex = categoryLines.length + 2;
  let totalIndex = categoryLines.length;
  if (hasMarkups) {
    totalIndex += 2;
    if (!isMarkupsCollapsed) totalIndex += markupLines.length;
  }

  const getIsCost = (cellKey?: CostTableColumnInputKey) =>
    cellKey &&
    [CostTableColumnInputKey.TOTAL, CostTableColumnInputKey.TOTAL_PER_METRIC].includes(cellKey);

  const costCell = (value: number, isBold: boolean, idx: number, key: CostTableColumnInputKey) => {
    const isCost = getIsCost(costTableColumnInputs?.at(idx)?.key);
    return value || isZeroPercent(value, !!isCost) ? (
      <div className={`${classes.cell}`}>
        <div
          className={`type-number ${isBold ? '!font-bold' : ''} 
          ${COLUMN_FONT_CLASSES}
          `}
        >
          {getFormattedColumnValue(key, value)}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const costRowContent = (
    costTableColumnInputs: CostTableColumnInputs,
    columnValues?: ProjectCompsCostTableColumnValue[],
    isBold = false
  ) => (
    <div
      className="grid flex-grow"
      style={{ gridTemplateColumns: formatGridTemplateColumns(costTableColumnInputs, true) }}
    >
      {columnValues?.map((columnValue, i) =>
        costCell(columnValue.valueNumeric, isBold, i, columnValue.costTableColumnInput.key)
      )}
    </div>
  );

  return (
    <div className={`@container ${classes.columnContent} ${isAverage ? classes.average : ''}`}>
      {/* Category Lines */}
      {categoryLines.map((categoryLine, i) => (
        <PrintProjectCompRow
          content={costRowContent(costTableColumnInputs, categoryLine.columnValues)}
          hasBreakpoint={rowHasBreakpoint(i)}
          headerHeight={headerHeight}
          key={`${categoryLine.category.number}-${columnKey}`}
        />
      ))}
      {/* Subtotal Line */}
      {hasMarkups && (
        <PrintProjectCompRow
          content={costRowContent(costTableColumnInputs, subtotalLine?.columnValues, true)}
          hasBorder
          hasBreakpoint={rowHasBreakpoint(subtotalIndex)}
          headerHeight={headerHeight}
        />
      )}
      {/* Markups Total Line */}
      {hasMarkups && (
        <PrintProjectCompRow
          content={costRowContent(costTableColumnInputs, markupsTotalLine?.columnValues, true)}
          hasBreakpoint={rowHasBreakpoint(markupsTotalIndex)}
          headerHeight={headerHeight}
        />
      )}
      {/* Individual Markup Lines */}
      {hasMarkups &&
        !isMarkupsCollapsed &&
        markupLines.map((markupLine, i) => (
          <PrintProjectCompRow
            content={costRowContent(costTableColumnInputs, markupLine.columnValues)}
            hasBreakpoint={rowHasBreakpoint(individualMarkupsTotalIndex + i)}
            headerHeight={headerHeight}
            key={`${markupLine.markupName}-${columnKey}`}
          />
        ))}
      {/* Total Line */}
      <PrintProjectCompRow
        content={costRowContent(costTableColumnInputs, totalLine?.columnValues, true)}
        hasBorder
        hasBreakpoint={rowHasBreakpoint(totalIndex)}
        headerHeight={headerHeight}
      />
    </div>
  );
};

export default withStyles(styles)(PrintCompColumn);
