import { FC } from 'react';

import { ContingencyInfo, MarkupDisplayType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';

import ContingencyReportBody from './ContingencyReportBody';
import ContingencyReportHeader from './ContingencyReportHeader';
import { styles } from './ContingencyReportStyles';
import {
  ContingencyReportType,
  ContingencyReportView,
  getContingencyNamesForDisplayType,
} from './ContingencyReportUtils';

type ContingencyReportProps = {
  classes: Classes<typeof styles>;
  milestoneID: string;
  projectID: string;
  milestoneName?: string;
  milestoneCost: Cost;
  activeMilestoneContingencies?: ContingencyReport;
  allMilestoneContingencies?: MultiMilestoneContingencyReport;
  reportView: ContingencyReportView;
  setReportView: (value: ContingencyReportView) => void;
  selectedReportType: ContingencyReportType;
  setSelectedReportType: (value: ContingencyReportType) => void;
  availableContingencies: Pick<ContingencyInfo, 'type' | 'name'>[];
  selectedContingencies: string[];
  setSelectedContingencies: (value: string[]) => void;
  selectedAllowances: string[];
  setSelectedAllowances: (value: string[]) => void;
  comments?: VarianceReportComments;
  print?: () => void;
  exportReport: () => void;
  isLoading: boolean;
};

const ContingencyReport: FC<ContingencyReportProps> = ({
  classes,
  milestoneID,
  milestoneName,
  milestoneCost,
  projectID,
  activeMilestoneContingencies,
  reportView,
  setReportView,
  selectedReportType,
  setSelectedReportType,
  availableContingencies,
  selectedContingencies,
  setSelectedContingencies,
  selectedAllowances,
  setSelectedAllowances,
  comments,
  allMilestoneContingencies,
  print,
  exportReport,
  isLoading,
}) => {
  const clearFilters = () => {
    setSelectedContingencies(
      getContingencyNamesForDisplayType(availableContingencies, MarkupDisplayType.CONTINGENCY)
    );
    setSelectedAllowances(
      getContingencyNamesForDisplayType(availableContingencies, MarkupDisplayType.ALLOWANCE)
    );
  };

  return (
    <div className={classes.print}>
      {!!print && (
        <ContingencyReportHeader
          projectID={projectID}
          milestoneID={milestoneID}
          milestoneName={milestoneName}
          reportView={reportView}
          setReportView={setReportView}
          selectedReportType={selectedReportType}
          setSelectedReportType={setSelectedReportType}
          availableContingencies={availableContingencies}
          selectedContingencies={selectedContingencies}
          setSelectedContingencies={setSelectedContingencies}
          selectedAllowances={selectedAllowances}
          setSelectedAllowances={setSelectedAllowances}
          print={print}
          exportReport={exportReport}
        />
      )}
      <ContingencyReportBody
        activeMilestoneContingencies={activeMilestoneContingencies}
        allMilestoneContingencies={allMilestoneContingencies}
        availableContingencies={availableContingencies}
        clearFilters={clearFilters}
        comments={comments}
        milestoneID={milestoneID}
        milestoneCost={milestoneCost}
        projectID={projectID}
        reportView={reportView}
        selectedAllowances={selectedAllowances}
        selectedContingencies={selectedContingencies}
        selectedReportType={selectedReportType}
        isLoading={isLoading}
      />
    </div>
  );
};

export default withStyles(styles)(ContingencyReport);
