import { ReactiveVar, makeVar, useReactiveVar } from '@apollo/client';

import { UsersSortBy, UsersSortKey } from '../../components/dragon-scales/ShareDialog/types';
import { NAV_HEIGHT } from '../../components/frame/FrameTitleBar/FrameTitleBarStyles';
import { PREVIEW_HEIGHT } from '../../components/frame/FrameTitleBar/FrameTitlePreviewBarStyles';
import { ItemActivityFeedReportType } from '../../components/Items/ItemActivityFeed/ItemActivityFeedUtils';
import { GridSortData } from '../../components/JoinGrid/types';
import { NavigationBarEntry } from '../../components/shared-widgets/NavigationBar/NavBreadcrumb/NavBreadcrumbUtils';
import {
  ADD_TO_MILESTONE_REACTIVE_VAR,
  GSF_ID,
  HELP_TIP_TRACKER,
  IMPORT_ESTIMATE_ACTIVE,
  IMPORT_ESTIMATE_BUDGET,
  IN_APP_NOTIFICATIONS_PAGINATION_LIMIT,
  IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_REACTIVE_VAR,
  ITEM_ACTIVITY_FEED_REPORT_TYPE,
  MERGE_REACTIVE_VAR,
  NULL_ID,
  PROJECT_COMPS_LOCAL_SETTINGS_VAR,
  TOAST_VAR,
  TRANSITION_REACTIVE_VAR,
  USER_REPORT_VAR,
  VARIANCE_REACTIVE_VAR,
} from '../../constants';
import { DD_NEW_NAV } from '../../features';
import {
  CostReportColumnType,
  EstimateTotalType,
  FilesSortBy,
  FilesSortKey,
  ProjectCompsSetInput,
  SortDirection,
  SourceSystem,
} from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import { logErrorToSentry } from '../../utilities/sentry';
import { getProjectIdFromUrl } from '../../utilities/url';
import {
  AddToMilestoneModal,
  AddToMilestoneStates,
  EstimateType,
  ImportModal,
  MergeModal,
  ProjectCompSectionType,
  ToastType,
  TransitionModal,
  UploadPlatform,
  VarianceModal,
} from '../gqlEnums';
import { CostMode } from '../gqlEnumsBe';

type ExtractReactiveVarType<Type> = Type extends ReactiveVar<infer X> ? X : never;

export const assetCacheVar = makeVar<Map<string, string>>(new Map<string, string>());

// sets an array of maps
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const replacer = (key: string, value: any) => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  }
  return value;
};

// parses an array of maps
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const reviver = (key: string, value: any) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
};

const getProjectKey = (key: string): string => `${getProjectIdFromUrl() || NULL_ID}-${key}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const setLocalStorage = async (key: string, value: any, useProjectKey = true) => {
  try {
    if (useProjectKey) {
      localStorage.setItem(getProjectKey(key), JSON.stringify(value, replacer));
    } else {
      // allow unique keys across multiple projects
      localStorage.setItem(`${key}`, JSON.stringify(value, replacer));
    }
  } catch (e) {
    logErrorToSentry(e);
  }
};

export const setReactiveLocal = <T = unknown>(
  reactiveVar: ReactiveVar<T>,
  key: string,
  value: ExtractReactiveVarType<typeof reactiveVar>,
  useProjectKey = true
) => {
  reactiveVar(value);
  setLocalStorage(key, value, useProjectKey);
};

export function getReactiveLocal<T>(key: string, defaultValue: T, useProjectKey = true): T {
  const { localStorage } = window || {};
  if (!localStorage) return defaultValue;
  let str: string | null = null;
  if (useProjectKey) {
    str = localStorage.getItem(getProjectKey(key));
  } else {
    str = localStorage.getItem(key);
  }
  if (!str) return defaultValue;
  try {
    return JSON.parse(str, reviver);
  } catch (e) {
    return defaultValue;
  }
}

export const defaultToastValue: ToastParameters = {
  show: false,
  message: '',
  item: null,
  type: ToastType.NONE,
  toastText: null,
};

export const toastParametersVar = makeVar<ToastParameters>(
  getReactiveLocal(TOAST_VAR, { ...defaultToastValue })
);

export const mountedItemsVar = makeVar<Map<string, boolean>>(new Map<string, boolean>());

const HELP_TIP_TRACKER_DEFAULT: HelpTipTracker = {
  estimateImportCount: 0,
  importedEstimateIDs: [],
};

export const helpTipTrackerVar = makeVar<HelpTipTracker>(
  getReactiveLocal(HELP_TIP_TRACKER, HELP_TIP_TRACKER_DEFAULT, false)
);

export const IMPORT_ESTIMATE_DEFAULT: ImportEstimateParameters = {
  assetId: null,
  categorizations: [],
  estimateTotalType: EstimateTotalType.TOTAL_TYPE_UNIT,
  id: null,
  mapping: new Map<string, string>(),
  mappingBuiltIn: new Map<string, Level>(),
  modal: ImportModal.MAP_UF_MF,
  modalIsOpen: false,
  numberOfLines: 0,
  selectedTotalType: EstimateTotalType.TOTAL_TYPE_UNIT,
};

// Reactive variable for progress on importing estimates
export const importEstimateActiveVar = makeVar<ImportEstimateParameters>(
  getReactiveLocal(IMPORT_ESTIMATE_ACTIVE, IMPORT_ESTIMATE_DEFAULT)
);
export const importEstimateBudgetVar = makeVar<ImportEstimateParameters>(
  getReactiveLocal(IMPORT_ESTIMATE_BUDGET, IMPORT_ESTIMATE_DEFAULT)
);

export const importEstimateHelpDialogVar = makeVar<ImportEstimateHelpParameters>({
  isOpen: false,
  platform: UploadPlatform.EXCEL,
});
export const getImportEstimateVar = (estimateType: EstimateType) =>
  estimateType === EstimateType.ACTIVE_ESTIMATE ? importEstimateActiveVar : importEstimateBudgetVar;

export const importEstimateIsPublishedVar = makeVar<boolean>(false);

// Reactive variable for tracking the time of canceling upload on import estimate file
export const importEstimateActiveSkipMsVar = makeVar<number>(0);
export const importEstimateBudgetSkipMsVar = makeVar<number>(0);

export const isEstimateUploadDialogOpen = makeVar<boolean>(false);

const VARIANCE_MODAL_DEFAULT: VarianceOnboardingParameters = {
  displayGroupBy: [],
  expressions: [],
  hideZeroVariance: false,
  milestone1Type: [],
  milestone2Type: [],
  modal: VarianceModal.MILESTONES,
  viewMode: '',
};

// Reactive variable for variance cost report onboarding
export const varianceOnboardingVar = makeVar<VarianceOnboardingParameters>(
  getReactiveLocal(VARIANCE_REACTIVE_VAR, VARIANCE_MODAL_DEFAULT)
);

const MERGE_ONBOARDING_DEFAULT = {
  modal: MergeModal.MILESTONE,
  modalIsOpen: false,
  viewMode: '',
};

export const mergeOnboardingVar = makeVar<MergeOnboardingParameters>(
  getReactiveLocal(MERGE_REACTIVE_VAR, MERGE_ONBOARDING_DEFAULT)
);

const TRANSITION_MODAL_DEFAULT: TransitionOnboardingParameters = {
  incorporateAccepted: false,
  milestoneID: undefined,
  modal: TransitionModal.MILESTONE,
  modalIsOpen: false,
  numberOfAcceptedItems: undefined,
  type: undefined,
};

// Reactive variable for transition milestone estimate
export const transitionOnboardingVar = makeVar<TransitionOnboardingParameters>(
  getReactiveLocal(TRANSITION_REACTIVE_VAR, TRANSITION_MODAL_DEFAULT)
);

export const ADD_TO_MILESTONE_MODAL_DEFAULT: AddToMilestoneOnboardingParameters = {
  averageCost: '',
  categorizationName: '',
  date: '',
  modal: AddToMilestoneModal.ADD_TO_MILESTONE,
  modalIsOpen: false,
  milestoneID: '',
  milestoneName: '',
  milestoneDesignPhase: undefined,
  state: AddToMilestoneStates.NEW_MILESTONE,
  type: CostReportColumnType.ESTIMATE_REPORT,
};

// Reactive variable for add to milestone
export const addToMilestoneVar = makeVar<AddToMilestoneOnboardingParameters>(
  getReactiveLocal(ADD_TO_MILESTONE_REACTIVE_VAR, ADD_TO_MILESTONE_MODAL_DEFAULT)
);

// Reactive variable for indicating that second pop up is shown on top of current one
export const isSecondPopupVar = makeVar<boolean>(false);

// Reactive variable for sorting data that is used in Join Grid
export const gridSortDataVar = makeVar<GridSortData>({
  sortKey: NULL_ID,
  sortDirection: SortDirection.SORT_NONE,
});

export const newItemDialogOpenVar = makeVar<boolean>(false);
export const itemEstimateDialogOpenVar = makeVar<boolean>(false);
export const collaboratorDialogOpenVar = makeVar<boolean>(false);
export const milestoneTransitionOpenVar = makeVar<boolean>(false);
export const drawerOpenVar = makeVar<boolean>(false);
export const hasTrendVar = makeVar<boolean>(false);
export const itemSidebarOpenVar = makeVar<string | null>(null);
export const itemSidebarHomeOpenVar = makeVar<string | null>(null);
export const itemSidebarForecastingOpenVar = makeVar<string | null>(null);
export const itemSidebarScenariosOpenVar = makeVar({ id: '', color: '' });
export const projectCurrencyOpenVar = makeVar<boolean>(false);
export const assetViewerOpenVar = makeVar<boolean>(false);

export const gridWidthVar = makeVar<number>(0);
export const reloadGridVersionVar = makeVar<number>(0);
export const paginationHasMoreVar = makeVar<boolean>(true);

const defaultProjectSettings: ProjectSettingStore = {
  ROUNDING_PRECISION: 3,
  CURRENCY: 'USD',
  SCHEDULE_IMPACT_DISPLAY: 'WORK_DAYS',
  PREV_SCHEDULE_IMPACT_DISPLAY: 'WORK_DAYS',
};
export const projectSettingsVar = makeVar<ProjectSettingStore>(defaultProjectSettings);

// PREVIEW SETTINGS
export const defaultPreviewSettings: PreviewVars = {
  previewUserId: undefined,
  previewRoleId: undefined,
  previewTrades: undefined,
  previewAllTrades: undefined,
  projectID: undefined,
};
const PREVIEW_SETTINGS = 'PREVIEW_SETTINGS';
const previewSettingsVar = makeVar<PreviewVars>(
  getReactiveLocal(PREVIEW_SETTINGS, defaultPreviewSettings)
);
export const usePreviewSettingsVar: () => PreviewVars = () => {
  const projectID = getProjectIdFromUrl() || undefined;
  const preview = useReactiveVar(previewSettingsVar);
  // If we are on a different project page, reset the preview settings
  if (preview.projectID !== projectID) {
    previewSettingsVar(defaultPreviewSettings);
  }
  return preview;
};

export const setPreviewSettingsVar = (value: PreviewVars) => {
  const projectID = getProjectIdFromUrl();
  setReactiveLocal(previewSettingsVar, PREVIEW_SETTINGS, {
    ...value,
    projectID,
  });
};

export const useTitleHeight = () => {
  const preview: PreviewVars = usePreviewSettingsVar();
  const { previewUserId, previewRoleId } = preview;
  const hasPreview = !!previewUserId || !!previewRoleId;

  const hasNewNav = useHasFeature(DD_NEW_NAV);
  if (hasNewNav) return 0;

  return hasPreview ? PREVIEW_HEIGHT + NAV_HEIGHT : NAV_HEIGHT;
};

// Reactive variable for ensuring only one excel download is active at a time
export const isDownloadingItemsListToExcelVar = makeVar<boolean>(false);
export const isDownloadingCostReportToExcelVar = makeVar<boolean>(false);
export const isDownloadingContingencyReportToExcelVar = makeVar<boolean>(false); // used for both active milestone and all milestones
export const isDownloadingEstimateToExcelVar = makeVar<boolean>(false);
export const isDownloadingBudgetToExcelVar = makeVar<boolean>(false);
export const isDownloadingProjectCompsToExcelVar = makeVar<boolean>(false);
export const selectOptionsLoadingDefaultVar = makeVar<boolean>(false);

export const currentUserReportVar = makeVar<
  Omit<UserReport, 'createdBy' | 'updatedAt'> | undefined
>(getReactiveLocal(USER_REPORT_VAR, undefined));

// Project Comps
type ProjectCompsSettingsLocal = {
  collapsed: ProjectCompSectionType[];
  showMinMaxCosts: boolean;
};

const projectCompsSettingsLocalDefault: ProjectCompsSettingsLocal = {
  collapsed: [ProjectCompSectionType.SECTION_GRAPHS],
  showMinMaxCosts: false,
};

export const projectCompsSettingsInputDefault: ProjectCompsSetInput = {
  costMode: CostMode.SeparatedMarkups,
  pinnedUnitID: GSF_ID,
  projectCompInputs: [],
  selectedUnitIDs: [GSF_ID],
};

export const projectCompsLocalSettingsVar = makeVar<ProjectCompsSettingsLocal>(
  getReactiveLocal(PROJECT_COMPS_LOCAL_SETTINGS_VAR, projectCompsSettingsLocalDefault)
);
// DO NOT pass values to this ReactiveVar directly - all updates should be made via the
// ProjectCompsSetInputUtils hooks.
export const projectCompsSetInputVar = makeVar<ProjectCompsSetInput>(
  projectCompsSettingsInputDefault
);
export const forecastingReportHasUnsavedChangesVar = makeVar<boolean>(true);

export const companyTabIDVar = makeVar<UUID | undefined>(undefined);

export const inAppNotificationsProjectIdVar = makeVar<UUID | null>(null);

const IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_DEFAULT = false;
export const inAppNotificationsShowUnreadOnlyVar = makeVar<boolean>(
  getReactiveLocal(
    IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_REACTIVE_VAR,
    IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_DEFAULT
  )
);

export const inAppNotificationsPanelAnchorVar = makeVar<HTMLAnchorElement | null>(null);

export const IN_APP_NOTIFICATIONS_PAGINATION_DEFAULT: PaginationByOffsetID = {
  offsetID: NULL_ID,
  limit: IN_APP_NOTIFICATIONS_PAGINATION_LIMIT,
};
export const inAppNotificationsPaginationVar = makeVar<PaginationByOffsetID>(
  IN_APP_NOTIFICATIONS_PAGINATION_DEFAULT
);

export const IN_APP_NOTIFICATIONS_PAGINATION_OFFSET_IDS_DEFAULT: UUID[] = [];
export const inAppNotificationsOffsetIdsVar = makeVar<UUID[]>(
  IN_APP_NOTIFICATIONS_PAGINATION_OFFSET_IDS_DEFAULT
);

// File Explorer
export enum FilesDialogView {
  DROPZONE = 'Upload Files',
  ALL_FILES = 'All Files',
  UPLOADED_FILES = 'Uploaded by You',
  FILES_SOURCES = 'Link Accounts',
}
export const filesDialogViewVar = makeVar<SourceSystem | FilesDialogView>(
  FilesDialogView.ALL_FILES
);

export type Selected = {
  [key: string]: boolean;
};
type SelectedAssets = {
  selectedMap: Selected;
  sourceID?: UUID;
  sourceProjectID?: string;
  hubID?: string;
};

export const selectedAssetsVar = makeVar<SelectedAssets>({ selectedMap: {} });

export type FileExplorerNavBarEntry = NavigationBarEntry & {
  element: JSX.Element;
};
export const filesExplorerNavigationVar = makeVar<FileExplorerNavBarEntry[]>([]);

export const filesExplorerSortStateVar = makeVar<FilesSortBy>({
  sortDirection: SortDirection.SORT_ASCENDING,
  sortKey: FilesSortKey.TYPE,
});

export const FILES_ASSETS_DEFAULT_SORT = {
  sortDirection: SortDirection.SORT_DESCENDING,
  sortKey: FilesSortKey.DATE,
};
export const filesAssetsSortByVar = makeVar<FilesSortBy>(FILES_ASSETS_DEFAULT_SORT);

// Project Activity Feed
const DEFAULT_ITEM_ACTIVITY_FEED_REPORT_TYPE = ItemActivityFeedReportType.ALL_ACTIVITY;
export const selectedItemActivityReportVar = makeVar<ItemActivityFeedReportType>(
  getReactiveLocal(ITEM_ACTIVITY_FEED_REPORT_TYPE, DEFAULT_ITEM_ACTIVITY_FEED_REPORT_TYPE, true)
);

export const usersSortStateVar = makeVar<UsersSortBy>({
  sortDirection: SortDirection.SORT_ASCENDING,
  sortKey: UsersSortKey.NAME,
});

// Item details view state
type ItemDetailsViewState = {
  isPrivateCollapsed: boolean;
};
const ITEM_DETAILS_VIEW_STATE_DEFAULT: ItemDetailsViewState = {
  isPrivateCollapsed: false,
};
export const itemDetailsViewStateVar = makeVar<ItemDetailsViewState>(
  ITEM_DETAILS_VIEW_STATE_DEFAULT
);
