import { getOperationName } from 'apollo-link';

import { useMutation } from '@apollo/client';

import {
  AllMilestonesTrendDateVisibilityQuery,
  AllMilestonesTrendDateVisibilityQueryVariables,
  DateVisibilityToggle,
  IntervalType,
  ToggleAllMilestonesTrendDateVisibilityMutation,
  ToggleAllMilestonesTrendDateVisibilityMutationVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { isNonNullable } from '../../../../utilities/types';
import { milestonesCostTrendsQuery } from '../../../dashboard/DashboardCharts/DashboardChartsTrend/hooks/queries';

import {
  allMilestonesTrendDateVisibilityQuery,
  toggleAllMilestonesTrendDateVisibilityMutation,
} from './queries';

export const useToggleAllMilestonesTrendDataVisibility = (
  projectID: UUID,
  intervalType: IntervalType
) => {
  const mutation = useMutation<
    ToggleAllMilestonesTrendDateVisibilityMutation,
    ToggleAllMilestonesTrendDateVisibilityMutationVariables
  >(toggleAllMilestonesTrendDateVisibilityMutation);

  const [toggleEventDataVisibility, { loading }] = mutation;

  const toggleFn = (toggles: DateVisibilityToggle[]) => {
    toggleEventDataVisibility({
      variables: { projectID, intervalType, toggles },
      refetchQueries: [getOperationName(milestonesCostTrendsQuery)].filter(isNonNullable),
      awaitRefetchQueries: true,
    });
  };

  return { toggleFn, toggleLoading: loading };
};

export const useAllMilestonesTrendDataVisibility: (
  variables: AllMilestonesTrendDateVisibilityQueryVariables
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
) => any = ({ projectID, intervalType }) =>
  useQuery<AllMilestonesTrendDateVisibilityQuery, AllMilestonesTrendDateVisibilityQueryVariables>(
    allMilestonesTrendDateVisibilityQuery,
    {
      variables: {
        projectID,
        intervalType,
      },
    }
  );
