import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    togglePadding: {
      borderRadius: '3px',
      padding: '4px',
      border: theme.border.invisible,
      '&:focus-visible': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    text: {
      ...theme.typography.chip,
    },
    toggleText: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  });

export default styles;
