export default (
  <svg
    className="fill-entities-milestone"
    width="10.5"
    height="11.9"
    viewBox="0 0 10.5 11.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 6.58 1.4 L 6.3 0 H 0 V 11.9 H 1.4 V 7 H 5.32 L 5.6 8.4 H 10.5 V 1.4 H 6.58 Z" />
  </svg>
);
