import { useState } from 'react';
import * as React from 'react';

import { Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons/';

import { PermissionResource } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import CTAIconButton from '../../../shared-widgets/CTAIconButton';
import { generateTitle, getItemDescriptor } from '../ItemsListItemUtils';

import ItemsListItemActionsStyles from './ItemsListItemActionsStyles';

type Props = {
  classes: Classes<typeof ItemsListItemActionsStyles>;
  item: ItemsListItem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onCtaClick?: (evt: any) => void;
  parentItem?: ItemsListItem;
};

const ItemsListItemActions: React.FC<Props> = ({ classes, item, onCtaClick, parentItem }) => {
  const [pending, setPending] = useState(false);
  // PERMISSIONS
  const { canEdit, canAdd, inPreviewMode, userID } = usePermissions({
    trades: item.categories,
  });
  // previewMode negates didCreateItem and isAssignedItem, as you are choosing
  // to view permissions separate from your own
  const didCreateItem = item?.creator.id === userID && !inPreviewMode;
  const isAssignedItem = item?.assigneeID === userID && !inPreviewMode;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));

  // GENERATORS
  const generateCta = (title: string) => (
    <CTAIconButton
      buttonText={title}
      dataCy="button-itemsListItemActions"
      disabled={pending}
      icon={<Add />}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      onClick={(evt: any) => {
        if (onCtaClick) {
          onCtaClick(evt);
          setPending(true);
        }
      }}
      variant="contained"
    />
  );

  if (parentItem) {
    return (
      <Typography title={generateTitle(parentItem)} className={classes.column3Text}>
        {`Option in ${getItemDescriptor(parentItem)}`}
      </Typography>
    );
  }
  const numberOptions = item?.options?.length;
  if (numberOptions) {
    const message = `${numberOptions} Option${numberOptions === 1 ? '' : 's'}`;
    return <Typography className={classes.column3Text}>{message}</Typography>;
  }
  if (onCtaClick && canEditItemDetails) {
    return generateCta('Add as Option');
  }
  return null;
};

export default withStyles(ItemsListItemActionsStyles)(ItemsListItemActions);
