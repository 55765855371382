import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-vis/dist/style.css';

import { hasTrendVar } from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import { CostReportColumnType, IntervalType } from '../../../../generated/graphql';
import { costTrendColumns } from '../../../../hooks/constants';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import ChartsAllMilestones from '../../../Charts/ChartsAllMilestones/ChartsAllMilestones';
import {
  HoverPoint,
  getTrendData,
} from '../../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';
import { allDataFilteredMessage, allPointsHiddenMessage } from '../../../Charts/ChartsUtils';
import DialogsHide from '../../../Dialogs/DialogsHide/DialogsHide';
import { useToggleAllMilestonesTrendDataVisibility } from '../../../Dialogs/DialogsHide/DialogsHideAllMilestonesTrendlineDate/DialogsHideAllMilestonesTrendlineDataHooks';
import { HideDate } from '../../../Dialogs/DialogsHide/DialogsHideList';
import { hasActiveFilter } from '../../../FilterPanel/filterUtils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import CTALink from '../../../shared-widgets/CTALink';
import { DASHBOARD_DEFAULTS } from '../../DashboardUtils';

import { DETAILED } from './DashboardChartsTrendDetailToggle';
import { useMilestonesCostTrendsQuery } from './hooks/useMilestonesCostTrendsQuery';

type DashboardChartsTrendAllMilestonesProps = {
  clearFilters?: () => void;
  isPrint?: boolean;
  onClose: () => void;
  setIsLoading?: (loading: boolean) => void;
  settings: typeof DASHBOARD_DEFAULTS;
  showDialogsHide: boolean;
  unitID?: UUID;
  viewFilter: ViewFilterInput;
};

export const dashboardColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];

const DashboardChartsTrendAllMilestones: FC<DashboardChartsTrendAllMilestonesProps> = ({
  clearFilters,
  isPrint = false,
  onClose,
  setIsLoading = () => {},
  showDialogsHide,
  settings,
  viewFilter,
  unitID,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const intervalType = IntervalType.BI_WEEKLY;
  const { detailLevel } = settings;
  const isDetailed = detailLevel === DETAILED;
  const columns: CostReportColumnInput[] = costTrendColumns.map(
    (column: CostReportColumnInput) => ({
      ...column,
      unitID,
    })
  );
  const { data, loading } = useMilestonesCostTrendsQuery(
    projectId,
    columns,
    intervalType,
    viewFilter,
    !isDetailed
  );

  const segments = data?.milestonesCostTrends;
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  const { toggleFn, toggleLoading } = useToggleAllMilestonesTrendDataVisibility(
    projectId,
    intervalType
  );

  // STATE = we will be following selected and hover separately
  const [hover, setHover] = useState<HoverPoint | null>(null);
  const [selected, setSelected] = useState<HideDate | null>(null);

  const onHover = (hoverPoint: HoverPoint | null) => {
    if (hoverPoint) {
      const { milestone, dateStart, dateEnd } = hoverPoint;
      const point = { dateStart, dateEnd, values: [], isVisible: true } as Point;
      const newSelected: HideDate = {
        point,
        milestone: { ...milestone, description: '' } as Pick<
          Milestone,
          'id' | 'name' | 'description' | 'date'
        >,
      };
      setSelected(newSelected);
    } else {
      setSelected(null);
    }
    setHover(hoverPoint);
  };

  const onSelect = (hideDate: HideDate | null) => {
    setSelected(hideDate);
    setHover(null);
  };

  const hasMultipleMilestones = segments?.length && segments.length > 1;

  const listItems: HideDate[] | undefined = segments
    ?.map(({ points, milestone }) =>
      points
        .filter((point) => point.values.some((v) => v.y > 0))
        .map((point, i, arr) => ({
          point,
          milestone,
          isLast: arr.length - 1 === i,
        }))
    )
    .flat();

  // DATA
  const terms = useContext(ProjectTermStore);
  const trendData = getTrendData(terms, segments, selected || undefined, unitID);
  const { lineData, somePointsHidden } = trendData;
  const hasData = lineData.length;
  const isFiltered = hasActiveFilter(viewFilter);

  const emptyMessage = useMemo(() => {
    if (!hasData && !loading) {
      // If there's filtering, try to turn that off.
      if (isFiltered) {
        return allDataFilteredMessage;
      }
      // If there were points, but they are filtered, prompt editing.
      if (somePointsHidden) {
        return allPointsHiddenMessage;
      }
      return `No data yet. Add ${terms.lowerCase(TermKey.ESTIMATE)} or ${terms.lowerCase(
        TermKey.TARGET
      )}.`;
    }
    return '';
  }, [hasData, loading, isFiltered, somePointsHidden, terms]);

  const onChartClick = () => {
    if (hover) {
      navigate(
        generateSharedPath(JoinProjectRoutes.MSR, { projectId, milestoneId: hover.milestone.id })
      );
    }
  };

  useEffect(() => {
    if (loading) {
      hasTrendVar(false);
    } else if (hasData) {
      hasTrendVar(true);
    } else {
      hasTrendVar(somePointsHidden || isFiltered); // hide editable for this one sort of data condition?
    }
  }, [loading, hasData, isFiltered, somePointsHidden]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const renderChart = (height?: number, onClick?: (clicked: any) => void) => (
    <ChartsAllMilestones
      clearFilters={clearFilters}
      detailLevel={detailLevel}
      emptyMessage={emptyMessage}
      height={height}
      hover={hover}
      isPrint={isPrint}
      onClick={onClick}
      rounded={!unitID}
      segmentsLoading={loading}
      trendData={trendData}
      setHover={onHover}
      viewFilter={viewFilter}
    />
  );

  return (
    <>
      <DialogsHide
        loading={toggleLoading}
        selected={selected}
        onClose={onClose}
        open={showDialogsHide}
        renderChart={renderChart}
        setSelected={onSelect}
        title="Hide Dates from All Milestones Trendline Chart"
        toggleFn={toggleFn}
        listItems={listItems}
      />
      {!loading && !hasMultipleMilestones ? (
        <CTALink
          linkText="Add more milestones to see trendlines."
          onClick={() => {
            navigate(generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }));
          }}
        />
      ) : (
        renderChart(undefined, onChartClick)
      )}
    </>
  );
};

export default DashboardChartsTrendAllMilestones;
