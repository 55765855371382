import { FC, useState } from 'react';

import {
  Button,
  DialogContent,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import {
  NewItemEvent,
  newItemEvent,
  optionsDialogTabAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { VISIBILITY_DRAFT_ITEM, VISIBILITY_PRIVATE_ITEM } from '../../../constants';
import { Visibility } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { escKeyDownHandler } from '../../frame/SidebarUtils';
import { Lightbulb } from '../../Icons/Lightbulb';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import {
  generateCostImpactInfo,
  isOptionOfPrivateItem,
  isPrivateVisibility,
} from '../../Items/ItemsUtils';
import ItemVisibilityToggle from '../../Items/ItemVisibilityToggle/ItemVisibilityToggle';
import { Button as ButtonScales, Callout, Checkbox } from '../../scales';
import DialogsStyles from '../DialogsStyles';

import DialogsNewItemCategories from './DialogsNewItemCategories';
import DialogsNewItemDetails from './DialogsNewItemDetails';
import DialogsNewItemSearch from './DialogsNewItemSearch';

type ScenarioProps = {
  color: string;
  name: string;
};

type DialogsNewItemContentProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  convertItem?: () => Promise<any>;
  count?: number;
  isValidName: boolean;
  item: DraftItem;
  loading?: boolean;
  onClose: () => void;
  onCloseNoChange?: () => void;
  onSubmit: (event?: () => void, createAsDraft?: boolean) => void;
  open: boolean;
  parentItem?: Item;
  scenarioProps?: ScenarioProps;
  setItem: (item: DraftItem) => void;
  visibilitySetting?: Visibility;
  setVisibilitySetting?: (visibility: Visibility) => void;
};

const DialogsNewItemContent: FC<DialogsNewItemContentProps> = ({
  classes,
  convertItem,
  isValidName,
  item,
  count = 1,
  loading,
  onClose,
  onCloseNoChange,
  onSubmit,
  open,
  parentItem,
  scenarioProps,
  setItem,
  visibilitySetting,
  setVisibilitySetting,
}) => {
  // CONSTANTS
  const NEW = 'new';
  const EXISTING = 'existing';

  // LOGIC
  const { activeEstimate, cost, id, name, number, visibility: parentVisibility } = parentItem || {};

  const isOption = !!parentItem;
  const isScenario = !!scenarioProps;
  const hasPrivateParentItem = isOptionOfPrivateItem(isOption, parentVisibility);
  const isConvertingToIWO = parentItem && !parentItem.hasOptions;
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');
  const draftNameTag = isItemSharingFeature ? VISIBILITY_DRAFT_ITEM : VISIBILITY_PRIVATE_ITEM;
  const onChange = (patch: DraftItem) => {
    setItem({ ...item, ...patch, dueDate: patch.dueDate ?? item.dueDate }); // dueDate can't be null
  };

  // VARIABLES
  const { categories } = item;
  const [openTab, setOpenTab] = useState(NEW);
  const [createAnotherItem, setCreateAnotherItem] = useState(false);
  const numberOrPrivate = hasPrivateParentItem
    ? `${draftNameTag.toLowerCase()} item`
    : styleItemNumber(number);
  const title = isOption ? `Add options for ${numberOrPrivate}: ${name}` : 'Create New Item';
  const showVisibilityToggle =
    openTab === NEW && !isScenario && visibilitySetting && setVisibilitySetting;

  const sendAnalytics = useAnalyticsEventHook();

  if (!open) return null;

  const showNewTabContent = openTab === NEW || isScenario || !convertItem;
  const content = showNewTabContent ? (
    <div>
      {loading && (
        <div className={classes.loading}>
          <LinearProgress hidden={!loading} />
        </div>
      )}
      <DialogContent className={classes.dialogContent}>
        <DialogsNewItemDetails
          hasParentItem={isOption}
          isScenario={isScenario}
          item={item}
          parentItemID={parentItem?.id}
          parentItemIsDraft={isPrivateVisibility(parentItem?.visibility)}
          key={count}
          onChange={onChange}
          onSubmit={() => {
            if (createAnotherItem) {
              onSubmit(undefined, true);
            } else onSubmit(onClose, true);
          }}
        />
        <DialogsNewItemCategories onChange={onChange} categories={categories} />
      </DialogContent>
    </div>
  ) : (
    !!parentItem && <DialogsNewItemSearch convertItem={convertItem} parent={parentItem} />
  );

  const newItemButtons = (
    <div className={classes.buttonsContainer}>
      {isItemSharingFeature ? (
        <div className={classes.buttonContainer}>
          {showVisibilityToggle && (
            <ButtonScales
              data-cy="button-createCloseDraft"
              disabled={!isValidName}
              label="Create as Draft"
              onClick={() => {
                const visibility = Visibility.PRIVATE_DRAFT;
                setItem({ ...item, visibility });
                if (createAnotherItem) {
                  onSubmit(undefined, true);
                } else onSubmit(onClose, true);
              }}
              type="secondary"
            />
          )}
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            data-cy="button-createAnother"
            disabled={!isValidName}
            onClick={() => {
              onSubmit();
            }}
          >
            Create Another
          </Button>
        </div>
      )}
      <div className={classes.buttonContainer}>
        {isItemSharingFeature && !hasPrivateParentItem && (
          <ButtonScales
            startIcon={!isScenario && <Check />}
            data-cy="button-createClose"
            disabled={!isValidName}
            label={isScenario ? 'Create' : 'Create and Publish'}
            onClick={() => {
              if (createAnotherItem) {
                onSubmit();
              } else onSubmit(onClose);
            }}
            type="primary"
          />
        )}
        {!isItemSharingFeature && (
          <Button
            data-cy="button-createClose"
            color="secondary"
            disabled={!isValidName}
            onClick={() => {
              onSubmit(onClose);
            }}
          >
            Create and Close
          </Button>
        )}
      </div>
    </div>
  );

  const existingItemButtons = (
    <div className={classes.buttonsContainer}>
      <Button
        data-cy="button-addExistingItemLikeDone"
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        Done
      </Button>
    </div>
  );

  const buttons = openTab === NEW ? newItemButtons : existingItemButtons;
  const footerAddMore = isItemSharingFeature && openTab === NEW && (
    <Checkbox
      isSelected={createAnotherItem}
      onChange={(event: boolean) => {
        setCreateAnotherItem(event);
        sendAnalytics(
          newItemEvent(NewItemEvent.CREATE_ANOTHER, { anotherItem: event ? 'yes' : 'no' })
        );
      }}
      data-cy="checkbox-createAnother"
    >
      <div className="type-body2">{`Create another ${isOption ? 'Option' : 'Item'}`}</div>
    </Checkbox>
  );

  const footerContent = (
    <div
      className={`${classes.action} ${classes.actionFlex} ${
        isItemSharingFeature && openTab !== NEW && classes.contentEnd
      } ${isScenario && !isItemSharingFeature && classes.contentEnd}`}
    >
      {showVisibilityToggle && !isItemSharingFeature && (
        <ItemVisibilityToggle
          isDisabled={hasPrivateParentItem}
          onChange={onChange}
          visibilitySetting={visibilitySetting}
          setVisibilitySetting={setVisibilitySetting}
        />
      )}
      {footerAddMore}
      {buttons}
    </div>
  );

  const subtitle = isConvertingToIWO && activeEstimate && (
    <div className={classes.subtitle}>
      <Typography data-cy="input-itemOptionTitle">
        {`The current estimate of ${generateCostImpactInfo(
          cost
        )} will be moved to Option ${styleItemNumber(
          number
        )}.1 when you create Option ${styleItemNumber(number)}.2 below.`}
      </Typography>
    </div>
  );
  const shareSettingsCalloutStyles = `px-4 pt-2 ${openTab === EXISTING && 'pb-2'}`;
  return (
    <div className={classes.dialogOverlay}>
      <div
        className={classes.dialogPaperDialogsNewItem}
        onKeyDown={(e) =>
          escKeyDownHandler(e, () => {
            onClose();
            onCloseNoChange?.();
          })
        }
      >
        {isScenario && <div className="h-2" style={{ background: scenarioProps?.color }} />}
        <div
          data-cy="input-itemOptionTitle"
          className={`${parentItem ? classes.tabTitle : ''} ${
            !parentItem ? classes.titleBorderBottom : ''
          } ${classes.titleContainer}`}
        >
          <Typography variant="title">{`${title} ${isScenario ? 'in Scenario' : ''}`}</Typography>
          <IconButton
            data-cy="DialogsNewItem-close"
            onClick={() => {
              onClose();
              onCloseNoChange?.();
            }}
            title="Close dialog"
          >
            <Close />
          </IconButton>
        </div>
        {subtitle}
        {parentItem && (
          <Tabs
            classes={{ root: classes.dialogTabs }}
            value={openTab}
            onChange={(_, v) => {
              sendAnalytics(optionsDialogTabAnalytics({ itemId: id || '', whichTab: v }));
              setOpenTab(v);
            }}
          >
            <Tab classes={{ label: classes.tabs }} data-cy="tab-new" value={NEW} label="New" />
            <Tab
              classes={{ label: classes.tabs }}
              data-cy="tab-choseExisting"
              value={EXISTING}
              label="Find Existing"
            />
          </Tabs>
        )}
        {hasPrivateParentItem && isItemSharingFeature && (
          <div className={shareSettingsCalloutStyles}>
            <Callout fullWidth>
              <Lightbulb />
              <div className="inline type-label">
                Tip: Draft options will inherit the share settings of the draft item it&apos;s added
                to.
              </div>
            </Callout>
          </div>
        )}
        {isScenario && (
          <div className={shareSettingsCalloutStyles}>
            <Callout fullWidth>
              <Lightbulb />
              <div className="inline type-label">
                Tip: Items created in a scenario are only visible in a scenario and do not appear
                anywhere else.
              </div>
            </Callout>
          </div>
        )}
        {content}
        {footerContent}
      </div>
    </div>
  );
};

export default withStyles(DialogsStyles)(DialogsNewItemContent);
