import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

const NOTE_ICON_WIDTH = 13;

export const styles = (theme: KomodoTheme) =>
  createStyles({
    noteIndicator: {
      color: theme.palette.buttonGrey,
      width: NOTE_ICON_WIDTH,
      height: 8,
      '&:hover': {
        color: theme.palette.disabledGrey,
      },
    },
    commentCount: {
      height: NOTE_ICON_WIDTH - 1,
      paddingTop: 1, // position text correctly
      width: NOTE_ICON_WIDTH,
      backgroundColor: theme.palette.disabledGrey,
      '& > p': { fontWeight: 700, fontSize: 10 },
    },
    commentCountText: {
      position: 'relative',
      top: 2,
    },
    commentCountDarkBackground: {
      backgroundColor: theme.palette.buttonGrey,
    },
  });

export default styles;
