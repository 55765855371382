import { Link } from 'react-router-dom';

import { JoinRoutes } from '../../../api/gqlEnums';
import { generateSharedPath } from '../../../utilities/routes/links';
import JoinLogo from '../JoinLogo';
import JoinWordmark from '../JoinWordMark';

type Props = {
  size: 'md' | 'lg';
};

export default function JoinLogoNavLink(props: Props) {
  return (
    <Link
      className={`group mr-auto outline-none ${props.size === 'lg' ? 'icon-lg' : 'icon-md'}`}
      data-cy="nav-logo-link"
      to={generateSharedPath(JoinRoutes.PROJECTS)}
    >
      <div className="text-type-primary outline-none group-focus-visible:text-selection-focus-outline">
        {props.size === 'lg' ? <JoinLogo /> : <JoinWordmark />}
      </div>
    </Link>
  );
}
