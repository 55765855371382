import { useState } from 'react';

import { Check } from '@material-ui/icons';

import {
  ItemShareEvent,
  ItemShareKey,
  itemShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import { PermissionResource, ResourceType } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import useCurrentCollaborator from '../../../../hooks/useCurrentCollaboratorQuery';
import { useHasFeature } from '../../../../hooks/useFeatureQuery';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import { toSharedResourceUsers } from '../../../../utilities/utilities';
import SharedUsersState from '../../../dragon-scales/SharedUsersState/SharedUsersState';
import ShareGroupAdd from '../../../Icons/ShareGroupAdd';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';
import { Button } from '../../../scales';
import { useGetSharedResource } from '../hooks/useGetShareResourceHook';
import ShareDraftItemDialogData from '../ShareDraftItemDialog/ShareDraftItemDialogData';

export type Props = {
  isDisabled: boolean;
  itemID?: string;
  itemIDs: UUID[];
  items?: ItemsListItem[];
  setShowPublishItemDialog?: (setShowPublishItemDialog: boolean) => void;
};

export default function ShareItemButton(props: Props) {
  const isBulk = props.itemIDs?.length > 1;
  const analyticsKey = isBulk ? ItemShareKey.BULK : ItemShareKey.DRAFT;
  const projectID = getProjectIdFromUrl();
  const itemId = getItemIdFromUrl();
  const { data: { item: currentItem = null } = {} } = useItemQuery(itemId);
  const sendAnalytics = useAnalyticsEventHook();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { collaborator } = useCurrentCollaborator();
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');
  const { data: sharedResourceData, loading: sharedResourceLoading } = useGetSharedResource(
    props.itemID,
    ResourceType.ITEM
  );
  const sharedUsers = toSharedResourceUsers(sharedResourceData);

  // Permissions
  const isItemOwner = collaborator?.user?.id === currentItem?.createdBy?.id;
  const { canEdit } = usePermissions({ trades: currentItem?.categories });
  const canShareDraft = canEdit(PermissionResource.SHARE_ITEMS_SCENARIOS) || isItemOwner;
  const canPublishDraft = canEdit(PermissionResource.PUBLISH_ITEMS_SCENARIOS) || isItemOwner;

  if (sharedResourceLoading || !isItemSharingFeature || !collaborator?.id) return null;

  const button =
    props.itemID && props.setShowPublishItemDialog ? (
      <>
        <SharedUsersState sharedUsers={sharedUsers} />
        {!props.isDisabled && canShareDraft && (
          <Button
            data-cy="share-draft-button"
            label="Share draft"
            onClick={() => {
              setIsDialogOpen(!isDialogOpen);
              sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.SHARE_CTA));
            }}
            startIcon={<ShareGroupAdd />}
            type="secondary"
          />
        )}
        {!props.isDisabled && canPublishDraft && (
          <Button
            data-cy="publish-item-button"
            label="Publish item"
            onClick={() => {
              props.setShowPublishItemDialog?.(true);
              sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.PUBLISH_CTA));
            }}
            startIcon={<Check />}
            type="primary"
          />
        )}
      </>
    ) : (
      <div>
        <Button
          data-cy="share-item-button"
          label="Share Items"
          onClick={() => {
            setIsDialogOpen(!isDialogOpen);
            sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.CTA));
          }}
          startIcon={<ShareGroupAdd />}
          type="primary"
        />
      </div>
    );
  const dialog = (
    <ShareDraftItemDialogData
      collaboratorID={collaborator?.id}
      isOpen={isDialogOpen}
      itemIDs={props.itemIDs}
      items={props.items}
      onClose={() => setIsDialogOpen(false)}
      projectID={projectID}
    />
  );

  return (
    <>
      {button}
      {isDialogOpen && dialog}
    </>
  );
}
