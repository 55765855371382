import { FC } from 'react';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { getUnfilteredCostImpactByCostMode } from '../../../utilities/items';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { renderCostString } from '../../CostReport/CostReportUtils';

import PrintItemDetailsContingencyDraws from './PrintItemDetailsContingencyDraws';
import PrintItemDetailsEstimateTable from './PrintItemDetailsEstimateTable';

type PrintItemDetailsEstimateProps = {
  estimate?: Estimate;
  isExact?: boolean;
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
};

const PrintItemDetailsEstimate: FC<PrintItemDetailsEstimateProps> = ({
  estimate,
  isExact = true,
  item,
  permissions,
}) => {
  const costMode = useCostMode();
  // PERMISSIONS
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewItemLines = permissions.canView(
    getItemLinesPermissionResource(permissions.checkInTrade(item?.categories || []))
  );
  const canViewMarkups =
    permissions.canView(PermissionResource.MARKUPS, {
      trades: item?.categories || [],
    }) && checkCostModeIncludesMarkups(costMode);

  const itemTotal: string = renderCostString({
    cost: getUnfilteredCostImpactByCostMode(item),
    isExact,
  });

  if (!shouldDisplayCosts || !estimate || !estimate.lines) return <></>;

  return (
    <>
      {item.itemType !== ITEM_WITH_OPTIONS && canViewItemLines && (
        <>
          <div className="print-estimate-header print-avoid-page-break">
            <div className="print-group-header-text">Estimate</div>
          </div>
          <PrintItemDetailsEstimateTable estimate={estimate} tableVariant="estimate" />
          <PrintItemDetailsEstimateTable
            canViewTable={canViewMarkups}
            estimate={estimate}
            tableVariant="markup"
          />
          <PrintItemDetailsEstimateTable
            canViewTable={canViewMarkups}
            estimate={estimate}
            tableVariant="inherited"
          />
          <PrintItemDetailsContingencyDraws draws={estimate.contingencyDraws} />
          <div className="print-estimate-footer print-avoid-page-break">
            <div className="print-estimate-footer-text">Total Cost Impact (Exact)</div>
            <div className="print-right-align print-estimate-footer-text print-cost-text">
              {itemTotal}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PrintItemDetailsEstimate;
