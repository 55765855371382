import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { calcMaxViewerHeight } from '../../utils';

import AssetsImageViewerStyles from './AssetsImageViewerStyles';

type AssetsImageViewerProps = {
  asset: Asset;
  classes: Classes<typeof AssetsImageViewerStyles>;
};

const AssetsImageViewer: FC<AssetsImageViewerProps> = ({ asset, classes }) => (
  <img
    className={classes.thumbnail}
    src={asset.blobUrl ?? undefined}
    alt="Asset"
    style={{ maxHeight: calcMaxViewerHeight(window.innerHeight) }}
  />
);

export default withStyles(AssetsImageViewerStyles)(AssetsImageViewer);
