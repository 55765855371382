import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const NAV_HEIGHT = 47;

export default (theme: KomodoTheme) =>
  createStyles({
    appBar: {
      display: 'block',
      backgroundColor: theme.palette.chartHeaderGrey, // this matches the "paper" color for the scroll bar
      paddingRight: '0 !important',
    },
    menuBar: {
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.disabledGrey}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '@media print': {
        display: 'none',
      },
    },
    avatar: {
      width: 36,
      height: 36,
      borderRadius: '50%',
      backgroundColor: 'white',
      objectFit: 'cover',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      height: 28,
      margin: 8,
    },
    noWrap: {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      height: 44,
    },
    printFrame: {
      borderBottom: `1px solid ${theme.palette.disabledGrey}`,
      padding: 0,
      display: 'none',
      width: '100%',
      alignContent: 'flex-start',
      backgroundColor: 'white',
      '@media print': {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
    },
    printTypography: {
      alignSelf: 'center',
      flex: '1 1 auto',
    },
    spacer: {
      flexGrow: 1,
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    thumbnail: {
      marginRight: '8px',
      display: 'inline-flex',
    },
  });
