import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  LINK_REMOTE_MILESTONE_ASSET,
  UPLOAD_ATTACH_MILESTONE_ASSET,
} from '../../../../actions/actionTypes';
import { getMilestoneAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { selectedAssetsVar } from '../../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { useAttachToMilestone } from '../../../assets/hooks/mutations/useAttachToMilestone';
import { filterSelectedAssets } from '../FilesTable/FilesTableUseUtils';

import FilesAttachButton from './FilesAttachButton';

type FilesAttachMilestoneProps = {
  milestoneID: UUID;
  onClick: () => void;
  selectedMap: Record<string, boolean>;
  sourceSystem?: SourceSystem;
};

const FilesAttachMilestone: FC<FilesAttachMilestoneProps> = ({
  milestoneID,
  onClick,
  selectedMap,
  sourceSystem,
}) => {
  // Props
  const { hubID, sourceID, sourceProjectID } = useReactiveVar(selectedAssetsVar);

  // Hooks
  const sendAnalytics = useAnalyticsEventHook();

  const attachToMilestone = useAttachToMilestone();

  const analyticsType = sourceSystem ? LINK_REMOTE_MILESTONE_ASSET : UPLOAD_ATTACH_MILESTONE_ASSET;

  // Actions
  const onClickAttachToMilestone = () => {
    filterSelectedAssets(selectedMap).forEach((assetID) => {
      attachToMilestone({
        input: { assetID, milestoneID, sourceSystem, sourceID, sourceProjectID, hubID },
      });

      sendAnalytics(getMilestoneAssetAnalytics(analyticsType, milestoneID, assetID));
    });

    onClick();
  };

  return <FilesAttachButton onClick={onClickAttachToMilestone} selectedMap={selectedMap} />;
};

export default FilesAttachMilestone;
