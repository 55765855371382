import { FC, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Card, CardHeader, FormControlLabel, Switch } from '@material-ui/core';

import { projectSettingsVar } from '../../../api/apollo/reactiveVars';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useUpdateProjectSetting } from '../../ProjectDisplaySettings/hooks';
import SelectValue from '../../Select/SelectValue/SelectValue';

import ProjectScheduleImpactDisableModal from './ProjectScheduleImpactDisableModal';
import ProjectScheduleImpactSettingsStyles from './ProjectScheduleImpactSettingsStyles';

type ScheduleImpactSettingsProps = {
  classes: Classes<typeof ProjectScheduleImpactSettingsStyles>;
  projectID: UUID;
};
type ScheduleImpactTypesMap = {
  [key: string]: string;
};

export const scheduleDisplayTypes = ['Work Days', 'Calendar Days'];
const typeMap: ScheduleImpactTypesMap = {
  WORK_DAYS: 'Work Days',
  CALENDAR_DAYS: 'Calendar Days',
  DISABLED: 'disabled',
};

// util function to get the schedule type from the project settings
export const getScheduleType = () => {
  const settings = projectSettingsVar();
  const key = settings.SCHEDULE_IMPACT_DISPLAY;
  if (key) {
    return typeMap[key];
  }
  return undefined;
};

const usePrevScheduleType = () => {
  const settings = useReactiveVar(projectSettingsVar);
  const key = settings.PREV_SCHEDULE_IMPACT_DISPLAY;
  return key;
};

export const isScheduleSettingsDisabled = () => {
  const type = getScheduleType();
  return !type || type === typeMap.DISABLED;
};

function getKeyByValue(object: ScheduleImpactTypesMap, value?: string) {
  if (!value) {
    return undefined;
  }
  return Object.keys(object).find((key) => object[key] === value);
}

const ScheduleImpactSettings: FC<ScheduleImpactSettingsProps> = ({ classes, projectID }) => {
  const scheduleType = getScheduleType();
  const [scheduleDisplayType, setScheduleDisplayType] = useState<string | undefined>(scheduleType);

  const [prevScheduleKey, setPrevScheduleKey] = useState<string | undefined>(usePrevScheduleType());
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const updatePrevSchedule = useUpdateProjectSetting(projectID, 'PREV_SCHEDULE_IMPACT_DISPLAY');
  const updateSchedule = useUpdateProjectSetting(projectID, 'SCHEDULE_IMPACT_DISPLAY');

  // necessary because the value is retained briefly from other projects when the display type is checked
  useEffect(() => {
    if (scheduleDisplayType !== scheduleType) {
      setScheduleDisplayType(scheduleType);
    }
  }, [scheduleType, scheduleDisplayType]);

  return (
    <>
      <Card elevation={0} className={classes.card} square>
        <CardHeader
          id="scheduleImpact"
          title="Item Schedule Impact and Display"
          subheader="Allow your team to include Schedule Impact information on Items with the appropriate unit for your project."
          action={
            <div className={classes.inputs}>
              {scheduleDisplayType && scheduleDisplayType !== typeMap.DISABLED && (
                <SelectValue
                  cySelect="ScheduleImpactSettings-Select"
                  values={scheduleDisplayTypes}
                  value={scheduleDisplayType}
                  onChange={(v) => {
                    const prevKey = getKeyByValue(typeMap, scheduleDisplayType);
                    if (prevKey) {
                      setPrevScheduleKey(prevKey);
                      updatePrevSchedule(prevKey);
                    }
                    const key = getKeyByValue(typeMap, v);
                    if (key) {
                      updateSchedule(key);
                    }
                    setScheduleDisplayType(v);
                  }}
                />
              )}
              <FormControlLabel
                className={`${
                  scheduleDisplayType === typeMap.DISABLED ? classes.disabled : classes.enabled
                }`}
                control={
                  <Switch
                    data-cy="ScheduleImpactSettings-Switch"
                    checked={scheduleDisplayType !== typeMap.DISABLED}
                    onChange={() => {
                      if (scheduleDisplayType !== typeMap.DISABLED) {
                        setDisableModalOpen(true);
                      } else if (prevScheduleKey) {
                        updateSchedule(prevScheduleKey);
                        setScheduleDisplayType(typeMap[prevScheduleKey]);
                        setPrevScheduleKey('DISABLED');
                        updatePrevSchedule('DISABLED');
                      }
                    }}
                  />
                }
                label={scheduleDisplayType === typeMap.DISABLED ? 'Disabled' : 'Enabled'}
              />
            </div>
          }
        />
      </Card>
      <ProjectScheduleImpactDisableModal
        open={disableModalOpen}
        onClose={() => setDisableModalOpen(false)}
        onSubmit={() => {
          const prevKey = getKeyByValue(typeMap, scheduleDisplayType);
          if (prevKey) {
            setPrevScheduleKey(prevKey);
            updatePrevSchedule(prevKey);
          }
          updateSchedule('DISABLED');
          setScheduleDisplayType(typeMap.DISABLED);
        }}
      />
    </>
  );
};
export default withStyles(ProjectScheduleImpactSettingsStyles)(ScheduleImpactSettings);
