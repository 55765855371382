import { Route } from 'react-router-dom';

import { GenericJoinRoutes, SharedRoute } from '../../routes/routes';

export const listAccessibleRoutes = (
  routes: GenericJoinRoutes,
  isAccessible: (route: SharedRoute) => boolean
) => Object.entries(routes).filter(([, route]) => isAccessible(route));

type RouteComponent = {
  element: JSX.Element;
  /** Components for any subroutes of this route. They should be rendered by <Outlet>s */
  subs?: { [key in string]: { element: JSX.Element } };
};

type RouteToComponent = {
  [key in string]: RouteComponent;
};

export const getSubRoutes = (
  routes: GenericJoinRoutes,
  routeToComponent: RouteToComponent,
  isAccessible: (route: SharedRoute) => boolean
) =>
  listAccessibleRoutes(routes, isAccessible).map(([key, route]) => {
    const { routes, path } = route;
    const { element, subs: routeToSubComponent } = routeToComponent[key];
    const hasSubRoutes = !!routes && !!routeToSubComponent;

    return (
      <Route key={path} path={path} element={element}>
        {hasSubRoutes && getSubRoutes(routes, routeToSubComponent, isAccessible)}
      </Route>
    );
  });
