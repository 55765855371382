import { FC } from 'react';

import { SelectVariant } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getItemIdFromUrl } from '../../../utilities/url';

import SelectCategoryBulkOption from './SelectCategoryBulkOption';
import SelectCategoryOption from './SelectCategoryOption';
import SelectCategoryOptionFirst from './SelectCategoryOptionFirst';
import styles from './SelectCategoryStyles';
import { formatCategoryDisplayName, getHasSubcategoryPermissions } from './SelectCategoryUtils';

interface SelectCategoryProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  categorySearchResultsCurrent: any;
  classes: Classes<typeof styles>;
  handleClick: (target: 'category' | 'parent' | 'subCategories' | 'bulkToggle') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleHover: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleUpdate: (event: any) => void;
  hasHistory: boolean;
  hasSearchResults: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  highlightedCategory: any;
  isScrollableDropdown: boolean;
  noCategoriesFound: boolean;
  noun: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  selectedCategories: any[];
  selectVariant: SelectVariant;
  slideDirection: 'left' | 'right';
  inTrade: boolean;
}

const SelectCategoryOptionList: FC<SelectCategoryProps> = ({
  categorySearchResultsCurrent,
  classes,
  handleClick,
  handleHover,
  handleUpdate,
  hasHistory,
  hasSearchResults,
  highlightedCategory,
  isScrollableDropdown,
  noCategoriesFound,
  noun,
  selectedCategories,
  selectVariant,
  slideDirection,
  inTrade,
}) => {
  const itemID = getItemIdFromUrl();

  // DISPLAY LOGIC
  const lastCategory =
    categorySearchResultsCurrent.length > 0
      ? categorySearchResultsCurrent[categorySearchResultsCurrent.length - 1]
      : {};
  // if user is nested in subcategories
  const isNested = hasHistory;
  const isMultiSelectVariant = selectVariant === SelectVariant.MULTI;
  // UI GENERATORS
  const listEmptyClass = categorySearchResultsCurrent.length === 1 ? classes.listEmpty : '';
  // loop through all but the first option
  // because the first option has it's own component
  let indexToStartSlice = 1;
  if (isMultiSelectVariant) {
    indexToStartSlice = isNested ? 2 : 1;
  }

  const options = categorySearchResultsCurrent
    .slice(indexToStartSlice, categorySearchResultsCurrent.length)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .map((category: any, index: number) => {
      const { name, number } = formatCategoryDisplayName(category);
      const isSelected =
        selectVariant === SelectVariant.SINGLE
          ? highlightedCategory === category
          : Boolean(selectedCategories.find((content) => content.id === category.id));
      return (
        <SelectCategoryOption
          category={category}
          canViewSubcategories={getHasSubcategoryPermissions(inTrade, itemID)}
          handleClick={handleClick}
          handleHover={handleHover}
          highlightedCategory={highlightedCategory}
          index={index}
          isSelected={isSelected}
          key={`${category.id}-${category.name}`}
          name={name}
          number={number}
          selectVariant={selectVariant}
          slideDirection={slideDirection}
        />
      );
    });
  const indexOfFirstOption = isNested && selectVariant === SelectVariant.MULTI ? 1 : 0;

  const firstOption = (
    <SelectCategoryOptionFirst
      firstCategory={
        categorySearchResultsCurrent.length > 0
          ? categorySearchResultsCurrent[indexOfFirstOption]
          : {}
      }
      handleClick={handleClick}
      handleHover={handleHover}
      handleUpdate={handleUpdate}
      hasHistory={hasHistory}
      highlightedCategory={highlightedCategory}
      key="firstItems"
      lastCategory={lastCategory}
      noCategoriesFound={noCategoriesFound}
      selectVariant={selectVariant}
      slideDirection={slideDirection}
    />
  );

  const bulkOption = (
    <SelectCategoryBulkOption
      contentListLength={categorySearchResultsCurrent.length}
      handleClick={handleClick}
      handleHover={handleHover}
      highlightedCategory={highlightedCategory}
      noun={noun}
      selectedCategories={selectedCategories}
      slideDirection={slideDirection}
    />
  );
  return (
    <ul
      className={`${listEmptyClass} ${isScrollableDropdown ? classes.list : classes.listNoScroll}`}
      id="listbox-list"
      role="listbox"
    >
      {selectVariant === SelectVariant.MULTI && bulkOption}
      {firstOption}
      {hasSearchResults && options}
    </ul>
  );
};

export default withStyles(styles)(SelectCategoryOptionList);
