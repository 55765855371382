import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    tab: {
      textDecoration: 'none !important',
      color: theme.palette.primaryGrey,
      minWidth: 80,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    badge: {
      textTransform: 'none',
    },
  });
