import { FC, useMemo, useState } from 'react';

import { ITEM, ITEM_WITH_OPTIONS } from '../../constants';
import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './CheckboxContainerStyles';
import CheckboxIndeterminateContainer, { CheckboxVariant } from './CheckboxIndeterminateContainer';

type CheckboxContainerProps = {
  classes: Classes<typeof styles>;
  itemsCount: { visibleCount: number; selectedCount: number };
  selectAllVisibleItems?: (checkAll: boolean) => void;
  settings: Settings;
};

const CheckboxContainer: FC<CheckboxContainerProps> = ({
  classes,
  itemsCount,
  selectAllVisibleItems,
  settings,
}) => {
  // HOOKS
  const [checkboxChecked, setToggleCheckbox] = useState(false);

  // FUNCTIONS

  const toggleCheckbox = () => {
    setToggleCheckbox(!checkboxChecked);
    if (selectAllVisibleItems) {
      selectAllVisibleItems(!checkboxChecked);
    }
  };

  // VARIABLES
  const { groupBy, show } = settings;

  const checkbox = useMemo(() => {
    // Checkbox state
    const selected =
      itemsCount.visibleCount === itemsCount.selectedCount && itemsCount.selectedCount !== 0;
    const empty = itemsCount.selectedCount === 0 && itemsCount.visibleCount !== 0;
    const indeterminate =
      itemsCount.visibleCount !== itemsCount.selectedCount && itemsCount.selectedCount !== 0;

    // Sizing constants: adjust padding to fit different nesting scenarios
    let checkboxSize = groupBy.length === 0 && show === ITEM ? classes.checkboxUnnestedList : '';
    if (groupBy.length === 0 && show === ITEM_WITH_OPTIONS)
      checkboxSize = classes.checkboxWidthWithOptions;
    if (groupBy.length === 1 && show === ITEM_WITH_OPTIONS)
      checkboxSize = classes.checkboxWidthWithGroupings;
    if (groupBy.length > 4) checkboxSize = classes.checkboxNestedList;

    let variant = CheckboxVariant.Empty;
    if (indeterminate) variant = CheckboxVariant.Indeterminate;
    if (selected) variant = CheckboxVariant.Selected;
    if (empty) variant = CheckboxVariant.Empty;

    return (
      <CheckboxIndeterminateContainer
        checkboxStyle={checkboxSize}
        variant={variant}
        onToggle={toggleCheckbox}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, itemsCount, groupBy, show]);

  return checkbox;
};

export default withStyles(styles)(CheckboxContainer);
