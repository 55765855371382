import { FC, useEffect, useRef, useState } from 'react';
import { useClickAway, useEffectOnce, useKey } from 'react-use';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Search from '@material-ui/icons/Search';

import { InputVariant, SelectVariant } from '../../../api/gqlEnums';
import useListCategoriesQuery from '../../../hooks/useListCategoriesQuery';
import useListSubcategoriesQuery from '../../../hooks/useListSubcategoriesQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getItemIdFromUrl } from '../../../utilities/url';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import SelectCategoryChip from '../SelectCategoryChipInput/SelectCategoryChip';

import SelectCategoryOptionList from './SelectCategoryOptionList';
import styles from './SelectCategoryStyles';
import {
  CLEAR_SEARCH,
  SelectCategoryInteractionVariants,
  SelectCategoryValue,
  bulkToggleOption,
  clearSearchOption,
  generateBackOption,
  getHasSubcategoryPermissions,
  isGoingBackwards,
  isSelectCategoryInteraction,
  noneOption,
  uncategorizedOption,
} from './SelectCategoryUtils';

interface SelectCategoryProps {
  addCategories: (categories: Category[]) => void;
  categorizationID: UUID;
  classes: Classes<typeof styles>;
  includeUncategorizedCategory?: boolean;
  inputValue: string;
  inputVariant: InputVariant;
  isCompact?: boolean;
  isPreview?: boolean;
  noun: string;
  onBlur?: () => void;
  onRendered?: () => void;
  onSubmit: () => void;
  placeholder?: string;
  removeCategories: (categories: Category[]) => void;
  selectVariant: SelectVariant;
  selectedCategories: Category[];
}

const SelectCategory: FC<SelectCategoryProps> = ({
  addCategories,
  categorizationID,
  classes,
  includeUncategorizedCategory,
  inputValue,
  inputVariant = InputVariant.GRID,
  isCompact = true,
  isPreview = true,
  noun,
  onBlur,
  onRendered,
  onSubmit,
  placeholder = undefined,
  removeCategories,
  selectVariant,
  selectedCategories,
}) => {
  const isSingleSelectVariant = selectVariant === SelectVariant.SINGLE;
  const isMultiSelectVariant = selectVariant === SelectVariant.MULTI;

  const itemId = getItemIdFromUrl();
  const { data: { item: currentItem = null } = {} } = useItemQuery(itemId);
  // PERMISSIONS
  let { inTrade } = usePermissions({ trades: currentItem?.categories });
  if (!isPreview) inTrade = true;

  // text value for the input
  const [stateValue, setStateValue] = useState<string>(isSingleSelectVariant ? '' : inputValue);
  // search results
  const [categorySearchResults, setCategorySearchResults] = useState<Category[]>([]);

  const level = !stateValue.length ? 1 : undefined;
  const { data: { category } = { category: [] }, loading } = useListCategoriesQuery(
    categorizationID,
    stateValue,
    level
  );

  useEffect(() => {
    if (loading || (!categorySearchResults.length && !category.length)) return;
    setCategorySearchResults(category as Category[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, loading]);

  // INLINE STYLES FOR VARIANTS
  const getInputVariantStyles = (): { minHeight: string } => {
    const inputVariantStyles = { minHeight: '' };
    switch (inputVariant) {
      case InputVariant.GRID:
        inputVariantStyles.minHeight = '';
        break;
      case InputVariant.CHIP_INPUT:
        inputVariantStyles.minHeight = '40px';
        break;
      default:
        inputVariantStyles.minHeight = '';
        break;
    }
    return inputVariantStyles;
  };

  // STATE
  // the history of the visible list of categories in the dropdown
  // updates when you navigate through subcategories
  const [categorySearchResultsHistory, setCategorySearchResultsHistory] = useState<
    SelectCategoryValue[][]
  >([]);
  const hasHistory = categorySearchResultsHistory.length > 1;
  // the scrollTop history of the dropdown.
  // updates when you navigate through subcategories
  const [scrollTopHistory, setScrollTopHistory] = useState<number[]>([]);
  const hasSearchResults = categorySearchResults.length > 0;
  // the current viewable list of search results
  const categorySearchResultsCurrent =
    categorySearchResultsHistory.length > 0
      ? categorySearchResultsHistory[categorySearchResultsHistory.length - 1]
      : [];
  const [isEditingInput, setIsEditingInput] = useState(false);
  // for animations
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
  const defaultCursorPosition = inputValue.length > 0 ? inputValue.length : 1;
  const [inputCursorPosition, setInputCursorPosition] = useState(defaultCursorPosition);
  const defaultHighlightedCategoryMulti = categorySearchResults[0]
    ? categorySearchResults[0]
    : noneOption;
  const [highlightedCategory, setHighlightedCategory] = useState<SelectCategoryValue>(
    isSingleSelectVariant ? noneOption : defaultHighlightedCategoryMulti
  );
  const selectCategoryRef = useRef(null);
  // the dropdown list area
  const dropdownArea = document!.getElementById('listbox-list')!;
  // the input
  const inputRef = useRef<HTMLInputElement>(null);
  const [isScrollableDropdown, setIsScrollableDropdown] = useState(false);
  // if the user intends on clearing their search
  const shouldClearSearch = isSelectCategoryInteraction(highlightedCategory)
    ? highlightedCategory.variant === CLEAR_SEARCH
    : false;

  // HANDLERS

  // handles the dynamic height of the dropdown list based on viewport height
  useEffect(() => {
    if (dropdownArea) {
      dropdownArea.style.maxHeight = `${(
        window.innerHeight - dropdownArea.getBoundingClientRect().top
      ).toString()}px`;
    }
  }, [dropdownArea, isScrollableDropdown]);

  // handles scrolling the scrollable list area to the top to reset it
  const handleScrollTop = () => {
    if (dropdownArea) {
      dropdownArea.scrollTop = 0;
    }
  };

  // handles when the search term is updated
  const onUpdate = (updateValue: string) => {
    setStateValue(updateValue);
    handleScrollTop();
  };

  const shouldSkip =
    categorySearchResultsCurrent.indexOf(highlightedCategory) === 0 &&
    categorySearchResultsHistory.length > 0 &&
    highlightedCategory.levels &&
    highlightedCategory.level === highlightedCategory.levels.length; // don't check for subcategories if category is the parent in the list

  const isPlaceholder = isSelectCategoryInteraction(highlightedCategory);

  // GET SUBCATEGORIES DATA
  const { data: subCategories } = useListSubcategoriesQuery(
    categorizationID,
    highlightedCategory.id,
    50,
    isPlaceholder || shouldSkip
  );
  const highlightedCategoryHasSubCategories = subCategories && subCategories.subcategory.length > 0;

  // handles adding or removing a category from the selected categories list
  const handleUpdateCategories = () => {
    const hasSelectedCategories = selectedCategories.length > 0;
    // BULK ADD/REMOVE CATEGORIES
    if (highlightedCategory === bulkToggleOption) {
      if (hasSelectedCategories) {
        // if there are selected categories, we remove all of them
        removeCategories(selectedCategories);
      } else {
        addCategories(categorySearchResultsCurrent);
      }
    }
    // INDIVIDUAL ADD/REMOVE CATEGORIES
    if (categorySearchResults.length > 0) {
      if (selectedCategories.map((cat) => cat.id).includes(highlightedCategory.id)) {
        removeCategories([highlightedCategory]);
      } else {
        addCategories([highlightedCategory]);
      }
    } else {
      // when there is gibberish in the input with no actionable categories to match with it
      // just send the stateValue ¯\_(ツ)_/¯
      // eslint-disable-next-line no-lonely-if
      if (highlightedCategory !== clearSearchOption) {
        addCategories([{ name: stateValue, number: '', id: '', level: 1 }] as Category[]);
      } else {
        onUpdate('');
      }
    }
  };

  // used to figure out how far to scroll the dropdown area while arrowing through results
  const getcurrentListItemOffsetHeight = (): {
    currentListItemOffsetHeight: number;
  } => {
    const highlightedCategoryIndex = categorySearchResultsCurrent.indexOf(highlightedCategory);
    const currentListItem =
      dropdownArea && dropdownArea.getElementsByTagName('li')
        ? dropdownArea.getElementsByTagName('li')[highlightedCategoryIndex]
        : null;
    const currentListItemOffsetHeight = currentListItem ? currentListItem.offsetHeight - 0.5 : 0;

    return { currentListItemOffsetHeight };
  };

  // handles when you click to go back after being nested in subcategories
  const handleGoingBack = () => {
    if (isGoingBackwards(highlightedCategory)) {
      setSlideDirection('right');
      const { length: searchResultsHistoryLength } = categorySearchResultsHistory;
      // get rid of the old category list history & update viewable list
      const newContentArrayHistory = categorySearchResultsHistory.slice(
        0,
        searchResultsHistoryLength - 1
      );
      setCategorySearchResultsHistory(newContentArrayHistory);
      // highlight the parent category
      const { name, number } = highlightedCategory;
      const newHighlightedCategory = newContentArrayHistory[newContentArrayHistory.length - 1].find(
        (x) => x.name === name && x.number === number
      );
      setHighlightedCategory(newHighlightedCategory || uncategorizedOption);
      // scroll to the old position
      const { length: scrollHistoryLength } = scrollTopHistory;
      const lastScrollOffest = scrollTopHistory[scrollHistoryLength - 1];
      dropdownArea.scrollBy(0, lastScrollOffest);
      const newCategorySearchAnchorScrollHistory = scrollTopHistory.slice(
        0,
        scrollHistoryLength - 1
      );
      setScrollTopHistory(newCategorySearchAnchorScrollHistory);
    }
  };

  // populates the viewable dropdown category list with a category's subcategories
  const handleSubcategories = () => {
    if (highlightedCategoryHasSubCategories && !isSelectCategoryInteraction(highlightedCategory)) {
      setSlideDirection('left');
      const backOption = generateBackOption(highlightedCategory);
      let newHistory;
      if (isSingleSelectVariant) {
        newHistory = [...categorySearchResultsHistory, [backOption, ...subCategories.subcategory]];
      } else {
        newHistory = [
          ...categorySearchResultsHistory,
          [bulkToggleOption, backOption, ...subCategories.subcategory],
        ];
      }

      // keep track of where your scroll position was in this list
      const { scrollTop } = dropdownArea;
      const newCategorySearchAnchorScrollHistory = [...scrollTopHistory, scrollTop];
      setScrollTopHistory(newCategorySearchAnchorScrollHistory);
      // update the new viewable data
      setCategorySearchResultsHistory(newHistory as SelectCategoryValue[][]);
      // make sure the parent category is selected
      setHighlightedCategory(backOption);
      // scroll the container to the top
      handleScrollTop();
    }
  };

  // when a user clicks on an option
  const handleClick = (target: 'category' | 'parent' | 'subCategories' | 'bulkToggle'): void => {
    // this makes sure that we always keep the select in focus
    if (inputRef.current) inputRef.current.focus();

    if (target === 'category') {
      handleUpdateCategories();
    }
    if (target === 'parent') {
      handleGoingBack();
    }
    if (target === 'subCategories') {
      handleSubcategories();
    }
    if (target === 'bulkToggle') {
      handleUpdateCategories();
    }
  };

  // when a user hovers over an option
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleHover = (categoryHover: any): void => {
    setHighlightedCategory(categoryHover);
  };

  // returns the current category index depending on variant
  const getCurrentCategoryIndex = (): number => {
    // when select variant is single
    const currentCatIndexSingle =
      categorySearchResultsCurrent.indexOf(highlightedCategory) > -1
        ? categorySearchResultsCurrent.indexOf(highlightedCategory)
        : 0;
    // when select variant is multi
    const currentCatIndexMulti = categorySearchResultsCurrent.indexOf(highlightedCategory);
    return isSingleSelectVariant ? currentCatIndexSingle : currentCatIndexMulti;
  };

  // index position floor/ceiling logic
  const getFloorAndCeilingValues = (): {
    categorySearchResultsHasLessItems: boolean;
    categorySearchResultsHasMoreItems: boolean;
    currentCategoryIndex: number;
  } => {
    const highlightedCategoryIndex = categorySearchResultsCurrent.indexOf(highlightedCategory);
    const categorySearchResultsHasMoreItems =
      highlightedCategoryIndex < categorySearchResultsCurrent.length - 1;
    const categorySearchResultsHasLessItems = highlightedCategoryIndex > 0;
    const currentCategoryIndex = getCurrentCategoryIndex();

    return {
      categorySearchResultsHasLessItems,
      categorySearchResultsHasMoreItems,
      currentCategoryIndex,
    };
  };

  // handles all keyboard navigation interractions (except Enter/Escape)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleNavigation = (event: any) => {
    const { key } = event;
    const {
      categorySearchResultsHasLessItems,
      categorySearchResultsHasMoreItems,
      currentCategoryIndex,
    } = getFloorAndCeilingValues();
    const { currentListItemOffsetHeight } = getcurrentListItemOffsetHeight();

    // GOING DOWN THE LIST
    if (['ArrowDown'].includes(key)) {
      event.preventDefault();
      setIsEditingInput(false);
      if (categorySearchResultsHasMoreItems) {
        if (categorySearchResultsCurrent.length > 1) {
          setHighlightedCategory(categorySearchResultsCurrent[currentCategoryIndex + 1]);
        } else {
          setHighlightedCategory(categorySearchResultsCurrent[0]);
        }
        // SCROLL BEHAVIOR
        const indexToScrollAfter = isSingleSelectVariant ? 2 : 1;
        if (currentCategoryIndex > indexToScrollAfter) {
          dropdownArea.scrollBy(0, currentListItemOffsetHeight);
        }
      }
    }

    // GOING UP THE LIST
    if (key === 'ArrowUp') {
      event.preventDefault();
      if (categorySearchResultsHasLessItems) {
        setHighlightedCategory(categorySearchResultsCurrent[currentCategoryIndex - 1]);
        dropdownArea.scrollBy(0, currentListItemOffsetHeight * -1);
      } else {
        setIsEditingInput(true);
        setInputCursorPosition(stateValue.length);
      }
    }

    // GOING LEFT
    if (key === 'ArrowLeft') {
      if (isEditingInput) {
        // eslint-disable-next-line no-param-reassign
        event.target.selectionStart = inputCursorPosition - 1;
        // eslint-disable-next-line no-param-reassign
        event.target.selectionEnd = inputCursorPosition - 1;
        if (inputCursorPosition > 0) {
          setInputCursorPosition(inputCursorPosition - 1);
        }
      } else {
        handleGoingBack();
        if (onBlur) {
          if (!hasHistory) {
            onBlur();
          }
        }
      }
    }

    // GOING RIGHT
    if (key === 'ArrowRight') {
      if (isEditingInput) {
        const newInputCursorPosition = inputCursorPosition + 1;
        setInputCursorPosition(newInputCursorPosition);
        // eslint-disable-next-line no-param-reassign
        event.target.selectionStart = newInputCursorPosition;
        // eslint-disable-next-line no-param-reassign
        event.target.selectionEnd = newInputCursorPosition;
      } else {
        handleSubcategories();
      }
    }
  };

  // COPY/PASTE

  // copy functionality
  async function writeToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  // paste functionality
  async function readFromClipboard() {
    try {
      await navigator.clipboard.readText().then((response) => {
        setStateValue(response);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  // WINDOW LISTENERS

  // watch for keyup events
  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  // generates initial search results on load
  useEffectOnce(() => {
    setStateValue(stateValue);
    if (onRendered) {
      onRendered();
    }
  });

  // HANDLE KEY CLOSE EVENTS
  // ESC key closes the component
  useKey('Escape', onSubmit);
  useKey('Tab', onSubmit);
  // clicking away closes the component
  useClickAway(selectCategoryRef, onSubmit);

  // SEARCH RESULTS LOGIC
  // wait for second render because firt has generic results
  const [renderCount, setRenderCount] = useState(0);
  // Watches for search results
  useEffect(() => {
    if (renderCount >= 0) {
      let newResults;
      if (isSingleSelectVariant) {
        newResults = hasSearchResults
          ? [uncategorizedOption, ...categorySearchResults]
          : [clearSearchOption, ...categorySearchResults];
      } else if (includeUncategorizedCategory) {
        // MULTI SELECT
        // if the select supports uncategorized. Not all do.
        newResults = hasSearchResults
          ? [bulkToggleOption, uncategorizedOption, ...categorySearchResults]
          : [bulkToggleOption, clearSearchOption, ...categorySearchResults];
      } else {
        // MULTI SELECT
        newResults = hasSearchResults
          ? [bulkToggleOption, ...categorySearchResults]
          : [bulkToggleOption, clearSearchOption, ...categorySearchResults];
      }

      setCategorySearchResultsHistory([newResults]);

      // auto highlight the top result
      if (hasSearchResults && stateValue.length > 0) {
        setHighlightedCategory(categorySearchResults[0]);
      }

      // if nothing is searched, autohighlight the uncategorized option
      if (isMultiSelectVariant && stateValue.length === 0) {
        setHighlightedCategory(uncategorizedOption);
      }

      handleScrollTop();
    }
    setRenderCount(renderCount + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySearchResults]);

  // handles updating scroll height when the history changes
  useEffect(() => {
    setIsScrollableDropdown(dropdownArea && dropdownArea.clientHeight < dropdownArea.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySearchResultsHistory]);

  return (
    <div ref={selectCategoryRef} className={classes.containerOuter} id="SelectCategory">
      {isSingleSelectVariant && (
        <SelectCategoryChip
          categoryString={inputValue}
          expanded
          hasSelectedCategory={!!inputValue}
          inputId="SelectCategory"
          isCompact={isCompact}
          onClick={onSubmit}
          placeholder={placeholder}
          variant={inputVariant}
        />
      )}
      <div
        aria-controls="listbox-input"
        aria-expanded
        aria-haspopup="listbox"
        aria-owns="listbox-list"
        className={classes.containerInput}
        role="combobox"
      >
        <Search classes={{ root: classes.iconSearch }} />
        <input
          aria-autocomplete="list"
          aria-controls="listbox-list"
          autoComplete="off"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={classes.input}
          data-cy="SelectCategory-input"
          disabled={!getHasSubcategoryPermissions(inTrade, itemId)} // don't allow editing unless user has category permissions for the item
          id="listbox-input"
          onChange={(event) => {
            onUpdate(event.target.value);
          }}
          onClick={() => {
            setIsEditingInput(true);
            const currentInput = document && document.activeElement;
            if (currentInput) {
              const selectionStart =
                currentInput &&
                currentInput instanceof HTMLInputElement &&
                currentInput.selectionStart;
              const newPosition = currentInput ? selectionStart : stateValue.length - 1;
              if (newPosition) setInputCursorPosition(newPosition);
            }
          }}
          onKeyDown={(event) => {
            const { ctrlKey, key } = event;
            if (key === 'Enter') {
              if (
                isSingleSelectVariant &&
                !stateValue &&
                (highlightedCategory as unknown as SelectCategoryInteractionVariants).variant ===
                  (noneOption as SelectCategoryInteractionVariants).variant
              ) {
                // if open selector and click enter, just close selector
                onSubmit();
              } else if (shouldClearSearch) {
                // if we want to clear results because no results are found
                onUpdate('');
              } else {
                handleUpdateCategories();
              }
            } else if (key === 'Backspace' || key === 'Delete') {
              if (isSingleSelectVariant && !stateValue) {
                // if input is empty and user click delete, set to uncategorized
                addCategories([uncategorizedOption]);
              }
            }
            // COPY/PASTE
            if (ctrlKey) {
              if (key === 'c') writeToClipboard(stateValue);
              else if (key === 'v') readFromClipboard();
            }
          }}
          placeholder={`Find a ${noun}`}
          ref={inputRef}
          style={getInputVariantStyles()}
          type="text"
          value={stateValue}
        />
        {isMultiSelectVariant && (
          <div
            title="close"
            role="button"
            className={classes.iconDropUpArrowButton}
            onClick={() => {
              onSubmit();
            }}
            onKeyPress={() => {
              onSubmit();
            }}
            tabIndex={0}
          >
            <ArrowDropUpIcon />
          </div>
        )}
      </div>
      <SelectCategoryOptionList
        categorySearchResultsCurrent={categorySearchResultsCurrent}
        handleClick={handleClick}
        handleHover={handleHover}
        handleUpdate={onUpdate}
        hasHistory={hasHistory}
        hasSearchResults={hasSearchResults}
        highlightedCategory={highlightedCategory}
        isScrollableDropdown={isScrollableDropdown}
        noCategoriesFound={categorySearchResults.length === 0}
        noun={noun}
        selectedCategories={selectedCategories}
        selectVariant={selectVariant}
        slideDirection={slideDirection}
        inTrade={inTrade}
      />
    </div>
  );
};

const selectCategoryStyled = withStyles(styles)(SelectCategory);

export default selectCategoryStyled;
