import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import { ToastType } from '../../../api/gqlEnums';
import { Status } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import useUpdateBulkItems from '../../ItemsList/BulkEditingPanel/ItemsListBulkEditingPanelHook';
import { useGetEstimateSourceItemInfos } from '../../JoinGrid/hooks/estimateQuery';
import { useUpdateMilestonePublishDraft } from '../hooks';

import RunningTotalEstimateDraftPublishModal from './RunningTotalEstimatePublishDraftModal';

type MilestonePublishModalManagerProps = {
  isOpen: boolean;
  milestone: Milestone;
  projectID: UUID;
  setIsOpen: (showMilestonePublishModalManager: boolean) => void;
};

const MilestonePublishModalManager: FC<MilestonePublishModalManagerProps> = ({
  isOpen,
  milestone,
  projectID,
  setIsOpen,
}) => {
  const { activeEstimateID, id: milestoneID, name } = milestone;

  const onSuccess = (itemCount: number) => {
    setToast(
      { message: `${itemCount} items added to ${milestone.name} with status of Incorporated` },
      ToastType.BULK_ITEM_UPDATE,
      null
    );
  };
  const showToast = (message: string) => {
    setToast({ message });
  };
  const updateBulkItems = useUpdateBulkItems(showToast, onSuccess);

  const [selectedItemIDs, setSelectedItemIDs] = useState<string[]>([]);
  const updateSelectedItemIDs = (itemID: string) => {
    if (selectedItemIDs.includes(itemID)) {
      setSelectedItemIDs(selectedItemIDs.filter((id) => id !== itemID));
    } else {
      setSelectedItemIDs((prevItemIDs) => [...prevItemIDs, itemID]);
    }
  };

  const estimateSourceItemInfos =
    useGetEstimateSourceItemInfos(projectID, activeEstimateID ?? undefined).data
      ?.estimateSourceItemInfos ?? [];
  const isRunningTotalEstimate = estimateSourceItemInfos?.length > 0;

  const dialogContent = `Are you sure you want to publish your draft milestone ${name}? Publishing is a permanent action and cannot be undone.`;
  const dialogTitle = name ? `Confirm Publish Milestone: ${name}?` : 'Confirm Publish Draft';

  const updateMilestonePublishDraft = useUpdateMilestonePublishDraft();
  const publishMilestone = (isRunningTotalEstimate: boolean) => {
    if (isRunningTotalEstimate && selectedItemIDs?.length > 0) {
      updateBulkItems(projectID, {
        itemIDs: selectedItemIDs,
        milestoneID,
        status: Status.INCORPORATED,
      });
    }
    updateMilestonePublishDraft(projectID, milestoneID, false);
    setIsOpen(false);
  };

  const generateDraftModalComponent = (
    activeEstimateID: UUID | null | undefined,
    hasSourceItemIDs: boolean
  ) => {
    if (activeEstimateID && hasSourceItemIDs) {
      return (
        <RunningTotalEstimateDraftPublishModal
          sourceItemInfos={estimateSourceItemInfos}
          setSelectedItemIDs={updateSelectedItemIDs}
        />
      );
    }
    return <Typography>{dialogContent}</Typography>;
  };

  if (isOpen) {
    return (
      <JoinDialog
        index={0}
        dynamicHeight
        dialog={{
          open: isOpen,
          onOpen: () => {
            setIsOpen(true);
          },
          onClose: () => setIsOpen(false),
          onNext: undefined,
          disabledNext: false,
          onBack: () => setIsOpen(false),
          contentComponent: generateDraftModalComponent(activeEstimateID, isRunningTotalEstimate),
          headerText: dialogTitle,
          actionButtons: [
            {
              isLeftAligned: true,
              color: 'primary',
              variant: 'outlined',
              onClick: () => {
                setIsOpen(false);
              },
              text: 'Cancel',
            },
            {
              color: 'primary',
              dataCy: 'button-ConfirmPublishDraftMilestone',
              variant: 'contained',
              onClick: () => publishMilestone(isRunningTotalEstimate),
              text: 'Publish',
            },
          ],
        }}
        pageCounter={undefined}
        hasDivider
      />
    );
  }
  return null;
};

export default MilestonePublishModalManager;
