import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onSelectRole: (selected: UUID) => void;
  roles: Role[] | undefined;
  selectedRoleID: UUID;
};

const RolesVerticalMenu = (props: Props) => {
  return (
    <div className="flex">
      <div className="border-r">
        {props.roles &&
          props.roles.map((role) => (
            <div
              aria-label="role button"
              className={`w-48 cursor-pointer pb-2 pl-6 pt-2 type-button focus-visible:outline active:outline-none ${
                props.selectedRoleID === role.id
                  ? 'border-r-4 border-type-primary bg-background-1'
                  : ''
              }`}
              key={role.id}
              onClick={() => props.onSelectRole(role.id)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') props.onSelectRole(role.id);
              }}
              role="button"
              tabIndex={0}
            >
              {role.name}
            </div>
          ))}
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default RolesVerticalMenu;
