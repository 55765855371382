import { FC, useEffect, useState } from 'react';

import { NetworkStatus } from '@apollo/client';

import { ToastType } from '../../../../api/gqlEnums';
import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';
import useItemLinkedChangeEventsQuery from '../../../../hooks/useItemLinkedChangeEvents';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { isNonNullable } from '../../../../utilities/types';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import {
  getSourceAuthURL,
  sourceSystemInfo,
} from '../../../DocumentMagic/Files/FilesDialog/FilesDialogUtils';

import { useGetSourceLinkedProjectQuery } from './hooks/useGetSourceLinkedProjectQuery';
import IntegrationsContent from './IntegrationsContent';
import IntegrationsModalManager, { LinkingAccountState } from './IntegrationsModalManager';
import { ItemInfo } from './IntegrationsUtils';

type IntegrationsDataWrapperProps = {
  isItemSidebar: boolean;
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  itemInfo: ItemInfo;
  isPrinting?: boolean;
  userSources: UserSourcesQuery['userSources'] | undefined;
  onLoadComplete?: () => void;
};

const IntegrationsDataWrapper: FC<IntegrationsDataWrapperProps> = ({
  isItemSidebar,
  canCreateChangeEvent,
  canDeleteItemIntegration,
  itemInfo,
  isPrinting = false,
  userSources,
  onLoadComplete,
}) => {
  const projectID = getProjectIdFromUrl();
  const [linkingAccountState, setLinkingAccountState] = useState<LinkingAccountState | null>(null);
  const [isCreatingChangeEvent, setIsCreatingChangeEvent] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Procore login info
  const availableProcoreSource = userSources?.clients.find(
    (s) => s.sourceSystem === SourceSystem.PROCORE
  );
  const loggedInProcoreSource = userSources?.sources.find(
    (s) => s.sourceSystem === SourceSystem.PROCORE
  );

  // ToDo: until we can link a project or an item in the UI this doesn't return anything
  const sourceID = loggedInProcoreSource?.id ?? '';
  const changeEventData = useItemLinkedChangeEventsQuery(
    {
      sourceID,
      projectID,
      itemID: itemInfo.id,
    },
    (data) => {
      if (data?.itemLinkedChangeEvents?.error)
        setToast({ message: data.itemLinkedChangeEvents.error }, ToastType.SERVER_ERROR);
    }
  );
  const changeEvents =
    changeEventData?.data?.itemLinkedChangeEvents?.changeEvents?.filter(isNonNullable);
  const pageLoadTime = changeEventData?.data?.itemLinkedChangeEvents?.refetchedAt
    ? new Date(changeEventData?.data?.itemLinkedChangeEvents?.refetchedAt)
    : undefined;
  const isLoading =
    changeEventData?.loading ||
    changeEventData?.networkStatus === NetworkStatus.refetch ||
    submitted;

  useEffect(() => {
    if (availableProcoreSource && !changeEventData?.data?.itemLinkedChangeEvents && onLoadComplete)
      onLoadComplete();
  }, [changeEventData?.data?.itemLinkedChangeEvents, onLoadComplete, availableProcoreSource]);

  const {
    refetch: refetchLinkedProjectQuery,
    data: linkedProjectQueryData,
    loading: linkedProjectQueryDataLoading,
  } = useGetSourceLinkedProjectQuery(projectID, SourceSystem.PROCORE);
  const linkedProject = linkedProjectQueryData?.sourceLinkedProject ?? undefined;
  const linkedProjectID = linkedProject?.sourceProjectID;
  const sourceCompanyID = linkedProjectQueryData?.sourceLinkedProject?.sourceCompanyID;

  if (!availableProcoreSource) return null; // TODO: show system error if Procore isn't an available source

  const openProcoreLogin = () => {
    setLinkingAccountState(LinkingAccountState.INITIAL_LINK);
    window.open(
      getSourceAuthURL({
        ...sourceSystemInfo[SourceSystem.PROCORE],
        clientID: availableProcoreSource.clientID,
        sourceSystem: availableProcoreSource.sourceSystem,
      }),
      '_blank'
    );
  };

  return (
    <>
      <IntegrationsContent
        canDeleteItemIntegration={canDeleteItemIntegration}
        onClickLinkProcoreAccount={openProcoreLogin}
        changeEvents={changeEvents}
        isLoadingChangeEvents={isLoading}
        isLoggedIntoProcore={!!loggedInProcoreSource}
        editProcoreLogin={() => setLinkingAccountState(LinkingAccountState.EDITING_LINK)}
        openCreateChangeEventModal={() => setIsCreatingChangeEvent(true)}
        isItemSidebar={isItemSidebar}
        isPrinting={isPrinting}
        canCreateChangeEvent={canCreateChangeEvent}
        pageLoadTime={pageLoadTime}
        sourceID={sourceID}
        refetchChangeEvents={() => changeEventData.refetch()}
        setSubmitted={setSubmitted}
        linkedProjectID={linkedProjectID}
        sourceCompanyID={sourceCompanyID}
      />
      <IntegrationsModalManager
        isCreatingChangeEvent={isCreatingChangeEvent}
        setIsCreatingChangeEvent={setIsCreatingChangeEvent}
        setSubmitted={setSubmitted}
        linkingAccountState={linkingAccountState}
        setLinkingAccountState={setLinkingAccountState}
        loggedInProcoreSource={loggedInProcoreSource}
        projectID={projectID}
        itemInfo={itemInfo}
        refetchLinkedProjectQuery={refetchLinkedProjectQuery}
        linkedProjectQueryDataLoading={linkedProjectQueryDataLoading}
        linkedProject={linkedProject}
      />
    </>
  );
};

export default IntegrationsDataWrapper;
