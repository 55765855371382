import { FC } from 'react';

import { ALL_MILESTONES, CATEGORIZATION_LIST, VIEW_FILTER } from '../../constants';
import { ResourceType } from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { returnGroupByOptions } from '../../utilities/grouping';
import {
  computeCollapseState,
  computeDistinctCreators,
  getDraftItemIDs,
  getSharedUsersMap,
} from '../../utilities/items';
import { computeItemIdsTree } from '../../utilities/itemsList';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../utilities/url';
import { useFilterManager } from '../FilterPanel/filterUtils';
import { useMilestonesQuery } from '../Milestones/hooks';
import { useLoadTimer } from '../PerfMonitor/utils';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { useItemsListAnalytics } from '../Timeline/hooks/useItemsListAnalytics';
import useMemoWrapper from '../useMemoWrapper';

import ItemsList from './ItemsList';
import { useItemsListSettings } from './ItemsListSettingsUtils';
import { computeGroupByOptions } from './ItemsListSortBarUtils';
import { getVisibleItemInformation, useItemsList, useItemsTree } from './ItemsListUtils';
import { useGetSharedResources } from './ItemsSharing/hooks/useGetShareResourceHook';

const ItemsListData: FC = () => {
  // preview mode
  const { inPreviewMode } = usePermissions();

  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );

  useItemsListAnalytics(settings);

  const { currentMilestone, groupBy } = settings;

  // DATA
  // Fetch relevant project data: categorizations list, project name
  const projectId = getProjectIdFromUrl();
  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name ?? '';
  const activeMilestoneId = project?.activeMilestone.id || '';

  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const { data: { milestones = [] } = {}, loading: milestonesLoading } = useMilestonesQuery(
    projectId,
    false
  );
  const projectLoading = projectPropsLoading || projectCategorizationsLoading || milestonesLoading;

  // TIME LINE
  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;
  const filteredBucketId = currentMilestone && currentMilestone[1];

  // GROUP BY - translate the settings from strings to groupings
  // We split up categorization levels as options
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const groupByOptions = useMemoWrapper(
    computeGroupByOptions,
    categorizations,
    isScheduleImpactEnabled,
    filteredMilestoneId
  );
  // We match the simple string to a complex option with level explanation for the tree
  const displayGroupBy = returnGroupByOptions(groupBy, groupByOptions);
  const variant =
    displayGroupBy.length > 0 ? displayGroupBy[0].variant || CATEGORIZATION_LIST : undefined;
  // used only for categorizations

  const isCollapsed = useMemoWrapper(computeCollapseState, settings);
  const loadItemCosts = false;
  const excludeDraftItems = inPreviewMode;
  const { itemsTree, loading: loadingItemsTree } = useItemsTree(
    filterManager,
    settings,
    excludeDraftItems
  );
  const {
    itemsList,
    itemsListMap,
    loading: loadingItemsList,
    refetch: refetchItemsList,
  } = useItemsList(filterManager, settings, excludeDraftItems, loadItemCosts);

  const itemIds = useMemoWrapper(computeItemIdsTree, itemsList);
  const creators = useMemoWrapper(computeDistinctCreators, itemsList);
  const visibleItemsInfo = useMemoWrapper(
    getVisibleItemInformation,
    itemsTree,
    itemsList,
    itemsListMap
  );

  const draftItemIDs = useMemoWrapper(getDraftItemIDs, itemsList);
  const sharedResourcesResult = useGetSharedResources(draftItemIDs, ResourceType.ITEM);
  const sharedUsersMap = useMemoWrapper(getSharedUsersMap, itemsList, sharedResourcesResult.data);

  const hooksLoading =
    projectLoading || loadingItemsTree || loadingItemsList || sharedResourcesResult.loading;

  useLoadTimer('ItemsList', hooksLoading);
  return (
    <ItemsList
      activeMilestoneID={activeMilestoneId}
      categorizations={categorizations}
      creators={creators}
      filterManager={filterManager}
      filteredBucketId={filteredBucketId ?? undefined}
      filteredMilestoneId={filteredMilestoneId}
      isCollapsed={isCollapsed}
      itemIds={itemIds}
      itemsList={itemsList}
      refetchItemsList={refetchItemsList}
      itemsListMap={itemsListMap}
      itemsTree={itemsTree}
      loading={hooksLoading}
      milestones={milestones}
      projectName={projectName}
      settingsManager={settingsManager}
      sharedUsersMap={sharedUsersMap}
      variant={variant}
      visibleItemsInfo={visibleItemsInfo}
    />
  );
};

export default ItemsListData;
