import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import UserAvatar from '../../Collaborators/UserAvatar';
import { SortManager, Table } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  collaboratorsGridWidth,
  collaboratorsHeaderContent,
  getIsDeactivated,
  getStatusColor,
  gridRowHeight,
} from '../CompanyTabUtils';

type CompanyTabCollaboratorsTableProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyCollaborators: CompanyCollaborator[];
  sortManager: SortManager;
  searchTerm: string;
  loading: boolean;
};

const CompanyTabCollaboratorsTable: FC<CompanyTabCollaboratorsTableProps> = ({
  classes,
  companyCollaborators,
  sortManager,
  searchTerm,
  loading,
}) => {
  const entries = useMemo(
    () =>
      companyCollaborators.map(({ companyUser: { user, company: userCompany } }) => {
        if (user) {
          const thumbnail = (
            <div className={classes.avatarTable}>
              <UserAvatar
                assignee={user}
                imgSettings={{ loading: 'lazy' }}
                deactivated={getIsDeactivated(user)}
              />
            </div>
          );
          const path = generateSharedPath(JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE, {
            userId: user.id,
          });
          const link = (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              to={path}
              onClick={() => {}}
            >
              <SearchText searchTerm={searchTerm} text={user.name} />
            </Link>
          );
          const status = (
            <>
              <div style={{ color: getStatusColor(user.status) }}>{'\u2B24'}&nbsp;</div>
              <div className={classes.text}>{user.status} </div>
            </>
          );
          const jobTitle = (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={user.jobTitle || ' '} />
            </div>
          );
          const email = (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={user.email} />
            </div>
          );
          const company = (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={userCompany?.name || ' '} />
            </div>
          );
          const companyType = (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={userCompany?.type || ' '} />
            </div>
          );
          return [
            { component: thumbnail, key: user.id },
            { component: link, key: user.id },
            { component: jobTitle, key: user.id },
            { component: email, key: user.email },
            { component: company, key: user.id },
            { component: companyType, key: user.id },
            { component: status, key: user.id },
          ];
        }
        return [];
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [companyCollaborators]
  );

  return (
    <Table
      columnWidths={collaboratorsGridWidth}
      entries={entries}
      headerContent={collaboratorsHeaderContent}
      loading={loading}
      rowHeight={gridRowHeight}
      sortManager={sortManager}
    />
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabCollaboratorsTable);
