import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

const Library = (props: IconProps, isOpen: boolean) => (
  <div style={{ fontSize: 24, lineHeight: 24 }}>
    <SvgIcon
      {...props}
      fontSize="inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(0,7)"
        d={
          !isOpen
            ? 'M0 3.89423L3.63461 7.78846V0L0 3.89423Z'
            : 'M3.63461 3.89386L-1.22646e-06 -0.000376065L-1.90735e-06 7.78809L3.63461 3.89386Z'
        }
      />
      <path
        transform="translate(3,-2)"
        d="M12 11.55C9.64 9.35 6.48 8 3 8v11c3.48 0 6.64 1.35 9 3.55 2.36-2.19 5.52-3.55 9-3.55V8c-3.48 0-6.64 1.35-9 3.55zM12 8c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3z"
      />
    </SvgIcon>
  </div>
);

export const CloseLibrary = (props: IconProps) => Library(props, false);
export const OpenLibrary = (props: IconProps) => Library(props, true);
