import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { AssistantPhoto } from '@material-ui/icons';
import BarChartIcon from '@material-ui/icons/BarChart';
import DoneIcon from '@material-ui/icons/Done';

import { EstimateType, JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import { CURRENT_MILESTONE } from '../../../../constants';
import { CostReportColumnType, PermissionResource } from '../../../../generated/graphql';
import { MILESTONE_OPTIONS_SET_ACTIVE_MILESTONE } from '../../../../tagConstants';
import theme, { withStyles } from '../../../../theme/komodo-mui-theme';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../../utilities/costMode';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { capitalizeString } from '../../../../utilities/string';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import Breadcrumbs from '../../../Breadcrumbs/Breadcrumbs';
import { ColumnDescription } from '../../../CostReport/CostReportColumns/CostReportColumns';
import CostReportSettingsPanel from '../../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import { getCostReportByType, renderCostString } from '../../../CostReport/CostReportUtils';
import { FilterManager } from '../../../FilterPanel/filterUtils';
import { getItemListLink } from '../../../ItemsList/ItemsListUtils';
import NavigationTabs from '../../../Navigation/NavigationTabs';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Button, Chip, Tooltip } from '../../../scales';
import CTAIconButton from '../../../shared-widgets/CTAIconButton';
import useSetMilestoneStatus from '../../hooks/SetMilestoneStatusHook';
import useMilestoneQuantitiesQuery from '../../hooks/useMilestoneQuantitiesQuery';
import MilestoneExportPanel from '../../MilestoneExportPanel/MilestoneExportPanel';
import MilestonePublishModalManager from '../../MilestoneModals/MilestoneModalsManager';
import MilestoneSettingsPanel from '../../MilestoneSettingsPanel/MilestoneSettingsPanel';
import { MilestoneDetailsTabs } from '../../utils';
import { getMagnitudeCount } from '../MilestoneDetailsQuantities/MilestoneDetailsQuantities';

import MilestoneDetailsNavStyles from './MilestoneDetailsNavStyles';

export type CostParams = {
  categorizations: Categorization[];
  columnDescriptions: ColumnDescription[];
  displayColumnDescriptions: ColumnDescription[];
  displayGroupBy: DisplayGroupBy[];
  filterManager: FilterManager;
  page: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting: (param: string, value: any) => void;
};

type MilestoneDetailsNavProps = {
  classes: Classes<typeof MilestoneDetailsNavStyles>;
  canDeleteDraftMilestones: boolean;
  canEditDraftMilestones: boolean;
  canEditMilestones: boolean;
  canViewAttachments: boolean;
  canViewMilestoneCosts: boolean;
  costParams: CostParams;
  isActive: boolean;
  milestone: Milestone;
  milestoneCostReports: MilestoneCostReports;
  projectName: string;
  numMilestoneAttachments: number;
  view: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setView: any;
};

export const getIconColorLabel = (
  key: MilestoneDetailsTabs | EstimateType,
  milestone: Milestone,
  label: string
) => {
  const { activeEstimateID, activeEstimateDraftID, budgetID, budgetDraftID } = milestone;
  let title;
  let color;
  const indicatorColor = theme.palette.fillGreen;
  const labelText = label.toLowerCase() || 'estimate';

  switch (key) {
    case MilestoneDetailsTabs.ESTIMATE:
    case EstimateType.ACTIVE_ESTIMATE:
      if (activeEstimateDraftID) {
        title = `Draft ${labelText}`;
        color = indicatorColor;
        break;
      }
      if (!activeEstimateID) {
        title = `Start ${labelText}`;
        color = indicatorColor;
      }
      break;
    case MilestoneDetailsTabs.TARGET:
    case EstimateType.BUDGET:
      if (budgetDraftID) {
        title = `Draft ${labelText}`;
        color = indicatorColor;
        break;
      }
      if (!budgetID) {
        title = `Start ${labelText}`;
        color = indicatorColor;
      }
      break;
    default:
      break;
  }
  return { color, title };
};

export const milestonePageStorageParam = (id: UUID) => `Milestone ${id} Page `;

const MilestoneDetailsNav: FC<MilestoneDetailsNavProps> = ({
  canDeleteDraftMilestones,
  canEditDraftMilestones,
  canEditMilestones,
  canViewAttachments,
  canViewMilestoneCosts,
  classes,
  costParams,
  isActive,
  milestone,
  milestoneCostReports,
  projectName,
  numMilestoneAttachments,
  view,
  setView,
}) => {
  const projectId = getProjectIdFromUrl();
  const costMode = useCostMode();
  const milestoneId = getMilestoneIdFromUrl();
  const { activeEstimateID, budgetID, deletable, description, id, isDraft, itemsCount, name } =
    milestone;
  const itemsListLink = getItemListLink(projectId, { [CURRENT_MILESTONE]: [id] });

  const t = useContext(ProjectTermStore);

  // DISPLAY DRAFT
  const [showPublishDraftMilestoneDialog, setShowPublishDraftMilestoneDialog] = useState(false);

  // PERMISSIONS
  const { canView } = usePermissions();
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);
  const canViewMilestoneEstimate = canView(PermissionResource.MILESTONE_LINES);
  const canViewMilestoneBudget = canView(PermissionResource.MILESTONE_BUDGET);
  const canViewDetails =
    view === MilestoneDetailsTabs.ESTIMATE ? canViewMilestoneEstimate : canViewMilestoneBudget;
  const canViewMarkups = canView(PermissionResource.MARKUPS);

  // FILTER INFO
  const {
    categorizations,
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    filterManager,
    page,
    settings,
    setSetting,
  } = costParams;
  const { status, viewMode } = settings;

  const { data: { quantities } = {} } = useMilestoneQuantitiesQuery(id);
  const magnitudeCount = getMagnitudeCount(quantities);

  const estimateReport = getCostReportByType(
    milestoneCostReports[0],
    CostReportColumnType.ESTIMATE_REPORT
  );
  const targetReport = getCostReportByType(
    milestoneCostReports[0],
    CostReportColumnType.TARGET_REPORT
  );
  const estimateCost = estimateReport && estimateReport.range;
  const targetCost = targetReport && targetReport.range;

  const [setMilestoneStatus] = useSetMilestoneStatus();
  const onSetActive = () => {
    setMilestoneStatus(projectId, milestone.id);
  };

  const getCount = (key: MilestoneDetailsTabs) => {
    switch (key) {
      case MilestoneDetailsTabs.METRICS:
        return magnitudeCount;
      case MilestoneDetailsTabs.ESTIMATE:
        return estimateCost && renderCostString({ cost: estimateCost, isWide: true });
      case MilestoneDetailsTabs.TARGET:
        return targetCost && renderCostString({ cost: targetCost, isWide: true });
      case MilestoneDetailsTabs.ATTACHMENTS:
        return numMilestoneAttachments;
      default:
        return null;
    }
  };
  // TABS
  const blocked: MilestoneDetailsTabs[] = [];
  if (!canViewAttachments) blocked.push(MilestoneDetailsTabs.ATTACHMENTS);
  if (!shouldDisplayCosts) {
    blocked.push(MilestoneDetailsTabs.ESTIMATE);
    blocked.push(MilestoneDetailsTabs.TARGET);
  }

  const options = Object.values(MilestoneDetailsTabs)
    .filter((o) => !blocked.includes(o))
    .map((key: MilestoneDetailsTabs) => {
      let label = Object.values(TermKey).includes(key as unknown as TermKey)
        ? t.titleCase(key as unknown as TermKey)
        : capitalizeString(key.toString().toLowerCase());
      const count = getCount(key);
      const { title, color } = getIconColorLabel(key, milestone, label);
      if (count != null) label = `${label} (${count})`;
      return {
        key,
        label,
        color,
        title,
      };
    });

  const hasCategorizations = categorizations && categorizations.length > 0;

  return (
    <div style={{ width: '100%' }} className={classes.nav}>
      <div className={classes.containerOuter}>
        <div className={classes.containerBreadcrumbs} data-cy="MilestoneDetailsNav">
          <Breadcrumbs
            links={[
              {
                display: 'Milestones',
                destination: generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }),
                tooltip: 'Back to Milestones',
              },
              {
                display: name,
                destination: '',
                tooltip: description,
              },
            ]}
          />
          {isActive && (
            <Tooltip content="Active milestone">
              <div>
                <Chip icon={<AssistantPhoto className="text-entities-milestone" />} text="Active" />
              </div>
            </Tooltip>
          )}
          {isDraft && (
            <Tooltip content="Draft milestone">
              <div>
                <Chip data-cy="badge-DraftMilestone" text="Draft" />
              </div>
            </Tooltip>
          )}
          {!isActive && !isDraft && canEditMilestones && (
            <Button
              data-cy={MILESTONE_OPTIONS_SET_ACTIVE_MILESTONE}
              startIcon={<AssistantPhoto />}
              label="Set as active"
              type="secondary"
              onClick={onSetActive}
            />
          )}
        </div>
        <div className={classes.containerLinks}>
          {!isDraft && (
            <Link to={itemsListLink} className={classes.link}>
              <Typography data-cy="milestonePage-ItemListNavigation" title="Go to Items List">
                Items ({itemsCount})
              </Typography>
            </Link>
          )}
          {shouldDisplayCosts && (
            <div className={classes.containerMilestoneReport}>
              <Link
                to={generateSharedPath(JoinProjectRoutes.MSR, { projectId, milestoneId })}
                className={classes.link}
              >
                <BarChartIcon className={classes.iconBarChart} />
                <Typography
                  data-cy="milestonePage-MilestoneReportNavigation"
                  title="Go to Milestone Summary Report"
                >
                  Milestone Report
                </Typography>
              </Link>
            </div>
          )}
          {canViewMarkups && shouldDisplayCosts && isActive ? (
            <div className={classes.containerMilestoneReport}>
              <Link
                to={generateSharedPath(JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT, {
                  projectId,
                })}
                className={classes.link}
              >
                <BarChartIcon className={classes.iconBarChart} />
                <Typography
                  data-cy="milestonePage-ContingencyReportNavigation"
                  title="Go to C&amp;A Report"
                >
                  C&amp;A Report
                </Typography>
              </Link>
            </div>
          ) : null}
          {isDraft && (
            <div className={classes.publishDraft}>
              <CTAIconButton
                dataCy="button-PublishDraftMilestone"
                buttonText="Publish Draft"
                icon={<DoneIcon className={classes.actionIcon} />}
                disabled={!canEditDraftMilestones}
                onClick={() => setShowPublishDraftMilestoneDialog(true)}
              />
            </div>
          )}
          <MilestonePublishModalManager
            milestone={milestone}
            isOpen={showPublishDraftMilestoneDialog}
            setIsOpen={setShowPublishDraftMilestoneDialog}
            projectID={projectId}
          />
          {shouldDisplayCosts && canViewDetails && hasCategorizations && (
            <CostReportSettingsPanel
              canViewProjectCategories={canViewProjectCategories}
              categorizations={categorizations}
              columnDescriptions={columnDescriptions}
              displayColumnDescriptions={displayColumnDescriptions}
              displayGroupBy={displayGroupBy}
              filterManager={filterManager}
              milestoneID={milestoneId}
              page={page}
              settings={settings}
              setSetting={setSetting}
              status={status}
              statusDisabled
              viewMode={viewMode}
              viewGroupByColumnsDisabled
            />
          )}
          <MilestoneSettingsPanel
            canDelete={deletable && (isDraft ? canDeleteDraftMilestones : true)}
            disabled={isDraft ? !canDeleteDraftMilestones : !canEditMilestones}
            isActive={isActive || milestone.isDraft}
            isDetails
            milestone={milestone}
          />
          {shouldDisplayCosts && (
            <MilestoneExportPanel
              canView={canViewMilestoneCosts}
              disabled={!canViewMilestoneCosts}
              estimateID={activeEstimateID ?? null}
              budgetID={budgetID ?? null}
              projectName={projectName}
              milestoneName={name}
              includeMarkups={checkCostModeIncludesMarkups(costMode)}
              isDetails
            />
          )}
        </div>
      </div>
      <div className={classes.tabContainer}>
        <NavigationTabs options={options} view={view} setView={setView} />
      </div>
    </div>
  );
};

export default withStyles(MilestoneDetailsNavStyles)(MilestoneDetailsNav);
