import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const GroupBy = (props: IconProps) => (
  <SvgIcon
    {...props}
    style={{ transform: 'scale(0.8) translate(0px, -2px)' }}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.5" y="17.5" width="16" height="1.88235" transform="rotate(-90 7.5 17.5)" />
    <rect x="8" y="10.5" width="2" height="9" transform="rotate(-90 8 10.5)" />
    <path d="M7.49998 17.5V15.5H16.5V17.5H7.49998Z" />
    <rect x="3" y="3.5" width="2" height="14" transform="rotate(-90 3 3.5)" />
    <rect x="0.5" y="4.5" width="4" height="4" transform="rotate(-90 0.5 4.5)" />
    <rect x="13.5" y="18.5" width="4" height="4" transform="rotate(-90 13.5 18.5)" />
    <rect x="13.5" y="11.5" width="4" height="4" transform="rotate(-90 13.5 11.5)" />
    <rect x="13.5" y="4.5" width="4" height="4" transform="rotate(-90 13.5 4.5)" />
  </SvgIcon>
);
