/* eslint-disable no-param-reassign */
import { FC, memo, useEffect, useState } from 'react';
import * as React from 'react';

import { DragHandle } from '@material-ui/icons';

import { INHERITED_MARKUP_CHECKBOX_CELL, SOURCE } from '../../constants';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import useUpdate from './hooks/useUpdate';
import JoinGridCell from './JoinGridCell';
import JoinPreloaderRow from './JoinGridPreloaderRow';
import GridRowCheckbox from './JoinGridRowCheckbox';
import { GridController, KeyBufferState } from './types';
import { getCypressTag } from './utilities/cell';
import { dragSelectHandler, reorderHandler } from './utilities/drag';
import { getLeftFixedLocation } from './utilities/size';

type Props = {
  bodyRef: React.RefObject<HTMLDivElement>;
  grid: GridController;
  isTotalCellArr: boolean[];
  startCellWidth: number;
  height: number;
  delay: number;
  i: number;
  id: UUID;
  numFields: number;
  renderGeneration: number;
  isEditing: boolean;
  setEditing: (b: boolean) => void;
  setEditorDefaultValue: (s: GridCellValue) => void;
};

// Similarly to innerTable, we don't want to re-render a row unless we have to
// if (for example) widths changed, or we need to propagate setEditing again.
const JoinGridRow: FC<Props> = memo(
  ({
    bodyRef,
    grid,
    startCellWidth,
    i,
    id,
    isEditing,
    setEditing,
    setEditorDefaultValue,
    delay,
    isTotalCellArr,
  }) => {
    const [isShow, setIsShow] = useState(delay === 0);
    const [hover, setHover] = useState(false);
    const rowHoverClass = hover && 'join-grid-row-hover';

    useEffect(() => {
      if (delay === 0) return;
      let timeout: NodeJS.Timeout | undefined;
      if (!isShow) {
        timeout = setTimeout(() => {
          setIsShow(true);
        }, delay);
      }
      // eslint-disable-next-line consistent-return
      if (timeout) return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    }, [delay]);

    const update = useUpdate();
    grid.setRowUpdater(i, update);
    const {
      data: { columns },
      colWidths,
      isReorderable,
      getRowHeights,
      linesReadOnly,
      isFiltering,
      isSorting,
      isReorderingRow,
    } = grid;

    const isReordering = isReorderingRow(i);
    const hasCheckBox = !linesReadOnly;
    const height = getRowHeights()[i];
    const widths = colWidths();
    const line = grid.data.lines[i];

    if (!isShow || !line) {
      return (
        <JoinPreloaderRow
          linePrefix={grid.linePrefix}
          columns={columns}
          isTotalCellArr={isTotalCellArr}
          startCellWidth={startCellWidth}
          widths={widths}
          height={height}
          i={i}
          id={id}
          hasCheckBox={hasCheckBox}
        />
      );
    }

    const handleClick = (e: React.MouseEvent) => {
      // Clear grid cell selection
      grid.setSelectionRange({ row: -1, column: -1 }, { row: -1, column: -1 });
      // Select range of rows
      if (e.shiftKey) {
        let start = grid.previouslySelectedRow === -1 ? i : grid.previouslySelectedRow;
        let end = i;
        if (i < grid.previouslySelectedRow) {
          end = start;
          start = i;
        }
        const value = !grid.isRowSelectedArr[i];
        for (let k = start; k <= end; k += 1) {
          if (value !== grid.isRowSelectedArr[k]) {
            grid.isRowSelectedArr[k] = value;
            grid.numSelectedRows += value ? 1 : -1;
          }
        }
      } else {
        grid.isRowSelectedArr[i] = !grid.isRowSelectedArr[i];
        grid.numSelectedRows += grid.isRowSelectedArr[i] ? 1 : -1;
      }
      grid.previouslySelectedRow = i;
      grid.updateTable();
    };

    return (
      <tr
        key={id}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onFocus={() => {}}
      >
        {hasCheckBox && (
          <th
            scope="row"
            className={`join-grid-td ${rowHoverClass}`}
            style={{ minWidth: startCellWidth }}
          >
            <GridRowCheckbox checked={grid.isRowSelectedArr[i]} onClick={handleClick} />
          </th>
        )}
        <th
          scope="row"
          className={`join-grid-td join-grid-line-number-cell column-cell-header-right join-grid-reorderable ${
            isReordering ? 'reordering' : ''
          } ${rowHoverClass}`}
          style={{
            left: getLeftFixedLocation(hasCheckBox, startCellWidth),
            minWidth: startCellWidth,
          }} // for border
        >
          <NormalTooltip
            title={
              (isFiltering || isSorting) && !linesReadOnly
                ? `Reordering is disabled in ${isSorting ? 'sort' : 'filter'} mode`
                : ''
            }
          >
            <div
              className="column-cell-border"
              style={{
                display: 'flex',
              }}
            >
              {isReordering ? null : (
                <div className="column-cell-content">{`${grid.linePrefix}${i + 1}`}</div>
              )}

              {!linesReadOnly && (
                <div
                  className="column-cell-border column-cell-menu"
                  style={{
                    minWidth: startCellWidth,
                  }}
                  onMouseDown={(e) => {
                    // This isn't ideal, but if the user is currently editing a row
                    // then we won't let them reorder.  When editing a cell we
                    // rely on the order of the rows to update the data we get back
                    // from backend after a mutation.  If the user attempts to reorder the
                    // a row when editing the estimate then we might update / insert the new data in the wrong place
                    //
                    // it's possible to know the correct row to update the data at for a given gime
                    // however, if the user moves the mouse fast enough then the row index will still be wrong
                    if (isReorderable && !isEditing) {
                      setEditing(false);
                      reorderHandler(grid, bodyRef, i)(e);
                    }
                  }}
                  data-cy={getCypressTag(grid.linePrefix, i, 'LineNumber')}
                  data-cy-row-height={height}
                >
                  <DragHandle
                    className="column-cell-content join-grid-drag-handle"
                    style={isReordering ? { display: 'block' } : { display: 'none' }}
                  />
                </div>
              )}
            </div>
          </NormalTooltip>
        </th>

        {columns.map((col, j) => {
          const data = grid.getCellData(i, j);
          const key = `${col.id}-${j}`;
          if (!data) return null;

          const isSourceCell = col.type === SOURCE;
          const isTotalCell = isTotalCellArr[j];
          let scope = '';
          if (isTotalCell) {
            scope = 'row-total';
          } else if (isSourceCell) {
            scope = 'row-source';
          }

          return (
            <td
              key={key}
              // eslint-disable-next-line jsx-a11y/scope
              scope={scope}
              className={`join-grid-td ${rowHoverClass}`}
            >
              <JoinGridCell
                grid={grid}
                column={col}
                row={i}
                isTotalCell={isTotalCell}
                isSourceCell={isSourceCell}
                data={data}
                height={height}
                onClick={() => {}}
                onDoubleClick={() => {
                  if (
                    grid.isCellEditable({ row: i, column: j }) ||
                    col.type === INHERITED_MARKUP_CHECKBOX_CELL
                  ) {
                    // Clear anything that may have been in the key buffer
                    grid.getKeyBufferString({ row: i, column: j });
                    grid.isRenderingEditor = KeyBufferState.CLEAR;
                    setEditing(true);
                    const refs = grid.getCellData(i, j);
                    if (refs) setEditorDefaultValue(refs.data.value);
                  }
                }}
                onMouseDown={(e) => {
                  setEditing(false);
                  if (
                    e.shiftKey &&
                    grid.selection.start.row > -1 &&
                    grid.selection.start.column > -1
                  ) {
                    grid.setSelectionRangeEnd({ row: i, column: j });
                  } else {
                    dragSelectHandler(grid, bodyRef, i, j)(e);
                  }
                }}
                onMouseEnter={() => {
                  if (grid.isSelecting()) {
                    grid.setSelectionRangeEnd({ row: i, column: j });
                  }
                }}
              />
            </td>
          );
        })}
      </tr>
    );
  }
);

JoinGridRow.displayName = 'JoinGridRow';
export default JoinGridRow;
