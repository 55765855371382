import { useMutation } from '@apollo/client';

import { DELETE_MILESTONE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  DeleteMilestonesMutation,
  DeleteMilestonesMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { getMilestonesQuery } from '../../Milestones/hooks/queries';

import { deleteMilestonesMutation } from './queries';

function useDeleteMilestone() {
  const [deleteMilestoneFunc] = useMutation<
    DeleteMilestonesMutation,
    DeleteMilestonesMutationVariables
  >(deleteMilestonesMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectId: UUID,
    milestoneID: UUID,
    showDrafts = true,
    onSuccess: () => void
  ) =>
    deleteMilestoneFunc({
      variables: {
        projectID: projectId,
        milestoneID,
      },
      update: (dataProxy, { data }) => {
        const updateProject = data?.updateProject;
        if (!updateProject) return;
        // update apollo cache
        dataProxy.writeQuery({
          query: getMilestonesQuery,
          variables: { projectID: projectId, showDrafts },
          data: { milestones: updateProject.milestones },
        });
      },
    }).then(() => {
      if (onSuccess) onSuccess();
      sendAnalytics(analyticsEvent(DELETE_MILESTONE));
    });

  return submitFunc;
}

export default useDeleteMilestone;
