import { FC, useContext } from 'react';

import { Typography } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

import { TermKey } from '../../../../../api/gqlEnums';
import { CostReportColumnType } from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';

import AddToMilestoneEstimatePublishedStyles from './AddToMilestoneEstimatePublishedStyles';

export const publishedType = (t: TermStore, estimateType: CostReportColumnType) =>
  estimateType === CostReportColumnType.ESTIMATE_REPORT
    ? `${t.lowerCase(TermKey.ESTIMATE)}`
    : `${t.lowerCase(TermKey.TARGET)}`;

type AddToMilestoneEstimatePublishedProps = {
  classes: Classes<typeof AddToMilestoneEstimatePublishedStyles>;
  estimateType: CostReportColumnType;
  milestoneName: string;
  isNewDraftMilestone: boolean;
};

const AddToMilestoneEstimatePublished: FC<AddToMilestoneEstimatePublishedProps> = ({
  classes,
  estimateType,
  milestoneName,
  isNewDraftMilestone,
}) => {
  const t = useContext(ProjectTermStore);

  return (
    <div className={classes.innerContainer}>
      <>
        <div className={classes.iconContainer}>
          <Check className={classes.icon} />
        </div>
        <Typography className={classes.textContent}>{`Your ${publishedType(
          t,
          estimateType
        )} has been added to ${
          isNewDraftMilestone ? 'a draft,' : ''
        } ${milestoneName}`}</Typography>
      </>
    </div>
  );
};

export default withStyles(AddToMilestoneEstimatePublishedStyles)(AddToMilestoneEstimatePublished);
