import { Search } from '@material-ui/icons';

import { TEAM } from '../../../constants';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import { Select, TextInput } from '../../scales';
import { TEAM_SETTINGS_DEFAULTS } from '../../Team/TeamLists/TeamListUtils';
import {
  useFilterCollaborators,
  useSearchCollaborators,
  useSortCollaborators,
} from '../CollaboratorsPage/CollaboratorsPageUtils';

import CollaboratorsList from './CollaboratorsList';

const CollaboratorsListData = () => {
  // Collaborators search
  const projectId = getProjectIdFromUrl();
  const { location } = window;
  const [settings, setSettings] = usePersistentStates(
    location,
    TEAM,
    TEAM_SETTINGS_DEFAULTS,
    `${projectId} - Team - `
  );

  const collaboratorsQueryResult = useCollaboratorsQuery(projectId);
  const collaborators =
    collaboratorsQueryResult.data?.collaborators ??
    collaboratorsQueryResult.previousData?.collaborators ??
    [];

  const { search: searchTerm } = settings;
  const onChangeSearchTerm = (s: string) => {
    setSettings({ search: s });
  };
  // Collaborators filtering and sorting
  const { entries, filterKey, filteredCollaborators, setFilterKey } =
    useFilterCollaborators(collaborators);
  const searchedCollaborators = useSearchCollaborators(filteredCollaborators, searchTerm);
  const { sortData, setSortData, sortedCollaborators } =
    useSortCollaborators(searchedCollaborators);

  return (
    <>
      <div className="flex flex-row gap-2 pl-6">
        <div className="w-64">
          <TextInput
            aria-label="search collaborators text fields"
            data-cy="collaborators-list-search"
            label="Search"
            onChange={(value) => {
              onChangeSearchTerm(value.trim());
            }}
            onClear={() => onChangeSearchTerm('')}
            placeholder="Search text fields"
            startAdornment={<Search />}
            value={searchTerm}
          />
        </div>
        <div className="w-48">
          <Select
            label="Filter"
            aria-label="filter users by role"
            data-cy="RoleFilter"
            entries={entries}
            onChange={setFilterKey}
            value={filterKey}
          />
        </div>
      </div>
      <CollaboratorsList
        collaborators={sortedCollaborators}
        searchTerm={searchTerm}
        setSortData={setSortData}
        sortData={sortData}
      />
    </>
  );
};

export default CollaboratorsListData;
