/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, SyntheticEvent } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './UploadHeaderStyles';

const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

type UploadHeaderProps = {
  addFile: (file: File) => void;
  asset: Asset | null;
  classes: Classes<typeof styles>;
  loading: boolean;
  orgLogos: string[];
};

const UploadHeader: FC<UploadHeaderProps> = ({ addFile, asset, classes, loading, orgLogos }) => {
  const addFiles = (files: FileList | null) => files && addFile(files[0]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;
    addFiles(files);
  };

  const loadingWheel = (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} />
    </div>
  );

  const teamImagesPlaceholder = orgLogos.map((logo: string) => (
    <img alt="logo" className={classes.teamLogo} key={JSON.stringify(logo)} src={logo} />
  ));

  const blobUrl = asset?.blobUrl ?? undefined;
  const header = asset && <img alt="logo" className={classes.img} key={blobUrl} src={blobUrl} />;

  const displayImage = asset ? header : teamImagesPlaceholder;

  return (
    <>
      <Typography className={classes.label}> Banner Image </Typography>
      <div className={classes.bannerUploadContainer}>
        <div
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <input
            style={{ display: 'none' }}
            id="file"
            key="file"
            type="file"
            multiple
            onChange={(evt) => {
              addFiles(evt.target.files);
            }}
          />
          <div className={classes.logosContainer}>{loading ? loadingWheel : displayImage}</div>

          <Typography className={classes.hintSubtitle} variant="body2">
            {`Drag and drop a banner image here, or  `}
            <label className={classes.link} htmlFor="upload-header">
              select it from your computer
            </label>
            <input
              className={classes.hidden}
              id="upload-header"
              type="file"
              onChange={(evt) => {
                addFiles(evt.target.files);
              }}
            />
          </Typography>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(UploadHeader);
