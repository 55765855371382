type Props = {
  width: number;
  y: number;
};

export default function AverageLine(props: Props) {
  return (
    <line
      x1={0}
      x2={props.width}
      y1={props.y}
      y2={props.y}
      className="stroke-2px stroke-chart-base-line"
      strokeDasharray="2"
    />
  );
}
