import { Key, useCallback } from 'react';

import { Status } from '../../../generated/graphql';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { isEnumValue } from '../../../utilities/types';
import { Select } from '../../scales';
import ItemStatusIcon from '../ItemStatusIcon/ItemStatusIcon';

type Props = {
  'aria-label'?: string;
  availableStatuses: Status[];
  'data-cy'?: string;
  defaultValue?: Status;
  isDisabled?: boolean;
  label?: string;
  onChange: (status: Status) => void;
  value?: Status;
};

const ItemStatusSelect = (props: Props) => {
  // When an option is Accepted, all other options that are Pending are converted to Not Chosen
  // however these Not Chosen options can actually be changed back to Pending (though the change is not visible)
  // there is little clarity into why we need both Not Chosen and Pending since they seem to be interchangable
  // we should ultimately remove Not Chose from the Status enum, but for now it is what it is
  // I am checking if it is part of the availableStatuses here, to conditionally show it as an option
  const includesNotChosen = props.availableStatuses.includes(Status.NOTCHOSEN);
  const statuses = [
    Status.NOTAPPLICABLE,
    Status.REJECTED,
    includesNotChosen ? Status.NOTCHOSEN : Status.PENDING,
    Status.ACCEPTED,
    Status.INCORPORATED,
  ];
  const entries = statuses.map((status) => {
    const disabled = !props.availableStatuses.includes(status);
    return {
      disabled,
      id: status,
      label: getItemStatusLabel(status),
      startAdornment: <ItemStatusIcon disabled={disabled} value={status} />,
    };
  });

  const { onChange: onChangeStatus } = props;
  const onChange = useCallback(
    (value: Key | null) => {
      if (typeof value !== 'string' || !isEnumValue(Status, value)) return;
      onChangeStatus(value);
    },
    [onChangeStatus]
  );

  return (
    <Select
      aria-label={props['aria-label']}
      data-cy={props['data-cy']}
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={onChange}
      value={props.value ?? props.defaultValue}
    />
  );
};

export default ItemStatusSelect;
