import { TimelineActivityType } from '../../../../../api/gqlEnumsBe';
import SelectEvent from '../../../../Select/SelectEvent/SelectEvent';
import { useTimelineQuery } from '../../../../Timeline/hooks/TimelineHook';

export const EVENTS_DEFAULT = 'No Events';

type EventsSelectProps = {
  selected: UUID[];
  onChange: (events: UUID[]) => void;
  projectID: UUID;
};

const EventsSelect = ({ selected, onChange, projectID }: EventsSelectProps) => {
  const { data: timelineEvents, loading: timelineLoading } = useTimelineQuery({
    projectID,
    types: [TimelineActivityType.EVENT],
  });

  const events = timelineEvents?.timeline?.activities ?? [];

  if (timelineLoading) return null;

  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">Events</div>
      <SelectEvent
        disabled={events.length === 0}
        events={events}
        onChange={onChange}
        labelFn={(id) => events.find((a) => a.id === id)?.name || id}
        maxChips={2}
        selected={selected}
      />
    </div>
  );
};

export default EventsSelect;
