import { FC } from 'react';
import { Link, Navigate } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
} from '../../analytics/analyticsEventProperties';
import { setPreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { TEAM } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import useRolesQuery from '../../hooks/useRolesQuery';
import { withStyles } from '../../theme/komodo-mui-theme';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { capitalizeString } from '../../utilities/string';
import { getProjectIdFromUrl } from '../../utilities/url';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { styles } from './RolesStyles';

type RolesProps = {
  classes: Classes<typeof styles>;
};

const Roles: FC<RolesProps> = ({ classes }) => {
  const sendAnalytics = useAnalyticsEventHook();

  const projectId = getProjectIdFromUrl();

  const { canView, canEdit, isViewOnly } = usePermissions();
  const canViewPermissions = canView(PermissionResource.PERMISSION_TEMPLATES);
  // TODO DD-876: We currently make an exception for ViewOnly mode to access Preview Mode
  const canViewPreviewMode = canEdit(PermissionResource.PERMISSION_TEMPLATES) || isViewOnly;

  const { data: { projectRoles: roles = [] } = {} } = useRolesQuery(projectId);

  if (projectId === null) return <Navigate to="/404.html" />;

  const breadcrumb = capitalizeString(TEAM);
  const route = JoinProjectRoutes.TEAM;
  const permissionsRoute = JoinProjectRoutes.TEAM_ROLE_PERMISSIONS;
  const title = 'Roles';

  return (
    <div className={classes.root}>
      <Card square className={classes.card} elevation={0}>
        <CardHeader
          classes={{
            action: classes.action,
            root: classes.cardRoot,
            title: classes.cardHeader,
          }}
          title={
            <>
              <Breadcrumbs
                links={[
                  {
                    display: breadcrumb,
                    destination: generateSharedPath(route, { projectId }),
                    tooltip: `Back to ${breadcrumb}`,
                  },
                ]}
              />
              {title}
            </>
          }
          subheader="View the permissions and access to features for each role."
        />
        <Table className={classes.tableGroup}>
          <TableRow className={`${classes.header} ${classes.tableRow}`}>
            <span className={`${classes.tableCell} ${classes.cell} ${classes.header}`}>
              Role Permissions
            </span>

            <TableCell className={classes.cells} />
          </TableRow>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :) */}
          {roles.map((role: any) => (
            <TableRow className={classes.row} key={role.id}>
              <TableCell className={classes.cells}>
                {canViewPermissions ? (
                  <Link
                    className={classes.cellLink}
                    title="View Role Permissions"
                    data-cy={`button-role-${role.name}`}
                    to={generateSharedPath(permissionsRoute, {
                      projectId,
                      roleId: role.id,
                    })}
                  >
                    <Typography>{role.name}</Typography>
                  </Link>
                ) : (
                  <Typography>{role.name}</Typography>
                )}
              </TableCell>
              <TableCell className={classes.cells}>
                <div className={classes.rowRightBlock}>
                  {canViewPreviewMode && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.previewButton}`}
                      data-cy="button-PreviewRole"
                      onClick={() => {
                        setPreviewSettingsVar({
                          previewUserId: undefined,
                          previewRoleId: role.id,
                        });
                        sendAnalytics(
                          previewPermissionsAnalytics(
                            previewEventTypes.PREVIEW_ROLES_CTA,
                            previewTypes.ROLE,
                            role.name
                          )
                        );
                      }}
                    >
                      Preview Role
                    </Button>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Card>
    </div>
  );
};

export const RolesStyled = withStyles(styles)(Roles);

export default RolesStyled;
