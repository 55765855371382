import { useMemo } from 'react';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { UNCATEGORIZED_ID, Uncategorized } from '../../../../constants';
import { useProjectCategorizationsQuery } from '../../../../hooks/useProjectCategorizationsQuery';
import { Select, TextInput } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';

const getEntries = (
  destinationCategorizations: Categorization[],
  categorizationMappings?: Map<UUID, UUID>
) => {
  const entries = [
    {
      id: UNCATEGORIZED_ID,
      label: Uncategorized,
      disabled: false,
    },
  ];
  destinationCategorizations.forEach((destinationCategorization) => {
    entries.push({
      id: destinationCategorization.id,
      label: destinationCategorization.name,
      disabled: Array.from(categorizationMappings?.values() || []).includes(
        destinationCategorization.id
      ),
    });
  });
  return entries;
};

type CategorizationMappingsListItemProps = {
  onClear: (sourceCategorizationID: UUID) => void;
  onUpdate: (sourceCategorizationID: UUID, destinationCategorizationID: UUID) => void;
  sourceCategorization: Categorization;
  state: ItemCopyState;
};

const CategorizationMappingsListItem = (props: CategorizationMappingsListItemProps) => {
  const { data } = useProjectCategorizationsQuery(props.state.projectID ?? '');
  const destinationCategorizations = useMemo(
    () => data?.projectCategorizations.map(({ categorization }) => categorization) ?? [],
    [data?.projectCategorizations]
  );

  const value = props.state.categorizationMappings?.get(props.sourceCategorization.id) || null;

  return (
    <div className="flex items-center gap-6">
      <div className="flex grow items-center gap-6">
        <div className="w-full">
          <TextInput
            aria-label="item categorization name"
            value={props.sourceCategorization.name}
            disabled
          />
        </div>
        <div className="flex justify-center">
          <ArrowRightAltIcon />
        </div>
        <Select
          aria-label="project-categorization"
          entries={getEntries(destinationCategorizations, props.state.categorizationMappings)}
          isClearable
          placeholder="Uncategorized"
          onChange={(value) => {
            if (value === UNCATEGORIZED_ID || typeof value !== 'string') {
              props.onClear(props.sourceCategorization.id);
            } else {
              props.onUpdate(props.sourceCategorization.id, value);
            }
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default CategorizationMappingsListItem;
