import { FC, useContext, useEffect, useRef } from 'react';

import { Add } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import Button from '../../ForecastingTab/common/Button';
import { Portal } from '../../Portal/Portal/Portal';
import { ProjectCompPortals } from '../constants/elementIdentifiers';
import ObserverContext from '../context/observer';

import styles from './PlaceholderCompStyles';

type PlaceholderCompProps = {
  classes: Classes<typeof styles>;
  index: number;
  onAddProjects: () => void;
};

const PlaceholderComp: FC<PlaceholderCompProps> = ({ classes, index, onAddProjects }) => {
  // STICKY COMPONENT OBSERVERS
  const {
    projectCompIntersectionObserver,
    showStickyProjectName,
    showStickyCostsHeader,
    showStickyCostsFooter,
  } = useContext(ObserverContext);
  const projectNameRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = projectNameRef;
    if (!current) return undefined;

    projectCompIntersectionObserver?.observe(current);
    return () => {
      projectCompIntersectionObserver?.unobserve(current);
    };
  }, [projectCompIntersectionObserver]);

  const button = (
    <div className="flex-grow p-3">
      <Button
        dataCy="addProjects-Button"
        fullWidth
        icon={<Add />}
        onClick={onAddProjects}
        type="secondary"
      >
        Add project data
      </Button>
    </div>
  );

  return (
    <div className={classes.container} ref={projectNameRef}>
      <div className={classes.innerPlaceholderContainer}>{button}</div>
      {showStickyProjectName && (
        <Portal
          portalTargetID={ProjectCompPortals.COMP_HEADER}
          attributes={{
            order: String(index),
            className: `${classes.compHeaderPortal}`,
          }}
        >
          <div className={classes.sectionPadding}>
            {button}
            {showStickyCostsHeader && <div />}
          </div>
        </Portal>
      )}
      {showStickyCostsFooter && (
        <Portal
          portalTargetID={ProjectCompPortals.COMP_FOOTER_COSTS}
          attributes={{
            order: String(index),
            className: `${classes.comp}`,
          }}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(PlaceholderComp);
