import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const MilestonesNavigationStyles = (theme: KomodoTheme) =>
  createStyles({
    containerBreadcrumbs: {
      paddingBottom: theme.spacing.generic[8],
      paddingTop: theme.spacing.generic[11],
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    containerLinks: {
      alignItems: 'center',
      display: 'flex',
    },
    containerMilestoneReport: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 2,
      paddingRight: 2,
    },
    containerOuter: {
      alignItems: 'center',
      padding: `0px ${theme.spacing.generic[11]}px`,
      display: 'flex',
      justifyContent: 'space-between',
      maxHeight: 64,
    },
    iconBarChart: {
      color: theme.palette.link,
      height: '18px',
      paddingRight: `${theme.spacing.generic[1]}px`,
    },
    link: {
      ...theme.typography.link,
      display: 'flex',
      justifyContent: 'space-between',
    },
    nav: {
      background: theme.palette.backgroundWhite,
    },
    tabContainer: {
      borderTop: theme.border.line,
      borderBottom: theme.border.line,
    },
    draftIcon: {
      border: '2px solid '.concat(theme.palette.pinkBadge),
      borderRadius: 4,
      color: theme.palette.pinkBadge,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      padding: '4px 8px 2px 8px',
      textAlign: 'center',
    },
    actionIcon: {
      float: 'left',
      marginRight: '4px',
    },
    actionText: {
      ...theme.typography.body1,
      fontWeight: 500,
      float: 'left',
      textTransform: 'none',
      paddingTop: '3px',
      whiteSpace: 'nowrap',
    },
    button: {
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
    },
    publishDraft: {
      paddingRight: theme.spacing.generic[3],
    },
  });

export default MilestonesNavigationStyles;
