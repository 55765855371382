import { FC } from 'react';

import { APV_BY_LOCATION } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useProjectLocationsQuery } from '../ExecutiveDashboardHooks';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  PROJECTS_MAP_TOOLTIP,
  PROJECT_MAP_HEIGHT,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectMap from './ExecutiveDashboardProjectMap';

const ExecutiveDashboardProjectMapLocationData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
}) => {
  const {
    data: {
      projectLocations: { projectLocations, projectsWithoutLocation } = {
        projectLocations: [] as ProjectLocationData[],
        projectsWithoutLocation: undefined,
      },
    } = {
      projectLocations: {
        projectLocations: [] as ProjectLocationData[],
        projectsWithoutLocation: undefined,
      },
    },
    loading,
  } = useProjectLocationsQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        <ExecutiveDashboardProjectMap
          onMouseOver={onHover}
          onFocus={onHover}
          loading={dashboardLoading || loading}
          projectLocations={projectLocations}
          projectsWithoutLocation={projectsWithoutLocation}
        />
      }
      height={PROJECT_MAP_HEIGHT}
      title={APV_BY_LOCATION}
      variant={CHART_LARGE}
      tooltip={PROJECTS_MAP_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardProjectMapLocationData;
