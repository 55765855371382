import { FC, MouseEvent } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { itemNewWindowOpen } from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { ESTIMATE, VIEW_FILTER } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { clickQuicklinkHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../ItemsList/ItemsListSettingsUtils';
import { useItemCostQuery } from '../../ItemsList/useItemCostQuery';
import CostImpact from '../../shared-widgets/CostImpact';

import ItemsListItemInfoStyles from './ItemsListItemInfo/ItemsListItemInfoStyles';
import { generateTitle } from './ItemsListItemUtils';

type ItemsListItemEstimateLinkProps = {
  classes: Classes<typeof ItemsListItemInfoStyles>;
  costImpact?: Cost;
  item: ItemLike | ItemsListItem;
  showNoEstimate?: boolean;
  totalValue?: Cost;
};

const ItemsListItemEstimateLink: FC<ItemsListItemEstimateLinkProps> = ({
  classes,
  costImpact,
  item,
  showNoEstimate = false,
  totalValue,
}) => {
  const navigate = useNavigate();
  const { cost, id: itemId, name } = item;
  const itemHasEstimate = 'hasEstimate' in item ? item.hasEstimate : true;
  const IWOhasOptions = 'options' in item ? !!item.options.length : false;
  const hasEstimateOuter = itemHasEstimate || IWOhasOptions;

  const projectId = getProjectIdFromUrl();

  const sendAnalytics = useAnalyticsEventHook();

  const itemNewWindowAnalytics = () => {
    sendAnalytics(itemNewWindowOpen(itemId));
  };

  const clickHandler = (e: MouseEvent, navigate: NavigateFunction) => {
    const link = generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
      projectId,
      itemId,
    });
    clickQuicklinkHandler(
      e,
      navigate,
      link,
      itemNewWindowAnalytics,
      undefined,
      undefined,
      `#${ESTIMATE}`
    );
  };

  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );
  const { filterQueryInput: viewFilter } = filterManager;
  const skipItemCostQuery = hasEstimateOuter && !IWOhasOptions;
  const { data: itemCostData, loading: itemCostLoading } = useItemCostQuery(
    itemId,
    projectId,
    viewFilter,
    settings,
    skipItemCostQuery
  );
  const itemCost = itemCostData?.itemCost?.cost;

  const hasEstimate = hasEstimateOuter || !!itemCost;

  if (itemCostLoading) return null;

  return (
    <div className={`${classes.rowContainer} ${classes.flexEnd}`}>
      <button
        data-cy={`button-item-row-cost-${name}`}
        aria-label={`view ${generateTitle(item)}`}
        className={classes.linkTitle}
        style={!hasEstimate ? { textDecorationColor: theme.palette.disabledGrey } : undefined}
        type="button"
        onClick={(e) => clickHandler(e, navigate)}
        onMouseDown={(e) => clickHandler(e, navigate)}
      >
        {hasEstimate || !showNoEstimate ? (
          <CostImpact
            value={itemCost || costImpact || cost || undefined}
            totalValue={totalValue}
            expanded
            fullWidth
          />
        ) : (
          <Typography data-cy="display-itemLikeCostImpact" className={classes.disabled}>
            No Estimate
          </Typography>
        )}
      </button>
    </div>
  );
};

export default withStyles(ItemsListItemInfoStyles)(ItemsListItemEstimateLink);
