import { FC, useEffect, useState } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';

import {
  reportVariance,
  varianceEventTypes,
} from '../../../../../analytics/analyticsEventProperties';
import { CostReportColumnType } from '../../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { getDateString, isDateValid, parseDate } from '../../../../../utilities/dates';
import { SetSettingsFunctionType } from '../../../../../utilities/urlState';
import MilestoneDatePicker from '../../../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneSelect from '../../../../Select/MilestoneSelect';
import { LATEST } from '../../../VarianceReportUtils';
import TypeSelect from '../VarianceModalMilestonesTypeSelect';

import styles from './VarianceModalMilestonesSelectsStyles';

type VarianceModalMilestonesSelectsProps = {
  classes: Classes<typeof styles>;
  i: number;
  milestones: Milestone[];
  settings: VarianceSettings;
  setSettings: SetSettingsFunctionType;
};

const SET_DATE = 'Changes through:';

const VarianceModalMilestonesSelects: FC<VarianceModalMilestonesSelectsProps> = ({
  classes,
  milestones,
  i,
  settings,
  setSettings,
}) => {
  const sendAnalytics = useAnalyticsEventHook();
  const { milestoneIDs = [], types = [], dates = [] } = settings as VarianceSettings;

  // MANAGE DATE STATE
  // local date is always something, while the date setting can be null
  const today = getDateString(new Date());
  const dateSetting: string = dates[i];
  const [localDate, setLocalDate] = useState<string>(dates[i] ? dateSetting : today);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  // is date picker closed? did local change?

  const setDate = (date: string) => {
    // global date settings
    const newDates = dates.slice();
    newDates[i] = date;
    setSettings({ dates: newDates });
  };

  const setDateFromLocal = () => {
    setDate(localDate);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleDateRadio = (_: any, newValue: string) => {
    if (newValue === LATEST) {
      setDate('');
    } else {
      setDateFromLocal();
    }
  };

  const onUpdate = (dateString: string | null) => {
    if (dateString && isDateValid(dateString)) {
      const parsedDate = parseDate(dateString) || '';
      setLocalDate(parsedDate);
      if (!isPickerOpen) {
        setDate(parsedDate);
      }
    } else {
      // if we are clearing, we are kicking it back to "today's date"
      setDate('');
      setLocalDate(today);
    }
  };

  // if picker closed, we are going to sync latest state and kick it into global
  useEffect(() => {
    // if not closed and not-cleared (there's still a date...)
    if (!isPickerOpen && dateSetting) {
      setDateFromLocal();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPickerOpen]); // eslint-disable-next-line react-hooks/exhaustive-deps

  // SETTING HELPERS

  const onChangeMilestone = (milestone: string | null) => {
    const newMilestoneIDs = milestoneIDs.slice();
    newMilestoneIDs[i] = milestone || '';
    setSettings({ milestoneIDs: newMilestoneIDs });
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_SELECT_MILESTONE_REPORT, {
        milestoneIDs: newMilestoneIDs,
        types,
      })
    );
  };

  const onChangeType = (type: CostReportColumnType) => {
    const newTypes = types.slice(0, 2); // always should be 2 milestones
    newTypes[i] = type;
    setSettings({ types: newTypes });
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_SELECT_MILESTONE_REPORT, {
        milestoneIDs,
        types: newTypes,
      })
    );
  };

  const dateRadioValue = dateSetting === '' ? LATEST : SET_DATE;
  const key = `${milestoneIDs[i]} ${types[i]} ${i} ${dateSetting}`;
  const radio = () => <Radio className={classes.smallButton} />;
  const formControlLabel = (value: string, label: string) => (
    <FormControlLabel
      className={dateRadioValue === value ? '' : classes.unSelected}
      classes={{
        root: classes.formLabelRoot,
      }}
      value={value}
      control={radio()}
      label={label}
    />
  );

  return (
    <div key={key} className={classes.innerColumn}>
      <Typography className={classes.label}>Milestone</Typography>
      <div className={classes.horizontal}>
        <MilestoneSelect
          milestones={milestones}
          selectedMilestone={milestoneIDs[i]}
          onChangeMilestone={onChangeMilestone}
        />
      </div>
      <div className={classes.horizontal}>
        <Typography className={classes.label}>Value</Typography>
        <TypeSelect selected={types[i]} onChange={onChangeType} />
      </div>
      <div className={classes.horizontal}>
        <Typography className={classes.label}>Date to Compare</Typography>
        <FormControl>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={dateRadioValue}
            onChange={handleDateRadio}
          >
            {formControlLabel(LATEST, LATEST)}
            {formControlLabel(SET_DATE, SET_DATE)}
          </RadioGroup>
        </FormControl>
        <div
          className={
            dateRadioValue === LATEST ? `${classes.unSelected} ${classes.picker}` : classes.picker
          }
        >
          <MilestoneDatePicker
            date={localDate}
            label=""
            onFocus={() => {
              handleDateRadio(null, SET_DATE);
            }}
            onUpdate={onUpdate}
            setDatePickerOpen={setIsPickerOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(VarianceModalMilestonesSelects);
