import { ItemsSortKey, SortDirection } from '../../generated/graphql';
import { isScenarioVisibility } from '../Items/ItemsUtils';
import { useMilestonesQuery } from '../Milestones/hooks';

import { Scenario } from './types';

export const getItemOverlayInputsFromItemIDs = (itemIDs: UUID[]) =>
  itemIDs.map((itemID) => ({ itemID }));

export const getLocalStorageSetting = (key: string) => {
  return localStorage.getItem(`SCENARIOS::${key}`);
};

export const setLocalStorageSetting = (key: string, value: string) => {
  return localStorage.setItem(`SCENARIOS::${key}`, value);
};

export const FILES_SCENARIO_DEFAULT_SORT = {
  sortDirection: SortDirection.SORT_ASCENDING,
  sortKey: ItemsSortKey.SORT_NUMBER,
};

export const getSortKeyVal = (keyString: string | null) => {
  switch (keyString) {
    case ItemsSortKey.SORT_COST_IMPACT:
      return ItemsSortKey.SORT_COST_IMPACT;
    case ItemsSortKey.SORT_NUMBER:
      return ItemsSortKey.SORT_NUMBER;
    case ItemsSortKey.SORT_STATUS:
      return ItemsSortKey.SORT_STATUS;
    default:
      return FILES_SCENARIO_DEFAULT_SORT.sortKey;
  }
};
export const getSortDirectionVal = (keyString: string | null) => {
  switch (keyString) {
    case SortDirection.SORT_ASCENDING:
      return SortDirection.SORT_ASCENDING;
    case SortDirection.SORT_DESCENDING:
      return SortDirection.SORT_DESCENDING;
    default:
      return FILES_SCENARIO_DEFAULT_SORT.sortDirection;
  }
};

export const getScenariosLocalStorageLocation = (projectID: UUID, key: string) =>
  `Project-${projectID}-Scenarios-${key}`;

export const useGetMilestoneNameFromID = (projectID: UUID, milestoneID?: UUID) => {
  const { data: milestonesData, loading: milestonesLoading } = useMilestonesQuery(projectID, false);
  const milestones = milestonesData?.milestones;
  const milestoneName = milestones?.find((m) => m.id === milestoneID)?.name ?? '';
  return { milestoneName, milestonesData, milestonesLoading };
};

export const getScenarioItems = (items: Scenario['items']) =>
  (items ?? []).filter((item) => isScenarioVisibility(item?.visibility));
