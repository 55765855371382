import { Select, SelectEntry } from '../scales';

export type ProjectProp = {
  id: string;
  name: string;
  description?: string;
  abbreviation?: string;
};

type Props = {
  editable?: boolean;
  name: string;
  search?: boolean;
  selectedValue?: string | null;
  setProjectProp: (p: ProjectProp | null) => void;
  values: ProjectProp[];
};

const getEntry = (id: string, label: string, description?: string): SelectEntry => {
  return {
    id,
    label,
    description,
  };
};

export default function ProjectPropSelector(props: Props) {
  const { editable = false, name, search = false, selectedValue, setProjectProp, values } = props;
  const entries = values.map((prop) => {
    const { id, name: entryName, description, abbreviation } = prop;
    const modifiedName = abbreviation ? `${entryName} (${abbreviation})` : entryName;
    return getEntry(id, modifiedName, description);
  });

  return (
    <Select
      data-cy={`dropdown-project${name.split(' ')[0]}`}
      aria-label={`Select a project ${name}`}
      isDisabled={!editable}
      entries={entries}
      onChange={(newID: UUID | null) => {
        const newProp = values.find((p: ProjectProp) => p.id === newID) || null;
        setProjectProp(newProp);
      }}
      isSearchable={search}
      value={selectedValue}
      placeholder={`Set project ${name}`}
    />
  );
}
