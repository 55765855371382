import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import VerificationLayersData from './login/VerificationLayersData';

const App = () => (
  <BrowserRouter>
    <VerificationLayersData />
  </BrowserRouter>
);

export default Sentry.withProfiler(App);
