import { FC, useContext } from 'react';
import { useLocalStorage } from 'react-use';

import { ProjectCompSectionType, TermKey } from '../../../../api/gqlEnums';
import { SUBTOTAL, TOTAL } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { categoryLabel } from '../../../../utilities/string';
import { getReportIdFromPrintUrl } from '../../../../utilities/url';
import { useLocalStorageParams } from '../../../../utilities/urlState';
import { useIsProjectCompSectionCollapsed } from '../../../ProjectComps/ProjectCompsSetUtils';
import {
  ChartParams,
  DEFAULT_PARAMS,
  getChartsParamsKey,
} from '../../../ProjectComps/ProjectCompsVisualization/ProjectCompsChart/ProjectCompsChartData';
import {
  TypeToggle,
  getStackedBarColor,
} from '../../../ProjectComps/ProjectCompsVisualization/ProjectCompsChart/ProjectCompsChartUtils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import {
  COLUMN_FONT_CLASSES,
  PRINT_PARAMS_DEFAULTS,
  PRINT_PARAMS_KEY,
} from '../PrintProjectCompsUtils';

import PrintProjectCompRow from './PrintProjectCompRow';
import PrintProjectCompStyles from './PrintProjectCompStyles';

type PrintLabelsColumnProps = {
  classes: Classes<typeof PrintProjectCompStyles>;
  hasMarkups: boolean;
  headerHeight: number;
  pageHeader: JSX.Element;
  projectCompsSet: ProjectCompsSet;
  rowHasBreakpoint: (rowIndex: number) => boolean;
};

const PrintLabelsColumn: FC<PrintLabelsColumnProps> = ({
  classes,
  hasMarkups,
  headerHeight,
  pageHeader,
  projectCompsSet,
  rowHasBreakpoint,
}) => {
  const reportID = getReportIdFromPrintUrl();
  const [printParams] = useLocalStorage(PRINT_PARAMS_KEY, PRINT_PARAMS_DEFAULTS);
  const hasCharts = printParams?.hasCharts;
  const [chartParams] = useLocalStorageParams<ChartParams>(
    DEFAULT_PARAMS,
    getChartsParamsKey(reportID)
  );
  const isCategorized = chartParams?.selectedType === TypeToggle.CATEGORIZED;
  const filterKeys = chartParams?.filterKeys ?? [];
  const { categories, markups } = projectCompsSet;
  const t = useContext(ProjectTermStore);
  const isMarkupsCollapsed = useIsProjectCompSectionCollapsed(
    ProjectCompSectionType.SUBSECTION_MARKUPS
  );

  const subtotalIndex = categories.length;
  const markupsTotalIndex = categories.length + 1;
  const individualMarkupsTotalIndex = categories.length + 2;
  let totalIndex = categories.length;
  if (hasMarkups) {
    totalIndex += 2;
    if (!isMarkupsCollapsed) totalIndex += markups.length;
  }

  const labelCell = (
    value: string,
    isBold: boolean,
    isIndented: boolean,
    hasCharts?: boolean,
    color?: string // We only convey a color if it should be visible
  ) => (
    <div className={`flex items-center gap-1 ${isIndented ? classes.indentedLabelCell : ''}`}>
      {hasCharts && <div className="h-2 w-1" style={{ backgroundColor: color }} />}
      <div className={`${COLUMN_FONT_CLASSES} ${isBold ? 'font-bold' : ''}`}>{value}</div>
    </div>
  );

  return (
    <div className={`flex-col @container ${classes.columnContent} ${classes.labelColumnContent}`}>
      {/* Category Labels */}
      {categories.map((category, i) => {
        const { name, number } = category;
        const label = categoryLabel(name, number);
        const keyIsVisible =
          isCategorized && (filterKeys.includes(number) || filterKeys.length === 0);
        const color = keyIsVisible ? getStackedBarColor(i) : undefined;
        return (
          <PrintProjectCompRow
            content={labelCell(label, false, false, hasCharts, color)}
            hasBreakpoint={rowHasBreakpoint(i)}
            headerHeight={headerHeight}
            key={label}
            pageHeader={pageHeader}
          />
        );
      })}
      {/* Subtotal Label */}
      {hasMarkups && (
        <PrintProjectCompRow
          content={labelCell(SUBTOTAL, true, false)}
          hasBorder
          hasBreakpoint={rowHasBreakpoint(subtotalIndex)}
          headerHeight={headerHeight}
          pageHeader={pageHeader}
        />
      )}
      {/* Markups Total Label */}
      {hasMarkups && (
        <PrintProjectCompRow
          content={labelCell(t.titleCase(TermKey.MARKUP), true, false)}
          hasBreakpoint={rowHasBreakpoint(markupsTotalIndex)}
          headerHeight={headerHeight}
          pageHeader={pageHeader}
        />
      )}
      {/* Individual Markup Labels */}
      {hasMarkups &&
        !isMarkupsCollapsed &&
        markups.map((markup, i) => {
          return (
            <PrintProjectCompRow
              content={labelCell(markup.name, false, true)}
              hasBreakpoint={rowHasBreakpoint(individualMarkupsTotalIndex + i)}
              headerHeight={headerHeight}
              key={markup.name}
              pageHeader={pageHeader}
            />
          );
        })}
      {/* Total Label */}
      <PrintProjectCompRow
        content={labelCell(TOTAL, true, false)}
        hasBorder
        hasBreakpoint={rowHasBreakpoint(totalIndex)}
        headerHeight={headerHeight}
        pageHeader={pageHeader}
      />
    </div>
  );
};

export default withStyles(PrintProjectCompStyles)(PrintLabelsColumn);
