import { FC, useState } from 'react';

import { Checkbox, DialogActions, IconButton, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button } from '../../scales';
import DialogsStyles from '../DialogsStyles';

type Props = {
  acceptCtaCopy?: string;
  cancelCtaCopy?: string;
  classes: Classes<typeof DialogsStyles>;
  destructiveConfirm?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onDisable?: () => void;
};

const DialogsConfirmActions: FC<Props> = ({
  acceptCtaCopy,
  cancelCtaCopy,
  classes,
  destructiveConfirm,
  onClose,
  onConfirm,
  onDisable,
}) => {
  const [disable, setDisable] = useState(false);

  return (
    <DialogActions className={classes.action}>
      {!!onDisable && (
        <div className={classes.inline}>
          <IconButton className={classes.checkbox} onClick={() => setDisable(!disable)}>
            <Checkbox checked={disable} data-cy="DialogsConfirm-disableCheckbox" />
          </IconButton>
          <Typography>Don&apos;t show this reminder again</Typography>
        </div>
      )}
      <div className={classes.spacer} />
      {onClose ? (
        <div>
          <Button
            data-cy="DialogsConfirm-cancelButton"
            label={cancelCtaCopy || 'Cancel'}
            type="secondary"
            onClick={() => {
              if (onClose) onClose();
            }}
          />
        </div>
      ) : null}
      {destructiveConfirm ? (
        <Button
          data-cy="DialogsConfirm-okButton"
          label={acceptCtaCopy || 'Ok'}
          startIcon={<DeleteOutline />}
          type="destructive"
          onClick={() => {
            if (onConfirm) onConfirm();
            if (disable && onDisable) onDisable();
          }}
        />
      ) : (
        <Button
          data-cy="DialogsConfirm-okButton"
          label={acceptCtaCopy || 'Ok'}
          onClick={() => {
            if (onConfirm) onConfirm();
            if (disable && onDisable) onDisable();
          }}
          type="primary"
        />
      )}
    </DialogActions>
  );
};

export default withStyles(DialogsStyles)(DialogsConfirmActions);
