import { FC, useState } from 'react';

import { ItemSidebarLocation } from '../../../../analytics/analyticsEventProperties';
import { itemSidebarHomeOpenVar } from '../../../../api/apollo/reactiveVars';
import { GENERAL_INFO } from '../../../../constants';
import { mapCostModeStringToEnum } from '../../../../utilities/costMode';
import ItemSidebar from '../../../frame/ItemSidebar/ItemSidebar';
import ItemSidebarFooterCopyButton from '../../../frame/ItemSidebar/ItemSidebarFooterCopyButton';
import { useCalculateItemPermissions } from '../../../frame/ItemSidebar/ItemSidebarUtils';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';

type SearchItemSidebarWrapperProps = {
  items: ForecastingItem[];
  itemID: UUID | null;
  costMode: string;
  projectID?: UUID | undefined;
};

const SearchItemSidebarWrapper: FC<SearchItemSidebarWrapperProps> = ({
  costMode,
  itemID,
  items,
  projectID,
}) => {
  const [expanded, setExpanded] = useState<string[]>([GENERAL_INFO]);

  // QUERIES
  const {
    data,
    loading,
    previousData,
    refetch: refetchItem,
  } = useItemQuery(itemID ?? undefined, undefined, mapCostModeStringToEnum(costMode));
  // TODO: Remove this cast - CT-481
  const currentItem = (data?.item ?? previousData?.item ?? null) as ItemLike;

  // TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct
  const itemSidebarPermissions = useCalculateItemPermissions(currentItem, projectID, true);

  const openSidebar = (id?: string | undefined) => {
    if (id) itemSidebarHomeOpenVar(id);
  };

  const closeSidebar = () => {
    itemSidebarHomeOpenVar(null);
  };

  return (
    <ItemSidebar
      closeSidebar={closeSidebar}
      costMode={costMode}
      expanded={expanded}
      footer={<ItemSidebarFooterCopyButton item={currentItem} />}
      item={currentItem}
      items={items}
      loading={loading}
      milestones={currentItem ? currentItem.project.milestones : []}
      openSidebar={openSidebar}
      permissions={itemSidebarPermissions}
      projectId={currentItem?.project?.id}
      setExpanded={setExpanded}
      setOpenDialogAddOption={() => {}}
      sidebarLocation={ItemSidebarLocation.HOME}
      onItemMutated={refetchItem}
    />
  );
};

export default SearchItemSidebarWrapper;
