import { JoinProjectRoutes } from '../../api/gqlEnums';
import {
  COMPANY_TYPE_ARCHITECTURE_DESIGN,
  COMPANY_TYPE_DESIGN_ENGINEERING,
  COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
} from '../../constants';
import { useCompanyUserQuery } from '../../hooks/useCompanyUserQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';

export const useDefaultProjectRoute = () => {
  const { loading: loadingPermissions } = usePermissions();
  const { data, loading: loadingCompanyUser } = useCompanyUserQuery(MountPolicy.SKIP_ON_MOUNT);
  const companyType = data?.companyUser?.company?.type ?? '';
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const route = getDefaultProjectRoute(companyType, shouldDisplayCosts);
  const loading = loadingCompanyUser || loadingPermissions;
  return { route, loading };
};

export const getDefaultProjectRoute = (companyType: string, shouldDisplayCosts: boolean) => {
  const shouldDirectToItemsList = [
    COMPANY_TYPE_ARCHITECTURE_DESIGN,
    COMPANY_TYPE_DESIGN_ENGINEERING,
    COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
  ].includes(companyType);

  return shouldDisplayCosts && !shouldDirectToItemsList
    ? JoinProjectRoutes.PROJECT_DASHBOARD
    : JoinProjectRoutes.ITEMS_LIST;
};
