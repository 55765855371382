import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { LOCALSTORAGE_COMPANY_COLLABORATORS_SHOW } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { SortManager } from '../../scales';
import CompanyTabSearch from '../CompanyTabSearch';
import CompanyTabStyles from '../CompanyTabStyles';
import CompanyTabUsersFilterMenu from '../CompanyTabUsersFilterMenu';
import {
  CompanyHeadingsTabs,
  CompanyUserSearchManager,
  searchCompanyCollaborators,
} from '../CompanyTabUtils';

import CompanyTabCollaboratorsTable from './CompanyTabCollaboratorsTable';

type CompanyTabCollaboratorsProps = {
  classes: Classes<typeof CompanyTabStyles>;
  collaboratorCounts: number[];
  companyCollaborators: CompanyCollaborator[];
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  countTotalCollaborators: number;
  loading: boolean;
  searchManager: CompanyUserSearchManager;
  sortManagerCollaborators: SortManager;
};

const CompanyTabCollaborators: FC<CompanyTabCollaboratorsProps> = ({
  classes,
  companyCollaborators,
  collaboratorCounts,
  loading,
  countTotalCollaborators,
  filterStatus,
  setFilterStatus,
  searchManager,
  sortManagerCollaborators,
}) => {
  const { searchTerm } = searchManager;

  const searchedCompanyCollaborators = searchCompanyCollaborators(companyCollaborators, searchTerm);
  const countSearchedCompanyCollaborators = searchedCompanyCollaborators.length ?? 0;
  const searchStorage = LOCALSTORAGE_COMPANY_COLLABORATORS_SHOW;

  return (
    <>
      <div className={classes.titleHeader}>
        <Typography className={classes.titleText} variant="title">
          {CompanyHeadingsTabs.COLLABORATORS}
        </Typography>
        <CompanyTabUsersFilterMenu
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          userCounts={collaboratorCounts}
          searchStorage={searchStorage}
          view={CompanyAdminView.COLLABORATORS_LIST}
        />
        <div className={classes.tableSearchBar}>
          <CompanyTabSearch
            searchManager={searchManager}
            page={CompanyAdminView.COLLABORATORS_LIST}
          />
        </div>
        <Typography className={classes.memberCount}>
          {countSearchedCompanyCollaborators} of {countTotalCollaborators} people shown
        </Typography>
      </div>
      <CompanyTabCollaboratorsTable
        companyCollaborators={searchedCompanyCollaborators}
        sortManager={sortManagerCollaborators}
        searchTerm={searchTerm}
        loading={loading}
      />
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabCollaborators);
