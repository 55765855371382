import {
  ListCustomCategorizationsQuery,
  ListCustomCategorizationsQueryVariables,
} from '../generated/graphql';

import { listCustomCategorizations } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export function useCustomCategorizations(projectID?: UUID | null, mountPolicy?: MountPolicy) {
  return useQuery<ListCustomCategorizationsQuery, ListCustomCategorizationsQueryVariables>(
    listCustomCategorizations,
    {
      variables: {
        projectID,
      },
      skip: !projectID,
    },
    mountPolicy
  );
}
