import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import joinAPI from '../../../api/joinAPI';
import {
  CreateMarkupsMutation,
  CreateMarkupsMutationVariables,
  GetDoMilestoneMarkupsHaveContingencyDrawsQuery,
  GetDoMilestoneMarkupsHaveContingencyDrawsQueryVariables,
  GridReorderMarkupsMutation,
  GridReorderMarkupsMutationVariables,
  RemoveMarkupsMutation,
  RemoveMarkupsMutationVariables,
  ToggleInheritedItemMarkupMutation,
  ToggleInheritedItemMarkupMutationVariables,
  UpdateMarkupsMutation,
  UpdateMarkupsMutationVariables,
} from '../../../generated/graphql';

import {
  createMarkupsMutation,
  getDoMilestoneMarkupsHaveContingencyDraws,
  gridReorderMarkupsMutation,
  removeMarkupsMutation,
  toggleInheritedItemMarkupMutation,
  updateMarkupsMutation,
} from './queries';

type CreateMarkupMutationResult = {
  data?: CreateMarkupsMutation;
};

type ReorderMarkupMutationResult = {
  data?: GridReorderMarkupsMutation;
};

export const createMarkups = (
  projectID: UUID,
  estimate: UUID | undefined,
  number: number,
  onSuccess: (r: CreateMarkupMutationResult) => void
) => {
  if (!estimate) return () => {};
  const variables: CreateMarkupsMutationVariables = {
    projectID,
    estimate,
    number,
  };
  return joinAPI.requestGraphQL(createMarkupsMutation, variables).then(onSuccess);
};

type UpdateMarkupMutationResult = {
  data?: UpdateMarkupsMutation;
};

export const updateMarkups = (
  projectID: UUID,
  estimate: UUID | undefined,
  markups: MarkupInput[],
  onSuccess: (r: UpdateMarkupMutationResult) => void,
  viewFilter?: ViewFilterInput
) => {
  if (!estimate) return () => {};
  const variables: UpdateMarkupsMutationVariables = {
    projectID,
    estimate,
    markups,
    viewFilter,
  };
  return joinAPI.requestGraphQL(updateMarkupsMutation, variables).then(onSuccess);
};

type RemoveMarkupsUpdateResult = {
  data?: RemoveMarkupsMutation;
};

export const removeMarkups = (
  projectID: UUID,
  estimate: UUID | undefined,
  markupIDs: UUID[],
  onSuccess: (r: RemoveMarkupsUpdateResult) => void,
  viewFilter?: ViewFilterInput
) => {
  if (!markupIDs.length || !estimate) return;
  const variables: RemoveMarkupsMutationVariables = {
    projectID,
    estimate,
    markupIDs,
    viewFilter,
  };
  joinAPI.requestGraphQL(removeMarkupsMutation, variables).then(onSuccess);
};

type ToggleMarkupsMutationResult = {
  data?: ToggleInheritedItemMarkupMutation;
};

export const toggleInheritedMarkup = (
  projectID: UUID,
  estimateId: UUID | undefined,
  markupId: UUID,
  disabled: boolean,
  onSuccess: (result: ToggleMarkupsMutationResult) => void
) => {
  if (!estimateId) return;
  const variables: ToggleInheritedItemMarkupMutationVariables = {
    projectID,
    estimateId,
    markupId,
    disabled,
  };
  joinAPI.requestGraphQL(toggleInheritedItemMarkupMutation, variables).then(onSuccess);
};

export const reorderMarkup = (
  projectID: UUID,
  estimate: UUID | undefined,
  markups: ReorderLineInput[],
  onSuccess: (result: ReorderMarkupMutationResult) => void
) => {
  if (!estimate) return;
  const variables: GridReorderMarkupsMutationVariables = {
    projectID,
    estimate,
    markups,
  };
  joinAPI.requestGraphQL(gridReorderMarkupsMutation, variables).then(onSuccess);
};

export const useGetDoMilestoneMarkupsHaveContingencyDrawsLazyQuery = (
  options?: LazyQueryHookOptions<
    GetDoMilestoneMarkupsHaveContingencyDrawsQuery,
    GetDoMilestoneMarkupsHaveContingencyDrawsQueryVariables
  >
) =>
  useLazyQuery<
    GetDoMilestoneMarkupsHaveContingencyDrawsQuery,
    GetDoMilestoneMarkupsHaveContingencyDrawsQueryVariables
  >(getDoMilestoneMarkupsHaveContingencyDraws, {
    fetchPolicy: 'no-cache', // force refetches since we depend on onCompleted
    ...options,
  });
