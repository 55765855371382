import { FC, useMemo, useRef } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import { projectSettingsVar } from '../../../api/apollo/reactiveVars';
import { Scale } from '../../../enums';
import { formatCommas, formatCost } from '../../../utilities/currency';
import ErrorTooltip from '../ErrorTooltip';

const title = (hasFormula: string) => (hasFormula ? 'This cell includes a formula' : '');

interface CostRendererProps {
  error?: string;
  scale?: number;
  value: RegularCell;
}

export const CostRenderer: FC<CostRendererProps> = ({ error, scale = Scale.CURRENCY, value }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { string, formula } = value;
  const disabled = 'disabled' in value ? value.disabled : false;
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled join-grid-string` : '';

  const projectSettings = projectSettingsVar();

  const displayValue = useMemo(() => {
    if (disabled) return 'Not Applied';
    if (error) return string;
    // if there is no error, the string is empty
    // and the formula has a value it means we're
    // we've sent a requst to set the formula
    // but don't yet have a response from teh server
    if (!string && formula) return '';
    return formatCost(value.string, { scale, showZeroCents: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [string, formula, disabled, projectSettings]);

  return (
    <div
      className={`join-grid-renderer join-grid-number ${
        error ? 'join-grid-cell-error' : ''
      }${disabledMarkupStyle}`}
    >
      {error ? (
        <div>
          <ErrorTooltip title={error}>
            <Error />
          </ErrorTooltip>
        </div>
      ) : (
        <div /> // Extra dummy div to get flex to play nice
      )}
      <div
        ref={ref}
        className={`join-grid-trim-text push-right ${formula ? 'join-grid-formula' : ''}`}
        title={title(formula)}
      >
        {displayValue}
      </div>
    </div>
  );
};

interface NumberRendererProps {
  error?: string;
  value: RegularCell & MarkupValueCell;
}

export const NumberRenderer: FC<NumberRendererProps> = ({ value, error }) => {
  const { string, formula, disabled } = value;
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled` : '';
  const style = `${error ? 'join-grid-trim-text' : ''} ${
    !error && formula ? 'join-grid-formula' : ''
  }`;

  const projectSettings = projectSettingsVar();

  const displayValue = useMemo(() => {
    if (error) return string;
    // if there is no error, the string is empty
    // and the formula has a value it means we're
    // we've sent a requst to set the formula
    // but don't yet have a response from teh server
    if (!string && formula) return '';
    return formatCommas(value.string);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [string, formula, projectSettings]);

  return (
    <div
      className={`join-grid-renderer join-grid-number ${
        error ? 'join-grid-cell-error' : ''
      } ${disabledMarkupStyle} `}
    >
      {error ? (
        <div>
          <ErrorTooltip title={error}>
            <Error />
          </ErrorTooltip>
        </div>
      ) : (
        <div /> // Extra dummy div to get flex to play nice
      )}
      <div className={style} title={title(formula)}>
        {displayValue}
      </div>
    </div>
  );
};
