// SORTING ACCORDING NUMBER (DEFAULT) HELPER FUNCTIONS
export function tokenizeNum(number) {
  const digits = new RegExp('[0-9]');
  const letters = new RegExp('[A-Za-z]');
  const characters = new RegExp('[^A-Za-z0-9]');

  if (!number || number.length < 1) {
    return [];
  }
  const substringList = [];
  let substring = number[0];

  for (let i = 1; i < number.length; i += 1) {
    if (letters.test(substring) || characters.test(substring)) {
      substringList.push(substring);
      substring = number[i];
      // eslint-disable-next-line no-continue
      continue;
    }

    if (digits.test(number[i]) === true && digits.test(substring) === true) {
      substring += number[i];
    } else {
      substringList.push(substring);
      substring = number[i];
    }
  }
  substringList.push(substring);

  const finalList = [];

  for (let i = 0; i < substringList.length; i += 1) {
    if (digits.test(substringList[i])) {
      const makeNum = Number(substringList[i]);
      finalList.push(makeNum);
    } else {
      finalList.push(substringList[i]);
    }
  }

  return finalList;
}

// COMPARING FUNCTION
export function compareStrings(a, b) {
  if (a === b) {
    return 0;
  }
  const substringsA = tokenizeNum(a);
  const substringsB = tokenizeNum(b);
  const minLength = Math.min(substringsA.length, substringsB.length);

  for (let i = 0; i < minLength; i += 1) {
    if (substringsA[i] === substringsB[i]) {
      // eslint-disable-next-line no-continue
      continue;
    }
    let lowerCaseItemA = substringsA[i];

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(lowerCaseItemA)) {
      lowerCaseItemA = lowerCaseItemA.toLowerCase();
    }

    let lowerCaseItemB = substringsB[i];

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(lowerCaseItemB)) {
      lowerCaseItemB = lowerCaseItemB.toLowerCase();
    }

    if (lowerCaseItemA === lowerCaseItemB) {
      if (substringsA[i] > substringsB[i]) {
        return 1;
      }
      return -1;
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(substringsA[i]) && isNaN(substringsB[i])) {
      return 1;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(substringsA[i]) && !isNaN(substringsB[i])) {
      return -1;
    }
    if (lowerCaseItemA > lowerCaseItemB) {
      return 1;
    }
    return -1;
  }
  if (minLength === substringsB.length) {
    return 1;
  }
  return -1;
}

export function compareCostImpactMagnitude(a, b) {
  const costImpactA = Math.abs(a);
  const costImpactB = Math.abs(b);
  if (costImpactA > costImpactB) return -1;
  if (costImpactA < costImpactB) return 1;
  return 0;
}

export function compareCostImpact(a, b) {
  if (a === undefined) return 1;
  if (b === undefined) return -1;
  const costImpactA = Number(a);
  const costImpactB = Number(b);
  if (costImpactA > costImpactB) return -1;
  if (costImpactA < costImpactB) return 1;
  return 0;
}

export function compareMilestoneDate(a, b) {
  if (!a || !a.date) return -1;
  if (!b || !b.date) return 1;
  return a.date > b.date ? -1 : 1;
}
