import { FC, useEffect, useRef } from 'react';
import * as React from 'react';

import useFocusHandler from '../hooks/useFocusHandler';
import { EditorPosition, GridController, KeyBufferState, Position } from '../types';
import { assignNonNullRef } from '../utilities/cell';

interface StringCellEditorProps {
  grid: GridController;
  defaultValue: RegularCell;
  updateCell: (position: Position, newValue: string) => void;
  position: EditorPosition;
  stopEditing: () => void;
  editingCell: Position;
}

const StringCellEditor: FC<StringCellEditorProps> = ({
  grid,
  updateCell,
  defaultValue,
  stopEditing,
  editingCell,
  position,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const previousFocusedElement = document.activeElement;

  // Autosizes on initial render and on every keypress. There are probably
  // more efficient ways to do this, but I am not noticing a performance bottleneck.
  const autosizeTextArea = () => {
    const area = textAreaRef.current;
    if (area) {
      // Resize the element to be 1px tall to make it all scroll but still visible
      area.style.height = '1px';
      // Then set it to what we actually want it to be
      area.style.height = `${Math.max(position.height, area.scrollHeight)}px`;
    }
  };

  // Set the cursor at the end of the textarea when we enter it, not the beginning.
  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.selectionStart = textAreaRef.current.value.length;
        textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
      }
    }, 0);
  });

  useFocusHandler(
    textAreaRef,
    previousFocusedElement,
    () => {
      const extraKeys = grid.getKeyBufferString(editingCell);
      // eslint-disable-next-line no-param-reassign
      grid.isRenderingEditor = KeyBufferState.CLEAR;
      // Add the buffered keys once this editor gains focus.
      if (extraKeys.length > 0 && textAreaRef.current) {
        textAreaRef.current.value = extraKeys;
      }
    },
    () => {
      if (textAreaRef.current) {
        if (grid.isRenderingEditor === KeyBufferState.CLEAR) {
          // Prevent any races if we never actually displayed this editor.
          updateCell(editingCell, textAreaRef.current.value);
        }
        textAreaRef.current.removeEventListener('keydown', autosizeTextArea);
      }
    }
  );

  // Autosize the text area on initial render
  useEffect(() => {
    setTimeout(autosizeTextArea, 0);
  });

  const handleKey = (event: React.KeyboardEvent<HTMLElement>, wrapper: boolean) => {
    if (wrapper && event.key === 'Escape') stopEditing();
    if (!event.shiftKey && (event.key === 'Enter' || event.key === 'Tab')) {
      stopEditing();
    } else if (wrapper) {
      event.stopPropagation();
    }
  };
  return (
    <div
      className="join-grid-cell-editor"
      style={position}
      onKeyDown={(event) => handleKey(event, true)}
    >
      <textarea
        className="join-grid-text-input join-grid-textarea"
        defaultValue={defaultValue.string}
        ref={(ref) => assignNonNullRef(ref, textAreaRef)}
        onBlur={stopEditing}
        onKeyDown={(event) => {
          handleKey(event, false);
          autosizeTextArea();
        }}
      />
    </div>
  );
};

export default StringCellEditor;
