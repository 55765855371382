import { useEffect, useMemo, useState } from 'react';

import { CostTableColumnInputKey, ProjectCompsSetQuery } from '../../../../generated/graphql';
import { getReportIdFromUrl } from '../../../../utilities/url';
import { useLocalStorageParams } from '../../../../utilities/urlState';

import ProjectCompsChartBar from './ProjectCompsChartBar';
import ProjectCompsChartSidebar from './ProjectCompsChartSidebar';
import ProjectCompsChartStackedBar from './ProjectCompsChartStackedBar';
import {
  TypeToggle,
  generateCategorizedData,
  generateCategoryNameMap,
  generateProjectCompsChartData,
  getColumnToggleOptionsForCharts,
  getStackedBarColor,
  getTitle,
  marshallColumnInputParam,
  unmarshallColumnInput,
} from './ProjectCompsChartUtils';

type Props = {
  projectCompsSet: ProjectCompsSetQuery['projectCompsSet'];
};

const CHART_HEIGHT = 400;

export type ChartParams = {
  selectedType: TypeToggle;
  selectedColumnInputParam: string; // In order to store, we must stringify
  filterKeys: string; // In order to store, we must stringify
};
export const DEFAULT_PARAMS: ChartParams = {
  selectedType: TypeToggle.CATEGORIZED,
  selectedColumnInputParam: marshallColumnInputParam(CostTableColumnInputKey.TOTAL),
  filterKeys: JSON.stringify([]),
};
export const getChartsParamsKey = (projectCompsSetID?: UUID) =>
  `forecasting-chart-params-${projectCompsSetID}`;

const ProjectCompsChartData = (props: Props) => {
  const { projectCompsSet } = props;
  const reportID = getReportIdFromUrl();

  // Display context
  const pinnedUnit = props.projectCompsSet.units?.find(
    (u) => u.id === props.projectCompsSet.input.pinnedUnitID
  );
  const pinnedUnitAbbreviation = pinnedUnit?.abbreviationSingular || 'unit';

  // State
  const [params, setParams] = useLocalStorageParams<ChartParams>(
    DEFAULT_PARAMS,
    getChartsParamsKey(reportID)
  );
  const { selectedType, selectedColumnInputParam } = params;
  const selectedColumnInput = unmarshallColumnInput(selectedColumnInputParam);

  // TYPE
  const setSelectedType = (selectedType: TypeToggle) => {
    setParams({
      ...params,
      selectedType,
      selectedColumnInputParam: marshallColumnInputParam(CostTableColumnInputKey.TOTAL),
    });
  };
  const isCategorized = params.selectedType === TypeToggle.CATEGORIZED;

  // Hover handler
  const [hover, setHover] = useState<string | null>(null);

  // UNIT
  const setSelectedColumn = (selectedColumnInputParam: string) => {
    return setParams({
      ...params,
      selectedColumnInputParam,
    });
  };

  const columnToggleOptions = getColumnToggleOptionsForCharts(
    projectCompsSet,
    isCategorized,
    pinnedUnit
  );

  const selectedUnitOption = columnToggleOptions.find(
    (option) => option.value === selectedColumnInputParam
  );

  useEffect(() => {
    if (!columnToggleOptions.map((o) => o.value).includes(selectedColumnInputParam)) {
      setParams({
        ...params,
        selectedColumnInputParam: columnToggleOptions[0].value,
      });
    }
  }, [columnToggleOptions, selectedColumnInputParam, params, setParams]);

  // Filtering
  const filterKeys = JSON.parse(params.filterKeys);
  const setFilterKeys = (filterKeys: string[]) =>
    setParams({ ...params, filterKeys: JSON.stringify(filterKeys) });

  // Display text
  const yLabel = selectedUnitOption?.label || '';
  const title = getTitle(selectedType, selectedColumnInput.key, pinnedUnitAbbreviation);

  // Data Transformation for Charts
  const { totalData, isCost } = useMemo(
    () =>
      generateProjectCompsChartData({
        projectCompsSet,
        selectedColumnInput,
      }),
    [projectCompsSet, selectedColumnInput]
  );

  // Key Legend values
  const categoryNameMap = useMemo(() => generateCategoryNameMap(totalData), [totalData]);
  const values = useMemo(
    () =>
      isCategorized
        ? Array.from(categoryNameMap.entries()).map(([legendKey, name], i) => ({
            legendKey,
            name,
            color: getStackedBarColor(i),
          }))
        : totalData.map(({ id, name, color }) => ({ legendKey: id, name, color })),
    [totalData, isCategorized, categoryNameMap]
  );

  return (
    <div className="flex" style={{ height: CHART_HEIGHT }}>
      <div className="w-75 flex-shrink-0 bg-background-1">
        <ProjectCompsChartSidebar
          columnToggleOptions={columnToggleOptions}
          filterKeys={filterKeys}
          hover={hover}
          isCategorized={isCategorized}
          values={values}
          onChangeColumn={setSelectedColumn}
          onChangeType={setSelectedType}
          onFiltersChange={setFilterKeys}
          selectedColumnInputParam={selectedColumnInputParam}
          selectedType={selectedType}
          setHover={setHover}
        />
      </div>
      <div className="flex flex-grow flex-col gap-4 overflow-hidden p-4">
        <div className="type-heading2" data-cy="chart-title">
          {title}
        </div>
        {isCategorized ? (
          <ProjectCompsChartStackedBar
            categorizedData={generateCategorizedData({
              totalData,
              filterKeys,
              selectedColumnInput,
            })}
            categoryNameMap={categoryNameMap}
            filterKeys={filterKeys}
            hasAverageComp={!!props.projectCompsSet.averageComp}
            hover={hover}
            isCost={isCost}
            onClickBarSetFilterKeys={setFilterKeys}
            onMouseSetHover={setHover}
            selectedUnits={projectCompsSet.selectedUnits}
            totalData={totalData}
            yLabel={yLabel}
          />
        ) : (
          <ProjectCompsChartBar
            hasAverageComp={!!props.projectCompsSet.averageComp}
            isCost={isCost}
            totalData={totalData}
            unitAbbreviation={pinnedUnitAbbreviation}
            yLabel={yLabel}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectCompsChartData;
