import { PcMarkup, ProjectCompInput } from '../../../generated/graphql';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import { EscalationTarget } from '../hooks/useAutoEscalateMultiple';
import {
  useProjectCompInputUpdateFunctions,
  useProjectCompsSetInputUpdateFunctions,
} from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { extractProjectCompValues } from '../ProjectCompsSetUtils';
import { useProjectCompMenu } from '../ProjectCompUtils';

import ProjectComp from './ProjectComp';

type Props = {
  averageCompExists: boolean;
  categories: ProjectCompsSet['categories'];
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  index: number;
  markups: PcMarkup[];
  onAutoEscalateAllLocation: (target?: EscalationTarget) => void;
  onAutoEscalateAllTime: () => void;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  targetLocation: EscalationTargetLocation;
  projectComp: ProjectComp;
  projectCompInput: ProjectCompInput;
  selectedUnits: Unit[];
  unit: Unit;
};

const ProjectCompData = ({
  averageCompExists,
  categories,
  costTableColumnInputs,
  hasMarkups,
  index,
  markups,
  onAutoEscalateAllLocation,
  onAutoEscalateAllTime,
  onTargetLocationChange,
  projectComp,
  projectCompInput,
  selectedUnits,
  targetLocation,
  unit,
}: Props) => {
  const { hasAccess, id, isResettable, milestones, projectID } =
    extractProjectCompValues(projectComp);

  const projectCompsSetInputUpdateFunctions = useProjectCompsSetInputUpdateFunctions();
  const projectCompInputUpdateFunctions = useProjectCompInputUpdateFunctions(id, milestones);
  const { sections } = useProjectCompMenu({
    hasAccess,
    isResettable,
    updateFunctions: { ...projectCompsSetInputUpdateFunctions, ...projectCompInputUpdateFunctions },
    projectCompInputID: id,
    projectId: projectID,
  });

  return (
    <ProjectComp
      averageCompExists={averageCompExists}
      categories={categories}
      costTableColumnInputs={costTableColumnInputs}
      hasMarkups={hasMarkups}
      index={index}
      markups={markups}
      onAutoEscalateAllLocation={onAutoEscalateAllLocation}
      onAutoEscalateAllTime={onAutoEscalateAllTime}
      projectComp={projectComp}
      projectCompInput={projectCompInput}
      projectCompInputUpdateFunctions={projectCompInputUpdateFunctions}
      menuSections={sections}
      selectedUnits={selectedUnits}
      onTargetLocationChange={onTargetLocationChange}
      targetLocation={targetLocation}
      unit={unit}
    />
  );
};

export default ProjectCompData;
