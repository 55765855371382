import { FC, useState } from 'react';

import { Add } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsAddCompanyEmployee from '../../Dialogs/DialogsAddCompanyEmployee/DialogsAddCompanyEmployee';
import CTAIconButton from '../../shared-widgets/CTAIconButton';

import AddEmployeeStyles from './styles';

type AddEmployeeProps = {
  classes: Classes<typeof AddEmployeeStyles>;
  company: Company;
  companyRoles: PartialRole[];
  companyProjectRoles: CompanyProjectRoles[];
  companyUsers: CompanyUser[];
  fullWidth?: boolean;
  onClick?: () => void;
};

const AddEmployee: FC<AddEmployeeProps> = ({
  classes,
  company,
  companyRoles,
  companyProjectRoles,
  companyUsers,
  fullWidth = false,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <CTAIconButton
        isFullWidth={fullWidth}
        isSmaller
        onClick={() => {
          setOpen(true);
          onClick?.();
        }}
        icon={<Add className={classes.icon} />}
        buttonText="New Employee"
      />
      <DialogsAddCompanyEmployee
        company={company}
        companyRoles={companyRoles}
        companyProjectRoles={companyProjectRoles}
        companyUsers={companyUsers}
        open={open}
        setDialogOpen={(open: boolean) => {
          setOpen(open);
        }}
      />
    </>
  );
};

export default withStyles(AddEmployeeStyles)(AddEmployee);
