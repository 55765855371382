import { FC, useState } from 'react';

import FilesDialog from '../../DocumentMagic/Files/FilesDialog/FilesDialog';
import AssetsCardsDropzone from '../AssetsCards/AssetsCardsDropzone/AssetsCardsDropzone';
import AssetsCardsHint from '../AssetsCards/AssetsCardsHint/AssetsCardsHint';
import AssetsCardsWrapper from '../AssetsCards/AssetsCardsWrapper';

type AssetsWrapperProps = {
  assets: EntityAttachment[];
  assetsLoading: boolean;
  bookmarks?: Bookmark[];
  customProjectID?: string;
  hasAssetAddPermission: boolean;
  hasAssetDeletePermission: boolean;
  itemID?: UUID;
  onAttachAsset: (file: File) => void;
  onDropFile: (file: File) => void;
  onItemMutated?: () => void;
};

const AssetsWrapper: FC<AssetsWrapperProps> = ({
  assets,
  assetsLoading,
  bookmarks = [],
  customProjectID,
  hasAssetAddPermission,
  hasAssetDeletePermission,
  itemID,
  onAttachAsset,
  onDropFile,
  onItemMutated,
}) => {
  // State
  const [dialogOpen, setDialogOpen] = useState(false);

  // Action Functions
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const showHint = hasAssetAddPermission;

  // Components
  const dropZone = (
    <AssetsCardsDropzone
      assetsLoading={assetsLoading}
      disabled={!hasAssetAddPermission}
      hasAddPermission={hasAssetAddPermission}
      onDropFile={onAttachAsset}
      openDialog={openDialog}
    >
      <AssetsCardsWrapper
        assets={assets}
        bookmarks={bookmarks}
        customProjectID={customProjectID}
        hasDeletePermission={hasAssetDeletePermission}
        itemID={itemID}
        showMenu
        onItemMutated={onItemMutated}
      />
    </AssetsCardsDropzone>
  );

  return (
    <div>
      {dropZone}
      {showHint && <AssetsCardsHint />}
      <FilesDialog
        onDropFile={onDropFile}
        onClose={closeDialog}
        open={dialogOpen}
        onItemMutated={onItemMutated}
      />
    </div>
  );
};

export default AssetsWrapper;
