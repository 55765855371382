import { FC, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { IconButton, Popper, Typography } from '@material-ui/core';
import { Add, Close, History, Info, Save } from '@material-ui/icons';

import { currentUserReportVar, setReactiveLocal } from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { SetSettingsFunctionType, removeNullKeys } from '../../../utilities/urlState';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import useMemoWrapper from '../../useMemoWrapper';
import { useUpdateUserReport } from '../ReportHooks';

import styles from './CurrentReportBarStyles';
import { MapReportManagerPagesToValidReports, ReportManagerPages, areSettingsDirty } from './utils';

type CurrentReportBarProps = {
  anchorEl: HTMLElement;
  classes: Classes<typeof styles>;
  onCreate: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  setSettings: SetSettingsFunctionType;
  variant: ReportManagerPages;
  isViewOnly?: boolean;
};

const CurrentReportBar: FC<CurrentReportBarProps> = ({
  anchorEl,
  classes,
  onCreate,
  settings,
  setSettings,
  variant,
  isViewOnly,
}) => {
  const projectID = getProjectIdFromUrl();
  const updateReport = useUpdateUserReport({
    onCompleted: (data) => {
      setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, data.updateUserReport);
      setToast({ message: 'Successfully saved report' });
    },
  });
  const updateReportWithoutReactiveVarChange = useUpdateUserReport({
    onCompleted: () => {
      setToast({ message: 'Successfully saved report' });
    },
  });
  const validReportTypes = MapReportManagerPagesToValidReports[variant];
  const currentReport = useReactiveVar(currentUserReportVar);
  const settingsDirty = useMemoWrapper(areSettingsDirty, currentReport, settings);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  if (!currentReport || !validReportTypes.includes(currentReport.reportType)) return null;
  const { name, shared } = currentReport;
  const displayName = `${name} ${shared ? ' [Shared]' : ''}`;

  return (
    <Popper
      data-cy="popper-ReportBar"
      style={{ zIndex: 100 }}
      anchorEl={anchorEl}
      className={classes.containerOuter}
      open={!!currentReport}
      placement="bottom"
      container={anchorEl}
    >
      {currentReport.description.length > 0 && (
        <NormalTooltip title={currentReport.description}>
          <IconButton className={`${classes.iconButton} ${classes.marginLeft}`}>
            <Info color="secondary" className={classes.icon} />
          </IconButton>
        </NormalTooltip>
      )}
      <Typography className={classes.titleText}>{displayName}</Typography>
      <div className={classes.actionContainer}>
        <div className={classes.buttonPlaceholder}>
          {settingsDirty && (
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setSettings(removeNullKeys(JSON.parse(currentReport.settings)));
              }}
              title="Revert to saved report"
              data-cy="saved-report-revert"
            >
              <History className={classes.icon} />
            </IconButton>
          )}
        </div>
        <div className={classes.buttonPlaceholder}>
          {settingsDirty &&
            (currentReport.owner ? (
              <IconButton
                className={classes.iconButton}
                onClick={() =>
                  updateReport(
                    {
                      ...currentReport,
                      milestoneID: currentReport.milestoneID ?? undefined,
                      settings: JSON.stringify(removeNullKeys(settings)),
                    },
                    projectID
                  )
                }
                title="Save changes to report"
                data-cy="saved-report-save"
              >
                <Save className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton
                className={classes.iconButton}
                onClick={onCreate}
                title="Create new report"
                disabled={isViewOnly}
              >
                <Add className={classes.icon} />
              </IconButton>
            ))}
        </div>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            if (settingsDirty && currentReport.owner) {
              setShowCloseConfirm(true);
            } else {
              setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
            }
          }}
          title="Exit report"
        >
          <Close className={classes.icon} />
        </IconButton>
      </div>
      <DialogsConfirm
        acceptCtaCopy="Save"
        body={`Do you want to save changes made to the report "${currentReport.name}"? Your changes will be lost if you don't save them.
        `}
        cancelCtaCopy="Don't Save"
        onCancel={() => setShowCloseConfirm(false)}
        onClose={() => {
          setShowCloseConfirm(false);
          setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
        }}
        onConfirm={() => {
          updateReportWithoutReactiveVarChange(
            {
              ...currentReport,
              milestoneID: currentReport.milestoneID ?? undefined,
              settings: JSON.stringify(removeNullKeys(settings)),
            },
            projectID
          );
          setShowCloseConfirm(false);
          setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
        }}
        open={showCloseConfirm}
        title="Save?"
      />
    </Popper>
  );
};

export default withStyles(styles)(CurrentReportBar);
