import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const Team = (props: IconProps) => (
  <SvgIcon
    {...props}
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 6.68105C2.67002 6.68105 2.67338 6.68106 2.67673 6.68107C2.56233 7.02593 2.5 7.39314 2.5 7.77438V8.34772H0.5V7.80105C0.5 7.46292 0.699238 7.1618 1.01179 7.02665L1.01289 7.02617C1.51911 6.80582 2.07802 6.68105 2.66667 6.68105ZM14.9871 7.02617L14.9882 7.02665C15.3008 7.1618 15.5 7.46292 15.5 7.80105V8.34772H13.5V7.77438C13.5 7.39314 13.4377 7.02593 13.3233 6.68107C13.3266 6.68106 13.33 6.68105 13.3333 6.68105C13.922 6.68105 14.4809 6.80582 14.9871 7.02617ZM4.91438 7.33219L4.64743 8.01438H5.38H10.62H11.3826L11.0785 7.31502C11.0763 7.30977 11.0737 7.30375 11.0709 7.29702C11.0406 7.2247 10.9761 7.07113 10.8176 6.92926C10.6688 6.79615 10.4639 6.69335 10.1953 6.58866C9.48719 6.31133 8.74512 6.18105 8 6.18105C7.25525 6.18105 6.51354 6.31121 5.80576 6.58825C5.5355 6.69228 5.32744 6.79505 5.17744 6.92926C5.01126 7.07795 4.95007 7.2386 4.9207 7.31573C4.91841 7.32174 4.91631 7.32724 4.91438 7.33219ZM3.5 4.18105C3.5 4.63824 3.12386 5.01438 2.66667 5.01438C2.20948 5.01438 1.83333 4.63824 1.83333 4.18105C1.83333 3.72386 2.20948 3.34772 2.66667 3.34772C3.12386 3.34772 3.5 3.72386 3.5 4.18105ZM14.1667 4.18105C14.1667 4.63824 13.7905 5.01438 13.3333 5.01438C12.8761 5.01438 12.5 4.63824 12.5 4.18105C12.5 3.72386 12.8761 3.34772 13.3333 3.34772C13.7905 3.34772 14.1667 3.72386 14.1667 4.18105ZM8 5.84772C9.00842 5.84772 9.89949 6.0828 10.6236 6.40462C11.1574 6.64185 11.5 7.17902 11.5 7.77438V8.34772H4.5V7.77438C4.5 7.17959 4.84199 6.64287 5.37488 6.4053C6.10311 6.0881 6.99449 5.84772 8 5.84772ZM9.16667 2.84772C9.16667 2.20491 8.64281 1.68105 8 1.68105C7.35719 1.68105 6.83333 2.20491 6.83333 2.84772C6.83333 3.49053 7.35719 4.01438 8 4.01438C8.64281 4.01438 9.16667 3.49053 9.16667 2.84772ZM6.5 2.84772C6.5 2.01719 7.16948 1.34772 8 1.34772C8.83052 1.34772 9.5 2.01719 9.5 2.84772C9.5 3.67824 8.83052 4.34772 8 4.34772C7.16948 4.34772 6.5 3.67824 6.5 2.84772Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </SvgIcon>
);
