import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { renderCostString } from '../../CostReport/CostReportUtils';

import styles from './EstimateTotalStyles';

type EstimateTotalProps = {
  classes: Classes<typeof styles>;
  header?: string;
  isEstimate?: boolean;
  cost?: Cost;
};

const EstimateTotal: FC<EstimateTotalProps> = ({
  classes,
  header,
  isEstimate = true,
  cost = { value: '0' },
}) => {
  const showHeaderWhite = !isEstimate;

  return (
    <div
      data-cy="total"
      className={`${classes.totalHeader} ${showHeaderWhite ? classes.totalHeaderWhite : ''} ${
        isEstimate ? '' : classes.target
      }`}
    >
      <Typography
        className={`${classes.total} ${showHeaderWhite ? classes.totalWhite : ''} `}
        variant="subheading"
        data-cy="estimate-subheader-total"
      >
        {header}
      </Typography>
      <div className={classes.spacer} />
      <Typography className={classes.number}>
        {renderCostString({
          cost,
          isWide: true,
          isExact: true,
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(EstimateTotal);
