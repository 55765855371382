import { FC, useState } from 'react';

import { Add } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { Button } from '../../../scales';
import CreateProjectTypeDialog from '../Dialogs/CreateProjectTypeDialog';

import ProjectTypesList from './ProjectTypesList';

type ProjectTypesProps = {
  projectTypes: ProjectType[];
};

const ProjectTypes: FC<ProjectTypesProps> = ({ projectTypes }) => {
  const [isNewProjectTypeDialogOpen, setIsNewProjectTypeDialogOpen] = useState(false);
  const sendAnalytics = useSendAnalyticsEventHook();

  return (
    <div className="flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="sticky flex border-b p-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <div className="flex type-heading2">Project Types</div>
            <div className="flex text-type-muted type-body2">
              Customize your project types that your teammates see in the project settings. We
              provided you a starting point but you can customize to your needs.
            </div>
          </div>
          <div className="flex">
            <Button
              data-cy="button-createNewProjectType"
              label="Create Project Type"
              onClick={() => {
                setIsNewProjectTypeDialogOpen(true);
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_CREATE_CTA)
                );
              }}
              startIcon={<Add />}
              type="primary"
            />
          </div>
          <CreateProjectTypeDialog
            isOpen={isNewProjectTypeDialogOpen}
            onClose={() => setIsNewProjectTypeDialogOpen(false)}
            types={projectTypes}
          />
        </div>
      </div>
      <ProjectTypesList projectTypes={projectTypes} />
    </div>
  );
};

export default ProjectTypes;
