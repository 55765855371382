import { FC, useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import {
  Hint,
  RadialChart,
  // @ts-ignore because the library is not typed
} from 'react-vis/dist';

import { ChartLegendOrientation, PieChartComponentOrientation } from '../../../api/gqlEnums';
import { OTHER_PROJECT_TYPES } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ChartsHintWrapper from '../ChartsHintWrapper/ChartsHintWrapper';
import ChartsLegend from '../ChartsLegend/ChartsLegend';
import ChartsLegendElement from '../ChartsLegend/ChartsLegendElement';
import {
  LegendData,
  LegendElementType,
  getLegendElementColorFunction,
} from '../ChartsLegend/ChartsLegendUtils';

import ChartsPieChartHint from './ChartsPieChartHint/ChartsPieChartHint';
import ChartsPieChartStyles from './ChartsPieChartStyles';
import {
  NA,
  NA_COLOR,
  PIE_CHART_LARGE,
  PIE_CHART_SMALL,
  PieChartData,
  PieChartLegendHintData,
  emptyLegendHintData,
} from './ChartsPieChartUtils';

type ChartsPieChartProps = {
  classes: Classes<typeof ChartsPieChartStyles>;
  data: PieChartData[] | null;
  hintCaption: string;
  isCost?: boolean;
  legendData: LegendData;
  onMouseOver: () => void;
  variant?: OrientationVariant;
};

type OrientationVariant = PieChartComponentOrientation;

const getOrientationClass = (
  variant: OrientationVariant,
  classes: Classes<typeof ChartsPieChartStyles>
) =>
  variant === PieChartComponentOrientation.VERTICAL
    ? classes.blockContainer
    : classes.flexContainer;

const ChartsPieChart: FC<ChartsPieChartProps> = ({
  classes,
  data,
  hintCaption,
  isCost = false,
  legendData,
  onMouseOver,
  variant = PieChartComponentOrientation.HORIZONTAL,
}) => {
  const [value, setValue] = useState<PieChartData | null>(null);
  const orientationClass = getOrientationClass(variant, classes);

  const { colorMap, customColors, legendElements } = legendData;
  const legendHintData: PieChartLegendHintData = {
    hintCaption,
    isCost,
    pieChartData: data || [],
  };

  const hint = useMemo(() => {
    if (!value) return null;

    const hintValue = value.text === OTHER_PROJECT_TYPES ? value.otherData : [value];
    return (
      <Hint
        value={value}
        align={{
          horizontal: Hint.ALIGN.AUTO,
          vertical: Hint.ALIGN.AUTO,
        }}
      >
        <ChartsHintWrapper
          content={
            <ChartsPieChartHint
              data={data}
              hintCaption={hintCaption}
              isCost={isCost}
              value={hintValue || []}
            />
          }
        />
      </Hint>
    );
  }, [value, data, hintCaption, isCost]);

  const getLegendHint = (element: LegendElementType) => {
    const { percentage, text } = element;
    const { hintCaption, isCost, pieChartData } = legendHintData || emptyLegendHintData;

    const percentageElement = pieChartData.find(
      (elem) => elem.text === text && elem.percentage === percentage
    );
    const percentageElementArray = percentageElement ? [percentageElement] : undefined;
    const hintValue =
      text === OTHER_PROJECT_TYPES
        ? pieChartData.find((elem) => elem.text === OTHER_PROJECT_TYPES)?.otherData
        : percentageElementArray;

    return (
      <ChartsPieChartHint
        data={pieChartData}
        hintCaption={hintCaption}
        isCost={isCost}
        value={hintValue || []}
      />
    );
  };

  const getLegendElementColor = getLegendElementColorFunction(colorMap, customColors);

  const smallPieChart = (
    <div className={orientationClass}>
      {data && data.length ? (
        <>
          <RadialChart
            colorType="literal"
            width={PIE_CHART_SMALL}
            height={PIE_CHART_SMALL}
            data={data}
            onValueMouseOver={(v: PieChartData) => setValue(v)}
            onSeriesMouseOut={() => setValue(null)}
            onSeriesMouseOver={onMouseOver}
          >
            {hint}
          </RadialChart>
          <ChartsLegend
            getLegendElementColor={getLegendElementColor}
            getLegendHint={getLegendHint}
            legendElements={legendElements}
            variant={ChartLegendOrientation.VERTICAL}
          />
        </>
      ) : (
        <>
          <div className={classes.placeholderSmall}>
            <Typography className={classes.placeholderText}>{NA}</Typography>
          </div>
          <ChartsLegendElement color={NA_COLOR} element={{ text: NA }} />
        </>
      )}
    </div>
  );

  const largePieChart = (
    <div className={orientationClass}>
      {data && data.length ? (
        <>
          <RadialChart
            colorType="literal"
            width={PIE_CHART_LARGE}
            height={PIE_CHART_LARGE}
            data={data}
            onValueMouseOver={(v: PieChartData) => setValue(v)}
            onSeriesMouseOut={() => setValue(null)}
            onSeriesMouseOver={onMouseOver}
          >
            {hint}
          </RadialChart>
          <ChartsLegend
            getLegendElementColor={getLegendElementColor}
            getLegendHint={getLegendHint}
            legendElements={legendElements}
            variant={ChartLegendOrientation.COLUMNS}
          />
        </>
      ) : (
        <>
          <div className={classes.placeholderLarge}>
            <Typography className={classes.placeholderText}>{NA}</Typography>
          </div>
          <ChartsLegendElement color={NA_COLOR} element={{ text: NA }} />
        </>
      )}
    </div>
  );

  return variant === PieChartComponentOrientation.VERTICAL ? largePieChart : smallPieChart;
};

export default withStyles(ChartsPieChartStyles)(ChartsPieChart);
