import { ElementType, ReactNode, RefObject, useCallback, useState } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

import { withStyles } from '../../theme/komodo-mui-theme';
import CollapseIcon from '../shared-widgets/CollapseIcon';

import styles from './ProjectCompsStyles';

type ProjectCompInformationFieldProps = {
  className: string;
  classNameTypography?: string;
  containerRef?: RefObject<HTMLDivElement>;
  dataCy?: string;
  endAdornment?: ReactNode;
  isCollapsed?: boolean;
  isCollapsible?: boolean;
  showEndAdornment?: boolean;
  toggleIsCollapsed?: () => void;
  typographyComponent?: ElementType<TypographyProps>;
  value: ReactNode;
};

const ProjectCompInformationField = (props: ProjectCompInformationFieldProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);
  const showEndAdornment = props.showEndAdornment ?? isHovered;

  let fieldContent = (
    <Typography component={props.typographyComponent} className={props.classNameTypography ?? ''}>
      {props.value}
    </Typography>
  );

  if (props.isCollapsible) {
    fieldContent = (
      <ButtonBase
        buttonRef={props.containerRef}
        className="flex h-full flex-grow items-center gap-2"
        onClick={props.toggleIsCollapsed}
      >
        <div className="text-base">
          <CollapseIcon fontSize="inherit" isCollapsed={props.isCollapsed} />
        </div>
        {fieldContent}
      </ButtonBase>
    );
  }

  return (
    <div
      className={props.className}
      data-cy={props.dataCy}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={props.containerRef}
    >
      {fieldContent}
      <div className={`ml-auto ${showEndAdornment ? 'visible' : 'invisible'}`}>
        {props.endAdornment}
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectCompInformationField);
