import { FC } from 'react';

import { DateGroup } from '../../../generated/graphql';
import { IconMenuButton } from '../../scales';

type ItemActivityDateSelectProps = {
  handleSelect: (dateGroup: DateGroup) => void;
  currentDateGroup?: DateGroup | null;
};

const ItemActivityDateSelect: FC<ItemActivityDateSelectProps> = ({
  handleSelect,
  currentDateGroup,
}) => {
  const options = [
    { label: 'Today', id: DateGroup.TODAY, onClick: () => handleSelect(DateGroup.TODAY) },
    {
      label: 'Yesterday',
      id: DateGroup.YESTERDAY,
      onClick: () => handleSelect(DateGroup.YESTERDAY),
    },
    {
      label: 'Last Week',
      id: DateGroup.LASTWEEK,
      onClick: () => handleSelect(DateGroup.LASTWEEK),
    },
  ].filter((option) => option.id !== currentDateGroup);

  return (
    <div className="relative inline-block w-10" data-cy="item-activity-date-divider-select">
      <IconMenuButton
        entries={options}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path d="M7.42126 10L12.4213 15L17.4213 10H7.42126Z" fill="black" />
          </svg>
        }
        aria-label="date-select"
        type="secondary"
      />
    </div>
  );
};

export default ItemActivityDateSelect;
