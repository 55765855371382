import { useState } from 'react';

import {
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  PROJECT_COMPANIES,
  PROJECT_LOCATION,
  PROJECT_SEARCH_HEADER,
  PROJECT_STATUS,
  PROJECT_TYPE,
  SEARCH_PROJECT,
} from '../../constants';
import { ProjectType } from '../../generated/graphql';
import { pluralizeCountString } from '../../utilities/string';
import { idsToNames } from '../../utilities/utilities';
import { MAGNITUDE_LIMIT } from '../Inputs/QuantityTextField/QuantityTextField';

export const calculateNumberOfFiltersToShow = (
  selected: string[],
  allowedSpace = 48,
  chipOverHead = 2
) => {
  let numberToShow = 0;
  let spaceUsed = 0;
  selected.forEach((s) => {
    spaceUsed += chipOverHead + Math.min(s.length, 20);
    if (spaceUsed > allowedSpace) return;
    numberToShow += 1;
  });
  return numberToShow;
};

const PROJECT_FILTER_TYPES = [
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
];

const RANGE_MIN_VALUE = -1;
const RANGE_MAX_VALUE = MAGNITUDE_LIMIT;
const RANGE_MIN_CONST = 'RANGE_MIN';
const RANGE_MAX_CONST = 'RANGE_MAX';

export type ProjectFilter = {
  value: string;
  values?: string[];
  type: string;
};

type ProjectFilterOptions = {
  companies: string[];
  locations: string[];
  statuses: string[];
  types: string[] | ProjectType[];
};

export type ProjectFilterState = {
  companies: string[];
  locations: string[];
  statuses: string[];
  types: string[];
};

export type ProjectFilterSetSettings = (settings: Record<string, string[]>) => void;

export type ProjectFilterManager = {
  clearFilters: (setSettings: ProjectFilterSetSettings) => void;
  clearFilterType: (type: string, setSettings: ProjectFilterSetSettings) => void;
  filterOptions: ProjectFilterOptions | undefined;
  filterState: ProjectFilterState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setFilter: (input: ProjectFilter, setSettings: ProjectFilterSetSettings, page?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setFilters: (input: ProjectFilter, setSettings: ProjectFilterSetSettings, page?: any) => void;
};

export type ProjectSearchManager = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  clearSearch: () => void;
};

const getEmptyFilterValue = (filterType: string) => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return { types: [] };
    case FILTER_PROJECT_LOCATION:
      return { locations: [] };
    case FILTER_PROJECT_COMPANIES:
      return { companies: [] };
    case FILTER_PROJECT_STATUS:
      return { statuses: [] };
    default:
      return {};
  }
};

const getEmptyFilter = (filterState: ProjectFilterState, filterType: string) => ({
  ...filterState,
  ...getEmptyFilterValue(filterType),
});

const getEmptyFilterState = (): ProjectFilterState => ({
  types: [],
  locations: [],
  companies: [],
  statuses: [],
});

export const projectFilterStateNumFilters = (filters: ProjectFilterState, searchTerm: string) =>
  filters.types.length +
  filters.companies.length +
  filters.locations.length +
  filters.statuses.length +
  (searchTerm !== '' ? 1 : 0);

export const projectFilterStateHasFilters = (filters: ProjectFilterState, searchTerm = '') =>
  !!filters.types.length ||
  !!filters.companies.length ||
  !!filters.locations.length ||
  !!filters.statuses.length ||
  searchTerm !== '';

export const getSelectedForFilterType = (
  filterManager: ProjectFilterManager,
  filterType: string
) => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return filterManager.filterState.types;
    case FILTER_PROJECT_LOCATION:
      return filterManager.filterState.locations;
    case FILTER_PROJECT_STATUS:
      return filterManager.filterState.statuses;
    case FILTER_PROJECT_COMPANIES:
      return filterManager.filterState.companies;
    default:
      return [];
  }
};

export const getOptionsForFilterType = (
  filterManager: ProjectFilterManager,
  filterType: string
) => {
  const {
    filterOptions = {
      locations: [],
      statuses: [],
      companies: [],
      types: [],
    },
  } = filterManager;
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return filterOptions.types;
    case FILTER_PROJECT_LOCATION:
      return filterOptions.locations;
    case FILTER_PROJECT_STATUS:
      return filterOptions.statuses;
    case FILTER_PROJECT_COMPANIES:
      return filterOptions.companies;
    default:
      return [];
  }
};

export const getTitleForFilterType = (filterType: string) => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return PROJECT_TYPE;
    case FILTER_PROJECT_LOCATION:
      return PROJECT_LOCATION;
    case FILTER_PROJECT_COMPANIES:
      return PROJECT_COMPANIES;
    case FILTER_PROJECT_STATUS:
      return PROJECT_STATUS;
    case SEARCH_PROJECT:
      return PROJECT_SEARCH_HEADER;
    default:
      return '';
  }
};

// FILTER FUNCTIONALITY
const toggleString = (arr: string[], input: string) => {
  const hasFilter = arr.some((str) => str.toLowerCase() === input.toLowerCase());
  if (hasFilter) return arr.filter((str) => str.toLowerCase() !== input.toLowerCase());
  return [...arr, input];
};

const toggleFilter = (
  filterState: ProjectFilterState,
  input: ProjectFilter
): ProjectFilterState => {
  switch (input.type) {
    case FILTER_PROJECT_TYPE:
      return {
        ...filterState,
        types: toggleString(filterState.types, input.value as string),
      };
    case FILTER_PROJECT_LOCATION:
      return {
        ...filterState,
        locations: toggleString(filterState.locations, input.value as string),
      };
    case FILTER_PROJECT_STATUS:
      return {
        ...filterState,
        statuses: toggleString(filterState.statuses, input.value as string),
      };
    case FILTER_PROJECT_COMPANIES:
      return {
        ...filterState,
        companies: toggleString(filterState.companies, input.value as string),
      };
    default:
      return filterState;
  }
};

const setStrings = (input: string[]) => [...input];

const applyFilter = (filterState: ProjectFilterState, input: ProjectFilter): ProjectFilterState => {
  switch (input.type) {
    case FILTER_PROJECT_TYPE:
      return {
        ...filterState,
        types: setStrings(input.values ?? []),
      };
    default:
      return filterState;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getEmptyProjectRangeFilterKeys = (settings: any) => {
  const keys = Object.keys(settings);
  return (keys || []).filter((key) => {
    const val = settings[key];
    if (val.length === 2)
      return (
        (val[0] === RANGE_MIN_VALUE || val[0] === RANGE_MIN_CONST) &&
        (val[1] === RANGE_MAX_VALUE || val[1] === RANGE_MAX_CONST)
      );
    return false;
  });
};

// FILTER SUMMARY

type FilterSummaryList = {
  type: string;
  selected: string[];
};

type FilterCollapseState = {
  ProjectCompanies: boolean;
  ProjectType: boolean;
  ProjectLocation: boolean;
  ProjectStatus: boolean;
};

export const generateFilterTooltipCopy = (
  filterState: ProjectFilterState,
  projectTypes: ProjectType[]
): string[] => {
  const { types: typesOuter, locations, companies, statuses } = filterState;
  const types = idsToNames([...typesOuter], projectTypes || []);
  const copy = [...statuses, ...types, ...locations, ...companies];
  return copy;
};

const mapTypeToSelected = (filterState: ProjectFilterState) => {
  const { types, locations, companies, statuses } = filterState;
  return [
    { type: FILTER_PROJECT_TYPE, selected: types },
    { type: FILTER_PROJECT_LOCATION, selected: locations },
    { type: FILTER_PROJECT_COMPANIES, selected: companies },
    { type: FILTER_PROJECT_STATUS, selected: statuses },
  ];
};

const getCollapsedString = (filterState: ProjectFilterState, filterType: string) => {
  const { types, locations, companies, statuses } = filterState;
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return pluralizeCountString('Project type', types.length);
    case FILTER_PROJECT_LOCATION:
      return pluralizeCountString('Location', locations.length);
    case FILTER_PROJECT_COMPANIES:
      return pluralizeCountString('Company filter', companies.length);
    case FILTER_PROJECT_STATUS:
      return pluralizeCountString('Status', statuses.length);
    default:
      return '';
  }
};

const formatFilterLabel = (text: string, includeLabel: boolean) =>
  includeLabel ? `${text}: ` : '';

const getSummaryStringForFilterType = (
  filterState: ProjectFilterState,
  filterType: string,
  includeLabels: boolean
) => {
  const { types, locations, companies, statuses } = filterState;
  switch (filterType) {
    case FILTER_PROJECT_TYPE: {
      const label = formatFilterLabel(PROJECT_TYPE, includeLabels);
      return types.length > 0 ? label + types.join(', ') : '';
    }
    case FILTER_PROJECT_LOCATION: {
      const label = formatFilterLabel(PROJECT_LOCATION, includeLabels);
      return locations.length > 0 ? label + locations.join(', ') : '';
    }
    case FILTER_PROJECT_STATUS: {
      const label = formatFilterLabel(PROJECT_STATUS, includeLabels);
      return statuses.length > 0 ? label + statuses.join(', ') : '';
    }
    case FILTER_PROJECT_COMPANIES:
      return companies.length > 0 ? companies.join(', ') : '';
    default:
      return '';
  }
};

const filterIsCollapsed = (filterCollapseState: FilterCollapseState, filterType: string) => {
  const { ProjectCompanies, ProjectType, ProjectLocation, ProjectStatus } = filterCollapseState;
  switch (filterType) {
    case FILTER_PROJECT_COMPANIES:
      return ProjectCompanies;
    case FILTER_PROJECT_TYPE:
      return ProjectType;
    case FILTER_PROJECT_LOCATION:
      return ProjectLocation;
    case FILTER_PROJECT_STATUS:
      return ProjectStatus;
    default:
      return '';
  }
};

const allFiltersCollapsed = (filterCollapseState: FilterCollapseState) => {
  const { ProjectType, ProjectLocation, ProjectCompanies } = filterCollapseState;
  return ProjectType && ProjectLocation && ProjectCompanies;
};

const setFilterCollapsed = (filterCollapseState: FilterCollapseState, filterType: string) => {
  const newCollapseState = filterCollapseState;
  switch (filterType) {
    case FILTER_PROJECT_COMPANIES:
      newCollapseState.ProjectCompanies = true;
      return newCollapseState;
    case FILTER_PROJECT_TYPE:
      newCollapseState.ProjectType = true;
      return newCollapseState;
    case FILTER_PROJECT_LOCATION:
      newCollapseState.ProjectLocation = true;
      return newCollapseState;
    case FILTER_PROJECT_STATUS:
      newCollapseState.ProjectStatus = true;
      return newCollapseState;
    default:
      return {
        ProjectCompanies: false,
        ProjectType: false,
        ProjectLocation: false,
        ProjectStatus: false,
      };
  }
};

const getCurrentFilterSummaryString = (
  type: string,
  filterState: ProjectFilterState,
  filterCollapseState: FilterCollapseState,
  includeLabels: boolean
) =>
  filterIsCollapsed(filterCollapseState, type)
    ? getCollapsedString(filterState, type)
    : getSummaryStringForFilterType(filterState, type, includeLabels);

const getFilterSummaryTotalLength = (
  filterState: ProjectFilterState,
  filterCollapseState: FilterCollapseState,
  includeLabels: boolean
) => {
  const [companyLength, locationLength, statusLength, typeLength] = PROJECT_FILTER_TYPES.map(
    (filterType) =>
      getCurrentFilterSummaryString(filterType, filterState, filterCollapseState, includeLabels)
        .length
  );

  return companyLength + locationLength + statusLength + typeLength;
};

const setLongestFilterCollapsed = (
  listFilters: FilterSummaryList[],
  filterCollapseState: FilterCollapseState
) => {
  const longestList = listFilters.find((filter) => {
    const maxLength = Math.max(
      ...listFilters.map((el) =>
        !filterIsCollapsed(filterCollapseState, el.type) ? el.selected.length : 0
      )
    );
    return (
      filter.selected.length === maxLength && !filterIsCollapsed(filterCollapseState, filter.type)
    );
  });

  if (!longestList) {
    return filterCollapseState;
  }
  const collapsedType = longestList && longestList.type;
  const newFilterCollapseState = setFilterCollapsed(filterCollapseState, collapsedType);
  return newFilterCollapseState;
};

const getFilterStrings = (
  filterState: ProjectFilterState,
  filterCollapseState: FilterCollapseState,
  isVerbose: boolean
) => {
  const [companiesString, locationString, statusString, typeString] = PROJECT_FILTER_TYPES.map(
    (filterType) =>
      getCurrentFilterSummaryString(filterType, filterState, filterCollapseState, isVerbose)
  );

  const filterStrings = [];
  if (companiesString !== '') filterStrings.push(companiesString);
  if (locationString !== '') filterStrings.push(locationString);
  if (statusString !== '') filterStrings.push(statusString);
  if (typeString !== '') filterStrings.push(typeString);
  return filterStrings;
};

export const getFilterSummaryString = (
  filterState: ProjectFilterState,
  width: number,
  isVerbose: boolean
) => {
  const widthLimit = 1200;
  const charLimit = 70;

  const numFilters = projectFilterStateNumFilters(filterState, '');
  if (width < widthLimit && numFilters > 0) return pluralizeCountString('filter', numFilters);

  const listFilters = mapTypeToSelected(filterState);

  let filterCollapseState = {
    ProjectCompanies: false,
    ProjectLocation: false,
    ProjectStatus: false,
    ProjectType: false,
  };

  let totalLength = getFilterSummaryTotalLength(filterState, filterCollapseState, isVerbose);
  while (totalLength > charLimit) {
    const newFilterCollapseState = setLongestFilterCollapsed(listFilters, filterCollapseState);
    filterCollapseState = newFilterCollapseState;
    totalLength = getFilterSummaryTotalLength(filterState, filterCollapseState, isVerbose);
    if (allFiltersCollapsed(filterCollapseState)) break;
  }
  return getFilterStrings(filterState, filterCollapseState, isVerbose).join(' \u00B7 ');
};

export const useFilterProjects = (
  settings: ProjectFilterState,
  onSetFilter?: (input: ProjectFilter) => void,
  onClearFilters?: () => void,
  onClearFilterType?: () => void
): ProjectFilterManager => {
  // FILTER HOOKS

  const [filterState, setFilterState] = useState<ProjectFilterState>(settings);
  const setFilter = (input: ProjectFilter, setSettings: ProjectFilterSetSettings) => {
    const newFilter = toggleFilter(filterState, input);
    setFilterState(newFilter);
    setSettings(newFilter);
    onSetFilter?.(input);
  };
  const setFilters = (input: ProjectFilter, setSettings: ProjectFilterSetSettings) => {
    const newFilter = applyFilter(filterState, input);
    setFilterState(newFilter);
    setSettings(newFilter);
    onSetFilter?.(input);
  };
  const clearFilters = (setSettings: ProjectFilterSetSettings) => {
    const emptyFilterState = getEmptyFilterState();
    setFilterState(emptyFilterState);
    setSettings(emptyFilterState);
    onClearFilters?.();
  };
  const clearFilterType = (filterType: string, setSettings: ProjectFilterSetSettings) => {
    setFilterState(getEmptyFilter(filterState, filterType));
    setSettings(getEmptyFilter(filterState, filterType));
    onClearFilterType?.();
  };

  return {
    clearFilters,
    clearFilterType,
    filterState,
    filterOptions: undefined,
    setFilter,
    setFilters,
  };
};
