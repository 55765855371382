/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, ReactNode } from 'react';

import { Collapse } from '@material-ui/core';

import useAnalyticsEventHook, { EventProperties } from '../../../hooks/useAnalyticsEventHook';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import ItemDetailsCollapseStyles from './ItemDetailsCollapseStyles';

type ItemDetailsCollapseProps = {
  analyticsEvent?: { type: string; eventProperties: EventProperties };
  canExpand: boolean;
  dataCy?: string;
  classes: Classes<typeof ItemDetailsCollapseStyles>;
  children: ReactNode;
  hasGrayBackground?: boolean;
  headerContent: JSX.Element;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  style?: { [key: string]: string | number };
};

const ItemDetailsCollapse: FC<ItemDetailsCollapseProps> = ({
  analyticsEvent,
  dataCy = 'itemEstimate',
  classes,
  canExpand = false,
  children,
  headerContent,
  hasGrayBackground = true,
  isExpanded,
  setIsExpanded,
  style,
}) => {
  const sendAnalytics = useAnalyticsEventHook();

  const collapseChildren = (
    <div
      className={`${classes.rowContainer} ${
        hasGrayBackground ? classes.cardHeaderBackground : ''
      } ${classes.cardHeader}`}
    >
      {canExpand && <CollapseIcon className={classes.expander} isCollapsed={!isExpanded} />}
      {headerContent}
    </div>
  );

  return (
    <div className={classes.card}>
      <div
        data-cy={`collapse-${dataCy}`}
        onClick={() => {
          if (canExpand) {
            setIsExpanded(!isExpanded);
            if (analyticsEvent) sendAnalytics(analyticsEvent);
          }
        }}
        aria-disabled={!canExpand}
        style={style}
      >
        <Collapse
          data-cy="button-addOption"
          collapsedHeight={(theme.spacing.unit * 10).toString()}
          classes={{ container: classes.collapse }}
          in={isExpanded}
        >
          {collapseChildren}
        </Collapse>
      </div>
      {canExpand && (
        <Collapse in={isExpanded} unmountOnExit>
          {children}
        </Collapse>
      )}
    </div>
  );
};

export default withStyles(ItemDetailsCollapseStyles)(ItemDetailsCollapse);
