import { useMutation } from '@apollo/client';

import { PUBLISH_DRAFT_MILESTONE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  SetMilestonePublishDraftMutation,
  SetMilestonePublishDraftMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { setMilestonePublishDraftMutation } from './queries';

export const useUpdateMilestonePublishDraft = () => {
  const sendAnalytics = useAnalyticsEventHook();
  const [publishDraftMilestoneFunc] = useMutation<
    SetMilestonePublishDraftMutation,
    SetMilestonePublishDraftMutationVariables
  >(setMilestonePublishDraftMutation);
  return (projectID: UUID, milestoneID: UUID, isDraft = false) =>
    publishDraftMilestoneFunc({
      variables: { projectID, input: { milestoneID, isDraft } },
    }).then(() => {
      sendAnalytics(analyticsEvent(PUBLISH_DRAFT_MILESTONE));
    });
};
