import { FC } from 'react';

import { OPEN_ITEMS_DUE } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useListItemDueDatesQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  ITEM_DUE_DATE_HEIGHT,
  OPEN_ITEMS_DUE_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardItemDueDate from './ExecutiveDashboardItemDueDate';

const ExecutiveDashboardItemDueDateData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
  projectMap,
}) => {
  const { data: { itemDueDates } = { itemDueDates: [] }, loading } =
    useListItemDueDatesQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <div onMouseOver={onHover} onFocus={onHover}>
            <ExecutiveDashboardItemDueDate itemDueDates={itemDueDates} projectMap={projectMap} />
          </div>
        )
      }
      height={ITEM_DUE_DATE_HEIGHT}
      title={OPEN_ITEMS_DUE}
      variant={CHART_LARGE}
      tooltip={OPEN_ITEMS_DUE_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardItemDueDateData;
