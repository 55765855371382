import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const TOTAL_NUMBER_PAD_RIGHT = 32;
export const ITEM_DETAILS_MAX_HEIGHT = 400;

export default (theme: KomodoTheme) =>
  createStyles({
    border: {
      marginBottom: 16,
      border: `2px solid ${theme.palette.backgroundGrey}`,
    },
    borderlessPadding: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
    },
    budgetButton: {
      marginLeft: theme.spacing.unit * 4,
      backgroundColor: theme.palette.budget,
    },
    button: {
      marginLeft: theme.spacing.unit * 4,
    },
    textButton: {
      alignItems: 'end',
      fontWeight: 500,
    },
    customHeader: {
      paddingLeft: theme.spacing.unit * 3,
    },
    expandedContentWidget: {
      borderWidth: 2,
    },
    costEstimate: {
      border: `1px solid ${theme.palette.backgroundGrey}`,
    },
    expander: {
      color: theme.palette.joinPrimary,
    },
    expandButton: {
      paddingLeft: theme.spacing.unit * 2,
      flex: '0 0 auto',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    number: {
      ...theme.typography.number,
      paddingLeft: theme.spacing.unit * 4,
    },
    sectionHeader: {
      padding: '4px 12px',
      color: theme.palette.primaryGrey,
      backgroundColor: theme.palette.backgroundGrey,
      justifyContent: 'left',
      alignItems: 'center',
      display: 'flex',
    },
    spacer: {
      flexGrow: 1,
    },
    total: {
      display: 'flex',
      paddingLeft: 34,
    },
    totalWhite: {
      paddingLeft: 36,
      color: theme.palette.backgroundWhite,
    },
    totalHeader: {
      padding: 8,
      paddingRight: TOTAL_NUMBER_PAD_RIGHT - 2,
      backgroundColor: theme.palette.backgroundGrey,
      display: 'flex',
      borderTop: theme.border.lineBorderBlack,
    },
    totalHeaderWhite: {
      backgroundColor: theme.palette.primaryGrey,
      color: theme.palette.backgroundWhite,
      borderTop: 'unset',
    },
    target: {
      backgroundColor: theme.palette.budget,
    },
    budget: {
      color: `${theme.palette.budget} !important`,
      minWidth: 80,
    },
    name: { minWidth: 80 },
  });
