import { FC, useContext } from 'react';

import { ButtonBase, Radio, TableCell, TableRow, Typography } from '@material-ui/core';
import {
  RadioButtonUnchecked as RadioIcon,
  RadioButtonChecked as RadioIconChecked,
} from '@material-ui/icons';

import { CANNOT_VIEW, NONE } from '../../../constants';
import {
  DD_PERMISSION_ROLES_CREATE_DELETE,
  NS_PROCORE_CHANGE_EVENT_INTEGRATION,
  NS_REPORT_DISTRIBUTION,
  YC_ITEM_SHARING,
  YC_SCENARIO_SHARING,
} from '../../../features';
import { PermissionResource } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getTextMapForPermissions } from '../../../utilities/permissions/permissions';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import oldTextMap from '../textMapForOptions';

import styles from './PermissionsTableRowStyles';

const cellWidth = ['15%', '15%', '25%', '25%'];

type PermissionsTableRowProps = {
  classes: Classes<typeof styles>;
  disabled?: boolean;
  editable: boolean;
  group: PermissionGroup;
  label?: string;
  onChange: (id: UUID, { level }: { level: PermissionLevel }, permission?: string) => void;
  permission: Permission;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
};

const PermissionsTableRow: FC<PermissionsTableRowProps> = ({
  classes,
  disabled = false,
  editable,
  group,
  label,
  onChange,
  permission,
  settings,
}) => {
  const t = useContext(ProjectTermStore);
  const textMap = getTextMapForPermissions(t);
  const isIntegrationsEnabled = useHasFeature(NS_PROCORE_CHANGE_EVENT_INTEGRATION);
  const isItemSharingFeature = useHasFeature(YC_ITEM_SHARING);
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);
  const hasReportDistributionAccess = useHasFeature(NS_REPORT_DISTRIBUTION);
  const isPermissionRolesCreateDeleteFeature = useHasFeature(DD_PERMISSION_ROLES_CREATE_DELETE);

  // Here's where we remove unused permission rows
  if (
    permission &&
    [
      PermissionResource.PROJECT_PRIORITIES,
      PermissionResource.ITEM_LIST,
      PermissionResource.ITEM_PRIORITIES,
      PermissionResource.MEETINGS,
      PermissionResource.SHARE_DRAFT_ITEMS,
      PermissionResource.PUBLISH_DRAFT_ITEMS,
      ...(isIntegrationsEnabled ? [] : [PermissionResource.ITEM_INTEGRATIONS]),
      ...(isItemSharingFeature ? [] : [PermissionResource.SHARE_ITEMS_SCENARIOS]),
      ...(isItemSharingFeature ? [] : [PermissionResource.PUBLISH_ITEMS_SCENARIOS]),
      ...(hasReportDistributionAccess ? [] : [PermissionResource.REPORT_DISTRIBUTION]),
    ].includes(permission.resource)
  )
    return null;

  const labelText =
    label ||
    textMap(permission.resource, isScenarioSharingFeature, isPermissionRolesCreateDeleteFeature) ||
    permission.resource;

  return editable ? (
    <TableRow className={`${disabled && classes.disabled} ${classes.row}`}>
      <TableCell className={classes.leftCell}>{labelText}</TableCell>
      {settings &&
        settings.labels &&
        settings.resource &&
        permission.resource &&
        settings.resource[permission.resource] &&
        settings.resource[permission.resource].map((s: string, i: number) => {
          const checked = disabled ? s === CANNOT_VIEW : permission.level === settings.labels[i];
          const cellContent = s ? (
            <ButtonBase
              data-cy={`${permission.resource}-${group.type}-${s}`}
              className={classes.button}
              disabled={disabled}
              onClick={() => onChange(permission.id, { level: settings.labels[i] }, labelText)}
            >
              <Radio
                className={classes.smallRadio}
                icon={<RadioIcon className={classes.smallRadio} />}
                checkedIcon={<RadioIconChecked className={classes.smallRadio} />}
                checked={checked}
                value={settings.labels[i]}
                aria-label={settings.labels[i]}
              />
              <Typography className={classes.tableText}>
                {textMap(s, isScenarioSharingFeature, isPermissionRolesCreateDeleteFeature) ||
                  oldTextMap[s] ||
                  s}
              </Typography>
            </ButtonBase>
          ) : (
            <Typography className={classes.na}>N/A</Typography>
          );

          return (
            <TableCell
              style={{ width: cellWidth[i] }}
              key={settings.resource + settings.labels[i]}
              className={s ? classes.editCell : classes.disableCell}
            >
              {cellContent}
            </TableCell>
          );
        })}
    </TableRow>
  ) : (
    <TableRow className={classes.row}>
      <TableCell
        className={classes.leftCell}
        data-cy={`${permission.resource}-${group.type}-${permission.level}`}
      >
        {label ||
          textMap(
            permission.resource,
            isScenarioSharingFeature,
            isPermissionRolesCreateDeleteFeature
          ) ||
          permission.resource}
      </TableCell>
      <TableCell
        data-cy={`${permission.resource}-${group.type}-${permission.level}`}
        className={classes.cell}
      >
        {disabled
          ? textMap(NONE)
          : textMap(
              permission.level,
              isScenarioSharingFeature,
              isPermissionRolesCreateDeleteFeature
            ) || permission.level}
      </TableCell>
      <TableCell
        data-cy={`${permission.resource}-${group.type}-${permission.level}`}
        className={classes.wideCell}
      >
        {disabled
          ? textMap(CANNOT_VIEW)
          : textMap(
              permission.description,
              isScenarioSharingFeature,
              isPermissionRolesCreateDeleteFeature
            ) ||
            oldTextMap[permission.description] ||
            permission.description}
      </TableCell>
    </TableRow>
  );
};

export const PermissionsTableRowStyled = withStyles(styles)(PermissionsTableRow);

export default PermissionsTableRowStyled;
