import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Popper, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { TILE_SMALL_WIDTH } from '../../ExecutiveDashboard/ExecutiveDashboardUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ChartsHintWrapper from '../ChartsHintWrapper/ChartsHintWrapper';

import ChartsLegendElementStyles, { LEGEND_PADDING } from './ChartsLegendElementStyles';
import { LegendElementType } from './ChartsLegendUtils';

type ChartsLegendElementProps = {
  borderStyle?: string;
  classes: Classes<typeof ChartsLegendElementStyles>;
  color: string;
  hint?: JSX.Element;
  isNarrow?: boolean;
  element: LegendElementType;
  pointerEntered?: (projectID: UUID) => void;
  pointerLeave?: () => void;
  selected?: UUID;
  maxTextWidth?: number;
};

const ChartsLegendElement: FC<ChartsLegendElementProps> = ({
  borderStyle,
  classes,
  color,
  hint,
  isNarrow = false,
  element,
  pointerEntered,
  pointerLeave,
  selected,
  maxTextWidth = TILE_SMALL_WIDTH / 2 - 2 * LEGEND_PADDING - 10,
}) => {
  const hintRef = useRef<HTMLDivElement>(null);
  const [open, setHintOpen] = useState(false);

  const { component, percentage, text, title, link, projectID } = element;

  const hintComponent = hint && (
    <Popper
      open={open}
      anchorEl={hintRef.current}
      placement="bottom"
      style={{
        zIndex: 100,
        pointerEvents: 'none',
      }}
    >
      <ChartsHintWrapper content={hint} />
    </Popper>
  );

  const legendColorBlock = (background: string) =>
    borderStyle ? (
      <div
        className={classes.legendLine}
        style={{
          borderTop: borderStyle,
        }}
      />
    ) : (
      <div
        className={classes.legendBox}
        style={{
          background,
        }}
      />
    );

  const legendIcon = !component ? (
    legendColorBlock(color)
  ) : (
    <div className={classes.legendComponent}>{component}</div>
  );

  const isSelected = selected === projectID;
  const boldLabel = isSelected ? classes.boldText : classes.greyText;
  const linkHover = isSelected ? `${classes.linkTextHover}` : `${classes.linkText}`;
  const legendHover = pointerEntered ? boldLabel : '';

  const tooltipTitle = !hint && projectID ? title : '';

  return (
    <div
      className={classes.legendElement}
      key={text}
      ref={hintRef}
      onMouseEnter={() => {
        setHintOpen(true);
        if (pointerEntered && projectID) {
          pointerEntered(projectID);
        }
      }}
      onMouseLeave={() => {
        setHintOpen(false);
        if (pointerLeave) {
          pointerLeave();
        }
      }}
    >
      {hint && <>{hintComponent}</>}
      {legendIcon}
      <div>
        {link === undefined ? (
          <>
            <Typography
              className={`${classes.legendText} ${isNarrow && classes.legendTextNarrow}`}
              style={{ maxWidth: maxTextWidth }}
            >
              {percentage ? (
                <span className={classes.legendPercentPadding}>{percentage}</span>
              ) : (
                <span className={classes.legendNoPercentPadding} />
              )}
              <NormalTooltip title={tooltipTitle}>
                <span
                  className={`${classes.legendTextPadding} ${classes.legendPercentPadding} ${legendHover}`}
                  data-cy="LegendText"
                >
                  {text}
                </span>
              </NormalTooltip>
            </Typography>
          </>
        ) : (
          <NormalTooltip title={tooltipTitle}>
            <Link
              className={` ${linkHover} ${classes.legendText} ${classes.legendTextNarrow}`}
              to={link}
            >
              <span data-cy="LegendText">{text}</span>
            </Link>
          </NormalTooltip>
        )}
      </div>
    </div>
  );
};

export default withStyles(ChartsLegendElementStyles)(ChartsLegendElement);
