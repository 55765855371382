import { KeyboardEvent, RefObject } from 'react';
import * as React from 'react';

import { UserReportCommentInput } from '../../../../../generated/graphql';
import { UserReportComment } from '../../../../ReportsTab/ReportHooks';

export const setIsAddingNoteClick = (
  e: React.KeyboardEvent | React.MouseEvent,
  isAddingOrEditingNote: boolean,
  isAddingNoteInRow: boolean,
  isPrint: boolean,
  setIsAddingNote: (value: boolean) => void,
  ref: RefObject<HTMLDivElement>
) => {
  if (isPrint) return;
  e?.stopPropagation(); // don't expand the report line
  e?.preventDefault();
  setIsAddingNote(isAddingOrEditingNote);
  if (isAddingNoteInRow) {
    // make sure the full expanded note cell is visible
    ref.current?.scrollIntoView({ block: 'nearest', inline: 'end' });
  }
};

export const calcCommentLineCostsChanged = (
  userCommentWithCosts: Pick<UserReportComment, 'comment' | 'costs'> | undefined,
  userReportCommentInput: Omit<UserReportCommentInput, 'text'> | undefined
) => {
  if (!userReportCommentInput || !userCommentWithCosts) return false;
  return !userCommentWithCosts?.costs?.every(
    (c, i) => Number(c) === Number(userReportCommentInput.costs[i])
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getMentionInput = (mentions: any[] | null | undefined) =>
  mentions?.map((m) => {
    return { position: m.position, text: m.text, userID: m.user.id };
  }) || [];

// Stop editing if the user hits escape
export const handleNavigation = (
  event: KeyboardEvent,
  setIsAddingNote: (value: boolean) => void
) => {
  const { key } = event;
  if (key === 'Escape') setIsAddingNote(false);
};
