import { Checkbox, MenuItem, Typography } from '@material-ui/core';
import { ErrorOutline as Error } from '@material-ui/icons';

import {
  CLEAR_REFERENCES,
  COST_TYPES,
  COST_TYPES_DISPLAY,
  RESOLVE_ERRORS,
  S1,
  S2,
  SELECT_ALL,
  TOTAL_REF,
} from '../../../constants';
import { CostType, EstimateTotalType } from '../../../generated/graphql';
import { CELL_REFERENCE_SELECTION } from '../../../tagConstants';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import { getReferencesMenuSubtotal } from './ReferencesMenuSubtotal';
import { getReferencesSubMenuItem } from './ReferencesSubMenuItem';
import {
  checkValidMarkupReferences,
  costTypeColors,
  getDirectCostSubtotalText,
  getMarkupSubtotalText,
  getReferenceValue,
  isMarkupChecked,
  markupText,
  markupTitle,
  updateReferencesAndCostFilters,
} from './utils';

// Note:
// Material-ui doesn't let us use a functional component to wrap
// a MenuItem component.  If we wrap it then the MenuItems aren't interactive
export const generateReferencesMenuItems = (
  classes: Classes<typeof InputsSelectReferenceStyles>,
  isFixedMarkup: boolean,
  markups: SelectableMarkup[],
  s1RefShouldIncludeS2: boolean,
  selectedCostTypes: CostType[],
  setSelectedCostTypes: (costTypes: CostType[]) => void,
  selectedReferences: string[],
  setSelectedReferences: (refs: string[]) => void,
  setNewErrorState: (err?: string) => void,
  termStore: TermStore,
  totalType?: EstimateTotalType,
  isItem = false,
  newError?: string
) => {
  // VARIABLES
  const hasMarkups = markups.length > 0;
  const hasReferences = selectedReferences.length > 0;

  // checkbox Logic
  const validMarkupRefs = selectedReferences.filter((r) => markups.find((m) => m.id === r));
  const isReferencingTotal = selectedReferences.includes(TOTAL_REF);

  const allCostTypesSelected = selectedCostTypes.length === COST_TYPES.length;
  const noCostTypesSelected = !selectedCostTypes.length;
  const isS1Checked = selectedReferences.includes(S1);
  const isEveryS1CostTypeChecked =
    isMarkupChecked(S1, selectedReferences, COST_TYPES.length) &&
    (allCostTypesSelected || noCostTypesSelected);

  const isS1FullyChecked =
    isFixedMarkup || isReferencingTotal || (isEveryS1CostTypeChecked && isS1Checked);

  const isS2Checked = validMarkupRefs.length === markups.length || isReferencingTotal;
  const totalChecked = isReferencingTotal || (isS1FullyChecked && isS2Checked);

  const costTypeIsChecked = (costType: CostType) => selectedCostTypes.includes(costType);

  const directCostSubtotalText = getDirectCostSubtotalText(termStore, s1RefShouldIncludeS2);
  const markupSubtotalText = getMarkupSubtotalText(termStore, s1RefShouldIncludeS2);

  const onClick = (newRef: string) => {
    // To make this easier to manage we added a prefix `ADDED_` or `REMOVED_` to the newRef
    // value the user clicked on to know when a user is adding or removing a reference clicking.
    updateReferencesAndCostFilters(
      newRef,
      markups,
      selectedReferences,
      setSelectedReferences,
      selectedCostTypes,
      setSelectedCostTypes
    );
  };

  // Clear & Subtotal option
  const selectAllOrClearAllKey = selectedReferences.length ? CLEAR_REFERENCES : SELECT_ALL;
  const menuOptions = [
    <MenuItem
      className={classes.selectHeader}
      value={selectAllOrClearAllKey}
      key={selectAllOrClearAllKey}
      onClick={() => onClick(selectAllOrClearAllKey)}
    >
      <div className={classes.selectHeaderOverflow}>Apply to: </div>
      {!isFixedMarkup && (
        <Typography className={classes.clearOrSelectAll}>
          {hasReferences ? 'Clear' : 'Select All'}
        </Typography>
      )}
    </MenuItem>,
    getReferencesMenuSubtotal({
      // S1 (direct cost) Subtotal
      classes,
      key: S1,
      text: directCostSubtotalText,
      isChecked: isS1FullyChecked,
      isUnchecked: !selectedCostTypes.length,
      value: getReferenceValue(S1, isS1FullyChecked),
      onClick,
    }),
  ];

  // costType / LEMSOU references
  if (totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES && !isFixedMarkup) {
    menuOptions.push(
      ...COST_TYPES.map((costType) => {
        const backgroundColor = costTypeColors.get(costType);
        const text = COST_TYPES_DISPLAY.get(costType) || '';
        const isChecked = costTypeIsChecked(costType) || isReferencingTotal;
        const value = getReferenceValue(costType, isChecked);
        return getReferencesSubMenuItem({
          classes,
          backgroundColor,
          id: costType,
          key: costType,
          isChecked: costTypeIsChecked(costType) || isReferencingTotal || isEveryS1CostTypeChecked,
          text,
          title: text,
          value,
          onClick,
        });
      })
    );
  }

  // S2 (markup) subtotal
  if (!isFixedMarkup && hasMarkups) {
    const value = getReferenceValue(S2, isS2Checked);
    menuOptions.push(
      getReferencesMenuSubtotal({
        classes,
        key: S2,
        includeTopBorder: true,
        isChecked: isS2Checked,
        isUnchecked: !validMarkupRefs.length,
        text: markupSubtotalText,
        value,
        onClick,
      })
    );
  }

  // when pasting in markups between estimates we might fail to find a reference
  // if that references is to another markup (like M1) This MenuItem clears
  // out those invalid references, because they are otherwise hard for users to remove
  const isReferenceError = newError && newError.includes('reference to missing markup');
  if (hasMarkups && isReferenceError)
    menuOptions.push(
      <MenuItem
        disableRipple
        className={`${classes.choice} ${classes.errorMessage}`}
        key="errorLine"
        value={RESOLVE_ERRORS}
      >
        <Error style={{ paddingRight: 12 }} />
        <Typography className={classes.selectHeaderOverflow}>{newError}</Typography>
        <Typography
          className={classes.clearOrSelectAll}
          onClick={() => {
            setSelectedReferences(checkValidMarkupReferences(markups, selectedReferences));
            setNewErrorState('');
          }}
        >
          Delete
        </Typography>
      </MenuItem>
    );

  // References for markups
  if (!isFixedMarkup && hasMarkups)
    menuOptions.push(
      ...markups.map((markup) => {
        const { lineNumber, id } = markup;
        const isChecked = isMarkupChecked(id, selectedReferences);
        const value = getReferenceValue(markup.id, isChecked);
        return getReferencesSubMenuItem({
          classes,
          id: lineNumber + CELL_REFERENCE_SELECTION,
          key: lineNumber + id,
          text: markupText(markup),
          title: markupTitle(selectedReferences, markup),
          isChecked,
          value,
          onClick,
        });
      })
    );

  if (!isFixedMarkup && hasMarkups) {
    const value = getReferenceValue(TOTAL_REF, totalChecked);
    menuOptions.push(
      <MenuItem
        className={`${classes.choice} ${classes.dividerTop}`}
        classes={{ selected: classes.selected }}
        key={TOTAL_REF}
        value={value}
        onClick={() => onClick(value)}
      >
        <Checkbox checked={totalChecked} />
        <span
          className={
            totalChecked
              ? `${classes.choiceText} ${classes.selectedChoiceText}`
              : classes.choiceText
          }
        >
          {isItem ? 'Item' : 'Project'} total
        </span>
      </MenuItem>
    );
  }

  return menuOptions;
};
