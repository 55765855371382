import { FC, memo } from 'react';

import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import { companyTabIDVar } from '../../../api/apollo/reactiveVars';
import { SortDirection } from '../../../generated/graphql';
import useModuleEntitlementsQuery from '../../../hooks/useModuleEntitlementsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { gridRowHeight } from '../../CompanyTab/CompanyTabUtils';
import { Table } from '../../scales';
import DialogsStyles from '../DialogsStyles';

import { filterCompanyInfo } from './DialogsCompanyRolesUtils';

type DialogsCompanyRolesProps = {
  classes: Classes<typeof DialogsStyles>;
  onClose: () => void;
  open: boolean;
};

const DialogsCompanyRoles: FC<DialogsCompanyRolesProps> = ({ classes, onClose, open }) => {
  const companyID = useReactiveVar(companyTabIDVar);
  const { data: { loadModuleEntitlementFlags: moduleEntitlementFlags = [] } = {} } =
    useModuleEntitlementsQuery(companyID);

  const check = (
    <div>
      <Check className={classes.check} />
    </div>
  );

  const companyInfo = filterCompanyInfo(moduleEntitlementFlags, true);

  const headers = companyInfo.map((info) => info.header);
  const permissions = companyInfo
    .slice(1)
    .map((info) => info.permissions)
    .filter((i) => !!i);
  const companyRoles = companyInfo
    .slice(1)
    .map((info) => info.companyRole)
    .filter((i) => !!i);

  const entries = permissions.map((text, i) => {
    return headers.map((_, j) => {
      const isDescriptionColumn = j === 0;
      const isPermissionColumn = j > 0;
      const role = isPermissionColumn ? companyRoles[j - 1] : '';

      return {
        id: isPermissionColumn ? role : 'description',
        key: isPermissionColumn ? role : 'description',
        text: isDescriptionColumn ? text : ' ',
        // Every row in the table should have one less check box
        // than the previous row so only show a checkbox if
        // the column index is greater than the row index
        // -------------
        // | X | X | X |
        // |   | X | X |
        // |   |   | X |
        component: i < j ? check : null,
      };
    });
  });

  const content = (
    <DialogContent>
      <div className="mx-5 my-6 border border-b-0">
        <Table
          entries={entries}
          columnWidths={companyInfo.map((_, i) => {
            if (i === 0) {
              return '5fr';
            }
            return '1fr';
          })}
          headerContent={headers}
          rowHeight={gridRowHeight}
          sortManager={{
            setSort: () => {},
            sortState: {
              sortDirection: SortDirection.SORT_NONE,
              sortKey: '',
            },
          }}
        />
      </div>
    </DialogContent>
  );

  return (
    <Dialog
      maxWidth={false}
      classes={{
        paper: classes.dialogPaperSmall,
      }}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <Typography variant="title">Company Roles</Typography>
        <IconButton onClick={onClose} title="Close dialog">
          <Close />
        </IconButton>
      </div>
      <Divider />
      {content}
      <DialogActions className={classes.action}>
        <Button
          data-cy="button-close-CompanyRoles"
          color="primary"
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(memo(DialogsCompanyRoles));
