import { FC, ReactNode } from 'react';

import { Typography } from '@material-ui/core';

import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { getTeamGUID } from '../../assets/utils';
import FullLogo from '../../logo/FullLogo.svg';
import { useOrganizationPictures } from '../../Print/PrintSharedComponents/useOrganizationPictures';

import styles from './SignupPageStyles';

type SignupContainerProps = {
  classes: Classes<typeof styles>;
  content: ReactNode;
};

/**
 * SignupContainer can be reached when you haven't verified your email, which
 * will cause 403 errors for projectPropsQuery if your URL includes a projectID
 */
const SignupContainer: FC<SignupContainerProps> = ({ classes, content }) => {
  const projectID = getProjectIdFromUrl();
  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT);
  const { name, team } = project || {};
  const loading = projectLoading;
  const projectNameText = name ? `Welcome to the ${name} team.` : 'Welcome to Join!';
  const { designTeamGUID, generalContractorGUID, ownerGUID } = getTeamGUID(team);
  const images: string[] = useOrganizationPictures([
    designTeamGUID,
    generalContractorGUID,
    ownerGUID,
  ]);

  const oneLogo = images.length === 1;
  const teamImages =
    !loading &&
    images.map((logo: string) => (
      <img alt="logo" className={classes.teamLogo} key={JSON.stringify(logo)} src={logo} />
    ));

  return (
    <div className={classes.contentOuter}>
      <img alt="joinlogo" src={FullLogo} className={classes.logoJoin} />
      <div className={classes.contentInner}>
        {projectNameText && (
          <Typography className={classes.projectText}>{projectNameText}</Typography>
        )}
        {content}
        {team && (
          <div className={!oneLogo ? classes.teamContainer : classes.teamContainerOne}>
            {teamImages}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SignupContainer);
