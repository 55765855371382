import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine2 } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
      marginTop: 'auto',
      alignItems: 'center',
    },
    chip: {
      ...theme.typography.webLabel,
      background: theme.palette.backgroundGrey,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      display: 'flex',
      gap: '2px',
      marginRight: 4,
      padding: 8,
      '&:focus': {
        outline: singleLine2(theme.palette.fillGreen),
      },
      '&:hover': {
        background: theme.palette.backgroundGreen,
      },
    },
    chip_selected: {
      background: theme.palette.app.selected,
    },
  });
