import { getProjectIdFromUrl } from '../../../../../utilities/url';
import { Chip, Chips } from '../../common/Chips';
import { GlobalSearchMode } from '../GlobalSearchUtils';

interface SearchModeSelectionProps {
  searchMode: GlobalSearchMode;
  onChange: (searchMode: GlobalSearchMode) => void;
}

const SearchModeSelection = (props: SearchModeSelectionProps) => {
  const isInProject = !!getProjectIdFromUrl();

  return (
    <Chips>
      <Chip
        isSelected={props.searchMode === GlobalSearchMode.ALL_PROJECTS}
        key="projects-chip"
        onClick={() => props.onChange(GlobalSearchMode.ALL_PROJECTS)}
      >
        {GlobalSearchMode.ALL_PROJECTS}
      </Chip>
      <Chip
        isSelected={props.searchMode === GlobalSearchMode.ALL_ITEMS}
        key="items-chip"
        onClick={() => props.onChange(GlobalSearchMode.ALL_ITEMS)}
      >
        {GlobalSearchMode.ALL_ITEMS}
      </Chip>
      {isInProject && (
        <Chip
          isSelected={props.searchMode === GlobalSearchMode.PROJECT_ITEMS}
          key="items-in-this-project-chip"
          onClick={() => props.onChange(GlobalSearchMode.PROJECT_ITEMS)}
        >
          {GlobalSearchMode.PROJECT_ITEMS}
        </Chip>
      )}
    </Chips>
  );
};

export default SearchModeSelection;
