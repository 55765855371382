import { isEnumValue } from '../../../utilities/types';
import { ButtonBar } from '../../scales';

export enum SearchToggleValue {
  ALL = 'COMPANY_RESULTS',
  MY = 'MY_RESULTS',
}

type SearchToggleProps = {
  counts: { all: number; my: number };
  labels: { all: string; my: string };
  onChange: (value: SearchToggleValue) => void;
  value: SearchToggleValue;
};

export type SearchToggleParams = Omit<SearchToggleProps, 'classes'>;

export default function SearchToggle({ counts, labels, onChange, value }: SearchToggleProps) {
  return (
    <ButtonBar
      onChange={(value: string) => {
        if (isEnumValue(SearchToggleValue, value)) onChange(value);
      }}
      options={[
        {
          value: SearchToggleValue.ALL,
          label: `${labels.all} (${counts.all})`,
        },
        {
          value: SearchToggleValue.MY,
          label: `${labels.my} (${counts.my})`,
        },
      ]}
      value={value}
    />
  );
}
