import { FC, useState } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { hasUserReportComments } from '../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';

import ContingencyDrawReportBody from './ContingencyDrawReportBody';
import ContingencyDrawReportHeader from './ContingencyDrawReportHeader';
import { styles } from './ContingencyReportStyles';
import { ContingencyReportType, getContingencyReportColumns } from './ContingencyReportUtils';
import ContingencySummaryReport from './SummaryReport/ContingencySummaryReport';

type ActiveMilestonReportProps = {
  classes: Classes<typeof styles>;
  projectID: string;
  contingencies: ContingencyReport;
  comments?: VarianceReportComments;
  selectedReportType: ContingencyReportType;
  activeMilestoneID: UUID;
};

const ActiveMilestonReport: FC<ActiveMilestonReportProps> = ({
  classes,
  projectID,
  contingencies,
  comments,
  selectedReportType,
  activeMilestoneID,
}) => {
  const [isNotesColumnExpanded, setIsNotesColumnExpanded] = useState(false);
  const [allRowsCollapsed, setAllRowsCollapsed] = useState(false);

  const columns = getContingencyReportColumns(selectedReportType);
  const hasComments = hasUserReportComments(comments);

  return (
    <>
      <div style={{ paddingBottom: 20 }}>
        <ContingencySummaryReport
          contingencyData={contingencies}
          selectedReportType={selectedReportType}
        />
      </div>
      <div className={classes.table}>
        <ContingencyDrawReportHeader
          allRowsCollapsed={allRowsCollapsed}
          columns={columns}
          hasComments={hasComments}
          isNotesColumnExpanded={isNotesColumnExpanded}
          setAllRowsCollapsed={setAllRowsCollapsed}
          setIsNotesColumnExpanded={setIsNotesColumnExpanded}
        />
        <ContingencyDrawReportBody
          columns={columns}
          contingencies={contingencies}
          comments={comments}
          allRowsCollapsed={allRowsCollapsed}
          isNotesColumnExpanded={isNotesColumnExpanded}
          setIsNotesColumnExpanded={setIsNotesColumnExpanded}
          projectID={projectID}
          activeMilestoneID={activeMilestoneID}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(ActiveMilestonReport);
