import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    closeAddNote: {
      color: theme.palette.buttonGrey,
      width: 15,
      height: 15,
      '&:hover': {
        color: theme.palette.disabledGrey,
      },
    },
    newNoteContainer: {
      display: 'flex',
      alignItems: 'start',
      gap: '6px',
      width: '100%',
      padding: '6px 0px',
    },
  });

export default styles;
