import queryString from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { DASHBOARD, TRUE, VIEW_FILTER } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import { getDashboardStorageParam } from '../../dashboard/Dashboard';
import DashboardChartsEstimate from '../../dashboard/DashboardCharts/DashboardChartsEstimate/DashboardChartsEstimate';
import DashboardChartsItems from '../../dashboard/DashboardCharts/DashboardChartsItems/DashboardChartsItems';
import DashboardChartsTrend from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrend';
import { DASHBOARD_DEFAULTS } from '../../dashboard/DashboardUtils';
import { restoreFilters } from '../../FilterPanel/filterUtils';
import { useThumbnailURL } from '../../frame/ProjectSummaryUtils';
import { useMilestoneQuery } from '../../Milestone/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import PrintSubheader from '../PrintCostReport/PrintSubheader';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import { LANDSCAPE_WIDTH, LandscapeOrientation } from '../PrintUtils';

import PrintDashboardStyles from './PrintDashboardStyles';

type PrintDashboardProps = {
  classes: Classes<typeof PrintDashboardStyles>;
};

const PrintDashboard: FC<PrintDashboardProps> = ({ classes }) => {
  // CONSTANTS
  const projectId = getProjectIdFromUrl();
  const routerLocation = useLocation();
  const hasSummary =
    routerLocation.pathname ===
    generateSharedPath(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD_SUMMARY, { projectId });

  // SETTINGS
  const [settings] = usePersistentStates(
    window.location,
    DASHBOARD,
    DASHBOARD_DEFAULTS,
    getDashboardStorageParam(projectId)
  );

  // DATA
  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const thumbnail = project?.thumbnail;
  const activeMilestoneId = project?.activeMilestone.id;
  const { thumbnailURL, thumbnailLoading } = useThumbnailURL(thumbnail, !hasSummary);

  const { data: { milestone: activeMilestone } = { milestone: undefined } } =
    useMilestoneQuery(activeMilestoneId);
  const milestoneName = (activeMilestone && activeMilestone.name) || '';

  const hasCategorization = useMemo(
    () => categorizations && categorizations.length > 0,
    [categorizations]
  );

  const filterState = restoreFilters(settings[VIEW_FILTER]);
  const { filterQueryInput: viewFilter } = filterState;

  // FIRE PRINT ON LOADING
  const [imagesAreReady, setImagesAreReady] = useState(false);
  const triggerOnComplete = () => setImagesAreReady(true);

  const [trendlineIsLoading, setTrendlineIsLoading] = useState(true);
  const [estimateIsLoading, setEstimateIsLoading] = useState(true);
  const [itemsIsLoading, setItemsIsLoading] = useState(true);
  const hooksLoading =
    thumbnailLoading ||
    trendlineIsLoading ||
    estimateIsLoading ||
    itemsIsLoading ||
    !imagesAreReady;
  useLoadTimer('PrintDashboard', hooksLoading);
  usePrintWindow(!hooksLoading);

  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Dashboard`;
  }, [project]);

  if (!projectId) return <Navigate to="/404" />;
  if (project && project.name) {
    document.title = `${project.name} - Dashboard`;
  }

  // COMPONENT - hack to fully re-generate chart widths on print
  const landscapeOrientation = <LandscapeOrientation />;

  const { search } = window.location;
  const parsed = queryString.parse(search, { arrayFormat: 'index' });
  const { showTable } = parsed;
  const isTableDisplayed = showTable === TRUE;

  const header = (
    <div style={{ width: LANDSCAPE_WIDTH }}>
      <PrintPageHeaderWrapper
        projectId={projectId}
        reportName={isTableDisplayed ? 'Estimate Chart with Details' : 'Dashboard'}
        milestoneName={!hasSummary ? milestoneName : undefined}
        triggerOnComplete={triggerOnComplete}
      />
      <PrintSubheader
        categorizations={categorizations}
        hasSummary={hasSummary}
        milestone={activeMilestone ?? undefined}
        project={project}
        settings={settings}
        thumbnailURL={thumbnailURL ?? undefined}
      />
    </div>
  );

  const charts = (
    <>
      {activeMilestone && !isTableDisplayed && (
        <>
          {header}
          <DashboardChartsTrend
            activeMilestoneId={activeMilestone.id}
            isPrint
            setIsLoading={setTrendlineIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </>
      )}
      {hasCategorization && activeMilestone && (
        <>
          {!isTableDisplayed && <PrintPageBreak />}
          {header}
          <DashboardChartsEstimate
            activeMilestoneId={activeMilestone.id}
            categorizations={categorizations}
            isPrint
            isTableDisplayed={isTableDisplayed}
            setIsLoading={setEstimateIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </>
      )}
      {hasCategorization && activeMilestone && !isTableDisplayed && (
        <>
          <PrintPageBreak />
          {header}
          <DashboardChartsItems
            activeMilestoneId={activeMilestone.id}
            categorizations={categorizations}
            viewFilter={viewFilter}
            isPrint
            settings={settings}
            setIsLoading={setItemsIsLoading}
          />
        </>
      )}
    </>
  );

  return (
    <div className="print-header-page">
      <div className={classes.root}>
        {landscapeOrientation}
        <div className={classes.page} style={{ width: LANDSCAPE_WIDTH }}>
          {charts}
        </div>
      </div>
    </div>
  );
};

export default withStyles(PrintDashboardStyles)(PrintDashboard);
