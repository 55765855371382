import { VerifyEmailMutationResult } from '../../../../../generated/graphql';
import TOSCheckLayer from '../../../TOSPage';
import { useAcceptedTOSQuery } from '../../../useTOSHooks';

import EmailVerificationSendUser from './EmailVerificationSendUser';

type Props = {
  acceptedTOSQuery: ReturnType<typeof useAcceptedTOSQuery>;
  mutationResult: VerifyEmailMutationResult;
};

const EmailVerificationData = (props: Props) => {
  const { data, loading } = props.mutationResult;

  // Wait...
  if (!data || loading) {
    return <div />;
  }

  // Verified? Go on to app...
  if (data.verifyEmail) {
    return <TOSCheckLayer acceptedTOSQuery={props.acceptedTOSQuery} />;
  }

  // Not verified? Send verification email...
  return <EmailVerificationSendUser />;
};

export default EmailVerificationData;
