import { useState } from 'react';

import { getProjectIdFromUrl } from '../../../utilities/url';
import { useLocalStorageParams } from '../../../utilities/urlState';
import useLoadOrCreateScenarioSandboxByMilestoneIDQuery from '../../Scenarios/hooks/useLoadOrCreateScenarioSandboxQuery';
import { SandboxView } from '../../Scenarios/Sandbox/SandboxUtil';
import { ScenariosSettings } from '../../Scenarios/Scenarios';
import {
  FILES_SCENARIO_DEFAULT_SORT,
  getScenariosLocalStorageLocation,
  getSortDirectionVal,
  getSortKeyVal,
  useGetMilestoneNameFromID,
} from '../../Scenarios/ScenarioUtils';
import usePrintWindow from '../PrintHooks/usePrintWindow';

import PrintPageScenariosSandbox from './PrintPageScenariosSandbox';

export default function PrintScenariosSandbox() {
  // Params
  const projectId = getProjectIdFromUrl();
  const [settings] = useLocalStorageParams<ScenariosSettings>(
    {
      milestoneID: '',
      showMilestone: true,
      sortDirection: FILES_SCENARIO_DEFAULT_SORT.sortDirection,
      sortKey: FILES_SCENARIO_DEFAULT_SORT.sortKey,
      sandboxView: SandboxView.SANDBOX_VIEW_WORK,
    },
    getScenariosLocalStorageLocation(projectId, '')
  );

  const sortKey = getSortKeyVal(settings.sortKey);
  const sortDirection = getSortDirectionVal(settings.sortDirection);

  // Data
  const { milestoneName, milestonesLoading } = useGetMilestoneNameFromID(
    projectId,
    settings.milestoneID
  );
  const {
    data: sandboxData,
    loading: sandboxLoading,
    previousData,
  } = useLoadOrCreateScenarioSandboxByMilestoneIDQuery(
    { sortKey, sortDirection },
    settings.milestoneID || undefined
  );
  const sandbox = sandboxLoading
    ? previousData?.loadOrCreateScenarioSandboxByMilestoneID
    : sandboxData?.loadOrCreateScenarioSandboxByMilestoneID;

  // Print trigger
  const [imagesAreReady, setImagesAreReady] = useState(false);
  const triggerOnComplete = () => setImagesAreReady(true);
  const hooksLoading = sandboxLoading || milestonesLoading || !imagesAreReady;

  usePrintWindow(!hooksLoading);

  if (!sandbox) return null;

  return (
    <PrintPageScenariosSandbox
      hooksLoading={hooksLoading}
      milestoneName={milestoneName}
      milestone={sandbox.milestone}
      showMilestone={settings.showMilestone}
      sandbox={sandbox}
      triggerOnComplete={triggerOnComplete}
    />
  );
}
