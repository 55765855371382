import { Label } from '../common/Label';

import RelevantItem from './RelevantItem';

interface RelevantItemsProps {
  items: ForecastingItem[];
  onClick: () => void;
}

const RelevantItems = (props: RelevantItemsProps) => (
  <>
    <Label>Relevant items</Label>
    {!props.items.length && (
      <div style={{ paddingTop: 4, paddingLeft: 4 }}>
        No results found. Try adjusting your search.
      </div>
    )}
    {props.items.slice(0, 5).map((item) => (
      <RelevantItem key={item.id} item={item} onClick={props.onClick} />
    ))}
  </>
);

export default RelevantItems;
