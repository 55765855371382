import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from 'd3-scale';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../../../api/gqlEnums';
import { Status } from '../../../../../generated/graphql';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import { pluralizeString } from '../../../../../utilities/string';
import SVGWithDimensions from '../../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../../Charts/ChartsD3/useChartDimensions';
import { renderCostString } from '../../../../CostReport/CostReportUtils';
import { ItemStatusIcon } from '../../../../dragon-scales';
import { Tooltip } from '../../../../scales';
import useItemStatusCostReport from '../../hooks/useItemStatusCostReport';

type Props = {
  projectID: UUID;
  activeMilestoneID?: UUID;
};

export default function ItemsChart(props: Props) {
  const report = useItemStatusCostReport(props.projectID, props.activeMilestoneID);

  const numItems =
    (report.accepted.numItems ?? 0) +
    (report.incorporated.numItems ?? 0) +
    (report.pending.numItems ?? 0) +
    (report.rejected.numItems ?? 0);

  if (numItems === 0) {
    return (
      <ChartLink projectID={props.projectID}>
        <div className="flex h-full w-full items-center justify-center text-type-muted type-body2">
          No items
        </div>
      </ChartLink>
    );
  }

  return (
    <ChartLink projectID={props.projectID}>
      <ItemsChartView
        acceptedItems={report.accepted}
        incorporatedItems={report.incorporated}
        pendingItems={report.pending}
        rejectedItems={report.rejected}
      />
    </ChartLink>
  );
}

function ChartLink(props: { children: ReactNode; projectID: UUID }) {
  return (
    <Link
      className="h-full w-full outline-none focus-visible:bg-selection-hover"
      data-cy="items-chart"
      to={generateSharedPath(JoinProjectRoutes.ITEMS_LIST, { projectId: props.projectID })}
    >
      {props.children}
    </Link>
  );
}

type ItemStatusData = {
  cost?: AddDeductCost;
  numItems?: number;
};

type ViewProps = {
  acceptedItems: ItemStatusData;
  incorporatedItems: ItemStatusData;
  pendingItems: ItemStatusData;
  rejectedItems: ItemStatusData;
};
export function ItemsChartView(props: ViewProps) {
  /**
   * When computing the margins here, we reserve space above and below the bar
   * via margins. The top and bottom margin will both contain the labels which
   * have a 16px line-height and are placed with a 4px gap between them and the
   * bar. The bottom margin will also contain the item status icons, serving as
   * an x-axis. The icons will also be 16px tall, with a 4px gap reserved.
   */
  const { ref, dimensions } = useChartDimensions({
    height: 110,
    marginTop: 20,
    marginRight: 0,
    marginBottom: 40,
    marginLeft: 0,
  });

  const allItems = [
    props.acceptedItems,
    props.incorporatedItems,
    props.pendingItems,
    props.rejectedItems,
  ];

  const maxY = allItems.reduce((currentMaxY, value) => {
    // the types are lying to us...the BE gives us a string not a number :\
    const adds = value.cost?.adds ? parseInt(`${value.cost?.adds}`, 10) : 0;
    return adds > currentMaxY ? adds : currentMaxY;
  }, 0);

  const minY = allItems.reduce((currentMinY, value) => {
    // the types are lying to us...the BE gives us a string not a number :\
    const deducts = value.cost?.deducts ? parseInt(`${value.cost?.deducts}`, 10) : 0;
    return deducts < currentMinY ? deducts : currentMinY;
  }, 0);

  const x = scaleBand<Status>()
    .domain([Status.REJECTED, Status.PENDING, Status.ACCEPTED, Status.INCORPORATED])
    .range([0, dimensions.width])
    .padding(0.1);

  const y = scaleLinear<number, number>().domain([minY, maxY]).range([dimensions.boundedHeight, 0]);

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      <Bar data={props.rejectedItems} itemStatus={Status.REJECTED} x={x} y={y} />
      <Bar data={props.pendingItems} itemStatus={Status.PENDING} x={x} y={y} />
      <Bar data={props.acceptedItems} itemStatus={Status.ACCEPTED} x={x} y={y} />
      <Bar data={props.incorporatedItems} itemStatus={Status.INCORPORATED} x={x} y={y} />
      <line
        y1={y(0)}
        y2={y(0)}
        x1={0}
        x2={dimensions.width}
        className="pointer-events-none stroke-chart-axis"
      />
    </SVGWithDimensions>
  );
}

const BAR_LABEL_GAP = 4;
const BAR_FILL: Partial<Record<Status, string>> = {
  [Status.PENDING]: 'fill-item-status-pending',
  [Status.REJECTED]: 'fill-item-status-rejected',
  [Status.ACCEPTED]: 'fill-item-status-accepted',
  [Status.INCORPORATED]: 'fill-item-status-incorporated',
};

function Bar(props: {
  data: ItemStatusData;
  itemStatus: Status;
  x: ScaleBand<Status>;
  y: ScaleLinear<number, number>;
}) {
  if (!props.data.cost) return null;

  const xLeft = props.x(props.itemStatus) ?? 0;
  const xMid = xLeft + props.x.bandwidth() / 2;

  return (
    <Tooltip
      content={
        <div className="flex flex-col">
          <div>{`${props.data.numItems} ${props.itemStatus.toLocaleLowerCase()} ${pluralizeString(
            'item',
            props.data.numItems ?? 0
          )}`}</div>
          <ul className="ml-4 list-disc">
            <li>{renderCostString({ cost: props.data.cost.adds })} adds</li>
            <li>{renderCostString({ cost: props.data.cost.deducts })} deducts</li>
          </ul>
        </div>
      }
      placement="right"
    >
      <g data-cy={`items-chart-bar--${props.itemStatus.toLocaleLowerCase()}`}>
        <BarLabel
          cost={props.data.cost.adds}
          data-cy="bar-label-adds"
          dominantBaseline="auto"
          x={xMid}
          y={props.y(props.data.cost.adds) - BAR_LABEL_GAP}
        />

        <rect
          className={BAR_FILL[props.itemStatus]}
          data-cy="bar-rect"
          height={props.y(props.data.cost.deducts) - props.y(props.data.cost.adds)}
          width={props.x.bandwidth()}
          x={xLeft}
          y={props.y(props.data.cost.adds)}
        />

        <BarLabel
          cost={props.data.cost.deducts}
          data-cy="bar-label-deducts"
          dominantBaseline="hanging"
          x={xMid}
          y={props.y(props.data.cost.deducts) + BAR_LABEL_GAP}
        />

        <g
          data-cy="bar-icon"
          transform={`translate(${xMid - 8 /* 8 is half the width of the icon */}, ${
            74 /* The chart's bounded height minus the height of the icon */
          })`}
        >
          <foreignObject className="h-4 w-4">
            <ItemStatusIcon size="sm" value={props.itemStatus} />
          </foreignObject>
        </g>
      </g>
    </Tooltip>
  );
}

function BarLabel(props: {
  cost: number;
  'data-cy': string;
  dominantBaseline?: string;
  x: number;
  y: number;
}) {
  if (!parseInt(`${props.cost}`, 10)) return null;

  return (
    <text
      className="fill-type-primary text-center lining-nums tabular-nums type-label"
      data-cy={props['data-cy']}
      textAnchor="middle"
      x={props.x}
      y={props.y}
      dominantBaseline={props.dominantBaseline}
    >
      {renderCostString({ cost: props.cost, isSigned: true })}
    </text>
  );
}
