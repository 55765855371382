import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  FORECASTING_EXPLORE_PATH,
  FORECASTING_PROJECT_COMP_PATH,
  FORECASTING_TAB_PATH,
} from '../../constants';
import usePageHeight from '../../hooks/usePageHeight';

import ForecastingHeader from './ForecastingHeader';

export default function ForecastingTab() {
  const { pathname } = useLocation();

  useNavigateToForecastingDefault(pathname);
  const hideHeader = getIsForecastingProjectCompPath(pathname);

  const height = usePageHeight();

  if (hideHeader) return <Outlet />;

  return (
    <div className="flex flex-col overflow-hidden bg-background-primary" style={{ height }}>
      <ForecastingHeader />
      <Outlet />
    </div>
  );
}

// Navigate to Explore by default
const useNavigateToForecastingDefault = (pathname: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === `/${FORECASTING_TAB_PATH}`) {
      navigate(FORECASTING_EXPLORE_PATH);
    }
  }, [navigate, pathname]);
};

const getIsForecastingProjectCompPath = (pathname: string = window.location.pathname) => {
  let hideHeader = false;
  const currentPath = pathname.split('/');
  if (currentPath[2] === FORECASTING_PROJECT_COMP_PATH) {
    hideHeader = true;
  }
  return hideHeader;
};
