import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { TextField, Typography } from '@material-ui/core';

import {
  updateItemAssigneeAnalytics,
  updateItemDescriptionAnalytics,
  updateItemDueDateAnalytics,
  updateItemNameAnalytics,
  updateItemNumberProps,
} from '../../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  getDateString,
  isDateValid,
  localeDateFormat,
  maskFn,
  parseDate,
  placeholderDefault,
} from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { computeMilestonesSorted } from '../../../utilities/milestones';
import { useAttachToItem } from '../../assets/hooks/mutations/useAttachToItem';
import UserAvatar from '../../Collaborators/UserAvatar';
import { getIsDeactivated } from '../../CompanyTab/CompanyTabUtils';
import InputsSelectAssigneeData from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { TipTapTextAreaVariant } from '../../Inputs/InputsTextAreaStyled/TipTapTextArea/TipTapTextArea';
import ItemNumberTextField from '../../Inputs/ItemNumberTextField/ItemNumberTextField';
import useUpdateStatus from '../../Items/ItemsHooks';
import {
  getItemTypeAnalytics,
  isPrivateVisibility,
  isScenarioVisibility,
} from '../../Items/ItemsUtils';
import ItemsIconsMap, { MEDIUM } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import ItemsIconsStatusSelect from '../../ItemsList/ItemsIcons/ItemsIconsStatusSelect/ItemsIconsStatusSelect';
import { ScrollContainer } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import {
  useItemAssigneeUpdate,
  useItemDescriptionUpdate,
  useItemDueDateUpdate,
  useItemNameUpdate,
  useItemNumberUpdate,
} from '../../ve-item-details/ItemDetailsHooks';

import ItemMilestoneContainer from './ItemMilestoneContainer/ItemMilestoneContainer';
import styles from './ItemSidebarCollapseGeneralStyle';
import ItemSidebarTextField from './ItemSidebarTextField';
import { ItemPermissions, getSidebarLocation } from './ItemSidebarUtils';

type ItemSidebarCollapseGeneralProps = {
  classes: Classes<typeof styles>;
  item: ItemLike;
  minHeight: number;
  projectId: UUID;
  permissions: ItemPermissions;
  projectMilestones: Milestone[];
  onItemMutated?: () => void;
};

const ItemSidebarCollapseGeneral: FC<ItemSidebarCollapseGeneralProps> = ({
  classes,
  item,
  minHeight,
  projectId,
  permissions,
  projectMilestones,
  onItemMutated,
}) => {
  // HOOKS
  const sendAnalytics = useAnalyticsEventHook();

  // QUERIES
  const activeMilestoneId = useProjectPropsQuery(projectId).data.project?.activeMilestone?.id;

  // VARIABLES
  const {
    assignee,
    availableStates,
    id,
    number,
    descriptionStyled,
    name,
    itemType,
    status,
    dueDate,
    visibility,
  } = item || {};
  const isDraft = isPrivateVisibility(visibility);

  // STATE
  const isScenario = isScenarioVisibility(visibility);
  const [itemDueDate, setItemDueDate] = useState(dueDate || null);
  const [itemName, setItemName] = useState(name || '');

  const milestones = useMemoWrapper(computeMilestonesSorted, projectMilestones, activeMilestoneId);

  // UPDATES
  const attachToItem = useAttachToItem({ onCompleted: onItemMutated });
  const onAttachAsset = (asset: Asset) => {
    attachToItem({ input: { assetID: asset.id, itemID: item.id } });
  };

  const updateStatus = useUpdateStatus({ onCompleted: onItemMutated });
  const setUpdateStatus = (newStatus: Status) => {
    if (status !== newStatus) {
      updateStatus(projectId, item, newStatus);
    }
  };

  const updateAssignee = useItemAssigneeUpdate({ onCompleted: onItemMutated });
  const setAssignee = (id: UUID, assigneeEmail: string | undefined) => {
    if (item && ((item.assignee && item.assignee.email) || undefined) !== assigneeEmail) {
      sendAnalytics(
        updateItemAssigneeAnalytics({
          id,
          assigneeEmail,
          itemType: getItemTypeAnalytics(item),
          location: getSidebarLocation(),
        })
      );
      updateAssignee(projectId, id, assigneeEmail);
    }
  };

  const updateDueDate = useItemDueDateUpdate({ onCompleted: onItemMutated });
  const setDueDate = (id: UUID, dueDate: string) => {
    if (item && (parseDate(item.dueDate) || undefined) !== parseDate(dueDate)) {
      sendAnalytics(
        updateItemDueDateAnalytics({
          id,
          dueDate,
          itemType: getItemTypeAnalytics(item),
          location: getSidebarLocation(),
        })
      );
      setItemDueDate(dueDate);
      updateDueDate(projectId, id, dueDate);
    }
  };

  const sendItemDescriptionAnalytics = (newDescription: string) => {
    sendAnalytics(
      updateItemDescriptionAnalytics({
        itemID: id,
        newDescription,
        itemType,
        location: getSidebarLocation(),
      })
    );
  };

  const updateItemDescription = useItemDescriptionUpdate({ onCompleted: onItemMutated });
  const setUpdateItemDescription = (description: string, descriptionStyled: string) => {
    sendItemDescriptionAnalytics(description);
    updateItemDescription(projectId, item.id, description, descriptionStyled);
  };

  const updateItemName = useItemNameUpdate({ onCompleted: onItemMutated });
  const setUpdateItemName = () => {
    if (name !== itemName) {
      sendAnalytics(
        updateItemNameAnalytics({
          itemID: id,
          newName: itemName,
          itemType,
          location: getSidebarLocation(),
        })
      );
      updateItemName(projectId, id, itemName);
    }
  };

  const updateItemNumber = useItemNumberUpdate({ onCompleted: onItemMutated });
  const setItemNumber = (itemId: UUID, itemNumber: string, type: string) => {
    if (number !== itemNumber) {
      updateItemNumber(projectId, itemId, itemNumber);
      sendAnalytics(updateItemNumberProps(itemId, itemNumber, type, getSidebarLocation()));
    }
  };

  // COMPONENTS
  const renderItemAssignee = () => {
    const selectedAssigneeEmail = assignee?.email || '';
    return (
      <div className={classes.halfContainer}>
        <Typography className={classes.captionColor} variant="caption">
          Assignee
        </Typography>
        {permissions.canEditItemAssignee ? (
          <div>
            <InputsSelectAssigneeData
              disabled={!permissions.canEditItemAssignee}
              key={item ? id : ''}
              onChange={(email) => setAssignee(id, email)}
              projectId={projectId}
              selected={selectedAssigneeEmail}
              selectedAssignee={assignee}
              isDraft={isDraft}
              itemID={item.id}
            />
          </div>
        ) : (
          <div>
            {assignee ? (
              <div className={classes.avatarDisabled}>
                <UserAvatar assignee={assignee} deactivated={getIsDeactivated(assignee)} />
                <ItemSidebarTextField value={assignee.name} />
              </div>
            ) : (
              <div className={classes.entryTopPadding}>
                <ItemSidebarTextField value="Unassigned" disabled />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderItemDescription = () => (
    <div>
      <Typography className={classes.captionColor} variant="caption">
        Item Description
      </Typography>
      {permissions.canEditItemDetails ? (
        <InputsTextAreaStyled
          data-cy="input-itemLikeDescription"
          editable={permissions.canEditItemDetails}
          placeholder="Describe this item..."
          onAttachAsset={onAttachAsset}
          onChangeComplete={(description, descriptionStyled) => {
            setUpdateItemDescription(description, descriptionStyled);
          }}
          textStyled={descriptionStyled}
        />
      ) : (
        <div>
          {!descriptionStyled ? (
            <ItemSidebarTextField
              dataCy="input-itemLikeDescription"
              disabled
              value="No item description"
            />
          ) : (
            <InputsTextAreaStyled
              data-cy="input-itemLikeDescription"
              editable={permissions.canEditItemDetails}
              placeholder="Describe this item..."
              onAttachAsset={onAttachAsset}
              onChangeComplete={(description, descriptionStyled) => {
                setUpdateItemDescription(description, descriptionStyled);
              }}
              textStyled={descriptionStyled}
              variant={TipTapTextAreaVariant.DESCRIPTION}
            />
          )}
        </div>
      )}
    </div>
  );

  const renderItemDueDate = () => (
    <div className={classes.halfContainer}>
      <Typography className={classes.captionColor} variant="caption">
        Due Date
      </Typography>
      <div>
        {permissions.canEditItemAssignee ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              data-cy="input-date-picker"
              keyboard
              keepCharPositions
              className={classes.date}
              disabled={!permissions.canEditItemAssignee}
              InputProps={{
                classes: { input: `picker ${classes.picker}` },
                disableUnderline: true,
              }}
              KeyboardButtonProps={{
                classes: { root: classes.datePickerIcon },
              }}
              FormHelperTextProps={{ classes: { error: classes.error } }}
              clearable
              fullWidth
              format={localeDateFormat}
              placeholder={placeholderDefault}
              invalidDateMessage=" "
              mask={maskFn}
              value={itemDueDate}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onKeyDown={(evt: any) => {
                if (evt.key === 'Enter') {
                  evt.target.blur();
                }
              }}
              onChange={(value) => {
                if ((value && isDateValid(value)) || value === null) {
                  setDueDate(item.id, value);
                }
              }}
              animateYearScrolling={false}
            />
          </MuiPickersUtilsProvider>
        ) : (
          <div className={classes.entryTopPadding}>
            {itemDueDate ? (
              <ItemSidebarTextField
                dataCy="input-date-picker"
                value={getDateString(new Date(itemDueDate))}
              />
            ) : (
              <ItemSidebarTextField dataCy="input-date-picker" value="No due date" disabled />
            )}
          </div>
        )}
      </div>
    </div>
  );

  const renderItemNumber = () => (
    <div className={classes.itemNumber}>
      <Typography className={classes.captionColor} variant="caption">
        Item Number
      </Typography>
      {permissions.canEditItemDetails ? (
        <ItemNumberTextField
          editable={permissions.canEditItemDetails}
          isPrivate={isPrivateVisibility(visibility)}
          itemId={id}
          itemType={itemType}
          setItemNumber={setItemNumber}
          value={number}
        />
      ) : (
        <div>
          {number === '' ? (
            <ItemSidebarTextField dataCy="textField-itemNumber" disabled value="None" />
          ) : (
            <ItemSidebarTextField dataCy="textField-itemNumber" value={number} />
          )}
        </div>
      )}
    </div>
  );

  const renderItemStatus = () => {
    const statusIcon = <ItemsIconsMap stateAvailabilityClass="" status={status} variant={MEDIUM} />;
    return (
      <div className={classes.flexGrow}>
        <Typography className={classes.captionColor} variant="caption">
          Item Status
        </Typography>
        {permissions.canEditItemStatus ? (
          <ItemsIconsStatusSelect
            availableStates={availableStates}
            editable={permissions.canEditItemStatus}
            item={item}
            options={(item as Item).hasOptions ? (item as Item).options : []}
            status={status}
            updateStatus={setUpdateStatus}
          />
        ) : (
          <ItemSidebarTextField
            dataCy="statusIcon-statusIconSelect"
            icon={statusIcon}
            value={getItemStatusLabel(status)}
          />
        )}
      </div>
    );
  };

  const renderItemName = () => (
    <div className={classes.fullContainer}>
      <Typography className={classes.captionColor} variant="caption">
        Item Name
      </Typography>
      {permissions.canEditItemDetails ? (
        <TextField
          className={classes.backgroundColor}
          data-cy="input-itemName"
          disabled={!permissions.canEditItemDetails}
          value={itemName}
          InputProps={{
            classes: {
              root: classes.title,
              input: classes.titleInput,
            },
            disableUnderline: true,
          }}
          fullWidth
          id="name"
          multiline
          placeholder="Item name"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onChange={(e: any) => setItemName(e.target.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') evt.target.blur();
          }}
          onBlur={() => {
            setUpdateItemName();
          }}
        />
      ) : (
        <div>
          {!itemName ? (
            <ItemSidebarTextField dataCy="input-itemName" value="No item name" disabled />
          ) : (
            <ItemSidebarTextField dataCy="input-itemName" value={itemName} />
          )}
        </div>
      )}
    </div>
  );

  const renderItemMilestone = () => (
    <ItemMilestoneContainer
      item={item}
      milestones={milestones}
      permissions={permissions}
      projectID={projectId}
      onItemMutated={onItemMutated}
    />
  );

  return (
    <ScrollContainer direction="vertical" style={{ minHeight }}>
      <div className={classes.generalContainerHeight}>
        <div className={classes.generalContainerSections}>
          {!isScenario && renderItemNumber()}
          {!isScenario && renderItemStatus()}
        </div>
        <div className={classes.flexBetween}>{renderItemName()}</div>
        {renderItemDescription()}
        <div className={classes.generalContainerSections}>
          {!isScenario && renderItemAssignee()}
          {renderItemDueDate()}
        </div>
        {!isScenario && renderItemMilestone()}
      </div>
    </ScrollContainer>
  );
};

export default withStyles(styles)(ItemSidebarCollapseGeneral);
