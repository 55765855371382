import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    head: {
      backgroundColor: 'none',
      border: 'none !important',
      color: theme.palette.primaryGrey,
      ...theme.typography.webButton,
      '@media print': {
        background: 'none',
      },
    },
    header: {
      background: 'none !important',
      paddingBottom: 8,
    },
    headerCell: {
      border: '0px',
      paddingBottom: '8px',
    },
    headerText: {
      ...theme.typography.h4,
      fontSize: '13px',
      lineHeight: '144%',
      fontFamily: theme.typography.fontFamily,
      paddingBottom: 12,
    },
    list: {
      overflow: 'visible',
      padding: 0,
    },
    root: {
      backgroundColor: 'white',
    },
    smallRadio: {
      height: 24,
      padding: '0px 8px 0px 0px',
      width: 24,
    },
    title: {
      display: 'inline-block',
      fontSize: '1.5rem',
      fontWeight: 500,
      paddingBottom: 8,
    },
    minimalTable: {
      width: '50%',
      marginBottom: 12,
    },
  });

export default styles;
