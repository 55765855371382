import { FC } from 'react';

import { getDateString } from '../../../utilities/dates';
import CostSummaryData from '../../estimate/CostSummaryData';
import ProjectSummaryPrintData from '../../frame/ProjectSummaryPrintData';
import { formatSettingsForExport } from '../../ItemsList/ItemsListUtils';
import { getFilterLabels, getGroupByLabels } from '../PrintUtils';

export type PrintSubheaderParams = {
  title: string;
  value: string | number;
  alignRight?: boolean;
};

type PrintSubheaderProps = {
  categorizations?: Categorization[];
  hasSummary?: boolean;
  milestone?: Pick<Milestone, 'id' | 'name'>;
  params?: PrintSubheaderParams[];
  project?: ProjectProps | Partial<ProjectProps>;
  settings?: Settings;
  thumbnailURL?: string;
};

export const getParameters = (params?: PrintSubheaderParams[] | undefined) =>
  (params || [])
    .filter(({ title, value }) => title && value)
    .map(({ title, value, alignRight }) => (
      <div key={title} className={alignRight ? 'print-align-right' : ''}>
        <span className="print-bold-text print-padding-left-16">{`${title}:`} </span>
        &nbsp;
        <span>{value}</span>
      </div>
    ));

export const getDateLine = (params?: PrintSubheaderParams[] | undefined) => (
  <div className="print-subheader-line">
    <span className="print-bold-text print-date-text">
      {`As of ${getDateString(new Date(), true)}`}{' '}
    </span>
    {getParameters(params)}
  </div>
);

export const getSettingsLine = (
  settings: Settings | undefined,
  categorizations: Categorization[] | undefined
) => {
  if (!settings || !categorizations) return null;
  const filters = formatSettingsForExport(settings, [], categorizations);
  const filterLabels = getFilterLabels(filters);
  const { groupBy } = settings;
  const groupByLabels = getGroupByLabels(groupBy);
  return (
    (filterLabels || groupByLabels) && (
      <div className="print-subheader-line">
        {groupByLabels}
        {filterLabels}
      </div>
    )
  );
};

const PrintSubheader: FC<PrintSubheaderProps> = ({
  categorizations,
  hasSummary,
  milestone,
  params,
  project,
  settings,
  thumbnailURL,
}) => {
  return (
    <>
      <div className={`print-subheader ${hasSummary ? 'print-subheader-summary' : ''}`}>
        <div className="print-subheader-columns">
          {hasSummary && milestone && project && (
            <div className="print-subheader-project-summary">
              <ProjectSummaryPrintData
                milestone={milestone}
                project={project as ProjectProps}
                thumbnailURL={thumbnailURL}
              />
            </div>
          )}
          {getDateLine(params)}
          {getSettingsLine(settings, categorizations)}
        </div>
        {hasSummary && milestone && (
          <div className="print-subheader-columns">
            <CostSummaryData milestoneId={milestone?.id} />
          </div>
        )}
      </div>
      <hr className="print-main-divider" />
    </>
  );
};

export default PrintSubheader;
