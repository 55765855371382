import { useMutation } from '@apollo/client';

import {
  CompanyQuery,
  CompanyQueryVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { companyQuery, createCompanyMutation } from '../../queries';

export const useCompanyQuery = (companyID?: UUID) =>
  useQuery<CompanyQuery, CompanyQueryVariables>(companyQuery, {
    variables: { companyID },
    skip: !companyID,
  });

export const useCreateCompanyMutation = () => {
  const [createCompanyFunc] = useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    createCompanyMutation
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (name: string, type: string, assetID: UUID | null, onSuccess?: any, onFailure?: any) => {
    createCompanyFunc({
      variables: { name, type, assetID },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { createCompany: createdCompany },
        } = result;
        if (onSuccess) onSuccess(createdCompany);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
};
