import { FC, useRef, useState } from 'react';
import * as React from 'react';
import { isEmpty, trim } from 'validator';

import { ApolloError } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import { CompanyCategorization } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useListCategoriesQuery from '../../../hooks/useListCategoriesQuery';
import useUpdateCategorization from '../tableUtils/UpdateCategorizationHook';

import AddCategoriesDialogStyles from './AddCategoriesDialogStyles';
import ErrorMessage, { processError } from './ErrorMessage';
import MultilevelCategorizations from './MultilevelCategorizations';
import { CreateCategorizationInput } from './types';

type MultilevelCategorizationsDialogProps = {
  classes: Classes<typeof AddCategoriesDialogStyles>;
  categorization: CompanyCategorization['categorization'] | ProjectCategorization['categorization'];
  categorizationInput?: CreateCategorizationInput;
  onClose: () => void;
  onClickReplace?: () => void;
  setCategorizationInput?: (val: string, inputType: 'name' | 'description') => void;
  projectId?: UUID;
  viewOnly?: boolean;
  contentOnly?: boolean;
};

const MultilevelCategorizationsDialog: FC<MultilevelCategorizationsDialogProps> = ({
  classes,
  categorization,
  categorizationInput,
  projectId,
  setCategorizationInput,
  onClose,
  onClickReplace,
  viewOnly,
  contentOnly,
}) => {
  const dialogSizeRef = useRef(null);
  const [description, setDescription] = useState(categorization?.description);
  const [errorMessage, setErrorMessage] = useState('');
  const updateCategorization = useUpdateCategorization(
    categorization ? categorization.id : '',
    setErrorMessage,
    (e: ApolloError) => setErrorMessage(processError(e)),
    projectId
  );
  const sendAnalytics = useSendAnalyticsEventHook();

  const columns: string[] = [];
  const metadata = categorization?.importMetadata || categorizationInput?.metadata;
  if (metadata) {
    const { columnLevel1: c1, columnLevel2: c2, columnLevel3: c3, columnLevel4: c4 } = metadata;
    if (c1) columns.push(c1);
    if (c2) columns.push(c2);
    if (c3) columns.push(c3);
    if (c4) columns.push(c4);
  }

  const categories = useListCategoriesQuery(categorization?.id, '', 1).data?.category;

  return (
    <div className={classes.innerPaper}>
      <div>
        {!contentOnly && (
          <div>
            <div className={classes.titleContainer}>
              <div>
                <Typography variant="title">
                  {viewOnly ? 'View Categorization' : 'Edit Categorization'}
                </Typography>
              </div>
              <IconButton title="Close dialog" className={classes.close}>
                <Close
                  onClick={() => {
                    onClose();
                    sendAnalytics(
                      categorizationEvent(
                        viewOnly
                          ? CategorizationEvent.VIEW_ML_CLOSE
                          : CategorizationEvent.EDIT_ML_CLOSE
                      )
                    );
                  }}
                />
              </IconButton>
            </div>
            <Divider />
          </div>
        )}
      </div>
      <DialogContent className={classes.content}>
        <div ref={dialogSizeRef}>
          <div>
            <Typography variant="caption">Name*</Typography>
            <TextField
              name="name"
              placeholder="Enter a name..."
              InputProps={{ disableUnderline: true, autoComplete: 'off' }}
              defaultValue={categorization?.name || categorizationInput?.name}
              onChange={(evt) => {
                setCategorizationInput?.(evt.target.value, 'name');
                if (isEmpty(evt.target.value, { ignore_whitespace: true })) {
                  setErrorMessage('Name cannot be empty');
                } else {
                  setErrorMessage('');
                }
              }}
              onBlur={(evt) => {
                const name = evt.target.value;
                if (
                  !isEmpty(name, { ignore_whitespace: true }) &&
                  categorization?.id &&
                  categorization?.name !== name
                ) {
                  updateCategorization({ name: trim(name) });
                }
              }}
              onKeyPress={(evt) => {
                if (evt.key === 'Enter') {
                  if (evt.target instanceof HTMLElement) {
                    evt.target.blur();
                  }
                  evt.stopPropagation();
                  evt.preventDefault();
                }
              }}
              disabled={viewOnly}
            />
          </div>
          <div className={classes.categoryPanel}>
            <Typography variant="caption">Description</Typography>
            <TextField
              fullWidth
              name="description"
              placeholder={viewOnly ? '' : 'Enter a description...'}
              InputProps={{ disableUnderline: true, autoComplete: 'off' }}
              value={description || categorizationInput?.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
                setCategorizationInput?.(event.target.value, 'description');
              }}
              onBlur={() => {
                const trimmed = description?.trim();
                if (trimmed !== categorization?.description && categorization?.id) {
                  updateCategorization({ description: trimmed });
                  if (trimmed) {
                    setCategorizationInput?.(trimmed, 'description');
                  }
                } else {
                  setDescription(
                    categorization?.description || categorizationInput?.description || ''
                  );
                }
              }}
              disabled={viewOnly}
            />
          </div>
          <ErrorMessage message={errorMessage} />
          <div className={classes.categoryPanel}>
            {categories && (
              <MultilevelCategorizations
                key={categorization?.id}
                categorizationID={categorization && categorization.id}
                categories={categories}
                onClickReplace={onClickReplace}
                onExpandCollapse={() => {
                  sendAnalytics(
                    categorizationEvent(
                      viewOnly
                        ? CategorizationEvent.VIEW_ML_EXPAND_COLLAPSE
                        : CategorizationEvent.EDIT_ML_EXPAND_COLLAPSE,
                      {
                        projectID: projectId,
                      }
                    )
                  );
                }}
                viewOnly={viewOnly}
              />
            )}
          </div>
          {columns.length > 0 && (
            <div className={classes.metadataContainer}>
              <Typography variant="caption">Estimate Import Mapping</Typography>
              <div className={classes.metadataContent}>{columns.join(' > ')}</div>
            </div>
          )}
        </div>
      </DialogContent>
      {!contentOnly && (
        <DialogActions className={classes.action}>
          <Typography variant="body2" className={classes.left}>
            <GetApp className={`${classes.icon} ${classes.leftIconPosition}`} />
            <button
              className={classes.linkButton}
              type="button"
              onClick={() => {
                joinAPI.exportCategories(categorization.id, undefined, [categorization.name]);
                sendAnalytics(
                  categorizationEvent(
                    viewOnly
                      ? CategorizationEvent.VIEW_ML_DOWNLOAD
                      : CategorizationEvent.EDIT_ML_DOWNLOAD
                  )
                );
              }}
            >
              Download categorization details
            </button>
          </Typography>

          <Button
            data-cy="JoinGridAddCategoriesDialog-doneButton"
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              sendAnalytics(
                categorizationEvent(
                  viewOnly ? CategorizationEvent.VIEW_ML_DONE : CategorizationEvent.EDIT_ML_SAVE
                )
              );
            }}
            disabled={!categorization.name}
          >
            Done
          </Button>
        </DialogActions>
      )}
    </div>
  );
};

export default withStyles(AddCategoriesDialogStyles)(MultilevelCategorizationsDialog);
