import * as React from 'react';

import { GridVariant } from '../../../actions/gridAnalytics';
import { STRING_CELL } from '../../../constants';
import { GetCategorizationQuery } from '../../../generated/graphql';
import { EventProperties } from '../../../hooks/useAnalyticsEventHook';
import { createCategories, deleteCategory, editCategories } from '../hooks/categorizationMutation';
import { CATEGORIZATION_TABLE_BODY_HEIGHT } from '../style/styleConstants';
import { GenericGridState, Position } from '../types';
import { compareValues, isRegularCell, serializeAPICell } from '../utilities/cell';
import { addColumnsErrors } from '../utilities/data';
import range from '../utilities/range';

import {
  addLinesToTable,
  asynchronousPaste,
  deleteLineFromTable,
  newGenericGridState,
  scrollToBottom,
  setLineCellValue,
  updateCellIndexed,
  withGridReflow,
} from './editing';
import { getCellValue } from './selecting';
import { normalizeRectangle } from './sizing';

const ID_PREFIX = 'cat';

type EmptyFunction = () => void;

type CategorizationGridState = GenericGridState & {
  projectID?: UUID;
  categorization?: GetCategorizationQuery['categorization'];
  nextID: () => string;
};

const emptyCategoryLine = (id: string, i: number): GridLine => ({
  id,
  isDisabled: false, // applies only to inheritedMarkups
  orderingNumerator: String(i),
  orderingDenominator: String(1),
  cells: [
    { value: { string: '', formula: '', formulaDisplay: [] } }, // number
    { value: { string: '', formula: '', formulaDisplay: [] } }, // name
  ],
});

const newCategoryLine = (
  category: { id: UUID; number: string; name: string },
  i: number
): GridLine => ({
  id: category.id,
  isDisabled: false, // applies only to inheritedMarkups
  orderingNumerator: String(i),
  orderingDenominator: String(1),
  cells: [
    { value: { string: category.number, formula: '', formulaDisplay: [] } }, // number
    { value: { string: category.name, formula: '', formulaDisplay: [] } }, // name
  ],
});

export const newCategorizationGridState = (
  readOnly: boolean,
  onCloseRef: React.MutableRefObject<EmptyFunction>,
  sendAnalytics: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  onSuccess: () => void,
  variant: GridVariant,
  categorization?: GetCategorizationQuery['categorization'],
  projectID?: UUID
): CategorizationGridState => {
  const columns = [
    {
      type: STRING_CELL,
      name: 'Category',
      id: 'Category',
      placeholder: readOnly ? '' : 'Add Category Name...',
    },
    {
      type: STRING_CELL,
      name: 'Description (optional)',
      id: 'Description',
      placeholder: readOnly ? '' : 'Add Description...',
    },
  ];

  let nextIDValue = 0;
  const nextID = () => {
    const next = `${ID_PREFIX}-${nextIDValue}`;
    nextIDValue += 1;
    return next;
  };

  let lines = [emptyCategoryLine(nextID(), 0)];
  if (categorization?.content && categorization.content.length > 0) {
    lines = categorization.content.map(newCategoryLine);
  }

  const hasMarkupCheckboxButton = false;
  addColumnsErrors(columns, lines.length);
  const data = {
    columns,
    lines,
    readOnly,
    hasMarkupCheckboxButton,
  };

  const state = newGenericGridState(
    data,
    CATEGORIZATION_TABLE_BODY_HEIGHT,
    undefined,
    () => {},
    variant
  );
  const result: CategorizationGridState = {
    ...state,
    projectID,
    categorization,
    nextID,
  };

  if (categorization) {
    // eslint-disable-next-line no-param-reassign
    onCloseRef.current = () => dispatchCategorizationUpdate(result, sendAnalytics, onSuccess);
  }

  return result;
};

const parseValues = (
  state: CategorizationGridState,
  values: (GridCellValue | undefined)[],
  offset: number
) => {
  const id = state.nextID();
  if (offset === 1) {
    const [name] = values;
    return { id, number: '', name: (name && isRegularCell(name) && name.string) || '' };
  }
  const [number, name] = values;
  return {
    id,
    number: (number && isRegularCell(number) && number.string) || '',
    name: (name && isRegularCell(name) && name.string) || '',
  };
};

const parseLine = (line: GridLine): CategoryContentInput => {
  const [number, name] = line.cells;
  return {
    number: number && number.value && 'string' in number.value ? number.value.string : '',
    name: name && name.value && 'string' in name.value ? name.value.string : '',
  };
};

export const mutateCategories = (
  state: CategorizationGridState,
  start: Position,
  end: Position,
  values: (GridCellValue | undefined)[][]
) => {
  const { data } = state;
  const [s, e] = normalizeRectangle(state, start, end);
  withGridReflow(state, () => {
    for (let i = s.row; i <= e.row; i += 1) {
      const input = parseLine(data.lines[i]);
      for (let j = s.column; j <= e.column; j += 1) {
        const { type } = data.columns[j];
        const newValue = values[i - s.row][j - s.column];
        const currentValue = getCellValue(state, i, j);

        // Might be an empty string, so we check undefined directly.
        // We don't send updates if the value is identical to the previous one (no change)
        if (newValue !== undefined && !compareValues(type, newValue, currentValue)) {
          const value = serializeAPICell(type, newValue);
          if (j === 0) input.number = value;
          if (j === 1) input.name = value;

          setLineCellValue(state, i, j, newValue);
          updateCellIndexed(state, i, j, { value: newValue });
        }
      }
    }
  });
  scanAndAddErrors(state);
};

export const addCategories = (
  state: CategorizationGridState,
  n: number,
  offset: number,
  input?: (GridCellValue | undefined)[][],
  onSuccess?: () => void
) => {
  const rows = state.data.lines.length;
  const lines: GridLine[] = input
    ? input.map((v, i) => newCategoryLine(parseValues(state, v, offset), rows + i))
    : createNewCategoryNames(state, n).map((v, i) => newCategoryLine(v, rows + i));

  addLinesToTable(state, lines, () => scanAndAddErrors(state));

  if (onSuccess) onSuccess();
};

export const removeCategory = (state: CategorizationGridState, index: number) => {
  const {
    data: { lines },
  } = state;

  if (!lines[index]) return;
  deleteLineFromTable(state, index);
};

export const removeCategories = (state: CategorizationGridState) => {
  const { isRowSelectedArr } = state;

  // Optimistically remove the line from the UI.
  for (let i = isRowSelectedArr.length - 1; i >= 0; i -= 1) {
    if (isRowSelectedArr[i]) deleteLineFromTable(state, i);
  }
};

export const pasteClipboard = (state: CategorizationGridState, start: Position) =>
  asynchronousPaste(state, start, mutateCategories, (state, _, number, inputs) =>
    addCategories(state, number, start.column, inputs, () => scrollToBottom(state))
  );

const createNewCategoryNames = (state: CategorizationGridState, n: number) =>
  range(0, n, () => ({ id: state.nextID(), number: '', name: '' }));

const scanAndAddErrors = (state: CategorizationGridState) => {
  const { data, cellData } = state;
  const numbers: { [key: string]: boolean } = {};
  for (let i = 0; i < Math.min(data.lines.length, cellData.length); i += 1) {
    const { number, name } = parseLine(data.lines[i]);
    if (numbers[number]) {
      updateCellIndexed(state, i, 0, { error: `${number} already defined` });
    } else if (number.length === 0 && name.length > 0) {
      updateCellIndexed(state, i, 0, { error: `Category name can't be empty` });
    } else {
      const lineOfCells = cellData[i];
      if (lineOfCells) {
        if (number !== '') {
          lineOfCells[0].data.error = '';
          numbers[number] = true;
        }
      }
    }
  }
};

// Diff the current state of the table against the original state of the categorization
// when editing began, and dispatch all of the appropriate updates.
const dispatchCategorizationUpdate = (
  state: CategorizationGridState,
  sendAnalytics: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  onSuccess: () => void
) => {
  const { projectID, categorization, data, cellData } = state;

  const stillPresent: { [key: string]: boolean } = {};
  const originalCategories: { [key: string]: Pick<Category, 'name' | 'number'> } = {};
  if (categorization?.content) {
    categorization.content.forEach((category) => {
      stillPresent[category.id] = false;
      originalCategories[category.id] = category;
    });
  }

  const toCreate: CategoryContentInput[] = [];
  const toEdit: EditCategoryInput[] = [];
  const toDelete: UUID[] = [];

  for (let i = 0; i < Math.min(data.lines.length, cellData.length); i += 1) {
    const line = data.lines[i];
    const isOriginal = !line.id.startsWith(ID_PREFIX);
    // Don't dispatch any updates to errored cells.
    const lineOfCells = cellData[i];
    if (lineOfCells && lineOfCells[0].data.error.length !== 0) {
      if (isOriginal) {
        // Mark as true so it's not wiped
        stillPresent[line.id] = true;
      }
      // Otherwise, don't create it
    } else if (isOriginal) {
      const { number, name } = parseLine(line);
      // If the number isn't empty, we edit. Otherwise,
      // We don't mark it as still here, so it is slated for deletion.
      if (number !== '') {
        stillPresent[line.id] = true;
        const original = originalCategories[line.id];
        if (number !== original.number || name !== original.name) {
          toEdit.push({ id: line.id, number, name });
        }
      }
    } else {
      const category = parseLine(line);
      if (category.number !== '') {
        toCreate.push(category);
      }
    }
  }

  const keys = Object.keys(stillPresent);
  // eslint-disable-next-line no-restricted-syntax
  for (const id of keys) {
    if (!stillPresent[id]) toDelete.push(id);
  }
  if (categorization?.id) {
    if (toCreate.length > 0) {
      createCategories(categorization.id, toCreate, sendAnalytics, projectID);
    }
    if (toEdit.length > 0) {
      editCategories(categorization.id, toEdit, projectID);
    }
    // TODO: batch this
    toDelete.forEach((id) => {
      deleteCategory(id, categorization.id, projectID);
    });
  }
  onSuccess();
};
