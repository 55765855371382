import { FC } from 'react';

import { OTHER_PROJECT_TYPES } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import DataTable, { TableHeader, TableRow } from '../../../Tables/DataTable/DataTable';
import {
  PieChartData,
  formatPieChartHintTableData,
  formatPieChartHintTableHeaderData,
} from '../ChartsPieChartUtils';

import ChartsPieChartHintStyles from './ChartsPieChartHintStyles';

type ChartsPieChartHintProps = {
  classes: Classes<typeof ChartsPieChartHintStyles>;
  data: PieChartData[] | null;
  hintCaption: string;
  isCost?: boolean;
  value: PieChartData[];
};

const ChartsPieChartHint: FC<ChartsPieChartHintProps> = ({
  classes,
  data,
  hintCaption,
  isCost = false,
  value,
}) => {
  const isOtherSection = value.length > 1;
  // data to reference for calculating section value and percent of the whole chart
  const hintData = isOtherSection ? value : data;

  const tableHeaderData: TableHeader = formatPieChartHintTableHeaderData(hintCaption);

  const tableRowData: TableRow[] = value.map((row) =>
    formatPieChartHintTableData(row, hintData, isCost)
  );

  return (
    <>
      {isOtherSection && <div className={classes.title}>{OTHER_PROJECT_TYPES}</div>}
      <DataTable tableHeaderData={tableHeaderData} tableRowData={tableRowData} isHint />
    </>
  );
};

export default withStyles(ChartsPieChartHintStyles)(ChartsPieChartHint);
