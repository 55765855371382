import { FC, useMemo, useState } from 'react';
import 'react-vis/dist/style.css';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Edit } from '@material-ui/icons';

import { reportCostTrend } from '../../../../analytics/analyticsEventProperties';
import { hasTrendVar } from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { ALL_MILESTONES, DEFAULT_SELECTION, MILESTONE, TOTAL } from '../../../../constants';
import { CostReportColumnType, PermissionResource } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { FilterManager } from '../../../FilterPanel/filterUtils';
import UnitToggle from '../../../Inputs/UnitToggle/UnitToggle';
import { getNonZeroQuantities } from '../../../Inputs/UnitToggle/UnitToggleUtils';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { useMilestonesQuery } from '../../../Milestones/hooks';
import { Button } from '../../../scales';
import MilestoneSelect from '../../../Select/MilestoneSelect';
import IconMenuIcon from '../../../Select/SelectMenu/IconMenuIcon';
import { SelectVariants } from '../../../Select/SelectMenu/SelectStyles';
import { MenuVariants } from '../../../Select/SelectTextMenu/SelectTextMenu';
import DashboardAnchor from '../../DashboardAnchor';
import DashboardFilterDescription from '../../DashboardFilterDescription/DashboardFilterDescription';
import { DASHBOARD_DEFAULTS } from '../../DashboardUtils';

import DashboardChartsTrendActiveMilestone from './DashboardChartsTrendActiveMilestone';
import DashboardChartsTrendAllMilestones from './DashboardChartsTrendAllMilestones';
import DetailToggle, { DETAILED } from './DashboardChartsTrendDetailToggle';
import styles from './DashboardChartsTrendStyles';

type DashboardChartsTrendProps = {
  activeMilestoneId: UUID;
  categorizations?: Categorization[];
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  filterManager?: FilterManager;
  isFiltered?: boolean;
  isPrint?: boolean;
  setIsLoading?: (loading: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting?: (param: any, value: any) => void;
  settings: typeof DASHBOARD_DEFAULTS;
  viewFilter: ViewFilterInput;
};

export const dashboardColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];

const DashboardChartsTrend: FC<DashboardChartsTrendProps> = ({
  activeMilestoneId,
  categorizations,
  classes,
  clearFilters,
  filterManager,
  isFiltered = false,
  isPrint = false,
  setIsLoading = () => {},
  setSetting = () => {},
  settings,
  viewFilter,
}) => {
  const navigate = useNavigate();

  const projectId = getProjectIdFromUrl();
  const { trendUnit } = settings;
  const setQuantity = (q: Quantity | undefined) => {
    setSetting('trendUnit', (q && q.unit && q.unit.abbreviationSingular) || TOTAL);
  };

  // TODO: Add quantity to persistent settimgs
  const { data: { quantities = emptyQuantities } = {} } =
    useMilestoneQuantitiesQuery(activeMilestoneId);

  const nonZeroQuantities = getNonZeroQuantities(quantities);
  const quantity =
    nonZeroQuantities && nonZeroQuantities.find((q) => trendUnit === q.unit.abbreviationSingular);
  const unitID: UUID | undefined = quantity && quantity.unit.id;

  // STATE
  const [showDialogsHide, setShowDialogsHide] = useState(false);
  const onClose = () => setShowDialogsHide(false);
  const { costTrendTimeFrame, detailLevel } = settings;
  const setDetailLevel = (newLevel: string) => {
    setSetting('detailLevel', newLevel);
  };
  const hasTrend = useReactiveVar(hasTrendVar);

  // MILESTONES
  const { data: { milestones = [] } = {} } = useMilestonesQuery(projectId, false);
  const activeMilestone = useMemo(
    () => milestones.filter((m: { id: string }) => m.id === activeMilestoneId)[0],
    [milestones, activeMilestoneId]
  );

  // PERMISSIONS
  const { canEdit } = usePermissions();
  const canEditMilestones = canEdit(PermissionResource.MILESTONES);

  // ANALYTICS
  const sendAnalytics = useAnalyticsEventHook();
  const reportTrendTimeFrameAnalytics = (charts: string, trigger: string) =>
    sendAnalytics(reportCostTrend(charts, trigger));

  // FUNCTIONS
  const setCostTrendTimeFrameAll = (newValue: string) => {
    setSetting('costTrendTimeFrame', newValue);
    reportTrendTimeFrameAnalytics('costTrendTimeFrame', newValue);
  };

  // EDIT BUTTON CONDITIONS
  const isSuperChart = costTrendTimeFrame === ALL_MILESTONES;
  const canShowHide = !isSuperChart || detailLevel === DETAILED;
  const buttonHint = canShowHide
    ? 'Show/hide changes in this chart'
    : `Switch to ${DETAILED} to show/hide changes`;

  // COMPONENTS
  const dashboardTypeSelector = (
    <div className="flex items-end gap-2">
      <Button
        type="tertiary"
        label="Item Activity Feed"
        data-cy="item-activity-feed-button"
        onClick={() => {
          navigate(generateSharedPath(JoinProjectRoutes.ITEM_ACTIVITY_FEED, { projectId }));
        }}
      />
      {hasTrend && canEditMilestones && !isPrint && (
        <div className="print:hidden">
          <IconMenuIcon
            disabled={!canShowHide}
            handleClick={() => setShowDialogsHide(true)}
            icon={<Edit />}
            cy="button-editTrendline"
            id="menu-button"
            iconName="menu"
            variant={SelectVariants.MENU}
            title={buttonHint}
          />
        </div>
      )}
      {isSuperChart && (
        <div className="flex flex-col gap-0.5">
          <div className="type-label">Display</div>
          <DetailToggle detailLevel={detailLevel} setDetailLevel={setDetailLevel} />
        </div>
      )}
      {nonZeroQuantities.length > 0 && (
        <UnitToggle
          quantity={quantity}
          setQuantity={setQuantity}
          quantities={nonZeroQuantities}
          variant={MenuVariants.INLINE}
        />
      )}
      <div className="flex flex-col gap-0.5">
        <div className="type-label">Milestone</div>
        <MilestoneSelect
          milestones={activeMilestone ? [activeMilestone] : []}
          selectedMilestone={
            costTrendTimeFrame === MILESTONE ? (activeMilestone || {}).id : DEFAULT_SELECTION
          }
          onChangeMilestone={(ID: UUID | null) =>
            setCostTrendTimeFrameAll(ID ? MILESTONE : ALL_MILESTONES)
          }
          defaultSelection={ALL_MILESTONES}
        />
      </div>
    </div>
  );

  const header = () =>
    !isPrint ? (
      <div className={classes.header}>
        <div className={classes.topBar}>
          <DashboardAnchor anchor="Cost Trendline" />
          <DashboardFilterDescription
            isFiltered={isFiltered}
            filterManager={filterManager}
            categorizations={categorizations || []}
          />
        </div>
        {dashboardTypeSelector}
      </div>
    ) : (
      <div className={classes.printChartTitle}>Cost Trendline</div>
    );

  const content = () =>
    isSuperChart ? (
      <DashboardChartsTrendAllMilestones
        clearFilters={clearFilters}
        onClose={onClose}
        setIsLoading={setIsLoading}
        settings={settings}
        showDialogsHide={showDialogsHide}
        unitID={unitID}
        viewFilter={viewFilter}
      />
    ) : (
      <DashboardChartsTrendActiveMilestone
        activeMilestoneId={activeMilestoneId}
        clearFilters={clearFilters}
        isFiltered={isFiltered}
        isPrint={isPrint}
        onClose={onClose}
        setIsLoading={setIsLoading}
        settings={settings}
        showDialogsHide={showDialogsHide}
        unitID={unitID}
        viewFilter={viewFilter}
      />
    );

  return (
    <div
      className={`${classes.root} ${isPrint ? classes.printArea : classes.itemArea}`}
      data-cy="dashboard-CostTrendlineChart"
    >
      {header()}
      {content()}
    </div>
  );
};

export default withStyles(styles)(DashboardChartsTrend);
