import {
  commonCategorizationFields,
  commonCategoryUniqueFields,
  commonCollaboratorNoAssetFields,
  commonCompanyRoleFields,
  commonCostReportColumnFields,
  commonEstimateFields,
  commonProjectFields,
  commonProjectPropsFields,
  commonRoleFields,
  commonUserFields,
  commonUserNotificationSettingFields,
  fullCategoryCategorizationFields,
  gql,
} from '../api/graphqlFragments';
import { commonCompanyUserFieldsQuery } from '../components/login/queries';

export const analyticsPropertiesQuery = gql`
  query analyticsProperties($projectId: UUID, $previewRoleId: UUID, $previewUserId: UUID) {
    analyticsProperties(
      projectID: $projectId
      previewRoleID: $previewRoleId
      previewUserID: $previewUserId
    ) {
      userProperties {
        userEmail
        userId
        userIsEmailVerified
        userJobTitle
        userName
        userSignUpAt
      }
      companyProperties {
        companyId
        companyName
        companyType
        companyDomain
        isIndependent
      }
      projectProperties {
        projectId
        projectLocation
        projectName
        projectStatus
      }
      permissionsProperties {
        previewMode
        previewRole
        previewRoleId
        previewUserEmail
        previewUserId
        userRole
      }
      groupProperties {
        groupId
        userId
        projectId
        companyId
      }
    }
  }
`;

export const currentCollaborator = gql`
  query currentCollaborator($projectID: UUID!) {
    currentCollaborator(projectID: $projectID) {
      ...commonCollaboratorNoAssetFields
    }
  }
  ${commonCollaboratorNoAssetFields}
`;

export const currentCompanyRole = gql`
  query currentCompanyRole {
    currentCompanyRole {
      ...commonCompanyRoleFields
    }
  }
  ${commonCompanyRoleFields}
`;

export const listProjectCategorizationsQuery = gql`
  query listProjectCategorizations($projectID: UUID!, $includeDisabled: Boolean) {
    projectCategorizations(projectID: $projectID, includeDisabled: $includeDisabled) {
      projectID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
      }
      ordering
      disabled
    }
  }
  ${commonCategorizationFields}
`;

export const projectCategorizationsMetadataQuery = gql`
  query ProjectCategorizationsMetadata($projectID: UUID!, $includeDisabled: Boolean) {
    projectCategorizations(projectID: $projectID, includeDisabled: $includeDisabled) {
      projectID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
        exampleCategories {
          id
          number
          name
          level
        }
      }
      ordering
      disabled
    }
  }
  ${commonCategorizationFields}
`;

export const standardCategorizationsQuery = gql`
  query StandardCategorizations($projectID: UUID!) {
    standardCategorizations(projectID: $projectID) {
      projectID
      categorization {
        ...commonCategorizationFields
        builtin
        createdFrom {
          id
          deprecated
        }
      }
      ordering
      disabled
    }
  }
  ${commonCategorizationFields}
`;

export const categoryCombinationsQuery = gql`
  query categoryCombinations($searches: [CategorySearch!]!, $estimateID: UUID) {
    categoryCombinations(searches: $searches, estimateID: $estimateID) {
      categories {
        ...fullCategoryCategorizationFields
      }
    }
  }
  ${fullCategoryCategorizationFields}
`;

export const categoryCombinationCountsQuery = gql`
  query categoryCombinationCounts($searches: [CategorySearch!]!) {
    categoryCombinationCounts(searches: $searches) {
      categorizationID
      count
    }
  }
`;

export const getProjectPropsQuery = gql`
  query getProjectProps($id: UUID!) {
    project(id: $id) {
      ...commonProjectPropsFields
    }
  }
  ${commonProjectPropsFields}
`;

export const companyProjectTypes = gql`
  query companyProjectTypes($companyID: UUID!, $includeHidden: Boolean!) {
    companyProjectTypes(companyID: $companyID, includeHidden: $includeHidden) {
      id
      name
      parentID
      hidden
    }
  }
`;

export const getProjectsWithTypeQuery = gql`
  query getProjectsWithType($typeID: UUID!) {
    getProjectsWithType(typeID: $typeID) {
      name
      projectID
      typeID
      variant
    }
  }
`;

export const getAllProjectTypesQuery = gql`
  query getProjectTypes($projectID: UUID!) {
    getProjectTypes(projectID: $projectID) {
      id
      name
      parentID
      hidden
    }
  }
`;

export const getProjectDeliveryTypes = gql`
  query projectDeliveryTypes {
    projectDeliveryTypes {
      id
      name
      abbreviation
    }
  }
`;

export const milestoneCostReportsQuery = gql`
  query milestoneCostReports(
    $milestones: [CostReportMilestoneInput!]!
    $columns: [CostReportColumnInput!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $viewFilter: ViewFilter!
  ) {
    milestoneCostReports(
      parameters: {
        milestones: $milestones
        columns: $columns
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        viewFilter: $viewFilter
        useDraftEstimate: false
        useDraftBudget: false
      }
    ) {
      milestoneID
      costReportColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${commonCostReportColumnFields}
`;

export const createProjectMutation = gql`
  mutation createProject(
    $name: String!
    $code: String
    $milestone: String!
    $milestoneDate: Time!
    $milestoneDesignPhaseID: UUID!
    $typeID: UUID
    $location: String!
    $lat: Float
    $lon: Float
    $statusID: UUID!
    $currency: String
    $templateID: UUID
    $projectDeliveryTypeID: UUID
    $locationDetails: LocationDetailsInput!
  ) {
    createProject(
      name: $name
      code: $code
      milestone: $milestone
      milestoneDate: $milestoneDate
      milestoneDesignPhaseID: $milestoneDesignPhaseID
      typeID: $typeID
      location: $location
      lat: $lat
      lon: $lon
      statusID: $statusID
      currency: $currency
      templateID: $templateID
      projectDeliveryTypeID: $projectDeliveryTypeID
      locationDetails: $locationDetails
    ) {
      ...commonProjectFields
    }
  }
  ${commonProjectFields}
`;

export const listSubcategoriesQuery = gql`
  query listSubcategories($search: SubcategorySearch!) {
    subcategory(search: $search) {
      ...commonCategoryUniqueFields
    }
  }
  ${commonCategoryUniqueFields}
`;

// USER

export const loadUserQuery = gql`
  query loadUser($userEmail: String!) {
    user(email: $userEmail) {
      ...commonUserFields
      status
    }
  }
  ${commonUserFields}
`;

export const loadCurrentUserStatusQuery = gql`
  query loadCurrentUserStatus($email: String!) {
    user(email: $email) {
      status
    }
  }
`;

// USER Notification Setting
export const loadUserNotificationSettingQuery = gql`
  query loadUserNotificationSetting($projectID: UUID!) {
    userNotificationSetting(projectID: $projectID) {
      ...commonUserNotificationSettingFields
    }
  }
  ${commonUserNotificationSettingFields}
`;

// USER Browser Notification Setting
export const loadUserInAppNotificationSettingQuery = gql`
  query loadUserInAppNotificationSetting($projectID: UUID!) {
    userInAppNotificationSetting(projectID: $projectID) {
      ...commonUserNotificationSettingFields
    }
  }
  ${commonUserNotificationSettingFields}
`;

export const toggleEventDataVisibilityMutation = gql`
  mutation toggleEventDataVisibility($projectID: UUID!, $visible: Boolean!, $events: [UUID!]!) {
    toggleEventDataVisibility(projectID: $projectID, visible: $visible, events: $events) {
      visible
      events
    }
  }
`;

// ROLES
export const loadProjectRolesQuery = gql`
  query loadProjectRoles($projectId: UUID!) {
    projectRoles(project: $projectId) {
      ...commonRoleFields
    }
  }
  ${commonRoleFields}
`;

export const loadProjectRoleQuery = gql`
  query loadProjectRole($projectID: UUID!, $roleID: UUID!) {
    projectRole(projectID: $projectID, roleID: $roleID) {
      ...commonRoleFields
    }
  }
  ${commonRoleFields}
`;

export const resendInviteMutation = gql`
  mutation resendInvite($inviteeUserID: UUID!, $projectID: UUID, $message: String) {
    resendInvite(inviteeUserID: $inviteeUserID, projectID: $projectID, message: $message) {
      ...commonCompanyUserFieldsQuery
    }
  }
  ${commonCompanyUserFieldsQuery}
`;

export const updateProjectMutation = gql`
  mutation updateProject(
    $projectID: UUID!
    $activeMilestoneID: UUID
    $name: String
    $code: String
    $description: String
    $descriptionStyled: String
    $thumbnail: String
    $location: String
    $lat: Float
    $lon: Float
    $statusID: UUID
    $team: TeamInput
    $projectDeliveryTypeID: UUID
    $locationDetails: LocationDetailsInput
  ) {
    updateProject(
      project: {
        id: $projectID
        activeMilestoneID: $activeMilestoneID
        name: $name
        code: $code
        description: $description
        descriptionStyled: $descriptionStyled
        thumbnail: $thumbnail
        location: $location
        lat: $lat
        lon: $lon
        statusID: $statusID
        team: $team
        projectDeliveryTypeID: $projectDeliveryTypeID
        locationDetails: $locationDetails
      }
    ) {
      ...commonProjectFields
    }
  }
  ${commonProjectFields}
`;

export const listCustomCategorizations = gql`
  query listCustomCategorizations($projectID: UUID!) {
    customCategorizations(projectID: $projectID) {
      id
      name
      builtin
      content {
        id
        name
        number
      }
    }
  }
`;

export const listDraftCategoriesQuery = gql`
  query listDraftCategories($estimateID: UUID!) {
    draftCategorizations(estimateID: $estimateID) {
      ...commonCategorizationFields
    }
  }
  ${commonCategorizationFields}
`;

export const featureFlagQuery = gql`
  query featureFlag($projectID: UUID!, $userEmail: String!) {
    loadFeatureFlags(projectID: $projectID, userEmail: $userEmail)
  }
`;

export const moduleEntitlementsQuery = gql`
  query moduleEntitlements($companyID: UUID) {
    loadModuleEntitlementFlags(companyID: $companyID)
  }
`;

export const estimateQuery = gql`
  query estimate(
    $estimateID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...commonEstimateFields
    }
  }
  ${commonEstimateFields}
`;
