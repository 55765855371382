import { FC } from 'react';

import { GetDecisionSummaryQuery, Status } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import DataTable, { TableHeader, TableRow } from '../../Tables/DataTable/DataTable';
import ExecutiveDashboardDataHeading from '../ExecutiveDashboardDataHeading/ExecutiveDashboardDataHeading';
import {
  STATUSES_CHANGED_THIS_WEEK,
  decisionSummaryTableHeaderData,
  formatDecisionSummaryTableRow,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardDecisionSummaryStyles from './ExecutiveDashboardDecisionSummaryStyles';

type ExecutiveDashboardDecisionSummaryProps = {
  classes: Classes<typeof ExecutiveDashboardDecisionSummaryStyles>;
  decisionSummary: GetDecisionSummaryQuery['decisionSummary'] | null;
};

const ExecutiveDashboardDecisionSummary: FC<ExecutiveDashboardDecisionSummaryProps> = ({
  classes,
  decisionSummary,
}) => {
  const {
    acceptedCount = 0,
    acceptedCost = 0,
    incorporatedCount = 0,
    incorporatedCost = 0,
    notApplicableCount = 0,
    notApplicableCost = 0,
    pendingCount = 0,
    pendingCost = 0,
    rejectedCount = 0,
    rejectedCost = 0,
    totalItemCount = 0,
  } = decisionSummary || {};

  const changeCount =
    acceptedCount + incorporatedCount + notApplicableCount + pendingCount + rejectedCount;
  const displayValue = `${formatCommas(changeCount)} of ${formatCommas(totalItemCount)}`;

  const tableHeaderData: TableHeader = decisionSummaryTableHeaderData;

  const tableRowData: TableRow[] = [
    formatDecisionSummaryTableRow(
      Status.ACCEPTED,
      acceptedCount,
      acceptedCost,
      classes.iconContainer
    ),
    formatDecisionSummaryTableRow(
      Status.INCORPORATED,
      incorporatedCount,
      incorporatedCost,
      classes.iconContainer
    ),
    formatDecisionSummaryTableRow(
      Status.NOTAPPLICABLE,
      notApplicableCount,
      notApplicableCost,
      classes.iconContainer
    ),
    formatDecisionSummaryTableRow(
      Status.REJECTED,
      rejectedCount,
      rejectedCost,
      classes.iconContainer
    ),
    formatDecisionSummaryTableRow(Status.PENDING, pendingCount, pendingCost, classes.iconContainer),
  ];

  return (
    <div className={classes.flexContainer}>
      <ExecutiveDashboardDataHeading
        label={STATUSES_CHANGED_THIS_WEEK}
        value={displayValue}
        tooltip={`${displayValue} items with status changes this week`}
      />
      <DataTable isHalfWidth tableHeaderData={tableHeaderData} tableRowData={tableRowData} />
    </div>
  );
};

export default withStyles(ExecutiveDashboardDecisionSummaryStyles)(
  ExecutiveDashboardDecisionSummary
);
