import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchEventTypes } from '../../../../../../analytics/analyticsEventProperties';
import { JoinRoutes } from '../../../../../../api/gqlEnums';
import {
  SearchProjectsQueryVariables,
  SearchResultType,
} from '../../../../../../generated/graphql';
import { generateSharedPath } from '../../../../../../utilities/routes/links';
import { pluralizeCountString } from '../../../../../../utilities/string';
import { Button } from '../../../../../scales';
import useCoreSearchPermissions from '../../../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../../hooks/useSendSearchAnalytics';
import { useSearchProjectsQuery } from '../../../projects/hooks/useSearchProjectsQuery';
import { DEFAULT_QUERY } from '../../../projects/hooks/useSearchProjectsQueryParams';
import { SearchToggleValue } from '../../../SearchToggle';
import {
  GlobalSearchMode,
  addToRecentSearches,
  deleteFromRecentSearches,
  getRecentSearches,
} from '../../GlobalSearchUtils';
import RecentProjectSearches from '../RecentSearches';

import RelevantProjects from './RelevantProjects';

interface ProjectContentProps {
  search: string;
}

const ProjectContent = (props: ProjectContentProps) => {
  const { hasAllProjectsAccess, hasAllResultsLinkAccess } = useCoreSearchPermissions();
  const sendSearchAnalytics = useSendSearchAnalytics();
  const navigate = useNavigate();

  const [searchTerms, setSearchTerms] = useState(getRecentSearches(GlobalSearchMode.ALL_PROJECTS));
  const deleteSearchTerm = useCallback((searchTerm: string) => {
    const updatedSearchHistory = deleteFromRecentSearches(
      searchTerm,
      GlobalSearchMode.ALL_PROJECTS
    );
    setSearchTerms(updatedSearchHistory);
  }, []);
  const addSearchTerm = useCallback((searchTerm: string) => {
    const updatedSearchHistory = addToRecentSearches(searchTerm, GlobalSearchMode.ALL_PROJECTS);
    setSearchTerms(updatedSearchHistory);
  }, []);

  const variables: SearchProjectsQueryVariables = {
    ...DEFAULT_QUERY,
    search: props.search,
    searchResultType:
      hasAllProjectsAccess && hasAllResultsLinkAccess
        ? SearchResultType.COMPANY
        : SearchResultType.MY,
    pagination: { limit: 25, offset: 0 },
  };
  const { data, previousData } = useSearchProjectsQuery(variables);
  const projects = data?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  const projectsCount = data?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0;

  const onNavigate = useCallback(
    (search: string) => {
      addSearchTerm(search);
      sendSearchAnalytics(searchEventTypes.SEARCH_ALL_CTA, {
        type: GlobalSearchMode.ALL_PROJECTS,
      });
      navigate(
        generateSharedPath(JoinRoutes.PROJECTS, {
          search: `?search=${search}&toggle=${
            hasAllProjectsAccess && hasAllResultsLinkAccess
              ? SearchToggleValue.ALL
              : SearchToggleValue.MY
          }`,
        })
      );
    },
    [addSearchTerm, hasAllProjectsAccess, hasAllResultsLinkAccess, navigate, sendSearchAnalytics]
  );

  const projectsButton = (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
      <Button
        label={`See ${pluralizeCountString('project', projectsCount)}`}
        onClick={() => onNavigate(props.search)}
        type="tertiary"
      />
    </div>
  );

  return (
    <>
      {props.search ? (
        <RelevantProjects projects={projects} onClick={() => addSearchTerm(props.search)} />
      ) : (
        <RecentProjectSearches
          onDelete={deleteSearchTerm}
          onNavigate={onNavigate}
          searches={searchTerms}
        />
      )}
      {projectsCount > 0 && projectsButton}
    </>
  );
};

export default ProjectContent;
