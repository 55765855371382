import { FC } from 'react';

import { Dialog, DialogContent, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import dialogsStyles from '../../Dialogs/DialogsStyles';

type MilestoneSettingsReplaceDialogProps = {
  classes: Classes<typeof dialogsStyles>;
  children: JSX.Element;
  open: boolean;
  term: string;
  onClose: () => void;
};

const MilestoneSettingsReplaceDialog: FC<MilestoneSettingsReplaceDialogProps> = ({
  classes,
  children,
  term,
  open,
  onClose,
}) => {
  return (
    <Dialog
      classes={{ paper: classes.replaceEstimateContainer }}
      disableBackdropClick
      open={open}
      onClose={onClose}
    >
      <div className={classes.titleContainer}>
        <div style={{ display: 'block' }}>
          <Typography className={classes.newTitleText}>Replace this {term}</Typography>
          <Typography>
            Replace the existing {term.toLowerCase()} by importing or starting a new draft.
          </Typography>
        </div>
        <IconButton title="Close dialog" className={classes.close}>
          <Close onClick={onClose} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.noPadding}>{children}</DialogContent>
    </Dialog>
  );
};

export default withStyles(dialogsStyles)(MilestoneSettingsReplaceDialog);
