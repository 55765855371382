import { useMutation } from '@apollo/client';

import { addCollaboratorAnalytics } from '../../../analytics/analyticsEventProperties';
import { REFETCH_COMPANIES } from '../../../api/refetchSets';
import {
  CollaboratorInput,
  CreateCollaboratorsMutation,
  CreateCollaboratorsMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { createCollaboratorsMutation } from './queries';

function useCreateCollaborators(refetchQueries: string[] = []) {
  const [createCollaboratorsFunc, mutationResult] = useMutation<
    CreateCollaboratorsMutation,
    CreateCollaboratorsMutationVariables
  >(createCollaboratorsMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectId: UUID,
    collaborators: CollaboratorInput[],
    message?: string,
    onSuccess?: (result: CreateCollaboratorsMutation['addCollaborators']) => void,
    onFailure?: () => void
  ) =>
    createCollaboratorsFunc({
      variables: { projectID: projectId, collaborators, message },
      refetchQueries: [...REFETCH_COMPANIES, ...refetchQueries],
      update: (cache, result) => {
        const addCollaborators = result.data?.addCollaborators || [];
        cache.modify({
          fields: {
            collaborators(collaboratorsRefs, { toReference }) {
              return [...collaboratorsRefs, ...addCollaborators.map((c) => toReference(c))];
            },
          },
        });
      },
    })
      .then((result) => {
        const addCollaborators = result.data?.addCollaborators;
        if (onSuccess && addCollaborators) onSuccess(addCollaborators);
        sendAnalytics(
          addCollaboratorAnalytics(
            (addCollaborators && addCollaborators.length) || 0,
            (addCollaborators &&
              addCollaborators[0] &&
              addCollaborators[0].role &&
              addCollaborators[0].role.name) ||
              ''
          )
        );
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [submitFunc, mutationResult] as const;
}

export default useCreateCollaborators;
