import { ProjectCompPortals } from './constants/elementIdentifiers';

export default function ProjectCompsStickyHeader() {
  return (
    <div data-cy="sticky-header" className="sticky top-0 z-10 w-full bg-background-primary">
      <div id={ProjectCompPortals.COMP_HEADER} className="flex w-max min-w-full shadow-downwards">
        <div className="sticky left-0 z-10 flex bg-background-primary">
          <div id={ProjectCompPortals.AVG_COMP_HEADER} className="z-20 flex border-r">
            <div className="w-75 border-r" />
          </div>
        </div>
      </div>
    </div>
  );
}
