import { useCallback, useEffect, useState } from 'react';

import { COPY_ITEM_DOWNLOAD_ASSET, COPY_ITEM_UPLOAD_ASSET } from '../../../../actions/actionTypes';
import { getAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { selectedAssetsVar } from '../../../../api/apollo/reactiveVars';
import { REFETCH_ASSETS } from '../../../../api/refetchSets';
import useSendAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { useRefetch } from '../../../../hooks/useRefetch';
import { useDownloadAsset, useProjectUploadAssets } from '../../../assets/hooks/AssetsMutationHook';
import useAssetsQuery from '../../../assets/hooks/useAssetsQuery';
import useOptimisticProjectUploadAssets from '../../../assets/hooks/useOptimisticProjectUploadAssets';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';

import ItemAttachmentsDialogFileExplore from './ItemAttachmentsDialogFileExplore';
import ItemAttachmentsDialogItemCopy from './ItemAttachmentsDialogItemCopy';

type ItemAttachmentsDialogProps = {
  state: ItemCopyState;
  onBack: () => void;
  onChange: (input: Partial<ItemCopyState>) => void;
  onClose: () => void;
  onNext: () => void;
  progressPercent?: number;
};

const ItemAttachmentsDialog = (props: ItemAttachmentsDialogProps) => {
  const sendAnalytics = useSendAnalyticsEventHook();

  const [filesDialogOpen, setFilesDialogOpen] = useState(false);

  const { data, previousData } = useAssetsQuery(props.state.assetIDs);
  const itemAssets = props.state.assetIDs.length === 0 ? [] : data?.assets ?? previousData?.assets;

  const refetchAssets = useRefetch(REFETCH_ASSETS);

  useEffect(() => {
    refetchAssets();
  }, [refetchAssets, props.state.assetIDs]);

  const { onChange } = props;

  const onRemove = useCallback(
    (assetID: UUID) => onChange({ assetIDs: props.state.assetIDs.filter((a) => a !== assetID) }),
    [onChange, props.state.assetIDs]
  );

  const onAttachItemCopy = useCallback(
    (assetIDs: UUID[]) => {
      const newAssetIDs: UUID[] = [];
      assetIDs.forEach((a) => {
        if (!props.state.assetIDs.some((sa) => sa === a)) newAssetIDs.push(a);
      });

      onChange({ assetIDs: [...props.state.assetIDs, ...newAssetIDs] });
      selectedAssetsVar({ selectedMap: {} });
    },
    [onChange, props.state.assetIDs]
  );

  const onCloseItemCopyDialog = () => {
    selectedAssetsVar({ selectedMap: {} });
    props.onClose();
  };

  const [downloadAssetFunc] = useDownloadAsset();
  const onDownload = (assetForDownload: UploadedAsset) => {
    downloadAssetFunc(assetForDownload.location, assetForDownload.name);
    sendAnalytics(getAssetAnalytics(COPY_ITEM_DOWNLOAD_ASSET, assetForDownload.id));
  };

  const { onDropFile, uploadingAssets } = useProjectUploadAssets(
    getAssetAnalytics(COPY_ITEM_UPLOAD_ASSET)
  );
  useOptimisticProjectUploadAssets(uploadingAssets);

  return filesDialogOpen ? (
    <ItemAttachmentsDialogFileExplore
      onAttachItemCopy={onAttachItemCopy}
      onBackFilesDialog={() => setFilesDialogOpen(false)}
      onDropFile={onDropFile}
      progressPercent={props.progressPercent}
      state={props.state}
    />
  ) : (
    <ItemAttachmentsDialogItemCopy
      itemAssets={itemAssets}
      onAddFiles={() => setFilesDialogOpen(true)}
      onBack={props.onBack}
      onClose={onCloseItemCopyDialog}
      onDownload={onDownload}
      onNext={props.onNext}
      onRemove={onRemove}
      progressPercent={props.progressPercent}
      state={props.state}
    />
  );
};

export default ItemAttachmentsDialog;
