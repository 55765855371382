import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const TOP_BAR_NAV_WIDTH = 49;
const SEARCH_BAR_OFFSET = 79;

export default (theme: KomodoTheme) =>
  createStyles({
    searchContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      paddingLeft: SEARCH_BAR_OFFSET,
      paddingBottom: 1,
      backgroundColor: 'white',
      filter: 'drop-shadow(0px 4px 16px rgba(0,0,0,.25))',
      zIndex: 2,
    },
    resultsContainer: {
      position: 'absolute',
      top: TOP_BAR_NAV_WIDTH + 8,
      left: SEARCH_BAR_OFFSET,
      padding: 24,
      borderRadius: 8,
      backgroundColor: 'white',
      filter: 'drop-shadow(0px 4px 16px rgba(0,0,0,.25))',
      zIndex: 2,
    },
    chips: {
      padding: '8px 4px',
    },
    label: {
      padding: '4px 0px',
      ...theme.typography.webLabel,
      '&:first-of-type': {
        paddingTop: 0,
      },
    },
  });
