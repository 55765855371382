import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Asset {
    blobUrl: String
    displayURL: String
    type: String
  }

  extend type Query {
    costModeLocal: CostMode!
    projectCompSettingsReactive: ProjectCompsSetInput!
    inAppNotificationsShowUnreadOnlyReactive: Boolean!
    inAppNotificationsProjectIdReactive: UUID!
    inAppNotificationsPaginationReactive: PaginationByOffsetID!
  }
`;
