import { ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useFocusRing, useTextField } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import { StrictUnion } from '../../../utilities/types';
import ClearButton from '../ClearButton/ClearButton';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import useHasClearButton from '../hooks/useHasClearButton';

type BaseTextInputProps = {
  autoFocus?: boolean;
  'data-cy'?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: ReactNode;
  errorMessage?: string;
  id?: string;
  maxLength?: number;
  name?: string;
  onBlur?: Parameters<typeof useTextField>[0]['onBlur'];
  onChange?: Parameters<typeof useTextField>[0]['onChange'];
  onClear?: () => void;
  onFocus?: Parameters<typeof useTextField>[0]['onFocus'];
  onKeyDown?: Parameters<typeof useTextField>[0]['onKeyDown'];
  placeholder?: string;
  startAdornment?: ReactNode;
  value?: string;
};

type LabelledTextInputProps = BaseTextInputProps & {
  'aria-label'?: string;
  label: string;
};

type UnlabelledTextInputProps = BaseTextInputProps & {
  'aria-label': string;
  label?: string;
};

type TextInputProps = StrictUnion<LabelledTextInputProps | UnlabelledTextInputProps>;

export default forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  props,
  forwardedRef
) {
  const { errorMessage } = props;
  const ref = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    {
      ...props,
      onKeyDown: (e) => {
        props.onKeyDown?.(e);

        if (e.key === 'Enter') {
          ref.current?.blur();
        }
      },
      isDisabled: Boolean(props.disabled),
      ...(errorMessage ? { validationState: 'invalid', 'aria-errormessage': errorMessage } : {}),
    },
    ref
  );

  const { hasTextClearButton, hasInlineClearButton } = useHasClearButton({
    label: props.label,
    isClearable: Boolean(props.onClear && inputProps.value),
    isDisabled: props.disabled,
  });
  const handleClear = () => {
    props.onClear?.();
    ref.current?.focus();
  };

  const { focusProps, isFocused } = useFocusRing();

  return (
    <div className="w-full text-type-primary">
      <div className="flex flex-col gap-0.5">
        {props.label && (
          <div className="flex">
            <label {...labelProps} className="mr-auto text-type-primary type-label">
              {props.label}
            </label>
            {hasTextClearButton && <ClearButton onClick={handleClear} />}
          </div>
        )}
        <div
          className={[
            'flex h-10 items-center gap-1 rounded-md border px-2 type-body1 placeholder:text-type-inactive',
            inputProps.disabled ? 'bg-button-inactive' : 'bg-background-primary',
            errorMessage ? 'border-type-error' : '',
            isFocused ? 'outline' : 'outline-none',
          ].join(' ')}
        >
          {props.startAdornment}
          <input
            {...mergeProps(inputProps, focusProps)}
            className="h-full min-w-0 flex-grow bg-background-primary outline-none type-body1 placeholder:text-type-inactive disabled:bg-button-inactive disabled:text-type-inactive"
            data-cy={props['data-cy']}
            ref={composeRefs(ref, forwardedRef)}
          />
          {hasInlineClearButton && <ClearIconButton onClick={handleClear} />}
          {props.endAdornment}
        </div>
        {errorMessage && (
          <div {...errorMessageProps} className="cursor-default text-type-error type-label">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});
