import useAssetsQuery from '../../assets/hooks/useAssetsQuery';

export const useOrganizationPictures = (logos: (string | null)[]): string[] => {
  // Make teamGUIDs array
  const teamGUIDs = logos.map((logoGUID: string | null) => String(logoGUID));
  // Query assets all at once
  const assets = useAssetsQuery(teamGUIDs)?.data?.assets ?? [];
  // Map blob urls
  const images = assets
    .map((asset) => asset?.blobUrl)
    .filter((value: string | undefined | null): value is string => {
      return value ? value !== 'null' : false;
    });

  return images;
};
