import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Close, Help } from '@material-ui/icons';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  defaultPreviewSettings,
  setPreviewSettingsVar,
  usePreviewSettingsVar,
} from '../../../api/apollo/reactiveVars';
import { getCategoriesInput } from '../../../hooks/previewUser';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { useUserProfile } from '../../../utilities/userProfile';
import AssigneeSelectData from '../../AssigneeSelect/AssigneeSelectData';
import { DialogsAddCollaborator } from '../../Dialogs';
import TradesMultiSelectPopover from '../../dragon-scales/TradesMultiSelectPopover/TradesMultiSelectPopover';
import ShareGroupAdd from '../../Icons/ShareGroupAdd';
import { Button, ButtonBar, Select, Tooltip } from '../../scales';

import ModeBar from './ModeBar';

export default function PreviewModeBar() {
  const sendAnalytics = useSendAnalyticsEventHook();
  const previewSettings = usePreviewSettingsVar();
  const isUserPreview = Boolean(previewSettings.previewUserId);

  const currentUserEmail = useUserProfile()?.email;

  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  const roles = useRolesQuery(projectId, MountPolicy.SKIP_ON_MOUNT).data?.projectRoles ?? [];
  const role = previewSettings.previewRoleId
    ? roles.find((r) => previewSettings.previewRoleId === r.id)
    : undefined;

  const collaborators = useCollaboratorsQuery(projectId).data?.collaborators ?? [];
  const collaborator = previewSettings.previewUserId
    ? collaborators.find((c) => previewSettings.previewUserId === c.user.id)
    : undefined;

  const previewAllTrades = previewSettings.previewAllTrades ?? collaborator?.allTrades ?? true;

  const [showAddCollaboratorDialog, setShowAddCollaboratorDialog] = useState(false);

  return (
    <>
      <ModeBar
        data-cy="preview-mode-bar"
        className="gap-2"
        title={
          <div className="flex gap-2">
            <div>Preview</div>
            <Tooltip content="Preview allows you to test drive as another person to see how your teammates will experience this project in Join. You can choose any teammate or role to preview.">
              <div className="icon-sm">
                <Help fontSize="inherit" />
              </div>
            </Tooltip>
          </div>
        }
      >
        <ButtonBar
          data-cy="preview-mode-button-bar"
          options={[{ value: 'Role' }, { value: 'User' }]}
          onChange={(value) => {
            if (value === 'User') {
              const newCollaborator =
                collaborator ?? collaborators.find((c) => c.user.email === currentUserEmail);

              if (!newCollaborator) return;

              setPreviewSettingsVar({
                previewUserId: newCollaborator.user.id,
                previewRoleId: previewSettings.previewRoleId,
              });

              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
                  previewTypes.USER,
                  newCollaborator.role.name
                )
              );
            } else {
              const newRole = role ?? roles[0];
              if (!newRole) return;

              setPreviewSettingsVar({
                previewUserId: undefined,
                previewRoleId: role?.id ?? roles[0].id,
                previewTrades: collaborator?.trades.map((t) => ({
                  id: t.id,
                  categorizationID: t.categorization?.id ?? '',
                })),
                previewAllTrades,
              });

              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
                  previewTypes.ROLE,
                  newRole.name
                )
              );
            }
          }}
          value={isUserPreview ? 'User' : 'Role'}
        />
        {isUserPreview && collaborator && (
          <>
            <div className="w-75">
              <AssigneeSelectData
                aria-label="select user to preview"
                data-cy="preview-select-user"
                projectID={projectId!}
                onChange={(email) => {
                  const newCollaborator = collaborators.find((c) => email === c.user.email);
                  if (!newCollaborator) return;

                  setPreviewSettingsVar({
                    previewUserId: newCollaborator.user.id,
                    previewRoleId: newCollaborator.role.id,
                  });

                  sendAnalytics(
                    previewPermissionsAnalytics(
                      previewEventTypes.PREVIEW_SELECT_USER,
                      previewTypes.USER,
                      newCollaborator.role.name
                    )
                  );
                }}
                value={collaborator.user.email}
              />
            </div>
            <div className="text-type-muted type-body2">Role: {collaborator.role.name}</div>
          </>
        )}
        {!isUserPreview && role && (
          <>
            <div className="w-50">
              <Select
                aria-label="select role to preview"
                data-cy="preview-select-role"
                entries={roles.map((r) => ({ id: r.id, label: r.name }))}
                onChange={(value) => {
                  const newRole = roles.find((r: Role) => value === r.id);
                  if (!newRole) return;

                  setPreviewSettingsVar({
                    previewUserId: undefined,
                    previewRoleId: newRole.id,
                    previewAllTrades,
                  });

                  sendAnalytics(
                    previewPermissionsAnalytics(
                      previewEventTypes.PREVIEW_SELECT_ROLE_TYPE,
                      previewTypes.ROLE,
                      newRole.name
                    )
                  );
                }}
                value={role.id}
              />
            </div>
            {role.hasTrade && (
              <div className="w-75">
                <TradesMultiSelectPopover
                  defaultValue={{
                    categories: getCategoriesInput(previewSettings.previewTrades) ?? [],
                    isAllSelected: Boolean(previewSettings.previewAllTrades),
                  }}
                  onChange={(trades) => {
                    setPreviewSettingsVar({
                      previewRoleId: role.id,
                      previewAllTrades: trades.isAllSelected,
                      previewTrades: trades.categories,
                    });
                  }}
                  projectID={projectId}
                />
              </div>
            )}
          </>
        )}
        <div className="ml-auto">
          <Button
            label="Invite Teammates"
            onClick={() => {
              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_ADD_COLLABORATORS_CTA,
                  previewTypes.ROLE,
                  role?.name || collaborator?.role.name || ''
                )
              );
              setShowAddCollaboratorDialog(true);
            }}
            startIcon={<ShareGroupAdd />}
            type="secondary"
          />
        </div>
        <Button
          data-cy="preview-exit-button"
          label="Exit Preview"
          onClick={() => {
            setPreviewSettingsVar(defaultPreviewSettings);
            sendAnalytics(
              previewPermissionsAnalytics(
                previewEventTypes.PREVIEW_EXIT_CTA,
                isUserPreview ? previewTypes.USER : previewTypes.ROLE,
                role?.name ?? collaborator?.role.name ?? ''
              )
            );
          }}
          startIcon={<Close />}
          type="primary"
        />
      </ModeBar>
      {showAddCollaboratorDialog && (
        <DialogsAddCollaborator onClose={() => setShowAddCollaboratorDialog(false)} />
      )}
    </>
  );
}
