import { FC, useState } from 'react';
import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useResendInviteMutation } from '../../../hooks/useResendInviteMutation';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import useGetProjectNameQuery from '../../Collaborators/hooks/UseGetProjectNameQuery';

import styles from './DialogsResendProjectInvitationStyles';

type DialogsResendProjectInvitationProps = {
  classes: Classes<typeof styles>;
  projectID: UUID;
  user: User;
  role: Role;
  open: boolean;
  setDialogOpen: (open: boolean) => void;
};

const DialogsResendProjectInvitation: FC<DialogsResendProjectInvitationProps> = ({
  classes,
  projectID,
  user,
  role,
  open = false,
  setDialogOpen,
}) => {
  const { data } = useGetProjectNameQuery(projectID);

  const resendInvite = useResendInviteMutation();

  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState<{ message?: string }>({});

  const onClose = () => {
    setInput({});
    setDialogOpen(false);
  };
  const onSubmit = () => {
    if (!submitted) {
      setSubmitted(true);
      resendInvite(user.id, projectID, input.message, () =>
        setToast({ message: `Invite email has been resent to ${user.email}` })
      );
      onClose();
    }
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      classes={{ paper: classes.paper }}
      onClose={onClose}
      PaperProps={{ square: true }}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title" className={classes.title}>
            Resend Invitation
          </Typography>
        </div>
        <IconButton title="Close dialog" className={classes.close}>
          <Close onClick={onClose} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography className={classes.subHeader}>Email</Typography>
        <Typography className={classes.userDetails}>{user.email}</Typography>
      </DialogContent>

      <DialogContent className={classes.content}>
        <Typography className={classes.subHeader}>Role</Typography>
        <Typography className={classes.userDetails}>{role.name}</Typography>
      </DialogContent>
      <DialogContent>
        <Typography className={classes.subHeader}>Include a message</Typography>
        <TextField
          className={classes.messageField}
          multiline
          rows={6}
          value={input.message ? input.message : ''}
          placeholder={`${user.name ? user.name : null}, invited you to Join to be a part of the ${
            data?.project?.name
          } team`}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            setInput({ message: evt.target.value });
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          data-cy="resend-project-invitation-button-cancel"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-cy="resend-project-invitation-submit"
          onClick={() => {
            onSubmit();
          }}
          variant="contained"
        >
          Resend Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsResendProjectInvitation);
