import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { selectedAssetsVar } from '../../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../../generated/graphql';
import { useEntityID } from '../FilesAssets/FilesAssetsUtils';

import FilesAttachItem from './FilesAttachItem';
import FilesAttachItemCopy from './FilesAttachItemCopy';
import FilesAttachMilestone from './FilesAttachMilestone';

type FilesAttachProps = {
  onAttachItemCopy?: (assetIDs: UUID[]) => void;
  onClick: () => void;
  sourceSystem?: SourceSystem;
  onItemMutated?: () => void;
};

const FilesAttach: FC<FilesAttachProps> = ({
  onAttachItemCopy,
  onClick,
  sourceSystem,
  onItemMutated,
}) => {
  const { itemID, milestoneID } = useEntityID();
  const { selectedMap } = useReactiveVar(selectedAssetsVar);

  if (onAttachItemCopy) {
    return (
      <FilesAttachItemCopy
        onAttachItemCopy={onAttachItemCopy}
        onClick={onClick}
        selectedMap={selectedMap}
      />
    );
  }
  if (itemID)
    return (
      <FilesAttachItem
        itemID={itemID}
        selectedMap={selectedMap}
        onClick={onClick}
        sourceSystem={sourceSystem}
        onItemMutated={onItemMutated}
      />
    );
  if (milestoneID)
    return (
      <FilesAttachMilestone
        milestoneID={milestoneID}
        selectedMap={selectedMap}
        onClick={onClick}
        sourceSystem={sourceSystem}
      />
    );
  return null;
};

export default FilesAttach;
