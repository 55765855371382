import { useRef } from 'react';
import { useButton } from 'react-aria';

export function ResetButton(props: { onClick: () => void }) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress: props.onClick }, ref);

  return (
    <button data-cy="resetButton" className="type-link" {...buttonProps}>
      reset
    </button>
  );
}
