import { FC } from 'react';
import { useKey } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { Dialog, DialogActions, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  FilesDialogView,
  filesDialogViewVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import useBrowserTabUpdate from '../../../../hooks/useBrowserTabUpdate';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { matchStringWithSourceEnum } from '../../../../utilities/url';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';
import { useMilestoneQuery } from '../../../Milestone/hooks';
import { useUserSourcesQuery } from '../../hooks';
import {
  getAssetIDsforDisabling,
  useEntityAttachments,
  useEntityID,
} from '../FilesAssets/FilesAssetsUtils';
import FilesAttach from '../FilesAttach/FilesAttach';

import FilesDialogContent from './FilesDialogContent';
import styles from './FilesDialogStyles';
import { getCurrentUserSource, getHeaderText } from './FilesDialogUtils';

export const FILES_DIALOG_TAB_KEY = 'FilesDialog_UserSources';

type FilesDialogProps = {
  classes: Classes<typeof styles>;
  open: boolean;
  onDropFile: (file: File) => void;
  onClose: () => void;
  onAttachItemCopy?: (assetIDs: UUID[]) => void;
  onItemMutated?: () => void;
};

const FilesDialog: FC<FilesDialogProps> = ({
  classes,
  open,
  onDropFile,
  onClose,
  onAttachItemCopy,
  onItemMutated,
}) => {
  const { data, refetch } = useUserSourcesQuery();
  const { itemID, milestoneID } = useEntityID();
  const itemResult = useItemQuery(itemID);
  const item = itemResult.data?.item;
  const milestone = useMilestoneQuery(milestoneID);
  const view = useReactiveVar(filesDialogViewVar);

  const entityAssets = useEntityAttachments();
  const disabledIDs = getAssetIDsforDisabling(entityAssets);

  // Keys
  useKey('Escape', onClose);

  // Refresh hooks
  const onTabEvent = (sourceString?: string | null) => {
    refetch();
    if (sourceString) {
      const source = matchStringWithSourceEnum(sourceString);
      if (source) {
        filesDialogViewVar(source);
      }
    }
  };
  useBrowserTabUpdate(FILES_DIALOG_TAB_KEY, onTabEvent);

  // Actions
  const closeDialog = () => {
    onClose();
    filesDialogViewVar(FilesDialogView.ALL_FILES);
    selectedAssetsVar({ selectedMap: {} });
  };

  const selectedUserSource = getCurrentUserSource(data?.userSources.sources, view);

  return (
    <Dialog open={open} classes={{ paper: classes.assetDialog }}>
      <div className={classes.titleContainer}>
        <div className={classes.flexShrink}>
          <Typography className={classes.titleText} variant="title">
            {getHeaderText(item, milestone.data?.milestone)}
          </Typography>
        </div>
        <IconButton onClick={closeDialog} title="Close Dialog">
          <Close />
        </IconButton>
      </div>
      <Divider />
      <FilesDialogContent
        disabledIDs={disabledIDs}
        onDropFile={onDropFile}
        onItemMutated={onItemMutated}
      />
      <Divider />
      <DialogActions className={classes.action}>
        <div />
        <FilesAttach
          onAttachItemCopy={onAttachItemCopy}
          onClick={closeDialog}
          sourceSystem={selectedUserSource?.sourceSystem}
          onItemMutated={onItemMutated}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(FilesDialog);
