import { FC, useCallback, useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { itemSidebarNavigation } from '../../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { capitalizeString } from '../../../utilities/string';
import { ItemTypes } from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button } from '../../scales';
import { ArrowDirection } from '../../shared-widgets/NavigationArrowLink/NavigationArrowLink';

import { itemNavigationTooltipText } from './ItemSidebarHeader';

type ItemSidebarFooterNavProps = {
  item: ItemLike;
  items?: ItemTypes[] | null;
  openSidebar: (id?: string) => void;
};

const ItemSidebarFooterNav: FC<ItemSidebarFooterNavProps> = ({ item, items, openSidebar }) => {
  const sendAnalytics = useAnalyticsEventHook();

  // VIEW BOOLEANS
  const viewerIsOpen = useReactiveVar(assetViewerOpenVar);

  // LOCAL CONSTANTS
  const ellipses = true;
  const showNumberSign = true;

  // TODO - helper fn
  const itemID = item.id;
  const itemIDs = useMemo(
    () => items?.flatMap((item) => (item?.id ? [item.id] : [])) ?? [],
    [items]
  );

  const indexes = useMemo(() => {
    const currentIndex = itemIDs ? itemIDs.findIndex((i: string) => i === itemID) : 0;
    const previousNodeIndex = currentIndex - 1;
    const nextNodeIndex = currentIndex + 1;
    return { previousNodeIndex, nextNodeIndex };
  }, [itemIDs, itemID]);
  const { previousNodeIndex, nextNodeIndex } = indexes;

  const onNavigation = useCallback(
    (nodeIndex: number, direction: string) => {
      sendAnalytics(itemSidebarNavigation(itemID, direction));
      openSidebar(itemIDs[nodeIndex]);
    },
    [itemID, itemIDs, openSidebar, sendAnalytics]
  );

  const previousArrowDisabled = previousNodeIndex < 0;
  const nextArrowDisabled = !itemIDs || nextNodeIndex > itemIDs.length - 1;

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case ARROW_LEFT:
          if (!previousArrowDisabled && !viewerIsOpen) {
            onNavigation(previousNodeIndex, ArrowDirection.PREVIOUS);
          }
          break;
        case ARROW_RIGHT:
          if (!nextArrowDisabled && !viewerIsOpen) {
            onNavigation(nextNodeIndex, ArrowDirection.NEXT);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [
    nextArrowDisabled,
    previousArrowDisabled,
    previousNodeIndex,
    nextNodeIndex,
    viewerIsOpen,
    onNavigation,
  ]);

  return (
    <>
      <NormalTooltip
        title={itemNavigationTooltipText(
          capitalizeString(ArrowDirection.PREVIOUS),
          !previousArrowDisabled && items ? items[previousNodeIndex] : null,
          ellipses,
          showNumberSign,
          previousArrowDisabled
        )}
        placement="bottom"
      >
        <div>
          <Button
            disabled={previousArrowDisabled}
            label={`${capitalizeString(ArrowDirection.PREVIOUS)} item`}
            onClick={() => onNavigation(previousNodeIndex, ArrowDirection.PREVIOUS)}
            type="primary"
          />
        </div>
      </NormalTooltip>
      <NormalTooltip
        title={itemNavigationTooltipText(
          capitalizeString(ArrowDirection.NEXT),
          !nextArrowDisabled && items ? items[nextNodeIndex] : null,
          ellipses,
          showNumberSign,
          nextArrowDisabled
        )}
        placement="bottom"
      >
        <div>
          <Button
            disabled={nextArrowDisabled}
            label={`${capitalizeString(ArrowDirection.NEXT)} item`}
            onClick={() => onNavigation(nextNodeIndex, ArrowDirection.NEXT)}
            type="primary"
          />
        </div>
      </NormalTooltip>
    </>
  );
};

export default ItemSidebarFooterNav;
