import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { idReplace } from '../analytics';
import { reportPrint } from '../analytics/analyticsEventProperties';
import { useDebounceFn } from '../hooks';
import useAnalyticsEventHook, {
  useAnalyticsIdentifyUser,
  useAnalyticsLocationViewEvent,
} from '../hooks/useAnalyticsEventHook';
import useModuleEntitlementsQuery from '../hooks/useModuleEntitlementsQuery';
import { getProjectIdFromUrl } from '../utilities/url';

import AlertsSnackBar from './alerts/AlertsSnackBar/AlertsSnackBar';
import { ModuleEntitlementsProvider } from './ModuleEntitlementsProvider';
import Router from './Nav/Router';
import { ProjectTermProvider } from './ProjectDisplaySettings/TerminologyProvider';

export default function AppFrame() {
  // LOCATION HOOKS
  // TODO: Move this into the Router for useParams
  const location = useLocation();
  const { pathname } = location;
  const projectId = getProjectIdFromUrl(pathname);

  const moduleEntitlementsQueryResult = useModuleEntitlementsQuery();
  const moduleEntitlements =
    moduleEntitlementsQueryResult.data?.loadModuleEntitlementFlags ??
    moduleEntitlementsQueryResult.previousData?.loadModuleEntitlementFlags ??
    [];

  // ANALYTICS
  useAnalyticsIdentifyUser();
  useAnalyticsLocationViewEvent();
  const sendAnalytics = useAnalyticsEventHook();
  const MILLISECONDS = 5000;
  const debouncedAnalytics = useDebounceFn(() => {
    sendAnalytics(reportPrint(idReplace(pathname)));
  }, MILLISECONDS);
  const debouncedReportPrintLocation = (e: MediaQueryListEvent) => {
    if (e.matches) {
      debouncedAnalytics();
    }
  };
  useEffect(() => {
    window.matchMedia('print').addEventListener('change', debouncedReportPrintLocation);
    return () => {
      window.matchMedia('print').removeEventListener('change', debouncedReportPrintLocation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, []);

  // otherwise, on to the regularly scheduled Join Application
  return (
    <ProjectTermProvider projectID={projectId}>
      <ModuleEntitlementsProvider moduleEntitlements={moduleEntitlements}>
        <Router />
        <AlertsSnackBar />
      </ModuleEntitlementsProvider>
    </ProjectTermProvider>
  );
}
