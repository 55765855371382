/* eslint-disable no-param-reassign */
import { CreationMethod, GridAnalytics, GridVariant } from '../../../actions/gridAnalytics';
import { CheckboxType, MarkupErrorField } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import {
  ADD,
  ALLOCATED_CELL,
  INHERITED_MARKUP_CHECKBOX_CELL,
  MARKUP_CONVERSION_FACTOR,
  MARKUP_VALUE_CELL,
  REFERENCE_CELL,
  REMOVE,
  S2,
  SELECT_CELL,
  SOURCE,
  SOURCE_CELL,
  STRING_CELL,
  USCENTS_CELL,
} from '../../../constants';
import { Scale } from '../../../enums';
import { EstimateTotalType, MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import {
  createMarkups,
  removeMarkups,
  toggleInheritedMarkup,
  updateMarkups,
} from '../hooks/markupMutation';
import { TABLE_BODY_HEIGHT } from '../style/styleConstants';
import AllocateTooltip from '../tooltips/Allocate';
import { CellData, Column, GridType, MarkupGridState, Position, ReferenceDisplay } from '../types';
import {
  formatRefsToNames,
  getFilterIds,
  getShouldExcludeFromAllocationFromCell,
  isAnotherTable,
  isInheritedMarkupCheckbox,
  isMarkupDisplayType,
  isMarkupReference,
  isMarkupType,
  isMarkupValue,
  isRegularCell,
} from '../utilities/cell';
import { addColumnsErrors } from '../utilities/data';

import {
  addLinesToTable,
  deleteLineFromTable,
  newCellRow,
  newCellState,
  newGenericGridState,
  scrollToBottom,
  synchronousPaste,
  updateCellIndexed,
  updateSubtotal,
  withGridReflow,
} from './editing';
import { getCellData } from './selecting';
import { normalizeRectangle } from './sizing';

// Markups allow all cells to be editable except total, source, allocate checkbox,
// incorporated item markup display type, and incorporate item markup draw value type cells
export const isCellEditable = (state: MarkupGridState, p: Position) => {
  const { data, isIncorporated } = state;
  const { name, type } = data.columns[p.column];

  const isSourceOrAllocatedCell = name === SOURCE || type === ALLOCATED_CELL;
  const isIncorporatedMarkupDisplayTypeCell =
    isIncorporated && type === FieldType.MARKUP_DISPLAY_TYPE;
  const isTotalCell = name.toLowerCase() === 'total';

  if (isSourceOrAllocatedCell || isIncorporatedMarkupDisplayTypeCell || isTotalCell) {
    return false;
  }

  const cellValue = getCellData(state, p.row, 0)?.data?.value;
  const displayType = isMarkupDisplayType(cellValue) ? cellValue.displayType : undefined;
  const isContingency =
    displayType === MarkupDisplayType.CONTINGENCY ||
    displayType === MarkupDisplayType.ALLOWANCE ||
    displayType === MarkupDisplayType.DRAW;
  const isIncorporatedItemMarkupDraw =
    isContingency && isIncorporated && type !== FieldType.REFERENCE;

  return !isIncorporatedItemMarkupDraw; // prevent editing of incorporated item markup draw value type cells
};

const makeCells = (
  markups: { id: UUID }[],
  m: Markup,
  inherited: boolean,
  hasSourceColumn: boolean,
  hasIncorporatedMarkups: boolean,
  hasDisplayTypeColumn: boolean
): GridCell[] => {
  const values = [
    // Inherited
    ...(inherited
      ? [{ checked: m.disabled ? CheckboxType.NOTCHECKED : CheckboxType.CHECKED }]
      : []),
    // Type (Display Type)
    ...(hasDisplayTypeColumn ? [{ displayType: m.displayType, disabled: m.disabled }] : []),
    // Description
    { string: m.name, disabled: m.disabled, formula: '' },
    // Value Type
    { type: m.type, disabled: m.disabled },
    // Value
    {
      type: m.type,
      number: m.value,
      disabled: m.disabled,
      scale: m.percentScale,
    },
    // References
    {
      type: m.type,
      references: m.markupReference?.appliesTo,
      filters: m.categoryFilters,
      sourceFilters: m.sourceFilters,
      costTypeFilters: m.costTypeFilters,
      disabled: m.disabled,
      names: formatRefsToNames(markups, m, inherited, hasIncorporatedMarkups),
    },
    // Add source IDs (defaulting to undefined) if we have a source column
    ...(hasSourceColumn ? [{ sourceIDs: m.source ?? undefined }] : []),
    { shouldExcludeFromAllocation: m.shouldExcludeFromAllocation },
    // Total
    { string: String(m.total), disabled: m.disabled, formula: '' },
  ];
  const cells: GridCell[] = values.map((value) => ({ value }));

  // eslint-disable-next-line no-restricted-syntax
  for (const error of m.errors) {
    const field = Object.values(MarkupErrorField).find((f) => f === error.field);
    let fieldIndex = -1;
    switch (field) {
      case MarkupErrorField.DESCRIPTION:
        fieldIndex = values.findIndex((v) => 'string' in v);
        break;
      case MarkupErrorField.REFERENCES:
        fieldIndex = values.findIndex((v) => 'references' in v);
        break;
      case MarkupErrorField.TOTAL:
        fieldIndex = values.length - 1;
        break;
      default:
        break;
    }

    if (fieldIndex >= 0) {
      cells[fieldIndex].error = error.error;
    }
  }
  return cells;
};

const newMarkupLine = (
  markups: { id: UUID }[],
  m: Markup,
  inherited = false,
  hasSourceColumn = false,
  hasIncorporatedMarkups = false,
  hasDisplayTypeColumn = false
): GridLine => ({
  id: m.id,
  isDisabled: m.disabled,
  orderingNumerator: String(m.orderingNumerator),
  orderingDenominator: String(m.orderingDenominator),
  cells: makeCells(
    markups,
    m,
    inherited,
    hasSourceColumn,
    hasIncorporatedMarkups,
    hasDisplayTypeColumn
  ),
  source: getMarkupSourceID(m) ?? undefined,
});

const markupColumns: Column[] = [
  {
    type: STRING_CELL,
    name: 'Description',
    id: 'Description',
  },
  {
    type: SELECT_CELL,
    name: 'Type',
    id: 'Type',
  },
  {
    type: MARKUP_VALUE_CELL,
    name: 'Value',
    id: 'Value',
  },
  {
    type: REFERENCE_CELL,
    name: 'Applies to...',
    id: 'References',
  },
];

const markupTotalColumn: Column[] = [
  {
    type: USCENTS_CELL,
    name: 'Total',
    id: 'Total',
  },
];

export const buildMarkupColumns = (
  isInherited: boolean,
  hasSourceColumn: boolean,
  hasDisplayTypeColumn: boolean
): {
  type: string;
  name: string;
  id: string;
  toolTip?: string;
}[] => {
  const inheritedColumn = isInherited
    ? [
        {
          type: INHERITED_MARKUP_CHECKBOX_CELL,
          name: 'Applied',
          id: 'Checkbox',
        },
      ]
    : [];

  const sourceColumn = hasSourceColumn
    ? [
        {
          type: SOURCE_CELL,
          name: 'Source',
          id: 'Source',
        },
      ]
    : [];

  const allocateColumn = [
    {
      type: FieldType.ALLOCATE,
      name: 'Allocate',
      id: 'Allocate',
      helpTip: AllocateTooltip,
    },
  ];

  const displayTypeColumn = hasDisplayTypeColumn
    ? [
        {
          type: FieldType.MARKUP_DISPLAY_TYPE,
          name: 'Type',
          id: 'Type',
        },
      ]
    : [];

  if (hasDisplayTypeColumn) {
    const existingTypeColumn = markupColumns.find((c) => c.type === SELECT_CELL);
    if (existingTypeColumn) existingTypeColumn.name = 'Value Type';
  }

  // the order we return these markup columns in matters
  return [
    ...inheritedColumn,
    ...displayTypeColumn,
    ...markupColumns,
    ...sourceColumn,
    ...allocateColumn,
    ...markupTotalColumn,
  ];
};

const shouldShowSourceColumn = (
  markups: Markup[],
  isInherited: boolean,
  isIncorporated: boolean
) => {
  const hasMarkupWithSource = markups.some((m) => !!m?.source);
  // don't show source column in item estimate even if markups have a source
  return isIncorporated || (hasMarkupWithSource && !isInherited);
};

export const newMarkupGridState = (
  estimate:
    | {
        id?: UUID;
        markups: Markup[];
        markupSubtotal: number;
        totalType: EstimateTotalType;
      }
    | undefined, // could be undefined if the user hasn't created an owner cost estimate yet
  readOnly: boolean,
  hasMarkupCheckboxButton: boolean,
  analytics: GridAnalytics,
  updateCostReports: () => void,
  isInherited: boolean,
  isIncorporated: boolean,
  maxWidth: number | undefined,
  projectID: UUID,
  variant: GridVariant,
  type: GridType,
  updateInheritedMarkupsController?: (markups: Markup[], newSubtotal: number) => void,
  updateIncorporatedMarkupsController?: (markups: Markup[], newSubtotal: number) => void,
  updateIncorporatedDrawsController?: (markups: Markup[], newSubtotal: number) => void,
  viewFilter?: ViewFilterInput,
  hasDisplayTypeColumn = false,
  refetch?: () => void,
  hasIncorporatedMarkups?: boolean
): MarkupGridState => {
  const markups = estimate?.markups || [];
  const hasSourceColumn = shouldShowSourceColumn(markups, isInherited, isIncorporated);
  const columns = buildMarkupColumns(isInherited, hasSourceColumn, hasDisplayTypeColumn);
  const lines = markups.map((m) =>
    newMarkupLine(
      markups,
      m,
      isInherited,
      hasSourceColumn,
      hasIncorporatedMarkups,
      hasDisplayTypeColumn
    )
  );
  addColumnsErrors(columns, lines.length);
  const data = {
    columns,
    lines,
    readOnly,
    hasMarkupCheckboxButton,
  };

  const state = newGenericGridState(data, TABLE_BODY_HEIGHT, maxWidth, updateCostReports, variant);

  const subtotal: CellData = {
    data: newCellState(USCENTS_CELL, {
      value: {
        string: String(estimate ? estimate.markupSubtotal : ''),
        formula: '',
        formulaDisplay: [],
      },
    }),
    dom: null,
  };

  const result: MarkupGridState = {
    ...state,
    projectID,
    estimateID: estimate?.id,
    subtotal,
    isInherited,
    isIncorporated,
    analytics,
    updateInheritedMarkupsController: (m, t) => {
      if (updateInheritedMarkupsController) updateInheritedMarkupsController(m, t);
    },
    updateIncorporatedMarkupsController: (m, t) => {
      if (updateIncorporatedMarkupsController) updateIncorporatedMarkupsController(m, t);
    },
    updateIncorporatedDrawsController: (m, t) => {
      if (updateIncorporatedDrawsController) updateIncorporatedDrawsController(m, t);
    },
    viewFilter,
    refetch,
    hasIncorporatedMarkups,
    hasSourceColumn,
    hasDisplayTypeColumn,
    estimateTotalType: estimate?.totalType ?? EstimateTotalType.TOTAL_TYPE_TOTAL,
  };

  return result;
};

const updateInput = (
  input: Partial<MarkupInput>,
  fieldType: string,
  value: GridCellValue | undefined
) => {
  switch (fieldType) {
    case FieldType.STRING:
      if (isRegularCell(value)) {
        input.name = value.string.trim();
      }
      return;
    case FieldType.SELECT:
      if (isMarkupType(value)) {
        input.type = value.type;
      }
      return;
    case FieldType.MARKUP_VALUE:
      if (isMarkupValue(value)) {
        if (input.type && value.type && input.type !== value.type) {
          input.value = 0;
          return;
        }
        if (input.type === MarkupType.FIXED) {
          input.value = value.number;
        } else {
          const conversionFactor = value.scale === 0 ? MARKUP_CONVERSION_FACTOR : 1;
          const percent = Math.round(Number(value.number * conversionFactor)) || 0;
          input.value = percent;
          input.percentScale = Scale.PERCENT_9;
        }
      }
      return;
    case FieldType.REFERENCE:
      if (isMarkupReference(value)) {
        input.references = value.references;
        input.categoryFilterIDs = getFilterIds(value.filters);
        input.sourceFilterIDs = value.sourceFilters?.map((s) => s.id);
        input.costTypeFilters = value.costTypeFilters;
      }
      return;
    case FieldType.INHERITED_MARKUP_CHECKBOX:
      if (isInheritedMarkupCheckbox(value) && value.checked && 'checked' in input) {
        input.checked = value.checked;
      }
      break;
    case FieldType.ALLOCATE:
      if (value) input.shouldExcludeFromAllocation = getShouldExcludeFromAllocationFromCell(value);
      break;
    case FieldType.MARKUP_DISPLAY_TYPE:
      if (isMarkupDisplayType(value)) {
        input.displayType = value.displayType;
      }
      break;
    default:
  }
};

export const replaceMarkups = (state: MarkupGridState, markups: Markup[], newSubtotal: number) => {
  const {
    cellData,
    data,
    isInherited,
    indexMap,
    hasIncorporatedMarkups,
    hasSourceColumn,
    hasDisplayTypeColumn,
  } = state;
  const newLines: GridLine[] = (markups || []).map((m) =>
    newMarkupLine(
      markups,
      m,
      isInherited,
      hasSourceColumn,
      hasIncorporatedMarkups,
      hasDisplayTypeColumn
    )
  );

  const hasBeenUpdated = withGridReflow(state, () => {
    const { length } = markups;
    if (cellData.length < length || data.lines.length < length) {
      for (let i = data.lines.length; i < length; i += 1) {
        data.lines.push(newLines[i]);
      }
      for (let i = cellData.length; i < length; i += 1) {
        cellData.push(newCellRow(data, data.lines[i]));
      }
    }

    for (let i = 0; i < length; i += 1) {
      indexMap[markups[i].id] = i;
      const lineOfCells = cellData[i];
      if (lineOfCells) {
        for (let j = 0; j < lineOfCells.length; j += 1) {
          const value = newLines[i].cells[j];
          if (value.value) {
            updateCellIndexed(state, i, j, value);
          }
        }
      }
    }

    for (let i = 0; i < length; i += 1) {
      data.lines[i] = newLines[i];
    }

    while (data.lines.length > length) data.lines.pop();
    while (cellData.length > length) cellData.pop();
  });
  updateSubtotal(state, newSubtotal);
  return hasBeenUpdated;
};

export const mutateMarkup = (
  state: MarkupGridState,
  start: Position,
  end: Position,
  values: (GridCellValue | undefined)[][]
) => {
  const {
    projectID,
    estimateID,
    data,
    isIncorporated,
    updateInheritedMarkupsController,
    updateIncorporatedMarkupsController,
    updateIncorporatedDrawsController,
    updateCostReports,
    analytics: { updateMarkupAnalytics },
    viewFilter,
    refetch,
    estimateTotalType,
  } = state;
  const [s, e] = normalizeRectangle(state, start, end);

  const markupInputs: MarkupInput[] = [];
  withGridReflow(state, () => {
    for (let i = s.row; i <= e.row; i += 1) {
      const line = data.lines[i];
      const id = line?.id;
      if (!line || !id) return;

      // Initialize the markup input from the base cells
      const input: MarkupInput = { id };

      // Now update the input with the target values
      line.cells.forEach((cell, j) => {
        const existingValue = cell?.value || undefined;

        // Construct a value from the inputs if we have it, otherwise
        // refer to the current contents of the table.
        const inputValue =
          j >= s.column && j <= e.column ? values[i - s.row][j - s.column] : existingValue;
        // Construct new markup references array, if names array is not empty.
        // If we paste from one item/milestone to another one, match m1 with 1st line markup id.
        // Remapping should not happen if user is pasting content in the same table.
        if (
          isMarkupReference(inputValue) &&
          inputValue &&
          inputValue.references &&
          isAnotherTable(data.lines, inputValue) &&
          // Fixed markups don't have references, but we want to display S1 on the frontend
          // This ensures we don't accidentally send any references to store on the backend
          inputValue.type !== MarkupType.FIXED
        ) {
          const remappedReferences: string[] = [];
          for (let n = 0; n < inputValue.references.length; n += 1) {
            if (
              inputValue.references[n] === ReferenceDisplay.NOT_APPLIED ||
              inputValue.references[n] === ReferenceDisplay.EMPTY_COST
            ) {
              break;
            }
            // the backend does not recognize S1, S2 so send S1
            if (inputValue.references[n] === ReferenceDisplay.S1_S2) {
              remappedReferences.push(ReferenceDisplay.S1);
              break;
            }

            const markupIndex = parseInt(inputValue.references[n].substring(1), 10);
            if (
              inputValue.references[n] === ReferenceDisplay.S1 ||
              inputValue.references[n] === S2 ||
              inputValue.references[n] === ReferenceDisplay.TOTAL
            ) {
              remappedReferences.push(inputValue.references[n]);
              break;
            } else if (markupIndex <= data.lines.length) {
              remappedReferences.push(data.lines[markupIndex - 1].id);
              break;
            } else {
              remappedReferences.push(inputValue.references[n]);
              break;
            }
          }
          inputValue.references = remappedReferences;
          // we might see cost types in a non cost-type estimate
          // when copying / pasting
          if (
            estimateTotalType !== EstimateTotalType.TOTAL_TYPE_COST_TYPES &&
            !!inputValue.costTypeFilters?.length
          )
            inputValue.costTypeFilters = [];
        }
        const fieldType = state.data.columns[j].type;
        if (inputValue) updateInput(input, fieldType, inputValue);
        // Optimistically respond for string cells in markups.
        if (j === 0) {
          updateCellIndexed(state, i, j, { value: inputValue });
        }
      });
      markupInputs.push(input);
    }
  });
  updateMarkups(
    projectID,
    estimateID,
    markupInputs,
    (result) => {
      if (result.data?.updateMarkups.estimateUpdateResult?.estimate) {
        // Potentially all of the markups changed, since they can reference each other,
        // so we have to update the whole table.
        // Perhaps later we can return only the lines that changed from the backend, but
        // markup tables are also not very big, so that's OK for now.
        const {
          markups,
          inheritedMarkups,
          incorporatedMarkups,
          incorporatedDraws,
          markupSubtotal,
          inheritedSubtotal,
          incorporatedSubtotal,
          incorporatedDrawsSubtotal,
        } = result.data.updateMarkups.estimateUpdateResult.estimate;

        // if the view is filtered then editing a markup may
        // remove it from the table
        if (data.lines.length !== markups.length) {
          for (let i = data.lines.length - 1; i >= 0; i -= 1) {
            deleteLineFromTable(state, i);
          }
        }

        replaceMarkups(state, markups, markupSubtotal);
        if (isIncorporated) {
          replaceMarkups(state, incorporatedMarkups, incorporatedSubtotal);
          // update the markup table after updating the incorporated markups
          if (refetch) refetch();
        }
        updateIncorporatedMarkupsController(incorporatedMarkups, incorporatedSubtotal);
        updateIncorporatedDrawsController(incorporatedDraws, incorporatedDrawsSubtotal);
        updateInheritedMarkupsController(inheritedMarkups, inheritedSubtotal);

        // TODO: Add Undo
        updateCostReports();
        updateMarkupAnalytics({
          includesCategoryFilters: result.data.updateMarkups.markups.some(
            (m) => m.categoryFilters.length > 0
          ),
          displayType: markupInputs[0]?.displayType,
        });
      }
    },
    viewFilter
  );
};

export const addMarkupLines = (
  state: MarkupGridState,
  method: CreationMethod,
  n: number,
  onSuccess?: () => void
) => {
  const {
    projectID,
    analytics: { createMarkupAnalytics },
    hasSourceColumn,
    hasDisplayTypeColumn,
  } = state;

  // For now, we will avoid optimistically responding to additions like this
  // because it's unclear how to handle any edits that take place on a line that doesn't exist yet...
  createMarkups(projectID, state.estimateID, n, (result) => {
    if (result.data) {
      // New markups don't have any references, so we don't pass them the existing markups.
      const lines = result.data.createMarkups.map((m) =>
        newMarkupLine([], m, false, hasSourceColumn, false, hasDisplayTypeColumn)
      );
      addLinesToTable(state, lines);
      createMarkupAnalytics({ method });
      if (onSuccess) onSuccess();
    }
  });
};

export const addMarkupLine = (
  state: MarkupGridState,
  method: CreationMethod,
  onSuccess?: () => void
) => addMarkupLines(state, method, 1, onSuccess);

export const deleteMarkups = (state: MarkupGridState) => {
  const {
    projectID,
    data: { lines },
    isRowSelectedArr,
    updateCostReports,
    analytics: { removeMarkupAnalytics },
    viewFilter,
    refetch,
    isIncorporated,
    updateTable,
  } = state;

  const ids = lines.filter((_, i) => isRowSelectedArr[i]).map((row) => row.id);

  // Optimistically remove the line from the UI.
  for (let i = isRowSelectedArr.length - 1; i >= 0; i -= 1) {
    if (isRowSelectedArr[i]) deleteLineFromTable(state, i);
  }

  removeMarkups(
    projectID,
    state.estimateID,
    ids,
    (result) => {
      if (result.data) {
        if (result.data.removeMarkups.estimate) {
          const {
            markups,
            markupSubtotal,
            incorporatedMarkups,
            incorporatedSubtotal,
            incorporatedDraws,
            incorporatedDrawsSubtotal,
          } = result.data.removeMarkups.estimate;

          if (isIncorporated) {
            replaceMarkups(state, incorporatedMarkups, incorporatedSubtotal);
            replaceMarkups(state, incorporatedDraws, incorporatedDrawsSubtotal);
          } else {
            // milestone markups, item markups
            replaceMarkups(state, markups, markupSubtotal);
          }
        }
        // updateTable force updates the virtual and inner table components
        updateTable();
        // refetch to update other join grids
        if (refetch) refetch();
        updateCostReports();
        removeMarkupAnalytics();
      }
    },
    viewFilter
  );
};

export const toggleInheritedItemMarkup = (state: MarkupGridState, index: number) => {
  const {
    projectID,
    data: { lines },
    updateInheritedMarkupsController,
    updateCostReports,
    analytics: { toggleInheritedItemMarkupAnalytics },
  } = state;

  if (!lines[index]) return;
  const { id } = lines[index];

  // This is default: checkbox was checked or error
  let newDisabledFlag = false;
  // Check what is the current value and set the opposite
  const cellValue = lines[index].cells[0].value;
  if (cellValue && 'checked' in cellValue && cellValue.checked === CheckboxType.CHECKED) {
    newDisabledFlag = true;
  }

  toggleInheritedMarkup(projectID, state.estimateID, id, newDisabledFlag, (result) => {
    if (result.data?.toggleInheritedItemMarkup.estimate) {
      const { inheritedMarkups, inheritedSubtotal } =
        result.data.toggleInheritedItemMarkup.estimate;
      replaceMarkups(state, inheritedMarkups, inheritedSubtotal);
      updateInheritedMarkupsController(inheritedMarkups, inheritedSubtotal);
      updateCostReports();
      toggleInheritedItemMarkupAnalytics({ status: newDisabledFlag === true ? REMOVE : ADD });
    }
  });
};

export const toggleAllocatedMarkupLine = (
  state: MarkupGridState,
  index: number,
  viewFilter?: ViewFilterInput
) => {
  const {
    projectID,
    data: { lines },
    isIncorporated,
    updateIncorporatedMarkupsController,
    updateIncorporatedDrawsController,
    updateCostReports,
    analytics: { toggleAllocatedMarkupAnalytics },
    refetch,
  } = state;

  const line = lines[index];
  if (!line) return;
  const { id } = lines[index];

  const markupInput: MarkupInput = {
    id,
    shouldExcludeFromAllocation: true,
    displayType: MarkupDisplayType.MARKUP,
  };
  // Check what is the current value and set the opposite
  const cell = line.cells.find((c) => {
    const { value } = c;
    if (value && 'shouldExcludeFromAllocation' in value) {
      markupInput.shouldExcludeFromAllocation = !value.shouldExcludeFromAllocation;
      return true;
    }
    if (value && 'displayType' in value && value.displayType !== undefined) {
      markupInput.displayType = value.displayType;
    }
    return false;
  });

  if (!cell || !cell.value) return;

  updateMarkups(
    projectID,
    state.estimateID,
    [markupInput],
    (result) => {
      const estimate = result?.data?.updateMarkups?.estimateUpdateResult?.estimate;
      if (estimate) {
        const {
          markups,
          incorporatedMarkups,
          incorporatedSubtotal,
          markupSubtotal,
          incorporatedDraws,
          incorporatedDrawsSubtotal,
        } = estimate;

        replaceMarkups(state, markups, markupSubtotal);
        if (isIncorporated) {
          replaceMarkups(state, incorporatedMarkups, incorporatedSubtotal);
          replaceMarkups(state, incorporatedDraws, incorporatedDrawsSubtotal);
          // update the markup table after updating the incorporated markups
        }
        if (refetch) refetch();
        updateIncorporatedMarkupsController(incorporatedMarkups, incorporatedSubtotal);
        updateIncorporatedDrawsController(incorporatedDraws, incorporatedDrawsSubtotal);

        updateCostReports();
        toggleAllocatedMarkupAnalytics({
          shouldExcludeFromAllocation: !markupInput.shouldExcludeFromAllocation,
        });
      }
    },
    viewFilter
  );
};

export const undoLastMutation = () => {};

export const pasteClipboard = (state: MarkupGridState, start: Position) =>
  synchronousPaste(state, start, mutateMarkup, (s, m, n, callback) =>
    addMarkupLines(s, m, n, () => {
      callback();
      scrollToBottom(state);
    })
  );

export const getMarkupSourceID = (m: Markup): UUID | null => {
  if (!m?.source) {
    return null;
  }
  return m?.source.itemID || m?.source.milestoneID;
};
