import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { UNTITLED_PROJECT } from '../../../constants';
import CommentDisplay from '../../Comments/CommentDisplay/CommentDisplay';
import { BabyButton, Tooltip } from '../../scales';
import {
  useManageTextClamp,
  useShowTextWithClamp,
} from '../../shared-widgets/EntryLink/EntryLinkUtils';

import { InAppNotificationEntryDescriptionType } from './InAppNotificationEntryUtils';

type Props = {
  description: InAppNotificationEntryDescriptionType;
  icon: ReactNode;
  isRead: boolean;
  onLinkClick: () => void;
  showProjectDetails: boolean;
  onToggleIsRead: () => void;
};

export default function InAppNotificationEntry(props: Props) {
  const { description } = props;

  const tooltipText = props.isRead ? 'Mark as unread' : 'Mark as read';

  const { showTextAsClamped, setShowTextAsClamped, isInitialTextClamped, setIsInitialTextClamped } =
    useManageTextClamp();
  const { ref } = useShowTextWithClamp(setIsInitialTextClamped);

  let comment = (
    <strong className={`${showTextAsClamped ? '' : 'line-clamp-3'}`} data-cy="IAN-Entry" ref={ref}>
      <CommentDisplay
        className={props.isRead ? 'text-type-muted' : 'text-notification-fill'}
        mentions={description.linkMentions}
        title={description.linkTitle}
      />
    </strong>
  );

  if (description.linkPath && !description.linkDisabled) {
    comment = (
      <Link
        className="rounded outline-none hover:underline focus-visible:bg-selection-selected"
        data-cy="IAN-Link"
        to={description.linkPath + (description.linkHash ?? '')}
        onClick={props.onLinkClick}
      >
        {comment}
      </Link>
    );
  }

  const showProjectDetails = props.showProjectDetails && description.project;

  return (
    <div
      className={`flex w-full items-start gap-2 p-4 ${
        props.isRead ? 'text-type-muted' : 'text-type-primary'
      }`}
      data-cy="IAN-Notification-Entry"
    >
      <div className={`icon-md ${props.isRead ? 'text-type-muted' : 'text-notification-fill'}`}>
        {props.icon}
      </div>
      <div className="flex flex-col overflow-hidden">
        <div className="type-body3">
          {description.whoDidWhat && (
            <span data-cy="IAN-Notification-WhoDidWhat">{description.whoDidWhat}</span>
          )}
          {description.whoDidWhat && description.timeDisplay && <span>&nbsp;·&nbsp;</span>}
          <span className="text-type-muted">{description.timeDisplay}</span>
        </div>
        <div className="flex flex-row overflow-hidden">
          {props.description.linkTitleIcon && (
            <div className="flex items-center">{props.description.linkTitleIcon}</div>
          )}
          {comment}
        </div>
        {description.displayFooter && (
          <div className="flex truncate text-type-muted type-body3">
            {showProjectDetails && (
              <div className="truncate" title={description.project.name}>
                {description.project.name || UNTITLED_PROJECT}
              </div>
            )}
            {showProjectDetails && description.tinyDetailsLineSecondary && <div>&nbsp;·&nbsp;</div>}
            {description.tinyDetailsLineSecondary && (
              <div
                className="flex items-center truncate"
                title={description.tinyDetailsLineSecondary}
              >
                {props.description.tinyDetailsLineSecondaryIcon && (
                  <div className="flex items-center pr-1">
                    {props.description.tinyDetailsLineSecondaryIcon}
                  </div>
                )}
                {description.tinyDetailsLineSecondary}
              </div>
            )}
          </div>
        )}
      </div>
      {isInitialTextClamped && (
        <BabyButton
          aria-label={showTextAsClamped ? 'collapse notification' : 'expand notification'}
          data-cy="notification-expander"
          onClick={() => setShowTextAsClamped(!showTextAsClamped)}
          icon={showTextAsClamped ? <ArrowDropUp /> : <ArrowDropDown />}
        />
      )}
      <div className="ml-auto">
        <Tooltip content={tooltipText}>
          <BabyButton
            aria-label={tooltipText}
            data-cy={props.isRead ? 'join-notification-icon-read' : 'join-notification-icon-unread'}
            icon={
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="9"
                  className={props.isRead ? 'fill-background-1' : 'fill-selection-focus-fill'}
                />
              </svg>
            }
            onClick={props.onToggleIsRead}
          />
        </Tooltip>
      </div>
    </div>
  );
}
