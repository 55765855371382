import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    close: {
      height: 32,
      margin: 8,
      width: 32,
    },
    title: {
      padding: '12px 12px 8px 12px',
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 12,
    },
  });

export default styles;
