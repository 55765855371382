import { CostRangeInput } from '../../../generated/graphql';
import { CostInput } from '../../scales';
import ExpandableFilterContainer from '../common/ExpandableFilterContainer';

type Props = {
  'data-cy'?: string;
  description?: string;
  onChange: (value: CostRangeInput) => void;
  title: string;
  value: CostRangeInput;
};

export default function CostRangeFilters(props: Props) {
  return (
    <ExpandableFilterContainer
      title={props.title}
      data-cy={props['data-cy']}
      description={props.description}
      onReset={() => props.onChange({ min: null, max: null })}
      canReset={typeof props.value.min === 'number' || typeof props.value.max === 'number'}
    >
      <div className="flex flex-row gap-2">
        <div className="min-w-0">
          <CostInput
            label="Min"
            onChange={(value) => {
              props.onChange({
                min: value,
                max: props.value.max,
              });
            }}
            placeholder="$0"
            value={props.value.min ?? null}
          />
        </div>
        <div className="min-w-0">
          <CostInput
            label="Max"
            onChange={(value) => {
              props.onChange({
                min: props.value.min,
                max: value,
              });
            }}
            placeholder="$9999999999"
            value={props.value.max ?? null}
          />
        </div>
      </div>
    </ExpandableFilterContainer>
  );
}
