import { Typography } from '@material-ui/core';

const header1 = `This setting affects how markups in reports are displayed, only when the "Display costs" mode
in the left sidebar is set to "Show Markups Allocated".`;

const header2 = `the markup cost will be allocated across direct costs set in the 'Applies
to' column`;

const header3 = `the markup cost will not be allocated across direct costs and instead
      will be displayed as a separate markup. The markup details (name, subtotal, etc.) will be
      visible to all users in reports`;

const AllocateTooltip: JSX.Element = (
  <div>
    <Typography>{header1}</Typography>
    <br />
    <Typography>
      <b>If selected:</b> {header2}
    </Typography>
    <br />
    <Typography>
      <b>If unselected:</b> {header3}
    </Typography>
  </div>
);

export default AllocateTooltip;
