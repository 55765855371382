const oldTextMap: Record<string, JSX.Element> = {
  CAN_VIEW_ADD_EDIT_CREATED_OR_ASSIGNED: (
    <span>
      Can add new items and options
      <br />
      Can edit items and options that you created or that are assigned to you
      <br />
      Can delete items and options that you created
      <br />
      NOTE: title and description are included here;
      <br />
      all other items and options details follow the permissions below
    </span>
  ),
  CAN_VIEW_ADD_EDIT_DELETE_EDIT_CURRENT_MILESTONE: (
    <span>
      Can view, add, edit, delete
      <br />
      Can edit active milestone
    </span>
  ),
  CAN_VIEW_ADD_EDIT_DELETE_ITEMS: (
    <span>
      Can add new items and options
      <br />
      Can edit all items and options
      <br />
      Can delete items and options
      <br />
      NOTE: title and description are included here;
      <br />
      all other item/option details follow the permissions below
    </span>
  ),
};

export default oldTextMap;
