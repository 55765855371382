import { FC } from 'react';

import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button } from '../../scales';
import dialogsStyles from '../DialogsStyles';

type Props = {
  classes: Classes<typeof dialogsStyles>;
  dialogContent?: string;
  dialogTitle?: string;
  displayDialogsConfirmDelete?: boolean;
  onClose: () => void;
  onDeleteSubmit: () => void;
};

const DialogsMilestoneConfirmDelete: FC<Props> = ({
  classes,
  dialogContent = '',
  dialogTitle = 'Confirm Delete',
  displayDialogsConfirmDelete = false,
  onClose,
  onDeleteSubmit,
}) => {
  if (displayDialogsConfirmDelete) {
    return (
      <Dialog disableBackdropClick open>
        <div className={classes.titleContainer}>
          <Typography variant="title">{dialogTitle}</Typography>
        </div>
        <Divider />
        <DialogContent className={classes.content}>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            label="Cancel"
            type="secondary"
            onClick={() => {
              if (onClose) onClose();
            }}
          />
          <Button
            data-cy="DialogsConfirm-okButton"
            label="Delete"
            startIcon={<DeleteOutline />}
            type="destructive"
            onClick={() => {
              onDeleteSubmit();
            }}
          />
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

export const DialogsRemoveConfirmationStyled = withStyles(dialogsStyles)(
  DialogsMilestoneConfirmDelete
);

export default DialogsRemoveConfirmationStyled;
