import { useEffect, useRef, useState } from 'react';
import { useInteractOutside } from 'react-aria';
import { useKeyPressEvent } from 'react-use';

import { searchEventTypes } from '../../../../analytics/analyticsEventProperties';
import useProjectPropsQuery from '../../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import useCoreSearchPermissions from '../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../hooks/useSendSearchAnalytics';

import ItemContent from './content/items/ItemContent';
import ProjectContent from './content/projects/ProjectContent';
import SearchBar from './content/SearchBar';
import SearchModeSelection from './content/SearchModeSelection';
import styles from './GlobalSearchContentStyles';
import { GlobalSearchMode } from './GlobalSearchUtils';

interface GlobalSearchContentProps {
  classes: Classes<typeof styles>;
  onClose: () => void;
}

const GlobalSearchContent = (props: GlobalSearchContentProps) => {
  const { classes, onClose } = props;

  const sendSearchAnalytics = useSendSearchAnalytics();

  const [searchMode, setSearchMode] = useState<GlobalSearchMode>(GlobalSearchMode.ALL_PROJECTS);
  const [search, setSearch] = useState('');
  useEffect(() => {
    if (search !== '') {
      sendSearchAnalytics(searchEventTypes.SEARCH_TEXT_ENTERED, {
        search,
        type: searchMode,
        location: 'Global',
      });
    }
  }, [search, searchMode, sendSearchAnalytics]);

  const projectName = useProjectPropsQuery(getProjectIdFromUrl()).data.project?.name;

  const { hasItemsAccess } = useCoreSearchPermissions();

  const ref = useRef(null);
  useInteractOutside({ ref, onInteractOutside: onClose }); // close search when clicking away
  useKeyPressEvent('Escape', onClose);

  const searchBar = (
    <SearchBar
      onChangeSearch={setSearch}
      project={searchMode === GlobalSearchMode.PROJECT_ITEMS ? projectName : undefined}
      search={search}
      searchMode={searchMode}
    />
  );
  const searchModeSelection = hasItemsAccess && (
    <>
      <div className={classes.label}>I&apos;m looking for</div>
      <div className={classes.chips}>
        <SearchModeSelection
          searchMode={searchMode}
          onChange={(searchMode) => {
            setSearchMode(searchMode);
            sendSearchAnalytics(searchEventTypes.SEARCH_MODE_CTA, {
              mode: searchMode,
              location: 'Global',
            });
          }}
        />
      </div>
    </>
  );

  return (
    <div ref={ref}>
      <div className={classes.searchContainer}>{searchBar}</div>

      <div className={classes.resultsContainer}>
        {searchModeSelection}
        {searchMode === GlobalSearchMode.ALL_PROJECTS ? (
          <ProjectContent search={search} />
        ) : (
          <ItemContent project={projectName} search={search} searchMode={searchMode} />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(GlobalSearchContent);
