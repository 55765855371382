import { AssetsQuery, Visibility } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useFeatureQuery';
import AssetsCard from '../../../assets/AssetsCards/AssetsCard/AssetsCard';
import AssetsCardAddFile from '../../../assets/AssetsCards/AssetsCard/AssetsCardAddFile';
import { Button, Dialog, DialogContent } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';

type ItemAttachmentsDialogItemCopyProps = {
  itemAssets: AssetsQuery['assets'];
  onBack: () => void;
  onClose: () => void;
  onDownload: (asset: UploadedAsset) => void;
  onNext: () => void;
  onRemove: (assetID: UUID) => void;
  progressPercent?: number;
  onAddFiles: () => void;
  state: ItemCopyState;
};

const ItemAttachmentsDialogItemCopy = (props: ItemAttachmentsDialogItemCopyProps) => {
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');
  const createButtonCopy =
    props.state.visibility === Visibility.PRIVATE_DRAFT ? 'Create as Draft' : 'Create and Publish';
  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onBack} type="secondary" />}
      footerRight={
        <Button
          disabled={!props.state.name}
          label={isItemSharingFeature ? createButtonCopy : 'Create Item'}
          onClick={props.onNext}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Edit Item Attachments"
    >
      <DialogContent>
        <div className="flex flex-col gap-6">
          {props.itemAssets && (
            <div className="flex flex-wrap gap-4">
              {props.itemAssets.map((asset) => (
                <div className="flex flex-col gap-1" key={asset?.id}>
                  <AssetsCard
                    asset={asset as UploadedAsset}
                    url={(asset as UploadedAsset).displayURL}
                    canDelete
                    showMenu={false}
                  />
                  <button
                    className="type-small-link"
                    onClick={() => props.onDownload(asset as UploadedAsset)}
                  >
                    Download
                  </button>
                  <button
                    className="text-type-delete outline-none type-label hover:underline"
                    onClick={() => props.onRemove(asset?.id || '')}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <AssetsCardAddFile onClick={props.onAddFiles} />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ItemAttachmentsDialogItemCopy;
