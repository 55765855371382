import { FC } from 'react';

import { Add } from '@material-ui/icons/';

import { SET_ITEM_ESTIMATE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { Button } from '../../scales';

import { useCreateItemEstimate } from './hooks/CreateItemEstimateHooks';

type ItemsButtonAddEstimateProps = {
  icon?: JSX.Element;
  itemId: UUID;
  projectId: UUID;
  setIsAllExpanded?: (expanded: boolean) => void;
  onItemMutated?: () => void;
};

const ItemsButtonAddEstimate: FC<ItemsButtonAddEstimateProps> = ({
  icon = <Add />,
  itemId,
  projectId,
  setIsAllExpanded,
  onItemMutated,
}) => {
  const sendAnalytics = useAnalyticsEventHook();
  const [createItemEstimate] = useCreateItemEstimate(projectId, itemId, {
    onCompleted: onItemMutated,
  });
  return (
    <Button
      data-cy="button-addEstimate"
      label="Add Estimate"
      onClick={() => {
        sendAnalytics(analyticsEvent(SET_ITEM_ESTIMATE));
        createItemEstimate();
        if (setIsAllExpanded) setIsAllExpanded(true);
      }}
      startIcon={icon}
      type="secondary"
    />
  );
};

export default ItemsButtonAddEstimate;
