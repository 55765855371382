import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (props: any) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66634 4.99998V1.66665H8.33301C8.69967 1.66665 8.99967 1.36665 8.99967 0.99998C8.99967 0.633313 8.69967 0.333313 8.33301 0.333313H1.66634C1.29967 0.333313 0.999674 0.633313 0.999674 0.99998C0.999674 1.36665 1.29967 1.66665 1.66634 1.66665H2.33301V4.99998C2.33301 6.10665 1.43967 6.99998 0.333008 6.99998V8.33331H4.31301V13L4.97967 13.6666L5.64634 13V8.33331H9.66634V6.99998C8.55968 6.99998 7.66634 6.10665 7.66634 4.99998Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
