import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import {
  ItemSidebarLocation,
  itemAssistantCTAAnalytics,
  navigateItemsAnalytics,
  printItemDetail,
  updateItemDescriptionAnalytics,
  updateItemNameAnalytics,
  updateItemNumberProps,
} from '../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { ACCEPTED, IS_EXPANDED, ITEM_DETAILS } from '../../constants';
import { NS_PROCORE_CHANGE_EVENT_INTEGRATION } from '../../features';
import { PermissionResource, ResourceType } from '../../generated/graphql';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import useLocalStorage from '../../hooks/useLocalStorage';
import { hasModuleEntitlement } from '../../hooks/useModuleEntitlementsQuery';
import { PROJECT_CREATION } from '../../moduleEntitlementFlagsList';
import { withStyles } from '../../theme/komodo-mui-theme';
import { costModeVar, useCostMode } from '../../utilities/costMode';
import { getDraftItemIDs, getSharedUsersMap } from '../../utilities/items';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { useScrollToHashOnce } from '../../utilities/scrolling';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { useAttachToItem } from '../assets/hooks/mutations/useAttachToItem';
import CommentsHistory from '../Comments/CommentsHistory/CommentsHistory';
import { DialogsNewItem } from '../Dialogs';
import InputsTextAreaStyled from '../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import ItemNumberTextField from '../Inputs/ItemNumberTextField/ItemNumberTextField';
import ItemsDetailsTopNav from '../Items/ItemDetailsTopNav/ItemDetailsTopNav';
import ItemPublishDialogFF from '../Items/ItemPublishModal/ItemPublishDialogFF';
import AssetsCollapse from '../Items/ItemsCollapse/AssetsCollapse';
import EstimateCollapse from '../Items/ItemsCollapse/EstimateCollapse';
import IntegrationsCollapse from '../Items/ItemsCollapse/IntegrationsCollapse';
import OptionCollapse from '../Items/ItemsCollapse/OptionCollapse';
import ScheduleImpactCollapse from '../Items/ItemsCollapse/ScheduleImpactCollapse';
import useUpdateStatus from '../Items/ItemsHooks';
import { getTotalItemAttachmentCount, isPrivateVisibility } from '../Items/ItemsUtils';
import ItemVisibilityToggleEditWrapper from '../Items/ItemVisibilityToggle/ItemVisibilityToggleEditWrapper';
import ItemsIconsStatusSelect from '../ItemsList/ItemsIcons/ItemsIconsStatusSelect/ItemsIconsStatusSelect';
import { useItemsListSettings } from '../ItemsList/ItemsListSettingsUtils';
import { useGetSharedResources } from '../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { ModuleEntitlementsStore } from '../ModuleEntitlementsProvider';
import { isPrintKeys } from '../Print/PrintUtils';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { Button, TextInput } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import ItemAssistantConfirmationDialog from './ItemAssistantConfirmationDialog/ItemAssistantConfirmationDialog';
import useGetExperimentArm from './ItemAssistantConfirmationDialog/itemAssistantExperimentEnrollment';
import ItemDetailExportPanel from './ItemDetailExportPanel/ItemDetailExportPanel';
import {
  useItemDescriptionUpdate,
  useItemNameUpdate,
  useItemNumberUpdate,
} from './ItemDetailsHooks';
import ItemDetailsSettingsPanel from './ItemDetailsSettingsPanel/ItemDetailsSettingsPanel';
import styles from './ItemDetailsStyles';
import ItemSummary from './ItemSummary';

type ItemDetailsProps = {
  canAddItemComments: boolean;
  canDeprecateItems: boolean;
  canEditItemDetails: boolean;
  canEditItemStatus: boolean;
  canViewItemIntegrations: boolean;
  canDeleteItemIntegration: boolean;
  classes: Classes<typeof styles>;
  isExpanded: boolean;
  item: ItemDataQueryItem;
  navigationLinks?: NavigationLinks;
  parentItem: ItemLike;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  refetchItem: (variables?: any) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  width: Breakpoint;
};

const ItemDetails: FC<ItemDetailsProps> = ({
  canAddItemComments = false,
  canDeprecateItems = false,
  canEditItemDetails = false,
  canEditItemStatus = false,
  canViewItemIntegrations = false,
  canDeleteItemIntegration = false,
  classes,
  isExpanded,
  item,
  navigationLinks,
  parentItem,
  refetchItem: refetchItemOuter,
  setIsExpanded,
  width = 'lg',
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const itemID = getItemIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const hasProcoreIntegrationAccess = useHasFeature(NS_PROCORE_CHANGE_EVENT_INTEGRATION);
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');
  const hasItemAssistFeature = useHasFeature('CT_ITEM_ASSIST_EXPERIMENT');

  const costMode = useCostMode();
  useScrollToHashOnce(window.location.hash);

  // REFETCH
  const refetchItem = () => refetchItemOuter({ id: itemID, costMode: costModeVar() });

  // DERIVED ITEM CONSTS
  const {
    availableStates,
    bookmarks,
    comments,
    descriptionStyled,
    id,
    itemType,
    milestone,
    name,
    number,
    status,
    visibility,
    estimateCost,
    assetCount,
    contingencyDrawCost,
  } = item;
  const isPrivate = isPrivateVisibility(visibility);
  const options = ('options' in item ? item.options : []) as Option[];
  const hasOptions = 'hasOptions' in item ? item.hasOptions : false;
  const { parent, parentVisibility } = (item as Option) || {};
  const draftOptionIDs = useMemoWrapper(getDraftItemIDs, options);
  const sharedResourcesResult = useGetSharedResources(draftOptionIDs, ResourceType.ITEM);
  const sharedUsersMap = useMemoWrapper(getSharedUsersMap, options, sharedResourcesResult.data);

  // STATE VARIABLES
  const [openDialogAddOption, setOpenDialogAddOption] = useState(false);
  const [itemName, setItemName] = useState(name || '');
  const [showPublishItemDialog, setShowPublishItemDialog] = useState(false);
  const [itemAssistItemIDs, setItemAssistItemIDs] = useLocalStorage(
    'ITEM_ASSIST_EXP_IDS',
    [] as string[]
  );
  const itemAssistAlreadyRequested = itemAssistItemIDs.includes(itemID);
  const [openItemAssistDialog, setOpenItemAssistDialog] = useState(false);

  const isOption = !!parent;

  const { canView, canEdit } = usePermissions({ trades: item.categories });
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canViewScheduleImpact = canView(PermissionResource.SCHEDULE_IMPACT);
  const canEditScheduleImpact = canEdit(PermissionResource.SCHEDULE_IMPACT);
  // Temporary. Used as a proxy permission for item assist experiment may 2024.
  const canEditEstimateMarkup = canEdit(PermissionResource.MARKUPS);

  const itemAssistExpArm = useGetExperimentArm();
  const moduleEntitlementFlags = useContext(ModuleEntitlementsStore);
  const canCreateProject = hasModuleEntitlement(moduleEntitlementFlags, PROJECT_CREATION);
  // Only show item assist if the FF is ON and the user is GC or admin (proxied by being able to edit markups)
  const showItemAssist =
    !itemAssistAlreadyRequested &&
    hasItemAssistFeature &&
    canCreateProject &&
    canEditEstimateMarkup &&
    itemAssistExpArm === 'E';

  const hideScheduleImpact = isScheduleSettingsDisabled() || hasOptions;

  const createdByID = item?.createdBy?.id;
  const numItemAttachments = getTotalItemAttachmentCount(item);

  // function for expanding the Option section when converting an item to an option
  const { persistSettingsChange } = useItemsListSettings();
  const expandEstimateContainer = () => persistSettingsChange(IS_EXPANDED, true);

  // analytics
  const location = ItemSidebarLocation.ITEM_DETAILS_PAGE;

  // Hooks to update item name, description number, status
  const updateItemName = useItemNameUpdate();
  const setUpdateItemName = () => {
    if (name !== itemName) {
      sendAnalytics(
        updateItemNameAnalytics({
          itemID: id,
          newName: itemName,
          itemType,
          location,
        })
      );
      updateItemName(projectId, id, itemName);
    }
  };

  const sendItemDescriptionAnalytics = (newDescription: string) => {
    sendAnalytics(
      updateItemDescriptionAnalytics({
        itemID: id,
        newDescription,
        itemType,
        location,
      })
    );
  };

  const updateItemDescription = useItemDescriptionUpdate();
  const setUpdateItemDescription = (description: string, descriptionStyled: string) => {
    sendItemDescriptionAnalytics(description);
    updateItemDescription(projectId, item.id, description, descriptionStyled);
  };

  const attachToItem = useAttachToItem();
  const onAttachAsset = (asset: Asset) => {
    attachToItem({ input: { assetID: asset.id, itemID } });
  };

  const updateItemNumber = useItemNumberUpdate();
  const setItemNumber = (itemId: UUID, itemNumber: string) => {
    if (number !== itemNumber) {
      updateItemNumber(projectId, itemId, itemNumber);
      sendAnalytics(updateItemNumberProps(itemId, itemNumber, itemType, location));
    }
  };

  const updateStatus = useUpdateStatus();
  const setUpdateStatus = (newStatus: Status) => {
    if (status !== newStatus) updateStatus(projectId, item, newStatus);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const printOnClick = (withHistory?: boolean) => {
    if (id) {
      sendAnalytics(printItemDetail(id));
      let search = `?costMode=${costMode}`;
      if (withHistory) search += '&withHistory=true';
      window.open(
        generateSharedPath(JoinProjectRoutes.PRINT_ITEM_DETAILS, { projectId, itemId: id, search }),
        '_blank'
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const handleKey = (e: any) => {
      if (isPrintKeys(e)) {
        e.stopImmediatePropagation();
        e.preventDefault();
        printOnClick();
        return;
      }
      if (
        // return if we've got no node next/previous data...
        !navigationLinks ||
        // or this is a key on an input
        (e.target && ['INPUT', 'TEXTAREA', 'LI'].includes(e.target.tagName)) ||
        // or this is a grid
        (e.path &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          e.path.filter((elt: any) => elt.classList && elt.classList.contains('ag-root')).length >
            0)
      )
        return;
      const { location: { search } = { search: '' } } = window;
      switch (e.keyCode) {
        case 37:
          if (navigationLinks.previous && !assetViewerOpenVar()) {
            sendAnalytics(navigateItemsAnalytics('Item Back Key'));
            navigate({
              pathname: generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
                projectId,
                itemId: navigationLinks.previous.id,
              }),
              search,
            });
          }
          break;
        case 39:
          if (navigationLinks.next && !assetViewerOpenVar()) {
            sendAnalytics(navigateItemsAnalytics('Item Forward Key'));
            navigate({
              pathname: generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
                projectId,
                itemId: navigationLinks.next.id,
              }),
              search,
            });
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [navigate, navigationLinks, printOnClick, projectId, sendAnalytics]);

  // COMPONENTS
  const commentsList = comments && (
    <CommentsHistory
      canComment={canAddItemComments}
      costMode={costMode}
      itemId={itemID}
      variant={ITEM_DETAILS}
    />
  );

  const itemNumber = (
    <ItemNumberTextField
      editable={canEditItemDetails}
      isPrivate={isPrivate}
      itemId={id}
      itemType={itemType}
      setItemNumber={setItemNumber}
      value={number}
    />
  );

  const statusIcon = (
    <ItemsIconsStatusSelect
      availableStates={availableStates}
      editable={canEditItemStatus}
      item={item}
      options={options}
      status={status}
      updateStatus={setUpdateStatus}
    />
  );

  const title = (
    <div className={`${classes.rowContainer} ${classes.titleRow}`}>
      <div className={classes.titleContainer}>
        <TextInput
          data-cy="input-itemName"
          aria-label="Item name"
          disabled={!canEditItemDetails}
          value={itemName}
          id="name"
          placeholder="Item name"
          onChange={(e) => setItemName(e)}
          onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
            if (evt.key === 'Enter') setUpdateItemName();
          }}
          onBlur={() => {
            setUpdateItemName();
          }}
        />
      </div>
      <div>{statusIcon}</div>
    </div>
  );

  const itemDetails = (
    <div className={classes.wrapPrint}>
      <div className={classes.root}>
        <div data-cy="itemDetails-centerCol" className={classes.centerColumn}>
          <ItemsDetailsTopNav
            item={item}
            parentItem={parentItem}
            navigationLinks={navigationLinks}
            projectId={projectId}
          />
          <div className={classes.flexNoPrint}>
            <div className={classes.flexNoPrint}>
              {itemNumber}
              {isItemSharingFeature && <ItemDetailExportPanel printOnClick={printOnClick} />}
              <ItemDetailsSettingsPanel
                canDeprecateItems={canDeprecateItems}
                canEditItemDetails={canEditItemDetails}
                isOption={isOption}
                item={item}
                options={options}
                projectId={projectId}
                setOpenDialogAddOption={setOpenDialogAddOption}
              />
              {showItemAssist && (
                <Button
                  label="Try Item Assist"
                  type="secondary"
                  startIcon={
                    <img
                      className="colors-button-inactive"
                      src="/img/science.svg"
                      width={24}
                      alt=""
                    />
                  }
                  onClick={() => {
                    sendAnalytics(itemAssistantCTAAnalytics({ type: 'CTA', itemID }));
                    setOpenItemAssistDialog(true);
                  }}
                />
              )}
              {itemAssistAlreadyRequested && (
                <div className="flex h-10 items-center gap-2">
                  <img src="/img/science.svg" width={16} alt="" />
                  <div className="type-body3">Item Assist Requested</div>
                </div>
              )}
            </div>
            <div className={classes.flexNoPrint}>
              <ItemVisibilityToggleEditWrapper
                createdByID={createdByID}
                isOption={isOption}
                parentVisibility={parentVisibility ?? undefined}
                setShowPublishItemDialog={setShowPublishItemDialog}
                visibility={visibility}
              />
              {!isItemSharingFeature && <ItemDetailExportPanel printOnClick={printOnClick} />}
              {milestone && (
                <DialogsNewItem
                  key={id}
                  onClose={() => {
                    refetchItem();
                    setOpenDialogAddOption(false);
                  }}
                  milestoneID={milestone.id}
                  open={openDialogAddOption}
                  parentItem={item as Item}
                  onCreateAnother={refetchItem}
                  expandEstimateContainer={expandEstimateContainer}
                />
              )}
            </div>
            <ItemPublishDialogFF
              itemCreatedByID={createdByID}
              itemID={itemID}
              isOpen={showPublishItemDialog}
              setIsOpen={setShowPublishItemDialog}
              projectID={projectId}
            />
            {openItemAssistDialog && (
              <ItemAssistantConfirmationDialog
                projectID={projectId}
                itemID={itemID}
                onSubmit={() => {
                  setOpenItemAssistDialog(false);
                  setItemAssistItemIDs([itemID, ...itemAssistItemIDs]);
                  sendAnalytics(itemAssistantCTAAnalytics({ type: 'Submit', itemID }));
                }}
                onDismiss={() => {
                  setOpenItemAssistDialog(false);
                  sendAnalytics(itemAssistantCTAAnalytics({ type: 'Dismiss', itemID }));
                }}
              />
            )}
          </div>
          {title}
          <InputsTextAreaStyled
            data-cy="input-itemLikeDescription"
            editable={canEditItemDetails}
            placeholder="Describe this item..."
            onAttachAsset={onAttachAsset}
            onChangeComplete={(description, descriptionStyled) => {
              setUpdateItemDescription(description, descriptionStyled);
            }}
            textStyled={descriptionStyled}
          />
          {hasOptions ? (
            <OptionCollapse
              projectID={projectId}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              sharedUsersMap={sharedUsersMap}
              setOpenDialogAddOption={setOpenDialogAddOption}
            />
          ) : (
            <EstimateCollapse
              setOpenDialogAddOption={setOpenDialogAddOption}
              isExpanded={isExpanded}
              isOption={!!parentItem}
              itemStatus={status}
              projectID={projectId}
              setIsExpanded={setIsExpanded}
            />
          )}
          {!hideScheduleImpact && canViewScheduleImpact && (
            <ScheduleImpactCollapse
              canEdit={canEditScheduleImpact}
              canView={canViewScheduleImpact}
            />
          )}
          <div className={classes.noPrint}>
            {!isWidthUp('lg', width) && <ItemSummary width={width} />}
          </div>
          {canViewItemAttachments && (
            <AssetsCollapse
              bookmarks={bookmarks as Bookmark[]}
              canView={canViewItemAttachments}
              numItemAttachments={numItemAttachments}
            />
          )}
          {canViewItemIntegrations && hasProcoreIntegrationAccess && (
            <IntegrationsCollapse
              canCreateChangeEvent={item.status === ACCEPTED}
              canDeleteItemIntegration={canDeleteItemIntegration}
              itemInfo={{
                id,
                name,
                descriptionStyled,
                estimateCost,
                assetCount,
                contingencyDrawCost,
              }}
            />
          )}
          {commentsList}
        </div>
        {isWidthUp('lg', width) && (
          <div className={classes.noPrint}>
            <div className={classes.rightColumn}>
              <ItemSummary width={width} />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return itemDetails;
};

const ItemDetailsStyled = withStyles(styles)(ItemDetails);
export default withWidth()(ItemDetailsStyled);
