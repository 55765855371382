import { SvgIcon } from '@material-ui/core';

type Props = {
  fontSize?: 'inherit' | 'default';
};

export const IconsOptions = (props: Props) => (
  <SvgIcon viewBox="0 0 16 16" fontSize={props.fontSize}>
    <path
      d="M9.33334 2.66669L10.86 4.19335L8.94 6.11335L9.88667 7.06002L11.8067 5.14002L13.3333 6.66669V2.66669H9.33334ZM6.66667 2.66669H2.66667V6.66669L4.19334 5.14002L7.33334 8.27335V13.3334H8.66667V7.72669L5.14 4.19335L6.66667 2.66669Z"
      fill="black"
    />
  </SvgIcon>
);
