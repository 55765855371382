import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { ARROW_LEFT, ARROW_RIGHT, ESC } from '../../../constants';
import { ItemAssetsQuery, Maybe } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { assetIsImageType, assetIsModelType, assetIsPdfType } from '../utils';

import AssetsImageViewer from './AssetsImageViewer/AssetsImageViewer';
import AssetsModelViewer from './AssetsModelViewer/AssetsModelViewer';
import AssetsPDFViewer from './AssetsPDFViewer/AssetsPDFViewer';
import AssetsViewerFooter from './AssetsViewerFooter';
import AssetsViewerNavigation from './AssetsViewerNavigation';
import AssetsViewerStyles from './AssetsViewerStyles';

type AssetsViewerProps = {
  asset: Asset | NonNullable<ItemAssetsQuery['itemAssets']>[number] | ProjectThumbnail;
  bookmarkEditIndex?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  bookmarks?: any;
  canDelete?: boolean;
  classes: Classes<typeof AssetsViewerStyles>;
  closeModal: (assetID: UUID) => void;
  detachAttachment?: () => void;
  downloadAsset?: () => void;
  hideActions?: boolean;
  numAssets?: number;
  open?: boolean;
  setEditingView?: (index: number) => void;
  shiftViewer?: (index: number) => void;
  type?: Maybe<string>;
  viewOnly?: boolean;
  showMenu: boolean;
};

const AssetsViewer: FC<AssetsViewerProps> = ({
  asset,
  canDelete = false,
  bookmarkEditIndex,
  bookmarks,
  classes,
  closeModal,
  detachAttachment,
  downloadAsset,
  numAssets,
  open = false,
  setEditingView,
  shiftViewer = () => {},
  type,
  viewOnly = false,
  hideActions,
  showMenu,
}) => {
  const viewerIsOpen = useReactiveVar(assetViewerOpenVar);
  const showNavigationButtons = !hideActions && (!numAssets || numAssets > 1);

  const assetID = asset?.id;
  const onCloseModal = (assetID?: UUID) => () => {
    if (assetID) closeModal(assetID);
  };

  // Key navigation
  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      const shouldShiftViewer = !!asset;
      e.stopPropagation();
      switch (e.key) {
        case ESC:
          if (assetID) closeModal(assetID);
          break;
        case ARROW_LEFT:
          if (shouldShiftViewer) shiftViewer(-1);
          break;
        case ARROW_RIGHT:
          if (shouldShiftViewer) shiftViewer(1);
          break;
        default:
          break;
      }
    };
    if (viewerIsOpen) {
      window.addEventListener('keydown', handleKey);
      return () => {
        window.removeEventListener('keydown', handleKey);
      };
    }
    return () => {};
  }, [asset, viewerIsOpen, assetID, closeModal, shiftViewer]);

  let viewerComponent: JSX.Element | undefined;
  if (asset && 'displayURL' in asset && asset.displayURL && assetIsModelType(type)) {
    // display model
    viewerComponent = (
      <AssetsModelViewer
        bookmarkEditIndex={bookmarkEditIndex}
        setEditingView={setEditingView}
        bookmarks={bookmarks}
        url={asset.displayURL}
        disabled={viewOnly}
      />
    );
  } else if (asset && assetIsImageType(type)) {
    // display image
    viewerComponent = <AssetsImageViewer asset={asset as Asset} />;
  } else if (asset && assetIsPdfType(type)) {
    // display pdf
    const assetLocation = ('displayURL' in asset && asset.displayURL) || undefined;
    viewerComponent = <AssetsPDFViewer assetLocation={assetLocation} />;
  }

  if (!viewerComponent) return <></>;

  return (
    <div>
      <Dialog
        aria-labelledby="Asset-Viewer"
        aria-describedby="a window for observing content loaded on Join"
        open={viewerIsOpen || open}
        onClose={onCloseModal(assetID)}
        classes={{ paper: classes.dialog }}
      >
        <>
          <Close
            onClick={onCloseModal(assetID)}
            className={classes.closeButton}
            data-cy="close-closeAssetViewer"
          />
          <div>
            <div className={classes.modal}>
              <div className={`${classes.paper} ${!hideActions ? classes.paperBackground : ''}`}>
                <>
                  {viewerComponent}
                  {!hideActions && (
                    <AssetsViewerFooter
                      asset={asset as UploadedAsset}
                      canDelete={canDelete}
                      downloadAsset={downloadAsset}
                      detachAttachment={detachAttachment}
                      showMenu={showMenu}
                      type={type}
                    />
                  )}
                </>
              </div>
            </div>
            {showNavigationButtons && <AssetsViewerNavigation shiftViewer={shiftViewer} />}
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default withStyles(AssetsViewerStyles)(AssetsViewer);
