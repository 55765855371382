import { FC } from 'react';
import { useMeasure } from 'react-use';

import { KeyboardArrowRight, MoreHoriz } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import useMemoWrapper from '../../useMemoWrapper';

import NavBreadcrumb from './NavBreadcrumb/NavBreadcrumb';
import { NavigationBarEntry } from './NavBreadcrumb/NavBreadcrumbUtils';
import styles from './NavigationBarStyles';
import { NavigationBarTruncatedEntry, getNavigationBarParams } from './NavigationBarUtils';

type NavigationBarProps = {
  classes: Classes<typeof styles>;
  entries: NavigationBarEntry[];
  onEntryClick: (entry: NavigationBarEntry) => void;
};

const NavigationBar: FC<NavigationBarProps> = ({ classes, entries, onEntryClick }) => {
  const [barRef, { width }] = useMeasure<HTMLDivElement>();

  const { breadcrumbEntries, hasBreadcrumbs, hasMenu, menuOptions } = useMemoWrapper(
    getNavigationBarParams,
    entries,
    width
  );

  const renderBreadcrumb = (crumb: NavigationBarTruncatedEntry, index: number) => {
    const isFirstOne = index === breadcrumbEntries.length - 1;
    const isLastOne = index === 0;
    const { id, name, textWidth, maxWidth } = crumb;

    const arrow = (
      <KeyboardArrowRight className={`${classes.arrow} ${isFirstOne ? classes.hidden : ''}`} />
    );
    const breadcrumb = (
      <NavBreadcrumb
        isActive={isLastOne}
        onClick={() => onEntryClick(crumb)}
        value={name}
        textWidth={textWidth}
        maxWidth={maxWidth}
      />
    );
    const breadcrumbStyle = `${classes.breadcrumb} ${isLastOne ? classes.pushLeft : ''}`;
    return (
      <div className={breadcrumbStyle} key={id}>
        {arrow}
        {breadcrumb}
      </div>
    );
  };

  const breadcrumbs = breadcrumbEntries.map(renderBreadcrumb);

  const menuEntries = menuOptions.map((entry: NavigationBarEntry) => ({
    name: entry.name,
    callback: () => onEntryClick(entry),
  }));

  const menu = (
    <div className={`${classes.horizontal} ${!hasBreadcrumbs ? classes.pushLeft : ''}`}>
      <KeyboardArrowRight
        className={`${classes.arrow} ${classes.centerAligned} ${
          !hasBreadcrumbs ? classes.hidden : ''
        }`}
      />
      <IconMenu icon={<MoreHoriz />} options={menuEntries} />
    </div>
  );

  return (
    <div className={classes.fullWidth} ref={barRef}>
      <div className={classes.container}>
        {hasBreadcrumbs && breadcrumbs}
        {hasMenu && menu}
      </div>
    </div>
  );
};

export default withStyles(styles)(NavigationBar);
