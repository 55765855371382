import { FC } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './InputsToggleStyles';

export type ToggleOption = {
  text: string;
  key?: string;
  title?: string;
  icon?: JSX.Element;
};

type InputsToggleProps = {
  classes: Classes<typeof styles>;
  isCompact?: boolean;
  isMarginless?: boolean;
  isWide?: boolean;
  isVisible?: boolean;
  selected: ToggleOption[];
  options: ToggleOption[];
  setSelected: (select: ToggleOption) => void;
};

// Note: text must be unique
export const createInputOptionsFromText = (optionTexts: string[]) =>
  optionTexts.map((text) => ({ text }));

const InputsToggle: FC<InputsToggleProps> = ({
  classes,
  isCompact = true,
  isMarginless = false,
  isWide = false,
  isVisible = true,
  setSelected,
  selected,
  options,
}) => (
  <div
    className={`${classes.toggle} ${isMarginless ? classes.toggleMarginless : ''} ${
      isCompact ? classes.toggleCompact : ''
    } ${isWide ? classes.toggleWide : ''}`}
    aria-label="Toggle Select"
    style={isVisible ? {} : { visibility: 'hidden' }}
  >
    {options.map((option: ToggleOption) => {
      // To determine selection, If key, use key, else use text
      const isSelected = selected.some(({ key, text }: ToggleOption) =>
        key ? key === option.key : text === option.text
      );
      return (
        <ButtonBase
          aria-label={`Select ${option.text}`}
          className={`${classes.button} ${isSelected ? classes.selected : ''} ${
            isCompact ? classes.buttonCompact : ''
          } ${isWide ? classes.buttonWide : ''}`}
          data-cy={isVisible ? option.text : ''}
          key={`${option.key}${option.text}`}
          title={option.title}
          onClick={() => setSelected(option)}
          onKeyPress={() => setSelected(option)}
        >
          {!option.icon && <Typography>{option.text}</Typography>}
          {option.icon && <>{option.icon}</>}
        </ButtonBase>
      );
    })}
  </div>
);

/** @deprecated in favor of design system component, please use scales/ButtonBar */
export default withStyles(styles)(InputsToggle);
