import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const HORIZONTAL_MARGIN = 320;
export const VERTICAL_MARGIN = 40;
export const BUTTON_MARGIN = 16;
export const PDF_PAGE_TOP_BOTTOM_PADDING = 8;
export const PDF_PAGE_GAP = PDF_PAGE_TOP_BOTTOM_PADDING * 2;

const CLOSE_BUTTON_SIZE = 28;
const CLOSE_BUTTON_PADDING = 18;

const viewerWidth = `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`;
const viewerHeight = `calc(100% - ${2 * VERTICAL_MARGIN}px)`;

export default (theme: KomodoTheme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.joinGrey200,
    },
    bookmarkName: {
      width: 160,
    },
    dialog: {
      width: viewerWidth,
      height: viewerHeight,
      position: 'unset',
      background: 'unset',
      margin: 'unset',
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
    closeButton: {
      color: theme.palette.joinGrey200,
      '&:hover': {
        backgroundColor: theme.palette.hoverSemitransparentJoinGrey200,
        color: theme.palette.backgroundWhite,
      },
      zIndex: 1066,
      margin: BUTTON_MARGIN,
      width: CLOSE_BUTTON_SIZE + CLOSE_BUTTON_PADDING,
      height: CLOSE_BUTTON_SIZE + CLOSE_BUTTON_PADDING,
      opacity: 0.9,
      padding: CLOSE_BUTTON_PADDING,
      right: 0,
      top: 0,
      position: 'absolute',
      borderRadius: '100%',
      cursor: 'pointer',
    },
    modal: {
      width: viewerWidth,
      height: viewerHeight,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
      },
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    paper: {
      outline: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    paperBackground: {
      background: theme.palette.shadedGrey,
    },
  });
