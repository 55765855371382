import { ComponentProps, useContext } from 'react';

import Https from '@material-ui/icons/Https';
import {
  DiscreteColorLegend,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';

import { YC_SCENARIO_SHARING } from '../../features';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import theme from '../../theme/komodo-mui-theme';
import { useLegendLabels } from '../Charts/ChartsEstimate/ChartsEstimateUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { Switch } from '../scales';
import MilestoneSelect from '../Select/MilestoneSelect';

import SandboxExportPanel from './Sandbox/SandboxExportPanel';
import { SandboxView, isWorkspaceView } from './Sandbox/SandboxUtil';
import SandboxViewModeToggle from './Sandbox/SandboxViewModeToggle';

type Props = {
  milestones: ComponentProps<typeof MilestoneSelect>['milestones'];
  onChangeMilestone: ComponentProps<typeof MilestoneSelect>['onChangeMilestone'];
  onToggleShowMilestone: ComponentProps<typeof Switch>['onChange'];
  sandboxView: SandboxView;
  setSandboxView: (view: SandboxView) => void;
  selectedMilestoneID: ComponentProps<typeof MilestoneSelect>['selectedMilestone'];
  showMilestone: boolean;
  hasBudget: boolean;
};

export const ScenariosHeader = (props: Props) => {
  const t = useContext(ProjectTermStore);
  const legendLabels = useLegendLabels(t, props.hasBudget);
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);
  return (
    <div className="flex shrink-0 grow-0 items-center gap-6 p-6 pt-5">
      <div className="w-[272px]">
        <MilestoneSelect
          milestones={props.milestones}
          onChangeMilestone={props.onChangeMilestone}
          selectedMilestone={props.selectedMilestoneID}
        />
      </div>
      <div className="flex-grow">
        <SandboxViewModeToggle
          sandboxView={props.sandboxView}
          setSandboxView={props.setSandboxView}
        />
        <Switch
          checked={props.showMilestone}
          data-cy="switch-show-base-milestone"
          label="Show Base Milestone"
          onChange={props.onToggleShowMilestone}
        />
      </div>
      {!isWorkspaceView(props.sandboxView) && (
        <div className="relative top-[-11px] h-8 overflow-visible">
          <DiscreteColorLegend
            style={{
              fontFamily: theme.typography.fontFamily,
            }}
            orientation="horizontal"
            items={legendLabels}
          />
        </div>
      )}
      {props.selectedMilestoneID && <SandboxExportPanel milestoneID={props.selectedMilestoneID} />}
      {!isScenarioSharingFeature && (
        <NormalTooltip title="Scenarios are private to you">
          <div className="flex gap-1 type-body1">
            <Https />
            Private
          </div>
        </NormalTooltip>
      )}
    </div>
  );
};

export default ScenariosHeader;
