import DialogsMilestoneConfirmDelete from './DialogsMilestoneConfirmDelete/DialogsMilestoneConfirmDelete';
import DialogsNewMilestone from './DialogsNewMilestone/DialogsNewMilestone';
import DialogsRemoveCategorization from './DialogsRemoveCategorization/DialogsRemoveCategorization';

export { default as DialogsNewItem } from './DialogsNewItem/DialogsNewItem';
export { default as DialogsAddCollaborator } from './DialogsAddCollaborator/DialogsAddCollaborator';
export { default as DialogsEditProfile } from './DialogsEditProfile/DialogsEditProfile';

export const NewMilestone = DialogsNewMilestone;
export const RemoveCategorization = DialogsRemoveCategorization;
export const MilestoneConfirmDelete = DialogsMilestoneConfirmDelete;
