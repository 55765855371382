import { isScenarioVisibility } from '../../../../Items/ItemsUtils';
import { Chip } from '../../../../scales';
import Dot from '../../../common/Dot';
import { ScenarioItemEntry } from '../../../common/ItemStatus/ItemStatusUtils';
import { Scenario } from '../../../types';

type Props = {
  item: ScenarioItemEntry;
  scenario: Scenario;
};

export default function IndicatorCell(props: Props) {
  return isScenarioVisibility(props.item.visibility) ? (
    <Chip text="New" backgroundColor={props.scenario.color ?? undefined} />
  ) : (
    <div
      data-cy={`${props.item.number} - indicator cell`}
      className="h-2 w-2 fill-selection-focus-fill"
    >
      {props.item.isOverlay && <Dot />}
    </div>
  );
}
