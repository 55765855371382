// NEW COLLABORATORS PAGE
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { PermissionResource } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { DialogsAddCollaborator } from '../../../Dialogs';
import { INVITE_TEAMMATES } from '../../../Dialogs/DialogsAddCollaborator/DialogsAddCollaborator';
import { Team } from '../../../Icons/Team';
import CTAIconButton from '../../../shared-widgets/CTAIconButton';

import styles from './TeamListHeaderStyles';

type TeamListHeaderProps = {
  classes: Classes<typeof styles>;
};

const TeamListHeader: FC<TeamListHeaderProps> = ({ classes }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { canView, canEdit } = usePermissions();
  const canViewPermissions = canView(PermissionResource.PERMISSION_TEMPLATES);
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);

  const [showAddCollaboratorDialog, setShowAddCollaboratorDialog] = useState(false);

  return (
    <div className={classes.containerOuter}>
      <div className={classes.containerTitle}>
        <header className="type-heading1">Team</header>
      </div>
      <div className={classes.containerCtas}>
        {canViewPermissions && (
          <CTAIconButton
            buttonText="See Roles"
            dataCy="button-viewRoles"
            icon={<Team />}
            variant="outlined"
            onClick={() => {
              navigate(generateSharedPath(JoinProjectRoutes.TEAM_ROLES, { projectId }));
            }}
          />
        )}
        {canEditCollaborators && (
          <CTAIconButton
            buttonText={INVITE_TEAMMATES}
            dataCy="AddCollaborators-button"
            icon={<Add />}
            onClick={() => setShowAddCollaboratorDialog(true)}
          />
        )}
        {showAddCollaboratorDialog && (
          <DialogsAddCollaborator onClose={() => setShowAddCollaboratorDialog(false)} />
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(TeamListHeader);
