import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { EstimateType } from '../../../api/gqlEnums';
import { GetDetailedMilestonesQuery, PermissionResource } from '../../../generated/graphql';
import { costModeVar } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import EstimateAccordion from '../../estimate/EstimateAccordion/EstimateAccordion';
import EstimateUploadMilestone from '../../EstimateImport/EstimateUpload/EstimateUploadMilestone';
import { useDraftEstimateInfoQuery } from '../../JoinGrid/hooks/estimateQuery';
import { MilestoneDetailsTabs, getLastUploadedEstimate } from '../utils';

import { CostParams } from './MilestoneDetailsNav/MilestoneDetailsNav';

type MilestoneDetailsEstimateProps = {
  canEditMilestones: boolean;
  costParams: CostParams;
  milestone: GetDetailedMilestonesQuery['milestones'][number];
  milestoneCostReports: MilestoneCostReports;
  refetchMilestone: () => void;
  refetchReports: () => void;
  view: MilestoneDetailsTabs;
};

const MilestoneDetailsEstimate: FC<MilestoneDetailsEstimateProps> = ({
  canEditMilestones,
  costParams,
  milestone,
  milestoneCostReports,
  refetchMilestone,
  refetchReports,
  view,
}) => {
  // Figure out what we are looking at...
  const isTarget = view === MilestoneDetailsTabs.TARGET;
  const draftID = isTarget ? milestone.budgetDraftID : milestone.activeEstimateDraftID;
  const estimateID = isTarget ? milestone.budgetID : milestone.activeEstimateID;
  const hasEstimate = !!estimateID;
  const estimateType = isTarget ? EstimateType.BUDGET : EstimateType.ACTIVE_ESTIMATE;

  const costMode = useReactiveVar(costModeVar);
  const draftEstimate = useDraftEstimateInfoQuery(draftID ?? undefined, costMode).data
    ?.draftEstimateInfo;

  // PERMISSIONS

  const { canView } = usePermissions();
  const canViewDraftEstimates = canView(PermissionResource.DRAFT_ESTIMATE);

  const estimateDisplay = (
    <EstimateAccordion
      costParams={costParams}
      isMilestoneDetails
      milestone={milestone}
      milestoneCostReports={milestoneCostReports}
      refetchReports={refetchReports}
      type={estimateType}
    />
  );

  const { importedEstimates } = milestone;
  const lastUploadedEstimate = getLastUploadedEstimate(importedEstimates, estimateType);
  const draftEstimateFilename = importedEstimates.find((a) => a.id === draftEstimate?.asset)?.name;

  return (
    <>
      <EstimateUploadMilestone
        disabled={!canEditMilestones}
        estimateId={draftID ?? undefined}
        estimateType={estimateType}
        hasEstimate={hasEstimate}
        hideCurrentUpload={!canViewDraftEstimates}
        lastUploadedEstimate={lastUploadedEstimate}
        name={draftEstimateFilename ?? undefined}
        refetchMilestone={refetchMilestone}
      />
      {hasEstimate && estimateDisplay}
    </>
  );
};

export default MilestoneDetailsEstimate;
