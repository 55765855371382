import { useCallback } from 'react';

import {
  SelectCategoryValue,
  UNCATEGORIZED,
} from '../../../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputSingle from '../../../../Select/SelectCategoryChipInput/SelectCategoryChipInputSingle';

type Props = {
  categorization: Categorization;
  category?: Category;
  onChange: (category?: Category) => void;
};

const CategorizationInput = (props: Props) => {
  const onChange = useCallback(
    (selected: SelectCategoryValue) => {
      props.onChange(selected?.id !== UNCATEGORIZED ? selected : undefined);
    },
    [props]
  );

  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">{props.categorization.name}</div>
      <SelectCategoryChipInputSingle
        setCategory={onChange}
        categorizations={[props.categorization]}
        id={props.categorization.id}
        includeUncategorizedCategory
        selectedCategory={props.category}
      />
    </div>
  );
};

export default CategorizationInput;
