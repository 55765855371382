import { useMutation } from '@apollo/client';

import { NULL_ID } from '../../constants';
import {
  GetProjectThumbnailQuery,
  GetProjectThumbnailQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';

import { getProjectThumbnailQuery } from './hooks/queries';
import { setProjectTypeMutation } from './projectPropertyMutations';

export const useGetProjectThumbnailQuery = (projectID: UUID | undefined) =>
  useQuery<GetProjectThumbnailQuery, GetProjectThumbnailQueryVariables>(
    getProjectThumbnailQuery,
    {
      notifyOnNetworkStatusChange: true,
      skip: !projectID || projectID === NULL_ID,
      variables: {
        projectID,
      },
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useSetProjectType = () => {
  const [setProjectType] = useMutation(setProjectTypeMutation);

  return (projectID: UUID, typeID: UUID) =>
    setProjectType({
      variables: { projectID, typeID },
    });
};
