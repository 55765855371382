import { useCallback, useMemo, useState } from 'react';

import { forecastingEventTypes } from '../../../../../analytics/analyticsEventProperties';
import { itemSidebarForecastingOpenVar } from '../../../../../api/apollo/reactiveVars';
import {
  ForecastingExploreItemsFilters,
  ForecastingExploreItemsSortBy,
  ForecastingExploreItemsSortKey,
  ForecastingProjectsFilters,
  SortDirection,
} from '../../../../../generated/graphql';
import { DEFAULT_ITEMS_SEARCH_QUERY } from '../../../../HomeTab/Search/items/SearchItemsUtils';
import { SortManager } from '../../../../scales';
import { useSavedQuery } from '../../../hooks/useSavedQuery';
import useSendForecastingAnalytics from '../../../hooks/useSendForecastingAnalytics';
import useForecastingProjectsQueryParams from '../../projects/hooks/useForecastingProjectsQueryParams';

type ForecastingExploreItemsVariables = {
  filters: ForecastingExploreItemsFilters;
  projectsFilters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingExploreItemsSortBy;
};

const LOCAL_STORAGE_KEY = 'FORECASTING_EXPLORE_ITEMS_QUERY';

const DEFAULT_QUERY: ForecastingExploreItemsVariables = {
  filters: {
    categoryNumbers: [],
    costImpact: {},
    milestoneDate: {},
    milestoneNames: [],
    projectLocations: [],
    projectNames: [],
    projectTypes: [],
    statuses: [],
  },
  projectsFilters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingExploreItemsSortKey.SCORE,
  },
};

export default () => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();

  const [savedQuery, setSavedQuery] = useSavedQuery<ForecastingExploreItemsVariables>(
    LOCAL_STORAGE_KEY,
    DEFAULT_QUERY
  );

  const [filters, setFilters] = useState<ForecastingExploreItemsFilters>(savedQuery.filters);
  const [search, setSearch] = useState(savedQuery.search);
  const [sortBy, setSortBy] = useState<ForecastingExploreItemsSortBy>(savedQuery.sortBy);

  const projectsParams = useForecastingProjectsQueryParams();
  const [projectsFilters, setProjectsFilters] = useState<ForecastingProjectsFilters>(
    projectsParams.variables.filters
  );

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingExploreItemsFilters,
      filterValue: ForecastingExploreItemsFilters[keyof ForecastingExploreItemsFilters]
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        filters: { [filterName]: filterValue },
      });
      sendForecastingAnalytics(forecastingEventTypes.SELECT_FILTERS, {
        filterSelected: filterValue,
        filterType: filterName,
        location: 'exploreItems',
        filterSection: 'itemsFilter',
      });
      if (itemSidebarForecastingOpenVar !== null) {
        itemSidebarForecastingOpenVar(null);
      }
    },
    [sendForecastingAnalytics, setSavedQuery]
  );
  const onChangeProjectsFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters]
    ) => {
      setProjectsFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        projectsFilters: { [filterName]: filterValue },
      });
      projectsParams.onChangeFilters(filterName, filterValue, false);
      // modify not only projects but items filters, too
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      let itemFilterName: any;
      if (filterName === 'locations') {
        itemFilterName = 'projectLocations';
      }
      if (filterName === 'milestoneDateRange') {
        itemFilterName = 'milestoneDate';
      }
      if (filterName === 'types') {
        itemFilterName = 'projectTypes';
      }
      if (itemFilterName) {
        setFilters((prevState) => ({
          ...prevState,
          [itemFilterName]: filterValue,
        }));
        setSavedQuery({
          filters: { [itemFilterName]: filterValue },
        });
      }
      sendForecastingAnalytics(forecastingEventTypes.SELECT_FILTERS, {
        filterSelected: filterValue,
        filterType: filterName,
        location: 'exploreItems',
        filterSection: 'projectsFilter',
      });
      if (itemSidebarForecastingOpenVar !== null) {
        itemSidebarForecastingOpenVar(null);
      }
    },
    [projectsParams, sendForecastingAnalytics, setSavedQuery]
  );
  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setSavedQuery({ search });
      sendForecastingAnalytics(forecastingEventTypes.SEARCH, {
        location: 'exploreItems',
        search,
        searchType: 'items',
      });
      if (itemSidebarForecastingOpenVar !== null) {
        itemSidebarForecastingOpenVar(null);
      }
    },
    [sendForecastingAnalytics, setSavedQuery]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingExploreItemsSortBy) => {
      setSortBy(sortBy);
      setSavedQuery({ sortBy });
      sendForecastingAnalytics(forecastingEventTypes.SORT, {
        location: 'exploreItems',
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        sortType: 'items',
      });
    },
    [sendForecastingAnalytics, setSavedQuery]
  );

  const onResetFilters = useCallback(() => {
    setFilters(DEFAULT_ITEMS_SEARCH_QUERY.filters);
    setProjectsFilters(DEFAULT_ITEMS_SEARCH_QUERY.projectsFilters);
    projectsParams.onResetFilters();
    setSavedQuery({
      filters: DEFAULT_ITEMS_SEARCH_QUERY.filters,
      projectsFilters: DEFAULT_ITEMS_SEARCH_QUERY.projectsFilters,
    });
  }, [projectsParams, setSavedQuery]);

  const sortManager = useMemo(
    () => getSortManager(sortBy, onChangeSortBy),
    [sortBy, onChangeSortBy]
  );

  return useMemo(
    () => ({
      onChangeFilters,
      onChangeProjectsFilters,
      onChangeSearch,
      onResetFilters,
      sortManager,
      variables: {
        filters,
        projectsFilters,
        search,
        sortBy,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [
      filters,
      projectsFilters,
      onChangeProjectsFilters,
      onChangeFilters,
      onChangeSearch,
      onResetFilters,
      search,
      sortBy,
      sortManager,
    ]
  );
};

export const getSortManager = (
  sortBy: ForecastingExploreItemsSortBy,
  setSortBy: (sortBy: ForecastingExploreItemsSortBy) => void
) => {
  const sortManager: SortManager = {
    setSort: (input) => {
      let { sortDirection } = input;
      let sortKey: ForecastingExploreItemsSortKey;
      switch (input.sortKey) {
        case ForecastingExploreItemsSortKey.COST_IMPACT:
        case ForecastingExploreItemsSortKey.MILESTONE_DATE:
        case ForecastingExploreItemsSortKey.PROJECT_NAME:
        case ForecastingExploreItemsSortKey.STATUS:
        case ForecastingExploreItemsSortKey.UPDATED_AT:
          sortKey = input.sortKey;
          break;
        case ForecastingExploreItemsSortKey.SCORE:
          // Never let users sort by the worst score
          sortDirection = SortDirection.SORT_DESCENDING;
          sortKey = input.sortKey;
          break;
        default:
          sortKey = ForecastingExploreItemsSortKey.SCORE;
          break;
      }
      setSortBy({ sortDirection, sortKey });
    },
    sortState: sortBy,
  };
  return sortManager;
};
