import { ReactNode, RefObject, useRef } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, useTooltip } from 'react-aria';
import { TooltipTriggerState } from 'react-stately';

import useActiveTheme from '../Popover/useActiveTheme';

type Props = {
  children: ReactNode;
  /** @default 8 */
  offset?: Parameters<typeof useOverlayPosition>[0]['offset'];
  /** @default "top" */
  placement?: Parameters<typeof useOverlayPosition>[0]['placement'];
  state: TooltipTriggerState;
  triggerRef: RefObject<HTMLDivElement | SVGElement>;
};

export default function TooltipOverlay(props: Props) {
  const overlayRef = useRef(null);
  const { tooltipProps } = useTooltip({}, props.state);
  const { overlayProps } = useOverlayPosition({
    offset: props.offset,
    overlayRef,
    placement: props.placement,
    targetRef: props.triggerRef,
  });

  const activeTheme = useActiveTheme(props.triggerRef);

  return (
    <OverlayContainer data-theme={activeTheme}>
      <div
        className="max-w-[400px] rounded-md border border-border-muted bg-background-1 p-2 text-type-primary shadow"
        ref={overlayRef}
        {...mergeProps(tooltipProps, overlayProps)}
      >
        {props.children}
      </div>
    </OverlayContainer>
  );
}

TooltipOverlay.defaultProps = {
  offset: 8,
  placement: 'top',
};
