import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  SIGN_UP_CREATE_COMPANY_CTA,
  SIGN_UP_CREATE_COMPANY_VIEW,
} from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes, ToastType } from '../../../../api/gqlEnums';
import joinAPI from '../../../../api/joinAPI';
import {
  COMPANY_TYPE_ARCHITECTURE_DESIGN,
  COMPANY_TYPE_BUILDING_PRODUCT_MANUFACTURER,
  COMPANY_TYPE_CONSTRUCTION_MANAGEMENT,
  COMPANY_TYPE_DESIGN_ENGINEERING,
  COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_OTHER,
  COMPANY_TYPE_OWNER,
  COMPANY_TYPE_SUBCONTRACTOR,
} from '../../../../constants';
import { CreateCompanyMutation } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import { generateSignupPath } from '../../SignUpLayerUtils';
import SignupContainer from '../SignupContainer';
import SignupContentBox from '../SignupContentBox';

import { useCreateCompanyUserMutation } from './ConfirmCompanyHooks';
import CreateCompany from './CreateCompany';
import { useCreateCompanyMutation } from './CreateCompanyHooks';

export const companyTypes = [
  COMPANY_TYPE_ARCHITECTURE_DESIGN,
  COMPANY_TYPE_BUILDING_PRODUCT_MANUFACTURER,
  COMPANY_TYPE_CONSTRUCTION_MANAGEMENT,
  COMPANY_TYPE_DESIGN_ENGINEERING,
  COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_OWNER,
  COMPANY_TYPE_SUBCONTRACTOR,
  COMPANY_TYPE_OTHER,
].map((t) => ({ id: t, label: t, name: t, type: t }));

const CreateCompanyData = () => {
  const navigate = useNavigate();
  document.title = `Join - Sign Up`;
  const sendAnalytics = useAnalyticsEventHook();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(SIGN_UP_CREATE_COMPANY_VIEW));
  });
  const projectID = getProjectIdFromUrl();
  const [company, setCompany] = useState({ name: '', type: '' });
  const { name, type } = company;
  const [asset, setAsset] = useState<Asset | null>(null);
  const [loading, setLoading] = useState(false);

  const createCompany = useCreateCompanyMutation();
  const createCompanyUser = useCreateCompanyUserMutation();
  const onNext = () => {
    if (name && type) {
      sendAnalytics(analyticsEvent(SIGN_UP_CREATE_COMPANY_CTA));
      createCompany(
        name.trim(),
        type,
        asset && asset.id,
        (createdCompany: CreateCompanyMutation['createCompany']) => {
          const rejected = false;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          const reason = null as any;
          createCompanyUser(createdCompany.id, rejected, reason, () => {
            const path = generateSignupPath(JoinProjectRoutes.SIGNUP_SETUP_USER, projectID);
            navigate(path);
          });
        }
      );
    }
  };

  const uploadImage = useUploadImage();
  const addFile = (file: File) => {
    setLoading(true);
    const onSuccess = async (a: Asset) => {
      const assetWithBlobUrl = a;
      assetWithBlobUrl.blobUrl = await joinAPI.requestAssetBlobURL(a.location, a.name);
      setAsset(assetWithBlobUrl);
      setLoading(false);
    };
    const onFailure = (message: string) => {
      setLoading(false);
      setToast({ message }, ToastType.DEFAULT, null);
    };
    uploadImage(file, null, onSuccess, onFailure);
    // sendAnalytics(initUploadAnalytics(file.name, 'User Thumbnail'));
  };

  const canUpdate = !!company && !!name.trim() && !!type;

  return (
    <SignupContainer
      content={
        <SignupContentBox
          data-cy="signup-contentBox"
          content={
            <CreateCompany
              addFile={addFile}
              company={{
                companyType: type,
                companyName: name,
                thumbnail: (asset && asset.blobUrl) || undefined,
              }}
              companyTypes={companyTypes}
              loading={loading}
              onUpdate={({ companyName, companyType }) =>
                setCompany({ name: companyName, type: companyType })
              }
            />
          }
          disableNext={!canUpdate || loading}
          onNext={onNext}
          title="Where do you work?"
        />
      }
    />
  );
};

export default CreateCompanyData;
