import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    label: {
      paddingBotttom: 4,
      paddingTop: 12,
      ...theme.typography.webLabel,
      '&:first-of-type': {
        paddingTop: 0,
      },
    },
  });
