import { useState } from 'react';

import {
  ScenarioShareEvent,
  ScenarioShareKey,
  scenarioShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { YC_SCENARIO_SHARING } from '../../../features';
import { PermissionResource } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useCurrentCollaborator from '../../../hooks/useCurrentCollaboratorQuery';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ShareGroupAdd from '../../Icons/ShareGroupAdd';
import { Button } from '../../scales';

import ShareScenarioDialog from './ShareScenarioDialog';

export type Props = {
  isDisabled?: boolean;
  scenarioColor?: string;
  scenarioCreatorID: UUID;
  scenarioID: UUID;
  scenarioName: string;
};

export default function ShareScenarioButton(props: Props) {
  const analyticsKey = ScenarioShareKey.SCENARIOS;
  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { collaborator } = useCurrentCollaborator();
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);
  const isScenarioCreator = collaborator?.user.id === props.scenarioCreatorID;
  const { canEdit } = usePermissions();
  const canShareScenario = canEdit(PermissionResource.SHARE_ITEMS_SCENARIOS) || isScenarioCreator;

  if (!isScenarioSharingFeature) return null;
  if (!collaborator?.id) return null;
  if (!canShareScenario) return null;

  const button = (
    <div>
      <Button
        data-cy="share-scenario-button"
        disabled={props.isDisabled}
        label="Share"
        onClick={() => {
          setIsDialogOpen(!isDialogOpen);
          sendAnalytics(scenarioShareEvent(analyticsKey, ScenarioShareEvent.CTA));
        }}
        startIcon={<ShareGroupAdd />}
        type="secondary"
      />
    </div>
  );

  const dialog = (
    <ShareScenarioDialog
      accentColor={props.scenarioColor}
      collaboratorID={collaborator?.id}
      isOpen={isDialogOpen}
      scenarioID={props.scenarioID}
      scenarioName={props.scenarioName}
      onClose={() => setIsDialogOpen(false)}
      projectID={projectID}
    />
  );

  return (
    <>
      {button}
      {isDialogOpen && dialog}
    </>
  );
}
