import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import HelpTip from '../../shared-widgets/HelpTip/HelpTip';

import styles from './ReportsListPlaceholderStyles';

export enum PlaceholderVariants {
  MY = 'MY',
  SHARED = 'SHARED',
}

const variantMy = `You haven't created any reports yet`;
const variantShared = 'No reports have been shared with you yet';
const header = (variant: PlaceholderVariants) =>
  variant === PlaceholderVariants.MY ? variantMy : variantShared;

export const getPlaceholderVariant = (i: number) => {
  switch (i) {
    case 0:
      return PlaceholderVariants.MY;
    case 1:
      return PlaceholderVariants.SHARED;
    default:
      return undefined;
  }
};

type ReportsListPlaceholderProps = {
  classes: Classes<typeof styles>;
  variant?: PlaceholderVariants;
};

const ReportsListPlaceholder: FC<ReportsListPlaceholderProps> = ({
  classes,
  variant = undefined,
}) => {
  if (!variant) return null;

  const placeholderHeader = (
    <p className={`${classes.header} ${variant === PlaceholderVariants.MY ? classes.padding : ''}`}>
      {header(variant)}
    </p>
  );

  const tipComponent = (
    <Typography className={classes.tipText}>
      To create a custom report, go to a Built-In Report, customize the report view, and then save
      the report using the Report Icon <Bookmark className={classes.reportsIcon} />
    </Typography>
  );

  const placeholderContent =
    variant === PlaceholderVariants.MY ? <HelpTip tip={tipComponent} /> : null;

  return (
    <div className={classes.container}>
      {placeholderHeader}
      {placeholderContent}
    </div>
  );
};

export const StyledReportsListPlaceholder = withStyles(styles)(ReportsListPlaceholder);

export default StyledReportsListPlaceholder;
