import { useState } from 'react';
import * as React from 'react';
import { useEffectOnce } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { ButtonBase, Menu } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { PROJECT_COMP_ESCALATION_DROPDOWN_BUTTON } from '../../../tagConstants';

import EscalationInputMenuList from './EscalationInputDropdownMenuList';
import RemoveAutoEscalationWarningDialog from './RemoveAutoEscalationWarningDialog';

const EscalationInputDropdown = (props: {
  buttonClassName: string;
  containerRef: React.RefObject<HTMLDivElement>;
  isAutoEscalated: boolean;
  onAutoEscalate: () => void;
  onAutoEscalateAll: () => void;
  onManualEscalate: () => void;
  onRemoveEscalation: () => void;
  type: 'date' | 'location';
}) => {
  const [id, setId] = useState('');
  useEffectOnce(() => {
    setId(uuidv4());
  });

  const menuId = `menu-${id}`;

  // dropdown menu and <button> handlers
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);
  const toggleDropdownMenu = () => setDropdownMenuIsOpen((prevState) => !prevState);

  // Warning functions and dialog state
  const [isWarnAboutManualEscalationDialogOpen, setIsWarnAboutManualEscalationDialogOpen] =
    useState(false);
  const [isWarnAboutRemoveEscalationDialogOpen, setIsWarnAboutRemoveEscalationDialogOpen] =
    useState(false);

  const handleManualEscalate = () => {
    if (props.isAutoEscalated) {
      setIsWarnAboutManualEscalationDialogOpen(true);
    } else {
      props.onManualEscalate();
    }
  };

  const handleRemoveEscalation = () => {
    if (props.isAutoEscalated) {
      setIsWarnAboutRemoveEscalationDialogOpen(true);
    } else {
      props.onRemoveEscalation();
    }
  };

  return (
    <>
      <ButtonBase
        aria-controls={menuId}
        aria-haspopup="true"
        aria-expanded={dropdownMenuIsOpen ? 'true' : undefined}
        aria-label="view escalation options"
        className={props.buttonClassName}
        data-cy={PROJECT_COMP_ESCALATION_DROPDOWN_BUTTON}
        onClick={toggleDropdownMenu}
        onKeyDown={(e) => {
          if (e.key === 'Esc') {
            toggleDropdownMenu();
          }
        }}
        type="button"
      >
        {dropdownMenuIsOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </ButtonBase>
      <Menu
        anchorEl={props.containerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        id={menuId}
        onBackdropClick={toggleDropdownMenu}
        onClose={toggleDropdownMenu}
        onClick={toggleDropdownMenu}
        open={dropdownMenuIsOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EscalationInputMenuList
          isAutoEscalated={props.isAutoEscalated}
          onAutoEscalate={props.onAutoEscalate}
          onAutoEscalateAll={props.onAutoEscalateAll}
          onManualEscalate={handleManualEscalate}
          onRemoveEscalation={handleRemoveEscalation}
          type={props.type}
        />
      </Menu>
      {isWarnAboutManualEscalationDialogOpen && (
        <RemoveAutoEscalationWarningDialog
          action="manual"
          escalationType={props.type}
          onClose={() => setIsWarnAboutManualEscalationDialogOpen(false)}
          onConfirm={props.onManualEscalate}
        />
      )}
      {isWarnAboutRemoveEscalationDialogOpen && (
        <RemoveAutoEscalationWarningDialog
          action="remove"
          escalationType={props.type}
          onClose={() => setIsWarnAboutRemoveEscalationDialogOpen(false)}
          onConfirm={props.onRemoveEscalation}
        />
      )}
    </>
  );
};

export default EscalationInputDropdown;
