import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import CostImpact from '../../shared-widgets/CostImpact';

import styles from './ItemDetailsCollapseStyles';

type ContingencyDrawTotalProps = {
  classes: Classes<typeof styles>;
  contingencyDrawTotal: Cost;
};

const ContingencyDrawTotal: FC<ContingencyDrawTotalProps> = ({ classes, contingencyDrawTotal }) => {
  return (
    <div className={classes.container}>
      <Typography variant="subheading">Contingency / Allowance Draw Subtotal</Typography>
      <CostImpact value={contingencyDrawTotal} />
    </div>
  );
};

export default withStyles(styles)(ContingencyDrawTotal);
