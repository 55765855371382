import { createStyles } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const hideOnSize = (theme: KomodoTheme, bp: Breakpoint) => ({
  [theme.breakpoints.down(bp)]: {
    display: 'none',
  },
});
const hideOnMobile = (theme: KomodoTheme) => hideOnSize(theme, 'sm');
const maxWidth = 600;
const shiftRight = Math.floor((maxWidth * (66.6666 - 50)) / 100);
// This style places the project content background line 150px right of center
const imageBorderWidth = `calc(50% + ${shiftRight}px)`;

const styles = (theme: KomodoTheme) => {
  const BORDER = `2px solid ${theme.palette.joinPrimary}`;
  return createStyles({
    acceptButton: {
      textTransform: 'none',
      marginTop: 20,
      maxWidth: 220,
    },
    actions: {
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    buttonBack: {
      padding: '3px 16px 2px 12px',
    },
    buttonNext: {
      marginLeft: 10,
      padding: '3px 12px 2px 16px',
    },
    buttonNextJoin: {
      paddingLeft: 14,
      paddingRight: 16,
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
    },
    projectText: {
      ...hideOnMobile(theme),
      ...theme.typography.title,
      fontSize: 18,
      paddingBottom: 20,
      width: imageBorderWidth,
    },
    content: {
      margin: 48,
      minHeight: 348,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    contentInner: {
      margin: 'auto',
      minWidth: 290,
      [theme.breakpoints.up('lg')]: {
        maxWidth,
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 48px) !important',
      },
      [theme.breakpoints.up('sm')]: {
        width: '50% !important',
      },
    },
    contentOuter: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      backgroundImage: `url(/img/signup/onboardingBG.svg)`,
      backgroundSize: 'cover',
    },
    colorRed: {
      color: theme.palette.red,
    },
    columnFirstContent: {
      display: 'grid',
      paddingBottom: 12,
    },
    columnFirstExpanded: {
      display: 'table-cell',
      width: '100%',
      maxWidth: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    columnSecondShrink: {
      paddingLeft: 25,
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        display: 'table-footer-group !important',
        height: 178,
      },
      [theme.breakpoints.up('sm')]: {
        display: 'table-cell',
      },
    },
    contentBox: {
      backgroundColor: theme.palette.backgroundWhite,
      border: BORDER,
      display: 'flex',
      flexDirection: 'column',
      margin: '100px auto',
      minWidth: 290,
      minHeight: 160,
      [theme.breakpoints.up('lg')]: {
        maxWidth,
      },
      [theme.breakpoints.down('md')]: {
        width: '50% !important',
      },
    },
    contentBoxOnboarding: {
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'column',
      border: BORDER,
    },
    contentBoxGrid: {
      display: 'table',
      tableLayout: 'auto',
      width: '100%',
    },
    contentHeader: {
      ...theme.typography.h3,
      fontSize: 21,
      fontWeight: 500,
    },
    contentSubheader: {
      fontSize: 16,
      fontWeight: 400,
    },
    contentRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formLabelRoot: {
      '&$formLabelFocused': {
        color: theme.palette.joinPrimary,
        fontWeight: 500,
      },
    },
    // this has to be here; it controls the color of the input label states
    // with formLabelRoot controls.
    formLabelFocused: {},
    header: {
      marginTop: 20,
      marginBottom: 20,
    },
    joinPrimaryInput: {
      border: `1px solid ${theme.palette.joinPrimary}`,
      height: 38,
    },
    label: {
      color: theme.palette.joinPrimary,
      fontWeight: 500,
    },
    linkText: {
      ...theme.typography.link,
    },
    logoJoin: {
      ...hideOnMobile(theme),
      alignSelf: 'center',
      height: 'auto',
      left: 0,
      position: 'fixed',
      top: 30,
      width: 150,
    },
    minWidthBtn: {
      minWidth: 118,
    },
    title: {
      fontWeight: 700,
      paddingBottom: 20,
    },
    paddingTop: {
      paddingTop: 8,
    },
    iconBack: {
      paddingRight: 4,
    },
    iconNext: {
      paddingRight: 4,
      transform: 'rotate(-180deg)',
    },
    paddingBottom: {
      paddingBottom: 8,
    },
    selectionCheckbox: {
      height: 38,
      width: 38,
    },
    selectionContentRowContainer: {
      backgroundColor: theme.palette.joinGrey200,
      alignItems: 'center',
      paddingRight: '8px',
      borderRadius: theme.border.radius,
    },
    selectionContentRowBottom: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
    },
    selectionContentRowTop: {
      cursor: 'pointer',
      paddingTop: 2,
      display: 'flex',
      alignItems: 'center',
      paddingRight: '8px',
    },
    selectionInputBox: {
      backgroundColor: theme.palette.joinGrey200,
      borderRadius: theme.border.radius,
      padding: '0px 8px 8px 8px',
    },
    selectionText: {
      ...theme.typography.body1,
      fontWeight: 400,
    },
    spacedText: {
      marginTop: 20,
    },
    subText: {
      ...theme.typography.h4,
      color: theme.palette.joinPrimary,
      fontWeight: 500,
    },
    teamContainer: {
      ...hideOnMobile(theme),
      width: imageBorderWidth,
      height: 'inherit',
      display: 'flex',
      justifyContent: 'space-around',
    },
    teamContainerOne: {
      ...hideOnMobile(theme),
      width: imageBorderWidth,
      height: 'inherit',
      justifyContent: 'space-around',
    },
    teamLogo: {
      color: theme.palette.primaryGrey,
      objectFit: 'contain',
      paddingTop: 20,
      width: '32%',
    },
    input: {
      '&:disabled': {
        border: 'none',
      },
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      border: theme.border.lineBorderBlack,
      borderRadius: theme.border.radius,
      marginLeft: 1,
      marginRight: 1,
    },
    inputPlaceholder: {
      '&::placeholder': {
        color: theme.palette.primaryGrey,
        fontWeight: 400,
        fontSize: '16px',
      },
    },
    inputWrapper: {
      border: 'none',
      height: 38,
      outline: 'none',
    },
    underlineText: {
      ...theme.typography.link,
      background: 'transparent',
      border: 'none',
      color: theme.palette.joinPrimary,
      outline: 'none',
      padding: 0,
    },
  });
};

export default styles;
