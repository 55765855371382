import { VisibilityView } from '../../../generated/graphql';
import { getProjectIdFromUrl } from '../../../utilities/url';

import { useItemsTimelineQuery } from './useItemsTimelineQuery';

export const useItemsTimeline = () =>
  useItemsTimelineQuery(
    null,
    null,
    getProjectIdFromUrl(),
    false,
    {},
    VisibilityView.PUBLIC_VIEW,
    [],
    false
  );
