import { useState } from 'react';

export const SCROLL_BUFFER = 4;

export const useScrollListener = (isVertical = true) => {
  const [overflowStart, setOverflowStart] = useState(false);
  const [overflowEnd, setOverflowEnd] = useState(false);

  const onScrollOverflow = ({
    offsetHeight,
    offsetWidth,
    scrollWidth,
    scrollHeight,
    scrollLeft,
    scrollTop,
  }: HTMLDivElement) => {
    const offsetDim = isVertical ? offsetHeight : offsetWidth;
    const scrollDim = isVertical ? scrollHeight : scrollWidth;
    const scrollPos = isVertical ? scrollTop : scrollLeft;
    const hasOverflow = offsetDim < scrollDim;
    const atStart = scrollPos < SCROLL_BUFFER;
    const atEnd = scrollPos > scrollDim - offsetDim - SCROLL_BUFFER;
    const overflowNowStart = hasOverflow && !atStart;
    if (overflowNowStart !== overflowStart) setOverflowStart(overflowNowStart);
    const overflowNowEnd = hasOverflow && !atEnd;
    if (overflowNowEnd !== overflowEnd) setOverflowEnd(overflowNowEnd);
  };

  // This helps when ref rerenders
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const ref = (el: any) => {
    if (el) {
      onScrollOverflow(el);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onScroll = ({ currentTarget }: any) => {
    if (currentTarget) {
      onScrollOverflow(currentTarget);
    }
  };

  return { onScroll, overflowStart, overflowEnd, ref };
};

export const useScrollToHashOnce = (hash: string, yOffset = 0, isVertical = false) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollToElement = (el: HTMLElement) => {
    if (yOffset !== 0) {
      const y = el.getBoundingClientRect().top + yOffset;
      const x = el.getBoundingClientRect().left;
      if (isVertical) {
        window.scrollTo({ left: x, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      el.scrollIntoView();
    }
    setHasScrolled(true);
  };

  const scrollToHash = () => {
    const el = document.getElementById(hash.substring(1));
    if (el) {
      setTimeout(() => scrollToElement(el), 1000);
    }
  };
  if (!hasScrolled) {
    scrollToHash();
  }
};
