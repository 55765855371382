import { useMutation } from '@apollo/client';

import { UPDATE_COLLABORATOR_ALL_TRADES } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  UpdateCollaboratorAllTradesMutation,
  UpdateCollaboratorAllTradesMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { updateCollaboratorAllTradesMutation } from './queries';

function useUpdateCollaboratorAllTrades() {
  const [updateCollaboratorAllTradesFunc, mutationResult] = useMutation<
    UpdateCollaboratorAllTradesMutation,
    UpdateCollaboratorAllTradesMutationVariables
  >(updateCollaboratorAllTradesMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectId: UUID,
    collaboratorId: UUID,
    tradeSetting: boolean,
    onSuccess: (c: UpdateCollaboratorAllTradesMutation['setCollaboratorAllTrades']) => void,
    onFailure: () => void
  ) =>
    updateCollaboratorAllTradesFunc({
      variables: { projectID: projectId, collaboratorId, tradeSetting },
    })
      .then((result) => {
        const setCollaboratorAllTrades = result.data?.setCollaboratorAllTrades;
        if (onSuccess && setCollaboratorAllTrades) onSuccess(setCollaboratorAllTrades);
        sendAnalytics(analyticsEvent(UPDATE_COLLABORATOR_ALL_TRADES));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [submitFunc, mutationResult];
}

export default useUpdateCollaboratorAllTrades;
