import { kebabDataCy } from '../../../utilities/cypress';
import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';
import { Link } from '../types';

import NavItem from './NavItem';
import ProjectsNavItem from './ProjectsNavItem';

type Props = {
  company?: {
    assetID?: UUID | null;
    name: string;
  };
  expanded?: boolean;
  lastViewedProjectIDs: UUID[];
  links: Link[];
};

export default function CompanySection(props: Props) {
  const assetBlobUrlQueryResult = useAssetBlobUrlQuery(props.company?.assetID);

  if (!props.company)
    return (
      <ProjectsNavItem
        expanded={props.expanded}
        lastViewedProjectIDs={props.lastViewedProjectIDs}
      />
    );

  const dataCy = 'company';

  return (
    <div data-cy={dataCy}>
      <div
        data-cy={kebabDataCy(dataCy, 'title')}
        className="flex items-center gap-2 px-4 py-1 type-body2"
      >
        <div className="overflow-hidden rounded">
          {/* TODO CT-772 should enable us to use the thumbnailed asset with <Thumbnail>
          Double-check non-square company logos like McCarthy first though. */}
          {assetBlobUrlQueryResult.url && !assetBlobUrlQueryResult.loading ? (
            <img
              alt="company logo"
              className="h-8 w-8 rounded object-scale-down"
              src={assetBlobUrlQueryResult.url}
            />
          ) : (
            <InitialMark name={props.company.name} />
          )}
        </div>
        {props.expanded && <div className="cursor-default type-heading3">{props.company.name}</div>}
      </div>
      <div className="flex flex-col items-stretch" data-cy={kebabDataCy(dataCy, 'links')}>
        <ProjectsNavItem
          expanded={props.expanded}
          lastViewedProjectIDs={props.lastViewedProjectIDs}
        />
        {props.links.map((link) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            label={props.expanded ? link.name : undefined}
            path={link.path}
            tooltip={props.expanded ? undefined : link.name}
          />
        ))}
      </div>
    </div>
  );
}

function InitialMark(props: { name?: string }) {
  return (
    <div className="flex h-8 w-8 cursor-default items-center justify-center bg-background-2">
      {(props.name ?? '-')
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('')}
    </div>
  );
}
