import { GSF_ID } from '../../../../constants';
import { CostTableColumnInput, CostTableColumnInputKey } from '../../../../generated/graphql';

export const MAX_NUM_COL_INPUTS = 3;

export const COST_GRID_DEFAULT_COLUMNS = [
  { key: CostTableColumnInputKey.TOTAL },
  { key: CostTableColumnInputKey.TOTAL_PER_METRIC, unitID: GSF_ID },
  { key: CostTableColumnInputKey.PERCENT },
];

export const columnInputIsSelected = (
  selected: CostTableColumnInput[],
  columnInput: CostTableColumnInput
) => !!selected.find((s) => columnInputsMatch(s, columnInput));
export const columnInputsMatch = (a: CostTableColumnInput, b: CostTableColumnInput) =>
  a.key === b.key && (a.unitID && b.unitID ? a.unitID === b.unitID : !a.unitID && !b.unitID);

export const getCheckboxOptions = (units?: Unit[]) => {
  const checkboxOptions: CostTableColumnInputs = [
    { key: CostTableColumnInputKey.TOTAL },
    { key: CostTableColumnInputKey.PERCENT },
  ];
  units?.forEach((unit) => {
    checkboxOptions.push({ key: CostTableColumnInputKey.TOTAL_PER_METRIC, unitID: unit.id });
    checkboxOptions.push({ key: CostTableColumnInputKey.METRIC, unitID: unit.id });
  });

  return checkboxOptions;
};
