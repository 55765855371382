import { upperCase } from 'lodash';

import {
  AUTHORIZE,
  COMPANY_COLLABORATORS_PATH,
  COMPANY_MEMBERS_PATH,
  COMPANY_TAB_PATH,
  FORECASTING_PROJECT_COMP_PATH,
  FORECASTING_PROJECT_COMP_PRINT_PATH,
  GUIDREGEX,
  IMPORT,
  ITEMS,
  MILESTONES,
  PROJECTS,
} from '../constants';
import { SourceSystem } from '../generated/graphql';

import { logErrorToSentry } from './sentry';

/*
- getUUID
- splitPathIntoUUID
- getItemIdFromUrl
- getMilestoneIdFromUrl
- getImportEstimateIdFromUrl
- getProjectIdFromUrl
 */

const getUUID = (input: string): string => {
  const result = GUIDREGEX.exec(input);
  return result && result.length > 0 ? result[0] : '';
};

// remove the domain ie localhost, app.join.build etc
// from the path if it exists
const stripDomain = (input: string): string => {
  const reg = /.*\/\/[^/]*/;
  return input.replace(reg, '');
};

const splitPathIntoUUID = (split: string, path = window.location.pathname): string => {
  const parts = stripDomain(path).split(split);
  return parts && parts.length > 1 && parts[1] !== PROJECTS ? getUUID(parts[1]) : '';
};

export const getItemIdFromUrl = (path?: string): string => splitPathIntoUUID(`/${ITEMS}/`, path);
export const getMemberUserIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}/`, path);

export const getCollaboratorUserIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}/`, path);

export const getMilestoneIdFromUrl = (path?: string): string => {
  try {
    // find the thing that's after the word 'milestones' and before the next '/'
    return splitPathIntoUUID(`/${MILESTONES}/`, path);
  } catch (e) {
    logErrorToSentry('Attempting to parse a milestone ID from a bad path.');
    return '';
  }
};

export function getRoleIdFromPath(path = window.location.pathname): string {
  try {
    const roleId = path.split('/')[4];
    if (roleId && GUIDREGEX.exec(roleId)) {
      const matches = GUIDREGEX.exec(roleId);
      if (matches && matches.length > 0) {
        return matches[0];
      }
    }
    return '';
  } catch (e) {
    logErrorToSentry(e);
    return '';
  }
}

// These relates to routes.ts
export const getImportEstimateIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${IMPORT}/`, path);

export const getReportIdFromUrl = (path?: string) =>
  splitPathIntoUUID(`/${FORECASTING_PROJECT_COMP_PATH}/`, path) || undefined;

export const getReportIdFromPrintUrl = (path?: string) =>
  splitPathIntoUUID(
    `/${FORECASTING_PROJECT_COMP_PATH}/${FORECASTING_PROJECT_COMP_PRINT_PATH}/`,
    path
  ) || undefined;

export const getProjectIdFromUrl = (path?: string): string => splitPathIntoUUID(`/`, path);

export const isBreakdownsReport = (path = window.location.pathname): boolean =>
  path.includes('/print/dashboard/breakdowns');

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const matchStringWithEnum = (enumeration: any, input: string) => {
  const enumValues = Object.values(enumeration);
  const match = enumValues.find((value) => value === input);
  return match && (match as typeof enumeration);
};

export const matchStringWithSourceEnum = (input: string) =>
  matchStringWithEnum(SourceSystem, input);

export const getSourceFromURL: () => SourceSystem | undefined = () => {
  const { pathname } = window.location;
  const [, auth, source] = pathname.split('/');
  if (auth === AUTHORIZE) {
    return matchStringWithSourceEnum(upperCase(source));
  }
  return undefined;
};

export const isPrintFromUrl = (path = window.location.pathname): boolean => {
  return path.indexOf('/print/') >= 0;
};
