import { FC, ReactElement } from 'react';

import { Avatar, Button, IconButton, Typography } from '@material-ui/core';
import {
  ChevronRight,
  DeleteOutline,
  Edit,
  ErrorOutline,
  Link as LinkIcon,
  MoreVert,
  Print,
} from '@material-ui/icons';

import { NS_REPORT_DISTRIBUTION } from '../../../../features';
import { useHasFeature } from '../../../../hooks/useFeatureQuery';
import theme from '../../../../theme/design-system-mui-theme';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { ExcelIcon } from '../../../Icons';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import IconMenu from '../../../Select/SelectMenu/IconMenu';

import styles from './ReportsListEntrySettingsStyles';

const tipExcel = 'Download as .xlsx';
const tipPrint = 'Print or export to PDF';
const tipGo = 'View in Join';
const tipLink = 'Copy link to clipboard';

const verticalDividerIcon = (
  <svg style={{ flexShrink: 0, marginRight: 4 }} id="line" width="1" height="26">
    <line x1="1" y1="0" x2="1" y2="26" stroke="grey" strokeWidth="1" />
  </svg>
);

export type ReportsListEntrySettingsType = {
  error?: string | undefined;
  cy?: string;
  onDelete?: (() => void) | undefined;
  onEdit?: (() => void) | undefined;
  onExcel?: (() => void) | undefined;
  onGo: (() => void) | undefined;
  onLink: (() => void) | undefined;
  onPrint?: (() => void) | undefined;
  onDistribute?: (() => void) | undefined;
  hasDistributionScheduled?: boolean;
};

type ClassesType = {
  classes: Classes<typeof styles>;
};

interface ReportsListEntrySettingsProps extends ClassesType, ReportsListEntrySettingsType {}

const ReportsListEntrySettings: FC<ReportsListEntrySettingsProps> = ({
  classes,
  error,
  cy = '',
  onDelete,
  onEdit,
  onExcel,
  onGo,
  onLink,
  onPrint,
  onDistribute,
  hasDistributionScheduled,
}) => {
  const hasReportDistributionAccess = useHasFeature(NS_REPORT_DISTRIBUTION);

  const menuOptions = [];
  if (onEdit) {
    menuOptions.push({
      name: 'Edit',
      callback: () => onEdit(),
      icon: <Edit />,
    });
  }
  if (hasReportDistributionAccess && onDistribute) {
    menuOptions.push({
      name: `${
        hasDistributionScheduled ? 'Edit Scheduled Distribution' : 'Send/Schedule Distribution'
      }`,
      callback: () => onDistribute(),
      icon: hasDistributionScheduled ? (
        <img
          src="/img/ReportDistribution/Icon_ScheduleDistribution.svg"
          width={24}
          alt="Has Distribution Scheduled"
        />
      ) : (
        <img
          src="/img/ReportDistribution/Icon_SendDistribution.svg"
          width={24}
          alt="Has Distribution Scheduled"
        />
      ),
    });
  }
  if (onDelete) {
    menuOptions.push({
      color: theme.palette.ds.type.delete,
      name: 'Delete',
      callback: () => onDelete(),
      icon: <DeleteOutline />,
    });
  }

  const optionButton = menuOptions.length ? (
    <IconMenu options={menuOptions} cy={cy} />
  ) : (
    <MoreVert className={classes.disabled} />
  );

  const errorSign = (
    <NormalTooltip title={error}>
      <Avatar className={classes.avatar}>
        <ErrorOutline className={classes.iconError} />
      </Avatar>
    </NormalTooltip>
  );

  if (error)
    return (
      <div className={classes.root}>
        {errorSign}
        {optionButton}
      </div>
    );

  const goButton = (
    <NormalTooltip title={tipGo}>
      <Button
        className={classes.go}
        color="primary"
        data-cy="goToReport-Button"
        disableRipple
        disabled={!onGo}
        onClick={onGo}
        variant="outlined"
      >
        <Typography className={classes.goText}>Go</Typography>
        <ChevronRight />
      </Button>
    </NormalTooltip>
  );

  const renderIcon = (icon: ReactElement, onClick: (() => void) | undefined, tip: string) => {
    const iconContent = (
      <IconButton disabled={!onClick} disableRipple onClick={onClick} className={classes.button}>
        {icon}
      </IconButton>
    );
    if (!onClick) return iconContent;
    return <NormalTooltip title={tip}>{iconContent}</NormalTooltip>;
  };

  return (
    <div className={classes.root}>
      {renderIcon(<ExcelIcon />, onExcel, tipExcel)}
      {renderIcon(<Print />, onPrint, tipPrint)}
      {verticalDividerIcon}
      {!goButton ? <NormalTooltip title={tipGo}>{goButton}</NormalTooltip> : goButton}
      {renderIcon(<LinkIcon />, onLink, tipLink)}
      {verticalDividerIcon}
      {optionButton}
    </div>
  );
};

export const StyledReportsListEntrySettings = withStyles(styles)(ReportsListEntrySettings);

export default StyledReportsListEntrySettings;
