import { useMutation } from '@apollo/client';

import { REFETCH_NEW_ITEM } from '../../../api/refetchSets';
import { INTRODUCTION, UNASSIGNED, UNIFORMAT_CATEGORY, Uncategorized } from '../../../constants';
import {
  CreateItemMutation,
  CreateItemMutationResult,
  CreateItemMutationVariables,
  CreateOptionMutation,
  CreateOptionMutationResult,
  CreateOptionMutationVariables,
  Visibility,
} from '../../../generated/graphql';
import { costModeVar } from '../../../utilities/costMode';
import { logErrorToSentry } from '../../../utilities/sentry';
import { createItemMutation } from '../../Items/hooks/itemMutation';
import { isPrivateVisibility } from '../../Items/ItemsUtils';

import { createOptionMutation } from './hooks/queries';

/*
HELPER FUNCTIONS
- filterCategoriesForSubmit
- getNewOptions

CUSTOM HOOKS
- createItemMutation 
- createOptionMutation
*/

type CreatedItem = NonNullable<NonNullable<CreateItemMutationResult['data']>['createItem']>;
type CreatedOption = NonNullable<NonNullable<CreateOptionMutationResult['data']>['createOption']>;

// HELPER FUNCTIONS

export const filterCategoriesForSubmit = (categories: Category[]) => {
  // filter empty categorizations Uncategorized, put back Intro
  if (categories) {
    const filteredCategories = categories.filter((c: Category) => c.name !== Uncategorized);
    const introIdx = filteredCategories.findIndex(
      (c: Category) =>
        c.categorization &&
        c.categorization.name &&
        c.categorization.name.includes(UNIFORMAT_CATEGORY) &&
        c.number === INTRODUCTION
    );
    if (introIdx > -1) {
      filteredCategories[introIdx].number = '';
    }
    return filteredCategories;
  }
  return null;
};

export const formatCategoriesForItemCreation = (categories: Category[]) =>
  categories.map((c: Category) => ({
    categorizationID: (c.categorization && c.categorization.id) || '', // this is where we send categorization id to categorization
    id: c.id,
  }));

// This helper identifies the new options in the item return from the option creation process
export function getNewOptions(
  newParent: CreatedItem,
  oldParent?: CreatedItem
): CreatedItem['options'] | null {
  if (newParent && oldParent && newParent.options && oldParent.options) {
    const newOptions = newParent.options.filter(
      (newOption) => !oldParent.options.map((o) => o.id).includes(newOption.id)
    );
    return newOptions;
  }
  return null;
}

export const getGenerateNewItemFromParent =
  (milestoneID: UUID, visibilitySetting?: Visibility) => (parent?: Item) =>
    parent
      ? {
          milestoneID,
          name: '',
          categories: parent.categories || [],
          visibility: isPrivateVisibility(parent.visibility)
            ? parent.visibility
            : visibilitySetting,
        }
      : {
          milestoneID,
          name: '',
          categories: [],
          visibility: visibilitySetting,
        };

// CUSTOM HOOKS
export function useCreateItemMutation({ projectId }: { projectId: UUID }) {
  const [createItemFunc] = useMutation<CreateItemMutation, CreateItemMutationVariables>(
    createItemMutation
  );

  const submitFunc = (
    item: DraftItem,
    onSuccess: (data: CreatedItem) => void,
    onFailure: () => void
  ) => {
    if (!item.name || !item.milestoneID) {
      const error = 'Attempted to create item without name or milestone';
      logErrorToSentry(error);
      throw new Error(error);
    }

    return createItemFunc({
      refetchQueries: [...REFETCH_NEW_ITEM],
      variables: {
        ...item,
        name: item.name,
        milestoneID: item.milestoneID,
        categories: formatCategoriesForItemCreation(item.categories),
        projectID: projectId,
        costMode: costModeVar(),
      },
    })
      .then((result) => {
        if (result.data?.createItem) {
          onSuccess(result.data.createItem);
        }
      })
      .catch(() => {
        onFailure();
      });
  };
  return submitFunc;
}

export function useCreateOptionMutation({
  projectId,
  parentItem,
}: {
  projectId: UUID;
  parentItem?: Item;
}) {
  const [createItemFunc] = useMutation<CreateOptionMutation, CreateOptionMutationVariables>(
    createOptionMutation
  );

  const submitFunc = (
    option: DraftItem,
    onSuccess: (item: CreatedOption) => void,
    onFailure: () => void
  ) => {
    if (!parentItem) {
      const error = 'Attempted to create option without parent item';
      logErrorToSentry(error);
      throw new Error(error);
    }
    if (!option.name || !option.milestoneID) {
      const error = 'Attempted to create option without name or milestone';
      logErrorToSentry(error);
      throw new Error(error);
    }

    return createItemFunc({
      variables: {
        projectID: projectId,
        parent: parentItem.id,
        item: {
          ...option,
          name: option.name,
          milestoneID: option.milestoneID,
          categories: formatCategoriesForItemCreation(option.categories),
          projectID: projectId,
        },
        costMode: costModeVar(),
      },
      refetchQueries: REFETCH_NEW_ITEM,
    })
      .then((result) => {
        if (!result.data?.createOption) {
          throw new Error('createItem result data does not exist');
        }
        const updatedItem = result.data.createOption;
        onSuccess(updatedItem);
      })
      .catch(() => {
        onFailure();
      });
  };

  return submitFunc;
}

export const getAssigneeEmail = (email?: string) => (email === UNASSIGNED ? undefined : email);

export const analyticItemType = (visibility: Visibility | undefined) => {
  switch (visibility) {
    case Visibility.INTERNAL_DRAFT:
    case Visibility.PRIVATE_DRAFT:
    case Visibility.SHARED_DRAFT:
      return 'draft';
    case Visibility.PUBLISHED:
      return 'published';
    case Visibility.SCENARIO:
      return 'scenario';
    default:
      return '';
  }
};

export const analyticPropsForItem = (draftItem: DraftItem) => ({
  activityIDs: draftItem.activityIDs,
  assigneeEmail: draftItem.assigneeEmail,
  bucketID: draftItem.bucketID,
  costImpact: draftItem.costImpact,
  description: draftItem.description,
  dueDate: draftItem.dueDate,
  milestoneID: draftItem.milestoneID,
  name: draftItem.name,
  numCategories: draftItem.categories.length,
  type: analyticItemType(draftItem.visibility),
});
