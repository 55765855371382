import { FC, useState } from 'react';

import {
  getReactiveLocal,
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../api/apollo/reactiveVars';
import { PROJECT_COMPS_REACTIVE_VAR } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getReportIdFromPrintUrl } from '../../../utilities/url';
import { useProjectComparisonReportQuery } from '../../ForecastingTab/ForecastingReports/hooks/useProjectComparisonReportQuery';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import { LANDSCAPE_WIDTH, LandscapeOrientation } from '../PrintUtils';

import PrintProjectCompsPageHeader from './PrintProjectCompsPageHeader';
import PrintProjectCompsSetData from './PrintProjectCompsSetData';
import styles from './PrintProjectCompsStyles';

type PrintProjectCompsProps = {
  classes: Classes<typeof styles>;
};

const PrintProjectComps: FC<PrintProjectCompsProps> = ({ classes }) => {
  document.title = 'Project Comparisons';

  const [loading, setLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(true);

  usePrintWindow(!loading, !loadingHeader);

  const reportID = getReportIdFromPrintUrl();
  const { data: reportData, loading: loadingReport } = useProjectComparisonReportQuery(reportID);
  const projectID = reportData?.projectComparisonReport.attachedProject?.hasAccess
    ? reportData?.projectComparisonReport.attachedProject?.id
    : undefined;

  // this updates our reactive variable to be what is saved in local storage
  // TODO: later on we should make it so you can provide a non-reactiveVar input to projectCompsSetQuery
  projectCompsSetInputVar(
    getReactiveLocal(PROJECT_COMPS_REACTIVE_VAR, projectCompsSettingsInputDefault)
  );

  const isLoading = loading || loadingHeader || loadingReport;
  const pageHeader = (
    <PrintProjectCompsPageHeader
      isLoading={isLoading}
      onLoaded={() => setLoadingHeader(false)}
      pageText={{ title: '', value: '' }} // Replaced when cloning this element
      reportName={reportData?.projectComparisonReport.name}
      projectID={projectID ?? ''}
    />
  );

  return (
    <>
      <div className="print-header-page">
        <div className={classes.printRoot}>
          <LandscapeOrientation />
          <div className={classes.page} style={{ width: LANDSCAPE_WIDTH }}>
            <PrintProjectCompsSetData pageHeader={pageHeader} setLoading={setLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(PrintProjectComps);
