import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Typography } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';

import { AnalyticsEvent, ItemEvents } from '../../../analytics/analyticsEventProperties';
import { EstimateType, JoinProjectRoutes } from '../../../api/gqlEnums';
import { Status } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import CostImpact from '../../shared-widgets/CostImpact';
import ScheduleImpact, { ScheduleImpactVariant } from '../../shared-widgets/ScheduleImpact';
import ItemsButtonAddOption from '../ItemsButtonAddOption/ItemsButtonAddOption';

import ItemsCollapseStyles from './ItemsCollapseStyles';

type Props = {
  canViewContent: boolean;
  shouldDisplayCosts: boolean;
  classes: Classes<typeof ItemsCollapseStyles>;
  estimateCost?: CostRange | CostScalar;
  hasActiveEstimate: boolean;
  hasOptions: boolean;
  itemID: UUID;
  options: DetailedOption[];
  projectID: UUID;
  scheduleImpact: Item['scheduleImpact'];
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void;
  setOpenDialogAddOption: (option: boolean) => void;
};

const EstimateContentHeader: FC<Props> = ({
  canViewContent = false,
  shouldDisplayCosts,
  classes,
  estimateCost,
  hasActiveEstimate,
  hasOptions,
  itemID,
  options,
  projectID,
  scheduleImpact,
  sendAnalytics,
  setOpenDialogAddOption,
}) => {
  const navigate = useNavigate();
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  const { search: oldSearch } = window.location;
  const search = `${oldSearch}${oldSearch ? `&` : '?'}type=${EstimateType.ITEM}`;

  // try to make a link back to item, if we know its id...
  const link =
    projectID &&
    itemID &&
    generateSharedPath(JoinProjectRoutes.ITEM_ESTIMATE, {
      projectId: projectID,
      itemId: itemID,
      search,
    });

  const editButton = (
    <div className={classes.editButtonContainer} data-cy="expand-item-estimate">
      <IconButton
        classes={{ root: classes.editButton }}
        onClick={(event) => {
          event.stopPropagation();
          sendAnalytics({
            type: ItemEvents.ITEM_ESTIMATE_FULLSCREEN_EDIT,
            eventProperties: { open: true },
          });
          navigate(link);
        }}
        disableRipple
      >
        <Fullscreen />
      </IconButton>
    </div>
  );

  const title = hasOptions && options ? `Options (${options.length})` : `Estimate`;

  const optionsCost = () => {
    const isZeroCost =
      estimateCost && 'value' in estimateCost && estimateCost.value.toString() === '0';
    const hasMultiplePendingOptions = options.filter((o) => o.status === Status.PENDING).length > 1;
    // If the cost range for an item is $0 to $0 then
    // we want to literally say that instead of just "$0"
    if (hasOptions && isZeroCost && hasMultiplePendingOptions) {
      const formattedCost = formatCost(0);
      return (
        <div className={classes.zeroCostRange}>
          {formattedCost} to {formattedCost}
        </div>
      );
    }
    return <CostImpact value={estimateCost} />;
  };

  return (
    <>
      <Typography className={classes.collapseTitle} data-cy="itemsCollapse-title">
        {title}
      </Typography>
      {hasActiveEstimate && !hasOptions && canViewContent && editButton}
      <div className={classes.spacer} />
      {shouldDisplayCosts &&
        (hasActiveEstimate || hasOptions ? (
          optionsCost()
        ) : (
          <Typography data-cy="text-noEstimate">No Estimate</Typography>
        ))}
      {isScheduleImpactEnabled && scheduleImpact && hasOptions ? (
        <div className={classes.schedule}>
          <Typography className={classes.scheduleDivider}> / </Typography>
          <ScheduleImpact scheduleImpact={scheduleImpact} variant={ScheduleImpactVariant.MINIMAL} />
        </div>
      ) : null}
      {hasOptions && (
        <div className={classes.addOptions}>
          <ItemsButtonAddOption
            disabled={!hasOptions}
            setOpenDialogAddOption={setOpenDialogAddOption}
            type="primary"
          />
        </div>
      )}
    </>
  );
};

export default withStyles(ItemsCollapseStyles)(EstimateContentHeader);
