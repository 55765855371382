import { FC } from 'react';

import { LinearProgress } from '@material-ui/core';

import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { getProjectIdFromUrl } from '../../utilities/url';
import ErrorCode from '../errors/ErrorCode';
import useProjectStatusesQuery from '../ProjectsList/hooks/useProjectStatusesQuery';

import ProjectProperties from './ProjectProperties';

const ProjectPropertiesData: FC = () => {
  const projectID = getProjectIdFromUrl();
  const project = useProjectPropsQuery(projectID)?.data?.project;
  // pre-load the project statuses so we don't need to wait for it later
  useProjectStatusesQuery();

  if (projectID === null) {
    return <ErrorCode message="Project not found" code={404} />;
  }

  if (!project?.id) {
    return <LinearProgress hidden={!project?.id} />;
  }

  return <ProjectProperties project={project} />;
};

export default ProjectPropertiesData;
