import { useCallback } from 'react';

import useSendAnalyticsEventHook, { EventProperties } from '../../../hooks/useAnalyticsEventHook';

export enum ScenariosAnalyticsEvents {
  ADD_ITEM = 'addItemToScenario',
  ADD_SCENARIO = 'addScenario',
  APPLY_SCENARIO = 'applyScenario',
  COPY_SCENARIO = 'copyScenario',
  CREATE_SCENARIO = 'createScenario',
  CREATE_SCENARIO_ITEM = 'createScenarioItem', // TODO: S5
  CREATE_SCENARIO_ITEM_CTA = 'createScenarioItemCTA',
  CREATE_SCENARIO_ITEM_CLOSE = 'createScenarioItemClose',
  CUSTOMIZE_COLOR = 'customizeColor',
  DELETE_SCENARIO = 'deleteScenario',
  DELETE_SCENARIO_CLOSE = 'deleteScenarioClose',
  DELETE_SCENARIO_ITEM = 'deleteScenarioItem', // TODO: S5
  EDIT_METADATA = 'editScenarioMetadata', // with value edited
  REMOVE_ITEM = 'removeItemFromScenario',
  REMOVE_SCENARIO = 'removeScenario',
  REMOVE_SCENARIO_ITEM = 'removeScenarioItem', // TODO: S5
  SEARCH_LIBRARY_ITEMS = 'searchLibraryItems',
  SEARCH_LIBRARY_SCENARIOS = 'searchLibraryScenarios',
  SET_MILESTONE = 'setSandboxMilestone',
  SORT_ITEMS = 'sortItems',
  SORT_LIBRARY_ITEMS = 'sortLibraryItems',
  SORT_LIBRARY_SCENARIOS = 'sortLibraryScenarios',
  TOGGLE_MILESTONE_VISIBILITY = 'toggleMilestoneVisibility',
  UPDATE_ITEMS = 'updateItems',
  UPDATE_SCENARIOS = 'updateScenarios',
  VIEW_ITEM_DETAILS = 'viewItemDetails',
  EDIT_SCENARIO_ITEM_SIDE_BAR = 'editScenarioItemSideBar',
  PRINT_SANDBOX = 'printScenarioSandbox',
  PRINT_SANDBOX_EXPORT_PANEL = 'printScenarioSandboxExportPanel',
  // ...add more here...
}

export default function useSendScenariosAnalytics() {
  const sendAnalytics = useSendAnalyticsEventHook();

  return useCallback(
    (type: ScenariosAnalyticsEvents, eventProperties: EventProperties = {}) => {
      sendAnalytics({
        type: `scenarios_${type}`,
        eventProperties,
      });
    },
    [sendAnalytics]
  );
}
