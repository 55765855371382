import { ScheduleImpactType } from '../api/gqlEnumsBe';
import { ItemActivityEvent, ItemActivityEventContent } from '../components/Events/utils';
import {
  generateCostImpactInfo,
  isPrivateVisibility,
  isPublicVisibility,
} from '../components/Items/ItemsUtils';
import { getScheduleType } from '../components/ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import {
  SCHEDULE_IMPACT_DEFAULT,
  getScheduleImpactText,
} from '../components/shared-widgets/ScheduleImpact';
import {
  ACCEPTED,
  ASSIGNEE_CHANGE,
  ATTACHMENT_CHANGE,
  CATEGORY_CHANGE,
  COST_CHANGE,
  DELETED_MILESTONE,
  DUEDATE_CHANGE,
  EVENT_CHANGE,
  INCORPORATED,
  ITEM_DRAW_SET_CHANGE,
  ITEM_DRAW_UPDATE_CHANGE,
  ITEM_REFERENCE_CHANGE,
  KIND_COPIED_ITEM,
  KIND_EVENT_DESCRIPTION_TEXT,
  KIND_EVENT_ITEM_LIKE_SUMMARY,
  KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY,
  MEETING_CHANGE,
  MILESTONE_CHANGE,
  NULL_ID,
  NUMBER_NAME_DESCR_CHANGE,
  OPTION_CHANGE,
  PROCORE_CHANGE_EVENT,
  REMOVE_MILESTONE_EVENT,
  SCHEDULE_IMPACT_CHANGE,
  SHARE_SETTING_CHANGE,
  STATUS_CHANGE,
  TIMELINE_ACTIVITY_CHANGES,
  VISIBILITY_CHANGE,
} from '../constants';
import {
  DateGroup,
  EventType,
  ItemHistoryLink,
  ItemLinkWithProjectId,
  Status,
  Visibility,
} from '../generated/graphql';

import { compareStrings } from './comparison';
import { formatCost } from './currency';
import truncateLabel from './dashboard';
import { eventDateString } from './dates';
import { getItemStatusLabel } from './item-status';
import {
  capitalizeString,
  categoryLabel,
  pluralizeCountString,
  pluralizeString,
  removeYear,
} from './string';
import { isNonNullable } from './types';
import { visibilityDisplayStringsNew } from './visibility/constants';

type AttachedDetachedOptions =
  | ItemHistoryEvent['eventContent']['attachedOptions']
  | ItemHistoryEvent['eventContent']['removedOptions'];

//  Takes item history events, all milestones and calculates milestone
//  information for item history events quicklink
const getMilestoneForItemHistory = (itemEvent: ItemHistoryEvent, milestones: Milestone[]) => {
  if (itemEvent.eventTypes[0] === REMOVE_MILESTONE_EVENT && itemEvent.eventContent.oldMilestoneID) {
    return milestones.find((m) => m.id === itemEvent.eventContent.oldMilestoneID);
  }
  if (itemEvent.eventTypes[0] === 'CHANGE_VISIBILITY' && itemEvent.eventContent.milestoneID) {
    return milestones.find((m) => m.id === itemEvent.eventContent.milestoneID);
  }
  if (itemEvent.eventContent.newMilestoneID !== NULL_ID) {
    return milestones.find((m) => m.id === itemEvent.eventContent.newMilestoneID);
  }
  return undefined;
};

// Takes Cost object and calculates if any of two costs has a range
const hasRange = (oldCost: Cost, newCost: Cost) => {
  const { min: oldMin, max: oldMax } = oldCost as CostRange;
  const { value: oldValue } = oldCost as CostScalar;
  const { min: newMin, max: newMax } = newCost as CostRange;
  const { value: newValue } = newCost as CostScalar;
  if ((oldMin && oldMax) || (newMin && newMax)) {
    return true;
  }
  return !(newValue || oldValue);
};

// Calculates if status we are checking is accepting
const isAcceptingStatus = (status: Status) => status === ACCEPTED || status === INCORPORATED;

// ALL THINGS IH CATEGORY CHANGES
type ItemHistoryEventContentCategoryChange = NonNullable<
  NonNullable<ItemHistoryEvent['eventContent']['categoryChanges']>[number]
>;
// Takes category change (IH only), returns categorization name
export const itemHistoryCategorizationName = (change: ItemHistoryEventContentCategoryChange) => {
  let categoryName;
  if (change.newCategory && change.newCategory.categorization) {
    categoryName = removeYear(change.newCategory.categorization.name);
  } else if (change.oldCategory && change.oldCategory.categorization) {
    categoryName = removeYear(change.oldCategory.categorization.name);
  } else {
    categoryName = ''; // Should not happen
  }
  return categoryName;
};

// Returns categorization names, split by commas
const formatCategorizationsString = (changes: ItemHistoryEventContentCategoryChange[]) => {
  const categorizations = changes.map((change) => {
    const categoryName = itemHistoryCategorizationName(change);
    return categoryName;
  });
  return categorizations.sort((a, b) => compareStrings(a, b)).join(', ');
};

export const itemHistoryCategoryLabel = (cat: Category | null | undefined, char?: number) => {
  if (!char) {
    return cat && cat.categorization
      ? categoryLabel(cat.name, cat.number, cat.categorization)
      : 'Uncategorized ';
  }
  return cat && cat.categorization
    ? truncateLabel(categoryLabel(cat.name, cat.number, cat.categorization), char)
    : 'Uncategorized ';
};

// Handles null category and if it's not null, creates category change string for expanded description
const formatCategoryChangeString = (change: ItemHistoryEventContentCategoryChange) =>
  ` ${
    change.oldCategory && change.oldCategory.categorization
      ? `"${categoryLabel(
          change.oldCategory.name,
          change.oldCategory.number,
          change.oldCategory.categorization
        )}"`
      : '"Uncategorized" '
  } to ${
    change.newCategory && change.newCategory.categorization
      ? `"${categoryLabel(
          change.newCategory.name,
          change.newCategory.number,
          change.newCategory.categorization
        )}"`
      : '"Uncategorized" '
  } `;

// Takes category change, user, and formats categorization description string
const formatCategorizationsDescriptionString = (
  change: ItemHistoryEventContentCategoryChange[],
  user: User | null | undefined
) =>
  user && user.name
    ? `${user.name} edited ${formatCategorizationsString(change)}`
    : `${formatCategorizationsString(change)} were edited `;

// DESRIPTION OBJECTS FOR EventDescription.tsx COMPONENT
// Generate a text description object
export const makeEventDescriptionTextObject = (text: string) => {
  const description: EventDescriptionText = {
    text,
    kind: KIND_EVENT_DESCRIPTION_TEXT,
  };
  return description;
};

// Takes itemStatus and creates a simple TEXT description object for status
const makeEventDescriptionTextStatusObject = (status: Status | undefined | null) => ({
  text: status ? getItemStatusLabel(status) : '',
});
// Takes visibility and creates description object for visibility
const makeEventDescriptionVisibilityObject = (visibility: Visibility | null | undefined) => {
  if (!visibility) return { text: '', kind: KIND_EVENT_DESCRIPTION_TEXT };
  return {
    visibility,
    text: visibilityDisplayStringsNew.get(visibility),
    kind: KIND_EVENT_DESCRIPTION_TEXT,
  };
};
const makeEventDescriptionVisibilityObjectNew = (milestone: Milestone | undefined) => {
  if (!milestone) return '';
  return ` in ${milestone.name}`;
};

const makeEventDescriptionTextMilestoneObject = (milestone: Milestone | undefined) => ({
  text: milestone ? milestone.name : DELETED_MILESTONE.name,
});

const makeEventDescriptionCopiedItemLikeObject = (copiedItemLike: ItemLinkWithProjectId) => ({
  copiedItemLike,
  kind: KIND_COPIED_ITEM,
});

// Takes itemLike and creates description object which is used to display item quicklink
const makeEventDescriptionItemLikeSummaryObject = (
  itemLike: ItemLikeSummary
): EventDescriptionItemLikeSummary => {
  const description: EventDescriptionItemLikeSummary = {
    itemLike,
    kind: KIND_EVENT_ITEM_LIKE_SUMMARY,
  };
  return description;
};

// Takes item history link and creates description object used to display the summary
const makeEventDescriptionItemHistoryLinkObject = (
  itemHistoryLink: ItemHistoryLink
): EventDescriptionItemLikeSummary => {
  const convertedItemLike: ItemLikeSummary =
    convertItemHistoryLinkToItemLikeSummary(itemHistoryLink);

  return {
    itemLike: convertedItemLike,
    kind: KIND_EVENT_ITEM_LIKE_SUMMARY,
  };
};

const convertItemHistoryLinkToItemLikeSummary = (
  itemHistoryLink: ItemHistoryLink
): ItemLikeSummary => {
  if (itemHistoryLink.visibility === Visibility.PRIVATE_DRAFT) {
    return {
      id: itemHistoryLink.id,
      name: itemHistoryLink.name,
      visibility: Visibility.PRIVATE_DRAFT,
    };
  }
  return {
    id: itemHistoryLink.id,
    number: itemHistoryLink.number,
    visibility: Visibility.PUBLISHED,
  };
};

// Takes private itemLike summary and creates description object used to display the summary
const makeEventDescriptionPrivateItemLikeSummaryObject = (
  count: number
): EventDescriptionPrivateItemLikeSummary => {
  const description: EventDescriptionPrivateItemLikeSummary = {
    count,
    kind: KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY,
  };
  return description;
};

// Takes item history event and calculates different description variants for cost change
function calculateEventDescriptionCostChange(event: ItemHistoryEvent, canViewCosts: boolean) {
  const costChanges = event.eventContent?.costChanges;
  const oldCost = event.eventContent.oldCost || undefined;
  const newCost = event.eventContent.newCost || undefined;
  const numberOfCostChanges = costChanges?.length || 0;
  const numberOfCostChangesString = numberOfCostChanges > 1 ? ` ${costChanges?.length} times` : '';
  const userEventString = `${event.user?.name ?? 'An unknown user'} changed cost`.concat(
    numberOfCostChangesString
  );
  // If the user cannot view costs then return a simple text object
  if (!canViewCosts) return [makeEventDescriptionTextObject(userEventString)];
  // If there is no overall cost change then return nothing
  if (oldCost === newCost) {
    return undefined;
  }
  // If the item has a cost range and options
  // then display how the range has changed
  if (newCost && oldCost && hasRange(oldCost, newCost) && event.eventContent.itemLikeSummary) {
    return [
      makeEventDescriptionTextObject(`${userEventString} impact of option`),
      makeEventDescriptionItemLikeSummaryObject({
        ...event.eventContent.itemLikeSummary,
        kind: event.eventContent.itemLikeSummary.kind ?? undefined,
      }),
      makeEventDescriptionTextObject(` to ${generateCostImpactInfo(newCost)}`),
    ];
  }
  return [
    makeEventDescriptionTextObject(
      `${userEventString}${
        oldCost ? ` from ${generateCostImpactInfo(oldCost)}` : ''
      } to ${generateCostImpactInfo(newCost)}`
    ),
  ];
}

const calculateEventDescriptionSharing = (event: ItemHistoryEvent) => {
  // List of users sharing/unsharing
  const namesString: string[] = [];
  event.eventContent.sharedUsers.forEach((u) => {
    if (u?.name) namesString.push(u.name);
  });
  // Message details
  const type = event.eventTypes[0];
  let messageString = '';
  if (type === EventType.ADD_SHARED_DRAFT_USER_EVENT) {
    messageString = 'shared with';
  } else if (type === EventType.REMOVE_SHARED_DRAFT_USER_EVENT) {
    messageString = 'removed sharing with';
  }

  // Generate message
  const userEventString = `${event.user?.name ?? 'An unknown user'} ${messageString} ${formatNames(
    namesString
  )}`;
  return [makeEventDescriptionTextObject(userEventString)];
};

const formatNames = (names: string[]) => {
  if (names.length === 0) return '';
  if (names.length === 1) return names[0];
  if (names.length === 2) return names.join(' and ');
  const last = names.pop();
  // eslint-disable-next-line
  return names.join(', ') + ', and ' + last;
};

const calculateEventDescriptionDrawUpdate = (event: ItemHistoryEvent, canViewCosts: boolean) => {
  const drawChanges = event.eventContent?.drawChanges;
  const numberOfChanges = drawChanges?.length || 0;
  const countString = numberOfChanges > 1 ? ` ${drawChanges?.length} times` : '';
  const userEventString = `${event.user?.name ?? 'An unknown user'} changed draw amount from ${
    event.eventContent.drawName || '<deleted contingency>'
  }`.concat(countString);

  if (!canViewCosts) return [makeEventDescriptionTextObject(userEventString)];

  const fromValue = makeEventDescriptionTextObject(
    `${formatCost(event.eventContent.drawFromValue)}`
  );
  const toValue = makeEventDescriptionTextObject(
    ` to ${formatCost(event.eventContent.drawToValue)}`
  );
  return [makeEventDescriptionTextObject(`${userEventString} `), fromValue, toValue];
};

const calculateEventDescriptionDrawSet = (event: ItemHistoryEvent, canViewCosts: boolean) => {
  const userEventString = `${event.user?.name ?? 'An unknown user'} set the draw amount ${
    event.eventContent.drawName || `<deleted contingency>`
  }`;
  if (!canViewCosts) return [makeEventDescriptionTextObject(userEventString)];

  const toValue = makeEventDescriptionTextObject(
    `to ${formatCost(event.eventContent.drawToValue)}`
  );
  return [makeEventDescriptionTextObject(`${userEventString} `), toValue];
};

const calculateEventDescriptionProcoreChangeEventSet = (
  event: ItemHistoryEvent,
  eventType: EventType.CREATE_PROCORE_CHANGE_EVENT | EventType.DELETE_PROCORE_CHANGE_EVENT
) => {
  const eventString = `Procore user ${event.user?.name ?? 'An unknown user'} ${
    eventType === EventType.CREATE_PROCORE_CHANGE_EVENT ? 'created' : 'removed'
  } Procore Change Event ${event.eventContent.changeEvent?.number} - ${
    event.eventContent.changeEvent?.title
  }`;
  return [makeEventDescriptionTextObject(eventString)];
};

const calculateEventDescriptionTimelineActivityChange = (event: ItemHistoryEvent) => {
  const { user } = event;
  const { oldTimelineActivities: o, newTimelineActivities: n } = event.eventContent;

  let added: ItemHistoryEvent['eventContent']['newTimelineActivities'] = [];
  let removed: ItemHistoryEvent['eventContent']['newTimelineActivities'] = [];

  const formatListText = (list: ItemHistoryEvent['eventContent']['newTimelineActivities']) => {
    if (list.length === 1) return list[0].name;
    if (list.length === 2) return `${list[0].name} and ${list[1].name}`;
    let text = '';
    list.forEach((item, i) => {
      if (i === list.length - 1) text += `and ${item.name}`;
      else text += `${item.name}, `;
    });
    return text;
  };
  if (n.length > o.length) {
    added = event.eventContent.newTimelineActivities.filter(
      (n) => !event.eventContent.oldTimelineActivities.includes(n)
    );
  } else if (n.length < o.length) {
    removed = event.eventContent.oldTimelineActivities.filter(
      (o) => !event.eventContent.newTimelineActivities.includes(o)
    );
  }

  const userText = makeEventDescriptionTextObject(
    `${user?.name ?? 'An unknown user'} ${added.length > 0 ? ' added ' : ' removed '}`
  );
  const itemText = makeEventDescriptionTextObject(
    `${event.eventContent.itemLikeSummary ? 'option' : 'item'}`
  );
  const listText = makeEventDescriptionTextObject(
    ` ${added.length > 0 ? `to ${formatListText(added)}` : `from ${formatListText(removed)}`} `
  );

  if (event.eventContent.itemLikeSummary) {
    const itemLikeText = makeEventDescriptionItemLikeSummaryObject({
      ...event.eventContent.itemLikeSummary,
      kind: event.eventContent.itemLikeSummary.kind ?? undefined,
    });
    return [userText, itemText, itemLikeText, listText];
  }

  return [userText, itemText, listText];
};

const calculateEventDescriptionScheduleImpactChange = (event: ItemHistoryEvent) => {
  const changes = event.eventContent.scheduleImpactChanges;
  const count = changes.length;
  const scheduleType = getScheduleType();
  if (count === 0 || scheduleType === undefined || scheduleType === 'disabled') return undefined;
  const userText = `${event.user?.name ?? 'An unknown user'} changed schedule impact ${
    count > 1 ? `${count} times` : ''
  }`;

  const oldImpact = changes[0].old ?? SCHEDULE_IMPACT_DEFAULT;
  const newImpact = changes[count - 1].new;
  const oldText = formatScheduleImpact(oldImpact, scheduleType);
  const newText = formatScheduleImpact(newImpact, scheduleType);
  if (event.eventContent.itemLikeSummary) {
    return [
      makeEventDescriptionTextObject(`${userText} of option`),
      makeEventDescriptionItemLikeSummaryObject({
        ...event.eventContent.itemLikeSummary,
        kind: event.eventContent.itemLikeSummary.kind ?? undefined,
      }),
      makeEventDescriptionTextObject(` to ${newText}`),
    ];
  }
  return [makeEventDescriptionTextObject(`${userText} from ${oldText} to ${newText}`)];
};

export const formatScheduleImpact = (
  s: { type: ScheduleImpactType; criticalPath: boolean; days: number },
  scheduleType: string
) => {
  const [impactText, criticalText] = getScheduleImpactText(s, {
    unit: scheduleType.toLowerCase(),
    tbd: '"To be determined"',
    na: '"None / Non-applicable"',
    critical: ' (critical path)',
    noncritical: '',
  });
  return `${impactText}${criticalText}`;
};

// Takes assets, user, and calculates asset change description array
const calculateEventDescriptionAssets = (
  assets: Asset[] | ItemHistoryEvent['eventContent']['addedAttachments'] | undefined,
  user: User | null | undefined
) => {
  if (assets && assets.length > 1) {
    return user && user.name
      ? `${user.name} attached ${assets.length} files `
      : `${assets.length} files were attached `;
  }
  if (assets && assets.length === 1) {
    const assetName = assets[0]?.name;
    return user && user.name
      ? `${user.name} attached "${assetName}" `
      : `"${assetName && capitalizeString(assetName)}" was attached `;
  }
  return 'User attached asset'; // Should never happen, too
};

export const calculateEventDescriptionItemReferences = (
  referencedByItems:
    | ItemLikeSummary[]
    | ItemHistoryEvent['eventContent']['referencedByItems']
    | undefined
) => {
  const referencedByItemsObject: ItemLikeEventDescription[] = [];
  if (referencedByItems && referencedByItems.length) {
    const listLength = referencedByItems.length;

    referencedByItems.forEach((referencedByItem, i) => {
      if (referencedByItem) {
        if (i === listLength - 1 && listLength !== 1) {
          referencedByItemsObject.push(makeEventDescriptionTextObject(` and`));
        } else if (i > 0 && listLength !== 1) {
          referencedByItemsObject.push(makeEventDescriptionTextObject(','));
        }
        referencedByItemsObject.push(makeEventDescriptionItemLikeSummaryObject(referencedByItem));
      }
    });
  }
  return referencedByItemsObject;
};

const formatCategoryChangeDescriptionString = (
  change: ItemHistoryEventContentCategoryChange,
  user: User | null | undefined
) =>
  user && user.name
    ? `${user.name} edited ${formatCategorizationsString([
        change,
      ])} from ${formatCategoryChangeString(change)}`
    : ` ${formatCategorizationsString([change])} was edited from ${formatCategoryChangeString(
        change
      )}`;

const getOptionsText = (
  publicItemLikeList: ItemLikeSummary[],
  publicItemLikeListCount: number,
  privateItemLikeListCount: number
) => {
  const listLength = publicItemLikeListCount + (privateItemLikeListCount === 0 ? 0 : 1);

  const itemLike: ItemLikeEventDescription[] = [];
  publicItemLikeList.forEach((option, i) => {
    if (i === listLength - 1 && listLength !== 1) {
      itemLike.push(makeEventDescriptionTextObject(' and '));
    } else if (i > 0 && listLength !== 1) {
      itemLike.push(makeEventDescriptionTextObject(','));
    }

    const isPrivateItem = isPrivateVisibility(option.visibility);
    if (!isPrivateItem) {
      itemLike.push(makeEventDescriptionItemLikeSummaryObject(option));
    }
  });
  return itemLike;
};

export const makeItemLikeSummaryList = (itemLikeList: ItemLikeSummary[] | undefined) => {
  const { list: publicItemLikeList, count: publicItemLikeListCount } = getFilteredItemLikeList(
    itemLikeList,
    isPublicVisibility
  );
  const { count: privateItemLikeListCount } = getFilteredItemLikeList(
    itemLikeList,
    isPrivateVisibility
  );

  const optionsText = getOptionsText(
    publicItemLikeList,
    publicItemLikeListCount,
    privateItemLikeListCount
  );

  if (privateItemLikeListCount !== 0) {
    if (publicItemLikeListCount !== 0) {
      optionsText.push(makeEventDescriptionTextObject(' and '));
    }
    optionsText.push(makeEventDescriptionPrivateItemLikeSummaryObject(privateItemLikeListCount));
  }
  return optionsText;
};

const getCreateOptionUserActionDescription = (
  itemLinks: ItemActivityEvent['itemLinks'],
  userName: string
) => {
  const description: SimpleEventDescription = { text: '' };
  if (itemLinks) {
    const optionString = `${pluralizeString(`option`, itemLinks.options?.length)}`;
    description.text = `${userName} added new ${optionString} to`;
  }
  return description;
};

const getOptionUserActionDescription = (
  options: AttachedDetachedOptions,
  userName: string,
  action?: string
) => {
  const { list: publicItemLikeList, count: publicItemLikeListCount } = getFilteredItemLikeList(
    options?.filter(isNonNullable),
    isPublicVisibility
  );
  const hasPublicItems = publicItemLikeListCount !== 0;
  const optionString = hasPublicItems
    ? `${pluralizeString(`option`, publicItemLikeList.length)}`
    : '';
  const actionString = action || `added ${hasPublicItems ? 'new' : ''}`;

  return makeEventDescriptionTextObject(`${userName}${actionString} ${optionString}`);
};

const getFilteredItemLikeList = (
  itemLikeList: ItemLikeSummary[] | null | undefined,
  filterFn: (visibility: Visibility) => boolean
) => {
  const list = itemLikeList?.filter((itemLike) => filterFn(itemLike.visibility)) || [];
  return { list, count: list.length };
};

// format events for the item history
export const computeItemHistoryEvents = (
  currentItem: ItemLike | undefined | null,
  itemHistoryEvents: ItemHistoryEvent[] | undefined | null,
  milestones: Milestone[] | undefined | null,
  canViewComments: boolean,
  canViewCosts: boolean,
  isItemSharingFeature?: boolean
): ItemActivityEvent[] => {
  if (!milestones || !itemHistoryEvents || !currentItem) return [];

  // get the comments from the item
  const { comments } = currentItem;
  const processedComments: ItemHistoryEvent[] = canViewComments
    ? comments.map((comment) => {
        const { author: user } = comment;
        const eventContent: ItemActivityHistoryEventContent = {
          comment,
          __typename: 'ItemHistoryEventContent',
          scheduleImpactChanges: [],
          oldTimelineActivities: [],
          newTimelineActivities: [],
          visibility: currentItem.visibility,
          sharedUsers: [],
        };
        const disableStatusColor = false;
        return {
          id: comment.id,
          user,
          eventTypes: [EventType.ADD_COMMENT],
          timestamp: comment.created,
          disableStatusColor,
          eventContent,
          itemID: currentItem.id,
        };
      })
    : [];
  // combine the item events and the comments
  const combined = processedComments.concat(itemHistoryEvents);

  // format the events
  const events = computeItemHistoryEventsFormat(
    combined,
    milestones,
    canViewCosts,
    currentItem,
    isItemSharingFeature
  );

  // since we merged comments and events we need to sort the resulting list
  events.sort((a, b) => {
    if (a.created === b.created) return a.id > b.id ? 1 : -1;
    if (a.created > b.created) return 1;
    return -1;
  });
  return events;
};

// format the input ItemActivityHistoryEvent to the ItemActivityEvent type so they can be displayed
export const computeItemHistoryEventsFormat = (
  itemHistoryEvents: ItemActivityHistoryEvent[] | undefined | null,
  milestones: Milestone[] | undefined | null,
  canViewCosts: boolean,
  currentItem?: ItemLike,
  isItemSharingFeature?: boolean,
  itemLinks?: ItemHistoryLink[]
): ItemActivityEvent[] => {
  if (!milestones || !itemHistoryEvents) return [];
  let hasAssignedLastWeek = false;
  const currentItemHistoryEvents: ItemActivityEvent[] = itemHistoryEvents.map((event) => {
    const userName = `${event.user ? event.user.name : 'User '} `; // User for unhandled edgecases
    const { eventTypes: types } = event;
    const user = event.user ?? undefined;
    const eventContent = getEventContent(event);

    // created is used for sorting comments and item history events
    const created = new Date(event.timestamp);
    const eventItemLinks = getEventItemLinks(event, itemLinks);

    const date = eventDateString(created);
    let dateGroup = getDateGroup(date);
    if (dateGroup === DateGroup.LASTWEEK && !hasAssignedLastWeek) {
      hasAssignedLastWeek = true;
    } else if (dateGroup === DateGroup.LASTWEEK && hasAssignedLastWeek) {
      dateGroup = null;
    }
    const disableStatusColor = false;
    if (types.length === 1) {
      const { description, eventCount } = getSingleEventTypeDescription(
        event,
        milestones,
        userName,
        eventItemLinks,
        canViewCosts,
        currentItem,
        isItemSharingFeature
      );

      return {
        user,
        type: types,
        created,
        disableStatusColor,
        description,
        eventContent,
        eventCount,
        itemID: event.itemID,
        id: event.id,
        eventTypes: event.eventTypes,
        itemLinks: eventItemLinks,
        date,
        dateGroup,
      };
    }

    const { description, eventCount } = getMultipleEventTypeDescription(event, userName);
    return {
      user,
      type: types,
      created,
      disableStatusColor,
      description,
      eventContent,
      eventCount,
      itemID: event.itemID,
      id: event.id,
      eventTypes: event.eventTypes,
      itemLinks: eventItemLinks,
      date: eventDateString(created),
      dateGroup,
    };
  });

  return currentItemHistoryEvents;
};

const getSingleEventTypeDescription = (
  event: ItemActivityHistoryEvent,
  milestones: Milestone[],
  userName: string,
  itemLinks: ItemActivityEvent['itemLinks'],
  canViewCosts: boolean,
  currentItem?: ItemLike,
  isItemSharingFeature?: boolean
): { description: SimpleEventDescription[]; eventCount: number } => {
  const type = event.eventTypes[0];
  const attachedOptions = event.eventContent.attachedOptions?.filter(isNonNullable);
  const removedOptions = event.eventContent.removedOptions?.filter(isNonNullable);
  let eventCount = 0;
  let description: SimpleEventDescription[] = [];
  // this is the string which we use for the display
  const milestone = getMilestoneForItemHistory(event, milestones);

  switch (type) {
    case EventType.CHANGE_COST:
      description =
        calculateEventDescriptionCostChange(
          {
            ...event,
            eventContent: { ...event.eventContent, oldCost: undefined },
          },
          canViewCosts
        ) ?? [];
      eventCount =
        event.eventContent.costChanges?.length && event.eventContent.costChanges?.length > 1
          ? event.eventContent.costChanges?.length
          : 0;
      break;
    case EventType.CHANGE_SCHEDULE_IMPACT:
      description = calculateEventDescriptionScheduleImpactChange(event) ?? [];
      eventCount =
        event.eventContent?.scheduleImpactChanges?.length > 1
          ? event.eventContent?.scheduleImpactChanges?.length
          : 0;
      break;
    case EventType.CHANGE_ACTIVITIES:
      description = calculateEventDescriptionTimelineActivityChange(event);
      break;
    case EventType.ADD_SHARED_DRAFT_USER_EVENT:
      description = calculateEventDescriptionSharing(event);
      break;
    case EventType.REMOVE_SHARED_DRAFT_USER_EVENT:
      description = calculateEventDescriptionSharing(event);
      break;
    case EventType.ATTACH_OPTION:
      if (attachedOptions && attachedOptions.length > 0) {
        description = [
          getOptionUserActionDescription(attachedOptions, userName),
          ...makeItemLikeSummaryList(attachedOptions),
        ];
        break;
      }
      if (event.eventContent.itemLikeSummary) {
        description = [
          { text: `${userName}added as option to` },
          makeEventDescriptionItemLikeSummaryObject(event.eventContent.itemLikeSummary),
        ];
      }
      break;
    case EventType.CREATE_OPTION:
      // used in the Item Activity Feed
      if (itemLinks.item) {
        description = [
          getCreateOptionUserActionDescription(itemLinks, userName),
          makeEventDescriptionItemHistoryLinkObject(itemLinks.item),
        ];
        break;
      }
      // used in the Item Details Page Item History
      if (attachedOptions && attachedOptions.length) {
        description = [
          getOptionUserActionDescription(attachedOptions, userName),
          ...makeItemLikeSummaryList(attachedOptions),
        ];
        break;
      }
      if (event.eventContent.itemLikeSummary)
        description = [
          { text: `${userName}created as an option in` },
          makeEventDescriptionItemLikeSummaryObject(event.eventContent.itemLikeSummary),
        ];
      if (canViewCosts) {
        description.push({
          text: ` with cost impact ${generateCostImpactInfo(event.eventContent.newCost)}`,
        });
      }
      break;
    case EventType.ITEM_DELETED:
      description = [
        {
          text: `${event.eventContent.deletedBy?.name} deleted item #${event.eventContent.oldNumber}: ${event.eventContent.name}`,
        },
      ];
      break;
    case EventType.CREATE_ITEM:
      description = [
        { text: `${userName}created in ` },
        makeEventDescriptionTextMilestoneObject(milestone),
      ];
      if (event.eventContent.copiedItem) {
        if (event.eventContent.copiedItem.hasAccess) {
          description.push(
            { text: ' from copy of' },
            makeEventDescriptionCopiedItemLikeObject(event.eventContent.copiedItem)
          );
        } else {
          description.push({ text: ' from a copy' });
        }
      }
      break;
    case EventType.CHANGE_MILESTONE:
      description = [
        { text: `${userName}added to ` },
        makeEventDescriptionTextMilestoneObject(milestone),
        { text: ` with status ` },
        makeEventDescriptionTextStatusObject(event.eventContent.status),
      ];
      break;
    case EventType.REMOVE_FROM_MILESTONE:
      description = [
        { text: `${userName}removed from ` },
        makeEventDescriptionTextMilestoneObject(milestone),
      ];
      break;
    case EventType.DETACH_OPTION:
      if (removedOptions && removedOptions.length) {
        description = [
          getOptionUserActionDescription(removedOptions, userName, 'detached'),
          ...makeItemLikeSummaryList(removedOptions),
        ];
        break;
      }
      if (event.eventContent.itemLikeSummary)
        description = [
          { text: `${userName}removed option from` },
          makeEventDescriptionItemLikeSummaryObject(event.eventContent.itemLikeSummary),
        ];
      break;
    case EventType.ITEM_REFERENCED:
      if (event.eventContent.referencedByItems && event.eventContent.referencedByItems.length > 0) {
        const referencedByItemsFiltered = event.eventContent.referencedByItems.filter(
          (referencedByItem) => referencedByItem?.id !== event.itemID
        );
        description = [
          { text: `${userName}referenced in` },
          ...calculateEventDescriptionItemReferences(referencedByItemsFiltered),
        ];
      }
      break;
    case EventType.CHANGE_STATUS:
      if (
        event.eventContent.status &&
        isAcceptingStatus(event.eventContent.status) &&
        event.eventContent.statusChangeDraws &&
        event.eventContent.statusChangeDraws?.length
      ) {
        const contingencies = event.eventContent.statusChangeDraws
          ?.map((d) => {
            return d ? `${formatCost(d.value)} from ${d.name}` : '';
          })
          .join(', ');
        description = [
          { text: `${userName}changed status to ` },
          makeEventDescriptionTextStatusObject(event.eventContent.status),
          { text: ` drawing ${contingencies}` },
        ];
        break;
      }

      // on the item details page of an IWO we want to know which option we updated
      if (
        currentItem &&
        event.eventContent.itemLikeSummary &&
        event.eventContent.itemLikeSummary.id !== currentItem.id
      ) {
        description = [
          { text: `${userName}changed status to ` },
          {
            text: event.eventContent.status && getItemStatusLabel(event.eventContent.status),
          },
          { text: ` for option` },
          { itemLike: event.eventContent.itemLikeSummary },
        ];
        break;
      }

      description = [
        { text: `${userName}changed status to ` },
        {
          text: event.eventContent.status && getItemStatusLabel(event.eventContent.status),
        },
      ];
      break;
    case EventType.CHANGE_NUMBER:
      description = [
        {
          text:
            event.eventContent.newNumber && event.eventContent.newNumber.length !== 0
              ? `${userName}changed number to "${event.eventContent.newNumber}"`
              : `${userName}removed number`,
        },
      ];
      break;
    case EventType.CHANGE_NAME:
      description = [
        {
          text:
            event.eventContent.name && event.eventContent.name.length !== 0
              ? `${userName}changed name to "${event.eventContent.name}"`
              : `${userName}removed name`,
        },
      ];
      break;
    case EventType.CHANGE_DESCRIPTION:
      eventCount =
        event.eventContent.description && event.eventContent.description.trim().length ? 1 : 0;
      description = [
        {
          text:
            event.eventContent.description && event.eventContent.description.trim().length !== 0
              ? `${userName}edited description `
              : `${userName}removed description `,
        },
      ];
      break;
    case EventType.CHANGE_VISIBILITY:
      description = [
        {
          text: isItemSharingFeature
            ? `${userName}published item`
            : `${userName}changed visibility to `,
        },
        {
          text: isItemSharingFeature
            ? makeEventDescriptionVisibilityObjectNew(milestone)
            : makeEventDescriptionVisibilityObject(event.eventContent.visibility).text,
        },
      ];
      break;
    case EventType.CHANGE_DUE_DATE:
      description = [
        {
          text: event.eventContent.dueDate
            ? `${userName}changed due date to ${eventDateString(
                new Date(event.eventContent.dueDate),
                false
              )}`
            : `${userName}removed due date`,
        },
      ];
      break;
    case EventType.CHANGE_ASSIGNEE:
      description = [
        {
          text: event.eventContent.assignee
            ? `${userName}assigned item to ${event.eventContent.assignee.name}`
            : `${userName}unassigned item`,
        },
      ];
      break;
    case EventType.ADD_COMMENT:
      description = [
        {
          text: `${userName}commented`,
        },
      ];
      break;
    case EventType.ADD_ASSET:
      if (event.eventContent.addedAttachments)
        description = [
          {
            text: calculateEventDescriptionAssets(event.eventContent.addedAttachments, event.user),
          },
        ];
      break;
    case EventType.CHANGE_CATEGORY:
      if (event.eventContent.categoryChanges) {
        eventCount =
          event.eventContent?.categoryChanges?.length > 1
            ? event.eventContent?.categoryChanges?.length
            : 0;
        description = [
          {
            text: `${
              event.eventContent.categoryChanges.length === 1
                ? formatCategoryChangeDescriptionString(
                    event.eventContent.categoryChanges[0],
                    event.user
                  )
                : formatCategorizationsDescriptionString(
                    event.eventContent.categoryChanges,
                    event.user
                  )
            } `,
          },
        ];
      }
      break;
    case EventType.REMOVE_ASSET:
      if (event.eventContent.removedAssets)
        description = [
          {
            text: `${userName}removed ${
              event.eventContent.removedAssets.length === 1
                ? ` asset "${event.eventContent.removedAssets[0]?.name}" `
                : `assets ${event.eventContent.removedAssets
                    .map((a) => (a ? `"${a.name}"` : null))
                    .join(', ')} `
            }`,
          },
        ];
      break;
    case EventType.ITEM_DRAW_UPDATE:
      eventCount =
        event.eventContent?.drawChanges?.length && event.eventContent?.drawChanges?.length > 1
          ? event.eventContent?.drawChanges?.length
          : 0;
      description = calculateEventDescriptionDrawUpdate(event, canViewCosts);
      break;
    case EventType.ITEM_DRAW_SET:
      description = calculateEventDescriptionDrawSet(event, canViewCosts);
      break;
    case EventType.CREATE_PROCORE_CHANGE_EVENT:
    case EventType.DELETE_PROCORE_CHANGE_EVENT:
      description = calculateEventDescriptionProcoreChangeEventSet(event, type);
      break;
    default:
  }
  return { description, eventCount };
};

const getMultipleEventTypeDescription = (
  event: ItemActivityHistoryEvent,
  userName: string
): { description: SimpleEventDescription[]; eventCount: number } => {
  const { eventTypes } = event;
  const typesArray = eventTypes.map((type) => {
    switch (type) {
      case EventType.REMOVE_ASSET:
        return (
          event.eventContent.removedAssets &&
          pluralizeCountString('asset', event.eventContent.removedAssets.length)
        );
      case EventType.CHANGE_ASSIGNEE:
        return 'assignee';
      case EventType.CHANGE_CATEGORY:
        return event.eventContent.categoryChanges && event.eventContent.categoryChanges.length > 0
          ? pluralizeCountString('category', event.eventContent.categoryChanges.length)
          : undefined;
      case EventType.CHANGE_DESCRIPTION:
        return 'description';
      case EventType.CHANGE_VISIBILITY:
        return 'visibility';
      case EventType.CHANGE_DUE_DATE:
        return 'due date';
      case EventType.CHANGE_MEETING:
        return 'meeting';
      case EventType.CHANGE_NAME:
        return 'name';
      case EventType.CHANGE_NUMBER:
        return 'number';
      case EventType.CHANGE_COST:
        return 'estimate';
      default:
        break;
    }
    return undefined;
  });
  const description = [
    { text: `${userName} edited ${typesArray.filter(isNonNullable).join(', ')}` },
  ];
  const eventCount = typesArray.filter((type) => !!type).length;
  return { description, eventCount };
};

const getEventContent = (event: ItemActivityHistoryEvent): ItemActivityEventContent => {
  return {
    ...event.eventContent,
    addedAttachments: event.eventContent.addedAttachments?.filter(isNonNullable) ?? undefined,
    comment: event.eventContent.comment ?? undefined,
    name: event.eventContent.name ?? undefined,
    description: event.eventContent.description ?? undefined,
    assignee: event.eventContent.assignee ?? undefined,
    oldNumber: event.eventContent.oldNumber ?? undefined,
    newNumber: event.eventContent.newNumber ?? undefined,
    dueDate: event.eventContent.dueDate ?? undefined,
    descriptionStyled: event.eventContent.descriptionStyled ?? undefined, // item description, but with styling. eg bold, italics, etc

    categoryChanges: event.eventContent.categoryChanges // We sort this so we need a copy we can modify later.  re: Do this on the backend?
      ? [...event.eventContent.categoryChanges]
      : undefined,
    costChanges: event.eventContent.costChanges ?? undefined,
    drawChanges: event.eventContent.drawChanges ?? undefined,
    scheduleImpactChanges: event.eventContent.scheduleImpactChanges ?? undefined,

    itemLikeSummary: event.eventContent.itemLikeSummary ?? undefined,
    visibility: event.eventContent.visibility ?? undefined,
  };
};

// Takes IH events list filter and calculates event types
export const computeFiltersList = (
  filters: string[],
  isScheduleImpactEnabled: boolean,
  isItemSharingEnabled: boolean
) => {
  return filters.reduce(
    (filterArray: EventType[], f: string) => {
      switch (f) {
        case ASSIGNEE_CHANGE:
          filterArray.push(EventType.CHANGE_ASSIGNEE);
          break;
        case ATTACHMENT_CHANGE:
          filterArray.push(EventType.ADD_ASSET, EventType.REMOVE_ASSET);
          break;
        case CATEGORY_CHANGE:
          filterArray.push(EventType.CHANGE_CATEGORY);
          break;
        case COST_CHANGE:
          filterArray.push(EventType.CHANGE_COST);
          break;
        case VISIBILITY_CHANGE:
          if (!isItemSharingEnabled) filterArray.push(EventType.CHANGE_VISIBILITY);
          break;
        case DUEDATE_CHANGE:
          filterArray.push(EventType.CHANGE_DUE_DATE);
          break;
        case MEETING_CHANGE:
          filterArray.push(EventType.CHANGE_MEETING);
          break;
        case EVENT_CHANGE:
          filterArray.push(EventType.CHANGE_MEETING);
          break;
        case MILESTONE_CHANGE:
          filterArray.push(EventType.CHANGE_MILESTONE);
          break;
        case NUMBER_NAME_DESCR_CHANGE:
          filterArray.push(
            EventType.CHANGE_NAME,
            EventType.CHANGE_NUMBER,
            EventType.CHANGE_DESCRIPTION
          );
          break;
        case OPTION_CHANGE:
          filterArray.push(
            EventType.ATTACH_OPTION,
            EventType.CREATE_OPTION,
            EventType.DETACH_OPTION
          );
          break;
        case STATUS_CHANGE:
          filterArray.push(EventType.CHANGE_STATUS);
          break;
        case TIMELINE_ACTIVITY_CHANGES:
          filterArray.push(EventType.CHANGE_ACTIVITIES);
          break;
        case ITEM_DRAW_UPDATE_CHANGE:
          filterArray.push(EventType.ITEM_DRAW_UPDATE);
          break;
        case PROCORE_CHANGE_EVENT:
          filterArray.push(
            EventType.CREATE_PROCORE_CHANGE_EVENT,
            EventType.DELETE_PROCORE_CHANGE_EVENT
          );
          break;
        case ITEM_DRAW_SET_CHANGE:
          filterArray.push(EventType.ITEM_DRAW_SET);
          break;
        case ITEM_REFERENCE_CHANGE:
          filterArray.push(EventType.ITEM_REFERENCED);
          break;
        case SHARE_SETTING_CHANGE:
          filterArray.push(
            EventType.ADD_SHARED_DRAFT_USER_EVENT,
            EventType.REMOVE_SHARED_DRAFT_USER_EVENT
          );
          break;
        case SCHEDULE_IMPACT_CHANGE:
          if (isScheduleImpactEnabled) filterArray.push(EventType.CHANGE_SCHEDULE_IMPACT);
          break;
        default:
          break;
      }
      return filterArray;
    },
    [EventType.CHANGE_VISIBILITY]
  );
};

const getEventItemLinks = (
  event: ItemActivityHistoryEvent,
  itemLinks: ItemHistoryLink[] | undefined
): ItemActivityEvent['itemLinks'] => {
  return {
    item: itemLinks?.find(
      (itemLink) =>
        !event.eventTypes.includes(EventType.ITEM_DELETED) && itemLink.id === event.itemID
    ),
    options:
      event.eventContent.attachedOptions
        ?.map((option) => itemLinks?.find((itemLink) => itemLink.id === option?.id))
        .filter((itemLink): itemLink is ItemHistoryLink => itemLink !== undefined) || [],
  };
};

// use the date to determine the DateGroup, need to clean this up and move it to utils
export const getDateGroup = (date: string) => {
  const eventDate = new Date(date);

  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const lastWeekMonday = new Date(today);
  lastWeekMonday.setDate(lastWeekMonday.getDate() - ((today.getDay() + 6) % 7));

  const isLastWeeksMonday = eventDate.toDateString() === lastWeekMonday.toDateString();
  const beforeLastWeekMonday = eventDate < lastWeekMonday;

  if (eventDate.toDateString() === today.toDateString()) {
    return DateGroup.TODAY;
  }
  if (eventDate.toDateString() === yesterday.toDateString()) {
    return DateGroup.YESTERDAY;
  }

  if (isLastWeeksMonday || beforeLastWeekMonday) {
    return DateGroup.LASTWEEK;
  }
  return null;
};
