import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const boldText = {
  fontWeight: 600,
};

export default (theme: KomodoTheme) =>
  createStyles({
    linkContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 1,
      flexWrap: 'wrap',
      fontFamily: theme.typography.fontFamily,
      gap: '2px',
    },
    linkTextClamp: {
      overflow: 'hidden',
      textOverflow: 'ellipse',
      display: '-webkit-box',
      lineClamp: 2,
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    linkTitle: {
      color: 'inherit',
      ...theme.linkFocus,
      '&:hover': {
        color: theme.palette.link,
      },
      background: 'transparent',
      border: '0',
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      paddingLeft: '0',
      lineHeight: '17.5px',
    },
    linkTitleDisabled: {
      color: 'inherit',
      background: 'transparent',
      border: '0',
      paddingLeft: '0',
    },
    linkText: {
      flexShrink: 1,
      textAlign: 'left',
      wordBreak: 'break-word',
      textDecoration: 'underline',
    },
    underline: {
      fontWeight: 300,
    },
  });
