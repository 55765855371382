import { FC, useContext, useEffect, useRef } from 'react';
import * as React from 'react';

import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import {
  CostTableColumnInputKey,
  ProjectCompsCostTableColumnValue,
} from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { renderPercentString } from '../../../utilities/string';
import { renderValueString } from '../../CostReport/CostReportUtils';
import { ProjectCompIntersectionElements } from '../constants/elementIdentifiers';
import ObserverContext from '../context/observer';
import ProjectCompInformationField from '../ProjectCompInformationField';
import styles from '../ProjectCompsStyles';

import { formatGridTemplateColumns } from './ProjectCompCostTableUtils';

type ProjectCompsCostTableSummaryRowProps = {
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  cyPercentId?: string;
  cyQuantityTotalId?: string;
  cyTotalId?: string;
  hasBorder?: boolean;
  shouldObserve?: boolean;
  showMinMax: boolean;
  columnValues: ProjectCompsCostTableColumnValue[];
};

// TODO: Combine with ProjectCompsCostTableRow
const ProjectCompsCostTableSummaryRow: FC<ProjectCompsCostTableSummaryRowProps> = ({
  classes,
  costTableColumnInputs,
  cyPercentId,
  cyQuantityTotalId,
  cyTotalId,
  hasBorder,
  shouldObserve = false,
  showMinMax,
  columnValues,
}) => {
  const { projectCompIntersectionObserver } = useContext(ObserverContext);
  const tableFooter = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = tableFooter;
    if (projectCompIntersectionObserver && current && shouldObserve) {
      projectCompIntersectionObserver.observe(current);
    }
    return () => {
      if (projectCompIntersectionObserver && current) {
        projectCompIntersectionObserver.unobserve(current);
      }
    };
  }, [projectCompIntersectionObserver, tableFooter, shouldObserve]);

  const gridTemplateColumns = formatGridTemplateColumns(costTableColumnInputs);

  return (
    <div
      ref={tableFooter}
      style={{ gridTemplateColumns }}
      className={`${classes.row} ${hasBorder ? classes.hardBorder : ''} ${
        shouldObserve ? ProjectCompIntersectionElements.COST_TABLE_FOOTER : ''
      }`}
    >
      {columnValues?.map((columnValue, i) => {
        const cellKey = costTableColumnInputs?.at(i)?.key;
        switch (cellKey) {
          case CostTableColumnInputKey.TOTAL:
            return (
              <ProjectCompInformationField
                className={classes.cell}
                classNameTypography={`${classes.summaryCostCell}`}
                dataCy={cyTotalId}
                value={renderValueString({
                  value: columnValue.valueNumeric,
                  isExact: true,
                })}
              />
            );
          case CostTableColumnInputKey.TOTAL_PER_METRIC: {
            const value = renderValueString({
              value: columnValue.valueNumeric,
              isExact: true,
            });
            let perUnitContents: React.ReactNode = value;
            let perUnitCellClassName = '';

            if (showMinMax && columnValue) {
              const { isMaxValue, isMinValue } = columnValue;

              if (isMaxValue || isMinValue) {
                perUnitCellClassName = isMaxValue ? classes.lightRedBG : classes.lightGreenBG;

                const ArrowIcon = isMaxValue ? ArrowUpward : ArrowDownward;
                perUnitContents = (
                  <>
                    {value}
                    <ArrowIcon className={classes.arrowIcon} />
                  </>
                );
              }
            }
            return (
              <ProjectCompInformationField
                className={`${classes.cell} ${perUnitCellClassName}`}
                classNameTypography={`${classes.summaryCostCell}`}
                dataCy={cyQuantityTotalId}
                value={perUnitContents}
              />
            );
          }
          case CostTableColumnInputKey.PERCENT:
            return (
              <ProjectCompInformationField
                className={classes.cell}
                classNameTypography={`${classes.summaryCostCell}`}
                dataCy={cyPercentId}
                value={renderPercentString({
                  value: columnValue.valueNumeric / 100,
                  overrideZeroCostDisplay: false,
                })}
              />
            );
          case CostTableColumnInputKey.METRIC:
            return (
              <ProjectCompInformationField
                className={classes.cell}
                classNameTypography={`${classes.summaryCostCell}`}
                dataCy={cyPercentId}
                value={formatCommas(columnValue.valueNumeric)}
              />
            );
          default:
            throw new Error('Unsupported cell type in ProjectCompsCostTableSummaryRow');
        }
      })}
    </div>
  );
};

export default withStyles(styles)(ProjectCompsCostTableSummaryRow);
