import { ReactNode } from 'react';

import { getTextColor } from '../../../utilities/colors';

import CloseButton from './CloseButton';

type Props = {
  'data-cy'?: string;
  backgroundColor?: string;
  icon?: ReactNode;
  onRemove?: () => void;
  text: ReactNode;
};

const Chip = (props: Props) => {
  let bgClassName: string | undefined = 'bg-background-2';
  let textClassName = 'text-type-primary';
  if (props.backgroundColor) {
    bgClassName = undefined;
    textClassName = getTextColor(props.backgroundColor);
  }

  const Icon = props.icon;

  return (
    <div
      className={`${textClassName} ${bgClassName} flex h-6 w-min max-w-full items-center gap-0.5 truncate whitespace-nowrap rounded px-1.5 type-label`}
      data-cy={props['data-cy']}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {Icon && (
        /*
          We're going to override the size of the icon that's been passed in.
          Currently, the icons passed in are almost always guaranteed to be MUI icons which
          set their own size via the font-size property. This allows us to override it.
          
          We could've also taken the icon component type as the prop and rendered it with a
          `fontSize="inherit"` prop but that'll require too many changes from users of this
          component if we ever start supporting non-MUI icons.
          */
        <div className="[&_*]:!icon-sm">{Icon}</div>
      )}
      <div className="truncate">{props.text}</div>
      {props.onRemove && (
        <CloseButton
          data-cy={`remove-${props['data-cy']}`}
          backgroundColor={props.backgroundColor}
          onPress={props.onRemove}
        />
      )}
    </div>
  );
};

export default Chip;
