import { FC } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import AssetsCardStyles from './AssetsCardStyles';

type AssetsCardAddFileProps = {
  classes: Classes<typeof AssetsCardStyles>;
  dragging?: boolean;
  onClick?: () => void;
};

const AssetsCardAddFile: FC<AssetsCardAddFileProps> = ({ classes, dragging, onClick }) => {
  const buttonClass = `${classes.root} ${classes.tile} ${classes.addFileBorder} ${
    dragging ? classes.dragging : classes.notDragging
  }`;

  return (
    <div className={classes.container}>
      <ButtonBase data-cy="buttonBase-projectAssetIcon" className={buttonClass} onClick={onClick}>
        {!dragging && (
          <div>
            <Add className={classes.addFileIcon} />
            <Typography className={classes.addFileText}>Add a file</Typography>
          </div>
        )}
      </ButtonBase>
    </div>
  );
};

export default withStyles(AssetsCardStyles)(AssetsCardAddFile);
