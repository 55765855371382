import { useReactiveVar } from '@apollo/client';

import {
  ListDetailedItemsQuery,
  ListDetailedItemsQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../../utilities/costMode';

import { listDetailedItemsQuery } from './queries';

function useListDetailedItemsQuery(
  projectId: string,
  milestoneId: string | null,
  viewFilter: ViewFilterInput
) {
  return useQuery<ListDetailedItemsQuery, ListDetailedItemsQueryVariables>(listDetailedItemsQuery, {
    variables: {
      costMode: useReactiveVar(costModeVar),
      viewFilter,
      milestoneId,
      projectId,
    },
  });
}

export default useListDetailedItemsQuery;
