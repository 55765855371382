import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { JoinRoutes } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { noPermissionTooltip, percentFormatterSimple } from '../../../utilities/string';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ProjectMap } from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectsMovementStyles from './ExecutiveDashboardProjectsMovementStyles';

type ExecutiveDashboardProjectMovementRowProps = {
  classes: Classes<typeof ExecutiveDashboardProjectsMovementStyles>;
  icon: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  row: any;
  projectMap?: ProjectMap;
};

const ExecutiveDashboardProjectMovementRow: FC<ExecutiveDashboardProjectMovementRowProps> = ({
  classes,
  icon,
  row,
  projectMap,
}) => {
  const { name, milestoneName, projectID } = row || { name: '-', milestoneName: '', projectID: '' };
  const { hasAccess = false } = projectMap?.get(projectID) || {};

  let lastUpdated = '\xA0';
  let absolute = '-';
  let relative = '-';

  if (row) {
    lastUpdated = `Updated ${new Date(row.lastUpdated).toLocaleDateString()}`;
    absolute = formatCost(row.absolute, { short: true, signed: true });
    if (Number.isFinite(row.relative) && row.relative !== 0)
      relative = percentFormatterSimple.format(row.relative);
  }

  const projectTooltip = (
    <>
      <div>{name}</div>
      <div>{milestoneName}</div>
      <div>{lastUpdated}</div>
    </>
  );
  const permissionTooltip = hasAccess ? projectTooltip : noPermissionTooltip(name);
  const tooltip = row ? permissionTooltip : '';

  const projectName = hasAccess ? (
    <Link
      className={`${classes.nameText} ${classes.linkText}`}
      to={generateSharedPath(JoinRoutes.PROJECT, { projectId: projectID })}
    >
      {name}
    </Link>
  ) : (
    <Typography className={`${classes.nameText} ${classes.noPermission}`}>{name}</Typography>
  );

  return (
    <tr className={`${classes.row} ${row ? '' : classes.rowDisabled}`}>
      <td className={classes.iconCell}>{icon}</td>
      <NormalTooltip title={tooltip}>
        <td className={classes.nameCell}>
          {projectName}
          <Typography variant="caption" className={classes.milestoneName}>
            {milestoneName || '\xA0'}
          </Typography>
        </td>
      </NormalTooltip>
      <td className={classes.dataCell}>
        <Typography>{absolute}</Typography>
      </td>
      <td className={classes.dataCell}>
        <Typography>{relative}</Typography>
        <Typography variant="caption" className={classes.lastUpdated}>
          {lastUpdated}
        </Typography>
      </td>
    </tr>
  );
};

export default withStyles(ExecutiveDashboardProjectsMovementStyles)(
  ExecutiveDashboardProjectMovementRow
);
