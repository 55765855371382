import { FC, useEffect, useState } from 'react';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import { CategorizationTemplate } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useImportCategories } from '../../assets/hooks/AssetsMutationHook';
import { Uploader, UploaderLink } from '../../scales';

import { CategoriesImportState } from './types';

const DOWNLOAD_TEMPLATE_TEXT = 'Download categorization import template';

type ImportCategoriesDataProps = {
  // The categorization that the categories we are importing should be associated with
  categorizationID?: UUID;
  projectID?: UUID;
  previewErrors?: string;
  // Used to let the parent component know where we uploaded the categories
  setImportAssetID?: (assetID: UUID) => void;
  // A way for the parent component to know the state of the import component
  handleImportState?: (state: CategoriesImportState) => void;
};

function isExcelFile(file: File): boolean {
  return /\.(xls|xlsx)$/i.test(file.name);
}

const ImportCategoriesData: FC<ImportCategoriesDataProps> = ({
  categorizationID,
  projectID,
  previewErrors,
  handleImportState,
  setImportAssetID,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const importCategories = useImportCategories();
  const sendAnalytics = useSendAnalyticsEventHook();

  const onFileSelected = (file: File | undefined, source: FileSource) => {
    if (file && isExcelFile(file)) {
      addFile(file, source);
    } else {
      setErrorText('Selected file is not an accepted file type. Please provide a .xls or .xlsx.');
    }
  };

  const addFile = (file: File, source: FileSource) => {
    setLoading(true);
    handleImportState?.(CategoriesImportState.loading);
    importCategories(
      file,
      categorizationID,
      projectID || undefined,
      (res) => {
        setLoading((prevLoading) => {
          const isCancelled = !prevLoading;
          if (isCancelled) return false;
          setImportAssetID?.(res.id);
          sendAnalytics(
            categorizationEvent(CategorizationEvent.CREATE_NEW_IMPORT_FILE, {
              source,
            })
          );
          return false;
        });
      },
      (err) => {
        setImportAssetID?.('');
        setErrorText(err?.message || 'An error occurred while importing');
        setLoading(false);
        handleImportState?.(CategoriesImportState.none);
      }
    );
  };

  const onCancelUploading = () => {
    setLoading(false);
    setErrorText('');
    handleImportState?.(CategoriesImportState.none);
    setImportAssetID?.('');
    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_LOADING_CANCEL));
  };

  useEffect(() => {
    if (previewErrors) {
      setImportAssetID?.('');
      setErrorText(previewErrors);
      setLoading(false);
      handleImportState?.(CategoriesImportState.none);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [loading, previewErrors]);

  const onDownloadTemplate = () => {
    joinAPI.exportCategories(undefined, CategorizationTemplate.BLANK_CATEGORIZATION, [
      'Join Categorization Template',
    ]);
    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_DOWNLOAD_TEMPLATE));
  };

  const onHoverHeaderTooltip = () => {
    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_TOOLTIP));
  };

  const onNeedHelp = () => {
    const needHelpLink = 'https://success.join.build/en/knowledge/importing-a-categorization';
    window.open(needHelpLink);
    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_HELP_CLICK));
  };

  const importTooltip = (
    <div>
      <div>Set up your categorizations.</div>
      <br />
      <div>Join supports multi-level and single-level categorizations.</div>
      <br />
      <div>
        Multi-level categorizations will allow you to roll up data into up to 4 levels, so you can
        see high-level to granular views of your data. Use single-level categorizations for a simple
        flat list of options.
      </div>
      <br />
      <div>
        Quickly import categorizations using our spreadsheet template, or create them manually.
        Currently, only single-level categorizations can be created and edited through the UI.
      </div>
    </div>
  );

  return (
    <Uploader
      errorTextDescription={errorText}
      footerLeft={<UploaderLink onClick={onDownloadTemplate} label={DOWNLOAD_TEMPLATE_TEXT} />}
      footerRight={<UploaderLink onClick={onNeedHelp} label="Need help?" />}
      header="Import categories from template"
      headerTooltip={importTooltip}
      loading={loading}
      onCancelUploading={onCancelUploading}
      onFileSelected={onFileSelected}
      onHoverHeaderTooltip={onHoverHeaderTooltip}
    />
  );
};

export default ImportCategoriesData;
