import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { NetworkStatus, useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { setItemEstimateExpanded } from '../../../analytics/analyticsEventProperties';
import { CostMode } from '../../../api/gqlEnumsBe';
import { ESTIMATE } from '../../../constants';
import { PermissionResource, Status } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { costModeVar } from '../../../utilities/costMode';
import { getItemCost } from '../../../utilities/items';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { getItemIdFromUrl } from '../../../utilities/url';
import { isCostScalar } from '../../CostReport/CostReportUtils';
import { NAV_HEIGHT } from '../../frame/FrameTitleBar/FrameTitleBarStyles';
import CostImpact from '../../shared-widgets/CostImpact';
import useMemoWrapper from '../../useMemoWrapper';
import { useGetItemContingenciesQuery } from '../hooks/useGetItemContingencies';
import { useItemQuery } from '../hooks/useItemQuery';

import ContingencyContent from './ContingencyContent';
import ContingencyDrawTotal from './ContingencyDrawTotal';
import EstimateContent from './EstimateContent';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemsCollapseStyles from './ItemsCollapseStyles';
import NullEstimateContent from './NullEstimateContent';
import { getContingencyDrawCosts, getContingencyEstimateCosts } from './utils';

type Props = {
  classes: Classes<typeof ItemsCollapseStyles>;
  isExpanded: boolean;
  isOption: boolean;
  itemStatus: Status;
  projectID: UUID;
  setOpenDialogAddOption: (option: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
};

const ItemsCollapse: FC<Props> = ({
  classes,
  isExpanded,
  isOption = false,
  itemStatus,
  projectID,
  setIsExpanded,
  setOpenDialogAddOption,
}) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isCostImpactExpanded, setIsCostImpactExpanded] = useState(false); // I probably need to store this globally
  const [previousCostMode, setPreviousCostMode] = useState(costModeVar());

  const itemID = getItemIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const { data, refetch, networkStatus } = useItemQuery(itemID, MountPolicy.SKIP_ON_MOUNT);
  const item = data?.item || undefined;

  const costMode = useReactiveVar(costModeVar);
  useEffect(() => {
    if (previousCostMode !== costMode) {
      refetch({ id: itemID, costMode });
      setPreviousCostMode(costMode);
    }
  }, [costMode, itemID, previousCostMode, refetch]);

  const categories: Category[] = useMemo(() => {
    if (item) return item.categories.map((c) => ({ ...c, levels: [] }));
    return [];
  }, [item]);

  // PERMISSIONS
  const { canView, inTrade } = usePermissions({ trades: categories });
  const canViewMarkups = canView(PermissionResource.MARKUPS) && costMode !== CostMode.NoMarkups;
  const canEditCosts = canView(getItemLinesPermissionResource(inTrade));
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewItemCost = canView(getItemLinesPermissionResource(inTrade));

  // Options
  const options: DetailedOption[] = useMemo(() => {
    if (item && 'options' in item) return item.options.filter((o) => !!o);
    return [];
  }, [item]);

  // Costs
  const contingencyDrawTotal: Cost = useMemoWrapper(getContingencyDrawCosts, item);
  const estimateCost: Cost = useMemoWrapper(getContingencyEstimateCosts, item);

  // Continencies
  const hasContingencyDrawTotal =
    isCostScalar(contingencyDrawTotal) && contingencyDrawTotal.value !== 0;
  const { data: queryData, refetch: refetchItemContingenciesData } = useGetItemContingenciesQuery(
    projectID,
    item?.id,
    !item || !canViewMarkups // skip if user doesn't have markups view permission
  );
  const itemContingenciesData = queryData?.getItemContingencies;
  const costImpact = useMemoWrapper(getItemCost, item);

  // HELPER FUNCTIONS
  const activeEstimate = useMemo(() => (item && item.activeEstimate) || null, [item]); // Is this useMemo necessary?

  const scrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (window.location.hash && window.location.hash.split('?')[0].slice(1) === ESTIMATE) {
      setIsCostImpactExpanded(true);
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  if (!item) return null;
  if (!shouldDisplayCosts) return null;
  if (activeEstimate)
    return (
      <div className={classes.card} ref={scrollRef} style={{ scrollMarginTop: NAV_HEIGHT + 1 }}>
        <ItemDetailsCollapse
          dataCy="costImpact"
          analyticsEvent={setItemEstimateExpanded(!isExpanded)}
          canExpand={canViewItemCost}
          headerContent={
            <>
              <Typography
                className={`${classes.collapseTitle} ${classes.bold}`}
                data-cy="itemsCollapse-costImpact"
              >
                Cost Impact
              </Typography>
              <div className={classes.costContainer}>
                {shouldDisplayCosts && <CostImpact value={costImpact} fontWeight={500} />}
              </div>
            </>
          }
          isExpanded={isCostImpactExpanded}
          setIsExpanded={setIsCostImpactExpanded}
        >
          <EstimateContent
            loading={networkStatus === NetworkStatus.refetch}
            categories={categories}
            estimateCost={estimateCost}
            item={item}
            isOption={isOption}
            isAllExpanded={isAllExpanded}
            isExpanded={isExpanded}
            options={options}
            projectID={projectID}
            sendAnalytics={sendAnalytics}
            setIsExpanded={setIsExpanded}
            setOpenDialogAddOption={setOpenDialogAddOption}
            itemID={itemID}
            hasGrayBackground={false}
          />
          {canViewMarkups && activeEstimate && itemContingenciesData && (
            <ContingencyContent
              fullWidth
              contingencyDrawTotal={contingencyDrawTotal}
              itemContingenciesData={itemContingenciesData}
              refetchItemContingenciesData={refetchItemContingenciesData}
              onItemMutated={refetch}
              estimateID={activeEstimate.id}
              itemID={itemID}
              itemStatus={itemStatus}
              projectID={projectID}
              estimateCost={estimateCost}
            />
          )}
          {canViewEstimateCostSubtotals &&
            hasContingencyDrawTotal &&
            !canViewMarkups &&
            costMode !== CostMode.NoMarkups && (
              <ContingencyDrawTotal contingencyDrawTotal={contingencyDrawTotal} />
            )}
        </ItemDetailsCollapse>
      </div>
    );

  return (
    <div className={classes.card}>
      {!activeEstimate && (canEditCosts || !isOption) && (
        <NullEstimateContent
          canEditCosts={canEditCosts}
          isOption={isOption}
          item={item}
          projectID={projectID}
          setIsAllExpanded={(isExpanded) => {
            setIsAllExpanded(isExpanded);
            setIsCostImpactExpanded(isExpanded);
          }}
          setOpenDialogAddOption={setOpenDialogAddOption}
        />
      )}
    </div>
  );
};

export default withStyles(ItemsCollapseStyles)(ItemsCollapse);
