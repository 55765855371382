import { FC } from 'react';

interface DropdownRendererProps {
  onSelect: () => void;
  value: string;
  disabled: boolean;
  readOnly: boolean;
}

const DropdownRenderer: FC<DropdownRendererProps> = ({ value, disabled, onSelect, readOnly }) => {
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled` : '';
  return (
    <div className={`join-grid-renderer join-grid-string ${disabledMarkupStyle}`}>
      <div className="join-grid-trim-text">{value}</div>
      {readOnly ? (
        <div />
      ) : (
        <div
          className="join-grid-dropdown-arrow push-right"
          onClick={onSelect}
          onKeyDown={onSelect}
        />
      )}
    </div>
  );
};

export default DropdownRenderer;
