import { FC } from 'react';

import { Button, Dialog, DialogActions, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './RoleEditConfirmStyles';

type RoleEditConfirmProps = {
  classes: Classes<typeof styles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onReset: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  open: any;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setConfirmModalOpen: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setEditable: any;
};

const RoleEditConfirm: FC<RoleEditConfirmProps> = ({
  classes,
  onReset,
  open,
  name,
  setConfirmModalOpen,
  setEditable,
}) => (
  <Dialog
    open={open}
    onClose={() => setConfirmModalOpen(false)}
    aria-labelledby="form-dialog-title"
  >
    <div className={classes.titleContainer}>
      <div>
        <Typography variant="title" className={classes.title}>
          Confirm navigation
        </Typography>
      </div>
      <IconButton
        onClick={() => {
          setConfirmModalOpen(false);
        }}
        title="Close dialog"
        className={classes.close}
      >
        <Close />
      </IconButton>
    </div>
    <Divider />
    <div className={classes.titleContainer}>
      <Typography>
        {`You have unsaved permission changes for the ${name} role. Are you sure you want to leave?`}
      </Typography>
    </div>
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setConfirmModalOpen(false);
        }}
      >
        Stay
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          onReset();
          setEditable(false);
          setConfirmModalOpen(false);
        }}
      >
        Leave
      </Button>
    </DialogActions>
  </Dialog>
);

export const RoleEditConfirmStyled = withStyles(styles)(RoleEditConfirm);

export default RoleEditConfirmStyled;
