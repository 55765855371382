import { FC } from 'react';

import { ForecastingProjectsSortKey } from '../../../../generated/graphql';
import MilestoneCell from '../../../ForecastingTab/ForecastingExplore/projects/cells/MilestoneCell';
import ProjectCell from '../../../ForecastingTab/ForecastingExplore/projects/cells/ProjectCell';
import { SortManager, Table } from '../../../scales';

import ActiveMilestoneCosts from './cells/ActiveMilestoneCosts';
import LastUpdatedCell from './cells/LastUpdatedCell';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: ForecastingProject[];
  searchText: string;
  sortManager: SortManager;
};

const SearchProjects: FC<Props> = (props) => {
  return (
    <>
      <Table
        entries={props.projects.map((project) => {
          const key = project.id;
          return [
            { key, component: <ProjectCell project={project} searchText={props.searchText} /> },
            { key, component: <MilestoneCell project={project} /> },
            {
              key,
              component: <LastUpdatedCell project={project} />,
            },
            {
              key,
              centered: true,
              component: <ActiveMilestoneCosts project={project} />,
            },
          ];
        })}
        columnWidths={[
          'minmax(300px, 6fr)',
          'minmax(200px, 2fr)',
          'minmax(200px, 1fr)',
          'minmax(225px, 1fr)',
        ]}
        headerContent={[
          {
            copy: `Projects (${new Intl.NumberFormat().format(
              props.counts.current
            )} of ${new Intl.NumberFormat().format(props.counts.total)})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Active milestone',
            key: 'active-milestone',
            headerSortKey: ForecastingProjectsSortKey.MILESTONE_NAME,
          },
          {
            copy: 'Last updated',
            key: 'last-updated',
            headerSortKey: ForecastingProjectsSortKey.UPDATED_AT,
          },
          { copy: 'Project costs', key: 'active-milestone-costs' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
      />
    </>
  );
};

export default SearchProjects;
