import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import {
  LOCALSTORAGE_COMPANY_COLLABORATORS_SORT,
  LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT,
} from '../../../constants';
import { getCollaboratorUserIdFromUrl } from '../../../utilities/url';
import { useCompanyCollaboratorsQuery } from '../../Companies/hooks/GetCompanyCollaboratorsQuery';
import { useCompanyCollaboratorDetailsQuery } from '../CompanyHooks';
import {
  filterCompanyCollaborators,
  getFilteredCollaboratorCount,
  useCompanyRoutesData,
  useSort,
} from '../CompanyTabUtils';

import CompanyTabCollaboratorsProfile from './CompanyTabCollaboratorsProfile';

const CompanyTabCollaboratorsProfileData: FC = () => {
  useLocation();
  const { companyID, filterManager, searchManager } = useCompanyRoutesData();
  const { filterStatus, setFilterStatus } = filterManager;
  // user ID
  const collaboratorID = getCollaboratorUserIdFromUrl();

  const sortManagerProjects = useSort(LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT);
  const sortManagerCollaborators = useSort(
    LOCALSTORAGE_COMPANY_COLLABORATORS_SORT,
    CompanyAdminView.COLLABORATORS_SIDEBAR
  );

  const { data: companyCollaboratorsData, loading: companyCollaboratorsLoading } =
    useCompanyCollaboratorsQuery(
      companyID,
      undefined,
      sortManagerCollaborators.sortState as CompanyUserSortBy
    );
  const companyCollaboratorDetails = useCompanyCollaboratorDetailsQuery(
    collaboratorID,
    companyID,
    sortManagerProjects.sortState as UserProjectSortBy
  ).data?.companyCollaboratorDetails;

  const companyCollaborators = companyCollaboratorsData?.companyCollaborators;

  if (companyCollaboratorsLoading || !companyCollaborators) return null;

  const countTotalCollaborators = companyCollaborators?.length ?? 0;

  const filteredCompanyCollaborators = filterCompanyCollaborators(
    companyCollaborators,
    filterStatus
  );

  const counts = getFilteredCollaboratorCount(companyCollaborators);
  const collaboratorCounts = [counts.all, counts.active, counts.deactivated, counts.pending];

  if (!companyID) return null;
  return (
    <div>
      <CompanyTabCollaboratorsProfile
        companyCollaborators={filteredCompanyCollaborators}
        companyCollaboratorDetails={companyCollaboratorDetails}
        companyCollaboratorsLoading={companyCollaboratorsLoading}
        countTotalCollaborators={countTotalCollaborators}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        sortManagerProjects={sortManagerProjects}
        sortManagerCollaborators={sortManagerCollaborators}
        searchManager={searchManager}
        userCounts={collaboratorCounts}
      />
    </div>
  );
};

export default CompanyTabCollaboratorsProfileData;
