import { FC } from 'react';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { LOCALSTORAGE_COMPANY_COLLABORATORS_SORT } from '../../../constants';
import { useCompanyCollaboratorsQuery } from '../../Companies/hooks/GetCompanyCollaboratorsQuery';
import {
  filterCompanyCollaborators,
  getFilteredCollaboratorCount,
  useCompanyRoutesData,
  useSort,
} from '../CompanyTabUtils';

import CompanyTabCollaborators from './CompanyTabCollaborators';

const CompanyTabCollaboratorsData: FC = () => {
  const { companyID, filterManager, searchManager } = useCompanyRoutesData();
  const { filterStatus, setFilterStatus } = filterManager;

  const sortManagerCollaborators = useSort(
    LOCALSTORAGE_COMPANY_COLLABORATORS_SORT,
    CompanyAdminView.COLLABORATORS_LIST
  );

  const {
    data: { companyCollaborators } = { companyCollaborators: [] },
    loading: companyCollaboratorsLoading,
  } = useCompanyCollaboratorsQuery(
    companyID,
    undefined,
    sortManagerCollaborators.sortState as CompanyUserSortBy
  );

  if (!companyCollaborators) return null;

  const countTotalCollaborators = companyCollaborators?.length ?? 0;

  const filteredCompanyCollaborators = filterCompanyCollaborators(
    companyCollaborators,
    filterStatus
  );
  const counts = getFilteredCollaboratorCount(companyCollaborators);

  const collaboratorCounts = [counts.all, counts.active, counts.deactivated, counts.pending];

  if (!companyID) return null;
  return (
    <CompanyTabCollaborators
      collaboratorCounts={collaboratorCounts}
      companyCollaborators={filteredCompanyCollaborators}
      countTotalCollaborators={countTotalCollaborators}
      filterStatus={filterStatus}
      loading={companyCollaboratorsLoading}
      setFilterStatus={setFilterStatus}
      searchManager={searchManager}
      sortManagerCollaborators={sortManagerCollaborators}
    />
  );
};

export default CompanyTabCollaboratorsData;
