import { FC, useState } from 'react';

import { MoreVert, OpenInNew } from '@material-ui/icons';

import {
  ITEM_ACTIVITY_PROCORE_CHANGE_EVENT_LINK_CTA,
  ITEM_ACTIVITY_UNLINK_PROCORE_CHANGE_EVENT,
} from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { ChangeEvent, SourceSystem, SourceType } from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import useUnlinkChangeEventMutation from '../../../../hooks/useUnlinkChangeEvent';
import { formatCost } from '../../../../utilities/currency';
import { capitalizeString } from '../../../../utilities/string';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import { Chip, IconMenuButton } from '../../../scales';

import { getProcoreChangeEventLink } from './IntegrationsUtils';
import ProcoreChangeEventLastUpdated from './ProcoreChangeEventLastUpdated';
import ProcoreSVGLoggedIn from './ProcoreSVGLoggedIn';
import ProcoreUnlinkDialog from './ProcoreUnlinkDialog';

type ProcoreChangeEventComponentProps = {
  canDeleteItemIntegration: boolean;
  changeEvent: ChangeEvent;
  pageLoadTime?: Date;
  sourceID: UUID;
  refetchChangeEvents: () => void;
  setSubmitted: (value: boolean) => void;
  linkedProjectID?: string;
};

const ProcoreChangeEventComponent: FC<ProcoreChangeEventComponentProps> = ({
  canDeleteItemIntegration,
  changeEvent,
  pageLoadTime,
  sourceID,
  refetchChangeEvents,
  setSubmitted,
  linkedProjectID,
}) => {
  const projectID = getProjectIdFromUrl();
  const itemID = getItemIdFromUrl();

  const sendAnalytics = useAnalyticsEventHook();
  const onCompleted = () => {
    sendAnalytics(analyticsEvent(ITEM_ACTIVITY_UNLINK_PROCORE_CHANGE_EVENT));
    setSubmitted(false);
  };

  const [unlinkChangeEvent] = useUnlinkChangeEventMutation({ onCompleted });

  const [isOpen, setIsOpen] = useState(false);

  const changeEventName = `${changeEvent.number} - ${changeEvent.title}${
    changeEvent?.isDeleted ? ' (Deleted)' : ''
  }`;

  const url = getProcoreChangeEventLink(changeEvent.id, linkedProjectID);

  const header = (
    <div className="flex justify-center gap-1 pb-1">
      <div className="h-6 w-6">
        <ProcoreSVGLoggedIn />
      </div>
      <div className="flex items-center justify-center text-type-muted type-body3">
        Procore Change Event
      </div>
    </div>
  );

  const title = (
    <div className="flex w-full">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="ml-auto flex flex-grow type-link"
        onClick={() => sendAnalytics(analyticsEvent(ITEM_ACTIVITY_PROCORE_CHANGE_EVENT_LINK_CTA))}
      >
        {changeEventName}
        <div className="text-base type-link">
          <OpenInNew fontSize="inherit" className="ml-1" />
        </div>
      </a>
    </div>
  );

  const chips = (
    <div className="flex gap-1">
      {changeEvent.cost && <Chip text={formatCost(changeEvent.cost)} />}
      {changeEvent.status && <Chip text={capitalizeString(changeEvent.status)} />}
      {changeEvent.eventType && <Chip text={`Type: ${changeEvent.eventType}`} />}
      <Chip text={`Change Reason: ${changeEvent.changeReason}`} />
      <ProcoreChangeEventLastUpdated onClick={refetchChangeEvents} pageLoadTime={pageLoadTime} />
    </div>
  );

  return (
    <div className="flex w-full flex-col  items-start justify-center p-2">
      <ProcoreUnlinkDialog
        isOpen={isOpen}
        onClose={() => {
          unlinkChangeEvent(
            projectID,
            itemID,
            SourceSystem.PROCORE,
            SourceType.CHANGE_EVENT,
            sourceID,
            {
              number: changeEvent.number,
              title: changeEvent.title,
            }
          );
          setSubmitted(true);
        }}
        setIsOpen={setIsOpen}
        name={changeEventName}
      />

      {header}
      <div className="flex w-full items-start">
        <div className="flex w-full flex-col items-start justify-center">
          {title}

          <div className="mb-2 mt-2 line-clamp-3 w-full self-center whitespace-pre-line type-body3">
            {changeEvent.description}
          </div>
          {chips}
        </div>
        {canDeleteItemIntegration && (
          <IconMenuButton
            aria-label="Edit"
            entries={[{ label: 'Unlink from Item', id: 'unlink', onClick: () => setIsOpen(true) }]}
            icon={<MoreVert />}
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default ProcoreChangeEventComponent;
