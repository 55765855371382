import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import styles from '../../SignupPageStyles';

type EmailVerificationProps = {
  classes: Classes<typeof styles>;
  email: string;
  resendEmail: () => void;
};

const EmailVerification: FC<EmailVerificationProps> = ({ classes, email, resendEmail }) => (
  <>
    <Typography>
      Please go to your inbox for <b>{email}</b> and follow the link in the email we just sent to
      verify your email.
    </Typography>
    <Typography className={`${classes.spacedText} ${classes.subText}`}>
      Don&apos;t see an email after a few moments?&nbsp;
      <button className={classes.underlineText} onClick={resendEmail} type="button">
        Resend email
      </button>
    </Typography>
  </>
);

export default withStyles(styles)(EmailVerification);
