import {
  GetItemContingenciesQuery,
  GetItemContingenciesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getItemContingenciesQuery } from './getItemContingenciesQuery';

export const useGetItemContingenciesQuery = (projectID: UUID, itemID?: UUID, skip = false) =>
  useQuery<GetItemContingenciesQuery, GetItemContingenciesQueryVariables>(
    getItemContingenciesQuery,
    {
      variables: {
        projectID,
        itemID,
      },
      skip: skip || !projectID || !itemID,
    }
  );
