import { forwardRef } from 'react';

import { Dimensions } from './useChartDimensions';

type Props = {
  dimensions: Dimensions;
  children: React.ReactNode;
};
export default forwardRef<HTMLDivElement, Props>(function SVGWithDimensions(props, ref) {
  return (
    <div ref={ref} className="h-full w-full">
      <svg width={props.dimensions.width} height={props.dimensions.height}>
        <g
          transform={`translate(${[props.dimensions.marginLeft, props.dimensions.marginTop].join(
            ','
          )})`}
        >
          {props.children}
        </g>
      </svg>
    </div>
  );
});
