import { FC } from 'react';

import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';

import { IMAGE, MODEL } from '../../../../constants';
import { Maybe } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { fromNow } from '../../../../utilities/dates';
import { prettyBytes } from '../../../../utilities/string';
import { getEntryIsPending } from '../../../DocumentMagic/Files/FilesTable/FilesTableUtils';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import AssetsActionMenu from '../../AssetsActionMenu/AssetsActionMenu';
import { getTruncatedAssetText, getUploadedByText } from '../../utils';

import AssetsCardStyles from './AssetsCardStyles';

type AssetsCardProps = {
  asset: AssetCard;
  bookmark?: Bookmark;
  canDelete?: boolean;
  classes: Classes<typeof AssetsCardStyles>;
  detachAttachment?: (assetID: UUID) => void;
  disabled?: boolean;
  downloadAsset?: (location: string, name: string, id: string) => void;
  onClick?: () => void;
  showMenu: boolean;
  url?: Maybe<string>;
};

const AssetsCard: FC<AssetsCardProps> = ({
  asset,
  bookmark,
  canDelete = false,
  classes,
  detachAttachment,
  disabled = false,
  downloadAsset,
  onClick,
  showMenu,
  url,
}) => {
  const uploadedAsset = asset as UploadedAsset;

  const type = uploadedAsset?.type || null;
  const isPending = getEntryIsPending(uploadedAsset);

  return (
    <div className={classes.container}>
      <NormalTooltip title={getUploadedByText(uploadedAsset.uploadedBy?.name)}>
        <ButtonBase
          data-cy="buttonBase-projectAssetIcon"
          disabled={disabled || isPending}
          className={`${classes.root} ${classes.tile} ${isPending ? classes.pending : ''}`}
          style={
            bookmark || (type === IMAGE && uploadedAsset.blobUrl && !isPending)
              ? {
                  background: `url(${
                    bookmark ? url : uploadedAsset.blobUrl
                  }) center center / cover no-repeat`,
                }
              : undefined
          }
          onClick={onClick}
        >
          {((type === IMAGE && !uploadedAsset.blobUrl) || isPending) && (
            <CircularProgress className={classes.icon} variant="indeterminate" />
          )}
          <div data-cy="div-attachmentItemAsset" className={classes.summaryTitleBookmark}>
            <Typography variant="body2" className={classes.summaryTitle}>
              {getTruncatedAssetText(uploadedAsset, bookmark)}
            </Typography>
            {bookmark || (type === IMAGE && !isPending) ? null : (
              <div>
                <Typography variant="caption" className={classes.metadata}>
                  {isPending ? 'Uploading...' : fromNow(uploadedAsset.lastUpdated)}
                </Typography>
                <Typography variant="caption" className={classes.metadata}>
                  {uploadedAsset?.size && prettyBytes(uploadedAsset.size)}
                </Typography>
              </div>
            )}
            {type === MODEL && url && !disabled && (
              <Typography variant="body2" className={classes.hasUrl}>
                Open Model Viewer
              </Typography>
            )}
          </div>
        </ButtonBase>
      </NormalTooltip>
      {showMenu && !bookmark && (
        <div className={classes.menu}>
          <AssetsActionMenu
            canDelete={canDelete}
            detachAttachment={detachAttachment ? () => detachAttachment(asset.id) : undefined}
            downloadAsset={
              downloadAsset
                ? () => downloadAsset(uploadedAsset.location, uploadedAsset.name, uploadedAsset.id)
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(AssetsCardStyles)(AssetsCard);
