import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCompanyUserQuery } from '../../hooks/useCompanyUserQuery';
import AppFrame from '../AppFrame';

import { requiresWaitForSignupRedirect } from './SignUpLayerUtils';
import useHubspotToken from './useHubspotToken';

const SignUpLayer = () => {
  // COMPANY USER
  const companyUser = useCompanyUserQuery().data?.companyUser;
  useHubspotToken(companyUser?.user?.email);

  // SKIP SIGN UP FLOW (CYPRESS TESTS)
  const skipSignupFlow = localStorage.getItem('skip_signup_flow');
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { wait, url } = requiresWaitForSignupRedirect(companyUser, !!skipSignupFlow);

  useEffect(() => {
    if (window.location.pathname !== url && url) {
      // this will load signup
      navigate(url);
    }
  }, [url, wait, pathname, navigate]);

  if (wait || !companyUser) return null;

  // the regularly scheduled Join Application
  return <AppFrame />;
};

export default SignUpLayer;
