import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectVariant } from '../../../../api/gqlEnumsBe';
import { ProjectType } from '../../../../generated/graphql';
import { useGetProjectsWithType } from '../../../../hooks/useGetProjectsWithType';
import { TreeEntry } from '../../../../utilities/utilities';
import { useToggleProjectTypeVisibilityMutation } from '../../CompanyHooks';
import { getProjectStatsLink } from '../../CompanyTabUtils';
import DeleteProjectSubtypeDialog from '../Dialogs/DeleteProjectSubtypeDialog';
import UpdateProjectSubtypeDialog from '../Dialogs/UpdateProjectSubtypeDialog';

import IconMenuProjectType from './Common/IconMenuProjectType';

type SubTypesListProps = {
  parentType: TreeEntry<ProjectType>;
  projectTypes: ProjectType[];
};

const SubTypesList: FC<SubTypesListProps> = (props) => {
  const [projectType, setProjectType] = useState<ProjectType | null>(null);

  const { data, loading, refetch } = useGetProjectsWithType(projectType?.id);
  const projectsWithType = data?.getProjectsWithType ?? [];
  const templateCount = projectsWithType.filter(
    (x) => x.variant === ProjectVariant.Template
  ).length;
  const toggleProjectTypeVisibility = useToggleProjectTypeVisibilityMutation();
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const onAddSubType = () => {};
  const onEditType = (type: ProjectType) => () => {
    setProjectType(type);
    refetch({ typeID: type.id });
    setIsEditDialogOpen(true);
  };
  const onHideType = (type: ProjectType) => () => {
    toggleProjectTypeVisibility(type.id, true);
  };
  const onShowType = (type: ProjectType) => () => {
    toggleProjectTypeVisibility(type.id, false);
  };
  const onDeleteType = (type: ProjectType) => () => {
    setProjectType(type);
    refetch({ typeID: type.id });
    setIsDeleteDialogOpen(true);
  };

  return (
    <div className="flex w-full flex-col">
      {(props.parentType.entries ?? []).map((subType) => (
        <div className="flex w-full items-center justify-between border-t" key={subType?.id}>
          <div className="flex items-center px-2">
            <div
              className={`flex pl-16 pr-2 type-body1 ${subType?.hidden && 'text-type-inactive'}`}
              data-cy={`collapse-${subType?.name}`}
              key={subType?.name}
            >
              {subType?.name}
            </div>
          </div>
          <div className="flex px-2">
            <div data-cy={`menu-${subType?.name}`}>
              <IconMenuProjectType
                onAddSubType={onAddSubType}
                onEditType={onEditType(subType)}
                onHideType={onHideType(subType)}
                onShowType={onShowType(subType)}
                onDeleteType={onDeleteType(subType)}
                projectType={subType}
              />
            </div>
          </div>
        </div>
      ))}
      {projectType && !loading && (
        <UpdateProjectSubtypeDialog
          isOpen={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setProjectType(null);
          }}
          subType={projectType}
          type={props.parentType}
          types={props.projectTypes}
        />
      )}
      {projectType && !loading && (
        <DeleteProjectSubtypeDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => {
            setIsDeleteDialogOpen(false);
            setProjectType(null);
          }}
          onProjectStats={() => {
            navigate(
              getProjectStatsLink({
                types: [projectType.id],
              })
            );
          }}
          subType={projectType}
          type={props.parentType}
          usedInProjectsCount={projectsWithType.length - templateCount}
          templateCount={templateCount}
        />
      )}
    </div>
  );
};

export default SubTypesList;
