import { FC, useState } from 'react';

import { ToastType } from '../../../api/gqlEnums';
import { AVERAGE, SET_LOCATION_PLACEHOLDER } from '../../../constants';
import { LocationDetails } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { useDontShowConfirm } from '../../Dialogs/DialogsConfirm/DialogsConfirmUtils';
import PlacesAutocompleteWrapper from '../../shared-widgets/PlacesAutocompleteWrapper';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import styles from '../ProjectCompsStyles';

type ProjectCompsInputsLocationProps = {
  averageCompName?: string;
  classes: Classes<typeof styles>;
  isHighlighted?: boolean;
  isLocationAutoEscalationApplied: boolean;
  location: string | null;
  setLocation: (location: EscalationTargetLocation) => void;
};

const ProjectCompsInputsLocation: FC<ProjectCompsInputsLocationProps> = ({
  averageCompName,
  classes,
  isHighlighted,
  isLocationAutoEscalationApplied,
  location,
  setLocation,
}) => {
  const [escalationTarget, setEscalationTarget] = useState<EscalationTargetLocation>({
    location: location || '',
  });

  const confirmationToast = (loc: EscalationTargetLocation) => {
    setToast(
      {
        message: (
          <div>
            <div>{`'${averageCompName}' location changed to ${loc.location}.`}</div>
            <div>Target location changed to {loc.location}.</div>
          </div>
        ),
      },
      ToastType.SUCCESS
    );
  };

  // Location change warning dialog control logic, only show if any projects are auto-escalated:
  const [displayWarning, setDisplayWarning] = useState(false);
  const [wasWarningDisplayed, setWasWarningDisplayed] = useState(false);

  const confirmLocationUpdate = useDontShowConfirm(
    'Average Comp Escalation Location Update Warning',
    () => setDisplayWarning(true),
    () => setDisplayWarning(false)
  );
  const confirmTargetLocationChange = (
    <DialogsConfirm
      acceptCtaCopy="Ok"
      body={
        <div>
          <div className={classes.dialogParagraph}>
            The RSMeans target location will change to match the edited location in the average
            column.
          </div>
          <div className={classes.dialogParagraph}>
            Any projects using RSMeans index to escalate location will also be affected.
          </div>
        </div>
      }
      open={displayWarning && !wasWarningDisplayed && !confirmLocationUpdate.disabled}
      onConfirm={() => {
        setDisplayWarning(false);
        setWasWarningDisplayed(true);
      }}
      onDisable={() => confirmLocationUpdate.setDisabled(true)}
      title="Editing location changes target RSMeans location"
    />
  );

  return (
    <div
      key={location || ''}
      onClick={() => {
        if (isLocationAutoEscalationApplied) setDisplayWarning(true);
      }}
      onKeyDown={() => {
        if (isLocationAutoEscalationApplied) setDisplayWarning(true);
      }}
    >
      <PlacesAutocompleteWrapper
        defaultValue={location || ''}
        isHighlighted={isHighlighted}
        onChange={(locationDetails?: LocationDetails) => {
          if (locationDetails) {
            const newSelectedLocation = {
              location: locationDetails.name,
              lat: locationDetails.lat,
              lon: locationDetails.lon,
            };
            setEscalationTarget(newSelectedLocation);
            if (escalationTarget.location !== newSelectedLocation.location) {
              setLocation(newSelectedLocation);
              if (isLocationAutoEscalationApplied) confirmationToast(newSelectedLocation);
            }
          }
        }}
        placeholder={SET_LOCATION_PLACEHOLDER}
        variant={AVERAGE}
      />
      {confirmTargetLocationChange}
    </div>
  );
};

export default withStyles(styles)(ProjectCompsInputsLocation);
