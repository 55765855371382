import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { COMPANY_PROJECT_STATS } from '../../../constants';
import { SearchResultType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import FilterSummaryText from '../../ProjectsList/FilterSummaryText';
import useSearchProjectsFilterOptionsQuery from '../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  generateFilterTooltipCopy,
} from '../../ProjectsList/ProjectsListUtils';
import { SortManager } from '../../scales';
import CompanyTabSearch from '../CompanyTabSearch';
import CompanyTabStyles from '../CompanyTabStyles';
import { CompanyUserSearchManager, searchProjectStats } from '../CompanyTabUtils';

import CompanyTabProjectStatsTable from './CompanyTabProjectStatsTable';
import ProjectStatsFilterPanel from './ProjectStatsFilterPanel';

type CompanyTabProjectStatsProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyID: UUID;
  searchManager: CompanyUserSearchManager;
  companyProjectStats: CompanyProjectStats[];
  loading: boolean;
  sortManager: SortManager;
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
  projectCount: number | undefined;
  projectCountLoading: boolean;
};

const CompanyTabProjectStats: FC<CompanyTabProjectStatsProps> = ({
  classes,
  companyID,
  companyProjectStats,
  loading,
  sortManager,
  searchManager,
  filterManager,
  setSettings,
  projectCount,
  projectCountLoading,
}) => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  const projectTypes = filterOptionsData?.searchProjectsFilterOptions?.projectTypes || [];

  const { searchTerm } = searchManager;

  const totalProjectCount = !projectCountLoading && projectCount !== undefined ? projectCount : '-';
  const searchedProjectStats = searchProjectStats(companyProjectStats, searchTerm);
  const countSearchedProjectStats = searchedProjectStats?.length ?? 0;

  const normalTooltipCopy = generateFilterTooltipCopy(filterManager.filterState, projectTypes).map(
    (t) => {
      return <div key={t}>{t}</div>;
    }
  );

  const projectStatsTooltip =
    'Project Stats displays projects created by your employees in Join. Projects created by partners will not be displayed here.';

  return (
    <>
      <div className={classes.titleHeader}>
        <Typography className={classes.titleText} variant="title">
          {COMPANY_PROJECT_STATS}
          <NormalTooltip title={projectStatsTooltip}>
            <Help className={classes.tooltip} />
          </NormalTooltip>
        </Typography>
        <Typography className={classes.memberCount}>
          {countSearchedProjectStats} of {totalProjectCount} projects shown
        </Typography>
        <div className={classes.filterTextContainer}>
          <NormalTooltip title={normalTooltipCopy}>
            <div>
              <FilterSummaryText className={classes.filterText} filterManager={filterManager} />
            </div>
          </NormalTooltip>
        </div>
        <div className={classes.filterPanel}>
          <ProjectStatsFilterPanel filterManager={filterManager} setSettings={setSettings} />
        </div>
        <div className={classes.tableSearchBar}>
          <CompanyTabSearch
            searchManager={searchManager}
            variant="projectStats"
            page={CompanyAdminView.PROJECT_STATS_LIST}
          />
        </div>
      </div>
      <CompanyTabProjectStatsTable
        companyID={companyID}
        companyProjectStats={searchedProjectStats}
        loading={loading}
        sortManager={sortManager}
        searchTerm={searchTerm}
      />
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabProjectStats);
