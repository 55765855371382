import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const SCROLL_MARGIN = 300;
const CONTAINER_PADDING = 30;
const EDIT_BUTTON_ICON_SIZE = 32;

export default (theme: KomodoTheme) =>
  createStyles({
    addOptions: {
      paddingLeft: 41, // Fine tuned to match 190-124button-24padding-1border
    },
    card: {
      overflow: 'unset',
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },
    collapseTitle: {
      ...theme.typography.cardHeader,
      backgroundColor: 'transparent',
      fontWeight: 500,
      paddingTop: 2,
    },
    bold: {
      fontWeight: 500,
    },
    costContainer: {
      flexGrow: 1,
    },
    contingencySelect: {
      flexGrow: 1,
      minWidth: 0, // this looks weird, but it's necessary for the text ellipses
    },
    contingencyContainer: {
      margin: 14,
      display: 'flex',
    },
    contingencyInput: {
      ...theme.typography.number,
      textAlign: 'right',
    },
    zeroCostRange: {
      ...theme.typography.number2,
    },
    congingencyButton: {
      display: 'flex',
      cursor: 'pointer',
      height: 32,
      width: 'auto',
      marginLeft: 4,
    },
    costSummaryContainer: {
      paddingBottom: 8,
      paddingTop: 8,
      paddingLeft: CONTAINER_PADDING + 11, // align to expand icon in grid
      paddingRight: CONTAINER_PADDING + 1,
      borderTop: theme.border.lineBorderBlack,
      background: theme.palette.backgroundGrey,
    },
    costImpact: {
      marginLeft: 8,
      marginRight: 4,
    },
    costImpactContainer: {
      display: 'flex',
    },
    contingencyFullDrawCheckbox: {
      width: 24,
      height: 24,
      margin: 4,
    },
    newContingencyIcon: {
      width: 24,
      height: 24,
      margin: 4,
      marginLeft: 4,
    },
    contingencyFullDrawText: {
      fontWeight: 400,
      alignSelf: 'center',
      textWrap: 'nowrap',
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    editButton: {
      height: EDIT_BUTTON_ICON_SIZE,
      width: EDIT_BUTTON_ICON_SIZE,
    },
    editButtonContainer: {
      paddingLeft: 8,
    },
    estimateContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    help: {
      color: theme.palette.joinGrey400,
      marginLeft: 4,
      marginTop: 4,
      fontSize: 16,
    },
    icon: {
      fill: theme.palette.disabledGrey,
      width: 16,
      height: 16,
      marginTop: 4,
      marginLeft: 4,
    },
    optionsButtonContainer: {
      display: 'flex',
    },
    rowContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    schedule: {
      alignItems: 'center',
      display: 'flex',
    },
    scheduleDivider: {
      padding: '0px 12px',
    },
    spacer: {
      flexGrow: 1,
    },
    textPadding: {
      padding: '10px 6px 6px 6px',
    },
  });
