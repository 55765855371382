import { FC } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ITEM_DETAILS } from '../../../constants';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { mapCostModeStringToEnum } from '../../../utilities/costMode';
import { useCurrentUserQuery } from '../../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import EventsItemHistoryList from '../../Events/EventsItemHistoryList/EventsItemHistoryList';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import CommentEntry from '../CommentEntry/CommentEntry';

import styles from './CommentsHistoryStyles';

type CommentsHistoryProps = {
  canComment: boolean;
  classes: Classes<typeof styles>;
  costMode: string;
  itemId: UUID;
  projectId?: UUID;
  triggerOnComplete?: () => void;
  variant: string;
  onItemMutated?: () => void;
};

const CommentsHistory: FC<CommentsHistoryProps> = ({
  canComment,
  classes,
  costMode,
  itemId,
  projectId,
  triggerOnComplete,
  variant,
  onItemMutated,
}) => {
  const user = useCurrentUserQuery()?.data?.currentUser || null;
  const { data: itemData, loading: itemLoading } = useItemQuery(
    itemId,
    MountPolicy.SKIP_ON_MOUNT,
    mapCostModeStringToEnum(costMode)
  );
  const item = itemData?.item;

  return (
    <div className={variant === ITEM_DETAILS ? classes.root : ''}>
      <List>
        <EventsItemHistoryList
          costMode={costMode}
          customProjectId={projectId}
          item={item ?? undefined}
          itemLoading={itemLoading}
          triggerOnComplete={triggerOnComplete}
          variant={variant}
        />
        {canComment && item && (
          <ListItem className={`${classes.commentEntryRoot} ${classes.newComment}`} disableGutters>
            <CommentEntry item={item} user={user} onItemMutated={onItemMutated} />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default withStyles(styles)(CommentsHistory);
