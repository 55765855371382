import { useQuery } from '@apollo/client';

import { changeEventFields, gql } from '../api/graphqlFragments';
import {
  ItemLinkedChangeEventsQuery,
  ItemLinkedChangeEventsQueryVariables,
} from '../generated/graphql';

export const itemLinkedChangeEventsQuery = gql`
  query ItemLinkedChangeEvents($sourceID: UUID!, $projectID: UUID!, $itemID: UUID!) {
    itemLinkedChangeEvents(sourceID: $sourceID, projectID: $projectID, itemID: $itemID) {
      changeEvents {
        ...changeEventFields
      }
      refetchedAt
      error
    }
  }
  ${changeEventFields}
`;

export default function useItemLinkedChangeEventsQuery(
  variables: ItemLinkedChangeEventsQueryVariables,
  onCompleted: (data: ItemLinkedChangeEventsQuery) => void
) {
  return useQuery<ItemLinkedChangeEventsQuery, ItemLinkedChangeEventsQueryVariables>(
    itemLinkedChangeEventsQuery,
    {
      variables: {
        ...variables,
      },
      skip: !variables.itemID || !variables.sourceID || !variables.projectID,
      onCompleted,
      notifyOnNetworkStatusChange: true,
    }
  );
}
