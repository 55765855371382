import { FC, useState } from 'react';

import { Button, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import ReplaceCategorizationConfirmStyles from './ReplaceCategorizationConfirmStyles';

type ReplaceCategorizationConfirmProps = {
  classes: Classes<typeof ReplaceCategorizationConfirmStyles>;
  onSubmit: () => void;
  onBack?: () => void;
};

const ReplaceCategorizationConfirm: FC<ReplaceCategorizationConfirmProps> = ({
  classes,
  onSubmit,
  onBack,
}) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className={classes.innerPaper}>
      <div className={classes.content}>
        <Typography className={`${classes.margin} ${classes.paddingTop}`}>
          Replacing an existing categorization may affect estimates, budgets and items that are
          currently using this categorization.
        </Typography>
        <Typography className={classes.textBold}>
          {' '}
          Please confirm before editing this categorization.{' '}
        </Typography>
        <div className={classes.paddingTop}>
          <Button className={classes.margin} variant="outlined" color="primary" onClick={onBack}>
            Cancel
          </Button>
          <Button
            className={classes.margin}
            variant="contained"
            color="primary"
            onClick={() => {
              if (!submitted) {
                onSubmit();
                setSubmitted(true);
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(ReplaceCategorizationConfirmStyles)(ReplaceCategorizationConfirm);
