import * as d3 from 'd3';
import { FC } from 'react';

import ChartsD3GraphWrapper, { ChartSize } from '../ChartsD3GraphWrapper';

import {
  LineDatum,
  LineGraphData,
  generateAxis,
  generateChartAxis,
  generateChartLines,
} from './ChartsLineGraphUtils';

type ChartsLineGraphProps = {
  chartSize: ChartSize;
  colors: string[];
  data: LineGraphData[] | null;
  formatValue?: (value: number) => string;
  mapData: Map<UUID, LineDatum[]>;
  pointerEntered: (projectID: UUID) => void;
  pointerLeave: () => void;
  selected?: UUID;
};

const ChartsLineGraphGraph: FC<ChartsLineGraphProps> = ({
  chartSize,
  colors,
  data,
  formatValue = (value: number) => value.toString(),
  mapData,
  pointerEntered,
  pointerLeave,
  selected,
}) => {
  if (!data) return <></>;
  const renderChartFn = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    const { scaleX, xAxis, scaleY, yAxis } = generateAxis(data, chartSize);
    generateChartAxis(svg, xAxis, yAxis);
    generateChartLines(
      svg,
      mapData,
      scaleX,
      scaleY,
      colors,
      chartSize,
      formatValue,
      pointerEntered,
      pointerLeave,
      selected
    );
  };

  return (
    <ChartsD3GraphWrapper
      chartSize={chartSize}
      chartDependencies={[chartSize, data, colors]}
      renderChartFn={renderChartFn}
    />
  );
};

export default ChartsLineGraphGraph;
