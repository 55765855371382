import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const KeyboardArrowEnd = (props: IconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
    <line x1="1" y1="20" x2="1" y2="4" strokeWidth="2" stroke="currentColor" />
  </SvgIcon>
);
