import { FC } from 'react';

import { CostReportColumnType } from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import ConfirmFollowing from '../AddToAMilestoneSelection/ConfirmFollowing';

import AddToNewMilestoneConfirmationStyles from './AddToNewMilestoneConfirmationStyles';

type AddToNewMilestoneConfirmationProps = {
  classes: Classes<typeof AddToNewMilestoneConfirmationStyles>;
  milestoneName: string;
  estimateType: CostReportColumnType;
};

const AddToNewMilestoneConfirmation: FC<AddToNewMilestoneConfirmationProps> = ({
  classes,
  milestoneName,
  estimateType,
}) => {
  return (
    <div className={classes.innerContainer}>
      <ConfirmFollowing
        isNewMilestone
        isNoCurrentEstimate
        milestoneName={milestoneName}
        type={estimateType}
      />
    </div>
  );
};

export default withStyles(AddToNewMilestoneConfirmationStyles)(AddToNewMilestoneConfirmation);
