import { useMemo } from 'react';

import { ItemsSortKey } from '../../../../generated/graphql';
import { SortManager, Table } from '../../../scales';
import { ScenarioItemEntry } from '../../common/ItemStatus/ItemStatusUtils';

import CostCell from './cells/CostCell';
import NameCell from './cells/NameCell';
import StatusCellChanged from './cells/StatusCell/StatusCellChanged';
import { getScenarioItemTitle } from './ItemsTableUtils';

type Props = {
  itemEntries: ScenarioItemEntry[];
  sortManager?: SortManager;
};

enum ScenarioTableColumns {
  INDICATOR = 'indicator',
  TITLE = 'title',
  COST = 'cost',
  STATUS = 'status',
}

const ItemsTableView = (props: Props) => {
  // Content
  const headerContent = [
    {
      key: ScenarioTableColumns.TITLE,
      headerSortKey: ItemsSortKey.SORT_NUMBER,
      copy: `Items (${props.itemEntries.length})`,
    },
    {
      key: ScenarioTableColumns.COST,
      headerSortKey: ItemsSortKey.SORT_COST_IMPACT,
      copy: 'Cost Impact',
      rightAligned: true,
    },
    {
      key: ScenarioTableColumns.STATUS,
      headerSortKey: ItemsSortKey.SORT_STATUS,
      copy: 'Status',
    },
  ];

  const entries = useMemo(
    () =>
      props.itemEntries.map((itemEntry) => [
        {
          component: <NameCell showClamped title={getScenarioItemTitle(itemEntry)} />,
          key: ScenarioTableColumns.TITLE,
        },
        {
          component: <CostCell cost={itemEntry.cost} />,
          key: ScenarioTableColumns.COST,
        },
        {
          component: <StatusCellChanged item={itemEntry} />,
          key: ScenarioTableColumns.STATUS,
        },
      ]),
    [props.itemEntries]
  );

  return (
    <Table
      columnWidths={['minmax(200px,6fr)', 'max-content', 'max-content']}
      compact
      entries={entries}
      headerContent={headerContent}
      sortManager={props.sortManager}
    />
  );
};

export default ItemsTableView;
