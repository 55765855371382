import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { createStyles, withStyles } from '@material-ui/core';

import {
  ONBOARDING_STEP1_CTA,
  ONBOARDING_STEP2_CTA,
  ONBOARDING_STEP3_CTA,
  ONBOARDING_STEP4_CTA,
} from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  IMPORT_ESTIMATE_DEFAULT,
  getImportEstimateVar,
  helpTipTrackerVar,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { EstimateType, ImportModal, JoinProjectRoutes } from '../../../api/gqlEnums';
import {
  HELP_TIP_TRACKER,
  IMPORT_ESTIMATE_ACTIVE,
  IMPORT_ESTIMATE_BUDGET,
  MANUAL,
  TYPE,
} from '../../../constants';
import { UserEventType } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { clickLinkKeyHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import JoinDialogMulti from '../../Dialogs/JoinDialog/JoinDialogMulti';
import { JoinDialogType } from '../../Dialogs/JoinDialog/JoinDialogUtils';
import { useImportEstimateTimer } from '../../PerfMonitor/utils';
import CategorizationSelectAndOrder from '../Modals/CategorizationSelectAndOrder/CategorizationSelectAndOrder';
import CategorizationSelectAndOrderHeader from '../Modals/CategorizationSelectAndOrder/CategorizationSelectAndOrderHeader';
import CategorizationsMap from '../Modals/CategorizationsMap/CategorizationsMap';
import EstimateTotalTypeSelect from '../Modals/EstimateTotalTypeSelect/EstimateTotalTypeSelect';
import ImportBuiltInTooltip from '../Modals/ImportBuiltInTooltip/ImportBuiltInTooltip';
import ImportMapTooltip from '../Modals/ImportMapTooltip/ImportMapTooltip';
import NewCategorizationsBuiltInMap from '../Modals/NewCategorizationsBuiltnMap/NewCategorizationsBuiltinMap';
import {
  BuiltInRadioButtonOptions,
  IncludeCategorizationTip,
  NewCategorizationBuiltinMapText,
} from '../Modals/NewCategorizationsBuiltnMap/NewCategorizationsBuiltinMapUtils';
import { getImportModal, useBuiltinToggles } from '../utils';

import useFinalizeImportEstimate from './hooks/FinalizeImportEstimateHook';

type ImportManagerProps = {
  classes: Classes<typeof styles>;
  estimateType: EstimateType;
};

const styles = () =>
  createStyles({
    dialogClass: {
      width: '916px',
    },
  });

const ImportManager: FC<ImportManagerProps> = ({ classes, estimateType }) => {
  const navigate = useNavigate();
  const sendAnalytics = useAnalyticsEventHook();
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const { hasMasterFormat, hasUniFormat, hasNoBuiltIns } = useBuiltinToggles();

  const showMapUfMfModal = !hasNoBuiltIns;

  const helpTipTrackerInfo = useReactiveVar(helpTipTrackerVar);

  const contentHeader1include = `Now let’s keep what you need and skip the clutter.`;
  const contentHeader2include = (count: number) => {
    return <CategorizationSelectAndOrderHeader count={count} />;
  };

  const contentHeader1wbs = 'Almost there!';
  const contentHeader2wbs =
    'Match your WBS Codes to an existing or new Category in Join. Select the Category you want in the dropdown menu below.';

  // State
  const [loading, setLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState<number | null>(null);
  const [radioBuiltInSelection, updateRadioBuiltInSelection] = useState(
    BuiltInRadioButtonOptions.ENABLED
  );

  const importEstimateVar = getImportEstimateVar(estimateType);

  const importEstimateKey =
    estimateType === EstimateType.ACTIVE_ESTIMATE ? IMPORT_ESTIMATE_ACTIVE : IMPORT_ESTIMATE_BUDGET;

  const importEstimateParams = useReactiveVar(importEstimateVar);

  const { id, categorizations, mapping, mappingBuiltIn, selectedTotalType, numberOfLines } =
    importEstimateParams;
  const categorizationIsIncluded = categorizations.find(({ include }) => include === true);
  const modal = getImportModal(importEstimateParams);

  // Functions
  const closeResetModal = () =>
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimateParams,
      modal: showMapUfMfModal ? ImportModal.MAP_UF_MF : ImportModal.INCLUDE,
      modalIsOpen: false,
    });

  const openModal = (m: ImportModal) => {
    const userDisabledBuiltIns = radioBuiltInSelection === BuiltInRadioButtonOptions.DISABLED;
    let updatedCategorizations = categorizations;
    // if the user disabled builtins, reset any categorizations that were mapped to a builtin, the existingCategorization and level properties
    // performing this update when the user changes modals allows them to renable the builtins without losing their current selections
    if (userDisabledBuiltIns) {
      updatedCategorizations = categorizations.map((categorization) => {
        const updatedCategorization = categorization;
        updatedCategorization.existingCategorization = '';
        updatedCategorization.categoryUpdates = { newCategories: [], categoryReplacements: [] };
        updatedCategorization.level = 1;

        return updatedCategorization;
      });
    }
    const mapping = new Map<string, string>();
    importEstimateParams.categorizations.forEach((c) => {
      if (c.existingCategorization) mapping.set(c.name, c.existingCategorization);
    });

    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimateParams,
      categorizations: updatedCategorizations,
      modal: m,
      mappingBuiltIn: userDisabledBuiltIns ? new Map() : mappingBuiltIn,
      mapping,
    });
  };

  const openMapUfMfModal = () => {
    openModal(ImportModal.MAP_UF_MF);
    sendAnalytics(analyticsEvent(ONBOARDING_STEP1_CTA));
  };

  const openIncludeModal = () => {
    openModal(ImportModal.INCLUDE);
    sendAnalytics(analyticsEvent(ONBOARDING_STEP2_CTA));
  };

  const openMapWbsModal = () => {
    openModal(ImportModal.MAP_WBS);
    sendAnalytics(analyticsEvent(ONBOARDING_STEP3_CTA));
  };

  const openTotalTypeModal = () => {
    openModal(ImportModal.TOTAL_TYPE);
    sendAnalytics(analyticsEvent(ONBOARDING_STEP4_CTA));
  };

  // this function updates the dialogs based on if we need to showMapUfMfModal
  const prepareDialogs = (showMapUfMfModal: boolean, dialogs: JoinDialogType[]) => {
    const addMapUfMfModal = (dialogs: JoinDialogType[]) => {
      const mapUfMfModal = {
        open: modal === ImportModal.MAP_UF_MF,
        onOpen: () => openModal(ImportModal.MAP_UF_MF),
        onClose: () => cancel(),
        onNext: openIncludeModal,
        onBack: () => cancel(),
        contentHeader1: NewCategorizationBuiltinMapText.ContentHeader1,
        contentHeader2: NewCategorizationBuiltinMapText.ContentHeader2,
        contentComponent: (
          <NewCategorizationsBuiltInMap
            radioBuiltInSelection={radioBuiltInSelection}
            updateRadioBuiltInSelection={updateRadioBuiltInSelection}
            importEstimateVar={importEstimateVar}
            importEstimateKey={importEstimateKey}
            hasMasterFormat={hasMasterFormat}
            hasUniFormat={hasUniFormat}
          />
        ),
        tooltip: <ImportBuiltInTooltip />,
      };
      return [mapUfMfModal, ...dialogs];
    };

    const updateDialogs = (dialogs: JoinDialogType[], showMapUfMfModal: boolean) => {
      const preparedDialogs = showMapUfMfModal ? addMapUfMfModal(dialogs) : dialogs;

      const importModalIncludeIndex = showMapUfMfModal ? 1 : 0;
      const importModalMapWbsIndex = importModalIncludeIndex + 1;
      const importModalTotalTypeIndex = importModalMapWbsIndex + 1;

      if (showMapUfMfModal) {
        preparedDialogs[importModalIncludeIndex].onBack = openMapUfMfModal;
      }

      preparedDialogs[importModalIncludeIndex].onNext = openMapWbsModal;
      preparedDialogs[importModalMapWbsIndex].onNext = openTotalTypeModal;
      preparedDialogs[importModalTotalTypeIndex].onBack = openMapWbsModal;

      return preparedDialogs;
    };

    return updateDialogs(dialogs, showMapUfMfModal);
  };

  const [finalizeImportEstimate] = useFinalizeImportEstimate();
  const finalize = () => {
    if (loading || !id) return;

    setLoading(true);
    const start = window.performance.now();

    finalizeImportEstimate(
      projectId,
      id,
      categorizations,
      estimateType,
      selectedTotalType,
      numberOfLines,
      mapping,
      mappingBuiltIn,
      (estimateId: UUID) => {
        setLoading(false);
        setElapsedTime(start ? window.performance.now() - start : null);
        closeResetModal();
        const path = generateSharedPath(JoinProjectRoutes.IMPORT_ESTIMATE, {
          projectId,
          milestoneId,
          estimateId,
        });
        clickLinkKeyHandler(
          navigate,
          undefined,
          path,
          `${TYPE}=${estimateType}&${MANUAL}=${false}`
        );
        setReactiveLocal(importEstimateVar, importEstimateKey, IMPORT_ESTIMATE_DEFAULT);
        if (helpTipTrackerInfo.estimateImportCount <= 3) {
          setReactiveLocal(
            helpTipTrackerVar,
            HELP_TIP_TRACKER,
            {
              estimateImportCount: helpTipTrackerInfo.estimateImportCount + 1,
              importedEstimateIDs: [...helpTipTrackerInfo.importedEstimateIDs, estimateId],
            },
            false
          );
        }
      },
      () => {
        setLoading(false);
        closeResetModal();
        setReactiveLocal(importEstimateVar, importEstimateKey, IMPORT_ESTIMATE_DEFAULT);
      }
    );
  };

  useImportEstimateTimer(
    id,
    UserEventType.IMPORT_ESTIMATE_CREATE_ESTIMATE_FINISH,
    elapsedTime,
    setElapsedTime
  );

  const cancel = () => {
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimateParams,
      modalIsOpen: false,
    });
  };

  // Componentry
  const dialogs: JoinDialogType[] = [
    {
      open: modal === ImportModal.INCLUDE,
      onOpen: () => openModal(ImportModal.INCLUDE),
      onClose: () => cancel(),
      onNext: openMapWbsModal,
      onBack: () => cancel(),
      contentHeader1: contentHeader1include,
      contentHeader2: contentHeader2include(categorizations.length),
      contentComponent: (
        <CategorizationSelectAndOrder
          importEstimateVar={importEstimateVar}
          importEstimateKey={importEstimateKey}
        />
      ),
      disabledNext: !categorizationIsIncluded,
      hoverTipText: !categorizationIsIncluded ? IncludeCategorizationTip : '',
    },
    {
      open: modal === ImportModal.MAP_WBS,
      onOpen: () => openModal(ImportModal.MAP_WBS),
      onClose: () => cancel(),
      onNext: () => openTotalTypeModal,
      onBack: openIncludeModal,
      contentHeader1: contentHeader1wbs,
      contentHeader2: contentHeader2wbs,
      dialogClass: classes.dialogClass,
      contentComponent: (
        <CategorizationsMap
          importEstimateVar={importEstimateVar}
          importEstimateKey={importEstimateKey}
        />
      ),
      tooltip: <ImportMapTooltip />,
    },
    {
      open: modal === ImportModal.TOTAL_TYPE,
      onOpen: () => openModal(ImportModal.TOTAL_TYPE),
      onClose: () => cancel(),
      onNext: () => finalize(),
      onBack: openMapWbsModal,
      loading,
      contentHeader1: 'Choose what level of cost detail to Import into Join.',
      contentComponent: (
        <EstimateTotalTypeSelect
          importEstimateVar={importEstimateVar}
          importEstimateKey={importEstimateKey}
        />
      ),
    },
  ];

  return (
    <JoinDialogMulti
      disablePageCounter
      hasLinearProgress
      dialogs={prepareDialogs(showMapUfMfModal, dialogs)}
    />
  );
};

export const StyledImportManager = withStyles(styles)(ImportManager);

export default StyledImportManager;
