import { titleCase } from 'title-case';

import { TermKey } from '../../api/gqlEnums';
import { ITEM_OPTION_DIRECT_COST, TEAM } from '../../constants';
import {
  PermissionDescription,
  PermissionGroupType,
  PermissionLevel,
  PermissionResource,
} from '../../generated/graphql';

// TODO: Use enums
export const settings = (isPermissionRolesCreateDeleteFeature: boolean) => ({
  labels: ['NONE', 'VIEW_ONLY', 'STANDARD', 'ADMIN'],
  resource: {
    MILESTONE_LINES: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    MILESTONE_BUDGET: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_IN_TRADE_LINES: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_OUT_OF_TRADE_LINES: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    [PermissionResource.SCHEDULE_IMPACT]: [
      false,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_EDIT,
      PermissionDescription.CAN_VIEW_EDIT_CHANGE_DISPLAY,
    ],
    [PermissionResource.DRAW_EVENTS_VIEW]: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
    [PermissionResource.ITEM_INTEGRATIONS]: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD_DELETE,
      false,
    ],
    [PermissionResource.SHARE_ITEMS_SCENARIOS]: [
      false,
      false,
      PermissionDescription.CAN_SHARE_PERSONAL,
      PermissionDescription.CAN_SHARE_ANY,
    ],
    [PermissionResource.PUBLISH_ITEMS_SCENARIOS]: [
      false,
      false,
      PermissionDescription.CAN_PUBLISH_PERSONAL,
      PermissionDescription.CAN_PUBLISH_ANY,
    ],
    [PermissionResource.REPORT_DISTRIBUTION]: [
      PermissionDescription.CANNOT_VIEW,
      false,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    MARKUPS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    GROSS_VIEW: [PermissionDescription.CANNOT_VIEW, PermissionDescription.CAN_VIEW, false, false],
    NET_VIEW: [PermissionDescription.CANNOT_VIEW, PermissionDescription.CAN_VIEW, false, false],
    NO_MARKUPS_VIEW: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
    ALLOCATED_MARKUPS_VIEW: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
    SEPARATED_MARKUPS_VIEW: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
    PROJECT_DETAILS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    COLLABORATORS: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    PERMISSION_TEMPLATES: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      isPermissionRolesCreateDeleteFeature
        ? PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE
        : PermissionDescription.CAN_VIEW_EDIT_COLUMNS,
    ],
    CATEGORIES_AND_TAGS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    MILESTONES: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE_EDIT_CURRENT_MILESTONE,
    ],
    MILESTONE_ATTACHMENTS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD,
      PermissionDescription.CAN_VIEW_ADD_DELETE,
    ],
    DRAFT_MILESTONE: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_EDIT_DELETE,
    ],
    DRAFT_ESTIMATE: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_EDIT_DELETE,
    ],
    METRICS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    MEETINGS: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_LIST: [false, PermissionDescription.CAN_VIEW, false, false],
    ITEM_DETAILS: [
      false,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD_EDIT_CREATED_OR_ASSIGNED,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_MILESTONE_AND_MEETINGS: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_CATEGORIES: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_ASSIGNEES: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ITEM_STATUS: [
      false,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_EDIT,
      false,
    ],
    PROJECT_TERMINOLOGY: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_EDIT,
    ],
    PROJECT_UNITS: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    PROJECT_CURRENCY: [
      false,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_EDIT,
    ],
    ITEM_ATTACHMENTS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD,
      PermissionDescription.CAN_VIEW_ADD_DELETE,
    ],
    ITEM_COMMENTS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD,
      false,
    ],
    ITEM_TEMPLATE: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      PermissionDescription.CAN_VIEW_EDIT_COLUMNS,
    ],
    USER_REPORT_NOTES: [
      false,
      false,
      PermissionDescription.CAN_VIEW_ADD_EDIT,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    TIMELINE: [
      false,
      PermissionDescription.CAN_VIEW,
      PermissionDescription.CAN_VIEW_ADD,
      PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE,
    ],
    ESTIMATE_COST_SUBTOTALS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
    DISPLAY_COSTS: [
      PermissionDescription.CANNOT_VIEW,
      PermissionDescription.CAN_VIEW,
      false,
      false,
    ],
  },
});

export const getTextMapForPermissions =
  (termStore: TermStore) =>
  (
    permissionKey:
      | string
      | PermissionDescription
      | PermissionGroupType
      | PermissionLevel
      | PermissionResource,
    isScenarioSharingFeature?: boolean,
    isPermissionRolesCreateDeleteFeature?: boolean
  ) => {
    switch (permissionKey) {
      // Description
      case PermissionDescription.CAN_VIEW_ADD_EDIT_DELETE:
        return 'Can view, add, edit, delete';
      case PermissionDescription.CAN_VIEW_EDIT_DELETE:
        return 'Can view, edit, delete';
      case PermissionDescription.CAN_VIEW_ADD_DELETE:
        return 'Can view, add, delete';
      case PermissionDescription.CAN_VIEW_ADD_EDIT:
        return 'Can view, add, edit';
      case PermissionDescription.CAN_VIEW_ADD:
        return 'Can view, add';
      case PermissionDescription.CAN_VIEW_EDIT_COLUMNS:
        return 'Can view, edit columns';
      case PermissionDescription.CAN_VIEW_EDIT_PREVIEW:
        return 'Can view, edit, and preview permissions';
      case PermissionDescription.CAN_VIEW_EDIT:
        return 'Can view, edit';
      case PermissionDescription.CANNOT_VIEW:
        return 'Cannot view';
      case PermissionDescription.CAN_VIEW:
        return 'Can view';
      case PermissionDescription.CAN_VIEW_EDIT_CHANGE_DISPLAY:
        return 'Can view, edit, change display';
      case PermissionDescription.CAN_PUBLISH_ANY:
        return isScenarioSharingFeature
          ? 'Can publish draft items/scenarios  shared with you and ones you created'
          : 'Can publish draft items shared with you and ones you created';
      case PermissionDescription.CAN_SHARE_ANY:
        return isScenarioSharingFeature
          ? 'Can share draft items/scenarios shared with you and ones you created'
          : 'Can share draft items shared with you and ones you created';
      case PermissionDescription.CAN_PUBLISH_PERSONAL:
        return isScenarioSharingFeature
          ? 'Can publish draft items/scenarios  you created'
          : 'Can publish draft items you created';
      case PermissionDescription.CAN_SHARE_PERSONAL:
        return isScenarioSharingFeature
          ? 'Can share draft items/scenarios you created'
          : 'Can share draft items you created';
      // Group
      case PermissionGroupType.COST:
        return 'Cost';
      case PermissionGroupType.PROJECT:
        return 'Project';
      case PermissionGroupType.MILESTONE:
        return 'Milestone';
      case PermissionGroupType.ITEM_IN_TRADE:
        return 'Item and option';
      case PermissionGroupType.ITEM_OUT_OF_TRADE:
        return 'Item and option';
      // Level
      case PermissionLevel.ADMIN:
        return 'Admin';
      case PermissionLevel.NONE:
        return 'None';
      case PermissionLevel.STANDARD:
        return 'Standard';
      case PermissionLevel.VIEW_ONLY:
        return 'View Only';
      // Resource
      case PermissionResource.MILESTONE_LINES:
        return `Milestone ${termStore.lowerCase(TermKey.ESTIMATE)} ${termStore.lowerCase(
          TermKey.DIRECT_COST
        )} details`;
      case PermissionResource.MILESTONE_BUDGET:
        return `Milestone target ${termStore.lowerCase(TermKey.TARGET)} details`;
      case PermissionResource.PROJECT_DETAILS:
        return 'Project details';
      case PermissionResource.COLLABORATORS:
        return titleCase(TEAM);
      case PermissionResource.PERMISSION_TEMPLATES:
        return isPermissionRolesCreateDeleteFeature
          ? 'Project roles and permissions'
          : 'Permission templates';
      case PermissionResource.CATEGORIES_AND_TAGS:
        return 'Categories';
      case PermissionResource.PROJECT_TERMINOLOGY:
        return 'Project terminology';
      case PermissionResource.USER_REPORT_NOTES:
        return 'Report notes';
      case PermissionResource.PROJECT_UNITS:
        return 'Project units of measure';
      case PermissionResource.PROJECT_CURRENCY:
        return 'Project currency / Rounding display';
      case PermissionResource.PROJECT_PRIORITIES:
        return 'Project priorities';
      case PermissionResource.MILESTONES:
        return 'Milestones';
      case PermissionResource.MILESTONE_ATTACHMENTS:
        return 'Milestone attachments';
      case PermissionResource.DRAFT_MILESTONE:
        return 'Draft milestones (other users)';
      case PermissionResource.DRAFT_ESTIMATE:
        return 'Draft estimates (other users)';
      case PermissionResource.METRICS:
        return 'Metrics';
      case PermissionResource.MEETINGS:
        return 'Meetings';
      case PermissionResource.GROSS_VIEW:
        return `Without ${termStore.lowerCase(TermKey.MARKUP)}`;
      case PermissionResource.NET_VIEW:
        return `With ${termStore.lowerCase(TermKey.MARKUP)}`;
      case PermissionResource.ITEM_LIST:
        return 'Items list';
      case PermissionResource.ITEM_DETAILS:
        return 'Items, item import, and options';
      case PermissionResource.ITEM_MILESTONE_AND_MEETINGS:
        return 'Milestones and events';
      case PermissionResource.ITEM_CATEGORIES:
        return 'Categories';
      case PermissionResource.ITEM_ASSIGNEES:
        return 'Assignees';
      case PermissionResource.ITEM_STATUS:
        return 'Status';
      case PermissionResource.ITEM_ATTACHMENTS:
        return 'Attachments';
      case PermissionResource.ITEM_PRIORITIES:
        return 'Item priorities';
      case PermissionResource.ITEM_COMMENTS:
        return 'Comments';
      case PermissionResource.ITEM_IN_TRADE_LINES:
        return `Item/option ${termStore.lowerCase(
          TermKey.DIRECT_COST
        )} details (with category permissions)`;
      case PermissionResource.ITEM_OUT_OF_TRADE_LINES:
        return `Item/option ${termStore.lowerCase(
          TermKey.DIRECT_COST
        )} details (without category permissions)`;
      case ITEM_OPTION_DIRECT_COST:
        return `Item/option ${termStore.lowerCase(TermKey.DIRECT_COST)} details`;
      case PermissionResource.MARKUPS:
        return `${termStore.sentenceCase(TermKey.ESTIMATE)} ${termStore.lowerCase(
          TermKey.MARKUP
        )} details`;
      case PermissionResource.ITEM_TEMPLATE:
        return 'Item estimate template';
      case PermissionResource.ALLOCATED_MARKUPS_VIEW:
        return `Show ${termStore.lowerCase(TermKey.MARKUP)} allocated`;
      case PermissionResource.SEPARATED_MARKUPS_VIEW:
        return `Show ${termStore.lowerCase(TermKey.MARKUP)} separated`;
      case PermissionResource.NO_MARKUPS_VIEW:
        return `${termStore.sentenceCase(TermKey.DIRECT_COST)} only`;
      case PermissionResource.SCHEDULE_IMPACT:
        return 'Schedule impact';
      case PermissionResource.TIMELINE:
        return 'Timeline';
      case PermissionResource.ESTIMATE_COST_SUBTOTALS:
        return 'Estimate cost subtotals';
      case PermissionResource.DRAW_EVENTS_VIEW:
        return 'Draw activity';
      case PermissionResource.ITEM_INTEGRATIONS:
        return 'Item integration activity';
      case PermissionResource.SHARE_ITEMS_SCENARIOS:
        return isScenarioSharingFeature ? 'Share draft items/scenarios' : 'Share draft items';
      case PermissionResource.PUBLISH_ITEMS_SCENARIOS:
        return isScenarioSharingFeature ? 'Publish draft items/scenarios' : 'Publish draft items';
      case PermissionResource.REPORT_DISTRIBUTION:
        return 'Report distribution';
      case PermissionResource.DISPLAY_COSTS:
        return 'Ability to view cost';
      default:
        return null;
    }
  };
