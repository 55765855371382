import { FC, useCallback, useEffect, useState } from 'react';

import { TimelineEvent, timelineEvent } from '../../analytics/analyticsEventProperties';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import { CREATE } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { getTodayAtUtcNoon } from '../../utilities/dates';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../utilities/url';
import { usePersistentStates } from '../../utilities/urlState';
import { TimelineData } from '../shared-widgets/TimelineChart/timeline/timeline.types';
import TimelineChart from '../shared-widgets/TimelineChart/TimelineChart';
import { getRangeExtendedStr } from '../shared-widgets/TimelineChart/TimelineChartUtils';
import useMemoWrapper from '../useMemoWrapper';

import { useItemsTimeline } from './hooks/ItemsHook';
import { useTimelineQuery } from './hooks/TimelineHook';
import { Activity, ActivityNode } from './Timeline.types';
import TimelineActivityModal from './TimelineActivityModal';
import TimelineHeader from './TimelineHeader';
import TimelineTable from './TimelineTable';
import {
  computeExpandable,
  computeExpandableMap,
  computeItems,
  filterChildren,
  getExpandSettings,
  getTimelineUrlConfig,
  tableNodeToTimelineData,
} from './TimelineUtils';

const DATE_STR_INIT = '0';

const ACTIVITIES_DEFAULT = [
  TimelineActivityType.EVENT,
  TimelineActivityType.PHASE,
  TimelineActivityType.MILESTONE,
  TimelineActivityType.ACTIVE_MILESTONE,
];

const Timeline: FC = () => {
  const projectID = getProjectIdFromUrl();

  const [settings, setSettings] = usePersistentStates(...getTimelineUrlConfig(projectID));

  // Activities
  const [activityFilter, setActivityFilter] = useState<TimelineActivityType[]>(ACTIVITIES_DEFAULT);
  const {
    data: recentData,
    loading: timelineLoading,
    previousData,
  } = useTimelineQuery({ projectID, types: activityFilter });
  const data = recentData || previousData;
  const activities = data?.timeline?.activities ?? [];
  const expandableActivities = useMemoWrapper(computeExpandable, activities);

  const sendAnalytics = useAnalyticsEventHook();

  const { data: itemsData, loading: itemsListLoading } = useItemsTimeline();
  const itemsList = itemsData?.itemsList?.items ?? [];

  const today = getTodayAtUtcNoon();
  const itemsListAll = itemsList;
  const items = useMemoWrapper(computeItems, itemsListAll, today);
  const withoutDueDateCount = itemsListAll.length - items.length;

  const loading = timelineLoading || itemsListLoading;

  // Min and Max date range values for zoom domain and pickers
  const [range, setRange] = useState<string[]>([DATE_STR_INIT, DATE_STR_INIT]);
  const [startStr, setStartStr] = useState<string>(DATE_STR_INIT);
  const [endStr, setEndStr] = useState<string>(DATE_STR_INIT);
  const rangeDate = [new Date(range[0]), new Date(range[1])];
  const startDate = new Date(startStr);
  const endDate = new Date(endStr);

  // Expand states
  const { collapse = [], expand = [] } = settings ?? {};
  const expandedMap = useMemoWrapper(computeExpandableMap, collapse, expand);

  const tree = useMemoWrapper(buildTree, activities);

  const [timelineActivityModal, setTimelineActivityModal] = useState({
    activity: undefined,
    open: false,
    type: CREATE,
  });

  // Permissions
  const { canEdit, canAdd, canDelete } = usePermissions();

  const canAddEvent = canAdd(PermissionResource.TIMELINE);
  const canEditEvent = canEdit(PermissionResource.TIMELINE);
  const canDeleteEvent = canDelete(PermissionResource.TIMELINE);

  const mapChildren = (a: ActivityNode): TimelineData => ({
    id: a.id,
    name: a.name,
    type: a.type,
    start: a.startDate,
    end: a.endDate ?? undefined,
    children: a.children.length === 0 ? undefined : a.children.map(mapChildren),
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const computeTimelineData = (tree: any) => tree.map(mapChildren);
  const timelineData: TimelineData[] = useMemoWrapper(computeTimelineData, tree);

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  useEffect(() => {
    if (loading) return;
    const domain = getRangeExtendedStr(timelineData, items, today);
    setStartEnd(domain);
    setRange(domain);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [data, loading]);

  const onZoom = (domain: string[]) => setStartEnd(domain);

  const setZoomInNode = (a: ActivityNode) => {
    const td = tableNodeToTimelineData(a);
    const domain = getRangeExtendedStr([td]);
    setStartEnd(domain);
  };

  const onExpand = useCallback(
    (id: UUID) => {
      expandedMap[id] = !expandedMap[id];
      const [newCollapse, newExpand] = getExpandSettings(expandedMap);
      setSettings({ collapse: newCollapse, expand: newExpand });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [collapse, expand, expandedMap]
  );

  const onAnalytics = (event: TimelineEvent) => {
    sendAnalytics(timelineEvent(event, { source: 'timeline' }));
  };

  if (!data || startStr === DATE_STR_INIT || itemsListLoading) return null;

  const header = (
    <TimelineHeader
      activities={activityFilter}
      canCreate={canAddEvent}
      endDate={endDate}
      expandableActivities={expandableActivities}
      rangeDate={rangeDate}
      setActivities={setActivityFilter}
      setEndStr={setEndStr}
      setStartStr={setStartStr}
      setTimelineActivityModal={setTimelineActivityModal}
      startDate={startDate}
    />
  );

  const chart = (
    <TimelineChart
      activities={activities}
      data={timelineData}
      items={items}
      endDate={endStr}
      expandedMap={expandedMap}
      maxHeight={400}
      onAnalytics={onAnalytics}
      onExpand={onExpand}
      onZoom={onZoom}
      settings={settings}
      startDate={startStr}
      today={today}
      withoutDueDateCount={withoutDueDateCount}
      zoomLineCompressed
    />
  );

  const table = (
    <TimelineTable
      activities={tree}
      canEdit={canEditEvent && canDeleteEvent}
      expandedMap={expandedMap}
      onExpand={onExpand}
      setModal={setTimelineActivityModal}
      setZoomIn={setZoomInNode}
    />
  );

  return (
    <div className="bg-background-primary">
      {activities && (
        <TimelineActivityModal
          activity={timelineActivityModal.activity}
          activities={activities}
          open={timelineActivityModal.open}
          onClose={() =>
            setTimelineActivityModal({ activity: undefined, open: false, type: CREATE })
          }
          type={timelineActivityModal.type as 'add' | 'edit' | 'delete'}
          types={activityFilter}
        />
      )}
      {header}
      {chart}
      {table}
    </div>
  );
};

export const buildTree = (activities: Activity[]) => {
  const indices = new Map<UUID, number>();
  const parents = new Map<UUID, number>();

  activities.forEach((a, i) => {
    indices.set(a.id, i);
    a.children.forEach((id) => parents.set(id, i));
  });

  const roots: number[] = [];
  activities.forEach((a, i) => {
    if (!parents.has(a.id)) roots.push(i);
  });

  const mapChildren =
    (depth: number, parent: UUID) =>
    (childID: UUID): ActivityNode => {
      const activity = activities[indices.get(childID)!];
      return {
        ...activity,
        children: filterChildren(activity.children, activities)
          .map(mapChildren(depth + 1, parent))
          .filter((a) => a !== null),
        depth,
        parent,
      };
    };

  const tree = roots.map((i) => {
    const activity = activities[i];
    const depth = 0;
    return {
      ...activity,
      children: filterChildren(activity.children, activities)
        .map(mapChildren(depth + 1, activity.id))
        .filter((a) => a !== null),
      depth,
    };
  });

  return tree;
};

export default Timeline;
