import { ComponentProps, Fragment, ReactNode, useState } from 'react';

import { Launch, StraightenOutlined, ThumbDown, ThumbUp } from '@material-ui/icons';

import { formatCost } from '../../../utilities/currency';
import { percentFormatterSimple } from '../../../utilities/string';
import { isCostRange, isCostScalar } from '../../CostReport/CostReportUtils';
import Calculate from '../../Icons/Calculate';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { TipTapTextAreaVariant } from '../../Inputs/InputsTextAreaStyled/TipTapTextArea/TipTapTextArea';
import { SpinnerIcon } from '../../Nav/icons';
import { Button, Callout, Chip, Dialog, DialogContent, Table, TableComponents } from '../../scales';
import suggestions from '../suggestions';

type Suggestion = (typeof suggestions)[number];

type Props = {
  isCreatingItem: boolean;
  onClose: () => void;
  onCreate: () => void;
  onDismiss: () => void;
  onViewEstimate: () => void;
  projectCost: Cost;
  suggestion: Suggestion;
};

export default function SuggestionDialog(props: Props) {
  const [view, setView] = useState('suggestions');

  const s = props.suggestion;

  let content: ReactNode = null;
  if (view === 'suggestions') {
    content = <SuggestedOptionsTab projectCost={props.projectCost} suggestion={props.suggestion} />;
  } else if (view === 'estimate') {
    content = (
      <EstimateTab onViewProjectEstimate={props.onViewEstimate} suggestion={props.suggestion} />
    );
  }

  return (
    <Dialog
      footerLeft={<Button label="Dismiss Suggestion" onClick={props.onDismiss} type="secondary" />}
      footerRight={
        <Button
          disabled={props.isCreatingItem}
          startIcon={props.isCreatingItem ? <SpinnerIcon /> : undefined}
          label={props.isCreatingItem ? 'Creating...' : 'Create Item'}
          onClick={props.onCreate}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      navigationTabs={{
        options: [
          { key: 'suggestions', label: 'Suggested Options' },
          { key: 'estimate', label: 'Potential Estimate Impact' },
        ],
        setView,
        view,
      }}
      onClose={props.onClose}
      size="lg"
      title={
        <div className="flex items-center gap-4">
          <div>{s.title}</div>
          <div className="flex gap-2 truncate">
            {s.categories.map((c) => (
              <Chip key={c} text={c} />
            ))}
          </div>
        </div>
      }
    >
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
}

function SuggestedOptionsTab(props: {
  projectCost: Cost;
  suggestion: ComponentProps<typeof SuggestionDialog>['suggestion'];
}) {
  const s = props.suggestion;

  let projectImpact = '';
  if (!props.projectCost) {
    projectImpact = '';
  } else if (isCostScalar(props.projectCost)) {
    projectImpact = percentFormatterSimple.format(
      s.baselineCost / parseInt(`${props.projectCost.value}`, 10)
    );
  } else if (isCostRange(props.projectCost)) {
    projectImpact = `${percentFormatterSimple.format(
      s.baselineCost / parseInt(`${props.projectCost.max}`, 10)
    )} – ${percentFormatterSimple.format(
      s.baselineCost / parseInt(`${props.projectCost.min}`, 10)
    )}`;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex">
        <Callout>{s.suggestionReason}</Callout>
        <div className="ml-auto flex gap-4">
          <div>
            <div className="type-number">{formatCost(s.baselineCost)}</div>
            <div className="type-body3">Baseline Cost</div>
          </div>
          <div className="text-type-muted">
            <div className="type-number">{projectImpact}</div>
            <div className="type-body3">Project Impact</div>
          </div>
        </div>
      </div>

      <div
        className="grid grid-flow-col gap-x-8 gap-y-4"
        style={{
          gridTemplateColumns: `repeat(${s.options.length}, 1fr)`,
          gridTemplateRows: `repeat(4, min-content)`, // the number of sections per Option
        }}
      >
        {s.options.map((o) => (
          <Fragment key={o.name}>
            {/* Section 1: Intro */}
            <div className="flex flex-col gap-2">
              <div className="type-heading2">{o.name}</div>

              {o.images.length ? (
                <div className="flex flex-wrap gap-2">
                  {o.images.map((i) => (
                    <a key={i.url} href={i.url} target="_blank" rel="noreferrer">
                      <img
                        alt=""
                        className="h-20 w-20 border border-border-muted object-cover"
                        src={i.url}
                      />
                    </a>
                  ))}
                </div>
              ) : null}
              <InputsTextAreaStyled
                editable={false}
                emptyElement={<></>}
                placeholder=""
                onAttachAsset={() => {}}
                onChangeComplete={() => {}}
                textStyled={o.description}
                variant={TipTapTextAreaVariant.ITEM_SUGGESTIONS}
              />
              {/* <div className="type-body1">{o.description}</div> */}
            </div>

            {/* Section 2: Pros */}
            {o.pros.length ? (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="text-type-muted icon-md">
                    <ThumbUp fontSize="inherit" />
                  </div>
                  <div className="type-body3">Pros</div>
                </div>
                {o.pros.map((p) => (
                  <div key={p} className="type-body1">
                    <InputsTextAreaStyled
                      editable={false}
                      emptyElement={<></>}
                      placeholder=""
                      onAttachAsset={() => {}}
                      onChangeComplete={() => {}}
                      textStyled={p}
                      variant={TipTapTextAreaVariant.ITEM_SUGGESTIONS}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div />
            )}

            {/* Section 3: Cons */}
            {o.cons.length ? (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="text-type-muted icon-md">
                    <ThumbDown fontSize="inherit" />
                  </div>
                  <div className="type-body3">Cons</div>
                </div>
                {o.cons.map((c) => (
                  <div key={c} className="type-body1">
                    <InputsTextAreaStyled
                      editable={false}
                      emptyElement={<></>}
                      placeholder=""
                      onAttachAsset={() => {}}
                      onChangeComplete={() => {}}
                      textStyled={c}
                      variant={TipTapTextAreaVariant.ITEM_SUGGESTIONS}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div />
            )}

            {/* Section 4: Product Documents */}
            {o.attachments.length ? (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="text-type-muted icon-md">
                    <StraightenOutlined fontSize="inherit" />
                  </div>
                  <div className="type-body3">Product Documents</div>
                </div>
                <ul className="ml-6 flex list-disc flex-col gap-1">
                  {o.attachments.map((a) => (
                    <li key={a.url}>
                      <a className="type-link" target="_blank" rel="noreferrer" href={a.url}>
                        {a.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function EstimateTab(props: {
  onViewProjectEstimate: () => void;
  suggestion: ComponentProps<typeof SuggestionDialog>['suggestion'];
}) {
  const s = props.suggestion;

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="shrink-0">
        <Callout>
          Potential Estimate Impact is the sum of all estimate lines that are impacted by a
          suggested item
        </Callout>
      </div>
      <div className="overflow-auto">
        <Table
          columnWidths={['1.5fr', '5fr', '1.5fr', '1.5fr', '1fr', '1fr']}
          entries={[
            ...s.baselineEstimateLines.map((l) => [
              { component: <TableComponents.TextCell text={l.lineNumber} /> },
              { component: <TableComponents.TextCell text={l.description} /> },
              { component: <TableComponents.CostCell cost={l.unitPrice} /> },
              { component: <TableComponents.NumberCell number={l.quantity} /> },
              { component: <TableComponents.TextCell text={l.unit} /> },
              { component: <TableComponents.CostCell cost={l.total} /> },
            ]),
            [
              { component: <TableComponents.TextCell bold text="Baseline Cost" /> },
              { component: null },
              { component: null },
              { component: null },
              { component: null },
              { component: <TableComponents.CostCell bold cost={s.baselineCost / 100} /> },
            ],
          ]}
          headerContent={[
            {
              copy: `Line (${s.baselineEstimateLines.length})`,
              key: 'line',
              tooltip: "Line number in the active milestone's estimate",
            },
            { copy: 'Description', key: 'description' },
            { copy: 'Unit Cost', key: 'description', rightAligned: true },
            { copy: 'Quantity', key: 'description', rightAligned: true },
            { copy: 'Unit', key: 'description' },
            { copy: 'Total', key: 'description', rightAligned: true },
          ]}
        />
      </div>
      <Button
        endIcon={<Launch />}
        label="View Full Project Estimate"
        onClick={() => props.onViewProjectEstimate()}
        startIcon={<Calculate className="icon-md" />}
        type="tertiary"
      />
    </div>
  );
}
