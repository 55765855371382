import { useMutation } from '@apollo/client';

import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  UpdateUserJobTitleMutation,
  UpdateUserJobTitleMutationVariables,
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables,
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables,
  UserDidSetupMutation,
  UserDidSetupMutationVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { userDidSetupMutation } from '../../login/queries';

import {
  currentUserQuery,
  updateUserJobTitleMutation,
  updateUserNameMutation,
  updateUserPictureMutation,
} from './queries';

export const useCurrentUserQuery = (
  options?: QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) =>
  useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    currentUserQuery,
    {
      ...options,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useUpdateUserNameMutation = () => {
  const [updateUserNameFunc] = useMutation<UpdateUserNameMutation, UpdateUserNameMutationVariables>(
    updateUserNameMutation
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (name: string, onSuccess?: any, onFailure?: any) =>
    updateUserNameFunc({
      variables: { name },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateUserName: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useUpdateUserJobTitleMutation = () => {
  const [updateUserJobTitleFunc] = useMutation<
    UpdateUserJobTitleMutation,
    UpdateUserJobTitleMutationVariables
  >(updateUserJobTitleMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (jobTitle: string, onSuccess?: any, onFailure?: any) =>
    updateUserJobTitleFunc({
      variables: { jobTitle },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateUserJobTitle: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useUpdateUserPictureMutation = () => {
  const [updateUserPictureFunc] = useMutation<
    UpdateUserPictureMutation,
    UpdateUserPictureMutationVariables
  >(updateUserPictureMutation);
  return (picture: string, onSuccess?: () => void) => {
    updateUserPictureFunc({
      variables: { picture },
    }).then(onSuccess);
  };
};

export const useUserDidSetupMutation = () => {
  const [userDidSetupFunc] = useMutation<UserDidSetupMutation, UserDidSetupMutationVariables>(
    userDidSetupMutation
  );
  return () => userDidSetupFunc();
};
