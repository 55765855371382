import { FC, useContext } from 'react';

import { Typography } from '@material-ui/core';

import { CostReportColumnKey } from '../../../../generated/graphql';
import { MILESTONE_REPORT_CELL } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getHoverDisplay } from '../../../../utilities/item-status';
import ItemsIcons from '../../../ItemsList/ItemsIcons/ItemsIcons';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import {
  Cell,
  VarianceColumnDescription,
  getColumnSubtotal,
  getColumnTermFromColumnKey,
} from '../../CostReportColumns/CostReportColumns';
import { itemCostToSegmented } from '../../CostReportUtils';
import { CostReportCategoriesTreeNode } from '../CostReportCategoriesTree';

import { styles } from './CostReportListRowStyles';
import {
  OptionCost,
  RowVariants,
  RowVariantsString,
  cellContent,
  costsDiffer,
  getLetterSpacingFromFontWeight,
} from './CostReportListRowUtils';

const getScaleYTransform = (zoomLevel: number, negate?: boolean) => {
  const scaleFactor = negate ? 1 / zoomLevel : zoomLevel;
  return (zoomLevel && `scaleY(${scaleFactor})`) || '';
};

export const getListRowTransforms = (zoomLevel: number) => {
  return {
    transform: getScaleYTransform(zoomLevel),
    negateTransform: getScaleYTransform(zoomLevel, true),
  };
};

type CostReportListRowCellProps = {
  cell: Cell;
  classes: Classes<typeof styles>;
  fontWeight: number;
  key: string;
  isHovering: boolean;
  isMetric?: boolean;
  isPrint: boolean;
  isVariance: boolean;
  optionCost: Cost;
  parentIsChosen: boolean;
  treeNode: CostReportCategoriesTreeNode;
  variant: RowVariantsString;
  height: number;
  zoomLevel: number;
};

const CostReportListRowCell: FC<CostReportListRowCellProps> = ({
  cell,
  classes,
  fontWeight,
  key,
  isHovering,
  isMetric = false,
  isPrint,
  isVariance,
  optionCost,
  parentIsChosen,
  treeNode,
  variant,
  height,
  zoomLevel,
}) => {
  const t = useContext(ProjectTermStore);

  const {
    branches,
    data: { id: itemId, status, parentId },
  } = treeNode;

  const {
    columnDescription,
    hasLedger,
    isEmpty,
    isExact,
    isProjectColumn,
    isWide,
    cost,
    status: itemStatus,
    isEstimateLine,
    isShaded,
  } = cell;

  const { milestoneID, date } = columnDescription as VarianceColumnDescription;

  const { columnKey } = columnDescription || {};
  if (!columnDescription) return null;

  const isItem = !!itemId && !!status;
  const statusText = (!!status && getHoverDisplay(parentIsChosen, status)) || '';
  const isVarianceColumn = isVariance && !(columnDescription && milestoneID);
  const hasShadedBackground = isShaded && !isMetric && !isHovering && !isPrint;

  const rowClass = `
    ${classes.fixedWidth}
    ${(isWide && !isVariance && classes.range) || ''}
    ${(isWide && isVariance && classes.varianceWideCell) || ''}
    ${(!isWide && isVariance && classes.varianceCell) || ''}
    ${(isVarianceColumn && classes.varianceText) || ''}
    ${(hasShadedBackground && classes.shaded) || ''}`;

  // if theres an option with an option contribution on rejected, we want to say "additional"
  let addAdds;
  let addDeducts;

  if (columnKey === CostReportColumnKey.REJECTED_KEY) {
    if (optionCost) {
      addAdds = costsDiffer(optionCost, cost as CostScalar);
      addDeducts = addAdds;
    } else if (isItem && branches.length) {
      const optionCosts: OptionCost[] = branches.map((branch: CostReportCategoriesTreeNode) => {
        const {
          data: { columns: branchColumns },
        } = branch;
        const optionsSubtotal = getColumnSubtotal(branchColumns, CostReportColumnKey.OPTIONS_KEY);
        const rejectedSubtotal = getColumnSubtotal(branchColumns, CostReportColumnKey.REJECTED_KEY);
        const o = optionsSubtotal && optionsSubtotal.directCostRange;
        const r = rejectedSubtotal && rejectedSubtotal.directCostRange;
        return { o, r };
      });
      addAdds = optionCosts.some(
        ({ o, r }: OptionCost) => itemCostToSegmented(o).adds !== undefined && costsDiffer(o, r)
      );
      addDeducts = optionCosts.some(
        ({ o, r }: OptionCost) => itemCostToSegmented(o).deducts !== undefined && costsDiffer(o, r)
      );
    }
  }

  const { text, title } = cellContent(
    getColumnTermFromColumnKey(t, columnKey),
    statusText,
    cost,
    variant,
    isProjectColumn,
    !!parentId,
    addAdds,
    addDeducts,
    isExact,
    !!date,
    isEstimateLine,
    isVarianceColumn
  );

  const showStatus = itemStatus && isVariance && !isVarianceColumn && !isEmpty && text;
  const showGap = hasLedger && !isEmpty && !showStatus;
  const rowVariantClass =
    variant &&
    ([RowVariants.SUBTOTAL, RowVariants.RUNNINGTOTAL] as RowVariantsString[]).includes(
      variant as RowVariantsString
    )
      ? classes.subtotal
      : '';
  const cellClass = `${classes.numberCells} ${showStatus && classes.numberCellsMin}`;

  const { transform, negateTransform } = getListRowTransforms(zoomLevel);
  const letterSpacing: number = getLetterSpacingFromFontWeight(fontWeight);
  // it the value is a range, and the text does not contain a negative and positive, we will add "up to"
  // if its "empty," its supposed to have a ledger line, and no text or hover
  return (
    <div
      style={{ height, transformOrigin: 'center left', transform: negateTransform }}
      className={`${hasShadedBackground && classes.shaded}`}
    >
      <div
        className={rowClass}
        style={{ height, transformOrigin: 'center left', transform }}
        key={`${text} ${title}${key}`}
        title={isEmpty || isPrint ? '' : title}
        data-cy={`${MILESTONE_REPORT_CELL}${`-${columnKey}`}${
          isEstimateLine ? `-${'estimateLine'}` : ''
        }`}
      >
        {!isEmpty && (
          <Typography className={`${cellClass} ${rowVariantClass}`}>
            <span style={{ fontWeight, letterSpacing }}>{text}</span>
          </Typography>
        )}
        {showGap && <div className={classes.gap} />}
        {showStatus && (
          <span key={key} className={classes.varianceItemStatus} style={{ fontWeight }}>
            <ItemsIcons status={itemStatus} />
          </span>
        )}
        {hasLedger && <div className={classes.spacer} />}
      </div>
    </div>
  );
};

export default withStyles(styles)(CostReportListRowCell);
