import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const CompanyTabStyles = (theme: KomodoTheme) =>
  createStyles({
    alignLeft: {
      display: 'flex',
      paddingBottom: 4,
    },
    alignLeftColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    alignRight: {
      display: 'flex',
      float: 'right',
      marginLeft: -8,
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    avatarImage: {
      height: 88,
      width: 88,
    },
    avatarPerson: {
      fontSize: 50,
    },
    avatarProfile: {
      paddingLeft: 12,
      paddingRight: 36,
    },
    avatarTable: {
      objectFit: 'cover',
      marginLeft: 12,
      padding: 6,
    },
    categorizationTitle: {
      fontSize: theme.typography.categorizationHeader.fontSize,
      fontWeight: theme.typography.title.fontWeight,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    description: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    containerNoCategorizations: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerLeft: {
      flex: '0 0 30%',
      display: 'flex',
      flexFlow: 'column',
      minWidth: 0,
      gap: 8,
    },
    containerMiddle: {
      flex: '1 1 auto',
      // NOTE: minWidth is necessary for single-line truncated text: https://css-tricks.com/flexbox-truncated-text/
      minWidth: 0,
      paddingLeft: 50,
    },
    containerRight: {
      display: 'flex',
      paddingLeft: 20,
    },
    collaboratorText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 400,
      paddingLeft: 6,
      lineHeight: '2rem',
    },
    modifiedBy: {
      paddingRight: 8,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 17,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 20,
      paddingLeft: 17,
    },

    deactivatedAvatar: {
      ...theme.grayscaleFilter,
    },
    divider: {
      padding: `0 ${theme.spacing.generic[2]}px`,
      position: 'relative',
      width: 'auto',
    },
    disabledInput: {
      border: '1px solid #d8dde5',
      borderRadius: '4px',
      color: 'red !important',
      paddingLeft: 6,
    },
    draftIcon: {
      alignItems: 'center',
      border: '2px solid '.concat(theme.palette.pinkBadge),
      borderRadius: 3,
      color: theme.palette.pinkBadge,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: 10,
      marginRight: 10,
      marginTop: -4.5,
      padding: '4px 8px 2px 8px',
      textAlign: 'center',
    },
    errorIcon: {
      color: theme.palette.red,
      fontSize: '20px',
      marginTop: '6px',
      marginRight: '4px',
    },
    filterText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: 300,
    },
    filterTextContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      alignItems: 'center',
      padding: '8px 8px 8px 8px',
    },
    filterPanel: {
      zIndex: 5000,
    },
    flexGrow: {
      flexGrow: 4,
    },
    flex: {
      display: 'flex',
    },
    headerPin: {
      background: theme.palette.backgroundWhite,
    },
    icon: {
      fontSize: theme.spacing.smallIcon,
      marginRight: 4,
    },
    iconMenu: {
      display: 'flex',
      marginRight: 10,
    },
    iconPaddingRight: {
      marginRight: 6,
    },
    image: {
      maxHeight: 240,
      width: 240,
      objectFit: 'contain',
    },
    input: {
      height: 40,
    },
    inline: {
      display: 'flex',
      width: 200,
    },
    lightbulb: {
      width: 50,
      margin: '0 20px',
    },
    linkBreadcrumb: {
      borderRadius: theme.border.radius,
      color: theme.palette.primaryGrey,
      cursor: 'pointer',
    },
    linkTitle: {
      color: theme.palette.link,
      textOverflow: 'ellipsis',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      textDecoration: 'underline',
    },
    linkBlack: {
      color: theme.palette.link,
      textDecoration: 'underline',
    },
    listEntry: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 72,
      justifyContent: 'space-between',
    },
    marginBottom: {
      marginBottom: 24,
    },
    marginRight: {
      marginRight: 24,
    },
    memberCount: {
      flexGrow: 1,
    },
    memberContainer: {
      display: 'flex',
      overflow: 'hidden',
      height: `calc(100vh - 133px)`,
    },
    memberContent: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'scroll',
    },
    memberData: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '0 2em',
      '&> div:first-child': {
        flex: '0 1 600px',
      },
    },
    memberSidebar: {
      flex: '0 0 300px',
      '@media screen and (max-width: 1050px)': {
        display: 'none',
      },
    },
    memberStatusColumn: {
      width: 200,
    },
    overflowAuto: {
      overflow: 'auto',
    },
    overflowHidden: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    paddingBottom: {
      paddingBottom: 8,
    },
    paddingRight: {
      paddingRight: 20,
    },
    paddingTop: {
      paddingTop: 8,
    },
    paddingSection: {
      paddingTop: 24,
    },
    paddingLabel: {
      paddingTop: 4,
    },
    projectTable: {
      border: `solid 1px ${theme.palette.joinGrey400}`,
      borderBottom: 'none',
    },
    projectTemplateTitle: {
      paddingLeft: 10,
      color: theme.palette.joinPrimary,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: theme.typography.title.fontWeight,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 72,
    },
    resendButton: {
      width: '100%',
      borderRadius: '18 px',
      borderColor: 'black',
    },
    select: {
      margin: '0.5em',
      minWidth: '12em',
      maxWidth: 350,
    },
    sidebarCount: {
      position: 'sticky',
      zIndex: 101,
      padding: '10px 0 10px 10px',
      fontWeight: 400,
      border: `solid 1px ${theme.palette.joinGrey400}`,
      background: theme.palette.backgroundWhite,
      fontSize: 16,
      bottom: 0,
      left: 0,
    },
    sidebarFirst: {
      padding: '10px 16px 6px 16px',
      borderRight: `solid 1px ${theme.palette.joinGrey400}`,
    },
    sidebarFilter: {
      padding: '6px 16px',
      borderRight: `solid 1px ${theme.palette.joinGrey400}`,
    },
    sidebarHeight: {
      height: `calc(100vh - 320px)`,
      borderRight: `solid 1px ${theme.palette.joinGrey400}`,
    },
    sidebarHeightCollaborators: {
      height: `calc(100vh - 270px)`,
      borderRight: `solid 1px ${theme.palette.joinGrey400}`,
    },
    standardsSidebar: {
      padding: 8,
      wordWrap: 'break-word',
    },
    selectedMenu: {
      background: theme.palette.backgroundFocusSelectGreen,
    },
    menu: {
      fontWeight: 500,
      padding: '4px 16px',
      textTransform: 'capitalize',
      '&:hover': {
        background: theme.palette.backgroundGreen,
      },
    },
    subText: {
      ...theme.typography.h4,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
    },
    tag: {
      whiteSpace: 'nowrap',
    },
    textRegular: {
      color: theme.palette.joinPrimary,
    },
    textDisabled: {
      color: theme.palette.disabledGrey,
    },
    tableSearchBar: {
      width: 260,
    },
    text: {
      textOverflow: 'ellipsis',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      marginTop: -4,
      paddingTop: 4,
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      fontWeight: 300,
      width: '100%',
    },
    textOverflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'normal',
    },
    title: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '17px 16px 15px 16px',
      zIndex: 101,
    },
    titleBold: {
      ...theme.typography.header,
      marginBottom: 12,
    },
    titleHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 16,
      gap: '16px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleText: {
      ...theme.typography.headline,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      fontSize: 16,
    },
    toggleText: {
      fontFamily: theme.typography.fontFamily,
    },
    tooltip: {
      fill: theme.palette.disabledGrey,
      width: 18,
      height: 18,
      marginLeft: 6,
      alignSelf: 'end',
    },
    sortArrow: theme.roundButton,
    sortArrowFlipped: {
      transform: 'rotate(180deg)',
    },
    settingContainer: {
      padding: 0,
      '&> div': {
        margin: 0,
      },
    },
  });

export default CompanyTabStyles;
