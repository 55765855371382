import {
  ACCEPTED,
  INCORPORATED,
  ITEM,
  ITEMS,
  ITEMTYPE_ITEM,
  ITEMTYPE_ITEM_WITH_OPTIONS,
  ITEMTYPE_OPTION,
  ITEM_WITH_OPTIONS,
  REJECTED,
} from '../../constants';
import { Status, Visibility } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { getURLState } from '../../utilities/urlState';
import { getTotalAttachmentCount } from '../assets/utils';

import { generateTitle } from './ItemsListItem/ItemsListItemUtils';

/*
HELPER FUNCTIONS 
- isNotAChosenOption
- isOption 
- generateCostImpactInfo
- generateItemDisplayTitle
- getStatusIsAcceptedIncorporated
- getAcceptedIncorporatedOption 
- getItemHasAcceptedIncorporatedOption
- getItemTypeAnalytics
*/

export const isNotAChosenOption = (availableStates: string[], isOption: boolean, status?: Status) =>
  // only options can be "not chosen"
  // if an option isn't accepted, and the parent is chosen, it doesn't have ACCEPTED as an available state
  (isOption && !availableStates.includes(ACCEPTED)) ||
  (status ? false : status === Status.NOTCHOSEN);

const isOption = (item: ItemLike) => item && 'parent' in item; // only options have a parent item

// TODO: Duplicated in CostReportUtils... consider how to streamline all of them
export const generateCostImpactInfo = (costImpact: Cost | Item['cost']) => {
  // if costImpact data shape is {value: ''}
  if (costImpact && 'value' in costImpact) {
    return formatCost(costImpact.value, { rounded: true, signed: true });
  }
  // if costImpact has data shape for a range {min: '', max: ''}
  if (costImpact && 'min' in costImpact && 'max' in costImpact) {
    return `${formatCost(costImpact.min, { rounded: true, signed: true })} to ${formatCost(
      costImpact.max,
      { rounded: true, signed: true }
    )}`;
  }
  // if costImpact data shape is a string or number (signed)
  if (costImpact) {
    return formatCost(costImpact, { rounded: true, signed: true });
  }
  return '';
};

// generates an item title for an item based on name/number availability
export function generateItemDisplayTitle(item: { name: string; number: string }) {
  const { name, number } = item;
  if (!number && !name) return 'Untitled';
  return generateTitle(item);
}

// generates a scenario title
export function generateScenarioDisplayTitle(scenario: { name: string }) {
  const { name } = scenario;
  if (!name) return 'Untitled';
  return scenario.name;
}

// returns a boolean to tell if status is ACCEPTED or INCORPORATED
export const getStatusIsAcceptedIncorporated = (status?: string) =>
  !!status && [ACCEPTED, INCORPORATED].includes(status);

export const getStatusIsChosen = (status?: string) =>
  !!status && [ACCEPTED, INCORPORATED, REJECTED].includes(status);

// filters through an array of options to find and return an ACCEPTED/INCORPORATED option
export const getAcceptedIncorporatedOption = (options: Pick<Option, 'number' | 'status'>[]) =>
  options.find((option) => getStatusIsAcceptedIncorporated(option.status));

// returns a boolean that tells if the item has accepted one of it's options
export const getItemHasAcceptedIncorporatedOption = (item: Item) =>
  item && item.options
    ? item.options.some((option) => getStatusIsAcceptedIncorporated(option.status))
    : false;

// returns what type of item was edited for analytics purposes
export const getItemTypeAnalytics = (item: ItemLike) => {
  const isOptionItem = isOption(item);
  const isItemWithOptions = 'options' in item && item.options && item.options.length > 0;
  let itemType = isItemWithOptions ? ITEMTYPE_ITEM_WITH_OPTIONS : ITEMTYPE_ITEM;
  itemType = isOptionItem ? ITEMTYPE_OPTION : itemType;
  return itemType;
};

export const getItemSettingsFromURL = () => {
  const settings = getURLState(window.location, ITEMS);
  const groupBy = settings && settings.groupBy ? settings.groupBy.length : 0;
  const show = settings && settings.show === ITEM ? ITEM : ITEM_WITH_OPTIONS;
  const hasGroupings = groupBy !== 0 || show !== ITEM;
  return { groupBy, show, hasGroupings };
};

export const getItemTotalValue = (
  milestoneCostImpact: Cost,
  mainCostImpact: Cost,
  showPartialCost: boolean
) => {
  let totalValue: Cost | undefined;
  if (!mainCostImpact) {
    if (showPartialCost) {
      const isValueTotal = JSON.stringify(milestoneCostImpact) === JSON.stringify(mainCostImpact);
      totalValue = !isValueTotal ? milestoneCostImpact : undefined;
    }
  }
  return totalValue;
};

export const isPrivateVisibility = (visibility?: Visibility) => visibility !== Visibility.PUBLISHED;
export const isPublicVisibility = (visibility?: Visibility) => visibility === Visibility.PUBLISHED;
export const isScenarioVisibility = (visibility?: Visibility) => visibility === Visibility.SCENARIO;

export const isOptionOfPrivateItem = (isOption: boolean, parentVisibility?: Visibility) =>
  isOption && isPrivateVisibility(parentVisibility);

export const showPrivateBadge = (isPrivate: boolean, number?: string) => isPrivate && number === '';

export const getTotalItemAttachmentCount = (
  item: Pick<ItemLike, 'assetCount' | 'remoteAssetCount'> | undefined
) => (item ? getTotalAttachmentCount(item.assetCount, item.remoteAssetCount) : 0);
