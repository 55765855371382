export default function InAppNotificationRemove() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9167 4C14.2056 4 14.4583 4.13296 14.675 4.39889C14.8917 4.66482 15 4.97507 15 5.32964L15 9.98338H13L13 6L3.89708 6L3.89708 18L13 18L13 13.9723H15L15 18.6704C15 19.0249 14.8917 19.3352 14.675 19.6011C14.4583 19.867 14.2056 20 13.9167 20L3.08333 20C2.79445 20 2.54167 19.867 2.325 19.6011C2.10833 19.3352 2 19.0249 2 18.6704L2 5.32964C2 4.97507 2.10833 4.66482 2.325 4.39889C2.54167 4.13296 2.79445 4 3.08333 4L13.9167 4Z"
        fill="currentColor"
      />
      <path d="M10 12H21M21 12L17 8M21 12L17 16" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}
