export enum GlobalSearchMode {
  ALL_PROJECTS = 'All Projects',
  ALL_ITEMS = 'All Items',
  PROJECT_ITEMS = 'Items in this Project',
}

export const getRecentSearchesKey = (searchMode: GlobalSearchMode, project?: string) =>
  `GLOBAL_SEARCH_HISTORY_${searchMode}${
    searchMode === GlobalSearchMode.PROJECT_ITEMS && project ? `_${project}` : ''
  }`
    .toUpperCase()
    .replaceAll(' ', '_');

export const getRecentSearches = (searchMode: GlobalSearchMode, project?: string): string[] => {
  const searches = window.localStorage.getItem(getRecentSearchesKey(searchMode, project));
  return searches ? JSON.parse(searches) : [];
};

export const addToRecentSearches = (
  search: string,
  searchMode: GlobalSearchMode,
  project?: string
) => {
  if (search === '') return getRecentSearches(searchMode, project);
  const searches = getRecentSearches(searchMode, project)
    .filter((s) => s.toLowerCase() !== search.toLowerCase())
    .slice(0, 99);
  searches.unshift(search);

  localStorage.setItem(getRecentSearchesKey(searchMode, project), JSON.stringify(searches));
  return searches;
};

export const deleteFromRecentSearches = (
  search: string,
  searchMode: GlobalSearchMode,
  project?: string
) => {
  const searches = getRecentSearches(searchMode, project).filter((s) => s !== search);

  localStorage.setItem(getRecentSearchesKey(searchMode, project), JSON.stringify(searches));
  return searches;
};
