import { FC } from 'react';

import { LinearProgress } from '@material-ui/core';

import { ContingencyInfo } from '../../../generated/graphql';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';

import ActiveMilestoneReport from './ActiveMilestoneReport';
import AllMilestoneReport from './AllMilestoneReport/AllMilestoneReport';
import { ContingencyReportType, ContingencyReportView } from './ContingencyReportUtils';

type Props = {
  milestoneID: string;
  projectID: string;
  milestoneName?: string;
  milestoneCost: Cost;
  activeMilestoneContingencies?: ContingencyReport;
  allMilestoneContingencies?: MultiMilestoneContingencyReport;
  reportView: ContingencyReportView;
  selectedReportType: ContingencyReportType;
  availableContingencies: Pick<ContingencyInfo, 'type' | 'name'>[];
  selectedContingencies: string[];
  selectedAllowances: string[];
  comments?: VarianceReportComments;
  clearFilters: () => void;
  isLoading: boolean;
};

const ContingencyReportBody: FC<Props> = ({
  milestoneID,
  milestoneName,
  milestoneCost,
  projectID,
  activeMilestoneContingencies,
  reportView,
  selectedReportType,
  availableContingencies,
  selectedContingencies,
  selectedAllowances,
  comments,
  allMilestoneContingencies,
  clearFilters,
  isLoading,
}) => {
  if (isLoading) return <LinearProgress />;

  return (
    <div>
      {reportView === ContingencyReportView.ACTIVE_MILESTONE && activeMilestoneContingencies && (
        <ActiveMilestoneReport
          projectID={projectID}
          contingencies={activeMilestoneContingencies}
          selectedReportType={selectedReportType}
          comments={comments}
          activeMilestoneID={milestoneID}
        />
      )}
      {reportView === ContingencyReportView.ALL_MILESTONES &&
        activeMilestoneContingencies &&
        allMilestoneContingencies && (
          <AllMilestoneReport
            allMilestoneContingencies={allMilestoneContingencies}
            activeMilestoneContingencies={activeMilestoneContingencies}
            milestoneName={milestoneName ?? ''}
            milestoneCost={milestoneCost}
            projectID={projectID}
            milestoneID={milestoneID}
            hasNoSelectedContingenciesOrAllowances={
              !(selectedContingencies.length || selectedAllowances.length) &&
              availableContingencies.length > 0
            }
            clearFilters={clearFilters}
          />
        )}
    </div>
  );
};

export default ContingencyReportBody;
