import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getTextWidth } from '../../../../utilities/string';
import TruncateText, { MIDDLE } from '../../TruncateText/TruncateText';
import { MIDDLE_TRUNC_PADDING } from '../../TruncateText/TruncateTextStyles';

import styles from './NavBreadcrumbStyles';
import { DEFAULT_MAX_WIDTH } from './NavBreadcrumbUtils';

type NavBreadcrumbProps = {
  classes: Classes<typeof styles>;
  isActive?: boolean;
  maxWidth?: number | undefined;
  onClick: () => void;
  textWidth?: number;
  value: string;
};

const NavBreadcrumb: FC<NavBreadcrumbProps> = ({
  classes,
  isActive = false,
  maxWidth = DEFAULT_MAX_WIDTH,
  onClick,
  textWidth: textWidthOuter,
  value,
}) => {
  const textWidth = textWidthOuter || getTextWidth(value, 16);
  const shouldTruncate = textWidth > maxWidth - MIDDLE_TRUNC_PADDING;
  const textStyle = `${classes.valueText} ${isActive ? classes.valueTextActive : ''}`;

  const truncateText = (
    <TruncateText
      maxWidth={maxWidth}
      text={value}
      textClass={textStyle}
      onClick={onClick}
      variant={MIDDLE}
    />
  );

  const text = (
    <Typography className={`${textStyle} ${classes.inlineText}`} onClick={onClick}>
      {value}
    </Typography>
  );

  const breadcrumb = shouldTruncate ? truncateText : text;

  return <div className={classes.valueDecoration}>{breadcrumb}</div>;
};

export default withStyles(styles)(NavBreadcrumb);
