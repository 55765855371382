import { ReactNode } from 'react';
import * as React from 'react';
import { useEffectOnce } from 'react-use';

import { RESIZE } from '../../../constants';
import { Portal } from '../Portal/Portal';

/**
 * PortalPositional
 *
 * This component is used to pass down a location DOMRect to
 * child components. This allows you to position child elements
 * however you want relative to the original location of embed.
 * EX: to the left, right, top, bottom, center, or whererver.
 */

const defaultBoundingRect: DOMRect = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: 0,
  x: 0,
  y: 0,
  height: 0,
  toJSON: () => {},
};

type PortalPositionalProps = {
  children: (val: DOMRect) => ReactNode;
};

export const PortalPositional: React.FC<PortalPositionalProps> = ({ children }) => {
  const parentRef = React.useRef<HTMLElement>(null);
  const [parentRect, setParentRect] = React.useState<DOMRect>(defaultBoundingRect);

  useEffectOnce(() => {
    const getParentRect = () => {
      if (!parentRef.current) {
        return;
      }

      setParentRect(parentRef.current.getBoundingClientRect());
    };

    getParentRect();
    window.addEventListener('scroll', getParentRect);
    window.addEventListener(RESIZE, getParentRect);
    return () => {
      window.removeEventListener('scroll', getParentRect);
      window.removeEventListener(RESIZE, getParentRect);
    };
  });

  return (
    <span style={{ width: 0, height: 0, maxHeight: 0 }} ref={parentRef}>
      <Portal>{children(parentRect)}</Portal>
    </span>
  );
};
