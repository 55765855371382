import { ComponentProps, useEffect, useRef } from 'react';
import { FocusScope, OverlayContainer, useDialog, useModalOverlay } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { isMac } from '../../../utilities/utilities';
import { IconButton, Tooltip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import { SearchIcon } from '../icons';

import SearchDialog from './SearchDialog';

type Props = {
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
};

export default function SearchButton(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const state = useOverlayTriggerState({
    onOpenChange: (isOpen) => {
      if (!isOpen) sendNavAnalytics(navEventTypes.SEARCH_CLOSED);
    },
  });
  const ref = useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog({}, ref);

  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable: true,
    },
    state,
    ref
  );

  const { open } = state;
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && ((isMac() && e.metaKey) || (!isMac() && e.ctrlKey))) {
        e.stopPropagation();
        sendNavAnalytics(navEventTypes.SEARCH_OPENED, { source: 'kb' });
        open();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open, sendNavAnalytics]);

  return (
    <>
      <Tooltip
        content={`Search (${isMac() ? '⌘' : 'Ctrl'}+K)`}
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <IconButton
          aria-label="open global search"
          type="secondary"
          icon={<SearchIcon />}
          onClick={() => {
            sendNavAnalytics(navEventTypes.SEARCH_OPENED, { source: 'mouse' });
            state.open();
          }}
        />
      </Tooltip>
      {state.isOpen && (
        <OverlayContainer>
          <FocusScope autoFocus contain restoreFocus>
            <div
              className="fixed bottom-0 left-0 right-0 top-0 z-[200] flex items-center justify-center bg-background-backdrop"
              data-cy="dialog-underlay"
              data-theme="dark"
              {...underlayProps}
            >
              <SearchDialog
                dialogProps={{ ...dialogProps, ...modalProps }}
                onClose={state.toggle}
                ref={ref}
              />
            </div>
          </FocusScope>
        </OverlayContainer>
      )}
    </>
  );
}
