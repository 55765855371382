import { ComponentProps } from 'react';

import { IconButton, ScrollContainer, Tooltip } from '../../scales';
import { ExpandSidebarIcon } from '../icons';
import SearchButton from '../SearchButton';
import { Link } from '../types';

import CompanySection from './CompanySection';
import useLinks from './hooks/useLinks';
import HubSpotChat from './HubSpotChat';
import JoinLogoNavLink from './JoinLogoNavLink';
import NavItem from './NavItem';
import NotificationsButton from './NotificationsButton';
import ProjectThumbnail from './ProjectThumbnail';
import UserMenuButton from './UserMenuButton';

type Props = {
  company: ComponentProps<typeof CompanySection>['company'];
  lastViewedProjectIDs: UUID[];
  onExpand: () => void;
  user: ComponentProps<typeof UserMenuButton>['user'];
};

export default function SidebarCollapsed(props: Props) {
  // TODO: If lastViewedProjectIDs is empty, it's a new user (or they cleared cache).
  // Maybe it'd be nice to fetch and use the most-recently edited project this user can view.

  const { companyLinks, projectLinks } = useLinks(props.lastViewedProjectIDs[0]);

  return (
    <div className="flex h-full w-16 flex-col items-stretch gap-4">
      <header className="flex flex-col items-center">
        <div className="py-2">
          <JoinLogoNavLink size="md" />
        </div>
        <Tooltip content="Expand sidebar" offset={TOOLTIP_OFFSET} placement={TOOLTIP_PLACEMENT}>
          <IconButton
            aria-label="expand sidebar"
            icon={<ExpandSidebarIcon />}
            onClick={props.onExpand}
            type="secondary"
          />
        </Tooltip>
        <NotificationsButton tooltipOffset={TOOLTIP_OFFSET} tooltipPlacement={TOOLTIP_PLACEMENT} />
        <SearchButton tooltipOffset={TOOLTIP_OFFSET} tooltipPlacement={TOOLTIP_PLACEMENT} />
      </header>
      <Separator />
      <ScrollContainer direction="vertical">
        {props.lastViewedProjectIDs.length ? (
          <>
            <ProjectSection links={projectLinks} projectID={props.lastViewedProjectIDs[0]} />
            <div className="py-4">
              <Separator />
            </div>
          </>
        ) : null}
        <CompanySection
          company={props.company}
          lastViewedProjectIDs={props.lastViewedProjectIDs}
          links={companyLinks}
        />
      </ScrollContainer>
      <footer className="mt-auto flex flex-col items-center justify-center gap-2 py-4">
        <UserMenuButton
          user={props.user}
          tooltipOffset={TOOLTIP_OFFSET}
          tooltipPlacement={TOOLTIP_PLACEMENT}
        />
        <HubSpotChat
          position={{ left: 64 /* 64px is the width of the sidebar */ }}
          tooltipOffset={TOOLTIP_OFFSET}
          tooltipPlacement={TOOLTIP_PLACEMENT}
        />
      </footer>
    </div>
  );
}

const TOOLTIP_PLACEMENT = 'right';
const TOOLTIP_OFFSET = 16;

const Separator = () => <div className="h-px bg-border-separator" />;

type ProjectSectionProps = {
  projectID: UUID;
  links: Link[];
};
function ProjectSection(props: ProjectSectionProps) {
  return (
    <div data-cy="project" className="flex flex-col items-stretch gap-2">
      <ProjectThumbnail projectID={props.projectID} />
      <div data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} tooltip={link.name} path={link.path} />
        ))}
      </div>
    </div>
  );
}
