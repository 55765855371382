import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import {
  itemSidebarMenuClose,
  setItemsListNewItemDialogOpen,
} from '../../analytics/analyticsEventProperties';
import {
  itemEstimateDialogOpenVar,
  itemSidebarOpenVar,
  newItemDialogOpenVar,
} from '../../api/apollo/reactiveVars';
import { EstimateType, JoinProjectRoutes } from '../../api/gqlEnums';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import theme from '../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import { getTextWidth } from '../../utilities/string';
import { getItemIdFromUrl, getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { getEstimateType } from '../estimate/EstimateAccordion/EstimateAccordionUtils';
import { isCtrlMeta, isPrintKeys } from '../Print/PrintUtils';

export const getNameFontSize = (name: string) => {
  const MAX_FONT_SIZE = 21;
  const MIN_FONT_SIZE = 16;
  const MAX_WIDTH = 370;

  const fullWidth = getTextWidth(name, MAX_FONT_SIZE);
  const scale = MAX_WIDTH / fullWidth;
  let size = MAX_FONT_SIZE * scale;
  if (size > MAX_FONT_SIZE) size = MAX_FONT_SIZE;
  if (size < MIN_FONT_SIZE) size = MIN_FONT_SIZE;
  return size;
};

export const useListKeyPressListener = (onPrint: () => void) => {
  const newItemDialogOpen = useReactiveVar(newItemDialogOpenVar);
  const itemEstimateDialogOpen = useReactiveVar(itemEstimateDialogOpenVar);
  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const sendAnalytics = useAnalyticsEventHook();

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.repeat) return;
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onPrint();
      }
      if (event.key === 'Escape') {
        if (newItemDialogOpen) {
          // close Dialog!
          newItemDialogOpenVar(false);
          sendAnalytics(setItemsListNewItemDialogOpen(false));
        } else if (!itemEstimateDialogOpen) {
          // close Sidebar!
          itemSidebarOpenVar(null);
          if (isItemSidebarOpen) sendAnalytics(itemSidebarMenuClose(isItemSidebarOpen));
        }
      }
      if (event.key === 'i' && isCtrlMeta(event) && !newItemDialogOpen) {
        newItemDialogOpenVar(true);
        sendAnalytics(setItemsListNewItemDialogOpen(true));
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [isItemSidebarOpen, itemEstimateDialogOpen, newItemDialogOpen, onPrint, sendAnalytics]);
};

const validEstimateTypes = [
  EstimateType.ACTIVE_ESTIMATE.toString(),
  EstimateType.ITEM.toString(),
  EstimateType.BUDGET.toString(),
];

export const useHasDrawer = (skip?: boolean) => {
  // Drawer is not shown on medium and wider screens
  const minWidth = theme.breakpoints.values.md;
  const settings = { minWidth };
  const isMediumWide = useMediaQuery(settings);

  const getHasDrawer = (path: string) => {
    if (skip) return false;
    if (!isMediumWide) return true;
    // Milestone Details Full screen with tabs?
    const isMilestoneDetails = !!getMilestoneIdFromUrl(path);
    if (isMilestoneDetails) return true;
    const projectId = getProjectIdFromUrl(path);
    if (!projectId) return false;
    // contingency report views
    if (
      path.startsWith(
        generateSharedPath(JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT, { projectId })
      ) ||
      path.startsWith(
        generateSharedPath(JoinProjectRoutes.CONTINGENCY_ALL_MILESTONES_REPORT, { projectId })
      )
    )
      return true;
    // Item estimate?
    const itemId = getItemIdFromUrl();
    if (!itemId) return false;
    const itemEstimatePath = generateSharedPath(JoinProjectRoutes.ITEM_ESTIMATE, {
      projectId,
      itemId,
    });
    const isItemEstimate = path === itemEstimatePath;
    if (isItemEstimate) return true;
    // Milestone estimate?
    const estimateType = getEstimateType(); // type index works for Milestone Estimates
    const isEstimate = !!estimateType && validEstimateTypes.includes(estimateType);
    return isEstimate;
  };

  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  return useMemo(() => getHasDrawer(location.pathname), [isMediumWide, location.pathname, skip]);
};

export const escKeyDownHandler = (
  e: KeyboardEvent | React.KeyboardEvent | React.KeyboardEvent<HTMLDivElement>,
  closeDialog: () => void
) => {
  if (e.repeat) return;
  if (e.key === 'Escape') {
    closeDialog();
    e.preventDefault();
    e.stopPropagation();
  }
};
