import { FC, useContext } from 'react';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { TermKey } from '../../../../../api/gqlEnums';
import { CostReportColumnType } from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';

import styles from './ConfirmFollowingStyles';

type ConfirmFollowingProps = {
  averageCost?: string;
  classes: Classes<typeof styles>;
  currentCost?: string;
  isNewMilestone?: boolean;
  isNoCurrentEstimate: boolean;
  milestoneName: string;
  type: CostReportColumnType;
};

const ConfirmFollowing: FC<ConfirmFollowingProps> = ({
  averageCost,
  classes,
  currentCost,
  isNewMilestone,
  isNoCurrentEstimate,
  milestoneName,
  type,
}) => {
  const t = useContext(ProjectTermStore);
  const unformattedTypeString =
    type === CostReportColumnType.ESTIMATE_REPORT ? TermKey.ESTIMATE : TermKey.TARGET;
  const titleTypeString = t.titleCase(unformattedTypeString);
  const rawTypeString = t.rawTerm(unformattedTypeString);

  const newMilestoneText = (
    <>
      <p className={classes.inlineText}>{`You will be creating a new milestone named `}</p>
      <p className={`${classes.titleBold} ${classes.inlineText}`}>{milestoneName}</p>
      <p className={classes.inlineText}>{` within `}</p>
      <p className={`${classes.titleBold} ${classes.inlineText}`}>{titleTypeString}</p>
      <p className={classes.inlineText}>.</p>
    </>
  );

  const estimateText = (
    <>
      <p
        className={classes.inlineText}
      >{`You will be adding your estimate to ${milestoneName} and `}</p>
      <p className={`${classes.titleBold} ${classes.inlineText}`}>overwriting the current data</p>
      <p className={classes.inlineText}>{` within ${titleTypeString}.`}</p>
    </>
  );

  const noEstimateText = (
    <>
      <p className={classes.inlineText}>{`You will be adding your estimate to `}</p>
      <p className={`${classes.titleBold} ${classes.inlineText}`}>{milestoneName}</p>
      <p className={classes.inlineText}>{` within `}</p>
      <p className={`${classes.titleBold} ${classes.inlineText}`}>{titleTypeString}</p>
      <p className={classes.inlineText}>.</p>
    </>
  );

  const currentEstimateComponent = isNoCurrentEstimate ? noEstimateText : estimateText;

  return (
    <div>
      <div>
        <p className={classes.text}>Confirm the following before publishing:</p>
        {isNewMilestone ? newMilestoneText : currentEstimateComponent}
      </div>
      {!isNoCurrentEstimate && (
        <div className={`${classes.horizontal} ${classes.paddingTop}`}>
          <div className={classes.priceContainer}>
            <p className={classes.titleBold}>{`Current ${rawTypeString}`}</p>
            <p className={classes.price}>{currentCost}</p>
          </div>
          <ArrowRightAltIcon className={classes.arrow} />
          <div className={classes.priceContainer}>
            <p className={classes.titleBold}>{`New ${rawTypeString}`}</p>
            <p className={classes.price}>{averageCost}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const StyledConfirmFollowing = withStyles(styles)(ConfirmFollowing);

export default StyledConfirmFollowing;
