import { ReactNode } from 'react';

import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { isEnumValue } from '../../../../utilities/types';
import { Tab, TabList, TabPanel, Tabs } from '../../../scales';
import { GearIcon } from '../../icons';

import CostsSummary from './CostsSummary';
import ItemsChart from './ItemsChart';
import Settings from './Settings';

enum TabKeys {
  COSTS = 'costs',
  ITEMS = 'items',
  SETTINGS = 'settings',
}

type Props = {
  projectID: UUID;
  milestoneID?: UUID;
};

export default function HUD(props: Props) {
  const [activeHUDTab, setActiveHUDTab] = useLocalStorage('NAV_ACTIVE_HUD_TAB', TabKeys.COSTS);

  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(props.projectID);

  const tabs: { key: string; element: ReactNode }[] = [];
  if (shouldDisplayCosts) {
    tabs.push(
      {
        key: TabKeys.COSTS,
        element: (
          <Tab compact key={TabKeys.COSTS} id={TabKeys.COSTS}>
            Costs
          </Tab>
        ),
      },
      {
        key: TabKeys.ITEMS,
        element: (
          <Tab compact key={TabKeys.ITEMS} id={TabKeys.ITEMS}>
            Items
          </Tab>
        ),
      }
    );
  }

  if (shouldDisplayCosts || setDisplayCostsToggle) {
    tabs.push({
      key: 'settings',
      element: (
        <Tab compact key={TabKeys.SETTINGS} id={TabKeys.SETTINGS} isRightAligned>
          <GearIcon className="!icon-sm" />
        </Tab>
      ),
    });
  }

  if (!tabs.length) return null;

  return (
    <Tabs
      data-cy="hud"
      onChange={(key) =>
        typeof key === 'string' && isEnumValue(TabKeys, key) && setActiveHUDTab(key)
      }
      // Make sure we always reset the current tab to one that actually exists. This handles
      // local-storage backcompat and any changes to permissions.
      value={tabs.find((t) => t.key === activeHUDTab) ? activeHUDTab : tabs[0].key}
    >
      <TabList data-cy="hud-tabs">{tabs.map((t) => t.element)}</TabList>
      <HUDTabPanel id={TabKeys.COSTS}>
        <CostsSummary activeMilestoneID={props.milestoneID} projectID={props.projectID} />
      </HUDTabPanel>
      <HUDTabPanel id={TabKeys.ITEMS}>
        <ItemsChart activeMilestoneID={props.milestoneID} projectID={props.projectID} />
      </HUDTabPanel>
      <HUDTabPanel id={TabKeys.SETTINGS}>
        <Settings projectID={props.projectID} />
      </HUDTabPanel>
    </Tabs>
  );
}

function HUDTabPanel(props: { children: ReactNode; id: string }) {
  return (
    <TabPanel id={props.id}>
      {/* If we moved this div that sets the height above the TabPanel components
          then the `TabPanel` would once again reset the size. We want a fixed size. */}
      <div className="h-[110px]">{props.children}</div>
    </TabPanel>
  );
}
