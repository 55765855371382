import { useMemo } from 'react';

import { SortDirection } from '../../../generated/graphql';

export type ProjectCompanyLocal = ProjectCompany & {
  filteredCollaborators: Collaborator[];
};

// Changing the order of this data changes the order the header columns appear on Companies.
export enum CompaniesListHeaders {
  Company = 'Company',
  Domain = 'Domain',
  People = 'People',
  Role = 'Company Role on Project',
}

export type CompaniesSortDataType = {
  sortKey: CompaniesListHeaders;
  sortDirection: SortDirection;
};

export const sortFunctions = {
  [CompaniesListHeaders.Company]: (pc: ProjectCompanyLocal) => pc.company.name.toLowerCase(),
  [CompaniesListHeaders.Domain]: (pc: ProjectCompanyLocal) =>
    pc.company.domain ? pc.company.domain.toLowerCase() : '',
  [CompaniesListHeaders.People]: (pc: ProjectCompanyLocal) => pc.filteredCollaborators.length,
  [CompaniesListHeaders.Role]: (pc: ProjectCompanyLocal) => (pc.role ? pc.role.toLowerCase() : ''),
};

export const headerTitleContent = {
  [CompaniesListHeaders.Company]: '',
  [CompaniesListHeaders.Domain]: `The web domain that identifies users' emails`,
  [CompaniesListHeaders.People]: '',
  [CompaniesListHeaders.Role]:
    "Setting a company's role\nhelps to describe how the company\nparticipates on the project",
};

export const generateSortAlgorithm = (
  a: ProjectCompanyLocal,
  b: ProjectCompanyLocal,
  sortDirection: SortDirection,
  getSortValue: (pc: ProjectCompanyLocal) => string | number
): number => {
  const aValue = getSortValue(a);
  const bValue = getSortValue(b);
  const ascendingAlgorithm = aValue > bValue ? 1 : -1;
  const descendingAlgorithm = aValue < bValue ? 1 : -1;
  return sortDirection === SortDirection.SORT_ASCENDING ? ascendingAlgorithm : descendingAlgorithm;
};

export type SortDataType = {
  sortKey: CompaniesListHeaders;
  sortDirection: SortDirection;
};

export const useSortCompanies = (
  companies: ProjectCompanyLocal[],
  sortData: CompaniesSortDataType
) =>
  useMemo(() => {
    const { sortDirection, sortKey } = sortData;
    const sortValue = sortFunctions[sortKey];
    return sortKey
      ? companies
          .slice()
          .sort((a: ProjectCompanyLocal, b: ProjectCompanyLocal) =>
            generateSortAlgorithm(a, b, sortDirection, sortValue)
          )
      : companies;
  }, [sortData, companies]);
