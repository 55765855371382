import { FC, useEffect } from 'react';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { getTeamGUID } from '../../assets/utils';
import { useActiveProjectBannerQuery } from '../../ProjectProperties/PrintHeader/hooks/GetActiveProjectBannerQuery';
import { useProjectBannersQuery } from '../../ProjectProperties/PrintHeader/hooks/GetProjectBannersQuery';

import { PrintPageHeader } from './PrintPageHeader';
import { useOrganizationPictures } from './useOrganizationPictures';

type PrintPageHeaderWrapperProps = {
  milestoneName?: string;
  projectId: UUID;
  reportName: string;
  triggerOnComplete?: () => void;
};

export const PrintPageHeaderWrapper: FC<PrintPageHeaderWrapperProps> = ({
  milestoneName,
  projectId,
  reportName,
  triggerOnComplete,
}) => {
  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const { team, name: projectName = '' } = project ?? {};
  const { designTeamGUID, generalContractorGUID, ownerGUID } = getTeamGUID(team);
  const images = useOrganizationPictures([designTeamGUID, generalContractorGUID, ownerGUID]);

  // Get active project banner query
  const { data: { activeProjectBanner = null } = { activeProjectBanner: null } } =
    useActiveProjectBannerQuery(projectId);

  // Get project banners // to calculate if we show old way imgs or null
  // Can be removed, when we ditch team logos
  const { data: { projectBanners = null } = { projectBanners: null } } =
    useProjectBannersQuery(projectId);

  useEffect(() => {
    if (triggerOnComplete && !projectId) {
      triggerOnComplete();
    } else if (
      triggerOnComplete &&
      team &&
      (activeProjectBanner || images.every((image: string | null) => image !== null)) &&
      projectBanners
    )
      triggerOnComplete();
  }, [team, images, activeProjectBanner, projectBanners, triggerOnComplete, projectId]);

  return (
    <PrintPageHeader
      projectName={projectName}
      milestoneName={milestoneName}
      reportName={reportName}
      images={images}
      banner={activeProjectBanner}
      bannersNum={projectBanners ? projectBanners.length : 0}
    />
  );
};
