import { FC } from 'react';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { JoinProjectRoutes } from '../../../../../api/gqlEnums';
import { ItemType, SearchCategory } from '../../../../../generated/graphql';
import { HOME_ITEM_NAME } from '../../../../../tagConstants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import { categoryLabel } from '../../../../../utilities/string';
import NormalTooltip from '../../../../NormalTooltip/NormalTooltip';
import SearchText from '../../../../Search/SearchText/SearchText';
import { Chip, Chips } from '../../../common/Chips';
import ProjectLink from '../../../common/ProjectLink';

import styles from './ItemCellStyles';

type Props = {
  classes: Classes<typeof styles>;
  item: ForecastingItem;
  searchText: string;
};

const ItemCell: FC<Props> = ({ classes, item, searchText }) => {
  // the following two functions ensures backwards compatible support for an older representation of search.ItemCategory
  // we can later ignore the case where we are given a string with a '::' separator
  const getCategorization = (category: SearchCategory) =>
    category.name.includes('::') ? category.name.split('::')[0] : category.categorization.name;
  const getCategoryLabel = (category: SearchCategory) =>
    category.name.includes('::')
      ? category.name.split('::')[1]
      : categoryLabel(category.name, category.number);

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <ItemTypeChip item={item} />
        <ProjectLink
          className={item.project.hasAccess ? classes.link : ''}
          hasAccess={item.project.hasAccess}
          projectName={item.project.name}
          to={generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
            projectId: item.project.id,
            itemId: item.id,
          })}
        >
          <div className={classes.title} data-item-id={item.id}>
            <span data-cy={HOME_ITEM_NAME}>
              <SearchText searchTerm={searchText} text={item.name} />
            </span>
          </div>
        </ProjectLink>
        <Chips>
          <Chip dataCy="project-location" iconComponent={LocationOnOutlined}>
            {item.project.location}
          </Chip>
          {item.project.type && (
            <Chip dataCy="project-type" iconComponent={BusinessOutlined}>
              {item.project.type}
            </Chip>
          )}
          {item.categories.map((category) => (
            <NormalTooltip key={category.id} title={getCategorization(category)}>
              <div>
                <Chip dataCy="item-category">
                  <SearchText searchTerm={searchText} text={getCategoryLabel(category)} />
                </Chip>
              </div>
            </NormalTooltip>
          ))}
        </Chips>
      </div>
    </div>
  );
};

const ItemTypeChip = withStyles(styles)(
  ({ item, classes }: { item: ForecastingItem; classes: Classes<typeof styles> }) => {
    return (
      <Chips>
        <Chip
          className={item.type !== ItemType.OPTION ? classes.itemChip : classes.optionChip}
          dataCy="item-type-chip"
        >
          {item.type !== ItemType.OPTION ? 'ITEM' : 'OPTION'}
        </Chip>
        {item.numOptions > 0 && (
          <div className={classes.optionCount}>{item.numOptions} Options</div>
        )}
        {item.parentID && item.parentName ? (
          <ProjectLink
            className={classes.parentItem}
            hasAccess={item.project.hasAccess}
            to={generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
              projectId: item.project.id,
              itemId: item.parentID,
            })}
            projectName={item.project.name}
          >
            {item.parentName}
          </ProjectLink>
        ) : null}
      </Chips>
    );
  }
);

export default withStyles(styles)(ItemCell);
