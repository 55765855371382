import { createStyles } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const menuPropsDefault: Partial<MenuProps> = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  getContentAnchorEl: null,
  transformOrigin: {
    horizontal: 'left',
    vertical: 'top',
  },
};

export default (theme: KomodoTheme) =>
  createStyles({
    bgnWhite: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: 3,
    },
    teamLogo: {
      height: 40,
      color: theme.palette.primaryGrey,
      objectFit: 'contain',
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 0 auto',
    },
    deleteButton: {
      color: theme.palette.joinPrimary,
      height: 40,
      margin: 0,
      width: 40,
    },
    hide: {
      opacity: 0,
    },
    hidePrint: {
      '@media print': {
        display: 'none',
      },
    },
    emptyOption: {
      fontSize: 16,
      fontStyle: 'italic',
      color: theme.palette.chartGrey,
    },
    subheader: {
      height: 40,
      padding: 10,
      opacity: 1,
    },
    location: {
      marginTop: -60,
    },
    marginLeft: {
      marginLeft: 6,
    },
    noCursor: {
      cursor: 'default',
    },
    rootItem: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
    },
    selectMenu: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      height: 60,
      padding: `0px 32px 0px 0px`,
      display: 'flex',
      alignItems: 'center',
    },
    subName: {
      '@media print': {
        ...theme.typography.h3,
      },
      ...theme.typography.body2,
    },
    selectContainer: {
      width: 600,
      minWidth: 400,
      height: 60,
      paddingLeft: 24,
      alignSelf: 'center',
    },
    imagesContainer: {
      display: 'flex',
      padding: 10,
    },
  });
