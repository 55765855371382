import * as d3 from 'd3';
import { useMemo } from 'react';

import { MAX_SHORT_DIGITS, formatCost } from '../../../../utilities/currency';
import { formatNumberShort } from '../../../../utilities/string';

import { filterNumberBySignificantDigits } from './BandCostAxesUtils';

type Props = {
  height: number;
  isCost?: boolean;
  marginLeft?: number;
  tickCount?: number;
  x: d3.ScaleBand<string>;
  xComponents: JSX.Element[];
  y: d3.ScaleLinear<number, number>;
  yLabel?: string;
};

export default function BandCostAxes(props: Props) {
  const { x, y, isCost, tickCount } = props;
  // Memoized consts
  const xTicks = useMemo(
    () =>
      x.domain().map((value) => ({
        value,
        offset: (x(value) ?? 0) + x.bandwidth() / 2,
      })),
    [x]
  );
  const yTicks = useMemo(
    () =>
      y.ticks(tickCount ?? 4).map((value) => ({
        value,
        offset: y(value),
      })),
    [y, tickCount]
  );

  // Values used over and over again
  const x0 = x.range()[0];
  const x1 = x.range()[1];
  const y0 = y.range()[0];
  const y1 = y.range()[1];

  return (
    <g>
      <line y1={y0} y2={y0} x1={x0} x2={x1} className="stroke-chart-axis stroke-2" />
      {xTicks.map(({ value, offset }, i) => (
        <g key={value} transform={`translate(${offset}, ${y0})`}>
          <line y2="6" className="stroke-chart-axis" />
          <foreignObject y="10" className="align-center overflow-visible">
            <div data-cy="x-axis-label" className="flex items-center justify-center">
              {props.xComponents[i]}
            </div>
          </foreignObject>
        </g>
      ))}
      <line y1={y0} y2={y1} x1={x0} x2={x0} className="stroke-chart-axis stroke-2" />
      {yTicks.map(({ value, offset }) => (
        <g key={value} transform={`translate(0, ${offset})`}>
          <line x1={x0 - 4} x2={x1} className="stroke-width-1 stroke-chart-axis" />
          {filterNumberBySignificantDigits(value, MAX_SHORT_DIGITS) && (
            <text
              key={value}
              className="text-type-primary type-number"
              data-cy="y-axis-label"
              textAnchor="end"
              dominantBaseline="middle"
              x={x0 - 8}
            >
              {isCost
                ? formatCost(value, { short: true, showCurrencySymbol: false })
                : formatNumberShort(value)}
            </text>
          )}
        </g>
      ))}
      {props.yLabel && (
        <text
          className="type-paragraph text-type-primary"
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`translate(${12 - (props.marginLeft ?? 0)}, ${props.height / 2}) rotate(-90)`} // x location based on text height
        >
          {props.yLabel}
        </text>
      )}
    </g>
  );
}
