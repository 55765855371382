// only edit visibility of items the user created
export const hasPrivateItemEditPermission = (createdByID: UUID, currentUserID: UUID) =>
  createdByID === currentUserID;

// Remove with YC_ITEM_SHARING feature flag
export const getMakeItemPublicActionText = (isStatus = true) =>
  `Make this item public to ${isStatus ? 'update the status' : 'assign a number'}.`;
export const getMakeItemPublicActionTextNew = (isStatus = true) =>
  `Publish this item to ${isStatus ? 'update the status' : 'assign an item number'}.`;

export const DISABLE_ITEM_NUMBER_TOOLTIP = `Private items cannot have numbers. ${getMakeItemPublicActionText()}`;
export const DISABLE_ITEM_NUMBER_TOOLTIP_NEW = `Draft items cannot have numbers. ${getMakeItemPublicActionTextNew(
  false
)}`;

export const DISABLE_ITEM_STATUS_TOOLTIP = `Private items always have a pending status. ${getMakeItemPublicActionText(
  true
)}`;
export const DISABLE_ITEM_STATUS_TOOLTIP_NEW = `Draft items always have a pending status. ${getMakeItemPublicActionTextNew(
  true
)}`;

export const DISABLE_VISIBILITY_TOGGLE_TOOLTIP = `Options of private items must be private. Convert the parent item to public to update this option's visibility.`;

// TODO remove next 4 items when YC_ITEM_SHARING ff is removed
export const PUBLISH_ITEM_LINK_COPY = 'Make public to all collaborators';
export const PRIVATE_ITEM_LINK_COPY = 'Make private to me';
export const PUBLIC_ITEM_ICON_COPY = 'Public to all collaborators';
export const PRIVATE_ITEM_ICON_COPY = 'Private';
export const PUBLISH_ITEM_LINK_COPY_NEW = 'Publish Draft';
export const DRAFT_ITEM_LINK_COPY_NEW = 'Create as draft';
export const PUBLISH_ITEM_ICON_COPY_NEW = 'Published';
export const DRAFT_ITEM_ICON_COPY_NEW = 'Draft';
export const DRAFT_ITEM_TOOLTIP =
  'Draft item has limited availability to the team.  Shareable with select teammates.';
export const PUBLISH_ITEM_TOOLTIP = 'Published item is available to all teammates.';
