import { MouseEvent, ReactNode } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { importEstimateNavigationLink } from '../../analytics/analyticsEventProperties';
import { EstimateType, JoinProjectRoutes } from '../../api/gqlEnums';
import { MANUAL, TYPE } from '../../constants';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../utilities/clickHandler';
import { generateSharedPath } from '../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';

import styles from './EstimateImportLinkStyles';

type EstimateImportLinkProps = {
  classes: Classes<typeof styles>;
  children: ReactNode;
  estimateId?: UUID;
  estimateType?: EstimateType;
};

const EstimateImportLink: React.FC<EstimateImportLinkProps> = ({
  classes,
  children,
  estimateId,
  estimateType,
}) => {
  const navigate = useNavigate();
  const sendAnalytics = useAnalyticsEventHook();
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();

  return (
    <Button
      data-cy="link-importEstimate"
      variant="flat"
      classes={{ root: classes.textButton }}
      onClick={(e: MouseEvent) => {
        if (!estimateId) return;
        const path = generateSharedPath(JoinProjectRoutes.IMPORT_ESTIMATE, {
          projectId,
          milestoneId,
          estimateId,
        });
        clickLinkKeyHandler(navigate, e, path, `${TYPE}=${estimateType}&${MANUAL}=${false}`);
        e.stopPropagation();
        sendAnalytics(importEstimateNavigationLink(estimateType, estimateId));
      }}
    >
      {children}
    </Button>
  );
};

export default withStyles(styles)(EstimateImportLink);
