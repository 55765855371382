import { useState } from 'react';

import { Search } from '@material-ui/icons';

import { TEAM } from '../../../constants';
import { SortDirection } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import { useSearchCompanies } from '../../Collaborators/CollaboratorsPage/CollaboratorsPageUtils';
import { TextInput } from '../../scales';
import { TEAM_SETTINGS_DEFAULTS } from '../../Team/TeamLists/TeamListUtils';
import { useProjectCompaniesQuery } from '../hooks/GetProjectCompaniesQuery';

import CompaniesList from './CompaniesList';
import {
  CompaniesListHeaders,
  CompaniesSortDataType,
  ProjectCompanyLocal,
  useSortCompanies,
} from './CompaniesListUtils';

const CompaniesListData = () => {
  // Company searching amd sorting
  const projectId = getProjectIdFromUrl();
  const { location } = window;
  const [settings, setSettings] = usePersistentStates(
    location,
    TEAM,
    TEAM_SETTINGS_DEFAULTS,
    `${projectId} - Team - `
  );
  const collaboratorsQueryResult = useCollaboratorsQuery(projectId);
  const collaborators =
    collaboratorsQueryResult.data?.collaborators ??
    collaboratorsQueryResult.previousData?.collaborators ??
    [];
  const projectCompaniesQueryResult = useProjectCompaniesQuery(projectId);
  const companies =
    projectCompaniesQueryResult.data?.projectCompanies ??
    projectCompaniesQueryResult.previousData?.projectCompanies ??
    [];
  const { search: searchTerm } = settings;
  const onChangeSearchTerm = (s: string) => {
    setSettings({ search: s });
  };

  const searchedCompanies = useSearchCompanies(companies, searchTerm);

  const [sortData, setSortData] = useState<CompaniesSortDataType>({
    sortKey: CompaniesListHeaders.Company,
    sortDirection: SortDirection.SORT_ASCENDING,
  });
  const collaboratorCompanies: ProjectCompanyLocal[] = searchedCompanies.map((co) => ({
    ...co,
    filteredCollaborators: collaborators.filter((c: Collaborator) =>
      c.user.email.includes(`@${co.company.domain}`)
    ),
  }));
  const sortedCompanies: ProjectCompanyLocal[] = useSortCompanies(collaboratorCompanies, sortData);

  return (
    <div className="pl-6">
      <div className="flex w-64 flex-row">
        <TextInput
          aria-label="search collaborators text fields"
          label="Search"
          onChange={(value) => {
            onChangeSearchTerm(value.trim());
          }}
          onClear={() => onChangeSearchTerm('')}
          placeholder="Search text fields"
          startAdornment={<Search />}
          value={searchTerm}
        />
      </div>
      <CompaniesList
        companies={sortedCompanies}
        searchTerm={searchTerm}
        setSortData={setSortData}
        sortData={sortData}
      />
    </div>
  );
};
export default CompaniesListData;
