import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';
import { mentionStyle } from '../../../../Events/ItemHistoryListComment/ItemHistoryListCommentStyles';
import { NOTES_COL_WIDTH_COLLAPSED, ROW_HEIGHT } from '../CostReportListRowStyles';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    reportNoteCell: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontWeight: 400,
    },
    reportNoteCellHovered: {
      backgroundColor: theme.palette.joinGrey200,
    },
    notesHoverSidebar: {
      height: ROW_HEIGHT,
      width: NOTES_COL_WIDTH_COLLAPSED / 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addNoteIcon: {
      color: theme.palette.buttonGrey,
      width: 12,
      height: 12,
    },
    circlePlaceholder: {
      width: 32,
      height: '100%',
    },
    noteText: {
      marginLeft: 8,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      whiteSpace: 'pre-wrap', // preserve line breaks and other whitespace in comments
    },
    addNoteLink: {
      display: 'flex',
      width: '100%',
      alignItems: 'inherit',
      color: theme.palette.disabledGrey,
      '& > svg': { color: theme.palette.disabledGrey },
    },
    noteContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'inherit',
    },
    newNoteContainer: {
      display: 'flex',
      alignItems: 'start',
      gap: '6px',
      width: '100%',
      maxWidth: 310,
      hyphens: 'auto',
      padding: '6px 8px',
    },
    mentionStyle,
    costsChangedIcon: {
      height: 15,
      width: 15,
      marginRight: 4,
      color: theme.palette.text.primary,
    },
    costsChangedText: {
      color: theme.palette.buttonGrey,
    },
  });

export default styles;
