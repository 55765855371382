import { FC } from 'react';

import { Checkbox, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, LayersOutlined } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import Tag from '../../ItemsList/Tag';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import ProjectCategorizationsStyles from './ProjectCategorizationsStyles';

type ProjectCategorizationsEntryProps = {
  classes: Classes<typeof ProjectCategorizationsStyles>;
  categorization: StandardCategorization;
  setOpenProjectCategorizationInfo: (categorizationID: UUID) => void;
  onToggle?: (value: string) => void;
  isSelected: boolean;
};

const ProjectCategorizationsEntry: FC<ProjectCategorizationsEntryProps> = ({
  classes,
  categorization,
  setOpenProjectCategorizationInfo,
  onToggle,
  isSelected,
}) => {
  const { name, id, description, createdFrom, levels } = categorization.categorization;
  const isMultilevel = levels > 1;
  const sendAnalytics = useSendAnalyticsEventHook();

  return (
    <div data-cy="standard-categorization-list-entry" className={classes.container}>
      <div className={classes.projectRow}>
        <Checkbox
          checked={isSelected}
          classes={{ root: 'self-center w-12' }}
          disableRipple
          inputProps={{ title: 'checkbox' }}
          onClick={() => onToggle?.(id)}
        />
        <div className={`${classes.smallDataElement} ${classes.nameCell}`}>
          <Typography className={`${classes.title} ${classes.textOverflow}`} variant="title">
            {name}
            {createdFrom?.deprecated && (
              <Tag
                name="Removed"
                tooltipText="This standard was removed. If unselected, this categorization cannot be added back and will need to be recreated."
                onMouseEnter={() => {
                  sendAnalytics(categorizationEvent(CategorizationEvent.MANAGE_REMOVED_TOOLTIP));
                }}
              />
            )}
          </Typography>
          <Typography variant="body2" className={classes.textOverflow}>
            {description}
          </Typography>
        </div>
        <div>
          {isMultilevel && (
            <NormalTooltip title="Multi-level categorization">
              <LayersOutlined />
            </NormalTooltip>
          )}
          <IconButton onClick={() => setOpenProjectCategorizationInfo(id)} disableRipple>
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default withStyles(ProjectCategorizationsStyles)(ProjectCategorizationsEntry);
