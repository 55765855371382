import type { Identifier, XYCoord } from 'dnd-core';
import { FC, useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

import { Avatar, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DragHandle } from '@material-ui/icons';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import styles from './MetricsDragChipStyles';

type MetricsDragChipProps = {
  classes: Classes<typeof styles>;
  id: UUID;
  index: number;
  sortCards: (dragIndex: number, hoverIndex: number) => void;
  onSelect: (id: UUID) => void;
  text: string;
  unitCountText: string;
};

interface DragItem {
  id: string;
  index: number;
  type: string;
}

const MetricsChip = {
  METRICS_CHIP: 'metricsChip',
};

const MetricsDragChip: FC<MetricsDragChipProps> = ({
  classes,
  id,
  index,
  sortCards,
  onSelect,
  text,
  unitCountText,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const type = MetricsChip.METRICS_CHIP; // Necessary to have fully functioning drag and drop
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: MetricsChip.METRICS_CHIP,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      sortCards(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      className={`${classes.drag} ${classes.metricsRow}`}
      data-cy="metricsDragChipRow-selected"
      data-handler-id={handlerId}
      ref={ref}
      style={{ opacity }}
    >
      <Chip
        avatar={
          <Avatar className={`${classes.avatar} ${classes.joinPrimaryColor}`}>
            <DragHandle />
          </Avatar>
        }
        classes={{
          root: `${classes.root} ${classes.metricsLine}`,
          label: `${classes.chipLabel}  ${classes.joinPrimaryColor}`,
        }}
        className={classes.chip}
        deleteIcon={
          <div className={classes.flexDisplay}>
            <div className={`${classes.chipUnitCount} ${classes.joinPrimaryColor}`}>
              {unitCountText}
            </div>
            <VisibilityOff
              className={`${classes.delete} ${classes.joinPrimaryColor}`}
              data-cy={`metricsDragChipIcon-${text}`}
            />
          </div>
        }
        key={text}
        label={text}
        onDelete={() => onSelect(id)}
        title={text}
      />
    </div>
  );
};

export default withStyles(styles)(MetricsDragChip);
