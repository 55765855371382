import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import TruncateTextStyles, { MIDDLE_TRUNC_PADDING } from './TruncateTextStyles';

const END = 'END';
export const MIDDLE = 'MIDDLE';

type TruncateVariant = typeof END | typeof MIDDLE;

type TruncateTextProps = {
  classes: Classes<typeof TruncateTextStyles>;
  maxWidth: number;
  textClass?: string;
  text: string;
  onClick?: () => void;
  variant?: TruncateVariant;
};

const TruncateText: FC<TruncateTextProps> = ({
  classes,
  maxWidth,
  textClass,
  text,
  onClick,
  variant = END,
}) => {
  const isTruncateMiddle = variant === MIDDLE;

  const getTextSectionClass = (truncBeginning?: boolean) =>
    `${textClass} ${classes.trunc} ${truncBeginning ? classes.truncBeginning : classes.truncEnd}`;

  const maxSectionWidth = isTruncateMiddle ? maxWidth / 2 - MIDDLE_TRUNC_PADDING : maxWidth;

  return (
    <div className={classes.textContainer}>
      <div style={{ maxWidth: maxSectionWidth }}>
        <Typography className={getTextSectionClass()} onClick={onClick}>
          {text}
        </Typography>
      </div>
      {isTruncateMiddle && (
        <div style={{ maxWidth: maxSectionWidth }}>
          <Typography className={getTextSectionClass(true)} onClick={onClick}>
            {text}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default withStyles(TruncateTextStyles)(TruncateText);
