import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    badge: {
      backgroundColor: theme.palette.badgeColor,
    },
    buttonSpacing: {
      marginLeft: 8,
    },
    dot: {
      backgroundColor: theme.palette.badgeColor,
      height: 8,
      margin: `10px 10px 0px 0px`,
      overflow: 'hidden',
      textIndent: '200%',
      whiteSpace: 'nowrap',
      width: 8,
    },
    invisible: {
      display: 'none',
    },
  });

export default styles;
