import { gql } from '@apollo/client';

import { TermKey } from '../../../api/gqlEnums';
import { ITEM_MARKUPS, NULL_ID } from '../../../constants';
import {
  CostReportColumnType,
  GetMilestonesMarkupsQuery,
  GetMilestonesMarkupsQueryVariables,
  MarkupDisplayType,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { nonZeroCost } from '../../../utilities/items';
import { UserReportComment } from '../../ReportsTab/ReportHooks';
import CostReportCategoriesTree, {
  NodeData,
  addCounts,
  addIsMarkup,
} from '../CostReportList/CostReportCategoriesTree';
import { ColumnMarkupCost } from '../CostReportList/CostReportList/CostReportListUtils';

export type MarkupOrder = {
  incorporatedMarkups: string[];
  markups: string[];
  contingencies: string[];
  allowances: string[];
};

export const nodeMatchComparator = (newNode: Partial<NodeData>) => (node: Partial<NodeData>) =>
  node.id === newNode.id;

const getNonZeroVarianceMarkups = (columnMarkups: ColumnMarkupCost[]) => {
  const nonZeroMarkupNames: string[] = [];
  const varianceColumnMarkups = columnMarkups.filter(
    (columnMarkup) => columnMarkup.columnInput.type === CostReportColumnType.VARIANCE
  );
  varianceColumnMarkups.forEach((columnMarkup) => {
    const { markupContribution } = columnMarkup;
    const isNonZeroMarkup = nonZeroCost(markupContribution.range);
    if (isNonZeroMarkup) nonZeroMarkupNames.push(markupContribution.markupName || '');
  });

  return nonZeroMarkupNames;
};

export const addMilestoneMarkupsToMarkupOrder = (
  milestone: MilestoneMarkups,
  order: MarkupOrder
) => {
  const { activeEstimate, budget } = milestone || {};
  orderEstimateMarkups(order, activeEstimate);
  orderEstimateMarkups(order, budget);
};

export const orderEstimateMarkups = (
  order: MarkupOrder,
  estimate: MilestoneMarkups['activeEstimate'] | null
) => {
  if (!estimate) return;
  const { incorporatedMarkups, markups, contingencies, allowances } = order;
  const estimateMarkups: { name: string }[] = [];
  const contingencyMarkups: { name: string }[] = [];
  const allowanceMarkups: { name: string }[] = [];
  estimate.markups.forEach((m) => {
    if (m?.displayType === MarkupDisplayType.MARKUP) estimateMarkups.push(m);
    if (m?.displayType === MarkupDisplayType.CONTINGENCY) {
      contingencyMarkups.push(m);
      // if there are any draws they should come immediately after
      contingencyMarkups.push({ ...m, name: `${m.name} Draw` });
    }
    if (m?.displayType === MarkupDisplayType.ALLOWANCE) {
      allowanceMarkups.push(m);
      // if there are any draws they should come immediately after
      allowanceMarkups.push({ ...m, name: `${m.name} Draw` });
    }
  });

  addMarkupOrder(markups, estimateMarkups);
  addMarkupOrder(contingencies, contingencyMarkups);
  addMarkupOrder(allowances, allowanceMarkups);
  addMarkupOrder(incorporatedMarkups, estimate.incorporatedMarkups);
};

export const addMarkupOrder = (order: string[], markups: { name: string }[]) =>
  markups.forEach((markup) => {
    if (!order.includes(markup.name)) {
      order.push(markup.name);
    }
  });

export const insertKeyMarkups = (
  markupsTree: CostReportCategoriesTree,
  columnMarkups: ColumnMarkupCost[],
  termStore: TermStore | null = null,
  hideZeroVariance = false,
  includeIncorpratedMarkups: boolean,
  userReportComments?: UserReportComment[]
) => {
  const nonZeroNames = hideZeroVariance ? getNonZeroVarianceMarkups(columnMarkups) : [];
  columnMarkups.forEach(({ markupContribution, columnInput, columnKey }) => {
    const { markupId, markupName: name = '', isIncorporated, displayType } = markupContribution;
    const isNonZeroMarkup = nonZeroNames.includes(name);
    const insertMarkup = hideZeroVariance ? isNonZeroMarkup : true;
    let displayName = name;
    if (markupId === NULL_ID)
      displayName = termStore ? `Item ${termStore.titleCase(TermKey.MARKUP)}` : ITEM_MARKUPS;

    const categories = getMarkupCategories(
      markupId,
      displayName,
      includeIncorpratedMarkups,
      isIncorporated,
      displayType === MarkupDisplayType.DRAW
    );

    const reportComment = userReportComments?.find((c) => c.commentLineID === markupId);

    if (insertMarkup) {
      markupsTree.insert(
        {
          ...markupContribution,
          directCostRange: markupContribution.range,
          range: markupContribution.range,
          categories,
          columnKey,
        },
        columnInput,
        false,
        reportComment
      );
    }
  });
};

const getMarkupCategories = (
  id: string,
  name: string,
  includeIncorpratedMarkups: boolean,
  isMarkupIncorporated: boolean,
  isDraw: boolean
) => {
  const categories: Category[] = [];

  // The order of entries in the category array (incorp, milestone, {id, name}) is important
  // They need to be in this order so the hierarchy of categories is correct in the UI
  // Eg
  // Markups
  //    Incorporated Item Markups
  //        My Incorporated Markup #1
  //        ...
  //    Milestone Estimate Markups
  //        My Milestone Markup #1
  //        ...
  if (includeIncorpratedMarkups) {
    if (isDraw) {
      categories.push({
        id: 'Incorporated Item Draws',
        name: 'Incorporated Item Draws',
      } as Category);
    } else if (isMarkupIncorporated) {
      categories.push({
        id: 'Incorporated Item Markups',
        name: 'Incorporated Item Markups',
      } as Category);
    } else if (!isMarkupIncorporated) {
      categories.push({
        id: 'Milestone Estimate Markups',
        name: 'Milestone Estimate Markups',
      } as Category);
    }
  }
  categories.push({ id, name } as Category); // the "category" is used to match
  return categories;
};

export const combineMarkups = (order: MarkupOrder, milestoneMarkups: ColumnMarkupCost[] = []) => {
  const markupNameToIdMap = new Map<string, UUID>();
  return milestoneMarkups
    .map((mm) => {
      const { markupContribution } = mm;
      const { markupId, markupName } = markupContribution;

      const matchedId = markupName && markupNameToIdMap.get(markupName);
      if (!matchedId) {
        markupNameToIdMap.set(markupName || '', markupId);
      }
      return {
        ...mm,
        markupContribution: {
          ...markupContribution,
          directCostRange: markupContribution.range,
          markupId: matchedId || markupId,
          name: markupName,
        },
      };
    })
    .sort((a, b) => {
      const aIdx = getMarkupIndex(
        order,
        a.markupContribution.displayType,
        a.markupContribution.name,
        a.markupContribution.isIncorporated
      );
      const bIdx = getMarkupIndex(
        order,
        a.markupContribution.displayType,
        b.markupContribution.name,
        b.markupContribution.isIncorporated
      );
      return aIdx - bIdx;
    });
};

const getMarkupIndex = (
  markupOrder: MarkupOrder,
  displayType: MarkupDisplayType,
  name?: string,
  isIncorporated = false
): number => {
  let offset = 0;
  let order = markupOrder.markups;
  if (isIncorporated) {
    order = markupOrder.incorporatedMarkups;
    offset += 1;
  }

  if (displayType === MarkupDisplayType.CONTINGENCY) order = markupOrder.contingencies;
  if (displayType === MarkupDisplayType.ALLOWANCE) order = markupOrder.allowances;
  if (displayType === MarkupDisplayType.DRAW) {
    // check if this is a contingency or allowance draw
    const contingencyIndex = markupOrder.contingencies.findIndex((c) => c === name);
    if (contingencyIndex >= 0) return contingencyIndex;

    const allowanceIndex = markupOrder.allowances.findIndex((c) => c === name);
    if (allowanceIndex >= 0) return allowanceIndex;
  }

  return offset + order.findIndex((n) => n === name);
};

// NOTE: We need to request the id, and estimateID props
// If we don't then apollo can't make an id for the apollo cache
// and the names of these markups are incorrect
const getMilestonesMarkupsQuery = gql`
  query getMilestonesMarkups($projectID: UUID!) {
    milestonesMarkups(projectID: $projectID) {
      id
      activeEstimate {
        markups {
          name
          id
          estimateId
          displayType
        }
        incorporatedMarkups {
          name
          id
          estimateId
          displayType
        }
      }
      budget {
        markups {
          name
          id
          estimateId
          displayType
        }
        incorporatedMarkups {
          name
          id
          estimateId
          displayType
        }
      }
    }
  }
`;

export type MilestoneMarkup = GetMilestonesMarkupsQuery['milestonesMarkups'][number];
export const useMilestonesMarkupsQuery = (projectID: UUID) =>
  useQuery<GetMilestonesMarkupsQuery, GetMilestonesMarkupsQueryVariables>(
    getMilestonesMarkupsQuery,
    {
      variables: { projectID },
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const setMarkupTree = (
  tree: CostReportCategoriesTree,
  nameOrder: MarkupOrder,
  markups: ColumnMarkupCost[],
  termStore: TermStore,
  canViewMarkups: boolean,
  hideZeroVariance: boolean,
  includeIncorporatedMarkups: boolean,
  userReportComments?: UserReportComment[]
) => {
  if (canViewMarkups) {
    const columnMarkupContributions = combineMarkups(nameOrder, markups);
    insertKeyMarkups(
      tree,
      columnMarkupContributions,
      termStore,
      hideZeroVariance,
      includeIncorporatedMarkups,
      userReportComments
    );
  }
};

export const useMarkupTrees = (
  markupSummaryData: NodeData,
  contingencySummaryData: NodeData | undefined,
  allowanceSummaryData: NodeData | undefined,
  markupCosts: ColumnMarkupCost[],
  termStore: TermStore,
  canViewMarkups: boolean,
  hideZeroVariance: boolean,
  includeIncorporatedMarkups: boolean,
  milestoneMarkups: MilestoneMarkups[],
  currentMilestoneID?: UUID,
  userReportComments?: UserReportComment[]
) => {
  const order: MarkupOrder = {
    incorporatedMarkups: [],
    markups: [],
    contingencies: [],
    allowances: [],
  };
  const currentMilestoneMarkups = milestoneMarkups.find((m) => m.id === currentMilestoneID);
  if (currentMilestoneID && currentMilestoneMarkups) {
    addMilestoneMarkupsToMarkupOrder(currentMilestoneMarkups, order);
  } else {
    milestoneMarkups.forEach((m) => addMilestoneMarkupsToMarkupOrder(m, order));
  }

  const setTree = (
    tree: CostReportCategoriesTree,
    markups: ColumnMarkupCost[],
    includeIncorporatedMarkups: boolean
  ) => {
    setMarkupTree(
      tree,
      order,
      markups,
      termStore,
      canViewMarkups,
      hideZeroVariance,
      includeIncorporatedMarkups,
      userReportComments
    );
  };
  const markupsTree = new CostReportCategoriesTree({ ...markupSummaryData }, nodeMatchComparator);
  const markups = markupCosts.filter(
    (s) =>
      s.markupContribution.displayType === MarkupDisplayType.MARKUP ||
      (s.markupContribution.displayType === MarkupDisplayType.DRAW &&
        s.markupContribution.isIncorporated)
  );
  const contingencyTree = new CostReportCategoriesTree(
    contingencySummaryData ? { ...contingencySummaryData } : undefined,
    nodeMatchComparator
  );
  const contingencies = isFilterMarkupContingencies(markupCosts, MarkupDisplayType.CONTINGENCY);

  const allowanceTree = new CostReportCategoriesTree(
    allowanceSummaryData ? { ...allowanceSummaryData } : undefined,
    nodeMatchComparator
  );
  const allowances = isFilterMarkupContingencies(markupCosts, MarkupDisplayType.ALLOWANCE);

  setTree(markupsTree, markups, includeIncorporatedMarkups);
  setTree(contingencyTree, contingencies, false);
  setTree(allowanceTree, allowances, false);

  addIsMarkup(markupsTree.root, true);
  addIsMarkup(contingencyTree.root, true);
  addIsMarkup(allowanceTree.root, true);

  addCounts(markupsTree.root, -1);
  addCounts(contingencyTree.root, -markupsTree.nodeCount - 1);
  addCounts(allowanceTree.root, -markupsTree.nodeCount - contingencyTree.nodeCount - 1);

  return {
    markupsTree,
    contingencyTree,
    allowanceTree,
  };
};

// used to filter for
const isFilterMarkupContingencies = (
  markupCosts: ColumnMarkupCost[],
  displayType: MarkupDisplayType.ALLOWANCE | MarkupDisplayType.CONTINGENCY
) => {
  const contingencies = markupCosts.filter((s) => s.markupContribution.displayType === displayType);

  // we also want to include any draws whose parent is a contingency
  const draws = markupCosts.filter(
    (draw) =>
      draw.markupContribution.displayType === MarkupDisplayType.DRAW &&
      !!contingencies.find(
        (contingency) =>
          draw.markupContribution.markupName ===
            `${contingency.markupContribution.markupName} Draw` &&
          !draw.markupContribution.isIncorporated
      )
  );

  return [...contingencies, ...draws];
};
