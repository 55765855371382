import { brushX } from 'd3-brush';
import { type ScaleTime } from 'd3-scale';

import type {
  BaseDataBaseSelection,
  BrushGroupSelection,
  BrushSelection,
  DivSelection,
  TimelineData,
  TimelineOptions,
} from './timeline.types';
import {
  BRUSH_EVENT_TOP,
  BRUSH_HEIGHT,
  BRUSH_INNER_GAP,
  BRUSH_INNER_HEIGHT,
  EVENT_SIZE,
  EVENT_SIZE_HALF,
  createXScale,
  isInterval,
  updateHorizontalLine,
} from './utils';
import { addBrushHandle, addToday, handleBrush } from './zoomUtils';

// brush handle shape's path
const HANDLE_PATH = [
  'M -8 3 V 21 C -8 23 -8 23 -6 23 H -2 C 0 23 0 23 0 21 V 3 C 0 1 0 1 -2 1 H -6 C -8 1 -8 1 -8 3 Z M -5 16 V 8 Z M -3 16 V 8',
  'M 0 3 V 21 C 0 23 0 23 2 23 H 6 C 8 23 8 23 8 21 V 3 C 8 1 8 1 6 1 H 2 C 0 1 0 1 0 3 Z M 3 16 V 8 Z M 5 16 V 8',
];

export function updateBrush(
  chart: DivSelection,
  brushXScale: ScaleTime<number, number>,
  zoomRange: number[],
  options: TimelineOptions,
  updateOuter: (domain: Date[]) => void
): void {
  const { brushHeight, dataFlat, isPrint } = options;
  if (isPrint) return;
  const timelineXScaleFixed = createXScale(dataFlat, options.items, options.width, options.today);

  let brushContainer: DivSelection = chart.select('#brush-container');

  // Create brush container
  if (brushContainer.empty()) {
    brushContainer = chart
      .append('div')
      .attr('id', 'brush-container')
      .style('width', '100%')
      .style('padding-top', '100%')
      .style('height', `${brushHeight}px`)
      .style('padding-top', '6px');
  }

  let brushSvg: BrushSelection = brushContainer.select('#brush-svg');

  // Remove brush if data is empty
  if (!brushSvg.empty() && dataFlat.length < 1) {
    brushSvg.remove();
    return;
  }

  if (brushSvg.empty()) {
    // Create brush svg
    brushSvg = brushContainer
      .append('svg')
      .attr('id', 'brush-svg')
      .attr('width', '100%')
      .attr('height', `${brushHeight}px`);
  }

  const datapoints: BaseDataBaseSelection = brushSvg.selectAll('g.datapoint');
  const brushGroups = datapoints.data(dataFlat, (d: TimelineData) => d.id);

  brushGroups.join(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    function enter(enter: any) {
      // Add svg group for each data point in dataset group to svg
      const groups = enter
        .append('g')
        .attr('class', (d: TimelineData) =>
          isInterval(d) ? 'datapoint interval' : 'datapoint instant'
        )
        // Set x,y for each data point
        .attr(
          'transform',
          (d: TimelineData) =>
            `translate(${timelineXScaleFixed(new Date(d.start).getTime())}, ${
              BRUSH_EVENT_TOP + BRUSH_INNER_GAP
            })`
        );

      // Add circle for each interval data point
      groups
        .filter((d: TimelineData) => isInterval(d))
        .append('circle')
        .attr('r', EVENT_SIZE_HALF)
        .attr('cx', EVENT_SIZE_HALF)
        .attr('cy', EVENT_SIZE_HALF)
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d))
        .style('pointer-events', 'none');

      // Add rect for each instant data point
      groups
        .filter((d: TimelineData) => !isInterval(d))
        .append('rect')
        .attr('height', EVENT_SIZE)
        .attr('width', EVENT_SIZE)
        .attr('x', -EVENT_SIZE_HALF)
        .style('transform-box', 'fill-box')
        .style('transform-origin', 'center')
        .style('transform', 'rotate(45deg)')
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d))
        .style('pointer-events', 'none');

      return groups;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    function update(update: any) {
      // Update x,y for each data point
      update.attr(
        'transform',
        (d: TimelineData) =>
          `translate(${timelineXScaleFixed(new Date(d.start).getTime())}, ${
            BRUSH_EVENT_TOP + BRUSH_INNER_GAP
          })`
      );

      return update;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    function exit(exit: any) {
      return exit.remove();
    }
  );

  // Add today line
  const { today } = options;
  if (today)
    addToday(today, brushSvg, timelineXScaleFixed, BRUSH_INNER_GAP, brushHeight - BRUSH_INNER_GAP);

  const existingBrush = brushSvg.select('#brush-group');
  if (!existingBrush.empty()) {
    existingBrush.remove();
  }

  // Create brush
  const brush = brushX().extent([
    [16, BRUSH_INNER_GAP],
    [brushXScale.range()[1], BRUSH_INNER_HEIGHT + 4],
  ]);

  brush.on(
    'brush end',
    handleBrush(brushSvg, brush, updateOuter, brushXScale, timelineXScaleFixed, options)
  );

  // Add brush to band
  brushSvg.insert('g', '.datapoint').attr('id', 'brush-group').call(brush);

  const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');

  updateHorizontalLine(brushGroup, options.width, BRUSH_INNER_GAP);
  updateHorizontalLine(brushGroup, options.width, BRUSH_HEIGHT - BRUSH_INNER_GAP);

  brushGroup
    .select('.selection')
    .attr('class', 'selection stroke-selection-selected fill-selection-selected')
    .attr('height', BRUSH_INNER_HEIGHT)
    .attr('y', BRUSH_INNER_GAP)
    .attr('stroke-opacity', '0.08')
    .attr('fill-opacity', '0.4');

  addBrushHandle(brushGroup, HANDLE_PATH);
  brushGroup.call(brush).call(brush.move, zoomRange);
}
