import { FC, ReactNode } from 'react';

import { Card, Typography } from '@material-ui/core';
import { Style } from '@material-ui/core/styles/createTypography';

import { withStyles } from '../../../theme/komodo-mui-theme';

import { styles } from './EntryCardStyles';

export enum EntryCardVariant {
  ACTIVE,
  HINT,
}

type EntryCardProps = {
  classes: Classes<typeof styles>;
  dataCy?: string;
  description: ReactNode;
  descriptionVariant?: Style | undefined;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  variant?: EntryCardVariant;
};

const EntryCard: FC<EntryCardProps> = ({
  classes,
  dataCy,
  description,
  descriptionVariant = 'subheading',
  icon,
  onClick,
  variant = EntryCardVariant.ACTIVE,
}) => {
  const isActive = variant === EntryCardVariant.ACTIVE;
  const cardStyle = `${classes.card} ${isActive ? classes.cardActive : ''}`;
  return (
    <Card className={cardStyle} data-cy={dataCy} elevation={0} onClick={onClick}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <Typography className={classes.text} variant={descriptionVariant}>
        {description}
      </Typography>
    </Card>
  );
};

export default withStyles(styles)(EntryCard);
