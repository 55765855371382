import { Outlet } from 'react-router-dom';

import { teamViewList } from '../../../analytics/analyticsEventProperties';
import { TEAM, TEAMMATES } from '../../../constants';
import { DD_PERMISSION_ROLES_NAVIGATION } from '../../../features';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import CollaboratorsListData from '../../Collaborators/CollaboratorsList/CollaboratorsListData';
import CompaniesListData from '../../Companies/CompaniesList/CompaniesListData';
import { useProjectCompaniesQuery } from '../../Companies/hooks/GetProjectCompaniesQuery';
import { ButtonBar } from '../../scales';

import TeamListHeader from './TeamListHeader/TeamListHeader';
import styles from './TeamListStyles';
import { TEAM_SETTINGS_DEFAULTS, TeamToggles } from './TeamListUtils';
import TeamNavBar from './TeamNavBar';

type Props = {
  classes: Classes<typeof styles>;
};

const TeamRoute = (props: Props) => {
  // Feature Flag
  const hasNewRolesNavigation = useHasFeature(DD_PERMISSION_ROLES_NAVIGATION);
  // Analytics
  const sendAnalytics = useAnalyticsEventHook();

  const projectId = getProjectIdFromUrl();

  // Old functionality: tabs with toggle options
  const { location } = window;
  const [settings, setSettings] = usePersistentStates(
    location,
    TEAM,
    TEAM_SETTINGS_DEFAULTS,
    `${projectId} - Team - `
  );
  const { view } = settings;

  const { data: { collaborators = [] } = {}, loading: teammatesLoading } =
    useCollaboratorsQuery(projectId);
  const { data: { projectCompanies = [] } = { projectCompanies: [] }, loading: companiesLoading } =
    useProjectCompaniesQuery(projectId);

  const toggleOptions = [
    {
      value: TeamToggles.COLLABORATORS,
      label: `${TEAMMATES} (${collaborators.length})`,
    },
  ];
  if (!companiesLoading)
    toggleOptions.push({
      value: TeamToggles.COMPANIES,
      label: `Companies (${projectCompanies.length})`,
    });

  const setSelected = (view: string) => {
    sendAnalytics(teamViewList(view));
    setSettings({ view, search: '' });
  };

  const selected = toggleOptions.find((o) => o.value === view);
  const isTeammates = selected && selected.value === TeamToggles.COLLABORATORS;
  const isCompanies = selected && selected.value === TeamToggles.COMPANIES;
  const noneLoading = !teammatesLoading && !companiesLoading;

  return (
    <div className={props.classes.containerOuter}>
      <TeamListHeader />
      {hasNewRolesNavigation ? (
        <>
          <TeamNavBar />
          <Outlet />
        </>
      ) : (
        <>
          <div className={`pb-2 pl-6 ${props.classes.containerInputs}`}>
            {noneLoading && (
              <ButtonBar
                options={toggleOptions}
                value={selected?.value || toggleOptions[0].value}
                onChange={setSelected}
              />
            )}
          </div>
          {isTeammates && <CollaboratorsListData />}
          {isCompanies && <CompaniesListData />}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(TeamRoute);
