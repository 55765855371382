import { FC } from 'react';

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import {
  ONBOARDING_STEP2_EXCLUDE_ALL,
  ONBOARDING_STEP2_INCLUDE_ALL,
  ONBOARDING_STEP4_REORDER_CATEGORY,
} from '../../../../actions/actionTypes';
import {
  analyticsEvent,
  importEstimatesReorderInclude,
} from '../../../../analytics/analyticsEventProperties';
import { setReactiveLocal } from '../../../../api/apollo/reactiveVars';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getExistingCategorizationMappings } from '../../utils';
import CategorizationsReorder from '../CategorizationsReorder/CategorizationsReorder';

import styles from './CategorizationSelectAndOrderStyles';
import CategorizationSelectorChip from './CategorizationSelectorChip';

type CategorizationSelectAndOrderProps = {
  classes: Classes<typeof styles>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
};

const CategorizationSelectAndOrder: FC<CategorizationSelectAndOrderProps> = ({
  classes,
  importEstimateKey,
  importEstimateVar,
}) => {
  const sendAnalytics = useAnalyticsEventHook();
  const importEstimate = useReactiveVar(importEstimateVar);
  const { categorizations, mapping, mappingBuiltIn } = importEstimate;
  const excludedCategorizations = categorizations.filter(({ include }) => !include);
  const includedCategorizations = categorizations.filter(
    // to prevent duplicate categorizations hide disabled categorizations
    // we also only want to include the lowest level categorization
    // mapped to our built-ins
    ({ include, existingCategorization, name }) => {
      if (!mappingBuiltIn.has(name)) return include;
      const concurrentBuiltInMappings = getExistingCategorizationMappings(
        mapping,
        mappingBuiltIn,
        categorizations,
        name,
        existingCategorization
      );
      if (concurrentBuiltInMappings.length && concurrentBuiltInMappings[0].name === name) {
        return include;
      }
      return false;
    }
  );
  const setSelectCategorization = (categorization: DraftCategorization, isSelected: boolean) => {
    const cats = [...categorizations].map((c) => {
      const cat = { ...c };
      if (cat.name === categorization.name) {
        cat.include = isSelected;
        if (!isSelected) cat.categoryUpdates = { newCategories: [], categoryReplacements: [] };
        sendAnalytics(importEstimatesReorderInclude(isSelected, cat.name));
      }
      return cat;
    });
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      categorizations: cats,
    });
  };

  const sort = (reordarable: DraftCategorization[]) => {
    const newCategorizations = [...categorizations];
    newCategorizations.sort((a, b) => {
      const aIndex = reordarable.findIndex(({ name }) => name === a.name);
      const bIndex = reordarable.findIndex(({ name }) => name === b.name);
      if (aIndex < bIndex) return -1;
      if (aIndex > bIndex) return 1;
      return 0;
    });

    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      categorizations: newCategorizations,
    });
    sendAnalytics(analyticsEvent(ONBOARDING_STEP4_REORDER_CATEGORY));
  };

  const includeAllCategorizations = () => {
    const cats = [...categorizations].map((c) => {
      const disabled = !!mappingBuiltIn.has(c.name);
      if (disabled) return { ...c };
      return { ...c, include: true };
    });
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      categorizations: cats,
    });
    sendAnalytics(analyticsEvent(ONBOARDING_STEP2_INCLUDE_ALL));
  };

  const excludeAllCategorizations = () => {
    const cats = [...categorizations].map((c) => {
      const disabled = !!mappingBuiltIn.has(c.name);
      if (disabled) return { ...c };
      return {
        ...c,
        include: false,
        categoryUpdates: { newCategories: [], categoryReplacements: [] },
      };
    });
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      categorizations: cats,
    });
    sendAnalytics(analyticsEvent(ONBOARDING_STEP2_EXCLUDE_ALL));
  };

  const excludedCategorizationsLines = excludedCategorizations.map(
    (categorization: DraftCategorization) => (
      <CategorizationSelectorChip
        key={categorization.name}
        categorization={categorization}
        selectCategorization={setSelectCategorization}
      />
    )
  );

  return (
    <div className={classes.fullContainer}>
      <div className={classes.controls}>
        <div className={classes.header}>
          <Typography className={classes.headerText}>Included Columns</Typography>
          <div
            className={classes.controlsButton}
            role="button"
            onClick={includeAllCategorizations}
            onKeyPress={includeAllCategorizations}
            tabIndex={0}
            data-cy="button-include-all-categorizations"
          >
            Include All
          </div>
          <div
            className={classes.controlsButton}
            role="button"
            onKeyPress={excludeAllCategorizations}
            onClick={excludeAllCategorizations}
            tabIndex={0}
            data-cy="button-exclude-all-categorizations"
          >
            Exclude All
          </div>
        </div>
      </div>
      <CategorizationsReorder
        sort={sort}
        mappingBuiltin={mappingBuiltIn}
        setSelectCategorization={setSelectCategorization}
        categorizations={includedCategorizations}
      />
      <Typography className={classes.headerText}>Excluded Columns</Typography>
      <div className={classes.container}>{excludedCategorizationsLines}</div>
    </div>
  );
};

export default withStyles(styles)(CategorizationSelectAndOrder);
