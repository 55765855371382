import { FC, useRef } from 'react';

import { Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import { FilesDialogView } from '../../../../api/apollo/reactiveVars';
import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './FilesDialogUploadButtonStyles';

export const FILES_DIALOG_TAB_KEY = 'FilesDialog_UserSources';

type FilesDialogUploadButtonProps = {
  classes: Classes<typeof styles>;
  onDropFile: (file: File) => void;
};

const FilesDialogUploadButton: FC<FilesDialogUploadButtonProps> = ({ classes, onDropFile }) => {
  // Handle file upload window
  const fileUpload = useRef<HTMLInputElement | null>(null);
  const addFiles = (files?: FileList | null) => {
    if (files) Array.from(files).forEach((file: File) => onDropFile(file));
  };
  const buttonText = FilesDialogView.DROPZONE;
  const onClick = () => {
    fileUpload?.current?.click();
  };

  return (
    <div>
      <Button
        aria-label={buttonText}
        data-cy="upload-button"
        variant="flat"
        className={classes.button}
        onClick={onClick}
        title={buttonText}
      >
        <div className={classes.content}>
          <CloudUpload className={classes.icon} />
          <div className={classes.buttonText}>{buttonText}</div>
        </div>
      </Button>
      <input
        className={classes.hidden}
        id="upload-files"
        type="file"
        onChange={(evt) => {
          if (evt.target.files) {
            addFiles(evt.target.files);
          }
        }}
        ref={fileUpload}
      />
    </div>
  );
};

export default withStyles(styles)(FilesDialogUploadButton);
