import { Link, useNavigate } from 'react-router-dom';

import { BusinessOutlined } from '@material-ui/icons';

import { searchEventTypes } from '../../../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../../../../api/gqlEnums';
import { withStyles } from '../../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../../utilities/routes/links';
import useSendSearchAnalytics from '../../../hooks/useSendSearchAnalytics';

import styles from './RelevantItemStyles';

interface RelevantItemProps {
  classes: Classes<typeof styles>;
  item: ForecastingItem;
  onClick: () => void;
}

const RelevantItem = (props: RelevantItemProps) => {
  const { classes } = props;

  const sendSearchAnalytics = useSendSearchAnalytics();
  const navigate = useNavigate();

  return (
    <button
      className={classes.itemContainer}
      onClick={() => {
        sendSearchAnalytics(searchEventTypes.SEARCH_SELECT_ITEM, {
          item: props.item.id,
          location: 'Global',
        });
        navigate(
          generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
            projectId: props.item.project.id,
            itemId: props.item.id,
          })
        );
      }}
    >
      <div className={classes.leftAlign} style={{ flexGrow: 1 }}>
        <div>
          <div className={`${classes.textLine} ${classes.titleContainer}`}>
            <Link
              className={classes.title}
              onClick={props.onClick}
              to={generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
                projectId: props.item.project.id,
                itemId: props.item.id,
              })}
            >
              {props.item.name}
            </Link>
          </div>
          <div className={`${classes.textLine} ${classes.titleContainer}`}>
            <div className={classes.label}>{props.item.project.name}</div>
          </div>
          <div className={classes.textLine}>
            <div className={classes.label}>{props.item.project.type}</div>
            <BusinessOutlined className={classes.icon} />
          </div>
        </div>
      </div>
    </button>
  );
};

export default withStyles(styles)(RelevantItem);
