import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './ErrorCodeStyles';

interface CodeText {
  [code: number]: string;
}

const messages: CodeText = {
  401: "That page doesn't exist, or you don't have permission to see it.",
  404: "That page couldn't be found.",
  503: 'Thanks for your patience.',
};

const greetings: CodeText = {
  401: 'Oops!',
  404: 'Oops!',
  503: 'Join is currently down for maintenance, but we will be back shortly.',
};

const nextSteps = (code: number) => {
  let next;
  switch (code) {
    case 404:
      next = (
        <Typography>
          {'Try going back to your '}
          <a href="/" target="_top">
            projects page
          </a>
          {' instead.'}
        </Typography>
      );
      break;
    case 503:
      next = (
        <Typography>
          {'Need help? Contact us at '}
          <a href="mailto:support@join.build" target="_top">
            support@join.build.
          </a>
        </Typography>
      );
      break;
    default:
      if (code >= 400 && code < 500) {
        next = (
          <Typography>
            {'Try going back to your '}
            <a href="/" target="_top">
              projects page
            </a>
          </Typography>
        );
      }
      break;
  }

  return next;
};

type ErrorCodeProps = {
  classes: Classes<typeof styles>;
  code: number;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  nextStep?: any;
};

const ErrorCode: FC<ErrorCodeProps> = ({ classes, code, message, nextStep }) => (
  <div className={classes.inner}>
    <div className={classes.inline}>
      <img
        className={classes.img}
        alt="Join"
        src={`/img/logos/${Math.floor(Math.random() * (9 - 1 + 1)) + 1}.png`}
      />
    </div>
    <div className={classes.inline}>
      <Typography variant="title" className={classes.headline}>
        {greetings[code]}
      </Typography>
      <Typography variant="subheading" className={classes.subtitle}>
        {message || messages[code]}
      </Typography>
      <Typography>{`Error code: ${code}`}</Typography>
      {nextStep || nextSteps(code)}
    </div>
  </div>
);

export default withStyles(styles)(ErrorCode);
