import { useLayoutEffect, useRef, useState } from 'react';
import { useButton } from 'react-aria';

import { formatCost } from '../../../utilities/currency';
import { Chip, Tooltip } from '../../scales';

type Props = {
  baselineCost: USCents;
  insights: string[];
  onClick: () => void;
  onDismiss: () => void;
  reason: string;
  title: string;
};

export default function SuggestionCard(props: Props) {
  const [isHeaderClamped, setIsHeaderClamped] = useState(false);
  const [isDescriptionClamped, setIsDescriptionClamped] = useState(false);

  const headerRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    setIsHeaderClamped(
      headerRef.current ? headerRef.current.scrollHeight > headerRef.current.offsetHeight : false
    );
    setIsDescriptionClamped(
      descriptionRef.current
        ? descriptionRef.current.scrollHeight > descriptionRef.current.offsetHeight
        : false
    );
  }, []);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress: props.onClick }, buttonRef);

  return (
    <button
      className="flex w-[350px] flex-col rounded bg-background-primary text-left text-type-primary shadow-button outline-none focus-visible:outline"
      {...buttonProps}
      ref={buttonRef}
    >
      <div className="flex h-32 grow flex-col items-stretch gap-2 p-4">
        <Tooltip content={props.title} isDisabled={isHeaderClamped === false}>
          <h3 className="line-clamp-2 type-heading3" ref={headerRef}>
            {props.title}
          </h3>
        </Tooltip>

        <Tooltip content={props.reason} isDisabled={isDescriptionClamped === false}>
          <div className="flex items-center gap-2">
            <Sparkle className="shrink-0 icon-sm" />
            <div
              className={`type-body3 ${isHeaderClamped ? 'line-clamp-1' : 'line-clamp-2'}`}
              ref={descriptionRef}
            >
              {props.reason}
            </div>
          </div>
        </Tooltip>

        <div className="mt-auto flex flex-wrap gap-2">
          <Chip text={`${formatCost(props.baselineCost, { short: true })} Baseline Cost`} />
          {props.insights.map((i) => (
            <Chip key={i} text={i} />
          ))}
        </div>
      </div>

      <style>
        {`
        .suggestion-card::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 4px solid #FFCF5D;
          border-radius: 4px;
          clip-path: inset(100%);
        }

        // .suggestion-card:hover::before {
        //   animation: clippath 0.75s ease-in-out;
        //   clip-path: unset;
        // }

        // .suggestion-card:focus-within::before {
        //   animation: clippath 2s infinite linear;
        // }

        @keyframes clippath {
          0%   { clip-path: inset(0 0 96% 0); }
          25%  { clip-path: inset(0 0 0 96%); }
          50%  { clip-path: inset(96% 0 0 0); }
          75%  { clip-path: inset(0 96% 0 0); }
          80%  { clip-path: inset(0 0 96% 0); }
          100% { clip-path: inset(0 0 0 0); }
        }
        `}
      </style>
    </button>
  );
}

const Sparkle = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_344_2025)">
      <path
        d="M9.5 4.5L10.125 3.125L11.5 2.5L10.125 1.875L9.5 0.5L8.875 1.875L7.5 2.5L8.875 3.125L9.5 4.5ZM5.75 4.75L4.5 2L3.25 4.75L0.5 6L3.25 7.25L4.5 10L5.75 7.25L8.5 6L5.75 4.75ZM9.5 7.5L8.875 8.875L7.5 9.5L8.875 10.125L9.5 11.5L10.125 10.125L11.5 9.5L10.125 8.875L9.5 7.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_344_2025">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
