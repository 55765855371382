import { FC } from 'react';

import { Chip } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './CategorizationSelectAndOrderStyles';

type CategorizationSelectorChipProps = {
  classes: Classes<typeof styles>;
  key: string;
  categorization: DraftCategorization;
  selectCategorization: (categorization: DraftCategorization, isSelected: boolean) => void;
};

const CategorizationSelectorChip: FC<CategorizationSelectorChipProps> = ({
  classes,
  key,
  categorization,
  selectCategorization,
}) => {
  const { name } = categorization;
  const handleSelection = () => selectCategorization(categorization, true);

  return (
    <div className={classes.autoWidth}>
      <Chip
        classes={{
          root: classes.root,
          label: classes.chipLabel,
        }}
        data-cy={`exclude-${name}`}
        key={key}
        label={name}
        deleteIcon={
          <AddCircleOutlineOutlined
            data-cy={`button-include${name}`}
            className={classes.addRemoveIcon}
          />
        }
        onDelete={() => handleSelection()}
      />
    </div>
  );
};

export default withStyles(styles)(CategorizationSelectorChip);
