import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { Switch } from '../../../scales';
import SelectCostModeData from '../../../Select/SelectCostMode/SelectCostModeData';

type Props = {
  projectID: UUID;
};
export default function Settings(props: Props) {
  const { shouldDisplayCosts, setDisplayCostsToggle, isToggleDisabled } = useShouldDisplayCosts(
    props.projectID
  );

  return (
    <div className="flex h-full flex-col gap-2 py-2" data-cy="settings">
      <SelectCostModeData />
      {setDisplayCostsToggle && (
        <Switch
          checked={shouldDisplayCosts}
          data-cy="display-costs-toggle"
          disabled={isToggleDisabled}
          isFullWidth
          label="Display Costs"
          onChange={setDisplayCostsToggle}
        />
      )}
    </div>
  );
}
