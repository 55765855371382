import { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { TimelineEvent } from '../../../analytics/analyticsEventProperties';
import { NULL_ID } from '../../../constants';
import { TimelineSettings } from '../../Timeline/TimelineUtils';
import useMemoWrapper from '../../useMemoWrapper';

import {
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineType,
} from './timeline/timeline.types';
import { isSettingsTimeline } from './timeline/utils';
import { computeItemsLegend, getFinalHeight, initTimeline } from './TimelineChartUtils';
import TimelineItemsExpandLegend from './TimelineItemsLegend/TimelineItemsExpandLegend';

type TimelineChartProps = {
  activities?: TimelineActivity[];
  data: TimelineData[];
  items?: TimelineItemData[];
  endDate?: string;
  expandedMap: TimelineExpandedMap;
  isPrint?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  maxHeight?: number;
  onAnalytics?: (event: TimelineEvent) => void;
  onExpand: (id: UUID) => void;
  onZoom?: (domain: string[]) => void;
  settings: TimelineSettings;
  startDate?: string;
  today?: string;
  width?: number;
  withoutDueDateCount?: number;
  zoomIn?: TimelineData;
  zoomLineCompressed?: boolean;
};

const TimelineChart = (props: TimelineChartProps) => {
  const {
    activities = [],
    data: dataOuter = [],
    items = [],
    endDate,
    expandedMap,
    isPrint,
    onAnalytics = () => {},
    onExpand: onExpandOuter,
    onZoom: onZoomOuter = () => {},
    settings,
    startDate,
    today,
    width: widthOuter,
    withoutDueDateCount = 0,
    zoomIn,
    zoomLineCompressed = false,
  } = props;
  const timelineRef = createRef<HTMLDivElement>();

  const [data, setData] = useState<TimelineData[]>(dataOuter);

  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  const [width, setWidth] = useState<number | undefined>(widthOuter);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (widthOuter) return;
      const newWidth = timelineRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef, timelineRef.current]);

  useDeepCompareEffect(() => {
    setData(dataOuter);
  }, [dataOuter]);

  const onExpand = ({ id }: TimelineData) => onExpandOuter(id);

  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    onZoomOuter([newMin, newMax]);
  };

  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!width) return;

    const chartProps = {
      width,
      withoutDueDateCount,
      height: getFinalHeight(props),
      data,
      items,
      today,
      expandedMap,
      zoomLineCompressed,
      onExpand,
      onZoom,
      onAnalytics,
      isPrint,
      settings,
    };
    setTimelineChart(initTimeline(timelineRef.current, chartProps, activities));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef?.current, width, data]);

  useEffect(() => {
    if (!timelineRef.current) return;
    timelineChart
      ?.expandedMap(expandedMap)
      .height(getFinalHeight(props))
      .render(timelineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [expandedMap]);

  useEffect(() => {
    if (zoomIn) timelineChart?.onZoomIn(zoomIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [zoomIn]);

  useEffect(() => {
    if (startDate && endDate) {
      const d = { end: endDate, id: NULL_ID, name: '', start: startDate, type: TimelineType.PHASE };
      timelineChart?.onZoomInChart(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [startDate, endDate]);

  useDeepCompareEffect(() => {
    timelineChart?.settings(settings).height(getFinalHeight(props));
  }, [settings]);

  const itemsLegend = useMemoWrapper(computeItemsLegend, items);
  const itemsLegendEl = (
    <TimelineItemsExpandLegend
      data={itemsLegend}
      isTimeline={isSettingsTimeline(settings)}
      timelineHeight={getFinalHeight(props).timelineHeight}
      total={items.length}
      withoutDueDateCount={withoutDueDateCount}
    />
  );

  return (
    <div className="relative block">
      <div ref={timelineRef} id="timeline-chart" className="overflow-hidden" />
      {itemsLegendEl}
    </div>
  );
};

export default TimelineChart;
