import Downshift from 'downshift';
import { FC } from 'react';
import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { AVERAGE } from '../../constants';
import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './AutocompleteStyles';

export type Suggestion = {
  key: string;
  value: string;
  mainText: string;
  secondaryText?: string;
};

type AutocompleteProps = {
  classes: Classes<typeof styles>;
  dataCyMenuItem?: string;
  dataCyTextField?: string;
  defaultValue?: string;
  disabled?: boolean;
  handleBlur: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleInput: (e: any) => void;
  handleSelect: (suggestion: Suggestion) => void;
  isHighlighted?: boolean;
  label?: string;
  placeholder?: string;
  suggestions: Suggestion[];
  value: string;
  variant?: string;
};

const MAX_SUGGESTIONS = 5;

const Autocomplete: FC<AutocompleteProps> = ({
  classes,
  dataCyMenuItem,
  dataCyTextField,
  disabled,
  handleBlur,
  handleInput,
  handleSelect,
  isHighlighted = false,
  label,
  placeholder,
  suggestions = [],
  value = '',
  variant,
}) => {
  const isAverage = variant === AVERAGE;

  return (
    <div className={`${classes.container} ${isAverage && classes.containerHeight}`}>
      <Downshift
        itemToString={(item: Suggestion) => (item ? item.value : '')}
        onSelect={(selectedItem: Suggestion) => {
          if (selectedItem) handleSelect(selectedItem);
        }}
        onOuterClick={handleBlur}
      >
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen }) => {
          const filteredSuggestions = suggestions.slice(0, MAX_SUGGESTIONS);

          return (
            <div className={isHighlighted ? classes.greenBG : undefined}>
              <TextField
                data-cy={dataCyTextField}
                disabled={disabled}
                fullWidth
                label={label}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  className: classes.text,
                  ...getInputProps({
                    onChange: handleInput,
                    onKeyDown: (e: React.KeyboardEvent) => {
                      if (e.key === 'Enter' || e.key === 'Tab') {
                        if (!e.shiftKey) (e.target as HTMLElement).blur();
                        if (highlightedIndex === undefined || highlightedIndex === null) {
                          if (filteredSuggestions.length === 1) {
                            handleSelect(filteredSuggestions[0]);
                          } else {
                            handleBlur();
                          }
                        }
                      }
                    },
                    placeholder,
                    value,
                  }),
                  disableUnderline: true,
                }}
                name="location-input"
              />

              {isOpen && (
                <Paper {...getMenuProps()} className={`${classes.paper}`} square>
                  {filteredSuggestions.map((suggestion: Suggestion, index: number) => {
                    const { key, mainText, secondaryText } = suggestion;
                    return (
                      <MenuItem
                        {...getItemProps({
                          className: `${classes.rootItem} ${classes.rootItemCompact}`,
                          index, // Explicitly specify index to avoid odd highlighting on hover
                          item: suggestion,
                          key,
                          selected: index === highlightedIndex,
                          style: { display: 'flex' },
                        })}
                        component="div"
                        data-cy={dataCyMenuItem}
                        key={key}
                      >
                        <div className={classes.row}>
                          <div>
                            <Typography className={`${classes.name} ${classes.nameBold}`}>
                              {mainText}
                            </Typography>
                            <Typography className={`${classes.name} ${classes.subName}`}>
                              {secondaryText}
                            </Typography>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Paper>
              )}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default withStyles(styles)(Autocomplete);
