import { ReactNode } from 'react';

import Close from '@material-ui/icons/Close';

import { Button, IconButton } from '../../scales';

type Props = {
  children: ReactNode;
  hasActiveFilters: boolean;
  onCloseFiltersPanel: (closePanel: boolean) => void;
  onResetFilters: () => void;
};

export default function AdvancedFilters(props: Props) {
  return (
    <div className="flex w-80 shrink-0 flex-col pr-4">
      <div className="flex w-full items-center gap-1 px-6 py-4">
        <p className="flex-grow type-body1">All filters</p>
        {props.hasActiveFilters && (
          <Button onClick={() => props.onResetFilters()} type="tertiary" label="Clear all" />
        )}
        <IconButton
          aria-label="Close"
          data-cy="close-advancedFilters"
          icon={<Close />}
          onClick={() => props.onCloseFiltersPanel(true)}
          type="secondary"
        />
      </div>
      {props.children}
    </div>
  );
}
