import { FC, useContext, useState } from 'react';

import { Table, TableCell, TableRow } from '@material-ui/core';

import { TermKey } from '../../../api/gqlEnums';
import { VIEW_ONLY } from '../../../constants';
import { PermissionLevel, PermissionResource } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getTextMapForPermissions } from '../../../utilities/permissions/permissions';
import { capitalizeString } from '../../../utilities/string';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import PermissionsTableCheckbox from './PermissionsTableCheckbox';
import PermissionCostModeStyles from './PermissionsTableCostModesStyles';

type PermissionsTableCostModesProps = {
  classes: Classes<typeof PermissionCostModeStyles>;
  editable: boolean;
  hideCosts: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (pID: string, change: any) => void;
  permissions: Permission[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
};

const PermissionsTableCostModes: FC<PermissionsTableCostModesProps> = ({
  classes,
  editable,
  hideCosts,
  onChange,
  permissions,
  settings,
}) => {
  const t = useContext(ProjectTermStore);
  const textMap = getTextMapForPermissions(t);

  const costModeDescription = (resource: string) => {
    const markupsTerm = t.lowerCase(TermKey.MARKUP);
    switch (resource) {
      case PermissionResource.ALLOCATED_MARKUPS_VIEW:
        return `Allocate selected ${markupsTerm} pro-rata. All unallocated ${markupsTerm} will be visible in reports.`;
      case PermissionResource.SEPARATED_MARKUPS_VIEW:
        return `Do not allocate ${markupsTerm}.`;
      case PermissionResource.NO_MARKUPS_VIEW:
        return `${capitalizeString(markupsTerm)} are not displayed or included in totals.`;
      default:
        return undefined;
    }
  };

  const isCostModePermission = (p: Permission) =>
    p.resource === PermissionResource.ALLOCATED_MARKUPS_VIEW ||
    p.resource === PermissionResource.SEPARATED_MARKUPS_VIEW ||
    p.resource === PermissionResource.NO_MARKUPS_VIEW;

  const sortCostModePermissions = (a: Permission, b: Permission) => {
    const mapCostModePermissionToOrder = (resource: PermissionResource) => {
      switch (resource) {
        case PermissionResource.ALLOCATED_MARKUPS_VIEW:
          return 0;
        case PermissionResource.SEPARATED_MARKUPS_VIEW:
          return 1;
        case PermissionResource.NO_MARKUPS_VIEW:
          return 2;
        default:
          return 3;
      }
    };
    const aOrder = mapCostModePermissionToOrder(a.resource);
    const bOrder = mapCostModePermissionToOrder(b.resource);
    if (aOrder < bOrder) return -1;
    if (bOrder < aOrder) return 1;
    return 0;
  };

  const costModePermissions = permissions
    .filter(isCostModePermission)
    .sort(sortCostModePermissions);
  const savedCostModePermissions = costModePermissions.filter((p) => p.level === VIEW_ONLY);
  const [selectedCostModePermissions, setSelectedCostModePermissions] = useState([
    ...savedCostModePermissions.map((p) => p.id),
  ]);
  const disableCheckedboxes = selectedCostModePermissions.length === 1;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onChangeCostPermissions = (permissionID: string, value: any) => {
    // keeps track of unpersisted changes in order to prevent disabling all cost modes
    const newEnabledPermissions = selectedCostModePermissions.filter((id) => id !== permissionID);
    if (value && value.level === VIEW_ONLY) newEnabledPermissions.push(permissionID);
    setSelectedCostModePermissions(newEnabledPermissions);
    onChange(permissionID, value);
  };

  return (
    <>
      <Table className={editable ? classes.table : classes.minimalTable}>
        <TableRow>
          <TableCell className={`${editable ? ' w-1/4' : 'w-1/2'} text-xs font-light`}>
            <div className="p-1"> Markup display options</div>
          </TableCell>
          {editable ? (
            costModePermissions.map((p: Permission) => (
              <TableCell key={p.resource} className="w-1/4">
                <PermissionsTableCheckbox
                  isDisabled={
                    hideCosts ||
                    (disableCheckedboxes && selectedCostModePermissions.some((id) => id === p.id))
                  }
                  isSelected={p.level === PermissionLevel.VIEW_ONLY}
                  onChange={onChangeCostPermissions}
                  permission={p}
                  settings={settings}
                  subText={costModeDescription(p.resource)}
                  text={textMap(p.resource) || p.resource}
                />
              </TableCell>
            ))
          ) : (
            <TableCell>
              <div className={`${editable ? '' : 'p-2'} text-xs font-light`}>
                {hideCosts ? (
                  <div>None</div>
                ) : (
                  savedCostModePermissions.map((p) => (
                    <div key={p.resource}>{textMap(p.resource) || p.resource}</div>
                  ))
                )}
              </div>
            </TableCell>
          )}
        </TableRow>
      </Table>
    </>
  );
};

export default withStyles(PermissionCostModeStyles)(PermissionsTableCostModes);
