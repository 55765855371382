import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    graph: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 15,
    },
    hintSpace: {
      fontSize: 16,
      textAlign: 'center',
      whiteSpace: 'pre-line', // for line breaks
      ...theme.typography.number,
    },
    newCTA: {
      color: theme.palette.primaryBlue,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: '0.9em',
    },
    root: {
      height: 300,
      padding: '8px 12px',
    },
    superlight: {
      opacity: 0.33,
    },
  });

export default styles;
