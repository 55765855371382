import { Link } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import {
  ALL_MILESTONES,
  CURRENT_MILESTONE,
  DUE_DATE,
  FILTER_TEXT,
  FROM,
  NONE_DUE_DATE,
  NONE_DUE_TEXT,
} from '../../../../constants';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { getItemListLink } from '../../../ItemsList/ItemsListUtils';

export default function TimelineItemsLegend({
  withoutDueDateCount,
}: {
  withoutDueDateCount: number;
}) {
  const toItems = getItemListLink(getProjectIdFromUrl(), {
    [DUE_DATE]: NONE_DUE_DATE,
    [FILTER_TEXT]: NONE_DUE_TEXT,
    [CURRENT_MILESTONE]: [ALL_MILESTONES],
    [FROM]: JoinProjectRoutes.TIMELINE,
  });

  return (
    <div className="ml-auto flex items-center pl-3">
      <Link data-cy="link-no-due-date" target="_blank" to={toItems}>
        <div className="p-1">
          <div className="text-type-link type-label">{`No Due Dates (${withoutDueDateCount})`}</div>
        </div>
      </Link>
    </div>
  );
}
