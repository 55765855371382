import { FC, useEffect, useRef } from 'react';
import * as React from 'react';
import { useEffectOnce } from 'react-use';

import { LinearProgress } from '@material-ui/core';

import './style/grid.scss';
import { GridVariant } from '../../actions/gridAnalytics';
import { useCustomCategorizations } from '../../hooks/useCustomCategorizations';

// eslint-disable-next-line import/no-cycle
import { useCategorizationController } from './hooks/useGridController';
import useWindowResize from './hooks/useWindowResize';
// eslint-disable-next-line import/no-cycle
import { Grid } from './JoinGrid';
import { CategorizationGridPermissions, GridController } from './types';

export interface CategorizationWrapperProps {
  // eslint-disable-next-line react/no-unused-prop-types
  categorizationID?: UUID;
  projectID?: UUID;
  permissions: CategorizationGridPermissions;
  dialogSizeRef: React.RefObject<HTMLDivElement>;
  onClickAddRow?: () => void;
  onClickDeleteRow?: () => void;
  onClickReplace?: () => void;
  gridType: { gridType: string; model: string };
  // eslint-disable-next-line react/no-unused-prop-types
  variant: GridVariant;
  // eslint-disable-next-line react/no-unused-prop-types
  setGridController?: React.Dispatch<React.SetStateAction<GridController | undefined>> | undefined;
}

// Welcome to JoinGrid! For more comprehensive documentation about what is happening here,
// why the grid is structured the way it is, and where to place a given edit or piece
// of functionality, please refer to the documentation at https://github.com/JoinCAD/komodo/wiki/Grids.
// Categorization tables in particular are implemented differently on the controller level
// than estimate or markup tables, in that they do not call the API directly but rather
// only do so on dialog close - hence, `onCloseRef` is defined and called.
const CategorizationWrapper: FC<CategorizationWrapperProps> = (props) => {
  const {
    dialogSizeRef,
    onClickAddRow,
    onClickDeleteRow,
    onClickReplace,
    gridType,
    projectID,
    permissions,
    setGridController,
  } = props;
  const onCloseRef = useRef(() => {});
  const { refetch } = useCustomCategorizations(projectID);
  const { loading, error, data } = useCategorizationController(props, onCloseRef, () => {
    refetch();
  });

  useEffect(() => {
    setGridController?.(data);
  }, [data, setGridController]);

  // When this component unmounts, wipe all the dummy categories we may
  // have created from the grid.
  useEffectOnce(() => () => onCloseRef.current());

  useWindowResize(data, dialogSizeRef);

  if (loading || error || !data) {
    return <LinearProgress hidden={!loading} />;
  }
  const grid = data;

  // Set the initial width of the table to the estimate collapse container
  if (dialogSizeRef.current) {
    grid.setOverallWidth(dialogSizeRef.current.clientWidth, false);
  }

  const editButtons = permissions.canEdit
    ? {
        onAddClick: () => {
          grid.addLine('Button');
          onClickAddRow?.();
        },
        onDeleteClick: () => {
          grid.numSelectedRows = 0;
          grid.previouslySelectedRow = -1;
          grid.deleteLines();
          onClickDeleteRow?.();
        },
        onReplaceClick: onClickReplace,
        replaceCategorization: true,
      }
    : undefined;

  return (
    <div className="join-grid-categorization-table">
      <Grid grid={grid} gridType={gridType} buttons={editButtons} />
    </div>
  );
};

export default CategorizationWrapper;
