import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const HEADER_HEIGHT = 88;

export const titleStyle = createStyles({
  title: {
    display: 'flex',
    flex: '1 0 315px',
    flexDirection: 'column',
    gap: '8px',
  },
});

export const headerStyle = createStyles({
  header: {
    display: 'flex',
    flex: '0 0 auto',
    '@media print': {
      display: 'none',
    },
  },
});

const styles = (theme: KomodoTheme) =>
  createStyles({
    customHeader: {
      padding: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 6,
    },
    ...headerStyle,
    ...titleStyle,
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  });

export default styles;
