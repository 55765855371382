import { Link } from 'react-router-dom';

import { NamedLink } from '../../../utilities/routes/types';

import { useTeamLinks } from './hooks/useTeamLinks';

const TeamNavBar = () => {
  const links = useTeamLinks();

  return (
    <>
      {links.map((link: NamedLink) => {
        const path = link.route.path;
        const name = link.name;
        return (
          <Link key={path} to={path}>
            {name}
          </Link>
        );
      })}
    </>
  );
};

export default TeamNavBar;
