import { FC, useContext } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { TermKey } from '../../../api/gqlEnums';
import {
  COST_ITEM_IN_TRADE,
  COST_ITEM_OUT_OF_TRADE,
  GROSS_VIEW,
  ITEM_OPTION_DIRECT_COST,
  NET_VIEW,
} from '../../../constants';
import { DD_PERMISSION_ROLES_CREATE_DELETE, DD_WT_SILVER_BULLET } from '../../../features';
import {
  PermissionGroupType,
  PermissionLevel,
  PermissionResource,
} from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getTextMapForPermissions, settings } from '../../../utilities/permissions/permissions';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Checkbox } from '../../scales';

import PermissionsTableCostModes from './PermissionsTableCostModes';
import PermissionsTableRow from './PermissionsTableRow';
import styles from './PermissionsTableStyles';

type PermissionsTableProps = {
  classes: Classes<typeof styles>;
  editable: boolean;
  group: PermissionGroup;
  hasTrade?: boolean;
  onChange: (id: UUID, { level }: { level: PermissionLevel }, permission?: string) => void;
};

const PermissionsTable: FC<PermissionsTableProps> = ({
  classes,
  editable,
  group,
  hasTrade = false,
  onChange,
}) => {
  const terms = useContext(ProjectTermStore);
  const textMap = getTextMapForPermissions(terms);
  const hasDisplayCostsPermission = useHasFeature(DD_WT_SILVER_BULLET);
  const isPermissionRolesCreateDeleteFeature = useHasFeature(DD_PERMISSION_ROLES_CREATE_DELETE);

  // helpers
  const computePermissionResource = (resource: PermissionResource, level: PermissionLevel) => {
    const p = group.permissions.find((permission: Permission) => permission.resource === resource);
    if (p) {
      return p.level === level;
    }
    return false;
  };
  const noInItemView = computePermissionResource(
    PermissionResource.ITEM_IN_TRADE_LINES,
    PermissionLevel.NONE
  );
  const noMilestoneView = computePermissionResource(
    PermissionResource.MILESTONE_LINES,
    PermissionLevel.NONE
  );
  const noOutItemView = computePermissionResource(
    PermissionResource.ITEM_OUT_OF_TRADE_LINES,
    PermissionLevel.NONE
  );
  const shouldDisableMarkups = noInItemView && noMilestoneView && (noOutItemView || !hasTrade);

  const displayCostPermission = group.permissions.find(
    (permission) => permission.resource === PermissionResource.DISPLAY_COSTS
  );
  const canViewCosts = displayCostPermission?.level === PermissionLevel.VIEW_ONLY;

  // Resources

  if (group && (hasTrade || group.type !== PermissionGroupType.ITEM_OUT_OF_TRADE)) {
    return (
      <div className={classes.root}>
        <Typography variant="subheading" className={classes.title}>
          {`${textMap(group.type as unknown as string) || group.type} permissions`}
          {hasTrade &&
            group.type === PermissionGroupType.ITEM_IN_TRADE &&
            " when user has access to the item's categories"}
          {hasTrade && group.type === PermissionGroupType.ITEM_OUT_OF_TRADE && (
            <span>
              {' when user '}
              <span style={{ textDecoration: 'underline' }}>does not</span>
              {` have access to the item's categories`}
            </span>
          )}
        </Typography>
        {group.type === PermissionGroupType.COST && (
          <>
            <div className={classes.headerText}>
              Report settings: Do you want users in this role to be able to see costs in reports and
              charts with or without markups? If you want someone to always see costs in a specific
              way, select one setting. Or you can choose multiple settings if you want a particular
              role to be able to switch between multiple different cost display modes.
            </div>
            {hasDisplayCostsPermission && displayCostPermission && (
              <Table className={classes.minimalTable}>
                <TableBody>
                  <TableRow>
                    <TableCell className="w-1/2">
                      <div className="p-1">Cost display options</div>
                    </TableCell>
                    <TableCell>
                      {editable ? (
                        <div className="p-2">
                          <Checkbox
                            data-cy="hide-costs-checkbox"
                            isSelected={!canViewCosts}
                            onChange={(checked) =>
                              onChange(
                                displayCostPermission.id,
                                {
                                  level: checked ? PermissionLevel.NONE : PermissionLevel.VIEW_ONLY,
                                },
                                displayCostPermission.description
                              )
                            }
                          >
                            <>
                              <div className="type-heading3">Hide all costs</div>
                              <div className="text-xs font-light">
                                All costs will be hidden. Overrides all cost <br /> configurations
                                below.
                              </div>
                            </>
                          </Checkbox>
                        </div>
                      ) : (
                        <div className="p-2 text-xs font-light">
                          {textMap(displayCostPermission.level)}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            <PermissionsTableCostModes
              editable={editable}
              hideCosts={!canViewCosts}
              onChange={onChange}
              permissions={group.permissions}
              settings={settings(isPermissionRolesCreateDeleteFeature)}
            />
            <Typography variant="subheading" className={classes.title} style={{ paddingTop: 24 }}>
              {terms.sentenceCase(TermKey.ESTIMATE)} permissions
            </Typography>
          </>
        )}
        <Table>
          <TableHead className={classes.head}>
            {editable ? (
              <TableRow className={classes.header}>
                <TableCell className={classes.headerCell}>Feature</TableCell>
                {settings &&
                  settings(isPermissionRolesCreateDeleteFeature).labels.map((label) => (
                    <TableCell key={label} className={classes.headerCell}>
                      {textMap(label) || label}
                    </TableCell>
                  ))}
              </TableRow>
            ) : (
              <TableRow className={classes.header}>
                <TableCell className={classes.headerCell}>Feature</TableCell>
                <TableCell className={classes.headerCell}>Permission Level</TableCell>
                <TableCell className={classes.headerCell}>Description</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {group &&
              group.permissions &&
              group.permissions.map(
                (permission: Permission, index: number) =>
                  (hasTrade || permission.resource !== COST_ITEM_OUT_OF_TRADE) &&
                  permission.resource !== GROSS_VIEW &&
                  permission.resource !== NET_VIEW &&
                  permission.resource !== PermissionResource.ALLOCATED_MARKUPS_VIEW &&
                  permission.resource !== PermissionResource.SEPARATED_MARKUPS_VIEW &&
                  permission.resource !== PermissionResource.NO_MARKUPS_VIEW &&
                  permission.resource !== PermissionResource.DISPLAY_COSTS && (
                    <PermissionsTableRow
                      disabled={
                        (group &&
                          group.type === PermissionGroupType.COST &&
                          permission &&
                          permission.resource === PermissionResource.MARKUPS &&
                          shouldDisableMarkups) ||
                        (group.type === PermissionGroupType.COST && !canViewCosts)
                      }
                      editable={editable}
                      group={group}
                      key={(permission && permission.resource) || index}
                      label={
                        (!hasTrade &&
                          permission.resource === COST_ITEM_IN_TRADE &&
                          textMap(ITEM_OPTION_DIRECT_COST)) ||
                        undefined
                      }
                      onChange={onChange}
                      permission={permission}
                      settings={settings(isPermissionRolesCreateDeleteFeature)}
                    />
                  )
              )}
          </TableBody>
        </Table>
      </div>
    );
  }
  return null;
};

export default withStyles(styles)(PermissionsTable);
