/* eslint-disable no-restricted-globals */
/* eslint-disable no-case-declarations */

// Contains almost all cell-type-specific logic for serializing, deserializing, and
// implementing operations that have different results based on cell type.

import { MutableRefObject } from 'react';

import { FieldType } from '../../../api/gqlEnumsBe';
import {
  ALLOCATED_CELL,
  CATEGORY_CELL,
  COST_TYPES,
  COST_TYPES_DISPLAY,
  INHERITED_MARKUP_CHECKBOX_CELL,
  INTRODUCTION,
  MARKUP_DISPLAY_TYPE,
  MARKUP_FIXED_SCALING_FACTOR,
  MARKUP_PERCENT_SCALING_FACTOR,
  MARKUP_VALUE_CELL,
  MASTERFORMAT_CATEGORIZATION_ID,
  NULL_ID,
  REFERENCE_CELL,
  SELECT_CELL,
  STRING_CELL,
  TOTAL_REF,
  UNIFORMAT_CATEGORIZATION_ID,
} from '../../../constants';
import { Scale } from '../../../enums';
import { MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import { arrayEqual, compareCategories } from '../../../utilities/compare';
import { compareStrings } from '../../../utilities/comparison';
import {
  formatCommas,
  formatCost,
  getCurrencyScale,
  scrubNumberString,
} from '../../../utilities/currency';
import { parseCost, removeYear } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { filterOutInvalidMarkupRefs } from '../../Inputs/InputsSelectReference/utils';
import {
  Borders,
  GenericGridState,
  GridData,
  GridType,
  Position,
  ReferenceDisplay,
} from '../types';

// this is taken from services/cost/formulas/operators.go
// we exclude +/- and () because these are valid for inputting positive/negative costs
export const formulaOperators = /[*/^%]/;

export const typeToEditCell = (type: string) => {
  switch (type) {
    case FieldType.CATEGORY:
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
    case FieldType.DECIMAL:
    case FieldType.MARKUP_VALUE:
    case FieldType.NUMBER:
    case FieldType.STRING:
      return true;
    default:
      return false;
  }
};

export const isActivatableCell = (type: string) => {
  switch (type) {
    case FieldType.CATEGORY:
    case FieldType.REFERENCE:
    case FieldType.SELECT:
      return true;
    default:
      return false;
  }
};

export const isCurrencyField = (type: string | FieldType) =>
  type === FieldType.CURRENCY || type === FieldType.CURRENCY_9;

export const isJSONCell = (type: string) => {
  switch (type) {
    case SELECT_CELL:
    case MARKUP_VALUE_CELL:
    case REFERENCE_CELL:
      return true;
    default:
      return false;
  }
};

export const cellTypesAssignable = (source: string, dest: string) => {
  // Allow markup cells to fill between each other, but not otherwise.
  if (isJSONCell(source) || isJSONCell(dest)) return source === dest;

  switch (source) {
    // Allow category cells to fill strings, but not numbers.
    case CATEGORY_CELL:
      return dest === CATEGORY_CELL || dest === STRING_CELL;
    // Allow number cells to fill anything but categories.
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
    case FieldType.DECIMAL:
    case FieldType.NUMBER:
      return dest !== CATEGORY_CELL;
    // Allow string cells to fill anything.
    default:
      return true;
  }
};

// Controls the default value
export const emptyMarkupValueCell: MarkupValueCell = { type: MarkupType.PERCENT, number: 0 };
export const emptyCellValue = (type: string): GridCellValue => {
  switch (type) {
    case CATEGORY_CELL:
      return { search: '' };
    case SELECT_CELL:
      return { type: MarkupType.PERCENT };
    case MARKUP_VALUE_CELL:
      return emptyMarkupValueCell;
    case REFERENCE_CELL:
      return { type: MarkupType.PERCENT, references: [], filters: [], costTypeFilters: [] };
    default:
      return { string: '', formula: '', formulaDisplay: [] };
  }
};

// Controls what gets dispatched to the backend
export const serializeAPICell = (type: string, value?: GridCellValue | null): string => {
  switch (type) {
    case CATEGORY_CELL:
      return JSON.stringify(value || emptyCellValue(type));
    default:
      const regularCell = { string: '', formula: '' };
      if (value && 'string' in value) {
        regularCell.string = value.string;
      }
      if (value && 'formula' in value) {
        regularCell.formula = value.formula;
      }
      return regularCell.formula || regularCell.string;
  }
};

// Controls what gets copied to the clipboard
export const serializeClipboardCell = (type: string, cellValue: GridCellValue): string => {
  switch (type) {
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
      if ('string' in cellValue && 'formula' in cellValue) {
        const { string, formula } = cellValue;
        if (formula) return formula.trim();

        return formatCost(string.trim(), {
          scale: getCurrencyScale(type),
          showAllDigits: true,
          showCurrencySymbol: false,
        });
      }
      return '';
    case FieldType.CATEGORY:
      if ('category' in cellValue && 'search' in cellValue) {
        const { category, search } = cellValue;
        const categoryName =
          category && category.name.trim() !== '' ? ` - ${category.name.trim()}` : '';
        if (category && category.name === INTRODUCTION) {
          // Introduction doesn't have a number. Blah.
          return category.name;
        }
        return category ? category.number.trim().concat(categoryName) : search.trim();
      }
      return '';
    case FieldType.SELECT:
      return 'type' in cellValue && cellValue.type.length ? cellValue.type[0] : '';
    case FieldType.MARKUP_DISPLAY_TYPE:
      return 'displayType' in cellValue && cellValue.displayType
        ? cellValue.displayType.toString()
        : '';
    case FieldType.MARKUP_VALUE:
    case FieldType.REFERENCE:
      return JSON.stringify(cellValue);
    case FieldType.NUMBER:
      return 'string' in cellValue ? formatCommas(cellValue.string) : '0';
    default:
      if ('formula' in cellValue && cellValue.formula) return cellValue.formula.trim();

      if ('shouldExcludeFromAllocation' in cellValue) {
        return getShouldExcludeFromAllocationFromCell(cellValue).toString();
      }
      // if the cell is a string value with multiple lines then we want
      // to encapsilate the value in double quotes otherwise it won't paste back in properly
      // excel also works this way
      const value = 'string' in cellValue ? cellValue.string.trim() : '';
      if (value && value.includes('\n')) return `"${value}"`;
      return value;
  }
};

// get the value of a markup when pasting in data
// if the markup is copied from another cell then
// parse the input json object
// if the markup is just a number then use that value
const getMarkupValue = (
  input: string,
  column: number,
  row: number,
  state?: GenericGridState
): MarkupValueCell | undefined => {
  // check if the pasted value is a number
  if (state) {
    const { cellData } = state;
    const cellInRow = cellData[row];
    const currentCell =
      cellData.length > row && cellInRow && cellInRow.length > column
        ? cellInRow[column].data.value
        : { type: null, disabled: false };

    const type = 'type' in currentCell ? currentCell.type : null;
    const disabled = 'disabled' in currentCell ? currentCell.disabled : false;

    if (Number(input) && type) {
      const number = Number(input);
      // if the markup is a percent then we need to scale the input value so it appears correct
      // ie if a user enters 50 then it would appear as .05 without scaling it.
      const scaleFactor =
        type === MarkupType.PERCENT ? MARKUP_PERCENT_SCALING_FACTOR : MARKUP_FIXED_SCALING_FACTOR;

      return { disabled, number: number * scaleFactor, type };
    }

    // check if pasted value is a json object
    if (input.length < 1 || input[0] !== '{') return undefined;
    return JSON.parse(input);
  }
  return undefined;
};

// Controls how a paste is interpreted
export const deserializeClipboardCell = (
  type: string,
  input: string,
  column: number,
  row: number,
  state?: GenericGridState
): GridCellValue | undefined => {
  switch (type) {
    case MARKUP_DISPLAY_TYPE:
      if (isEnumValue(MarkupDisplayType, input)) {
        return { displayType: input };
      }
      return undefined;
    case CATEGORY_CELL:
      return { search: input };
    case SELECT_CELL:
      if (input && input[0] === 'F') return { type: MarkupType.FIXED };
      if (input && input[0] === 'P') return { type: MarkupType.PERCENT };
      return undefined;
    case MARKUP_VALUE_CELL:
      return getMarkupValue(input, column, row, state);
    case REFERENCE_CELL:
      if (input.length < 1 || input[0] !== '{') return undefined;
      return JSON.parse(input);
    case ALLOCATED_CELL:
      const shouldExcludeFromAllocation = JSON.parse(input.toLowerCase());
      return { shouldExcludeFromAllocation };
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
      return { string: input, formula: '', formulaDisplay: [] };
    default:
      return { string: input, formula: '', formulaDisplay: [] };
  }
};

// Used to transform a cell value string into something that is ready to be used in a
// mutateData() call. Note that this is NOT the same as converting the CellValue into an
// API string - this makes a cellValue from the textfield string.
export const postprocessCost = (newValue: string, scale: number = Scale.CURRENCY): string => {
  const number = parseCost(newValue, scale);
  // if there are invalid characters or the parsed value is NaN, return the original string value
  if (formulaOperators.test(newValue) || isNaN(number)) {
    return newValue;
  }
  return String(number);
};

// Used to transform a cell value string into something that is ready to be used in a
// mutateData() call. Note that this is NOT the same as converting the CellValue into an
// API string - this makes a cellValue from the textfield string.
export const postprocessNumber = (newValue: string) => {
  const scrubbedString = scrubNumberString(newValue, false);

  if (!isNaN(scrubbedString)) {
    return scrubbedString;
  }
  return newValue;
};

// Used to transform a cell value string into something that is ready to be used in a
// mutateData() call. Note that this is NOT the same as converting the CellValue into an
// API string - this makes a cellValue from the textfield string.
export const postprocessPercent = (s: string) => {
  if (!isNaN(Number(s))) {
    return String(Number(s) * 10000000);
  }
  return s;
};

export const getEmptyEstimateLine = (data: GridData) =>
  data.columns.map((f) => serializeAPICell(f.type));

export const getFilledEstimateLine = (
  data: GridData,
  values: (GridCellValue | undefined)[],
  offset: number
) =>
  data.columns.map((f, j) =>
    j < offset ? serializeAPICell(f.type) : serializeAPICell(f.type, values[j - offset])
  );

export const getBorders = (
  selected: boolean,
  start: Position,
  end: Position,
  i: number,
  j: number
): Borders => {
  if (!selected) {
    return { top: false, right: false, left: false, bottom: false };
  }
  return {
    top: i === start.row,
    right: j === end.column,
    left: j === start.column,
    bottom: i === end.row,
  };
};

export const affectsCosts = (type: string) => type !== STRING_CELL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
function cast<T>(...args: any): T[] {
  return [...args];
}

// Determines whether two cell values are equal for the purpose of triggering a re-render or API update.
export const compareValues = (
  type: string,
  a: GridCellValue,
  b: GridCellValue | undefined
): boolean => {
  if (!b) return false;
  switch (type) {
    case CATEGORY_CELL:
      const [c1, c2] = cast<GridCategoryCell>(a, b);
      return c1.category || c2.category
        ? compareCategories(c1.category, c2.category)
        : c1.search === c2.search;
    case SELECT_CELL:
      const [t1, t2] = cast<MarkupTypeCell>(a, b);
      return t1.type === t2.type;
    case MARKUP_VALUE_CELL:
      const [v1, v2] = cast<MarkupValueCell>(a, b);
      return v1.type === v2.type && v1.number === v2.number;
    case REFERENCE_CELL:
      const [r1, r2] = cast<MarkupReferenceCell>(a, b);
      return (
        r1.type === r2.type &&
        arrayEqual(r1.references, r2.references) &&
        arrayEqual(r1.filters, r2.filters)
      );
    case STRING_CELL:
      const [s1, s2] = cast<RegularCell>(a, b);
      return s1?.string?.trim() === s2?.string?.trim();
    case INHERITED_MARKUP_CHECKBOX_CELL:
      const [i1, i2] = cast<MarkupCheckboxCell>(a, b);
      return i1.checked === i2.checked;
    case FieldType.DECIMAL:
    case FieldType.NUMBER:
      const [n1, n2] = cast<RegularCell>(a, b);
      // if we're updating a cell, then the new cell value
      // may not yet have a formula.  the value might be the formula
      const n1Value = n1.formula || n1.string;
      const n2Value = n2.formula || n2.string;
      return n1Value === n2Value;
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
      // a = newValue, b = currentValue
      const [cu1, cu2] = cast<RegularCell>(a, b);
      const cu1Value = getCurrencyCellValue(cu1);
      const cu2Value = getCurrencyCellValue(cu2);
      return cu1Value === cu2Value;
    default:
      return false;
  }
};

export function getCurrencyCellValue(cell: RegularCell) {
  // if we're updating a cell, then the new cell value
  // may not yet have a formula.  the value might be the formula

  if (cell.formula) return cell.formula;
  // remove all non-numeric characters except '-' (for negative numbers) to compare the number strings
  // if removing all non-numeric charcaters reults in an emtpy string, use the original string
  const cellValue = cell.string?.replaceAll(/[^\d-]/g, '') ?? '';
  return cellValue || cell.string;
}

export function isRegularCell(cell?: GridCellValue): cell is RegularCell {
  return !!cell && 'string' in cell && cell.string !== undefined;
}

export function isRegularOrMarkupCell(cell?: GridCellValue): cell is RegularCell & MarkupValueCell {
  return !!cell && 'string' in cell && 'formula' in cell;
}

export function isMarkupType(cell?: GridCellValue): cell is MarkupTypeCell {
  return !!cell && 'type' in cell;
}

export function isMarkupDisplayType(cell?: GridCellValue): cell is MarkupDisplayTypeCell {
  return !!cell && 'displayType' in cell;
}

export function isMarkupValue(cell?: GridCellValue): cell is MarkupValueCell {
  return !!cell && 'number' in cell;
}

export function isMarkupReference(cell?: GridCellValue): cell is MarkupReferenceCell {
  return !!cell && 'references' in cell && 'filters' in cell;
}

export function isInheritedMarkupCheckbox(cell?: GridCellValue): cell is MarkupCheckboxCell {
  return !!cell && 'checked' in cell;
}

export function isCategoryCell(cell?: GridCellValue): cell is CategoryCell {
  return !!cell && 'search' in cell;
}

export function isSourceCell(cell?: GridCellValue): cell is SourceCell {
  return !!cell && 'sourceIDs' in cell;
}

export function isDisplayTypeCell(cell?: GridCellValue): cell is MarkupDisplayTypeCell {
  return !!cell && 'displayType' in cell;
}

// Helper function for markups table returns true if user is pasting markup references in another table,
// and returns false if user is pasting references in the same table.
export function isAnotherTable(lines: GridLine[], input: MarkupReferenceCell) {
  if (lines.some((line) => input.references.some((reference) => reference === line.id)))
    return false;
  return true;
}

export const getFilterIds = (categories: Category[]) => {
  const filterIds: string[] = [];
  categories.forEach((c) => {
    if (c.id === NULL_ID) {
      if (c.categorization) {
        filterIds.push(c.categorization.id);
      }
    } else {
      filterIds.push(c.id);
    }
  });
  return filterIds;
};

export const getFiltersText = (categories: Category[]) => {
  let filtersText = '';
  if (categories.length === 0) return filtersText;

  // Map categorizations -> categories
  const categorizationMap: Map<string, Categorization> = new Map();
  const categoryMap: Map<string, Category[]> = new Map();
  categories.forEach((c) => {
    if (c.categorization) {
      categorizationMap.set(c.categorization.id, c.categorization);
      // eslint-disable-next-line no-shadow
      let categories = categoryMap.get(c.categorization.id);
      if (categories) {
        categories.push(c);
      } else {
        categories = [c];
      }
      categoryMap.set(c.categorization.id, categories);
    }
  });

  // Get sorted list of categorizations
  const categorizations: Categorization[] = [];
  const keys = categorizationMap.values();
  let c = keys.next();
  while (!c.done) {
    categorizations.push(c.value);
    c = keys.next();
  }
  categorizations.sort((a, b) => {
    if (a.id === UNIFORMAT_CATEGORIZATION_ID) return -1;
    if (b.id === UNIFORMAT_CATEGORIZATION_ID) return 1;
    if (a.id === MASTERFORMAT_CATEGORIZATION_ID) return -1;
    if (b.id === MASTERFORMAT_CATEGORIZATION_ID) return 1;
    return a.name && b.name && a.name < b.name ? -1 : 1;
  });

  filtersText += 'This markup only applies to costs in these categories:';
  // Add categories for each categorization to filters text
  categorizations.forEach((categorization: Categorization) => {
    const catList = categoryMap.get(categorization.id);
    if (catList) {
      catList.sort((a, b) => compareStrings(a.number, b.number));
    }
    filtersText += '\n';
    filtersText += `${categorization.name && removeYear(categorization.name)}: ${
      catList && catList.map((f) => f.number || f.name).join(', ')
    }`;
  });
  return filtersText;
};

export const formatMarkupRefPrefix = (
  currentMarkup: Pick<Markup, 'source' | 'displayType'>,
  isInherited: boolean,
  hasIncorporatedMarkups: boolean
) => {
  if (currentMarkup.source?.itemID != null) {
    if (currentMarkup.displayType === MarkupDisplayType.DRAW) {
      return 'D';
    }
    return 'IM';
  }
  if (isInherited) {
    return 'P';
  }
  if (hasIncorporatedMarkups) {
    return 'MM';
  }
  return 'M';
};

export const formatRefsToNames = (
  markups: { id: UUID }[],
  currentMarkup: Pick<
    Markup,
    'type' | 'markupReference' | 'source' | 'costTypeFilters' | 'displayType'
  >,
  isInherited: boolean,
  hasIncorporatedMarkups: boolean
) => {
  const appliesTo = currentMarkup.markupReference?.appliesTo || [];
  // if this is an incorporated markup, or a milestone markup on an item (inherited)
  // then we are on the third subtotal
  const hasIncorporatedMarkupsAndSources = hasIncorporatedMarkups && !currentMarkup?.source;
  const hasIncorporatedMarkupsFromItems = hasIncorporatedMarkups && !currentMarkup?.source?.itemID;
  const hasTwoLevels =
    isInherited || hasIncorporatedMarkupsAndSources || hasIncorporatedMarkupsFromItems;
  // it's possible that the only references for a markup are not valid
  // this might happen when pasting a markup that references other markups
  // between two different estimates
  const hasRefs = filterOutInvalidMarkupRefs(appliesTo, markups).length > 0;
  if (!hasRefs && currentMarkup.type === MarkupType.PERCENT) {
    return [ReferenceDisplay.NOT_APPLIED];
  }
  const includesAllCostTypes = currentMarkup.costTypeFilters?.length === COST_TYPES.length;
  const includesNoCostTypes = !currentMarkup.costTypeFilters?.length;
  const isIncorporatedItemMarkup = !!currentMarkup?.source?.itemID;

  if (!isIncorporatedItemMarkup && currentMarkup.type === MarkupType.FIXED) {
    return hasTwoLevels ? [ReferenceDisplay.S1_S2] : [ReferenceDisplay.S1];
  }

  const referenceDisplayNames = [];
  if (currentMarkup.markupReference?.item) {
    const item = currentMarkup.markupReference.item;
    if (item.isDeprecated) {
      referenceDisplayNames.push(`${ReferenceDisplay.DELETED_ITEM}`);
    }
    if (currentMarkup.type === MarkupType.FIXED) {
      return `${ReferenceDisplay.S1} of #${item.number} - ${item.name}`;
    }
    if (appliesTo.includes(ReferenceDisplay.TOTAL)) {
      return `${ReferenceDisplay.TOTAL} of #${item.number} - ${item.name}`;
    }
    if (
      (appliesTo.includes(ReferenceDisplay.S1) && includesNoCostTypes) ||
      !hasRefs ||
      includesAllCostTypes
    ) {
      referenceDisplayNames.push(`${ReferenceDisplay.S1} of #${item.number} - ${item.name}`);
    }
  }

  if (appliesTo.includes(TOTAL_REF)) return ReferenceDisplay.TOTAL;

  if (currentMarkup.displayType === MarkupDisplayType.DRAW && hasIncorporatedMarkups) {
    return ReferenceDisplay.S3;
  }

  // if all cost types are included then we are referencing S1 / S1 & S2
  const includesAllOrNoCostTypes = includesAllCostTypes || includesNoCostTypes;
  if (
    !isIncorporatedItemMarkup &&
    includesAllOrNoCostTypes &&
    appliesTo.includes(ReferenceDisplay.S1)
  ) {
    referenceDisplayNames.push(hasTwoLevels ? ReferenceDisplay.S1_S2 : ReferenceDisplay.S1);
    // otherwise we list out all the cost types
  } else if (!includesAllCostTypes && currentMarkup.costTypeFilters?.length) {
    const costTypeFilters = currentMarkup.costTypeFilters.map((f) => COST_TYPES_DISPLAY.get(f));
    referenceDisplayNames.push(`Cost Type: ${costTypeFilters.join(', ')}`);
  }

  // if this markup is applied to all other markups then call this S2 (or S3 depending on how many markup levels we have)
  const markupIDs = markups.map((m) => m.id);
  const markupRefs = appliesTo.filter((r) => markupIDs.includes(r));
  if (markupRefs.length === markups.length) {
    if (hasTwoLevels) {
      referenceDisplayNames.push(ReferenceDisplay.S3);
    } else {
      referenceDisplayNames.push(ReferenceDisplay.S2);
    }
  } else {
    const markupNumbers: string[] = [];
    markupRefs.forEach((ref) => {
      const idx = markups.findIndex((markup) => ref === markup.id);
      const prefix = formatMarkupRefPrefix(currentMarkup, isInherited, hasIncorporatedMarkups);
      markupNumbers.push(`${prefix}${idx + 1}`);
    });
    referenceDisplayNames.push(...markupNumbers.sort());
  }
  return referenceDisplayNames.join(', ');
};

export const getCypressTag = (linePrefix: string, row: number, columnName: string) =>
  `grid-${linePrefix}${row + 1}-${columnName}`;

export const initializeReferenceOption = (
  line: GridLine,
  linePrefix: string,
  lineNumber: number
): SelectableMarkup => {
  // we want to get the description cell for each markup, which represents the markup name
  // however, first cell might be the displayType cell, and that type
  // doesn't have a 'string' property.
  const name = line.cells.find((c) => c && c.value && 'string' in c.value);

  return {
    id: line.id,
    lineNumber: `${linePrefix}${lineNumber + 1}`,
    name: name ?? { value: { disabled: false, formula: '', string: '' } },
  };
};

// Incorporated Item Markups can only apply to the markups from the same item estimate
// we check the selectedLineSourceID to filter out lines from other item estimates (sources)
export const getSelectableMarkups = (
  gridType: GridType | undefined,
  lines: GridLine[],
  linePrefix: string,
  selectedRow: number
) => {
  const selectedLine = lines[selectedRow];
  const selectedLineSourceID = 'source' in selectedLine ? selectedLine.source : undefined;
  const selectableMarkups: SelectableMarkup[] = [];

  lines.forEach((line: GridLine, i: number) => {
    if (
      gridType !== GridType.INCORPORATED_ITEM_MARKUP_GRID ||
      ('source' in line && line?.source === selectedLineSourceID)
    ) {
      selectableMarkups.push(initializeReferenceOption(line, linePrefix, i));
    }
  });
  return selectableMarkups;
};

// When changing a markup check if the user chnaged any source references.
export const sourceFilteresHaveChanged = (
  sourceFilters: ItemLink[],
  newSourceFilters: ItemLink[]
) => {
  if (sourceFilters.length !== newSourceFilters.length) {
    return true;
  }
  return sourceFilters.some(
    (source) => !newSourceFilters.some((newSource) => source.id === newSource.id)
  );
};

export const assignNonNullRef = (
  ref: HTMLTextAreaElement | HTMLInputElement | null,
  mutableRef:
    | MutableRefObject<HTMLTextAreaElement | null>
    | MutableRefObject<HTMLInputElement | null>
) => {
  // eslint-disable-next-line no-param-reassign
  if (ref && mutableRef) mutableRef.current = ref;
};

export const getShouldExcludeFromAllocationFromCell = (cell: GridCellValue) =>
  'shouldExcludeFromAllocation' in cell && !!cell.shouldExcludeFromAllocation;
