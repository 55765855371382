import { ApolloError, useMutation } from '@apollo/client';

import { editCategorizationSchemeAnalytics } from '../../../analytics/analyticsEventProperties';
import {
  UpdateCategorizationMutation,
  UpdateCategorizationMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { updateCategorizationMutation } from '../hooks/queries';

function useUpdateCategorization(
  categorizationId: UUID,
  setErrorMessage: (msg: string) => void,
  onError?: (e: ApolloError) => void,
  projectID?: UUID
) {
  const [updateCategorization] = useMutation<
    UpdateCategorizationMutation,
    UpdateCategorizationMutationVariables
  >(updateCategorizationMutation, {
    onError,
  });
  const triggerAnalytics = useAnalyticsEventHook();
  const submitFunc = (input: Partial<Pick<CategorizationMetadata, 'name' | 'description'>>) =>
    updateCategorization({
      variables: {
        id: categorizationId,
        name: input.name,
        description: input.description,
        projectID,
      },
    }).then((result) => {
      const updated = result.data?.editCategorization;
      if (updated && !('id' in updated)) {
        setErrorMessage('Categorization name already exists. Try a different one.');
      }
      triggerAnalytics(editCategorizationSchemeAnalytics(categorizationId));
    });
  return submitFunc;
}

export default useUpdateCategorization;
