import { FC, useState } from 'react';
import * as React from 'react';

import { useReactiveVar } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  updateProjectDeliveryTypeAnalytics,
  updateProjectDescriptionAnalytics,
  updateProjectNameAnalytics,
  updateProjectShortNameAnalytics,
  updateProjectStatusAnalytics,
} from '../../analytics/analyticsEventProperties';
import { projectCurrencyOpenVar, projectSettingsVar } from '../../api/apollo/reactiveVars';
import {
  NULL_ID,
  PROJECT_CURRENCY,
  PROJECT_LOCATION_CITY,
  PROJECT_SHORTNAME,
  SET_PROJECT_LOCATION_PLACEHOLDER,
  UNTITLED_PROJECT,
} from '../../constants';
import { LocationDetailsInput, ProjectType } from '../../generated/graphql';
import useUpdateProject from '../../hooks/ProjectHooks';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { useGetProjectTypesQuery } from '../../hooks/useGetProjectTypesQuery';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import { withStyles } from '../../theme/komodo-mui-theme';
import { showCurrencyDescription } from '../../utilities/currency';
import {
  computeClearInactive,
  computeProjectTypes,
} from '../Dialogs/DialogsNewProject/DialogsNewProjectUtils';
import ProjectTypeSelector from '../Dialogs/DialogsNewProject/ProjectTypeSelector';
import { SelectLevelsEntry } from '../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import InputsTextAreaStyled from '../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import ErrorTooltip from '../JoinGrid/ErrorTooltip';
import useProjectStatusesQuery from '../ProjectsList/hooks/useProjectStatusesQuery';
import SelectProjectCurrencyButton from '../Select/SelectProjectCurrencyButton/SelectProjectCurrencyButton';
import PlacesAutocompleteWrapper from '../shared-widgets/PlacesAutocompleteWrapper';
import useMemoWrapper from '../useMemoWrapper';

import ChangeProjectCurrencyModal from './ProjectCurrency/ChangeProjectCurrencyModal';
import styles from './ProjectDetailsStyles';
import { useSetProjectType } from './ProjectPropertiesHooks';
import ProjectPropSelector, { ProjectProp } from './ProjectPropSelector';
import Thumbnail from './ProjectThumbnail';

export const MAX_PROJECT_CODE_LENGTH = 5;

type ProjectDetailsProps = {
  canEditCurrency: boolean;
  classes: Classes<typeof styles>;
  editable?: boolean;
  project: ProjectProps;
};

const ProjectDetails: FC<ProjectDetailsProps> = ({
  canEditCurrency,
  classes,
  editable = false,
  project,
}) => {
  // Logic
  const {
    id: projectID,
    location,
    description,
    name,
    type,
    status,
    code,
    projectDeliveryType,
  } = project;
  const hasType = type && type.id !== NULL_ID;

  // Hooks
  const updateProjectProps = useUpdateProject();
  const [projectChanges, setProjectChanges] = useState(project);
  const setProjectType = useSetProjectType();
  const sendAnalytics = useAnalyticsEventHook();
  const settings = useReactiveVar(projectSettingsVar);
  const showProjectCurrency = useReactiveVar(projectCurrencyOpenVar);

  // Project Statuses
  const projectStatuses = useProjectStatusesQuery()?.data?.projectStatuses || [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);

  // Project Types
  const projectTypesResult = useGetProjectTypesQuery(projectID);
  const { data: typesData, loading } = projectTypesResult;
  const projectTypes = typesData?.getProjectTypes || [];
  const loadingTypes = loading;
  const types: ProjectType[] = useMemoWrapper(computeProjectTypes, projectTypes, type);

  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  const closeDialog = () => {
    // Note: Add analytics here in separate PR
    projectCurrencyOpenVar(false);
  };

  return (
    <div className="max-w-[1000px] p-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-24">
          <div className="flex flex-grow flex-col gap-2">
            <div className="flex flex-col gap-0.5">
              <div className="type-label">Project Name*</div>
              <TextField
                data-cy="textField-projectName"
                required
                id="name"
                disabled={!editable}
                InputProps={{
                  className: classes.textFieldStyles,
                  disableUnderline: true,
                  endAdornment: !projectChanges.name?.trim() && (
                    <ErrorTooltip title="Project name is required.">
                      <ErrorOutlineIcon className={classes.errorIcon} />
                    </ErrorTooltip>
                  ),
                }}
                placeholder="Set project name"
                value={editable ? projectChanges.name : name || UNTITLED_PROJECT}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setProjectChanges({ ...projectChanges, name: event.target.value });
                }}
                onBlur={() => {
                  if (projectChanges.name !== project.name && projectChanges.name?.trim()) {
                    sendAnalytics(updateProjectNameAnalytics(project.name, projectChanges.name));
                    updateProjectProps({ id: projectID, name: projectChanges.name });
                  } else {
                    setProjectChanges(project);
                  }
                }}
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLElement) {
                    e.target.blur();
                  }
                }}
                fullWidth
              />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="type-label">Status*</div>
              <ProjectPropSelector
                editable={editable}
                name="status"
                setProjectProp={(p) => {
                  if (project.status && p && project.status.id !== p?.id) {
                    const { id, name, description = '' } = p;
                    sendAnalytics(updateProjectStatusAnalytics(project.status.name, name));
                    updateProjectProps({ id: projectID, statusID: id });
                    setProjectChanges({
                      ...projectChanges,
                      status: { __typename: 'ProjectStatus', id, name, description, type: p.name },
                    });
                  }
                }}
                selectedValue={status?.id}
                values={statuses}
              />
            </div>
            {!loadingTypes && (
              <>
                {(editable || hasType) && (
                  <ProjectTypeSelector
                    editable={editable}
                    name="type"
                    label="Type*"
                    search
                    setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
                      // if the is equals null id this means the user selected
                      // their old existing type that isn't part of our default list
                      if (!p || p?.id === NULL_ID) return;
                      sendAnalytics(updateProjectStatusAnalytics(project?.type?.name, type?.name));
                      setProjectType(projectID, p.id);
                    }}
                    selectedValue={type?.id}
                    values={types}
                  />
                )}
                {(editable || location) && (
                  <div className="flex flex-col gap-0.5">
                    <div className="type-label">{PROJECT_LOCATION_CITY}*</div>
                    <PlacesAutocompleteWrapper
                      defaultValue={location}
                      disabled={!editable}
                      onChange={(locationDetails?: LocationDetailsInput) => {
                        if (locationDetails)
                          updateProjectProps({
                            id: projectID,
                            location: locationDetails.name,
                            lat: locationDetails.lat || undefined,
                            lon: locationDetails.lon || undefined,
                            locationDetails,
                          });
                      }}
                      placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
                      locationRequired
                    />
                  </div>
                )}
              </>
            )}

            {(editable || projectDeliveryType) && (
              <div className="flex flex-col gap-0.5">
                <div className="type-label">Project Delivery Method *</div>
                <ProjectPropSelector
                  editable={editable}
                  name="delivery method"
                  search
                  setProjectProp={(p: ProjectProp | null) => {
                    if (!p || p?.id === NULL_ID) return;
                    sendAnalytics(
                      updateProjectDeliveryTypeAnalytics(
                        project?.projectDeliveryType?.name || '',
                        p.name
                      )
                    );
                    updateProjectProps({
                      id: projectID,
                      projectDeliveryTypeID: p.id,
                    });
                  }}
                  selectedValue={projectDeliveryType?.id}
                  values={projectDeliveryTypes}
                />
              </div>
            )}
            <div className="flex flex-grow gap-2">
              <div className="flex flex-grow flex-col gap-0.5">
                <div className="type-label">{PROJECT_CURRENCY}</div>
                <SelectProjectCurrencyButton
                  canEditCurrency={canEditCurrency}
                  disabled={!canEditCurrency}
                  name={showCurrencyDescription(settings.CURRENCY)}
                  onClick={() => {
                    projectCurrencyOpenVar(true);
                  }}
                />
                {showProjectCurrency && canEditCurrency && (
                  <ChangeProjectCurrencyModal
                    open={showProjectCurrency}
                    onCancel={closeDialog}
                    onClose={closeDialog}
                    canEditCurrency
                    projectID={projectID}
                  />
                )}
              </div>
              {(editable || code) && (
                <div className="flex flex-grow flex-col gap-0.5">
                  <div className="type-label">{PROJECT_SHORTNAME}</div>
                  <TextField
                    data-cy="textField-projectShortname"
                    id="code"
                    disabled={!editable}
                    InputProps={{ className: classes.textFieldStyles, disableUnderline: true }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ maxLength: MAX_PROJECT_CODE_LENGTH }}
                    placeholder={`Set ${PROJECT_SHORTNAME.toLowerCase()}`}
                    value={projectChanges.code || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setProjectChanges({ ...projectChanges, code: event.target.value });
                    }}
                    onBlur={() => {
                      if (projectChanges.code !== project.code) {
                        sendAnalytics(
                          updateProjectShortNameAnalytics(project?.code, projectChanges?.code)
                        );
                        updateProjectProps({ id: projectID, code: projectChanges.code || '' });
                      }
                    }}
                    onKeyPress={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLElement) {
                        e.target.blur();
                      }
                    }}
                    fullWidth
                  />
                </div>
              )}
            </div>
          </div>
          <Thumbnail editable={editable} />
        </div>
        {(editable || description) && (
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Description</div>
            <InputsTextAreaStyled
              data-cy="input-projectDescription"
              editable={editable}
              onChangeComplete={(description: string, descriptionStyled: string) => {
                if (
                  description !== project.description ||
                  descriptionStyled !== project.descriptionStyled
                ) {
                  sendAnalytics(
                    updateProjectDescriptionAnalytics(project.description, description)
                  );
                  updateProjectProps({
                    id: projectID,
                    description,
                    descriptionStyled,
                  });
                }
              }}
              placeholder="Describe this project..."
              textStyled={projectChanges.descriptionStyled || ''}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectDetails);
