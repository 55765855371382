import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { forecastingEventTypes } from '../../../../analytics/analyticsEventProperties';
import { itemSidebarForecastingOpenVar } from '../../../../api/apollo/reactiveVars';
import { ForecastingExploreItemsSortKey } from '../../../../generated/graphql';
import { CloseItemSidebar, OpenItemSidebar } from '../../../Icons/OpenSidebar';
import { SortManager, Table } from '../../../scales';
import Button from '../../common/Button';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';

import CostCell from './cells/CostCell';
import ItemCell from './cells/ItemCell';
import ItemStatusCell from './cells/ItemStatusCell';
import MilestoneDateCell from './cells/MilestoneDateCell';
import ProjectCell from './cells/ProjectCell';

type Props = {
  items: ForecastingItem[];
  loading: boolean;
  onFetchMore: () => void;
  onOpenItemSidebar: (id: UUID) => void;
  searchText: string;
  sortManager: SortManager;
  totalResults?: number;
};

const ForecastingItemsList: FC<Props> = (props) => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();
  const itemIdForecastingReactiveVar = useReactiveVar(itemSidebarForecastingOpenVar);

  return (
    <Table
      entries={props.items.map((item) => {
        const key = item.id;
        return [
          { key, component: <ItemCell item={item} searchText={props.searchText} /> },
          { key, component: <ItemStatusCell status={item.status} /> },
          { key, component: <CostCell cost={item.cost} /> },
          { key, component: <CostCell cost={item.project.runningTotal} /> },
          { key, component: <ProjectCell item={item} /> },
          { key, component: <MilestoneDateCell item={item} /> },
          {
            key,
            component: (
              <Button
                icon={
                  itemIdForecastingReactiveVar === item.id ? (
                    <CloseItemSidebar />
                  ) : (
                    <OpenItemSidebar />
                  )
                }
                onClick={() => {
                  props.onOpenItemSidebar(item.id);
                  sendForecastingAnalytics(forecastingEventTypes.ITEM_DETAIL_CTA, {
                    id: item.id,
                    name: item.name,
                    state: itemIdForecastingReactiveVar === item.id ? 'Close' : 'Open',
                  });
                }}
                type="secondary"
                disabled={!item.hasAccess}
              >
                {itemIdForecastingReactiveVar === item.id ? 'Close' : 'Details'}
              </Button>
            ),
          },
        ];
      })}
      columnWidths={[
        'minmax(300px, 10fr)',
        'max-content',
        'max-content',
        'max-content',
        'minmax(150px, 3fr)',
        'max-content',
        'max-content',
      ]}
      headerContent={[
        {
          copy: `Items ${
            props.totalResults !== undefined
              ? `(${new Intl.NumberFormat().format(props.totalResults)})`
              : ''
          }`,
          key: 'items',
          headerSortKey: ForecastingExploreItemsSortKey.SCORE,
        },
        {
          copy: 'Status',
          key: 'status',
          headerSortKey: ForecastingExploreItemsSortKey.STATUS,
        },
        {
          copy: 'Cost impact',
          key: 'cost-impact',
          headerSortKey: ForecastingExploreItemsSortKey.COST_IMPACT,
        },
        {
          copy: 'Project total',
          key: 'project-total',
          tooltip: 'Running total',
        },
        {
          copy: 'Project',
          key: 'project',
          headerSortKey: ForecastingExploreItemsSortKey.PROJECT_NAME,
        },
        {
          copy: 'Milestone date',
          key: 'milestone-date',
          headerSortKey: ForecastingExploreItemsSortKey.MILESTONE_DATE,
        },
        { copy: '', key: 'expand', centered: true },
      ]}
      loading={props.loading}
      onNeedMoreData={props.onFetchMore}
      sortManager={props.sortManager}
    />
  );
};

export default ForecastingItemsList;
