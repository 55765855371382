import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    style={{ transform: 'scale(0.8) translate(0px, -2px)' }}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.13997 0.875061H1.37609C0.959418 0.875061 0.681641 1.15284 0.681641 1.56951V7.3334C0.681641 7.75006 0.959418 8.02784 1.37609 8.02784H7.13997C7.55664 8.02784 7.83442 7.75006 7.83442 7.3334V1.56951C7.83442 1.22228 7.4872 0.875061 7.13997 0.875061Z"
      fill="black"
    />
    <path
      d="M15.9603 0.875061H10.1964C9.77973 0.875061 9.50195 1.15284 9.50195 1.56951V7.3334C9.50195 7.75006 9.77973 8.02784 10.1964 8.02784H15.9603C16.377 8.02784 16.6547 7.75006 16.6547 7.3334V1.56951C16.6547 1.22228 16.377 0.875061 15.9603 0.875061ZM15.2658 6.63895H10.8908V2.26395H15.2658V6.63895Z"
      fill="black"
    />
    <path
      d="M7.13997 9.97229H1.37609C0.959418 9.97229 0.681641 10.2501 0.681641 10.6667V16.4306C0.681641 16.8473 0.959418 17.1251 1.37609 17.1251H7.13997C7.55664 17.1251 7.83442 16.8473 7.83442 16.4306V10.6667C7.83442 10.2501 7.4872 9.97229 7.13997 9.97229ZM6.44553 15.7362H2.07053V11.3612H6.44553V15.7362Z"
      fill="black"
    />
    <path
      d="M15.9603 9.97229H10.1964C9.77973 9.97229 9.50195 10.2501 9.50195 10.6667V16.4306C9.50195 16.8473 9.77973 17.1251 10.1964 17.1251H15.9603C16.377 17.1251 16.6547 16.8473 16.6547 16.4306V10.6667C16.6547 10.2501 16.377 9.97229 15.9603 9.97229Z"
      fill="black"
    />
  </SvgIcon>
);
