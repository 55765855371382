import { commonLocationDetailsFields, gql } from '../../../../api/graphqlFragments';
import { CommonProjectDeliveryTypeFieldsFragmentDoc } from '../../../../generated/graphql';
import { commonProjectSetting } from '../../../ProjectDisplaySettings/hooks/queries';

export const createProjectTemplateMutation = gql`
  mutation createProjectTemplate($name: String!, $templateID: UUID) {
    createProjectTemplate(name: $name, templateID: $templateID) {
      id
      name
    }
  }
`;

export const deleteProjectTemplateMutation = gql`
  mutation deleteProjectTemplate($templateID: UUID!) {
    deleteProjectTemplate(templateID: $templateID)
  }
`;

export const setProjectTemplatePublicationMutation = gql`
  mutation setProjectTemplatePublication($templateID: UUID!, $published: Boolean!) {
    setProjectTemplatePublication(templateID: $templateID, published: $published) {
      id
      isDraft
    }
  }
`;

export const projectTemplateQuery = gql`
  query projectTemplate($projectID: UUID!) {
    projectTemplate(projectID: $projectID) {
      id
      name
      isDraft
    }
  }
`;

export const projectTemplatesQuery = gql`
  query projectTemplates($companyID: UUID!, $showDrafts: Boolean!) {
    projectTemplates(companyID: $companyID, showDrafts: $showDrafts) {
      id
      name
      isDraft
      project {
        id
        name
        code
        thumbnail
        location
        lat
        lon
        activeMilestone {
          id
          name
          date
          designPhase {
            id
            name
            abbreviation
          }
        }
        status {
          id
        }
        type {
          id
        }
        projectDeliveryType {
          ...commonProjectDeliveryTypeFields
        }
        locationDetails {
          ...commonLocationDetailsFields
        }
      }
      settings {
        ...commonProjectSetting
      }
    }
  }
  ${commonProjectSetting}
  ${CommonProjectDeliveryTypeFieldsFragmentDoc}
  ${commonLocationDetailsFields}
`;

export const updateProjectTemplateNameMutation = gql`
  mutation updateProjectTemplateName($templateID: UUID!, $name: String!) {
    updateProjectTemplateName(templateID: $templateID, name: $name) {
      id
      name
    }
  }
`;
