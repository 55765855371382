import { debounce } from 'lodash';
import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { PROJECT_STATUS, PROJECT_TYPE } from '../../../constants';
import { GetProjectsOverviewQuery } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas, formatCost } from '../../../utilities/currency';
import ChartsPieChart from '../../Charts/ChartsPieChart/ChartsPieChart';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ExecutiveDashboardChartViewToggle from '../ExecutiveDashboardChartViewToggle/ExecutiveDashboardChartViewToggle';
import ExecutiveDashboardDataHeading from '../ExecutiveDashboardDataHeading/ExecutiveDashboardDataHeading';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  BY_PROJECT_STATUS,
  BY_PROJECT_STATUS_TOOLTIP,
  BY_PROJECT_TYPE,
  BY_PROJECT_TYPE_TOOLTIP,
  COST_PROJECTS,
  ChartType,
  ChartView,
  NUM_PROJECTS,
  ProjectsOverview,
  TOTAL_CONSTRUCTION_VOLUME,
  TOTAL_PROJECTS,
  formatProjectsOverviewLegendData,
  formatProjectsOverviewPieChartData,
  populatePieChartInputData,
  sortOverviewData,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectsOverviewStyles from './ExecutiveDashboardProjectsOverviewStyles';

type ExecutiveDashboardProjectsOverviewProps = {
  classes: Classes<typeof ExecutiveDashboardProjectsOverviewStyles>;
  loading?: boolean;
  projectCount: number;
  projectsOverview: GetProjectsOverviewQuery['projectsOverview'] | null;
};

const ExecutiveDashboardProjectsOverview: FC<ExecutiveDashboardProjectsOverviewProps> = ({
  classes,
  loading = false,
  projectCount,
  projectsOverview,
}) => {
  const { totalAPV, projectStatusData, projectTypeData } = projectsOverview || {
    apv: null,
    projectStatusData: null,
    projectTypeData: null,
  };

  const [chartView, setChartView] = useState<ChartView>(COST_PROJECTS);

  const sendAnalytics = useAnalyticsEventHook();

  const projectOverviewPieChart = (chartType: ChartType) => {
    const isTypeChart = chartType === PROJECT_TYPE;
    const isCost = chartView === COST_PROJECTS;
    const sortedStatusData = sortOverviewData(projectStatusData, chartView);
    const sortedTypeData = sortOverviewData(projectTypeData, chartView);
    const data: ProjectsOverview[] | null = isTypeChart ? sortedTypeData : sortedStatusData;
    const caption = isTypeChart ? BY_PROJECT_TYPE : BY_PROJECT_STATUS;
    const hintCaption = isCost ? TOTAL_CONSTRUCTION_VOLUME : NUM_PROJECTS;
    const byProjectTooltip = isTypeChart ? BY_PROJECT_TYPE_TOOLTIP : BY_PROJECT_STATUS_TOOLTIP;

    const { groupedData, formattedOtherData } = populatePieChartInputData(
      data,
      chartType,
      chartView
    );

    const projectsOverviewChartData = formatProjectsOverviewPieChartData(
      groupedData,
      chartType,
      chartView,
      formattedOtherData
    );

    const legendData = formatProjectsOverviewLegendData(groupedData, chartType, chartView);

    const MILLISECONDS = 5000;
    const debouncedAnalytics = debounce(() => {
      sendAnalytics(
        execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_HOVER, {
          chart: {
            chartType: caption,
          },
        })
      );
    }, MILLISECONDS);

    return (
      <div>
        <div className={classes.iconAlign}>
          <Typography variant="caption" className={classes.captionText}>
            {caption}
          </Typography>
          <NormalTooltip title={byProjectTooltip}>
            <Info className={classes.icon} />
          </NormalTooltip>
        </div>
        <div className={`${classes.flexContainer} ${classes.chartContainer}`}>
          {loading ? (
            <ExecutiveDashboardLoadingState />
          ) : (
            <>
              <ChartsPieChart
                data={projectsOverviewChartData}
                hintCaption={hintCaption}
                isCost={isCost}
                legendData={legendData}
                onMouseOver={debouncedAnalytics}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer}>
        <ExecutiveDashboardDataHeading
          label={TOTAL_CONSTRUCTION_VOLUME}
          value={formatCost(totalAPV || 0, { rounded: true })}
        />
        <ExecutiveDashboardDataHeading label={TOTAL_PROJECTS} value={formatCommas(projectCount)} />
      </div>
      <div className={classes.toggleContainer}>
        <ExecutiveDashboardChartViewToggle chartView={chartView} setChartView={setChartView} />
      </div>
      <div className={classes.flexContainer}>
        {projectOverviewPieChart(PROJECT_TYPE)}
        {projectOverviewPieChart(PROJECT_STATUS)}
      </div>
    </div>
  );
};

export default withStyles(ExecutiveDashboardProjectsOverviewStyles)(
  ExecutiveDashboardProjectsOverview
);
