import { FC, useEffect, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import {
  ASSETS_PDF_PAGE_DOWN,
  ASSETS_PDF_PAGE_UP,
  ASSETS_PDF_TYPE_PAGE_NUMBER,
} from '../../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../../analytics/analyticsEventProperties';
import { ARROW_DOWN, ARROW_UP } from '../../../../../constants';
import useAnalyticsEventHook from '../../../../../hooks/useAnalyticsEventHook';
import { useHideOnMouseStop } from '../../../../../hooks/useHideOnMouseStop';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import FullHeight from '../../../../Icons/FullHeightIcon';
import FullWidth from '../../../../Icons/FullWidthIcon';
import NormalTooltip from '../../../../NormalTooltip/NormalTooltip';

import AssetsPDFNavigationStyles from './AssetsPDFNavigationStyles';
import PageNumberInput from './PageNumberInput/PageNumberInput';

type AssetsPDFNavigationProps = {
  classes: Classes<typeof AssetsPDFNavigationStyles>;
  isFullWidth: boolean;
  onFullWidth: () => void;
  pageIndex: number;
  pagesTotal: number;
  setPageIndex: (index: number) => void;
};

const AssetsPDFNavigation: FC<AssetsPDFNavigationProps> = ({
  classes,
  isFullWidth,
  onFullWidth,
  pageIndex,
  pagesTotal,
  setPageIndex,
}) => {
  const sendAnalytics = useAnalyticsEventHook();
  const hideNavComponent = useHideOnMouseStop();
  const [pageNumberFocus, setPageNumberFocus] = useState<boolean>(false);

  const nextDisabled = pageIndex === pagesTotal - 1;
  const previousDisabled = pageIndex === 0;

  const onPageChange = (index: number) => {
    setPageIndex(index);
  };

  const onNextPage = () => {
    onPageChange(pageIndex + 1);
    sendAnalytics(analyticsEvent(ASSETS_PDF_PAGE_DOWN));
  };

  const onPreviousPage = () => {
    onPageChange(pageIndex - 1);
    sendAnalytics(analyticsEvent(ASSETS_PDF_PAGE_UP));
  };

  const onPageNumber = (v: number) => {
    onPageChange(v);
    sendAnalytics(analyticsEvent(ASSETS_PDF_TYPE_PAGE_NUMBER));
  };

  const pdfWidthTooltip = isFullWidth ? 'Fit one page within the window' : 'Fit to window width';

  // Key navigation
  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      switch (e.key) {
        case ARROW_DOWN:
          onNextPage();
          break;
        case ARROW_UP:
          onPreviousPage();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [pageIndex]);

  const navigationContainerStyle =
    hideNavComponent && !pageNumberFocus
      ? classes.navigationContainerHide
      : classes.navigationContainerShow;

  return (
    <div className={`${classes.navigationContainer} ${navigationContainerStyle}`}>
      <PageNumberInput
        onPageChange={onPageNumber}
        pageIndex={pageIndex}
        pagesTotal={pagesTotal}
        setPageNumberFocus={setPageNumberFocus}
        tooltipText="Go to a specific page number"
      />
      <PageNumberInput
        disabled
        onPageChange={onPageChange}
        pageIndex={pagesTotal - 1}
        pagesTotal={pagesTotal}
        tooltipText="Total number of pages in this pdf"
      />
      <NormalTooltip title="Go to previous page">
        <div>
          <IconButton
            className={classes.navigationButtonContainer}
            disabled={previousDisabled}
            onClick={onPreviousPage}
          >
            <KeyboardArrowUp className={classes.navigationButton} />
          </IconButton>
        </div>
      </NormalTooltip>
      <NormalTooltip title="Go to next page">
        <div>
          <IconButton
            className={classes.navigationButtonContainer}
            disabled={nextDisabled}
            onClick={onNextPage}
          >
            <KeyboardArrowDown className={classes.navigationButton} />
          </IconButton>
        </div>
      </NormalTooltip>
      <div className={classes.navigationDivider} />
      <NormalTooltip title={pdfWidthTooltip}>
        <IconButton
          className={`${classes.navigationButtonContainer} ${classes.fitPDFButtonPadding}`}
          onClick={onFullWidth}
        >
          {isFullWidth ? <FullHeight /> : <FullWidth />}
        </IconButton>
      </NormalTooltip>
    </div>
  );
};

export default withStyles(AssetsPDFNavigationStyles)(AssetsPDFNavigation);
