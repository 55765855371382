import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core/';
import {
  FlexibleXYPlot,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';

import { projectSettingsVar } from '../../../api/apollo/reactiveVars';
import { FILTER_TEXT, GROUPBY, SEARCH, STATUS } from '../../../constants';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost, getCurrencySymbol } from '../../../utilities/currency';
import { DataPoint } from '../../frame/FrameSidebarSubtotalChart/FrameSidebarSubtotalChartUtils';
import ItemsIconsMap, { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';

import ChartsItemsSubtotalsStyles from './ChartsItemsSubtotalsStyles';
import { generateLabel, getColor } from './ChartsItemsUtils';

// TYPES
type Point = {
  x: string;
  y: number;
};

const ADDS = 'adds';
const DEDUCTS = 'deducts';

type ChartsItemsSubtotalsProps = {
  classes: Classes<typeof ChartsItemsSubtotalsStyles>;
  itemsUrl?: string;
  statusData: DataPoint[];
  statusOrder: string[];
};

const ChartsItemsSubtotals: FC<ChartsItemsSubtotalsProps> = ({
  classes,
  itemsUrl,
  statusData,
  statusOrder,
}) => {
  const navigate = useNavigate();
  const [hint, setHint] = useState<Point | null>(null);
  const hintX = (hint && hint.x) || undefined;

  const data = useMemo(() => {
    const sortOrder = (a: Point, b: Point) =>
      statusOrder.findIndex((s) => s === a.x) < statusOrder.findIndex((s) => s === b.x) ? -1 : 1;
    const generateReportType = (name: string) =>
      statusData
        .map((report: DataPoint) => {
          const y = Number(name === ADDS ? report.adds : report.deducts);
          const { status } = report;
          const isPositive = y >= 0;
          const labelAnchorY = isPositive ? 'baseline' : 'hanging';
          const yOffset = isPositive ? -6 : 6;
          const isHover = status === hintX;
          const color = getColor(status);
          return {
            status,
            x: status,
            y,
            color,
            opacity: isHover ? 1 : '0.8',
            yOffset,
            style: {
              // this styling only works for labels
              // this is used to find tag in cypress tests
              dominantBaseline: labelAnchorY,
              fontVariant: 'lining-nums tabular-nums',
            },
          };
        })
        .filter((p: Point) => p.y !== 0);
    const adds = generateReportType(ADDS);
    const deducts = generateReportType(DEDUCTS);
    return [...adds, ...deducts].sort(sortOrder);
  }, [hintX, statusOrder, statusData]);

  const hintLabel = generateLabel(statusData, hintX);
  const projectSettings = useReactiveVar(projectSettingsVar);
  const currencyLabel = `Cost Impact in ${getCurrencySymbol(projectSettings.CURRENCY)}`;

  return (
    <div className={classes.root}>
      {data.length ? (
        <div>
          <FlexibleXYPlot
            className={classes.graph}
            height={200}
            margin={{ left: 0, right: 0, top: 24, bottom: 64 }}
            xType="ordinal"
            xDomain={statusOrder}
          >
            <XAxis
              style={{
                stroke: 'none',
              }}
              tick
              tickValues={statusOrder}
              tickLabelAngle={0}
              tickFormat={(status: string) => {
                const icon = <ItemsIconsMap status={status} variant={SMALL} />;
                const color = getColor(status);
                return (
                  <g style={{ color, transformOrigin: '-18px 32px' }} transform="scale(0.12)">
                    {icon}
                  </g>
                );
              }}
            />
            <LabelSeries
              data-cy="graph"
              data={data}
              getLabel={(d: Point) =>
                formatCost(d.y, { short: true, signed: true, showCurrencySymbol: false })
              }
              labelAnchorX="middle"
              stack={false}
            />
            <VerticalBarSeries
              colorType="literal"
              onValueClick={(d: Point) => {
                navigate(
                  `${itemsUrl}&${GROUPBY}[0]=&${FILTER_TEXT}=&${SEARCH}=&${STATUS}[0]=${d.x}`
                );
              }}
              onValueMouseOut={() => {
                setHint(null);
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onValueMouseOver={(d: any, e: any) => setHint({ ...d, e })}
              style={{
                strokeOpacity: 1,
                fillOpacity: 0.8,
                cursor: 'pointer',
              }}
              data={data}
              stack={false}
            />
            <HorizontalGridLines
              stack={false}
              tickTotal={1}
              tickValues={[0]}
              style={{ strokeWidth: 1, stroke: theme.palette.primaryGrey }}
            />
          </FlexibleXYPlot>
          <Typography data-cy="chartLabel-sidebarCurrency" className={classes.hintSpace}>
            {currencyLabel}
          </Typography>
          <Typography className={classes.hintSpace}>{hintLabel}</Typography>
        </div>
      ) : (
        <Typography
          className={classes.newCTA}
          onClick={() => itemsUrl && navigate(itemsUrl)}
          variant="subheading"
        >
          Need add/deducts to see chart
        </Typography>
      )}
    </div>
  );
};

export const ChartsItemsSubtotalsStyled = withStyles(ChartsItemsSubtotalsStyles)(
  ChartsItemsSubtotals
);

export default ChartsItemsSubtotalsStyled;
