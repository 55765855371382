import { FC } from 'react';

import { NamedLink } from '../../utilities/routes/types';
import { getProjectIdFromUrl } from '../../utilities/url';

import CompanySidebar from './CompanySidebar';
import ProjectSidebar from './ProjectSidebar';

type SidebarProps = {
  hasDrawer: boolean;
  links: NamedLink[];
};

const Sidebar: FC<SidebarProps> = ({ hasDrawer, links }) => {
  const projectId = getProjectIdFromUrl();
  if (projectId) return <ProjectSidebar links={links} hasDrawer={hasDrawer} />;
  return <CompanySidebar links={links} />;
};

export default Sidebar;
