import { FC } from 'react';

import { UserReportType } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useCreateUserReport, useUpdateUserReport } from '../../ReportsTab/ReportHooks';

import DialogsReports from './DialogsReports';

type DialogReportsDataProps = {
  allowableReportTypes: UserReportType[];
  cannotShare?: boolean;
  inputReport: UserReportInput;
  onClose: () => void;
  onSuccess?: (r?: UserReport) => void;
  open: boolean;
  setInputReport: (newInput: UserReportInput) => void;
  variant: 'reports-tab' | 'reports-manager';
  hasDistribution?: boolean;
};

const DialogReportsData: FC<DialogReportsDataProps> = ({
  allowableReportTypes,
  cannotShare,
  hasDistribution,
  inputReport,
  onClose,
  onSuccess,
  open,
  setInputReport,
  variant,
}) => {
  const createUserReport = useCreateUserReport({
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.createUserReport);
      setToast({ message: 'Successfully created report' });
    },
  });
  const updateUserReport = useUpdateUserReport({
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.updateUserReport);
    },
  });

  const projectID = getProjectIdFromUrl();
  const onSubmitUserReport = () => {
    if (inputReport.id) updateUserReport(inputReport, projectID);
    else createUserReport(inputReport, projectID);
    onClose();
  };

  return (
    <DialogsReports
      allowableReportTypes={allowableReportTypes}
      cannotShare={cannotShare}
      open={open}
      onClose={onClose}
      onSubmit={onSubmitUserReport}
      inputReport={inputReport}
      setInputReport={setInputReport}
      variant={variant}
      hasDistribution={hasDistribution ?? false}
    />
  );
};
export default DialogReportsData;
