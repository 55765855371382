import { FC } from 'react';

import { GridVariant } from '../../../actions/gridAnalytics';
import {
  AnalyticsEvent,
  setItemEstimateExpanded,
} from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import CostEstimate from '../../estimate/CostEstimate';

import EstimateContentHeader from './EstimateContentHeader';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemsCollapseStyles, { SCROLL_MARGIN } from './ItemsCollapseStyles';

type Props = {
  categories: Category[];
  classes: Classes<typeof ItemsCollapseStyles>;
  estimateCost?: CostRange | CostScalar;
  item: ItemDataQueryItem;
  loading: boolean;
  isOption: boolean;
  isAllExpanded: boolean;
  isExpanded: boolean;
  options: DetailedOption[];
  projectID: UUID;
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void;
  setIsExpanded: (value: boolean) => void;
  setOpenDialogAddOption: (value: boolean) => void;
  itemID: UUID;
  hasGrayBackground: boolean;
};

const EstimateContent: FC<Props> = ({
  categories,
  classes,
  estimateCost,
  loading,
  item,
  isOption = false,
  isAllExpanded,
  isExpanded,
  options,
  projectID,
  sendAnalytics,
  setIsExpanded,
  setOpenDialogAddOption,
  itemID,
  hasGrayBackground,
}) => {
  const activeEstimate = item?.activeEstimate;
  const { canView, inTrade } = usePermissions({ trades: item.categories });
  const canViewItemCost = canView(getItemLinesPermissionResource(inTrade));

  return (
    <ItemDetailsCollapse
      analyticsEvent={setItemEstimateExpanded(!isExpanded)} // update?
      canExpand={canViewItemCost}
      hasGrayBackground={hasGrayBackground}
      headerContent={
        <EstimateContentHeader
          canViewContent={canViewItemCost}
          shouldDisplayCosts // We can only get into EstimateContent with shouldDisplayCosts
          estimateCost={estimateCost}
          hasActiveEstimate
          itemID={itemID}
          options={options}
          projectID={projectID}
          sendAnalytics={sendAnalytics}
          setOpenDialogAddOption={setOpenDialogAddOption}
          scheduleImpact={undefined}
          hasOptions={false}
        />
      }
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      style={{ scrollMarginTop: SCROLL_MARGIN }}
    >
      <div
        onDragOver={(e) => {
          if (e) e.preventDefault();
        }}
        className={classes.estimateContainer}
      >
        {activeEstimate ? (
          <CostEstimate
            sendRefetch={loading}
            activeEstimateID={activeEstimate.id}
            canViewDetails
            isExpanded={isAllExpanded}
            isItemEstimateView={!!window.location.hash}
            isOption={isOption}
            itemId={item.id}
            itemTrade={categories}
            projectID={projectID}
            sendAnalytics={sendAnalytics}
            variant={GridVariant.ITEM_DETAILS}
          />
        ) : null}
      </div>
    </ItemDetailsCollapse>
  );
};

export default withStyles(ItemsCollapseStyles)(EstimateContent);
