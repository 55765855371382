import {
  ContingencyInfo,
  ContingencyReportItem,
  MarkupDisplayType,
  MilestoneContingencyInfo,
  Status,
} from '../../../generated/graphql';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';
import { isDefaultReport } from '../../ReportsTab/ReportsManagerMenu/utils';

export enum ContingencyReportType {
  ACCEPTED = 'Accepted Items only',
  PENDING = 'Pending Items only',
  ACCEPTED_AND_PENDING = 'Both Accepted and Pending Items',
}

export enum ContingencyReportView {
  ACTIVE_MILESTONE = 'Active Milestone',
  ALL_MILESTONES = 'All Milestones',
}

export function getItemStatuses(selectedReportType: ContingencyReportType): Status[] {
  if (selectedReportType === ContingencyReportType.ACCEPTED) {
    return [Status.ACCEPTED];
  }
  if (selectedReportType === ContingencyReportType.PENDING) {
    return [Status.PENDING];
  }
  return [Status.ACCEPTED, Status.PENDING];
}

export enum ContingencyReportColumns {
  NAME = 'Name',
  DRAWN = 'Drawn',
  OVERDRAWN = 'Overdrawn',
  DATE_ACCEPTED = 'Date Accepted',
  PENDING = 'Pending',
  ACCEPTED_BY = 'Accepted By',
  DATE_CREATED = 'Date Created',
  CREATED_BY = 'Created By',
  NOTES = 'Notes',
}

export type ContingencySummaryReportSortManager = {
  sortState: {
    sortKey: string;
    sortDirection: SortDirection;
  };
  setSort: (sortInfo: SortBy) => void;
};

export const getContingencyReportColumns = (reportType: ContingencyReportType) => {
  switch (reportType) {
    case ContingencyReportType.ACCEPTED_AND_PENDING:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.PENDING,
        ContingencyReportColumns.DRAWN,
        ContingencyReportColumns.DATE_ACCEPTED,
        ContingencyReportColumns.ACCEPTED_BY,
        ContingencyReportColumns.NOTES,
      ];
    case ContingencyReportType.ACCEPTED:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.DRAWN,
        ContingencyReportColumns.DATE_ACCEPTED,
        ContingencyReportColumns.ACCEPTED_BY,
        ContingencyReportColumns.NOTES,
      ];
    case ContingencyReportType.PENDING:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.PENDING,
        ContingencyReportColumns.DATE_CREATED,
        ContingencyReportColumns.CREATED_BY,
        ContingencyReportColumns.NOTES,
      ];
    default:
      return [];
  }
};

export const getContingencyReportSummaryColumns = (reportType: ContingencyReportType) => {
  switch (reportType) {
    case ContingencyReportType.ACCEPTED_AND_PENDING:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.PENDING,
        ContingencyReportColumns.DRAWN,
        ContingencyReportColumns.DATE_ACCEPTED,
        ContingencyReportColumns.ACCEPTED_BY,
        ContingencyReportColumns.NOTES,
      ];
    case ContingencyReportType.ACCEPTED:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.DRAWN,
        ContingencyReportColumns.DATE_ACCEPTED,
        ContingencyReportColumns.ACCEPTED_BY,
        ContingencyReportColumns.NOTES,
      ];
    case ContingencyReportType.PENDING:
      return [
        ContingencyReportColumns.NAME,
        ContingencyReportColumns.PENDING,
        ContingencyReportColumns.DATE_CREATED,
        ContingencyReportColumns.CREATED_BY,
        ContingencyReportColumns.NOTES,
      ];
    default:
      return [];
  }
};

export const ContingencySummaryReportSortKeys = {
  NAME: 'NAME',
  STARTING_AMOUNT: 'STARTING_AMOUNT',
  PENDING: 'PENDING',
  DRAWN: 'DRAWN',
  REMAINING: 'REMAINING',
};

export const handleOptionClick = (
  selectedOptions: string[],
  setSelectedOptions: (v: string[]) => void,
  optionID: string
) => {
  if (selectedOptions.includes(optionID)) {
    setSelectedOptions(selectedOptions.filter((ID) => ID !== optionID));
  } else {
    setSelectedOptions([...selectedOptions, optionID]);
  }
};

export const toggleAllOptions = (
  setSelectedOptions: (v: string[]) => void,
  selectedOptions: string[],
  allOptions: string[]
) => {
  if (selectedOptions.length === allOptions.length) {
    setSelectedOptions([]);
  } else {
    setSelectedOptions(allOptions);
  }
};

export const parentCheckboxState = (selectedOptions: string[], possibleOptions: JSX.Element[]) => {
  const allSelected = selectedOptions.length === possibleOptions.length;
  const indeterminate = !allSelected && selectedOptions.length > 0;

  return { allSelected, indeterminate };
};

export const getContingencyNamesForDisplayType = (
  allContingencies: Pick<ContingencyInfo, 'type' | 'name'>[],
  type: MarkupDisplayType
) => allContingencies.filter((c) => c.type === type).map((c) => c.name);

export function isStringArray(x: unknown[]): x is string[] {
  return x.every((i) => typeof i === 'string');
}

export function isContingencyReportType(value: string): value is ContingencyReportType {
  return Object.values<string>(ContingencyReportType).includes(value);
}

export const getReportSettings = (
  report: Omit<UserReport, 'createdBy' | 'updatedAt'> | undefined,
  contingencyInfo: Pick<MilestoneContingencyInfo, 'name' | 'type'>[] | undefined
): [string[], string[], ContingencyReportType] => {
  if (!report || isDefaultReport(report?.id)) {
    // names
    const contingencies =
      contingencyInfo?.filter((c) => c.type === MarkupDisplayType.CONTINGENCY).map((c) => c.name) ??
      [];
    const allowances =
      contingencyInfo?.filter((c) => c.type === MarkupDisplayType.ALLOWANCE).map((c) => c.name) ??
      [];
    return [contingencies, allowances, ContingencyReportType.ACCEPTED_AND_PENDING];
  }

  const parsedSettings = JSON.parse(report.settings);

  const reportType =
    parsedSettings.selectedReportType ?? ContingencyReportType.ACCEPTED_AND_PENDING;
  const contingencies = parsedSettings.selectedContingencies ?? [];
  const allowances = parsedSettings.selectedAllowances ?? [];

  return [contingencies, allowances, reportType];
};

export const getContingencyComment = (
  contingency: ContingencyReport[number],
  item: ContingencyReportItem,
  comments?: VarianceReportComments
) => {
  const commentLineID = `${contingency.contingencyID}${'⌘'}${item.itemID}`;
  const comment = comments?.itemComments?.find((c) => c.commentLineID === commentLineID);
  return { commentLineID, comment };
};
