import { SvgIcon } from '@material-ui/core';

export const UploadFilesIcon = () => (
  <SvgIcon
    width="72"
    height="50"
    viewBox="0 0 72 50"
    style={{ transform: 'scale(3) translate(0px, -4px)' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.05 18.875C56.01 8.09375 46.92 0 36 0C27.33 0 19.8 5.125 16.05 12.625C7.02 13.625 0 21.5938 0 31.25C0 41.5938 8.07 50 18 50H57C65.28 50 72 43 72 34.375C72 26.125 65.85 19.4375 58.05 18.875ZM42 28.125V40.625H30V28.125H21L36 12.5L51 28.125H42Z"
      fill="#5CD746"
    />
    <path d="M42 40.625V28.125H51L36 12.5L21 28.125H30V40.625H42Z" fill="white" />
  </SvgIcon>
);
