import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.backgroundWhite,
      borderBottom: theme.border.line,
      borderLeft: theme.border.line,
      borderRight: theme.border.line,
      padding: `24px 0px 24px 18px`,
    },
    content: {
      marginRight: 16,
      paddingLeft: '5px',
    },
    header: {
      ...theme.typography.title,
      fontSize: 16,
      margin: 0,
    },
    padding: {
      marginBottom: '18px',
    },
    reportsIcon: {
      height: 20,
      verticalAlign: 'text-bottom',
      width: 20,
    },
    tipText: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamily,
    },
  });
