import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: theme.border.line,
      overflow: 'overlay',
      overflowX: 'auto',
    },
  });
