import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './ChipsStyles';

type ChipsProps = {
  children: React.ReactNode;
  classes: Classes<typeof styles>;
};

const _Chips = (props: ChipsProps) => <div className={props.classes.chips}>{props.children}</div>;

export const Chips = withStyles(styles)(_Chips);

type ChipProps = {
  children: React.ReactNode;
  className?: string;
  classes: Classes<typeof styles>;
  dataCy?: string;
  iconComponent?: React.ComponentType<{ className?: string }>;
  onClick?: () => void;
};

const _Chip = (props: ChipProps) => {
  const Icon = props.iconComponent;

  if (props.onClick)
    return (
      <button
        className={`${props.classes.chip_button} ${props.className ?? ''}`}
        data-cy={props.dataCy}
        onClick={props.onClick}
      >
        {Icon && <Icon className={props.classes.chip_icon} />}
        <div>{props.children}</div>
      </button>
    );

  return (
    <div className={`${props.classes.chip} ${props.className ?? ''}`} data-cy={props.dataCy}>
      {Icon && <Icon className={props.classes.chip_icon} />}
      <div>{props.children}</div>
    </div>
  );
};

export const Chip = withStyles(styles)(_Chip);
