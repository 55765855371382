import { COST, METRICS, SUBCOLUMNS, TOTAL, VARIANCE } from '../../../constants';
import { isNonNullable } from '../../../utilities/types';
import {
  findQuantity,
  getMilestoneQuantities,
} from '../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';
import {
  getCategorizedText,
  getIsUnitColumn,
  getUnitText,
  keyIsCategorized,
} from '../CostReportList/CostReportList/CostReportListUtils';

export type SelectedOption = {
  text: string;
  opacity: number;
};

export type MetricGroup = {
  headerText: string;
  identifier: string;
  rows: MetricGroupRow[];
  selectedOptions: SelectedOption[];
};

export type MetricGroupRow = {
  key: string;
  text: string;
};

type MetricGroupVariant = typeof METRICS | typeof SUBCOLUMNS | typeof VARIANCE;

export const formatUnitName = (unit: Unit) => `${unit.name} (${unit.abbreviationSingular})`;

export const getPerUnitString = (unit: Unit) =>
  `Calculate ${getUnitText(unit.abbreviationSingular, false)} using`;
export const displayString = 'Display';

export const costHeaderTitles: MetricGroupRow[] = [
  {
    key: TOTAL,
    text: TOTAL,
  },
];

type MetricHeaderCase = {
  unitString: string;
  isMetric: boolean;
  isCategorized: boolean;
};

export const metricHeaderCases = (unitString: string): MetricHeaderCase[] => [
  { unitString, isMetric: true, isCategorized: false },
  { unitString, isMetric: false, isCategorized: false },
  { unitString, isMetric: true, isCategorized: true },
  { unitString, isMetric: false, isCategorized: true },
];
// TODO:  We can use the above, and filter on metrics for the MSR

const headerTitle = (
  unitAbbreviation: string,
  isMetric: boolean,
  isCategorized: boolean
): MetricGroupRow => ({
  key: getUnitText(unitAbbreviation, isMetric, isCategorized),
  text: getMetricTitle(unitAbbreviation, isMetric, isCategorized),
});

export const getMetricTitle = (
  unitAbbreviation: string,
  isMetric: boolean,
  isCategorized: boolean
) => `${getUnitText(unitAbbreviation, isMetric)} (${getCategorizedText(isCategorized)})`;

const unitHeaderRows = (
  unit: Unit,
  variant: MetricGroupVariant,
  hasBreakdowns: boolean
): MetricGroupRow[] => {
  const { abbreviationSingular } = unit;
  let cases = metricHeaderCases(abbreviationSingular);
  if (variant === METRICS) cases = cases.filter((c) => c.isMetric);
  if (variant === SUBCOLUMNS) cases = cases.filter((c) => !c.isMetric);
  if (!hasBreakdowns) cases = cases.filter((c) => !c.isCategorized);
  return cases.map((m) => headerTitle(m.unitString, m.isMetric, m.isCategorized));
};

const UNIT_QUARTER_OPACITY = 0.25;
const UNIT_HALF_OPACITY = 0.5;
const UNIT_THREE_QUARTER_OPACITY = 0.75;
const UNIT_FULL_OPACITY = 1;

export const getOpacity = (s: string) => {
  if (keyIsCategorized(s)) {
    return getIsUnitColumn(s) ? UNIT_THREE_QUARTER_OPACITY : UNIT_FULL_OPACITY;
  }
  return getIsUnitColumn(s) ? UNIT_QUARTER_OPACITY : UNIT_HALF_OPACITY;
};

export const getSelectedOptions = (options: string[], selectedKeys: string[], unit: Unit) => {
  // TODO: This needs to know "alot" about the Total row...
  const selectedGrouOptionKeys =
    selectedKeys && selectedKeys.filter((u) => (unit ? options.includes(u) : TOTAL === u));
  return selectedGrouOptionKeys.map((selected) => ({
    text: selected,
    opacity: getOpacity(selected),
  }));
};

export const getGroupsForUnits = (
  milestones: Milestone[],
  milestoneIDs: UUID[],
  units: Unit[],
  selectedKeys: string[],
  variant: MetricGroupVariant = VARIANCE
) => {
  const groups: MetricGroup[] = units // Todo: Change this to "Variance" case only
    .map((unit: Unit) => {
      const { milestone1Quantities, milestone2Quantities } = getMilestoneQuantities(
        milestones,
        milestoneIDs
      );
      const quantities = [
        findQuantity(unit, milestone1Quantities?.filter(isNonNullable)),
        findQuantity(unit, milestone2Quantities?.filter(isNonNullable)),
      ];

      const unitHasMagnitude =
        quantities.some((quantity) => quantity && Number(quantity.magnitude) !== 0) || !unit;
      if (!unitHasMagnitude) return null;

      const unitHasBreakdowns = quantities.some((quantity) => quantity?.hasBreakdown);
      const rows = unitHeaderRows(unit, variant, unitHasBreakdowns);
      const selectedOptions: SelectedOption[] = getSelectedOptions(
        rows.map((r) => r.key),
        selectedKeys,
        unit
      );
      const identifier = unit.abbreviationSingular;
      const headerText = formatUnitName(unit);
      return { headerText, identifier, rows, selectedOptions };
    })
    .filter((g) => !!g) as MetricGroup[];

  if ([VARIANCE, SUBCOLUMNS].includes(variant)) {
    groups.unshift({
      headerText: COST,
      rows: costHeaderTitles,
      identifier: TOTAL,
      selectedOptions: selectedKeys.includes(TOTAL) ? [{ text: TOTAL, opacity: 1 }] : [],
    });
  }

  return groups;
};

export const getGroupsFlatOptions = (groups: MetricGroup[]) =>
  groups.map((g) => g.rows.map((r) => r.key)).flat();

// Functions
export const toggleGroupsOptionsKeys =
  (groups: MetricGroup[], selected: string[], setSelected: (selected: string[]) => void) =>
  (key: string) => {
    const options: string[] = getGroupsFlatOptions(groups);
    let newSelection = selected.slice();
    const index = newSelection.indexOf(key);
    if (index === -1) {
      newSelection.push(key);
      // This is to preserve the order of options
      newSelection = options.filter((o) => newSelection.includes(o));
    } else {
      newSelection.splice(index, 1);
    }
    setSelected(newSelection);
  };
