import { FC } from 'react';

import { YC_ITEM_SHARING } from '../../../features';
import { useHasFeature } from '../../../hooks/useFeatureQuery';

import ItemPublishDialog from './ItemPublishDialog';
import ItemPublishModal from './ItemPublishModal';

type ItemPublishDialogFFProps = {
  isOpen: boolean;
  itemCreatedByID?: UUID;
  itemID: UUID;
  projectID: UUID;
  setIsOpen: (setShowPublishItemDialog: boolean) => void;
  onItemMutated?: () => void;
};

const ItemPublishDialogFF: FC<ItemPublishDialogFFProps> = (props) => {
  const isItemSharingFeature = useHasFeature(YC_ITEM_SHARING);

  if (isItemSharingFeature) {
    return <ItemPublishDialog {...props} />;
  }
  return <ItemPublishModal {...props} />;
};

export default ItemPublishDialogFF;
