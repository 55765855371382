import { FC } from 'react';

import InAppNotificationsFooter from '../InAppNotificationsFooter/InAppNotificationsFooter';
import InAppNotificationsHeader from '../InAppNotificationsHeader/InAppNotificationsHeader';
import InAppNotificationsList from '../InAppNotificationsList/InAppNotificationsList';

type InAppNotificationsPanelProps = {
  loadInAppNotificationsCount?: LoadInAppNotificationsCount;
  notificationsData: InAppNotificationWithTime[][] | null;
  paginationData?: PaginationData;
};

const InAppNotificationsPanel: FC<InAppNotificationsPanelProps> = ({
  loadInAppNotificationsCount,
  notificationsData,
  paginationData,
}) => (
  <>
    <InAppNotificationsHeader loadInAppNotificationsCount={loadInAppNotificationsCount} />
    <InAppNotificationsList notificationsData={notificationsData} />
    <InAppNotificationsFooter
      loadInAppNotificationsCount={loadInAppNotificationsCount}
      paginationData={paginationData}
    />
  </>
);

export default InAppNotificationsPanel;
