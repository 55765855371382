import { ComponentProps, useEffect, useState } from 'react';

import { IconButton, Tooltip } from '../../scales';
import { HubSpotChatIcon, SpinnerIcon } from '../icons';

type OpenState = 'closed' | 'loading' | 'open';

type Props = {
  position: {
    left: number;
  };
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
};

/**
 * Renders a button that shows/hides the Hubspot chat.
 *
 * We use the Widget SDK to programmatically load and remove the widget rather
 * than merely opening and closing it. This allows us to fully remove the widget
 * and its button when it's closed, relying instead on a custom-rendered button.
 */
export default function HubSpotChat(props: Props) {
  const [openState, setOpenState] = useState<OpenState>(
    window.HubSpotConversations?.widget.status().loaded ? 'open' : 'closed'
  );

  useEffect(() => {
    // Despite the fact that we load the widget via `widgetOpen: true`, the widget
    // sometimes fails to open by itself. This generally seems to occur when the widget
    // took longer than usual to load. As a fallback, we hook into this widgetLoaded
    // event and call open() just in case.
    window.HubSpotConversations?.on('widgetLoaded', () => {
      window.HubSpotConversations?.widget.open();
      setOpenState('open');
    });

    // The widget can be closed by clicking on the big X button that Hubspot renders.
    // If the user closes the chat that way, we want to remove the default Hubspot
    // chat button, which is accomplished by removing the widget entirely.
    window.HubSpotConversations?.on('widgetClosed', () => {
      window.HubSpotConversations?.widget.remove();
      setOpenState('closed');
    });
  }, []);

  if (!window.HubSpotConversations) return null;

  let icon = <HubSpotChatIcon />;
  if (openState === 'loading') icon = <SpinnerIcon />;
  if (openState === 'open') icon = <div />;

  return (
    <>
      <Tooltip
        content="Open support chat"
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <IconButton
          aria-label={openState === 'closed' ? 'open support chat' : 'close support chat'}
          icon={icon}
          onClick={() => {
            if (openState === 'open') {
              window.HubSpotConversations?.widget.remove();
              setOpenState('closed');
            } else {
              window.HubSpotConversations?.widget.load({ widgetOpen: true });
              setOpenState('loading');
            }
          }}
          type="secondary"
        />
      </Tooltip>
      <style>
        {/* Hyper-specificity to beat out Hubspot's !important styles */}
        {`div#hubspot-messages-iframe-container.widget-align-left:has(iframe) {
          left: ${props.position.left}px !important;
        }`}
      </style>
    </>
  );
}
