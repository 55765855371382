import PropTypes from 'prop-types';
import { Component } from 'react';
import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import { Avatar, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, DragHandle } from '@material-ui/icons';

import { ITEMS } from '../../constants';

const styles = (theme) => ({
  alignLeft: {
    justifyContent: 'flex-start',
  },
  DropTarget: {
    color: 'blue',
  },
  chip: {
    cursor: 'move',
    background: theme.palette.backgroundGrey,
    borderRadius: theme.border.radius,
    flexGrow: 1,
    border: theme.border.line,
    '&:focus': {
      background: theme.palette.backgroundGrey,
    },
  },
  importChip: {
    cursor: 'move',
    background: theme.palette.backgroundGrey,
    borderRadius: 1,
    flexGrow: 1,
    height: 40,
    border: theme.border.line,
    '&:focus': {
      background: theme.palette.backgroundGrey,
    },
  },
  chipLabel: {
    color: theme.palette.joinPrimary,
    flex: 1,
  },
  importChipLabel: {
    color: theme.palette.joinPrimary,
    fontSize: 16,
    flex: 1,
    overflow: 'initial',
  },
  delete: {
    color: theme.palette.joinPrimary,
    fontSize: 16,
    '&:hover': {
      color: theme.palette.joinPrimary,
      opacity: 0.5,
    },
  },
  importDelete: {
    '&:hover': {
      color: theme.palette.joinPrimary,
      opacity: 0.5,
    },
  },
  disabledDelete: {
    color: theme.palette.disabledGrey,
    opacity: 0.5,
    '&:hover': {
      color: theme.palette.disabledGrey,
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  drag: {
    display: 'flex',
    background: 'none',
  },
  avatar: {
    height: 32,
    width: 32,
    background: 'none',
  },
});

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
  endDrag(props) {
    props.moveListItem(null, null, null);
  },
};

const cardTarget = {
  drop(props) {
    props.moveListItem(null, null, null);
  },
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const dragId = monitor.getItem().id;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = component && component.node && component.node.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveListItem(dragIndex, hoverIndex, dragId);

    /* eslint-disable no-param-reassign */
    monitor.getItem().index = hoverIndex;
    /* eslint-enable no-param-reassign */
  },
};

class DragChip extends Component {
  static propTypes = {
    chipCount: PropTypes.number.isRequired,
    classes: PropTypes.shape({}).isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    monitor: PropTypes.shape({}).isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deleteIcon: PropTypes.shape(<React.Component />),
    isDisabled: PropTypes.bool,
    chipType: PropTypes.string,
    dataCy: PropTypes.string,
  };

  static defaultProps = {
    handleClose: undefined,
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  render() {
    const {
      chipCount,
      classes,
      dataCy,
      text,
      title,
      connectDragSource,
      connectDropTarget,
      handleClose,
      deleteIcon,
      isDisabled,
      chipType,
    } = this.props;

    const getChip = (chipType) => {
      let chipLabelClass = classes.chipLabel;
      let deleteIconClass = classes.delete;
      let chipClass = classes.chip;

      if (chipType === 'CATEGORIZATION_REORDER') {
        chipLabelClass = classes.importChipLabel;
        deleteIconClass = classes.importDelete;
        chipClass = classes.importChip;
      }

      return (
        <Chip
          avatar={
            <Avatar className={classes.avatar}>
              {chipCount > 1 && <DragHandle className={deleteIconClass} />}
            </Avatar>
          }
          node={this.node}
          key={text}
          classes={{
            root: classes.alignLeft,
            label: chipLabelClass,
            deleteIcon: isDisabled ? classes.disabledDelete : deleteIconClass,
          }}
          title={title}
          label={text}
          data-cy={dataCy}
          deleteIcon={deleteIcon || <Close className={classes.noPrint} />}
          className={chipClass}
          onDelete={handleClose}
        />
      );
    };

    return connectDragSource(
      connectDropTarget(
        <div
          ref={(node) => {
            this.node = node;
          }}
          className={classes.drag}
          style={{ transform: 'translate(0px, 0px)' }}
        >
          {getChip(chipType)}
        </div>
      )
    );
  }
}

const withDropTarget = DropTarget(ITEMS, cardTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  monitor,
}));

const withDropSource = DragSource(ITEMS, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}));

const DragDropChip = withDropTarget(withDropSource(DragChip));

export default withStyles(styles)(DragDropChip);
