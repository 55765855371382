import { FC, useState } from 'react';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import {
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  PROJECTS,
} from '../../../constants';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ClearFiltersPanelHeader from '../../FilterPanel/ClearFilters/ClearFiltersPanelHeader';
import FilterIcon from '../../Icons/FilterIcon';
import InputsSettingsPanel from '../../Inputs/InputsSettingsPanel/InputsSettingsPanel';
import ProjectsListFilterSelect from '../../ProjectsList/ProjectsListFilter/ProjectsListFilterSelect/ProjectsListFilterSelect';
import ProjectTypeFilter from '../../ProjectsList/ProjectsListFilter/ProjectTypeFilter';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  projectFilterStateHasFilters,
} from '../../ProjectsList/ProjectsListUtils';

type ProjectStatsFilterPanelProps = {
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
};

const ProjectStatsFilterPanel: FC<ProjectStatsFilterPanelProps> = ({
  filterManager,
  setSettings,
}) => {
  const hasFilters = projectFilterStateHasFilters(filterManager.filterState);
  const [show, setShow] = useState(false);

  return (
    <InputsSettingsPanel
      header={
        <ClearFiltersPanelHeader
          filterManager={filterManager}
          projectID={getProjectIdFromUrl()}
          setSettings={setSettings}
          variant={PROJECTS}
        />
      }
      icon={<FilterIcon isFilled={hasFilters} />}
      show={show}
      setShow={setShow}
      page={CompanyAdminView.PROJECT_STATS_LIST}
    >
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_STATUS}
        setSettings={setSettings}
      />
      <ProjectTypeFilter
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_TYPE}
        setSettings={setSettings}
      />
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_LOCATION}
        setSettings={setSettings}
      />
    </InputsSettingsPanel>
  );
};

export default ProjectStatsFilterPanel;
