import { ItemType, Status } from '../../../../../../../generated/graphql';
import ItemOptionSelect from '../../../../../common/ItemOptionSelect/ItemOptionSelect';
import { ScenarioItemEntry } from '../../../../../common/ItemStatus/ItemStatusUtils';
import ItemStatusSelect from '../../../../../common/ItemStatusSelect/ItemStatusSelect';

import { getItemOptionStatusChanges } from './StatusCellEditUtils';

type ItemUpdate = {
  itemID: UUID;
  status: Status;
};

type Props = {
  item: ScenarioItemEntry;
  onUpdateItems: (changes: ItemUpdate[]) => void;
};

export default function StatusCellEditSelect(props: Props) {
  const itemID = props.item.id;
  const baseStatus = props.item.overlay?.baseStatus ?? undefined;

  // Statuses
  const availableStatuses = [Status.ACCEPTED, Status.PENDING, Status.REJECTED];

  if ([baseStatus, props.item.status].includes(Status.NOTAPPLICABLE)) {
    availableStatuses.push(Status.NOTAPPLICABLE);
  }

  if (props.item.itemType === ItemType.ITEM_WITH_OPTIONS) {
    const hasOptions = props.item.options.length > 0;
    return hasOptions ? (
      <ItemOptionSelect
        options={props.item.options}
        onChange={(selectedOptionID) => {
          props.onUpdateItems(getItemOptionStatusChanges(props.item, selectedOptionID));
        }}
      />
    ) : (
      <div data-cy="status-no-options" className="flex-grow text-type-inactive type-label ">
        No options
      </div>
    );
  }

  return (
    <ItemStatusSelect
      defaultStatus={props.item.status}
      onChange={(status) => props.onUpdateItems?.([{ itemID, status }])}
      availableStatuses={availableStatuses}
    />
  );
}
