import { CurrentCollaboratorQuery, CurrentCollaboratorQueryVariables } from '../generated/graphql';
import { useProjectID } from '../utilities/routes/params';

import { currentCollaborator } from './queries';
import { useQuery } from './useMountAwareQuery';

export default function useCurrentCollaborator(projectID?: UUID) {
  const currentProjectID = useProjectID();
  const { data, loading, previousData } = useQuery<
    CurrentCollaboratorQuery,
    CurrentCollaboratorQueryVariables
  >(currentCollaborator, {
    variables: { projectID: projectID ?? currentProjectID },
    skip: !(projectID ?? currentProjectID),
  });
  return { collaborator: data?.currentCollaborator ?? previousData?.currentCollaborator, loading };
}
