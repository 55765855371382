import { RefObject, useEffect } from 'react';

import { gridWidthVar } from '../../../api/apollo/reactiveVars';
import { RESIZE } from '../../../constants';
import { TABLE_MARGIN } from '../style/styleConstants';
import { GridController } from '../types';

// Resizes the grid width to reflow it if the window size changes. We need this since we
// need to know how wide we are for layout, so we rely on that number not changing.

const FULLSCREEN_ADDITIONAL_WIDTH = 2;
export const calculateOverallWidth = (measuredWidth: number, isAccordion: boolean) =>
  measuredWidth - TABLE_MARGIN + (isAccordion ? FULLSCREEN_ADDITIONAL_WIDTH : 0);

export default function useWindowResize(
  grid: GridController | undefined,
  collapseSizeRef: RefObject<HTMLDivElement>,
  isAccordion?: boolean
) {
  const doResize = (width: number, height: number) => {
    if (!grid) return;
    if (isAccordion) {
      // if max height if known, else scrollingly tall...
      // sometimes, height is measured as zero when expanding
      const maxHeight = height || window.innerHeight;
      const oldHeight = grid.maxHeight();
      grid.setMaxHeight(maxHeight);
      if (maxHeight > (oldHeight || 0)) grid.updateTable();
    }
    if (width) {
      const overallWidth = calculateOverallWidth(width, !!isAccordion);
      gridWidthVar(overallWidth);
      grid?.setOverallWidth(overallWidth);
    }
  };

  useEffect(() => {
    if (grid) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      const resizeObserver = (entries: any) => {
        // Only care about the first element, we expect one element to be watched
        const { height, width } = entries[0].contentRect;
        doResize(width, height);
      };
      const { current } = collapseSizeRef;
      const resizeListener = () => {
        if (current) {
          const { clientWidth: width, clientHeight: height } = current;
          doResize(width, height);
        }
      };
      if (current) {
        const observer = new ResizeObserver(resizeObserver);
        observer.observe(current);
        window.addEventListener(RESIZE, resizeListener);
        return () => {
          observer.unobserve(current);
          window.removeEventListener(RESIZE, resizeListener);
        };
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [collapseSizeRef.current, grid]);
}
