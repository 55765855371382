import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';

import ProjectsListMultiOption from './SelectFilterMultiOption';
import styles from './SelectFilterStyles';

interface SelectFilterMultiOptionListProps {
  adornment: string[];
  classes: Classes<typeof styles>;
  filterSearchResultsCurrent: string[];
  filterLabels: string[];
  handleClick: () => void;
  handleHover: (filterOption: string) => void;
  hasSearchResults: boolean;
  highlightedFilter: string;
  isScrollableDropdown: boolean;
  selectedFilters: string[];
  indeterminate: string[];
  formatValueForDisplay?: (value: string) => string;
}

const SelectFilterMultiOptionList: FC<SelectFilterMultiOptionListProps> = ({
  adornment,
  classes,
  filterSearchResultsCurrent,
  filterLabels,
  handleClick,
  handleHover,
  hasSearchResults,
  highlightedFilter,
  isScrollableDropdown,
  selectedFilters,
  indeterminate,
  formatValueForDisplay,
}) => {
  const listEmptyClass = filterSearchResultsCurrent.length === 1 ? classes.listEmpty : '';

  const options = filterSearchResultsCurrent.map((filterOption: string, i) => {
    const isSelected = selectedFilters.includes(filterOption);
    const isAdornment = adornment.includes(filterOption);
    const isIndeterminate = indeterminate.includes(filterOption);
    const filterLabel = filterLabels[i];
    return (
      <ProjectsListMultiOption
        filterOption={filterOption}
        filterLabel={filterLabel}
        handleClick={handleClick}
        handleHover={handleHover}
        highlightedFilter={highlightedFilter}
        isAdornment={isAdornment}
        isIndeterminate={isIndeterminate}
        isSelected={isSelected}
        key={filterOption}
        formatValueForDisplay={formatValueForDisplay}
      />
    );
  });

  return (
    <ul
      className={`${listEmptyClass} ${isScrollableDropdown ? classes.list : classes.listNoScroll}`}
      id="listbox-list"
      role="listbox"
    >
      {hasSearchResults && options}
    </ul>
  );
};

export default withStyles(styles)(SelectFilterMultiOptionList);
