import { estimateImportUploadedCTA } from '../../../analytics/analyticsEventProperties';
import {
  importEstimateActiveSkipMsVar,
  importEstimateActiveVar,
  importEstimateBudgetSkipMsVar,
  importEstimateBudgetVar,
  importEstimateHelpDialogVar,
  isEstimateUploadDialogOpen,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { EstimateType, ImportModal, UploadPlatform } from '../../../api/gqlEnums';
import { IMPORT_ESTIMATE_ACTIVE, IMPORT_ESTIMATE_BUDGET } from '../../../constants';
import { EstimateTotalType } from '../../../generated/graphql';
import { EventProperties } from '../../../hooks/useAnalyticsEventHook';
import { Status } from '../types';

export const uploadedActionAnalytics = (
  success: boolean,
  uploadStart: number,
  sendAnalytics: (event: { type: string; eventProperties: EventProperties }) => void
) => {
  const timeToUploadMs = Date.now() - uploadStart;
  const timeToUploadMins = timeToUploadMs / 1000 / 60;
  return sendAnalytics(estimateImportUploadedCTA(success, timeToUploadMins));
};

export const onSuccessfulEstimateUpload = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  uploaded: any,
  estimateType: EstimateType,
  uploadStart: number,
  showMapUfMfModal: boolean,
  loading: boolean,
  start: number,
  file: File,
  setFileStatus: (status: Status) => void,
  setElapsedTime: (time: number | null) => void,
  refetch?: () => void
) => {
  const importEstimateSkipMsVar =
    estimateType === EstimateType.ACTIVE_ESTIMATE
      ? importEstimateActiveSkipMsVar
      : importEstimateBudgetSkipMsVar;
  const skipMs = importEstimateSkipMsVar();
  if (skipMs > uploadStart) return;

  isEstimateUploadDialogOpen(false);

  // setup categoryMatchCounts maps to use Typescript's Map
  const categorizations = uploaded?.categorizations || [];
  categorizations?.forEach((c: DraftCategorization, i: number) => {
    const matchMap = new Map<string, CategoryMatchInfo>();
    Object.entries(c.categoryMatchCounts).forEach(([k, v]) => {
      if (!!v && 'unmatched' in v && 'matched' in v) {
        matchMap.set(k, v);
      }
      categorizations[i].categoryMatchCounts = matchMap;
    });
  });

  const ie = {
    ...uploaded,
    mapping: new Map<string, string>(),
    mappingBuiltIn: new Map<string, Level>(),
    modal: showMapUfMfModal || loading ? ImportModal.MAP_UF_MF : ImportModal.INCLUDE,
    modalIsOpen: true,
    // Set the default total type for the import manager.
    selectedTotalType:
      uploaded.estimateTotalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES
        ? EstimateTotalType.TOTAL_TYPE_UNIT
        : uploaded.estimateTotalType,
  };
  if (estimateType === EstimateType.ACTIVE_ESTIMATE) {
    setReactiveLocal(importEstimateActiveVar, IMPORT_ESTIMATE_ACTIVE, ie);
  } else {
    setReactiveLocal(importEstimateBudgetVar, IMPORT_ESTIMATE_BUDGET, ie);
  }
  setFileStatus({ isUploading: false, file, estimateId: undefined });
  setElapsedTime(start ? window.performance.now() - start : null);
  if (refetch) refetch(); // milestone.importedEstimates list updated
};

export const onFailedEstimateUpload = (
  result: { response: string; status?: number },
  file: File,
  setFileStatus: (status: Status) => void
) => {
  // if the user uploaded an .est file then show a link
  // to the directions to export an estimate
  if (file.name.endsWith('.est')) {
    importEstimateHelpDialogVar({ isOpen: true, platform: UploadPlatform.WINEST });
  }
  setFileStatus({
    isUploading: false,
    file,
    estimateId: undefined,
    error: result.response,
  });
};
