// TODO DD-741 OLD_NAV_BACKCOMPAT Remove this function since we'll no longer need it.
export const getCategoriesInput = (categories?: PreviewVars['previewTrades']) => {
  return (
    categories &&
    categories.map((c) => {
      let categorizationID = '';
      if ('categorizationID' in c) {
        categorizationID = c.categorizationID;
      } else {
        categorizationID = c.categorization?.id ?? '';
      }

      return {
        categorizationID,
        id: c.id,
      };
    })
  );
};
