import { MouseEvent } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../api/gqlEnums';
import { NULL_ID } from '../../constants';
import { withStyles } from '../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../utilities/clickHandler';
import { generateSharedPath } from '../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';

import { styles } from './MilestonesStyles';

type ItemMilestoneReportLinkProps = {
  classes: Classes<typeof styles>;
  milestoneId: UUID;
  milestoneName: string;
  isItemMilestone: boolean;
};

const ItemMilestoneReportLink: React.FC<ItemMilestoneReportLinkProps> = ({
  classes,
  milestoneId,
  milestoneName,
  isItemMilestone,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl(window.location.pathname);
  const milestoneID = getMilestoneIdFromUrl() || NULL_ID;
  const linkDisabled = milestoneID !== NULL_ID && milestoneId === milestoneID;
  const onClick = (e: MouseEvent<Element> | React.KeyboardEvent<Element>) => {
    if (!linkDisabled) {
      const path = generateSharedPath(JoinProjectRoutes.MSR, { projectId, milestoneId });
      clickLinkKeyHandler(navigate, e, path, '');
      e.stopPropagation();
    }
  };
  return (
    <span
      className={`${classes.containerItemMilestoneReport}`}
      onClick={onClick}
      role="button"
      tabIndex={-1}
      onKeyDown={(evt) => {
        if (evt.key === 'Enter') onClick(evt);
      }}
    >
      <Typography
        component="span"
        className={`${classes.itemMilestoneLinkText} ${
          linkDisabled && classes.itemMilestoneLinkDisabled
        } ${isItemMilestone ? classes.currentMilestone : ''}`}
        title={`Go to ${milestoneName} Summary Report`}
      >
        {milestoneName}
      </Typography>
    </span>
  );
};

export default withStyles(styles)(ItemMilestoneReportLink);
