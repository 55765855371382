import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { updateItemVisibilityAnalytics } from '../../../analytics/analyticsEventProperties';
import { Visibility } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import { useSetItemPublished } from '../../ve-item-details/ItemDetailsHooks';
import { PUBLISH_ITEM_LINK_COPY } from '../ItemVisibilityToggle/ItemVisibilityToggleUtils';

type ItemPublishModalProps = {
  isOpen: boolean;
  itemCreatedByID?: UUID;
  itemID: UUID;
  projectID: UUID;
  setIsOpen: (setShowPublishItemDialog: boolean) => void;
  onItemMutated?: () => void;
};

const ItemPublishModal: FC<ItemPublishModalProps> = ({
  isOpen,
  itemCreatedByID,
  itemID,
  projectID,
  setIsOpen,
  onItemMutated,
}) => {
  const sendAnalytics = useSendAnalyticsEventHook();

  const dialogTitle = 'Convert to public item?';
  const dialogContent =
    'Converting this item to public will make it visible to all teammates on the project. This action cannot be undone.';

  const updateVisibility = useSetItemPublished({ onCompleted: onItemMutated });
  const publishItem = () => {
    sendAnalytics(
      updateItemVisibilityAnalytics({
        id: itemID,
        visibility: Visibility.PUBLISHED,
      })
    );
    updateVisibility(projectID, itemID, itemCreatedByID, undefined);
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <JoinDialog
        index={0}
        dynamicHeight
        dialog={{
          open: isOpen,
          onOpen: () => {
            setIsOpen(true);
          },
          onClose: () => setIsOpen(false),
          onNext: undefined,
          onBack: () => setIsOpen(false),
          contentComponent: <Typography>{dialogContent}</Typography>,
          headerText: dialogTitle,
          actionButtons: [
            {
              isLeftAligned: true,
              color: 'primary',
              variant: 'outlined',
              onClick: () => {
                setIsOpen(false);
              },
              text: 'Cancel',
            },
            {
              color: 'primary',
              dataCy: 'button-ConfirmPublishItem',
              variant: 'contained',
              onClick: () => publishItem(),
              text: PUBLISH_ITEM_LINK_COPY,
            },
          ],
        }}
        pageCounter={undefined}
        hasDivider
      />
    );
  }
  return null;
};

export default ItemPublishModal;
