import { SvgIcon } from '@material-ui/core';

export default ({ isFilled = false, isError = false }) => (
  <SvgIcon
    width="16"
    height="20"
    viewBox="0 0 16 20"
    stroke={isError ? 'red' : 'black'}
    transform="scale(0.65)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8805 1.15734C15.8805 1.28509 15.8521 1.40548 15.8055 1.50866L15.7751 1.56763L9.68356 10.938V17.3282C9.68356 17.5936 9.56396 17.8245 9.3876 17.9498L9.30449 17.9989L7.06858 19.5689L7.03817 19.586C6.96317 19.6278 6.88005 19.6499 6.79086 19.6499C6.46247 19.6499 6.19489 19.3281 6.19489 18.9301V10.938L0.10541 1.57008L0.0750035 1.51112C0.0263525 1.40793 0 1.28755 0 1.15979C0 0.761785 0.26758 0.43994 0.595974 0.43994H15.2865C15.6149 0.437483 15.8805 0.759329 15.8805 1.15734Z"
      // eslint-disable-next-line no-nested-ternary
      fill={isFilled ? (isError ? 'red' : 'black') : 'none'}
    />
  </SvgIcon>
);
