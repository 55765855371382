import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
  removeCollaboratorAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { setPreviewSettingsVar } from '../../../api/apollo/reactiveVars';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import theme from '../../../theme/design-system-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useUserProfile } from '../../../utilities/userProfile';
import { getIsPending } from '../../CompanyTab/CompanyTabUtils';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import DialogsResendProjectInvitation from '../../Dialogs/DialogsResendProjectInvitation/DialogsResendProjectInvitation';
import { previewTooltipCopy } from '../../frame/FrameTitleBar/FrameTitlePreviewBar';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { useDeleteCollaborator } from '../hooks';

type CollaboratorsIconMenuProps = {
  collaborator: Collaborator;
  canEditPermissions: boolean;
  deactivated: boolean;
};

const CollaboratorsIconMenu: FC<CollaboratorsIconMenuProps> = ({
  collaborator,
  canEditPermissions,
  deactivated,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const deleteCollaborator = useDeleteCollaborator();
  const { id, user, role } = collaborator;
  const currentUser = useUserProfile();
  const isCurrentUser = currentUser?.email === user.email;
  const sendAnalytics = useAnalyticsEventHook();

  const menuOptions: MenuOption[] = [
    {
      callback: () => {
        setShowResendInviteModal(true);
      },
      name: 'Resend Invitation',
      tooltip: 'Resend an invitation to an invited user.',
    },
    {
      callback: () => {
        setPreviewSettingsVar({
          previewUserId: user.id,
          previewRoleId: undefined,
        });
        sendAnalytics(
          previewPermissionsAnalytics(
            previewEventTypes.PREVIEW_MENU_CTA,
            previewTypes.USER,
            role.name
          )
        );
      },
      name: 'Preview',
      tooltip: previewTooltipCopy,
    },
    {
      callback: () => {
        setShowConfirmationModal(true);
      },
      color: theme.palette.ds.type.delete,
      name: 'Remove',
      tooltip: `Remove ${user.name} from the project. They'll get a message to let them know.`,
    },
  ];

  const availableMenuOptions = (): MenuOption[] => {
    if (canEditPermissions && !deactivated && getIsPending(user)) {
      return menuOptions;
    }
    if (canEditPermissions && !deactivated) {
      return menuOptions.slice(1);
    }
    return menuOptions.slice(2);
  };

  return (
    <>
      <>
        <DialogsResendProjectInvitation
          projectID={projectId}
          user={user}
          role={role}
          open={showResendInviteModal}
          setDialogOpen={setShowResendInviteModal}
        />
        <DialogsConfirm
          acceptCtaCopy="Remove"
          body={`Are you sure? Removing ${user.name} will prevent them from accessing any project information.`}
          cancelCtaCopy="Cancel"
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => {
            if (id) {
              deleteCollaborator(projectId, id, (success) => {
                if (success && currentUser) {
                  sendAnalytics(removeCollaboratorAnalytics(collaborator, currentUser));
                  if (isCurrentUser) {
                    navigate(`/`);
                  }
                }
              });
            }
          }}
          open={showConfirmationModal}
          title={`Remove ${user.name} from project`}
        />
      </>
      <div data-cy="CollaboratorsListItem-iconMenu" style={{ paddingLeft: '8px' }}>
        <IconMenu options={availableMenuOptions()} />
      </div>
    </>
  );
};
export default CollaboratorsIconMenu;
