import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import CollaboratorsListItem from '../CollaboratorsListItem/CollaboratorsListItem';
import styles from '../CollaboratorsPage/CollaboratorsPageStyles';
import { SortDataType } from '../CollaboratorsPage/CollaboratorsPageUtils';

import CollaboratorsListHeader from './CollaboratorsListHeader';
import { headerContent } from './CollaboratorsListUtils';

type CollaboratorsListProps = {
  classes: Classes<typeof styles>;
  collaborators: Collaborator[];
  searchTerm: string;
  setSortData: (sortData: SortDataType) => void;
  sortData: SortDataType;
};

const CollaboratorsList: FC<CollaboratorsListProps> = ({
  classes,
  collaborators,
  searchTerm,
  setSortData,
  sortData,
}) => {
  // UI GENERATORS
  const collaboratorsList: JSX.Element[] = collaborators.map((collaborator: Collaborator) => (
    <CollaboratorsListItem
      collaborator={collaborator}
      key={collaborator.id}
      searchTerm={searchTerm}
    />
  ));
  const collaboratorsListHeaders: JSX.Element[] = headerContent.map(
    (header: { copy: string; title: string }) => (
      <CollaboratorsListHeader
        header={header}
        hasMultiple={collaborators.length > 1}
        key={header.copy}
        setSortData={setSortData}
        sortData={sortData}
      />
    )
  );
  return (
    <div>
      <div className={`${classes.containerRow} ${classes.containerRowHeader}`}>
        {collaboratorsListHeaders}
      </div>
      {collaboratorsList}
    </div>
  );
};
export default withStyles(styles)(CollaboratorsList);
