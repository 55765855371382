import { useEffect, useState } from 'react';

import { FieldType } from '../../../../api/gqlEnumsBe';
import { getCategorizationLevelName } from '../../../../utilities/categorization';
import { removeYear } from '../../../../utilities/string';
import { isNonNullable } from '../../../../utilities/types';
import { selectCategoryDisplayName } from '../../../Select/SelectCategory/SelectCategoryUtils';

export const HEADER_CONTAINER_HEIGHT = 56;
export const MIN_NUM_LINES = 4;
export const MAX_NUM_COMBINATIONS = 1000;
const MAX_CATEGORIZATIONS_CHARACTERS = 30;

export type QuantityListItem = {
  abbreviationPlural: string;
  abbreviationSingular: string;
  cy: string;
  estimateID?: UUID;
  hasBreakdown: boolean;
  magnitude?: number;
  milestoneID: UUID;
  name: string;
  placeholder: string;
  quantityID?: UUID;
  unitID: UUID;
  unitName: string;
};

// Auto fill category combos
export const AUTOFILL = 'AUTOFILL';
export const MANUAL = 'MANUAL';

const AUTOFILL_MODE = 'AutoFillMode';
export const generateAutoFillModeStorageLocation = (milestoneID: UUID, unitID: UUID) =>
  `${AUTOFILL_MODE} ${milestoneID} ${unitID}`;

export const formatGroupByDisplay = (categorizations: Categorization[]) => {
  const groupByList = listGroupBy(categorizations);
  if (groupByList.length <= MAX_CATEGORIZATIONS_CHARACTERS) return groupByList;

  const numCategorizations = categorizations.length;
  let listAbbreviated = '';
  for (let i = 0; i < numCategorizations; i += 1) {
    const name = removeYear(categorizations[i].name);
    const overflowText = `+${numCategorizations - i}`;
    const extraChars = 2; // ', '
    if (
      i === 0 &&
      numCategorizations === 1 &&
      name &&
      name.length > MAX_CATEGORIZATIONS_CHARACTERS
    ) {
      return name;
    }
    if (
      listAbbreviated.length + (name?.length || 0) + overflowText.length + extraChars <=
      MAX_CATEGORIZATIONS_CHARACTERS
    ) {
      listAbbreviated += `${i > 0 ? ', ' : ''}${name}`;
    } else {
      listAbbreviated += ` ${overflowText}`;
      return listAbbreviated;
    }
  }

  return listAbbreviated;
};

const mapCategorizationToString = (
  categorizations: (Categorization | null)[],
  getCategorizationString: (categorization: Categorization) => string
) =>
  !categorizations || categorizations.length === 0
    ? []
    : categorizations.filter(isNonNullable).map(getCategorizationString);

export const getGroupByNames = (categorizations: (Categorization | null)[]) =>
  mapCategorizationToString(categorizations, getCategorizationLevelName);

const getCategorizationNameWithoutYear = (categorization: Categorization) =>
  removeYear(categorization?.name);

export const listGroupBy = (categorizations: (Categorization | null)[]) => {
  const names = mapCategorizationToString(categorizations, getCategorizationNameWithoutYear);
  return names && names.join(', ');
};

export const formatQuantityInfo = (quantityListItem: QuantityListItem) => {
  const {
    abbreviationPlural,
    abbreviationSingular,
    magnitude,
    milestoneID,
    quantityID,
    unitName,
    unitID,
  } = quantityListItem;
  const unitInfo: UnitInfo = { abbreviationPlural, abbreviationSingular, unitID, unitName };
  return {
    quantityID,
    magnitude,
    milestoneID,
    unitInfo,
  };
};

export const defaultQuantity: QuantityInfo = {
  magnitude: 0,
  milestoneID: '',
  quantityID: '',
  unitInfo: {
    abbreviationPlural: '',
    abbreviationSingular: '',
    unitID: '',
    unitName: '',
  },
};

const formatGridCategory = (category?: Category) => ({
  search: category ? selectCategoryDisplayName(category) : '',
});

export const formulateCategoryCombinations = (
  categorizationIDs: UUID[],
  categoryCombinations: CategoryCombination[]
) =>
  categoryCombinations.map((combination) =>
    categorizationIDs.map((categorizationID) => {
      const category = combination.categories.find(
        (category: Category) => category.categorization?.id === categorizationID
      );
      return formatGridCategory(category);
    })
  );

export const formatCategorizationInput = (
  categorizationID: UUID,
  term = '',
  limit = 100,
  level = 1
) => ({
  categorizationID,
  term,
  limit,
  level,
});

export const formatFieldInputs = (
  categorizations: Pick<Categorization, 'name' | 'id'>[]
): FieldInput[] =>
  categorizations.map(({ id, name }, i) => ({
    categorizationID: id,
    name,
    ordering: i,
    type: FieldType.CATEGORY,
  }));

export const useDisableButtonsTilPopulated = (enableLogic: boolean) => {
  const [disableButtons, setDisableButtons] = useState(false);
  useEffect(() => {
    if (disableButtons && enableLogic) setDisableButtons(false);
  }, [disableButtons, enableLogic]);
  return { disableButtons, setDisableButtons };
};
