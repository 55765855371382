import { FC } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import FilterIcon from '../../Icons/FilterIcon';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ErrorTooltip from '../ErrorTooltip';
import NoticeTooltip from '../NoticeTooltip';
import { ReferenceDisplay } from '../types';

import SelectedReferencesToolTip from './SelectedReferencesToolTip';

interface ReferenceRendererProps {
  onSelect: () => void;
  value: MarkupReferenceCell;
  readOnly: boolean;
  error: string;
  dataCy?: string;
}

const ReferenceRenderer: FC<ReferenceRendererProps> = ({
  value,
  onSelect,
  readOnly,
  error,
  dataCy,
}) => {
  const { names, filters } = value;

  const hasNoReferences = names && names.length === 1 && names[0] === ReferenceDisplay.NOT_APPLIED;

  const arrowStyle = 'join-grid-dropdown-arrow';

  const hasDropdown = !readOnly;
  const hasFilters = (value.filters || []).length > 0;
  const disabledMarkupStyle = value.disabled ? `join-grid-line-disabled` : '';

  let colorStyle = '';
  if (error) colorStyle = 'join-grid-error';
  if (hasNoReferences && !error) colorStyle = 'join-grid-notice';

  const errorTooltip =
    error || (hasNoReferences && 'Calculate your percent markups by selecting where they apply to');

  const errorText = (
    <Error
      className={colorStyle}
      color={hasNoReferences ? 'inherit' : 'error'}
      style={{ fontSize: '20px' }}
    />
  );

  return (
    <div
      className={`join-grid-renderer join-grid-string ${
        ((error || hasNoReferences) && 'join-grid-reference-cell-error') || ''
      }`}
    >
      <div className={`join-grid-trim-text  ${disabledMarkupStyle} ${colorStyle}`}>
        {hasFilters ? (
          <NormalTooltip title={<SelectedReferencesToolTip categories={filters} />}>
            <div className="join-grid-filter-icon">
              <FilterIcon isFilled isError={!!error} />
            </div>
          </NormalTooltip>
        ) : null}
        {names}
      </div>
      {error || hasNoReferences ? (
        <div className="push-right">
          {error && <ErrorTooltip title={errorTooltip}>{errorText}</ErrorTooltip>}
          {!error && hasNoReferences && (
            <NoticeTooltip title={errorTooltip}>{errorText}</NoticeTooltip>
          )}
        </div>
      ) : null}
      <div
        data-cy={`button-${dataCy}`}
        onClick={hasDropdown ? onSelect : () => {}}
        onKeyDown={hasDropdown ? onSelect : () => {}}
        className={
          hasDropdown ? `${arrowStyle} ${error || hasNoReferences ? '' : 'push-right'}` : ''
        }
      />
    </div>
  );
};

export default ReferenceRenderer;
