import { useEffect } from 'react';

import { ApolloSandbox as Sandbox } from '@apollo/sandbox/react';
import { createStyles } from '@material-ui/core';

import { withStyles } from '../theme/komodo-mui-theme';
import { tokenUtil } from '../utilities/authUtils';

import ErrorCode from './errors/ErrorCode';

const styles = () =>
  createStyles({
    sandbox: {
      height: 'calc(100vh - 51px)',
      width: '100vw',
    },
  });

const ApolloSandbox = ({ classes }: { classes: Classes<typeof styles> }) => {
  const isUsable = process?.env?.NODE_ENV === 'development';

  useEffect(() => {
    if (isUsable) {
      document.title = 'Apollo Sandbox';
    }
  }, [isUsable]);

  if (!isUsable) {
    return <ErrorCode code={404} />;
  }

  return (
    <Sandbox
      initialEndpoint="http://localhost:3001/graphql"
      includeCookies={false}
      className={classes.sandbox}
      handleRequest={async (endpointUrl, options) => {
        const token = await tokenUtil.getAccessTokenSilently();
        return fetch(endpointUrl, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        });
      }}
    />
  );
};

export default withStyles(styles)(ApolloSandbox);
