import { FC } from 'react';

import { ApolloError } from '@apollo/client';

import { CostReportColumnType, MilestoneEstimateInfo } from '../../generated/graphql';
import { SetSettingsFunctionType } from '../../utilities/urlState';
import CostReportBody from '../CostReport/CostReportBody/CostReportBody';
import { VarianceColumnDescription } from '../CostReport/CostReportColumns/CostReportColumns';
import { FilterManager } from '../FilterPanel/filterUtils';
import { VarianceReportComments } from '../ReportsTab/ReportHooks';

export type VarianceCleanInput = {
  milestoneId: UUID;
  type: CostReportColumnType;
};

type VarianceCostReportProps = {
  activeMilestoneID: UUID;
  categorizations: Categorization[];
  displayGroupBy: DisplayGroupBy[];
  error?: ApolloError;
  filterManager: FilterManager;
  headerDescriptions: VarianceColumnDescription[];
  loading: boolean;
  milestoneEstimates: MilestoneEstimateInfo[];
  settings: VarianceSettings;
  scroll?: boolean;
  setIsLoading?: (loading: boolean) => void;
  setSettings: SetSettingsFunctionType;
  varianceDescriptions: VarianceColumnDescription[];
  varianceReports: VarianceReports;
  varianceReportComments?: VarianceReportComments;
};

const VarianceCostReport: FC<VarianceCostReportProps> = ({
  activeMilestoneID,
  displayGroupBy,
  categorizations,
  error,
  filterManager,
  headerDescriptions,
  loading,
  milestoneEstimates,
  settings,
  scroll,
  setIsLoading = () => {},
  setSettings,
  varianceDescriptions,
  varianceReports,
  varianceReportComments,
}) => (
  <CostReportBody
    activeMilestoneID={activeMilestoneID}
    categorizations={categorizations}
    costReports={varianceReports}
    reportComments={varianceReportComments}
    displayColumnDescriptions={varianceDescriptions}
    displayGroupBy={displayGroupBy}
    error={error}
    filterManager={filterManager}
    isVariance
    headerDescriptions={headerDescriptions}
    loading={loading}
    milestoneEstimates={milestoneEstimates}
    settings={settings}
    scroll={scroll}
    setIsLoading={setIsLoading}
    setSettings={setSettings}
  />
);

export default VarianceCostReport;
