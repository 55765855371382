import { TermKey } from '../../../api/gqlEnums';
import { CostReportColumnType } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { capitalizeString } from '../../../utilities/string';

export const SMALL_CHART_HEIGHT = 260;
export const SMALL_CHART_LEFT_PADDING = 64;
export const CHART_RIGHT_PADDING = 12;
export const CHART_TOP_PADDING = 8;
export const CHART_BOTTOM_PADDING = 74;

export const mockTermStore = {
  titleCase: (e: string) => e,
  rawTerm: (e: string) => e,
  lowerCase: (e: string) => e.toLowerCase(),
  sentenceCase: (e: string) => capitalizeString(e),
};

// OUTPUT TYPES
export type LinePoint = {
  x: number;
  y: number;
  dateStart: string;
  dateEnd: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  label?: any;
  isVisible: boolean;
  milestone: MilestonesData;
  values: PointValue[]; // for hoverf
};

export type HoverPoint = LinePoint & {
  color?: string;
  opacity?: number;
};

export type LineProp = {
  color?: string;
  curve?: string;
  strokeDasharray?: string;
  strokeWidth?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  style?: any;
  title?: string;
  type?: CostReportColumnType;
};

export type Trendline = LineProp & {
  data: LinePoint[];
};

// TODO: These are modification of existing types
type LabelPoint = {
  x: number;
  dateStart: Date | string;
  y: number;
  label?: string;
  milestone: MilestonesData;
  values: PointValue[];
  yOffset: number;
};

export type TrendLegendLabel = LineProp;

export type TrendLabel = LineProp & {
  color?: string;
  data: LabelPoint[];
  labelAnchorX: string;
  labelAnchorY: string;
};

export type MilestonesData = Pick<Segment['milestone'], 'id' | 'name' | 'date' | 'description'> & {
  quantity?: NonNullable<Segment['milestone']['quantities']>[number];
  x?: number;
};

export type TrendData = {
  milestonesXData: MilestonesData[];
  labels: TrendLabel[];
  legendLabels: TrendLegendLabel[];
  lineData: Trendline[];
  minorMarkData: Trendline[];
  markData: Trendline[];
  xDomain: number[];
  selectedPoints: HoverPoint[];
  somePointsHidden: boolean;
};

// DEFINITIONS
const linesProps: LineProp[] = [
  {
    color: theme.palette.joinPrimary,
    type: CostReportColumnType.RUNNINGTOTAL_REPORT,
    strokeWidth: '2',
  },
  {
    color: theme.palette.primaryGrey,
    type: CostReportColumnType.ESTIMATE_REPORT,
    strokeDasharray: '1, 2',
    strokeWidth: '1',
  },
  {
    color: theme.palette.budget,
    type: CostReportColumnType.TARGET_REPORT,
  },
];

// HELPERS
export const getXWithDomain = (mIdx: number, mCount: number, iIdx: number, iCount: number) => {
  const start = mIdx / mCount; // milestone startpoint
  const isLastSubDomain = mIdx === mCount - 1;
  const lastCount = isLastSubDomain ? 1 : 0;
  const intervalDivisions = iCount - lastCount || 1; // if the last only has 1, then we pad
  const inM = iIdx / intervalDivisions / mCount; // Here's how do define overlap?
  return start + inM;
};

const sortReports = (a: Trendline, b: Trendline) => {
  // last in the list gets rendered on top
  if (a.type === CostReportColumnType.RUNNINGTOTAL_REPORT) return 1;
  if (b.type === CostReportColumnType.RUNNINGTOTAL_REPORT) return -1;
  if (a.type === CostReportColumnType.TARGET_REPORT) return -1;
  if (b.type === CostReportColumnType.TARGET_REPORT) return 1;
  return 0;
};

export const getLegend: (lineData: Trendline[], t: TermStore) => TrendLegendLabel[] = (
  lineData,
  t
) => {
  const legendLines: LineProp[] = [
    {
      title: `${t.titleCase(TermKey.ESTIMATE)} Total`,
      type: CostReportColumnType.ESTIMATE_REPORT,
      strokeDasharray: '1, 2',
      color: theme.palette.primaryGrey,
    },
    {
      title: t.titleCase(TermKey.RUNNING_TOTAL),
      type: CostReportColumnType.RUNNINGTOTAL_REPORT,
      color: theme.palette.primaryBlue,
    },
    {
      title: t.titleCase(TermKey.TARGET),
      type: CostReportColumnType.TARGET_REPORT,
      color: theme.palette.budget,
    },
  ];

  return legendLines.filter((lineProp: LineProp) =>
    lineData.some((line: Trendline) => line.type === lineProp.type)
  );
};

export const getLabels: (lineData: Trendline[]) => TrendLabel[] = (lineData) => {
  const milestoneFontSize = Number(theme.typography.chart.fontSize);
  // when a label overlaps, how far should we move it to prevent overlap (percent of height)
  const moveConstant = 1.5;
  return lineData.map((line: Trendline) => {
    const data = line.data.map((pt: LinePoint) => {
      // find order from greatest to least
      const peerYValues: number[] = pt.values.map((v: PointValue) => v.y);
      const sortLarge = (a: number, b: number) => b - a;
      // const uniqueYValues = Array.from(new Set(peerYValues.sort(sortLarge)));
      const idx = peerYValues.sort(sortLarge).findIndex((y) => Number(y) === pt.y);
      const { y } = pt;
      let direction = -1;
      if (idx > 0) {
        // y = uniqueYValues.pop() || y;
        direction = idx;
      }
      const yOffset = direction * milestoneFontSize * moveConstant;
      return { ...pt, y, yOffset };
    });
    return {
      ...line,
      style: { fill: line.color, fontSize: milestoneFontSize, fontWeight: 500 },
      labelAnchorX: 'middle',
      labelAnchorY: 'middle',
      data,
    };
  });
};

export const extendValues = (inputValues: PointValue[]) => {
  const values = inputValues.slice();
  const target = values.find((value) => value.type === CostReportColumnType.TARGET_REPORT);
  const runningTotal = values.find(
    (value) => value.type === CostReportColumnType.RUNNINGTOTAL_REPORT
  );
  const estimate = values.find((value) => value.type === CostReportColumnType.ESTIMATE_REPORT);
  if (runningTotal && estimate) {
    values.push({
      type: CostReportColumnType.ACCEPTED_REPORT,
      y: runningTotal.y - estimate.y,
      isChanged: true,
    });
  }
  if (runningTotal && target) {
    values.push({
      type: CostReportColumnType.REMAINING_REPORT,
      y: target.y - runningTotal.y,
      isChanged: true,
    });
  }
  return values.map(({ type, y }: PointValue) => ({
    type,
    report: { directCostRange: { value: y }, range: { value: y } },
  }));
};

// HELPER FUNCTION TO BUILD MARKS
const addTrendlineValue = (
  linePtData: LinePoint[],
  trendlines: Trendline[],
  type: CostReportColumnType
) => {
  const markIdx = trendlines.findIndex((l: Trendline) => l.type === type);
  if (markIdx < 0) {
    const lineProp = linesProps.find((prop: LineProp) => prop.type === type);
    if (lineProp) trendlines.push({ data: linePtData.slice(), ...lineProp });
  } else {
    trendlines[markIdx].data.push(...linePtData);
  }
};

export const getTrendData: (
  terms: TermStore,
  segments?: Segment[],
  selectedVisible?: { milestone: { id: string }; point: { dateStart: string } },
  unitID?: UUID
) => TrendData = (terms, segments, selectedVisible, unitID) => {
  const milestonesXData: MilestonesData[] = [];
  // We keep 5 data sets up to date: what we've added in the milestone, the entire lines,
  // minor marks and the major marks
  let milestoneLineData: Trendline[] = []; // always add a mark if the milestoneLines have't started
  const lineData: Trendline[] = []; // for lines
  const minorMarkData: Trendline[] = []; // for minor points, changes on lines
  const markData: Trendline[] = []; // for major points with marks
  const labelData: Trendline[] = []; // for major points with labels + hovered
  let lastX = 1;
  const selectedPoints: HoverPoint[] = [];
  let somePointsHidden = false;
  const costFormat = { short: true, showCurrencySymbol: false };
  if (segments) {
    let ptCount = 1;
    segments.forEach((segment: Segment) => {
      // a segment is per-milestone
      let lastPoint: LinePoint | null = null;
      const { milestone: segmentMilestone } = segment;
      const { quantities } = segmentMilestone;
      const quantity = quantities && quantities.find((q) => q?.unit.id === unitID);
      const milestone = { ...segmentMilestone, quantity };
      // if all the points are filtered out we still want to display the milestone on the chart
      if (segment.points.length === 0) {
        milestonesXData.push({ ...milestone, x: ptCount });
        ptCount += 1;
      }
      segment.points.forEach((pt: Point) => {
        let didAddValue = false;
        // MILESTONE BEGIN + END
        const milestoneIndex = milestonesXData.findIndex(
          (m: MilestonesData) => m.id === milestone.id
        );
        // Milestone Labels
        const isNewMilestone = milestoneIndex < 0;
        if (isNewMilestone) {
          milestonesXData.push({ ...milestone, x: ptCount });
          lastX = ptCount * 1.15; // buffer more for milestone name
          milestoneLineData = [];
          didAddValue = true;
        }
        // any changed? Show line...
        pt.values.forEach((value: PointValue) => {
          if (value.y > 0) {
            const linePoint: LinePoint = {
              label: formatCost(value.y, costFormat),
              milestone,
              dateStart: pt.dateStart,
              dateEnd: pt.dateEnd,
              values: pt.values,
              isVisible: pt.isVisible,
              x: ptCount,
              y: Number(value.y),
            };
            // TESTS
            const milestoneLine = milestoneLineData.find((l: Trendline) => l.type === value.type);
            const isFirstPoint = !milestoneLine;
            const isSelected =
              !!selectedVisible &&
              selectedVisible.milestone.id === milestone.id &&
              selectedVisible.point.dateStart === pt.dateStart;
            // if the user is selecting the date, we'll show the point
            const { isVisible } = pt;
            if (isSelected) {
              didAddValue = true;
              const lineProp = linesProps.find((prop: LineProp) => prop.type === value.type);
              const color = (lineProp && lineProp.color) || 'black';
              const opacity = isVisible ? 1 : 0.5;
              selectedPoints.push({ ...linePoint, x: ptCount, isVisible: true, color, opacity });
              addTrendlineValue([linePoint], labelData, value.type);
            }
            // always add the first data point in a milestone-type line
            if (isVisible) {
              didAddValue = true;
              addTrendlineValue([linePoint], lineData, value.type); // EVERY POINT HAS CHANGE, NOW
              lastPoint = linePoint;
              if (!isFirstPoint && value.isChanged) {
                addTrendlineValue([linePoint], minorMarkData, value.type);
              }
              if (isFirstPoint) {
                addTrendlineValue([linePoint], milestoneLineData, value.type);
                addTrendlineValue([linePoint], markData, value.type);
                addTrendlineValue([linePoint], labelData, value.type);
              }
            } else {
              somePointsHidden = true;
            }
          }
        });
        // if we added any milestone or point... advance x count
        if (didAddValue) {
          ptCount += 1;
        }
      });
      // show everything for the "last point"
      if (lastPoint) {
        const { values, x } = lastPoint as LinePoint;
        if (values)
          values.forEach((value: PointValue) => {
            if (value.y > 0 && !!lastPoint) {
              lastX = Number(x) * 1.05;
              const data = [
                {
                  ...lastPoint,
                  label: formatCost(value.y, costFormat),
                  isVisible: true,
                  y: Number(value.y),
                },
              ];
              addTrendlineValue(data, markData, value.type);
              addTrendlineValue(data, labelData, value.type);
              addTrendlineValue(data, lineData, value.type);
            }
          });
      }
    });
  }
  const xDomain = [1 - 0.05 * lastX, lastX];
  lineData.sort(sortReports);
  markData.sort(sortReports);
  labelData.sort(sortReports);
  const labels: TrendLabel[] = getLabels(labelData);
  const legendLabels: TrendLegendLabel[] = getLegend(lineData, terms);
  return {
    milestonesXData,
    labels,
    legendLabels,
    lineData,
    minorMarkData,
    markData,
    xDomain,
    selectedPoints,
    somePointsHidden,
  };
};
