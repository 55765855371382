import { AnalyticsEvent, gridAnalytics } from '../analytics/analyticsEventProperties';
import { InputMaybe, MarkupDisplayType } from '../generated/graphql';

export type EventCategory = 'MILESTONE' | 'ITEM';
type MilestoneEventKind = 'ESTIMATE' | 'BUDGET' | 'QUANTITY';
type ItemEventKind =
  | 'ITEM_DETAILS'
  | 'OPTION_DETAILS'
  | 'ITEM_FULLSCREEN'
  | 'OPTION_FULLSCREEN'
  | 'ITEM_TEMPLATE';
type EventKind<T extends EventCategory> = T extends 'MILESTONE'
  ? MilestoneEventKind
  : ItemEventKind;

type AnalyticsFn<Properties = object> = (
  type: EventCategory,
  kind: EventKind<EventCategory>
) => (p: Properties) => AnalyticsEvent;

// For grid analytics, we always want to include certain properties distinguishing the different
// kinds of events that occur on each milestone and item estimates. This function dedupicates
// that creation logic in a type-safe way.
export function makeMilestoneItemAnalytics<Type extends EventCategory, Properties>(
  type: Type,
  kind: EventKind<Type>,
  name: (type: Type) => string
) {
  const kindProps = type === 'MILESTONE' ? { estimateType: kind } : { itemType: kind };
  const eventName: string = name(type);

  return (properties: Properties) => gridAnalytics(eventName, { ...properties, ...kindProps });
}

export type AddColumnProperties = {
  columns: number; // number of columns after creation
};

export const addColumnAnalytics: AnalyticsFn<AddColumnProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_COLUMN`);

export type CreationMethod = 'Button' | 'EnterPress' | 'Paste' | 'Initial';

export type CreateLinesProperties = {
  createdLines: number; // number of lines that were added
  source: CreationMethod;
};

export const createLinesAnalytics: AnalyticsFn<CreateLinesProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_LINES`);

export type CreateMarkupsProperties = {
  method: CreationMethod;
};

export const createMarkupAnalytics: AnalyticsFn<CreateMarkupsProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_MARKUP`);

export type EditCellsProperties = {
  cells: number; // # of cells touched;
  fields: string[]; // names of the columns that were touched
  includesCategoryCells: boolean;
};

export const updateCellsAnalytics: AnalyticsFn<EditCellsProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `EDIT_${t}_ESTIMATE_CELLS`);

export const openColumnMenuAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `OPEN_${t}_ESTIMATE_COLUMN_MENU`);

export type RemoveColumnProperties = {
  columns: number; // # of columns after action completed
};

export const moveColumnAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `MOVE_${t}_ESTIMATE_COLUMN`);

export const removeColumnAnalytics: AnalyticsFn<RemoveColumnProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_COLUMN`);

export type RemoveLinesProperties = {
  estimateLength: number; // # of rows after action completed
};

export const removeLineAnalytics: AnalyticsFn<RemoveLinesProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_LINES`);

export const removeMarkupAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_MARKUP`);

export const reorderLineAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REORDER_${t}_ESTIMATE_LINES`);

export const reorderMarkupAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REORDER_${t}_MARKUPS`);

export const selectCategorizationAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `SELECT_${t}_COLUMN_CATEGORIZATION`);

export type SetExpandedProperties = {
  expanded: boolean; // State of the collapse after the action;
};

export const setExpandedAnalytics: AnalyticsFn<SetExpandedProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `SET_${t}_ESTIMATE_EXPANDED`);

export type SetTotalTypeProperties = {
  newTotalType: string;
  oldTotalType: string;
};

export const setTotalTypeAnalytics: AnalyticsFn<SetTotalTypeProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `${t.toLowerCase()}Estimate_setTotalType`);

export type ToggleInheritedItemMarkupProperties = {
  status: string;
};

export const toggleInheritedItemMarkupAnalytics: AnalyticsFn<
  ToggleInheritedItemMarkupProperties
> = (t, k) => makeMilestoneItemAnalytics(t, k, (t) => `TOGGLE_INHERITED_${t}_MARKUP`);

export type ToggleAllocatedMarkupProperties = {
  shouldExcludeFromAllocation: boolean;
};

export const toggleAllocatedMarkupAnalytics: AnalyticsFn<ToggleAllocatedMarkupProperties> = (
  t,
  k
) => makeMilestoneItemAnalytics(t, k, (t) => `TOGGLE_ALLOCATED_${t}_MARKUP`);

export type UpdateMarkupsProperties = {
  includesCategoryFilters: boolean;
  displayType: MarkupDisplayType | undefined | InputMaybe<MarkupDisplayType>;
};

export const updateMarkupAnalytics: AnalyticsFn<UpdateMarkupsProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `UPDATE_${t}_ESTIMATE_MARKUP`);

export type GridAnalytics = {
  addColumnAnalytics: (p: AddColumnProperties) => void;
  createLinesAnalytics: (p: CreateLinesProperties) => void;
  createMarkupAnalytics: (p: CreateMarkupsProperties) => void;
  removeLineAnalytics: (p: RemoveLinesProperties) => void;
  removeMarkupAnalytics: () => void;
  reorderLineAnalytics: () => void;
  reorderMarkupAnalytics: () => void;
  updateMarkupAnalytics: (p: UpdateMarkupsProperties) => void;
  updateCellsAnalytics: (p: EditCellsProperties) => void;
  moveColumnAnalytics: () => void;
  removeColumnAnalytics: (p: RemoveColumnProperties) => void;
  setTotalTypeAnalytics: (p: SetTotalTypeProperties) => void;
  toggleInheritedItemMarkupAnalytics: (p: ToggleInheritedItemMarkupProperties) => void;
  toggleAllocatedMarkupAnalytics: (p: ToggleAllocatedMarkupProperties) => void;
};

export enum GridVariant {
  CATEGORIZATION = 'CATEGORIZATION',
  ITEM_DETAILS = 'ITEM_DETAILS',
  ITEM_FULLSCREEN = 'ITEM_FULLSCREEN',
  ITEM_TEMPLATE = 'ITEM_TEMPLATE',
  MILESTONE_ESTIMATE = 'MILESTONE_ESTIMATE',
  MILESTONE_BUDGET = 'MILESTONE_BUDGET',
  QUANTITY = 'QUANTITY',
}
export const isItemEstimateVariant = (variant?: GridVariant) =>
  variant === GridVariant.ITEM_DETAILS || variant === GridVariant.ITEM_FULLSCREEN;

export const isNonFullscreenVariant = (variant?: GridVariant) =>
  variant === GridVariant.CATEGORIZATION ||
  variant === GridVariant.ITEM_DETAILS ||
  variant === GridVariant.ITEM_TEMPLATE ||
  variant === GridVariant.QUANTITY;

export const isAccordionVariant = (variant?: GridVariant) =>
  isItemEstimateVariant(variant) ||
  variant === GridVariant.MILESTONE_BUDGET ||
  variant === GridVariant.MILESTONE_ESTIMATE;

export const isItemVariant = (variant?: GridVariant) =>
  isItemEstimateVariant(variant) || variant === GridVariant.ITEM_TEMPLATE;

export const isMilestoneVariant = (variant?: GridVariant) =>
  !!variant && [GridVariant.MILESTONE_ESTIMATE, GridVariant.MILESTONE_BUDGET].includes(variant);

const getGridKind = (variant: GridVariant, isOption: boolean) => {
  switch (variant) {
    case GridVariant.ITEM_DETAILS:
      return isOption ? 'OPTION_DETAILS' : 'ITEM_DETAILS';
    case GridVariant.ITEM_FULLSCREEN:
      return isOption ? 'OPTION_FULLSCREEN' : 'ITEM_FULLSCREEN';
    case GridVariant.ITEM_TEMPLATE:
      return 'ITEM_TEMPLATE';
    case GridVariant.MILESTONE_ESTIMATE:
      return 'ESTIMATE';
    case GridVariant.MILESTONE_BUDGET:
      return 'BUDGET';
    case GridVariant.QUANTITY:
      return 'QUANTITY';
    default:
      return 'ITEM_FULLSCREEN';
  }
};

export const makeGridAnalytics = (
  isOption: boolean,
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void,
  variant: GridVariant
) => {
  const type: EventCategory = isItemVariant(variant) ? 'ITEM' : 'MILESTONE';
  const kind: EventKind<EventCategory> = getGridKind(variant, isOption);

  const analytics = {
    createLinesAnalytics: (p: CreateLinesProperties) => {
      sendAnalytics(createLinesAnalytics(type, kind)(p));
    },
    createMarkupAnalytics: (p: CreateMarkupsProperties) => {
      sendAnalytics(createMarkupAnalytics(type, kind)(p));
    },
    updateMarkupAnalytics: (p: UpdateMarkupsProperties) => {
      sendAnalytics(updateMarkupAnalytics(type, kind)(p));
    },
    removeLineAnalytics: (p: RemoveLinesProperties) => {
      sendAnalytics(removeLineAnalytics(type, kind)(p));
    },
    removeMarkupAnalytics: () => {
      sendAnalytics(removeMarkupAnalytics(type, kind)({}));
    },
    reorderLineAnalytics: () => {
      sendAnalytics(reorderLineAnalytics(type, kind)({}));
    },
    reorderMarkupAnalytics: () => {
      sendAnalytics(reorderMarkupAnalytics(type, kind)({}));
    },
    updateCellsAnalytics: (p: EditCellsProperties) => {
      sendAnalytics(updateCellsAnalytics(type, kind)(p));
    },
    addColumnAnalytics: (p: AddColumnProperties) => {
      sendAnalytics(addColumnAnalytics(type, kind)(p));
    },
    moveColumnAnalytics: () => {
      sendAnalytics(moveColumnAnalytics(type, kind)({}));
    },
    removeColumnAnalytics: (p: RemoveColumnProperties) => {
      sendAnalytics(removeColumnAnalytics(type, kind)(p));
    },
    setTotalTypeAnalytics: (p: SetTotalTypeProperties) => {
      sendAnalytics(setTotalTypeAnalytics(type, kind)(p));
    },
    toggleInheritedItemMarkupAnalytics: (p: ToggleInheritedItemMarkupProperties) => {
      sendAnalytics(toggleInheritedItemMarkupAnalytics(type, kind)(p));
    },
    toggleAllocatedMarkupAnalytics: (p: ToggleAllocatedMarkupProperties) => {
      sendAnalytics(toggleAllocatedMarkupAnalytics(type, kind)(p));
    },
  };
  return analytics;
};
