import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const OpenSidebar = (props: IconProps) => (
  <SvgIcon
    {...props}
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.75 18.5H13.6731V0.5H6.75V18.5Z" fill="black" />
    <path d="M0 9.41346L3.63461 13.3077V5.51923L0 9.41346Z" fill="black" />
  </SvgIcon>
);

export const CloseItemSidebar = (props: IconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.6732 21H11.7501V3H18.6732V21Z" />
    <path d="M5 7.99999L8.63462 11.8942L5 15.7885V7.99999Z" />
  </SvgIcon>
);

export const OpenItemSidebar = (props: IconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.75 21H18.6731V3H11.75V21Z" />
    <path d="M5 11.9135L8.63461 15.8077V8.01923L5 11.9135Z" />
  </SvgIcon>
);
