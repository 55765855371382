import { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { UserStatus } from '../api/gqlEnumsBe';
import { REPORT_DISTRIBUTION_FILE } from '../constants';
import { DD_NEW_NAV } from '../features';
import { useHasFeature } from '../hooks/useFeatureQuery';
import { useIsRouteAccessible } from '../hooks/useIsRouteAccessible';
import useLoadCurrentUserStatusQuery from '../hooks/useLoadCurrentUserStatusQuery';
import { companyLinks, projectLinks } from '../utilities/routes/links';
import { getProjectIdFromUrl } from '../utilities/url';

import AppStyles from './AppStyles';
import FrameTitleBar from './frame/FrameTitleBar/FrameTitleBar';
import Sidebar from './frame/Sidebar';
import { useHasDrawer } from './frame/SidebarUtils';
import { joinAuthContext } from './JoinAuthProvider';

type Props = { classes: Classes<typeof AppStyles> };

const AppNavLegacy = (props: Props) => {
  // LOCATION HOOKS
  const location = useLocation();
  const { pathname } = location;
  const projectId = getProjectIdFromUrl(pathname);

  // MODULES, FEATURES, AND LINKS
  const { isAccessible } = useIsRouteAccessible();
  const companyLinksFiltered = companyLinks(isAccessible);
  const hasCompanyLinks = companyLinksFiltered.length > 1;
  const companyLinksDisplay = hasCompanyLinks ? companyLinksFiltered : [];
  const hasCompanyOrProject = hasCompanyLinks || !!projectId;
  const links = !projectId ? companyLinksDisplay : projectLinks(projectId, isAccessible);
  const { joinUser } = useContext(joinAuthContext);

  const hasNewNav = useHasFeature(DD_NEW_NAV);
  useEffect(() => {
    if (hasNewNav === false) {
      window.HubSpotConversations?.widget.load();
    }
  }, [hasNewNav]);

  // LOCATION DEPENDENCIES
  // TODO: Make into a testable util
  const isPrint = pathname.indexOf('print') >= 0 || pathname.indexOf('export') >= 0;
  const isReportDistributionFile = pathname.indexOf(REPORT_DISTRIBUTION_FILE) >= 0;
  const isDeactivated = pathname.startsWith('/deactivated');
  const hasDrawerLocation = useHasDrawer(isPrint);
  const isSignup =
    pathname.indexOf('/sign-up/company/') >= 0 || pathname.indexOf('/sign-up/user/') >= 0;

  const hasDrawer = hasCompanyOrProject && hasDrawerLocation;
  const noFrame = isPrint || isSignup || isDeactivated || isReportDistributionFile;
  const hasSidebar = hasCompanyOrProject && !noFrame;

  const { data } = useLoadCurrentUserStatusQuery(joinUser?.email);
  const status = data?.user?.status;
  const deactivated = !status || status === UserStatus.DEACTIVATED;
  // COMPONENTS
  const titleBar = !noFrame && (
    <FrameTitleBar
      deactivated={deactivated}
      hasDrawer={hasDrawer}
      hasSidebar={hasSidebar}
      links={links}
    />
  );
  const sidebar = hasSidebar && <Sidebar hasDrawer={hasDrawer} links={links} />;

  return (
    <>
      {titleBar}
      <main className={props.classes.content}>
        {sidebar}
        <div className={props.classes.main}>
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default withStyles(AppStyles)(AppNavLegacy);
