import { useCallback, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { forecastingReportHasUnsavedChangesVar } from '../../../api/apollo/reactiveVars';
import { NEW_COLUMN_FROM_AVERAGES } from '../../../constants';
import {
  AverageCompInput,
  Maybe,
  MetricsInput,
  ProjectCompEscalationInput,
  ProjectCompEscalationMetaInput,
  ProjectCompInput,
  ProjectCompsCostTableCellType,
  ProjectCompsSetInput,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { getNewPickerColor } from '../../../utilities/colors';
import { mapCostModeStringToEnum } from '../../../utilities/costMode';
import { EscalationTargetLocation } from '../constants/projectCompTypes';

import { ProjectCompsSetInputStoreContext } from './ProjectCompsSetInputStoreContext';
import {
  createOrUpdateCategoryLineInputCost,
  createOrUpdateCategoryLineInputCostDescription,
  createOrUpdateMarkupLineInputCost,
  createOrUpdateMarkupLineInputCostDescription,
  resetCategoryLine,
  resetMarkupLine,
  updateMarkupLineInputName,
} from './UpdatersUtils';

export enum LineActionType {
  EDIT_TOTAL_COST,
  EDIT_QUANTITY_COST,
  EDIT_DESCRIPTION,
  RESET_LINE,
}

const useProjectCompsSetInputContextUpdateFunctions = () => {
  const { setProjectCompsSetInput: setProjectCompsSetInputContextVar } = useContext(
    ProjectCompsSetInputStoreContext
  );

  // this is used only for initializing the input from our saved input, used for useInitialSetSettings
  const initializeProjectCompsSetInput = useCallback(
    (input: ProjectCompsSetInput) => {
      setProjectCompsSetInputContextVar(input);
      if (forecastingReportHasUnsavedChangesVar()) forecastingReportHasUnsavedChangesVar(false);
    },
    [setProjectCompsSetInputContextVar]
  );

  const setProjectCompsSetInput = useCallback(
    (update: Parameters<React.Dispatch<React.SetStateAction<ProjectCompsSetInput>>>[0]) => {
      setProjectCompsSetInputContextVar(update);
      // because this is an update specifically, we also mark the input as having "usaved changes"
      if (!forecastingReportHasUnsavedChangesVar()) forecastingReportHasUnsavedChangesVar(true);
    },
    [setProjectCompsSetInputContextVar]
  );

  const setAverageCompInput = useCallback(
    (update: Parameters<React.Dispatch<React.SetStateAction<AverageCompInput>>>[0]) => {
      setProjectCompsSetInput((prevState) => {
        // don't change average comp unless it already exists
        if (!prevState.averageInput) return prevState;
        // update via function or provided value
        if (typeof update === 'function') {
          return { ...prevState, averageInput: update(prevState.averageInput) };
        }
        return {
          ...prevState,
          averageInput: update,
        };
      });
    },
    [setProjectCompsSetInput]
  );

  type PCIUpdater = ProjectCompInput | ((prevState: ProjectCompInput) => ProjectCompInput);
  const setProjectCompInput = useCallback(
    (id: UUID, update: PCIUpdater) => {
      setProjectCompsSetInput((prevState) => {
        const projectCompInputs = prevState.projectCompInputs.map((projectCompInput) => {
          // don't change other project comps
          if (projectCompInput.id !== id) return projectCompInput;

          // update via the provided value or an updater function
          let updatedPCI: Partial<ProjectCompInput> | undefined;
          if (typeof update === 'function') {
            updatedPCI = { ...update(projectCompInput) };
          } else {
            updatedPCI = update;
          }

          return {
            ...updatedPCI,
            id,
            projectID: projectCompInput.projectID,
          };
        });

        return { ...prevState, projectCompInputs };
      });
    },
    [setProjectCompsSetInput]
  );

  return useMemo(
    () => ({
      initializeProjectCompsSetInput,
      setAverageCompInput,
      setProjectCompInput,
      setProjectCompsSetInput,
    }),
    [
      initializeProjectCompsSetInput,
      setAverageCompInput,
      setProjectCompInput,
      setProjectCompsSetInput,
    ]
  );
};

export const useProjectCompsSetInputUpdateFunctions = () => {
  const sendAnalytics = useAnalyticsEventHook();

  const { initializeProjectCompsSetInput, setProjectCompsSetInput } =
    useProjectCompsSetInputContextUpdateFunctions();

  const addMarkupLine = useCallback(
    (markupName: string) => {
      setProjectCompsSetInput((prevState) => ({
        ...prevState,
        addedMarkupNames: [
          ...(prevState.addedMarkupNames ?? []).filter((mn) => mn !== markupName),
          markupName,
        ],
        excludedMarkupNames: [
          ...(prevState.excludedMarkupNames ?? []).filter((mn) => mn !== markupName),
        ],
      }));

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ADDED_MARKUP_LINE, {
          markupName,
        })
      );
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const renameMarkupLine = useCallback(
    (newMarkupName: string, previousMarkupName: string) => {
      setProjectCompsSetInput((prevState) => {
        // Start by editing the existing markup names in the addedMarkupNames
        // at the top level of the PCSI.
        const newState = {
          ...prevState,
          addedMarkupNames: [
            ...(prevState.addedMarkupNames ?? []).map((mn) => {
              if (mn === previousMarkupName) {
                return newMarkupName;
              }

              return mn;
            }),
          ],
        };

        // Next, we have to carry forward any edits made to the markup line
        // in the average comp or project comps
        if (prevState.averageInput) {
          newState.averageInput = {
            ...prevState.averageInput,
            markupLineInputs: updateMarkupLineInputName(
              prevState.averageInput,
              previousMarkupName,
              newMarkupName
            ),
          };
        }

        newState.projectCompInputs = newState.projectCompInputs.map((pci) => ({
          ...pci,
          markupLineInputs: updateMarkupLineInputName(pci, previousMarkupName, newMarkupName),
        }));

        return newState;
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_RENAMED_MARKUP_LINE, {
          newMarkupName,
          previousMarkupName,
        })
      );
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const createAverageCompInput = useCallback(() => {
    setProjectCompsSetInput((prevState) => {
      if (prevState.averageInput) return prevState;

      return {
        ...prevState,
        averageInput: {
          name: NEW_COLUMN_FROM_AVERAGES,
          categoryLineInputs: [],
          isHidden: false,
          markupLineInputs: [],
          color: getNewPickerColor([]),
        },
      };
    });

    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_START_AVERAGE_CTA)
    );
  }, [sendAnalytics, setProjectCompsSetInput]);

  const addProjectCompInputs = useCallback(
    (projectIDs: UUID[]) => {
      setProjectCompsSetInput((prevState) => {
        return {
          ...prevState,
          projectCompInputs: [
            ...prevState.projectCompInputs,
            ...projectIDs.map((projectID) => ({ id: uuidv4(), projectID })),
          ],
        };
      });
    },
    [setProjectCompsSetInput]
  );

  const cloneProjectCompInput = useCallback(
    (projectCompInputID: UUID) => {
      setProjectCompsSetInput((prevState) => {
        const pciToClone = prevState.projectCompInputs.find((pci) => pci.id === projectCompInputID);
        if (!pciToClone) return prevState;

        return {
          ...prevState,
          projectCompInputs: [...prevState.projectCompInputs, { ...pciToClone, id: uuidv4() }],
        };
      });
    },
    [setProjectCompsSetInput]
  );

  const setCostMode = useCallback(
    (costModeString: string) => {
      const costMode = mapCostModeStringToEnum(costModeString);

      setProjectCompsSetInput((prevState) => ({ ...prevState, costMode }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_DISPLAY_SETTINGS_COSTS, {
          selectedCostType: costModeString,
        })
      );
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const toggleExcludedCategoryContent = useCallback(
    (category: CategoryContentInput) => {
      setProjectCompsSetInput((prevState) => {
        const excludedCategoryContents = prevState.excludedCategoryContents ?? [];
        const prevIsExcluded = excludedCategoryContents.some(
          (c: CategoryContentInput) => c.number.toLowerCase() === category.number.toLowerCase()
        );
        let updatedExcludedCategoryContents = [...excludedCategoryContents];
        if (prevIsExcluded)
          updatedExcludedCategoryContents = excludedCategoryContents.filter(
            (c: CategoryContentInput) => c.number.toLowerCase() !== category.number.toLowerCase()
          );
        else updatedExcludedCategoryContents.push(category);

        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EXCLUDE_LINE_CTA, {
            categoryNumber: category.number,
            categoryName: category.name,
            isExcluded: !prevIsExcluded,
            lineType: 'Category',
          })
        );

        return { ...prevState, excludedCategoryContents: updatedExcludedCategoryContents };
      });
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const toggleExcludedMarkup = useCallback(
    (markupName: string) => {
      setProjectCompsSetInput((prevState) => {
        const excludedMarkupNames = prevState.excludedMarkupNames ?? [];
        const prevIsExcluded = excludedMarkupNames.some((m: string) => m === markupName);
        let updatedExcludedMarkupNames = [...excludedMarkupNames];
        if (prevIsExcluded)
          updatedExcludedMarkupNames = excludedMarkupNames.filter((m) => m !== markupName);
        else updatedExcludedMarkupNames.push(markupName);

        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EXCLUDE_LINE_CTA, {
            isExcluded: !prevIsExcluded,
            lineType: 'Markup',
            markupName,
          })
        );

        return { ...prevState, excludedMarkupNames: updatedExcludedMarkupNames };
      });
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const setEscalationMultiple = useCallback(
    (escalations: (Partial<ProjectCompEscalationInput> & { id: string })[]) => {
      const data: { type: string; meta: ProjectCompEscalationMetaInput[] } = {
        type: '',
        meta: [],
      };

      escalations.forEach((e) => {
        let meta: Maybe<ProjectCompEscalationMetaInput> | undefined;
        if ('locationMeta' in e) {
          data.type = 'location';
          meta = e.locationMeta;
        } else if ('timeMeta' in e) {
          data.type = 'time';
          meta = e.timeMeta;
        }

        if (meta) {
          data.meta.push({
            isFuzzyMatch: meta.isFuzzyMatch,
            sourceLabel: meta.sourceLabel,
          });
        }
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_ESCALATION_MULTIPLE_SET,
          data
        )
      );

      setProjectCompsSetInput((prevState) => ({
        ...prevState,
        projectCompInputs: prevState.projectCompInputs.map((pc) => {
          const escalation = escalations.find((e) => e.id === pc.id);
          if (!escalation) {
            return pc;
          }

          const { id, ...escalationValue } = escalation;

          if ('timeMeta' in escalation) {
            return {
              ...pc,
              escalation: {
                ...pc.escalation,
                time: escalationValue.time,
                timeMeta: escalationValue.timeMeta,
              },
            };
          }
          if ('locationMeta' in escalation) {
            return {
              ...pc,
              escalation: {
                ...pc.escalation,
                location: escalationValue.location,
                locationMeta: escalationValue.locationMeta,
              },
            };
          }

          return {
            ...pc,
            escalation: escalationValue,
          };
        }),
      }));
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  const setExcludedLines = useCallback(
    (excludedCategoryContents: CategoryContentInput[], excludedMarkupNames: string[]) => {
      setProjectCompsSetInput((prevState) => ({
        ...prevState,
        excludedCategoryContents,
        excludedMarkupNames,
      }));
    },
    [setProjectCompsSetInput]
  );

  const setPinnedUnitID = useCallback(
    (pinnedUnitID: string) => {
      setProjectCompsSetInput((prevState) => {
        let { averageInput } = prevState;
        if (averageInput) {
          averageInput = {
            ...averageInput,
            categoryLineInputs: [],
            markupLineInputs: [],
          };
        }

        return {
          ...prevState,
          averageInput,
          pinnedUnitID,
        };
      });
    },
    [setProjectCompsSetInput]
  );

  const setSelectedUnitIDs = useCallback(
    (selectedUnitIDs: UUID[]) => {
      setProjectCompsSetInput((prevState) => {
        let { averageInput, pinnedUnitID } = prevState;
        const isPinnedUnitSelected = selectedUnitIDs.some((id: UUID) => id === pinnedUnitID);
        if (!isPinnedUnitSelected && selectedUnitIDs.length > 0) {
          [pinnedUnitID] = selectedUnitIDs;
          if (averageInput) {
            averageInput = { ...averageInput, categoryLineInputs: [], markupLineInputs: [] };
          }
        }
        return {
          ...prevState,
          averageInput,
          pinnedUnitID,
          selectedUnitIDs,
        };
      });
    },
    [setProjectCompsSetInput]
  );

  // ProjectCompInput - ColumnInputs
  const setProjectCompsSetCostTableColumnInputs = useCallback(
    (costTableColumnInputs: CostTableColumnInputs) => {
      setProjectCompsSetInput((prevState) => ({ ...prevState, costTableColumnInputs }));
      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_SET_COST_TABLE_COLUMN_INPUTS,
          {
            costTableColumnInputs,
          }
        )
      );
    },
    [sendAnalytics, setProjectCompsSetInput]
  );

  return useMemo(
    () => ({
      addMarkupLine,
      renameMarkupLine,
      createAverageCompInput,
      initializeProjectCompsSetInput,
      addProjectCompInputs,
      cloneProjectCompInput,
      setCostMode,
      setProjectCompsSetCostTableColumnInputs,
      setEscalationMultiple,
      setExcludedLines,
      setPinnedUnitID,
      setSelectedUnitIDs,
      toggleExcludedCategoryContent,
      toggleExcludedMarkup,
    }),
    [
      addMarkupLine,
      renameMarkupLine,
      createAverageCompInput,
      initializeProjectCompsSetInput,
      addProjectCompInputs,
      cloneProjectCompInput,
      setCostMode,
      setProjectCompsSetCostTableColumnInputs,
      setEscalationMultiple,
      setExcludedLines,
      setPinnedUnitID,
      setSelectedUnitIDs,
      toggleExcludedCategoryContent,
      toggleExcludedMarkup,
    ]
  );
};

export type ProjectCompsSetInputUpdateFunctions = ReturnType<
  typeof useProjectCompsSetInputUpdateFunctions
>;

export const useAverageCompInputUpdateFunctions = () => {
  const sendAnalytics = useAnalyticsEventHook();
  const { setAverageCompInput, setProjectCompsSetInput } =
    useProjectCompsSetInputContextUpdateFunctions();

  const deleteAverageCompInput = useCallback(() => {
    setProjectCompsSetInput((prevState) => ({
      ...prevState,
      averageInput: undefined,
      // get all the comps, and reset isExcluded
      projectCompInputs: prevState.projectCompInputs.map((pci) => ({ ...pci, isExcluded: false })),
    }));
  }, [setProjectCompsSetInput]);

  const resetAverageCompInput = useCallback(() => {
    setAverageCompInput({ categoryLineInputs: [], markupLineInputs: [] });
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_RESET_DATA_CTA, {
        location: 'Average',
      })
    );
  }, [sendAnalytics, setAverageCompInput]);

  // AverageCompInput - Color and ThumbnailID
  const setAverageCompInputColorAndThumbnailID = useCallback(
    (color?: string, thumbnailAssetID?: string) => {
      setAverageCompInput((prevState) => ({ ...prevState, color, thumbnailAssetID }));
    },
    [setAverageCompInput]
  );

  // AverageCompInput - IsHidden
  const toggleAverageCompInputIsHidden = useCallback(() => {
    setAverageCompInput((prevState) => {
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_TOGGLE_AVERAGE_IS_HIDDEN, {
          isHidden: !prevState.isHidden,
        })
      );

      return { ...prevState, isHidden: !prevState.isHidden };
    });
  }, [sendAnalytics, setAverageCompInput]);

  // AverageCompInput - Location
  const setAverageCompInputLocation = useCallback(
    (newLocation: EscalationTargetLocation) => {
      setAverageCompInput((prevState) => ({
        ...prevState,
        location: newLocation.location,
        lat: newLocation.lat,
        lon: newLocation.lon,
      }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_LOCATION, {
          newLocation,
        })
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  // AverageCompInput - Name
  const setAverageCompInputName = useCallback(
    (name: string | null) => {
      setAverageCompInput((prevState) => ({ ...prevState, name }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_NAME, {
          name,
        })
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  // AverageCompInput - Description
  const setAverageCompInputDescription = useCallback(
    (description: string | undefined) => {
      setAverageCompInput((prevState) => ({ ...prevState, description }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ADD_DESCRIPTION, {
          location: 'Average',
          description,
        })
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  const resetAverageCompInputName = useCallback(() => {
    setAverageCompInputName(null);
  }, [setAverageCompInputName]);

  // AverageCompInput - Metrics
  const setAverageCompMetricsInput = useCallback(
    (unitID: UUID, quantityMagnitude: string | null) => {
      setAverageCompInput((prevState) => {
        const metrics =
          prevState.metrics?.filter((input: MetricsInput) => input.unitID !== unitID) ?? [];

        metrics.push({ unitID, quantityMagnitude, hasMilestoneQuantity: false });

        return { ...prevState, metrics };
      });
    },
    [setAverageCompInput]
  );

  // AverageCompInput - CategoryLineInputs
  const resetCategoryLineInput = useCallback(
    (category: CategoryContentInput) => {
      setAverageCompInput((prevState) => {
        const categoryLineInputs = resetCategoryLine(prevState, category);
        return { ...prevState, categoryLineInputs };
      });
    },
    [setAverageCompInput]
  );

  const setCategoryLineInputCost = useCallback(
    (
      category: CategoryContentInput,
      cellType: ProjectCompsCostTableCellType,
      editedValue: number,
      unitID?: UUID
    ) => {
      setAverageCompInput((prevState) => {
        const categoryLineInputs = createOrUpdateCategoryLineInputCost(
          prevState,
          category,
          cellType,
          editedValue,
          unitID
        );
        // update reactive var and trigger analytics
        return { ...prevState, categoryLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_COST, {
          categoryName: category.name,
          cellType,
        })
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  const setCategoryLineInputDescription = useCallback(
    (category: CategoryContentInput, description: string) => {
      setAverageCompInput((prevState) => {
        const categoryLineInputs = createOrUpdateCategoryLineInputCostDescription(
          prevState,
          category,
          description
        );
        // update reactive var and trigger analytics
        return { ...prevState, categoryLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_COST_DESCRIPTION,
          {
            categoryName: category.name,
            nextDescription: description,
          }
        )
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  const applyCategoryLineInputAction = useCallback(
    (
      actionType: LineActionType,
      category: CategoryContentInput,
      input?: string | number,
      unitID?: UUID
    ) => {
      switch (actionType) {
        case LineActionType.RESET_LINE:
          resetCategoryLineInput(category);
          break;
        case LineActionType.EDIT_TOTAL_COST:
          setCategoryLineInputCost(
            category,
            ProjectCompsCostTableCellType.TOTAL,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_QUANTITY_COST:
          setCategoryLineInputCost(
            category,
            ProjectCompsCostTableCellType.QUANTITY,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_DESCRIPTION:
          setCategoryLineInputDescription(category, input as string);
          break;
        default:
          break;
      }
    },
    [resetCategoryLineInput, setCategoryLineInputCost, setCategoryLineInputDescription]
  );

  const getCategoryLineInputUpdateFunction = useCallback(
    (category: CategoryContentInput) => {
      return (actionType: LineActionType, input?: string | number, unitID?: UUID) =>
        applyCategoryLineInputAction(actionType, category, input, unitID);
    },
    [applyCategoryLineInputAction]
  );

  // AverageCompInput - MarkupLineInputs
  const resetMarkupLineInput = useCallback(
    (markupName: string) => {
      setAverageCompInput((prevState) => {
        const markupLineInputs = resetMarkupLine(prevState, markupName);
        return { ...prevState, markupLineInputs };
      });
    },
    [setAverageCompInput]
  );

  const setMarkupLineInputCost = useCallback(
    (
      markupName: string,
      cellType: ProjectCompsCostTableCellType,
      editedValue: number,
      unitID?: UUID
    ) => {
      setAverageCompInput((prevState) => {
        const markupLineInputs = createOrUpdateMarkupLineInputCost(
          prevState,
          markupName,
          cellType,
          editedValue,
          unitID
        );
        // update reactive var and trigger analytics
        return { ...prevState, markupLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_COST, {
          markupName,
          cellType,
        })
      );
    },
    [sendAnalytics, setAverageCompInput]
  );
  const setMarkupLineInputDescription = useCallback(
    (markupName: string, description: string) => {
      setAverageCompInput((prevState) => {
        const markupLineInputs = createOrUpdateMarkupLineInputCostDescription(
          prevState,
          markupName,
          description
        );
        // update reactive var and trigger analytics
        return { ...prevState, markupLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_EDIT_AVERAGE_COST_DESCRIPTION,
          {
            markupName,
            nextDescription: description,
          }
        )
      );
    },
    [sendAnalytics, setAverageCompInput]
  );

  const applyMarkupLineInputAction = useCallback(
    (actionType: LineActionType, markupName: string, input?: string | number, unitID?: UUID) => {
      switch (actionType) {
        case LineActionType.RESET_LINE:
          resetMarkupLineInput(markupName);
          break;
        case LineActionType.EDIT_TOTAL_COST:
          setMarkupLineInputCost(
            markupName,
            ProjectCompsCostTableCellType.TOTAL,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_QUANTITY_COST:
          setMarkupLineInputCost(
            markupName,
            ProjectCompsCostTableCellType.QUANTITY,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_DESCRIPTION:
          setMarkupLineInputDescription(markupName, input as string);
          break;
        default:
          break;
      }
    },
    [resetMarkupLineInput, setMarkupLineInputCost, setMarkupLineInputDescription]
  );

  const getMarkupLineInputUpdateFunction = useCallback(
    (markupName: string) => {
      return (actionType: LineActionType, input?: string | number, unitID?: UUID) =>
        applyMarkupLineInputAction(actionType, markupName, input, unitID);
    },
    [applyMarkupLineInputAction]
  );

  return useMemo(
    () => ({
      deleteAverageCompInput,
      resetAverageCompInput,
      resetAverageCompInputName,
      setAverageCompInputColorAndThumbnailID,
      setAverageCompInputDescription,
      setAverageCompInputLocation,
      setAverageCompInputName,
      setAverageCompMetricsInput,
      toggleAverageCompInputIsHidden,
      averageCompLineInputUpdateFunctions: {
        getCategoryLineInputUpdateFunction,
        getMarkupLineInputUpdateFunction,
      },
    }),
    [
      deleteAverageCompInput,
      getCategoryLineInputUpdateFunction,
      getMarkupLineInputUpdateFunction,
      resetAverageCompInput,
      resetAverageCompInputName,
      setAverageCompInputColorAndThumbnailID,
      setAverageCompInputDescription,
      setAverageCompInputLocation,
      setAverageCompInputName,
      setAverageCompMetricsInput,
      toggleAverageCompInputIsHidden,
    ]
  );
};

export type AverageCompInputUpdateFunctions = ReturnType<typeof useAverageCompInputUpdateFunctions>;
export type LineInputUpdateFunctions =
  AverageCompInputUpdateFunctions['averageCompLineInputUpdateFunctions'];
export type LineInputUpdateFunction = ReturnType<
  LineInputUpdateFunctions[keyof LineInputUpdateFunctions]
>;

/**
 *
 * @param id The Project Comp Input's ID
 * @param milestones
 * @returns object containing updater callbacks
 */
export const useProjectCompInputUpdateFunctions = (
  id: UUID, // The ProjectCompInput's ID
  milestones: { id: UUID; name: UUID }[]
) => {
  const sendAnalytics = useAnalyticsEventHook();
  const { setProjectCompsSetInput, setProjectCompInput } =
    useProjectCompsSetInputContextUpdateFunctions();

  // ProjectCompInput
  const deleteProjectCompInput = useCallback(() => {
    setProjectCompsSetInput((prevState) => {
      return {
        ...prevState,
        projectCompInputs: prevState.projectCompInputs.filter((pci) => pci.id !== id),
      };
    });
  }, [id, setProjectCompsSetInput]);

  const reorderProjectCompInput = useCallback(
    (index: number) => {
      setProjectCompsSetInput((prevState) => {
        const projectCompInput = prevState.projectCompInputs.find((pci) => pci.id === id);
        if (!projectCompInput || index < 0 || index >= prevState.projectCompInputs.length) {
          return prevState;
        }

        const reordererProjectCompInputs = prevState.projectCompInputs.filter(
          (projectCompInput) => projectCompInput.id !== id
        );
        reordererProjectCompInputs.splice(index, 0, projectCompInput);
        return {
          ...prevState,
          projectCompInputs: reordererProjectCompInputs,
        };
      });
    },
    [id, setProjectCompsSetInput]
  );

  const resetProjectCompInput = useCallback(() => {
    setProjectCompInput(id, (prevState) => ({
      id,
      projectID: prevState.projectID,
    }));
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_RESET_DATA_CTA, {
        location: 'Project Comp',
      })
    );
  }, [id, sendAnalytics, setProjectCompInput]);

  // ProjectCompInput - Name
  const setProjectCompInputName = useCallback(
    (name: string | null) => {
      setProjectCompInput(id, (prevState) => ({ ...prevState, name }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_PROJECT_NAME, { name })
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  // ProjectCompInput - Description
  const setProjectCompInputDescription = useCallback(
    (description: string | undefined) => {
      setProjectCompInput(id, (prevState) => ({ ...prevState, description }));
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ADD_DESCRIPTION, {
          location: 'Project Comp',
          description,
        })
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const resetProjectCompInputName = useCallback(() => {
    setProjectCompInputName(null);
  }, [setProjectCompInputName]);

  // ProjectCompInput - MilestoneID
  const setProjectCompInputMilestoneID = useCallback(
    (milestoneID: UUID | null) => {
      setProjectCompInput(id, (prevState) => {
        return { ...prevState, milestoneID };
      });

      const selectedMilestone = milestones.find(({ id }) => id === milestoneID);
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_MILESTONE, {
          milestoneName: selectedMilestone?.name,
        })
      );
    },
    [milestones, id, sendAnalytics, setProjectCompInput]
  );

  // ProjectCompInput - Escalation
  const setProjectCompInputEscalationLocation = useCallback(
    (location: string | null, meta?: ProjectCompEscalationMetaInput | null) => {
      setProjectCompInput(id, (prevState) => {
        const escalation = {
          ...(prevState.escalation ?? {}),
          location: location ?? null,
          locationMeta: meta ?? null,
        };

        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ESCALATION_SET, {
            locationIsEscalated: !!escalation.location,
            locationMeta: escalation.locationMeta,
            timeIsEscalated: !!escalation.time,
            timeMeta: escalation.timeMeta,
            futureTimeIsEscalated: !!escalation.timeFuture,
          })
        );

        return {
          ...prevState,
          escalation,
        };
      });
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const setProjectCompInputEscalationTime = useCallback(
    (time: string | null, meta?: ProjectCompEscalationMetaInput | null) => {
      setProjectCompInput(id, (prevState) => {
        const escalation = {
          ...(prevState.escalation ?? {}),
          time: time ?? null,
          timeMeta: meta ?? null,
        };

        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ESCALATION_SET, {
            locationIsEscalated: !!escalation.location,
            locationMeta: escalation.locationMeta,
            timeIsEscalated: !!escalation.time,
            timeMeta: escalation.timeMeta,
            futureTimeIsEscalated: !!escalation.timeFuture,
          })
        );

        return {
          ...prevState,
          escalation,
        };
      });
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const setProjectCompInputEscalationFutureTime = useCallback(
    (timeFuture: string | null) => {
      setProjectCompInput(id, (prevState) => {
        const escalation = {
          ...(prevState.escalation ?? {}),
          timeFuture: timeFuture ?? null,
        };

        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ESCALATION_SET, {
            locationIsEscalated: !!escalation.location,
            locationMeta: escalation.locationMeta,
            timeIsEscalated: !!escalation.time,
            timeMeta: escalation.timeMeta,
            futureTimeIsEscalated: !!escalation.timeFuture,
          })
        );

        return {
          ...prevState,
          escalation,
        };
      });
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const setProjectCompMetricsInput = useCallback(
    (unitID: UUID, quantityMagnitude: string | null) => {
      setProjectCompInput(id, (prevState) => {
        const metrics =
          prevState.metrics?.filter((input: MetricsInput) => input.unitID !== unitID) ?? [];
        metrics.push({ unitID, quantityMagnitude, hasMilestoneQuantity: false });

        return { ...prevState, metrics };
      });
    },
    [id, setProjectCompInput]
  );

  // ProjectCompInput - CategorizationID
  const setProjectCompInputCategorization = useCallback(
    (categorizationID: UUID, categorizationLevel: number) => {
      setProjectCompInput(id, (prevState) => ({
        ...prevState,
        categorizationID,
        categorizationLevel,
      }));
    },
    [id, setProjectCompInput]
  );

  // ProjectCompInput - IsExcluded
  const setProjectCompInputIsExcluded = useCallback(
    (isExcluded: boolean | null) => {
      setProjectCompInput(id, (prevState) => {
        sendAnalytics(
          projectCompsAnalyticsEvent(
            projectCompsEventTypes.PROJECT_COMPS_TOGGLE_PROJECT_IS_EXCLUDED,
            {
              toggledProjectId: prevState.projectID,
              isExcluded: !!isExcluded,
            }
          )
        );
        return { ...prevState, isExcluded };
      });
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const resetCategoryLineInput = useCallback(
    (category: CategoryContentInput) => {
      setProjectCompInput(id, (prevState) => {
        const categoryLineInputs = resetCategoryLine(prevState, category);
        return { ...prevState, categoryLineInputs };
      });
    },
    [id, setProjectCompInput]
  );

  const setCategoryLineInputCost = useCallback(
    (
      category: CategoryContentInput,
      cellType: ProjectCompsCostTableCellType,
      editedValue: number,
      unitID?: UUID
    ) => {
      setProjectCompInput(id, (prevState) => {
        const categoryLineInputs = createOrUpdateCategoryLineInputCost(
          prevState,
          category,
          cellType,
          editedValue,
          unitID
        );
        // update reactive var and trigger analytics
        return { ...prevState, categoryLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_PROJECT_COMP_COST, {
          id,
          categoryName: category.name,
          cellType,
        })
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const setCategoryLineInputDescription = useCallback(
    (category: CategoryContentInput, description: string) => {
      setProjectCompInput(id, (prevState) => {
        const categoryLineInputs = createOrUpdateCategoryLineInputCostDescription(
          prevState,
          category,
          description
        );
        // update reactive var and trigger analytics
        return { ...prevState, categoryLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_EDIT_PROJECT_COMP_COST_DESCRIPTION,
          {
            id,
            categoryName: category.name,
            nextDescription: description,
          }
        )
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const applyCategoryLineInputAction = useCallback(
    (
      actionType: LineActionType,
      category: CategoryContentInput,
      input?: string | number,
      unitID?: UUID
    ) => {
      switch (actionType) {
        case LineActionType.RESET_LINE:
          resetCategoryLineInput(category);
          break;
        case LineActionType.EDIT_TOTAL_COST:
          setCategoryLineInputCost(
            category,
            ProjectCompsCostTableCellType.TOTAL,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_QUANTITY_COST:
          setCategoryLineInputCost(
            category,
            ProjectCompsCostTableCellType.QUANTITY,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_DESCRIPTION:
          setCategoryLineInputDescription(category, input as string);
          break;
        default:
          break;
      }
    },
    [resetCategoryLineInput, setCategoryLineInputCost, setCategoryLineInputDescription]
  );

  const getCategoryLineInputUpdateFunction = useCallback(
    (category: CategoryContentInput) => {
      return (actionType: LineActionType, input?: string | number, unitID?: UUID) =>
        applyCategoryLineInputAction(actionType, category, input, unitID);
    },
    [applyCategoryLineInputAction]
  );

  const resetMarkupLineInput = useCallback(
    (markupName: string) => {
      setProjectCompInput(id, (prevState) => {
        // remove markup line from markup line inputs
        const markupLineInputs = resetMarkupLine(prevState, markupName);
        return { ...prevState, markupLineInputs };
      });
    },
    [id, setProjectCompInput]
  );

  const setMarkupLineInputCost = useCallback(
    (
      markupName: string,
      cellType: ProjectCompsCostTableCellType,
      editedValue: number,
      unitID?: UUID
    ) => {
      setProjectCompInput(id, (prevState) => {
        const markupLineInputs = createOrUpdateMarkupLineInputCost(
          prevState,
          markupName,
          cellType,
          editedValue,
          unitID
        );
        // update reactive var and trigger analytics
        return { ...prevState, markupLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EDIT_PROJECT_COMP_COST, {
          id,
          markupName,
          cellType,
        })
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );
  const setMarkupLineInputDescription = useCallback(
    (markupName: string, description: string) => {
      setProjectCompInput(id, (prevState) => {
        const markupLineInputs = createOrUpdateMarkupLineInputCostDescription(
          prevState,
          markupName,
          description
        );
        // update reactive var and trigger analytics
        return { ...prevState, markupLineInputs };
      });

      sendAnalytics(
        projectCompsAnalyticsEvent(
          projectCompsEventTypes.PROJECT_COMPS_EDIT_PROJECT_COMP_COST_DESCRIPTION,
          {
            id,
            markupName,
            nextDescription: description,
          }
        )
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );

  const applyMarkupLineInputAction = useCallback(
    (actionType: LineActionType, markupName: string, input?: string | number, unitID?: UUID) => {
      switch (actionType) {
        case LineActionType.RESET_LINE:
          resetMarkupLineInput(markupName);
          break;
        case LineActionType.EDIT_TOTAL_COST:
          setMarkupLineInputCost(
            markupName,
            ProjectCompsCostTableCellType.TOTAL,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_QUANTITY_COST:
          setMarkupLineInputCost(
            markupName,
            ProjectCompsCostTableCellType.QUANTITY,
            input as number,
            unitID
          );
          break;
        case LineActionType.EDIT_DESCRIPTION:
          setMarkupLineInputDescription(markupName, input as string);
          break;
        default:
          break;
      }
    },
    [resetMarkupLineInput, setMarkupLineInputCost, setMarkupLineInputDescription]
  );

  const getMarkupLineInputUpdateFunction = useCallback(
    (markupName: string) => {
      return (actionType: LineActionType, input?: string | number, unitID?: UUID) =>
        applyMarkupLineInputAction(actionType, markupName, input, unitID);
    },
    [applyMarkupLineInputAction]
  );

  // ProjectCompInput - View Filter
  const setProjectCompInputViewFilter = useCallback(
    (viewFilter: ProjectCompInput['viewFilter']) => {
      setProjectCompInput(id, (prevState) => {
        return { ...prevState, viewFilter };
      });
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SET_VIEW_FILTER, {
          viewFilter,
        })
      );
    },
    [id, sendAnalytics, setProjectCompInput]
  );
  const resetProjectCompInputViewFilter = useCallback(() => {
    setProjectCompInput(id, (prevState) => {
      return { ...prevState, viewFilter: { categories: [] } };
    });
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_RESET_VIEW_FILTER)
    );
  }, [id, sendAnalytics, setProjectCompInput]);

  return useMemo(
    () => ({
      deleteProjectCompInput,
      reorderProjectCompInput,
      resetProjectCompInput,
      resetProjectCompInputName,
      setProjectCompInput,
      setProjectCompInputCategorization,
      setProjectCompInputDescription,
      setProjectCompInputEscalationFutureTime,
      setProjectCompInputEscalationLocation,
      setProjectCompInputEscalationTime,
      setProjectCompInputIsExcluded,
      setProjectCompInputMilestoneID,
      setProjectCompInputName,
      setProjectCompMetricsInput,
      projectCompLineInputUpdateFunctions: {
        getCategoryLineInputUpdateFunction,
        getMarkupLineInputUpdateFunction,
      },
      projectCompInputViewFilterFunctions: {
        setProjectCompInputViewFilter,
        resetProjectCompInputViewFilter,
      },
    }),
    [
      deleteProjectCompInput,
      getCategoryLineInputUpdateFunction,
      getMarkupLineInputUpdateFunction,
      reorderProjectCompInput,
      resetProjectCompInput,
      resetProjectCompInputName,
      setProjectCompInput,
      setProjectCompInputCategorization,
      setProjectCompInputDescription,
      setProjectCompInputEscalationFutureTime,
      setProjectCompInputEscalationLocation,
      setProjectCompInputEscalationTime,
      setProjectCompInputIsExcluded,
      setProjectCompInputMilestoneID,
      setProjectCompInputName,
      setProjectCompInputViewFilter,
      setProjectCompMetricsInput,
      resetProjectCompInputViewFilter,
    ]
  );
};

export type ProjectCompInputUpdateFunctions = ReturnType<typeof useProjectCompInputUpdateFunctions>;
