import { ScaleTime } from 'd3-scale';
import { isEqual } from 'lodash';

import { DivSelection, TimelineEvent, type TimelineOptions } from './timeline.types';
import { fixDomain } from './utils';

export const updateZoomResetBtn = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions,
  updateOuter: (selection: Date[]) => void
): void => {
  if (!options.dataFlat) return;
  if (options.isPrint) return;
  const domain: Date[] = fixDomain(brushXScale.domain());

  const isHidden = isEqual(domain, timelineXScale.domain());
  const display = isHidden ? 'none' : 'block';

  let zoomResetBtn: DivSelection = chart.select('#zoom-reset-btn');

  if (zoomResetBtn.empty()) {
    zoomResetBtn = chart
      // Insert div container
      .insert('div', '#timeline-groups')
      .attr('id', 'zoom-reset-btn')
      .classed('tl-button', true);

    zoomResetBtn
      // Append button
      .append('span')
      .classed('tl-zoom-reset', true)
      .text('Reset Zoom')
      .on('click', () => {
        options.onZoom(domain);
        options.onAnalytics(TimelineEvent.TIMELINE_RESET_ZOOM);
        updateOuter(domain);
        zoomResetBtn.style('display', 'none');
      });
  }
  zoomResetBtn.style('display', display);
};
