import { ADMINISTRATOR_ROLE } from '../../../constants';
import { TextInput } from '../../scales';

type Props = {
  editable: boolean;
  roleName: string;
  onChange: (name: string) => void;
};

const PermissionsRoleEditName = (props: Props) => {
  // TODO DD-843: Create role type to avoid need for special names
  const isSavingError = props.roleName?.trim() === ADMINISTRATOR_ROLE;

  return (
    <div className="w-1/2">
      {props.editable ? (
        <TextInput
          disabled={!props.editable}
          errorMessage={isSavingError ? `Role cannot be named '${props.roleName?.trim()}'` : ''}
          label="Role Name"
          onChange={(newName) => props.onChange(newName)}
          placeholder="Add role name"
          value={props.roleName}
        />
      ) : (
        <div className="flex flex-col gap-2">
          <div className="h-6 content-center type-label">Role Name</div>
          <div className="type-body-1">{props.roleName}</div>
        </div>
      )}
    </div>
  );
};

export default PermissionsRoleEditName;
