import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './NewCategorizationBuiltinMapStyles';

type NewCategorizationsBuiltInCategoryMatchCountsProps = {
  classes: Classes<typeof styles>;
  selectedDraftCategorization: DraftCategorization;
  builtInCategorizationName: Level['builtIn'];
};

const NewCategorizationsBuiltInCategoryMatchCounts: FC<
  NewCategorizationsBuiltInCategoryMatchCountsProps
> = ({ classes, selectedDraftCategorization, builtInCategorizationName }) => {
  const { matched, unmatched } = selectedDraftCategorization.categoryMatchCounts.get(
    builtInCategorizationName
  ) || { matched: 0, unmatched: 0 };
  const showMatchedCount = matched > 0;
  const showUnmatchedCount = unmatched > 0;
  const matchedCopy = showUnmatchedCount ? `${matched} Matched` : `All ${matched} Matched`;
  const unmatchedCopy = showMatchedCount
    ? `${unmatched} Unmatched `
    : `All ${unmatched} Unmatched `;

  return (
    <div className={classes.matchCountContainer}>
      {showMatchedCount && (
        <>
          <Check className={classes.checkIcon} />
          <Typography data-cy="label-matched" className={classes.matchCountText}>
            {matchedCopy}
          </Typography>
        </>
      )}
      {showUnmatchedCount && (
        <>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <Typography data-cy="label-unmatched" className={classes.unmatchCountText}>
            {unmatchedCopy}
          </Typography>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(NewCategorizationsBuiltInCategoryMatchCounts);
