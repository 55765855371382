import { useReactiveVar } from '@apollo/client';

import { ItemsListTotalCostQuery, ItemsListTotalCostQueryVariables } from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { costModeVar } from '../../utilities/costMode';

import { itemsListTotalCostQuery } from './hooks/itemsListQuery';
import { ItemsListSettings, useItemsListMilestone } from './ItemsListUtils';

export const useItemsListTotalCostQuery = (
  itemIDs: UUID[] | undefined,
  projectID: UUID,
  viewFilter: ViewFilterInput,
  settings: ItemsListSettings,
  skipQuery?: boolean
) =>
  useQuery<ItemsListTotalCostQuery, ItemsListTotalCostQueryVariables>(
    itemsListTotalCostQuery,
    {
      variables: {
        itemIDs,
        milestoneID: useItemsListMilestone(settings),
        projectID,
        costMode: useReactiveVar(costModeVar),
        viewFilter,
      },
      skip: skipQuery || !itemIDs || !itemIDs.length,
    },
    MountPolicy.FETCH_ON_VARS
  );
