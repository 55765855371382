import { FC, useState } from 'react';

import { ButtonBase } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';

import { IMAGE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import AssetsViewer from '../AssetsViewer/AssetsViewer';

import ImageViewerWrapperStyles from './AssetsProjectCompsImageWrapperStyles';

type ImageViewerWrapperProps = {
  asset: Asset | ProjectThumbnail;
  children: JSX.Element;
  classes: Classes<typeof ImageViewerWrapperStyles>;
  showHoverIcon?: boolean;
};

const ImageViewerWrapper: FC<ImageViewerWrapperProps> = ({
  asset,
  children,
  classes,
  showHoverIcon,
}) => {
  const [openViewer, setOpenViewer] = useState(false);

  return (
    <>
      <AssetsViewer
        asset={asset}
        closeModal={() => setOpenViewer(false)}
        open={openViewer}
        type={IMAGE}
        viewOnly
        hideActions
        showMenu
      />
      <ButtonBase
        className={classes.thumbnailButton}
        onClick={() => {
          setOpenViewer(true);
        }}
      >
        {children}
        {showHoverIcon && (
          <div className={classes.expandThumbnailButton}>
            <Fullscreen />
          </div>
        )}
      </ButtonBase>
    </>
  );
};

export default withStyles(ImageViewerWrapperStyles)(ImageViewerWrapper);
