import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

const INNER_HEIGHT = 110;

export default (theme: KomodoTheme) =>
  createStyles({
    icon: {
      width: theme.iconSizes.mediumCompact,
      height: theme.iconSizes.mediumCompact,
    },
    iconContainer: {
      paddingRight: 4,
      alignItems: 'center',
    },
    innerContainer: {
      alignItems: 'center',
      display: 'flex',
      background: 'none',
      minHeight: INNER_HEIGHT,
    },
    textContent: {
      ...theme.typography.cardHeader,
    },
  });
