import { useReactiveVar } from '@apollo/client';

import { CanApplyScenarioQuery, CanApplyScenarioQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../utilities/costMode';

import { canApplyScenarioQuery } from './queries';

const useCanApplyScenarioQuery = (scenarioID: UUID, projectID: UUID, milestoneID: UUID) =>
  useQuery<CanApplyScenarioQuery, CanApplyScenarioQueryVariables>(canApplyScenarioQuery, {
    variables: {
      scenarioID,
      projectID,
      milestoneID,
      costMode: useReactiveVar(costModeVar),
    },
    fetchPolicy: 'cache-and-network',
    skip: !scenarioID,
  });

export default useCanApplyScenarioQuery;
