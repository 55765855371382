import { SvgIcon } from '@material-ui/core';

const FullWidthIcon = () => (
  <SvgIcon style={{ transform: 'translate(4px, 2px)' }}>
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2857 20H1.42857C0.642857 20 0 19.3571 0 18.5714V1.42857C0 0.642857 0.642857 0 1.42857 0H11.2643C11.85 0 12.5786 0.3 12.9857 0.714286L15 2.74286C15.4143 3.15714 15.7143 3.88571 15.7143 4.46429V18.5714C15.7143 19.3571 15.0714 20 14.2857 20ZM1.42857 1.42857V18.5714H14.2857V4.46429C14.2857 4.25714 14.1357 3.89286 13.9929 3.75L11.9786 1.72143C11.8357 1.57857 11.4714 1.42857 11.2643 1.42857H1.42857Z"
        fill="black"
      />
      <path
        d="M12.1147 10.7784H3.94329C3.55043 10.7784 3.229 10.457 3.229 10.0641C3.229 9.67128 3.55043 9.34985 3.94329 9.34985H12.1147C12.5076 9.34985 12.829 9.67128 12.829 10.0641C12.829 10.457 12.5076 10.7784 12.1147 10.7784Z"
        fill="black"
      />
      <path
        d="M10.7146 12.7856C10.5289 12.7856 10.3503 12.7141 10.2075 12.5784C9.92889 12.2998 9.92889 11.8498 10.2075 11.5713L11.7432 10.0356L10.2075 8.43555C9.92889 8.15698 9.92889 7.70698 10.2075 7.42841C10.486 7.14984 10.936 7.14984 11.2146 7.42841L12.7503 8.96413C13.0289 9.2427 13.1789 9.61413 13.1789 10.007C13.1789 10.3998 13.0289 10.7713 12.7503 11.0498L11.2146 12.5856C11.0717 12.7284 10.8932 12.7927 10.7075 12.7927L10.7146 12.7856Z"
        fill="black"
      />
      <path
        d="M5.00024 12.7856C4.81452 12.7856 4.63595 12.7142 4.49309 12.5785L2.95738 11.0428C2.38595 10.4713 2.38595 9.53562 2.95738 8.95704L4.49309 7.42133C4.77167 7.14276 5.22167 7.14276 5.50024 7.42133C5.77881 7.6999 5.77881 8.1499 5.50024 8.42847L3.96452 9.96419L5.50024 11.5642C5.77881 11.8428 5.77881 12.2928 5.50024 12.5713C5.35738 12.7142 5.17881 12.7785 4.99309 12.7785L5.00024 12.7856Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);

export default FullWidthIcon;
