import { FC, MouseEvent, useEffect } from 'react';

import {
  IN_APP_NOTIFICATIONS_POLLING_INTERVAL_LONG,
  IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT,
} from '../../../constants';
import useHasUnreadInAppNotificationsQuery from '../hooks/useHasUnreadInAppNotificationsQuery';

import InAppNotificationsIcon from './InAppNotificationsIcon';

type InAppNotificationsIconDataProps = {
  isOpen: boolean;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
};

const InAppNotificationsIconData: FC<InAppNotificationsIconDataProps> = ({ isOpen, onClick }) => {
  const pollingInterval = isOpen
    ? IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT
    : IN_APP_NOTIFICATIONS_POLLING_INTERVAL_LONG;
  const {
    data: { hasUnreadInAppNotifications } = {},
    loading,
    startPolling,
    stopPolling,
  } = useHasUnreadInAppNotificationsQuery();
  useEffect(() => {
    startPolling(pollingInterval);
    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [isOpen]);

  const count = hasUnreadInAppNotifications ? 1 : 0;
  return <InAppNotificationsIcon count={loading ? 0 : count} onClick={onClick} isDot />;
};

export default InAppNotificationsIconData;
