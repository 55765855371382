import { MenuItem, Typography } from '@material-ui/core';

import GridRowCheckbox from '../../JoinGrid/JoinGridRowCheckbox';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type ReferencesMenuSubtotalProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  key: string;
  includeTopBorder?: boolean;
  isChecked: boolean;
  isUnchecked?: boolean;
  value: string;
  text: string;
  onClick: (newRef: string) => void;
};

// Note:
// Material-ui doesn't let us use a functional component to wrap
// a MenuItem component.  If we wrap it then the MenuItems aren't interactive
export const getReferencesMenuSubtotal = ({
  classes,
  key,
  includeTopBorder = false,
  isChecked,
  isUnchecked,
  value,
  text,
  onClick,
}: ReferencesMenuSubtotalProps) => (
  <MenuItem
    data-cy="markup-choice"
    className={`${classes.choice} ${classes.referenceItem} ${classes.dividerBottom} ${
      includeTopBorder ? classes.dividerTop : ''
    }`}
    classes={{
      selected: classes.selected,
    }}
    key={key}
    value={value}
    onClick={() => onClick(value)}
  >
    <span
      className={
        isChecked
          ? `${classes.subtotalChoiceText} ${classes.selectedChoiceText}`
          : classes.subtotalChoiceText
      }
    >
      <div className={classes.checkBox}>
        <GridRowCheckbox
          checked={isChecked}
          unchecked={isUnchecked}
          onClick={() => {}}
          height={24}
          width={24}
          position="none"
          includeBorder={false}
          cy={`GridRowCheckbox-${text}`}
        />
        <Typography className={classes.checkboxLabel}>{text}</Typography>
      </div>
    </span>
  </MenuItem>
);
