import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { exportAllContingenciesAnalytics } from '../../../analytics/analyticsEventProperties';
import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import JoinAPI from '../../../api/joinAPI';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { getReportSettings } from '../../ContingencyReport/ContingencyReport/ContingencyReportUtils';
import { useProjectContingenciesQuery } from '../../ItemsList/hooks/useMilestoneContingenciesQuery';
import { useCurrentUserReportID } from '../../VarianceReport/VarianceReportUtils';

const ExportAllMilestonesContingency: FC = () => {
  // Read our project id from the URL to populate queries
  const projectID = getProjectIdFromUrl();

  const reportId = useCurrentUserReportID();
  const currentReport = useReactiveVar(currentUserReportVar);

  const { data: projectContingencyData, loading: loadingContingencies } =
    useProjectContingenciesQuery(projectID);
  const projectContingencies = projectContingencyData?.getProjectContingencies;

  const [savedSelectedContingencies, savedSelectedAllowances] = getReportSettings(
    currentReport,
    projectContingencies
  );

  // we need the project props too
  const { data: projectData, loading: loadingProjectProps } = useProjectPropsQuery(projectID);
  const projectName = projectData?.project?.name || '';

  // analytics
  const sendAnalytics = useAnalyticsEventHook();

  useEffect(() => {
    // we need the project name to name the exported excel file
    const fileNameTokens = [projectName];
    // only export if everything is loaded
    if (!loadingProjectProps && !loadingContingencies) {
      const exportAndClose = () => {
        sendAnalytics(exportAllContingenciesAnalytics());
        JoinAPI.exportAllMilestonesContingencyReport(
          projectID,
          savedSelectedContingencies,
          savedSelectedAllowances,
          fileNameTokens,
          reportId,
          () => {
            // close this dialog when we're down exporting
            window.close();
          }
        );
      };
      setTimeout(exportAndClose, 100);
    }
  }, [
    loadingProjectProps,
    loadingContingencies,
    projectID,
    projectName,
    reportId,
    savedSelectedAllowances,
    savedSelectedContingencies,
    sendAnalytics,
  ]);

  return (
    <Typography style={{ fontSize: 18, paddingTop: 16 }}>
      &nbsp;&nbsp;&nbsp;&nbsp;Exporting Contingency & Allowance Report...
    </Typography>
  );
};

export default ExportAllMilestonesContingency;
