import { FC, Fragment, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Close, ErrorOutline, Help } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { CompanyEmployeeInput } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { isValidEmail } from '../../../utilities/string';
import { useAddCompanyEmployeeMutation } from '../../CompanyTab/CompanyHooks';
import { companyRoleTooltip } from '../../CompanyTab/CompanyTabUtils';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import { ModuleEntitlementsStore } from '../../ModuleEntitlementsProvider';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import { allRoles } from '../DialogsCompanyRoles/DialogsCompanyRolesUtils';

import styles from './styles';

type DialogsAddCompanyEmployeeProps = {
  classes: Classes<typeof styles>;
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  open: boolean;
  setDialogOpen: (open: boolean) => void;
};

type ProjectRoleInput = {
  id: string;
  name: string;
  key: number;
};

const DialogsAddCompanyEmployee: FC<DialogsAddCompanyEmployeeProps> = ({
  classes,
  company,
  companyProjectRoles,
  companyRoles,
  companyUsers,
  open = false,
  setDialogOpen,
}) => {
  const moduleEntitlementFlags = useContext(ModuleEntitlementsStore);

  const defaultCompanyRoleName = companyRoles?.[0].name ?? '';
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState<CompanyEmployeeInput>({
    email: '',
    companyRoleName: defaultCompanyRoleName,
    projectIDs: [],
    projectRoleNames: [],
  });
  const { email, name = '', jobTitle = '', companyRoleName } = input;
  const [projectInputs, setProjectInputs] = useState<ProjectRoleInput[]>([
    { id: '', name: '', key: 0 },
  ]);
  const [showEmailError, setShowEmailError] = useState(false);
  const addCompanyEmployee = useAddCompanyEmployeeMutation();
  const [nextKey, setNextKey] = useState(1);
  const sendAnalytics = useAnalyticsEventHook();

  // Disable the add permission button if there are empty fields, or all projects have been assigned.
  const disableAddPermission =
    !companyProjectRoles || projectInputs.length >= companyProjectRoles.length;
  const hasPartialPermission = projectInputs.some((p) => !!p.id !== !!p.name);
  const isCompanyEmail = isValidEmail(email) && company.domain && email.endsWith(company.domain);
  const isExistingUser = companyUsers.some((companyUser) => companyUser?.user?.email === email);
  const isValidInput =
    email && isCompanyEmail && !isExistingUser && companyRoleName && !hasPartialPermission;

  const onClose = () => {
    setDialogOpen(false);
    setInput({
      email: '',
      companyRoleName: defaultCompanyRoleName,
      projectIDs: [],
      projectRoleNames: [],
    });
    setProjectInputs([{ id: '', name: '', key: 0 }]);
    setShowEmailError(false);
  };

  const onSubmit = () => {
    if (!submitted && isValidInput) {
      setSubmitted(true);
      const projectIDs: UUID[] = [];
      const projectRoleNames: string[] = [];
      projectInputs.forEach((p) => {
        if (!p.id || !p.name) return;
        projectIDs.push(p.id);
        projectRoleNames.push(p.name);
      });
      addCompanyEmployee({ ...input, projectIDs, projectRoleNames });
      onClose();
    }
  };

  const companyRoleOptions =
    companyRoles
      ?.filter((r) =>
        allRoles.some(
          (a) =>
            a.companyRole === r.name &&
            (!a.entitlement || moduleEntitlementFlags.find((m) => m === a.entitlement))
        )
      )
      .map((r) => {
        return getEntry(r.name, r.name);
      }) ?? [];

  const emailError = (
    <ErrorTooltip
      title={
        isExistingUser
          ? 'User with this email already exists.'
          : `Please provide a ${company ? company.domain : 'valid'} email address.`
      }
    >
      <ErrorOutline className={classes.errorIcon} />
    </ErrorTooltip>
  );

  return (
    <Dialog
      className={classes.root}
      open={open}
      classes={{ paper: classes.paper }}
      onClose={() => {
        onClose();
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_CLOSE));
      }}
      PaperProps={{ square: true }}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title" className={classes.title}>
            Add New Employee
          </Typography>
        </div>
        <IconButton title="Close dialog" className={classes.close}>
          <Close
            onClick={() => {
              onClose();
              sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_CLOSE));
            }}
          />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <TextField
          autoComplete="off"
          autoFocus
          className={classes.row}
          fullWidth
          id="email"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            classes: {
              input: showEmailError ? classes.red : undefined,
            },
            disableUnderline: true,
            endAdornment: showEmailError && emailError,
          }}
          label="Email"
          placeholder="Email"
          onBlur={() => {
            setShowEmailError(!!email && (!isCompanyEmail || isExistingUser));
          }}
          onChange={(evt) => {
            setInput({ ...input, email: evt.target.value });
          }}
          required
          value={email}
        />
        <TextField
          className={classes.row}
          fullWidth
          id="name"
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true }}
          label="Full Name"
          placeholder="Full Name"
          onChange={(evt) => {
            setInput({ ...input, name: evt.target.value });
          }}
          value={name || ''}
        />
        <TextField
          className={classes.row}
          fullWidth
          id="job-title"
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true }}
          label="Job Title"
          placeholder="Job Title"
          onChange={(evt) => {
            setInput({ ...input, jobTitle: evt.target.value });
          }}
          value={jobTitle ?? undefined}
        />
      </DialogContent>
      <Divider />
      <DialogContent className={classes.content}>
        <div>
          <Typography className={classes.titleBold2}>Company Permissions</Typography>
          <div className={classes.flex}>
            <Typography className={`${classes.subText} ${classes.paddingTop}`} variant="title">
              Company Role
            </Typography>
            <NormalTooltip title={companyRoleTooltip}>
              <Help className={classes.tooltip} />
            </NormalTooltip>
          </div>
          <JoinSelect
            cySelect="select-company-role"
            entries={companyRoleOptions}
            onChange={(roleName: string) => {
              setInput({ ...input, companyRoleName: roleName });
            }}
            value={input.companyRoleName}
          />
        </div>
        <div>
          <Typography className={classes.titleBold2}>Project Permissions</Typography>
          <div className={classes.projectRoleGrid}>
            <Typography className={classes.label} variant="caption">
              Project Name
            </Typography>
            <Typography className={classes.label} variant="caption">
              Project Role
            </Typography>
            <div />
            {projectInputs.map(({ id: projectID, name: roleName, key }, index) => {
              // Filter out projects that have already been selected.
              const projectOptions = companyProjectRoles
                ?.filter(
                  (option) =>
                    !projectInputs?.filter((_x, i) => index !== i).find((p) => p.id === option.id)
                )
                // Truncate name length
                .map((option) => ({ ...option, name: option.name.slice(0, 80) }));
              // Find the project roles for the selected project.
              const projectRoleOptions =
                companyProjectRoles
                  ?.find((p) => p.id === projectID)
                  ?.roles.map((r) => getEntry(r.name, r.name)) ?? [];
              return (
                <Fragment key={key}>
                  <JoinSelect
                    cySelect="select-project"
                    entries={projectOptions}
                    onChange={(newProjectID: string) => {
                      setProjectInputs((prev) => {
                        const result = prev.slice();
                        result[index].id = newProjectID;
                        return result;
                      });
                    }}
                    search
                    value={projectID}
                    valuePlaceholder="Select Project"
                  />
                  <JoinSelect
                    cySelect="select-project-role"
                    entries={projectRoleOptions}
                    onChange={(newRoleName: string) => {
                      setProjectInputs((prev) => {
                        const result = prev.slice();
                        result[index].name = newRoleName;
                        return result;
                      });
                    }}
                    value={roleName}
                    valuePlaceholder="Select Role"
                  />
                  <IconButton
                    className={classes.close}
                    data-cy="button-delete"
                    disabled={!projectInputs}
                    onClick={() => {
                      if (projectInputs.length === 1) {
                        setProjectInputs([{ id: '', name: '', key: 0 }]);
                      } else {
                        setProjectInputs((prev) => {
                          const result = prev.slice();
                          result.splice(index, 1);
                          return result;
                        });
                      }
                      sendAnalytics(
                        companyAdminAnalyticsEvent(
                          CompanyAdminEventType.NEW_MEMBER_DELETE_PROJECT_PERMISSION
                        )
                      );
                    }}
                    title="Remove project permission"
                  >
                    <Close />
                  </IconButton>
                </Fragment>
              );
            })}
          </div>
        </div>
        <Button
          color="primary"
          className={classes.smallButton}
          data-cy="button-add-project-permission"
          disabled={disableAddPermission}
          onClick={() => {
            setProjectInputs([...projectInputs, { id: '', name: '', key: nextKey }]);
            setNextKey(nextKey + 1);
            sendAnalytics(
              companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_ADD_PROJECT_PERMISSION)
            );
          }}
          variant="outlined"
        >
          <div style={{ display: 'flex' }}>
            <Add className={classes.addIcon} />
            <div style={{ lineHeight: 1.5 }}>Add Project</div>
          </div>
        </Button>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          data-cy="button-cancel"
          onClick={() => {
            onClose();
            sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_CANCEL));
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-cy="button-add"
          disabled={!isValidInput}
          onClick={() => {
            onSubmit();
            sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_ADD_CTA));
          }}
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsAddCompanyEmployee);
