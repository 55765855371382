import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { IconsOptions } from '../../Icons';
import { styleItemNumber } from '../ItemsListItem/ItemsListItemUtils';
import { generateItemDisplayTitle } from '../ItemsUtils';

import ItemsOptionChipSquareListAreaStyles from './ItemsOptionChipSquareListAreaStyles';

type Props = {
  classes: Classes<typeof ItemsOptionChipSquareListAreaStyles>;
  item: ItemLink;
};

const ItemsOptionChip: FC<Props> = ({ classes, item }) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();

  if (!item) return null;
  return (
    <div
      className={classes.outerContainer}
      data-cy="list-itemsOptionsChipSquare"
      title={generateItemDisplayTitle(item)}
    >
      <div className={classes.optionsSvgContainer}>
        <IconsOptions />
      </div>
      <Typography className={classes.text} variant="caption">
        {'Option in '}
        <span
          onClick={(e: MouseEvent) => {
            const path = generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
              projectId,
              itemId: item.id,
            });
            clickLinkKeyHandler(navigate, e, path);
          }}
          onKeyPress={() => {}}
          role="button"
          className={classes.clickable}
          tabIndex={0}
        >
          {styleItemNumber(item.number)}
        </span>
      </Typography>
    </div>
  );
};

export default withStyles(ItemsOptionChipSquareListAreaStyles)(ItemsOptionChip);
