import { FC } from 'react';

import { Card, CardHeader, Divider } from '@material-ui/core';

import { GridVariant } from '../../../actions/gridAnalytics';
import { PROJECT_ITEM_TEMPLATE } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useEstimateQuery } from '../../../hooks/useEstimateQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import CostEstimate from '../../estimate/CostEstimate';

import ProjectItemTemplateStyles from './ProjectItemTemplateStyles';

type ProjectItemTemplateProps = {
  templateEstimateID: UUID | undefined;
  classes: Classes<typeof ProjectItemTemplateStyles>;
};

const ProjectItemTemplate: FC<ProjectItemTemplateProps> = ({ classes, templateEstimateID }) => {
  const { data, refetch } = useEstimateQuery(templateEstimateID);
  const projectID = getProjectIdFromUrl();
  const estimate = data?.estimate;
  useEstimateQuery(templateEstimateID);
  const sendAnalytics = useAnalyticsEventHook();

  return (
    <div id={PROJECT_ITEM_TEMPLATE} data-cy="itemEstimateTemplate">
      <Card square elevation={0} className={classes.card}>
        <CardHeader
          title="Default Item Template"
          subheader="This controls the default column structure setup when a new item is created. Add or delete columns that you would like to see in the item estimate by default. Updates will only affect the setup of new items, not existing items."
        />
        <Divider />
        {!!templateEstimateID && (
          <div className={classes.estimate}>
            <CostEstimate
              activeEstimateID={estimate?.id}
              canViewDetails
              isOption={false}
              refetchOuter={refetch}
              projectID={projectID}
              sendAnalytics={sendAnalytics}
              variant={GridVariant.ITEM_TEMPLATE}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default withStyles(ProjectItemTemplateStyles)(ProjectItemTemplate);
