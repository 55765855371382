import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircle from '@material-ui/icons/AddCircle';
import Info from '@material-ui/icons/Info';

import { UPLOAD_PROJECT_THUMBNAIL } from '../../actions/actionTypes';
import { getAssetAnalytics } from '../../analytics/analyticsEventProperties';
import useUpdateProject from '../../hooks/ProjectHooks';
import { withStyles } from '../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../utilities/url';
import { useProjectUploadAttachAssets } from '../assets/hooks/AssetsMutationHook';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import { Button, Dialog, DialogContent } from '../scales';

import { useGetProjectThumbnailQuery } from './ProjectPropertiesHooks';
import ProjectThumbnailStyles from './ProjectThumbnailStyles';
import { isImageFile } from './utils';

const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const showFileUpload = (fileUpload: any) => {
  if (fileUpload) {
    fileUpload.click();
  }
};

type ProjectThumbnailProps = {
  classes: Classes<typeof ProjectThumbnailStyles>;
  editable: boolean;
};

const ProjectThumbnail: FC<ProjectThumbnailProps> = ({ classes, editable }) => {
  const projectID = getProjectIdFromUrl();
  const [startedRefetching, setStartedRefetching] = useState(false);
  const [finishedRefetching, setFinishedRefetching] = useState(false);

  let fileUpload = useRef(null);
  const [openWrongFileTypeDialog, setOpenWrongFileTypeDialog] = useState(false);

  const {
    data: { projectThumbnail } = {},
    refetch,
    loading,
  } = useGetProjectThumbnailQuery(projectID);
  const { blobUrl: thumbnail = null } = projectThumbnail || {};

  const updateProjectProperties = useUpdateProject();
  const onEditProjectProperties = (modifiedProject: ProjectInput) => {
    updateProjectProperties(modifiedProject, () => {
      refetch();
      setFinishedRefetching(true);
    });
  };

  useEffect(() => {
    if (startedRefetching && finishedRefetching && !loading && thumbnail) {
      setStartedRefetching(false);
      setFinishedRefetching(false);
    }
  }, [startedRefetching, finishedRefetching, loading, thumbnail]);

  const project = { id: projectID };
  const { onAttachAsset } = useProjectUploadAttachAssets(
    project,
    getAssetAnalytics(UPLOAD_PROJECT_THUMBNAIL),
    () => onEditProjectProperties(project)
  );

  const addFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i += 1) {
      const isImage = isImageFile(files[i]);
      if (isImage) {
        setStartedRefetching(true);
        onAttachAsset(files[i]);
      }
      if (!isImage) {
        setOpenWrongFileTypeDialog(true);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;

    addFiles(files);
  };

  const closeWrongFileTypeDialog = () => {
    setOpenWrongFileTypeDialog(false);
  };

  const image = useMemo(() => {
    if (thumbnail && !startedRefetching && !loading) {
      return <img alt="project thumbnail" className={classes.img} src={thumbnail} />;
    }
    if (startedRefetching || loading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      );
    }
    return <AddCircle className={classes.hoverThumbnail} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [thumbnail, startedRefetching, loading]);

  const thumbnailTooltip =
    'Images can be PNG, JPG, GIF, or SVG. We recommend a square crop with a minimum of 240px by 240px. If you need help, contact support below.';

  return (
    <div
      className={classes.hoverThumbnail}
      onDragEnter={editable ? onDragEnter : undefined}
      onDrop={editable ? onDrop : undefined}
      onDragLeave={editable ? onDragLeave : undefined}
      onDragOver={editable ? onDragOver : undefined}
    >
      {editable ? (
        <div>
          <IconButton
            className={`${classes.thumbButton} ${thumbnail && classes.fullButton}`}
            aria-label="Thumbnail"
            aria-owns="thumbnail"
            aria-haspopup="true"
            onClick={() => showFileUpload(fileUpload)}
            disabled={!editable}
          >
            {image}
          </IconButton>
          <input
            type="file"
            accept=".png, .jpg, .gif, .svg"
            style={{ display: 'none' }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            ref={(input: any) => {
              fileUpload = input;
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onChange={(evt: any) => {
              addFiles(evt.target && evt.target.files);
            }}
          />
          <Typography className={classes.emptyMessage}>
            Drop image or click to choose file
            <NormalTooltip title={thumbnailTooltip}>
              <Info className={classes.icon} />
            </NormalTooltip>
            <br />
            Need help? &nbsp;
            <a className={classes.link} href="mailto:support@join.build" target="_top">
              Contact Join Support
            </a>
          </Typography>
        </div>
      ) : (
        !!thumbnail && <img alt="project thumbnail" className={classes.img} src={thumbnail} />
      )}
      <Dialog
        footerRight={<Button label="OK" onClick={closeWrongFileTypeDialog} type="primary" />}
        isOpen={openWrongFileTypeDialog}
        onClose={closeWrongFileTypeDialog}
        title="Wrong File Type"
      >
        <DialogContent>Only png, jpg, gif, and svg files are accepted.</DialogContent>
      </Dialog>
    </div>
  );
};

export default withStyles(ProjectThumbnailStyles)(ProjectThumbnail);
