import { useReactiveVar } from '@apollo/client';

import { getTimezoneOffset } from '../../../api/joinAPI';
import { PROJECT_ACTIVITY_PAGINATION_LIMIT } from '../../../constants';
import { ProjectActivityQuery, ProjectActivityQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../utilities/costMode';

import { projectActivityQuery } from './projectActivityQuery';

export default function useProjectActivityQuery(
  variables: Pick<ProjectActivityQueryVariables, 'projectID' | 'pagination' | 'filterByUserID'>
) {
  const query = useQuery<ProjectActivityQuery, ProjectActivityQueryVariables>(
    projectActivityQuery,
    {
      variables: {
        ...variables,
        dateFilter: {},
        costMode: useReactiveVar(costModeVar),
        timezoneOffset: getTimezoneOffset(),
      },
    }
  );

  const fetchMore = (offsetID?: string | null) => {
    return query.fetchMore({
      variables: {
        pagination: {
          limit: PROJECT_ACTIVITY_PAGINATION_LIMIT,
          offsetID,
        },
      },
    });
  };

  return {
    ...query,
    fetchMore,
  };
}
