import { useQuery, useReactiveVar } from '@apollo/client';

import {
  LoadOrCreateScenarioSandboxByMilestoneIdQuery,
  LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables,
  ScenariosSortBy,
} from '../../../generated/graphql';
import { costModeVar } from '../../../utilities/costMode';

import { loadOrCreateScenarioSandboxByMilestoneIDQuery } from './queries';

const useLoadOrCreateScenarioSandboxByMilestoneIDQuery = (
  scenariosSortBy: ScenariosSortBy,
  milestoneID?: UUID
) =>
  useQuery<
    LoadOrCreateScenarioSandboxByMilestoneIdQuery,
    LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables
  >(loadOrCreateScenarioSandboxByMilestoneIDQuery, {
    variables: {
      milestoneID: milestoneID || '',
      costMode: useReactiveVar(costModeVar),
      scenariosSortBy,
    },
    fetchPolicy: 'cache-and-network',
    skip: !milestoneID,
  });

export default useLoadOrCreateScenarioSandboxByMilestoneIDQuery;
