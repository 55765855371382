import { FC } from 'react';

import { Divider } from '@material-ui/core';

import { DesignPhaseType } from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import MilestoneDatePicker from '../../../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneDesignPhaseSelector from '../../../../Milestone/MilestoneDesignPhaseSelector/MilestoneDesignPhaseSelector';
import MilestoneTitle from '../../../../Milestone/MilestoneTitle/MilestoneTitle';

import AddToMilestoneCategorizationName from './AddToMilestoneCategorizationName';
import styles from './AddToMilestoneNewMilestoneStyles';

type AddToMilestoneNewMilestoneProps = {
  categorizationName: string;
  classes: Classes<typeof styles>;
  date: string;
  errorMessage?: string;
  isBuiltIn: boolean;
  milestoneDesignPhase: DesignPhaseType | undefined;
  milestoneDesignPhaseValues: DesignPhaseType[];
  milestoneName: string;
  setCategorizationName: (value: string) => void;
  setDate: (date: string | null) => void;
  setDesignPhase: (designPhase: DesignPhaseType | undefined) => void;
  setErrorMessage: (message?: string) => void;
  setMilestoneName: (value: string) => void;
};

const AddToMilestoneNewMilestone: FC<AddToMilestoneNewMilestoneProps> = ({
  categorizationName,
  classes,
  date,
  errorMessage,
  isBuiltIn,
  milestoneDesignPhase,
  milestoneDesignPhaseValues,
  milestoneName,
  setCategorizationName,
  setDate,
  setDesignPhase,
  setErrorMessage,
  setMilestoneName,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.topPadding}>
        <MilestoneTitle
          disabled={false}
          name={milestoneName}
          onUpdate={setMilestoneName}
          onSet={setMilestoneName}
        />
      </div>
      <div className={`${classes.topPadding} ${classes.columns}`}>
        <MilestoneDatePicker date={date} onUpdate={setDate} />
      </div>
      <div className={classes.topPadding}>
        <div className="type-label">Milestone Design Phase *</div>
        <MilestoneDesignPhaseSelector
          setSelection={(designPhase: DesignPhaseType | undefined) => {
            if (designPhase) {
              setDesignPhase(designPhase);
            }
          }}
          selectedValue={milestoneDesignPhase?.id}
          values={milestoneDesignPhaseValues}
        />
      </div>
      {!isBuiltIn && (
        <AddToMilestoneCategorizationName
          errorMessage={errorMessage}
          name={categorizationName}
          onChange={setCategorizationName}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

export default withStyles(styles)(AddToMilestoneNewMilestone);
