import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

// TODO: alphabetize when we get these styles cleaned out
export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'unset',
      border: theme.border.line,
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
      '@media print': {
        marginTop: 0,
      },
    },
    cardExpanded: {
      border: `2px solid ${theme.palette.backgroundGrey}`,
    },
    cardHeader: theme.cardHeader,
    cardHeaderOptions: {
      alignItems: 'center',
      display: 'flex',
      height: '24px',
    },
    cardHeaderTypography: theme.typography.cardHeader,
    flexNoPrint: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      paddingBottom: theme.spacing.unit,
      paddingTop: theme.spacing.unit,
      justifyContent: 'space-between',
      '@media print': {
        display: 'none',
      },
    },
    rootDescription: {
      '@media print': {
        margin: 0,
      },
    },
    createOptionMenu: {
      position: 'relative',
      right: 4 * theme.spacing.unit,
    },
    menuLabel: {
      padding: '13px 12px 10px 12px',
    },
    multiline: {
      '@media print': {
        paddingLeft: 0,
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
    navIcon: {
      width: theme.iconSizes.medium,
      height: theme.iconSizes.medium,
      fontSize: '20px',
      margin: '0px',
      color: 'inherit',
      '@media print': {
        display: 'none',
      },
    },
    root: {
      minHeight: '101%',
      color: theme.palette.primaryGrey,
      display: 'flex',
      overflowY: 'auto',
      '@media print': {
        minHeight: 'unset',
        overflowX: 'hidden',
      },
    },
    spacer: {
      flexGrow: 1,
    },
    status: {
      marginRight: theme.spacing.unit * 4,
      width: 48,
      height: 48,
    },
    rightColumn: {
      padding: 24,
      paddingTop: 12,
      [theme.breakpoints.down('lg')]: {
        padding: 8,
      },
    },
    centerColumn: {
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 48px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 400px)',
      },
      flexDirection: 'column',
      flex: '1 0 auto',
      backgroundColor: 'white',
      border: theme.border.line,
      padding: '0px 24px',
      '@media print': {
        border: `0px solid ${theme.palette.joinGrey400}`,
        padding: '8px 0px 24px 0px',
        maxWidth: '100%',
      },
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      flexWrap: 'wrap',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    titleRow: {
      alignItems: 'start',
    },
    costSummaryContainer: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      borderTop: theme.border.line,
    },
    expander: {
      color: theme.palette.joinPrimary,
      marginLeft: -8,
      marginRight: 8,
      '@media print': {
        display: 'none',
      },
    },
    clickMe: {
      cursor: 'pointer',
    },
    printBorder: {
      '@media print': {
        border: theme.border.line,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      ...theme.typography.title,
      overflow: 'hidden',
      color: theme.palette.joinPrimary,
      '@media print': {
        paddingLeft: 0,
      },
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
    },
    titleContainer: {
      flexGrow: 1,
      paddingBottom: 10,
      '@media print': {
        paddingBottom: 0,
      },
    },
    titleInput: {
      paddingTop: 1,
      textOverflow: 'ellipsis',
    },
    wrapPrint: {
      display: 'flex',
      flexDirection: 'column',
    },
    printButton: {
      padding: theme.spacing.generic[1],
      minWidth: 124,
      '@media print': {
        display: 'none',
      },
    },
  });
