import { ChangeEvent, FC, useState } from 'react';

import { TextField } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { ENTER, NUMBER } from '../../../constants';
import { PROJECT_COMP_ERROR_ICON_INPUT } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { useStateLocalUpdater } from '../ProjectCompUtils';

import styles from './ProjectCompsInputsQuantityStyles';

type ProjectCompsInputsQuantityProps = {
  classes: Classes<typeof styles>;
  isHighlighted?: boolean;
  isPinnedUnit: boolean;
  onMouseOver?: (isOver: boolean) => void;
  quantity: string;
  setMetrics: (unitID: UUID, quantityMagnitude: string | null) => void;
  unit: Unit;
};

const ProjectCompsInputsQuantity: FC<ProjectCompsInputsQuantityProps> = ({
  classes,
  isHighlighted,
  isPinnedUnit,
  onMouseOver = () => {},
  quantity,
  setMetrics,
  unit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useStateLocalUpdater(quantity);

  const missingMetricError = Boolean(localValue) === false && !isEditing && isPinnedUnit;

  let displayValue = '';
  if (localValue) {
    if (isEditing) {
      displayValue = localValue;
    } else {
      displayValue = formatCommas(localValue);
    }
  }

  const placeholder = missingMetricError ? `Enter missing value` : `Enter a value`;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const onEnter = () => {
    setIsEditing(true);
  };

  const onExit = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMetrics(unit.id, e.target.value);
    setIsEditing(false);
  };

  const inputStyle = `${classes.quantityField} ${
    isHighlighted && !isEditing ? classes.greenBG : classes.whiteBG
  } ${classes.quantityInput} ${missingMetricError ? classes.errorFeedbackText : ''}`;

  // Takes care of highlights, borders and error styling
  const className = `${isHighlighted ? classes.greenBG : ''} ${classes.quantityField} 
  ${missingMetricError || displayValue === '0' ? classes.quantityFieldError : ''}`;

  const icon = missingMetricError ? (
    <ErrorOutlineIcon
      className={`${classes.errorIcon} ${classes.errorIconTextBox}`}
      data-cy={PROJECT_COMP_ERROR_ICON_INPUT}
    />
  ) : undefined;

  return (
    <TextField
      className={className}
      data-cy={`unit-${unit.abbreviationPlural}-quantityInput`}
      fullWidth
      InputProps={{
        className: inputStyle,
        disableUnderline: true,
        startAdornment: icon,
        endAdornment: (
          <div
            className={`${classes.unitAbbreviationInput} ${classes.titleHeading} ${
              missingMetricError ? classes.errorTextColor : ''
            }`}
          >
            {unit.abbreviationPlural}
          </div>
        ),
      }}
      name="quantity-input"
      onBlur={onExit}
      onChange={onChange}
      onFocus={onEnter}
      onKeyPress={(e) => {
        if (e.target instanceof HTMLElement) {
          // exit input on 'Enter'
          if (e.key === ENTER) e.target.blur();
          // block input on '.' - Quantity only supports integer
          if (e.key === '.') e.preventDefault();
        }
      }}
      placeholder={placeholder}
      type={isEditing ? NUMBER : undefined}
      value={displayValue}
      onMouseEnter={() => onMouseOver(true)}
      onMouseLeave={() => onMouseOver(false)}
    />
  );
};

export default withStyles(styles)(ProjectCompsInputsQuantity);
