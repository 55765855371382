import { useState } from 'react';

import { assetCacheVar } from '../../../api/apollo/reactiveVars';
import { fetchBlobUrl } from '../../../api/assets';
import { AssetQueryApolloQuery } from '../../../generated/graphql';

import useAssetQuery from './useAssetQuery';

export const getAssetBlobCacheKey = (assetID: UUID | null | undefined) => `AssetBlob:${assetID}`;

export default function useAssetBlobUrlQuery(assetID: UUID | null | undefined) {
  const blobUrlCached = assetCacheVar().get(getAssetBlobCacheKey(assetID));
  const [url, setUrl] = useState<string | undefined>(blobUrlCached);
  const onCompleted = async (data: AssetQueryApolloQuery) => {
    const blobUrl = await fetchBlobUrl(data.asset);
    setUrl(blobUrl ?? undefined);
    if (blobUrl) assetCacheVar().set(getAssetBlobCacheKey(assetID), blobUrl);
  };
  const { loading } = useAssetQuery(assetID, onCompleted);
  return { loading, url };
}
