import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { UserReportType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import DialogsStyles from '../DialogsStyles';

import DialogsReportsContent from './DialogsReportsContent/DialogsReportsContent';

type DialogReportsProps = {
  allowableReportTypes: UserReportType[];
  cannotShare?: boolean;
  classes: Classes<typeof DialogsStyles>;
  inputReport: UserReportInput;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  setInputReport: (newInput: UserReportInput) => void;
  variant: 'reports-tab' | 'reports-manager';
  hasDistribution?: boolean;
};

const DialogReports: FC<DialogReportsProps> = ({
  allowableReportTypes,
  cannotShare,
  classes,
  hasDistribution,
  inputReport,
  onClose,
  onSubmit,
  open,
  setInputReport,
  variant,
}) => {
  const location = useLocation();
  const projectId = getProjectIdFromUrl(location.pathname);

  const header = inputReport.id ? 'Edit Report' : 'New Report';

  return (
    <Dialog
      maxWidth={false}
      classes={{
        paper: classes.dialogPaperSmall,
      }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">{header}</Typography>
        </div>
        <IconButton title="Close dialog" className={classes.close}>
          <Close onClick={onClose} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <DialogsReportsContent
          cannotShare={cannotShare}
          userReport={inputReport}
          setUserReport={setInputReport}
          onSubmit={onSubmit}
          projectId={projectId}
          allowableReportTypes={allowableReportTypes}
          variant={variant}
          hasDistribution={hasDistribution ?? false}
        />
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-cy="DialogsConfirm-okButton"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={inputReport.name === '' || inputReport.reportType === UserReportType.NONE}
        >
          {inputReport.id ? 'Update' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogReports = withStyles(DialogsStyles)(DialogReports);

export default StyledDialogReports;
