import { createStyles } from '@material-ui/core';

import { MARGIN, SHADOW } from '../PrintUtils';

const styles = () =>
  createStyles({
    page: {
      '@media not print': {
        background: 'white',
        boxShadow: SHADOW,
        outline: `white solid ${MARGIN}px`,
        '-webkit-box-shadow': SHADOW,
      },
      margin: 'auto',
    },
  });

export default styles;
