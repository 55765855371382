import * as d3 from 'd3';
import { FC, useEffect, useRef } from 'react';

import { PieChartData, PieChartSize, generateChart } from './ChartsPieGraphUtils';

type ChartsPieGraphProps = {
  centerLabel?: string;
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  includeGradiant?: boolean;
};

const ChartsPieGraphGraph: FC<ChartsPieGraphProps> = ({
  centerLabel,
  chartSize,
  colors,
  data,
  includeGradiant = false,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .selectAll('svg')
      .data([null]) // Use enter/update pattern to avoid creating duplicate SVGs
      .join('svg')
      .attr('width', chartSize.diameter)
      .attr('height', chartSize.diameter)
      .append('g');

    generateChart(
      svg,
      colors.reverse(),
      data.reverse(), // D3 draws anticlockwise so we need to reverse the list to be in clockwise order
      chartSize,
      centerLabel,
      includeGradiant
    );
  }, [centerLabel, chartSize, colors, data, includeGradiant]);

  if (!data) return <></>;

  return <svg ref={svgRef} />;
};

export default ChartsPieGraphGraph;
