import { ReactElement } from 'react';

import TipTapTextArea, { TipTapTextAreaVariant } from './TipTapTextArea/TipTapTextArea';
import { isEmptyText, isRichText } from './TipTapTextArea/TipTapTextAreaUtils';

type InputsTextAreaStyledProps = {
  editable: boolean;
  emptyElement?: ReactElement;
  flexGrow?: boolean;
  hideBorder?: boolean;
  hasMinHeight?: boolean;
  onAttachAsset?: (asset: Asset) => void;
  onChangeComplete?: (value: string, valueStyled: string) => void;
  placeholder: string;
  textStyled?: string;
  variant?: TipTapTextAreaVariant;
};

const InputsTextAreaStyled = ({
  editable,
  emptyElement,
  flexGrow,
  hideBorder = false,
  hasMinHeight = true,
  onAttachAsset,
  onChangeComplete,
  placeholder,
  textStyled = '',
  variant = TipTapTextAreaVariant.DEFAULT,
}: InputsTextAreaStyledProps) => {
  // If empty...
  if (isEmptyText(textStyled)) {
    // If disabled, then we should render nothing
    if (!editable) {
      return null;
    }
    // if we specify an empty element, then we should render that
    if (emptyElement && isRichText(textStyled)) {
      return emptyElement;
    }
  }

  return (
    <TipTapTextArea
      dataCy="inputsTextAreaStyled"
      editable={editable}
      placeholder={placeholder}
      hasMinHeight={hasMinHeight}
      onAttachAsset={onAttachAsset}
      onChangeComplete={onChangeComplete}
      textStyled={textStyled}
      flexGrow={flexGrow}
      hideBorder={hideBorder}
      variant={variant}
    />
  );
};

export default InputsTextAreaStyled;
