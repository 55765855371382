import { FC, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { PermissionResource } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { SelectCategoryValue } from '../SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputMulti from '../SelectCategoryChipInput/SelectCategoryChipInputMulti';
import SelectCategoryChipInputSingle from '../SelectCategoryChipInput/SelectCategoryChipInputSingle';
import SelectPanel from '../SelectPanel/SelectPanel';

import SelectCategoryPermissionsStyles from './SelectCategoryPermissionsStyles';

interface SelectCategoryPermissionsProps {
  addCategories: (categories: SelectCategoryValue[]) => void;
  classes: Classes<typeof SelectCategoryPermissionsStyles>;
  hasAllCategoryPermissions: boolean;
  id: string;
  isCompact?: boolean;
  onClose: () => void;
  onSelectAll: () => void;
  removeCategories: (categories: Category[]) => void;
  selectedCategories: Category[];
  isPreview?: boolean;
}

const SelectCategoryPermissions: FC<SelectCategoryPermissionsProps> = ({
  addCategories,
  classes,
  hasAllCategoryPermissions,
  id,
  isCompact = true,
  onClose,
  onSelectAll,
  removeCategories,
  selectedCategories,
  isPreview = true,
}) => {
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  const { canEdit } = usePermissions();
  const canEditCollaborators = isPreview ? canEdit(PermissionResource.COLLABORATORS) : true;

  const projectId = getProjectIdFromUrl();

  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const categoryInput = hasAllCategoryPermissions ? (
    <SelectCategoryChipInputSingle
      categorizations={categorizations}
      disabled={!canEditCollaborators}
      id={`SelectCategoryChipInput-${id}`}
      includeUncategorizedCategory={false}
      isCompact={isCompact}
      placeholder="Add Categories"
      selectedCategory={{ id: '', name: 'All Categories', number: '', level: 0 } as Category}
      setCategory={(newTrade: SelectCategoryValue) => addCategories([newTrade])}
    />
  ) : (
    <SelectCategoryChipInputMulti
      addCategories={(newTrades: SelectCategoryValue[]) => {
        addCategories(newTrades);
      }}
      categorizations={categorizations}
      disabled={!canEditCollaborators}
      id={`SelectCategoryChipInput-${id}`}
      isCompact={isCompact}
      isPreview={isPreview}
      noun="Category"
      placeholder="Add Categories"
      removeCategories={(newTrades) => {
        removeCategories(newTrades);
      }}
      selectedCategories={selectedCategories}
    />
  );

  const closedSelector = (
    <div
      data-cy="select-tradeCategory"
      className={classes.closedSelector}
      onClick={() => {
        onClose();
        setSelectorIsOpen(true);
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      onKeyDown={(event: any) => {
        onClose();
        if (event.key === 'Enter') {
          setSelectorIsOpen(true);
        }
      }}
    >
      {categoryInput}
    </div>
  );

  const openSelector = (
    <SelectPanel
      data-cy="select-tradeCategory"
      addCategories={(newTrades: SelectCategoryValue[]) => {
        addCategories(newTrades);
      }}
      categorizations={categorizations}
      isCompact={isCompact}
      isPreview={isPreview}
      noun="Category"
      onSelectAll={onSelectAll}
      removeCategories={(newTrades) => {
        removeCategories(newTrades);
      }}
      selectedCategories={selectedCategories}
      setShowSelectPanel={setSelectorIsOpen}
    />
  );

  return selectorIsOpen && canEditCollaborators ? (
    <div className={classes.containerOpenSelector}>{openSelector}</div>
  ) : (
    closedSelector
  );
};

export default withStyles(SelectCategoryPermissionsStyles)(SelectCategoryPermissions);
