import { FC } from 'react';

import { Divider, Typography } from '@material-ui/core';

import { formatCost } from '../../../utilities/currency';

import ExecutiveDashboardProjectMapStyles from './ExecutiveDashboardProjectMapStyles';

type Project = ProjectLocationData['projects'][number];

type ExecutiveDashboardProjectMapLocationProps = {
  classes: Classes<typeof ExecutiveDashboardProjectMapStyles>;
  location: string;
  projects: Project[];
};

const ExecutiveDashboardProjectMapLocation: FC<ExecutiveDashboardProjectMapLocationProps> = ({
  classes,
  location,
  projects,
}) => (
  <>
    <Typography className={classes.location}>{location}</Typography>
    <Divider />
    {projects.map((p: Project) => (
      <div className={classes.popupContent} key={p.id}>
        <Typography className={classes.name}>{p.name}</Typography>
        <div>
          <Typography className={classes.status}>{p.status}</Typography>
          <Typography className={classes.cost}>
            {formatCost(p.apv, { showCents: false })}
          </Typography>
        </div>
      </div>
    ))}
  </>
);

export default ExecutiveDashboardProjectMapLocation;
