import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    closedSelector: {
      ...theme.typography.h3,
      width: '100%',
    },
    containerOuter: {
      alignItems: 'center',
      background: 'none',
      border: theme.border.line,
      borderRadius: '4px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      height: '40px',
      justifyContent: 'space-between',
      minHeight: '40px',
      padding: '4px',
      width: '100%',
    },
    containerOpenSelector: {
      position: 'relative',
      top: '-18px',
      width: '100%',
    },
  });
