import { ComponentProps } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import PrintPageScenariosSandboxStyles from './PrintPageScenariosSandboxStyles';
import PrintViewScenariosSandbox from './PrintViewScenariosSandbox';
import { printScenarioWidth } from './PrintViewScenariosSandboxUtils';

type Props = ComponentProps<typeof PrintViewScenariosSandbox> & {
  classes: Classes<typeof PrintPageScenariosSandboxStyles>;
};

const PrintPageScenariosSandbox = (props: Props) => (
  <div className={props.classes.page} style={{ width: printScenarioWidth }}>
    <PrintViewScenariosSandbox {...props} />
  </div>
);

export default withStyles(PrintPageScenariosSandboxStyles)(PrintPageScenariosSandbox);
