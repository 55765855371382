import { GUIDREGEX } from '../../constants';
import { ImageDimension } from '../../generated/graphql';
import { useThumbnailQuery } from '../assets/hooks/useAssetQuery';

export const useThumbnailURL = (projectThumbnailGUID?: string, skip?: boolean) => {
  const { data, loading } = useThumbnailQuery(projectThumbnailGUID, ImageDimension._92, skip);
  const { asset } = data || {};
  const { thumbnailURL } =
    asset ||
    (!(projectThumbnailGUID && GUIDREGEX.exec(projectThumbnailGUID)) && {
      thumbnailURL: projectThumbnailGUID,
    }) ||
    {};
  const thumbnailLoading = !skip && (loading || !thumbnailURL);

  return { thumbnailURL, thumbnailLoading };
};
