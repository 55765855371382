import { createStyles } from '@material-ui/core';

import { titleStyle } from '../../CostReport/CostReportHeader/CostReportHeaderStyles';

export const HEADER_HEIGHT = 88;

const styles = () =>
  createStyles({
    header: {
      display: 'flex',
      flex: '0 0 auto',
      '@media print': {
        display: 'none',
      },
    },
    ...titleStyle,
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 16,
      flex: '0 1 auto',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  });

export default styles;
