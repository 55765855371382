import {
  HasUnreadInAppNotificationsQuery,
  HasUnreadInAppNotificationsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { hasUnreadInAppNotificationsQuery } from './queries';

const useHasUnreadInAppNotificationsQuery = () =>
  useQuery<HasUnreadInAppNotificationsQuery, HasUnreadInAppNotificationsQueryVariables>(
    hasUnreadInAppNotificationsQuery,
    {}
  );

export default useHasUnreadInAppNotificationsQuery;
