import { FC } from 'react';

import { CardHeader, Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { COSTS } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { FilterManager } from '../../FilterPanel/filterUtils';
import { ColumnDescription } from '../CostReportColumns/CostReportColumns';
import CostReportExportPanel from '../CostReportExportPanel/CostReportExportPanel';
import CostReportSettingsPanel from '../CostReportSettingsPanel/CostReportSettingsPanel';

import styles from './CostReportHeaderStyles';

type CostReportHeaderProps = {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  columnDescriptions: ColumnDescription[];
  displayColumnDescriptions: ColumnDescription[];
  displayGroupBy: DisplayGroupBy[];
  exportCostReport: () => void;
  filterManager: FilterManager;
  milestone: Milestone;
  printOnClick: (route: JoinProjectRoutes) => void;
  projectId: UUID;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSettings?: (settings: { [key: string]: any }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting: (param: string, value: any) => void;
  status: string[];
  viewMode: string;
};

const CostReportHeader: FC<CostReportHeaderProps> = ({
  categorizations,
  classes,
  columnDescriptions,
  displayColumnDescriptions,
  displayGroupBy,
  exportCostReport,
  filterManager,
  milestone,
  printOnClick,
  projectId,
  settings,
  setSettings,
  setSetting,
  status,
  viewMode,
}) => {
  const controlHeader = (
    <div className={classes.controlHeader}>
      <CostReportSettingsPanel
        categorizations={categorizations}
        columnDescriptions={columnDescriptions}
        displayColumnDescriptions={displayColumnDescriptions}
        displayGroupBy={displayGroupBy}
        filterManager={filterManager}
        milestoneID={milestone.id}
        page={COSTS}
        settings={settings}
        setSettings={setSettings}
        setSetting={setSetting}
        status={status}
        viewMode={viewMode}
      />
      <CostReportExportPanel printOnClick={printOnClick} exportCostReport={exportCostReport} />
    </div>
  );

  const { id: milestoneId, name } = milestone;

  return (
    <CardHeader
      title={
        <div className={classes.header}>
          <div className={classes.title}>
            <Breadcrumbs
              links={[
                {
                  display: 'Milestones',
                  destination: generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }),
                  tooltip: 'Back to milestones list',
                },
                {
                  display: name || 'Loading...',
                  destination: generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                    projectId,
                    milestoneId,
                  }),
                  tooltip: 'Back to milestone details',
                },
              ]}
            />
            <Typography data-cy="CostReport-title" variant="headline">
              Milestone Summary Report
            </Typography>
          </div>
          {controlHeader}
        </div>
      }
    />
  );
};

export default withStyles(styles)(CostReportHeader);
