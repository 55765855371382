import theme from '../../../theme/komodo-mui-theme';
import { getTextDot } from '../../Navigation/NavigationTabs';

// Tabs
export const THIS_PROJECT = 'This project';
export const ALL_PROJECTS = 'All projects';

// Dot Determination
const unreadDots = (
  hasUnreadInAppNotifications: boolean,
  hasProjectUnreadNotifications: boolean
) => {
  const dotTabs: string[] = [];
  if (hasProjectUnreadNotifications) dotTabs.push(THIS_PROJECT);
  if (hasUnreadInAppNotifications) dotTabs.push(ALL_PROJECTS);
  return dotTabs;
};

// Get styling for tabs based on unread info
export const getUnreadTabs = (
  hasUnreadInAppNotifications: boolean,
  hasProjectUnreadNotifications: boolean
) => {
  const getTextLabel = (text: string) =>
    unreadDots(hasUnreadInAppNotifications, hasProjectUnreadNotifications).includes(text)
      ? getTextDot(text, { color: theme.palette.badgeColor })
      : text;

  return { getTextLabel };
};
