import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import {
  reportDistributionEventTypes,
  userReportDistributionEvent,
} from '../../analytics/analyticsEventProperties';
import { getServerErrorCode } from '../../api/apollo/errors';
import { REPORT_PDF } from '../../constants';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { getProjectIdFromUrl } from '../../utilities/url';
import AssetsPDFViewer from '../assets/AssetsViewer/AssetsPDFViewer/AssetsPDFViewer';
import AssetsViewerFooter from '../assets/AssetsViewer/AssetsViewerFooter';
import { useDownloadAsset } from '../assets/hooks/AssetsMutationHook';
import useAssetQuery from '../assets/hooks/useAssetQuery';
import ErrorCode from '../errors/ErrorCode';
import { useLoadReportIDByReportDistributionAssetIDQuery } from '../ReportsTab/ReportHooks';

const ReportDistributionView = () => {
  const projectId = getProjectIdFromUrl();
  const { assetId } = useParams();

  // Hooks
  const sendAnalytics = useAnalyticsEventHook();
  const { data, error, loading } = useAssetQuery(assetId ?? null);
  const { data: reportData, loading: loadingReport } =
    useLoadReportIDByReportDistributionAssetIDQuery(assetId);

  const asset = data?.asset;
  const [downloadAssetFunc] = useDownloadAsset();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const reportType = params.get('utm_report_type');
    sendAnalytics(
      userReportDistributionEvent(reportDistributionEventTypes.REPORT_DISTRIBUTION_VIEW, {
        reportType,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || loadingReport) {
    return <LinearProgress />;
  }

  if (!asset) {
    return <ErrorCode code={getServerErrorCode(error?.networkError) ?? 404} />;
  }

  const downloadAsset = () => downloadAssetFunc(asset.location, asset.name);
  // TODO: NS-969 add analytics for file download

  return (
    <div>
      <AssetsViewerFooter
        asset={asset}
        canDelete={false}
        showMenu={false}
        downloadAsset={downloadAsset}
        hideFileInfo
        reportId={reportData?.reportIDByReportDistributionAssetID}
        projectId={projectId}
        type={REPORT_PDF}
      />
      <AssetsPDFViewer assetLocation={asset.displayURL ?? undefined} />
    </div>
  );
};

export default ReportDistributionView;
