import { gql } from '../../../../../api/graphqlFragments';

export const searchProjects = gql`
  query searchProjects(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        budget
        estimate
        hasAccess
        id
        location
        milestoneDate
        milestoneID
        milestoneName
        name
        runningTotal
        status
        thumbnail
        type
        updatedAt
        updatedBy
      }
      total
    }
  }
`;
