import { useMutation } from '@apollo/client';

import { removeCollaboratorTradeAnalytics } from '../../../analytics/analyticsEventProperties';
import {
  RemoveCollaboratorTradesMutation,
  RemoveCollaboratorTradesMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { removeCollaboratorTradesMutation } from './queries';

function useRemoveTradesCollaborator() {
  const [removeCollaboratorTradeFunc, mutationResult] = useMutation<
    RemoveCollaboratorTradesMutation,
    RemoveCollaboratorTradesMutationVariables
  >(removeCollaboratorTradesMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (
    projectID: UUID,
    collaboratorID: UUID,
    categoryIDs: UUID[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure: any
  ) =>
    removeCollaboratorTradeFunc({
      variables: { projectID, collaboratorID, categoryIDs },
    })
      .then((result) => {
        const removeTrades = result.data?.removeTrades;
        if (onSuccess) onSuccess(removeTrades);
        sendAnalytics(removeCollaboratorTradeAnalytics(collaboratorID, categoryIDs));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [submitFunc, mutationResult];
}

export default useRemoveTradesCollaborator;
