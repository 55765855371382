import { FC, MouseEvent } from 'react';

import { Badge, IconButton } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './InAppNotificationsIconStyles';

const MAX = 99;

type InAppNotificationsIconProps = {
  count: number;
  classes: Classes<typeof styles>;
  isDot?: boolean;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
};

const InAppNotificationsIcon: FC<InAppNotificationsIconProps> = ({
  count,
  classes,
  isDot,
  onClick,
}) => {
  const invisible = count === 0;
  const badgeContent = count > MAX ? `${MAX}+` : count;
  const badgeStyle = isDot ? classes.dot : classes.badge;
  return (
    <IconButton
      onClick={onClick}
      className={classes.buttonSpacing}
      data-cy="view-notifications-button"
    >
      <Badge
        classes={{ badge: invisible ? classes.invisible : badgeStyle }}
        badgeContent={badgeContent}
      >
        <Notifications />
      </Badge>
    </IconButton>
  );
};

export default withStyles(styles)(InAppNotificationsIcon);
