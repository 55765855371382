import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    containerHeight: {
      height: 32,
    },
    greenBG: {
      backgroundColor: theme.palette.backgroundGreen,
    },
    name: {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameBold: {
      fontWeight: 400,
    },
    paper: {
      minWidth: '100%',
      position: 'absolute',
      zIndex: 1000,
    },
    rootItem: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      height: 32,
      padding: `7px 16px 7px 0px`,
    },
    rootItemCompact: {
      padding: `5px 16px 4px 0px`,
    },
    row: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing.selectPaddingLeft,
      minHeight: 24,
    },
    subName: {
      ...theme.typography.body2,
    },
    text: { height: '32px', ...theme.typography.body1, fontWeight: 400 },
  });

export default styles;
