import { useReactiveVar } from '@apollo/client';

import { mountedItemsVar } from '../../api/apollo/reactiveVars';
import { ItemCostQuery, ItemCostQueryVariables } from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { costModeVar } from '../../utilities/costMode';

import { itemCostQuery } from './hooks/itemsListQuery';
import { ItemsListSettings, useItemsListMilestone } from './ItemsListUtils';

export const useItemCostQuery = (
  itemID: UUID,
  projectID: UUID,
  viewFilter: ViewFilterInput,
  settings: ItemsListSettings,
  skipQuery?: boolean
) =>
  useQuery<ItemCostQuery, ItemCostQueryVariables>(
    itemCostQuery,
    {
      variables: {
        itemID,
        milestoneID: useItemsListMilestone(settings),
        projectID,
        costMode: useReactiveVar(costModeVar),
        viewFilter,
      },
      skip: !!skipQuery,
      context: { batch: true },
    },
    mountedItemsVar().get(itemID) ? MountPolicy.SKIP_ON_MOUNT : MountPolicy.FETCH_ON_MOUNT
  );
