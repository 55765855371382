import { ReactNode, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import { setCostModeAnalytics } from '../../../analytics/analyticsEventProperties';
import { TermKey } from '../../../api/gqlEnums';
import { CostMode } from '../../../api/gqlEnumsBe';
import { REFETCH_NEW_COST_MODE } from '../../../api/refetchSets';
import { ALLOCATED_MARKUPS, NO_MARKUPS, SEPARATED_MARKUPS } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useRefetch } from '../../../hooks/useRefetch';
import {
  costModeVar,
  getCostModeString,
  getLocalCostModeLocation,
  mapCostModeStringToEnum,
  useAvailableCostModes,
  useCostMode,
} from '../../../utilities/costMode';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { BabyButton, Popover, Radio, RadioGroup, Switch, Tooltip } from '../../scales';
import {
  ConfigureIcon,
  CostModeAllocatedIcon,
  CostModeNoMarkupsIcon,
  CostModeSeparatedIcon,
} from '../icons';

export default function CostModePopover() {
  const overlayTriggerState = useOverlayTriggerState({});
  const ref = useRef<HTMLButtonElement>(null);
  const { shouldDisplayCosts, setDisplayCostsToggle, isToggleDisabled } = useShouldDisplayCosts();

  const availableCostModes = useAvailableCostModes();
  const canSelectCostMode = availableCostModes.length > 1;

  if (!setDisplayCostsToggle) return null; // don't show menu at all if display costs are disabled

  return (
    <>
      <Tooltip content="Change cost mode">
        <BabyButton
          aria-label="change cost mode"
          data-cy="change-cost-mode-btn"
          icon={<ConfigureIcon />}
          onClick={overlayTriggerState.toggle}
          ref={ref}
        />
      </Tooltip>
      {overlayTriggerState.isOpen && (
        <Popover
          className="w-75"
          placement="bottom start"
          state={overlayTriggerState}
          triggerRef={ref}
        >
          <div className="bg-background-1">
            {setDisplayCostsToggle && (
              <div className="p-2">
                <Switch
                  checked={shouldDisplayCosts}
                  data-cy="display-costs-toggle"
                  disabled={isToggleDisabled}
                  isFullWidth
                  label="Display Costs"
                  onChange={setDisplayCostsToggle}
                />
              </div>
            )}
            {canSelectCostMode && <div className="h-px w-full bg-border-separator" />}
            {canSelectCostMode && (
              <div className="p-4">
                <CostModeRadioGroup />
              </div>
            )}
          </div>
        </Popover>
      )}
    </>
  );
}

function CostModeRadioGroup() {
  const { projectId } = useParams();
  const costMode = useCostMode();
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const sendAnalytics = useAnalyticsEventHook();
  const refetchCostQueries = useRefetch(REFETCH_NEW_COST_MODE);

  const entries = useRadioEntries();
  if (entries.length < 2) return null;

  return (
    <RadioGroup
      aria-label="cost mode"
      isDisabled={!shouldDisplayCosts}
      value={costMode}
      onChange={(newMode) => {
        costModeVar(mapCostModeStringToEnum(newMode)); // apollo store
        localStorage.setItem(getLocalCostModeLocation(projectId), newMode); // local storage
        sendAnalytics(setCostModeAnalytics(newMode));
        setTimeout(() => refetchCostQueries(), 0);
      }}
    >
      <div className="flex flex-col gap-2">
        {entries.map((entry) => (
          <Radio key={entry.value} value={entry.value}>
            <div className="flex items-center gap-2">
              <div className="shrink-0 icon-md">{entry.icon}</div>
              <div className="flex flex-col gap-1">
                <div className="type-body1">{entry.label}</div>
                <div className="type-body3">{entry.description}</div>
              </div>
            </div>
          </Radio>
        ))}
      </div>
    </RadioGroup>
  );
}

type CostModeRadioEntry = {
  'data-cy': string;
  description: string;
  icon: ReactNode;
  label: string;
  value: string;
};

function useRadioEntries() {
  const t = useContext(ProjectTermStore);

  const availableCostModes = useAvailableCostModes();

  const entries: CostModeRadioEntry[] = [];
  if (availableCostModes.includes(CostMode.AllocatedMarkups))
    entries.push({
      'data-cy': ALLOCATED_MARKUPS,
      description: `Allocate selected ${t.lowerCase(TermKey.MARKUP)} pro-rata`,
      icon: <CostModeAllocatedIcon />,
      label: getCostModeString(CostMode.AllocatedMarkups, t),
      value: ALLOCATED_MARKUPS,
    });
  if (availableCostModes.includes(CostMode.SeparatedMarkups))
    entries.push({
      'data-cy': SEPARATED_MARKUPS,
      description: `Do not allocate ${t.lowerCase(TermKey.MARKUP)}`,
      icon: <CostModeSeparatedIcon />,
      label: getCostModeString(CostMode.SeparatedMarkups, t),
      value: SEPARATED_MARKUPS,
    });
  if (availableCostModes.includes(CostMode.NoMarkups))
    entries.push({
      'data-cy': NO_MARKUPS,
      description: `${t.sentenceCase(TermKey.MARKUP)} are not displayed or included in totals`,
      icon: <CostModeNoMarkupsIcon />,
      label: getCostModeString(CostMode.NoMarkups, t),
      value: NO_MARKUPS,
    });

  return entries;
}
