import { FC, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { withStyles } from '../../../theme/komodo-mui-theme';
import PrintProjectCompsChartData from '../../ProjectComps/ProjectCompsVisualization/ProjectCompsChart/PrintProjectCompsChartData';
import { PORTRAIT_WIDTH } from '../PrintUtils';

import PrintProjectCompsColumnGroup from './PrintProjectCompsColumnGroup';
import PrintProjectCompsStyles from './PrintProjectCompsStyles';
import {
  CompsPrintThumbnailSize,
  PRINT_PARAMS_DEFAULTS,
  PRINT_PARAMS_KEY,
  getColumnGroups,
  getHasMarkups,
  isProjectCompType,
} from './PrintProjectCompsUtils';

type PrintProjectCompsSetProps = {
  onLoad: () => void;
  pageHeader: JSX.Element;
  parentProject?: ProjectProps;
  projectCompsSet: ProjectCompsSet;
};

const PrintProjectCompsSet: FC<PrintProjectCompsSetProps> = ({
  onLoad,
  pageHeader,
  parentProject,
  projectCompsSet,
}) => {
  const [params] = useLocalStorage(PRINT_PARAMS_KEY, PRINT_PARAMS_DEFAULTS);

  const { columns, columnGroups } = getColumnGroups(projectCompsSet);
  const hasMarkups = getHasMarkups(projectCompsSet);

  // Thumbnail Loading
  // this is used to ensure we print after all column thumbnails are visible
  const [loadedThumbnails, setLoadedThumbnails] = useState<null | boolean[]>(null);

  useEffect(() => {
    setLoadedThumbnails(Array(columns.length).fill(false));
  }, [columns.length]);
  const onThumbnailLoad = (columnIndex: number) =>
    setLoadedThumbnails((prevLoadedThumbnails) => {
      if (!prevLoadedThumbnails || prevLoadedThumbnails[columnIndex] === true)
        return prevLoadedThumbnails;
      const updatedLoadedThumbnails = [...prevLoadedThumbnails];
      updatedLoadedThumbnails.splice(columnIndex, 1, true);
      return updatedLoadedThumbnails;
    });

  // Complete loaded effect
  const [chartLoaded, setChartLoaded] = useState(false);
  const hasNoThumbnails = params?.imageSize === CompsPrintThumbnailSize.NONE;
  const hasNoCharts = !params?.hasCharts;
  useEffect(() => {
    const allThumbnailsLoaded = loadedThumbnails && loadedThumbnails.every((loaded) => loaded);
    const thumbnailsReady = hasNoThumbnails || allThumbnailsLoaded;
    const chartReady = hasNoCharts || chartLoaded;
    if (thumbnailsReady && chartReady) onLoad();
  }, [chartLoaded, hasNoThumbnails, loadedThumbnails, onLoad, hasNoCharts]);

  return (
    <>
      {params?.hasCharts && (
        <PrintProjectCompsChartData
          height={PORTRAIT_WIDTH}
          onLoad={() => setChartLoaded(true)}
          pageHeader={pageHeader}
          projectCompsSet={projectCompsSet}
        />
      )}
      {columnGroups.map((columnGroup, groupIndex) => {
        const groupKey = columnGroup
          .map((comp) => (isProjectCompType(comp) ? comp.project.id : 'Average'))
          .join(':');
        return (
          <PrintProjectCompsColumnGroup
            columnGroup={columnGroup}
            costTableColumnInputs={projectCompsSet.input.costTableColumnInputs}
            groupIndex={groupIndex}
            hasMarkups={hasMarkups}
            key={groupKey}
            onThumbnailLoad={onThumbnailLoad}
            pageHeader={pageHeader}
            parentProject={parentProject}
            projectCompsSet={projectCompsSet}
            thumbnailSize={params?.imageSize ?? CompsPrintThumbnailSize.SMALL}
            showNotes={params?.hasNotes ?? false}
          />
        );
      })}
    </>
  );
};

export default withStyles(PrintProjectCompsStyles)(PrintProjectCompsSet);
