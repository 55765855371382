import { memo, useCallback } from 'react';

import { PROJECT_COMP_CUSTOM_CATZ } from '../../../../tagConstants';
import JoinSelect from '../../../Select/JoinSelect/JoinSelect';

import {
  CategorizationEntry,
  getMatchedEntry,
  useCategorizationEntries,
} from './CategorizationInputUtils';

interface CategorizationInputProps {
  categorizationID?: UUID;
  categorizationLevel?: number;
  projectID: UUID;
  setCategorization: (categorizationID: UUID, categorizationLevel: number) => void;
}

const CategorizationInput = (props: CategorizationInputProps) => {
  const { categorizationID, categorizationLevel, setCategorization } = props;

  const { entries } = useCategorizationEntries(props.projectID);

  const currentEntry = getMatchedEntry(entries, {
    categorizationID,
    categorizationLevel,
  });
  const selectCategorizationEntry = useCallback(
    (entry: CategorizationEntry | undefined) => {
      if (!entry) return;
      setCategorization(entry.categorizationID, entry.categorizationLevel);
    },
    [setCategorization]
  );

  const onChange = (selectedID: string) => {
    if (currentEntry?.id === selectedID) return;
    const entry = entries.find((entry) => entry.id === selectedID);
    selectCategorizationEntry(entry);
  };

  return (
    <JoinSelect
      cySelect={PROJECT_COMP_CUSTOM_CATZ}
      entries={entries}
      groupingKey="type"
      onChange={onChange}
      valuePlaceholder="Select Categorization"
      value={currentEntry?.id ?? ''}
    />
  );
};

export default memo(CategorizationInput);
