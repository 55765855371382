import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Close, KeyboardReturn } from '@material-ui/icons';

import { usePreviewSettingsVar } from '../../../api/apollo/reactiveVars';
import { getCategoriesInput } from '../../../hooks/previewUser';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { isValidEmail } from '../../../utilities/string';
import useCreateCollaborators from '../../Collaborators/hooks/CreateCollaboratorsHook';
import TradesMultiSelectPopover from '../../dragon-scales/TradesMultiSelectPopover/TradesMultiSelectPopover';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Select,
  TextArea,
  TextInput,
} from '../../scales';

type CategoryReference = { id: UUID; categorizationID: UUID };
export const INVITE_TEAMMATES = 'Invite Teammates';

type CollaboratorRow = {
  email: string;
  user?: User;
  id: string;
  // TODO DD-741 -- shouldn't refer to PreviewVars
  trades?: PreviewVars['previewTrades'];
  role?: Role;
  allTrades?: boolean;
  responsibility: string;
};

const filterMapCollaborators = (
  rows: CollaboratorRow[],
  role: Role,
  allTrades: boolean,
  trades: CategoryReference[]
) =>
  rows
    .filter(
      // after this filter, we know for sure that the email property exists
      (row): row is CollaboratorRow & Required<Pick<CollaboratorRow, 'email'>> =>
        !!row.email && row.email.includes('@')
    )
    .map((row) => ({
      userEmail: row.email,
      allTrades,
      roleName: role.name,
      responsibility: row.responsibility,
      trades,
    }));

const allEmailsValid = (collaboratorRows: CollaboratorRow[]) =>
  collaboratorRows.every(
    (row: CollaboratorRow) =>
      isValidEmail(row.email ? row.email : '') ||
      ((row.email === undefined || row.email === '') && collaboratorRows.length > 1)
  );

const createDefaultRow = () => ({
  allTrades: true,
  email: '',
  id: Date.now().toString(),
  responsibility: '',
  trades: [],
});

type Props = {
  onClose: () => void;
};

export default function DialogsAddCollaborator(props: Props) {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  const [rows, setRows] = useState<CollaboratorRow[]>([createDefaultRow()]);
  const [message, setMessage] = useState<string>('');
  const [role, setRole] = useState<Role>({
    id: `${Date.now()}`,
    name: '',
    hasTrade: false,
    permissionGroups: [],
  });

  const { previewUserId, previewTrades, previewRoleId, previewAllTrades } = usePreviewSettingsVar();
  const [updatedTrades, setUpdatedTrades] = useState<{ id: UUID; categorizationID: UUID }[]>(
    getCategoriesInput(previewTrades) ?? []
  );
  const [isAllTrades, setIsAllTrades] = useState<boolean>(previewAllTrades ?? true);

  // Check if we're previewing as a particular user.
  // If we are, pull their role/trades and set it as the default role/trades.
  useCollaboratorsQuery(projectId, {
    onCompleted: (data) => {
      const collaborator = data.collaborators.find((c) => previewUserId === c.user.id);
      if (collaborator) {
        setRole(collaborator.role);
        setUpdatedTrades(getCategoriesInput(collaborator.trades) ?? []);
        setIsAllTrades(collaborator.allTrades);
      }
    },
  });

  const roles =
    useRolesQuery(projectId, MountPolicy.SKIP_ON_MOUNT, {
      onCompleted: (data) => {
        // Check if we're previewing as a particular role.
        // If we are, set it as the default role.
        if (!previewRoleId) return;

        const previewedRole = data.projectRoles.find((r) => r.id === previewRoleId);
        if (previewedRole) {
          setRole(previewedRole);
          setUpdatedTrades(getCategoriesInput(previewTrades) ?? []);
          setIsAllTrades(Boolean(previewAllTrades));
        }
      },
    }).data?.projectRoles ?? [];

  const [createCollaborators] = useCreateCollaborators();
  const onSubmit = () => {
    const collaboratorsInput = filterMapCollaborators(rows, role, isAllTrades, updatedTrades);
    createCollaborators(projectId, collaboratorsInput, message);
    props.onClose();

    // we clear rows after submitting.
    setRows([createDefaultRow()]);
    setRole({
      id: '',
      name: '',
      hasTrade: false,
      permissionGroups: [],
    });
    setIsAllTrades(true);
    setUpdatedTrades([]);
  };

  const updateRow = (i: number, update: Partial<CollaboratorRow>) => {
    setRows((prevState) => {
      const newState = [...prevState];
      newState[i] = { ...newState[i], ...update };
      return newState;
    });
  };

  const addRow = () => {
    setRows((prevState) => [...prevState, createDefaultRow()]);
  };

  const removeRow = (i: number) => {
    setRows((prevState) => {
      const newState = [...prevState];
      newState.splice(i, 1);
      return newState;
    });
  };

  return (
    <Dialog
      data-cy="add-collaborator-dialog"
      isOpen
      onClose={props.onClose}
      size="lg"
      title="Invite New Teammates"
      footerRight={
        <Button
          data-cy="add-collaborators-submit-button"
          disabled={!role.name || !allEmailsValid(rows)}
          label={INVITE_TEAMMATES}
          onClick={onSubmit}
          type="primary"
        />
      }
    >
      <DialogContent className="flex flex-col gap-4">
        <div className="flex items-end gap-4">
          <div className="w-75">
            <Select
              data-cy="add-collaborators-select-role"
              entries={roles.map((r) => ({ id: r.id, label: r.name }))}
              label="Role *"
              onChange={(roleID) => {
                const newRole = roles.find(({ id }) => roleID === id);
                if (newRole) setRole(newRole);
              }}
              placeholder="Select role"
              value={role.id}
            />
          </div>
          {role.hasTrade && (
            <TradesMultiSelectPopover
              defaultValue={{
                categories: updatedTrades,
                isAllSelected: isAllTrades,
              }}
              onChange={(trades) => {
                setUpdatedTrades(trades.categories);
                setIsAllTrades(trades.isAllSelected);
              }}
              projectID={projectId}
            />
          )}
        </div>
        <div className="grid grid-cols-[1fr_1fr_min-content] gap-2">
          <div className="type-body3">Email *</div>
          <div className="type-body3">Project Responsibilites</div>
          <div />
          {rows.map((row, i) => (
            <Fragment key={row.id}>
              <TextInput
                aria-label="email"
                autoFocus
                data-cy="add-collaborators-email-text-input"
                errorMessage={
                  row.email && !isValidEmail(row.email) ? 'Invalid email address' : undefined
                }
                onChange={(email) => updateRow(i, { email })}
                onKeyDown={(e) => e.key === 'Enter' && addRow()}
                placeholder="email@example.com"
                value={row.email}
              />
              <TextInput
                aria-label="project responsibilities"
                onChange={(responsibility) => updateRow(i, { responsibility })}
                onKeyDown={(e) => e.key === 'Enter' && addRow()}
                placeholder="e.g., precon team, subcontractor"
                value={row.responsibility}
              />
              <div className="self-start">
                {i === rows.length - 1 ? (
                  <IconButton
                    aria-label="add row"
                    icon={<KeyboardReturn />}
                    onClick={addRow}
                    type="secondary"
                  />
                ) : (
                  <IconButton
                    aria-label="remove row"
                    icon={<Close />}
                    onClick={() => removeRow(i)}
                    type="secondary"
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>
        <TextArea
          label="Include a message with your invitation"
          onChange={setMessage}
          value={message}
        />
      </DialogContent>
    </Dialog>
  );
}
