import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    arrow: {
      width: 30,
      height: 30,
      padding: '24px 6px',
    },
    horizontal: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    inlineText: {
      ...theme.typography.header,
      display: 'inline',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
    },
    price: {
      ...theme.typography.number,
      fontFamily: theme.typography.fontFamily,
    },
    priceContainer: {
      textAlign: 'center',
    },
    paddingTop: {
      paddingTop: 24,
    },
    text: {
      ...theme.typography.cardHeader,
      fontFamily: theme.typography.fontFamily,
      marginTop: 0,
    },
    titleBold: {
      ...theme.typography.header,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
  });
