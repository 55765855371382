import { useMutation } from '@apollo/client';

import { changeEventFields, gql } from '../api/graphqlFragments';
import { REFETCH_ITEM_INTEGRATIONS } from '../api/refetchSets';
import {
  CreateChangeEventInput,
  CreateLinkedChangeEventMutation,
  CreateLinkedChangeEventMutationOptions,
  CreateLinkedChangeEventMutationVariables,
  SourceSystem,
} from '../generated/graphql';

const createLinkedChangeEventMutation = gql`
  mutation CreateLinkedChangeEvent(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $sourceProjectID: String!
    $sourceCompanyID: String!
    $itemID: UUID!
    $input: CreateChangeEventInput!
  ) {
    createLinkedChangeEvent(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      sourceProjectID: $sourceProjectID
      sourceCompanyID: $sourceCompanyID
      itemID: $itemID
      input: $input
    ) {
      changeEvent {
        ...changeEventFields
      }
      error
    }
  }
  ${changeEventFields}
`;

export const useCreateLinkedChangeEventMutation = (
  options?: CreateLinkedChangeEventMutationOptions
) => {
  const [mutationFunc, mutationResult] = useMutation<
    CreateLinkedChangeEventMutation,
    CreateLinkedChangeEventMutationVariables
  >(createLinkedChangeEventMutation, options);
  return [
    (
      sourceSystem: SourceSystem,
      sourceID: UUID,
      sourceProjectID: string,
      sourceCompanyID: string,
      itemID: UUID,
      input: CreateChangeEventInput
    ) => {
      return mutationFunc({
        variables: { sourceSystem, sourceID, sourceProjectID, sourceCompanyID, itemID, input },
        refetchQueries: REFETCH_ITEM_INTEGRATIONS,
      });
    },
    mutationResult,
  ] as const;
};
