import { ComponentProps } from 'react';

import { useLocalStorageParams } from '../../../utilities/urlState';

import LibraryOpen from './LibraryOpen';
import SidebarCollapseButton from './SidebarCollapseButton';

type Props = {
  onAddToAllScenarios: ComponentProps<typeof LibraryOpen>['onAddToAllScenarios'];
  onCreateScenario: ComponentProps<typeof LibraryOpen>['onCreateScenario'];
  onAddToScenario: ComponentProps<typeof LibraryOpen>['onAddToScenario'];
  sandbox: ComponentProps<typeof LibraryOpen>['sandbox'];
};

enum View {
  ITEMS = 'Items',
  SCENARIOS = 'Scenarios',
}

type LibrarySettings = {
  view: View;
  collapsed: boolean;
};

const Library = (props: Props) => {
  // State
  const [settings, setSettings] = useLocalStorageParams<LibrarySettings>(
    { view: View.ITEMS, collapsed: false },
    `Scenarios-Library-`
  );

  if (settings.collapsed)
    return (
      <div className="p-2 pl-4">
        <SidebarCollapseButton
          isClosed={settings.collapsed}
          onClick={() => setSettings({ ...settings, collapsed: !settings.collapsed })}
        />
      </div>
    );

  return <LibraryOpen {...props} settings={settings} setSettings={setSettings} />;
};

export default Library;
