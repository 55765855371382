/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import { CreationMethod, GridAnalytics, GridVariant } from '../../../actions/gridAnalytics';
import { TermKey } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import {
  DECIMAL_CELL,
  NUMBER_CELL,
  SOURCE,
  TOTAL,
  UNIT_PRICE,
  USCENTS_CELL,
} from '../../../constants';
import { Scale } from '../../../enums';
import { EstimateTotalType } from '../../../generated/graphql';
import {
  addCurrencySeparatorWithZeros,
  getDecimalSeparator,
  getThousandSeparator,
  isNegative,
} from '../../../utilities/currency';
import { removeThousandsSeparator, removeYear } from '../../../utilities/string';
import {
  SetEstimateTotalTypeMutationResult,
  createColumns,
  createLines,
  deleteColumns,
  deleteLines,
  estimateMutation,
  reorderColumn,
  replaceFieldCategory,
  setEstimateTotalType,
} from '../hooks/estimateMutation';
import { getEstimateLineIDs, getEstimateLines } from '../hooks/estimateQuery';
import { TABLE_BODY_HEIGHT } from '../style/styleConstants';
import { CellData, Column, EstimateGridState, GridData, PaginationInput, Position } from '../types';
import {
  affectsCosts,
  compareValues,
  getEmptyEstimateLine,
  getFilledEstimateLine,
  isCurrencyField,
  isRegularCell,
  postprocessCost,
  serializeAPICell,
} from '../utilities/cell';
import {
  addColumnErrors,
  addColumnsErrors,
  addColumnsResolutionErrors,
  areEqualErrors,
} from '../utilities/data';
import { getOrderingData } from '../utilities/drag';
import { isFormula } from '../utilities/formula';
import range from '../utilities/range';

import {
  addLinesToTable,
  asynchronousPaste,
  getCellAPIValue,
  newCellRow,
  newCellState,
  newGenericGridState,
  scrollToBottom,
  updateCell,
  updateCellIndexed,
  updateSubtotal,
  updateTableWidth,
  withGridReflow,
} from './editing';
import { getCellData, setSelectionRange } from './selecting';
import { newSizingState, normalizeRectangle } from './sizing';

export const newEstimateGridState = (
  estimate: GridEstimate,
  errors: ImportEstimateError[],
  readOnly: boolean,
  analytics: GridAnalytics,
  maxWidth: number | undefined,
  projectID: UUID,
  sortBy: EstimateSortBy | undefined,
  variant: GridVariant,
  viewFilter: ViewFilterInput | undefined,
  updateCostReports: () => void,
  replaceMarkups: (markups: Markup[], newSubtotal: number) => void,
  termStore: TermStore,
  updateInheritedMarkupsController?: (markups: Markup[], newSubtotal: number) => void,
  updateIncorporatedMarkupsController?: (markups: Markup[], newSubtotal: number) => void,
  updateIncorporatedDrawsController?: (markups: Markup[], newSubtotal: number) => void,
  refetch?: () => void,
  quantity?: QuantityInfo
): EstimateGridState => {
  const columns = estimate.fields;
  const { lines } = estimate;
  const hasMarkupCheckboxButton = false;
  addColumnsResolutionErrors(columns, errors);
  addColumnsErrors(columns, lines?.length || 0);
  const data: GridData = {
    columns,
    lines: lines || [],
    readOnly,
    hasMarkupCheckboxButton,
  };

  // Set the description field to always enable editing.
  data.columns.forEach((column: Column, index: number) => {
    if (
      (column.name === 'Description' && column.type === FieldType.STRING) ||
      (column.name === TOTAL &&
        (isCurrencyField(column.type) || column.type === FieldType.DECIMAL) &&
        index === columns.length - 1)
    ) {
      // eslint-disable-next-line no-param-reassign
      column.hasColumnMenu = true;
    }
    if (column.name === SOURCE) {
      // TODO: add SOURCE column type
      column.type = SOURCE;
      column.group = SOURCE;
    }
  });

  if (lines?.[0]) {
    data.lines.forEach((line) => {
      line.cells.push({
        value: {},
      });
    });
  }

  const state = newGenericGridState(data, TABLE_BODY_HEIGHT, maxWidth, updateCostReports, variant);
  const subtotal: CellData = {
    data: newCellState(USCENTS_CELL, {
      value: { string: String(estimate.subtotal), formula: '', formulaDisplay: [] },
    }),
    dom: null,
  };

  const estimateTermKey =
    state.variant === GridVariant.MILESTONE_BUDGET ? TermKey.TARGET : TermKey.ESTIMATE;
  const estimateTerm = termStore.titleCase(estimateTermKey);

  return {
    ...state,
    projectID,
    estimateID: estimate.id,
    quantity,
    subtotal,
    analytics,
    sortBy,
    viewFilter,
    replaceMarkups,
    updateInheritedMarkupsController: (m, t) => {
      if (updateInheritedMarkupsController) updateInheritedMarkupsController(m, t);
    },
    updateIncorporatedMarkupsController: (m, t) => {
      if (updateIncorporatedMarkupsController) updateIncorporatedMarkupsController(m, t);
    },
    updateIncorporatedDrawsController: (m, t) => {
      if (updateIncorporatedDrawsController) updateIncorporatedDrawsController(m, t);
    },
    refetch,
    totalType: estimate.totalType,
    variant,
    estimateTerm,
  };
};

const applyEstimateUpdate = (
  state: EstimateGridState,
  cells: IndexedGridCell[] = [],
  updatedColumnsMap: Map<number, null>,
  updatedColumns: Column[] = []
) => {
  updatedColumns.forEach((c) => {
    const column = state.data.columns.find(({ id }) => id === c.id);
    if (!column) return;
    if (!areEqualErrors(column.errors, c.errors)) {
      column.errors = c.errors;
      addColumnErrors(column, state.data.lines.length, true);
    }
  });

  // Analytics
  const {
    analytics: { updateCellsAnalytics },
  } = state;
  let numChanged = 0;
  let includesCategoryCells = false;
  const updatedFieldNames: string[] = [];
  const setAnalyticsData = (column: Column) => {
    // get the name of the field for analytics
    const { name, categorization } = column;
    includesCategoryCells = includesCategoryCells || Boolean(categorization);
    if (categorization) {
      updatedFieldNames.push(removeYear(categorization.name));
    } else {
      updatedFieldNames.push(name);
    }
  };

  cells.forEach((c) => {
    const [changed, col] = updateCell(state, c);
    if (changed) {
      numChanged += 1; // analytics
      updatedColumnsMap.set(col, null);
    }
  });

  const updatedColumnIndicies = Array.from(updatedColumnsMap.keys());
  updatedColumnIndicies.forEach((i) => {
    const column = state.data.columns[i];
    setAnalyticsData(column);
  });

  updateCellsAnalytics({ cells: numChanged, fields: updatedFieldNames, includesCategoryCells });
};

export const mutateEstimate = (
  state: EstimateGridState,
  start: Position,
  end: Position,
  values: (GridCellValue | undefined)[][]
) => {
  const { estimateID, data, sortBy, viewFilter } = state;
  const [s, e] = normalizeRectangle(state, start, end);
  // We don't know what the IDs are, so we don't mutate.
  if (!data) {
    return;
  }

  // we need to check if all the lines are loaded before
  // attempting to modify the estimate.  If a user pastes
  // more lines than are loaded in the estimate then
  // we need to load the ids for any unloaded lines
  let linesAreLoaded = true;
  const lineIDs: UUID[] = [];
  for (let i = s.row; i <= e.row; i += 1) {
    if (!data.lines[i]) {
      linesAreLoaded = false;
      break;
    }
    lineIDs.push(data.lines[i].id);
  }

  if (!linesAreLoaded) {
    getEstimateLineIDs(estimateID, sortBy, viewFilter, (result) =>
      asyncMutateEstimate(state, result.data?.estimate?.lineIDs || [], s, e, values)
    );
  } else {
    asyncMutateEstimate(state, lineIDs, s, e, values);
  }
};

export const asyncMutateEstimate = (
  state: EstimateGridState,
  lineIDs: UUID[],
  s: { row: number; column: number },
  e: { row: number; column: number },
  values: (GridCellValue | undefined)[][]
) => {
  const {
    estimateID,
    data,
    quantity,
    mutationHistory,
    projectID,
    replaceMarkups,
    updateInheritedMarkupsController,
    updateCostReports,
    refetch,
  } = state;
  // We don't know what the IDs are or the values are empty, so we don't mutate.
  if (!lineIDs || !lineIDs.length || !values) {
    return;
  }
  const cellInputs: CellInput[] = [];
  const currentCellValues: IndexedGridCell[] = [];
  const updatedColumnsMap = new Map<number, null>();
  let shouldUpdateCosts = false;
  withGridReflow(state, () => {
    for (let i = s.row; i <= e.row; i += 1) {
      for (let j = s.column; j <= e.column; j += 1) {
        if (
          (!isCellEditable(state, { row: i, column: j }) &&
            data.lines &&
            data.columns &&
            data.lines[i] &&
            data.columns[j]) ||
          !values.length
        )
          // eslint-disable-next-line no-continue
          continue;

        const line = data.lines[i] ? data.lines[i].id : lineIDs[i];
        const { id: field, type } = data.columns[j];
        let newValue = values[i - s.row][j - s.column];

        if (
          (type === FieldType.CURRENCY || type === FieldType.CURRENCY_9) &&
          isRegularCell(newValue) &&
          !isFormula(newValue.string) && // skip formulas
          !newValue.string.match(/[a-z]/i) // skip non-numeric
        ) {
          const currencySeparator = getDecimalSeparator(true);
          const thousandsSeparator = getThousandSeparator(true);
          // if the newValue does not contain a currency separator, we need to add one with two zeros
          if (!newValue.string.includes(currencySeparator)) {
            newValue.string = addCurrencySeparatorWithZeros(
              newValue,
              currencySeparator,
              thousandsSeparator
            );
          }
        }

        const currentData = getCellData(state, i, j);

        // we need to copy the cell value because we may modify it
        // in the next line
        const currentValue = { ...currentData?.data?.value };
        const currentError = currentData && currentData.data.error;
        const hasAnError = currentError && currentError.length > 0;
        const hasNoValue = 'search' in currentValue ? currentValue.search === '' : true;

        // Might be an empty string, so we check undefined directly.
        // We don't send updates if the value is identical to the previous one (no change)
        // update if hasAnError && hasNoValue, issue #6279
        if (
          (newValue !== undefined && !compareValues(type, newValue, currentValue)) ||
          (hasAnError && hasNoValue)
        ) {
          // remember which columns have been updated
          updatedColumnsMap.set(j, null);

          // process the value of the input for number and currency cells
          // we need to do this here, rather than in the cell editor
          // because pasting bypasses the cell editor
          newValue = newValue && processCellUpdates(type, newValue);

          // Don't send cost report updates for string cells
          shouldUpdateCosts = shouldUpdateCosts || affectsCosts(type);
          const value = serializeAPICell(type, newValue);
          cellInputs.push({ line, field, value });

          // if this cell contains a formula then the optimistic update
          // value should be empty.  We need to wait for the backend
          // to properly calcuate the value
          const optimisticValue =
            (isCurrencyField(type) || type === NUMBER_CELL || type === DECIMAL_CELL) &&
            isFormula(value)
              ? { string: '', formula: value, formulaDisplay: [] }
              : newValue;

          // Optimistically update the cell. This doesn't do anything to the row heights.
          currentCellValues.push({ line, field, value: currentValue });
          updateCellIndexed(state, i, j, { value: optimisticValue });
        }
      }
    }
  });

  if (cellInputs.length > 0) {
    estimateMutation(projectID, estimateID, cellInputs, quantity, (result) => {
      if (result.data) {
        const { cells, estimateUpdateResult } = result.data.updateCells;
        const estimate = estimateUpdateResult?.estimate;

        mutationHistory.push(currentCellValues);
        withGridReflow(state, () => {
          applyEstimateUpdate(state, cells, updatedColumnsMap, estimate?.fields);
        });

        if (shouldUpdateCosts) {
          // update the markup tables after the estimate table if we have incorporated markups
          if (
            estimate &&
            'incorporatedMarkups' in estimate &&
            estimate.incorporatedMarkups.length > 0
          ) {
            if (refetch) refetch();
          }
          if (estimate?.subtotal) updateSubtotal(state, estimate.subtotal);
          if (!quantity && estimate && 'markups' in estimate) {
            replaceMarkups(estimate.markups, estimate.markupSubtotal);
            updateInheritedMarkupsController(estimate.inheritedMarkups, estimate.inheritedSubtotal);
          }
          updateCostReports();
        }
      } else {
        // Something went wrong, don't keep the dirty values.
        undoLastMutation(state);
      }
    });
  }
};

const processCellUpdates = (columnType: string, value: GridCellValue): GridCellValue => {
  // strip out any commas, and check if this is a number
  // if it's not a number, then leave it as-is
  // numbers in parentheses are negative
  const string = 'string' in value ? value.string : '';
  if (isFormula(string)) return value;
  // Currency
  if (columnType === NUMBER_CELL || columnType === DECIMAL_CELL) {
    return { string: processNumberCell(string), formula: '', formulaDisplay: [] };
  }
  if (columnType === USCENTS_CELL) {
    return { string: postprocessCost(string), formula: '', formulaDisplay: [] };
  }
  if (columnType === FieldType.CURRENCY_9) {
    return { string: postprocessCost(string, Scale.CURRENCY_9), formula: '', formulaDisplay: [] };
  }
  return value;
};

const processNewCells = (columnType: string, value: string) => {
  // strip out any commas, and check if this is a number
  // if it's not a number, then leave it as-is
  // numbers in parentheses are negative
  // Currency
  switch (columnType) {
    case FieldType.NUMBER:
    case FieldType.DECIMAL:
      return processNumberCell(value);
    case FieldType.CURRENCY:
      return postprocessCost(value);
    case FieldType.CURRENCY_9:
      return postprocessCost(value, Scale.CURRENCY_9);
    default:
      return value.trim();
  }
};

const processNumberCell = (string: string) => {
  const parsed = isNegative(string) ? string.replace('(', '-').replace(')', '') : string;
  let deCommafied = removeThousandsSeparator(parsed, false);

  // if the decimal separator is not a period we will replace that too
  const decimalSeparator = getDecimalSeparator(false);
  if (decimalSeparator !== '.' && deCommafied.includes(decimalSeparator)) {
    deCommafied = deCommafied.replace(decimalSeparator, '.');
  }

  if (!Number.isNaN(deCommafied)) {
    return deCommafied;
  }
  return string;
};

export const replaceCategory = (
  state: EstimateGridState,
  fieldID: UUID,
  oldValue?: string,
  newValue?: UUID
) => {
  const { estimateID, projectID } = state;
  const updatedColumnsMap = new Map<number, null>();

  if (newValue && oldValue) {
    replaceFieldCategory(projectID, estimateID, fieldID, oldValue, newValue, (result) => {
      if (result.data) {
        const { cells } = result.data.replaceFieldCategories;
        const columns = result.data.replaceFieldCategories.estimateUpdateResult?.estimate?.fields;
        withGridReflow(state, () => applyEstimateUpdate(state, cells, updatedColumnsMap, columns));
      } else {
        // Something went wrong, don't keep the dirty values.
        undoLastMutation(state);
      }
    });
  }
};

// Create however many new lines and fill them
export const addEstimateLines = (
  state: EstimateGridState,
  method: CreationMethod,
  n: number,
  offset: number,
  input?: (GridCellValue | undefined)[][],
  onSuccess?: () => void
) => {
  const {
    projectID,
    estimateID,
    data,
    quantity,
    replaceMarkups,
    updateInheritedMarkupsController,
    updateCostReports,
    analytics: { createLinesAnalytics },
  } = state;

  const fields = data.columns.map((f) => f.id);
  const fieldTypes = data.columns.map((f) => f.type);
  let values: string[][] = [];
  if (input) {
    values = input.map((v) => getFilledEstimateLine(data, v, offset));
  } else {
    const empties = getEmptyEstimateLine(data);
    values = range(0, n, () => [...empties]);
  }
  const lines: LineInput[] = [];
  for (let i = 0; i < Math.min(n, values.length); i += 1) {
    for (let j = 0; j < values[i].length; j += 1) {
      // process the value of the input for number and currency cells
      // we need to do this here, rather than in the cell editor
      // because pasting bypasses the cell editor
      values[i][j] = processNewCells(fieldTypes[j], values[i][j]);
    }
    lines.push({ fields, values: values[i] });
  }

  // Send off the mutation
  createLines(projectID, estimateID, lines, quantity, (result) => {
    if (result.data) {
      const { lines, estimateUpdateResult } = result.data.createLines;
      const estimate = estimateUpdateResult?.estimate;

      // update the errors
      if (estimate?.fields)
        estimate.fields.forEach((f, i) => {
          if (state.data.columns[i]) state.data.columns[i].errors = f.errors;
        });

      addLinesToTable(state, lines);
      if (!quantity && estimate && 'markups' in estimate) {
        replaceMarkups(estimate.markups, estimate.markupSubtotal);
        updateInheritedMarkupsController(estimate.inheritedMarkups, estimate.inheritedSubtotal);
      }
      if (estimate?.subtotal) updateSubtotal(state, estimate.subtotal);
      addColumnsErrors(state.data.columns, state.data.lines.length, true);

      // Don't send an update if we just added an empty line
      if (input) updateCostReports();
      if (onSuccess) onSuccess();
      createLinesAnalytics({ createdLines: lines.length, source: method });
    }
  });
};

export const addEstimateLine = (
  state: EstimateGridState,
  method: CreationMethod,
  onSuccess?: () => void
) => addEstimateLines(state, method, 1, 0, undefined, onSuccess);

export const addCategorizedMetricLines = (
  state: EstimateGridState,
  categories: GridCategoryCellInputs[][],
  refetch: () => void
) => {
  const input: GridCellValue[][] = categories.map((combo) => [...combo, {}]); // include 0 value total cell
  return addEstimateLines(state, 'Button', input.length, 0, input, () => {
    scrollToBottom(state);
    refetch();
  });
};

export const deleteEstimateLines = (state: EstimateGridState) => {
  const { estimateID, sortBy, viewFilter } = state;
  getEstimateLineIDs(estimateID, sortBy, viewFilter, (result) =>
    deleteSelectedEstimateLines(state, result.data?.estimate?.lineIDs || [])
  );
};

export const deleteSelectedEstimateLines = (state: EstimateGridState, lineIDs: UUID[]) => {
  const {
    projectID,
    estimateID,
    isRowSelectedArr,
    quantity,
    updateCostReports,
    refetch,
    analytics: { removeLineAnalytics },
  } = state;

  const removeLineIDs = lineIDs.filter((_, i) => isRowSelectedArr[i]);
  if (lineIDs.length === 0) return;

  // Easy: Clear undo buffer on an incompatible operation.
  // TODO: When buffing up undo/redo post-launch, we can
  // either add this delete line action to the undo buffer
  // or we can iterate through the mutation history and
  // remove any cells from undos that touch this line.
  state.mutationHistory = [];
  deleteLines(projectID, estimateID, removeLineIDs, quantity, (result) => {
    if (result.data) {
      if (refetch) refetch();
      updateCostReports();
      removeLineAnalytics({ estimateLength: removeLineIDs.length });
    }
  });
};

export const addColumns = (state: EstimateGridState, fieldInputs: FieldInput[]) => {
  const {
    projectID,
    estimateID,
    data,
    analytics: { addColumnAnalytics },
    refetch,
  } = state;

  createColumns(projectID, estimateID, fieldInputs, () => {
    if (refetch) refetch();
    addColumnAnalytics({ columns: data.columns.length });
  });
};

export const moveColumn = (state: EstimateGridState, currentIndex: number, futureIndex: number) => {
  const {
    analytics: { moveColumnAnalytics },
    cellData,
    data,
    estimateID,
    indexMap,
    projectID,
    refetch,
  } = state;
  const currentColumnID = data.columns[currentIndex].id;
  const futureOrdering = futureIndex + 1;
  withGridReflow(state, () => {
    [data.columns[currentIndex], data.columns[futureIndex]] = [
      data.columns[futureIndex],
      data.columns[currentIndex],
    ];
    indexMap[data.columns[currentIndex].id] = currentIndex;
    indexMap[data.columns[futureIndex].id] = futureIndex;
    for (let i = 0; i < data.lines.length; i += 1) {
      const lineOfCells = cellData[i];
      if (lineOfCells) {
        [lineOfCells[currentIndex], lineOfCells[futureIndex]] = [
          lineOfCells[futureIndex],
          lineOfCells[currentIndex],
        ];
      }
      const line = data.lines[i];
      if (line) {
        const { cells } = line;
        [cells[currentIndex], cells[futureIndex]] = [cells[futureIndex], cells[currentIndex]];
      }
    }
    state.updateTable();
  });

  reorderColumn(projectID, estimateID, currentColumnID, futureOrdering, (result) => {
    if (result.data) {
      if (refetch) refetch();
      moveColumnAnalytics();
    }
  });
};

// Removes a given field.
// TODO: We don't need data coming back from this, we just need a confirmation
// that it actually succeeded in removing the column server-side (or that the
// column is at least, now, removed, in the case of a duplicate request).
export const removeColumns = (state: EstimateGridState, columnIDs: UUID[]) => {
  const {
    projectID,
    estimateID,
    indexMap,
    cellData,
    data,
    analytics: { removeColumnAnalytics },
    refetch,
  } = state;
  state.mutationHistory = [];

  const indicesToRemove = data.columns.reduce(
    (indices: number[], column: Column, currentIndex: number) => {
      if (columnIDs.includes(column.id)) indices.push(currentIndex);
      return indices;
    },
    []
  );
  if (indicesToRemove.length === 0) return;
  // Sort in ascending order.
  indicesToRemove.sort();

  // Check if the indices are consecutive.
  let isConsecutive = true;
  for (let i = 0; i < indicesToRemove.length - 1; i += 1) {
    if (indicesToRemove[i + 1] - indicesToRemove[i] !== 1) isConsecutive = false;
  }
  if (!isConsecutive) return;

  // Optimistically perform the update.
  withGridReflow(state, () => {
    // Reset the selection.
    setSelectionRange(state, { row: -1, column: -1 }, { row: -1, column: -1 });

    data.columns.splice(indicesToRemove[0], indicesToRemove.length);
    for (let j = indicesToRemove[0]; j < data.columns.length; j += 1) {
      indexMap[data.columns[j].id] = j;
    }
    for (let i = 0; i < data.lines.length; i += 1) {
      const line = data.lines[i];
      if (line) {
        data.lines[i].cells.splice(indicesToRemove[0], indicesToRemove.length);
      }
      const lineOfCells = cellData[i];
      if (lineOfCells) {
        lineOfCells.splice(indicesToRemove[0], indicesToRemove.length);
      }
    }

    // If we're down to just the total column, enable the menu.
    if (data.columns.length === 1) {
      data.columns[0].hasColumnMenu = true;
    }
    updateTableWidth(state);
  });

  deleteColumns(projectID, estimateID, columnIDs, (result) => {
    if (result.data) {
      if (refetch) refetch();
      removeColumnAnalytics({ columns: data.columns.length });
    }
  });
};

export const setTotalType = (state: EstimateGridState, estimateTotalType: EstimateTotalType) => {
  const {
    projectID,
    estimateID,
    analytics: { setTotalTypeAnalytics },
    refetch,
  } = state;
  setEstimateTotalType(
    projectID,
    estimateID,
    estimateTotalType,
    (result: SetEstimateTotalTypeMutationResult) => {
      if (result.data) {
        if (refetch) refetch();
        setTotalTypeAnalytics({
          oldTotalType: state.totalType ?? '',
          newTotalType: estimateTotalType,
        });
      }
    }
  );
};

export const fetchMoreEstimate = (
  state: EstimateGridState,
  varsObj: PaginationInput,
  cb?: () => void
) => {
  const {
    estimateID,
    data,
    sortBy,
    viewFilter,
    cellData,
    indexMap,
    orderingData,
    linesCallParams,
  } = state;
  const { lines } = data;

  const {
    variables: { pagination },
  } = varsObj;
  const { offset, limit } = pagination;
  getEstimateLines(estimateID, sortBy, viewFilter, pagination, (result) => {
    const page = ((result.data || {}).estimate || {}).lines || [];
    page.forEach((line) => {
      const placeholderLine: Line['cells'][number] = {
        value: {},
      };
      line.cells.push(placeholderLine);
    });
    for (
      let index = offset, i = 0;
      index < offset + limit && index < lines.length && i < page.length;
      index += 1, i += 1
    ) {
      const line = page[i];
      lines[index] = line;
      cellData[index] = newCellRow(data, line);
      indexMap[(line || {}).id] = index;
      orderingData[index] = getOrderingData(line);
    }

    if (linesCallParams?.offset === offset) {
      const { maxHeight, visibleWidth } = state;
      newSizingState(data, maxHeight, visibleWidth, state);
      state.updateTable();
    }
    if (cb) cb();
  });
};

// Currently, we allow any cell that isn't the total cell or Source Column cell of an estimate with a unit price column to be editable.
export const isCellEditable = (state: EstimateGridState, p: Position) => {
  const { data, totalType } = state;
  if (p.column < 0 || p.column >= data.columns.length) return false;
  const field = data.columns[p.column];
  if (field.type === SOURCE) return false;
  if (totalType === EstimateTotalType.TOTAL_TYPE_UNIT) {
    // Total cost is not editable.
    if (field.name === TOTAL && field.type === FieldType.CURRENCY) return false;
  }
  if (totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES) {
    // Total cost and total unit price are not editable.
    if (field.name === TOTAL && field.type === FieldType.CURRENCY) return false;
    if (field.name === UNIT_PRICE && isCurrencyField(field.type) && field.group === '')
      return false;
  }
  return true;
};

// Paste the copied range into the cells, starting from this cell at the upper left.
export const pasteClipboard = (state: EstimateGridState, start: Position) =>
  asynchronousPaste(
    state,
    start,
    mutateEstimate,
    (s, method, n, i) =>
      addEstimateLines(s, method, n, start.column, i, () => scrollToBottom(state)),
    state.estimateTerm
  );

export const undoLastMutation = (state: EstimateGridState) => {
  const {
    data,
    mutationHistory,
    estimateID,
    projectID,
    quantity,
    replaceMarkups,
    updateInheritedMarkupsController,
    updateCostReports,
  } = state;

  const lastCells = mutationHistory.pop();
  if (lastCells) {
    const updatedColumnsMap = new Map<number, null>();
    const cells = lastCells.map((c) => {
      let categoryCellValue: GridCategoryCellInputs | undefined;
      if (c.value && 'category' in c.value && c.value.category) {
        categoryCellValue = { id: c.value.category.id, search: c.value.category.number };
      } else if (c.value && 'search' in c.value) {
        categoryCellValue = { search: c.value.search };
      }
      const j = data.columns.findIndex(({ id }) => id === c.field);
      updatedColumnsMap.set(j, null);

      // for category cells the function getCellAPIValue
      // returns a json string of a the cell category eg {category: {id, name, etc...}}
      // however, for category cells we now use a simplified representation
      // that only includes what we need, eg { id, name, number, search }
      // since getCellAPIValue is used elsewhere we need to convert categoryCellValue to json here
      const value = categoryCellValue
        ? JSON.stringify(categoryCellValue)
        : getCellAPIValue(state, c);
      return {
        field: c.field,
        line: c.line,
        value,
      };
    });
    if (cells.length > 0) {
      estimateMutation(projectID, estimateID, cells, quantity, (result) => {
        if (result.data) {
          const { cells, estimateUpdateResult } = result.data.updateCells;
          const estimate = estimateUpdateResult?.estimate;

          withGridReflow(state, () =>
            applyEstimateUpdate(state, cells, updatedColumnsMap, estimate?.fields)
          );
          if (estimate && 'markups' in estimate) {
            replaceMarkups(estimate.markups, estimate.markupSubtotal);
            updateInheritedMarkupsController(estimate.inheritedMarkups, estimate.inheritedSubtotal);
          }
          if (estimate?.subtotal) updateSubtotal(state, estimate.subtotal);
          addColumnsErrors(state.data.columns, state.data.lines.length, true);
          // Always refetch on undo, if there is indeed anything to refetch.
          updateCostReports();
        }
      });
    }
  }
};
