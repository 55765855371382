import { FC, useEffect, useRef, useState } from 'react';

import { TextField } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import EventIcon from '@material-ui/icons/Event';

import { TimelineActivityType } from '../../api/gqlEnumsBe';
import { CREATE, DELETE, EDIT } from '../../constants';
import { TimelineActivityInput, TimelineQuery } from '../../generated/graphql';
import { withStyles } from '../../theme/komodo-mui-theme';
import { getDateString, parseDate, preProcessDay } from '../../utilities/dates';
import { getProjectIdFromUrl } from '../../utilities/url';
import { ActionButton } from '../Dialogs/JoinDialog/JoinDialogUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import { Button, Checkbox, Dialog, DialogContent } from '../scales';
import JoinSelect, { SelectEntry, getEntry } from '../Select/JoinSelect/JoinSelect';

import { useCreateTimelineActivity } from './hooks/CreateTimelineActivityHook';
import { useDeleteTimelineActivity } from './hooks/DeleteTimelineActivityHook';
import { useUpdateTimelineActivity } from './hooks/UpdateTimelineActivityHook';
import { ActivityNode } from './Timeline.types';
import TimelineDatePicker from './TimelineDatePicker';
import { styles } from './TimelineStyles';

type TimelineActivityModalProps = {
  classes: Classes<typeof styles>;
  type: 'add' | 'edit' | 'delete';
  types: TimelineActivityType[];
  open: boolean;
  activity?: ActivityNode;
  activities: TimelineQuery['timeline']['activities'];
  onClose: () => void;
};

const TimelineActivityModal: FC<TimelineActivityModalProps> = ({
  classes,
  type,
  types,
  open,
  activity,
  activities,
  onClose,
}) => {
  const projectID = getProjectIdFromUrl();
  // Parses date string into date object
  const getDate = (dateString: Date | string): Date =>
    new Date(getDateString(preProcessDay(new Date(dateString))));

  // Generates a date object that is at least a day after the start date
  const genMinDate = () => {
    const minDate = new Date();
    minDate.setDate(startDate.getDate() + 1);
    return minDate;
  };

  // state
  const [activityName, setActivityName] = useState<string>(activity?.name || '');
  const [eventType, setEventType] = useState<TimelineActivityType>(
    (activity?.type as TimelineActivityType) || TimelineActivityType.EVENT
  );
  const [startDate, setStartDate] = useState<Date>(
    activity ? getDate(activity?.startDate) : getDate(new Date())
  );
  const [endDate, setEndDate] = useState<Date | undefined>(() => {
    if (eventType === TimelineActivityType.EVENT) return undefined;
    if (activity?.endDate) return getDate(activity?.endDate);
    return genMinDate();
  });

  const [parentActivity, setParentActivity] = useState<TimelineActivity | undefined>(
    activities.find((a) => a?.children?.find((c) => c === activity?.id))
  );

  const [isCreateAnother, setIsCreateAnother] = useState<boolean>(false);

  // hooks
  const [createTimelineActivity] = useCreateTimelineActivity();
  const [updateTimelineActivity] = useUpdateTimelineActivity();
  const [deleteTimelineActivity] = useDeleteTimelineActivity();

  const prevActivityRef = useRef<ActivityNode | undefined>(activity);

  useEffect(() => {
    // Bumps end date to be at least a day after start date
    if (
      (eventType === TimelineActivityType.PHASE && endDate && endDate <= startDate) ||
      (eventType === TimelineActivityType.PHASE && !endDate)
    ) {
      setEndDate(genMinDate());
    }

    // Updates the modal inputs when the activity changes
    if (activity && prevActivityRef.current !== activity) {
      setActivityName(activity.name);
      setEventType(activity.type);
      setStartDate(getDate(activity.startDate));
      if (activity.endDate) {
        setEndDate(getDate(activity.endDate));
      }
      setParentActivity(activities.find((a) => a?.children?.find((c) => c === activity?.id)));
      prevActivityRef.current = activity;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [startDate, eventType, activity]);

  // helpers

  // Ensures that the current activity has dates that fall within a given parent activity
  const isBelongsToValid = (a: TimelineActivity): boolean => {
    const parentStart = getDate(a.startDate);
    const parentEnd = a.endDate ? getDate(a.endDate) : undefined;
    const childStart = getDate(startDate);
    const childEnd = endDate ? getDate(endDate) : undefined;

    if (!parentEnd) return false;
    if (parentEnd < childStart) return false;
    if (childStart < parentStart) return false;
    if (childEnd && parentEnd && childEnd > parentEnd) return false;
    return true;
  };

  const generateActivityInput = (): TimelineActivityInput => ({
    name: activityName,
    type: eventType,
    startDate: getDate(startDate).toISOString(),
    endDate:
      eventType === TimelineActivityType.PHASE && endDate
        ? getDate(endDate).toISOString()
        : undefined,
    activityID: parentActivity?.type === TimelineActivityType.PHASE ? parentActivity.id : undefined,
    milestoneID:
      parentActivity?.type === TimelineActivityType.MILESTONE ||
      parentActivity?.type === TimelineActivityType.ACTIVE_MILESTONE
        ? parentActivity.id
        : undefined,
  });

  const clearInputs = () => {
    setActivityName('');
    setEventType(TimelineActivityType.EVENT);
    setStartDate(new Date());
    setEndDate(undefined);
    setParentActivity(undefined);
  };

  const actionButtons: ActionButton[] = [
    {
      color: 'primary',
      isRightAligned: true,
      onClick: () => {
        onClose();
      },
      text: 'Cancel',
      variant: 'outlined',
    },
    {
      color: 'primary',
      onClick: () => {},
      text: '',
      variant: 'contained',
    },
  ];

  // Delete modal
  const deleteContent = (
    <div className="type-body1">
      <p>
        Are you sure you want to delete the phase below?{' '}
        {activity?.type === TimelineActivityType.EVENT ? 'event' : 'phase'}.
      </p>
      <br />
      <p className="type-heading2">{`${parseDate(activity?.startDate)} ${
        activity?.endDate ? '-' : ''
      } ${parseDate(activity?.endDate) ?? ''} ${activity?.name}`}</p>
      <br />
      <br />
      <p>
        All Activities related to this activity will be changed to None.
        <br />
        All items linked to this event will be unlinked.
      </p>
    </div>
  );

  // Entries for related activities
  // Events can show phases and milestones
  // Phases can only show phases
  const relatedActivityEntries: SelectEntry[] = [
    getEntry('none', 'None'),
    ...(activities
      .map((a) => {
        // If the activity is the current activity or a child of the current activity, don't show it
        if (
          (activity &&
            (a.id === activity.id || activity.children.map((c) => c.id).includes(a.id))) ||
          a.type !== TimelineActivityType.PHASE
        )
          return undefined;

        return getEntry(
          a.id,
          isBelongsToValid(a) ? a.name : `${a.name} (Date outside of this phase)`,
          !isBelongsToValid(a),
          undefined,
          undefined,
          undefined,
          undefined,
          'Phases'
        );
      })
      .filter((a) => a !== undefined) as SelectEntry[]),
  ].sort((a, b) => {
    // make sure none is always first
    if (!a.type) return -1;
    if (!b.type) return 1;
    // orders it such that phases come before milestones
    if (a?.type < b?.type) {
      return 1;
    }
    if (a.type > b.type) {
      return -1;
    }
    return 0;
  });

  // Entries for activity types
  const activityTypeEntries: SelectEntry[] = [
    getEntry(
      TimelineActivityType.EVENT.toString(),
      'Event',
      undefined,
      <i className={classes.eventIcon} />
    ),
    getEntry(
      TimelineActivityType.PHASE.toString(),
      'Phase',
      undefined,
      <i className={classes.phaseIcon} />
    ),
  ];

  const tooltipText = (
    <div>
      There are 3 types of Activities:
      <br />
      Phases
      <br />
      Milestones
      <br />
      Events
      <br />
      <br />
      Phases are durations on your project used to group or block out chunks of time. Events,
      Milestones and Phases can belong to a Phase.
      <br />
      <br />
      Milestones are significant dates you added to Join that contain estimates, budgets, and
      attachments. Items are tied to Milestones.
      <br />
      <br />
      Events are significant dates that you want your team to keep track of and target, like
      meetings. Items can be tagged to events.
    </div>
  );

  // Add/edit modal
  const activityContent = (
    <div className="flex flex-col gap-4">
      <div className="gap-0.5 text-type-primary type-label">
        <div className="type-label">Name*</div>
        <TextField
          className={classes.textInput}
          placeholder="Activity Name"
          InputProps={{
            disableUnderline: true,
          }}
          value={activityName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setActivityName(event.target.value);
          }}
        />
      </div>
      <div className="flex gap-2">
        <div>
          <div className="flex items-center gap-0.5">
            <div className="type-label">Type*</div>
            <NormalTooltip title={tooltipText}>
              <Help className={classes.helpIcon} color="disabled" />
            </NormalTooltip>
          </div>

          <JoinSelect
            onChange={(e) => {
              setParentActivity(undefined);
              setEventType(Number(e));
            }}
            entries={activityTypeEntries}
            value={eventType.toString()}
            disabled={type === EDIT}
          />
        </div>
        <div className="gap-0.5">
          <div className="type-label">Start Date*</div>
          <TimelineDatePicker
            onChange={(d) => setStartDate(d)}
            value={startDate}
            minDate={parentActivity?.startDate ? getDate(parentActivity?.startDate) : undefined}
            maxDate={parentActivity?.endDate ? getDate(parentActivity?.endDate) : undefined}
          />
        </div>

        <div className="gap-0.5">
          <div className="type-label">End Date*</div>
          {eventType === TimelineActivityType.PHASE ? (
            <TimelineDatePicker
              onChange={(d) => setEndDate(d)}
              value={endDate}
              minDate={startDate}
              maxDate={parentActivity?.endDate ? getDate(parentActivity?.endDate) : undefined}
            />
          ) : (
            <div className={classes.emptyDateInput}>
              <p style={{ flex: 1 }}>-</p>
              <EventIcon className={classes.emptyDateInputIcon} />
            </div>
          )}
        </div>
      </div>
      <div className="gap-0.5">
        <div className="type-label">Belongs to Timeline Phase</div>
        <JoinSelect
          onChange={(e) => {
            const relatedActivity = activities.find((a) => a?.id === e);
            setParentActivity(relatedActivity);
          }}
          groupingKey="type"
          entries={relatedActivityEntries}
          value={parentActivity?.id || 'none'}
        />
      </div>
    </div>
  );

  // The switch here determines the information being displayed in the modal
  let headerText = '';
  let contentComponent;
  let createAnotherCheckbox;
  switch (type) {
    case CREATE:
      actionButtons[0].text = '';
      createAnotherCheckbox = (
        <Checkbox
          isSelected={isCreateAnother}
          onChange={(event: boolean) => {
            setIsCreateAnother(event);
          }}
          data-cy="checkbox-createAnother"
        >
          <div className="type-body2">Create another Activity</div>
        </Checkbox>
      );
      actionButtons[1].text = 'Create';
      actionButtons[1].onClick = () => {
        createTimelineActivity(projectID, types, generateActivityInput());
        clearInputs();
        if (!isCreateAnother) {
          onClose();
        }
      };
      headerText = 'Create Activity';
      contentComponent = activityContent;
      break;
    case EDIT:
      actionButtons[1].text = 'Save';
      actionButtons[1].onClick = () => {
        if (activity?.id) {
          updateTimelineActivity(projectID, types, activity.id, generateActivityInput());
          clearInputs();
          onClose();
        }
      };
      headerText = 'Edit Activity';
      contentComponent = activityContent;
      break;
    case DELETE:
      actionButtons[1].text = 'Delete';
      actionButtons[1].onClick = () => {
        if (activity?.id) {
          deleteTimelineActivity(projectID, types, activity.id);
          clearInputs();
          onClose();
        }
      };
      headerText = 'Delete Activity';
      contentComponent = deleteContent;
      break;
    default:
      break;
  }

  const disabledButtons =
    !activityName?.trim() || !startDate || (eventType === TimelineActivityType.PHASE && !endDate);
  actionButtons[0].disabled = disabledButtons;
  actionButtons[1].disabled = disabledButtons;

  const onCloseClick = () => {
    clearInputs();
    onClose();
  };

  return (
    <Dialog
      isOpen={open}
      footerLeft={
        actionButtons[0].text ? (
          <Button
            label={actionButtons[0].text}
            type="secondary"
            disabled={actionButtons[0].disabled}
            onClick={actionButtons[0].onClick}
          />
        ) : (
          createAnotherCheckbox
        )
      }
      footerRight={
        <Button
          label={actionButtons[1].text}
          type="primary"
          disabled={actionButtons[1].disabled}
          onClick={actionButtons[1].onClick}
        />
      }
      onClose={onCloseClick}
      title={headerText}
      isNotDismissable={false}
    >
      <DialogContent className="flex flex-col gap-2">
        <div className="type-body1">{contentComponent}</div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(TimelineActivityModal);
