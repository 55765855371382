import { JoinRoutes } from '../api/gqlEnums';

import CompanyTab from './CompanyTab/CompanyTab';
import { routeToCompanyComponent } from './CompanyTab/CompanyTabRouteUtils';
import AuthorizeRedirectPage from './DocumentMagic/AuthorizeRedirectPage/AuthorizeRedirectPage';
import ExecutiveDashboard from './ExecutiveDashboard/ExecutiveDashboard';
import ForecastingTab from './ForecastingTab/ForecastingTab';
import { routeToForecastingComponent } from './ForecastingTab/routes';
import Search from './HomeTab/Search/Search';
import ProjectRoute from './ProjectRoute/ProjectRoute';
import { routeToProjectComponent } from './ProjectRoute/routes';

export const routeToAppComponent = {
  [JoinRoutes.AUTHORIZE]: { element: <AuthorizeRedirectPage /> },
  [JoinRoutes.COMPANY_TAB]: { element: <CompanyTab />, subs: routeToCompanyComponent },
  [JoinRoutes.EXECUTIVE_DASHBOARD]: { element: <ExecutiveDashboard /> },
  [JoinRoutes.FORECASTING_TAB]: { element: <ForecastingTab />, subs: routeToForecastingComponent },
  [JoinRoutes.ITEMS]: { element: <Search /> },
  [JoinRoutes.PROJECT]: { element: <ProjectRoute />, subs: routeToProjectComponent },
  [JoinRoutes.PROJECTS]: { element: <Search /> },
};
