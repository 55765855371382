import { useMemo } from 'react';

import { makeVar, useReactiveVar } from '@apollo/client';

import { CostMode } from '../api/gqlEnumsBe';
import { ALLOCATED_MARKUPS, COST_MODE, NONE, NO_MARKUPS, SEPARATED_MARKUPS } from '../constants';
import { PermissionResource } from '../generated/graphql';

import { getTextMapForPermissions } from './permissions/permissions';
import usePermissions from './permissions/usePermissions';
import { getProjectIdFromUrl } from './url';

export const checkCostModeIncludesMarkups = (costModeString: string) =>
  costModeString === ALLOCATED_MARKUPS || costModeString === SEPARATED_MARKUPS;

export const mapCostModeStringToEnum = (costModeString: string | null | undefined) => {
  switch (costModeString) {
    case NO_MARKUPS:
      return CostMode.NoMarkups;
    case SEPARATED_MARKUPS:
      return CostMode.SeparatedMarkups;
    case ALLOCATED_MARKUPS:
      return CostMode.AllocatedMarkups;
    default:
      return CostMode.AllocatedMarkups;
  }
};

export const getCostModeString = (costMode: CostMode, t: TermStore) => {
  const textMap = getTextMapForPermissions(t);
  switch (costMode) {
    case CostMode.AllocatedMarkups:
      return textMap(PermissionResource.ALLOCATED_MARKUPS_VIEW) || '';
    case CostMode.SeparatedMarkups:
      return textMap(PermissionResource.SEPARATED_MARKUPS_VIEW) || '';
    case CostMode.NoMarkups:
      return textMap(PermissionResource.NO_MARKUPS_VIEW) || '';
    default:
      return textMap(PermissionResource.ALLOCATED_MARKUPS_VIEW) || '';
  }
};

export const mapCostModeEnumToString = (costMode: CostMode) => {
  switch (costMode) {
    case CostMode.SeparatedMarkups:
      return SEPARATED_MARKUPS;
    case CostMode.AllocatedMarkups:
      return ALLOCATED_MARKUPS;
    case CostMode.NoMarkups:
      return NO_MARKUPS;
    default:
      return ALLOCATED_MARKUPS;
  }
};

export const getLocalCostModeLocation = (projectId?: UUID) =>
  `${projectId || getProjectIdFromUrl()}-${COST_MODE}`;

export const costModeVar = makeVar<CostMode>(
  mapCostModeStringToEnum(localStorage.getItem(getLocalCostModeLocation()) || NONE)
);

// See what cost modes are available, return the current reactiveVar value if it's set and permissioned, else return the first available
// cost mode

export const useAvailableCostModes = (projectID?: UUID) => {
  const { canView } = usePermissions({ projectID });
  return useMemo(() => {
    const availableCostModes = [];
    if (canView(PermissionResource.ALLOCATED_MARKUPS_VIEW))
      availableCostModes.push(CostMode.AllocatedMarkups);
    if (canView(PermissionResource.SEPARATED_MARKUPS_VIEW))
      availableCostModes.push(CostMode.SeparatedMarkups);
    if (canView(PermissionResource.NO_MARKUPS_VIEW)) availableCostModes.push(CostMode.NoMarkups);
    return availableCostModes;
  }, [canView]);
};

export const useCostMode = (customProjectID: UUID | undefined = undefined): string => {
  const projectID = customProjectID ?? getProjectIdFromUrl();
  const availableCostModes = useAvailableCostModes(projectID);
  const storedCostModeString = localStorage.getItem(getLocalCostModeLocation(projectID));
  costModeVar(mapCostModeStringToEnum(storedCostModeString));
  const costMode = useReactiveVar(costModeVar);

  if (availableCostModes.length > 0) {
    if (availableCostModes.indexOf(costMode) >= 0) {
      return mapCostModeEnumToString(costMode);
    }
    localStorage.setItem(
      getLocalCostModeLocation(projectID),
      mapCostModeEnumToString(availableCostModes[0])
    );
    return mapCostModeEnumToString(availableCostModes[0]);
  }
  return mapCostModeEnumToString(costMode);
};
