import { FC, useState } from 'react';

import { PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID } from '../../../constants';
import usePageHeight from '../../../hooks/usePageHeight';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import ItemHistoryEventTable from '../../Events/ItemHistoryEvent/ItemHistoryEventTable';
import { ItemActivityEvent } from '../../Events/utils';
import ItemSidebarWrapper from '../../frame/ItemSidebar/ItemSidebarWrapper';

import ItemActivityFeedEmptyState from './ItemActivityFeedEmptyState';
import ItemActivityFeedHeader from './ItemActivityFeedHeader';
import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

type Props = {
  selectedReportType: ItemActivityFeedReportType;
  setReportType: (reportType: ItemActivityFeedReportType) => void;
  events: ItemActivityEvent[];
  projectID: UUID;
  fetchMoreEvents: () => void;
  milestones: Milestone[];
  isDoneFetching: boolean;
  isLoading: boolean;
  onItemMutated: () => void;
};

const ItemActivityFeed: FC<Props> = ({
  selectedReportType,
  setReportType,
  events,
  projectID,
  fetchMoreEvents,
  milestones,
  isDoneFetching,
  isLoading,
  onItemMutated,
}) => {
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const [selectedEventID, setSelectedEventID] = useState<string | null>(null);
  const showEventTable = !isLoading && events?.length;
  return (
    <div className="flex w-full overflow-hidden" style={{ height: usePageHeight() }}>
      <div className="flex w-full flex-grow flex-col items-center overflow-auto bg-background-primary">
        <div className="inset-x-0 top-16 flex w-full justify-center shadow-md">
          <ItemActivityFeedHeader
            selectedReportType={selectedReportType}
            setReportType={setReportType}
          />
        </div>
        <div
          id={PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID}
          className={`mt-2 h-full max-h-[calc(100vh-185px)] w-full flex-grow ${
            showEventTable ? 'overflow-y-auto' : ''
          } `}
          onScroll={({ currentTarget }) => {
            if (
              currentTarget.scrollTop + currentTarget.clientHeight * 1.05 >
                currentTarget.scrollHeight &&
              !isLoading
            ) {
              fetchMoreEvents();
            }
          }}
        >
          {showEventTable ? (
            <ItemHistoryEventTable
              shouldDisplayCosts={shouldDisplayCosts}
              canViewItemAttachments // The backend should automatically filter out any attachment events a user doesn't have access to
              events={events}
              isDoneFetching={isDoneFetching}
              projectID={projectID}
              selectedEventID={selectedEventID}
              setSelectedEventID={setSelectedEventID}
            />
          ) : (
            <ItemActivityFeedEmptyState
              selectedReportType={selectedReportType}
              setReportType={setReportType}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <ItemSidebarWrapper
        defaultExpanded=""
        milestones={milestones}
        onItemMutated={onItemMutated}
        onClose={() => setSelectedEventID(null)}
      />
    </div>
  );
};

export default ItemActivityFeed;
