import { isUUID } from 'validator';

import {
  AssetQueryApolloQuery,
  AssetQueryApolloQueryVariables,
  AssetThumbnailQuery,
  AssetThumbnailQueryVariables,
  ImageDimension,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { assetQueryApollo, assetThumbnailQuery } from './queries';

// TODO: Make this obsolete
// used in signup containers, frame titlebar, sidebar
function useAssetQuery(
  assetID: UUID | null | undefined,
  onCompleted?: (data: AssetQueryApolloQuery) => void
) {
  return useQuery<AssetQueryApolloQuery, AssetQueryApolloQueryVariables>(assetQueryApollo, {
    variables: { assetID },
    skip: !assetID || !isUUID(assetID),
    context: { batch: true },
    onCompleted,
  });
}

// the url will refresh every so often, so use the new one the network might return
export const useThumbnailQuery = (
  assetID: UUID | null | undefined,
  dimension?: ImageDimension,
  skip?: boolean
) =>
  useQuery<AssetThumbnailQuery, AssetThumbnailQueryVariables>(assetThumbnailQuery, {
    variables: { assetID, dimension },
    skip: skip || !assetID || !isUUID(assetID),
    context: { batch: true },
  });

export default useAssetQuery;
