import { FC } from 'react';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../theme/komodo-mui-theme';
import SearchBar from '../Search/SearchBar/SearchBar';

import CompanyTabStyles from './CompanyTabStyles';
import { CompanyUserSearchManager } from './CompanyTabUtils';

type CompanyTabSearchProps = {
  searchManager: CompanyUserSearchManager;
  page?: CompanyAdminView;
  variant?: 'projectStats' | 'categorizations';
};

const getCompanyAnalyticsEventType = (page: CompanyAdminView) => {
  switch (page) {
    case CompanyAdminView.MEMBERS_LIST:
    case CompanyAdminView.MEMBER_SIDEBAR:
      return CompanyAdminEventType.MEMBER_SEARCH;
    case CompanyAdminView.COLLABORATORS_LIST:
    case CompanyAdminView.COLLABORATORS_SIDEBAR:
      return CompanyAdminEventType.COLLABORATORS_SEARCH;
    case CompanyAdminView.PROJECT_STATS_LIST:
      return CompanyAdminEventType.PROJECT_STATS_SEARCH;
    case CompanyAdminView.STANDARD_CATEGORIZATIONS:
      return CompanyAdminEventType.STANDARD_CATEGORIZATION_SEARCH;
    default:
      return undefined;
  }
};

const CompanyTabSearch: FC<CompanyTabSearchProps> = ({
  searchManager,
  page,

  variant,
}) => {
  const { searchTerm, setSearchTerm, clearSearch } = searchManager;
  const sendAnalytics = useSendAnalyticsEventHook();

  const placeholder = () => {
    if (variant === 'projectStats') {
      return 'Find a project...';
    }
    if (variant === 'categorizations') {
      return 'Search categorizations...';
    }
    return 'Search user text fields...';
  };

  return (
    <div>
      <SearchBar
        onClear={clearSearch}
        onChange={(evt) => {
          setSearchTerm(evt.target.value);
        }}
        onBlur={(evt) => {
          const eventType = page ? getCompanyAnalyticsEventType(page) : undefined;
          if (!eventType) return;

          sendAnalytics(
            companyAdminAnalyticsEvent(eventType, { view: page, search: evt.target.value })
          );
        }}
        placeholder={placeholder()}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabSearch);
