import { FC, useContext, useRef, useState } from 'react';

import { Button, ClickAwayListener, Paper, Popper, Typography } from '@material-ui/core';
import { Add, ArrowDropDown, Info } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  NewProjectEvent,
  companyAdminAnalyticsEvent,
  newProjectEvent,
} from '../../../analytics/analyticsEventProperties';
import { ImageDimension } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { hasModuleEntitlement } from '../../../hooks/useModuleEntitlementsQuery';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useProjectTemplatesQuery } from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import DialogsNewProject from '../../Dialogs/DialogsNewProject/DialogsNewProject';
import { YellowLightbulb } from '../../Icons/YellowLightbulb';
import { ModuleEntitlementsStore } from '../../ModuleEntitlementsProvider';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import CTAIconButton from '../CTAIconButton';
import EntryCard, { EntryCardVariant } from '../EntryCard/EntryCard';
import LearnMore from '../LearnMore/LearnMore';
import Thumbnail from '../Thumbnail/Thumbnail';

import { styles } from './styles';

type NewProjectButtonProps = {
  classes: Classes<typeof styles>;
  isLarge?: boolean;
};

const NewProjectButton: FC<NewProjectButtonProps> = ({ classes, isLarge = false }) => {
  const canCreateProject = hasModuleEntitlement(
    useContext(ModuleEntitlementsStore),
    PROJECT_CREATION
  );

  const { data: companyUserData, loading: companyUserLoading } = useCompanyUserQuery();
  const companyID = companyUserData?.companyUser?.company?.id;
  const { data: projectTemplatesData, loading: projectTemplatesLoading } = useProjectTemplatesQuery(
    companyID,
    false
  );
  const projectTemplates = projectTemplatesData?.projectTemplates ?? [];

  const sendAnalytics = useSendAnalyticsEventHook();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);
  const [template, setTemplate] = useState<ProjectTemplate>();
  const anchorRef = useRef<HTMLDivElement>(null);

  const hasTemplates = projectTemplates.length > 0;

  const onClick = () => {
    if (isLarge) {
      setDialogOpen(true);
    } else {
      setPopperOpen(true);
      sendAnalytics(newProjectEvent(NewProjectEvent.CTA, { location: window.location.pathname }));
    }
  };

  const blankProjectCard = (
    <EntryCard
      dataCy="button-blankProject"
      description="Blank project"
      icon={<Add color="inherit" fontSize="inherit" />}
      onClick={() => {
        setTemplate(undefined);
        setPopperOpen(false);
        setDialogOpen(true);
      }}
    />
  );

  const noProjectTemplatesCard = (
    <EntryCard description="No project templates available." variant={EntryCardVariant.HINT} />
  );

  const learnMore = (
    <LearnMore
      href="https://success.join.build/en/knowledge/project-templates"
      onClick={() => {
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_HELP_CTA));
      }}
      text="Learn More"
    />
  );

  const templateHintCardDescription = (
    <>
      Start projects quicker with templates. {learnMore} <br />
      <br />
      To create a template, please contact your company admin.
    </>
  );
  const templateHintCard = (
    <EntryCard
      description={templateHintCardDescription}
      icon={<YellowLightbulb />}
      variant={EntryCardVariant.HINT}
    />
  );

  const loading = companyUserLoading || projectTemplatesLoading;
  if (loading || !companyID || !canCreateProject) {
    return null;
  }

  return (
    <>
      <div ref={anchorRef}>
        {isLarge ? (
          <Button
            className={classes.buttonLarge}
            data-cy="button-createNewProject"
            onClick={onClick}
          >
            Create First Project
          </Button>
        ) : (
          <CTAIconButton
            dataCy="button-createNewProject"
            icon={<Add />}
            iconRight={<ArrowDropDown />}
            isSmaller
            onClick={onClick}
            buttonText="New Project"
          />
        )}
      </div>
      <Popper
        open={popperOpen}
        anchorEl={anchorRef.current}
        className={classes.popper}
        placement="bottom-end"
      >
        <ClickAwayListener
          onClickAway={() => {
            setPopperOpen(false);
          }}
        >
          <Paper className={classes.paper}>
            {/* Blank project */}
            {blankProjectCard}
            <div className={classes.templateLabel}>
              <Typography className={classes.body}>Or start from a template</Typography>
              <NormalTooltip
                title={
                  <>
                    <div>
                      You can start a project quickly by using a company provided template. These
                      templates are set up with your company standards including Categorizations,
                      Units of Measures, Item Templates, Team, Roles, Items and/or Milestones.
                    </div>
                    <div>
                      To create new template or change an existing one, please contact your company
                      admin.
                    </div>
                  </>
                }
              >
                <div className={classes.icon_info}>
                  <Info color="inherit" fontSize="inherit" />
                </div>
              </NormalTooltip>
            </div>
            {!hasTemplates && noProjectTemplatesCard}
            {!hasTemplates && templateHintCard}
            {/* Template projects */}
            {projectTemplates.map((t) => {
              return (
                <EntryCard
                  key={t.id}
                  dataCy="NewProjectButton-template"
                  description={t.name}
                  icon={
                    <Thumbnail
                      thumbnail={t.project.thumbnail}
                      dimension={ImageDimension._50}
                      size={50}
                      padding={0}
                    />
                  }
                  onClick={() => {
                    setTemplate(t);
                    setPopperOpen(false);
                    setDialogOpen(true);
                  }}
                />
              );
            })}
          </Paper>
        </ClickAwayListener>
      </Popper>
      {dialogOpen && (
        <DialogsNewProject
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          template={template}
        />
      )}
    </>
  );
};

export default withStyles(styles)(NewProjectButton);
