import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine } from '../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      alignItems: 'center',
      backgroundColor: theme.palette.nestingGrey,
      border: `${theme.palette.nestingGrey} solid 1px`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      cursor: 'default',
      display: 'flex',
      flex: '0 0 auto',
      gap: '20px',
      padding: `10px 10px`,
      width: '100%',
    },
    cardActive: {
      '&:active': {
        background: theme.palette.backgroundFocusSelectGreen,
      },
      '&:hover': {
        background: theme.palette.backgroundGreen,
        border: singleLine(theme.palette.borderGreen),
      },
      backgroundColor: theme.palette.backgroundWhite,
      border: `${theme.palette.joinGrey400} solid 1px`,
      cursor: 'pointer',
    },
    icon: {
      color: theme.palette.disabledGrey,
      display: 'flex',
      fontSize: 50,
      lineHeight: 0,
    },
    text: {
      margin: '8px 0px',
    },
  });
