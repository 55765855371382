import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { getProjectIdFromUrl } from '../url';

export const useProjectID = () => {
  const { projectId: projectID } = useParams<{ projectId: UUID }>();
  if (projectID && isUUID(projectID)) return projectID;
  return getProjectIdFromUrl() || undefined;
};
