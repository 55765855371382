import { JoinTeamRoutes } from '../../../../api/gqlEnums';
import { DD_PERMISSION_ROLES_NAVIGATION } from '../../../../features';
import { useIsRouteAccessible } from '../../../../hooks/useIsRouteAccessible';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';

export const useTeamLinks = () => {
  const projectId = getProjectIdFromUrl();
  const { isAccessible } = useIsRouteAccessible();
  const links = [
    {
      name: 'Teammates',
      route: {
        path: generateSharedPath(JoinTeamRoutes.TEAM_TEAMMATES, { projectId }),
      },
    },
    {
      name: 'Companies',
      route: { path: generateSharedPath(JoinTeamRoutes.TEAM_COMPANIES, { projectId }) },
    },
    {
      name: 'Roles',
      route: {
        path: generateSharedPath(JoinTeamRoutes.TEAM_ROLES, { projectId }),
        featureFlag: DD_PERMISSION_ROLES_NAVIGATION,
      },
    },
  ];

  const filteredLinks = links.filter((link) => isAccessible(link.route));
  return filteredLinks;
};
