import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export const ROW_HEIGHT = 29;
export const LIST_PADDING = 24;
export const LEDGER_WIDTH = LIST_PADDING / 2 - 3;
export const DESCRIPTION_WIDTH = 320; // what it needs for the collapse link
export const REPORT_COL_WIDTH = 145;
export const METRIC_COL_WIDTH = 145;
export const VARIANCE_COL_WIDTH = 134;
export const RANGE_COL_WIDTH = 210;
export const HEADER_PADDING = 40;
export const CELL_PADDING = 8;
const MIN_CELL_WIDTH = 90;
// widths for variance report notes column:
export const NOTES_COL_WIDTH_EXPANDED = 338;
export const NOTES_COL_WIDTH_COLLAPSED = 64;
export const NOTES_ROW_HEIGHT_EXPANDED = 80;

const ledgerStyle = (theme: KomodoTheme) => `1px dotted ${theme.palette.joinGrey400}`;
export const shadedClass = (theme: KomodoTheme) => ({
  background: theme.palette.joinGrey100,
});

export const styles = (theme: KomodoTheme) =>
  createStyles({
    shaded: shadedClass(theme),
    collapse: {
      margin: 0, // importantly correct MUI negative margin for collapse border
    },
    description: {
      flex: '0 1 auto',
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    descriptionColumn: {
      position: 'sticky',
      left: 0,
      flex: `1 0 auto`,
      height: '100%',
      zIndex: 3,
      backgroundColor: theme.palette.backgroundWhite,
      minWidth: DESCRIPTION_WIDTH,
    },
    descriptionWidth: {
      width: DESCRIPTION_WIDTH,
    },
    scroll: {
      borderRight: theme.border.line,
    },
    descriptionFront: {
      alignItems: 'center',
      display: 'flex',
      flex: `1 0 auto`,
    },
    expander: {
      flexGrow: 1,
    },
    gap: {
      width: 8,
      flex: `0 0 8px`,
    },
    itemGap: {
      width: 8,
    },
    // Ledger Line nesting dependent styling
    printNesting: { display: 'flex', flex: '0 0 8px' },
    nesting: {
      display: 'flex',
      flex: `0 0 ${LIST_PADDING / 2}px`,
      paddingLeft: LIST_PADDING / 2 - 1,
    },
    levelSpacer: {
      borderLeft: ledgerStyle(theme),
      height: ROW_HEIGHT,
      marginLeft: 1,
    },
    lastLevelSpacer: {
      // note: this order is important. It supercedes the above
      height: ROW_HEIGHT / 2,
      borderLeft: ledgerStyle(theme),
      marginBottom: ROW_HEIGHT / 2,
      marginLeft: 1,
      width: 1,
    },
    ledger: {
      height: ROW_HEIGHT / 2,
      marginBottom: ROW_HEIGHT / 2,
      borderBottom: ledgerStyle(theme),
      width: LEDGER_WIDTH,
    },
    printLedger: {
      width: 3,
    },
    // End Ledger Line nesting
    hover: {
      backgroundColor: theme.palette.hoverBackground,
      cursor: 'pointer',
    },
    spacer: {
      height: 1, // this is neccesary for the ledger spacer line to be visible at various zooms
      margin: '8px 0px',
      flexGrow: 1,
      flexShrink: 0,
      borderBottom: ledgerStyle(theme),
    },
    iconContainer: {
      minWidth: LIST_PADDING,
      display: 'flex',
      '@media print': {
        width: 0,
      },
    },
    icon: {
      color: theme.palette.joinPrimary,
      height: 20,
      margin: 'auto',
      width: 20,
      cursor: 'pointer',
      '@media print': {
        display: 'none',
      },
    },
    fixedWidth: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      width: REPORT_COL_WIDTH,
      minWidth: REPORT_COL_WIDTH,
    },
    pageBreak: {
      height: 0,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'always',
      width: 'calc(100% + 68px)',
      left: -38,
      position: 'relative',
      '@media not print': {
        borderTop: '1px dotted grey',
      },
    },
    range: {
      width: RANGE_COL_WIDTH,
      minWidth: RANGE_COL_WIDTH,
    },
    listContainer: {
      '&:focus': {
        outline: 0,
      },
    },
    descriptionText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    scrollContainer: {
      flex: '0 1 auto',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    subtotal: {
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    row: {
      display: 'flex',
      height: '100%',
      minWidth: '100%',
      width: 'fit-content',
      flexDirection: 'row',
      flex: '1 1 0',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
      '@media print': {
        paddingRight: 0,
        pageBreakInside: 'avoid',
      },
    },
    varianceItemStatus: {
      paddingLeft: LIST_PADDING,
      paddingRight: LIST_PADDING / 2,
    },
    varianceWideCell: {
      width: 2 * (VARIANCE_COL_WIDTH + HEADER_PADDING),
    },
    varianceCell: {
      width: VARIANCE_COL_WIDTH + HEADER_PADDING,
    },
    varianceText: {
      color: theme.palette.budget,
    },
    numberCells: {
      textAlign: 'right',
      ...theme.typography.number,
      paddingRight: CELL_PADDING,
    },
    numberCellsMin: {
      minWidth: MIN_CELL_WIDTH,
    },
    // PRINT MSR ITEM LINE DESCRIPTION
    descriptionRow: {
      display: 'flex',
    },
    itemDescription: {
      fontFamily: theme.fontFamily,
      fontSize: `${theme.typography.body1.fontSize}`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flex: '1 0 auto',
      zIndex: 1,
      maxWidth: 'fit-content',
      paddingLeft: 4,
      paddingTop: 2,
    },
    itemRow: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    itemPrintRow: {
      display: 'flex',
    },
    scrollContainerItem: {
      flex: '0 0 0',
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      marginLeft: 320,
    },
    itemSidebarItem: {
      backgroundColor: theme.palette.sidebarBackground,
    },
    itemQuickLink: {
      display: 'flex',
    },
    itemQuickLinkPadding: {
      paddingLeft: 8,
    },
    reportNoteCell: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontWeight: 400,
    },
    reportNoteCellHovered: {
      backgroundColor: theme.palette.joinGrey200,
    },
    notesHoverSidebar: {
      height: ROW_HEIGHT,
      width: NOTES_COL_WIDTH_COLLAPSED / 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noteIndicator: {
      color: theme.palette.buttonGrey,
      width: 8,
      height: 8,
      '&:hover': {
        color: theme.palette.disabledGrey,
      },
    },
    addNoteIcon: {
      color: theme.palette.buttonGrey,
      width: 12,
      height: 12,
    },
    closeAddNote: {
      color: theme.palette.buttonGrey,
      width: 15,
      height: 15,
      '&:hover': {
        color: theme.palette.disabledGrey,
      },
    },
    addNoteText: {
      marginLeft: 8,
      fontWeight: 400,
    },
    addNoteLink: {
      display: 'inherit',
      alignItems: 'inherit',
      '&:hover': {
        color: theme.palette.disabledGrey,
        '& > svg': { color: theme.palette.disabledGrey },
      },
    },
    newNoteContainer: {
      display: 'flex',
      alignItems: 'start',
      gap: '6px',
      width: '100%',
    },
  });

export default styles;
