import { JoinForecastingRoutes } from '../../api/gqlEnums';
import PrintProjectComps from '../Print/PrintProjectComps/PrintProjectComps';
import ProjectCompsSetData from '../ProjectComps/ProjectCompsSetData';

import ForecastingExplore from './ForecastingExplore/ForecastingExplore';
import ForecastingReports from './ForecastingReports/ForecastingReports';

export type RouteToForecastingComponent = {
  [key in JoinForecastingRoutes]: { element: JSX.Element };
};
export const routeToForecastingComponent: RouteToForecastingComponent = {
  [JoinForecastingRoutes.FORECASTING_EXPLORE]: { element: <ForecastingExplore /> },
  [JoinForecastingRoutes.FORECASTING_HISTORICAL_ITEMS]: { element: <ForecastingExplore /> },
  [JoinForecastingRoutes.FORECASTING_REPORTS]: { element: <ForecastingReports /> },
  [JoinForecastingRoutes.FORECASTING_UNSAVED_PROJECT_COMPARISON]: {
    element: <ProjectCompsSetData />,
  },
  [JoinForecastingRoutes.FORECASTING_SAVED_PROJECT_COMPARISON]: {
    element: <ProjectCompsSetData />,
  },
  [JoinForecastingRoutes.FORECASTING_PRINT_UNSAVED_PROJECT_COMPS]: {
    element: <PrintProjectComps />,
  },
  [JoinForecastingRoutes.FORECASTING_PRINT_SAVED_PROJECT_COMPS_SET]: {
    element: <PrintProjectComps />,
  },
};
