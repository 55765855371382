import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../utilities/clickHandler';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import breadcrumbsStyles from './BreadcrumbsStyles';

type BreadcrumbLink = {
  destination?: string;
  onClick?: () => void;
  display: string;
  tooltip: string;
};

type BreadcrumbsProps = {
  classes: Classes<typeof breadcrumbsStyles>;
  links: BreadcrumbLink[];
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ classes, links }) => {
  const navigate = useNavigate();
  const linksMap = links.map((link, index) => {
    const divider = index !== links.length - 1 ? '/' : '';
    const { destination, onClick, display, tooltip } = link;
    const hasLink = destination || onClick;

    const eventHandler = (event: Parameters<typeof clickLinkKeyHandler>[1]) => {
      if (destination) {
        clickLinkKeyHandler(navigate, event, destination, '');
      }
    };
    const onClickPress = onClick || eventHandler;

    const content = hasLink ? (
      <div
        className={classes.breadcrumbButton}
        onClick={onClickPress}
        onKeyDown={onClickPress}
        role="button"
        tabIndex={0}
      >
        <NormalTooltip title={tooltip}>
          <Typography data-cy="Breadcrumbs-link" className={classes.breadcrumbLink}>
            {display}
          </Typography>
        </NormalTooltip>
      </div>
    ) : (
      <NormalTooltip title={tooltip}>
        <Typography data-cy="typography-MilestoneBreadcrumb" className={classes.breadcrumb}>
          {display}
        </Typography>
      </NormalTooltip>
    );

    return (
      <div className={classes.inlineRow} key={destination || display}>
        {content}
        <Typography className={classes.breadcrumbSeparator}>{divider}</Typography>
      </div>
    );
  });

  return (
    <div className={`breadcrumbs ${classes.inlineRow}`} data-cy="Breadcrumbs-container">
      {linksMap}
    </div>
  );
};

export default withStyles(breadcrumbsStyles)(Breadcrumbs);
