import { FC, useContext } from 'react';

import { Divider } from '@material-ui/core';

import { TermKey } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import 'react-vis/dist/style.css';
import { formatCost } from '../../../utilities/currency';
import { EMPTY_COST } from '../../../utilities/string';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import styles from './ExecutiveDashboardBudgetGapsStyles';

type ExecutiveDashboardBudgetGapsHintProps = {
  classes: Classes<typeof styles>;
  rounded?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  value: any;
};

const ExecutiveDashboardBudgetGapsHint: FC<ExecutiveDashboardBudgetGapsHintProps> = ({
  classes,
  rounded = true,
  value,
}) => {
  const t = useContext(ProjectTermStore);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const bold = (v: any, match: string) =>
    `${v && v.type === match ? classes.bold : ''} ${classes.inline}`;
  const { accepted, budget, estimate, milestoneName = '', name = '', runningTotal } = value;
  const remaining = budget - runningTotal;
  const acceptedText = formatCost(accepted, { rounded });
  const estimateText = formatCost(estimate, { rounded });
  const budgetText = formatCost(budget, { rounded });
  const remainingText = formatCost(remaining, { rounded });
  const runningTotalText = formatCost(runningTotal, { rounded });
  return (
    <>
      <div className={`${classes.bold} ${classes.ellipsis} ${classes.projectName}`}>{name}</div>
      <div className={`${classes.bold} ${classes.ellipsis} ${classes.milestoneName}`}>
        {milestoneName}
      </div>
      <div className={bold(value, 'estimate')}>
        <div>{`${t.titleCase(TermKey.ESTIMATE)} Total`}</div>
        <div className={classes.spacer} />
        <div className={classes.number}>{estimate ? estimateText : EMPTY_COST}</div>
      </div>
      {accepted !== 0 && (
        <>
          <div className={bold(value, 'accepted')}>
            <div className={classes.accepted}>Accepted Changes</div>
            <div className={classes.spacer} />
            <div className={`${classes.number} ${classes.accepted}`}>{acceptedText}</div>
          </div>
          <Divider className={classes.divider} />
          <div className={bold(value, 'running')}>
            <div>{t.titleCase(TermKey.RUNNING_TOTAL)}</div>
            <div className={classes.spacer} />
            <div className={classes.number}>{runningTotalText}</div>
          </div>
        </>
      )}
      {(remaining < 0 || remaining > 0) && remaining !== budget && (
        <div className={bold(value, 'budget')}>
          <div className={classes.remaining}>{t.titleCase(TermKey.GAP)}</div>
          <div className={classes.spacer} />
          <div className={`${classes.number} ${classes.remaining}`}>{remainingText}</div>
        </div>
      )}
      <Divider className={classes.divider} />
      <div data-cy="budget-gap-chart-hover" className={bold(value, 'budget')}>
        <div className={classes.budget}>{t.titleCase(TermKey.TARGET)}</div>
        <div className={classes.spacer} />
        <div className={`${classes.number} ${classes.budget}`}>
          {budget ? budgetText : EMPTY_COST}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(ExecutiveDashboardBudgetGapsHint);
