import { GridVariant, isMilestoneVariant } from '../../actions/gridAnalytics';
import { TermKey } from '../../api/gqlEnums';
import { USCENTS_CELL } from '../../constants';
import { EstimateArmatureQuery } from '../../generated/graphql';

import { EstimateGridPermissions, Footer, GridType, MarkupGridState } from './types';

export const getControllerMarkups = (
  estimate: EstimateArmatureQuery['estimate'],
  gridType: GridType
) => {
  let markups: Markup[] = [];
  let markupSubtotal = 0;
  if (!estimate) return { markups, markupSubtotal };
  switch (gridType) {
    case GridType.MARKUP_GRID:
    case GridType.OWNER_COST_GRID:
      markups = estimate.markups;
      markupSubtotal = estimate.markupSubtotal;
      break;
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      markups = estimate.incorporatedMarkups;
      markupSubtotal = estimate.incorporatedSubtotal;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      markups = estimate.incorporatedDraws;
      markupSubtotal = estimate.incorporatedDrawsSubtotal;
      break;
    case GridType.INHERITED_GRID:
      markups = estimate.inheritedMarkups;
      markupSubtotal = Number(estimate.inheritedSubtotal);
      break;
    default:
  }
  return { markups, markupSubtotal };
};

export const getControllerMarkupSettings = (
  estimate: EstimateArmatureQuery['estimate'],
  gridType: GridType,
  gridVariant: GridVariant,
  permissions: EstimateGridPermissions
) => {
  const { editMarkups, summaryMarkups } = permissions;
  const hasIncorporatedMarkups = !!estimate && estimate.incorporatedMarkups.length > 0;
  const hasIncorporatedDraws = !!estimate && estimate.incorporatedDraws.length > 0;

  let hasMarkupCheckboxButton = false;
  let isIncorporated = false;
  let isInherited = false;
  let linesReadOnly = !editMarkups;
  let s1RefShouldIncludeS2 = hasIncorporatedMarkups;
  let hasDisplayTypeColumn = false;

  let linePrefix = '';
  let milestoneName: string | undefined;

  switch (gridType) {
    case GridType.MARKUP_GRID:
      {
        const isItem =
          gridVariant === GridVariant.ITEM_DETAILS ||
          gridVariant === GridVariant.ITEM_FULLSCREEN ||
          gridVariant === GridVariant.ITEM_TEMPLATE;

        hasDisplayTypeColumn = !isItem;

        linePrefix = hasIncorporatedMarkups ? 'MM' : 'M';
      }
      break;
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      isIncorporated = true;
      s1RefShouldIncludeS2 = false;
      linePrefix = 'IM';
      hasDisplayTypeColumn = true;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      isIncorporated = true;
      s1RefShouldIncludeS2 = false;
      linePrefix = 'D';
      hasDisplayTypeColumn = true;
      break;
    case GridType.INHERITED_GRID:
      hasMarkupCheckboxButton = editMarkups;
      isInherited = true;
      linesReadOnly = true;
      s1RefShouldIncludeS2 = true;
      linePrefix = 'P';
      milestoneName =
        (estimate && 'milestoneName' in estimate && estimate.milestoneName) || undefined;
      hasDisplayTypeColumn = true;
      break;
    case GridType.OWNER_COST_GRID:
      linePrefix = 'O';
      hasDisplayTypeColumn = false;
      break;
    default:
  }
  return {
    hasIncorporatedMarkups,
    hasIncorporatedDraws,
    hasMarkupCheckboxButton,
    hasDisplayTypeColumn,
    isIncorporated,
    isInherited,
    isSummary: summaryMarkups,
    linePrefix,
    linesReadOnly,
    milestoneName,
    s1RefShouldIncludeS2,
  };
};

export const getMarkupHeaderName = (t: TermStore, isMilestoneMarkups: boolean) =>
  isMilestoneMarkups
    ? `Milestone ${t.titleCase(TermKey.MARKUP)}, Contingencies, and Allowances`
    : `Item ${t.titleCase(TermKey.MARKUP)}`;

export const getControllerMarkupFooter = (
  state: MarkupGridState,
  gridType: GridType,
  gridVariant: GridVariant,
  hasIncorporatedMarkups: boolean,
  hasIncorporatedDraws: boolean,
  t: TermStore
): Footer => {
  const isMilestoneMarkups = isMilestoneVariant(gridVariant);
  const footer = { prefix: '', name: '', type: USCENTS_CELL, data: state.subtotal };
  switch (gridType) {
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      footer.prefix = 'S2';
      footer.name = `Incorporated ${t.titleCase(TermKey.MARKUP)} Subtotal`;
      break;
    case GridType.INHERITED_GRID:
      footer.prefix = 'S3';
      footer.name = `Milestone ${t.titleCase(TermKey.MARKUP)} Subtotal`;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      footer.prefix = hasIncorporatedMarkups ? 'S4' : 'S3';
      footer.name = `Incorporated Draws Subtotal`;
      break;
    case GridType.OWNER_COST_GRID:
      if (hasIncorporatedMarkups && hasIncorporatedDraws) {
        footer.prefix = 'S5';
      } else if (hasIncorporatedMarkups || hasIncorporatedDraws) {
        footer.prefix = 'S4';
      } else {
        footer.prefix = 'S3';
      }
      footer.name = `Owner Costs Subtotal`;
      break;
    case GridType.MARKUP_GRID:
    default:
      footer.prefix = hasIncorporatedMarkups ? 'S3' : 'S2';
      footer.name = `${getMarkupHeaderName(t, isMilestoneMarkups)} Subtotal`;
      break;
  }
  return footer;
};
