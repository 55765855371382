import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ChipsStyles';

type ChipsProps = {
  children: React.ReactNode;
  classes: Classes<typeof styles>;
};

const _Chips = (props: ChipsProps) => <div className={props.classes.chips}>{props.children}</div>;

export const Chips = withStyles(styles)(_Chips);

type ChipProps = {
  children: React.ReactNode;
  className?: string;
  classes: Classes<typeof styles>;
  dataCy?: string;
  isSelected: boolean;
  onClick: () => void;
};

const _Chip = (props: ChipProps) => {
  return (
    <button
      className={`${props.classes.chip} ${props.isSelected ? props.classes.chip_selected : ''} ${
        props.className ?? ''
      }`}
      data-cy={props.dataCy}
      onClick={props.onClick}
    >
      <div>{props.children}</div>
    </button>
  );
};

export const Chip = withStyles(styles)(_Chip);
