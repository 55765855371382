import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const PREVIEW_HEIGHT = 50;
export const VERTICAL_TEXT_HEIGHT = 18;

export default (theme: KomodoTheme) =>
  createStyles({
    addAdminButton: {
      marginLeft: 'auto',
    },
    bar: {
      alignItems: 'center',
      border: theme.border.line,
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'row',
      height: PREVIEW_HEIGHT,
      paddingLeft: 16,
      paddingRight: 16,
    },
    borderBottomNone: {
      borderBottom: 'none',
    },
    previewBar: {
      justifyContent: 'space-between',
    },
    chip: {
      alignContent: 'center',
      color: theme.palette.primaryGrey,
      backgroundColor: theme.palette.joinGrey400,
      borderRadius: theme.border.radius,
      height: 20,
      display: 'inline-flex',
      padding: '0px 6px',
      marginLeft: 8,
    },
    count: {
      fontWeight: 500,
      fontSize: '0.9em',
      userSelect: 'none',
    },
    draftIcon: {
      alignItems: 'center',
      border: '2px solid '.concat(theme.palette.pinkBadge),
      borderRadius: 3,
      color: theme.palette.pinkBadge,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      margin: '5px 8px 5px 0px',
      padding: '4px 6px 2px 6px',
      textAlign: 'center',
    },
    location: {
      top: '5px !important',
    },
    select: {
      display: 'inline-flex',
      alignItems: 'center',
      height: 26,
    },
    tooltipContainer: {
      background: theme.palette.joinGrey400,
      textAlign: 'left',
    },
    tooltipText: {
      ...theme.typography.body1,
      color: theme.palette.primaryGrey,
      whiteSpace: 'pre-wrap',
      fontFamily: theme.typography.fontFamily,
    },
    trade: {
      minWidth: 240,
      marginLeft: 8,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mode: {
      color: theme.palette.backgroundWhite,
      fontWeight: 600,
      height: 16, // this is the right size to have this vertically aligned
      lineHeight: 'unset',
      flexGrow: 0,
      flexShrink: 0,
      paddingRight: 8,
    },
    option: {
      ...theme.typography.body1,
    },
    padding: {
      height: VERTICAL_TEXT_HEIGHT,
    },
    paddingLeft: {
      paddingLeft: 20,
    },
    progress: {
      marginLeft: 8,
      height: 24,
      width: 24,
    },
    info: {
      color: theme.palette.joinGrey400,
      height: 24,
      marginRight: 8,
    },
    paper: {
      top: '0px !important',
    },
    role: {
      flexShrink: 0,
      paddingLeft: 8,
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    modeContainer: {
      display: 'inline-flex',
      flexShrink: 0,
      paddingLeft: 8,
      alignItems: 'center',
      flexGrow: 1,
      minWidth: 0,
    },
    textField: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      width: 300,
    },
    userLabel: {
      fontWeight: 600,
      whiteSpace: 'pre-wrap',
      height: VERTICAL_TEXT_HEIGHT,
      color: theme.palette.backgroundWhite,
    },
    userName: {
      flexShrink: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.backgroundWhite,
    },
  });
