import { FC } from 'react';

import { CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useCurrentItemsBreakdownQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  CHART_LARGE,
  CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT_TOOLTIP,
  CURRENT_ITEMS_BREAKDOWN_HEIGHT,
  ExecutiveDashboardDataProps,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardCurrentItemsBreakdown from './ExecutiveDashboardCurrentItemsBreakdown';

const ExecutiveDashboardActiveUsersData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
  projectMap,
}) => {
  const { data: { currentItemsBreakdown } = { currentItemsBreakdown: null }, loading } =
    useCurrentItemsBreakdownQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <div onMouseOver={onHover} onFocus={onHover}>
            <ExecutiveDashboardCurrentItemsBreakdown
              currentItemsBreakdown={currentItemsBreakdown}
              projectMap={projectMap}
            />
          </div>
        )
      }
      height={CURRENT_ITEMS_BREAKDOWN_HEIGHT}
      title={CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT}
      variant={CHART_LARGE}
      tooltip={CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardActiveUsersData;
