import { get } from 'lodash';
import { useMemo, useState } from 'react';

import { UserStatus } from '../../../api/gqlEnumsBe';
import {
  ALL_COLLABORATORS,
  INVITED_COLLABORATOR,
  TABLE_HEADER_EMAIL,
  TABLE_HEADER_NAME,
  TABLE_HEADER_RESPONSIBILITIES,
  TABLE_HEADER_ROLE,
} from '../../../constants';
import { SortDirection } from '../../../generated/graphql';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { UsersSortKey } from '../../dragon-scales/ShareDialog/types';
import { SelectEntry } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';

/*

TYPES
- SortDataType

SEARCH HELPER FUNCTIONS
- generateLowerCaseValue
- useSearchCollaborators

SORT HELPER FUNCTIONS
- getSortValue
- generateSortAlgorithm 
- useSortCollaborators

FILTER HELPER FUNCTIONS
- generateFilterJoinSelectData
- useFilterCollaborators
*/

// TYPES

export type SortDataType = {
  sortKey: string;
  sortDirection: SortDirection;
};

export const generateLowerCaseValue = (value: string | undefined): string =>
  value ? value.toLowerCase() : '';

export function sortCollaborators<T extends Collaborator>(
  sortData: SortDataType,
  collaborators: T[]
): T[] {
  const { sortDirection, sortKey } = sortData;
  const newCollaboratorsList: T[] =
    sortKey !== ''
      ? collaborators
          .slice()
          .sort((a: T, b: T) => generateSortAlgorithm(a, b, sortDirection, getSortValue(sortKey)))
      : collaborators;
  return newCollaboratorsList;
}

// SEARCH
// All functionality related to SEARCHING the collaborators list
export function useSearchCollaborators<T extends Collaborator>(
  collaborators: T[],
  searchTerm: string
): T[] {
  return useMemo(() => {
    const isSearching = searchTerm.length > 0;
    if (isSearching) {
      const generateFilterText = (c: T) =>
        [
          generateLowerCaseValue(c.user.name),
          generateLowerCaseValue(c.user.email),
          generateLowerCaseValue(c.responsibility ?? undefined),
          generateLowerCaseValue(c.role.name),
          c.trades.map((t) => generateLowerCaseValue(t.name)).join(' '),
          generateLowerCaseValue(get(c, UsersSortKey.COMPANY)),
        ].join(' ');
      const newCollaborators = isSearching
        ? collaborators
            .slice()
            .filter((c: T) => generateFilterText(c).includes(generateLowerCaseValue(searchTerm)))
        : collaborators;
      return newCollaborators;
    }
    return collaborators;
  }, [searchTerm, collaborators]);
}

export const useSearchCompanies = (companies: (ProjectCompany | null)[], searchTerm: string) =>
  useMemo(() => {
    const isSearching = searchTerm.length > 0;
    const projectCompanies: ProjectCompany[] = [];
    companies.forEach((c: ProjectCompany | null) => {
      if (c) projectCompanies.push(c);
    });
    if (isSearching) {
      const generatefilterText = (c: ProjectCompany) =>
        [
          generateLowerCaseValue(c.company.name),
          generateLowerCaseValue(c.company.type),
          generateLowerCaseValue(c.company.domain || ''),
          c.company.domain ? `@${generateLowerCaseValue(c.company.domain)}` : '',
        ].join(' ');
      return projectCompanies
        .slice()
        .filter((c) => generatefilterText(c).includes(generateLowerCaseValue(searchTerm)));
    }
    return projectCompanies;
  }, [searchTerm, companies]);

// SORT
// gets the value that we want to sort by when given a key
export const getSortValue =
  (sortKey: string) =>
  (collaborator: Collaborator): string => {
    let returnValue;
    switch (sortKey) {
      case TABLE_HEADER_NAME:
        returnValue = generateLowerCaseValue(collaborator.user.name);
        break;
      case TABLE_HEADER_EMAIL:
        returnValue = generateLowerCaseValue(collaborator.user.email);
        break;
      case TABLE_HEADER_RESPONSIBILITIES:
        returnValue = generateLowerCaseValue(collaborator.responsibility ?? undefined);
        break;
      case TABLE_HEADER_ROLE:
        returnValue = collaborator.role.name;
        break;
      default:
        returnValue = generateLowerCaseValue(get(collaborator, sortKey));
        break;
    }
    return returnValue || '';
  };

// generates an algorithm by piecing all sort logic together
export const generateSortAlgorithm = (
  a: Collaborator,
  b: Collaborator,
  sortDirection: SortDirection,
  getSortKeyValue: (c: Collaborator) => string | number
) => {
  const aValue = getSortKeyValue(a);
  const bValue = getSortKeyValue(b);
  const ascendingAlgorithm = aValue > bValue ? 1 : -1;
  const descendingAlgorithm = aValue < bValue ? 1 : -1;
  return sortDirection === SortDirection.SORT_ASCENDING ? ascendingAlgorithm : descendingAlgorithm;
};

// sorts a list of collaborators and handles the logic by which they are sorted
export const useSortCollaborators = (
  collaborators: Collaborator[]
): {
  sortData: SortDataType;
  setSortData: (sortData: SortDataType) => void;
  sortedCollaborators: Collaborator[];
} => {
  const [sortData, setSortData] = useState<SortDataType>({
    sortKey: '',
    sortDirection: SortDirection.SORT_ASCENDING,
  });

  const sortedCollaborators = useMemoWrapper(sortCollaborators, sortData, collaborators);

  return { sortData, setSortData, sortedCollaborators };
};

// FILTER
// generates the data in the shape that is needed for the SelectEntry component
export const generateFilterJoinSelectData = (
  collaboratorsLength: number,
  roles: string[]
): SelectEntry[] => {
  const allData = {
    id: ALL_COLLABORATORS,
    label: ALL_COLLABORATORS,
  };
  const roleData = roles.map((role: string) => ({
    id: role,
    label: role,
  }));
  const pendingData = {
    id: INVITED_COLLABORATOR,
    label: INVITED_COLLABORATOR,
    // Note: right now the only way to tell if a user is invited (but hasn't logged in yet) is that their picture is an empty string. Will update later in #7092
  };
  return collaboratorsLength > 0 ? [allData, pendingData, ...roleData] : [];
};

// filters a list of collaborators
export const useFilterCollaborators = (
  collaborators: Collaborator[]
): {
  entries: SelectEntry[];
  filteredCollaborators: Collaborator[];
  setFilterKey: (filterKey: string) => void;
  filterKey: string;
} => {
  const [filterKey, setFilterKey] = useState(ALL_COLLABORATORS);
  // grab the roles data
  const projectID = getProjectIdFromUrl();
  const { data: { projectRoles: roles = [] } = {} } = useRolesQuery(projectID);
  const roleNames = roles.map((r: Role) => r.name);
  const entries = generateFilterJoinSelectData(collaborators.length, roleNames);

  const filteredCollaborators = useMemo(() => {
    if (filterKey === ALL_COLLABORATORS) {
      return collaborators;
    }
    if (filterKey === INVITED_COLLABORATOR) {
      return collaborators.filter((c: Collaborator) => c.user.status === UserStatus.PENDING);
    }
    return collaborators.filter((c: Collaborator) => c.role.name === filterKey);
  }, [collaborators, filterKey]);

  return {
    entries,
    filteredCollaborators,
    setFilterKey,
    filterKey,
  };
};
