import { ComponentProps, Key } from 'react';
import { AriaMenuProps } from 'react-aria';
import { Item, Section } from 'react-stately';

import MenuLogicWrapper from './MenuLogicWrapper';
import { MenuEntry, MenuEntryID, MenuSection } from './types';

type MenuProps<T> = {
  entries?: MenuEntry[];
  sections?: MenuSection[];
} & Pick<AriaMenuProps<T>, 'aria-label' | 'autoFocus' | 'onClose'>;

/** Takes either `entries` or `sections` props.  */
export default function Menu<T>(props: MenuProps<T>) {
  const { entries, sections, ...menuProps } = props;
  const onAction = (id: Key) => {
    if (entries) {
      entries?.find((entry) => entry.id === id)?.onClick?.();
    } else if (sections) {
      const flatEntries: MenuEntry[] = [];
      sections.forEach((section) => flatEntries.push(...section.entries));
      flatEntries.find((entry) => entry.id === id)?.onClick?.();
    }
  };

  // Build the children that we'll feed into the MenuLogicWrapper
  let menu: ComponentProps<typeof MenuLogicWrapper>['children'];
  if (entries) {
    menu = <Section>{entries.map(renderItem)}</Section>;
  } else if (sections) {
    menu = sections.map(renderSection);
  } else {
    throw Error('<Menu>: No `entries` or `sections` provided');
  }

  let disabledKeys: MenuEntryID[] = [];
  if (entries) {
    disabledKeys = entries.flatMap((entry) => (entry.disabled ? [entry.id] : []));
  } else if (sections) {
    disabledKeys = sections.flatMap((section) =>
      section.entries.flatMap((entry) => (entry.disabled ? [entry.id] : []))
    );
  }

  return (
    <MenuLogicWrapper
      aria-label={props['aria-label'] ?? 'menu'}
      disabledKeys={disabledKeys}
      {...menuProps}
      onAction={onAction}
    >
      {menu}
    </MenuLogicWrapper>
  );
}

// WARNING! Do not attempt to abstract this out into a component instead of a function until you've
// read this directory's readme, its suggested reading, and want to go down the rabbit hole
// of React Stately's Collection Components and `getCollectionNode`.
const renderItem = (item: MenuEntry) => {
  let textColor = 'text-type-primary';
  if (item.disabled) textColor = 'text-type-inactive';
  else if (item.type === 'destructive') textColor = 'text-type-delete';
  return (
    <Item key={item.id} textValue={item.label}>
      <div
        data-cy={`menu-item-content-${item.id}`}
        className={[
          'flex min-w-[180px] items-center gap-2',
          'bg-background-primary p-2 outline-none focus:bg-selection-hover',
          textColor,
          item.disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-selection-hover',
        ].join(' ')}
      >
        {item.startAdornment}
        <div className="flex w-full flex-col gap-0.5">
          <div className="type-body1">{item.label}</div>
          {item.description && <div className="type-label">{item.description}</div>}
        </div>
        {item.endAdornment}
      </div>
    </Item>
  );
};

// WARNING! Do not attempt to abstract this out into a component instead of a function until you've
// read this directory's readme, its suggested reading, and want to go down the rabbit hole
// of React Stately's Collection Components and `getCollectionNode`.
const renderSection = (section: MenuSection, index: number) => {
  return (
    <Section
      aria-label={section['aria-label']}
      key={section.label ?? section['aria-label'] ?? index}
      title={
        section.label ? (
          <div className="flex min-w-[180px] cursor-default items-center bg-background-1 p-2">
            <div className="type-label">{section.label}</div>
          </div>
        ) : undefined
      }
    >
      {section.entries.map(renderItem)}
    </Section>
  );
};
