import { FC } from 'react';

type AddUploadDragSVGProps = {
  backgroundImage: string;
  svgClassName: string;
};

const AddUploadDragSVG: FC<AddUploadDragSVGProps> = ({ backgroundImage, svgClassName }) => (
  <div
    style={{
      backgroundImage,
    }}
    className={svgClassName}
  />
);

export default AddUploadDragSVG;
