import { FC, useEffect, useState } from 'react';
import * as React from 'react';

import { Typography } from '@material-ui/core';
import { AddComment as AddCommentIcon, History as HistoryIcon } from '@material-ui/icons';

import { MentionableTextInput, UserReportCommentInput } from '../../../../../generated/graphql';
import theme, { withStyles } from '../../../../../theme/komodo-mui-theme';
import CommentDisplay from '../../../../Comments/CommentDisplay/CommentDisplay';
import { UserReportComment } from '../../../../ReportsTab/ReportHooks';
import {
  NOTES_COL_WIDTH_COLLAPSED,
  NOTES_COL_WIDTH_EXPANDED,
  ROW_HEIGHT,
} from '../CostReportListRowStyles';

import NoteCellCommentWrapper from './NoteCellCommentWrapper';
import NoteCellIconMenu from './NoteCellIconMenu';
import { styles } from './NoteCellStyles';
import {
  calcCommentLineCostsChanged,
  getMentionInput,
  setIsAddingNoteClick,
} from './NoteCellUtils';
import NoteIcon from './NoteIcon';

const NoteCell: FC<{
  classes: Classes<typeof styles>;
  userCommentWithCosts: Pick<UserReportComment, 'comment' | 'costs'> | undefined;
  isNotesColumnExpanded: boolean;
  setNotesColumnExpanded: (v: boolean) => void;
  isAddingNoteInRow: boolean;
  setIsAddingNote: (v: boolean) => void;
  canDeleteUserReportComment: boolean;
  projectID: UUID;
  currentReportID?: UUID;
  userReportCommentInput?: Omit<UserReportCommentInput, 'text'>;
  canAddNote: boolean;
  isCurrentReportShared: boolean;
  collapsedChildCommentCount?: number;
  isPrint?: boolean;
  isVarianceReport?: boolean;
}> = ({
  classes,
  userCommentWithCosts,
  isNotesColumnExpanded,
  setNotesColumnExpanded,
  isAddingNoteInRow,
  setIsAddingNote,
  canAddNote = false,
  canDeleteUserReportComment,
  projectID,
  currentReportID,
  userReportCommentInput,
  collapsedChildCommentCount,
  isPrint = false,
  isCurrentReportShared = false,
  isVarianceReport = true,
}) => {
  const [isNotesCellHovered, setNotesCellHovered] = useState(false);
  const [previousReportID, setPreviousReportID] = useState(currentReportID);
  const expandNotes = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation(); // we don't want to expand the whole report line if someone clicks on the comment indicator
    setNotesColumnExpanded(true);
  };

  // ref for scrolling this cell into view
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // collapsing the notes column should close the "add note" interface
    if (!isNotesColumnExpanded) {
      setIsAddingNote(false);
    }
  }, [isNotesColumnExpanded, setIsAddingNote]);

  const userComment = userCommentWithCosts?.comment;
  const haveCommentLineCostsChanged = calcCommentLineCostsChanged(
    userCommentWithCosts,
    userReportCommentInput
  );

  const mentions = getMentionInput(userComment?.text?.mentions);

  const [commentInput, setComment] = useState({
    contents: userComment?.text?.contents || '',
    mentions,
  } as MentionableTextInput);
  const { contents } = commentInput;
  const hasContent = !!contents;
  const commentIsAlreadyCreated = !!userComment && !!userComment.id;

  // if the user closes the current report or switches to a new report
  // then reset the comment text
  useEffect(() => {
    if (previousReportID !== currentReportID) {
      setComment({
        contents: userComment?.text?.contents || '',
        mentions,
      } as MentionableTextInput);
      setPreviousReportID(currentReportID);
    }
  }, [previousReportID, currentReportID, userComment, mentions]);

  // text content
  const text = hasContent ? (
    <CommentDisplay
      className={classes.mentionStyle}
      mentions={userComment?.text?.mentions ?? undefined}
      title={contents}
    />
  ) : undefined;

  const showCommentIndicator = !!collapsedChildCommentCount || hasContent;
  const showAddNoteLink =
    canAddNote && isNotesColumnExpanded && !isAddingNoteInRow && isNotesCellHovered && !hasContent;

  // note cell alignment, based on cell contents
  let justifyContent = 'flex-end';
  if (showCommentIndicator) justifyContent = 'center';
  if (isNotesColumnExpanded) justifyContent = 'flex-start';

  return (
    <div
      className={classes.reportNoteCell}
      style={{
        minHeight: !isPrint ? ROW_HEIGHT : undefined,
        width: isNotesColumnExpanded ? NOTES_COL_WIDTH_EXPANDED : NOTES_COL_WIDTH_COLLAPSED,
        justifyContent,
        background: hasContent && isVarianceReport ? theme.palette.backgroundWhite : undefined,
      }}
      onMouseEnter={() => setNotesCellHovered(true)}
      onFocus={() => setNotesCellHovered(true)}
      onMouseLeave={() => {
        setNotesCellHovered(false);
      }}
      data-cy={!commentIsAlreadyCreated ? 'create-ReportNote' : undefined}
      onBlur={() => setNotesCellHovered(false)}
      ref={ref}
    >
      <NoteIcon
        collapsedChildCommentCount={collapsedChildCommentCount}
        commentContents={commentInput?.contents}
        hasCollapsedChildComments={!!collapsedChildCommentCount}
        showAddNoteLink={showAddNoteLink}
        hasContent={hasContent}
        isAddingNoteInRow={isAddingNoteInRow}
        isExpanded={isNotesColumnExpanded}
        onClick={expandNotes}
      />

      {showAddNoteLink && (
        <div
          className={classes.addNoteLink}
          onClick={(e) =>
            setIsAddingNoteClick(e, true, isAddingNoteInRow, isPrint, setIsAddingNote, ref)
          }
          onKeyDown={(e) =>
            setIsAddingNoteClick(e, true, isAddingNoteInRow, isPrint, setIsAddingNote, ref)
          }
        >
          <AddCommentIcon className={classes.addNoteIcon} />
          <Typography className={classes.noteText} variant="body1">
            Add a note
          </Typography>
        </div>
      )}

      {isNotesColumnExpanded && !isAddingNoteInRow && hasContent && (
        <div
          className={classes.noteContainer}
          onDoubleClick={(e) =>
            setIsAddingNoteClick(e, true, isAddingNoteInRow, isPrint, setIsAddingNote, ref)
          }
        >
          <Typography
            data-cy="comment-ReportNote"
            className={`${classes.noteText} ${
              haveCommentLineCostsChanged ? classes.costsChangedText : ''
            }`}
            variant="body1"
          >
            {haveCommentLineCostsChanged && (
              <HistoryIcon data-cy="icon-CostChanged" className={classes.costsChangedIcon} />
            )}
            {text}
          </Typography>
          {!isPrint && (
            <NoteCellIconMenu
              canDeleteUserReportComment={canDeleteUserReportComment}
              currentReportID={currentReportID}
              projectID={projectID}
              setIsAddingNote={setIsAddingNote}
              userComment={userComment}
              viewParameters={userReportCommentInput?.viewParameters}
              setComment={setComment}
              setNotesCellHovered={setNotesCellHovered}
            />
          )}
        </div>
      )}

      {!isPrint && (
        <NoteCellCommentWrapper
          commentInput={commentInput}
          currentReportID={currentReportID}
          isAddingNoteInRow={isAddingNoteInRow}
          isNotesColumnExpanded={isNotesColumnExpanded}
          projectID={projectID}
          setComment={setComment}
          setIsAddingNote={setIsAddingNote}
          userCommentWithCosts={userCommentWithCosts}
          userReportCommentInput={userReportCommentInput}
          isCurrentReportShared={isCurrentReportShared}
          isVarianceReport={isVarianceReport}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(NoteCell);
