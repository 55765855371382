// Constants of known feature flags. These should directly reflect those in scalar/featureflag.go

export const PROJECT_COMPS = 'PROJECT_COMPS';
export const FORECASTED_COST_REPORT = 'FORECASTED_COST_REPORT';
export const NS_PROCORE_CHANGE_EVENT_INTEGRATION = 'NS_PROCORE_CHANGE_EVENT_INTEGRATION';
export const YC_ITEM_SHARING = 'YC_ITEM_SHARING';
export const YC_SCENARIO_SHARING = 'YC_SCENARIO_SHARING';
export const YC_INSIGHTS_V2 = 'YC_INSIGHTS_V2';
export const DD_NEW_NAV = 'DD_NEW_NAV';
export const NS_REPORT_DISTRIBUTION = 'NS_REPORT_DISTRIBUTION';
export const DD_WT_SILVER_BULLET = 'DD_WT_SILVER_BULLET';
export const DD_WT_SILVER_BULLET_TOGGLE = 'DD_WT_SILVER_BULLET_TOGGLE';
export const CT_AP_ITEM_SUGGEST_DEMO = 'CT_AP_ITEM_SUGGEST_DEMO';
export const DD_PERMISSION_ROLES_NAVIGATION = 'DD_PERMISSION_ROLES_NAVIGATION';
export const CT_ITEM_ASSIST_EXPERIMENT = 'CT_ITEM_ASSIST_EXPERIMENT';
export const NS_OWNER_COSTS = 'NS_OWNER_COSTS';
export const DD_PERMISSION_ROLES_CREATE_DELETE = 'DD_PERMISSION_ROLES_CREATE_DELETE';
