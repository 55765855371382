import { FC } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import CTAIconButton from '../shared-widgets/CTAIconButton';

import DeactivatedPageStyles from './DeactivatedPageStyles';

type DeactivatedPageProps = {
  classes: Classes<typeof DeactivatedPageStyles>;
};

const DeactivatedPage: FC<DeactivatedPageProps> = ({ classes }) => {
  const { logout } = useAuth0();

  return (
    <div className={classes.container}>
      <div className={classes.valuePropsContainer}>
        <img
          className={classes.smallLogo}
          alt="logo"
          src="https://s3.us-east-2.amazonaws.com/joincad-assets-prod/logo/join-logo-2019.png"
        />
        <div className={classes.messagesContainer}>
          <div className={classes.deniedMessageContainer}>
            <Typography className={classes.deniedMessage} data-cy="access-Deactivated">
              YOUR ACCESS IS CURRENTLY DEACTIVATED.
            </Typography>
          </div>
          <div className={classes.contactMessageContainer}>
            <Typography className={classes.deniedMessage}>
              Please contact your company administrator to restore access.
            </Typography>
          </div>
          <div className={classes.logout}>
            <CTAIconButton
              onClick={() => logout({ returnTo: window.location.origin })}
              buttonText="Log out"
            />
          </div>
          <div className={classes.supportMessageContainer}>
            <Typography className={classes.deniedMessage}>
              If you think this is an error or reoccurring issue, please contact
              <span
                className={classes.deniedMessage}
                style={{ color: '#3F5B9C', display: 'inline' }}
              >
                &nbsp;support@join.build&nbsp;
              </span>
              for help.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withStyles(DeactivatedPageStyles)(DeactivatedPage);
