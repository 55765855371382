import { useEffect, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Divider } from '@material-ui/core';

import { itemSidebarForecastingOpenVar } from '../../../../api/apollo/reactiveVars';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ItemsFilters from '../../../frame/AdvancedFiltersSidebar/ItemsFilters';
import ProjectsFilters from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import { activeFiltersCountItems } from '../../../HomeTab/Search/items/activeFilterCountItems';
import { activeFiltersCountProjects } from '../../../HomeTab/Search/projects/activeFilterCountProjects';
import { useFilterOptions } from '../../../HomeTab/Search/projects/SearchProjectsData';
import SearchHeader from '../../../HomeTab/Search/SearchHeader';
import { SearchMode } from '../types';

import ForecastingItemSidebarWrapper from './ForecastingItemSidebarWrapper';
import ForecastingItemsList from './ForecastingItemsList';
import { useForecastingItemsFilterOptions } from './hooks/useForecastingItemsFilterOptions';
import { useForecastingItemsQuery } from './hooks/useForecastingItemsQuery';
import useForecastingItemsQueryParams from './hooks/useForecastingItemsQueryParams';

type Props = {
  isFilterMenuOpen: boolean;
  onChangeSearchMode: (mode: SearchMode) => void;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const ForecastingItemsListData = (props: Props) => {
  const [isProjectsSectionExpanded, setExpandedProjectFiltersSection] = useState(true);
  const [isItemsSectionExpanded, setExpandedItemFiltersSection] = useState(true);

  const params = useForecastingItemsQueryParams();

  const itemSidebarForecastingID = useReactiveVar(itemSidebarForecastingOpenVar);

  const { data, fetchMore, loading, previousData } = useForecastingItemsQuery(params.variables);
  const filterOptions = useForecastingItemsFilterOptions(
    data?.forecastingItems?.aggregations ?? previousData?.forecastingItems?.aggregations,
    params.variables.filters
  );

  const projectsFilterOptions = useFilterOptions(true, params.variables.projectsFilters);

  const onOpenItemSidebar = (id: string) => {
    if (itemSidebarForecastingID === id) {
      itemSidebarForecastingOpenVar(null);
    } else {
      if (props.isFilterMenuOpen) props.onToggleFilterMenu(!props.isFilterMenuOpen);
      itemSidebarForecastingOpenVar(id);
    }
  };

  useEffect(() => {
    return () => {
      // Close item sidebar before component is unmounted
      itemSidebarForecastingOpenVar(null);
    };
  }, []);

  const activeFiltersCount = useMemo(() => {
    return (
      activeFiltersCountProjects(params.variables.projectsFilters) +
      activeFiltersCountItems(params.variables.filters)
    );
  }, [params.variables.projectsFilters, params.variables.filters]);

  return (
    <>
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        onChangeSearch={params.onChangeSearch}
        onChangeSearchMode={props.onChangeSearchMode}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        searchMode={SearchMode.ITEMS}
      />
      <Divider />
      <div className="flex overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={projectsFilterOptions}
              filterValues={params.variables.projectsFilters}
              isExpanded={isProjectsSectionExpanded}
              onChangeFilters={params.onChangeProjectsFilters}
              onClickSectionName={() =>
                setExpandedProjectFiltersSection(!isProjectsSectionExpanded)
              }
            />
            <ItemsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded={isItemsSectionExpanded}
              onChangeFilters={params.onChangeFilters}
              onClickSectionName={() => setExpandedItemFiltersSection(!isItemsSectionExpanded)}
            />
          </AdvancedFilters>
        )}
        <div className="overflow-x-auto">
          <ForecastingItemsList
            items={data?.forecastingItems?.data ?? previousData?.forecastingItems?.data ?? []}
            loading={loading}
            onFetchMore={fetchMore}
            onOpenItemSidebar={onOpenItemSidebar}
            searchText={params.variables.search}
            sortManager={params.sortManager}
            totalResults={data?.forecastingItems?.total}
          />
        </div>
        {itemSidebarForecastingID && (
          <ForecastingItemSidebarWrapper
            items={data?.forecastingItems?.data ?? previousData?.forecastingItems?.data ?? []}
            itemID={itemSidebarForecastingID}
          />
        )}
      </div>
    </>
  );
};

export default ForecastingItemsListData;
