import { FC, useState } from 'react';
import { isEmpty } from 'validator';

import { TextField, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import ErrorMessage from '../../../../table/categorization/ErrorMessage';

import AddToAMilestoneSelectionStyles from './AddToAMilestoneSelectionStyles';

type AddToMilestoneCategorizationNameProps = {
  classes: Classes<typeof AddToAMilestoneSelectionStyles>;
  errorMessage?: string;
  name: string;
  onChange: (value: string) => void;
  setErrorMessage: (message: string | undefined) => void;
};

const AddToMilestoneCategorizationName: FC<AddToMilestoneCategorizationNameProps> = ({
  classes,
  errorMessage,
  name,
  onChange,
  setErrorMessage,
}) => {
  const [categorizationName, setCategorizationName] = useState(name);

  const label = 'Name the work breakdown structure in your new estimate *';
  const placeholder = `eg. 'Bid Package' or 'Bid Category'`;
  const caption = 'Note: You can modify the custom category name in the settings at any time';

  const handleError = (value: string) => {
    if (!isEmpty(value, { ignore_whitespace: true })) {
      setErrorMessage(undefined);
    }
  };

  return (
    <div className={`${classes.vertical} ${classes.topPadding}`}>
      <div className="type-label">{label}</div>
      <TextField
        data-cy="input-new-categorization-name"
        value={name}
        inputRef={(input) => {
          if (input) {
            input.focus();
          }
        }}
        InputProps={{
          classes: {
            root: classes.editingLabel,
            input: classes.editingLabel,
          },
          className: errorMessage ? classes.error : undefined,
          disableUnderline: true,
          startAdornment: !!errorMessage && <ErrorOutlineIcon className={classes.errorIcon} />,
        }}
        fullWidth
        id="name"
        multiline={false}
        placeholder={placeholder}
        onChange={({ target: { value } }) => {
          setCategorizationName(value);
          onChange(value);
          handleError(value);
        }}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter' && evt.target instanceof HTMLElement) {
            evt.target.blur();
          }
        }}
        onBlur={() => {
          onChange(categorizationName);
        }}
      />
      <Typography className={classes.caption}>{caption}</Typography>
      <ErrorMessage message={errorMessage} />
    </div>
  );
};

export default withStyles(AddToAMilestoneSelectionStyles)(AddToMilestoneCategorizationName);
