import { FC } from 'react';

import { Link as LinkIcon } from '@material-ui/icons';

import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { IconButton } from '../../scales';

import ItemActivityFeedReportSelector from './ItemActivityFeedReportSelector';
import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

type Props = {
  selectedReportType: ItemActivityFeedReportType;
  setReportType: (reportType: ItemActivityFeedReportType) => void;
};

const ItemActivityFeedHeader: FC<Props> = ({ selectedReportType, setReportType }) => {
  const copyLinkButton = (
    <div className="ml-auto mt-6 flex" data-cy="item-activity-feed-copy-link-button">
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setToast({ message: 'Link copied to clipboard.' });
        }}
        aria-label="Copy Link"
        type="secondary"
        icon={<LinkIcon />}
      />
    </div>
  );

  const headerText = <div className="pt-4 type-heading1"> Item Activity Feed</div>;

  return (
    <div className="sticky inset-x-0 top-12 mx-auto flex w-full justify-center bg-background-primary pb-2 shadow-md">
      <div className="mx-auto flex w-full max-w-[850px] flex-col">
        <div className="flex w-full flex-row" data-cy="item-activity-feed-header">
          {headerText}
          {copyLinkButton}
        </div>
        <ItemActivityFeedReportSelector
          selectedReportType={selectedReportType}
          setReportType={setReportType}
        />
      </div>
    </div>
  );
};

export default ItemActivityFeedHeader;
