import { FC } from 'react';

import { Typography } from '@material-ui/core';
import Https from '@material-ui/icons/Https';

import { YC_ITEM_SHARING } from '../../../features';
import { Visibility } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { GroupsIcon } from '../../Icons/GroupsIcon';
import ShareItemButton from '../../ItemsList/ItemsSharing/ShareItemButton/ShareItemButton';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button, Chip } from '../../scales';
import { isPrivateVisibility } from '../ItemsUtils';

import ItemVisibilityToggleStyles from './ItemVisibilityToggleStyles';
import {
  DISABLE_VISIBILITY_TOGGLE_TOOLTIP,
  DRAFT_ITEM_ICON_COPY_NEW,
  DRAFT_ITEM_LINK_COPY_NEW,
  DRAFT_ITEM_TOOLTIP,
  PRIVATE_ITEM_ICON_COPY,
  PRIVATE_ITEM_LINK_COPY,
  PUBLIC_ITEM_ICON_COPY,
  PUBLISH_ITEM_ICON_COPY_NEW,
  PUBLISH_ITEM_LINK_COPY,
  PUBLISH_ITEM_LINK_COPY_NEW,
  PUBLISH_ITEM_TOOLTIP,
} from './ItemVisibilityToggleUtils';

type ItemVisibilityToggleProps = {
  classes: Classes<typeof ItemVisibilityToggleStyles>;
  displayButton?: boolean;
  isDisabled: boolean;
  itemID?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (event: any) => void;
  visibilitySetting: Visibility;
  setShowPublishItemDialog?: (setShowPublishItemDialog: boolean) => void;
  setVisibilitySetting: (visibility: Visibility) => void;
};

const ItemVisibilityToggle: FC<ItemVisibilityToggleProps> = ({
  classes,
  displayButton,
  isDisabled,
  itemID,
  onChange,
  visibilitySetting,
  setShowPublishItemDialog,
  setVisibilitySetting,
}) => {
  const isItemSharingFeature = useHasFeature(YC_ITEM_SHARING);
  const isPrivate = isPrivateVisibility(visibilitySetting);
  let linkCopy: string;
  if (isItemSharingFeature) {
    linkCopy = isPrivate ? PUBLISH_ITEM_LINK_COPY_NEW : DRAFT_ITEM_LINK_COPY_NEW;
  } else {
    linkCopy = isPrivate ? PUBLISH_ITEM_LINK_COPY : PRIVATE_ITEM_LINK_COPY;
  }
  let iconCaptionCopy;
  if (isItemSharingFeature) {
    iconCaptionCopy = isPrivate ? DRAFT_ITEM_ICON_COPY_NEW : PUBLISH_ITEM_ICON_COPY_NEW;
  } else {
    iconCaptionCopy = isPrivate ? PRIVATE_ITEM_ICON_COPY : PUBLIC_ITEM_ICON_COPY;
  }
  const toolTip = isPrivate ? DRAFT_ITEM_TOOLTIP : PUBLISH_ITEM_TOOLTIP;

  const icon = isPrivate ? <Https /> : <GroupsIcon />;
  const updateVisibility = (visibility: Visibility) => {
    if (setShowPublishItemDialog) {
      setShowPublishItemDialog(true);
    } else {
      setVisibilitySetting(visibility);
      onChange({ visibility });
    }
  };

  const onChangeInputVisibility = () => {
    if (isPrivateVisibility(visibilitySetting)) {
      updateVisibility(Visibility.PUBLISHED);
    } else {
      updateVisibility(Visibility.PRIVATE_DRAFT);
    }
  };

  const visibilityIcon = (
    <div className={classes.flex}>
      {!isItemSharingFeature && <div className={`${classes.togglePadding}`}>{icon}</div>}
      <NormalTooltip title={toolTip}>
        <div className={classes.togglePadding}>
          {isItemSharingFeature ? (
            <Chip data-cy="import-private-feedback" icon={icon} text={iconCaptionCopy} />
          ) : (
            <Typography className={classes.text}>{iconCaptionCopy}</Typography>
          )}
        </div>
      </NormalTooltip>
    </div>
  );

  if (isPrivate && displayButton && itemID && isItemSharingFeature) {
    if (setShowPublishItemDialog) {
      return (
        <ShareItemButton
          isDisabled={isDisabled}
          itemID={itemID}
          itemIDs={[itemID]}
          setShowPublishItemDialog={setShowPublishItemDialog}
        />
      );
    }
    return (
      <Button
        data-cy="publish-draft-button"
        label="Publish Draft"
        onClick={onChangeInputVisibility}
        type="primary"
      />
    );
  }

  return (
    <div className={classes.flex}>
      {isDisabled ? (
        <NormalTooltip title={DISABLE_VISIBILITY_TOGGLE_TOOLTIP}>{visibilityIcon}</NormalTooltip>
      ) : (
        <>
          {visibilityIcon}
          <div
            className={classes.togglePadding}
            data-cy="toggle-itemVisibility"
            tabIndex={0}
            role="button"
            onClick={onChangeInputVisibility}
            onKeyPress={onChangeInputVisibility}
          >
            <Typography className={`${classes.text} ${classes.toggleText}`}>{linkCopy}</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(ItemVisibilityToggleStyles)(ItemVisibilityToggle);
