import { useTitleHeight } from '../api/apollo/reactiveVars';
import { DD_NEW_NAV } from '../features';

import { useHasFeature } from './useFeatureQuery';

export default function usePageHeight() {
  const hasNewNav = useHasFeature(DD_NEW_NAV);
  const titleHeight = useTitleHeight();

  if (hasNewNav) return '100%';

  return `calc(100vh - ${titleHeight}px)`;
}
