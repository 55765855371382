import { ItemAssetsQuery, ItemAssetsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { itemAssetsQuery } from './queries';

const useItemAssetsQuery = (id: UUID | null | undefined) =>
  useQuery<ItemAssetsQuery, ItemAssetsQueryVariables>(itemAssetsQuery, {
    variables: { id },
    skip: !id,
  });

export default useItemAssetsQuery;
