import {
  LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables,
  LoadScenariosSummaryByMilestoneIdQuery,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../../utilities/costMode';

import { loadScenariosSummaryByMilestoneIDQuery } from './queries';

const useLoadScenariosSummaryByMilestoneIDQuery = (milestoneID?: string) =>
  useQuery<
    LoadScenariosSummaryByMilestoneIdQuery,
    LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables
  >(loadScenariosSummaryByMilestoneIDQuery, {
    variables: { milestoneID, costMode: costModeVar() },
    skip: !milestoneID,
  });

export default useLoadScenariosSummaryByMilestoneIDQuery;
