import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import { itemSidebarHomeOpenVar } from '../../../api/apollo/reactiveVars';
import { JoinRoutes } from '../../../api/gqlEnums';
import useLocalStorage from '../../../hooks/useLocalStorage';
import usePageHeight from '../../../hooks/usePageHeight';
import { generateSharedPath } from '../../../utilities/routes/links';
import useProjectsCountsQuery from '../../ProjectsList/hooks/useProjectsCountsQuery';

import useCoreSearchPermissions from './hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from './hooks/useSendSearchAnalytics';
import SearchItemsData from './items/SearchItemsData';
import NoProjectsPage from './NoProjectsPage';
import SearchProjectsData from './projects/SearchProjectsData';
import { SearchMode } from './types';

export default function Search() {
  useSetDocumentTitle();
  const [isFilterMenuOpen, setFilterMenuOpen] = useLocalStorage('SEARCH_FILTERS_OPEN', true);

  const searchMode = useCurrentSearchMode();
  const { hasItemsAccess } = useCoreSearchPermissions();
  const hasNoProjects = useUserHasNoProjects();
  const sendSearchAnalytics = useSendSearchAnalytics();

  const navigate = useNavigate();

  // Backcompat for moving to the /items route. Remove me with DD_NEW_NAV
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.get('mode') === 'items') {
      search.delete('mode');
      navigate(generateSharedPath(JoinRoutes.ITEMS, { search: `?${search.toString()}` }));
    }
  }, [navigate]);

  const onChangeSearchMode = useCallback(
    (mode: SearchMode) => {
      sendSearchAnalytics(searchEventTypes.SEARCH_MODE_CTA, { mode, Location: 'Home' });
      const routeKey = mode === SearchMode.PROJECTS ? JoinRoutes.PROJECTS : JoinRoutes.ITEMS;
      navigate(generateSharedPath(routeKey));
    },
    [navigate, sendSearchAnalytics]
  );

  const onToggleFilterMenu = useCallback(() => {
    itemSidebarHomeOpenVar(null);
    setFilterMenuOpen((prevState) => !prevState);
  }, [setFilterMenuOpen]);

  const pageHeight = usePageHeight();

  if (hasNoProjects) return <NoProjectsPage />;

  let SearchComponent = SearchProjectsData;
  if (searchMode === SearchMode.ITEMS && hasItemsAccess) {
    SearchComponent = SearchItemsData;
  }

  return (
    <div
      // TODO: Cleanup when we're fully onto the new nav. We'll only need an `h-full` className then.
      className="flex flex-col bg-background-primary"
      style={{ height: pageHeight }}
    >
      <SearchComponent
        isFilterMenuOpen={isFilterMenuOpen}
        onChangeSearchMode={onChangeSearchMode}
        onToggleFilterMenu={onToggleFilterMenu}
      />
    </div>
  );
}

const useSetDocumentTitle = () => {
  useEffect(() => {
    document.title = 'Projects';
  }, []);
};

const useUserHasNoProjects = () => {
  const { data, loading } = useProjectsCountsQuery();
  return (
    !loading && !data?.projectsCounts.allProjectsCount && !data?.projectsCounts.myProjectsCount
  );
};

const useCurrentSearchMode = (): SearchMode => {
  const isItemsSearch = window.location.pathname === generateSharedPath(JoinRoutes.ITEMS);
  return isItemsSearch ? SearchMode.ITEMS : SearchMode.PROJECTS;
};
