import { FC } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import {
  DIRECT_COSTS,
  DRAW_MARKUPS,
  ITEM_MARKUPS,
  LUMP_SUM_DISPLAY,
  MARKUPS,
  MILESTONE_MARKUPS,
  PERCENT_DISPLAY,
  S3,
  SUBTOTAL,
} from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { constantCountLabel } from '../../../utilities/string';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ScrollContainer } from '../../scales';
import CostImpact from '../../shared-widgets/CostImpact';

import ItemSidebarCollapseEstimateHeader from './ItemSidebarCollapseEstimateHeader';
import ItemSidebarCollapseEstimateTooltip from './ItemSidebarCollapseEstimateTooltip';
import ItemSidebarStyles from './ItemSidebarStyles';
import {
  FormattedLine,
  MarkupLine,
  getItemEstimateLines,
  getMarkupsLines,
  isToggleDisabled,
} from './ItemSidebarUtils';

type ItemCost = CostScalar | CostRange;

type ItemSidebarCollapseEstimateProps = {
  classes: Classes<typeof ItemSidebarStyles>;
  activeEstimate: NonNullable<Item['activeEstimate']>;
  canViewMarkups: boolean;
  maxHeight: number;
  totalCost?: ItemCost;
};

const ItemSidebarCollapseEstimate: FC<ItemSidebarCollapseEstimateProps> = ({
  classes,
  activeEstimate,
  maxHeight,
  canViewMarkups,
  totalCost,
}) => {
  // DATA
  const {
    fields,
    lines,
    markups,
    inheritedMarkups,
    contingencyDraws,
    drawSubtotal,
    inheritedSubtotal,
    markupSubtotal,
    subtotal,
  } = activeEstimate;

  const estimateLines = getItemEstimateLines(lines, fields);
  const markupsLines = getMarkupsLines(MARKUPS, markups);
  const inheritedMarkupsLines = getMarkupsLines(MILESTONE_MARKUPS, inheritedMarkups);

  const contingencyLines: MarkupLine[] = contingencyDraws
    ? contingencyDraws?.map((c) => {
        return {
          cost: formatCost(c.draw),
          markupReferences: S3,
          markupType: c.isDrawingFullAmount ? PERCENT_DISPLAY : LUMP_SUM_DISPLAY,
          markupValue: formatCost(c.draw),
          name: c.name,
        };
      })
    : [];

  // COMPONENTS
  const estimateDivider = (className: string) => <div className={className} />;
  const linesComponentDetailed = (lines: FormattedLine[], id = '') => (
    <div className={classes.linesContainer}>
      {lines.map((l: FormattedLine, i: number) => (
        <>
          <div className={classes.estimateLineContainer}>
            <div key={`${l.name}${l.cost}`} className={classes.estimateContent}>
              <Typography className={classes.estimateSubtext}>{l.name}</Typography>
              <div className={classes.estimateLineInfo}>
                <NormalTooltip title={<ItemSidebarCollapseEstimateTooltip id={id} line={l} />}>
                  <IconButton className={classes.estimateLineInfoHover}>
                    <MoreHoriz className={classes.estimateTooltipIcon} />
                  </IconButton>
                </NormalTooltip>
                <Typography className={`${classes.estimateSubtext} ${classes.cost}`}>
                  {l.cost}
                </Typography>
              </div>
            </div>
          </div>
          {i !== lines.length - 1 && estimateDivider(classes.estimateDivider)}
        </>
      ))}
    </div>
  );
  const linesComponent = linesComponentDetailed;

  const costComponent = (cost: USCents) => (
    <Typography className={classes.cost}>{formatCost(cost, { showZeroCents: true })}</Typography>
  );
  const subtotalComponent = (cost: USCents) => (
    <div className={classes.linesContainer}>
      <div className={`${classes.estimateContent} ${classes.estimateHeaderFooter}`}>
        <Typography className={classes.estimateSubtext}>{SUBTOTAL}</Typography>
        <Typography className={classes.cost}>
          {formatCost(cost, { showZeroCents: true })}
        </Typography>
      </div>
    </div>
  );
  const totalCostComponent = (cost: ItemCost) => (
    <div className={classes.linesContainer}>
      <div className={`${classes.estimateContent} ${classes.estimateHeaderFooter}`}>
        <Typography className={classes.estimateTitle}>Total Cost Impact</Typography>
        <CostImpact value={cost as Cost} signed={false} />
      </div>
    </div>
  );

  const getHeader = (id: string, lines: FormattedLine[], cost: USCents) => (
    <ItemSidebarCollapseEstimateHeader
      cost={costComponent(cost)}
      disabled={isToggleDisabled(lines.length)}
      lines={linesComponent(lines, id)}
      name={constantCountLabel(id, lines.length)}
      subtotal={subtotalComponent(cost)}
    />
  );

  return (
    <ScrollContainer
      className={classes.estimateContentContainer}
      direction="vertical"
      style={{ maxHeight }}
    >
      {getHeader(DIRECT_COSTS, estimateLines, subtotal)}
      {estimateDivider(classes.estimateSectionDivider)}
      {canViewMarkups && markupsLines && getHeader(ITEM_MARKUPS, markupsLines, markupSubtotal)}
      {estimateDivider(classes.estimateSectionDivider)}
      {canViewMarkups && getHeader(MILESTONE_MARKUPS, inheritedMarkupsLines, inheritedSubtotal)}
      {canViewMarkups && getHeader(DRAW_MARKUPS, contingencyLines, drawSubtotal)}
      {estimateDivider(classes.estimateTotalDivider)}
      {totalCost && totalCostComponent(totalCost)}
    </ScrollContainer>
  );
};
export default withStyles(ItemSidebarStyles)(ItemSidebarCollapseEstimate);
