import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export function initializeSentry() {
  if (process.env.REACT_APP_SENTRY_RELEASE_TAG) {
    Sentry.init({
      dsn: 'https://dfccbe3151ad4670af924704edf76923@sentry.io/1395325',
      release: process.env.REACT_APP_SENTRY_RELEASE_TAG,
      build: process.env.REACT_APP_BUILD_NUMBER,
      environment: window.location.hostname,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded', // Ignore Safari browser errors
        'Error during service worker registration:', // the service worker checks the browser veresion of the app.  This error is that we are using the wrong MIME format of text/htttp in the content-type header
      ],
      integrations: [
        new BrowserTracing({
          tracingOrigins: [
            'http://localhost:3001',
            'https://test-infra-api.join.build',
            'https://master-staging-api.join.build',
            'https://staging-api.join.build',
            'https://api.join.build',
          ],
          beforeNavigate: (context) => {
            return {
              ...context,
              name: window.location.pathname.replace(
                /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
                ':uuid'
              ),
            };
          },
        }),
      ],
      tracesSampleRate: 0.02,
    });
  }
}

export function logErrorToSentry(e) {
  console.error(e);
  Sentry.captureException(e);
}
