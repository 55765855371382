import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Search } from '@material-ui/icons';

import { searchEventTypes } from '../../../../analytics/analyticsEventProperties';
import { GLOBAL_SEARCH_BUTTON } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import CTAIconButton from '../../../shared-widgets/CTAIconButton';
import useSendSearchAnalytics from '../hooks/useSendSearchAnalytics';

import GlobalSearchContent from './GlobalSearchContent';
import styles from './GlobalSearchContentStyles';

const GlobalSearch = () => {
  const sendSearchAnalytics = useSendSearchAnalytics();

  const [open, setOpen] = useState(false);
  useCloseOnLocationChange(open, () => setOpen(false)); // close search when on url change

  return (
    <>
      {/* we rely on an older button component to match the existing new project button */}
      <CTAIconButton
        buttonText="Search"
        icon={<Search />}
        isSmaller
        onClick={() => {
          setOpen(true);
          sendSearchAnalytics(searchEventTypes.SEARCH_SELECT);
        }}
        dataCy={GLOBAL_SEARCH_BUTTON}
      />
      {open && <GlobalSearchContent onClose={() => setOpen(false)} />}
    </>
  );
};

const useCloseOnLocationChange = (open: boolean, onClose: () => void) => {
  const location = useLocation();
  const [locationBeforeOpen, setLocationOnOpen] = useState(location);

  useEffect(() => {
    if (!open) setLocationOnOpen(location);
    else if (location !== locationBeforeOpen) onClose();
  }, [location, open, locationBeforeOpen, onClose]);
};

export default withStyles(styles)(GlobalSearch);
