import { useContext } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import { CostReportColumnType } from '../../../generated/graphql';
import { getCostReportByType, renderCostString } from '../../CostReport/CostReportUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

type Props = {
  costReports: MilestoneCostReport | MilestonesCostTrendsBreakdownReport | null;
  milestoneName?: string;
};

export const costFormatter = (value: Cost, isSigned?: boolean) =>
  renderCostString({
    cost: value,
    isExact: false,
    isWide: true,
    showZero: true,
    showCents: false,
    isSigned,
  });

const CostSummary = (props: Props) => {
  const { costReports } = props;
  const t = useContext(ProjectTermStore);

  if (!costReports) return null;

  // Costs...
  const runningTotal = getCostReportByType(
    costReports,
    CostReportColumnType.RUNNINGTOTAL_REPORT
  )?.range;
  const scenarioDeltaTotal = getCostReportByType(
    costReports,
    CostReportColumnType.SCENARIODELTA_REPORT
  )?.range;
  const estimate = getCostReportByType(costReports, CostReportColumnType.ESTIMATE_REPORT)?.range;
  const accepted = getCostReportByType(costReports, CostReportColumnType.ACCEPTED_REPORT)?.range;
  const target = getCostReportByType(costReports, CostReportColumnType.TARGET_REPORT)?.range;

  // If there is a scenario delta, its a scenario
  const hasScenarioDelta = !!scenarioDeltaTotal;
  const runningTotalTerm = t.titleCase(TermKey.RUNNING_TOTAL);
  const runningTotalText = hasScenarioDelta ? `Projected ${runningTotalTerm}` : runningTotalTerm;

  return (
    <div className="flex flex-grow break-inside-avoid flex-col truncate">
      <div className="flex h-7 items-center">
        {hasScenarioDelta ? (
          <>
            <div className="flex-grow type-heading3">Scenario Changes</div>
            <div className="type-heading2" data-cy="scenario-delta">
              {costFormatter(scenarioDeltaTotal, true)}
            </div>
          </>
        ) : (
          <>
            <div className="flex-grow truncate type-body1">
              {t.titleCase(TermKey.ESTIMATE)}
              {props.milestoneName && ` (${props.milestoneName})`}
            </div>
            <div className="pl-2 type-number">{costFormatter(estimate)}</div>
          </>
        )}
      </div>
      <div className="flex items-center pb-1">
        <div className="flex-grow text-item-status-accepted type-body1">Accepted Changes</div>
        <div className="text-item-status-accepted type-number">{costFormatter(accepted)}</div>
      </div>
      <div className="flex items-center border-t-2 border-button-primary py-1">
        <div className="flex-grow type-body1">{runningTotalText}</div>
        <div data-cy="cost-summary-running-total" className="type-number">
          {costFormatter(runningTotal)}
        </div>
      </div>
      <div className="h-7 shrink-0">
        {!hasScenarioDelta && (
          <div className="flex items-center border-t-2 border-button-primary pt-1">
            <div className="flex-grow text-type-link type-body1">{t.titleCase(TermKey.TARGET)}</div>
            <div className="text-type-link type-number">{costFormatter(target)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CostSummary;
