import { FC } from 'react';

import { CostReportColumnType } from '../../../generated/graphql';
import { getCostReportByType } from '../../CostReport/CostReportUtils';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import { SortManager } from '../../scales';
import { costFormatter } from '../common/CostSummary';
import { getScenarioName } from '../common/ScenariosUtils';
import { Scenario } from '../types';

import ApplyScenarioDialogContent from './ApplyScenarioDialogContent';

type ApplyScenarioDialogProps = {
  scenario: Scenario;
  milestoneName: string;
  onClose: () => void;
  isOpen: boolean;
  onApply: () => void;
  sortManager: SortManager;
};

const ApplyScenarioDialog: FC<ApplyScenarioDialogProps> = ({
  scenario,
  milestoneName,
  onClose,
  isOpen,
  onApply,
  sortManager,
}) => {
  // Cost values
  const scenarioDeltaTotal = getCostReportByType(
    scenario.costReports,
    CostReportColumnType.SCENARIODELTA_REPORT
  )?.range;

  const runningTotalText = costFormatter(scenarioDeltaTotal, true);

  // Color border styles
  const dialogClass = `flex w-full flex-grow overflow-y-scroll ${
    scenario.color ? `border-t-8` : ''
  }`;
  const borderColor = scenario.color ?? undefined;

  return (
    <JoinDialog
      dynamicHeight
      dialog={{
        actionButtons: [
          {
            isRightAligned: true,
            color: 'primary',
            dataCy: 'apply-button',
            onClick: onApply,
            text: 'Apply scenario',
            variant: 'contained',
          },
        ],
        borderColor,
        contentComponent: (
          <ApplyScenarioDialogContent
            items={scenario.items}
            costReports={scenario.costReports}
            sortManager={sortManager}
            milestoneName={milestoneName}
          />
        ),
        dialogClass,
        headerText: `Apply Scenario: ${getScenarioName(scenario)} (${runningTotalText})`,
        onClose,
        open: isOpen,
      }}
      hasDivider
    />
  );
};

export default ApplyScenarioDialog;
