import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    minimalTable: {
      width: '50%',
    },
    table: {
      width: '100%',
    },
  });

export default styles;
