import { TermKey } from '../../../../api/gqlEnums';
import {
  CHANGE_COST_EVENT,
  CHANGE_MILESTONE_EVENT,
  CHANGE_STATUS_EVENT,
  CREATE_ITEM_EVENT,
  DASHBOARD_TOOLTIP_LINE_CHARLIMIT,
  ITEM_DRAW_SET,
  ITEM_DRAW_UPDATE,
  ITEM_EVENT_ATTACH_OPTION,
  ITEM_EVENT_CREATE_OPTION,
  ITEM_EVENT_DETACH_OPTION,
  KIND_EVENT_DESCRIPTION_NEW_LINE,
  KIND_ITEM,
  KIND_OPTION,
  REMOVE_MILESTONE_EVENT,
} from '../../../../constants';
import { CostTrendlineEventsQuery, Status } from '../../../../generated/graphql';
import theme from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { getItemStatusLabel } from '../../../../utilities/item-status';
import { makeEventDescriptionTextObject } from '../../../../utilities/itemHistoryEvents';
import { pluralizeString } from '../../../../utilities/string';
import { generateCostImpactInfo } from '../../../Items/ItemsUtils';
import {
  SMALL,
  TRENDING_DOWN,
  TRENDING_FLAT,
  TRENDING_LOWER_BOUND_DOWN,
  TRENDING_LOWER_BOUND_UP,
  TRENDING_PENDING,
  TRENDING_UP,
  TRENDING_UPPER_BOUND_DOWN,
  TRENDING_UPPER_BOUND_UP,
} from '../../../ItemsList/ItemsIcons/ItemsIconsMap';

export type CostTrendlineEvents = CostTrendlineEventsQuery['costTrendlineEvents'];
export type CostTrendlineEvent = CostTrendlineEvents['events'][number];

export const getCostForEventItem = (cost?: Cost | null): Cost => cost ?? { value: '0' };

// create a list of options with seperators depending
// on the length of the list
export const makeOptionsList = (optionsList?: Option[] | ItemSummary[] | null) => {
  const options: (Option | ItemSummary | EventDescriptionText)[] = [];
  optionsList?.forEach((option, i) => {
    if (i === optionsList.length - 1 && optionsList.length !== 1) {
      options.push(makeEventDescriptionTextObject(' and '));
    } else if (i > 0 && optionsList.length !== 1) {
      options.push(makeEventDescriptionTextObject(', '));
    }
    options.push({ ...option, kind: KIND_OPTION });
  });
  return options;
};

// If there are too many characters in the description
// then insert line breaks as needed
const insertLineBreaks = (eventDescriptions: EventDescription[]) => {
  const descriptions = eventDescriptions.slice();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const totals = descriptions.map((d: any) => (d.text && d.text.length) || 0);
  // if the length of the current line is greater than
  // the limit then insert a new line
  let total = 0;
  let offset = 0;
  totals.forEach((t: number, i: number) => {
    total += t;
    if (total > DASHBOARD_TOOLTIP_LINE_CHARLIMIT) {
      descriptions.splice(i + offset, 0, {
        kind: KIND_EVENT_DESCRIPTION_NEW_LINE,
      });
      offset += 1;
      total = t;
    }
  });
  return descriptions;
};

export const getEventDescription = (
  event: CostTrendlineEvent,
  currentMilestoneId: UUID,
  includeLineBreaks: boolean
) => {
  if (!event) return [];
  const costImpact = generateCostImpactInfo(getCostForEventItem(event.item?.cost));
  const isOptionEvent = Boolean(event.eventContent.option);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  let description: any = []; // TODO - remove any type DD-883
  const itemDescription = {
    ...event.item,
    kind: KIND_ITEM,
  };

  switch (event.eventType) {
    case 'BASELINE':
      description = [
        makeEventDescriptionTextObject(
          `Estimate total is ${formatCost(event.cost, {
            rounded: true,
          })}`
        ),
      ];
      break;
    case ITEM_DRAW_SET:
    case ITEM_DRAW_UPDATE:
    case CHANGE_COST_EVENT:
      if (isOptionEvent) {
        description = [
          makeEventDescriptionTextObject(`Changed estimate for option `),
          { ...event.eventContent.option, kind: KIND_OPTION },
          makeEventDescriptionTextObject(` on `),
          itemDescription,
          makeEventDescriptionTextObject(
            ` from ${generateCostImpactInfo(
              event.eventContent.oldCost
            )} to ${generateCostImpactInfo(event.eventContent.newCost)}`
          ),
        ];
      } else {
        description = [
          makeEventDescriptionTextObject(`Changed estimate for `),
          itemDescription,
          makeEventDescriptionTextObject(
            ` from ${generateCostImpactInfo(
              event.eventContent.oldCost
            )} to ${generateCostImpactInfo(event.eventContent.newCost)}`
          ),
        ];
      }
      break;
    case CHANGE_MILESTONE_EVENT:
      description = [
        makeEventDescriptionTextObject(`Moved `),
        itemDescription,
        event.eventContent.newMilestone === currentMilestoneId
          ? makeEventDescriptionTextObject(' into this milestone')
          : makeEventDescriptionTextObject(' out of this milestone'),
      ];
      break;
    case CHANGE_STATUS_EVENT:
      if (isOptionEvent) {
        description = [
          makeEventDescriptionTextObject(`Changed status of option `),
          { ...event.eventContent.option, kind: KIND_OPTION },
          makeEventDescriptionTextObject(` on `),
          itemDescription,
          makeEventDescriptionTextObject(
            ` from ${getItemStatusLabel(
              event.eventContent.oldOptionStatus
            )} to ${getItemStatusLabel(
              event.eventContent.newOptionStatus
            )} with item cost impact ${costImpact}`
          ),
        ];
      } else {
        description = [
          makeEventDescriptionTextObject(`Changed status of `),
          itemDescription,
          makeEventDescriptionTextObject(
            ` from ${getItemStatusLabel(event.eventContent.oldStatus)} to ${getItemStatusLabel(
              event.eventContent.newStatus
            )} with cost impact ${costImpact}`
          ),
        ];
      }
      break;
    case CREATE_ITEM_EVENT:
      description = [
        makeEventDescriptionTextObject(`Created `),
        itemDescription,
        makeEventDescriptionTextObject(` with cost impact ${costImpact}`),
      ];
      break;
    case ITEM_EVENT_ATTACH_OPTION:
      description = [
        makeEventDescriptionTextObject(
          `Added new ${pluralizeString(`option`, event.eventContent.options?.length ?? 0)} `
        ),
        ...makeOptionsList(event.eventContent.options),
        makeEventDescriptionTextObject(` to `),
        itemDescription,
        makeEventDescriptionTextObject(` with cost impact ${costImpact}`),
      ];
      break;
    case ITEM_EVENT_CREATE_OPTION:
      description = [
        makeEventDescriptionTextObject(
          `Added new ${pluralizeString(`option`, event.eventContent.options?.length ?? 0)} `
        ),
        ...makeOptionsList(event.eventContent.options),
        makeEventDescriptionTextObject(` for `),
        itemDescription,
        makeEventDescriptionTextObject(` with cost impact ${costImpact}`),
      ];
      break;
    case ITEM_EVENT_DETACH_OPTION:
      description = [
        makeEventDescriptionTextObject(
          `Detached ${pluralizeString(`option`, event.eventContent.options?.length ?? 0)} `
        ),
        ...makeOptionsList(event.eventContent.options),
        makeEventDescriptionTextObject(` from `),
        itemDescription,
        makeEventDescriptionTextObject(` with cost impact ${costImpact}`),
      ];
      break;
    case REMOVE_MILESTONE_EVENT:
      description = [
        makeEventDescriptionTextObject(`Removed `),
        itemDescription,
        makeEventDescriptionTextObject(` with cost impact ${costImpact}`),
      ];
      break;
    default:
  }

  return includeLineBreaks ? insertLineBreaks(description) : description;
};

function isRunningTotalChangedEvent(event: CostTrendlineEvent) {
  return event.yPoint === event.cost;
}
function isMaxChangedEvent(event: CostTrendlineEvent) {
  return event.yPoint === event.y;
}
function isMinChangedEvent(event: CostTrendlineEvent) {
  return event.yPoint === event.y0;
}

export const getEventHint = (
  t: TermStore,
  costTrendlineEvent: CostTrendlineEvent,
  costTrendlineEvents: CostTrendlineEvent[] = []
) => {
  let trendStatus = '';
  let trendDifferenceDescription = '';
  let trendDescription = '';

  const isCost = isRunningTotalChangedEvent(costTrendlineEvent);
  const isMax = isMaxChangedEvent(costTrendlineEvent);
  const isMin = isMinChangedEvent(costTrendlineEvent);

  const trendVariant = isCost ? SMALL : TRENDING_PENDING;

  const eventsOfSameType = costTrendlineEvents.filter((e) => {
    if (isCost) return isRunningTotalChangedEvent(e);
    if (isMax) return isMaxChangedEvent(e);
    if (isMin) return isMinChangedEvent(e);

    return false;
  });

  const currentIdx = eventsOfSameType.findIndex((e) => e.ids === costTrendlineEvent.ids);
  const previousEvent = currentIdx > 0 ? eventsOfSameType[currentIdx - 1] : eventsOfSameType[0];

  if (!costTrendlineEvent || !previousEvent) return { trendDescription, trendVariant, trendStatus };

  // add a tooltip to describe how the trend changes
  const eventCost = Number(costTrendlineEvent.yPoint ?? 0);
  const previousEventCost = Number(previousEvent.yPoint ?? 0);
  const costDifference = eventCost - previousEventCost;

  if (costDifference < 0) {
    trendDifferenceDescription = 'decreased to ';
    trendStatus = TRENDING_DOWN;
  } else if (costDifference > 0) {
    trendDifferenceDescription = 'increased to ';
    trendStatus = TRENDING_UP;
  } else {
    trendDifferenceDescription = 'remains at ';
    trendStatus = TRENDING_FLAT;
  }

  // Create a string that describes how the trendline has changed.
  // swap out the upper or lower bound trend icon as appropriate

  if (isMax) {
    trendDescription = `Upper bound ${trendDifferenceDescription}${formatCost(eventCost, {
      short: true,
    })}`;
    if (trendStatus !== TRENDING_FLAT) {
      trendStatus =
        trendStatus === TRENDING_UP ? TRENDING_UPPER_BOUND_UP : TRENDING_UPPER_BOUND_DOWN;
    }
  } else if (isMin) {
    trendDescription = `Lower bound ${trendDifferenceDescription}${formatCost(eventCost, {
      short: true,
    })}`;
    if (trendStatus !== TRENDING_FLAT) {
      trendStatus =
        trendStatus === TRENDING_UP ? TRENDING_LOWER_BOUND_UP : TRENDING_LOWER_BOUND_DOWN;
    }
  } else {
    trendDescription = `${t.rawTerm(
      TermKey.RUNNING_TOTAL
    )} ${trendDifferenceDescription}${formatCost(eventCost, { short: true })}`;
  }

  return {
    trendDescription,
    trendVariant,
    trendStatus,
  };
};

type LegendLabel = {
  color: string;
  title: string;
  strokeDasharray?: string;
  strokeWidth?: number;
  style?: { fill: string };
};

export const getCostTrendLegendLabels = (
  t: TermStore,
  estimate: number,
  runningTotal: number,
  target: number,
  hasVisibleEvents?: boolean
) => {
  const labels: LegendLabel[] = [];

  if (estimate !== 0) {
    labels.push({
      title: `${t.titleCase(TermKey.ESTIMATE)} Total`,
      strokeDasharray: '1, 2',
      color: theme.palette.primaryGrey,
    });
  }
  if (runningTotal !== 0 && hasVisibleEvents) {
    labels.push({
      title: t.titleCase(TermKey.RUNNING_TOTAL),
      color: theme.palette.joinPrimary,
      strokeWidth: 2,
    });
  }
  if (target !== 0) {
    labels.push({
      title: t.titleCase(TermKey.TARGET),
      style: { fill: theme.palette.budget },
      color: theme.palette.budget,
      strokeWidth: 2,
    });
  }
  if (hasVisibleEvents) {
    labels.push({
      title: 'Min/Max Available Range',
      color: theme.palette.pending,
      strokeDasharray: '1, 2',
      strokeWidth: 4,
    });
  }
  return labels;
};

// Label Positioner will prioritize the labels in order, and avoid trend points and target.
// It doesn't really care about min, max & estimate since they are soooo light,
// but will make sure their labels show up in the right place

const graphHeight = 330;
const labelFontSize = Number(theme.typography.chart.fontSize);
// adds a buffer to the yDomain set by the min-max data points based on fontSize
const bufferConstant = 3;
// when a label overlaps, how far should we move it to prevent overlap (percent of height)
const moveConstant = 1.5;

export type CostTrendChartData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  labels: any[];
  legendLabels: LegendLabel[];
  trend?: CostTrendlineEvent[];
  xDomain: (Date | number)[];
  yDomain: number[];
  yOrigin: number;
  estimate?: number;
  runningTotal?: number;
  target?: number;
};

const computeCostTrendLabelsDomain = (
  t: TermStore,
  costTrendData?: CostTrendChartData,
  quantityMagnitude?: number
) => {
  if (!costTrendData) return {} as CostTrendChartData;
  const { xDomain, yDomain, trend, estimate, runningTotal, target } = costTrendData;

  const unitConversion = (yDomain[1] - yDomain[0]) / graphHeight;
  const fontHeight = unitConversion * Number(labelFontSize);

  let visibleEvents;
  if (trend && trend.length > 0) {
    visibleEvents = trend.filter((tr) => tr.visible);
  }

  const x0Timestamp = visibleEvents?.[0]?.timestamp;
  const x0 = (x0Timestamp && new Date(x0Timestamp)) || xDomain?.[0] || 0;

  const x1Timestamp = visibleEvents?.[visibleEvents.length - 1]?.timestamp;
  const x1 = (x1Timestamp && new Date(x1Timestamp)) || xDomain?.[1] || 1;

  const min = Number(visibleEvents?.[visibleEvents.length - 1]?.y0);
  const max = Number(visibleEvents?.[visibleEvents.length - 1]?.y);

  const allLabels = [
    {
      text: t.titleCase(TermKey.ESTIMATE),
      value: estimate,
      y: estimate,
      xGroup: 0,
      yDirection: 1,
    },
    {
      text: t.titleCase(TermKey.TARGET),
      value: target,
      y: target,
      xGroup: 0,
      yDirection: 0,
      style: { fill: theme.palette.budget },
    },
    {
      text: t.titleCase(TermKey.RUNNING_TOTAL),
      value: runningTotal,
      y: runningTotal,
      xGroup: 1,
      yDirection: 1,
    },
    {
      text: 'Minimum with available items',
      value: min,
      y: min,
      xGroup: 2,
      yDirection: 0,
    },
    {
      text: 'Maximum with available items',
      value: max,
      y: max,
      xGroup: 2,
      yDirection: 1,
    },
  ];

  if (target && estimate && target > estimate) {
    allLabels[0].yDirection = 0;
    allLabels[1].yDirection = 1;
  }

  // set initial locations based on y-direction
  const movableLabels = allLabels
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .map((l: any) => ({ ...l, y: l.y + moveConstant * fontHeight * (l.yDirection ? 1 : -1) }))
    .filter((_, i) => i < 2 || (trend && trend?.filter((tr) => tr.visible).length > 0))
    .filter((v) => v.value && v.value !== 0);

  // the y in values is overwritten as labels are moved to avoid conflicts with lines and other labels
  const labels = movableLabels.map((v, i) => {
    const aX = v.xGroup;
    const aY = v.yDirection;

    // remove conflict with any previous by pushing a label out
    for (let b = 0; b < i; b += 1) {
      const { y, xGroup } = movableLabels[b];
      if (Boolean(xGroup) === Boolean(aX)) {
        if (Math.abs(movableLabels[i].y - y) < fontHeight) {
          movableLabels[i].y = y + moveConstant * fontHeight * (aY ? 1 : -1);
        }
      }
    }
    // the label should now be locked in a good y position, re-buffer domain
    yDomain[0] = Math.min(yDomain[0], movableLabels[i].y - bufferConstant * fontHeight);
    yDomain[1] = Math.max(yDomain[1], movableLabels[i].y + bufferConstant * fontHeight);
    return {
      x: v.xGroup ? x1 : x0,
      y: movableLabels[i].y,
      xOffset: v.xGroup ? -6 : 6,
      style: v.style,
      anchorX: v.xGroup ? 'end' : 'start',
      anchorY: 'middle',
      label: `${v.text}: ${formatCost(v.value, {
        rounded: !quantityMagnitude,
        showCurrencySymbol: false,
      })}`,
    };
  });
  return { ...costTrendData, labels, yDomain };
};

const itemStatusColors: Record<Status, string> = {
  [Status.ACCEPTED]: theme.palette.accepted,
  [Status.INCORPORATED]: theme.palette.incorporated,
  [Status.NOTAPPLICABLE]: theme.palette.notapplicable,
  [Status.NOTCHOSEN]: theme.palette.disabledGrey,
  [Status.PENDING]: theme.palette.pending,
  [Status.REJECTED]: theme.palette.rejected,
};

const getBufferedXDomain = (visibleEvents?: CostTrendlineEvent[]) => {
  const xDomain: (number | Date)[] = [...(visibleEvents?.map((e) => new Date(e.timestamp)) ?? [])];

  let bufferedDomain = [];
  if (xDomain.length > 1) {
    const padding = Math.floor(xDomain.length * 0.025);
    const buffer1 = Array(padding)
      .fill(1)
      .map((_, i) => {
        const date = new Date(xDomain[0]);
        return date.getTime() - i - 1;
      });
    const buffer2 = Array(padding)
      .fill(1)
      .map((_, i) => {
        const date = new Date(xDomain[xDomain.length - 1]);
        return date.getTime() - padding + 1 + i;
      });
    bufferedDomain = [...buffer1, ...xDomain, ...buffer2];
  } else {
    const d = new Date();
    const d1 = new Date(d.getTime() + 1);
    bufferedDomain = [d, d1];
  }
  return bufferedDomain;
};

const getBufferedYDomain = (
  visibleEvents?: CostTrendlineEvent[],
  estimate?: number,
  target?: number
) => {
  const defaultMin = estimate || target || 0;
  const defaultMax = estimate || target || 0;
  let min = visibleEvents ? Math.min(...visibleEvents.map((e) => Number(e.y0))) : defaultMin;
  let max = visibleEvents ? Math.max(...visibleEvents.map((e) => Number(e.y))) : defaultMax;

  // yBuffer uses 2 standard deviations, or 1/6 of the min max,
  // to buffer around the min/max ( from target, estimate, last min, + last max)
  if (target) {
    min = Math.min(min, target);
    max = Math.max(max, target);
  }
  let diff = max - min;
  if (diff === 0) diff = max;
  const yBuffer = diff / 6;
  min -= yBuffer;
  max += yBuffer;
  const bufferedYDomain = [min, max];
  const yOrigin = (max + min) / 2;

  return { bufferedYDomain, yOrigin };
};

export const computeCostTrendData = (
  t: TermStore,
  quantityMagnitude?: number,
  costTrendlineEvents?: CostTrendlineEvents
) => {
  const estimate = Number(costTrendlineEvents?.estimate ?? 0);
  const target = Number(costTrendlineEvents?.target ?? 0);
  const runningTotal = Number(costTrendlineEvents?.runningTotal ?? 0);

  // Formatted trendline events for display
  const visibleEvents = costTrendlineEvents?.events.filter((e) => e.visible);
  const hasVisibleEvents = visibleEvents && visibleEvents.length > 0;

  // Legend data
  const legendLabels = getCostTrendLegendLabels(
    t,
    estimate || 0,
    runningTotal || 0,
    target || 0,
    hasVisibleEvents
  );

  const bufferedXDomain = getBufferedXDomain(visibleEvents);
  const { bufferedYDomain, yOrigin } = getBufferedYDomain(visibleEvents, estimate, target);

  const costTrendData: CostTrendChartData = {
    estimate,
    labels: [],
    legendLabels,
    runningTotal,
    target,
    trend: formatVisibleTrendlineForDisplay(costTrendlineEvents?.events),
    xDomain: bufferedXDomain,
    yDomain: bufferedYDomain,
    yOrigin,
  };

  return computeCostTrendLabelsDomain(t, costTrendData, quantityMagnitude);
};

const formatVisibleTrendlineForDisplay = (trend?: CostTrendlineEvent[]) =>
  trend?.map((t) => ({
    ...t,
    x: new Date(t.timestamp),
    color: itemStatusColors[t.item?.status || Status.PENDING],
  }));
