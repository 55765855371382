import { FC, KeyboardEvent, useState } from 'react';

import { Button, CircularProgress, Divider, Typography, withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { NewProjectEvent, newProjectEvent } from '../../../analytics/analyticsEventProperties';
import {
  PROJECT_CURRENCY,
  PROJECT_LOCATION_CITY,
  PROJECT_SHORTNAME,
  SET_PROJECT_LOCATION_PLACEHOLDER,
} from '../../../constants';
import {
  CreateProjectMutationVariables,
  DesignPhaseType,
  ImageDimension,
  LocationDetailsInput,
  ProjectType,
} from '../../../generated/graphql';
import { useCreateProject } from '../../../hooks/ProjectHooks';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useProjectDeliveryTypes } from '../../../hooks/useProjectDeliveryTypesQuery';
import { constructCurrencyEntries } from '../../../utilities/currency';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { SelectLevelsEntry } from '../../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import MilestoneDatePicker from '../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneDesignPhaseSelector from '../../Milestone/MilestoneDesignPhaseSelector/MilestoneDesignPhaseSelector';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { MAX_PROJECT_CODE_LENGTH } from '../../ProjectProperties/ProjectDetails';
import ProjectPropSelector, { ProjectProp } from '../../ProjectProperties/ProjectPropSelector';
import useProjectStatusesQuery from '../../ProjectsList/hooks/useProjectStatusesQuery';
import { Dialog, DialogContent, Select, TextInput } from '../../scales';
import PlacesAutocompleteWrapper from '../../shared-widgets/PlacesAutocompleteWrapper';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './DialogsNewProjectStyles';
import {
  computeClearInactive,
  computeProjectTypes,
  currencyTooltip,
  getProjectInputDefault,
  milestoneLabel,
  milestoneTooltip,
  shortnameTooltip,
} from './DialogsNewProjectUtils';
import ProjectTypeSelector from './ProjectTypeSelector';

type DialogsNewProjectProps = {
  classes: Classes<typeof styles>;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  template?: ProjectTemplate;
};

const DialogsNewProject: FC<DialogsNewProjectProps> = ({
  classes,
  dialogOpen = false,
  setDialogOpen,
  template,
}) => {
  const createProject = useCreateProject();
  const sendAnalytics = useSendAnalyticsEventHook();

  const [submitted, setSubmitted] = useState(false);

  const tp = template?.project;
  const projectInput = getProjectInputDefault(tp, template?.settings);

  const [project, setProject] = useState<CreateProjectMutationVariables>(projectInput);

  const resetProject = () => setProject(projectInput);
  const {
    code,
    location,
    milestone,
    milestoneDate,
    milestoneDesignPhaseID,
    name,
    statusID,
    typeID,
    projectDeliveryTypeID,
  } = project;

  const onCreateProject = () => {
    setSubmitted(true);
    createProject(
      project,
      () => {
        setSubmitted(false);
        setDialogOpen(false);
        resetProject();
      },
      () => {
        setSubmitted(false);
        setDialogOpen(false);
        resetProject();
      }
    );
    sendAnalytics(
      newProjectEvent(NewProjectEvent.CREATE_CTA, {
        location: window.location.pathname,
        milestoneDesignPhase: milestoneDesignPhaseID,
        milestoneName: milestone,
        milestoneStartDate: milestoneDate,
        projectDeliveryMethod: projectDeliveryTypeID ?? undefined,
        projectStatus: statusID,
        projectType: typeID ?? undefined,
      })
    );
  };
  const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter' && name !== '' && milestone && !submitted && project.statusID) {
      setSubmitted(true);
      onCreateProject();
    }
  };
  // Currency

  // Project Statuses
  const projectStatuses = useProjectStatusesQuery().data?.projectStatuses ?? [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);

  // Project Types
  const { companyID } = useCompanyRoutesData();
  const { data: typesDataNew } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes = typesDataNew?.companyProjectTypes || [];
  const types: ProjectType[] = useMemoWrapper(computeProjectTypes, projectTypes);

  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  // Design Phase Types
  const designPhaseTypes = useDesignPhaseTypes();

  const shortnameTooltipFormat = (
    <div>
      {shortnameTooltip.map((line) => (
        <div key={line}>{line}</div>
      ))}
    </div>
  );

  const infoIcon = <Info className="text-type-muted" fontSize="inherit" />;

  return (
    <Dialog
      isOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="form-dialog-title"
      title={`New ${template?.name ?? ''} Project`}
      image={
        template ? (
          <Thumbnail
            thumbnail={template.project.thumbnail}
            dimension={ImageDimension._50}
            size={40}
            padding={0}
          />
        ) : undefined
      }
      footerRight={
        submitted ? (
          <div className={classes.footerCentered}>
            <CircularProgress size={18} />
            <Typography className={classes.boldBlue}>
              Creating: {name}. This might take a few minutes.
            </Typography>
          </div>
        ) : (
          <Button
            data-cy="button=createProject"
            variant="contained"
            color="primary"
            disabled={
              !name ||
              !milestone ||
              !statusID ||
              !typeID ||
              !location ||
              !milestoneDate ||
              !milestoneDesignPhaseID ||
              !projectDeliveryTypeID
            }
            onClick={() => {
              if (!submitted) {
                onCreateProject();
              }
            }}
          >
            Create
          </Button>
        )
      }
    >
      <DialogContent className={classes.content}>
        <div className="flex flex-col gap-2 pb-4">
          <TextInput
            autoFocus
            disabled={submitted}
            label="Project Name"
            id="name"
            data-cy="input-new-project-name"
            placeholder={tp?.name || 'Set project name'}
            value={name || ''}
            onChange={(value) => {
              setProject({ ...project, name: value });
            }}
            onKeyDown={onKeyDown}
          />
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Project Status *</div>
            <ProjectPropSelector
              editable={!submitted}
              name="status"
              setProjectProp={(p: ProjectProp | null) => {
                if (p) {
                  setProject({ ...project, statusID: p.id });
                }
              }}
              selectedValue={statusID}
              values={statuses}
            />
          </div>
          <ProjectTypeSelector
            editable={!submitted}
            name="type"
            label="Project Type *"
            search
            setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
              setProject({ ...project, typeID: p?.id });
            }}
            selectedValue={typeID}
            values={types}
          />
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Project Delivery Method *</div>
            <ProjectPropSelector
              editable={!submitted}
              name="delivery method"
              setProjectProp={(p: ProjectProp | null) => {
                setProject({ ...project, projectDeliveryTypeID: p?.id });
              }}
              selectedValue={projectDeliveryTypeID}
              values={projectDeliveryTypes}
            />
          </div>
          <PlacesAutocompleteWrapper
            disabled={submitted}
            defaultValue={location}
            label={`${PROJECT_LOCATION_CITY} *`}
            onChange={(locationDetails?: LocationDetailsInput) => {
              if (locationDetails)
                setProject({
                  ...project,
                  location: locationDetails.name,
                  lat: locationDetails.lat,
                  lon: locationDetails.lon,
                  locationDetails,
                });
            }}
            placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
          />
          <div className="flex flex-row gap-2">
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="items-center gap-0.5 type-label">
                {PROJECT_CURRENCY}
                <NormalTooltip title={currencyTooltip}>{infoIcon}</NormalTooltip>
              </div>
              <Select
                data-cy="selectCurrency"
                aria-label="Select project currency"
                isDisabled={submitted}
                entries={constructCurrencyEntries()}
                onChange={(currency: string) => {
                  setProject({ ...project, currency });
                }}
                value={project.currency}
                placeholder="$ USD US Dollar"
              />
            </div>
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="gap-0.5 type-label">
                {PROJECT_SHORTNAME}
                <NormalTooltip title={shortnameTooltipFormat}>{infoIcon}</NormalTooltip>
              </div>
              <TextInput
                disabled={submitted}
                aria-label="Set project shortname"
                maxLength={MAX_PROJECT_CODE_LENGTH}
                placeholder={tp?.code || `Set ${PROJECT_SHORTNAME.toLowerCase()}`}
                value={code || ''}
                onChange={(code) => {
                  setProject({ ...project, code });
                }}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-2 pt-4">
          <div className="flex flex-row gap-2">
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="gap-0.5 type-label">
                {milestoneLabel(!template)}
                <NormalTooltip
                  title={
                    <>
                      <div>{milestoneTooltip}</div>
                      {template && (
                        <div>
                          Once a project is created you can go to the milestone and change its name.
                        </div>
                      )}
                    </>
                  }
                >
                  {infoIcon}
                </NormalTooltip>
              </div>
              <TextInput
                data-cy="input-new-milestone-name"
                disabled={!!template || submitted}
                aria-label="Set active milestone name"
                placeholder="Set milestone name"
                value={milestone || ''}
                onChange={(milestone) => {
                  setProject({ ...project, milestone });
                }}
                onKeyDown={onKeyDown}
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <MilestoneDatePicker
                date={milestoneDate}
                disabled={submitted}
                onUpdate={(date) => {
                  setProject({ ...project, milestoneDate: date || '' });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Milestone Design Phase *</div>
            <MilestoneDesignPhaseSelector
              disabled={submitted}
              setSelection={(designPhase: DesignPhaseType | undefined) => {
                if (designPhase) {
                  setProject({ ...project, milestoneDesignPhaseID: designPhase.id });
                }
              }}
              selectedValue={milestoneDesignPhaseID}
              values={designPhaseTypes}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsNewProject);
