import { useContext, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { UserStatus } from '../../api/gqlEnumsBe';
import { DD_NEW_NAV } from '../../features';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import { useIsRouteAccessible } from '../../hooks/useIsRouteAccessible';
import useLoadCurrentUserStatusQuery from '../../hooks/useLoadCurrentUserStatusQuery';
import { RootRoutes } from '../../routes/routes';
import { getSubRoutes } from '../../utilities/routes/routes';
import ApolloSandbox from '../ApolloSandbox';
import AppNavLegacy from '../AppNavLegacy';
import DeactivatedPage from '../deactivate/DeactivatedPage';
import ErrorCode from '../errors/ErrorCode';
import { joinAuthContext } from '../JoinAuthProvider';
import { routeToAppComponent } from '../RouteToAppComponent';

import Nav from './Nav';

export default function Router() {
  const { isAccessible, loading: isAccessibleLoading } = useIsRouteAccessible();
  const { isDeactivated, isLoading: isLoadingDeactivatedUserCheck } = useIsUserDeactivated();
  const isLoading = isAccessibleLoading || isLoadingDeactivatedUserCheck;

  const hasNewNav = useHasFeature(DD_NEW_NAV);

  const routes = useMemo(
    () => getSubRoutes(RootRoutes, routeToAppComponent, isAccessible),
    [isAccessible]
  );

  if (isLoading) return null;

  return (
    <Routes>
      <Route
        path="/deactivated"
        element={isDeactivated ? <DeactivatedPage /> : <Navigate to="/" />}
      />
      <Route path="/apollo" element={<ApolloSandbox />} />
      <Route path="/404" element={<ErrorCode code={404} />} />
      <Route element={hasNewNav ? <Nav /> : <AppNavLegacy />}>{routes}</Route>
      <Route path="*" element={<ErrorCode code={404} />} />
    </Routes>
  );
}

function useIsUserDeactivated() {
  const { joinUser } = useContext(joinAuthContext);
  const queryResult = useLoadCurrentUserStatusQuery(joinUser?.email);

  const userStatus = queryResult.data?.user?.status ?? queryResult.previousData?.user?.status;
  const isLoading = userStatus === undefined;
  const isDeactivated = userStatus === UserStatus.DEACTIVATED;

  const navigate = useNavigate();
  useEffect(() => {
    if (isDeactivated && window.location.pathname !== '/deactivated') {
      navigate('/deactivated');
    }
  }, [isDeactivated, navigate]);

  return { isDeactivated, isLoading };
}
