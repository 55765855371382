import { FC } from 'react';

import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import AssetsViewerNavigationStyles from './AssetsViewerNavigationStyles';

type AssetsViewerNavigationProps = {
  classes: Classes<typeof AssetsViewerNavigationStyles>;
  shiftViewer: (index: number) => void;
};

const AssetsViewerNavigation: FC<AssetsViewerNavigationProps> = ({ classes, shiftViewer }) => {
  const onClick = (index: number) => () => shiftViewer(index);
  return (
    <>
      <IconButton
        onClick={onClick(-1)}
        className={`${classes.navigationButtonContainer} ${classes.navigationButtonContainerLeft}`}
      >
        <KeyboardArrowLeft className={classes.navigationButton} />
      </IconButton>
      <IconButton
        onClick={onClick(1)}
        className={`${classes.navigationButtonContainer} ${classes.navigationButtonContainerRight}`}
      >
        <KeyboardArrowRight className={classes.navigationButton} />
      </IconButton>
    </>
  );
};

export default withStyles(AssetsViewerNavigationStyles)(AssetsViewerNavigation);
