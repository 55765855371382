import { FC, useEffect, useState } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import ProjectCategorizationsEntry from './ProjectCategorizationsEntry';
import ProjectCategorizationsStyles from './ProjectCategorizationsStyles';

type ProjectCategorizationsListProps = {
  classes: Classes<typeof ProjectCategorizationsStyles>;
  categorizations: StandardCategorization[];
  setOpenProjectCategorizationInfo: (categorizationID: UUID) => void;
  onToggle?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  selectedCategorizations: any;
};

const ProjectCategorizationsList: FC<ProjectCategorizationsListProps> = ({
  classes,
  categorizations,
  setOpenProjectCategorizationInfo,
  onToggle,
  selectedCategorizations,
}) => {
  const [toggledCategorizations, setToggledCategorizations] = useState(selectedCategorizations);

  useEffect(() => {
    setToggledCategorizations(selectedCategorizations);
  }, [selectedCategorizations, toggledCategorizations]);

  return (
    <div className={classes.minHeight}>
      <div className={classes.list}>
        {categorizations.map((categorization) => (
          <ProjectCategorizationsEntry
            key={categorization.categorization.id}
            categorization={categorization}
            setOpenProjectCategorizationInfo={setOpenProjectCategorizationInfo}
            onToggle={onToggle}
            isSelected={toggledCategorizations[categorization.categorization.id]}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(ProjectCategorizationsStyles)(ProjectCategorizationsList);
