import { FC, useEffect, useState } from 'react';

import { ButtonBase } from '@material-ui/core';

import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import AssigneeChip from '../../Collaborators/AssigneeChip';
import DropDownArrow from '../../Select/DropDownArrow/DropDownArrow';

import { assigneeComp } from './InputsSelectAssigneePopover';
import InputsSelectAssigneePopoverData from './InputsSelectAssigneePopoverData';
import styles from './InputsSelectAssigneeStyles';
import { getCollaboratorEmail } from './InputsSelectAssigneeUtils';

type InputsSelectAssigneeProps = {
  classes: Classes<typeof styles>;
  dataCy?: string;
  defaultLabel?: string;
  disabled: boolean;
  onChange: (email: string | undefined) => void;
  projectId: UUID;
  selectedCollaborator?: Collaborator | undefined;
  isCompact?: boolean;
  isDraft?: boolean;
  isReset?: boolean;
  itemID?: UUID;
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

const InputsSelectAssignee: FC<InputsSelectAssigneeProps> = ({
  classes,
  dataCy = 'InputsSelectAssignee-Button',
  defaultLabel,
  disabled,
  onChange,
  projectId,
  selectedCollaborator,
  isCompact = false,
  isDraft,
  isReset = false,
  itemID,
  variant,
}) => {
  const [buttonRef, setButtonRef] = useState();
  const [label, setDefaultLabel] = useState(defaultLabel);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // this local state can be removed when we move to apollo optimistic responses for setAssignee
  const [localSelected, setLocalSelected] = useState<Collaborator | undefined>(
    selectedCollaborator
  );

  // Automatically come back to uncategorized selection once the state in the component is cleared
  const selected = getCollaboratorEmail(selectedCollaborator ?? null);
  useEffect(() => {
    if (localSelected !== undefined) setLocalSelected(localSelected);
  }, [selected, localSelected]);

  const handleSubmit = (collaborator: Collaborator | { user: null }) => {
    setAnchorEl(null);
    const email = getCollaboratorEmail(collaborator);
    if (email) {
      setLocalSelected(collaborator as Collaborator);
      onChange(email);
    } else {
      setLocalSelected(undefined);
      onChange(undefined);
      setDefaultLabel(undefined);
    }
  };

  if (disabled) {
    return (
      <ButtonBase
        aria-label="toggle menu"
        className={classes.button}
        disabled={disabled}
        disableRipple
      >
        <AssigneeChip assignee={(localSelected || {}).user} defaultLabel={defaultLabel} />
      </ButtonBase>
    );
  }

  return (
    <>
      <ButtonBase
        data-cy={dataCy}
        aria-label="toggle menu"
        className={classes.button}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        onBlur={undefined}
        disableRipple
        buttonRef={(ref) => setButtonRef(ref)}
      >
        {assigneeComp(localSelected, false, true, isCompact, label, classes.text)}
        <DropDownArrow
          dataCy="arrowDropDown-inputsSelectAssignee"
          onClick={() => setAnchorEl(buttonRef ?? null)}
        />
      </ButtonBase>
      <InputsSelectAssigneePopoverData
        anchorEl={anchorEl}
        handleSubmit={handleSubmit}
        isCompact={isCompact}
        isReset={isReset}
        isDraft={isDraft}
        itemID={itemID}
        label={label}
        projectId={projectId}
        selectedCollaborator={selectedCollaborator}
        setAnchorEl={setAnchorEl}
        variant={variant}
      />
    </>
  );
};

export default withStyles(styles)(InputsSelectAssignee);
