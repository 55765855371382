import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    link: {
      ...theme.typography.link,
      display: 'inline-flex',
      marginLeft: 0,
      paddingLeft: 0,
    },
    linkIcon: {
      alignSelf: 'center',
      fontSize: 16,
    },
  });
