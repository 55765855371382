import { createStyles } from '@material-ui/core';

const INNER_HEIGHT = 110;

export default () =>
  createStyles({
    innerContainer: {
      alignItems: 'center',
      display: 'flex',
      background: 'none',
      minHeight: INNER_HEIGHT,
    },
  });
