import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchEventTypes } from '../../../../../../analytics/analyticsEventProperties';
import { JoinRoutes } from '../../../../../../api/gqlEnums';
import { SearchItemsQueryVariables, SearchResultType } from '../../../../../../generated/graphql';
import { generateSharedPath } from '../../../../../../utilities/routes/links';
import { pluralizeCountString } from '../../../../../../utilities/string';
import { Button } from '../../../../../scales';
import useCoreSearchPermissions from '../../../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../../hooks/useSendSearchAnalytics';
import { useSearchItemsQuery } from '../../../items/hooks/useSearchItemsQuery';
import { DEFAULT_ITEMS_SEARCH_QUERY } from '../../../items/SearchItemsUtils';
import { SearchToggleValue } from '../../../SearchToggle';
import {
  GlobalSearchMode,
  addToRecentSearches,
  deleteFromRecentSearches,
  getRecentSearches,
} from '../../GlobalSearchUtils';
import RecentSearches from '../RecentSearches';

import RelevantItems from './RelevantItems';

interface ItemContentProps {
  project?: string;
  search: string;
  searchMode: GlobalSearchMode;
}

const ItemContent = (props: ItemContentProps) => {
  const { hasAllItemsAccess, hasAllResultsLinkAccess } = useCoreSearchPermissions();
  const sendSearchAnalytics = useSendSearchAnalytics();
  const navigate = useNavigate();

  const [searchTerms, setSearchTerms] = useState(
    getRecentSearches(props.searchMode, props.project)
  );
  useEffect(
    () => setSearchTerms(getRecentSearches(props.searchMode, props.project)),
    [props.project, props.searchMode]
  );
  const removeSearchTerm = useCallback(
    (search: string) => {
      const updatedSearchHistory = deleteFromRecentSearches(
        search,
        props.searchMode,
        props.project
      );
      setSearchTerms(updatedSearchHistory);
    },
    [props.project, props.searchMode]
  );
  const addSearchTerm = useCallback(
    (search: string) => {
      const updatedSearchHistory = addToRecentSearches(search, props.searchMode, props.project);
      setSearchTerms(updatedSearchHistory);
    },
    [props.project, props.searchMode]
  );

  const variables: SearchItemsQueryVariables = {
    ...DEFAULT_ITEMS_SEARCH_QUERY,
    filters: {
      ...DEFAULT_ITEMS_SEARCH_QUERY.filters,
      projectNames:
        props.project && props.searchMode === GlobalSearchMode.PROJECT_ITEMS ? [props.project] : [],
    },
    search: props.search,
    pagination: { limit: 25, offset: 0 },
    searchResultType:
      hasAllItemsAccess && hasAllResultsLinkAccess ? SearchResultType.COMPANY : SearchResultType.MY,
  };
  const { data, previousData } = useSearchItemsQuery(variables);
  const items = data?.searchItems?.data ?? previousData?.searchItems?.data ?? [];
  const itemsCount = data?.searchItems?.total ?? previousData?.searchItems?.total ?? 0;

  const onNavigate = useCallback(
    (search: string) => {
      addSearchTerm(search);
      sendSearchAnalytics(searchEventTypes.SEARCH_ALL_CTA, {
        type: props.searchMode,
      });
      navigate(
        generateSharedPath(JoinRoutes.ITEMS, {
          search: `?search=${search}&toggle=${
            hasAllItemsAccess && hasAllResultsLinkAccess
              ? SearchToggleValue.ALL
              : SearchToggleValue.MY
          }${
            props.project && props.searchMode === GlobalSearchMode.PROJECT_ITEMS
              ? `&project=${props.project}`
              : ''
          }`,
        })
      );
    },
    [
      addSearchTerm,
      hasAllItemsAccess,
      hasAllResultsLinkAccess,
      navigate,
      props.project,
      props.searchMode,
      sendSearchAnalytics,
    ]
  );

  return (
    <>
      {props.search ? (
        <RelevantItems items={items} onClick={() => addSearchTerm(props.search)} />
      ) : (
        <RecentSearches
          onNavigate={onNavigate}
          onDelete={removeSearchTerm}
          searches={searchTerms}
        />
      )}
      {itemsCount > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
          <Button
            label={`See ${pluralizeCountString('item', itemsCount)}`}
            onClick={() => onNavigate(props.search)}
            type="tertiary"
          />
        </div>
      )}
    </>
  );
};

export default ItemContent;
