import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      marginTop: 36,
      overflowX: 'auto',
    },
    pageBreak: {
      pageBreakBefore: 'always',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      height: 92,
      width: 'fit-content',
    },
    headerLegend: {
      position: 'sticky',
      width: 320,
      flexShrink: 0,
      left: 0,
      whiteSpace: 'nowrap',
      borderRight: theme.border.darkGrey,
      background: theme.palette.backgroundWhite,
      zIndex: 1,
      padding: '0px 48px 8px 24px',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
    },
    contingencyHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      borderBottom: theme.border.darkGrey,
      width: '100%',
    },
    contingencyHeader: {
      width: 250,
      whiteSpace: 'nowrap',
      padding: '4px',
      paddingLeft: 12,
      fontWeight: 500,
      paddingBottom: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      '& > p': {
        fontSize: 14,
        color: theme.palette.app.disabledText,
        fontWeight: 400,
      },
    },
    activeMilestoneBadge: {
      fontWeight: 700,
      display: 'flex',
      columnGap: 8,
      alignItems: 'center',
      marginBottom: 8,
    },
    greenDot: {
      marginLeft: 8,
      fontSize: 6,
      color: theme.palette.accepted,
      position: 'relative',
      top: -1,
    },
    hideChartText: {
      ...theme.typography.webLink,
      textDecoration: 'underline',
      fontSize: 14,
      marginRight: 20,
    },
    hideChartButton: {
      alignSelf: 'start',
      '@media print': {
        display: 'none',
      },
    },
    pieChart: {
      width: 'fit-content',
      margin: 'auto',
    },
    pieChartContainer: {
      display: 'flex',
      position: 'sticky',
      left: 0,
    },
  });

export default styles;
