import { FC } from 'react';

import { Tab, Tabs } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './NavigationTabsStyles';

export type TabOption = {
  label: string;
  key: string;
  color?: string;
  title?: string;
};

const CIRCLE_CHAR = '\u2B24';

type NavigationBarProps = {
  classes: Classes<typeof styles>;
  options: TabOption[];
  view: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setView: any;
};

export const getTextDot = (text: string, { title, color }: { title?: string; color?: string }) => (
  <div style={{ height: 18, display: 'inline-flex' }}>
    <div>{text}</div>
    <div
      title={title}
      style={{
        color,
        fontSize: '50%',
        width: 0,
        transform: 'translate(4px, -3px)',
      }}
    >
      {color ? CIRCLE_CHAR : ''}
    </div>
  </div>
);

const NavigationTabs: FC<NavigationBarProps> = ({ classes, options, view, setView }) => (
  <Tabs centered={false} classes={{ root: classes.root }} value={view}>
    {options.map(({ key, label, title, color }) => (
      <Tab
        className={classes.tab}
        classes={{ root: classes.root, wrapper: classes.wrapper }}
        id={key}
        key={key}
        label={getTextDot(label, { title, color })}
        onClick={() => {
          setView(key);
        }}
        value={key}
        data-cy={`navigationTab-${key}`}
      />
    ))}
  </Tabs>
);

export default withStyles(styles)(NavigationTabs);
