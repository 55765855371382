import { useReactiveVar } from '@apollo/client';

import {
  IntervalType,
  MilestonesCostTrendsBreakdownsDataQuery,
  MilestonesCostTrendsBreakdownsDataQueryVariables,
} from '../../../../generated/graphql';
import { columns as defaultColumns } from '../../../../hooks/constants';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { costModeVar } from '../../../../utilities/costMode';

import { milestonesCostTrendsBreakdownsQuery } from './queries';

export const useMilestonesCostTrendsBreakdownsQuery = (
  projectID: UUID | null,
  columns: CostReportColumnInput[] = defaultColumns,
  intervalType: IntervalType,
  viewFilters: ViewFilterInput[],
  summary: boolean,
  milestoneID: UUID | null
) =>
  useQuery<
    MilestonesCostTrendsBreakdownsDataQuery,
    MilestonesCostTrendsBreakdownsDataQueryVariables
  >(milestonesCostTrendsBreakdownsQuery, {
    variables: {
      projectID,
      columns,
      costMode: useReactiveVar(costModeVar),
      intervalType,
      viewFilters,
      summary,
      milestones: milestoneID ? [{ milestoneID }] : [],
      groupByItems: [],
    },
    skip: !projectID || !milestoneID,
  });
