import { SvgIcon } from '@material-ui/core';

export const ExcelIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="-5 0 24 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z" />
      <path d="M5 9.5H3.5L5.5 13L3.5 16.5H5L6.5 14L8 16.5H9.5L7.5 13L9.5 9.5H8L6.5 12L5 9.5Z" />
    </svg>
  </SvgIcon>
);
