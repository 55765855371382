import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    tipContainer: {
      alignItems: 'baseline',
      display: 'flex',
    },
    tipHeader: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
    tipText: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamily,
    },
  });
