import { FC, useMemo } from 'react';

import { Add } from '@material-ui/icons';

import { DEFAULT_SELECTION, NULL_ID } from '../../constants';

import JoinSelect, { SelectEntry } from './JoinSelect/JoinSelect';

export const MEETING_DEFAULT = 'No Meeting';

type MilestoneSelectProps = {
  className?: string;
  defaultSelection?: string;
  disabled?: boolean;
  hidePrint?: boolean;
  isCompact?: boolean;
  isCreateNew?: boolean;
  isMeeting?: boolean;
  milestones: SelectEntry[] | undefined;
  nameOptionClassName?: string;
  onChangeMilestone: (milestone: UUID | null) => void;
  selectedMilestone?: string;
};

const MilestoneSelect: FC<MilestoneSelectProps> = ({
  className,
  defaultSelection,
  disabled = false,
  hidePrint = false,
  isCompact = true,
  isCreateNew = false,
  isMeeting = false,
  milestones = [],
  nameOptionClassName,
  onChangeMilestone,
  selectedMilestone,
}) => {
  const entries = useMemo(() => {
    if (isCreateNew)
      return milestones.concat({ id: NULL_ID, name: 'Create a new milestone', rightIcon: <Add /> });
    if (defaultSelection) return [{ id: DEFAULT_SELECTION, name: defaultSelection }, ...milestones];
    return milestones;
  }, [defaultSelection, isCreateNew, milestones]);
  const onChange = useMemo(() => {
    if (defaultSelection) {
      // This default selection logic used to live inside of JoinSelect
      // however, MilestoneSelect was the only place where it was being used, so it's been pulled out
      return (value: string) => {
        onChangeMilestone(value === DEFAULT_SELECTION ? null : value);
      };
    }
    return onChangeMilestone;
  }, [defaultSelection, onChangeMilestone]);
  const value = useMemo(() => {
    if (defaultSelection) return selectedMilestone || DEFAULT_SELECTION;
    return selectedMilestone;
  }, [defaultSelection, selectedMilestone]);

  return (
    <JoinSelect
      classNameRightText={nameOptionClassName}
      classNameSelect={className}
      cySelect={isMeeting ? 'select-meetingSelect' : 'select-milestoneSelect'}
      disabled={disabled}
      entries={entries}
      hidePrint={hidePrint}
      isCompact={isCompact}
      onChange={onChange}
      value={value}
    />
  );
};

export default MilestoneSelect;
