import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { UploadPlatform } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import UploadFileSVG from '../../shared-widgets/AddUploadDrag/AddUploadDragSVG';
import { getUploadPlatformSVGs } from '../../shared-widgets/AddUploadDrag/AddUploadDragUtils';

import styles from './EstimateImportPlatformSVGsStyles';
import UpperSVGText from './ImportEstimateUpperSVGText';

type EstimateImportPlatformSVGsProps = {
  estimatePlatform: UploadPlatform;
  classes: Classes<typeof styles>;
};

const EstimateImportPlatformSVGs: FC<EstimateImportPlatformSVGsProps> = ({
  estimatePlatform,
  classes,
}) => {
  const estimatePlatformSVGs = getUploadPlatformSVGs(estimatePlatform);
  const isEmptyState = estimatePlatform === UploadPlatform.EMPTY_STATE;
  const isSage = estimatePlatform === UploadPlatform.SAGE;
  const isExcel = estimatePlatform === UploadPlatform.EXCEL;
  const isProcore = estimatePlatform === UploadPlatform.PROCORE;
  const upperSVGClasses = `
  ${isEmptyState ? classes.upperSVGIconEmptyState : classes.upperEstimatePlatformIcon}
  ${isSage && classes.upperSageEstimatePlatformIcon}
  ${isExcel && classes.upperExcelEstimatePlatformIcon}
  ${isProcore && classes.upperExcelEstimatePlatformIcon}
  `;
  return (
    <div data-cy={`image-${estimatePlatform}`} className={classes.image}>
      <UpperSVGText estimatePlatform={estimatePlatform} />
      <UploadFileSVG backgroundImage={estimatePlatformSVGs.upper} svgClassName={upperSVGClasses} />
      {estimatePlatformSVGs.lower !== '' ? (
        <>
          <Typography className={classes.textSubTitle}>
            <b>Step 2: Import into Join</b>
          </Typography>
          <UploadFileSVG
            backgroundImage={estimatePlatformSVGs.lower}
            svgClassName={classes.lowerEstimatePlatformIcon}
          />
        </>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(EstimateImportPlatformSVGs);
