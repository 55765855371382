import { FC } from 'react';

import { Avatar } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import {
  NOTES_COL_WIDTH_COLLAPSED,
  NOTES_COL_WIDTH_EXPANDED,
} from '../CostReportListRow/CostReportListRowStyles';

import { styles } from './CostReportListHeaderStyles';
import { getNoteTooltip } from './NoteTooltip';

type ReportNoteHeaderProps = {
  classes: Classes<typeof styles>;
  isNotesColumnExpanded: boolean;
  setNotesColumnExpanded?: (isExpanded: boolean) => void;
  canExpandVarianceReportNotesColumn?: boolean;
};

const ReportNoteHeader: FC<ReportNoteHeaderProps> = ({
  classes,
  isNotesColumnExpanded,
  setNotesColumnExpanded = () => null,
  canExpandVarianceReportNotesColumn = false,
}) => {
  const commentColumnWidth = isNotesColumnExpanded
    ? NOTES_COL_WIDTH_EXPANDED
    : NOTES_COL_WIDTH_COLLAPSED;

  const varianceReportNotesExpandTitle = getNoteTooltip(canExpandVarianceReportNotesColumn);

  // Match column keys to column descriptions for header content
  return (
    <div className={classes.expandNotesContainer} style={{ width: commentColumnWidth }}>
      {!isNotesColumnExpanded && (
        <NormalTooltip title={varianceReportNotesExpandTitle}>
          <Avatar
            data-cy="expand-ReportNotes"
            className={`${
              canExpandVarianceReportNotesColumn
                ? classes.expandNotesCircle
                : classes.expandNotesCircleDisabled
            }`}
            onClick={() => {
              if (canExpandVarianceReportNotesColumn) setNotesColumnExpanded(true);
            }}
            aria-disabled={!canExpandVarianceReportNotesColumn}
          >
            <ExpandMoreIcon className={classes.expandNotesArrow} />
          </Avatar>
        </NormalTooltip>
      )}
    </div>
  );
};

export default withStyles(styles)(ReportNoteHeader);
