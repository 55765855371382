/* eslint-disable react/no-array-index-key */
import { FC, Fragment } from 'react';
import 'react-vis/dist/style.css';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import {
  ITEM_NUMBER_CHARLIMIT,
  KIND_COPIED_ITEM,
  KIND_EVENT_DESCRIPTION_NEW_LINE,
  KIND_EVENT_DESCRIPTION_STATUS,
  KIND_EVENT_DESCRIPTION_TEXT,
  KIND_EVENT_ITEM_LIKE_SUMMARY,
  KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY,
  KIND_ITEM,
  KIND_MEETING,
  KIND_MILESTONE,
  KIND_OPTION,
  VISIBILITY_PRIVATE_ITEM,
} from '../../../constants';
import { Status } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeString } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ItemsQuickLink from '../../Items/ItemsQuickLink/ItemsQuickLink';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { HIDDEN, SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import MeetingQuickLink from '../../shared-widgets/MeetingQuickLink/MeetingQuickLink';
import MilestoneQuickLink from '../../shared-widgets/MilestoneQuickLink/MilestoneQuickLink';

import styles from './EventsDescriptionStyles';

type Props = {
  classes: Classes<typeof styles>;
  charNameLimit: number;
  description: EventDescription[];
  isItemHistory?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  variant: any;
};
const EventsDescription: FC<Props> = ({
  classes,
  description,
  charNameLimit,
  isItemHistory = false,
  variant,
}) => (
  <>
    {description &&
      description.length > 0 &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      description.map((d: any, i) => {
        switch (d.kind) {
          case KIND_EVENT_DESCRIPTION_TEXT:
            return (
              <Typography
                data-cy="text-eventDescription"
                className={classes.inline}
                variant={variant}
                key={d.text + i}
              >
                {d.text}
              </Typography>
            );
          case KIND_EVENT_DESCRIPTION_NEW_LINE:
            return <div className={classes.overflow} key={`newLine ${i}`} />;
          case KIND_EVENT_DESCRIPTION_STATUS:
            return !isItemHistory ? (
              <Fragment key={`${d.text + i}`}>
                <ItemsIcons status={d.status} variant={SMALL} key={`d.text ${i}`} />
                <Typography className={classes.statusText} variant={variant} key={d.text + i}>
                  {d.text}
                </Typography>
              </Fragment>
            ) : (
              <Fragment key={`${d.text + i}`}>
                <Typography className={classes.inline} variant={variant} key={d.text + i}>
                  {d.text}
                </Typography>
              </Fragment>
            );
          case KIND_MEETING:
            return (
              <MeetingQuickLink
                meeting={d.meeting}
                isExpanded={false}
                key={`${d.meeting.id}${d.meeting.name}`}
              />
            );
          case KIND_MILESTONE:
            // If we have project id, then we are looking at the editable item sidebar
            // otherwise we are outside of the project, observing forecasting or home page sidebars
            return getProjectIdFromUrl() ? (
              <MilestoneQuickLink milestone={d} key={JSON.stringify(d)} />
            ) : (
              <div key={d.id}>
                <Typography variant={variant} key={d.text + i}>
                  {d.name}
                </Typography>
              </div>
            );
          case KIND_COPIED_ITEM:
            // this link is styled to look similar to the MilestoneQuickLink and ItemQuickLink
            if (!d.copiedItemLike.hasAccess)
              return (
                <div className="inline-flex items-end type-body2" key={`${JSON.stringify(d)}${i}`}>
                  {d.copiedItemLike.name}
                </div>
              );
            return (
              <Link
                className="inline-flex items-end underline type-body2 hover:text-type-link"
                key={`${JSON.stringify(d)}${i}`}
                to={generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
                  projectId: d.copiedItemLike.projectID,
                  itemId: d.copiedItemLike.id,
                })}
              >
                {d.copiedItemLike.name}
              </Link>
            );
          case KIND_ITEM:
          case KIND_OPTION:
            return (
              <div className={classes.item} key={`${JSON.stringify(d)}${i}`}>
                <ItemsQuickLink
                  charNameLimit={charNameLimit}
                  charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                  ellipses
                  hasDefaultWidth={false}
                  item={d as ItemLike}
                  showNumberSign
                  textVariant={variant}
                  variant={d ? SMALL : HIDDEN}
                  isVariance={isItemHistory} // reusing existing boolean: do not show status
                  key={`${JSON.stringify(d)} ${i}`}
                />
              </div>
            );
          case KIND_EVENT_ITEM_LIKE_SUMMARY:
            return (
              <div className={classes.item} key={`${JSON.stringify(d)}${i}`}>
                <ItemsQuickLink
                  charNameLimit={charNameLimit}
                  charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                  ellipses
                  hasDefaultWidth={false}
                  item={{
                    // set some defaults for required properties if they don't exist
                    name: '',
                    number: '',
                    status: Status.PENDING,
                    ...(d as EventDescriptionItemLikeSummary).itemLike,
                  }}
                  showNumberSign
                  textVariant={variant}
                  variant={d ? SMALL : HIDDEN}
                  isVariance={isItemHistory}
                  key={`${JSON.stringify(d)} ${i}`}
                />
              </div>
            );
          case KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY: {
            const { count } = d as EventDescriptionPrivateItemLikeSummary;
            const countString = count === 1 ? 'a' : `${count}`;
            return (
              <div className={classes.item} key={`${JSON.stringify(d)}${i}`}>
                <Typography
                  key={`${JSON.stringify(d)} ${i}`}
                >{` ${countString} ${VISIBILITY_PRIVATE_ITEM.toLowerCase()} ${pluralizeString(
                  'option',
                  count
                )}`}</Typography>
              </div>
            );
          }
          default:
            return null;
        }
      })}
  </>
);
export default withStyles(styles)(EventsDescription);
