import { FC, useMemo } from 'react';

import SelectValue from '../../../Select/SelectValue/SelectValue';

type DialogsReportsSharedSelectorProps = {
  userReport: UserReportInput;
  setUserReport: (report: UserReportInput) => void;
};

type SharedReport = {
  name: string;
  value: boolean;
};

const settings = [
  { name: 'Private to me', value: false } as SharedReport,
  { name: 'Team', value: true } as SharedReport,
];

const DialogsReportsSharedSelector: FC<DialogsReportsSharedSelectorProps> = ({
  userReport,
  setUserReport,
}) => {
  const currentSetting = useMemo(() => {
    const value = settings.find((s) => s.value === userReport.shared);
    return value ? value.name : '';
  }, [userReport]);

  const values = settings.map(({ name }) => name);
  return (
    <SelectValue
      cySelect="select-reportSharing"
      values={values}
      hidePrint={false}
      onChange={(v: string | null) => {
        const newValue = settings.find((s) => s.name === v);
        setUserReport({
          ...userReport,
          shared: newValue ? newValue.value : false,
        });
      }}
      value={currentSetting}
    />
  );
};

export default DialogsReportsSharedSelector;
