import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Tab, Tabs } from '@material-ui/core';

import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
  settingNotificationEventTypes,
  settingsNotificationsAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  inAppNotificationsPanelAnchorVar,
  inAppNotificationsProjectIdVar,
  inAppNotificationsShowUnreadOnlyVar,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { REFETCH_IN_APP_NOTIFICATIONS } from '../../../api/refetchSets';
import {
  IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER,
  IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_REACTIVE_VAR,
} from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useRefetch } from '../../../hooks/useRefetch';
import { getTotalPlusText } from '../../../utilities/pagination';
import { generateSharedPath } from '../../../utilities/routes/links';
import { constantCountLabel } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import Settings from '../../Icons/Settings';
import { Button, ButtonBar, IconButton, useActiveOverlayState } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import useSetAllInAppNotificationsReadMutation from '../hooks/useSetAllInAppNotificationsReadMutation';
import { resetNotificationsPagination } from '../InAppNotificationUtils/InAppNotificationUtils';

import { ALL_PROJECTS, THIS_PROJECT, getUnreadTabs } from './InAppNotificationsHeaderUtils';

const UNREAD = 'Unread';
const ALL = 'All';

const getOption = (value: string, count?: number) => {
  const label = count
    ? constantCountLabel(
        value,
        getTotalPlusText(count, IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER)
      )
    : value;

  return { value, label };
};

type Props = {
  loadInAppNotificationsCount?: LoadInAppNotificationsCount;
};

export default function InAppNotificationsHeader(props: Props) {
  // Data
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const {
    allNotificationsCount,
    unreadNotificationsCount,
    allProjectNotificationsCount,
    unreadProjectNotificationsCount,
  } = props.loadInAppNotificationsCount ?? {};

  const refetchNotifications = useRefetch(REFETCH_IN_APP_NOTIFICATIONS);
  const setAllInAppNotificationsRead = useSetAllInAppNotificationsReadMutation();
  const sendAnalytics = useAnalyticsEventHook();
  const { getTextLabel } = useMemoWrapper(
    getUnreadTabs,
    Boolean(unreadNotificationsCount && unreadNotificationsCount > 0),
    Boolean(unreadProjectNotificationsCount && unreadProjectNotificationsCount > 0)
  );

  const activeOverlayState = useActiveOverlayState();

  // Vars
  const displayAllProjects = useReactiveVar(inAppNotificationsProjectIdVar) === null;
  const showUnreadOnly = useReactiveVar(inAppNotificationsShowUnreadOnlyVar);

  // Counts, depending on tab
  const allCount = displayAllProjects ? allNotificationsCount : allProjectNotificationsCount;
  const unreadCount = displayAllProjects
    ? unreadNotificationsCount
    : unreadProjectNotificationsCount;
  const buttonBarOptions = [getOption(ALL, allCount), getOption(UNREAD, unreadCount)];

  // Tabs
  const onlyAllProjects = !projectId;
  const tab = onlyAllProjects || displayAllProjects ? ALL_PROJECTS : THIS_PROJECT;

  const setTab = (isDisplayAll: boolean) => {
    inAppNotificationsProjectIdVar(isDisplayAll ? null : projectId);
    resetNotificationsPagination();
    refetchNotifications();

    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        isDisplayAll
          ? inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_ALL_PROJECT_CLICK
          : inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_THIS_PROJECT_CLICK,
        {
          projectId,
        }
      )
    );
  };

  return (
    <div className="bg-background-primary text-type-primary">
      <div className="flex gap-2 p-4">
        <div className="mr-auto type-heading2">Notifications</div>
        {!onlyAllProjects && (
          <IconButton
            aria-label="open notification settings"
            data-cy="IAN-Settings-Icon"
            icon={<Settings />}
            onClick={() => {
              navigate({
                pathname: generateSharedPath(JoinProjectRoutes.SETTINGS, {
                  projectId,
                }),
                hash: '#Notifications',
              });

              sendAnalytics(
                settingsNotificationsAnalyticsEvent(
                  settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_CTA,
                  {
                    projectId,
                  }
                )
              );
              setTimeout(() => inAppNotificationsPanelAnchorVar(null), 1000);
              activeOverlayState?.close();
            }}
            type="secondary"
          />
        )}
      </div>
      {!onlyAllProjects && (
        <div className="border-b">
          <Tabs value={tab} textColor="inherit">
            <Tab
              className="grow"
              data-cy="IAN-Tab-All-Projects"
              label={getTextLabel(ALL_PROJECTS)}
              value={ALL_PROJECTS}
              onClick={() => {
                setTab(true);
              }}
            />
            <Tab
              className="grow"
              data-cy="IAN-Tab-This-Project"
              label={getTextLabel(THIS_PROJECT)}
              value={THIS_PROJECT}
              onClick={() => {
                setTab(false);
              }}
            />
          </Tabs>
        </div>
      )}
      <div className="flex justify-between p-4 pr-0">
        <ButtonBar
          onChange={(selected) => {
            const newUnreadOnlyValue = selected === UNREAD;
            if (newUnreadOnlyValue === showUnreadOnly) return;

            setReactiveLocal(
              inAppNotificationsShowUnreadOnlyVar,
              IN_APP_NOTIFICATIONS_SHOW_UNREAD_ONLY_REACTIVE_VAR,
              newUnreadOnlyValue
            );
            resetNotificationsPagination();
            refetchNotifications();

            sendAnalytics(
              inAppNotificationsAnalyticsEvent(
                inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_TOGGLE_VIEW,
                {
                  projectId,
                  view: newUnreadOnlyValue ? UNREAD : ALL,
                }
              )
            );
          }}
          options={buttonBarOptions}
          value={showUnreadOnly ? UNREAD : ALL}
          data-cy="ian-all-unread-btn-bar"
        />
        {unreadNotificationsCount ? (
          <Button
            data-cy="IAN-Mark-all-as-read"
            label="Mark all as read"
            onClick={() => {
              const selectedAllTab = inAppNotificationsProjectIdVar() === null;
              resetNotificationsPagination();
              setAllInAppNotificationsRead(projectId || null, selectedAllTab);
              refetchNotifications();

              sendAnalytics(
                inAppNotificationsAnalyticsEvent(
                  inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_MARK_ALL_READ_CLICK,
                  {
                    projectId,
                  }
                )
              );
            }}
            type="tertiary"
          />
        ) : null}
      </div>
    </div>
  );
}
