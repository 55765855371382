import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { companyTabIDVar } from '../../api/apollo/reactiveVars';
import { JoinCompanyRoutes, JoinRoutes } from '../../api/gqlEnums';
import {
  COMPANY_CATEGORIZATIONS_PATH,
  COMPANY_STANDARDS_PATH,
  COMPANY_TAB,
  COMPANY_TAB_PATH,
} from '../../constants';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import usePageHeight from '../../hooks/usePageHeight';
import { withStyles } from '../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import { useUserProfile } from '../../utilities/userProfile';
import JoinSelect from '../Select/JoinSelect/JoinSelect';

import { useCompaniesQuery } from './CompanyHooks';
import CompanyTabNav from './CompanyTabNav/CompanyTabNav';
import CompanyTabStyles from './CompanyTabStyles';
import { CompanyHeadingsTabs, UserStatusToggle, useCompanyRoutesData } from './CompanyTabUtils';

type CompanyTabProps = {
  classes: Classes<typeof CompanyTabStyles>;
};

const CompanyTab: FC<CompanyTabProps> = ({ classes }) => {
  const navigate = useNavigate();
  const profile = useUserProfile();
  document.title = `${COMPANY_TAB} ${profile ? ` - ${profile.nickname}` : ''}`;

  const sendAnalytics = useAnalyticsEventHook();

  useRedirectCompanyHome();

  const { companyID, filterManager, searchManager } = useCompanyRoutesData();

  const view = window.location.pathname.split('/')[2] || CompanyHeadingsTabs.MEMBERS;

  const setView = (view: string) => {
    const currentView = window.location.pathname.split('/')[2] || CompanyHeadingsTabs.MEMBERS;
    if (view !== currentView) {
      searchManager.clearSearch();
      filterManager.setFilterStatus(UserStatusToggle.ALL);
    }
    if (view === COMPANY_STANDARDS_PATH) {
      navigate(`/${COMPANY_TAB_PATH}/${view}/${COMPANY_CATEGORIZATIONS_PATH}`);
    } else {
      navigate(`/${COMPANY_TAB_PATH}/${view}`);
    }

    switch (view) {
      case CompanyHeadingsTabs.MEMBERS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBERS_CTA));
        break;
      case CompanyHeadingsTabs.COLLABORATORS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.COLLABORATORS_CTA));
        break;
      case CompanyHeadingsTabs.SETTINGS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_CTA));
        break;
      case CompanyHeadingsTabs.PROJECT_STATS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_CTA));
        break;
      default:
    }
  };

  const height = usePageHeight();

  const companiesQueryResult = useCompaniesQuery();
  const companies =
    companiesQueryResult.data?.companies ?? companiesQueryResult.previousData?.companies ?? [];
  if (!companyID) return null;

  return (
    <div className="flex flex-col overflow-hidden bg-background-primary" style={{ height }}>
      <div className={classes.headerPin}>
        {/* Display the selector for verified Join users. */}
        {companies?.length > 0 && (
          <JoinSelect
            classNameSelect={classes.select}
            entries={companies}
            onChange={(newValue: UUID) => {
              if (newValue) {
                companyTabIDVar(newValue);
                navigate(`/${COMPANY_TAB_PATH}/${view}`);
              }
            }}
            search
            value={companyID}
          />
        )}
        <div>
          <CompanyTabNav view={view} setView={setView} />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTab);

export const useRedirectCompanyHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/$/, '');
    const rootCompanyPath = generateSharedPath(JoinRoutes.COMPANY_TAB, {});
    if (pathnameWithoutTrailingSlash === rootCompanyPath) {
      navigate(generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS, {}));
    }
  }, [location, navigate]);
};
