import queryString from 'query-string';

import { GridVariant } from '../../../actions/gridAnalytics';
import { EstimateType } from '../../../api/gqlEnums';
import { TYPE } from '../../../constants';

export const getEstimateType = (): string | undefined => {
  const { search } = window.location;
  const parsed = queryString.parse(search, { arrayFormat: 'index' });
  return parsed[TYPE]?.toString();
};

export const getGridVariantFromEstimateType = (
  estimateType?: EstimateType | string
): GridVariant | null => {
  switch (estimateType) {
    case EstimateType.ACTIVE_ESTIMATE:
      return GridVariant.MILESTONE_ESTIMATE;
    case EstimateType.BUDGET:
      return GridVariant.MILESTONE_BUDGET;
    case EstimateType.ITEM:
      return GridVariant.ITEM_FULLSCREEN;
    default:
  }
  return null;
};
