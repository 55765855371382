export default function InAppNotificationIconNewItem() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12L20.56 9.22001L20.9 5.54001L17.29 4.72001L15.4 1.54001L12 3.00001L8.6 1.54001L6.71 4.72001L3.1 5.53001L3.44 9.21001L1 12L3.44 14.78L3.1 18.47L6.71 19.29L8.6 22.47L12 21L15.4 22.46L17.29 19.28L20.9 18.46L20.56 14.78L23 12ZM18.49 14.11L18.75 16.9L16.01 17.52L14.58 19.93L12 18.82L9.42 19.93L7.99 17.52L5.25 16.9L5.51 14.1L3.66 12L5.51 9.88001L5.25 7.10001L7.99 6.49001L9.42 4.08001L12 5.18001L14.58 4.07001L16.01 6.48001L18.75 7.10001L18.49 9.89001L20.34 12L18.49 14.11ZM11 15H13V17H11V15ZM11 7.00001H13V13H11V7.00001Z"
        fill="currentColor"
      />
    </svg>
  );
}
