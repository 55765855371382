import { TimelineGroups } from '../../../../api/gqlEnums';
import TimelineCollapse from '../../../Timeline/TimelineCollapse';
import { TimelineItemLegend } from '../timeline/timeline.types';

import TimelineItemsLegend from './TimelineItemsLegend';

const TOP_WITH_TIMELINE = 6;
const TOP_NO_TIMELINE = 12;

export default function TimelineItemsExpandLegend({
  data,
  timelineHeight,
  total,
  withoutDueDateCount,
  isTimeline,
}: {
  data: TimelineItemLegend;
  timelineHeight: number;
  total: number;
  withoutDueDateCount: number;
  isTimeline: boolean;
}) {
  const top = isTimeline ? timelineHeight + TOP_WITH_TIMELINE : TOP_NO_TIMELINE;
  return (
    <div className="absolute w-full pl-6 pr-6" style={{ top }}>
      <div className="flex w-full">
        <div className="ml-[-8px] flex self-end">
          <TimelineCollapse nodeId={TimelineGroups.ITEMS} />
        </div>
        <TimelineItemsLegend data={data} total={total} withoutDueDateCount={withoutDueDateCount} />
      </div>
      <div className="bg-border mt-[6px] w-full border-b" />
    </div>
  );
}
