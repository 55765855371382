import { FC } from 'react';

import { FiberManualRecord as Circle } from '@material-ui/icons';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { PROJECT_COMP_MODIFIED_DOT } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';

import styles from './ProjectCompsInputsModifiedDotStyles';

type ProjectCompsInputsModifiedDotProps = {
  addPaddingRight?: boolean;
  classes: Classes<typeof styles>;
  isAverageComp?: boolean;
  options: MenuOption[];
};

const ProjectCompsInputsModifiedDot: FC<ProjectCompsInputsModifiedDotProps> = ({
  addPaddingRight,
  classes,
  isAverageComp,
  options,
}) => {
  const sendAnalytics = useSendAnalyticsEventHook();

  const icon = <Circle className={classes.icon} />;

  return (
    <IconMenu
      className={`${classes.iconMenu} ${addPaddingRight ? classes.paddingRight : ''}`}
      cy={PROJECT_COMP_MODIFIED_DOT}
      icon={icon}
      iconButtonClassName={classes.iconButton}
      onClick={() => {
        sendAnalytics(
          projectCompsAnalyticsEvent(
            isAverageComp
              ? projectCompsEventTypes.PROJECT_COMPS_AVERAGE_CELL_MENU_CTA
              : projectCompsEventTypes.PROJECT_COMPS_PROJECT_COMP_CELL_MENU_CTA
          )
        );
      }}
      options={options}
      variant={SelectVariants.UNSTYLED}
    />
  );
};

export default withStyles(styles)(ProjectCompsInputsModifiedDot);
