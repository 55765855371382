// NEW COLLABORATORS PAGE
import { FC } from 'react';

import { Help } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { TABLE_HEADER_PERMISSIONS } from '../../../constants';
import { SortDirection } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { CompaniesSortDataType } from '../../Companies/CompaniesList/CompaniesListUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import styles from '../CollaboratorsPage/CollaboratorsPageStyles';
import { SortDataType } from '../CollaboratorsPage/CollaboratorsPageUtils';

type CollaboratorsListHeaderProps = {
  classes: Classes<typeof styles>;
  hasMultiple: boolean;
  header: { copy: string; title: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSortData: (sortData: any) => void;
  sortData: SortDataType | CompaniesSortDataType;
};

const CollaboratorsListHeader: FC<CollaboratorsListHeaderProps> = ({
  classes,
  hasMultiple,
  header,
  setSortData,
  sortData,
}) => {
  const { copy, title } = header;
  const { sortKey, sortDirection } = sortData;
  // DISPLAY LOGIC
  const hasTitle = copy !== '';
  const isActiveHeader = sortKey === copy;
  const isNotClickable = [TABLE_HEADER_PERMISSIONS].includes(copy);

  // UI GENERATORS
  const arrowDirectionClass =
    sortDirection === SortDirection.SORT_ASCENDING || sortKey !== copy
      ? ''
      : classes.iconSortArrowFlipped;
  const headerButton = (
    <button
      aria-label={copy}
      className={`${!isNotClickable ? classes.clickable : ''} ${classes.cell} ${
        classes.headerCell
      }`}
      data-cy={`CollaboratorsListHeader-${copy.replace(' ', '')}Button`}
      onClick={() => {
        if (!isNotClickable) {
          const newSortDirection =
            sortDirection === SortDirection.SORT_ASCENDING
              ? SortDirection.SORT_DESCENDING
              : SortDirection.SORT_ASCENDING; // toggle the direction
          setSortData({ sortKey: copy, sortDirection: newSortDirection });
        }
      }}
      tabIndex={isNotClickable ? -1 : 0}
      type="submit"
    >
      <div className={classes.copy}>{copy}</div>
      {title && (
        <NormalTooltip title={title}>
          <Help className={classes.icon} />
        </NormalTooltip>
      )}
      {!isNotClickable && hasMultiple && (
        <ArrowDownwardIcon
          classes={{
            root: isActiveHeader
              ? `${classes.iconSortArrowActive} ${arrowDirectionClass}`
              : `${classes.iconSortArrow} ${arrowDirectionClass}`,
          }}
        />
      )}
    </button>
  );
  return hasTitle ? headerButton : <span />;
};

export default withStyles(styles)(CollaboratorsListHeader);
