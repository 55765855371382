import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    highlighted: {
      color: theme.palette.primaryBlue,
    },
    avatar: {
      height: 36,
      width: 36,
      borderRadius: '50%',
      objectFit: 'cover',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    ignoreLinkStyles: {
      color: 'unset',
      textDecoration: 'unset',
      outline: 'unset',
    },
    supportIcon: {
      width: 28,
      height: 28,
    },
  });

export default styles;
