import { formatRelative } from 'date-fns';

import en from 'date-fns/locale/en-US';

import { isProduction } from '../../../../api/joinAPI';
import { ChangeEvent } from '../../../../generated/graphql';
import { LocaleRelative } from '../../../../utilities/dates';
import { SelectEntry } from '../../../scales';

export type ItemInfo = Pick<
  Item,
  'id' | 'name' | 'descriptionStyled' | 'estimateCost' | 'assetCount' | 'contingencyDrawCost'
>;

// fake data for a procore change event
export const fakeProcoreChangeEvents: ChangeEvent[] = [
  {
    id: 1,
    number: 1,
    alphanumericNumber: '1',
    title: 'Fire Sprinkler Updates',
    description: 'Adding fire sprinklers to the building',
    status: 'Open',
    createdAt: '2021-01-01T00:00:00.000Z',
    updatedAt: '2021-01-01T00:00:00.000Z',
    eventType: 'Owner Change',
    changeReason: 'Client Request',
    eventScope: 'tbd',
    cost: 100000,
    isDeleted: false,
  },
  {
    id: 2,
    number: 2,
    alphanumericNumber: '2',
    title: 'Upgrade to LED Lighting',
    description: 'Upgrading all lighting to LED',
    status: 'Open',
    createdAt: '2021-01-01T00:00:00.000Z',
    updatedAt: '2021-01-01T00:00:00.000Z',
    eventType: 'Allowance',
    changeReason: 'Client Request',
    eventScope: 'tbd',
    cost: 50000,
    isDeleted: false,
  },
];

export const EventStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
  PENDING: 'pending',
  VOID: 'void',
};

export const changeEventStatuses: SelectEntry[] = [
  { label: 'Open', id: EventStatuses.OPEN },
  { label: 'Closed', id: EventStatuses.CLOSED },
  { label: 'Pending', id: EventStatuses.PENDING },
  { label: 'Void', id: EventStatuses.VOID },
];

export const EventTypes = {
  ALLOWANCE: 'allowance',
  CONTINGENCY: 'contingency',
  OWNER_CHANGE: 'owner_change',
  TBD: 'tbd',
  TRANSFER: 'transfer',
};

export const changeEventTypes: SelectEntry[] = [
  { label: 'Allowance', id: EventTypes.ALLOWANCE },
  { label: 'Contingency', id: EventTypes.CONTINGENCY },
  { label: 'Owner Change', id: EventTypes.OWNER_CHANGE },
  { label: 'TBD', id: EventTypes.TBD },
  { label: 'Transfer', id: EventTypes.TRANSFER },
];

export const ChangeReasons = {
  ALLOWANCE: 'Allowance',
  BACKCHARGE: 'Backcharge',
  CLIENT_REQUEST: 'Client Request',
  DESIGN_DEVELOPMENT: 'Design Development',
  EXISTING_CONDITION: 'Existing Condition',
};

export const changeEventChangeReasons: SelectEntry[] = [
  { label: 'Allowance', id: ChangeReasons.ALLOWANCE },
  { label: 'Backcharge', id: ChangeReasons.BACKCHARGE },
  { label: 'Client Request', id: ChangeReasons.CLIENT_REQUEST },
  { label: 'Design Development', id: ChangeReasons.DESIGN_DEVELOPMENT },
  { label: 'Existing Condition', id: ChangeReasons.EXISTING_CONDITION },
];

export const loadTimeDateString = (date: Date) => {
  const formatRelativeLocale: LocaleRelative = {
    today: `'today at' h:mm aa`,
    other: `MM/dd/yyyy h:mm aa`,
  };

  const locale: Locale = {
    ...en,
    formatRelative: (token: keyof LocaleRelative) =>
      formatRelativeLocale[token] || formatRelativeLocale.other,
  };

  const dateString = formatRelative(date, new Date(), { locale });
  return dateString;
};

export const getProcoreChangeEventLink = (sourceEntityID: number, linkedProject?: string) => {
  if (!linkedProject) return '#';

  const domain = isProduction ? `procore` : `sandbox.procore`;
  return `https://${domain}.com/${linkedProject}/project/change_events/events/${sourceEntityID}`;
};
