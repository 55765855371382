import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';

import useListSubcategoriesQuery from '../../../hooks/useListSubcategoriesQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import MultilevelCategorizationsStyles from './MultilevelCategorizationStyles';
import { CategoryMapInput } from './types';

type MultilevelAccordionProps = {
  classes: Classes<typeof MultilevelCategorizationsStyles>;
  id: string;
  categoryMap?: CategoryMapInput[];
  category?: Category;
  categorizationID?: string;
  expanded: boolean;
};

const MultilevelAccordion: FC<MultilevelAccordionProps> = ({
  classes,
  id,
  categoryMap,
  category,
  categorizationID,
  expanded,
}) => {
  const { data: subCategories } = useListSubcategoriesQuery(
    categorizationID || '',
    category?.id || '',
    100,
    !categorizationID
  );

  const mappingCategories = categoryMap || subCategories?.subcategory;

  const [isCollapsed, setIsCollapsed] = useState<boolean[]>(
    mappingCategories?.map(() => !expanded) || []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onTitleClick = (index: any) => {
    const temp = [...isCollapsed];
    temp[index] = !temp[index];
    setIsCollapsed(temp);
  };

  useEffect(() => {
    if (subCategories && isCollapsed.length === 0 && subCategories?.subcategory?.length > 0) {
      setIsCollapsed(subCategories?.subcategory?.map(() => !expanded));
    } else if (mappingCategories) {
      setIsCollapsed(
        mappingCategories.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          (category: any) =>
            (category?.hasSubcategories || category?.subcategories?.length > 0) && expanded
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [subCategories, isCollapsed.length, expanded]);

  if (mappingCategories && mappingCategories.length > 0) {
    return (
      <div key={id}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :) */}
        {mappingCategories.map((singleCategory: any, index: any) => (
          <React.Fragment key={singleCategory.number || singleCategory.code}>
            <div
              onClick={() => onTitleClick(index)}
              onKeyDown={() => {}}
              className={`${
                singleCategory?.subcategories?.length > 0 || singleCategory?.hasSubCategories
                  ? ''
                  : classes.child
              } ${classes.descriptionFront}`}
            >
              <div className={classes.iconContainer}>
                {(singleCategory?.subcategories?.length > 0 ||
                  singleCategory?.hasSubCategories) && (
                  <CollapseIcon className={classes.icon} isCollapsed={isCollapsed?.[index]} />
                )}
                <Typography className={classes.title}>
                  {singleCategory.number || singleCategory.code}
                  {singleCategory.name || singleCategory.description ? ' - ' : ''}
                  {singleCategory.name || singleCategory.description}
                </Typography>
              </div>
            </div>
            {(singleCategory?.subcategories?.length > 0 || singleCategory?.hasSubCategories) &&
              !isCollapsed?.[index] &&
              isCollapsed.length > 0 && (
                <div className={classes.paddingLeft}>
                  <MultilevelAccordion
                    classes={classes}
                    id={id}
                    category={singleCategory}
                    categoryMap={singleCategory.subcategories}
                    categorizationID={categorizationID}
                    expanded={expanded}
                  />
                </div>
              )}
          </React.Fragment>
        ))}
      </div>
    );
  }
  return <></>;
};

export default withStyles(MultilevelCategorizationsStyles)(MultilevelAccordion);
