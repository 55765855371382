import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const FilterMenuStyles = (theme: KomodoTheme) =>
  createStyles({
    alignItemsCenter: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 3,
    },
    buttonContainer: {
      margin: '0 8px',
    },
    filterText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      fontWeight: 300,
    },
    leftAlign: {
      display: 'flex',
      alignItems: 'start',
    },
    rightAlign: {
      display: 'flex',
      marginLeft: 'auto',
    },
    settingContainer: {
      padding: 0,
      '&> div': {
        margin: 0,
      },
    },
  });

export default FilterMenuStyles;
