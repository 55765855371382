import { ChangeEvent, FC, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useHasFeature } from '../../../hooks/useFeatureQuery';
import {
  DISABLE_ITEM_NUMBER_TOOLTIP,
  DISABLE_ITEM_NUMBER_TOOLTIP_NEW,
} from '../../Items/ItemVisibilityToggle/ItemVisibilityToggleUtils';
import { Chip, Tooltip } from '../../scales';

import ItemNumberTextFieldStyles from './ItemNumberTextFieldStyles';

type ItemNumberTextFieldProps = {
  editable: boolean;
  classes: Classes<typeof ItemNumberTextFieldStyles>;
  isPrivate: boolean;
  itemId: string;
  itemType: string;
  setItemNumber: (id: string, number: string, itemType: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  value: any;
  width?: number | null;
};

const ItemNumberTextField: FC<ItemNumberTextFieldProps> = ({
  editable,
  classes,
  isPrivate,
  itemId,
  itemType,
  setItemNumber,
  value = '',
  width = null,
}) => {
  const [number, setNumber] = useState('');
  useEffect(() => {
    setNumber(value);
  }, [value]);

  const isDisabled = !editable || isPrivate;
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');

  const textField = (
    <TextField
      data-cy="textField-itemNumber"
      disabled={isDisabled}
      className={isPrivate ? classes.disabledField : classes.field}
      InputProps={{
        classes: { root: isPrivate ? classes.disabledInput : classes.input },
        disableUnderline: true,
      }}
      // Note: Our current version of Material UI uses both inputProps & InputProps for different things
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        style: { alignItems: 'center', display: 'flex' },
      }}
      onBlur={() => {
        setItemNumber(itemId, number, itemType);
      }}
      onChange={(evt: ChangeEvent<HTMLInputElement>) => setNumber(evt.target.value)}
      onKeyDown={(evt) => {
        if (evt.key === 'Enter') {
          (evt.target as HTMLElement).blur();
        }
      }}
      placeholder={isPrivate ? 'No item number' : 'Item Number'}
      style={{ width: width ?? undefined }}
      type="text"
      value={number}
      title={!isPrivate ? `Item Number: ${number}` : undefined}
    />
  );

  return isPrivate ? (
    <Tooltip
      content={
        <div className="type-table-text">
          {isItemSharingFeature ? DISABLE_ITEM_NUMBER_TOOLTIP_NEW : DISABLE_ITEM_NUMBER_TOOLTIP}
        </div>
      }
    >
      <div>
        <Chip text="Draft" />
      </div>
    </Tooltip>
  ) : (
    textField
  );
};

export default withStyles(ItemNumberTextFieldStyles)(ItemNumberTextField);
