import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    divider: {
      marginBottom: 40,
      marginTop: 40,
    },
    editingLabel: {
      ...theme.typography.body1,
    },
    horizontal: {
      display: 'flex',
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
    },
    innerContainer: {
      background: 'none',
      paddingBottom: 5,
      paddingLeft: 4,
      paddingRight: 4,
    },
    label: {
      ...theme.typography.caption,
      marginTop: 8,
    },
    nameOption: {
      ...theme.typography.number,
      fontSize: '0.9rem',
      fontWeight: 300,
    },
    caption: {
      ...theme.typography.caption,
      marginTop: 4,
    },
    topPadding: {
      paddingTop: 24,
    },
    pullRight: {
      marginLeft: 'auto',
    },
    error: {
      border: `1px solid ${theme.palette.red}`,
      color: theme.palette.red,
      outline: `1px solid ${theme.palette.red}`,
    },
    errorIcon: {
      color: theme.palette.red,
      paddingLeft: '4px',
      paddingTop: '4px',
    },
  });
