import { FC, useState } from 'react';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { EventType } from '../../../generated/graphql';
import { eventDateString } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import AssetCardsWrapper from '../../assets/AssetsCards/AssetsCardsWrapper';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ItemHistoryListComment from '../ItemHistoryListComment/ItemHistoryListComment';
import ItemHistoryEventExpanded from '../ItemHistoryListItem/ItemHistoryEventExpanded';
import { ItemActivityEvent, ItemEventDetails } from '../utils';

import EventDescription from './EventDescription';
import ItemHistoryEventPath from './ItemHistoryEventPath';
import ItemHistoryItemDetails from './ItemHistoryItemDetails';
import ItemHistoryItems from './ItemHistoryItems';
import ItemHistorySidebarButton from './ItemHistorySidebarButton';

type Props = {
  event: ItemActivityEvent;
  isItemHistory?: boolean;
  itemDetails?: ItemEventDetails;
  projectID: UUID;
  isPrint: boolean;
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  selectedEventID?: UUID | null;
  setSelectedEventID?: (eventID: UUID | null) => void;
};

// This isn't used yet, but will be used in the future
const itemDetailsType = [
  EventType.ATTACH_OPTION,
  EventType.CHANGE_ASSIGNEE,
  EventType.CHANGE_CATEGORY,
  EventType.CHANGE_DESCRIPTION,
  EventType.CHANGE_DUE_DATE,
  EventType.CHANGE_NAME,
  EventType.CHANGE_NUMBER,
  EventType.CHANGE_STATUS,
  EventType.CHANGE_VISIBILITY, // check draft item events later
  EventType.CREATE_ITEM,
  EventType.CREATE_OPTION,
];

export const getItemDetails = (event: ItemActivityEvent) => {
  const itemDetails: ItemEventDetails | undefined = undefined;
  // for some event types we want to display additional item details
  if (event.eventTypes.find((e) => itemDetailsType.includes(e))) {
    // query item details here
  }
  return itemDetails;
};

const ItemHistoryEvent: FC<Props> = ({
  event,
  isItemHistory = false,
  projectID,
  itemDetails,
  isPrint,
  shouldDisplayCosts,
  canViewItemAttachments,
  selectedEventID,
  setSelectedEventID,
}) => {
  const [expanded, setExpanded] = useState(false);

  const createdAtString = eventDateString(event.created, !isPrint);
  const createdAtTooltipString = `${event.created.toLocaleTimeString(undefined, {
    timeStyle: 'short',
  })} ${event.created.toLocaleDateString()}`;

  const assets = canViewItemAttachments ? event.eventContent?.addedAttachments : [];
  const comment = event.eventContent?.comment;
  const itemlinks = event.itemLinks.options.length
    ? event.itemLinks.options
    : [event.itemLinks.item].filter(isNonNullable);

  const isDeprecatedItem = !itemlinks.length || itemlinks[0].deprecated;

  return (
    <div
      data-cy="listItem-eventsItemHistoryListItem"
      className={`group flex flex-row gap-2 ${comment ? 'mt-2' : ''}`}
    >
      <div className="mt-1 h-6 w-20 flex-shrink-0 items-center whitespace-nowrap text-right text-type-muted type-table-number">
        <NormalTooltip title={createdAtTooltipString}>
          <div>{createdAtString}</div>
        </NormalTooltip>
      </div>
      <div>
        <ItemHistoryEventPath user={comment?.author} />
      </div>
      <div className={`mt-0.5 overflow-hidden ${!isItemHistory ? `w-full flex-grow` : ''}`}>
        <div className={`flex ${!isItemHistory ? `w-full flex-grow` : ''}`}>
          <EventDescription
            description={event.description}
            isItemHistory={isItemHistory}
            eventID={event.id}
            projectID={projectID}
          />
          {isItemHistory && !!event.eventCount && !isPrint && (
            <button
              data-cy="itemHistory-showMore"
              className="t-1 relative bottom-0.5 mr-auto h-6 w-6"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
            </button>
          )}
        </div>
        {itemlinks.map((itemLink) => {
          return (
            <ItemHistoryItems
              key={`${itemLink.id}-item`}
              isItemHistory={isItemHistory}
              itemLink={itemLink}
              eventID={`${event.id}-item`}
              projectID={projectID}
            />
          );
        })}
        <ItemHistoryItemDetails itemDetails={itemDetails} />
        {assets && !!assets.length && (
          <div className="mt-2 flex flex-wrap gap-2">
            <AssetCardsWrapper
              assets={assets}
              hasDeletePermission={false}
              readOnly
              itemID={itemDetails?.id}
              showMenu={false}
            />
          </div>
        )}
        {comment && (
          <div className="mt-2 w-fit rounded-xl border border-border-muted px-3 py-2 type-body1">
            <ItemHistoryListComment itemEvent={event} commentText={comment.text} isActivityFeed />
          </div>
        )}
        {(expanded || isPrint) && (
          <ItemHistoryEventExpanded shouldDisplayCosts={shouldDisplayCosts} event={event} />
        )}
        <div className="h-4" />
      </div>
      {!isItemHistory && !isDeprecatedItem && (
        <ItemHistorySidebarButton
          eventID={event.id}
          itemID={event.itemID}
          selectedEventID={selectedEventID}
          setSelectedEventID={setSelectedEventID}
        />
      )}
    </div>
  );
};

export default ItemHistoryEvent;
