import { FC } from 'react';

import { ChangeEvent } from '../../../../generated/graphql';

import ProcoreChangeEventComponent from './ProcoreChangeEvent';

type IntegrationsChangeEventContentProps = {
  canDeleteItemIntegration: boolean;
  changeEvents: ChangeEvent[];
  pageLoadTime?: Date;
  sourceID: UUID;
  linkedProjectID?: string;
  refetchChangeEvents: () => void;
  setSubmitted: (value: boolean) => void;
};

const IntegrationsChangeEventContent: FC<IntegrationsChangeEventContentProps> = ({
  canDeleteItemIntegration,
  changeEvents,
  pageLoadTime,
  sourceID,
  linkedProjectID,
  refetchChangeEvents,
  setSubmitted,
}) => {
  const changeEventContent = changeEvents?.map((changeEvent) => (
    <ProcoreChangeEventComponent
      key={changeEvent.id}
      canDeleteItemIntegration={canDeleteItemIntegration}
      changeEvent={changeEvent}
      pageLoadTime={pageLoadTime}
      sourceID={sourceID}
      refetchChangeEvents={refetchChangeEvents}
      setSubmitted={setSubmitted}
      linkedProjectID={linkedProjectID}
    />
  ));

  return <>{changeEventContent}</>;
};

export default IntegrationsChangeEventContent;
