import { Close } from '@material-ui/icons';

import { GLOBAL_RECENT_SEARCH } from '../../../../../tagConstants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import Clock from '../../../../Icons/Clock';
import { Button, IconButton } from '../../../../scales';

import styles from './RecentSearchStyles';

interface RecentSearchProps {
  classes: Classes<typeof styles>;
  onDelete: (search: string) => void;
  onNavigate: (search: string) => void;
  search: string;
}

const RecentSearch = ({ classes, search, onNavigate, onDelete }: RecentSearchProps) => (
  <div className={classes.container}>
    <div key={search} className={classes.leftAlign}>
      <Button
        data-cy={GLOBAL_RECENT_SEARCH}
        label={`‘${search}’`}
        onClick={() => onNavigate(search)}
        startIcon={<Clock />}
        type="tertiary"
      />
    </div>
    <IconButton
      aria-label={`close-${search}`}
      icon={<Close />}
      onClick={() => onDelete(search)}
      type="secondary"
    />
  </div>
);

export default withStyles(styles)(RecentSearch);
