import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { isUUID } from 'validator';

const LOCAL_STORAGE_KEY = 'NAV_LATEST_PROJECTS';

export default function useLastViewedProjectIDs() {
  const [lastViewedProjectIDs, setLastViewedProjectID] = useMRUCache<UUID>(
    LOCAL_STORAGE_KEY,
    5,
    []
  );

  // Whenever we've nav'd to a new project, set it as most-recently-used.
  // Since we're relying on useParams, this must be called from within the router.
  const { projectId } = useParams();
  useEffect(() => {
    if (projectId && isUUID(projectId)) {
      setLastViewedProjectID(projectId);
    }
  }, [projectId, setLastViewedProjectID]);

  return lastViewedProjectIDs;
}

function useMRUCache<T>(key: string, size: number, initialValue: T[]) {
  /**
   * We explitly use a two-level cache here rather than just relying on
   * useLocalStorage. The reason behind this is that we want an updater-form of
   * the setter function so that we can memoize well and uLS has a bug with
   * that form.
   */
  const [localStorageCache, setLocalStorageCache] = useLocalStorage<T[]>(key, initialValue);
  const [cache, setCache] = useState(localStorageCache);

  const onTouch = useCallback(
    (entry: T) => {
      setCache((prevState = []) => {
        const newValue = [entry, ...prevState.filter((v) => v !== entry)].slice(0, size);

        setLocalStorageCache(newValue);
        return newValue;
      });
    },
    [setLocalStorageCache, size]
  );

  return [cache ?? [], onTouch] as const;
}
