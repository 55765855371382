export type ItemDescriptors = { number?: string; name?: string };

export const styleItemNumber = (number?: string | null) => (number ? `#${number}` : '');

export const generateTitle = (item: ItemDescriptors) => {
  const { name, number } = item;
  if (!number) return name || '';
  return `${styleItemNumber(number)}: ${name}`;
};

export const getItemDescriptor = (item: ItemDescriptors | null) =>
  styleItemNumber(item?.number) || item?.name || '';
