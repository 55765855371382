import { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Card, CardHeader, Divider, Typography } from '@material-ui/core';
import { FullscreenExit } from '@material-ui/icons';

import { EstimateType, JoinProjectRoutes, TermKey } from '../../../api/gqlEnums';
import { DEFAULT } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  checkCostModeIncludesMarkups,
  costModeVar,
  mapCostModeEnumToString,
} from '../../../utilities/costMode';
import { computeDraftEstimateTotal } from '../../../utilities/permissions/itemsPermissions';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import CostReportSettingsPanel from '../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import { useGridEstimateArmatureQuery } from '../../JoinGrid/hooks/estimateQuery';
import { CostParams } from '../../Milestone/MilestoneDetails/MilestoneDetailsNav/MilestoneDetailsNav';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import CTAIconButton from '../../shared-widgets/CTAIconButton';
import useMemoWrapper from '../../useMemoWrapper';
import CostEstimate from '../CostEstimate';

import styles from './EstimateAccordionStyles';
import { getEstimateType, getGridVariantFromEstimateType } from './EstimateAccordionUtils';
import EstimateTotal from './EstimateTotal';

export enum MilestoneGridEvents {
  m_eCTA = 'milestone_estimateCTA',
  m_bCTA = 'milestone_budgetCTA',
}

export const computeCategorizations = (fields: Field[] = []): Categorization[] =>
  fields.map(({ categorization }) => categorization).filter((c): c is Categorization => !!c);

type EstimateAccordionProps = {
  classes: Classes<typeof styles>;
  costParams: CostParams;
  isMilestoneDetails?: boolean;
  milestone: Milestone;
  milestoneCostReports: MilestoneCostReports;
  refetchReports?: () => void;
  type?: EstimateType;
};

const EstimateAccordion: FC<EstimateAccordionProps> = ({
  classes,
  costParams,
  isMilestoneDetails = false,
  milestone,
  milestoneCostReports,
  refetchReports,
  type,
}) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const estimateType = type || getEstimateType();
  const isEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;

  const sendAnalytics = useAnalyticsEventHook();
  const { canView } = usePermissions();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);
  // TODO CT-750: MILESTONE_LINES deals with access to both Milestone Lines and Milestone Estimate
  const canViewDetails = isEstimate
    ? canView(PermissionResource.MILESTONE_LINES)
    : canView(PermissionResource.MILESTONE_BUDGET);
  const canViewMilestoneCosts = canView(PermissionResource.MILESTONE_LINES);

  const costMode = useReactiveVar(costModeVar);

  const { activeEstimateID, budgetID } = milestone;
  const estimateID = isEstimate ? activeEstimateID : budgetID;

  const estimateHandle = isEstimate ? t.titleCase(TermKey.ESTIMATE) : t.titleCase(TermKey.TARGET);
  const estimateArmature = useGridEstimateArmatureQuery(estimateID ?? undefined, costMode).data
    ?.estimate;
  const fields = estimateArmature?.fields;
  const categorizations = useMemoWrapper(computeCategorizations, fields);
  const value = useMemoWrapper(computeDraftEstimateTotal, milestoneCostReports, estimateType);
  const header = useMemo(
    () =>
      `${estimateHandle} (${
        checkCostModeIncludesMarkups(mapCostModeEnumToString(costMode))
          ? 'Including Markups'
          : 'Excluding Markups'
      })`,
    [estimateHandle, costMode]
  );

  const milestoneName = (milestone || {}).name || '';

  // FILTER INFO
  const {
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    filterManager,
    page,
    settings,
    setSetting,
  } = costParams;
  const { status, viewMode } = settings;
  const { clearFilters, filterQueryInput: viewFilter } = filterManager;
  const gridVariant = getGridVariantFromEstimateType(estimateType);

  const innerContentEdit = (
    <>
      {canViewMilestoneCosts && gridVariant && (
        <CostEstimate
          activeEstimateID={estimateID ?? undefined}
          canViewDetails={canViewDetails}
          clearFilters={clearFilters}
          variant={gridVariant} // only milestone estimates can be filtered
          viewFilter={viewFilter}
          projectID={projectId}
          refetchOuter={refetchReports}
          sendAnalytics={sendAnalytics}
          hasOwnerCostEstimate={!!estimateArmature?.ownerCostEstimate}
        />
      )}
      <EstimateTotal isEstimate={isEstimate} header={header} cost={{ value }} />
    </>
  );

  if (isMilestoneDetails)
    return (
      <div className="grow" data-cy="milestone-estimate">
        {innerContentEdit}
      </div>
    );

  const settingsComponent = (
    <CostReportSettingsPanel
      canViewProjectCategories={canViewProjectCategories}
      categorizations={categorizations}
      columnDescriptions={columnDescriptions}
      displayColumnDescriptions={displayColumnDescriptions}
      displayGroupBy={displayGroupBy}
      filterManager={filterManager}
      milestoneID={milestoneId}
      page={page}
      settings={settings}
      setSetting={setSetting}
      status={status}
      statusDisabled
      viewMode={viewMode}
      viewGroupByColumnsDisabled
    />
  );

  const title = (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <Breadcrumbs
            links={[
              {
                display: 'Milestones',
                destination: generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }),
                tooltip: 'Back to milestones list',
              },
              {
                display: milestoneName || 'Loading...',
                destination: generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                  projectId,
                  milestoneId,
                }),
                tooltip: 'Back to milestone details',
              },
              {
                display: estimateHandle,
                destination: '',
                tooltip: `${milestone.name} ${estimateHandle}`,
              },
            ]}
          />
        </div>
        <div className={classes.controlHeader}>
          <div className={classes.headerOptions}>
            {settingsComponent}
            <CTAIconButton
              color={DEFAULT}
              variant="outlined"
              onClick={() => {
                sendAnalytics({
                  type: isEstimate ? MilestoneGridEvents.m_eCTA : MilestoneGridEvents.m_bCTA,
                  eventProperties: { status: 'close' },
                });
                navigate(
                  generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                    projectId,
                    milestoneId,
                  })
                );
              }}
              buttonText={`Close ${estimateHandle}`}
              icon={<FullscreenExit />}
            />
          </div>
        </div>
      </div>
    </>
  );

  const cardHeader = <CardHeader title={title} />;

  const content =
    milestoneId === null ? (
      <Typography className={classes.empty}>Something went wrong. Please reload.</Typography>
    ) : (
      <Card square className={classes.card} elevation={0}>
        <div className={classes.background}>{cardHeader}</div>
        <Divider />
        {innerContentEdit}
      </Card>
    );

  return (
    <div className="grow" onDragOver={(e) => e?.preventDefault()}>
      {content}
    </div>
  );
};

export default withStyles(styles)(EstimateAccordion);
