import { useMutation } from '@apollo/client';

import { DELETE_BUCKET } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { DeleteBucketMutation, DeleteBucketMutationVariables } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';

import { deleteBucketMutation, getMilestoneQuery } from './queries';

function useDeleteMilestoneBucket() {
  const [deleteMilestoneBucketFunc, mutationResult] = useMutation<
    DeleteBucketMutation,
    DeleteBucketMutationVariables
  >(deleteBucketMutation);

  const sendAnalytics = useAnalyticsEventHook();

  const submitFunc = (projectId: UUID, milestoneId: UUID, bucketId: UUID) =>
    deleteMilestoneBucketFunc({
      variables: { projectID: projectId, bucket: bucketId },
      update: (dataProxy, { data }) => {
        if (!data?.deleteBucket) return;
        // update apollo cache
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        const { milestone }: any = dataProxy.readQuery({
          query: getMilestoneQuery,
          variables: { id: milestoneId },
        });
        const milestoneUpdated = {
          ...milestone,
          buckets: ((milestone || {}).buckets || []).filter(
            ({ id }: { id: UUID }) => id !== bucketId
          ),
        };
        dataProxy.writeQuery({
          query: getMilestoneQuery,
          variables: { id: milestoneId },
          data: { milestone: milestoneUpdated },
        });
      },
    }).then(() => {
      sendAnalytics(analyticsEvent(DELETE_BUCKET));
    });

  return [submitFunc, mutationResult];
}

export default useDeleteMilestoneBucket;
