import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProjectCompsSetInputQuery } from '../../generated/graphql';
import { copy } from '../../utilities/state';
import { isNonNullable } from '../../utilities/types';

import { useProjectCompsSetInputUpdateFunctions } from './ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

// this is similar to a helper in ProjectCompUtils, just typed differently...
// the key part in layering
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const filterNull = (input: any) => {
  const newInput = copy(input);
  Object.entries(input).forEach(([key, val]) => {
    if (val === null || val === undefined) {
      delete newInput[key];
    }
  });
  return newInput;
};

// Handle a fresh route navigation. We'll check if whoever navigated into us
// populated some projectIDs for us to init the Project Comp with.
export const useHandleIncomingLink = () => {
  const { addProjectCompInputs } = useProjectCompsSetInputUpdateFunctions();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // If we were just navigated to and have projectIDs in the navigation state...
    const { state } = location;
    if (state?.projectIDs && Array.isArray(state.projectIDs)) {
      // ...pull the IDs from the `state`...
      const projectIDs = state.projectIDs
        .map((id: unknown) => (typeof id === 'string' ? id : undefined))
        .filter(isNonNullable);

      // ...put them into our inputs...
      addProjectCompInputs(projectIDs);

      // ...and then clear out the state since we've handled it.
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate, addProjectCompInputs]);
};

export const useInitialSetSettings = (
  projectCompsSetInput?: ProjectCompsSetInputQuery['projectCompsSetInput']
) => {
  const { initializeProjectCompsSetInput } = useProjectCompsSetInputUpdateFunctions();

  useEffect(() => {
    if (projectCompsSetInput) {
      initializeProjectCompsSetInput({
        ...filterNull(projectCompsSetInput),
      });
    }
  }, [projectCompsSetInput, initializeProjectCompsSetInput]);
};
