import { useRef } from 'react';
import { AriaMenuProps, useMenu, useMenuItem, useMenuSection } from 'react-aria';
import { Node, TreeState, useTreeState } from 'react-stately';

export default function MenuLogicWrapper<T extends object>(props: AriaMenuProps<T>) {
  const state = useTreeState(props);

  const ref = useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul {...menuProps} className="outline-none" ref={ref}>
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <MenuSection key={item.key} section={item} state={state} />
        ) : (
          <MenuItem key={item.key} item={item} state={state} />
        )
      )}
    </ul>
  );
}

type MenuSectionProps<T> = {
  section: Node<T>;
  state: TreeState<T>;
};

function MenuSection<T extends object>({ section, state }: MenuSectionProps<T>) {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <li {...itemProps} className="border-b border-border-muted last:border-b-0">
      {section.rendered && <span {...headingProps}>{section.rendered}</span>}
      <ul {...groupProps}>
        {[...section.childNodes].map((node) => (
          <MenuItem key={node.key} item={node} state={state} />
        ))}
      </ul>
    </li>
  );
}

type MenuItemProps<T> = {
  item: Node<T>;
  state: TreeState<T>;
};

function MenuItem<T extends object>(props: MenuItemProps<T>) {
  const ref = useRef(null);
  const { menuItemProps, isDisabled } = useMenuItem({ key: props.item.key }, props.state, ref);

  return (
    <li
      {...menuItemProps}
      ref={ref}
      // We style based on this `group` in the child components.
      className={`group outline-none
        ${isDisabled ? 'cursor-not-allowed text-type-inactive' : 'cursor-pointer text-type-primary'}
      `}
    >
      {props.item.rendered}
    </li>
  );
}
