import { FC, useContext, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';

import { GridVariant, makeGridAnalytics } from '../../../../../actions/gridAnalytics';
import { gridSortDataVar } from '../../../../../api/apollo/reactiveVars';
import { refetchMilestoneQuantities } from '../../../../../api/refetchSets';
import { useDebounceFn } from '../../../../../hooks';
import useAnalyticsEventHook from '../../../../../hooks/useAnalyticsEventHook';
import { useEstimateQuery } from '../../../../../hooks/useEstimateQuery';
import {
  getEnabledCategorizationsIDsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../../hooks/useProjectCategorizationsQuery';
import { useRefetch } from '../../../../../hooks/useRefetch';
import { getGridType } from '../../../../../utilities/analytics';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../../utilities/url';
import JoinGridWrapper from '../../../../JoinGrid/JoinGridWrapper';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';

type MilestoneDetailsQuantitiesGridProps = {
  canEditMetrics: boolean;
  quantityEstimateID: UUID | undefined;
  quantityInfo: QuantityInfo;
};

const MilestoneDetailsQuantitiesGrid: FC<MilestoneDetailsQuantitiesGridProps> = ({
  canEditMetrics,
  quantityEstimateID,
  quantityInfo,
}) => {
  const projectID = getProjectIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();
  const { refetch } = useEstimateQuery(quantityEstimateID);
  const refetchQuantities = useRefetch(refetchMilestoneQuantities(milestoneID));
  // LOAD DATA FOR REFRESHING
  const { data } = useProjectCategorizationsQuery(projectID, true);
  const enabledCategorizationsIDs = getEnabledCategorizationsIDsForProjectFromQueryData(data);

  // GRID WRAPPER PROPS
  const sendAnalytics = useAnalyticsEventHook();
  const t = useContext(ProjectTermStore);
  const sortData = useReactiveVar(gridSortDataVar);
  const refetchCombined = () => {
    refetch();
    refetchQuantities();
  };

  const refetchDebounced = useDebounceFn(refetchCombined, 1000);
  const collapseSizeRef = useRef(null);
  const analytics = makeGridAnalytics(false, sendAnalytics, GridVariant.QUANTITY);
  const gridType = getGridType(false);

  // PERMISSIONS
  const canEditLines = canEditMetrics;
  const canEditColumns = canEditMetrics;

  return (
    <div>
      {!!quantityEstimateID && (
        <>
          <JoinGridWrapper
            t={t}
            gridType={gridType}
            enabledCategorizationsIDs={enabledCategorizationsIDs}
            estimateID={quantityEstimateID}
            sortData={sortData}
            refetchOuter={refetchCombined}
            projectID={projectID}
            updateCostReports={() => {
              refetchDebounced();
            }}
            variant={GridVariant.QUANTITY}
            collapseSizeRef={collapseSizeRef}
            permissions={{
              viewEstimate: true, // cannot expand categorized metric if no view access
              editLines: canEditLines,
              editColumns: canEditColumns,
              viewMarkups: false,
              summaryMarkups: false,
              editMarkups: false,
              viewEstimateCostSubtotals: false,
            }}
            quantity={quantityInfo}
            analytics={analytics}
          />
          <div ref={collapseSizeRef} />
        </>
      )}
    </div>
  );
};

export default MilestoneDetailsQuantitiesGrid;
