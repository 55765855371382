import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import {
  createItemContingencyDraw,
  removeItemContingencyDraw,
  setContingencyExpanded,
  updateItemContingencyDraw,
} from '../../../analytics/analyticsEventProperties';
import { MARKUP_CONVERSION_FACTOR, NULL_ID, S1 } from '../../../constants';
import { Scale } from '../../../enums';
import {
  GetItemContingenciesQuery,
  ItemDrawInfo,
  MarkupType,
  Status,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  getContingencyTooltip,
  shouldItemAllowDraws,
} from '../../frame/ItemSidebar/ItemSidebarUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import CostImpact from '../../shared-widgets/CostImpact';
import { useContingencyDrawEvent } from '../hooks/useContingencyDrawEvent';
import { useCreateContingencyDraw } from '../hooks/useCreateContingencyDraw';
import { useRemoveContingency } from '../hooks/useRemoveMarkups';
import { useUpdateMarkups } from '../hooks/useUpdateMarkups';

import ContingencyDraw from './ContingencyDraw';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemsCollapseStyles, { SCROLL_MARGIN } from './ItemsCollapseStyles';

type Props = {
  classes: Classes<typeof ItemsCollapseStyles>;
  contingencyDrawTotal?: Cost;
  itemContingenciesData: GetItemContingenciesQuery['getItemContingencies'];
  refetchItemContingenciesData: () => void;
  estimateCost: Cost;
  estimateID: UUID;
  fullWidth?: boolean;
  hasGrayBackground?: boolean;
  itemID: UUID;
  isItemSidebar?: boolean;
  itemStatus: Status;
  projectID: UUID;
  onItemMutated: () => void;
};

const ContingencyContent: FC<Props> = ({
  classes,
  contingencyDrawTotal,
  itemContingenciesData,
  refetchItemContingenciesData,
  estimateCost,
  estimateID,
  fullWidth = false,
  hasGrayBackground = false,
  itemID,
  isItemSidebar,
  itemStatus,
  projectID,
  onItemMutated,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { draws, availableMilestoneContingencies: available } = itemContingenciesData;
  const hasMultipleDraws = draws.length > 1;

  const createContingency = useCreateContingencyDraw();
  const contingencyDrawEvent = useContingencyDrawEvent();
  const updateMarkups = useUpdateMarkups();
  const removeContingency = useRemoveContingency();

  // ANALYTICS
  const sendAnalytics = useAnalyticsEventHook();

  const refetch = () => {
    onItemMutated();
    refetchItemContingenciesData();
  };

  // Hooks
  const updateContingency = (input: MarkupInput) => {
    const draw = draws.find((d) => d.id === input?.id);
    const drawValue = draw?.draw ?? 0;
    // if we are removing the DrawFromID
    updateMarkups(projectID, estimateID, [input], undefined, undefined, () => {
      sendAnalytics(
        updateItemContingencyDraw(
          input.id,
          input.value ?? 0,
          input.type === MarkupType.PERCENT,
          input?.drawFromID ?? undefined
        )
      );
      contingencyDrawEvent(
        projectID,
        estimateID,
        itemID,
        input?.drawFromID ?? '',
        input.value ?? 0,
        false,
        input.type ?? MarkupType.PERCENT,
        drawValue,
        refetch
      );
    });
  };

  const onAdd = () => {
    createContingency(projectID, estimateID, itemID, undefined, refetch, () =>
      sendAnalytics(createItemContingencyDraw(itemID))
    );
  };

  const onSetFullAmount = ({ id, isDrawingFullAmount, drawFromID }: ItemDrawInfo) => {
    // if we are not currently drawing the full amount
    // then update the draw to be a 100% markup
    if (!isDrawingFullAmount) {
      updateContingency({
        id,
        type: MarkupType.PERCENT,
        drawFromID,
        value: MARKUP_CONVERSION_FACTOR * -100_000,
        percentScale: Scale.PERCENT_9,
        references: [S1],
      });
      // if we are unchecking full draw, then convert this to a
      // fixed type markup with a cost equal to the negative full value of the item
    } else {
      updateContingency({ id, type: MarkupType.FIXED, value: 0, drawFromID, references: [] });
    }
  };

  // if there are no draws, we still want to include an empty row
  const rows = !draws.length ? (
    <ContingencyDraw
      availableContingencies={available}
      estimateCost={estimateCost}
      fullWidth={fullWidth}
      key="default"
      onAdd={onAdd}
      onSelect={(contingencyID: string) => {
        if (contingencyID === NULL_ID) return;

        const draw = draws.find((d) => d.id === contingencyID);
        const drawValue = draw?.draw ?? 0;
        // Note:
        // It's important that we dont' refetch the item query until after we've inserted
        // the eventData event with `contingencyDrawEvent`.  If we refetch before that
        // then the cost will be incorrect, because we don't update the milestone_item_map
        // table with the latest event_id (and therefore it's associated costdata_id)
        // until we insert an event into event_data (which triggers the process_event_insert trigger)
        createContingency(projectID, estimateID, itemID, contingencyID, undefined, () => {
          sendAnalytics(createItemContingencyDraw(itemID, contingencyID));
          contingencyDrawEvent(
            projectID,
            estimateID,
            itemID,
            contingencyID,
            0,
            true,
            MarkupType.PERCENT,
            drawValue,
            refetch
          );
        });
      }}
      hasMultipleDraws={false}
      onSetValue={() => {}}
      onSetFullAmount={() => {}}
    />
  ) : (
    draws.map((draw, i) => {
      const { id } = draw;
      const isLast = i === draws.length - 1;
      return (
        <ContingencyDraw
          estimateCost={estimateCost}
          availableContingencies={available}
          draw={draw}
          fullWidth={fullWidth}
          key={id}
          hasMultipleDraws={hasMultipleDraws}
          onAdd={isLast ? onAdd : undefined}
          onSelect={(contingencyID) => {
            // if the user is selecting "no draw" then also clear out the value
            const hasValue = draw.draw.toString() !== '0';
            let value = contingencyID === NULL_ID ? 0 : undefined;
            let type =
              contingencyID === NULL_ID && !hasMultipleDraws ? MarkupType.PERCENT : undefined;
            // if the user is setting a contingency to draw from, and
            // has not set a lump sum cost, then make this a full draw
            const isSettingDrawFrom = !draw.drawFromID && contingencyID !== NULL_ID;
            const isLumpSumDraw = hasValue;
            if (isSettingDrawFrom && !isLumpSumDraw && !hasMultipleDraws) {
              type = MarkupType.PERCENT;
              value = MARKUP_CONVERSION_FACTOR * -100_000;
            }
            updateContingency({
              id,
              drawFromID: contingencyID,
              value,
              type,
              references: type === MarkupType.PERCENT ? [S1] : undefined,
            });
          }}
          onSetValue={(contingencyID, value) => {
            updateContingency({
              id,
              type: MarkupType.FIXED,
              value,
              drawFromID: contingencyID,
            });
          }}
          onSetFullAmount={() => onSetFullAmount(draw)}
          onRemove={
            !isLast
              ? () => {
                  removeContingency(projectID, estimateID, [id], undefined, refetch, () =>
                    sendAnalytics(removeItemContingencyDraw(itemID))
                  );
                }
              : undefined
          }
        />
      );
    })
  );

  const { shouldAllowDraws, canAddDraws } = shouldItemAllowDraws(
    draws,
    available,
    estimateCost,
    itemStatus
  );
  const tooltipCopy = getContingencyTooltip(shouldAllowDraws, itemStatus);

  if (isItemSidebar) {
    return <div style={{ overflow: 'hidden', paddingLeft: '8px' }}>{rows}</div>;
  }

  return (
    <>
      <ItemDetailsCollapse
        analyticsEvent={setContingencyExpanded(!isExpanded)}
        canExpand={canAddDraws}
        hasGrayBackground={hasGrayBackground}
        dataCy="contingency"
        headerContent={
          <>
            <Typography
              className={`${classes.collapseTitle} ${!shouldAllowDraws ? classes.disabled : ''}`}
              data-cy="itemsCollapse-contingencies-title"
            >
              Contingency &amp; Allowance Draws
            </Typography>
            <NormalTooltip title={tooltipCopy}>
              <Help className={classes.icon} />
            </NormalTooltip>
            <div className={classes.costContainer}>
              {contingencyDrawTotal ? (
                <CostImpact value={contingencyDrawTotal} />
              ) : (
                <Typography>N/A</Typography>
              )}
            </div>
          </>
        }
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        style={{ scrollMarginTop: SCROLL_MARGIN }}
      >
        <div style={{ overflow: 'hidden' }}>{rows}</div>
      </ItemDetailsCollapse>
    </>
  );
};

export default withStyles(ItemsCollapseStyles)(ContingencyContent);
