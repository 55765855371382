import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { VERTICAL_TEXT_HEIGHT } from '../../frame/FrameTitleBar/FrameTitlePreviewBarStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    compactLocation: {
      top: '4px !important',
    },
    container: { display: 'flex', flexDirection: 'column', width: theme.itemFieldWidth },
    highlight: {
      backgroundColor: theme.palette.hoverBackground,
    },
    menuHeadItem: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      height: 40,
      margin: `0px 1px 0px 1px`,
      padding: `0px ${theme.spacing.selectIconRight}px 0px ${theme.spacing.selectPaddingLeft}px`,
    },
    menuItem: {
      padding: `${theme.spacing.generic[2]}px ${theme.spacing.selectIconRight}px ${theme.spacing.generic[2]}px ${theme.spacing.selectPaddingLeft}px`,
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
    },
    noScroll: {
      display: 'flex',
      overflowY: 'hidden',
    },
    scroll: {
      maxHeight: 280,
      overflowY: 'auto',
    },
    search: {
      margin: 0,
      width: '100%',
    },
    text: {
      height: VERTICAL_TEXT_HEIGHT,
      flexGrow: 1,
    },
  });
