import { FC, useRef } from 'react';
import * as React from 'react';
import { isEmpty, trim } from 'validator';

import { TextField, Typography } from '@material-ui/core';

import { GridVariant } from '../../../actions/gridAnalytics';
import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { getGridType } from '../../../utilities/analytics';
// eslint-disable-next-line import/no-cycle
import CategorizationWrapper from '../../JoinGrid/JoinGridCategorizationWrapper';
import { CategorizationGridPermissions, GridController } from '../../JoinGrid/types';

import ErrorMessage from './ErrorMessage';
import { CreateCategorizationInput } from './types';

type Classes = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  categoryPanel: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  tablesContainer: any;
};

type CategoryPanelProps = {
  classes: Classes;
  categorization?: Pick<CategorizationMetadata, 'id' | 'name' | 'description'>;
  categorizationInput?: CreateCategorizationInput;
  errorMessage: string;
  setErrorMessage: (s: string) => void;
  setGridController?: React.Dispatch<React.SetStateAction<GridController | undefined>>;
  setCategorizationInput?: (val: string, inputType: 'name' | 'description') => void;
  projectId?: UUID;
  permissions: CategorizationGridPermissions;
  updateCategorization: (
    input: Partial<Pick<CategorizationMetadata, 'name' | 'description'>>
  ) => void;
  onClickReplace?: () => void;
  viewOnly?: boolean;
  disableOnReplace?: boolean;
};

const JoinGridCategoryPanel: FC<CategoryPanelProps> = ({
  classes,
  categorization,
  categorizationInput,
  errorMessage,
  setErrorMessage,
  setGridController,
  setCategorizationInput,
  projectId,
  permissions,
  updateCategorization,
  onClickReplace,
  viewOnly,
  disableOnReplace,
}) => {
  const dialogSizeRef = useRef(null);
  const sendAnalytics = useSendAnalyticsEventHook();

  return (
    <div ref={dialogSizeRef}>
      <div>
        <Typography variant="caption">Name*</Typography>
        <TextField
          data-cy="input-categorization-name"
          fullWidth
          name="name"
          placeholder="Enter a name..."
          InputProps={{ disableUnderline: true, autoComplete: 'off' }}
          defaultValue={categorization?.name || categorizationInput?.name}
          onChange={(evt) => {
            setCategorizationInput?.(evt.target.value, 'name');
            if (isEmpty(evt.target.value, { ignore_whitespace: true })) {
              setErrorMessage('Name cannot be empty');
            } else {
              setErrorMessage('');
            }
          }}
          onBlur={(evt) => {
            const name = evt.target.value;
            if (
              !isEmpty(name, { ignore_whitespace: true }) &&
              categorization?.id &&
              categorization?.name !== name
            ) {
              updateCategorization({ name: trim(name) });
            }
          }}
          onKeyPress={(evt) => {
            if (evt.key === 'Enter') {
              if (evt.target instanceof HTMLElement) {
                evt.target.blur();
              }
              evt.stopPropagation();
              evt.preventDefault();
            }
          }}
          disabled={viewOnly || disableOnReplace}
        />
      </div>
      <div className={classes.categoryPanel}>
        <Typography variant="caption">Description</Typography>
        <TextField
          data-cy="input-categorization-description"
          fullWidth
          name="description"
          placeholder={viewOnly ? '' : 'Enter a description...'}
          InputProps={{ disableUnderline: true, autoComplete: 'off' }}
          defaultValue={categorization?.description || categorizationInput?.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCategorizationInput?.(event.target.value, 'description');
          }}
          onBlur={(evt) => {
            const trimmed = evt.target.value.trim();
            if (trimmed !== categorization?.description && categorization?.id) {
              updateCategorization({ description: trimmed });
              if (trimmed) {
                setCategorizationInput?.(trimmed, 'description');
              }
            }
          }}
          disabled={viewOnly || disableOnReplace}
        />
      </div>
      <ErrorMessage message={errorMessage} />
      {!disableOnReplace && (
        <div className={classes.categoryPanel}>
          <div className={classes.tablesContainer}>
            {/* All actual updating of categorizations happens here. */}
            <CategorizationWrapper
              gridType={getGridType(false)}
              dialogSizeRef={dialogSizeRef}
              projectID={projectId}
              categorizationID={categorization?.id}
              permissions={permissions}
              onClickAddRow={() => {
                sendAnalytics(
                  categorizationEvent(
                    categorization
                      ? CategorizationEvent.EDIT_SL_ADD_ROW
                      : CategorizationEvent.CREATE_NEW_SL_ADD_ROW
                  )
                );
              }}
              onClickDeleteRow={() => {
                sendAnalytics(
                  categorizationEvent(
                    categorization
                      ? CategorizationEvent.EDIT_SL_DELETE_ROW
                      : CategorizationEvent.CREATE_NEW_SL_DELETE_ROW
                  )
                );
              }}
              onClickReplace={onClickReplace}
              variant={GridVariant.CATEGORIZATION}
              setGridController={setGridController}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinGridCategoryPanel;
