import { FC } from 'react';

import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { CreateCategorizationMutation } from '../../../generated/graphql';

import AddCategoriesDialogStyles from './AddCategoriesDialogStyles';
// eslint-disable-next-line import/no-cycle
import JoinGridAddCategoriesDialog from './JoinGridAddCategoriesDialog';
import { CreateCategorizationInput } from './types';

type Props = {
  buttonText: string;
  classes: Classes<typeof AddCategoriesDialogStyles>;
  categorization?: CategorizationMetadata;
  projectId?: UUID;
  categorizationInput?: CreateCategorizationInput;
  onClose: () => void;
  onClickReplace?: () => void;
  onClickBack?: () => void;
  onCreateSuccess?: (c: CreateCategorizationMutation['createCategorization']) => void;
  setCategorizationInput?: (c: CreateCategorizationInput) => void;
  title: string;
  viewOnly?: boolean;
  contentOnly?: boolean;
  onBackButton?: () => void;
  onBackButtonMultilevel?: () => void;
};

const AddCategoriesDialog: FC<Props> = ({
  buttonText,
  classes,
  categorization,
  projectId,
  categorizationInput,
  onClose,
  onClickReplace,
  onClickBack,
  onCreateSuccess,
  setCategorizationInput,
  title,
  viewOnly,
  contentOnly,
  onBackButton,
  onBackButtonMultilevel,
}) => {
  return (
    <Dialog disableBackdropClick onClose={onClose} open>
      <JoinGridAddCategoriesDialog
        buttonText={buttonText}
        classes={classes}
        projectId={projectId}
        categorization={categorization}
        categorizationInput={categorizationInput}
        onClose={onClose}
        onClickReplace={onClickReplace}
        onClickBack={onClickBack}
        onCreateSuccess={onCreateSuccess}
        setCategorizationInput={setCategorizationInput}
        title={title}
        viewOnly={viewOnly}
        contentOnly={contentOnly}
        onBackButton={onBackButton}
        onBackButtonMultilevel={onBackButtonMultilevel}
      />
    </Dialog>
  );
};

export default withStyles(AddCategoriesDialogStyles)(AddCategoriesDialog);
