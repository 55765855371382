import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    root: {
      color: theme.palette.something,
    },
    header: {
      paddingBottom: theme.spacing.unit,
    },
    inputHeight: {
      minHeight: 18,
      lineHeight: 'normal',
    },
    setting: {
      paddingBottom: theme.spacing.unit * 6,
    },
    tipHeader: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      display: 'inline',
    },
    link: {
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    tipText: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamily,
      display: 'inline',
    },
  });
