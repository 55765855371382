import { companyUserQuery } from '../components/login/queries';
import { CompanyUserQuery, CompanyUserQueryVariables } from '../generated/graphql';

import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useCompanyUserQuery = (
  mountPolicy?: MountPolicy,
  onCompleted?: (data: CompanyUserQuery) => void
) =>
  useQuery<CompanyUserQuery, CompanyUserQueryVariables>(
    companyUserQuery,
    {
      onCompleted,
    },
    mountPolicy
  );
