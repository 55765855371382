import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { JoinRoutes } from '../../../../../api/gqlEnums';
import { ImageDimension } from '../../../../../generated/graphql';
import {
  HOME_PROJECT_LOCATION,
  HOME_PROJECT_NAME,
  HOME_PROJECT_STATUS,
  HOME_PROJECT_TYPE,
} from '../../../../../tagConstants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import SearchText from '../../../../Search/SearchText/SearchText';
import Thumbnail from '../../../../shared-widgets/Thumbnail/Thumbnail';
import { Chip, Chips } from '../../../common/Chips';
import ProjectLink from '../../../common/ProjectLink';

import styles from './ProjectCellStyles';

type Props = {
  classes: Classes<typeof styles>;
  project: ForecastingProject;
  searchText: string;
};

export default withStyles(styles)(({ classes, project, searchText }: Props) => (
  <div className={classes.container}>
    <Thumbnail dimension={ImageDimension._92} padding={0} size={72} thumbnail={project.thumbnail} />
    <div className={classes.info}>
      <ProjectLink
        className={project.hasAccess ? classes.link : ''}
        hasAccess={project.hasAccess}
        to={generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id })}
        projectName={project.name}
      >
        <div
          className={classes.title}
          data-cy={`${HOME_PROJECT_NAME}-${project.name}`}
          data-project-id={project.id}
        >
          <SearchText searchTerm={searchText} text={project.name} />
        </div>
      </ProjectLink>
      <Chips>
        <div data-cy={`${HOME_PROJECT_LOCATION}-${project.name}-${project.location}`}>
          <Chip iconComponent={LocationOnOutlined}>
            <SearchText searchTerm={searchText} text={project.location} />
          </Chip>
        </div>
        {project.type && (
          <div data-cy={`${HOME_PROJECT_TYPE}-${project.name}-${project.type}`}>
            <Chip iconComponent={BusinessOutlined}>
              <SearchText searchTerm={searchText} text={project.type} />
            </Chip>
          </div>
        )}
        {project.status && (
          <div data-cy={`${HOME_PROJECT_STATUS}-${project.name}-${project.status}`}>
            <Chip>
              <SearchText searchTerm={searchText} text={project.status} />
            </Chip>
          </div>
        )}
      </Chips>
    </div>
  </div>
));
