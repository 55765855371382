import * as React from 'react';

import { OrganizationType } from '../../api/gqlEnums';
import { getTeamGUID } from '../assets/utils';

const defaultOrganization = { name: '', picture: '', id: '' };

// the order of this list determines the order of the organizations in the ui
export const organizationLabels = Object.values(OrganizationType) as OrganizationType[];

// update a project organization
export const getUpdatedOrganizationFromEvent = (
  type: OrganizationType,
  organization: Organization,
  project: ProjectProps | Partial<ProjectProps>,
  event: React.ChangeEvent<HTMLInputElement>
): ProjectProps | Partial<ProjectProps> | null => {
  let update = {} as Organization;
  switch (type) {
    case OrganizationType.OWNER:
      update = { ...organization, name: event.target.value };
      return {
        ...project,
        team: { ...project.team, owner: update },
      };
    case OrganizationType.GC:
      update = { ...organization, name: event.target.value };
      return {
        ...project,
        team: { ...project.team, generalContractor: update },
      };
    case OrganizationType.DESIGNTEAM:
      update = { ...organization, name: event.target.value };
      return {
        ...project,
        team: { ...project.team, designTeam: update },
      };
    default:
      return null;
  }
};

// given an organization return the projectInput to update the project
export const projectInputUpdate = (
  type: OrganizationType,
  organization: Organization,
  projectID: UUID,
  assetID?: UUID
): ProjectInput | null => {
  const teamUpdate = {
    name: organization.name,
    picture: assetID || organization.picture,
  } as OrganizationInput;
  switch (type) {
    case OrganizationType.OWNER:
      return {
        id: projectID,
        team: { owner: teamUpdate },
      };
    case OrganizationType.GC:
      return { id: projectID, team: { generalContractor: teamUpdate } };
    case OrganizationType.DESIGNTEAM:
      return { id: projectID, team: { designTeam: teamUpdate } };
    default:
      return null;
  }
};

// update the picture for a teammate
export const teamPictureUpdate = (
  type: OrganizationType,
  organization: Organization,
  project: ProjectProps | Partial<ProjectProps>,
  assetID: UUID
): ProjectProps | Partial<ProjectProps> | null => {
  const teamUpdate = {
    ...organization,
    picture: assetID,
  } as Organization;
  switch (type) {
    case OrganizationType.OWNER:
      return {
        ...project,
        team: { ...project.team, owner: teamUpdate },
      };
    case OrganizationType.GC:
      return {
        ...project,
        team: { ...project.team, generalContractor: teamUpdate },
      };
    case OrganizationType.DESIGNTEAM:
      return {
        ...project,
        team: { ...project.team, designTeam: teamUpdate },
      };
    default:
      return null;
  }
};

// from the project get the asset ids for each organization in the team
export const getTeamAssetID = (
  team: Team | undefined,
  canViewProjectDetails: boolean,
  canViewItemAttachments: boolean
): UUID[] => {
  if (!canViewItemAttachments) return [];
  const teamGUIDs = getTeamGUID(team);
  const assetIDs: string[] = [];
  if (!canViewProjectDetails) return assetIDs;
  if (teamGUIDs.ownerGUID) assetIDs.push(teamGUIDs.ownerGUID);
  if (teamGUIDs.generalContractorGUID) assetIDs.push(teamGUIDs.generalContractorGUID);
  if (teamGUIDs.designTeamGUID) assetIDs.push(teamGUIDs.designTeamGUID);
  return assetIDs;
};

export const getAssetForTeam = (
  team: Team | undefined,
  assets: Pick<Asset, 'id' | 'blobUrl'>[]
): string[] => {
  if (!team) return ['', '', ''];

  const ownerAsset = assets.find((a) => team.owner && a.id === team.owner.picture);
  const gcAsset = assets.find(
    (a) => team.generalContractor && a.id === team.generalContractor.picture
  );
  const designTeamAsset = assets.find((a) => team.designTeam && a.id === team.designTeam?.picture);

  // note: the order is here is important.  We'll use the same order
  // as the organization labels
  const sortedAssets = organizationLabels.map((o) => {
    switch (o) {
      case OrganizationType.OWNER:
        return ownerAsset && ownerAsset.blobUrl ? ownerAsset.blobUrl : '';
      case OrganizationType.GC:
        return gcAsset && gcAsset.blobUrl ? gcAsset.blobUrl : '';
      case OrganizationType.DESIGNTEAM:
        return designTeamAsset && designTeamAsset.blobUrl ? designTeamAsset.blobUrl : '';
      default:
        return '';
    }
  });
  return sortedAssets;
};

export const getOrderedTeam = (team: Team | undefined): Organization[] => {
  if (!team) return [];

  // note: the order is here is important.  We'll use the same order
  // as the organization labels
  const orderedTeam = organizationLabels.map((o) => {
    switch (o) {
      case OrganizationType.OWNER:
        return team.owner || defaultOrganization;
      case OrganizationType.GC:
        return team.generalContractor || defaultOrganization;
      case OrganizationType.DESIGNTEAM:
        return team.designTeam || defaultOrganization;
      default:
        return defaultOrganization;
    }
  });
  return orderedTeam;
};

interface DataTransfer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  dataTransfer: any;
}

// get the uploaded file from an event
// we should only be uploading a single file
export const getFile = (
  event: React.DragEvent | React.ChangeEvent<HTMLInputElement>
): File | null => {
  const { target } = event as React.ChangeEvent<HTMLInputElement>;
  const { dataTransfer } = event as Required<DataTransfer>;

  if (dataTransfer) {
    const { files } = dataTransfer;
    if (files && files.length === 1) return files[0];
  } else if (target) {
    const { files } = target;
    if (files && files.length === 1) return files[0];
  }
  return null;
};
