import { createStyles } from '@material-ui/core';

import { FILTER_SELECT_MIN_WIDTH } from '../../FilterPanel/FilterPanelStyles';

const panelPadding = '12px';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    body: {
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      padding: `4px ${panelPadding} ${panelPadding} ${panelPadding}`,
    },
    containerInner: {
      borderRadius: theme.border.radius,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      maxHeight: theme.portal.maxHeight,
      overflowY: 'scroll',
      width: '100%',
    },
    containerOuter: {
      paddingBottom: 350,
      position: 'absolute',
      width: '100%',
      zIndex: 99,
      minWidth: FILTER_SELECT_MIN_WIDTH,
    },
    label: theme.typography.label,
  });
