import { Search } from '@material-ui/icons';

import { ALL_COLLABORATORS } from '../../../constants';
import { Select, TextInput } from '../../scales';

export type Props = {
  collaboratorsTotal: number;
  collaboratorsShown: number;
  companies: string[];
  roles: string[];
  search: string;
  setSearch: (search: string) => void;
  onCompanyChange: (company: string) => void;
  onRoleChange: (role: string) => void;
};

export default function UsersFilter(props: Props) {
  const {
    collaboratorsTotal,
    collaboratorsShown,
    companies,
    roles,
    search,
    setSearch,
    onCompanyChange,
    onRoleChange,
  } = props;

  const defaultRoleEntry = { id: ALL_COLLABORATORS, label: 'All Roles' };
  const roleEntries = [defaultRoleEntry, ...roles.map((role) => ({ id: role, label: role }))];
  const defaultCompanyEntry = { id: ALL_COLLABORATORS, label: 'All Companies' };
  const companyEntries = [
    defaultCompanyEntry,
    ...companies.map((company) => ({ id: company, label: company })),
  ];

  const filterInfo = (
    <div className="type-paragraph flex items-center whitespace-nowrap text-type-primary">{`${collaboratorsShown} of ${collaboratorsTotal} teammates shown`}</div>
  );

  return (
    <div className="flex flex-row gap-2">
      <div className="w-[26rem]">
        <Select
          aria-label="Role"
          entries={roleEntries}
          defaultValue={defaultRoleEntry.id}
          onChange={onRoleChange}
        />
      </div>
      <div className="w-[40rem]">
        <Select
          aria-label="Company"
          entries={companyEntries}
          defaultValue={defaultCompanyEntry.id}
          onChange={onCompanyChange}
        />
      </div>
      <div className="w-full">
        <TextInput
          aria-label="search users"
          onChange={setSearch}
          placeholder="Find people to share with"
          startAdornment={<Search />}
          value={search}
        />
      </div>
      {filterInfo}
    </div>
  );
}
