import { Close } from '@material-ui/icons';

import { CheckboxType } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import {
  LUMP_SUM_DISPLAY,
  MARKUP_APPLIED_DISPLAY,
  MARKUP_NOT_APPLIED_DISPLAY,
  PERCENT_DISPLAY,
} from '../../../constants';
import { Scale } from '../../../enums';
import { MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import { getMarkupDisplayTypeString, isItemMarkupGrid } from '../editors/utils';
import GridRowCheckbox from '../JoinGridRowCheckbox';
import { GridController, GridType } from '../types';
import {
  getShouldExcludeFromAllocationFromCell,
  isCategoryCell,
  isDisplayTypeCell,
  isInheritedMarkupCheckbox,
  isMarkupDisplayType,
  isMarkupReference,
  isMarkupType,
  isMarkupValue,
  isRegularCell,
  isRegularOrMarkupCell,
  isSourceCell,
} from '../utilities/cell';

import CategoryRenderer from './Category';
import { CostRenderer, NumberRenderer } from './Number';
import PercentRenderer from './Percent';
import { StringRenderer } from './Plain';
import ReferenceRenderer from './Reference';
import DropdownRenderer from './Select';
import SourceRenderer from './Source';

export const renderCell = (
  grid: GridController,
  column: { type: string; placeholder?: string },
  row: number,
  cell: GridCellValue,
  error: string,
  select: () => void,
  readOnly: boolean,
  dataCy?: string
) => {
  const columnType = column.type;
  const isIncorporatedItemMarkup = grid.type === GridType.INCORPORATED_ITEM_MARKUP_GRID;
  const isIncorporatedItemDraw = grid.type === GridType.INCORPORATED_ITEM_DRAWS_GRID;
  const cellValue = grid.getCellValue(row, 0); // get value from cell in first column to see if it is markup display type
  const isIncorporatedItemMarkupDisplayTypeCell =
    isIncorporatedItemMarkup && isMarkupDisplayType(cellValue);

  if (columnType === FieldType.CATEGORY && isCategoryCell(cell)) {
    return <CategoryRenderer readOnly={readOnly} value={cell} error={error} onSelect={select} />;
  }
  if (columnType === FieldType.CURRENCY && isRegularCell(cell)) {
    return <CostRenderer value={cell} error={error} />;
  }
  if (columnType === FieldType.CURRENCY_9 && isRegularCell(cell)) {
    return <CostRenderer value={cell} error={error} scale={Scale.CURRENCY_9} />;
  }
  if (
    (columnType === FieldType.NUMBER || columnType === FieldType.DECIMAL) &&
    isRegularOrMarkupCell(cell)
  ) {
    return <NumberRenderer value={cell} error={error} />;
  }

  if (columnType === FieldType.SELECT && isMarkupType(cell)) {
    const typeCell = {
      string: cell.type === MarkupType.FIXED ? LUMP_SUM_DISPLAY : PERCENT_DISPLAY,
      disabled: cell.disabled,
      formula: '',
    };
    return (
      <DropdownRenderer
        onSelect={select}
        value={typeCell.string}
        disabled={typeCell.disabled || false}
        readOnly={readOnly || isIncorporatedItemDraw}
      />
    );
  }

  if (columnType === FieldType.MARKUP_VALUE && isMarkupValue(cell)) {
    const { type, number, disabled, scale } = cell;
    if (type === MarkupType.FIXED) {
      return (
        <CostRenderer
          value={{ string: String(number), formula: '', formulaDisplay: [] }}
          error={error}
        />
      );
    }
    return <PercentRenderer error={error} value={number} disabled={disabled} scale={scale} />;
  }

  if (columnType === FieldType.REFERENCE && isMarkupReference(cell)) {
    return (
      <ReferenceRenderer
        onSelect={select}
        value={cell}
        readOnly={readOnly}
        error={error}
        dataCy={dataCy}
      />
    );
  }

  if (columnType === FieldType.INHERITED_MARKUP_CHECKBOX && isInheritedMarkupCheckbox(cell)) {
    const { checked } = cell;
    const checkedCell = {
      string:
        checked === CheckboxType.CHECKED ? MARKUP_APPLIED_DISPLAY : MARKUP_NOT_APPLIED_DISPLAY,
      disabled: false,
      formula: '',
    };
    return (
      <DropdownRenderer
        onSelect={select}
        value={checkedCell.string}
        disabled={checkedCell.disabled || false}
        readOnly={false}
      />
    );
  }

  if (columnType === FieldType.DELETE) {
    return !readOnly ? (
      <button className="join-grid-delete-button column-cell-content" type="button">
        <Close data-cy="deleteLineButton" className="join-grid-delete-icon" />
      </button>
    ) : null;
  }

  if (columnType === FieldType.ALLOCATE) {
    return (
      <GridRowCheckbox
        position="center"
        checked={!getShouldExcludeFromAllocationFromCell(cell)}
        onClick={() => {
          if (!(isIncorporatedItemMarkupDisplayTypeCell || readOnly || isIncorporatedItemDraw))
            grid.toggleAllocatedMarkupLine(row);
        }}
        disabled={readOnly || isIncorporatedItemMarkupDisplayTypeCell || isIncorporatedItemDraw}
        showHoverBackground={false}
        cy="button-allocateCheckBox"
      />
    );
  }

  if (columnType === FieldType.SOURCE && isSourceCell(cell)) {
    return <SourceRenderer value={cell} />;
  }

  if (columnType === FieldType.MARKUP_DISPLAY_TYPE && isDisplayTypeCell(cell)) {
    const displayType = cell.displayType || MarkupDisplayType.MARKUP;
    const isItem =
      isItemMarkupGrid(grid.variant, grid.type) ||
      grid.type === GridType.INCORPORATED_ITEM_MARKUP_GRID;
    return (
      <DropdownRenderer
        onSelect={select}
        value={getMarkupDisplayTypeString(displayType, isItem)}
        disabled={cell.disabled || false}
        readOnly={
          grid.type === GridType.INHERITED_GRID ||
          isIncorporatedItemMarkupDisplayTypeCell ||
          isIncorporatedItemDraw
        }
      />
    );
  }

  const regularCell: RegularCell = isRegularCell(cell)
    ? cell
    : { string: '', formula: '', formulaDisplay: [] }; // we shouldn't ever have to use this default value
  return <StringRenderer placeholder={column.placeholder} value={regularCell} error={error} />;
};
