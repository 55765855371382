import { FC } from 'react';

import { FieldType } from '../../api/gqlEnumsBe';
import {
  CATEGORIZATIONS,
  CATEGORY_CELL,
  QTY,
  QUANTITY,
  SOURCE,
  TOTAL,
  UNIT,
  U_M,
} from '../../constants';
import { EstimateTotalType } from '../../generated/graphql';
import { removeYear } from '../../utilities/string';
import { defaultQuantity } from '../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesUtils';
// eslint-disable-next-line import/no-cycle
import EstimateHeader from '../table/estimate/EstimateHeader';

import {
  COLUMN_VERTICAL_BORDER,
  ROW_HEADER_CLASS,
  columnGroupColors,
} from './style/styleConstants';
import { Column, ColumnSettings, GridController, GridType, stringAsFieldGroup } from './types';
import { mouseDown } from './utilities/resize';

type HeaderCellsProps = {
  addColumn: (index: number) => void;
  grid: GridController;
  hasGroups?: boolean;
  setEditing: (editing: boolean) => void;
  scrollToCell: (error: number, col: number) => void;
  topOffset: number;
};

type ColumnData = {
  column: Column;
  colSpan?: number;
  group?: string;
  index?: number;
  width?: number;
};

const HeaderCells: FC<HeaderCellsProps> = ({
  addColumn,
  grid,
  hasGroups = false,
  setEditing,
  scrollToCell,
  topOffset,
}) => {
  const {
    closeErrorHeaders,
    columnsReadOnly,
    colWidths,
    data: { columns, lines },
    estimateID,
    moveColumn,
    refetch,
    removeColumns,
    replaceCategory,
    selectCell,
    sortEnabled,
    totalType,
    quantity,
    variant,
    type: gridType,
  } = grid;
  const widths = [...colWidths()];
  const categorizationColumnsNumber = columns.filter(
    (col: Column) => col.type === CATEGORY_CELL
  ).length;

  const columnData: ColumnData[] = hasGroups
    ? columns.reduce((colData: ColumnData[], column: Column, index: number) => {
        const { name, type } = column;
        const group = type === FieldType.CATEGORY ? CATEGORIZATIONS : column.group;
        const last = colData.length - 1;
        if (group !== '') {
          if (last < 0 || group !== colData[last].group) {
            colData.push({
              column,
              group,
              colSpan: 1,
              index,
              width: widths[index],
            });
          } else {
            /* eslint-disable no-param-reassign */
            const colDataLast = colData[last];
            if (colDataLast) {
              if (colDataLast.colSpan) colDataLast.colSpan += 1;
              if (colDataLast.width) colDataLast.width += widths[index];
            }
            /* eslint-enable no-param-reassign */
          }
        } else {
          colData.push({
            column,
            group: name,
            colSpan: 1,
            index,
            width: widths[index],
          });
        }
        return colData;
      }, [])
    : columns.map((column: Column) => ({
        column,
      }));
  return (
    <>
      {columnData.map((data: ColumnData, j: number) => {
        const { column, group, colSpan } = data;
        const { categorization, id, name, type, hasColumnMenu } = column;
        const {
          unitInfo: { unitName },
        } = quantity || defaultQuantity;

        let displayName = categorization ? removeYear(categorization.name) : name;
        let index = j;
        let columnWidth = widths[j];
        let enableColumnMenu = type === CATEGORY_CELL || hasColumnMenu;
        if (hasGroups) {
          if (group) displayName = group;
          if (data.index) index = data.index;
          if (data.width) columnWidth = data.width;
          enableColumnMenu = true;
        }
        if (name === U_M && type === FieldType.STRING) displayName = UNIT;
        if (name === QTY && type === FieldType.NUMBER) displayName = QUANTITY;

        const isAllocatedCell = type === FieldType.ALLOCATE;

        const columnSettings: ColumnSettings = {
          isLastAddableColumn: type !== CATEGORY_CELL,
          isFirstCategoryColumn: type === CATEGORY_CELL && j === 0,
          isLastCategoryColumn: j === categorizationColumnsNumber - 1,
          isTotalCell: hasGroups ? group === TOTAL : j === columns.length - 1,
          isTotalCellShadow: columnData[j - 1]?.column?.type !== SOURCE,
          isSourceCell: hasGroups ? group === SOURCE : type === SOURCE,
          isAllocatedCell,
        };

        const isTotalColumnInMarkupGrid =
          gridType !== GridType.ESTIMATE_GRID && columnSettings.isTotalCell;

        let headerScope = '';
        let rightOffset = 'auto';
        if (columnSettings.isTotalCell) {
          headerScope = 'root-row-total';
          rightOffset = '0px';
        }
        if (columnSettings.isSourceCell) {
          headerScope = 'root-row-source';
          rightOffset = '117px';
        }

        if (quantity && !categorization) {
          displayName = unitName;
        }

        const fieldGroup = stringAsFieldGroup(group);
        return (
          <th
            key={`header-${id}-${type}`}
            scope={headerScope}
            className={`join-grid-td ${ROW_HEADER_CLASS} ${j === 0 ? 'header-th-first' : ''}`}
            colSpan={colSpan}
            style={
              !hasGroups
                ? {
                    minWidth: widths[j] - COLUMN_VERTICAL_BORDER,
                    right: rightOffset,
                    top: topOffset,
                  }
                : {
                    right: rightOffset,
                    background: fieldGroup ? columnGroupColors.get(fieldGroup) : undefined,
                  }
            }
          >
            <EstimateHeader
              addColumn={addColumn}
              canEditColumns={!columnsReadOnly}
              categorization={(!hasGroups && categorization) || undefined}
              closeErrorHeaders={closeErrorHeaders}
              column={column}
              columnSettings={columnSettings}
              deleteColumn={(colID: UUID) => removeColumns([colID])}
              displayName={displayName}
              enableColumnMenu={enableColumnMenu}
              estimateID={estimateID}
              estimateTotalType={totalType}
              fieldId={id}
              gridType={gridType}
              hasGroups={hasGroups}
              index={index}
              moveColumn={(c: number, f: number) => moveColumn(c, f)}
              refetch={refetch || (() => {})}
              replaceCategory={replaceCategory}
              scrollToCell={scrollToCell}
              selectCell={selectCell}
              setEditing={setEditing}
              setTotalType={(estimateTotalType: EstimateTotalType) => {
                if (estimateTotalType === totalType) return;
                grid.setTotalType(estimateTotalType);
              }}
              sortEnabled={sortEnabled && lines.length > 1}
              variant={variant}
              width={columnWidth - COLUMN_VERTICAL_BORDER}
            />
            <div
              ref={(a) => {
                // eslint-disable-next-line no-param-reassign
                if (a) column.divider = a;
              }}
              onMouseDown={(e) => {
                // we don't want users changing the size of the allocated column in markups
                if (!isTotalColumnInMarkupGrid)
                  mouseDown(grid, e, index - 1, isAllocatedCell, isTotalColumnInMarkupGrid);
              }}
              className={index !== 0 && !isTotalColumnInMarkupGrid ? 'join-grid-col-resize' : ''}
            />
          </th>
        );
      })}
    </>
  );
};

export default HeaderCells;
