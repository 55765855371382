import { FC, memo, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';

import { GridVariant, isAccordionVariant, isItemVariant } from '../../actions/gridAnalytics';
import { gridWidthVar } from '../../api/apollo/reactiveVars';
import { TermKey } from '../../api/gqlEnums';
import { BUTTON_ESTIMATE_TABLE_ADD_LINE, BUTTON_MARKUP_TABLE_ADD_LINE } from '../../tagConstants';
import { allDataFilteredMessage } from '../Charts/ChartsUtils';
import DashboardChartPlaceholder from '../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import MilestoneDetailsQuantitiesGridHeaderData from '../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesGrid/MilestoneDetailsQuantitiesGridHeaderData';

import { useEstimateMarkupController } from './hooks/useGridController';
import usePrintAllRows from './hooks/usePrintAllRows';
import useWindowResize, { calculateOverallWidth } from './hooks/useWindowResize';
import { Grid } from './JoinGrid';
import JoinGridAccordion from './JoinGridAccordion';
import './style/grid.scss';
import { EstimateGridPermissions, JoinGridWrapperProps } from './types';

// These consolidate the various usages of the JoinGridWrapper into one.
// Currently that is 3 places, all of which will may have different values
// for these props -- but the grid doesn't need to care about that, since
// it will simply be passed these permissions.
// - Item Estimates
// - Milestone Estimates
// - Milestone Target Budgets

const permissionsEqual = (old: EstimateGridPermissions, next: EstimateGridPermissions) =>
  old.viewEstimate === next.viewEstimate &&
  old.editLines === next.editLines &&
  old.viewMarkups === next.viewMarkups &&
  old.editMarkups === next.editMarkups &&
  old.summaryMarkups === next.summaryMarkups;

// Memoization: When these props change, the grid will be forced to update
const areEqual = (old: JoinGridWrapperProps, next: JoinGridWrapperProps) =>
  JSON.stringify(old.enabledCategorizationsIDs) ===
    JSON.stringify(next.enabledCategorizationsIDs) &&
  old.estimateID === next.estimateID &&
  old.projectID === next.projectID &&
  old.sortData === next.sortData &&
  isItemVariant(old.variant) === isItemVariant(next.variant) &&
  old.collapseSizeRef === next.collapseSizeRef &&
  permissionsEqual(old.permissions, next.permissions) &&
  old.viewFilter === next.viewFilter &&
  old.sendRefetch === next.sendRefetch;

// Welcome to JoinGrid! For more comprehensive documentation about what is happening here,
// why the grid is structured the way it is, and where to place a given edit or piece
// of functionality, please refer to the documentation at https://github.com/JoinCAD/komodo/wiki/Grids
const JoinGridWrapper: FC<JoinGridWrapperProps> = (props) => {
  const {
    clearFilters,
    collapseSizeRef,
    gridType,
    isExpanded,
    isItemEstimateView,
    hasOwnerCostEstimate,
    permissions,
    t,
    variant,
    sendRefetch = false,
  } = props;
  const [isRefetching, setIsRefetching] = useState(false);
  const {
    viewEstimate,
    viewMarkups,
    summaryMarkups,
    editLines,
    editMarkups,
    viewEstimateCostSubtotals,
  } = permissions;
  const isAccordion = isAccordionVariant(variant);
  const isTemplate = variant === GridVariant.ITEM_TEMPLATE;
  // DATA
  const { data, error, loading } = useEstimateMarkupController(props);
  const directRef = useRef(null);
  const markupRef = useRef(null);
  const incorporatedMarkupRef = useRef(null);
  const incorporatedDrawRef = useRef(null);
  const directSizeRef = isAccordion ? directRef : collapseSizeRef;

  // If the user changes any item contingencies we use this to
  // reload the estimate in the grid
  useEffect(() => {
    if (!sendRefetch && isRefetching) {
      setIsRefetching(false);
    }
    if (sendRefetch && !isRefetching) {
      setIsRefetching(true);
      if (data?.estimate?.refetch) data.estimate.refetch();
    }
  }, [sendRefetch, isRefetching, data]);

  // SIZING EFFECTS
  useWindowResize(data?.estimate, directSizeRef, isAccordion);
  useWindowResize(data?.markup, markupRef, isAccordion); // ensure markup grid.maxHeight stays up to date
  // when adding a new grid type, we might need additional calls to useWindowResize()
  // to make sure their maxHeight stays up to date

  usePrintAllRows(
    [
      data?.estimate,
      data?.markup,
      data?.inheritedMarkups,
      data?.incorporatedMarkups,
      data?.incorporatedDraws,
    ],
    collapseSizeRef
  );
  useEffectOnce(() => () => {
    gridWidthVar(0);
  });

  // VARS
  const hasWidth = !!useReactiveVar(gridWidthVar);

  if (loading || error || !data) {
    return <LinearProgress hidden={!loading} />;
  }
  const { estimate, markup, inheritedMarkups, incorporatedMarkups, incorporatedDraws } = data;

  // Set the initial width(s) of the tables to the collapse container;
  const { clientWidth } = collapseSizeRef.current || {};
  if (clientWidth) {
    // However, we don't do it synchronously - the next table draw will use this width
    // without us having to update it.
    const widthTotal = calculateOverallWidth(clientWidth, isAccordion);
    if (inheritedMarkups) inheritedMarkups.setOverallWidth(widthTotal, false);
    if (incorporatedMarkups) incorporatedMarkups.setOverallWidth(widthTotal, false);
    if (incorporatedDraws) incorporatedDraws.setOverallWidth(widthTotal, false);
    data.markup.setOverallWidth(widthTotal, false);
    data.estimate.setOverallWidth(widthTotal, false);
  }
  if (isAccordion) {
    estimate?.setMaxHeight(window.innerHeight);
  }

  if (!viewEstimate || !estimate) return null;

  const {
    isFiltering,
    data: { lines },
  } = estimate;
  const isEmpty = !lines.length;

  const noDataPlaceholder = isEmpty && isFiltering && (
    <div className="join-grid-placeholder">
      <DashboardChartPlaceholder
        clearFilters={clearFilters}
        emptyMessage={allDataFilteredMessage}
      />
    </div>
  );

  const canViewMarkups = (viewMarkups || summaryMarkups) && viewEstimateCostSubtotals;

  if (isAccordion)
    return (
      <JoinGridAccordion
        canViewDetails={viewEstimate}
        canViewMarkups={canViewMarkups}
        canViewMarkupsDetails={viewMarkups}
        directRef={directRef}
        editLines={editLines}
        editMarkups={editMarkups}
        gridType={gridType}
        gridData={data}
        hasWidth={hasWidth}
        isFiltering={isFiltering}
        isItemEstimateView={isItemEstimateView}
        isExpanded={isExpanded}
        markupRef={markupRef}
        incorporatedMarkupRef={incorporatedMarkupRef}
        incorporatedDrawRef={incorporatedDrawRef}
        hasOwnerCostEstimate={!!hasOwnerCostEstimate}
        noDataPlaceholder={noDataPlaceholder || undefined}
        variant={variant}
      />
    );

  return (
    <>
      <div className="grid-component-estimate">
        {noDataPlaceholder || (
          <div className="flex flex-col gap-0.5">
            <div className="type-label">
              {variant !== GridVariant.QUANTITY && t.titleCase(TermKey.DIRECT_COST)}
            </div>
            {variant === GridVariant.QUANTITY && (
              <MilestoneDetailsQuantitiesGridHeaderData grid={estimate} />
            )}
            {hasWidth && (
              <Grid
                grid={estimate}
                gridType={gridType}
                buttons={
                  editLines
                    ? {
                        id: BUTTON_ESTIMATE_TABLE_ADD_LINE,
                        isAddDisabled: isFiltering,
                        onAddClick: () => {
                          estimate.addLine('Button', estimate.scrollToBottom);
                        },
                        onDeleteClick: () => {
                          estimate.numSelectedRows = 0;
                          estimate.previouslySelectedRow = -1;
                          estimate.deleteLines();
                        },
                      }
                    : undefined
                }
              />
            )}
          </div>
        )}
      </div>
      {canViewMarkups && hasWidth && markup && (
        <>
          {!editLines && <div className="join-grid-table-padding" />}
          <div className="flex flex-col gap-0.5">
            <div className="type-label">{t.titleCase(TermKey.MARKUP)}</div>
            <Grid
              grid={markup}
              gridType={gridType}
              buttons={
                editMarkups
                  ? {
                      id: BUTTON_MARKUP_TABLE_ADD_LINE,
                      onAddClick: () => {
                        markup.addLine('Button', markup.scrollToBottom);
                      },
                      onDeleteClick: () => {
                        markup.numSelectedRows = 0;
                        markup.previouslySelectedRow = -1;
                        markup.deleteLines();
                      },
                    }
                  : undefined
              }
            />
          </div>
          {inheritedMarkups && !isTemplate && (
            <>
              {!editMarkups && <div className="join-grid-table-padding" />}
              <div className="flex flex-col gap-0.5">
                <div className="type-label">{`Milestone ${t.titleCase(TermKey.MARKUP)}`}</div>
                <Grid grid={inheritedMarkups} gridType={gridType} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const JoinGridWrapperMemo = memo(JoinGridWrapper, areEqual);
export default JoinGridWrapperMemo;
