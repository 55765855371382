type Props = {
  showClamped?: boolean;
  title: string;
};

export default function NameCell(props: Props) {
  return (
    <div
      className={`${props.showClamped ? 'mb-2 mt-2 line-clamp-3' : 'truncate'} type-table-text`}
      title={props.title}
      data-cy="scenario-name-cell"
    >
      {props.title}
    </div>
  );
}
