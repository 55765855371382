import { FC, useState } from 'react';

import { setToast } from '../../hooks/useToastParametersLocalQuery';
import { useUploadImage } from '../assets/hooks/AssetsMutationHook';
import { getUserPicture } from '../Collaborators/UserUtils';
import { blockedTypes } from '../login/Signup/SignupPages/SetupUserData';
import UploadIcon from '../Select/UploadIcon/UploadIcon';

import { useUpdateCompanyUserPictureMutation } from './CompanyHooks';

type CompanyUserPhotoProps = {
  user: CompanyUserDetails['user'];
};

const CompanyUserPhoto: FC<CompanyUserPhotoProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const updateCompanyUserPicture = useUpdateCompanyUserPictureMutation();
  const uploadImage = useUploadImage(blockedTypes);

  const addFile = (file: File) => {
    setLoading(true);
    const onSuccess = (asset: Asset) => {
      updateCompanyUserPicture(user.id, asset.location);
      setLoading(false);
    };
    const onFailure = (message: string) => {
      setLoading(false);
      setToast({ message });
    };
    uploadImage(file, null, onSuccess, onFailure);
  };

  return (
    <UploadIcon
      accept=".png, .jpg, .gif" // we currently do not support .svg for user icons
      addFile={addFile}
      loading={loading}
      thumbnail={getUserPicture(user)}
      height={88}
      width={88}
    />
  );
};

export default CompanyUserPhoto;
