import { FC, useRef } from 'react';
import { useClickAway, useKey } from 'react-use';

import { withStyles } from '@material-ui/core/styles';

import { pluralizeString, removeYear } from '../../../utilities/string';
import SelectAll from '../SelectAll/SelectAll';
import { SelectCategoryValue, bulkToggleOption } from '../SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputMulti from '../SelectCategoryChipInput/SelectCategoryChipInputMulti';

import styles from './SelectPanelStyles';

interface SelectPanelProps {
  addCategories: (categories: Category[]) => void;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  isCompact?: boolean;
  isPreview: boolean;
  noun: string;
  onSelectAll: () => void;
  removeCategories: (categories: Category[]) => void;
  selectedCategories: Category[];
  setShowSelectPanel: (value: boolean) => void;
}

const SelectPanel: FC<SelectPanelProps> = ({
  addCategories,
  categorizations,
  classes,
  isCompact = true,
  isPreview,
  noun, // used to display what you are selecting
  onSelectAll,
  removeCategories,
  selectedCategories,
  setShowSelectPanel,
}) => {
  // KEY LISTENERS
  useKey('Escape', () => {
    const hasNoActiveSelectors = document.getElementById('SelectCategory') === null;
    // don't close the select panel if user is currently editing a select
    if (hasNoActiveSelectors) {
      setShowSelectPanel(false);
    }
  });
  const panelRef = useRef(null);

  useClickAway(panelRef, () => {
    const hasNoActiveSelectors = document.getElementById('SelectCategory') === null;
    if (hasNoActiveSelectors) {
      setShowSelectPanel(false);
    }
  });
  // VARIABLES
  const hasSelected = selectedCategories.length > 0;
  const hasCategorizations = categorizations && categorizations.length > 0;

  return (
    <div ref={panelRef} className={classes.containerOuter}>
      <div className={classes.containerInner}>
        <SelectAll
          onClearAll={() => {
            removeCategories([bulkToggleOption, ...selectedCategories]);
          }}
          onSelectAll={() => {
            onSelectAll();
            setShowSelectPanel(false);
          }}
          clearAllText="clear all"
          selectAllText={`select all ${pluralizeString(noun, 2).toLowerCase()}`}
          clearAllActive={hasSelected}
          selectAllActive
        />
        {hasCategorizations && (
          <div className={classes.body}>
            {categorizations.map((categorization) => (
              <div
                id={`SelectPanel-${categorization.id}`}
                key={`SelectPanel-input-${categorization.id}`}
              >
                <p className={classes.label} data-cy={`SelectPanel-title-${categorization.name}`}>
                  {removeYear(
                    categorization.name,
                    (categorization as CategorizationFeDefined).builtin
                  )}
                </p>
                <SelectCategoryChipInputMulti
                  addCategories={(newCategories: SelectCategoryValue[]) =>
                    addCategories(newCategories)
                  }
                  categorizations={[categorization]}
                  disabled={false}
                  id={`SelectPanel-${categorization.id}`}
                  isCompact={isCompact}
                  isPreview={isPreview}
                  key={`SelectPanel-${categorization.id}`}
                  noun="category"
                  placeholder="Select Categories"
                  removeCategories={(oldCategories) => {
                    removeCategories(oldCategories);
                  }}
                  selectedCategories={selectedCategories.filter(
                    (category: Category) =>
                      category.categorization && category.categorization.id === categorization.id
                  )}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SelectPanel);
