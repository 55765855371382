import { FC, KeyboardEvent, useRef } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Popover } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import MultiGroupAnchor from './MultiGroupAnchor';
import MultiGroupStyles from './MultiGroupStyles';

type MultiGroupProps = {
  classes: Classes<typeof MultiGroupStyles>;
  children: JSX.Element;
  displayChips: number;
  emptyMessage: string;
  open: boolean;
  selectedNames: string[];
  setOpen: (isOpen: boolean) => void;
};

const MultiGroup: FC<MultiGroupProps> = ({
  classes,
  children,
  displayChips,
  emptyMessage,
  open,
  selectedNames,
  setOpen,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // Actions
  const onClick = () => {
    setOpen(true);
  };
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Escape':
        setOpen(false);
        break;
      default:
    }
  };

  return (
    <div data-cy="input-groupBy" ref={ref} className={classes.groupContainer}>
      <MultiGroupAnchor
        displayChips={displayChips}
        emptyMessage={emptyMessage}
        onClick={onClick}
        selectedNames={selectedNames}
      />
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="simple-menu"
        open={open}
        onClose={() => setOpen(false)}
        onKeyDown={onKeyDown}
      >
        {children}
      </Popover>
    </div>
  );
};

const MultiGroupOrderStyled = withStyles(MultiGroupStyles)(MultiGroup);
export default DragDropContext(HTML5Backend)(MultiGroupOrderStyled);
