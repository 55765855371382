import { ReactNode } from 'react';

type Props = {
  compact?: boolean;
  children?: ReactNode;
};

export default function Cell(props: Props) {
  return (
    <div
      className={`flex items-center truncate ${props.compact ? 'min-h-[36px]' : 'min-h-[40px]'}`}
    >
      {props.children}
    </div>
  );
}
