import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    inner: {
      minWidth: '100%',
      width: 'max-content',
    },
    root: {
      minHeight: 350,
      '@media print': {
        overflow: 'visible',
        margin: 0,
        marginTop: 0,
      },
    },
    divider: {
      margin: '0',
      backgroundColor: theme.palette.joinGrey400,
    },
    pageBreakLine: {
      '@media not print': {
        borderTop: '1px dotted grey',
      },
      width: 'calc(100% + 68px)',
      left: -38,
      position: 'relative',
    },
  });

export default styles;
