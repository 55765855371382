import { ComponentProps, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function SearchResultLink(props: ComponentProps<typeof Link>) {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <Link
      className="flex items-center gap-4 rounded p-2 text-left outline-none focus-visible:bg-button-secondary-hover"
      {...props}
      onKeyDown={(e) => {
        if (e.key === 'ArrowDown') {
          const nextElement = ref.current?.nextElementSibling;
          if (nextElement instanceof HTMLAnchorElement) {
            nextElement.focus();
          }
        } else if (e.key === 'ArrowUp') {
          const prevElement = ref.current?.previousElementSibling;
          if (prevElement instanceof HTMLAnchorElement) {
            prevElement.focus();
          }
        }
      }}
      ref={ref}
    />
  );
}
