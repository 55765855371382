import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { JoinRoutes } from '../../api/gqlEnums';
import { generateSharedPath } from '../../utilities/routes/links';

import { useDefaultProjectRoute } from './utils';

export default function ProjectRoute() {
  useNavigateToDefaultRoute();

  return <Outlet />;
}

function useNavigateToDefaultRoute() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { route, loading } = useDefaultProjectRoute();
  const pathnameWithoutTrailingSlash = useLocation().pathname.replace(/\/$/, '');

  useEffect(() => {
    const rootProjectPath = generateSharedPath(JoinRoutes.PROJECT, { projectId });
    if (pathnameWithoutTrailingSlash === rootProjectPath && !loading) {
      // 'replace: true' makes it so that this temporary redirect doesn't become part of the browser history
      if (!projectId || !isUUID(projectId)) navigate('/404', { replace: true });
      else navigate(generateSharedPath(route, { projectId }), { replace: true });
    }
  }, [loading, navigate, pathnameWithoutTrailingSlash, projectId, route]);
}
