import { FC } from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import { Add, Cached, DeleteOutline } from '@material-ui/icons';

import { GRID_ADD_ROW_BUTTON, GRID_DELETE_ROW_BUTTON } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { capitalizeString } from '../../../utilities/string';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import AddRemoveRowButtonsStyles from './AddRemoveRowButtonsStyles';

type AddRemoveRowButtonsProps = {
  disabled: boolean;
  disabledDelete: boolean;
  id?: UUID;
  classes: Classes<typeof AddRemoveRowButtonsStyles>;
  onClick?: () => void;
  onClickDelete?: () => void;
  onClickReplace?: () => void;
  tooltip?: string;
  variant?: string;
};

const AddRemoveRowButtons: FC<AddRemoveRowButtonsProps> = ({
  disabled,
  disabledDelete,
  id = '',
  classes,
  onClick,
  onClickDelete,
  onClickReplace,
  tooltip,
  variant = 'row',
}) => {
  const variantName = capitalizeString(variant);

  return (
    <div className={classes.lineMessage}>
      {onClickDelete && (
        <Button
          data-cy={GRID_DELETE_ROW_BUTTON}
          disabled={disabledDelete}
          onClick={onClickDelete}
          className={`${classes.deleteButton} ${
            disabledDelete ? classes.disabled : classes.delete
          }`}
          variant="outlined"
        >
          <DeleteOutline className={classes.addIcon} />
          {`Delete Selected ${variantName}s`}
        </Button>
      )}
      {onClick && (
        <>
          <NormalTooltip title={tooltip || ''}>
            <div>
              <Button
                data-cy={GRID_ADD_ROW_BUTTON}
                disabled={disabled}
                id={id}
                onClick={onClick}
                className={`${classes.littleButton} ${disabled ? classes.disabled : ''}`}
              >
                <Add className={classes.addIcon} />
                {`Add a ${variantName}`}
              </Button>
            </div>
          </NormalTooltip>
          <Typography variant="body2">
            or hit
            <span className={classes.key}>Enter</span>
            in the last row to add another.
          </Typography>
        </>
      )}
      {onClickReplace && (
        <div className={classes.iconRight}>
          <NormalTooltip title="Replace categories">
            <IconButton className={classes.iconGrid} disableRipple onClick={onClickReplace}>
              <Cached className={classes.iconReplace} />
            </IconButton>
          </NormalTooltip>
        </div>
      )}
    </div>
  );
};

export default withStyles(AddRemoveRowButtonsStyles)(AddRemoveRowButtons);
