import { useMutation } from '@apollo/client';

import { createCategorizationSchemeAnalytics } from '../../../analytics/analyticsEventProperties';
import { REFETCH_CHANGE_CATEGORIZATION } from '../../../api/refetchSets';
import {
  CreateCategorizationMutation,
  CreateCategorizationMutationVariables,
} from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { createCategorizationMutation } from '../hooks/queries';

export const useCreateCategorization = (
  projectID: UUID,
  setErrorMessage: (msg: string) => void,
  onSuccess?: (
    c: Extract<CreateCategorizationMutation['createCategorization'], Categorization>
  ) => void
) => {
  const [mutationFunction, mutationResult] = useMutation<
    CreateCategorizationMutation,
    CreateCategorizationMutationVariables
  >(createCategorizationMutation, {
    refetchQueries: REFETCH_CHANGE_CATEGORIZATION,
  });
  const triggerAnalytics = useAnalyticsEventHook();
  const createCategorization = (name: string, description?: string) =>
    mutationFunction({
      variables: {
        projectID,
        name,
        description,
      },
    }).then((result) => {
      const created = result?.data?.createCategorization;
      if (created && 'id' in created) {
        triggerAnalytics(createCategorizationSchemeAnalytics(created.id));
        onSuccess?.(created);
      } else if (created && created.userErrors.length > 0) {
        setErrorMessage(created.userErrors[0]);
      }
    });

  return [createCategorization, mutationResult] as const;
};
