import {
  ScenarioShareEvent,
  ScenarioShareKey,
  scenarioShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { YC_SCENARIO_SHARING } from '../../../features';
import { ResourceType } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import SharedUsersState from '../../dragon-scales/SharedUsersState/SharedUsersState';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

type Props = {
  analyticsKey: ScenarioShareKey;
  scenarioID: UUID;
};

export default function SharedScenarioIcon(props: Props) {
  const isScenarioSharingFeature = useHasFeature(YC_SCENARIO_SHARING);
  const sendAnalytics = useAnalyticsEventHook();

  const { data, loading } = useGetSharedResources(
    [props.scenarioID],
    ResourceType.SCENARIO,
    true,
    !isScenarioSharingFeature
  );
  const users = data?.getSharedResources?.resources?.[0]?.users;
  const isShared = !!(users?.length && users?.length >= 2);

  const onShareTooltip = () => {
    sendAnalytics(
      scenarioShareEvent(props.analyticsKey, ScenarioShareEvent.TOOLTIP, { type: 'shared' })
    );
  };
  const sharingBadge = <SharedUsersState onTooltip={onShareTooltip} sharedUsers={users} />;

  if (loading) return null;
  if (!isScenarioSharingFeature) return null;
  return isShared ? sharingBadge : null;
}
