import { FC } from 'react';
import * as React from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import {
  NOTES_COL_WIDTH_COLLAPSED,
  NOTES_COL_WIDTH_EXPANDED,
} from '../CostReportListRow/CostReportListRowStyles';

import { styles } from './CostReportListHeaderStyles';

type ExpandReportNotesHeaderProps = {
  classes: Classes<typeof styles>;
  isPrint: boolean;
  hasComments: boolean;
  isNotesColumnExpanded: boolean;
  setNotesColumnExpanded?: (isExpanded: boolean) => void;
  notesHeaderRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const ExpandReportNotesHeader: FC<ExpandReportNotesHeaderProps> = ({
  classes,
  isPrint,
  hasComments,
  isNotesColumnExpanded,
  setNotesColumnExpanded = () => null,
  notesHeaderRef,
}) => {
  const commentColumnWidth = isNotesColumnExpanded
    ? NOTES_COL_WIDTH_EXPANDED
    : NOTES_COL_WIDTH_COLLAPSED;

  // Match column keys to column descriptions for header content
  if (isPrint && !hasComments) return null;
  return (
    <div
      style={{
        width: commentColumnWidth,
        justifyContent: isNotesColumnExpanded ? 'flex-start' : 'center',
      }}
      className={classes.notesHeader}
      ref={notesHeaderRef}
    >
      {isNotesColumnExpanded ? (
        <>
          <Typography className={classes.notesHeaderTitle} variant="subheading">
            Notes
          </Typography>
          {!isPrint && (
            <Typography
              data-cy="button-CollapseNotes"
              variant="caption"
              role="button"
              onClick={() => setNotesColumnExpanded(false)}
              className={classes.expandCollapse}
            >
              collapse notes
            </Typography>
          )}
        </>
      ) : (
        <Typography className={classes.notesHeaderTitleCollapsed} variant="subheading">
          Notes
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(ExpandReportNotesHeader);
