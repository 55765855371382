import { createStyles } from '@material-ui/core';

import CompanyTabStyles from '../../CompanyTab/CompanyTabStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    ...CompanyTabStyles(theme),
    addIcon: {
      height: 16,
      width: 16,
    },
    close: {
      width: 32,
      height: 32,
    },
    content: {
      padding: '16px 24px',
      '@media screen and (min-height: 600px)': {
        overflowY: 'visible',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: '11px',
    },
    label: {
      fontWeight: 300,
    },
    paper: {
      overflow: 'visible',
      borderRadius: '4px',
    },
    projectRoleGrid: {
      display: 'grid',
      gap: '4px 11px',
      gridTemplateColumns: '2fr 1fr auto',
      marginTop: 8,
    },
    red: {
      color: theme.palette.red,
    },
    root: {
      color: theme.palette.primaryGrey,
    },
    row: {
      margin: 0,
    },
    smallButton: {
      fontSize: 12,
      height: 24,
      minHeight: 24,
      padding: '0 8px 0 4px',
      alignSelf: 'start',
    },
    title: {
      padding: '12px 12px 8px 12px',
    },
    titleBold2: {
      ...theme.typography.header,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
    },
  });
