import { PageViews } from '../analytics/analyticsEventProperties';
import {
  EXECUTIVE_DASHBOARD_PATH,
  FORECASTING_EXPLORE_PATH,
  FORECASTING_PROJECT_COMP_PATH,
  FORECASTING_REPORTS_PATH,
  FORECASTING_TAB_PATH,
  ITEM_ACTIVITY_PATH,
} from '../constants';

export function getPageView(pathname: string) {
  const currentPath = pathname.split('/');
  const currentPage = currentPath[2];
  const currentPageDetail = currentPath[3];
  const msrPage = currentPath[4] === 'costs';
  const isExecDashboard = currentPath[1] === EXECUTIVE_DASHBOARD_PATH;
  const isItemActivity = currentPath[3] === ITEM_ACTIVITY_PATH;
  const isForecasting = currentPath[1] === FORECASTING_TAB_PATH;

  if ((currentPage === undefined || (currentPage === '/' && !isExecDashboard)) && !isForecasting) {
    return PageViews.PROJECT_LIST;
  }
  if (currentPage === 'milestones' && currentPageDetail === '') {
    return PageViews.MILESTONES;
  }
  if (currentPage === 'milestones' && currentPageDetail !== '') {
    if (msrPage) {
      return PageViews.MSR;
    }
    if (currentPageDetail === 'variance') {
      return PageViews.VARIANCE_REPORT;
    }
    return PageViews.MILESTONE_DETAILS;
  }
  if (currentPage === 'items' && isItemActivity) {
    return PageViews.ITEM_ACTIVITY;
  }
  if (currentPage === 'items' && currentPageDetail === '') {
    return PageViews.ITEM_LIST;
  }
  if (currentPage === 'items' && currentPageDetail !== '') {
    return PageViews.ITEM_DETAILS;
  }
  if (currentPage === 'settings' && currentPageDetail === '') {
    return PageViews.SETTINGS;
  }
  if (currentPage === 'team' && currentPageDetail === '') {
    return PageViews.TEAM;
  }
  if (currentPage === 'dashboard' && currentPageDetail === '') {
    return PageViews.DASHBOARD;
  }
  if (currentPage === 'reports' && currentPageDetail === '') {
    return PageViews.REPORTS;
  }
  if (isExecDashboard) {
    return PageViews.EXECUTIVE_DASHBOARD;
  }
  if (isForecasting) {
    if (!currentPage) return PageViews.FORECASTING_HOME;
    switch (currentPage) {
      case FORECASTING_EXPLORE_PATH:
        return PageViews.FORECASTING_EXPLORE;
      case FORECASTING_REPORTS_PATH:
        return PageViews.FORECASTING_REPORTS;
      case FORECASTING_PROJECT_COMP_PATH:
        return PageViews.FORECASTING_PROJECT_COMPARISON;
      default:
    }
  }
  if (currentPageDetail === 'roles') {
    return PageViews.TEAM_ROLES;
  }
  return `${currentPage}_view`;
}
