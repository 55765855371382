import { memo, useContext, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { PcMarkup, ProjectCompInput } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import {
  PROJECT_COMP_PERCENT_FIELD,
  PROJECT_COMP_QUANTITY_TOTAL_FIELD,
  PROJECT_COMP_TOTAL_FIELD,
} from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { StrictUnion } from '../../../utilities/types';
import { isCostScalar } from '../../CostReport/CostReportUtils';
import Settings from '../../Icons/Settings';
import { Portal } from '../../Portal/Portal/Portal';
import { Button } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import { ProjectCompPortals } from '../constants/elementIdentifiers';
import { ProjectCompSectionVariant } from '../constants/projectCompTypes';
import ObserverContext from '../context/observer';
import EditCostGridsDialog from '../DialogsProjectComps/EditCostGridsDialog/EditCostGridsDialog';
import HiddenCostsDialog from '../DialogsProjectComps/HiddenCostsDialog/HiddenCostsDialog';
import ProjectCompsCostLabels from '../ProjectCompsCostTable/ProjectCompsCostLabels';
import ProjectCompsCostTable from '../ProjectCompsCostTable/ProjectCompsCostTable';
import ProjectCompsCostTableSummaryRow from '../ProjectCompsCostTable/ProjectCompsCostTableSummaryRow';
import CategorizationInput from '../ProjectCompsInputs/ProjectCompsInputsCategorization/CategorizationInput';
import { LineInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import {
  getDisplayValueString,
  showMinMaxCosts,
  toggleShowMinMaxCosts,
  useIsProjectCompSectionCollapsed,
} from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { COSTS_LABEL } from './ProjectCompsSectionLabels';

type CommonCostsProps = {
  categories: ProjectCompsSet['categories'];
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  markups: PcMarkup[];
};

type ProjectCompCostsProps = CommonCostsProps & {
  index: number;
  lineInputUpdateFunctions: LineInputUpdateFunctions;
  projectCompInput: ProjectCompInput;
  projectCompsCostTable: ProjectCompsCostTable;
  selectedUnits: Unit[];
  setCategorization: (categorizationID: UUID, categorizationLevel: number) => void;
  unit: Unit;
  variant: ProjectCompSectionVariant.PROJECT_COMP;
};

type AverageCompCostsProps = CommonCostsProps & {
  index: number;
  lineInputUpdateFunctions: LineInputUpdateFunctions;
  projectCompsCostTable: ProjectCompsCostTable;
  unit: Unit;
  variant: ProjectCompSectionVariant.AVERAGE_COMP;
};

type SidebarCostsProps = CommonCostsProps & {
  hasProjects: boolean;
  onEditCategorizations: () => void;
  variant: ProjectCompSectionVariant.SIDEBAR;
};

type Props = StrictUnion<ProjectCompCostsProps | AverageCompCostsProps | SidebarCostsProps>;

const ProjectCompSectionCosts = ({
  categories,
  classes,
  costTableColumnInputs,
  hasMarkups,
  hasProjects,
  index = 0,
  lineInputUpdateFunctions,
  markups,
  onEditCategorizations,
  projectCompInput,
  projectCompsCostTable,
  selectedUnits,
  setCategorization,
  unit,
  variant,
}: Props) => {
  const sendAnalytics = useSendAnalyticsEventHook();
  const costsSection = ProjectCompSectionType.SECTION_COSTS;
  const isCostsCollapsed = useIsProjectCompSectionCollapsed(costsSection);

  const showMinMax = showMinMaxCosts();

  const pcsi = useReactiveVar(projectCompsSetInputVar);
  const { projectCompInputs } = pcsi;

  const excludedCategoryContents = pcsi.excludedCategoryContents ?? [];
  const excludedMarkupNames = pcsi.excludedMarkupNames ?? [];

  const { setActivateGridPortal, setActivateFooterPortal, showStickyCostsFooter } =
    useContext(ObserverContext);
  useEffect(() => {
    if (isCostsCollapsed) {
      setActivateGridPortal(false);
      setActivateFooterPortal(false);
    }
  }, [isCostsCollapsed, setActivateFooterPortal, setActivateGridPortal]);

  // Values
  const quantityValue =
    projectCompsCostTable?.summaryLines?.totalLine?.pinnedUnitValues.quantityValue;
  let totalQuantityValue: number | undefined;
  if (quantityValue && isCostScalar(quantityValue)) {
    totalQuantityValue = quantityValue.value;
  }
  const displayMetric =
    variant !== ProjectCompSectionVariant.SIDEBAR
      ? getDisplayValueString(totalQuantityValue, {
          isExact: true,
          isWide: true,
          unit: unit?.abbreviationSingular,
        })
      : undefined;

  const showingMinMaxCosts = showMinMaxCosts();
  const hasExcludedLines = excludedCategoryContents.length || excludedMarkupNames.length;

  const [showHiddenCostsDialog, setShowHiddenCostsDialog] = useState(false);
  const hiddenCostsDialog =
    variant === ProjectCompSectionVariant.SIDEBAR ? (
      <HiddenCostsDialog
        excludedCategoryContents={excludedCategoryContents}
        excludedMarkupNames={excludedMarkupNames}
        open={showHiddenCostsDialog}
        onClose={() => {
          setShowHiddenCostsDialog(false);
        }}
      />
    ) : null;

  const [showEditCostGridsDialog, setShowEditCostGridsDialog] = useState(false);
  const editCostGridsDialog =
    variant === ProjectCompSectionVariant.SIDEBAR ? (
      <EditCostGridsDialog
        onCloseDialog={() => {
          setShowEditCostGridsDialog(false);
        }}
        open={showEditCostGridsDialog}
        costTableColumnInputs={pcsi.costTableColumnInputs}
        units={selectedUnits}
      />
    ) : null;

  const menuOptions: MenuOption[] = [
    {
      callback: () => {
        toggleShowMinMaxCosts();
        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_MIN_MAX_CTA, {
            showMinMax: !showingMinMaxCosts,
            projectCompCount: projectCompInputs.length,
          })
        );
      },
      name: 'Show highs/lows',
    },
    {
      callback: () => {
        setShowHiddenCostsDialog(true);
        sendAnalytics(
          projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_OPEN_HIDDEN_COSTS_DIALOG)
        );
      },
      name: 'Hidden costs',
      disabled: !hasExcludedLines,
    },
    {
      callback: () => {
        setShowEditCostGridsDialog(true);
        sendAnalytics(
          projectCompsAnalyticsEvent(
            projectCompsEventTypes.PROJECT_COMPS_OPEN_EDIT_COST_GRIDS_DIALOG
          )
        );
      },
      name: 'Edit cost grids',
    },
  ];

  const toggleSettingsBtn =
    variant === ProjectCompSectionVariant.SIDEBAR ? (
      <IconMenu
        cy="iconMenu-costSettings"
        icon={<Settings />}
        iconButtonClassName={classes.actionButton}
        isBottomOriented
        options={menuOptions}
        variant={SelectVariants.UNSTYLED}
      />
    ) : undefined;

  const content: JSX.Element[] = [];

  if (isCostsCollapsed === false) {
    content.push(
      <div key="cc" className={`${classes.inputContainer} ${classes.sectionMargin}`}>
        <div className={classes.fullWidth}>
          {variant === ProjectCompSectionVariant.PROJECT_COMP && (
            <CategorizationInput
              categorizationID={projectCompInput.categorizationID ?? undefined}
              categorizationLevel={projectCompInput.categorizationLevel ?? undefined}
              projectID={projectCompInput?.projectID}
              setCategorization={setCategorization}
            />
          )}
          {variant === ProjectCompSectionVariant.SIDEBAR && hasProjects && (
            <div className="flex items-center justify-between">
              <div className="text-type-primary type-body2">Categorizations</div>
              <Button type="tertiary" onClick={onEditCategorizations} label="Edit" />
            </div>
          )}
        </div>
      </div>
    );

    if (variant === ProjectCompSectionVariant.SIDEBAR) {
      content.push(
        <ProjectCompsCostLabels
          categories={categories}
          hasMarkups={hasMarkups}
          index={index}
          key="labels"
          markups={markups}
        />
      );
    } else {
      content.push(
        <ProjectCompsCostTable
          categories={categories}
          costTableColumnInputs={costTableColumnInputs}
          hasMarkups={hasMarkups}
          lineInputUpdateFunctions={lineInputUpdateFunctions}
          key="cost-table"
          markups={markups}
          projectCompsCostTable={projectCompsCostTable}
          selectedUnits={selectedUnits}
          showMinMax={showMinMax}
          variant={variant}
        />,
        <Portal
          attributes={{
            order: String(index + 1),
            className: classes.comp,
          }}
          key="sticky-footer-costs"
          portalTargetID={
            variant === ProjectCompSectionVariant.AVERAGE_COMP
              ? ProjectCompPortals.AVG_COMP_FOOTER_COSTS
              : ProjectCompPortals.COMP_FOOTER_COSTS
          }
        >
          {showStickyCostsFooter ? (
            <div className={classes.sectionPadding}>
              <ProjectCompsCostTableSummaryRow
                costTableColumnInputs={costTableColumnInputs}
                cyPercentId={PROJECT_COMP_PERCENT_FIELD}
                cyQuantityTotalId={PROJECT_COMP_QUANTITY_TOTAL_FIELD}
                cyTotalId={PROJECT_COMP_TOTAL_FIELD}
                hasBorder
                showMinMax={showMinMax}
                columnValues={projectCompsCostTable?.summaryLines?.totalLine.columnValues}
              />
            </div>
          ) : null}
        </Portal>
      );
    }
  }

  return (
    <>
      {hiddenCostsDialog}
      {editCostGridsDialog}
      <div className={classes.fieldsContainer}>
        <ProjectCompSectionHeader
          actionButton={toggleSettingsBtn}
          collapseContentSummary={displayMetric}
          collapseSection={costsSection}
          isHighlightable={variant === ProjectCompSectionVariant.SIDEBAR}
          isTogglable={variant === ProjectCompSectionVariant.SIDEBAR}
          label={COSTS_LABEL}
        />
        {content}
      </div>
    </>
  );
};

export default memo(withStyles(styles)(ProjectCompSectionCosts));
