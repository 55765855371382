import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    container: {
      paddingTop: 16,
    },
    columns: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '10px',
    },
    divider: {
      marginTop: 40,
    },
    bottomPadding: {
      paddingBottom: 24,
    },
    selectorContainer: {
      flexGrow: 1,
    },
    span2: {
      gridColumn: 'span 2',
    },
    topPadding: {
      paddingTop: 24,
    },
  });
