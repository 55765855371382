// NEW ROLE PAGE HEADER
// TO DO: remove word New from the file name

import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  roleName: string;
};

const PermissionsRoleHeader = (props: Props) => {
  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-2">
        <h1
          className="type-heading1"
          data-cy="div-rolePermissionHeader"
        >{`${props.roleName} Role Permissions`}</h1>
        <div className="type-body1">
          All teammates with this role will inherit the permissions below
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default PermissionsRoleHeader;
