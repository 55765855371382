import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../api/gqlEnums';
import useLocalStorage from '../../hooks/useLocalStorage';
import { generateSharedPath } from '../../utilities/routes/links';
import useSummaryCostReport from '../Nav/Sidebar/hooks/useSummaryCostReport';
import { ScrollContainer } from '../scales';

import SuggestionCard from './SuggestionCard/SuggestionCard';
import SuggestionDialog from './SuggestionDialog/SuggestionDialog';
import SUGGESTIONS from './suggestions';
import { Suggestion } from './types';
import useCreateItemFromSuggestion from './useCreateItemFromSuggestion';

type Props = {
  milestoneID: UUID;
  projectID: UUID;
};

export default function ItemSuggestions(props: Props) {
  const [suggestions, setSuggestions] = useLocalStorage('SKUNK_SUGGESTIONS', SUGGESTIONS);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | undefined>();

  const [isCreatingItem, setIsCreatingItem] = useState(false);

  const navigate = useNavigate();
  const handleViewEstimate = useCallback(() => {
    navigate(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: props.projectID,
        milestoneId: props.milestoneID,
        search: '?view=ESTIMATE',
      })
    );
  }, [navigate, props.milestoneID, props.projectID]);

  const handleDismiss = (id: UUID) => {
    if (suggestions.length > 1) {
      setSuggestions((prevState) => (prevState ?? []).filter((s) => s.id !== id));
    } else {
      setSuggestions(SUGGESTIONS);
    }
    setSelectedSuggestion(undefined);
  };

  const onCreateItem = useCreateItemFromSuggestion(
    props.projectID,
    props.milestoneID,
    selectedSuggestion
  );
  const handleCreateItem = async () => {
    setIsCreatingItem(true);

    let createdItemID: UUID | undefined;
    try {
      createdItemID = await onCreateItem();
    } catch (err) {
      console.error(err);
    }

    // eslint-disable-next-line no-console
    console.log({ createdItemID });
    setIsCreatingItem(false);

    if (createdItemID) {
      setSuggestions((prevState) =>
        (prevState ?? []).filter((s) => s.id !== selectedSuggestion?.id)
      );
      navigate(
        generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
          projectId: props.projectID,
          itemId: createdItemID,
        })
      );
    }
  };

  const projectRunningCost = useSummaryCostReport(props.projectID, props.milestoneID).running;

  if (!suggestions.length) return null;

  return (
    <>
      <div className="w-full bg-background-2">
        <div className="p-4 pb-0 type-heading2">Join Generative Items</div>
        <ScrollContainer direction="horizontal">
          <div className="flex gap-4 p-4">
            {suggestions.map((suggestion) => (
              <SuggestionCard
                key={suggestion.id}
                title={suggestion.title}
                reason={suggestion.suggestionReason}
                baselineCost={suggestion.baselineCost}
                insights={suggestion.suggestionsInsights}
                onClick={() =>
                  setSelectedSuggestion(suggestions.find((s) => s.id === suggestion.id))
                }
                onDismiss={() => handleDismiss(suggestion.id)}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
      {selectedSuggestion ? (
        <SuggestionDialog
          isCreatingItem={isCreatingItem}
          onClose={() => setSelectedSuggestion(undefined)}
          onCreate={handleCreateItem}
          onDismiss={() => handleDismiss(selectedSuggestion.id)}
          onViewEstimate={handleViewEstimate}
          projectCost={projectRunningCost}
          suggestion={selectedSuggestion}
        />
      ) : null}
    </>
  );
}
