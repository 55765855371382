import { FC } from 'react';

import { LARGEST_RECENT_MOVEMENT } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useProjectMovementQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  LARGEST_MOVEMENT_TOOLTIP,
  PROJECT_MOVEMENT_HEIGHT,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectMovement from './ExecutiveDashboardProjectMovement';

const ExecutiveDashboardProjectMovementData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  onHover,
  projectMap,
}) => {
  const { data: { projectMovements } = { projectMovements: null }, loading } =
    useProjectMovementQuery(projectIDs);
  return (
    <div onMouseOver={onHover} onFocus={onHover}>
      <ExecutiveDashboardChartFrame
        component={
          loading || dashboardLoading ? (
            <ExecutiveDashboardLoadingState />
          ) : (
            <ExecutiveDashboardProjectMovement
              projectMovements={projectMovements}
              projectMap={projectMap}
            />
          )
        }
        height={PROJECT_MOVEMENT_HEIGHT}
        title={LARGEST_RECENT_MOVEMENT}
        variant={CHART_LARGE}
        tooltip={LARGEST_MOVEMENT_TOOLTIP}
      />
    </div>
  );
};

export default ExecutiveDashboardProjectMovementData;
