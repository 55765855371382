import * as types from '../actions/actionTypes';
import { EstimateType } from '../api/gqlEnums';
import { DateGroup, SourceSystem } from '../generated/graphql';
import { getItemIdFromUrl, getMilestoneIdFromUrl } from '../utilities/url';

type EventPropertyValue =
  | object
  | object[]
  | boolean
  | number
  | string
  | string[]
  | undefined
  | null;

export type EventProperties = {
  [key: string]: EventPropertyValue;
};

export type AnalyticsEvent = {
  type: string;
  eventProperties?: EventProperties;
};

export type SendAnalyticsFn = (event: AnalyticsEvent) => void;

// GENERIC ANALYTICS EVENT WITHOUT PROPERTIES
export const analyticsEvent = (type: string) => ({
  type,
  eventProperties: {},
});

// ASSETS
// TODO: same as generic
export const downloadAsset = (type: string) => ({
  type,
  eventProperties: {},
});

export const initUploadAnalytics = (file: string, origin: string, organization?: string) => ({
  type: types.INIT_UPLOAD,
  eventProperties: {
    file,
    origin,
    organization,
  },
});

export const getItemAssetAnalytics = (type: string, itemID?: UUID, assetID?: UUID) => ({
  type,
  eventProperties: {
    itemID,
    assetID,
  },
});

export const getMilestoneAssetAnalytics = (type: string, milestoneID: UUID, assetID?: UUID) => ({
  type,
  eventProperties: {
    milestoneID,
    assetID,
  },
});

export const getAssetAnalytics = (type: string, assetID?: UUID) => ({
  type,
  eventProperties: {
    assetID,
  },
});

export const getEntityDragDropDialogAnalytics = (path?: string) => {
  const itemID = getItemIdFromUrl(path);
  const milestoneID = getMilestoneIdFromUrl(path);
  if (itemID) {
    return getItemAssetAnalytics(types.DRAG_DROP_ASSET_ITEM_DIALOG, itemID);
  }
  return getMilestoneAssetAnalytics(types.DRAG_DROP_ASSET_MILESTONE_DIALOG, milestoneID);
};

export const itemAssetViewerOpenCloseAnalytics = (
  itemID: UUID,
  assetID: UUID | undefined,
  isOpen: boolean
) => ({
  type: isOpen ? types.ITEM_ASSET_VIEWER_OPEN : types.ITEM_ASSET_VIEWER_CLOSE,
  eventProperties: {
    itemID,
    assetID,
  },
});

export const milestoneAssetViewerOpenCloseAnalytics = (
  milestoneID: UUID,
  assetID: UUID,
  isOpen: boolean
) => ({
  type: isOpen ? types.MILESTONE_ASSET_VIEWER_OPEN : types.MILESTONE_ASSET_VIEWER_CLOSE,
  eventProperties: {
    milestoneID,
    assetID,
  },
});

export const assetOpenNewTabAnalytics = (assetID: UUID, assetName: string, assetType?: string) => ({
  type: types.OPEN_ASSET_NEW_TAB,
  eventProperties: {
    assetID,
    assetName,
    assetType,
  },
});

export const assetDownoadAnalytics = (assetID: UUID, assetName: string, assetType?: string) => ({
  type: types.OPEN_ASSET_NEW_TAB,
  eventProperties: {
    assetID,
    assetName,
    assetType,
  },
});

// CATEGORIZATIONS

export const editCategorizationSchemeAnalytics = (categorization: string) => ({
  type: types.EDIT_CATEGORIZATION_SCHEME_NAME,
  eventProperties: {
    categorization,
  },
});

export const removeCategorizationSchemeAnalytics = (categorization: string) => ({
  type: types.REMOVE_CATEGORIZATION_SCHEME,
  eventProperties: {
    categorization,
  },
});

export const disableProjectCategorizationAnalytics = (
  categorizationID: UUID,
  categorizationName: string,
  disabled: boolean
) => ({
  type: types.SETTINGS_SET_DISABLED_CATEGORIZATION,
  eventProperties: {
    categorizationID,
    categorizationName,
    disabled,
  },
});

export const setEditingCategorizationAnalytics = (id: UUID, location: string) => ({
  type: types.SET_EDITING_CATEGORIZATION,
  eventProperties: {
    id,
    location,
  },
});

export const selectItemColumnCategorizationAnalytics = (id: UUID) => ({
  type: types.SELECT_ITEM_COLUMN_CATEGORIZATION,
  eventProperties: {
    id,
  },
});

export const selectMilestoneColumnCategorizationAnalytics = (id: UUID) => ({
  type: types.SELECT_MILESTONE_COLUMN_CATEGORIZATION,
  eventProperties: {
    id,
  },
});

export const createCategorizationSchemeAnalytics = (error: UUID | string) => ({
  type: types.CREATE_CATEGORIZATION_SCHEME,
  eventProperties: {
    error, // Populated if the user gets a same-name error
  },
});

// CATEGORY SELECT

export const addItemCategoryCellAnalytics = (props: CategorySelectAnalytics) => ({
  type: types.ADD_CATEGORY_ANALYTICS,
  eventProperties: {
    ...props,
  },
});

export const removeItemCategoryCellAnalytics = (props: CategorySelectAnalytics) => ({
  type: types.REMOVE_CATEGORY_ANALYTICS,
  eventProperties: {
    ...props,
  },
});

// ITEMS
export const itemAssistantCTAAnalytics = (props: {
  type: 'CTA' | 'Dismiss' | 'Submit';
  itemID: UUID;
}) => {
  let t = types.ITEM_ASSISTANT_CTA;
  if (props.type === 'Dismiss') {
    t = types.ITEM_ASSISTANT_DISMISS;
  } else if (props.type === 'Submit') {
    t = types.ITEM_ASSISTANT_SUBMIT;
  }
  return {
    type: t,
    eventProperties: {
      itemID: props.itemID,
    },
  };
};

export const updateItemNameAnalytics = (props: {
  itemID: UUID;
  newName: string;
  itemType: string;
  location: string;
}) => ({
  type: types.UPDATE_ITEM_NAME,
  eventProperties: {
    ...props,
  },
});

export const updateItemDescriptionAnalytics = (props: {
  itemID: UUID;
  newDescription: string;
  itemType: string;
  location: string;
}) => ({
  type: types.UPDATE_ITEM_DESCRIPTION,
  eventProperties: {
    ...props,
  },
});
export const updateItemStatusAnalytics = (props: {
  itemID: UUID;
  oldStatus: string;
  newStatus: string;
}) => ({
  type: types.ITEMS_STATUS_CHANGE,
  eventProperties: {
    ...props,
  },
});

export const createItemAnalytics = (props: {
  assigneeEmail?: string;
  bucketID?: UUID;
  costImpact?: USCents;
  description?: string;
  dueDate?: Time;
  milestoneID?: UUID;
  name?: string;
  numCategories: number;
}) => ({
  type: types.CREATE_ITEM,
  eventProperties: {
    ...props,
  },
});

export const createOptionAnalytics = (props: { howCreated: string }) => ({
  type: types.CREATE_OPTION,
  eventProperties: {
    ...props,
  },
});

export const updateProjectSettingsAnalytics = (key: string, value: string) => {
  const type = key === 'CURRENCY' ? types.SETTINGS_CURRENCY : types.SETTINGS_ROUNDING;
  const props: { currency?: string; rounding?: string } =
    key === 'CURRENCY' ? { currency: value } : { rounding: value };
  return {
    type,
    eventProperties: {
      ...props,
    },
  };
};

export const optionsDialogTabAnalytics = (props: { itemId: string; whichTab: string }) => ({
  type: types.OPTIONS_DIALOG_TAB,
  eventProperties: {
    ...props,
  },
});

export const addItemCategoryAnalytics = (
  itemID: string,
  category: Category,
  itemType: string,
  location: string
) => ({
  type: types.ADD_CATEGORY,
  eventProperties: {
    itemID,
    itemType,
    categoryID: category.id,
    categoryName: category.name,
    categoryNumber: category.number,
    categorizationID: category.categorization ? category.categorization.id : undefined,
    location,
  },
});

export const removeItemCategoryAnalytics = (
  itemID: string,
  category: Category,
  itemType: string,
  location: string
) => ({
  type: types.REMOVE_CATEGORY,
  eventProperties: {
    itemID,
    itemType,
    categoryID: category.id,
    categoryName: category.name,
    categoryNumber: category.number,
    categorizationID: category.categorization ? category.categorization.id : undefined,
    location,
  },
});

export const openItemMilestoneTransitionDialog = (props: {
  id: string;
  milestoneId?: string;
  isOpen: string;
}) => ({
  type: types.DIALOG_ITEM_MILESTONE_TRANSITION_CTA,
  eventProperties: {
    ...props,
  },
});

export const updateItemMilestoneAnalytics = (props: {
  id: string;
  itemType: string;
  milestoneId: string;
  status: Status;
  location: string;
}) => ({
  type: types.UPDATE_ITEM_MILESTONE_ANALYTICS,
  eventProperties: {
    ...props,
  },
});

export const removeItemMilestoneAnalytics = (props: {
  itemId: string;
  milestoneId: string;
  location: string;
}) => ({
  type: types.REMOVE_MILESTONE_HISTORY,
  eventProperties: {
    ...props,
  },
});

export const updateItemVisibilityAnalytics = (props: { id: string; visibility: Visibility }) => ({
  type: types.UPDATE_ITEM_VISIBILITY,
  eventProperties: {
    ...props,
  },
});

export const updateItemAssigneeAnalytics = (props: {
  id: string;
  assigneeEmail: string | undefined;
  itemType: string;
  location: string;
}) => ({
  type: types.UPDATE_ITEM_ASSIGNEE,
  eventProperties: {
    ...props,
  },
});

export const updateItemDueDateAnalytics = (props: {
  id: string;
  dueDate: string;
  itemType: string;
  location: string;
}) => ({
  type: types.UPDATE_ITEM_DUE_DATE,
  eventProperties: {
    ...props,
  },
});

export const detachOptionAnalytics = (props: { itemId: string; optionId: string }) => ({
  type: types.DETACH_OPTION,
  eventProperties: {
    ...props,
  },
});

export const addItemCommentAnalytics = (props: { itemId: string; comment: string }) => ({
  type: types.ADD_COMMENT,
  eventProperties: {
    ...props,
  },
});

export const addCollaboratorTradeAnalytics = (collaboratorID: string, categories: string[]) => ({
  type: types.ADD_COLLABORATOR_TRADES,
  eventProperties: {
    collaboratorID,
    categories,
  },
});

export const removeCollaboratorAnalytics = (
  collaborator: Collaborator,
  user: User | UserProfile
) => ({
  type: types.DELETE_COLLABORATOR,
  eventProperties: {
    collaboratorID: collaborator.id,
    collaboratorEmail: collaborator.user.email,
    removerEmail: user.email,
  },
});

export const removeCollaboratorTradeAnalytics = (collaboratorID: string, categories: string[]) => ({
  type: types.REMOVE_COLLABORATOR_TRADES,
  eventProperties: {
    collaboratorID,
    categories,
  },
});

export const navigateItemsAnalytics = (event: string) => ({
  type: types.REPORT_ITEM_NAVIGATION,
  eventProperties: {
    event,
  },
});

// COLLABORATOR

export const updateCollaboratorRole = (roleID: string) => ({
  type: types.UPDATE_COLLABORATOR_ROLE,
  eventProperties: {
    roleID,
  },
});

export const updateCollaboratorResponsibility = (responsibility: string) => ({
  type: types.UPDATE_COLLABORATOR_RESPONSIBILITY,
  eventProperties: {
    responsibility,
  },
});

export const addCollaboratorAnalytics = (number: number, role: string) => ({
  type: types.CREATE_COLLABORATOR,
  eventProperties: {
    number,
    role,
  },
});

// ESTIMATE HEADER

export const cellErrorSelectedCTA = (direction: string) => ({
  type: types.CELL_ERROR_SELECTED_CTA,
  eventProperties: {
    direction,
  },
});

// GRID

export const gridAnalytics = (type: string, props: EventProperties) => ({
  type,
  eventProperties: {
    ...props,
  },
});

// IMPORT ESTIMATE

export const estimateImportUploadedCTA = (success: boolean, timeToUploadMins: number) => ({
  type: types.ESTIMATE_IMPORT_UPLOADED_CTA,
  eventProperties: {
    success,
    timeToUploadMins,
  },
});

export const publishEstimateCTA = (
  estimateId: UUID,
  estimateType: EstimateType,
  isRunningTotalEstimate: boolean
) => ({
  type: types.PUBLISH_ESTIMATE_CTA,
  eventProperties: {
    estimateId,
    estimateType,
    isRunningTotalEstimate,
  },
});

export const deleteEstimateCTA = (estimateId: UUID) => ({
  type: types.DELETE_ESTIMATE_CTA,
  eventProperties: {
    estimateId,
  },
});

export const importEstimateNavigationLink = (
  estimateType: EstimateType | undefined,
  estimateId: UUID
) => ({
  type: types.IMPORT_ESTIMATE_NAVIGATION_LINK,
  eventProperties: {
    estimateId,
    estimateType,
  },
});

export const importEstimatesSelectFile = (
  estimateType: EstimateType | undefined,
  source: FileSource
) => ({
  type: types.IMPORT_ESTIMATE_SELECT_FILE_CTA,
  eventProperties: { estimateType, source },
});

export const importEstimatesSelectFileInvalid = (
  estimateType: EstimateType | undefined,
  source: FileSource,
  extension: string | undefined
) => ({
  type: types.IMPORT_ESTIMATE_SELECT_FILE_INVALID,
  eventProperties: { estimateType, source, extension },
});

export const importEstimatesManual = (
  estimateType: EstimateType | undefined,
  estimateId: UUID
) => ({
  type: types.IMPORT_ESTIMATE_MANUAL_CTA,
  eventProperties: { estimateId, estimateType },
});

export const importEstimatesSelectWBS = (
  categorizationName: string,
  levelBuiltIn: string,
  levelName: string,
  levelNumber: number
) => ({
  type: types.ONBOARDING_STEP1_SELECT_WBS,
  eventProperties: {
    categorizationName,
    levelBuiltIn,
    levelName,
    levelNumber,
  },
});

export const importEstimatesSelectCategory = (
  importName: string,
  existName: string | undefined
) => ({
  type: types.ONBOARDING_STEP3_SELECT_CATEGORY,
  eventProperties: {
    importName,
    existName,
  },
});

export const importEstimatesReorderInclude = (isSelected: boolean, categorizationName: string) => ({
  type: isSelected ? types.ONBOARDING_STEP2_INCLUDE : types.ONBOARDING_STEP2_EXCLUDE,
  eventProperties: {
    categorizationName,
  },
});

export const createItemContingencyDraw = (itemID: string, drawFromID?: UUID) => ({
  type: types.CREATE_CONTINTENCY,
  eventProperties: {
    itemID,
    drawFromID,
  },
});

export const updateItemContingencyDraw = (
  markupID: string,
  value: number,
  isFullAmount: boolean,
  drawFromID?: UUID
) => ({
  type: types.UPDATE_CONTINTENCY,
  eventProperties: {
    markupID,
    value,
    isFullAmount,
    drawFromID,
  },
});

export const removeItemContingencyDraw = (markupID: string) => ({
  type: types.REMOVE_CONTINTENCY,
  eventProperties: {
    markupID,
  },
});

export const finalizeImportEstimateAnalytics = (
  estimateType: EstimateType,
  numberOfLines: number
) => ({
  type: types.FINALIZE_IMPORT_ESTIMATE,
  eventProperties: {
    estimateType,
    numberOfLines,
  },
});

export const replaceCategoryFromErrorAnalytics = (estimateType: EstimateType) => ({
  type: types.REPLACE_CATEGORY_FROM_ERROR_CTA,
  eventProperties: {
    estimateType,
  },
});

export const addAllNewCategoriesFromErrorAnalytics = (estimateType: EstimateType) => ({
  type: types.ADD_ALL_NEW_CATEGORIES_FROM_ERROR_CTA,
  eventProperties: {
    estimateType,
  },
});

// ITEMS LIST

export const printItemsListAnalytics = (milestone: string, settings: EventProperties) => ({
  type: types.PRINT_ITEMS_LIST,
  eventProperties: {
    milestone,
    ...settings,
  },
});

export const printItemsListAndDetailsAnalytics = (
  milestone: string,
  settings: EventProperties
) => ({
  type: types.PRINT_ITEMS_LIST_AND_DETAILS,
  eventProperties: {
    milestone,
    ...settings,
  },
});

export const exportItemsListAnalytics = (milestone: string, settings: EventProperties) => ({
  type: types.EXPORT_ITEMS,
  eventProperties: {
    milestone,
    ...settings,
  },
});

export const setCollapseAnalytics = (collapse: boolean, IDs: string[], node: string) => ({
  type: types.COLLAPSE_NODE,
  eventProperties: {
    collapse,
    IDs,
    node,
  },
});

export const reportItemsList = (
  setting: string[] | string | undefined,
  trigger: string,
  settings?: EventProperties
) => ({
  type: types.REPORT_ITEMS_LIST,
  eventProperties: {
    setting,
    trigger,
    ...settings,
  },
});

export const costReportExpandCollapse = (collapse: boolean) => ({
  type: types.MILESTONE_COSTREPORT_EXPAND_COLLAPSE,
  eventProperties: {
    collapse,
  },
});

export const costReportSettingsAnalytics = (type: string, props: EventProperties) => ({
  type,
  eventProperties: {
    ...props,
  },
});

// ITEMS LIST BULK EDIT

export const reportItemsListEditItemsCTA = (itemsCount: number) => ({
  type: types.IMPORT_ESTIMATE_DOWNLOAD_CTA,
  eventProperties: {
    itemsCount,
  },
});

export const reportItemsListBulkEditSubmit = (itemsCount: number, changesApplied: string[]) => ({
  type: types.ITEMS_BULK_EDIT,
  eventProperties: {
    itemsCount,
    changesApplied,
  },
});

export const filterEvent = (page: string, categoryList: string[]) => ({
  type: types.CATEGORY_FILTER_APPLIED,
  eventProperties: {
    page,
    categoryList,
  },
});

// ITEM

export const searchOptionsDialogAnalytics = (itemId: UUID) => ({
  type: types.SEARCH_OPTIONS_DIALOG,
  eventProperties: {
    itemId,
  },
});

export const deprecateItemAnalytics = (itemID: UUID) => ({
  type: types.DEPRECATE_ITEM,
  eventProperties: {
    itemID,
  },
});

// MILESTONE

export const setActiveMilestoneAnalytics = (milestoneID: string) => ({
  type: types.SET_ACTIVE_MILESTONE,
  eventProperties: {
    milestoneID,
  },
});

export const createOrUpdateBucketAnalytics = (bucketID: string, milestoneID: string) => ({
  type: types.CREATE_OR_UPDATE_BUCKET,
  eventProperties: {
    bucketID,
    milestoneID,
  },
});

export const exportEstimateAnalytics = (payload: EventPropertyValue) => ({
  type: types.EXPORT_MILESTONE_ESTIMATE,
  eventProperties: {
    payload,
  },
});

export const exportAllContingenciesAnalytics = () => ({
  type: types.EXPORT_ALL_MILESTONES_CONTINGENCY_REPORT,
  eventProperties: {},
});
export const exportActiveContingenciesAnalytics = () => ({
  type: types.EXPORT_ACTIVE_MILESTONE_CONTINGENCY_REPORT,
  eventProperties: {},
});

export const setItemEstimateExpanded = (estimateExpanded: boolean) => ({
  type: types.SET_ITEM_ESTIMATE_EXPANDED,
  eventProperties: {
    estimateExpanded,
  },
});

export const setContingencyExpanded = (contingencyExpanded: boolean) => ({
  type: types.SET_ITEM_CONTINGENCY_EXPANDED,
  eventProperties: {
    contingencyExpanded,
  },
});

export const setItemScheduleImpactExpanded = (scheduleImpactExpanded: boolean) => ({
  type: types.SET_ITEM_SCHEDULE_IMPACT_EXPANDED,
  eventProperties: {
    scheduleImpactExpanded,
  },
});

export const setItemAssetsExpanded = (assetsExpanded: boolean) => ({
  type: types.SET_ITEM_ASSETS_EXPANDED,
  eventProperties: {
    assetsExpanded,
  },
});

// METRICS

export const createQuantity = (
  quantityID: string,
  magnitude: string,
  unit: string,
  milestoneID: string
) => ({
  type: types.CREATE_QUANTITY,
  eventProperties: {
    quantityID,
    magnitude,
    unit,
    milestoneID,
  },
});

export const addQuantityBreakdownAnalytics = (
  quantityID: string,
  categorizations: string[],
  unit: string,
  milestoneID: string
) => ({
  type: types.ADD_QUANTITY_BREAKDOWN,
  eventProperties: {
    quantityID,
    categorizations,
    unit,
    milestoneID,
  },
});

export const removeQuantityBreakdownAnalytics = (
  quantityID: string,
  unit: string,
  milestoneID: string
) => ({
  type: types.REMOVE_QUANTITY_BREAKDOWN,
  eventProperties: {
    quantityID,
    unit,
    milestoneID,
  },
});

export const autoPopulateQuantityRowsAnalytics = (
  quantityID?: string,
  unit?: string,
  milestoneID?: string
) => ({
  type: types.AUTO_POPULATE_QUANTITY_ROWS,
  eventProperties: {
    quantityID,
    unit,
    milestoneID,
  },
});

export const costReportMetricColumnsAnalytics = (selectedColumns: string[]) => ({
  type: types.COSTREPORT_METRIC_COLUMNS,
  eventProperties: {
    selectedColumns,
  },
});

// TODO: Our analytics call always adds projectId ... we can remove this duplicate prop from all the below calls

export const createUpdateCustomUnit = (projectId: string, unit: string) => ({
  type: types.CREATE_OR_UPDATE_UNIT,
  eventProperties: {
    projectId,
    unit,
  },
});

export const toggleUnits = (projectId: string, enabled: string[], disabled: string[]) => ({
  type: types.TOGGLE_UNIT,
  eventProperties: {
    projectId,
    enabled,
    disabled,
  },
});

export const removeUnit = (projectId: string, unit: string) => ({
  type: types.REMOVE_UNIT,
  eventProperties: {
    projectId,
    unit,
  },
});

// PERMISSIONS

export const setEditRolePermissionsAnalytics = (edit: boolean, role?: string) => ({
  type: types.SET_EDIT_ROLE,
  eventProperties: {
    edit,
    role,
  },
});

// PROJECT

export const createProjectAnalytics = (
  name: string,
  projectId: string,
  currency: string,
  milestoneDesignPhaseName: string,
  projectDeliveryTypeName: string
) => ({
  type: types.CREATE_PROJECT,
  eventProperties: {
    name,
    projectId,
    currency,
    milestoneDesignPhaseName,
    projectDeliveryTypeName,
  },
});

export const setProjectsListFilterAnalytics = (type: string) => ({
  type: types.SET_PROJECTS_PAGE_FILTERS,
  eventProperties: {
    type,
  },
});

export const setProjectStatsFilterAnalytics = (type: string) => ({
  type: CompanyAdminEventType.PROJECT_STATS_FILTER,
  eventProperties: {
    type,
  },
});

export const updateProjectStatusAnalytics = (
  oldStatus?: string | null,
  newStatus?: string | null
) => {
  return {
    type: types.UPDATE_PROJECT_STATUS,
    eventProperties: {
      oldStatus,
      newStatus,
    },
  };
};

export const updateProjectDeliveryTypeAnalytics = (
  oldDeliveryType: string,
  newDeliveryType: string
) => {
  return {
    type: types.UPDATE_PROJECT_DELIVERY_TYPE,
    eventProperties: {
      oldDeliveryType,
      newDeliveryType,
    },
  };
};

export const updateProjectNameAnalytics = (oldName: string, newName: string) => {
  return {
    type: types.UPDATE_PROJECT_NAME,
    eventProperties: {
      oldName,
      newName,
    },
  };
};

export const updateProjectDescriptionAnalytics = (
  oldDescription: string,
  newDescription: string
) => {
  return {
    type: types.UPDATE_PROJECT_DESCRIPTION,
    eventProperties: {
      oldDescription,
      newDescription,
    },
  };
};

export const updateProjectShortNameAnalytics = (
  oldName?: string | null,
  newName?: string | null
) => {
  return {
    type: types.UPDATE_PROJECT_SHORT_NAME,
    eventProperties: {
      oldName,
      newName,
    },
  };
};

// VIEW TYPE

export const setViewTypeAnalytics = (type: string, viewType: string) => ({
  type,
  eventProperties: {
    viewType,
  },
});

// COST MODE

export const setCostModeAnalytics = (costMode: string) => ({
  type: types.SET_COST_MODE,
  eventProperties: {
    costMode,
  },
});

// ITEM DETAILS

export const updateItemNumberProps = (
  id: string,
  number: string,
  itemType: string,
  location: string
) => ({
  type: types.UPDATE_ITEM_NUMBER,
  eventProperties: {
    id,
    number,
    itemType,
    location,
  },
});

export const printItemDetail = (itemId: string) => ({
  type: types.PRINT_ITEM_DETAIL,
  eventProperties: {
    itemId,
  },
});

// DASHBOARD / CHARTS
export const reportChart = (chart: string, mouseEvent: string) => ({
  type: types.REPORT_CHART,
  eventProperties: {
    chart,
    mouseEvent,
  },
});

export const reportCostTrend = (settings: string, trigger: string) => ({
  type: types.SET_COST_TREND,
  eventProperties: {
    settings,
    trigger,
  },
});

export const setItemChartViewBy = (viewBy: string) => ({
  type: types.SET_ITEM_CHART_VIEW_BY,
  eventProperties: {
    viewBy,
  },
});

export const setEstimateChartViewBy = (viewBy: string) => ({
  type: types.SET_ESTIMATE_CHART_VIEW_BY,
  eventProperties: {
    viewBy,
  },
});

export const setMilestoneEstimateExpanded = (expanded: boolean) => ({
  type: types.SET_MILESTONE_ESTIMATE_EXPANDED,
  eventProperties: {
    expanded,
  },
});

export const setMilestoneBudgetExpanded = (expanded: boolean) => ({
  type: types.SET_MILESTONE_BUDGET_EXPANDED,
  eventProperties: {
    expanded,
  },
});

export const setItemsListNewItemDialogOpen = (open: boolean) => ({
  type: types.SET_ITEMS_LIST_NEW_ITEM_DIALOG_OPEN,
  eventProperties: {
    open,
  },
});

export const itemNewWindowOpen = (itemId: string) => ({
  type: types.OPEN_ITEM_NEW_WINDOW,
  eventProperties: {
    itemId,
  },
});

// TEAM
export const teamViewList = (view: string) => ({
  type: types.TEAM_VIEW_LIST,
  eventProperties: {
    view,
  },
});

export const teamSetCompany = (role: string) => ({
  type: types.TEAM_SET_COMPANY_ROLE,
  eventProperties: {
    role,
  },
});

// SUPPORT MENU
export const openSupportMenu = () => ({
  type: types.OPEN_SUPPORT_MENU,
  eventProperties: {},
});

export const visitWhatsNew = () => ({
  type: types.VISIT_WHATS_NEW,
  eventProperties: {},
});

export const visitSuccess = () => ({
  type: types.VISIT_SUCCESS,
  eventProperties: {},
});

export const emailSupport = () => ({
  type: types.EMAIL_SUPPORT,
  eventProperties: {},
});

export const viewKeyboardShortcuts = () => ({
  type: types.VIEW_KEYBOARD_SHORTCUTS,
  eventProperties: {},
});

// PRINT
export const reportPrint = (location: string) => ({
  type: types.REPORT_PRINT,
  eventProperties: {
    location,
  },
});

export const itemSidebarMenuOpen = (itemId: string) => ({
  type: itemSidebarEventTypes.MENU_CTA,
  eventProperties: {
    itemId,
  },
});

export const itemSidebarMenuOpenFullscreen = (itemId: string) => ({
  type: itemSidebarEventTypes.FULLSCREEN_CTA,
  eventProperties: {
    itemId,
  },
});

export const itemSidebarOpen = (itemId: string) => ({
  type: itemSidebarEventTypes.ITEM_SIDEBAR_OPEN,
  eventProperties: {
    itemId,
  },
});

export const itemSidebarMenuClose = (itemId: string) => ({
  type: itemSidebarEventTypes.CLOSE_CTA,
  eventProperties: {
    itemId,
  },
});

export const itemSidebarMenuOpenPrint = (itemId: string) => ({
  type: itemSidebarEventTypes.PRINT_CTA,
  eventProperties: {
    itemId,
  },
});

export const itemSidebarNavigation = (itemId: string, direction: string) => ({
  type: itemSidebarEventTypes.NAVIGATION_CTA,
  eventProperties: {
    itemId,
    direction,
  },
});

export const openItemSidebarExpandableHeaders = (itemId: string, header: string) => ({
  type: itemSidebarEventTypes.EXPANDABLE_HEADERS,
  eventProperties: {
    itemId,
    header,
  },
});

// PREVIEW
export enum previewEventTypes {
  PREVIEW_MENU_CTA = 'preview_menuCTA',
  PREVIEW_ROLES_CTA = 'preview_rolesCTA',
  PREVIEW_SELECT_PREVIEW_TYPE = 'preview_selectPreviewType',
  PREVIEW_SELECT_TRADES = 'preview_selectTrades',
  PREVIEW_SELECT_ROLE_TYPE = 'preview_selectRoleType',
  PREVIEW_SELECT_USER = 'preview_selectUser',
  PREVIEW_ADD_COLLABORATORS_CTA = 'preview_addCollaboratorsCTA',
  PREVIEW_EXIT_CTA = 'preview_exitCTA',
}

export enum previewTypes {
  USER = 'USER',
  ROLE = 'ROLE',
}

export const previewPermissionsAnalytics = (
  type: previewEventTypes,
  previewType: previewTypes,
  roleName: string
) => ({
  type,
  eventProperties: {
    previewType,
    roleName,
  },
});

// BANNER / PRINT HEADER

export const settingsPrintHeaderSelector = (type: string, id?: UUID, kind?: string) => ({
  type,
  eventProperties: {
    id,
    bannerKind: kind,
  },
});

export enum varianceEventTypes {
  VARIANCE_MENU_CTA = 'variance_menuCTA',
  VARIANCE_SELECT_MILESTONE_REPORT = 'variance_selectMilestoneReport',
  VARIANCE_SELECT_UNITS = 'variance_selectUnits',
  VARIANCE_SELECT_SETTINGS = 'variance_selectSettings',
  VARIANCE_ESTIMATE_LINES = 'variance_estimateLines',
  VARIANCE_FINISH_MENU = 'variance_finishMenu',
  VARIANCE_EDIT_MENU = 'variance_editMenu',
  VARIANCE_CANCEL_MENU = 'variance_cancelMenu',
  VARIANCE_VISIT_MENU = 'variance_visitMenu',
  VARIANCE_PRINT = 'variance_print',
  VARIANCE_SWAP_COLUMNS = 'variance_swapColumns',
  VARIANCE_EXPORT_REPORT = 'variance_exportReport',
}

// variance
export const reportVariance = (type: varianceEventTypes, eventProperties?: EventProperties) => ({
  type,
  eventProperties,
});

export enum transitionEventTypes {
  TRANSITION_MENU_CTA = 'transition_menuCTA',
  TRANSITION_FINISH_MENU = 'transition_finishMenu',
  TRANSITION_EDIT_MENU = 'transition_editMenu',
  TRANSITION_CANCEL_MENU = 'transition_cancelMenu',
  TRANSITION_VISIT_MENU = 'transition_visitMenu',
  TRANSITION_SELECT_MILESTONE = 'transition_selectMilestone',
}

// Milestone Transition
export const reportTransition = (
  type: transitionEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

export enum excelDownloadEventTypes {
  DOWNLOAD_JOIN_EXCEL_TEMPLATE = 'download_join_excel_template',
}

// Import Estimate Help Dialog Excel Downloads
export const reportExcelDownload = (
  type: excelDownloadEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

// format is feature_action
// userReportMenu describes the IconMenu embedded on the Dashboard, Breakdowns, Items List, MSR, and variance page
// userReportModal is the create/edit modal raised from the userReportMenu and the userReportTab
// userReportTab is the reports tab

type userReportEventTypes =
  | 'userReportMenu_open'
  | 'userReportMenu_switchReport'
  | 'userReportMenu_applySwitchReport'
  | 'userReportMenu_saveReport'
  | 'userReportModal_create'
  | 'userReportModal_edit'
  | 'userReportModal_delete'
  | 'userReportTab_copy'
  | 'userReportTab_xlsx'
  | 'userReportTab_go'
  | 'userReportTab_print'
  | 'userReportTab_edit'
  | 'userReportTab_delete'
  | 'userReportTab_distributeNow'
  | 'userReportTab_scheduleDistribution';

export const userReportEvent = (type: userReportEventTypes, eventProperties?: EventProperties) => ({
  type,
  eventProperties,
});

export enum reportDistributionEventTypes {
  REPORT_DISTRIBUTION_CREATE = 'userReportDistribution_create',
  REPORT_DISTRIBUTION_UPDATE = 'userReportDistribution_update',
  REPORT_DISTRIBUTION_DELETE = 'userReportDistribution_delete',
  REPORT_DISTRIBUTION_VIEW = 'userReportDistribution_view',
  REPORT_DISTRIBUTION_DOWNLOAD = 'userReportDistribution_download',
  REPORT_DISTRIBUTION_NEW_TAB = 'userReportDistribution_newTab',
  REPORT_DISTRIBUTION_UNSUBCRIBE = 'userReportDistribution_unsubscribe',
}

export const userReportDistributionEvent = (
  type: reportDistributionEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

// ToDO: Update user report comment analytics types per design

type userReportCommentEventTypes =
  | 'userReportComment_create'
  | 'userReportComment_edit'
  | 'userReportComment_delete';

export const userReportCommentEvent = (
  type: userReportCommentEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

export enum execDashboardEventTypes {
  EXEC_DASHBOARD_HOVER = 'execDashboard_hover',
  EXEC_DASHBOARD_PROJECTS_DROPDOWN_VIEW = 'execDashboard_projectsDropdownView',
  EXEC_DASHBOARD_TAB_VIEW = 'execDashboard_tabView',
  EXEC_DASHBOARD_CHART_VIEW = 'execDashboard_chartView',
}

// Executive dashboard
export const execDashboardEvent = (
  type: execDashboardEventTypes,
  eventProperties: EventProperties
) => ({
  type,
  eventProperties,
});

// Page views
export enum PageViews {
  PROJECT_LIST = 'projectList_view',
  DASHBOARD = 'projectDashboard_view',
  MILESTONES = 'milestones_view',
  MSR = 'msr_view',
  VARIANCE_REPORT = 'varianceReport_view',
  MILESTONE_DETAILS = 'milestoneDetails_view',
  ITEM_LIST = 'itemsList_view',
  ITEM_DETAILS = 'itemDetails_view',
  ITEM_ACTIVITY = 'itemActivity_view',
  SETTINGS = 'projectSettings_view',
  TEAM = 'team_view',
  EXECUTIVE_DASHBOARD = 'executiveDashboard_view',
  TEAM_ROLES = 'teamRoles_view',
  REPORTS = 'reports_view',
  FORECASTING_HOME = 'forecasting_homeView',
  FORECASTING_REPORTS = 'forecasting_reportsView',
  FORECASTING_EXPLORE = 'forecasting_exploreView',
  FORECASTING_PROJECT_COMPARISON = 'forecasting_projectComparisonView',
}

export enum ViewTypeAnalytics {
  PROJECTS_SET_VIEW = 'projects_setViewType',
  ITEMS_SET_VIEW = 'items_setViewType',
}

// Item Sidebar
export enum ItemSidebarLocation {
  MILESTONE_SUMMARY_REPORT = 'milestoneSummaryReport',
  ITEM_DETAILS_PAGE = 'itemDetails',
  ITEMS_LIST = 'itemsList',
  FORECASTING = 'forecasting',
  HOME = 'home',
  SCENARIOS = 'scenarios',
}

enum itemSidebarEventTypes {
  MENU_CTA = 'itemSidebar_menuCTA',
  FULLSCREEN_CTA = 'itemSidebar_menuFullscreenCTA',
  PRINT_CTA = 'itemSidebar_menuPrintCTA',
  CLOSE_CTA = 'itemSidebar_menuCloseCTA',
  NAVIGATION_CTA = 'itemSidebar_navigationCTA',
  EXPANDABLE_HEADERS = 'itemSidebar_expandableHeadersCTA',
  ITEM_SIDEBAR_OPEN = 'itemSidebar_openCTA',
}

// ITEM DETAILS
export enum ItemEvents {
  ITEM_ESTIMATE_FULLSCREEN_EDIT = 'itemEstimate_fullscreenEdit',
  ITEM_ESTIMATE_FULLSCREEN_CLOSE = 'itemEstimate_fullscreenClose',
}

// PROJECT COMPS
export enum addToMilestoneEventTypes {
  ADD_TO_MILESTONE_MENU_CTA = 'projectComps_addToMilestone_menuCTA',
  ADD_TO_MILESTONE_FINISH_MENU = 'projectComps_addToMilestone_finishMenu',
  ADD_TO_MILESTONE_CANCEL_MENU = 'projectComps_addToMilestone_cancelMenu',
  ADD_TO_MILESTONE_VISIT_MENU = 'projectComps_addToMilestone_visitMenu',
  ADD_TO_MILESTONE_SELECT_MILESTONE = 'projectComps_addToMilestone_selectMilestone',
  ADD_TO_MILESTONE_REPLACE_ESTIMATE = 'projectComps_addToMilestone_replaceEstimate',
  ADD_TO_MILESTONE_PUBLISH_ESTIMATE = 'projectComps_addToMilestone_publishEstimate',
  ADD_TO_MILESTONE_CREATE_DRAFT = 'projectComps_addToMilestone_createDraft',
  ADD_TO_MILESTONE_CREATE_PUBLISH = 'projectComps_addToMilestone_createAndPublish',
}
// add to milestone
export const reportAddToMilestone = (
  type: addToMilestoneEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

// TODO: Replace the above with this upon flag removal of DD_CREATE_ESTIMATE
export enum CreateEstimateFromProjectCompsEventTypes {
  BUTTON_CTA = 'projectComps_createEstimate_menuCTA',
  FINISH_MENU = 'projectComps_createEstimate_finishMenu',
  CANCEL_MENU = 'projectComps_createEstimate_cancelMenu',
  // POSSIBLE OUTCOMES
  REPLACE_ESTIMATE = 'projectComps_createEstimate_existingMilestone_replace',
  CREATE_ESTIMATE = 'projectComps_createEstimate_existingMilestone_createNew',
  CREATE_DRAFT = 'projectComps_createEstimate_createNew_Draft',
  CREATE_PUBLISH = 'projectComps_createEstimate_createNew_Publish',
}
export const createEstimateFromProjectCompsEvent = (
  type: CreateEstimateFromProjectCompsEventTypes,
  eventProperties: EventProperties = {}
) => ({
  type,
  eventProperties,
});

export enum projectCompsEventTypes {
  PROJECT_COMPS_ADD_DESCRIPTION = 'projectComps_AddDescription',
  PROJECT_COMPS_ADDED_MARKUP_LINE = 'projectComps_AddedMarkupLine',
  PROJECT_COMPS_ADD_MARKUP_LINE_CTA = 'projectComps_AddMarkupLineCTA',
  PROJECT_COMPS_ADD_PROJECT_CTA = 'projectComps_AddProjectCTA',
  PROJECT_COMPS_AVERAGE_CELL_EDIT_DESCRIPTION_CTA = 'projectComps_averageCellEditDescriptionCTA',
  PROJECT_COMPS_AVERAGE_CELL_MENU_CTA = 'projectComps_averageCellMenuCTA',
  PROJECT_COMPS_AVERAGE_CELL_RESET_COST_CTA = 'projectComps_averageCellResetCostCTA',
  PROJECT_COMPS_CREATE_AVERAGE_CTA = 'projectComps_createAvgCTA',
  PROJECT_COMPS_CUSTOMIZE_MENU_CLOSE = 'projectComps_customizeMenu_Close',
  PROJECT_COMPS_CUSTOMIZE_MENU_CTA = 'projectComps_customizeMenuCTA',
  PROJECT_COMPS_CUSTOMIZE_MENU_DONE_CTA = 'projectComps_customizeMenu_DoneCTA',
  PROJECT_COMPS_DISPLAY_SETTINGS_CATEGORIZATION = 'projectComps_displaySettings_Categorization',
  PROJECT_COMPS_DISPLAY_SETTINGS_COSTS = 'projectComps_displaySettings_Costs',
  PROJECT_COMPS_DISPLAY_SETTINGS_UNIT = 'projectComps_displaySettings_Unit',
  PROJECT_COMPS_EDIT_AVERAGE_COST = 'projectComps_editAverageCost',
  PROJECT_COMPS_EDIT_AVERAGE_COST_DESCRIPTION = 'projectComps_editAverageCostDescription',
  PROJECT_COMPS_EDIT_AVERAGE_LOCATION = 'projectComps_editAverageLocation',
  PROJECT_COMPS_EDIT_AVERAGE_NAME = 'projectComps_editAverageName',
  PROJECT_COMPS_EDIT_PROJECT_COMP_COST = 'projectComps_editProjectCompCost',
  PROJECT_COMPS_EDIT_PROJECT_COMP_COST_DESCRIPTION = 'projectComps_editProjectCompCostDescription',
  PROJECT_COMPS_EDIT_PROJECT_NAME = 'projectComps_editProjectName',
  PROJECT_COMPS_ESCALATION_CHANGE_DATE = 'projectComps_escalationChangeDate',
  PROJECT_COMPS_ESCALATION_CHANGE_SOURCE_LOCATION = 'projectComps_escalationChangeSourceLocation',
  PROJECT_COMPS_ESCALATION_MULTIPLE_SET = 'projectComps_escalationMultipleSet',
  PROJECT_COMPS_ESCALATION_SET = 'projectComps_escalationSet',
  PROJECT_COMPS_EXCLUDE_LINE_CTA = 'projectComps_excludeLineCTA',
  PROJECT_COMPS_EXPORT = 'projectComps_exportExcel',
  PROJECT_COMPS_HIDDEN_COSTS_DIALOG_CTA = 'projectComps_hiddenCostsDialogCTA',
  PROJECT_COMPS_MILESTONE_LINK_CTA = 'projectComps_milestoneLinkCTA',
  PROJECT_COMPS_MIN_MAX_CTA = 'projectComps_minMaxCTA',
  PROJECT_COMPS_OPEN_EDIT_COST_GRIDS_DIALOG = 'projectComps_openEditCostGridsDialog',
  PROJECT_COMPS_OPEN_HIDDEN_COSTS_DIALOG = 'projectComps_openHiddenCostsDialog',
  PROJECT_COMPS_PROJECT_COMP_CELL_MENU_CTA = 'projectComps_projectCompCellMenuCTA',
  PROJECT_COMPS_PROJECT_COMP_RESET_COST_CTA = 'projectComps_costReset',
  PROJECT_COMPS_RENAMED_MARKUP_LINE = 'projectComps_RenamedMarkupLine',
  PROJECT_COMPS_RESET_DATA_CTA = 'projectComps_resetDataCTA',
  PROJECT_COMPS_RESET_VIEW_FILTER = 'projectComps_ResetViewFilter',
  PROJECT_COMPS_SAVE_REPORT_CTA = 'projectComps_saveReportCTA',
  PROJECT_COMPS_SELECT_MILESTONE = 'projectComps_selectMilestone',
  PROJECT_COMPS_SELECT_PROJECTS_MODAL_CANCEL = 'projectComps_SelectProjectsModal_Cancel',
  PROJECT_COMPS_SELECT_PROJECTS_MODAL_CTA = 'projectComps_SelectProjectsModalCTA',
  PROJECT_COMPS_SELECT_PROJECTS_MODAL_VIEW = 'projectComps_SelectProjectsModalView',
  PROJECT_COMPS_SET_COST_TABLE_COLUMN_INPUTS = 'projectComps_SetCostTableColumnInputs',
  PROJECT_COMPS_SET_VIEW_FILTER = 'projectComps_SetViewFilter',
  PROJECT_COMPS_START_AVERAGE_CTA = 'projectComps_StartAverageCTA',
  PROJECT_COMPS_TOGGLE_AVERAGE_IS_HIDDEN = 'projectComps_toggleAverageIsHidden',
  PROJECT_COMPS_TOGGLE_PROJECT_IS_EXCLUDED = 'projectComps_toggleProjectIsExcluded',
  PROJECT_COMPS_CHARTS_TOGGLE = 'projectComps_toggleCharts',
  PROJECT_COMPS_CHARTS_CHANGE_TYPE = 'projectComps_changeChartType',
  PROJECT_COMPS_CHARTS_CHANGE_UNIT = 'projectComps_changeChartUnit',
  PROJECT_COMPS_CHARTS_FILTER = 'projectComps_filterChart',
}

export const projectCompsAnalyticsEvent = (
  type: projectCompsEventTypes,
  eventProperties: EventProperties = {}
) => ({
  type,
  eventProperties,
});

export enum forecastingEventTypes {
  ADD_REPORT_TO_PROJECT_DIALOG_CTA = 'addReportToProjectDialogCTA',
  ADD_REPORT_TO_PROJECT_DIALOG_VIEW = 'addReportToProjectDialogView',
  CREATE_REPORT_CTA = 'createReportCTA',
  DELETE_REPORT_DIALOG_CTA = 'deleteReportDialogCTA',
  DELETE_REPORT_DIALOG_VIEW = 'deleteReportDialogView',
  EDIT_REPORT_DIALOG_CTA = 'editReportDialogCTA',
  EDIT_REPORT_DIALOG_VIEW = 'editReportDialogView',
  EXPAND_CTA = 'expandCTA',
  EXPLORE_CTA = 'exploreCTA',
  EXPLORE_MODE_CTA = 'exploreModeCTA',
  ITEM_DETAIL_CTA = 'itemDetailCTA',
  MENU = 'menu',
  REPORTS_CTA = 'reportsCTA',
  SEARCH = 'search',
  SELECT_CTA = 'selectCTA',
  SELECT_FILTERS = 'selectFilters',
  SORT = 'sort',
  VIEW_PROJECT_CTA = 'viewProjectCTA',
  VIEW_REPORT_CTA = 'viewReportCTA',
}

export const forecastingAnalyticsEvent = (
  type: forecastingEventTypes,
  eventProperties: EventProperties = {}
) => ({
  type: `forecasting_${type}`,
  eventProperties,
});

// NAV
export enum navEventTypes {
  SEARCH_MODE_CHANGED = 'searchModeChanged',
  SEARCH_TEXT_CHANGED = 'searchTextChanged',
  SEARCH_CLOSED = 'searchClosed',
  SEARCH_OPENED = 'searchOpened',
  SEARCH_VIEW_ALL = 'searchViewAll',
  SEARCH_VIEW_ITEM = 'searchViewItem',
  SEARCH_VIEW_PROJECT = 'searchViewProject',
}

export const navAnalyticsEvent = (type: navEventTypes, eventProperties: EventProperties = {}) => ({
  type: `nav_${type}`,
  eventProperties,
});

// SEARCH

export enum searchEventTypes {
  SEARCH_SELECT = 'search_select',
  SEARCH_ALL_CTA = 'search_allCTA',
  SEARCH_SELECT_ITEM = 'search_selectItem',
  SEARCH_SELECT_PROJECT = 'search_selectProject',
  SEARCH_ITEM_DETAILS = 'search_itemDetails',
  SEARCH_TEXT_ENTERED = 'search_textEntered',
  SEARCH_MODE_CTA = 'search_modeCTA',
  SEARCH_FILTERS = 'search_filters',
  SEARCH_SORT = 'search_sort',
  SEARCH_TOGGLE = 'search_toggle',
  SEARCH_ITEM_DETAIL_CTA = 'search_itemDetailCTA',
}

export const searchAnalyticsEvent = (
  type: searchEventTypes,
  eventProperties: EventProperties = {}
) => ({
  type,
  eventProperties,
});

// copy item anlytics

export enum copyItemEventTypes {
  OPEN_DIALOG_CTA = 'openDialogCTA',
  CLOSE_DIALOG_CTA = 'closeDialogCTA',
  PROJECT_SELECTION_CTA = 'projectSelectionCTA',
  GENERAL_INFO_CTA = 'generalInfoCTA',
  ITEM_CATEGORIES_CTA = 'itemCategoriesCTA',
  CATEGORIZATION_MAPPINGS_CTA = 'categorizationMappingsCTA',
  ITEM_ASSETS_CTA = 'itemAssetsCTA',
  COMPLETE_DIALOG_CTA = 'completeDialogCTA',
}

export const copyItemAnalyticsEvent = (
  type: copyItemEventTypes,
  eventProperties: EventProperties = {}
) => ({
  type: `copyItem_${type}`,
  eventProperties,
});

// IN-APP NOTIFICATIONS

export enum inAppNotificationsEventTypes {
  IN_APP_NOTIFICATIONS_OPEN_CLICK = 'notifications_openClick',
  IN_APP_NOTIFICATIONS_THIS_PROJECT_CLICK = 'notifications_thisProjectClick',
  IN_APP_NOTIFICATIONS_ALL_PROJECT_CLICK = 'notifications_allProjectsClick',
  IN_APP_NOTIFICATIONS_TOGGLE_VIEW = 'notifications_toggleView',
  IN_APP_NOTIFICATIONS_MARK_ALL_READ_CLICK = 'notifications_markAllReadClick',
  IN_APP_NOTIFICATIONS_LINK_VIEW_CLICK = 'notifications_linkViewClick',
  IN_APP_NOTIFICATIONS_MARK_READ_CLICK = 'notifications_markReadClick',
  IN_APP_NOTIFICATIONS_NEXT = 'notifications_next',
  IN_APP_NOTIFICATIONS_PREVIOUS = 'notifications_previous',
}

export const inAppNotificationsAnalyticsEvent = (
  type: inAppNotificationsEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

// SETTINGS - NOTIFICATIONS

export enum settingNotificationEventTypes {
  SETTINGS_NOTIFICATIONS_TOGGLE = 'settings_notificationsAllToggle',
  SETTINGS_NOTIFICATIONS_CHANGE = 'settings_notificationsChange',
  SETTINGS_NOTIFICATIONS_CTA = 'settings_notificationsCTA',
}

export const settingsNotificationsAnalyticsEvent = (
  type: settingNotificationEventTypes,
  eventProperties?: EventProperties
) => ({
  type,
  eventProperties,
});

// COMPANY ADMIN

export enum CompanyAdminEventType {
  ADD_PROJECT_ADMIN = 'addProjectAdmin',
  COLLABORATORS_CTA = 'collaboratorsCTA',
  COLLABORATORS_BREADCRUMB_CLICK = 'collaboratorsBreadcrumbClick',
  COLLABORATORS_FILTER = 'collaboratorsFilter',
  COLLABORATORS_SEARCH = 'collaboratorsSearch',
  COLLABORATORS_SORT = 'collaboratorsSort',
  COLLABORATORS_PROJECT_LINK = 'collaboratorProjectLinkClick',
  MEMBER_NEW_CTA = 'memberNewCTA',
  MEMBER_FILTER = 'memberFilter',
  MEMBER_SEARCH = 'memberSearch',
  MEMBER_SORT = 'memberSort',
  MEMBER_NAME_CHANGE = 'memberNameChange',
  MEMBER_STATUS_CHANGE = 'memberStatusChange',
  MEMBER_RESEND_INVITE_CTA = 'memberResendInviteCTA',
  MEMBER_PROJECT_LINK = 'memberProjectLinkClick',
  NEW_MEMBER_ADD_PROJECT_PERMISSION = 'newMember_addProjectPermission',
  NEW_MEMBER_DELETE_PROJECT_PERMISSION = 'newMember_deleteProjectPermission',
  NEW_MEMBER_CLOSE = 'newMember_close',
  NEW_MEMBER_CANCEL = 'newMember_cancel',
  NEW_MEMBER_VALIDATE_ERROR = 'newMember_validateError',
  NEW_MEMBER_ADD_CTA = 'newMember_addCTA',
  MEMBER_JOB_TITLE_CHANGE = 'memberJobTitleChange',
  MEMBER_COMPANY_ROLE_CHANGE = 'memberCompanyRoleChange',
  MEMBER_COMPANY_ROLE_MODAL = 'memberCompanyRoleModal',
  MEMBER_VIEW_CLICK = 'memberViewClick',
  MEMBERS_BREADCRUMB_CLICK = 'membersBreadcrumbClick',
  MEMBERS_CTA = 'membersCTA',
  SETTINGS_CTA = 'settingsCTA',
  PROJECT_STATS_CTA = 'projectStatsCTA',
  PROJECT_STATS_FILTER_CLICK = 'projectStatsFilterClick',
  PROJECT_STATS_FILTER = 'projectStatsFilter',
  PROJECT_STATS_REMOVE_FILTER = 'projectStatsRemoveFilter',
  PROJECT_STATS_CLEAR_FILTER = 'projectStatsFilterClear',
  PROJECT_STATS_SEARCH = 'projectStatsSearch',
  PROJECT_STATS_SORT = 'projectStatsSort',
  PROJECT_STATS_PROJECT_LINK = 'projectStatsProjectLinkClick',
  PROJECT_STATS_CREATOR_LINK = 'projectStatsCreatorLinkClick',
  SETTINGS_NAME_CHANGE = 'settingsNameChange',
  SETTINGS_TYPE_CHANGE = 'settingsTypeChange',
  SETTINGS_LOGO_CHANGE = 'settingsLogoChange',
  // Standards
  STANDARDS_CTA = 'standardsCTA',
  // Categorizations
  CATEGORIZATIONS_CTA = 'categorizationsCTA',
  STANDARD_CATEGORIZATION_SEARCH = 'standardCategorizationSearch',
  STANDARD_CATEGORIZATION_EDIT_CTA = 'standardCategorizationEditCTA',
  STANDARD_CATEGORIZATION_DELETE_CTA = 'standardCategorizationDeleteCTA',
  STANDARD_CATEGORIZATION_CREATE_CTA = 'standardCategorizationCreateCTA',
  STANDARD_CATEGORIZATION_HELP_CTA = 'standardCategorizationHelpCTA',
  STANDARD_CATEGORIZATION_DELETE = 'standardCategorizationDelete',
  STANDARD_CATEGORIZATION_DELETE_CANCEL = 'standardCategorizationDeleteCancel',
  STANDARD_CATEGORIZATION_DELETE_CLOSE = 'standardCategorizationDeleteClose',
  // Project templates
  PROJECT_TEMPLATES_CTA = 'projectTemplatesCTA',
  PROJECT_TEMPLATES_NEW = 'standardProjTemplatesNew',
  PROJECT_TEMPLATES_CLICK = 'standardProjTemplatesClick',
  PROJECT_TEMPLATES_PUBLISH = 'standardProjTemplatesPublishCTA',
  PROJECT_TEMPLATES_UNPUBLISH = 'standardProjTemplatesUnpublishCTA',
  PROJECT_TEMPLATES_EDIT_CTA = 'standardProjTemplatesEditCTA',
  PROJECT_TEMPLATES_DUPLICATE_CTA = 'standardProjTemplatesDuplicateCTA',
  PROJECT_TEMPLATES_DELETE_CTA = 'standardProjTemplatesDeleteCTA',
  PROJECT_TEMPLATES_CREATE_DRAFT = 'standardProjTemplatesCreateDraft',
  PROJECT_TEMPLATES_CREATE_CANCEL = 'standardProjTemplatesCreateCancel',
  PROJECT_TEMPLATES_CREATE_CLOSE = 'standardProjTemplatesCreateClose',
  PROJECT_TEMPLATES_DUPLICATE_DRAFT = 'standardProjTemplatesDuplicateDraft',
  PROJECT_TEMPLATES_DUPLICATE_CANCEL = 'standardProjTemplatesDuplicateCancel',
  PROJECT_TEMPLATES_DUPLICATE_CLOSE = 'standardProjTemplatesDuplicateClose',
  PROJECT_TEMPLATES_DELETE = 'standardProjTemplatesDelete',
  PROJECT_TEMPLATES_DELETE_CANCEL = 'standardProjTemplatesDeleteCancel',
  PROJECT_TEMPLATES_DELETE_CLOSE = 'standardProjTemplatesDeleteClose',
  PROJECT_TEMPLATES_EDIT_NAME = 'standardProjTemplatesEditName',
  PROJECT_TEMPLATES_EDIT_PUBLISH_DRAFT = 'standardProjTemplatesEditPublishDraft',
  PROJECT_TEMPLATES_EDIT_DONE = 'standardProjTemplatesEditDone',
  PROJECT_TEMPLATES_HELP_CTA = 'projectTemplatesHelpCTA',
  // Project types
  PROJECT_TYPES_CTA = 'projectTypesCTA',
  PROJECT_TYPES_EXPAND_COLLAPSE = 'projectTypesExpandCollapse',
  PROJECT_TYPES_ACTION = 'projectTypesAction',
  PROJECT_TYPES_CREATE_CTA = 'projectTypesCreateCTA',
  PROJECT_TYPES_CREATE_CLOSE_CANCEL = 'projectTypesCreateCloseCancel',
  PROJECT_TYPES_CREATE_CREATE = 'projectTypesCreateCreate',
  PROJECT_TYPES_CREATE_ERROR = 'projectTypesCreateError',
  PROJECT_TYPES_ADD_CLOSE_CANCEL = 'projectTypesAddCloseCancel',
  PROJECT_TYPES_ADD_CREATE = 'projectTypesAddCreate',
  PROJECT_TYPES_ADD_ERROR = 'projectTypesAddError',
  PROJECT_TYPES_EDIT_TYPE_CLOSE_CANCEL = 'projectTypesEditTypeCloseCancel',
  PROJECT_TYPES_EDIT_TYPE_SAVE = 'projectTypesEditTypeSave',
  PROJECT_TYPES_EDIT_TYPE_ERROR = 'projectTypesEditTypeError',
  PROJECT_TYPES_EDIT_SUBTYPE_CLOSE_CANCEL = 'projectTypesEditSubtypeCloseCancel',
  PROJECT_TYPES_EDIT_SUBTYPE_SAVE = 'projectTypesEditSubtypeSave',
  PROJECT_TYPES_EDIT_SUBTYPE_ERROR = 'projectTypesEditSubtypeError',
  PROJECT_TYPES_DELETE_CLOSE_CANCEL = 'projectTypesDeleteCloseCancel',
  PROJECT_TYPES_DELETE_CONFIRM = 'projectTypesDeleteConfirm',
  PROJECT_TYPES_DELETE_OK = 'projectTypesDeleteOK',
  PROJECT_TYPES_DELETE_STATS_LINK = 'projectTypesDeleteStatsLink',
}

export enum CompanyAdminView {
  MEMBER_DETAILS = 'memberDetails',
  MEMBER_SIDEBAR = 'memberSidebar',
  MEMBERS_LIST = 'membersList',
  COLLABORATORS_DETAILS = 'collaboratorDetails',
  COLLABORATORS_LIST = 'collaboratorsList',
  COLLABORATORS_SIDEBAR = 'collaboratorsSidebar',
  PROJECT_STATS_LIST = 'projectstatsList',
  STANDARD_CATEGORIZATIONS = 'standardCategorizations',
}

export const companyAdminAnalyticsEvent = (
  type: CompanyAdminEventType,
  eventProperties: {
    view?: CompanyAdminView;
    filter?: string;
    sort?: string;
    search?: string;
    status?: string;
    projectID?: string;
    targetID?: string;
    userRole?: string;
    jobTitle?: string;
    companyID?: string;
    parentStart?: string;
    parentEnd?: string;
    typeName?: string;
    typeLevel?: string;
    action?: string;
    existingConflict?: boolean;
  } = {}
) => ({
  type: `companyAdmin_${type}`,
  eventProperties,
});

// User Source Systems
export enum UserSourceSystemEventType {
  REMOVE = 'fileExplorer_removeUserPartnerAccount',
  ADD = 'fileExplorer_addUserPartnerAccount',
  RELOGIN = 'fileExplorer_reloginUserPartnerAccount',
}

export const getUserSourceSystemAnalyticsEvent = (
  type: UserSourceSystemEventType,
  sourceSystem: SourceSystem
) => ({
  type,
  eventProperties: {
    sourceSystem,
  },
});

export enum CategorizationEvent {
  MANAGE_CTA = 'ManageCTA',
  EMPTY_MANAGE_CTA = 'EmptyManageCTA',
  VIEW_CTA = 'ViewCTA',
  EDIT_CTA = 'EditCTA',
  DELETE_CTA = 'DeleteCTA',
  REORDER = 'Reorder',
  STANDARD_TOOLTIP = 'StandardTooltip',
  MULTILEVEL_TOOLTIP = 'MultilevelTooltip',
  BUILTINS_ENABLE = 'BuiltinsEnable',
  BUILTINS_DISABLE = 'BuiltinsDisable',
  BUILTINS_DISABLE_CANCEL = 'BuiltinsDisableCancel',
  BUILTINS_DISABLE_CLOSE = 'BuiltinsDisableClose',
  DELETE = 'Delete',
  DELETE_CANCEL = 'DeleteCancel',
  DELETE_CLOSE = 'DeleteClose',

  MANAGE_CLOSE = 'ManageClose',
  MANAGE_STANDARD_TOOLTIP = 'ManageStandardTooltip',
  MANAGE_MULTILEVEL_TOOLTIP = 'ManageMultilevelTooltip',
  MANAGE_REMOVED_TOOLTIP = 'ManageRemovedTooltip',
  MANAGE_DUPLICATE_TOOLTIP = 'ManageDuplicateTooltip',
  MANAGE_STANDARD_VIEW_CTA = 'ManageStandardViewCTA',
  MANAGE_CREATE_NEW_CTA = 'ManageCreateNewCTA',
  MANAGE_CONFIRM_CTA = 'ManageConfirmCTA',
  MANAGE_SELECT_ALL = 'ManageSelectAll',
  MANAGE_UNSELECT_ALL = 'ManageUnselectAll',

  // Viewing standard categorizations to add.
  MANAGE_STANDARD_ML_VIEW_EXPAND_COLLAPSE = 'ManageStandardMLViewExpandCollapse',
  MANAGE_STANDARD_ML_VIEW_SELECT = 'ManageStandardMLViewSelect',
  MANAGE_STANDARD_ML_VIEW_UNSELECT = 'ManageStandardMLViewUnselect',
  MANAGE_STANDARD_ML_VIEW_BACK = 'ManageStandardMLViewBack',
  MANAGE_STANDARD_SL_VIEW_SELECT = 'ManageStandardSLViewSelect',
  MANAGE_STANDARD_SL_VIEW_UNSELECT = 'ManageStandardSLViewUnselect',
  MANAGE_STANDARD_SL_VIEW_BACK = 'ManageStandardSLViewBack',

  MANAGE_CONFIRM_DELETE_CONFIRM = 'ManageConfirmDeleteConfirm',
  MANAGE_CONFIRM_DELETE_CANCEL = 'ManageConfirmDeleteCancel',

  CREATE_NEW_CLOSE = 'CreateNewClose',
  CREATE_NEW_LOADING_CANCEL = 'CreateNewLoadingCancel',
  CREATE_NEW_IMPORT_FILE = 'CreateNewImportFile',
  CREATE_NEW_MANUAL_CTA = 'CreateNewManualCTA',

  CREATE_NEW_TOOLTIP = 'CreateNewTooltip',
  CREATE_NEW_DOWNLOAD_TEMPLATE = 'CreateNewDownloadTemplate',
  CREATE_NEW_HELP_CLICK = 'CreateNewHelpClick',

  CREATE_NEW_ML_EXPAND_COLLAPSE = 'CreateNewMLExpandCollapse',
  CREATE_NEW_ML_CLOSE = 'CreateNewMLClose',
  CREATE_NEW_ML_CREATE = 'CreateNewMLCreate',
  CREATE_NEW_ML_REPLACE = 'CreateNewMLReplace',

  CREATE_NEW_SL_CLOSE = 'CreateNewSLClose',
  CREATE_NEW_SL_CREATE = 'CreateNewSLCreate',
  CREATE_NEW_SL_REPLACE = 'CreateNewSLReplace',
  CREATE_NEW_SL_ADD_ROW = 'CreateNewSLAddRow',
  CREATE_NEW_SL_DELETE_ROW = 'CreateNewSLDeleteRow',
  CREATE_NEW_IMPORT_BACK_CTA = 'CreateNewImportBackCTA',

  EDIT_ML_EXPAND_COLLAPSE = 'EditMLExpandCollapse',
  EDIT_ML_CLOSE = 'EditMLClose',
  EDIT_ML_SAVE = 'EditMLSave',
  EDIT_ML_REPLACE = 'EditMLReplace',
  EDIT_ML_UNDO = 'EditMLUndo',
  EDIT_ML_DOWNLOAD = 'EditMLDownload',

  EDIT_SL_CLOSE = 'EditSLClose',
  EDIT_SL_REPLACE = 'EditSLReplace',
  EDIT_SL_ADD_ROW = 'EditSLAddRow',
  EDIT_SL_DELETE_ROW = 'EditSLDeleteRow',
  EDIT_SL_UNDO = 'EditSLUndo',
  EDIT_SL_DOWNLOAD = 'EditSLDownload',

  // Viewing standard categorizations directly.
  VIEW_ML_EXPAND_COLLAPSE = 'ViewMLExpandCollapse',
  VIEW_ML_CLOSE = 'ViewMLClose',
  VIEW_ML_DONE = 'ViewMLDone',
  VIEW_ML_DOWNLOAD = 'ViewMLDownload',
  VIEW_SL_CLOSE = 'ViewSLClose',
  VIEW_SL_DONE = 'ViewSLDone',
  VIEW_SL_DOWNLOAD = 'ViewSLDownload',
}

export const categorizationEvent = (
  type: CategorizationEvent,
  eventProperties: {
    projectID?: string;
    companyID?: string;
    categorizationID?: string;
    type?: string;
    source?: string;
  } = {}
) => ({
  type: `project_settingsCategorization${type}`,
  eventProperties,
});

export enum ItemShareKey {
  ITEM = 'item_sharing',
  SIDEBAR = 'item_sidebar',
  ITEMS = 'itemsList_itemShare',
  BULK = 'itemsList_bulkSharing',
  DRAFT = 'item_draft',
}

export enum ItemShareEvent {
  SHARE_CLICK = 'ShareClick',
  FILTER = 'Filter',
  CLOSE = 'Close',
  ADD = 'Add',
  REMOVE = 'Remove',
  CTA = 'CTA',
  PUBLISH_CTA = 'PublishCTA',
  SHARE_CTA = 'ShareCTA',
  VIEW_OPEN_CLOSE = 'ViewOpenClose',
  TOOLTIP = 'Tooltip',
  INDETERMINATE_TOOLTIP = 'IndeterminateTooltip',
}

export type ItemShareEventProp = {
  filterSearch?: string;
  filterRole?: string;
  filterCompany?: string;
  addCount?: number;
  removeCount?: number;
  type?: string;
};

export const itemShareEvent = (
  key: ItemShareKey,
  type: ItemShareEvent,
  eventProperties: ItemShareEventProp = {}
) => ({ type: `${key}_${type}`, eventProperties });

export enum ScenarioShareKey {
  SCENARIOS = 'scenarios_sharing',
  SIDEBAR = 'scenarios_sidebar',
}

export enum ScenarioShareEvent {
  SHARE_CLICK = 'ShareClick',
  FILTER = 'Filter',
  CLOSE = 'Close',
  ADD = 'Add',
  REMOVE = 'Remove',
  CTA = 'CTA',
  VIEW_OPEN_CLOSE = 'ViewOpenClose',
  TOOLTIP = 'Tooltip',
}

export type ScenarioShareEventProp = {
  filterSearch?: string;
  filterRole?: string;
  filterCompany?: string;
  addCount?: number;
  removeCount?: number;
  type?: string;
};

export const scenarioShareEvent = (
  key: ScenarioShareKey,
  type: ScenarioShareEvent,
  eventProperties: ScenarioShareEventProp = {}
) => ({ type: `${key}${type}`, eventProperties });

export enum TimelineEvent {
  ACTIVITY_NEW_CTA = 'activityNewCTA',
  TIMELINE_FILTER_ACTIVITY = 'timelineFilterActivity',
  TIMELINE_FILTER_END_CHANGE = 'timelineFilterEndDateChange',
  TIMELINE_FILTER_START_CHANGE = 'timelineFilterStartDateChange',
  TIMELINE_EXPAND_COLLAPSE = 'timelineExpandCollapse',
  DUE_DATE_EXPAND_COLLAPSE = 'duedateExpandCollapse',
  // Chart
  TIMELINE_DRAG_ZOOM = 'timelineDragZoom',
  TIMELINE_DBL_CLICK_ZOOM = 'timelineDoubleClickZoom',
  TIMELINE_SUBCHART_ZOOM = 'timelineSubchartZoom',
  TIMELINE_RESET_ZOOM = 'timelineZoomReset',
  TIMELINE_COLLAPSE = 'timelineCollapse',
  TIMELINE_EXPAND = 'timelineExpand',
  DUE_DATE_DRAG_ZOOM = 'duedateDragZoom',
  DUE_DATE_TOOLTIP_CLICK = 'duedateTooltipClick',
  DUE_DATE_NO_DUE_DATE_CLICK = 'duedateNoDueDateClick',
  ACTIVITY_TOOLTIP_CLICK = 'activityTooltipClick',
  MILESTONE_TOOLTIP_CLICK = 'milestoneTooltipClick',
  // Table
  TABLE_COLLAPSE = 'tableCollapse',
  TABLE_EXPAND = 'tableExpand',
  TABLE_MILESTONE_CLICK = 'tableMilestoneClick',
  TABLE_ITEMS_CLICK = 'tableItemsClick',
  TABLE_MENU_CLICK = 'tableMenuClick',
  TABLE_EDIT_CLICK = 'tableEditClick',
  TABLE_ZOOM_CLICK = 'tableZoomClick',
  TABLE_DELETE_CLICK = 'tableDeleteClick',
}

export const timelineEvent = (
  type: TimelineEvent,
  eventProperties: {
    source?: string;
    filters?: string[];
    collapse?: boolean;
    type?: string;
    dateRange?: string;
  } = {}
) => ({ type: `timeline_${type}`, eventProperties });

export enum NewProjectEvent {
  CTA = 'newProjectCTA',
  CREATE_CTA = 'newProject_CreateCTA',
}

export const newProjectEvent = (
  type: NewProjectEvent,
  eventProperties: {
    location?: string;
    milestoneDesignPhase?: string;
    milestoneName?: string;
    milestoneStartDate?: string;
    projectDeliveryMethod?: string;
    projectStatus?: string;
    projectType?: string;
  }
) => ({
  type,
  eventProperties,
});

export enum NewItemEvent {
  CREATE_ANOTHER = 'itemCreateAnother',
}

export const newItemEvent = (
  type: NewItemEvent,
  eventProperties: { anotherItem?: string } = {}
) => ({
  type: `newItem_${type}`,
  eventProperties,
});

export enum ItemEvent {
  CHANGE_HISTORY_FILTER = 'changeHistoryFilter',
  SCHEDULE_IMPACT_EDIT_TYPE = 'scheduleImpactEditType',
  SCHEDULE_IMPACT_EDIT_CRITICAL_PATH = 'scheduleImpactEditCriticalPath',
  SCHEDULE_IMPACT_EDIT_DAYS = 'scheduleImpactEditDays',
  EVENT_SELECT = 'eventSelect',
}

export const itemEvent = (
  type: ItemEvent,
  eventProperties: { value?: string; filters?: string[] } = {}
) => ({
  type: `item_${type}`,
  eventProperties,
});

export const itemSidebarEvent = (type: ItemEvent, eventProperties: { value?: string } = {}) => ({
  type: `itemSidebar_${type}`,
  eventProperties,
});

// ITEMS LIST IMPORT
export enum ItemsListImportEvent {
  IMPORT_CTA = 'importCTA',
  IMPORT_DOWNLOAD_TEMPLATE = 'importDownloadTemplate',
  IMPORT_HELP = 'importHelp',
  IMPORT_FILE = 'importFile',
  IMPORT_LOADING_CANCEL = 'importLoadingCancel',
  IMPORT_SUCCESS_RESET = 'importSuccessReset',
  IMPORT_CANCEL = 'importCancel',
  IMPORT_IMPORT = 'importImport',
}

export const itemsListImportEvent = (
  type: ItemsListImportEvent,
  eventProperties: {
    itemsCount?: number;
    itemsUploadCount?: number;
    importState?: string;
    milestone?: string;
    collaboration?: string;
    source?: string;
  } = {}
) => ({
  type: `itemsListImport_${type}`,
  eventProperties,
});

// ITEMS LIST
export enum ItemsListEvent {
  ACTIVITY_FILTER = 'activityFilter',
}

export const itemsListEvent = (
  type: ItemsListEvent,
  eventProperties: {
    activityType?: string;
  } = {}
) => ({
  type: `itemsList_${type}`,
  eventProperties,
});

// ITEM ACTIVITY PAGE
export const itemActivityTimeSelector = (dateGroup: DateGroup) => ({
  type: 'itemActivity_timeSelector',
  eventProperties: {
    dateGroup,
  },
});

export enum SettingsEvent {
  PERMISSION_CHANGE = 'permissionChange',
}

export const settingsEvent = (
  type: SettingsEvent,
  eventProperties: {
    projectId?: string;
    role?: string;
    permission?: string;
    level?: string;
  } = {}
) => ({
  type: `settings_${type}`,
  eventProperties,
});

export enum MilestoneEvent {
  BASIC_START_DATE = 'basicsStartDate',
  BASIC_BELONGS_TO = 'basicsBelongTo',
}

export const milestoneEvent = (type: MilestoneEvent) => ({
  type: `milestone_${type}`,
  eventProperties: {},
});

export const createProcoreChangeEvent = (includeCosts: boolean, includeAttachments: boolean) => ({
  type: types.ITEM_ACTIVITY_CREATE_PROCORE_CHANGE_EVENT,
  eventProperties: { includeCosts, includeAttachments },
});
