import { Label } from './common/Label';
import RecentSearch from './RecentSearch';

interface RecentSearchesProps {
  onDelete: (search: string) => void;
  onNavigate: (search: string) => void;
  searches: string[];
}

const RecentSearches = (props: RecentSearchesProps) => (
  <>
    <Label>Recent searches</Label>
    {!props.searches.length && <div style={{ padding: 4 }}>No recent searches</div>}
    {props.searches
      .filter((_, index) => index < 5)
      .map((search: string) => (
        <RecentSearch
          key={search}
          onDelete={props.onDelete}
          onNavigate={props.onNavigate}
          search={search}
        />
      ))}
  </>
);
export default RecentSearches;
