import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { tableStyles } from '../../../theme/table-styles';

const CATEGORY_COLUMN_WIDTH = 275;

const styles = (theme: KomodoTheme) => {
  const { cell } = tableStyles(theme);
  return createStyles({
    avatar: {
      marginLeft: 8,
    },
    button: {
      cursor: 'pointer',
      '& > svg': {
        cursor: 'pointer',
      },
    },
    cell: {
      ...cell,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: 'none',
      wordBreak: 'break-all',
      padding: `${theme.spacing.generic[3]}px`,
      textAlign: 'left',
      '& > button': {
        background: 'none',
        border: 'none',
      },
    },
    categoryColumn: { minWidth: CATEGORY_COLUMN_WIDTH },
    cellTradeAndDelete: {
      ...theme.typography.chip,
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'space-between',
    },
    containerFilters: {
      width: '70%',
      '@media screen and (max-width: 900px)': {
        width: '100%',
      },
    },
    containerInputs: {
      alignItems: 'center',
      display: 'flex',
      '@media screen and (max-width: 900px)': {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    containerOuter: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderBottom: '0px',
      minWidth: 700, // setting a min width because the original design wasn't built for mobile
    },
    containerRow: {
      borderBottom: theme.border.line,
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 2fr 3fr 3fr 4fr', // THIS IS THE LAYOUT FOR THE ENTIRE COLLABORATORS TABLE
    },
    companyGridRow: {
      borderBottom: theme.border.line,
      display: 'grid',
      gridTemplateColumns: '2fr 3fr 3fr 2fr 3fr', // THIS IS THE LAYOUT FOR THE ENTIRE COMPANIES TABLE}
    },
    containerRowHeader: {
      borderBottom: theme.border.line,
      backgroundColor: theme.palette.ds.background.primary,
    },
    containerSearchBar: {
      width: '30%',
      paddingTop: 2,
      paddingBottom: 2,
      '@media screen and (max-width: 900px)': {
        paddingTop: theme.spacing.generic[3],
        width: '100%',
      },
    },
    containerTextarea: {
      display: 'inline-flex',
    },
    containerTop: {
      paddingLeft: theme.spacing.generic[11],
      paddingRight: theme.spacing.generic[11],
      paddingBottom: theme.spacing.generic[11],
    },
    copy: {
      flex: '0 1 auto',
      textOverflow: 'ellipses',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    clickable: {
      cursor: 'pointer',
    },
    headerCell: {
      ...theme.typography.webButton,
      gap: '4px',
      background: 'none',
      height: 40,
    },
    hoverTitle: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    icon: {
      fill: theme.palette.disabledGrey,
      width: 16,
      height: 16,
    },
    iconSortArrow: {
      fill: theme.palette.disabledGrey,
      position: 'relative',
      width: '16px',
    },
    iconSortArrowActive: {
      fill: theme.palette.primaryBlue,
      width: '16px',
    },
    iconSortArrowFlipped: {
      transform: 'rotate(180deg)',
      position: 'relative',
    },
    image: {
      maxHeight: 50,
      width: 144, // TODO: use enum here
      objectFit: 'contain',
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
    },
    notActiveState: {
      opacity: 0.5,
    },
    roleSelect: {
      width: 166,
    },
    text: { width: '100%' },
    textarea: {
      border: theme.border.line,
      borderRadius: '2px',
      padding: '9px',
      resize: 'none',
    },
  });
};

export default styles;
