import { FC, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Fullscreen, Help } from '@material-ui/icons';

import { itemEstimateDialogOpenVar, useTitleHeight } from '../../../api/apollo/reactiveVars';
import { CostMode } from '../../../api/gqlEnumsBe';
import {
  ACCEPTED,
  ATTACHMENTS,
  CATEGORIZATIONS,
  COMMENTS_AND_EDIT_HISTORY,
  CONTINGENCY,
  COST_IMPACT,
  ESTIMATE_SUMMARY,
  GENERAL_INFO,
  INTEGRATIONS,
  ITEM_WITH_OPTIONS,
  OPTIONS,
  SCHEDULE_IMPACT,
  SIDEBAR,
} from '../../../constants';
import { NS_PROCORE_CHANGE_EVENT_INTEGRATION } from '../../../features';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { mapCostModeEnumToString } from '../../../utilities/costMode';
import { getItemCost } from '../../../utilities/items';
import { constantCountLabel } from '../../../utilities/string';
import AssetsWrapper from '../../assets/AssetsWrapper/AssetsWrapper';
import AssetsWrapperItemData from '../../assets/AssetsWrapper/AssetsWrapperItemData';
import { useItemAttachments } from '../../assets/AssetsWrapper/AssetsWrapperUtils';
import CommentsHistory from '../../Comments/CommentsHistory/CommentsHistory';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';
import EstimateAccordionItemContent from '../../estimate/EstimateAccordion/EstimateAccordionItemContent';
import { useGetItemContingenciesQuery } from '../../Items/hooks/useGetItemContingencies';
import ContingencyContent from '../../Items/ItemsCollapse/ContingencyContent';
import IntegrationsDataWrapper from '../../Items/ItemsCollapse/Integrations/IntegrationsDataWrapper';
import ScheduleImpactWrapperItemData from '../../Items/ItemsCollapse/ScheduleImpactWrapper/ScheduleImpactWrapperItemData';
import {
  getContingencyDrawCosts,
  getContingencyEstimateCosts,
} from '../../Items/ItemsCollapse/utils';
import { isScenarioVisibility } from '../../Items/ItemsUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { useProjectSettings } from '../../ProjectDisplaySettings/hooks';
import { Button, Dialog, IconButton } from '../../scales';
import CostImpact from '../../shared-widgets/CostImpact';
import ScheduleImpact, {
  SCHEDULE_IMPACT_DEFAULT,
  ScheduleImpactVariant,
} from '../../shared-widgets/ScheduleImpact';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './ItemSidebarBodyStyles';
import ItemSidebarCollapseCategories from './ItemSidebarCollapseCategories';
import ItemSidebarCollapseEstimate from './ItemSidebarCollapseEstimate';
import ItemSidebarCollapseGeneral from './ItemSidebarCollapseGeneral';
import ItemSidebarCollapseHeader from './ItemSidebarCollapseHeader';
import ItemSidebarNoEstimate from './ItemSidebarNoEstimate';
import ItemSidebarOptions from './ItemSidebarOptions';
import { FOOTER_HEIGHT, HEADER_HEIGHT, SUMMARY_HEADER_HEIGHT } from './ItemSidebarStyles';
import { ItemPermissions, getContingencyTooltip, shouldItemAllowDraws } from './ItemSidebarUtils';

type ItemSidebarBodyProps = {
  classes: Classes<typeof styles>;
  costMode: string;
  expanded: string[];
  isProjectItemSidebar?: boolean;
  item: ItemLike;
  milestones: Milestone[];
  openSidebar: (id?: string | undefined) => void;
  permissions: ItemPermissions;
  projectId: UUID;
  onItemMutated: () => void;
  setExpanded: (name: string[]) => void;
  setOpenDialogAddOption: (b: boolean) => void;
};

const ItemSidebarBody: FC<ItemSidebarBodyProps> = ({
  classes,
  costMode,
  expanded,
  isProjectItemSidebar,
  item,
  milestones,
  openSidebar,
  permissions,
  projectId,
  onItemMutated,
  setExpanded,
  setOpenDialogAddOption,
}) => {
  const hasProcoreIntegrationAccess = useHasFeature(NS_PROCORE_CHANGE_EVENT_INTEGRATION);

  const isScenario = isScenarioVisibility(item.visibility);
  const settings = useProjectSettings(projectId);
  const userSources = useUserSourcesQuery().data?.userSources;

  const { id, itemType, activeEstimate, categories } = item;
  const hasOptions = itemType === ITEM_WITH_OPTIONS;
  const options = hasOptions ? (item as Item).options : [];
  const { itemAssets, loading } = useItemAttachments(id);

  const scheduleImpact = item?.scheduleImpact ?? SCHEDULE_IMPACT_DEFAULT;
  const [localImpact, setLocalImpact] = useState(scheduleImpact);
  const [previousID, setPreviousID] = useState(id);
  const itemEstimateDialogOpen = useReactiveVar(itemEstimateDialogOpenVar);
  if (previousID !== id) {
    setPreviousID(id);
    setLocalImpact(scheduleImpact);
  }
  const isScheduleImpactDisabled = settings.SCHEDULE_IMPACT_DISPLAY === 'DISABLED';
  const hideScheduleImpact =
    isScheduleImpactDisabled || hasOptions || !permissions.canViewScheduleImpact;

  // HELPER FUNCTIONS
  const isExpanded = (s: string | null) => {
    if (!s) return false;
    return expanded.includes(s);
  };

  // STYLES
  // max collapse height
  const titleHeight = useTitleHeight();
  const maxHeight =
    window.innerHeight - 5 * HEADER_HEIGHT - FOOTER_HEIGHT - SUMMARY_HEADER_HEIGHT - titleHeight;
  const minHeight = 250;

  // Cost container
  const cost = useMemoWrapper(getItemCost, item);

  const estimateHeaderContent = activeEstimate ? (
    <CostImpact value={item.estimateCost ?? { value: 0 }} compact={false} />
  ) : (
    <Typography className={classes.estimateNullTextHeader}>No estimate</Typography>
  );

  const scheduleHeaderContent = (
    <ScheduleImpact scheduleImpact={localImpact} variant={ScheduleImpactVariant.ITEM_COLLAPSE} />
  );

  // Contingency container
  const shouldSkipItemContingenciesQuery =
    // we hide the contingency draw section if this is an item with options, or if the user can't view markups
    itemType === ITEM_WITH_OPTIONS || !permissions.canViewMarkups;
  const contingencyDrawTotal: Cost = useMemoWrapper(getContingencyDrawCosts, item);
  const estimateCost: Cost = useMemoWrapper(getContingencyEstimateCosts, item);
  const { data, refetch: refetchItemContingenciesData } = useGetItemContingenciesQuery(
    projectId,
    item.id,
    shouldSkipItemContingenciesQuery
  );
  const itemContingenciesData = data?.getItemContingencies;
  const contingencyContent = contingencyDrawTotal ? (
    <CostImpact value={contingencyDrawTotal} />
  ) : (
    <Typography>N/A</Typography>
  );
  const { shouldAllowDraws, canAddDraws } = shouldItemAllowDraws(
    itemContingenciesData?.draws,
    itemContingenciesData?.availableMilestoneContingencies,
    estimateCost,
    item.status
  );
  const contingencyTooltipCopy = getContingencyTooltip(shouldAllowDraws, item.status);

  // Allows user to navigate to the fullscreen estimate page
  const canShowEstimate =
    permissions.canViewItemLines && item.activeEstimate && item.activeEstimate.lines.length > 0;

  const estimateIcon = canShowEstimate ? (
    <IconButton
      aria-label="View Estimate"
      data-cy="item-sidebar-fullscreen-estimate"
      icon={<Fullscreen />}
      onClick={() => itemEstimateDialogOpenVar(true)}
      type="secondary"
    />
  ) : null;

  const sidebarContent = (
    <>
      <ItemSidebarCollapseHeader
        isExpanded={isExpanded(GENERAL_INFO)}
        itemId={item.id}
        name={GENERAL_INFO}
        sectionID={GENERAL_INFO}
        setExpanded={setExpanded}
        expanded={expanded}
      />
      {isExpanded(GENERAL_INFO) && (
        <ItemSidebarCollapseGeneral
          item={item}
          key={item.id}
          minHeight={minHeight}
          permissions={permissions}
          projectId={projectId}
          projectMilestones={milestones}
          onItemMutated={onItemMutated}
        />
      )}
      {permissions.shouldDisplayCosts && itemType !== ITEM_WITH_OPTIONS && (
        <>
          <ItemSidebarCollapseHeader
            content={
              <div>
                <CostImpact value={cost} />
              </div>
            }
            disabled={!permissions.canViewItemLines}
            isExpanded={isExpanded(COST_IMPACT)}
            itemId={item.id}
            name={COST_IMPACT}
            sectionID={COST_IMPACT}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isExpanded(COST_IMPACT) && permissions.canViewItemLines && (
            <>
              <ItemSidebarCollapseHeader
                content={estimateHeaderContent}
                isLight
                disabled={!activeEstimate && !permissions.canEditItemLines}
                iconCTA={estimateIcon ?? undefined}
                isExpanded={isExpanded(ESTIMATE_SUMMARY)}
                itemId={item.id}
                name={ESTIMATE_SUMMARY}
                sectionID={ESTIMATE_SUMMARY}
                setExpanded={setExpanded}
                expanded={expanded}
              />
              <Dialog
                size="lg"
                title="Item Estimate"
                isOpen={itemEstimateDialogOpen}
                footerRight={
                  <Button
                    label="Close"
                    type="primary"
                    onClick={() => itemEstimateDialogOpenVar(false)}
                  />
                }
                onClose={() => itemEstimateDialogOpenVar(false)}
                isFullHeight
              >
                <EstimateAccordionItemContent
                  item={item}
                  estimateHandle="Item Estimate"
                  onItemMutated={onItemMutated}
                  projectID={projectId}
                />
              </Dialog>
            </>
          )}
          {isExpanded(ESTIMATE_SUMMARY) && permissions.canViewItemLines && (
            <>
              {activeEstimate ? (
                <ItemSidebarCollapseEstimate
                  activeEstimate={activeEstimate}
                  canViewMarkups={
                    permissions.canViewMarkups ||
                    (permissions.canViewEstimateCostSubtotals &&
                      costMode !== mapCostModeEnumToString(CostMode.NoMarkups))
                  }
                  maxHeight={maxHeight}
                  totalCost={cost}
                />
              ) : (
                <ItemSidebarNoEstimate
                  canEditEstimate={permissions.canEditItemLines}
                  itemID={item.id}
                  onItemMutated={onItemMutated}
                />
              )}
            </>
          )}
        </>
      )}
      {isExpanded(COST_IMPACT) &&
        itemType !== ITEM_WITH_OPTIONS &&
        activeEstimate &&
        permissions.canViewMarkups && (
          <>
            <ItemSidebarCollapseHeader
              content={contingencyContent}
              disabled={!canAddDraws}
              isExpanded={isExpanded(CONTINGENCY)}
              itemId={item.id}
              name={
                <div style={{ display: 'flex', fontWeight: 300 }}>
                  <div>Contingency &amp; Allowance Draws</div>
                  <NormalTooltip title={contingencyTooltipCopy}>
                    <Help className={classes.icon} />
                  </NormalTooltip>
                </div>
              }
              sectionID={CONTINGENCY}
              setExpanded={setExpanded}
              expanded={expanded}
            />
            {isExpanded(CONTINGENCY) && itemContingenciesData && (
              <div className={classes.collapseHeight} style={{ maxHeight }}>
                <ContingencyContent
                  hasGrayBackground
                  contingencyDrawTotal={contingencyDrawTotal}
                  itemContingenciesData={itemContingenciesData}
                  refetchItemContingenciesData={refetchItemContingenciesData}
                  onItemMutated={onItemMutated}
                  estimateID={activeEstimate.id}
                  itemID={item.id}
                  itemStatus={item.status}
                  isItemSidebar
                  projectID={projectId}
                  estimateCost={estimateCost}
                />
              </div>
            )}
          </>
        )}
      {itemType === ITEM_WITH_OPTIONS && (
        <>
          <ItemSidebarCollapseHeader
            disabled={options?.length === 0 && !isProjectItemSidebar}
            isExpanded={isExpanded(OPTIONS)}
            itemId={item.id}
            name={constantCountLabel(OPTIONS, options?.length ?? 0)}
            sectionID={OPTIONS}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isExpanded(OPTIONS) && (
            <div className={classes.collapseHeight} style={{ maxHeight }}>
              <ItemSidebarOptions
                shouldDisplayCosts={permissions.shouldDisplayCosts}
                isProjectItemSidebar={isProjectItemSidebar}
                openSidebar={openSidebar}
                options={options}
                setExpanded={setExpanded}
                setOpenDialogAddOption={setOpenDialogAddOption}
              />
            </div>
          )}
        </>
      )}
      {!hideScheduleImpact && (
        <>
          <ItemSidebarCollapseHeader
            content={scheduleHeaderContent}
            disabled={!permissions.canEditScheduleImpact}
            isExpanded={isExpanded(SCHEDULE_IMPACT)}
            itemId={item.id}
            name={SCHEDULE_IMPACT}
            sectionID={SCHEDULE_IMPACT}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isExpanded(SCHEDULE_IMPACT) && (
            <div className={classes.collapseHeight} style={{ maxHeight }}>
              <ScheduleImpactWrapperItemData
                isSidebar
                item={item}
                localImpact={localImpact}
                scheduleImpact={scheduleImpact}
                setLocalImpact={setLocalImpact}
                onItemMutated={onItemMutated}
              />
            </div>
          )}
        </>
      )}
      <ItemSidebarCollapseHeader
        disabled={!isProjectItemSidebar && categories.length === 0}
        isExpanded={isExpanded(CATEGORIZATIONS)}
        itemId={item.id}
        name={constantCountLabel(CATEGORIZATIONS, categories ? categories.length : 0)}
        sectionID={CATEGORIZATIONS}
        setExpanded={setExpanded}
        expanded={expanded}
      />
      {isExpanded(CATEGORIZATIONS) && (
        <div className={classes.collapseHeight} style={{ minHeight }}>
          <ItemSidebarCollapseCategories
            canEditItemCategories={permissions.canEditItemCategories}
            item={item}
            projectId={projectId}
            onItemMutated={onItemMutated}
          />
        </div>
      )}
      {permissions.canViewItemAttachments && !isScenario && (
        <>
          <ItemSidebarCollapseHeader
            disabled={!isProjectItemSidebar && item.assetCount === 0}
            isExpanded={isExpanded(ATTACHMENTS)}
            itemId={item.id}
            name={constantCountLabel(ATTACHMENTS, item.assetCount)}
            sectionID={ATTACHMENTS}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isExpanded(ATTACHMENTS) && (
            <div className={classes.collapseHeight} style={{ maxHeight }}>
              <div
                className={classes.containerPadding}
                style={{ background: theme.palette.backgroundWhite }}
              >
                {isProjectItemSidebar ? (
                  <AssetsWrapperItemData
                    bookmarks={item.bookmarks}
                    itemID={id}
                    onItemMutated={onItemMutated}
                  />
                ) : (
                  <AssetsWrapper
                    assets={itemAssets}
                    assetsLoading={loading}
                    bookmarks={item.bookmarks}
                    hasAssetAddPermission={false}
                    hasAssetDeletePermission={false}
                    itemID={item.id}
                    onAttachAsset={() => {}}
                    onDropFile={() => {}}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
      {hasProcoreIntegrationAccess &&
        isProjectItemSidebar &&
        permissions.canViewItemIntegrations && (
          <>
            <ItemSidebarCollapseHeader
              isExpanded={isExpanded(INTEGRATIONS)}
              itemId={item.id}
              name={INTEGRATIONS}
              sectionID={INTEGRATIONS}
              setExpanded={setExpanded}
              expanded={expanded}
            />
            {isExpanded(INTEGRATIONS) && (
              <IntegrationsDataWrapper
                canCreateChangeEvent={item.status === ACCEPTED}
                canDeleteItemIntegration={permissions.canDeleteItemIntegrations}
                isItemSidebar
                itemInfo={{
                  id: item.id,
                  name: item.name,
                  descriptionStyled: item.descriptionStyled,
                  assetCount: item.assetCount,
                  contingencyDrawCost: item.contingencyDrawCost,
                }}
                userSources={userSources}
              />
            )}
          </>
        )}
      {!isScenario && (
        <>
          <ItemSidebarCollapseHeader
            isExpanded={isExpanded(COMMENTS_AND_EDIT_HISTORY)}
            itemId={item.id}
            name={COMMENTS_AND_EDIT_HISTORY}
            sectionID={COMMENTS_AND_EDIT_HISTORY}
            setExpanded={setExpanded}
            expanded={expanded}
          />
          {isExpanded(COMMENTS_AND_EDIT_HISTORY) && (
            <div className={classes.collapseHeight} style={{ minHeight }}>
              <div className={classes.containerPadding}>
                <CommentsHistory
                  canComment={permissions.canAddItemComments}
                  costMode={costMode}
                  itemId={item.id}
                  projectId={projectId}
                  variant={SIDEBAR}
                  onItemMutated={onItemMutated}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  return <div className={classes.bodyContent}>{sidebarContent}</div>;
};

export default withStyles(styles)(ItemSidebarBody);
