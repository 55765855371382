import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const DeactivatedPageStyles = (theme: KomodoTheme) =>
  createStyles({
    container: {
      width: '100%',
      background: 'white',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
    },
    deniedMessage: {
      lineHeight: '144%',
      letterSpacing: '0.0119em',
      textAlign: 'center',
      alignSelf: 'center',
      ...theme.typography.h4newMedium,
    },
    smallLogo: {
      alignSelf: 'center',
      margin: '10vh 0 40px 0',
      width: '30%',
    },
    logo: {
      maxWidth: 500,
      marginLeft: 32,
      width: 'calc(100% - 400px)',
      height: '100%',
      backgroundImage: 'url(/img/logos/login.jpg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'center',
      zIndex: 10000000000,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    logout: {
      padding: '3vh',
    },
    messagesContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    deniedMessageContainer: {
      position: 'relative',
      height: '40px',
      width: '300px',
      margin: 'auto',
      justifyContent: 'center',
      display: 'flex',
      color: 'white',
      background: '#EC4F27',
    },
    contactMessageContainer: {
      color: theme.palette.text.primary,
      marginTop: '3vh',
      width: '300px',
    },
    supportMessageContainer: {
      color: theme.palette.disabledGrey,
      marginTop: '2.5vh',
      width: '300px',
    },
    valuePropsContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    valueProp: {
      padding: '6px',
      fontSize: '28px',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  });

export default DeactivatedPageStyles;
