import { withStyles } from '../../../../../../theme/komodo-mui-theme';

import styles from './LabelStyles';

interface LabelProps {
  classes: Classes<typeof styles>;
  children: React.ReactNode;
}

const _Label = (props: LabelProps) => <div className={props.classes.label}>{props.children}</div>;
export const Label = withStyles(styles)(_Label);
