import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Card, Divider, Drawer, MenuItem, MenuList } from '@material-ui/core';

import { drawerOpenVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import {
  CONTINGENCY_ALLOWANCE_REPORT,
  CONTINGENCY_ALL_MILESTONE_REPORT,
  ITEM_ACTIVITY_PATH,
  SCENARIOS,
  TOTAL,
} from '../../constants';
import { DD_WT_SILVER_BULLET_TOGGLE } from '../../features';
import { CostReportColumnType, PermissionResource } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useFeatureQuery';
import { useMilestoneCostReportsQuery } from '../../hooks/useMilestoneCostReportsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { withStyles } from '../../theme/komodo-mui-theme';
import { computeColumnInputs, computeSelectCostReport } from '../../utilities/milestones';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../utilities/routes/links';
import { NamedLink } from '../../utilities/routes/types';
import { getProjectIdFromUrl } from '../../utilities/url';
import CostSummary from '../estimate/CostSummary';
import UnitToggle from '../Inputs/UnitToggle/UnitToggle';
import { useMilestoneQuery } from '../Milestone/hooks';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../Milestone/hooks/useMilestoneQuantitiesQuery';
import CostMode from '../Nav/Sidebar/CostMode';
import { Chip } from '../scales';
import SelectCostModeData from '../Select/SelectCostMode/SelectCostModeData';
import ZeroState from '../ZeroState/ZeroState';

import FrameSidebarSubtotalChart from './FrameSidebarSubtotalChart/FrameSidebarSubtotalChart';
import FrameTitleBar from './FrameTitleBar/FrameTitleBar';
import ProjectSummary from './ProjectSummary';
import { useThumbnailURL } from './ProjectSummaryUtils';
import styles from './SidebarStyles';

type ProjectSidebarProps = {
  classes: Classes<typeof styles>;
  hasDrawer: boolean;
  links: NamedLink[];
};

const ProjectSideBar: FC<ProjectSidebarProps> = ({ classes, hasDrawer, links }) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const drawerOpen = useReactiveVar(drawerOpenVar);

  const hasToggleFeature = useHasFeature(DD_WT_SILVER_BULLET_TOGGLE);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const location = useLocation();
  const { pathname } = location;
  const isTimeline = pathname.indexOf('/timeline') >= 0;
  const isContingencyReport =
    pathname.indexOf(`/${CONTINGENCY_ALLOWANCE_REPORT}`) >= 0 ||
    pathname.indexOf(`/${CONTINGENCY_ALL_MILESTONE_REPORT}`) >= 0;
  const isScenarios = pathname.indexOf(`/${SCENARIOS}`) >= 0;
  const isItemActivityFeed = pathname.indexOf(`/${ITEM_ACTIVITY_PATH}`) >= 0;
  const hasNoSidebarContent =
    isContingencyReport || isTimeline || isScenarios || isItemActivityFeed;

  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);

  const { thumbnail } = project || {};
  const activeMilestoneId = project?.activeMilestone?.id ?? undefined;

  const projectLoading = projectPropsLoading;

  const { data: { milestone = {} } = {} } = useMilestoneQuery(activeMilestoneId);
  // get quantities
  const { data: { quantities = emptyQuantities } = {} } =
    useMilestoneQuantitiesQuery(activeMilestoneId);

  // Project Size Quantity - get chosen value if available, or default
  const storageLocation = `Sidebar Project ${projectId} Unit`;
  const [quantityID, setQuantityID] = useState<string>(
    localStorage.getItem(storageLocation) || TOTAL
  );

  const quantity = quantities && quantities.find((q) => quantityID === q.id);
  // will default to null and the toggle will default to Total
  const setQuantity = (q: Quantity | undefined) => {
    const qID = (q && q.id) || TOTAL;
    setQuantityID(qID);
    localStorage.setItem(storageLocation, qID);
  };

  const columnInputs = useMemo(() => {
    const sidebarColumnTypes: CostReportColumnType[] = [
      CostReportColumnType.TARGET_REPORT,
      CostReportColumnType.ACCEPTED_REPORT,
      CostReportColumnType.RUNNINGTOTAL_REPORT,
      CostReportColumnType.REMAINING_REPORT,
      CostReportColumnType.ESTIMATE_REPORT,
      CostReportColumnType.REJECTED_REPORT,
      CostReportColumnType.PENDING_REPORT,
      CostReportColumnType.INCORPORATED_REPORT,
    ];
    return computeColumnInputs(sidebarColumnTypes, quantity);
  }, [quantity]);

  const {
    data: { milestoneCostReports } = { milestoneCostReports: [] },
    loading: costReportsLoading,
  } = useMilestoneCostReportsQuery(activeMilestoneId, projectId, {}, columnInputs);
  // We will get our display columns by filtering for the display quantity in the reports
  const costReport = useMemo(
    () => computeSelectCostReport(milestoneCostReports, quantity),
    [milestoneCostReports, quantity]
  );
  const costDataLoading = useMemo(
    () => projectLoading || costReportsLoading,
    [projectLoading, costReportsLoading]
  );

  const { canView } = usePermissions();
  const canViewProjectDetails = canView(PermissionResource.PROJECT_DETAILS);

  // Project Thumbnail
  const queriedAssetID: UUID | undefined = canViewProjectDetails ? thumbnail : undefined;
  const { thumbnailURL } = useThumbnailURL(queriedAssetID);

  const onCostSummaryClick = () => {
    if (hasDrawer) drawerOpenVar(false);
    navigate(
      generateSharedPath(JoinProjectRoutes.MSR, {
        projectId,
        milestoneId: activeMilestoneId,
      })
    );
  };

  const sidebarContent = (
    <>
      {hasDrawer && <FrameTitleBar hasSidebar hasDrawer hideProfile />}
      <div className={classes.root}>
        <div className={classes.fixSidebarPosition}>
          {!projectLoading && !!project && (
            <ProjectSummary
              project={project as ProjectProps}
              quantities={quantities}
              thumbnailURL={thumbnailURL ?? undefined}
            />
          )}
          {!costDataLoading && hasDrawer && (
            <div>
              <Divider className={classes.divider} />
              <MenuList>
                {links.map((link) => {
                  const selected = link.route.path === window.location.pathname;
                  return (
                    <MenuItem
                      key={link.route.path}
                      className={`${classes.menu} ${selected ? classes.selectedMenu : ''}`}
                      onClick={() => {
                        drawerOpenVar(false);
                        navigate(link.route.path);
                      }}
                    >
                      <div>{link.name}</div>
                      {link.isBeta && <Chip backgroundColor="#000" text="Beta" />}
                    </MenuItem>
                  );
                })}
              </MenuList>
              <Divider className={classes.divider} />
            </div>
          )}
          <div className="flex flex-col gap-2">
            {hasToggleFeature ? (
              <CostMode shouldDisplayCosts={shouldDisplayCosts} />
            ) : (
              <SelectCostModeData caption="Display costs" />
            )}
            {shouldDisplayCosts && (
              <UnitToggle
                data-cy="UnitToggle"
                quantities={quantities}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            )}
            {shouldDisplayCosts && (
              <Card square className={classes.card} elevation={0}>
                {costReportsLoading ? (
                  <ZeroState loading height={90} />
                ) : (
                  <div
                    className={classes.costSummaryContainer}
                    onClick={onCostSummaryClick}
                    data-cy="sidebar-cost-summary"
                    role="button"
                    tabIndex={0}
                    onKeyDown={(evt) => {
                      if (evt.key === 'Enter') onCostSummaryClick();
                    }}
                  >
                    <CostSummary costReport={costReport} rounded={!quantity} />
                  </div>
                )}
              </Card>
            )}
          </div>
          {shouldDisplayCosts && (
            <FrameSidebarSubtotalChart
              activeMilestoneId={activeMilestoneId}
              milestone={milestone}
              costReport={costReport}
              costReportLoading={costReportsLoading}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className={classes.noPrint}>
      {hasDrawer ? (
        <Drawer
          classes={{ paper: classes.drawer }}
          open={drawerOpen}
          onClose={() => drawerOpenVar(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {sidebarContent}
        </Drawer>
      ) : (
        !hasNoSidebarContent && sidebarContent
      )}
    </div>
  );
};

export const ProjectSideBarStyled = withStyles(styles)(ProjectSideBar);
export default ProjectSideBarStyled;
