import { isImage } from '../components/assets/utils';
import { AssetQueryApolloQuery } from '../generated/graphql';

import { assetCacheVar } from './apollo/reactiveVars';
import { blobUrlCacheKey } from './apollo/resolvers';
import joinAPI from './joinAPI';

export const fetchBlobUrl = async (asset: AssetQueryApolloQuery['asset']) => {
  if (asset) {
    const blobUrlCached = assetCacheVar().get(blobUrlCacheKey(asset));
    if (blobUrlCached) return blobUrlCached;
    if (isImage(asset.name)) {
      // default images are already a url so we can return that
      if (asset.location.startsWith('https://static-assets.join.build/')) {
        return asset.location;
      }
      const url = await joinAPI.requestAssetBlobURL(asset.location, asset.name);
      assetCacheVar().set(blobUrlCacheKey(asset), url);
      return url;
    }
  }
  return null;
};
