import { generatePath } from 'react-router-dom';

import { JoinProjectRoutes, JoinRoutes } from '../../api/gqlEnums';
import {
  COMPANY_ADMIN,
  COMPANY_DASHBOARD,
  COMPANY_TAB as COMPANY_TAB_NAME,
  DASHBOARD,
  EXECUTIVE_DASHBOARD,
  FORECASTING_TAB_NAME,
  ITEMS,
  MILESTONES,
  PROJECTS,
  PROJECT_DASHBOARD,
  PROJECT_SETTINGS,
  REPORTS,
  SCENARIOS,
  SETTINGS,
  TEAM,
  TIMELINE,
} from '../../constants';
import { FORECASTING } from '../../moduleEntitlementFlagsList';
import { AllRoutes, RootRoutes, SharedRoute } from '../../routes/routes';

import { NamedLink } from './types';

type SharedPathParams = {
  estimateId?: string;
  itemId?: string;
  milestoneId?: string;
  setId?: string;
  projectId?: string;
  reportId?: string;
  roleId?: string;
  search?: string;
  userId?: string;
  assetID?: string;
};

const generateSharedRoutePath = (route: SharedRoute, params: SharedPathParams): string =>
  `${generatePath(route.path, params)}${params.search || ''}`;

export const generateSharedRoute = (
  routeKey: keyof typeof AllRoutes,
  params?: SharedPathParams
): SharedRoute => {
  const route = AllRoutes[routeKey];
  if (!route) throw new Error('Route does not exist.');
  const path = generateSharedRoutePath(route, params || {});
  return { ...route, path };
};

export const generateSharedPath = (routeKey: keyof typeof AllRoutes, params?: SharedPathParams) =>
  generateSharedRoute(routeKey, params || {}).path;

export const companyLinks = (isAccessible: (route: SharedRoute) => boolean) => {
  const links: NamedLink[] = [
    {
      name: PROJECTS,
      route: RootRoutes[JoinRoutes.PROJECTS],
    },
    {
      analyticsName: EXECUTIVE_DASHBOARD,
      name: COMPANY_DASHBOARD,
      route: RootRoutes[JoinRoutes.EXECUTIVE_DASHBOARD],
    },
    {
      analyticsName: FORECASTING,
      name: FORECASTING_TAB_NAME,
      route: RootRoutes[JoinRoutes.FORECASTING_TAB],
    },
    {
      analyticsName: COMPANY_ADMIN,
      name: COMPANY_TAB_NAME,
      route: RootRoutes[JoinRoutes.COMPANY_TAB],
    },
  ];
  return links.filter((link: NamedLink) => isAccessible(link.route));
};

export const projectLinks: (
  projectId: UUID,
  isAccessible: (route: SharedRoute) => boolean
) => NamedLink[] = (projectId, isAccessible) => {
  if (!projectId) return [];

  const links: NamedLink[] = [
    {
      analyticsName: PROJECT_DASHBOARD,
      name: DASHBOARD,
      route: generateSharedRoute(JoinProjectRoutes.PROJECT_DASHBOARD, { projectId }),
    },
    {
      name: REPORTS,
      route: generateSharedRoute(JoinProjectRoutes.REPORTS, { projectId }),
    },
    {
      name: TIMELINE,
      route: generateSharedRoute(JoinProjectRoutes.TIMELINE, { projectId }),
    },
    {
      name: ITEMS,
      route: generateSharedRoute(JoinProjectRoutes.ITEMS_LIST, { projectId }),
    },
    {
      name: MILESTONES,
      route: generateSharedRoute(JoinProjectRoutes.MILESTONES, { projectId }),
    },
    {
      name: SCENARIOS,
      route: generateSharedRoute(JoinProjectRoutes.SCENARIOS, { projectId }),
    },
    {
      analyticsName: PROJECT_SETTINGS,
      name: SETTINGS,
      route: generateSharedRoute(JoinProjectRoutes.SETTINGS, { projectId }),
    },
    {
      name: TEAM,
      route: generateSharedRoute(JoinProjectRoutes.TEAM, { projectId }),
    },
  ];

  const filteredLinks = links.filter((link: NamedLink) => isAccessible(link.route));
  return filteredLinks;
};
