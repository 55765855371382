import { FC, useCallback, useEffect, useState } from 'react';

import { CustomizationTab, ToastType } from '../../../api/gqlEnums';
import joinAPI from '../../../api/joinAPI';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { colorOptionsFromPalette } from '../../../utilities/colors';
import AssetsUploadArea from '../../assets/AssetsProjectCompImage/AssetsUploadArea/AssetsUploadArea';
import { useUploadImage } from '../../assets/hooks/AssetsMutationHook';
import { useThumbnailQuery } from '../../assets/hooks/useAssetQuery';
import { TabOption } from '../../Navigation/NavigationTabs';
import { thumbnailSize } from '../../ProjectComps/ProjectComp/ProjectComp';
import { Button, Dialog } from '../../scales';

import ColorButton from './ColorButton';

const customizationDialogTabOptions: TabOption[] = [
  {
    key: CustomizationTab.IMAGE,
    label: CustomizationTab.IMAGE,
  },
  {
    key: CustomizationTab.COLOR,
    label: CustomizationTab.COLOR,
  },
];

type Props = {
  color: string | undefined;
  onClose: () => void;
  setColorAndThumbnailID: (color?: string, thumbnailID?: UUID | null) => void;
  thumbnailAssetID: UUID | undefined;
  title?: string;
};

const CustomizationDialog: FC<Props> = ({
  color,
  onClose,
  setColorAndThumbnailID,
  title = 'Customize',
  thumbnailAssetID,
}) => {
  // Local state
  const [view, setView] = useState<string>(CustomizationTab.IMAGE);
  const [uploading, setUploading] = useState(false);
  const [localThumbnailAssetID, setLocalThumbnailAssetID] = useState<UUID | undefined>(
    thumbnailAssetID
  );
  const [localColor, setLocalColor] = useState<string | undefined>(color);

  // Fetch Asset
  const { data, loading } = useThumbnailQuery(localThumbnailAssetID, thumbnailSize);
  const { asset } = data || {};

  // once asset is loaded, we need it to show up in customization dialog
  useEffect(() => {
    if (!loading && asset) {
      setUploading(false);
    }
  }, [asset, loading]);

  const removeAsset = useCallback(() => {
    setLocalThumbnailAssetID(undefined);
    setColorAndThumbnailID(localColor, null);
  }, [localColor, setColorAndThumbnailID]);

  const uploadImage = useUploadImage();
  const addFile = useCallback(
    (file: File) => {
      setUploading(true);
      const onSuccess = async (a: Asset) => {
        const assetWithBlobUrl = a;
        assetWithBlobUrl.blobUrl = await joinAPI.requestAssetBlobURL(a.location, a.name);
        setColorAndThumbnailID(localColor, assetWithBlobUrl.id);
        setLocalThumbnailAssetID(assetWithBlobUrl.id);
      };
      const onFailure = (message: string) => {
        setUploading(false);
        setToast({ message }, ToastType.DEFAULT, null);
      };
      uploadImage(file, null, onSuccess, onFailure);
    },
    [localColor, setColorAndThumbnailID, uploadImage]
  );

  const setColor = (color: string) => {
    setLocalColor(color);
    setColorAndThumbnailID(color, localThumbnailAssetID);
  };

  // Components
  const generateDialogContent = (view: string) =>
    view === CustomizationTab.COLOR ? (
      <div className="grid grid-cols-[repeat(auto-fit,144px)] justify-between gap-4 p-6">
        <ColorButton setColor={setColor} text="None" color="" isChosen={!localColor} />
        {colorOptionsFromPalette.map((color) => (
          <ColorButton
            key={color}
            setColor={setColor}
            text=""
            color={color}
            isChosen={color === localColor}
          />
        ))}
      </div>
    ) : (
      <div className="px-6 pb-6">
        <AssetsUploadArea
          addFile={addFile}
          asset={asset as Asset | null}
          loading={uploading}
          removeAsset={removeAsset}
        />
      </div>
    );

  const actionButton = (
    <Button data-cy="done-button" label="Done" onClick={onClose} type="primary" />
  );

  const nav = {
    options: customizationDialogTabOptions,
    view,
    setView,
  };

  return (
    <Dialog
      footerRight={actionButton}
      isOpen
      navigationTabs={nav}
      onClose={onClose}
      size="lg"
      title={title}
    >
      {generateDialogContent(view)}
    </Dialog>
  );
};

export default CustomizationDialog;
