import { createStyles } from '@material-ui/core';

import { APP_MAXWIDTH, SIDEBAR_MINWIDTH } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    content: {
      minWidth: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 2067,
      [theme.breakpoints.up('xl')]: {
        maxWidth: APP_MAXWIDTH,
      },
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
      },
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      '@media print': {
        marginTop: 0,
      },
    },
    main: {
      flexGrow: 1,
      width: `calc(100% - ${SIDEBAR_MINWIDTH} - 16px)`,
    },
  });
