import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { KeyboardBackspace } from '@material-ui/icons';

import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  inAppNotificationsProjectIdVar,
  inAppNotificationsShowUnreadOnlyVar,
  inAppNotificationsOffsetIdsVar as offsetIdsVar,
  inAppNotificationsPaginationVar as paginationVar,
} from '../../../api/apollo/reactiveVars';
import {
  ARROW_LEFT,
  ARROW_RIGHT,
  IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER,
  NULL_ID,
  PAGINATION_DEFAULT_LIMIT,
} from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { getPageCounterText, onNextPage, onPrevPage } from '../../../utilities/pagination';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { Button } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import { resetNotificationsPagination } from '../InAppNotificationUtils/InAppNotificationUtils';

type Props = {
  loadInAppNotificationsCount?: LoadInAppNotificationsCount;
  paginationData?: PaginationData;
};

const getTotalCount = (
  loadInAppNotificationsCount: LoadInAppNotificationsCount | undefined,
  showUnreadOnly: boolean,
  displayAllProjects: boolean
) => {
  if (!loadInAppNotificationsCount) return 0;
  const {
    allNotificationsCount,
    unreadNotificationsCount,
    allProjectNotificationsCount,
    unreadProjectNotificationsCount,
  } = loadInAppNotificationsCount;
  if (showUnreadOnly) {
    return displayAllProjects ? unreadNotificationsCount : unreadProjectNotificationsCount;
  }
  return displayAllProjects ? allNotificationsCount : allProjectNotificationsCount;
};

export default function InAppNotificationsFooter(props: Props) {
  // Vars
  const projectId = getProjectIdFromUrl();
  const displayAllProjects = useReactiveVar(inAppNotificationsProjectIdVar) === null;
  const showUnreadOnly = useReactiveVar(inAppNotificationsShowUnreadOnlyVar);
  const notificationsPagination = useReactiveVar(paginationVar);

  // Hooks
  const sendAnalytics = useAnalyticsEventHook();
  useEffectOnce(() => resetNotificationsPagination());

  const totalCount = useMemoWrapper(
    getTotalCount,
    props.loadInAppNotificationsCount,
    showUnreadOnly,
    displayAllProjects
  );

  const nextOffsetID = props.paginationData?.nextOffsetID || NULL_ID;
  const { offsetID } = notificationsPagination;
  const previousDisabled = offsetID === NULL_ID;
  const nextDisabled = nextOffsetID === NULL_ID || nextOffsetID === offsetID;

  // Functions
  const onPrevClick = () => {
    onPrevPage(paginationVar, offsetIdsVar);
    // Analytics
    sendAnalytics(
      inAppNotificationsAnalyticsEvent(inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_PREVIOUS, {
        projectId,
      })
    );
  };
  const onNextClick = () => {
    onNextPage(paginationVar, offsetIdsVar, offsetID, nextOffsetID);
    // Analytics
    sendAnalytics(
      inAppNotificationsAnalyticsEvent(inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_NEXT, {
        projectId,
      })
    );
  };

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case ARROW_LEFT:
          if (previousDisabled) break;
          onPrevClick();
          break;
        case ARROW_RIGHT:
          if (nextDisabled) break;
          onNextClick();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [paginationVar, offsetIdsVar, offsetID, nextOffsetID]);

  if (!props.loadInAppNotificationsCount) return null;

  if (previousDisabled && nextDisabled) return null;

  return (
    <div className="flex w-full items-center">
      <Button
        data-cy="pagination-navigation-previous"
        disabled={previousDisabled}
        label="Previous"
        onClick={onPrevClick}
        startIcon={<KeyboardBackspace />}
        type="tertiary"
      />
      <div className="grow text-center type-body2" data-cy="pagination-counter">
        {getPageCounterText(
          offsetIdsVar().length,
          totalCount,
          PAGINATION_DEFAULT_LIMIT,
          IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER
        )}
      </div>
      <Button
        data-cy="pagination-navigation-next"
        disabled={nextDisabled}
        endIcon={<KeyboardBackspace className="rotate-180" />}
        label="Next"
        onClick={onNextClick}
        type="tertiary"
      />
    </div>
  );
}
