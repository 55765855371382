import { pluralizeCountString } from '../../../utilities/string';

type Props = {
  sharedUsers?: Pick<User, 'id' | 'name'>[];
  hasPrivateParent: boolean;
};

export default function SharedUsersTooltipContent(props: Props) {
  if (!props.sharedUsers) return null;
  const sharedUserCount = props.sharedUsers?.length;
  const renderSharedUsers = (user: Pick<User, 'id' | 'name'>) => (
    <div className="type-table-text" key={user.id}>
      {user.name}
    </div>
  );

  if (props.hasPrivateParent) {
    return (
      <div className="type-table-text">
        Options of draft items inherit share settings from the parent item.
      </div>
    );
  }

  if (sharedUserCount >= 2) {
    return (
      <div>
        <div
          className="type-table-text"
          data-cy="shared-user-tooltip"
        >{`Shared between ${pluralizeCountString('teammate', sharedUserCount)}:`}</div>
        {sharedUserCount > 10 ? (
          <div className="type-table-text">
            <div>{props.sharedUsers.map(renderSharedUsers).slice(0, 10)}</div>
            <div>{`+ ${sharedUserCount - 10} more`}</div>
          </div>
        ) : (
          <div>{props.sharedUsers.map(renderSharedUsers).slice(0, 10)}</div>
        )}
      </div>
    );
  }

  return (
    <div className="type-table-text">
      Not Shared
      <br />
      <br />
      Click draft button and select share draft to start sharing with teammates.
    </div>
  );
}
