type Props = {
  x: number;
  y: number;
  height: number;
  width: number;
};

const AverageColRect = ({ x, y, width, height }: Props) => (
  <rect x={x} y={y} className="fill-background-1" width={width} height={height} />
);

export default AverageColRect;
