import { FC } from 'react';

const ProcoreSVGLoggedOut: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g opacity="0.3" clipPath="url(#clip0_574_99952)">
        <path
          d="M14.8547 2.44934H5.06672C3.22272 2.44934 1.95605 3.71734 1.95605 5.56001V14.5467C1.95605 16.3893 3.22272 17.6573 5.06672 17.6573H14.8587C16.7014 17.6573 17.9694 16.3893 17.9694 14.5467V12.0133H14.2787L12.6667 14.7733H7.13605L4.37072 10.0533L7.13605 5.32934H12.6667L14.2801 8.09467H17.9667V5.56134C17.9654 3.71734 16.6974 2.44934 14.8547 2.44934Z"
          fill="black"
        />
        <path
          d="M8.51857 7.40405L7.02124 10.0534L8.51857 12.5881H11.5132L13.0106 10.0534L11.5132 7.40405H8.51857Z"
          fill="#FF5200"
        />
      </g>
      <defs>
        <clipPath id="clip0_574_99952">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProcoreSVGLoggedOut;
