import {
  getDisplayUrlImagePdf,
  getDisplayUrlModel,
  isImage,
  isModel,
  isPdf,
} from '../../components/assets/utils';
import { IMAGE, MODEL, PDF } from '../../constants';
import joinAPI from '../joinAPI';

export const blobUrlCacheKey = (asset) => `Asset:${asset.id} ${asset.thumbnailURL}`;
// Resolvers are deprecated in apollo, and we should move away from these
// They are replaced with type policies, which can be found at
// typePolicies.ts
// Note: type policies do not support async functions, so we'll
// need a way to get these assets without querying them
// in the typePolicy iteself.....
const resolvers = (auth) => ({
  Asset: {
    // Drop for Company Asset - DONE
    blobUrl: async (asset, _args, context) => {
      if (asset) {
        const blobUrlCached = (context.cache.data.data[blobUrlCacheKey(asset)] || {}).blobUrl;
        if (blobUrlCached) return blobUrlCached;
        if (isImage(asset.name)) {
          // default images are already a url so we can return that
          if (asset.location.startsWith('https://static-assets.join.build/')) {
            return asset.location;
          }
          const url = await joinAPI.requestAssetBlobURL(asset.location, asset.name, auth);
          return url;
        }
      }
      return null;
    },
    displayURL: (asset, _args, { cache }) => {
      const displayURLCached = (cache.data.data[blobUrlCacheKey(asset)] || {}).displayURL;
      if (displayURLCached) return displayURLCached;
      const { name } = asset;
      if (isImage(name) || isPdf(name)) {
        return getDisplayUrlImagePdf(asset);
      }
      if (isModel(name)) {
        return getDisplayUrlModel(asset);
      }
      return null;
    },
    type: (asset, _args, { cache }) => {
      const typeCached = (cache.data.data[blobUrlCacheKey(asset)] || {}).type;
      if (typeCached) return typeCached;
      const { name } = asset;
      if (isImage(name)) {
        return IMAGE;
      }
      if (isModel(name)) {
        return MODEL;
      }
      if (isPdf(name)) {
        return PDF;
      }
      return null;
    },
  },
});

export default resolvers;
