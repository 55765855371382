import { useMemo, useRef } from 'react';

import { ProjectCompsSetQuery } from '../../../../generated/graphql';
import { getReportIdFromPrintUrl } from '../../../../utilities/url';
import { useLocalStorageParams } from '../../../../utilities/urlState';

import ProjectCompsChartBar from './ProjectCompsChartBar';
import { ChartParams, DEFAULT_PARAMS, getChartsParamsKey } from './ProjectCompsChartData';
import ProjectCompsChartStackedBar from './ProjectCompsChartStackedBar';
import {
  TypeToggle,
  generateCategorizedData,
  generateCategoryNameMap,
  generateProjectCompsChartData,
  getColumnToggleOptionsForCharts,
  getTitle,
  unmarshallColumnInput,
} from './ProjectCompsChartUtils';

type Props = {
  pageHeader: JSX.Element;
  height: number;
  onLoad?: () => void;
  projectCompsSet: ProjectCompsSetQuery['projectCompsSet'];
};

const PrintProjectCompsChartData = (props: Props) => {
  // Display context
  const pinnedUnit = props.projectCompsSet.units?.find(
    (u) => u.id === props.projectCompsSet.input.pinnedUnitID
  );
  const pinnedUnitAbbreviation = pinnedUnit?.abbreviationSingular || 'unit';
  const reportID = getReportIdFromPrintUrl();

  // State
  const [params] = useLocalStorageParams<ChartParams>(DEFAULT_PARAMS, getChartsParamsKey(reportID));
  const { selectedType, selectedColumnInputParam } = params;
  const selectedColumnInput = unmarshallColumnInput(selectedColumnInputParam);

  // TYPE
  const isCategorized = params.selectedType === TypeToggle.CATEGORIZED;

  // UNIT
  const columnToggleOptions = getColumnToggleOptionsForCharts(
    props.projectCompsSet,
    isCategorized,
    pinnedUnit
  );

  const selectedUnitOption = columnToggleOptions.find(
    (option) => option.value === selectedColumnInputParam
  );

  // Filtering
  const filterKeys = JSON.parse(params.filterKeys);

  // Display text
  const yLabel = selectedUnitOption?.label || '';
  const title = getTitle(selectedType, selectedColumnInput.key, pinnedUnitAbbreviation);

  // Data Transformation for Charts
  const { projectCompsSet } = props;
  const { totalData, isCost } = useMemo(
    () =>
      generateProjectCompsChartData({
        projectCompsSet,
        selectedColumnInput,
      }),
    [projectCompsSet, selectedColumnInput]
  );

  // Key Legend values
  const categoryNameMap = useMemo(() => generateCategoryNameMap(totalData), [totalData]);

  // Chart loading
  const chartRef = useRef<HTMLDivElement>(null);
  const { onLoad } = props;
  useMemo(() => {
    const currentHeight = chartRef.current?.clientHeight || 0;
    if (currentHeight > 0 && onLoad) onLoad();
  }, [onLoad]);

  return (
    <div
      ref={chartRef}
      className="pointer-events-none flex break-after-page flex-col gap-2"
      style={{ height: props.height }}
    >
      <div>{props.pageHeader}</div>
      <div className="text-center type-heading2" data-cy="chart-title">
        {title}
      </div>
      {isCategorized ? (
        <ProjectCompsChartStackedBar
          categorizedData={generateCategorizedData({
            totalData,
            filterKeys,
            selectedColumnInput,
          })}
          categoryNameMap={categoryNameMap}
          filterKeys={[]}
          hasAverageComp={!!props.projectCompsSet.averageComp}
          hover={null}
          isCost={isCost}
          selectedUnits={props.projectCompsSet.selectedUnits}
          totalData={totalData}
          yLabel={yLabel}
        />
      ) : (
        <ProjectCompsChartBar
          hasAverageComp={!!props.projectCompsSet.averageComp}
          isCost={isCost}
          totalData={totalData}
          unitAbbreviation={pinnedUnitAbbreviation}
          yLabel={yLabel}
        />
      )}
    </div>
  );
};

export default PrintProjectCompsChartData;
