import { DOMAttributes, forwardRef, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import composeRefs from '@seznam/compose-react-refs';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { JoinRoutes } from '../../../api/gqlEnums';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isEnumValue } from '../../../utilities/types';
import {
  GlobalSearchMode,
  getRecentSearchesKey,
} from '../../HomeTab/Search/global/GlobalSearchUtils';
import useCoreSearchPermissions from '../../HomeTab/Search/hooks/useCoreSearchPermissions';
import { SearchToggleValue } from '../../HomeTab/Search/SearchToggle';
import { ButtonBar, TextInput } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';

import ItemSearchResults from './ItemSearchResults';
import ProjectSearchResults from './ProjectSearchResults';
import RecentSearches from './RecentSearches';

type Props = {
  dialogProps: DOMAttributes<Element>;
  onClose: () => void;
};

export default forwardRef<HTMLDivElement, Props>(function SearchDialog(props, forwardedRef) {
  const sendNavAnalytics = useSendNavAnalytics();
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const { projectId } = useParams();
  const projectName = useProjectPropsQuery(projectId).data.project?.name;

  const [searchText, debouncedSearchText, setSearchText] = useDebouncedState('', {
    duration: 500,
    leading: false,
    trailing: true,
  });

  const permissions = useCoreSearchPermissions();
  const [lsSearchMode, setSearchMode] = useLocalStorage<GlobalSearchMode>(
    'NAV_SEARCH_MODE',
    GlobalSearchMode.ALL_ITEMS
  );
  const searchMode = lsSearchMode ?? GlobalSearchMode.ALL_ITEMS;

  const [recentSearches, setRecentSearches] = useRecentSearches(searchMode, projectId);

  const handleRemoveFromSearchHistory = (searchToRemove: string) => {
    setRecentSearches((prevState) => (prevState ?? []).filter((v) => v !== searchToRemove));
  };

  const handleNavigate = (selection?: { itemID?: UUID; projectID?: UUID }) => {
    setRecentSearches((prevState) => [
      searchText,
      ...(prevState ?? []).filter((v) => v !== searchText).slice(0, 4),
    ]);

    if (selection?.itemID) {
      sendNavAnalytics(navEventTypes.SEARCH_VIEW_ITEM, {
        item: selection.itemID,
        location: 'nav',
        project: selection.projectID,
      });
    } else if (selection?.projectID) {
      sendNavAnalytics(navEventTypes.SEARCH_VIEW_PROJECT, {
        location: 'nav',
        project: selection.projectID,
      });
    } else {
      sendNavAnalytics(navEventTypes.SEARCH_VIEW_ALL, { type: searchMode });

      const routeKey =
        searchMode === GlobalSearchMode.ALL_PROJECTS ? JoinRoutes.PROJECTS : JoinRoutes.ITEMS;
      const project =
        searchMode === GlobalSearchMode.PROJECT_ITEMS ? `&project=${projectName}` : '';

      let toggle = SearchToggleValue.MY;
      if (searchMode === GlobalSearchMode.ALL_PROJECTS) {
        toggle = permissions.hasAllResultsLinkAccess ? SearchToggleValue.ALL : SearchToggleValue.MY;
      } else {
        toggle = permissions.hasAllItemsAccess ? SearchToggleValue.ALL : SearchToggleValue.MY;
      }

      navigate(
        generateSharedPath(routeKey, {
          search: `?search=${searchText}&toggle=${toggle}${project}`,
        })
      );
    }

    props.onClose();
  };

  return (
    <div
      className="fixed top-8 flex w-[550px] flex-col gap-4 rounded-lg bg-background-primary p-6 text-type-primary shadow-menu"
      {...props.dialogProps}
      ref={composeRefs(forwardedRef, ref)}
    >
      <div className="flex w-full flex-col gap-4">
        <ButtonBar
          isFullWidth
          label="Search within"
          onChange={(value) => isEnumValue(GlobalSearchMode, value) && setSearchMode(value)}
          options={[
            { value: GlobalSearchMode.ALL_PROJECTS },
            { value: GlobalSearchMode.ALL_ITEMS },
            ...(projectId ? [{ value: GlobalSearchMode.PROJECT_ITEMS }] : []),
          ]}
          value={searchMode}
        />
        <TextInput
          aria-label="enter a search term"
          autoFocus
          onClear={() => setSearchText('')}
          onChange={setSearchText}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              props.onClose();
            } else if (e.key === 'Enter') {
              handleNavigate();
            } else if (e.key === 'ArrowDown') {
              // select the first result link
              ref.current?.querySelector('a')?.focus();
            }
          }}
          placeholder={getSearchTextInputPlaceholder(searchMode, projectName)}
          value={searchText}
        />
      </div>
      {!searchText ? (
        <RecentSearches
          onRemove={handleRemoveFromSearchHistory}
          onSelect={setSearchText}
          searches={recentSearches ?? []}
        />
      ) : (
        <SearchResults
          onNavigate={handleNavigate}
          projectName={projectName}
          searchMode={searchMode}
          searchText={debouncedSearchText}
        />
      )}
    </div>
  );
});

function SearchResults(props: {
  onNavigate: (selection?: { itemID?: UUID; projectID?: UUID }) => void;
  projectName?: UUID;
  searchMode: GlobalSearchMode;
  searchText: string;
}) {
  return props.searchMode === GlobalSearchMode.ALL_PROJECTS ? (
    <ProjectSearchResults {...props} />
  ) : (
    <ItemSearchResults {...props} />
  );
}

function getSearchTextInputPlaceholder(searchMode: GlobalSearchMode, projectName?: string) {
  let searchTextInputPlaceholder = '';
  if (searchMode === GlobalSearchMode.ALL_ITEMS) {
    searchTextInputPlaceholder = 'Search for items across all your projects...';
  } else if (searchMode === GlobalSearchMode.ALL_PROJECTS) {
    searchTextInputPlaceholder = 'Search for projects...';
  } else if (searchMode === GlobalSearchMode.PROJECT_ITEMS) {
    searchTextInputPlaceholder = `Search for items in ${projectName ?? 'this project'}...`;
  }
  return searchTextInputPlaceholder;
}

function useRecentSearches(searchMode: GlobalSearchMode, projectId?: string) {
  // The underlying hook doesn't automatically update if you change the key
  // you pass into it across renders so we need to grab all local storage values
  // and switch between them across renders instead.
  const recentSearches = {
    [GlobalSearchMode.ALL_PROJECTS]: useLocalStorage<string[]>(
      getRecentSearchesKey(GlobalSearchMode.ALL_PROJECTS, projectId),
      []
    ),
    [GlobalSearchMode.ALL_ITEMS]: useLocalStorage<string[]>(
      getRecentSearchesKey(GlobalSearchMode.ALL_ITEMS, projectId),
      []
    ),
    [GlobalSearchMode.PROJECT_ITEMS]: useLocalStorage<string[]>(
      getRecentSearchesKey(GlobalSearchMode.PROJECT_ITEMS, projectId),
      []
    ),
  };

  return recentSearches[searchMode];
}
