import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

import { BUTTON_MARGIN } from './AssetsViewerStyles';

const BUTTON_SIZE = 150;
const ICON_PADDING = 4;

export default (theme: KomodoTheme) =>
  createStyles({
    navigationButton: {
      color: theme.palette.joinGrey200,
      '&:hover': {
        color: theme.palette.backgroundWhite,
      },
      height: BUTTON_SIZE,
      width: BUTTON_SIZE,
      opacity: 1,
    },
    navigationButtonContainer: {
      height: BUTTON_SIZE,
      width: BUTTON_SIZE,
      '&:hover': {
        backgroundColor: theme.palette.hoverSemitransparentJoinGrey200,
      },
      margin: BUTTON_MARGIN,
      position: 'absolute',
      top: '45%',
    },
    navigationButtonContainerLeft: {
      left: 0,
      paddingLeft: ICON_PADDING,
    },
    navigationButtonContainerRight: {
      right: 0,
      paddingRight: ICON_PADDING,
    },
  });
