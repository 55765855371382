import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem, MenuList } from '@material-ui/core';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  COMPANY_CATEGORIZATIONS,
  COMPANY_PROJECT_TEMPLATES,
  COMPANY_PROJECT_TYPES,
} from '../../../constants';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { NamedLink } from '../../../utilities/routes/types';
import CompanyTabStyles from '../CompanyTabStyles';

type StandardsSidebarProps = {
  classes: Classes<typeof CompanyTabStyles>;
  links: NamedLink[];
};

const StandardsSidebar: FC<StandardsSidebarProps> = ({ classes, links }) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalyticsEventHook();
  return (
    <>
      <div className={classes.standardsSidebar}>
        <div>
          <MenuList>
            {links.map((link: NamedLink) => {
              const {
                route: { path },
                name,
              } = link || {};
              const sel = path === window.location.pathname;
              return (
                <MenuItem
                  key={path}
                  className={`${classes.menu} ${sel ? classes.selectedMenu : ''}`}
                  onClick={() => {
                    navigate(path);
                    switch (name) {
                      case COMPANY_CATEGORIZATIONS:
                        sendAnalytics(
                          companyAdminAnalyticsEvent(CompanyAdminEventType.CATEGORIZATIONS_CTA)
                        );
                        break;
                      case COMPANY_PROJECT_TEMPLATES:
                        sendAnalytics(
                          companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CTA)
                        );
                        break;
                      case COMPANY_PROJECT_TYPES:
                        sendAnalytics(
                          companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_CTA)
                        );
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {name}
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      </div>
    </>
  );
};

export default withStyles(CompanyTabStyles)(StandardsSidebar);
