import { ItemReference } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import { isPrintFromUrl } from '../../../utilities/url';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import JoinLinkify from '../../shared-widgets/JoinLinkify';
import ItemReferenceDisplay from '../ItemReferenceDisplay/ItemReferenceDisplay';

import { commentRefSort, getCommentRefEnd } from './CommentDisplayUtils';

type CommentDisplayProps = {
  className: string;
  title?: string;
  mentions?: Omit<Mention, 'user'>[];
  itemReferences?: ItemHistoryComment['text']['itemReferences'];
  hoveredID?: string | null;
  setHoveredID?: (id: string | null) => void;
  curHoverTimeout?: NodeJS.Timeout | null;
  setCurHoverTimeout?: (timeout: NodeJS.Timeout | null) => void;
};

const linkifyStringSpan = (text: string, className?: string) => (
  <span data-cy="mention-span" className={className} key={text}>
    <JoinLinkify text={text} />
  </span>
);

const CommentDisplay = ({
  title,
  className,
  mentions,
  itemReferences,
  hoveredID,
  setHoveredID,
  curHoverTimeout,
  setCurHoverTimeout,
}: CommentDisplayProps) => {
  if (!title || title.length === 0) {
    return (
      <em style={{ color: theme.palette.app.disabledText }}>
        Failed to render text. If you sent this message, try sending it again.
      </em>
    );
  }
  if ((!mentions || mentions.length === 0) && (!itemReferences || itemReferences.length === 0)) {
    return (
      <span>
        <JoinLinkify text={title} />
      </span>
    );
  }

  const allReferences = [...(mentions || []), ...(itemReferences || [])].sort(commentRefSort);
  const spans: JSX.Element[] = [];
  const isPrint = isPrintFromUrl();

  let currentPosition = 0;

  allReferences.forEach((reference, i, arr) => {
    const { position } = reference;
    const end = getCommentRefEnd(reference);
    const before = title.substring(currentPosition, position);
    const referenceText = title.substring(position, end);

    spans.push(linkifyStringSpan(before));
    if (reference?.__typename === 'Mention') {
      // it is a user mention
      spans.push(linkifyStringSpan(referenceText, className));
    } else if (reference?.__typename === 'ItemReference') {
      // it is an item reference
      const { referencedItem } = reference as ItemReference; // this cast is ok because of type check
      const displayText = referencedItem?.number
        ? styleItemNumber(referencedItem.number)
        : referenceText;
      spans.push(
        <ItemReferenceDisplay
          item={referencedItem ?? undefined}
          displayText={displayText}
          isPrint={isPrint}
          referenceID={reference.id}
          hoveredID={hoveredID}
          setHoveredID={setHoveredID}
          curHoverTimeout={curHoverTimeout}
          setCurHoverTimeout={setCurHoverTimeout}
        />
      );
    }

    currentPosition = end;

    if (i === arr.length - 1 && currentPosition < title.length) {
      const remainingText = title.substring(currentPosition);
      spans.push(linkifyStringSpan(remainingText));
    }
  });

  return <span>{spans}</span>;
};

export default CommentDisplay;
