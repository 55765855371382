import { ProjectCompPortals } from './constants/elementIdentifiers';

export default function ProjectCompsStickyFooter() {
  return (
    <div data-cy="sticky-footer" className="sticky bottom-0 z-10 w-full bg-background-primary">
      <div className="flex w-max min-w-full bg-background-primary shadow-upwards">
        <div className="sticky left-0 z-10 flex bg-background-primary">
          <div className="flex flex-col border-r">
            <div id={ProjectCompPortals.AVG_COMP_FOOTER_COSTS} className="z-20 flex">
              {/* match the sidebar's width */}
              <div id={ProjectCompPortals.LABEL_FOOTER_COSTS} className="w-75 border-r" />
            </div>
            <div id={ProjectCompPortals.AVG_COMP_FOOTER_CONTROLS} className="z-20 flex grow">
              {/* match the sidebar's width */}
              <div id={ProjectCompPortals.LABEL_FOOTER_CONTROLS} className="w-75 border-r" />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div id={ProjectCompPortals.COMP_FOOTER_COSTS} className="flex" />
          <div id={ProjectCompPortals.COMP_FOOTER_CONTROLS} className="flex grow items-center" />
        </div>
      </div>
    </div>
  );
}
