import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  dialogPaper: {
    width: 'auto',
  },
  root: {
    maxWidth: 450,
  },
  modalHeader: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  modalBody: {
    paddingTop: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
  },
};

const ConfirmDeleteMilestone = ({ classes, open, onClose, onCancel, onSubmit, milestone }) => (
  <Dialog
    maxWidth={false}
    classes={{
      paper: classes.dialogPaper,
    }}
    open={open}
    onClose={onClose}
  >
    <div className={classes.root}>
      <div className={classes.modalHeader}>
        <Typography variant="title">Remove item from milestone</Typography>
      </div>
      <Divider />
      <div className={classes.modalBody}>
        <Typography>
          {`Are you sure you want to remove this item from ${
            milestone && milestone.name
          }?  This will update running totals in ${
            milestone && milestone.name
          } to no longer include this item.`}
        </Typography>
      </div>

      <Divider />
      <DialogActions>
        <Button
          size="medium"
          variant="text"
          color="secondary"
          aria-label="Cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-cy="button-removeItemFromMilestone"
          size="medium"
          color="primary"
          variant="contained"
          aria-label="REMOVE"
          onClick={onSubmit}
        >
          Remove
        </Button>
      </DialogActions>
    </div>
  </Dialog>
);

ConfirmDeleteMilestone.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  milestone: PropTypes.shape({}),
};

ConfirmDeleteMilestone.defaultProps = {
  open: false,
  milestone: null,
};

export default withStyles(styles)(ConfirmDeleteMilestone);
