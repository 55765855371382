import Help from '@material-ui/icons/Help';

import { BabyButton, Select, Tooltip } from '../../scales';

type Props = {
  editable: boolean;
  hasTrade: boolean;
  onChange: (hasTrades: boolean) => void;
};

const PermissionsRoleTradeEdit = (props: Props) => {
  const tradesTooltipContent =
    'Do you need certain teammates in a specific Role to only see information in a category like Sitework or a set of categories like Electrical and Plumbing? Set this to "yes" to control different levels of permissions depending on whether someone has access to some specific categories. Set this to "no" if you want all users in this role to have the same level of permissions for items and information in all categories.';

  const selectEntries = [
    { id: 'NO', label: 'No' },
    { id: 'YES', label: 'Yes' },
  ];

  const selectedValue = props.hasTrade ? selectEntries[1].id : selectEntries[0].id;
  return (
    <div className="w-1/2">
      {props.editable ? (
        <Select
          entries={selectEntries}
          label="Limit Access by Category"
          value={selectedValue}
          onChange={(value) => props.onChange(value === 'YES')}
        />
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center">
            <div className="type-label">Limit Access by Category</div>
            <Tooltip content={tradesTooltipContent}>
              <BabyButton aria-label="trades tooltip" icon={<Help />} />
            </Tooltip>
          </div>
          <div className="type-body-1">{props.hasTrade ? 'Yes' : 'No'}</div>
        </div>
      )}
    </div>
  );
};

export default PermissionsRoleTradeEdit;
