import { ComponentProps, useEffect, useRef, useState } from 'react';

import { formatCost } from '../../../utilities/currency';
import TextInput from '../TextInput/TextInput';

type TextInputProps = ComponentProps<typeof TextInput>;
type PassableTextInputProps = Omit<TextInputProps, 'defaultValue' | 'value' | 'onChange'>;
type Props = {
  /** Default value as number of cents */
  defaultValue?: number;
  /** Value passed is the number of cents */
  onChange?: (value: number | null) => void;
  /** Value as number of cents */
  value?: number | null;
} & PassableTextInputProps;

export default function CostInput(props: Props) {
  const { defaultValue, onChange, ...textFieldProps } = props;

  const ref = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState<boolean>(false);

  // This is stored as a dollar value (eg, $1.23 is stored as "1.23") to simplify display logic.
  // This component is the one that does the tracking of whether or not there is a decimal separator.
  // The parent and children components remain blissfully unaware of those complexities.
  const [value, setValue] = useState(
    props.defaultValue !== undefined ? (props.defaultValue / 100).toString() : ''
  );

  // This adds support for running this component as a controlled component. We want to update
  // our stored value with a new value that the parent passed in. However, we can't just /always/
  // take the value the parent passed in because of that quirky little edge case of the decimal
  // separator in a value that has a cents part. As a result, we need this useEffect. :shrug:
  useEffect(() => {
    if (props.value === null) {
      setValue('');
    } else if (typeof props.value === 'number') {
      setValue((props.value / 100).toString());
    }
  }, [props.value]);

  // This also is initialized as a dollar value.
  let displayValue = value;
  if (!focused) {
    displayValue = value
      ? formatCost(parseFloat(value) * 100, { showCents: value.includes('.') })
      : '';
  }

  return (
    <TextInput
      {...textFieldProps}
      // We coerce the `label` and `aria-label` to strings so that the TextInput's
      // type validation passes. The TextInput component uses a StrictUnion<> to ensure
      // that either an aria-label or label is always passed in. TypeScript isn't smart
      // enough to infer the type safety if we do the same approach here, though.
      // As a result, we're relying on React Aria's console warning to notify about
      // cases where we're missing both an aria-label and a label.
      // Future versions of TypeScript (>4.9) may be able to infer this so feel free to
      // try it out, dear reader.
      label={textFieldProps.label ?? ''} // coerce to string for types
      aria-label={textFieldProps['aria-label'] ?? ''} // coerce to string for types
      onBlur={() => setFocused(false)}
      onChange={(newValue) => {
        const strippedValue = newValue.replaceAll(/[^.-\d]/g, '');
        const hasDecimalPoint = strippedValue.includes('.');
        const [integerValue, fractionalValue] = strippedValue.split('.');
        let truncatedValue = integerValue;
        if (hasDecimalPoint) {
          truncatedValue += `.${fractionalValue.substring(0, 9)}`;
        }

        setValue(truncatedValue);

        const floatValue = parseFloat(truncatedValue) * 100;
        if (Number.isNaN(floatValue)) {
          props.onChange?.(null);
        } else {
          props.onChange?.(floatValue);
        }
      }}
      onFocus={() => setFocused(true)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
          ref.current?.blur();
        }
      }}
      ref={ref}
      value={displayValue}
    />
  );
}
