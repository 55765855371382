import { FC, useContext, useMemo } from 'react';

import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import ChartsEstimate from '../ChartsEstimate/ChartsEstimate';
import {
  hasTarget,
  makeEstimateChartCategoriesList,
  useLegendLabels,
} from '../ChartsEstimate/ChartsEstimateUtils';

type Classes = {
  root: string;
};

type ChartsReportMilestoneProps = {
  categorizations: Categorization[];
  classes: Classes;
  clearFilters?: () => void;
  costReports: MilestoneCostReports;
  groupBy: Categorization[];
  isFiltered?: boolean;
  isPrint?: boolean;
  loading?: boolean;
  milestoneId?: UUID;
  projectId: UUID;
};

const ChartsReportMilestone: FC<ChartsReportMilestoneProps> = ({
  categorizations,
  classes,
  clearFilters,
  costReports,
  groupBy,
  isFiltered = false,
  isPrint = false,
  loading = false,
  milestoneId = '',
  projectId,
}) => {
  const t = useContext(ProjectTermStore);
  const chartGroupBy: Categorization | undefined = groupBy && groupBy[0];

  // make the chart data
  const groupedCategories = useMemo(() => {
    if (!chartGroupBy || !costReports || costReports.length < 1 || !categorizations) {
      return [];
    }
    return makeEstimateChartCategoriesList((costReports as MilestoneCostReports)[0], t);
  }, [categorizations, costReports, chartGroupBy, t]);

  const estimateLegend = useLegendLabels(t, hasTarget(groupedCategories));

  return (
    <div className={classes.root}>
      <ChartsEstimate
        clearFilters={clearFilters}
        groupedCategories={groupedCategories}
        isFiltered={isFiltered}
        isPrint={isPrint}
        legend={estimateLegend}
        loading={loading}
        milestoneId={milestoneId}
        projectId={projectId}
        viewByName={chartGroupBy && chartGroupBy.name}
      />
    </div>
  );
};

export default ChartsReportMilestone;
