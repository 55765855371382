import { gql } from '../../../api/graphqlFragments';

export const getItemContingenciesQuery = gql`
  query getItemContingencies($projectID: UUID!, $itemID: UUID!) {
    getItemContingencies(projectID: $projectID, itemID: $itemID) {
      draws {
        id
        type
        name
        error
        draw
        drawFromID
        isDrawingFullAmount
      }
      availableMilestoneContingencies {
        id
        type
        name
        error
        total
        isBeingDrawn
      }
    }
  }
`;
