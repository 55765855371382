import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    container: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      maxWidth: 500,
      paddingBottom: 4,
      paddingTop: 4,
      width: '-webkit-fill-available',
    },
    leftAlign: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
    },
  });
