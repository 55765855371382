import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import { costDescription, renderCostString } from '../CostReport/CostReportUtils';
import { generateCostImpactInfo } from '../Items/ItemsUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import CostField from './CostField';
import styles from './CostImpactStyles';

type CostImpactProps = {
  alwaysNegative?: boolean;
  classes: Classes<typeof styles>;
  id?: UUID;
  fontWeight?: number;
  compact?: boolean;
  editable?: boolean;
  expanded?: boolean;
  fullWidth?: boolean;
  onChange?: (cost: number) => void;
  onKeyDown?: (event: Pick<KeyboardEvent, 'key'>) => void;
  onBlur?: (event: FocusEvent) => void;
  value?: Cost;
  placeholder?: string;
  totalValue?: Cost;
  rounded?: boolean;
  signed?: boolean;
  width?: number;
};

const CostImpact: FC<CostImpactProps> = ({
  alwaysNegative = false,
  classes,
  id = null,
  compact = false,
  editable = false,
  expanded = false,
  fontWeight,
  fullWidth = false,
  onChange = () => {},
  onBlur = () => {},
  onKeyDown = () => {},
  value,
  placeholder = 'Cost Impact',
  totalValue = null,
  rounded = true,
  signed = true,
  width,
}) => {
  // GENERATORS
  const style = { fontWeight };

  const generateCostImpactDisplay = (costImpactValue: Cost) => {
    let cost = 0;

    if (!!costImpactValue && typeof costImpactValue === 'string')
      cost = parseFloat(costImpactValue);

    if (!!costImpactValue && typeof costImpactValue !== 'string' && 'value' in costImpactValue) {
      const { value } = costImpactValue;
      if (typeof value === 'string') {
        cost = parseFloat(value);
      } else {
        cost = value;
      }
    }
    return editable ? (
      <CostField
        alwaysNegative={alwaysNegative}
        editable={editable}
        fullWidth={fullWidth}
        inputProps={{
          classes: { input: !compact ? classes.input : classes.compactInput },
        }}
        placeholder={placeholder}
        value={cost}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    ) : (
      <Typography style={style} data-cy="display-itemLikeCostImpact" className={classes.number}>
        {renderCostString({
          cost: costImpactValue,
          isExact: !rounded,
          isWide: !compact,
          isSigned: signed,
        })}
      </Typography>
    );
  };

  const generateTitle = (costImpactValue: Cost | null | undefined) => {
    const { exactCost, roundedCost } = costDescription(costImpactValue, true);
    return [`Total estimated value:`, exactCost, roundedCost];
  };

  const costImpactDisplay = generateCostImpactDisplay(value);

  const tooltipCopy = generateTitle(value).map((t) => {
    return <div key={t}>{t}</div>;
  });

  return (
    <NormalTooltip title={tooltipCopy}>
      <div
        className={`${fullWidth ? classes.fullWidth : ''} ${classes.root} ${
          !width ? classes.rootWidth : undefined
        }`}
        style={{ width }}
      >
        <div className={classes.spacer} hidden={expanded} />
        {costImpactDisplay}
        {totalValue && (
          <Typography className={classes.totalNumber} id={id || ''}>
            {`across all categories: ${generateCostImpactInfo(totalValue)}`}
          </Typography>
        )}
      </div>
    </NormalTooltip>
  );
};

export default withStyles(styles)(CostImpact);
