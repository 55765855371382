import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { UPLOADING_IMAGE_PLACEHOLDER } from '../../../constants';
import { FilesSortBy } from '../../../generated/graphql';

import { assetTemplate } from './assetTemplate';
import { readProjectAssets, writeProjectAssets } from './useProjectAssetsQuery';

export const uploadingAssetToAsset = (uploadingAsset: UploadingAsset): Asset => ({
  ...assetTemplate,
  id: uploadingAsset.id,
  lastUpdated: new Date().toISOString(),
  name: uploadingAsset.name || '',
  size: Number.parseFloat(uploadingAsset.size || '0'),
  blobUrl: UPLOADING_IMAGE_PLACEHOLDER,
});

const useOptimisticUploadAssets = (
  projectID: UUID,
  sortBy: FilesSortBy,
  uploadingAssets: UploadingAsset[]
) => {
  const client = useApolloClient();
  useEffect(() => {
    if (!uploadingAssets.length) return;
    const assets = readProjectAssets(client, projectID, sortBy);
    const uploading = uploadingAssets.map(uploadingAssetToAsset);
    const uploadingFiltered = uploading.filter((ua) => assets.every((a) => a.id !== ua.id));
    if (!uploadingFiltered.length) return;
    const optimistic = [...uploadingFiltered, ...assets];
    writeProjectAssets(client, optimistic, projectID, sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [uploadingAssets]);
};

export default useOptimisticUploadAssets;
