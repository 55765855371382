import {
  IN_APP_NOTIFICATIONS_PAGINATION_DEFAULT,
  IN_APP_NOTIFICATIONS_PAGINATION_OFFSET_IDS_DEFAULT,
  inAppNotificationsOffsetIdsVar,
  inAppNotificationsPaginationVar,
} from '../../../api/apollo/reactiveVars';
import { fromNow, parseDate } from '../../../utilities/dates';
import { InAppNotificationEntryDescriptionType } from '../InAppNotificationEntry/InAppNotificationEntryUtils';

export const resetNotificationsPagination = () => {
  inAppNotificationsPaginationVar(IN_APP_NOTIFICATIONS_PAGINATION_DEFAULT);
  inAppNotificationsOffsetIdsVar(IN_APP_NOTIFICATIONS_PAGINATION_OFFSET_IDS_DEFAULT);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const groupNotificationsByDateMock = (notificationsData: any[]) => {
  const notificationByDate: Record<string, InAppNotificationEntryDescriptionType[]> = {};
  const notificationGroupedByDates: InAppNotificationEntryDescriptionType[][] = [];
  notificationsData.forEach((n) => {
    const notification = n;
    const nowDate = parseDate(new Date(Date.now()));
    const notificationDate = parseDate(notification.time);
    const isToday = nowDate === notificationDate;
    const timeElapsed = fromNow(String(new Date(notification.time)));

    if (isToday) {
      notification.timeDateDisplay = 'TODAY';
      notification.timeElapsedDisplay = timeElapsed;
    } else {
      notification.timeDateDisplay = notificationDate;
    }

    if (notificationDate) {
      if (notificationByDate[notificationDate]) {
        notificationByDate[notificationDate].push(notification);
      } else {
        notificationByDate[notificationDate] = [notification];
      }
    }
  });

  const notifications = Object.entries(notificationByDate);
  notifications.forEach((notificationGroup) => {
    notificationGroupedByDates.push(notificationGroup[1]);
  });

  return notificationGroupedByDates;
};

export const groupNotificationsByDate = (notificationsData: InAppNotification[]) => {
  const notificationByDate: Record<string, InAppNotificationWithTime[]> = {};
  const notificationGroupedByDates: InAppNotificationWithTime[][] = [];
  notificationsData.forEach((n) => {
    const notification = { ...n } as InAppNotificationWithTime;
    const nowDate = parseDate(new Date(Date.now()));
    const notificationDate = parseDate(notification.createdAt);
    const isToday = nowDate === notificationDate;
    const timeElapsed = fromNow(String(new Date(notification.createdAt)));

    // Determine elapsed time from entry date
    if (isToday) {
      notification.timeDateDisplay = 'TODAY';
      notification.timeElapsedDisplay = timeElapsed;
    } else if (notificationDate) {
      notification.timeDateDisplay = notificationDate;
    }

    // Sort notifications by date
    if (notificationDate) {
      if (notificationByDate[notificationDate]) {
        notificationByDate[notificationDate].push(notification);
      } else {
        notificationByDate[notificationDate] = [notification];
      }
    }
  });

  const notifications = Object.entries(notificationByDate);
  notifications.forEach((notificationGroup) => {
    notificationGroupedByDates.push(notificationGroup[1]);
  });

  return notificationGroupedByDates;
};
