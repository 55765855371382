import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const HEADER_HEIGHT = 88;

const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      minHeight: '88%',
      overflowX: 'hidden',
      border: theme.border.line,
      backgroundColor: theme.palette.backgroundWhite,
      flex: '0 1 auto',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      '@media print': {
        border: 'none',
        marginTop: 0,
        marginBottom: 0,
        overflowX: 'visible',
      },
    },
    root: {
      minWidth: 700,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '@media print': {
        height: 'auto',
        border: `0px none`,
        width: 'auto',
        overflowX: 'visible',
      },
    },
    scrollBody: {
      backgroundColor: theme.palette.backgroundWhite,
      overflowX: 'scroll',
      height: 'min-content',
      '@media print': {
        display: 'block',
        height: 'auto',
        overflow: 'visible',
        overflowX: 'visible',
        width: 'min-content',
        margin: 0,
        marginTop: 0,
      },
    },
    sticky: {
      background: theme.palette.backgroundWhite,
      header: HEADER_HEIGHT,
      left: 0,
      position: 'sticky',
      top: 0,
      zIndex: 102,
    },
  });

export default styles;
