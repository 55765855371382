import { IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER } from '../../../constants';
import {
  LoadInAppNotificationsCountQuery,
  LoadInAppNotificationsCountQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';

import { loadInAppNotificationsCountQuery } from './queries';

const useLoadInAppNotificationsCountQuery = () =>
  useQuery<LoadInAppNotificationsCountQuery, LoadInAppNotificationsCountQueryVariables>(
    loadInAppNotificationsCountQuery,
    {
      variables: {
        projectID: getProjectIdFromUrl() || null,
        maxCount: IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER,
      },
    }
  );

export default useLoadInAppNotificationsCountQuery;
