import { useCallback } from 'react';

import { SharedRoute } from '../routes/routes';
import usePermissions from '../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../utilities/permissions/useShouldDisplayCosts';
import { isAccessibleRoute } from '../utilities/routes/isAccessibleRoute';

import useFeatureQuery from './useFeatureQuery';
import useModuleEntitlementsQuery from './useModuleEntitlementsQuery';

export const useIsRouteAccessible = (args?: { projectID?: UUID }) => {
  const featureQueryResult = useFeatureQuery();
  const moduleEntitlementsQueryResult = useModuleEntitlementsQuery();
  const { canView, isInitialLoadComplete } = usePermissions({ projectID: args?.projectID });
  const { shouldDisplayCosts } = useShouldDisplayCosts(args?.projectID);

  const loadFeatureFlags =
    featureQueryResult.data?.loadFeatureFlags ?? featureQueryResult.previousData?.loadFeatureFlags;

  const loadModuleEntitlementFlags =
    moduleEntitlementsQueryResult.data?.loadModuleEntitlementFlags ??
    moduleEntitlementsQueryResult.previousData?.loadModuleEntitlementFlags;

  const loading =
    isInitialLoadComplete === false ||
    loadFeatureFlags === undefined ||
    loadModuleEntitlementFlags === undefined;

  const isAccessible = useCallback(
    (route: SharedRoute) =>
      isAccessibleRoute(
        loadFeatureFlags ?? [],
        loadModuleEntitlementFlags ?? [],
        canView,
        route,
        shouldDisplayCosts
      ),
    [canView, shouldDisplayCosts, loadFeatureFlags, loadModuleEntitlementFlags]
  );

  return { isAccessible, loading };
};
