import { ServerError, ServerParseError } from 'apollo-link-http-common';
import { GraphQLError } from 'graphql';
import { NavigateFunction } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { Auth0ContextInterface } from '@auth0/auth0-react';

import { TOAST_VAR } from '../../constants';
import { logErrorToSentry } from '../../utilities/sentry';
import {
  toastPermissions,
  toastServerError,
  toastUnauthorizedServerError,
} from '../../utilities/toast';

import { setReactiveLocal, toastParametersVar } from './reactiveVars';

export const getServerErrorCode = (error: ApolloError['networkError'] | undefined) =>
  error && 'statusCode' in error ? error.statusCode : undefined;

export const handleErrors = (
  graphQlErrors: GraphQLError[] | null,
  networkError: Error | ServerError | ServerParseError | null,
  auth: Auth0ContextInterface,
  navigate: NavigateFunction
) => {
  // Note: The order of these if statements is important!!!!!
  // we need to check graphQL errors first so we know if a user
  // has permission to access a project
  if (graphQlErrors) {
    handleGraphQLError(graphQlErrors, navigate);
  }
  if (networkError) {
    handleNetworkError(networkError, auth);
  }
};

const handleGraphQLError = (errors: GraphQLError[], navigate: NavigateFunction) => {
  // TODO: call analytics here.
  errors.forEach((error) => {
    logErrorToSentry(error);
  });

  if (graphQlErrorsIncludeUnauthorizedProjectQuery(errors)) {
    // if the user is unauthorized for this project then show a toast message
    setReactiveLocal(toastParametersVar, TOAST_VAR, toastUnauthorizedServerError);
    // send the user back to the projects list
    navigate('/');
  }
};

const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError,
  auth: Auth0ContextInterface
) => {
  if (networkError) {
    const { statusCode } = networkError as ServerError;
    // The user is not authenticated...
    try {
      const { isAuthenticated } = auth;
      if (!isAuthenticated) {
        auth.logout({ returnTo: window.location.origin });
      } else if (statusCode === 401 || statusCode === 403) {
        // if the action is forbidden, send a message
        toastPermissions();
      } else if (statusCode === 500) {
        // if there is a network error then display a toast to warn the user
        toastServerError();
        logErrorToSentry(networkError);
      }
    } catch (e) {
      logErrorToSentry(e);
    }
  }
};

// check the input list of graphQLerrors to see if there are errors related
// to the project query and that the error message us unauthorized
const graphQlErrorsIncludeUnauthorizedProjectQuery = (
  errors: GraphQLError[] | undefined
): boolean => {
  if (!errors || !errors.length) return false;
  return !!errors.find((error) => graphQLErrorIsUnauthorizedProjectQuery(error));
};

// check the path for the error, and determine if it's from the project query
const graphQLErrorIsUnauthorizedProjectQuery = (error: GraphQLError): boolean => {
  if (!error.path || !error.path.find) return false;

  const isProjectQuery = !!error.path.find((queryName) => queryName === 'project');
  const isUnauthorized = error.message === 'Unauthorized error';

  return isProjectQuery && isUnauthorized;
};
