import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine2 } from '../../../../theme/komodo-mui-theme';
import { ICON_GRID_MARGIN_RIGHT } from '../../../Select/SelectMenu/IconMenuStyles';

const SOURCE_ICON_SIZE = 12;
const CONTAINER_SIZE = 124;
const TILE_SIZE = 120;
const MENU_POSITION = 4;

const AssetsCardStyles = (theme: KomodoTheme) =>
  createStyles({
    addFileText: {
      ...theme.typography.header,
      color: theme.palette.shadedGrey,
    },
    addFileBorder: {
      border: `${theme.border.lineDashed2} !important`,
      '&:hover': {
        border: `${theme.border.line} !important`,
        outline: `${singleLine2(theme.palette.borderGreen)}`,
      },
    },
    addFileIcon: {
      color: theme.palette.shadedGrey,
    },
    dragging: {
      backgroundColor: `${theme.palette.fillGreen} !important`,
      /* eventually do away with this gradient, but for now not here... */
      '&:before': { backgroundImage: 'none !important' },
    },
    hasUrl: {
      textDecoration: 'underline',
    },
    container: {
      width: CONTAINER_SIZE,
      height: CONTAINER_SIZE,
      textAlign: 'right',
      '&:hover $menu': {
        visibility: 'visible',
      },
    },
    menu: {
      position: 'relative',
      right: MENU_POSITION - ICON_GRID_MARGIN_RIGHT - TILE_SIZE + CONTAINER_SIZE,
      top: MENU_POSITION - TILE_SIZE,
      visibility: 'hidden',
      zIndex: 160,
    },
    notDragging: {
      backgroundImage: 'none !important',
    },
    root: {
      color: theme.palette.primaryGrey,
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      alignItems: 'center',
      border: theme.border.line,
      cursor: 'pointer',
    },
    spacer: {
      flexGrow: 1,
    },
    icon: {
      color: theme.palette.primaryBlue,
      position: 'absolute',
      width: theme.iconSizes.large,
      height: theme.iconSizes.large,
    },
    metadata: {
      fontSize: 10,
      lineHeight: 'unset',
    },
    tile: {
      width: TILE_SIZE,
      height: TILE_SIZE,
    },
    pending: {
      opacity: 0.5,
      '&:hover': {
        border: theme.border.line,
        outline: `none`,
      },
    },
    summaryTitle: {
      overflowWrap: 'break-word',
    },
    summaryTitleBookmark: {
      '&:hover': {
        outline: singleLine2(theme.palette.borderGreen),
        outlineOffset: -2,
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: TILE_SIZE,
      height: TILE_SIZE,
      padding: 4,
      position: 'absolute',
      top: 0,
      left: 0,
      textAlign: 'left',
      overflowWrap: 'break-word',
    },
    sourceContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 2,
    },
    sourceIcon: {
      width: SOURCE_ICON_SIZE,
      height: SOURCE_ICON_SIZE,
    },
    sourceIconCard: {
      marginRight: 4,
    },
  });

export default AssetsCardStyles;
