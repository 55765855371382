import { CostReportColumnType, GetMilestoneItemCountQuery } from '../../../generated/graphql';
import { getCostReportByType } from '../../CostReport/CostReportUtils';
import { FILTERED_STATUSES } from '../../ItemsList/ItemsListUtils';

type ItemCounts = GetMilestoneItemCountQuery['itemCounts'];

export type DataPoint = {
  status: string;
  adds?: number;
  deducts?: number;
  itemCount?: number;
};

export const STATE_LIST_SIDEBAR_CHART = [
  CostReportColumnType.REJECTED_REPORT,
  CostReportColumnType.PENDING_REPORT,
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.INCORPORATED_REPORT,
];

const combineReportWithItemByStatus = (
  type: CostReportColumnType,
  costReports: MilestoneCostReport,
  itemCountByStatus: ItemCounts | undefined | null
) => {
  // we combine the cost report with
  // item counts by status
  const report = getCostReportByType(costReports, type);
  const status = FILTERED_STATUSES.find((s: string) => type.includes(s));
  if (report && status && itemCountByStatus) {
    const key = status.toLowerCase() as keyof ItemCounts;
    const { segmented } = report;
    const { adds, deducts } = segmented;
    const itemCount = itemCountByStatus[key] || 0;
    return { status, segmented, adds, deducts, itemCount: Number(itemCount) };
  }
  return null;
};

export const computeStatusData = (
  costReports: MilestoneCostReport,
  itemCountByStatus: ItemCounts | undefined | null
) =>
  costReports && costReports.costReportColumns.length > 0
    ? STATE_LIST_SIDEBAR_CHART.map((status) =>
        combineReportWithItemByStatus(status, costReports, itemCountByStatus)
      )
    : [];
