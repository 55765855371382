import { useMutation } from '@apollo/client';

import {
  CurrentUserCheckVerificationQuery,
  CurrentUserCheckVerificationQueryVariables,
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../../hooks/usePolicyOnFirstMount';
import {
  currentUserCheckVerificationQuery,
  sendEmailVerificationMutation,
  verifyEmailMutation,
} from '../../../queries';

export const useCurrentUserQuery = () =>
  useQuery<CurrentUserCheckVerificationQuery, CurrentUserCheckVerificationQueryVariables>(
    currentUserCheckVerificationQuery,
    {},
    MountPolicy.SKIP_ON_MOUNT
  );

export const useSendEmailVerificationMutation = (url: string, onSuccess?: () => void) => {
  const [sendEmailFunc] = useMutation<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >(sendEmailVerificationMutation);
  return () =>
    sendEmailFunc({
      variables: { url },
    }).then(onSuccess);
};

export const useVerifyEmailMutation = () => {
  const [verifyEmailAddress, mutationResult] = useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(verifyEmailMutation);

  const verifyEmail = (emailVerify: string) =>
    verifyEmailAddress({
      variables: { emailVerify },
    });
  return { verifyEmail, mutationResult };
};
