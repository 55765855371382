import { FC, useState } from 'react';

import { INTEGRATIONS } from '../../../constants';
import { SourceSystem } from '../../../generated/graphql';
import useItemLinkedChangeEventsCountQuery from '../../../hooks/useItemLinkedChangeEventsCount';
import { constantCountLabel } from '../../../utilities/string';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';

import IntegrationsDataWrapper from './Integrations/IntegrationsDataWrapper';
import IntegrationsHeader from './Integrations/IntegrationsHeader';
import { ItemInfo } from './Integrations/IntegrationsUtils';
import ItemDetailsCollapse from './ItemDetailsCollapse';

type IntegrationsCollapseProps = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  itemInfo: ItemInfo;
};

const IntegrationsCollapse: FC<IntegrationsCollapseProps> = ({
  canCreateChangeEvent,
  canDeleteItemIntegration,
  itemInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const userSources = useUserSourcesQuery().data?.userSources;
  const isLoggedIntoProcore = !!userSources?.sources.some(
    (s) => s.sourceSystem === SourceSystem.PROCORE
  );

  const countData = useItemLinkedChangeEventsCountQuery({ itemID: itemInfo.id });
  const count = countData?.data?.itemLinkedChangeEventsCount ?? 0;

  const headerContent = (
    <IntegrationsHeader
      isLoggedIntoProcore={isLoggedIntoProcore}
      name={constantCountLabel(INTEGRATIONS, count)}
    />
  );

  return (
    <ItemDetailsCollapse
      canExpand
      headerContent={headerContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <IntegrationsDataWrapper
        canDeleteItemIntegration={canDeleteItemIntegration}
        isItemSidebar={false}
        canCreateChangeEvent={canCreateChangeEvent}
        itemInfo={itemInfo}
        userSources={userSources}
      />
    </ItemDetailsCollapse>
  );
};

export default IntegrationsCollapse;
