import { FC, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useAuth0 } from '@auth0/auth0-react';

import {
  SIGN_UP_RESEND_EMAIL_CTA,
  SIGN_UP_VERIFY_EMAIL_VIEW,
} from '../../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../../../../hooks/useAnalyticsEventHook';
import SignupContainer from '../../SignupContainer';
import SignupContentBox from '../../SignupContentBox';

import EmailVerification from './EmailVerification';
import { useCurrentUserQuery, useSendEmailVerificationMutation } from './EmailVerificationHooks';

const EmailVerificationSendUser: FC = () => {
  document.title = `Join - Sign Up`;
  const { data } = useCurrentUserQuery();
  const { currentUser } = data || {};

  const sendAnalytics = useAnalyticsEventHook();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(SIGN_UP_VERIFY_EMAIL_VIEW));
  });

  const { location } = window;
  const { origin } = location;
  const sendEmailCall = useSendEmailVerificationMutation(origin);

  const [emailSent, setEmailSent] = useState(false);
  const sendEmail = () => {
    sendEmailCall();
    setEmailSent(true);
  };

  const { logout } = useAuth0();

  // Effect for verify email on page
  useEffect(() => {
    if (!emailSent) sendEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [emailSent]);

  const onResendCta = () => {
    sendAnalytics(analyticsEvent(SIGN_UP_RESEND_EMAIL_CTA));
    sendEmailCall();
  };

  // resend the verification email on page load if they signed up more than a day ago
  if (!currentUser) return null;
  return (
    <SignupContainer
      content={
        <SignupContentBox
          content={<EmailVerification email={currentUser.email} resendEmail={onResendCta} />}
          onBack={() => logout({ returnTo: window.location.origin })}
          title="Please verify your email address."
        />
      }
    />
  );
};

export default EmailVerificationSendUser;
