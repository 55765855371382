import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    inline: {
      color: theme.palette.primaryGrey,
      display: 'inline-block',
      maxWidth: 'calc(100% - 260px)',
      minWidth: 250,
      verticalAlign: 'middle',
      textAlign: 'left',
    },
    inner: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headline: {
      color: theme.palette.primaryGrey,
      fontSize: 36,
      paddingBottom: 18,
      maxWidth: 500,
    },
    subtitle: {
      color: theme.palette.primaryGrey,
      fontSize: 24,
      paddingBottom: 12,
      maxWidth: 500,
    },
    img: {
      height: 400,
      width: 400,
      objectFit: 'cover',
    },
  });
