import { FC } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Checkbox, Divider, MenuItem, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DragChip from '../DragChip';

import {
  GroupByOption,
  MultiGroupOrderOption,
  mapLevelNamesToOptions,
} from './MultiGroupOrderCategorizationUtils';
import MultiGroupOrderSuggestions from './MultiGroupOrderSuggestions';
import MultiGroupOrderStyles from './MultiGroupStyles';

export const EMPTY_MESSAGE_NONE = 'None';

type MultiGroupOrderSelectorProps = {
  classes: Classes<typeof MultiGroupOrderStyles>;
  handleAdd: (toAdd: MultiGroupOrderOption[]) => void;
  handleDelete: (toDelete: MultiGroupOrderOption[]) => void;
  handleSet: (toSet: GroupByOption[]) => void;
  handleSort: (a: number, b: number) => void;
  hasSuggestions?: boolean;
  options?: MultiGroupOrderOption[];
  selected?: MultiGroupOrderOption[];
};

// utilities for narrowing the type of the options prop
type GroupByOptionWithLevelNames = GroupByOption & { levelNames: string[] };
const groupByOptionHasLevelNames = (g: GroupByOption): g is GroupByOptionWithLevelNames =>
  !!g.levelNames;

const MultiGroupOrderSelector: FC<MultiGroupOrderSelectorProps> = ({
  classes,
  handleAdd,
  handleDelete,
  handleSet,
  handleSort,
  hasSuggestions = false,
  options = [],
  selected = [],
}) => {
  // Logic
  const isEmpty = selected.length === 0;
  const hasCategories = Boolean(selected.find((s) => (s as DisplayGroupBy).id));
  const hasCategorySelected = hasCategories || isEmpty;

  // Actions
  const toggleOption = (option: MultiGroupOrderOption) => {
    const idx = selected.findIndex((s) => s.name === option.name);
    if (idx === -1) {
      handleAdd([option]);
    } else {
      handleDelete([option]);
    }
  };

  // Multi-select
  const multiSelectLevelOption = (option: GroupByOptionWithLevelNames) => {
    const allSelected = option.levelNames.every((o: string) =>
      selected.map((s) => s.name).includes(o)
    );
    const someNotAllSelected =
      !allSelected && selected.map((s) => (s as DisplayGroupBy).id).includes(option.id);

    return (
      <div key={option.name}>
        <MenuItem
          key={option.name}
          title={option.name}
          classes={{
            root: classes.checkboxMenuItem,
          }}
          className={classes.menuOption}
          onClick={() => {
            if (option.levelNames) {
              if (allSelected) {
                handleDelete(option.levelNames.map(mapLevelNamesToOptions(option.id)));
              } else {
                handleAdd(
                  option.levelNames
                    .filter((level) => selected.findIndex((s) => s.name === level) === -1)
                    .map(mapLevelNamesToOptions(option.id))
                );
              }
            }
          }}
        >
          <Checkbox
            className={classes.smallIcon}
            data-cy="groupByContainerItem"
            indeterminate={someNotAllSelected}
            checked={allSelected}
          />
          <div className={classes.optionOverflow}>{option.name}</div>
        </MenuItem>
        {option.levelNames.map((name, l) => (
          <MenuItem
            key={name}
            title={option && (option as GroupByOption).hints?.[l]}
            classes={{
              root: classes.checkboxMenuItem,
            }}
            className={classes.submenuOption}
            data-cy={`select-${option.name}`}
            onClick={() =>
              toggleOption({
                id: option.id,
                name,
                level: l + 1,
              })
            }
            value={name}
          >
            <Checkbox
              className={classes.smallIcon}
              checked={selected.map((s) => s.name).includes(name)}
            />
            <div className={classes.optionOverflow}>{name}</div>
          </MenuItem>
        ))}
      </div>
    );
  };

  const multiSelectOption = (option: MultiGroupOrderOption) =>
    (option as GroupByOption).label ? (
      <Typography key={(option as GroupByOption).label} variant="body2" className={classes.header}>
        {(option as GroupByOption).label}
      </Typography>
    ) : (
      <MenuItem
        key={`${option.name} menu`}
        className={classes.menuOption}
        classes={{ root: classes.checkboxMenuItem }}
        data-cy={`select-${option.name}`}
        onClick={() => {
          toggleOption({
            id: (option as GroupByOption).id,
            name: option.name,
            level: 1, // Assumption: Custom categorizations are only ever level 1
          });
        }}
        title={(option as GroupByOption).title}
        value={option.name}
      >
        <Checkbox
          className={classes.smallIcon}
          checked={selected.map((s) => s.name).includes(option.name)}
        />
        <div className={classes.optionOverflow}>{option.name}</div>
      </MenuItem>
    );

  // single-select options and headers
  // Identified as they do not have IDs
  const singleSelectOptionSet = options
    .filter((o) => !(o as DisplayGroupBy).id)
    .map((option) => (
      <MenuItem
        key={`${option.name} menu`}
        className={classes.singleOption}
        onClick={() => toggleOption(option)}
        classes={{ root: classes.checkboxMenuItem }}
        selected={selected.map((s) => s.name).includes(option.name)}
        value={option.name}
      >
        <div className={classes.optionOverflow}>{option.name}</div>
      </MenuItem>
    ));
  const hasSingleOptionSet = singleSelectOptionSet.length > 0;

  // We use the ID field to identify multi-select options (categories)
  // All options with sublevels (multiSelectLevelOption) and without (multiSelectOption)
  const multiSelectOptionSet =
    hasCategorySelected &&
    options
      .filter((o) => 'id' in o && o.id)
      .map((o: MultiGroupOrderOption) =>
        'levelNames' in o && groupByOptionHasLevelNames(o)
          ? multiSelectLevelOption(o)
          : multiSelectOption(o)
      );

  const hasMultiSelectOptionSet = multiSelectOptionSet && multiSelectOptionSet.length > 0;

  const categoryModeLine = hasSingleOptionSet && hasMultiSelectOptionSet && (
    <MenuItem
      key="categories"
      className={`${classes.singleOption} ${classes.multiOptionHeader}`}
      onClick={() =>
        hasCategorySelected ? handleDelete(selected) : !isEmpty && handleDelete([selected[0]])
      }
      classes={{ root: classes.checkboxMenuItem }}
      value="categories"
    >
      <div className={classes.optionOverflow}>Categories</div>
      {hasCategorySelected && (
        <Typography variant="body2" className={classes.underline}>
          Clear all
        </Typography>
      )}
    </MenuItem>
  );

  // Option Components
  const optionSet = (
    <div>
      {hasSuggestions && <MultiGroupOrderSuggestions handleSet={handleSet} options={options} />}
      {singleSelectOptionSet}
      {categoryModeLine}
      <div className={hasCategorySelected && hasSingleOptionSet ? classes.activeLabel : ''}>
        {multiSelectOptionSet}
      </div>
    </div>
  );

  const draggableChips = (
    <>
      <div className={classes.chipContainer}>
        {selected.length === 0 ? (
          <Typography>{EMPTY_MESSAGE_NONE}</Typography>
        ) : (
          selected.map((select, i) => (
            <div key={`${select.name} div`} className={classes.chipPadding}>
              <DragChip
                chipCount={selected.length}
                key={select.name}
                index={i}
                id={select.name}
                title={select.name}
                text={select.name}
                moveListItem={handleSort}
                handleClose={() => handleDelete([select])}
              />
            </div>
          ))
        )}
      </div>
      <Divider />
    </>
  );

  return (
    <div className={classes.scroll}>
      {draggableChips}
      {optionSet}
    </div>
  );
};

const MultiGroupSelectorStyled = withStyles(MultiGroupOrderStyles)(MultiGroupOrderSelector);
export default DragDropContext(HTML5Backend)(MultiGroupSelectorStyled);
