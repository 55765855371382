import { FC, useState } from 'react';
import { isUUID } from 'validator';

import {
  itemAssetViewerOpenCloseAnalytics,
  milestoneAssetViewerOpenCloseAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { computePayload } from '../../../utilities/items';
import { isNonNullable } from '../../../utilities/types';
import { getItemIdFromUrl, getMilestoneIdFromUrl } from '../../../utilities/url';
import { usePrintItemHistory } from '../../Events/EventsItemHistoryList/EvenItemsHistoryListUtils';
import AssetsViewer from '../AssetsViewer/AssetsViewer';
import { isRemoteAssetType } from '../utils';

import AssetsCard from './AssetsCard/AssetsCard';
import RemoteAssetCard from './AssetsCard/RemoteAssetCard';

type AssetsCardsProps = {
  assets: EntityAttachment[];
  bookmarks?: Bookmark[];
  detachAttachment?: (assetID: string, sourceSystem?: SourceSystem) => void;
  downloadAsset: (location: string, name: string, id: string) => void;
  hasDeletePermission: boolean;
  readOnly?: boolean;
  showMenu: boolean;
};

const AssetsCards: FC<AssetsCardsProps> = ({
  assets,
  bookmarks = [],
  detachAttachment,
  downloadAsset,
  hasDeletePermission,
  readOnly,
  showMenu,
}) => {
  const isPrint = usePrintItemHistory();
  const sendAnalytics = useSendAnalyticsEventHook();
  const itemID = getItemIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();

  // State
  const [edittingThumbnail, setEdittingThumbnail] = useState(0);
  const [asset, setAsset] = useState<Asset | UploadedAsset | undefined>();

  // Action Functions

  const getAssetViewerOpenCloseAnalytics = (
    assetID: UUID,
    itemID: UUID | null,
    milestoneID: UUID | null,
    isOpen: boolean
  ) => {
    if (itemID) {
      sendAnalytics(itemAssetViewerOpenCloseAnalytics(itemID, assetID, isOpen));
    } else if (milestoneID) {
      sendAnalytics(milestoneAssetViewerOpenCloseAnalytics(milestoneID, assetID, isOpen));
    }
  };

  const openViewer = (i: number, a: UploadedAsset) => {
    setAsset(a);
    setEdittingThumbnail(i);
    assetViewerOpenVar(true);
    getAssetViewerOpenCloseAnalytics(a.id, itemID, milestoneID, true);
  };

  const closeViewer = (assetID: UUID) => {
    assetViewerOpenVar(false);
    getAssetViewerOpenCloseAnalytics(assetID, itemID, milestoneID, false);
  };

  const canDeleteAsset = hasDeletePermission && !readOnly;

  const getRemoteAssetCard = (asset: RemoteAsset) => {
    const card = (
      <RemoteAssetCard
        asset={asset}
        canDelete={canDeleteAsset}
        detachAttachment={
          detachAttachment ? () => detachAttachment(asset.id, asset.sourceSystem) : undefined
        }
        disabled={isPrint}
        key={asset.id}
        showMenu={showMenu && !isPrint}
      />
    );

    if (!isPrint) {
      return (
        <a
          href={asset.remoteLink ?? undefined}
          key={asset.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          {card}
        </a>
      );
    }
    return card;
  };

  const getAssetCard = (asset: UploadedAsset, i: number) => {
    const assetUploadComplete = isUUID(asset.id || ''); // while upload not yet complete, asset id is the file name
    const key = `${asset.blobUrl}${asset.id}${i}`;
    const canOpenViewer = !!asset.displayURL;
    const onClick = () => {
      if (canOpenViewer) openViewer(0, asset);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      if ((asset as any).remoteLink) window.open((asset as any).remoteLink, '_blank');
    };

    return (
      <AssetsCard
        asset={asset}
        key={key}
        url={asset.displayURL}
        canDelete={canDeleteAsset && assetUploadComplete}
        onClick={onClick}
        detachAttachment={detachAttachment}
        disabled={isPrint}
        downloadAsset={downloadAsset}
        showMenu={showMenu && !isPrint}
      />
    );
  };

  const getBookmarkCard = (bookmark: Bookmark, asset: UploadedAsset) => {
    const assetUploadComplete = isUUID(asset.id); // while upload not yet complete, asset id is the file name
    if (!assetUploadComplete) {
      return null;
    }

    return (
      <AssetsCard
        asset={asset}
        canDelete={canDeleteAsset && assetUploadComplete}
        bookmark={bookmark}
        key={`${asset.blobUrl}${asset.id}`}
        url={bookmark.thumbnail}
        onClick={() => openViewer(bookmarks.indexOf(bookmark), asset)}
        showMenu={showMenu}
      />
    );
  };

  const mapAssetBookmarkCards = (assets: EntityAttachment[], bookmarks: Bookmark[]) => {
    const cards: JSX.Element[] = [];
    assets.forEach((a, i) => {
      if (isRemoteAssetType(a)) {
        const asset = a as RemoteAsset;
        cards.push(getRemoteAssetCard(asset));
      } else {
        const asset = a as UploadedAsset;
        cards.push(getAssetCard(asset, i));
        const assetBookmarks = bookmarks
          ? bookmarks.filter(
              (bookmark) => asset.displayURL === computePayload(bookmark.payload)?.url
            )
          : [];
        if (assetBookmarks.length) {
          const bookmarkCards = assetBookmarks
            .map((bookmark) => getBookmarkCard(bookmark, asset))
            .filter(isNonNullable);
          cards.push(...bookmarkCards);
        }
      }
    });
    return cards;
  };

  const shiftViewer = (d: number) => {
    const files = assets as UploadedAsset[];
    const idx = files.findIndex((a) => a.id === (asset ? asset.id : null));
    const next = files[idx + d];
    if (next) {
      openViewer(0, next as UploadedAsset);
    } else {
      const wrapAroundOperator = d < 0 ? 1 : -1;
      const nextWrapAround = files[idx + d + wrapAroundOperator * files.length];
      if (nextWrapAround) {
        openViewer(0, nextWrapAround as UploadedAsset);
      }
    }
  };
  return (
    <>
      {mapAssetBookmarkCards(assets, bookmarks)}
      {asset && (
        <AssetsViewer
          asset={asset as Asset}
          bookmarkEditIndex={edittingThumbnail}
          canDelete={canDeleteAsset}
          bookmarks={bookmarks}
          closeModal={closeViewer}
          detachAttachment={detachAttachment ? () => detachAttachment(asset.id) : undefined}
          downloadAsset={() => downloadAsset(asset.location, asset.name, asset.id)}
          numAssets={assets.length}
          setEditingView={setEdittingThumbnail}
          shiftViewer={shiftViewer}
          type={asset.type}
          viewOnly={false}
          showMenu={showMenu}
        />
      )}
    </>
  );
};

export default AssetsCards;
