import { FC, useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useTitleHeight } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { DASHBOARD, FALSE, TRUE, VIEW_FILTER } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';
import { usePersistentStates } from '../../utilities/urlState';
import FilterPanelWrapper from '../FilterPanel/FilterPanelWrapper';
import { hasActiveFilter, useFilterManager } from '../FilterPanel/filterUtils';
import { useLoadTimer } from '../PerfMonitor/utils';
import { isPrintKeys } from '../Print/PrintUtils';
import ReportsManagerMenu from '../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import TeamSummary from '../Team/TeamSummary/TeamSummary';
import useMemoWrapper from '../useMemoWrapper';

import DashboardChartsEstimate from './DashboardCharts/DashboardChartsEstimate/DashboardChartsEstimate';
import DashboardChartsItems from './DashboardCharts/DashboardChartsItems/DashboardChartsItems';
import DashboardChartsTrend from './DashboardCharts/DashboardChartsTrend/DashboardChartsTrend';
import DashboardExportPanel from './DashboardExportPanel/DashboardExportPanel';
import styles from './DashboardStyles';
import { DASHBOARD_DEFAULTS } from './DashboardUtils';

type DashboardProps = {
  classes: Classes<typeof styles>;
  ownStorageParam?: string;
  page?: string;
};

export const getDashboardStorageParam = (projectId: string) => `Dashboard Project ${projectId} `;

const Dashboard: FC<DashboardProps> = ({ classes, ownStorageParam, page }) => {
  // HOOKS
  const top = useTitleHeight();

  // TODO - if we like this, we will need to elevate this hash load effect globally
  const { pathname } = window.location;
  useEffect(() => {
    const { hash } = window.location;
    const anchor = decodeURI(hash).toLowerCase();
    let tries = 0;
    const tryHash = () => {
      setTimeout(() => {
        const el = document.querySelector(anchor);
        if (el) {
          el.scrollIntoView();
        } else if (tries < 10) {
          tries += 1;
          tryHash();
        }
      }, 500);
    };
    if (anchor) {
      tryHash();
    }
  }, [pathname]);

  // CONSTANTS
  const projectId = getProjectIdFromUrl();
  const { search } = window.location;

  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name ?? '';
  const activeMilestoneId = project?.activeMilestone.id;
  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const { canView, isViewOnly } = usePermissions();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);

  const [settings, setSettings] = usePersistentStates(
    window.location,
    page || DASHBOARD,
    DASHBOARD_DEFAULTS,
    ownStorageParam || getDashboardStorageParam(projectId)
  );

  // store settings changes without immediately persisting
  const [settingsToUpdate, setSettingsToUpdate] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const setSetting = (param: any, value: any) => {
    setSettingsToUpdate({ ...settingsToUpdate, [param]: value });
  };
  // rely on filterPanel open status to determine when to persist settings updates
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  useEffect(() => {
    if (Object.keys(settingsToUpdate).length !== 0 && !showFilterPanel) {
      setSettings(settingsToUpdate); // persist settings change
      setSettingsToUpdate({}); // reset intermediate state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterPanel, settingsToUpdate]);

  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    setSetting(VIEW_FILTER, newValue)
  );

  const { clearFilters, filterQueryInput: viewFilter } = filterManager;
  const isFiltered = useMemoWrapper(hasActiveFilter, viewFilter);

  // Print click and key press
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const printOnClick = (route: JoinProjectRoutes) => {
    const params = new URLSearchParams(window.location.search); // modifies the URL of the current page
    params.append('showTable', FALSE);
    const search = `?${params.toString()}`;
    // To-Do: see if we can remove showTable instead of appending False
    // Workaround for now - however, don't want showTable to end up controlling the Dashboard view
    // Later - change the name to something more standalone than showTable
    window.open(generateSharedPath(route, { projectId, search }), '_blank');
  };

  const onEstimateTableClick = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.append('showTable', TRUE);
    const search = `?${params.toString()}`;
    window.open(
      generateSharedPath(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD, { projectId, search }),
      '_blank'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [projectId, search]);

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        printOnClick(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD);
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [printOnClick]);

  const [trendlineIsLoading, setTrendlineIsLoading] = useState(true);
  const [estimateIsLoading, setEstimateIsLoading] = useState(true);
  const [itemsIsLoading, setItemsIsLoading] = useState(true);
  const chartsLoading =
    projectLoading ||
    projectCategorizationsLoading ||
    trendlineIsLoading ||
    estimateIsLoading ||
    itemsIsLoading;
  useLoadTimer('Dashboard', chartsLoading);

  useEffect(() => {
    if (projectName) document.title = `${projectName} - Dashboard`;
  }, [projectName]);

  if (!projectId) return <Navigate to="/404" />;

  const charts = (
    <>
      <div
        style={{ top }}
        className={`${classes.containerPanels} ${classes.hidePrint}`}
        id="dashboardPageHeader"
      >
        <header className="type-heading1">Dashboard</header>
        <TeamSummary projectID={projectId} />
        <div className={classes.controlHeader}>
          {canViewProjectCategories && (
            <FilterPanelWrapper
              showFilterSummary={false}
              filterManager={filterManager}
              page="dashboard"
              show={showFilterPanel}
              setShow={setShowFilterPanel}
            />
          )}
          <ReportsManagerMenu
            settings={settings}
            setSettings={setSettings}
            variant="dashboard"
            isViewOnly={isViewOnly}
          />
          <DashboardExportPanel
            onEstimateTableClick={onEstimateTableClick}
            printOnClick={printOnClick}
            projectId={projectId}
          />
        </div>
      </div>
      <div className={classes.graphs}>
        {activeMilestoneId && (
          <DashboardChartsTrend
            activeMilestoneId={activeMilestoneId}
            categorizations={categorizations}
            clearFilters={clearFilters}
            filterManager={filterManager}
            isFiltered={isFiltered}
            setIsLoading={setTrendlineIsLoading}
            setSetting={setSetting}
            settings={settings}
            viewFilter={viewFilter}
          />
        )}
        {activeMilestoneId && (
          <DashboardChartsEstimate
            activeMilestoneId={activeMilestoneId}
            categorizations={categorizations}
            clearFilters={clearFilters}
            filterManager={filterManager}
            isFiltered={isFiltered}
            setIsLoading={setEstimateIsLoading}
            setSetting={setSetting}
            settings={settings}
            viewFilter={viewFilter}
          />
        )}
        {activeMilestoneId && (
          <DashboardChartsItems
            activeMilestoneId={activeMilestoneId}
            categorizations={categorizations}
            clearFilters={clearFilters}
            filterManager={filterManager}
            isFiltered={isFiltered}
            setIsLoading={setItemsIsLoading}
            setSetting={setSetting}
            settings={settings}
            viewFilter={viewFilter}
          />
        )}
      </div>
    </>
  );

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0} square>
        {charts}
      </Card>
    </div>
  );
};

export default withStyles(styles)(Dashboard);
