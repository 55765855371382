import * as React from 'react';

import { FieldType } from '../../../api/gqlEnumsBe';
import { Scale } from '../../../enums';
import { MarkupType } from '../../../generated/graphql';
import { formatCost, getCurrencyScale, isCurrency } from '../../../utilities/currency';
import { removeThousandsSeparator } from '../../../utilities/string';
import {
  Column as ColumnInterface,
  GridController,
  GridType,
  KeyBufferState,
  Position,
} from '../types';
import {
  cellTypesAssignable,
  deserializeClipboardCell,
  emptyCellValue,
  isCurrencyField,
  isMarkupValue,
  isRegularCell,
  postprocessCost,
  postprocessPercent,
  serializeClipboardCell,
  typeToEditCell,
} from '../utilities/cell';
import {
  boundedRectangle,
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
  wrappingLeft,
  wrappingRight,
} from '../utilities/position';
import range from '../utilities/range';

// HELPERS

export function normalizeRectangle(grid: GridController, start: Position, end: Position) {
  return boundedRectangle(grid.numRows(), grid.numCols(), start, end);
}

// MAIN TYPE

export interface HandleState {
  // Reference to update grid if needed
  grid: GridController;

  // Start and end of the current selection
  start: Position;
  end: Position;

  // Current editing state and update functions
  editing: boolean;
  setEditing: (b: boolean) => void;
  setEditorPosition: (p: Position) => void;
  setEditorDefaultValue: (v: GridCellValue) => void;
}

export type GridKeyHandler = (event: React.KeyboardEvent, handle: HandleState) => void;

// HANDLERS

const postprocess = (type: string, value: GridCellValue, string: string) => {
  switch (type) {
    case FieldType.CURRENCY:
      return postprocessCost(string);
    case FieldType.CURRENCY_9:
      return postprocessCost(string, Scale.CURRENCY_9);
    case FieldType.NUMBER:
    case FieldType.DECIMAL:
      return removeThousandsSeparator(string, false);
    case FieldType.MARKUP_VALUE:
      switch ('type' in value ? value.type : undefined) {
        case MarkupType.PERCENT:
          return postprocessPercent(string);
        case MarkupType.FIXED:
          return postprocessCost(string);
        default:
          return string;
      }
    default:
      return string;
  }
};

export const dispatchKeysIfBuffering = (handle: HandleState) => {
  const { grid, start } = handle;
  if (grid.isRenderingEditor === KeyBufferState.BUFFERING) {
    grid.isRenderingEditor = KeyBufferState.DISPATCHED;
    const extraKeys = grid.getKeyBufferString(start);
    const value = grid.getCellValue(start.row, start.column);
    if ((extraKeys.length > 0 && isRegularCell(value)) || isMarkupValue(value)) {
      const { type } = grid.data.columns[start.column];
      const string = postprocess(type, value, extraKeys);
      grid.mutateData(start, start, [[{ string, formula: '', formulaDisplay: [] }]]);
    }
  }
};

export const EditCellWithContents: GridKeyHandler = (event, handle) => {
  const { editing, start, grid } = handle;
  const { row, column } = start;

  if (!editing && grid.isCellEditable(start)) {
    handle.setEditing(true);
    handle.setEditorPosition(start);
    const refs = grid.getCellData(row, column);
    if (refs) handle.setEditorDefaultValue(refs.data.value);
  }
};

export const FillSelectedRange: GridKeyHandler = (event, handle) => {
  const { editing, start, end, grid } = handle;
  const {
    data: { columns },
  } = grid;

  if (editing) {
    handle.setEditing(false);
  }

  const source = grid.getCellValue(start.row, start.column);
  if (!source) return;
  const sourceType = columns[start.column].type;
  // We allow arbitrary type conversions by canonicalizing the
  // source cell to a string, which allows us to re-use the
  // clipboard handlers to let each cell type decide how it wants
  // to handle the given input
  const stringInput = serializeClipboardCell(sourceType, source);
  const [s, e] = normalizeRectangle(grid, start, end);
  const values = range(s.row, e.row + 1, (i) =>
    range(s.column, e.column + 1, (j) => {
      const destType = columns[j].type;
      if (cellTypesAssignable(sourceType, destType)) {
        return deserializeClipboardCell(destType, stringInput, j, i);
      }
      // If a cell isn't assignable, we don't affect the old value.
      return undefined;
    })
  );

  grid.mutateData(s, e, values);
  event.preventDefault();
  event.stopPropagation();
};

export const FillRangeDown: GridKeyHandler = (event, handle) => {
  const { editing, start, end, grid } = handle;

  if (editing) {
    handle.setEditing(false);
  }

  // When filling a range down, we don't need any type-checking, since cells
  // will always be of the same type as the source cell.
  const [s, e] = normalizeRectangle(grid, start, end);
  const sources = range(s.column, e.column + 1, (j) => grid.getCellValue(start.row, j));
  const col = grid.data.columns[s.column];
  const values = range(s.row, e.row + 1, () =>
    range(s.column, e.column + 1, (j) => {
      const source = sources[j - s.column];
      // for currency cells since we process in the input
      // we have to format the contents of the original value
      if (isCurrencyField(col.type) && source) {
        const value = 'string' in source ? source.string : '';
        const string = isCurrency(value)
          ? formatCost(value, {
              scale: getCurrencyScale(col.type),
              showAllDigits: true,
              showCurrencySymbol: false,
            })
          : value;
        return { ...source, string };
      }
      return source && { ...source };
    })
  );

  grid.mutateData(s, e, values);
  event.preventDefault();
  event.stopPropagation();
};

export const FillRangeRight: GridKeyHandler = (event, handle) => {
  const { editing, start, end, grid } = handle;
  const {
    data: { columns },
    getCellValue,
  } = grid;

  if (editing) {
    handle.setEditing(false);
  }

  const [s, e] = normalizeRectangle(grid, start, end);
  const sourceType = columns[start.column].type;
  const sources = range(s.row, e.row + 1, (i) => {
    const value = getCellValue(i, start.column);
    return (value && serializeClipboardCell(sourceType, value)) || '';
  });
  const validDestType = range(s.column, e.column + 1, (j) =>
    cellTypesAssignable(sourceType, columns[j].type)
  );
  const values = range(s.row, e.row + 1, (i) =>
    range(s.column, e.column + 1, (j) =>
      validDestType[j - s.column]
        ? deserializeClipboardCell(columns[j].type, sources[i - s.row], j, i)
        : undefined
    )
  );
  grid.mutateData(s, e, values);
  event.preventDefault();
  event.stopPropagation();
};

export const HandleCellEnter: GridKeyHandler = (event, handle) => {
  const { editing, start, grid } = handle;
  // do not add new lines to the incorporated item markups grid
  if (
    grid.type === GridType.INCORPORATED_ITEM_MARKUP_GRID ||
    grid.type === GridType.INCORPORATED_ITEM_DRAWS_GRID
  )
    return;

  const numRows = grid.numRows();

  if (grid.isCellActivatable(start)) {
    EditCellWithContents(event, handle);
  } else {
    if (editing) {
      handle.setEditing(false);
    }
    const dest = moveDown(start, numRows + 1);

    // If this is the last row, create another line and
    // wait for the grid to update before moving focus.
    if (start.row === numRows - 1) {
      if (grid.isFiltering) return;
      grid.addLine('EnterPress', () => {
        dispatchKeysIfBuffering(handle);
        grid.setSelectionRange(dest, dest);
        grid.moveFocus(dest);
      });
    } else {
      dispatchKeysIfBuffering(handle);
      grid.setSelectionRange(dest, dest);
      grid.moveFocus(dest);
    }
  }
};

export const ScrollToActiveCell: GridKeyHandler = (event, handle) => {
  const { grid, start } = handle;
  grid.scrollToRow(start.row);
  grid.moveFocus(start);
};

// TODO: Unlike sheets or excel, selectRow and selectColumn move the rangehead to the start
// of the row / column. This is due to a fundamental incompatibility in how we handle selection
// state: we only allow it to ever be at a corner of the selection rectangle. We should address
// this when we update the selection representation to support ctrl selects.
export const SelectRow: GridKeyHandler = (event, handle) => {
  const { grid, start, end } = handle;
  const newStart = { row: start.row, column: 0 };
  const newEnd = { row: end.row, column: grid.numCols() - 1 };
  grid.setSelectionRange(newStart, newEnd);
  grid.moveFocus(newStart);
};

export const SelectColumn: GridKeyHandler = (event, handle) => {
  const { grid, start, end } = handle;
  const newStart = { row: 0, column: start.column };
  const newEnd = { row: grid.numRows() - 1, column: end.column };
  grid.setSelectionRange(newStart, newEnd);
  grid.moveFocus(newStart);
};

export const MoveToRowStart: GridKeyHandler = (event, handle) => {
  const { grid, start } = handle;
  const dest = { row: start.row, column: 0 };
  grid.setSelectionRange(dest, dest);
  grid.moveFocus(dest);

  event.preventDefault();
  event.stopPropagation();
};

export const MoveToRowEnd: GridKeyHandler = (event, handle) => {
  const { grid, start } = handle;
  const dest = { row: start.row, column: grid.numCols() - 2 };
  grid.setSelectionRange(dest, dest);
  grid.moveFocus(dest);

  event.preventDefault();
  event.stopPropagation();
};

export const MoveToTableStart: GridKeyHandler = (event, handle) => {
  const { grid } = handle;
  const dest = { row: 0, column: 0 };
  grid.scrollToTop();
  grid.setSelectionRange(dest, dest);
  setTimeout(() => grid.moveFocus(dest), 0);

  event.preventDefault();
  event.stopPropagation();
};

export const MoveToTableEnd: GridKeyHandler = (event, handle) => {
  const { grid } = handle;
  const dest = { row: grid.numRows() - 1, column: grid.numCols() - 2 };
  grid.scrollToBottom();
  grid.setSelectionRange(dest, dest);
  setTimeout(() => grid.moveFocus(dest), 0);

  event.preventDefault();
  event.stopPropagation();
};

export const NavigateLeft: GridKeyHandler = (event, handle) => {
  const { grid, editing, start, end } = handle;
  if (editing) handle.setEditing(false);
  if (event.shiftKey) {
    const dest = moveLeft(end, grid.numCols());
    grid.setSelectionRangeEnd(dest);
  } else {
    const dest = moveLeft(start, grid.numCols());
    dispatchKeysIfBuffering(handle);
    grid.setSelectionRange(dest, dest);
    grid.moveFocus(dest);
  }

  event.preventDefault();
  event.stopPropagation();
};

export const NavigateRight: GridKeyHandler = (event, handle) => {
  const { grid, editing, start, end } = handle;

  if (editing) handle.setEditing(false);
  if (event.shiftKey) {
    const dest = moveRight(end, grid.numCols());
    grid.setSelectionRangeEnd(dest);
  } else {
    const dest = moveRight(start, grid.numCols());
    dispatchKeysIfBuffering(handle);
    grid.setSelectionRange(dest, dest);
    grid.moveFocus(dest);
  }

  event.preventDefault();
  event.stopPropagation();
};

export const NavigateUp: GridKeyHandler = (event, handle) => {
  const { grid, editing, start, end } = handle;

  if (editing) handle.setEditing(false);
  if (event.shiftKey) {
    const dest = moveUp(end, grid.numRows());
    grid.setSelectionRangeEnd(dest);
  } else {
    const dest = moveUp(start, grid.numRows());
    dispatchKeysIfBuffering(handle);
    grid.setSelectionRange(dest, dest);
    grid.moveFocus(dest);
  }

  event.preventDefault();
  event.stopPropagation();
};

export const NavigateDown: GridKeyHandler = (event, handle) => {
  const { grid, editing, start, end } = handle;

  if (editing) handle.setEditing(false);
  if (event.shiftKey) {
    const dest = moveDown(end, grid.numRows());
    grid.setSelectionRangeEnd(dest);
  } else {
    const dest = moveDown(start, grid.numRows());
    dispatchKeysIfBuffering(handle);
    grid.setSelectionRange(dest, dest);
    grid.moveFocus(dest);
  }

  event.preventDefault();
  event.stopPropagation();
};

// Fully wrap around back to the start of the grid when needed.
export const HandleCellTab: GridKeyHandler = (event, handle) => {
  const { grid, end } = handle;
  const [numRows, numCols] = [grid.numRows(), grid.numCols()];
  const dest = event.shiftKey
    ? wrappingLeft(end, numRows, numCols)
    : wrappingRight(end, numRows, numCols);

  handle.setEditing(false);
  dispatchKeysIfBuffering(handle);
  grid.setSelectionRange(dest, dest);
  grid.moveFocus(dest);

  event.preventDefault();
  event.stopPropagation();
};

export const HandleEsc: GridKeyHandler = (event, handle) => {
  const { grid } = handle;
  grid.closeErrorHeaders();
  HandleCellTab(event, handle);

  event.preventDefault();
  event.stopPropagation();
};

export const SelectAll: GridKeyHandler = (event, handle) => {
  const { grid, editing } = handle;
  const [numRows, numCols] = [grid.numRows(), grid.numCols()];

  const start = { row: 0, column: 0 };
  const end = { row: numRows - 1, column: numCols - 1 };

  if (!editing) {
    // only select all if you aren't editing a cell.
    // otherwise we want to select all inisde the cell instead
    event.preventDefault();
    event.stopPropagation();
    grid.setSelectionRange(start, end);
  }
};

export const FilterSelectedRangeValues = (
  columns: ColumnInterface[],
  e: { row: number; column: number },
  end: Position,
  s: { row: number; column: number }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
): { filteredEnd: Position; filteredValues: any[][] } => {
  let filteredEnd = end;
  const values = range(s.row, e.row + 1, () => {
    // eslint-disable-next-line consistent-return
    return range(s.column, e.column + 1, (j) => {
      // remove columns we don't want to have mutated (ex: Delete)
      if (['Delete'].includes(columns[j].id)) {
        filteredEnd = { ...end, column: end.column - 1 };
      } else {
        return emptyCellValue(columns[j].type);
      }
    });
  });
  const filteredValues = values.map((valuesArray) => valuesArray.filter((x) => x !== undefined));
  return { filteredEnd, filteredValues };
};

export const ClearSelectedRange: GridKeyHandler = (event, handle) => {
  const { grid, start, end } = handle;
  const {
    data: { columns },
  } = grid;
  const [s, e] = normalizeRectangle(grid, start, end);
  const { filteredEnd, filteredValues } = FilterSelectedRangeValues(columns, e, end, s);

  grid.mutateData(start, filteredEnd, filteredValues);

  event.stopPropagation();
};

export const isLinesLoaded = (handle: HandleState): boolean => {
  const {
    grid: {
      data: { lines },
    },
    start,
    end,
  } = handle;
  let isLoaded = true;
  for (let i = end.row; i >= start.row; i -= 1) {
    if (!lines[i]) {
      isLoaded = false;
      break;
    }
  }
  return isLoaded;
};

export const CopyToClipboard: GridKeyHandler = (event, handle) => {
  const { grid, start, end } = handle;
  const isLoaded = isLinesLoaded(handle);
  if (grid.type === GridType.ESTIMATE_GRID && !isLoaded) {
    grid.fetchMore(
      {
        variables: {
          pagination: { offset: 0, limit: end.row + 1 },
        },
      },
      () => grid.copyToClipboard(start, end)
    );
  } else {
    grid.copyToClipboard(start, end);
  }
};

export const PasteClipboard: GridKeyHandler = (event, handle) => {
  const { grid, start } = handle;
  grid.pasteClipboard(start);
};

export const CutToClipboard: GridKeyHandler = (event, handle) => {
  const { grid, start, end } = handle;
  const isLoaded = isLinesLoaded(handle);
  if (grid.type === GridType.ESTIMATE_GRID && !isLoaded) {
    grid.fetchMore(
      {
        variables: {
          pagination: { offset: 0, limit: end.row + 1 },
        },
      },
      () => {
        grid.copyToClipboard(start, end);
        ClearSelectedRange(event, handle);
      }
    );
  } else {
    grid.copyToClipboard(start, end);
    ClearSelectedRange(event, handle);
  }
};

export const UndoLastMutation: GridKeyHandler = (event, handle) => {
  const { grid } = handle;
  grid.undoLastMutation();
};

export const DefaultKeypress: GridKeyHandler = (event, handle) => {
  // In the case that there are several very fast key inputs in a row here,
  // sometimes the editor might not spawn for another key or two. In that case
  // we want to avoid dropping the keystrokes, so we place them in a position-keyed
  // buffer (so they don't accidentally get used for any other position, ever,
  // and that we don't accidentally overwrite any other position's buffer if updates
  // to that position are happening asynchronously (i.e. after focus has moved).
  const { grid } = handle;
  if (event.key.length === 1 && grid.isRenderingEditor === KeyBufferState.BUFFERING) {
    // Only add keys to the buffer if they're 'texty'.
    // Our buffering, as such, is append-only - backspace won't work.
    grid.addKeyToBuffer(handle.start, event.key);
    return;
  }
  // TODO: Is there a better way to identify "texty" keys?
  if (!handle.editing && grid.isCellEditable(handle.start) && event.key.length === 1) {
    const { type } = grid.data.columns[handle.start.column];
    if (typeToEditCell(type)) {
      grid.isRenderingEditor = KeyBufferState.BUFFERING;
      // Wipe the cache in case hasty edits put anything in here earlier.
      grid.getKeyBufferString(handle.start);
      grid.addKeyToBuffer(handle.start, event.key);
      handle.setEditing(true);
      handle.setEditorPosition(handle.start);
      // Pretend as if we're "pasting" this key in...
      // TODO: rename
      const value = deserializeClipboardCell(
        type,
        event.key,
        handle.start.column,
        handle.start.row
      );
      if (value) handle.setEditorDefaultValue(value);
    }
  }
};
