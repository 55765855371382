import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { trim } from 'validator';

import { useReactiveVar } from '@apollo/client';

import {
  addToMilestoneEventTypes,
  reportAddToMilestone,
} from '../../../../analytics/analyticsEventProperties';
import {
  ADD_TO_MILESTONE_MODAL_DEFAULT,
  addToMilestoneVar,
  importEstimateIsPublishedVar,
  projectCompsSetInputVar,
  setReactiveLocal,
} from '../../../../api/apollo/reactiveVars';
import {
  AddToMilestoneModal,
  AddToMilestoneStates,
  EstimateType,
  JoinProjectRoutes,
} from '../../../../api/gqlEnums';
import {
  ADD_TO_MILESTONE_REACTIVE_VAR,
  MASTERFORMAT_CATEGORIZATION_ID,
  NULL_ID,
  UNIFORMAT_CATEGORIZATION_ID,
} from '../../../../constants';
import {
  CostReportColumnType,
  CreateEstimateFromProjectCompMutation,
} from '../../../../generated/graphql';
import useAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { generateSharedPath } from '../../../../utilities/routes/links';
import JoinDialogMulti from '../../../Dialogs/JoinDialog/JoinDialogMulti';
import { JoinDialogType } from '../../../Dialogs/JoinDialog/JoinDialogUtils';
import { MilestoneDetailsTabs } from '../../../Milestone/utils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { useCreateEstimateFromProjectComp } from '../../hooks/useCreateEstimateFromProjectCompMutation';
import AddToAMilestoneSelection from '../Modals/AddToAMilestoneSelection/AddToAMilestoneSelection';
import AddToMilestoneEstimatePublished, {
  publishedType,
} from '../Modals/AddToMilestoneEstimatePublished/AddToMilestoneEstimatePublished';
import AddToNewMilestoneConfirmation from '../Modals/AddToNewMilestoneConfirmation/AddToNewMilestoneConfirmation';

const headerText = 'Add your estimate to a milestone';

const contentHeader1AddToMilestone = '';

export const openAddToMilestoneOnboarding = (averageCost: string, resetModal = false) => {
  const addToMilestoneOnboarding = addToMilestoneVar();
  const value = {
    ...addToMilestoneOnboarding,
    averageCost,
    modal: AddToMilestoneModal.ADD_TO_MILESTONE,
    modalIsOpen: true,
  };
  if (resetModal || value.modal === AddToMilestoneModal.ESTIMATE_PUBLISHED) {
    value.modal = AddToMilestoneModal.ADD_TO_MILESTONE;
  }
  setReactiveLocal(addToMilestoneVar, ADD_TO_MILESTONE_REACTIVE_VAR, value);
};

const getAddToMilestoneNextText = (
  t: TermStore,
  state: AddToMilestoneStates,
  type: CostReportColumnType
) => {
  switch (state) {
    case AddToMilestoneStates.EXISTING_MILESTONE_NO_ESTIMATE:
      return 'Publish';
    case AddToMilestoneStates.EXISTING_MILESTONE_WITH_ESTIMATE:
      return `Replace ${publishedType(t, type)}`;
    case AddToMilestoneStates.NEW_MILESTONE:
      return 'Next';
    default:
      return 'Next';
  }
};

const AddToMilestoneManager = (props: { projectId: string }) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);

  const {
    categorizationName,
    categorizationNameError,
    date: selectedMilestoneDate,
    modal: modalStored,
    modalIsOpen,
    milestoneDesignPhase: selectedMilestoneDesignPhase,
    milestoneID: selectedMilestoneID,
    milestoneName: selectedMilestoneName,
    state,
    type: selectedType,
  }: AddToMilestoneOnboardingParameters = useReactiveVar(addToMilestoneVar);

  const { projectCompInputs } = useReactiveVar(projectCompsSetInputVar);
  const isBuiltIn =
    projectCompInputs.every((pc) => pc.categorizationID === UNIFORMAT_CATEGORIZATION_ID) ||
    projectCompInputs.every((pc) => pc.categorizationID === MASTERFORMAT_CATEGORIZATION_ID);

  const sendAnalytics = useAnalyticsEventHook();
  const [loading, setLoading] = useState(false);
  const [isNewDraftMilestone, setIsNewDraftMilestone] = useState(false);

  const openModal = (modal: AddToMilestoneModal) => {
    setReactiveLocal(addToMilestoneVar, ADD_TO_MILESTONE_REACTIVE_VAR, {
      ...addToMilestoneVar(),
      modal,
    });
    sendAnalytics(
      reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_VISIT_MENU, { modal })
    );
  };

  const resetModal = () =>
    setReactiveLocal(addToMilestoneVar, ADD_TO_MILESTONE_REACTIVE_VAR, {
      ...ADD_TO_MILESTONE_MODAL_DEFAULT,
      modal: addToMilestoneVar().modal,
      modalIsOpen: true,
    });

  const closeResetModal = () =>
    setReactiveLocal(
      addToMilestoneVar,
      ADD_TO_MILESTONE_REACTIVE_VAR,
      ADD_TO_MILESTONE_MODAL_DEFAULT
    );

  const modal: AddToMilestoneModal | undefined = modalIsOpen ? modalStored : undefined;
  const isNewMilestone = selectedMilestoneID === NULL_ID;

  const cancel = (isFinish?: boolean) => {
    setReactiveLocal(addToMilestoneVar, ADD_TO_MILESTONE_REACTIVE_VAR, {
      ...addToMilestoneVar(),
      modalIsOpen: false,
      categorizationName: '',
      categorizationNameError: undefined,
    });
    if (!isFinish)
      sendAnalytics(reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_CANCEL_MENU));
  };

  const onSuccess = ({
    id: milestoneID,
  }: CreateEstimateFromProjectCompMutation['createEstimateFromProjectComp']) => {
    setLoading(false);
    const type = selectedType;
    const milestoneName = selectedMilestoneName;
    resetModal();
    addToMilestoneVar({
      ...addToMilestoneVar(),
      milestoneID,
      milestoneName,
      type,
    });

    importEstimateIsPublishedVar(true);
    openModal(AddToMilestoneModal.ESTIMATE_PUBLISHED);
    sendAnalytics(reportAddToMilestone(addToMilestoneEventTypes.ADD_TO_MILESTONE_FINISH_MENU));
  };
  const onFailure = () => {
    setLoading(false);
  };
  const estimateType: EstimateType =
    selectedType === CostReportColumnType.ESTIMATE_REPORT
      ? EstimateType.ACTIVE_ESTIMATE
      : EstimateType.BUDGET;

  const [createEstimateFromProjectComp] = useCreateEstimateFromProjectComp();

  const finalize = (
    isDraft: boolean,
    analyticsEvent: addToMilestoneEventTypes,
    selectedType: CostReportColumnType
  ) => {
    if (loading) return;
    const milestoneInput: MilestoneInput = isNewMilestone
      ? {
          projectID: props.projectId,
          name: selectedMilestoneName,
          date: selectedMilestoneDate,
          isDraft,
          designPhaseID: selectedMilestoneDesignPhase?.id,
        }
      : {
          id: selectedMilestoneID,
          projectID: props.projectId,
          isDraft,
          date: selectedMilestoneDate,
          designPhaseID: selectedMilestoneDesignPhase?.id,
        };

    setIsNewDraftMilestone(isNewMilestone && isDraft);

    // TODO - take categorization name from modal input
    const estimateCreationInput: EstimateCreationInput = {
      categorizationName: trim(categorizationName || ''),
      estimateType,
      milestoneInput,
    };

    createEstimateFromProjectComp(props.projectId, estimateCreationInput, onSuccess, onFailure);
    sendAnalytics(reportAddToMilestone(analyticsEvent, { selectedType }));
  };

  const onClickMilestoneDetails = (navigate: NavigateFunction) => {
    if (selectedMilestoneID === NULL_ID) return;
    navigate(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: props.projectId,
        milestoneId: selectedMilestoneID,
        search: `?view=${
          selectedType === CostReportColumnType.ESTIMATE_REPORT
            ? MilestoneDetailsTabs.ESTIMATE
            : MilestoneDetailsTabs.TARGET
        }`,
      })
    );
  };

  const getAddToMilestoneNextAction = () => {
    const isCustomCatzAndEmptyCatzName = !isBuiltIn && !categorizationName;
    const isAllMilestoneInputsEntered =
      !selectedMilestoneName || !selectedMilestoneDate || !selectedMilestoneDesignPhase;
    const isAddToAMilestoneSelectionNextButton =
      !(isNewMilestone && isAllMilestoneInputsEntered) &&
      !categorizationNameError &&
      !isCustomCatzAndEmptyCatzName;

    if (!isAddToAMilestoneSelectionNextButton) return undefined;
    switch (state) {
      case AddToMilestoneStates.EXISTING_MILESTONE_NO_ESTIMATE:
        return () =>
          finalize(false, addToMilestoneEventTypes.ADD_TO_MILESTONE_PUBLISH_ESTIMATE, selectedType);
      case AddToMilestoneStates.EXISTING_MILESTONE_WITH_ESTIMATE:
        return () =>
          finalize(false, addToMilestoneEventTypes.ADD_TO_MILESTONE_REPLACE_ESTIMATE, selectedType);
      case AddToMilestoneStates.NEW_MILESTONE:
        return () => openModal(AddToMilestoneModal.AS_DRAFT_OR_PUBLISH);
      default:
        return undefined;
    }
  };

  const dialogs: JoinDialogType[] = [
    {
      open: modal === AddToMilestoneModal.ADD_TO_MILESTONE,
      onOpen: () => openModal(AddToMilestoneModal.ADD_TO_MILESTONE),
      onClose: () => cancel(),
      onNext: getAddToMilestoneNextAction(),
      onNextText: getAddToMilestoneNextText(t, state, selectedType),
      onBack: () => cancel(),
      onBackText: 'Cancel',
      contentHeader1: contentHeader1AddToMilestone,
      contentHeader2: '',
      contentComponent: <AddToAMilestoneSelection projectID={props.projectId} />,
      headerText,
    },
    {
      open: modal === AddToMilestoneModal.AS_DRAFT_OR_PUBLISH,
      onOpen: () => openModal(AddToMilestoneModal.AS_DRAFT_OR_PUBLISH),
      onClose: () => cancel(),
      onNext: undefined,
      onBack: undefined,
      contentHeader1: contentHeader1AddToMilestone,
      contentHeader2: '',
      contentComponent: (
        <AddToNewMilestoneConfirmation
          estimateType={selectedType}
          milestoneName={selectedMilestoneName}
        />
      ),
      actionButtons: [
        {
          isLeftAligned: true,
          color: 'primary',
          variant: 'outlined',
          onClick: () => openModal(AddToMilestoneModal.ADD_TO_MILESTONE),
          text: 'Back',
        },
        {
          color: 'primary',
          dataCy: 'button-createDraft',
          variant: 'outlined',
          onClick: () =>
            finalize(true, addToMilestoneEventTypes.ADD_TO_MILESTONE_CREATE_DRAFT, selectedType),
          text: 'Create as Draft',
        },
        {
          color: 'primary',
          dataCy: 'button-createPublish',
          variant: 'contained',
          onClick: () =>
            finalize(false, addToMilestoneEventTypes.ADD_TO_MILESTONE_CREATE_PUBLISH, selectedType),
          text: 'Create and Publish',
        },
      ],
      headerText,
    },
    {
      open: modal === AddToMilestoneModal.ESTIMATE_PUBLISHED,
      onOpen: () => openModal(AddToMilestoneModal.ESTIMATE_PUBLISHED),
      onClose: () => cancel(),
      onNext: () => {
        cancel(true);
        closeResetModal();
      },
      onNextText: 'Done',
      onBack: () => {
        cancel(true);
        closeResetModal();
        onClickMilestoneDetails(navigate);
      },
      onBackText: `Continue to ${publishedType(t, selectedType)}`,
      contentHeader1: contentHeader1AddToMilestone,
      contentHeader2: '',
      contentComponent: (
        <AddToMilestoneEstimatePublished
          estimateType={selectedType}
          milestoneName={selectedMilestoneName}
          isNewDraftMilestone={isNewDraftMilestone}
        />
      ),
      headerText,
    },
  ];
  return <JoinDialogMulti dialogs={dialogs} disablePageCounter dynamicHeight />;
};

export default AddToMilestoneManager;
