import { FC, useState } from 'react';

import { FormControl, Input, MenuItem, Select, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import BorderlessDropDownArrow from '../DropDownArrow/BorderlessDropDownArrow';
import { SelectEntry } from '../JoinSelect/JoinSelect';

import styles, { menuPropsDefault } from './BorderlessJoinSelectStyles';

// NOTE: PLEASE AVOID USING THIS COMPONENT
// JoinSelect should be reusable, and allow us to configure styles from outside the component

// the logic of this component was copied from JoinSelect
// to use existing patterns with new styles applied
type BorderlessJoinSelectProps = {
  className?: string | undefined;
  classes: Classes<typeof styles>;
  cySelect?: string | undefined;
  valuePlaceholder?: string | undefined;
  entries: SelectEntry[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (entry: any) => void;
  value?: string;
};

const BorderlessJoinSelect: FC<BorderlessJoinSelectProps> = ({
  className = undefined,
  classes,
  cySelect = undefined,
  valuePlaceholder,
  entries = [],
  onChange,
  value,
}) => {
  const rootItemPaddingStyle = `${classes.rootItem} ${classes.rootItemCompact}`;
  const selectedStyle = classes.selected;

  const [open, setOpen] = useState(false);

  const renderEntry = (
    entry: SelectEntry,
    isSelect?: boolean,
    valClass?: string,
    selectClass?: string
  ) => {
    const {
      disabled: entryDisabled,
      id,
      name,
      rightText,
      rightIcon,
      leftIcon,
      title: entryTitle,
      subName,
    } = entry;
    const isPlaceholder = name === valuePlaceholder;
    return (
      <MenuItem
        classes={{
          root: `${rootItemPaddingStyle} ${selectClass}`,
          selected: selectedStyle,
        }}
        disabled={entryDisabled}
        value={id}
        key={id}
        data-cy={isSelect ? undefined : `select-${name}`}
        disableRipple
      >
        <div className={classes.row}>
          {leftIcon && leftIcon}
          <div className={classes.fullWidth}>
            <div className={classes.horizontal}>
              <Typography
                className={`${
                  isPlaceholder ? classes.borderlessSelected : classes.borderlessName
                } ${leftIcon ? classes.marginLeft : ''} ${valClass} ${subName && classes.nameBold}`}
                title={entryTitle}
              >
                {name}
              </Typography>
              {rightText && (
                <Typography
                  className={`${classes.name} ${valClass} ${subName && classes.nameBold}`}
                >
                  {rightText}
                </Typography>
              )}
              {!isSelect && rightIcon}
            </div>
            {subName && (
              <Typography
                className={`${classes.name} ${classes.subName} ${
                  leftIcon ? classes.marginLeft : ''
                }`}
              >
                {subName}
              </Typography>
            )}
          </div>
        </div>
      </MenuItem>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const renderSelectValue = (value: any) => {
    return renderEntry(
      {
        ...(entries.find(({ id }) => id === value) || ({ name: valuePlaceholder } as SelectEntry)),
        subName: undefined,
      },
      true,
      !value ? classes.defaultText : undefined,
      classes.selectValue
    );
  };

  const selectRootClasses = `${classes.hideBorder} ${classes.bgnWhite}`;

  return (
    <FormControl className={classes.hidePrint}>
      <Select
        classes={{
          icon: classes.hideBorder,
          root: selectRootClasses,
          selectMenu: classes.borderlessSelectMenu,
        }}
        data-cy={cySelect}
        disableUnderline
        displayEmpty
        IconComponent={() => <BorderlessDropDownArrow onClick={() => setOpen(true)} />}
        input={<Input classes={{ root: classes.hideBorder }} className={classes.bgnWhite} />}
        MenuProps={{
          ...menuPropsDefault,
          PopoverClasses: {
            paper: classes.locationCompact,
          },
          MenuListProps: {
            className: classes.borderBox,
          },
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        className={className}
        open={open}
        renderValue={renderSelectValue}
        value={value}
      >
        {entries.map((entry) => renderEntry(entry))}
      </Select>
    </FormControl>
  );
};

/** @deprecated in favor of design system component, please use scales/Select */
export default withStyles(styles)(BorderlessJoinSelect);
