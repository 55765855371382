import { FC, MouseEvent } from 'react';

import { Button } from '@material-ui/core';

import { DEFAULT } from '../../constants';
import theme, { withStyles } from '../../theme/komodo-mui-theme';

import styles from './CTAIconButtonStyles';

type CTAIconButtonProps = {
  background?: string;
  buttonText: string;
  classes: Classes<typeof styles>;
  color?: string;
  dataCy?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconRight?: JSX.Element;
  isSmaller?: boolean;
  isFullWidth?: boolean;
  marginLeft?: number;
  marginRight?: number;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
  reverseContent?: boolean;
  variant?: 'contained' | 'outlined';
  colorVariant?: boolean;
};

const CTAIconButton: FC<CTAIconButtonProps> = ({
  background,
  buttonText,
  classes,
  color,
  dataCy,
  disabled,
  isSmaller,
  isFullWidth,
  icon,
  iconRight,
  marginLeft,
  marginRight,
  onClick,
  reverseContent,
  variant = 'contained',
  colorVariant,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  let buttonStyle = { marginLeft, marginRight } as any;

  const baseButtonClass =
    variant === 'contained'
      ? `${classes.button} ${classes.buttonPrimaryFocus}`
      : `${classes.button} ${classes.buttonHover}`;

  const colorButtonClass =
    variant === 'contained'
      ? `${classes.button} ${classes.buttonPrimaryFocusYellow}`
      : `${classes.button} ${classes.buttonHoverYellow}`;

  const variantButtonClass = colorVariant ? colorButtonClass : baseButtonClass;

  if (color) {
    if (variant === 'contained' && colorVariant) {
      buttonStyle = {
        backgroundColor: color,
        color: theme.palette.joinPrimary,
        '&:hover': {
          backgroundColor: theme.palette.joinPrimary,
          color,
          opacity: 0.5,
        },
        marginLeft,
        marginRight,
      };
    } else if (variant === 'contained' && !colorVariant) {
      buttonStyle = {
        backgroundColor: color,
        color: theme.palette.joinPrimary,
        marginLeft,
        marginRight,
      };
    } else if (colorVariant) {
      buttonStyle = {
        border: `${color} 1px solid`,
        color,
        backgroundColor: theme.palette.joinPrimary,
        marginLeft,
        marginRight,
      };
    } else {
      buttonStyle = {
        border: `${color} 1px solid`,
        color,
        '&:hover': {
          backgroundColor: theme.palette.backgroundWhite,
        },
        marginLeft,
        marginRight,
      };
    }
  }
  if (background) {
    buttonStyle = {
      background,
      border: `${color} 1px solid`,
      marginLeft,
    };
  }
  if (isFullWidth) {
    buttonStyle.width = '100%';
    buttonStyle.margin = 0;
  }

  // special case: default to grey
  const isDefault = color === DEFAULT;

  const textClass = `${classes.buttonText} ${reverseContent && classes.buttonTextReverse} ${
    isSmaller ? classes.titlebarText : ''
  }`;
  const buttonClass = `${variantButtonClass} ${reverseContent && classes.buttonReverse} ${
    isSmaller ? classes.titlebarButton : ''
  }`;

  return (
    <Button
      aria-label={buttonText}
      data-cy={dataCy}
      disabled={disabled}
      variant={variant}
      color={isDefault ? undefined : 'primary'}
      className={buttonClass}
      onClick={onClick}
      style={buttonStyle}
      title={buttonText}
    >
      <div className={`${classes.content} ${reverseContent && classes.reverseContent}`}>
        {icon && icon}
        <div className={textClass}>{buttonText}</div>
        {iconRight}
      </div>
    </Button>
  );
};

/** @deprecated in favor of design system component, please use scales/IconButton or scales/Button */
export default withStyles(styles)(CTAIconButton);
