import { FC } from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeCountString } from '../../../utilities/string';
import JoinSlide from '../../shared-widgets/JoinSlide';

import styles from './SelectCategoryStyles';
import { bulkToggleOption } from './SelectCategoryUtils';

interface SelectCategoryBulkOptionProps {
  classes: Classes<typeof styles>;
  contentListLength: number;
  handleClick: (target: 'category' | 'parent' | 'subCategories' | 'bulkToggle') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleHover: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  highlightedCategory: any;
  noun: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  selectedCategories: any[];
  slideDirection: 'left' | 'right';
}

const SelectCategoryBulkOption: FC<SelectCategoryBulkOptionProps> = ({
  classes,
  contentListLength,
  handleClick,
  handleHover,
  highlightedCategory,
  noun,
  selectedCategories,
  slideDirection,
}) => {
  // DISPLAY LOGIC
  // highlighted = hover, tab-ing through
  // selected = is checked
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const isHighlightedSelectOption = (selectOption: any) =>
    highlightedCategory.id === selectOption.id;
  const bulkToggleIsHighlighted = isHighlightedSelectOption(bulkToggleOption);
  const selectedCategoriesLength = selectedCategories.length;
  const showSelectAll = selectedCategoriesLength === 0;
  const showClearAll = !showSelectAll;
  const hasSelectedCategoryClass = showClearAll ? classes.clearContentOption : '';
  const isSelected = selectedCategories.length === contentListLength;

  // UI GENERATORS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const generateHighlightedClass = (selectOption: any) =>
    isHighlightedSelectOption(selectOption) && classes.categoryHighlighted;
  const selectedMessage = pluralizeCountString(noun, selectedCategories.length);
  const message = showClearAll ? selectedMessage : 'Select All';
  return (
    <JoinSlide direction={slideDirection}>
      <li
        aria-selected={bulkToggleIsHighlighted}
        className={`${hasSelectedCategoryClass} ${
          classes.categoryBulkToggle
        } ${generateHighlightedClass(bulkToggleOption)}`}
        key="totalSelected"
        onClick={() => {
          handleClick('bulkToggle');
        }}
        onKeyDown={() => {
          handleClick('bulkToggle');
        }}
        onMouseEnter={() => {
          handleHover(bulkToggleOption);
        }}
        role="option"
        title={selectedMessage}
        value={0}
      >
        <div data-cy="SelectCategoryBulkOption-message">
          <Checkbox
            classes={{ root: classes.checkboxRoot }}
            indeterminate={showClearAll}
            checked={isSelected}
          />
          {message}
        </div>
        {showClearAll && <span className={classes.underline}> clear</span>}
      </li>
    </JoinSlide>
  );
};

export default withStyles(styles)(SelectCategoryBulkOption);
