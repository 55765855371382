import { DeleteOutline } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalyticsEventHook from '../../../../hooks/useAnalyticsEventHook';
import { pluralizeCountString } from '../../../../utilities/string';
import { Button, Dialog, DialogContent } from '../../../scales';
import { useDeleteProjectTypeMutation } from '../../CompanyHooks';

export type Props = {
  isBlocked?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onProjectStats: () => void;
  type: ProjectType;
  typeProjectsCount: number;
  templateCount: number;
  subTypes: ProjectType[];
  subTypesProjectsCountMap: { [key in UUID]: { projectCount: number; templateCount: number } };
};

export default function DeleteProjectTypeDialog(props: Props) {
  const deleteProjectType = useDeleteProjectTypeMutation();
  const sendAnalytics = useSendAnalyticsEventHook();
  const analyticsEventProperties = {
    typeName: props.type.name,
    typeLevel: props.type.parentID ? 'subtype' : 'type',
    existingConflict: props.isBlocked,
  };
  const onDelete = () => {
    deleteProjectType(props.type.id);
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_DELETE_CONFIRM,
        analyticsEventProperties
      )
    );
    props.onClose?.();
  };
  const onProjectStats = () => {
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_DELETE_STATS_LINK,
        analyticsEventProperties
      )
    );
    props.onClose?.();
    props.onProjectStats();
  };
  const onClose = () => {
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_DELETE_CLOSE_CANCEL,
        analyticsEventProperties
      )
    );
    props.onClose?.();
  };
  const disabled = Boolean(props.isBlocked);

  const getCountText = (count: number | undefined) => pluralizeCountString(`project`, count ?? 0);

  const renderType = (type: ProjectType) => (
    <strong className="type-body1" key={type.id}>{`${type.name} (${getCountText(
      props.typeProjectsCount
    )}${
      props.templateCount ? ` / ${pluralizeCountString(`template`, props.templateCount ?? 0)}` : ''
    } total)`}</strong>
  );
  const renderSubtype = (type: ProjectType) => {
    const value = props.subTypesProjectsCountMap[type.id];
    if (!value) return null;
    return (
      <strong className="type-body1" key={type.id}>{`${type.name} (${getCountText(
        value.projectCount
      )}${
        value.templateCount ? ` / ${pluralizeCountString(`template`, value.templateCount)}` : ''
      })`}</strong>
    );
  };

  return (
    <Dialog
      {...props}
      onClose={onClose}
      title={`Delete Project Type: ${props.type.name}`}
      footerLeft={
        disabled && <Button label="Go to Project Stats" type="secondary" onClick={onProjectStats} />
      }
      footerRight={
        disabled ? (
          <Button
            label="OK"
            type="primary"
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(
                  CompanyAdminEventType.PROJECT_TYPES_DELETE_OK,
                  analyticsEventProperties
                )
              );
              props.onClose?.();
            }}
          />
        ) : (
          <Button
            label="Delete"
            startIcon={<DeleteOutline />}
            type="destructive"
            disabled={disabled}
            onClick={onDelete}
          />
        )
      }
    >
      <DialogContent className="flex flex-col gap-2">
        {disabled ? (
          <div>
            <div className="pb-8 type-body1">
              We cannot delete this project type because there are still projects assigned to this
              project type and its related subtypes.
            </div>
            <div className="flex max-h-40 flex-col overflow-y-auto py-1">
              <p>{renderType(props.type)}</p>
              <div className="pl-8">{props.subTypes.map(renderSubtype)}</div>
            </div>
            <div className="pt-8 type-body1">
              You can use the Project Stats button below to reassign projects, then try again.
            </div>
          </div>
        ) : (
          <div className="type-body1">
            Are you sure? By deleting this project type, this type and all its subtypes will be
            deleted as well. Once deleted, your teammates will no longer have these options
            available to select for Project Type.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
