import { useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import {
  ListCategoriesByIDsQuery,
  ListCategoriesQuery,
  ListSubcategoriesQuery,
} from '../../../generated/graphql';
import { listSubcategoriesQuery } from '../../../hooks/queries';
import useListCategoriesQuery from '../../../hooks/useListCategoriesQuery';
import { removeYear } from '../../../utilities/string';
import { MultiSelect, SelectEntry } from '../../scales';
import { categoryDisplayName } from '../../Select/SelectCategory/SelectCategoryUtils';

import useListCategoriesByIDsQuery from './useListCategoriesByIDsQuery';

type Category = ListCategoriesQuery['category'][number];
type Subcategory = ListSubcategoriesQuery['subcategory'][number];
export type CategorySelectEntry = Category | Subcategory;

type Props = {
  categorization: { name: string; id: UUID; builtin: boolean };
  'data-cy'?: string;
  isDisabled?: boolean;
  onChange: (value: UUID[]) => void;
  value: UUID[];
};

export default function CategoryMultiSelect(props: Props) {
  const [searchString, setSearchString] = useState('');
  const [entries, setEntries] = useState<SelectEntry[]>([]);

  const onCompleted = (categories: Category[]) => {
    setEntries((prevState) => {
      const prevIDs = prevState.map((e) => e.id);
      const categoryEntries = categories.map((c) => formatEntry(c));
      return [...prevState, ...categoryEntries.filter((c) => !prevIDs.includes(c.id))];
    });
  };

  /*
  The following queries handle fetching our category data. After each query is completed, 
  we format them as SelectEntry values and update the entries state. 

  listCategories -> queries the top level categories for the current categorization

  listSubcategories -> queries the subcategories for a category. We fire this query
  when a category in the selector gets hovered

  listCategoriesByIDs -> queries the categories based on the list of selected categoryIDs. 
  This is so that we have the category data available after the component rerenders and we may
  not yet have queried subcategories upon hover (required for category data in the chip display).
  */

  const level = searchString.length ? undefined : 1;
  useListCategoriesQuery(props.categorization?.id, searchString, level, undefined, {
    onCompleted: (data: ListCategoriesQuery) => onCompleted(data.category),
  });

  useListCategoriesByIDsQuery(props.categorization.id, props.value, {
    onCompleted: (data: ListCategoriesByIDsQuery) => onCompleted(data.categories),
  });

  const [fetchSubcategories] = useLazyQuery(listSubcategoriesQuery, {
    onCompleted: (data: ListSubcategoriesQuery) => onCompleted(data.subcategory),
  });

  const onHoverEntry = (entryID: UUID) => {
    fetchSubcategories({
      variables: {
        search: {
          categorizationID: props.categorization.id,
          categoryID: entryID,
          limit: 50,
        },
      },
    });
  };

  return (
    <MultiSelect
      data-cy={props['data-cy'] ?? 'category-select'}
      entries={entries}
      isAllSelectable
      isClearable
      isDisabled={props.isDisabled}
      isSearchable
      label={removeYear(props.categorization?.name, props.categorization?.builtin)}
      onChange={props.onChange}
      onSearchChange={setSearchString}
      onHoverEntry={onHoverEntry}
      placeholder="Select Categories"
      value={props.value}
    />
  );
}

const formatEntry = (c: Category) => ({
  id: c.id,
  parentID: c.parentID,
  label: categoryDisplayName({
    categorization: { id: c.categorization?.id ?? '', name: c.categorization?.name ?? '' },
    id: c.id,
    name: c.name,
    number: c.number,
  }),
  hasChildren: c.hasSubCategories,
});
