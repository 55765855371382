import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      height: 48,
      paddingTop: 4,
      paddingBottom: 4,
      display: 'flex',
      alignItems: 'center',
    },
    focused: {
      background: 'none',
    },
    iconButton: {
      height: 20,
      margin: 'auto',
      marginRight: 8,
      width: 20,
      '& svg': {
        fontSize: 20,
        margin: 4,
        color: theme.palette.joinPrimary,
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '40px',
      ...theme.typography.webParagraph,
    },
    searchBox: {
      boxSizing: 'border-box',
      width: '100%',
      minWidth: 600,
    },
    searchIcon: {
      color: theme.palette.joinPrimary,
      marginLeft: theme.spacing.generic[3],
      width: 24,
      height: 24,
    },
  });
