import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { FilesDialogView, filesAssetsSortByVar } from '../../../../api/apollo/reactiveVars';
import { FilesSortBy, FilesSortKey, SortDirection } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { useUserProfile } from '../../../../utilities/userProfile';
import useProjectAssetsQuery from '../../../assets/hooks/useProjectAssetsQuery';
import NavBreadcrumb from '../../../shared-widgets/NavigationBar/NavBreadcrumb/NavBreadcrumb';
import useMemoWrapper from '../../../useMemoWrapper';
import styles from '../../FileExplorer/FilesExplorerStyles';
import { getUploadedAssetEntries } from '../../FileExplorer/FilesExplorerUtils';
import FilesTable from '../FilesTable/FilesTable';
import { useFilesTableSelection } from '../FilesTable/FilesTableUseUtils';
import { getEntryIsPending } from '../FilesTable/FilesTableUtils';

import FilesAddUploadDrag from './FilesAddUploadDrag';

export const FILES_ASSETS_DEFAULT_SORT = {
  sortDirection: SortDirection.SORT_DESCENDING,
  sortKey: FilesSortKey.DATE,
};

type FilesAssetsProps = {
  addFiles: (files: FileList | null) => void;
  classes: Classes<typeof styles>;
  disabledIDs: UUID[];
  isFilteredToUser?: boolean;
  isZeroState: boolean;
  setIsZeroState: (setZero: boolean) => void;
};

const FilesAssets: FC<FilesAssetsProps> = ({
  addFiles,
  classes,
  disabledIDs,
  isFilteredToUser,
  isZeroState,
  setIsZeroState,
}) => {
  const userInfo = useUserProfile();
  const projectId = getProjectIdFromUrl();
  const sortBy = useReactiveVar(filesAssetsSortByVar);

  const { loading, data } = useProjectAssetsQuery(projectId, sortBy);
  const projectAssets = data?.projectAssets ?? [];
  const filteredAssets = projectAssets.filter(
    (a) => !isFilteredToUser || a?.uploadedBy?.email === userInfo?.email || getEntryIsPending(a)
  );
  // entityAssetIDs are either asset remote IDs or enttity ids;
  const entries = useMemoWrapper(getUploadedAssetEntries, filteredAssets, disabledIDs);
  const { onToggleEntry, selectedIds, onSelectAll, onUnselectAll } = useFilesTableSelection();

  useEffect(() => {
    if (entries.length === 0 && !loading) {
      setIsZeroState(true);
    } else {
      setIsZeroState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [entries]);

  const sortManager = {
    sortState: sortBy,
    setSort: (state: FilesSortBy) => filesAssetsSortByVar(state),
  };

  return (
    <div className={classes.sourceContent}>
      <div className={classes.sourceHeader}>
        <NavBreadcrumb
          isActive
          onClick={() => {}}
          value={isFilteredToUser ? FilesDialogView.UPLOADED_FILES : FilesDialogView.ALL_FILES}
        />
      </div>
      {isZeroState && !loading ? (
        <FilesAddUploadDrag addFiles={addFiles} />
      ) : (
        <FilesTable
          entries={entries}
          loading={loading}
          onEntry={() => {}}
          onToggleEntry={onToggleEntry}
          selectedIds={selectedIds}
          sortManager={sortManager}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(FilesAssets);
