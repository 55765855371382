import { TextField, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './DialogsForecastingReportContentStyles';

const DialogsForecastingReportContent = (props: {
  classes: Classes<typeof styles>;
  onSubmit: () => void;
  reportInput: ForecastingReportInput;
  setReportInput: React.Dispatch<React.SetStateAction<ForecastingReportInput>>;
}) => {
  const { classes } = props;

  return (
    <div>
      <div className={classes.setting}>
        <Typography className={classes.header} variant="caption">
          Report Name *
        </Typography>
        <TextField
          data-cy="input-reportName"
          fullWidth
          InputProps={{
            className: classes.inputHeight,
            disableUnderline: true,
          }}
          onChange={(evt) => {
            props.setReportInput((prev) => ({ ...prev, name: evt.target.value }));
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !!props.reportInput.name) props.onSubmit();
          }}
          placeholder="Name this report..."
          value={props.reportInput.name}
        />
      </div>
      <div className={classes.setting}>
        <Typography className={classes.header} variant="caption">
          Description
        </Typography>
        <TextField
          data-cy="input-reportDescription"
          fullWidth
          InputProps={{
            className: classes.inputHeight,
            disableUnderline: true,
          }}
          multiline
          onChange={(evt) => {
            props.setReportInput((prev) => ({ ...prev, description: evt.target.value }));
          }}
          placeholder="Describe this report..."
          value={props.reportInput.description}
          rows={4}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(DialogsForecastingReportContent);
