import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import * as React from 'react';

import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { localeDateFormat, maskFn } from '../../../utilities/dates';

import styles from './DateFieldStyles';

type Props = {
  classes: Classes<typeof styles>;
  label?: string;
  onChange: (value: Date | null) => void;
  value: Date | null;
};

const DateField = (props: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        clearable
        format={localeDateFormat}
        InputLabelProps={{ className: props.classes.label, shrink: true }}
        InputProps={{
          disableUnderline: true,
          classes: {
            formControl: props.classes.formControlMargin,
            input: props.classes.input,
            root: props.classes.root,
          },
        }}
        invalidDateMessage="Not a valid date"
        keepCharPositions
        keyboard
        KeyboardButtonProps={{ classes: { root: props.classes.icon } }}
        label={props.label ?? 'Date'}
        mask={maskFn}
        placeholder="mm/dd/yyyy"
        onChange={props.onChange}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
            e.preventDefault(); // prevent display of calendar dialog

            // Skip processing of non-empty strings that won't create valid Date objects.
            if (e.target.value && Number.isNaN(Date.parse(e.target.value))) {
              return;
            }

            // We know there's a valid entry, blur the field to let the onChange take over.
            e.target.blur();
          }
        }}
        value={props.value}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(DateField);
