import { useParams } from 'react-router-dom';

import { getOperationName } from '@apollo/client/utilities';
import { Add, Help } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { currentCollaborator } from '../../../hooks/queries';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { isNonNullable } from '../../../utilities/types';
import useCreateCollaborators from '../../Collaborators/hooks/CreateCollaboratorsHook';
import { useCurrentUserQuery } from '../../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import { Button, Tooltip } from '../../scales';

import ModeBar from './ModeBar';

export default function ViewOnlyModeBar() {
  const sendAnalytics = useSendAnalyticsEventHook();

  const [createCollaborators] = useCreateCollaborators(
    [getOperationName(currentCollaborator)].filter(isNonNullable) // only include if operation name exists
  );

  const { projectId } = useParams();
  const currentUserEmail = useCurrentUserQuery().data?.currentUser.email;
  if (!projectId || !currentUserEmail) return null;

  return (
    <ModeBar
      data-cy="view-only-bar"
      title={
        <div className="flex gap-2">
          <div>View-Only Mode</div>
          <Tooltip
            content={
              'As Company Admin, you have access to view all your company projects. To make changes to a project, click on "Add yourself as Project Administrator".'
            }
          >
            <div className="icon-sm">
              <Help fontSize="inherit" />
            </div>
          </Tooltip>
        </div>
      }
    >
      <div className="ml-auto">
        <Button
          data-cy="view-only-bar-add-self-button"
          label="Add yourself as Project Administrator"
          onClick={() => {
            createCollaborators(
              projectId,
              [
                {
                  userEmail: currentUserEmail,
                  allTrades: true,
                  roleName: 'Administrator',
                  responsibility: '',
                  trades: [],
                },
              ],
              ''
            );
            sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.ADD_PROJECT_ADMIN));
          }}
          startIcon={<Add />}
          type="primary"
        />
      </div>
    </ModeBar>
  );
}
