import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Add, Close, Help } from '@material-ui/icons';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  collaboratorDialogOpenVar,
  defaultPreviewSettings,
  setPreviewSettingsVar,
  usePreviewSettingsVar,
} from '../../../api/apollo/reactiveVars';
import { PREVIEW_ROLE } from '../../../constants';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useRolesQuery from '../../../hooks/useRolesQuery';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useUserProfile } from '../../../utilities/userProfile';
import { INVITE_TEAMMATES } from '../../Dialogs/DialogsAddCollaborator/DialogsAddCollaborator';
import InputsSelectAssigneeData from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import { getCollaboratorEmail } from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import {
  SelectCategoryValue,
  isSelectCategoryInteraction,
  selectCategoryDisplayName,
} from '../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryPermissions from '../../Select/SelectCategoryPermissions/SelectCategoryPermissions';
import CTAIconButton from '../../shared-widgets/CTAIconButton';

import styles from './FrameTitlePreviewBarStyles';

type FrameTitlePreviewBarProps = {
  classes: Classes<typeof styles>;
};
export const previewTooltipCopy = `Preview allows you to test drive as another person to see how your teammates will experience this project in Join. You can choose any teammate or role to preview.`;

// TODO DD-741 Backcompat for the old nav
// The casts in this file could error because we need to currently support two
// shapes of `trades` in the reactive var. If you 1) Use the new nav and set
// preview trades 2) switch to old nav 3) stay in preview mode and reload the
// page, then you'll see errors. The errors as of now are a failure to correctly
// show the selected categories in the chips, and instead it'll show stuff like
// `Undefined - Uncategorized`. To workaround, exit preview mode; remove and
// reselect the trades; or clear local storage. This isn't a big enough error to
// try and mitigate otherwise.

const FrameTitlePreviewBar: FC<FrameTitlePreviewBarProps> = ({ classes }) => {
  // DATA HOOKS
  const projectId = getProjectIdFromUrl();
  const { data: { collaborators } = { collaborators: [] } } = useCollaboratorsQuery(projectId);
  const { data: { projectRoles: roles = [] } = { roles: [] } } = useRolesQuery(
    projectId,
    MountPolicy.SKIP_ON_MOUNT
  );
  const { previewUserId, previewRoleId, previewAllTrades, previewTrades } = usePreviewSettingsVar();
  const sendAnalytics = useAnalyticsEventHook();
  const { email: currentUserEmail } = useUserProfile() || {};

  // LOCAL STATE
  const isUserPreview = !!previewUserId;
  const collab = previewUserId ? collaborators.find((c) => c.user.id === previewUserId) : undefined;

  const role = previewRoleId ? roles.find((r: Role) => previewRoleId === r.id) : undefined;

  const userTrades = collab?.trades ?? [];
  const trades = previewUserId ? userTrades : previewTrades;

  const userAllTrades = collab?.allTrades ?? true;
  const allTrades = previewAllTrades ?? userAllTrades;

  const tradesText: string[] = userTrades.map((category) =>
    selectCategoryDisplayName(category as SelectCategoryValue)
  );

  // FUNCTIONS
  // User Selector
  const onChangeUser = (email: string | undefined) => {
    const selectedCollab = collaborators.find((c) => email === c.user.email);
    if (selectedCollab) {
      setPreviewSettingsVar({
        previewUserId: selectedCollab.user.id,
        previewRoleId,
      });
      sendAnalytics(
        previewPermissionsAnalytics(
          previewEventTypes.PREVIEW_SELECT_USER,
          previewTypes.USER,
          selectedCollab.role.name
        )
      );
    }
  };

  // Role Selector
  const onChangeRole = (roleID: UUID | null) => {
    const selectedRole = roles.find((r: Role) => roleID === r.id);
    if (selectedRole) {
      setPreviewSettingsVar({
        previewUserId: undefined,
        previewRoleId: selectedRole.id,
        previewAllTrades,
      });
      sendAnalytics(
        previewPermissionsAnalytics(
          previewEventTypes.PREVIEW_SELECT_ROLE_TYPE,
          previewTypes.ROLE,
          selectedRole.name
        )
      );
    }
  };

  const USER_TYPE = 'User';
  const ROLE_TYPE = 'Role';
  const onChangeMode = (previewType: string) => {
    // if there is no collaborator selected
    // then default to the current user
    const newCollab = collab || collaborators.find((c) => c.user.email === currentUserEmail);
    const { user } = newCollab || {};
    if (newCollab && user && previewType === USER_TYPE) {
      setPreviewSettingsVar({
        previewUserId: user.id,
        previewRoleId,
      });
      sendAnalytics(
        previewPermissionsAnalytics(
          previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
          previewTypes.USER,
          newCollab.role && newCollab.role.name
        )
      );
    } else {
      const newRole = role || roles[0];
      setPreviewSettingsVar({
        previewUserId: undefined,
        previewRoleId: (role || roles[0]).id,
        previewTrades: (trades as Category[]) || undefined,
        previewAllTrades: allTrades,
      });
      sendAnalytics(
        previewPermissionsAnalytics(
          previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
          previewTypes.ROLE,
          newRole.name
        )
      );
    }
  };

  const previewTypesList = isUserPreview ? [USER_TYPE, ROLE_TYPE] : [ROLE_TYPE, USER_TYPE];
  const previewTypesData = previewTypesList.map((pt: string) => getEntry(pt, pt));

  return (
    <div className={`${classes.bar} ${classes.previewBar}`}>
      <Typography data-cy="FrameTitlePreviewBar-PreviewModeTitle" className={classes.mode}>
        PREVIEW MODE
      </Typography>
      <div className={classes.info}>
        <NormalTooltip title={previewTooltipCopy}>
          <Help />
        </NormalTooltip>
      </div>
      <div className={classes.select}>
        <JoinSelect
          cySelect="preview-MenuSelect"
          entries={previewTypesData}
          isCompact={false}
          menuProps={{ PopoverClasses: { paper: classes.location } }}
          onChange={(type: string | null) => onChangeMode(type || USER_TYPE)}
          value={isUserPreview ? USER_TYPE : ROLE_TYPE}
        />
      </div>
      {collab && isUserPreview && (
        <div className={classes.modeContainer}>
          <div className={classes.select}>
            <InputsSelectAssigneeData
              dataCy="user-preview-select"
              disabled={false}
              selected={getCollaboratorEmail(collab)}
              selectedAssignee={(collab || {}).user}
              isCompact
              onChange={onChangeUser}
              projectId={projectId}
              variant={PREVIEW_ROLE}
            />
          </div>
          <div className={classes.role}>
            <Typography className={classes.userLabel}> ROLE: </Typography>
            <div className={classes.padding}>
              <Typography data-cy="FrameTitlePreviewBar-PreviewRole" className={classes.userName}>
                {collab.role.name}
              </Typography>
            </div>
            {userTrades.length > 0 && (
              <NormalTooltip title={tradesText}>
                <div className={classes.chip}>
                  <Typography
                    data-cy="FrameTitlePreviewBar-PreviewTrades"
                    className={classes.count}
                  >{`+${userTrades.length}`}</Typography>
                </div>
              </NormalTooltip>
            )}
          </div>
        </div>
      )}
      {!isUserPreview && role && (
        <div className={classes.modeContainer}>
          <div className={classes.select}>
            <JoinSelect
              cySelect="preview-MenuSelectRoleName"
              entries={roles}
              isCompact={false}
              menuProps={{ PopoverClasses: { paper: classes.location } }}
              onChange={onChangeRole}
              value={role.id}
            />
            {role.hasTrade && (
              <div data-cy="previewRole-SubTrades" className={classes.trade}>
                <SelectCategoryPermissions
                  isPreview={false}
                  addCategories={(newCategories: SelectCategoryValue[]) => {
                    const added = newCategories.filter(
                      (category) => !isSelectCategoryInteraction(category)
                    ); // remove the bulk toggle option!
                    if (added.length) {
                      // this actually removes bulk toggle
                      const previewTradeList = [...(trades || []), ...added];
                      setPreviewSettingsVar({
                        previewRoleId,
                        previewTrades: previewTradeList as Category[],
                        previewAllTrades: false,
                      });
                      sendAnalytics(
                        previewPermissionsAnalytics(
                          previewEventTypes.PREVIEW_SELECT_TRADES,
                          previewTypes.ROLE,
                          role.name
                        )
                      );
                    }
                  }}
                  hasAllCategoryPermissions={allTrades}
                  key="Collaborator-SelectCategoryPermissions-Preview"
                  id="Preview"
                  isCompact={false}
                  onClose={() => {
                    // this is actaully on OPEN!
                    // TODO: Please kill everything about SelectCategoryPermissions
                    if (allTrades) {
                      // Setting the previewSettingsVar on both open and close
                      // was unecessarily triggering full rerenders causing
                      // cypress failures, so I've gone ahead and made it so
                      // that we only update the reactive variable if theres an
                      // actual change.
                      if (previewTrades || previewAllTrades || previewUserId)
                        setPreviewSettingsVar({
                          previewRoleId,
                          previewTrades: undefined,
                          previewAllTrades: false,
                        });
                      sendAnalytics(
                        previewPermissionsAnalytics(
                          previewEventTypes.PREVIEW_SELECT_TRADES,
                          previewTypes.ROLE,
                          role.name
                        )
                      );
                    }
                  }}
                  onSelectAll={() => {
                    setPreviewSettingsVar({
                      previewRoleId,
                      previewTrades: undefined,
                      previewAllTrades: true,
                    });
                    sendAnalytics(
                      previewPermissionsAnalytics(
                        previewEventTypes.PREVIEW_SELECT_TRADES,
                        previewTypes.ROLE,
                        role.name
                      )
                    );
                  }}
                  removeCategories={(newCategories) => {
                    const categoryIDs = newCategories.map((category) => category.id);

                    // TODO DD-741
                    const previewTradesList =
                      (trades as Category[])?.filter(
                        (category) => !categoryIDs.includes(category.id)
                      ) ?? [];
                    setPreviewSettingsVar({
                      previewRoleId,
                      previewTrades: previewTradesList,
                      previewAllTrades: false,
                    });
                    sendAnalytics(
                      previewPermissionsAnalytics(
                        previewEventTypes.PREVIEW_SELECT_TRADES,
                        previewTypes.ROLE,
                        role.name
                      )
                    );
                  }}
                  // TODO DD-741
                  selectedCategories={(trades ?? []) as Category[]}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <CTAIconButton
        buttonText={INVITE_TEAMMATES}
        color={theme.palette.pending}
        dataCy="FrameTitlePreviewBar-PreviewAddCollaborators"
        variant="outlined"
        icon={<Add />}
        isSmaller
        colorVariant
        onClick={() => {
          sendAnalytics(
            previewPermissionsAnalytics(
              previewEventTypes.PREVIEW_ADD_COLLABORATORS_CTA,
              previewTypes.ROLE,
              (role && role.name) || (collab && collab.role.name) || ''
            )
          );
          collaboratorDialogOpenVar(true);
        }}
      />
      <CTAIconButton
        buttonText="Exit Preview"
        color={theme.palette.pending}
        dataCy="FrameTitlePreviewBar-PreviewExitButton"
        icon={<Close />}
        isSmaller
        colorVariant
        onClick={() => {
          setPreviewSettingsVar(defaultPreviewSettings);
          sendAnalytics(
            previewPermissionsAnalytics(
              previewEventTypes.PREVIEW_EXIT_CTA,
              isUserPreview ? previewTypes.USER : previewTypes.ROLE,
              (role && role.name) || (collab && collab.role.name) || ''
            )
          );
        }}
      />
    </div>
  );
};

export default withStyles(styles)(FrameTitlePreviewBar);
