import { FC, useMemo, useRef } from 'react';
import * as React from 'react';

import { FieldType } from '../../../api/gqlEnumsBe';
import useFocusHandler from '../hooks/useFocusHandler';
import { EditorPosition, GridController, KeyBufferState, Position } from '../types';
import { assignNonNullRef, isCurrencyField } from '../utilities/cell';

interface DefaultEditorProps {
  grid: GridController;
  defaultValue: RegularCell;
  preprocess?: (s: string) => string;
  updateCell: (position: Position, newValue: string) => void;
  position: EditorPosition;
  stopEditing: () => void;
  editingCell: Position;
}

const DefaultEditor: FC<DefaultEditorProps> = ({
  grid,
  updateCell,
  defaultValue,
  stopEditing,
  editingCell,
  position,
  preprocess,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const previousFocusedElement = document.activeElement;

  useFocusHandler(
    inputRef,
    previousFocusedElement,
    () => {
      const extraKeys = grid.getKeyBufferString(editingCell);
      // eslint-disable-next-line no-param-reassign
      grid.isRenderingEditor = KeyBufferState.CLEAR;
      if (extraKeys.length > 0 && inputRef.current) {
        inputRef.current.value = extraKeys;
      }
    },
    () => {
      if (inputRef.current && grid.isRenderingEditor === KeyBufferState.CLEAR) {
        updateCell(editingCell, inputRef.current.value);
      }
    }
  );

  const handleKey = (event: React.KeyboardEvent<HTMLElement>) => {
    const isEscape = event.key === 'Escape';
    if (isEscape || (!event.shiftKey && (event.key === 'Enter' || event.key === 'Tab'))) {
      stopEditing();
      if (isEscape) {
        event.stopPropagation();
      }
    }
    if (
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Backspace' ||
      event.key === 'Delete'
    ) {
      event.stopPropagation();
    }
  };
  const defaultInput = useMemo(() => {
    if (defaultValue.formula) return defaultValue.formula;
    let value = defaultValue.string;
    if (preprocess) {
      const processed = preprocess(defaultValue.string);
      if (processed !== 'NaN') value = processed;
    }
    // if the value of a number cell is zero then don't show anything
    const { type } = grid.data.columns[editingCell.column];
    if (
      (type === FieldType.NUMBER || type === FieldType.DECIMAL || isCurrencyField(type)) &&
      value === '0'
    )
      value = '';
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [preprocess, defaultValue]);

  return (
    <div className="join-grid-cell-editor" style={position} onKeyDown={handleKey}>
      <input
        className="join-grid-text-input"
        defaultValue={defaultInput}
        ref={(ref) => assignNonNullRef(ref, inputRef)}
        onBlur={stopEditing}
      />
    </div>
  );
};

export default DefaultEditor;
