import { JoinProjectRoutes, JoinRoutes } from '../../../../api/gqlEnums';
import {
  COMPANY_ADMIN,
  EXECUTIVE_DASHBOARD,
  FORECASTING_EXPLORE_ITEMS,
} from '../../../../constants';
import { useIsRouteAccessible } from '../../../../hooks/useIsRouteAccessible';
import { FORECASTING } from '../../../../moduleEntitlementFlagsList';
import { generateSharedRoute } from '../../../../utilities/routes/links';
import {
  DashboardIcon,
  ForecastingIcon,
  HistoricalItemsIcon,
  InsightsIcon,
  ItemsIcon,
  MilestonesIcon,
  ReportsIcon,
  ScenariosIcon,
  SettingsIcon,
  TeamIcon,
  TimelineIcon,
} from '../../icons';

const COMPANY_LINKS = [
  {
    analyticsName: FORECASTING_EXPLORE_ITEMS,
    name: 'Historical Items',
    icon: <HistoricalItemsIcon />,
    route: generateSharedRoute(JoinRoutes.ITEMS),
  },
  {
    analyticsName: FORECASTING,
    name: 'Forecasting',
    icon: <ForecastingIcon />,
    route: generateSharedRoute(JoinRoutes.FORECASTING_TAB),
  },
  {
    analyticsName: EXECUTIVE_DASHBOARD,
    name: 'Insights',
    icon: <InsightsIcon />,
    route: generateSharedRoute(JoinRoutes.EXECUTIVE_DASHBOARD),
  },
  {
    analyticsName: COMPANY_ADMIN,
    name: 'Company Settings',
    icon: <SettingsIcon />,
    route: generateSharedRoute(JoinRoutes.COMPANY_TAB),
  },
];

export default function useLinks(projectId?: UUID) {
  const { isAccessible } = useIsRouteAccessible({ projectID: projectId });

  const companyLinks = COMPANY_LINKS.filter((link) => isAccessible(link.route)).map((link) => ({
    path: link.route.path,
    name: link.name,
    icon: link.icon,
  }));

  const projectLinks = projectId
    ? [
        {
          name: 'Dashboard',
          icon: <DashboardIcon />,
          route: generateSharedRoute(JoinProjectRoutes.PROJECT_DASHBOARD, { projectId }),
        },
        {
          name: 'Items',
          icon: <ItemsIcon />,
          route: generateSharedRoute(JoinProjectRoutes.ITEMS_LIST, { projectId }),
        },
        {
          name: 'Milestones',
          icon: <MilestonesIcon />,
          route: generateSharedRoute(JoinProjectRoutes.MILESTONES, { projectId }),
        },
        {
          name: 'Reports',
          icon: <ReportsIcon />,
          route: generateSharedRoute(JoinProjectRoutes.REPORTS, { projectId }),
        },
        {
          name: 'Timeline',
          icon: <TimelineIcon />,
          route: generateSharedRoute(JoinProjectRoutes.TIMELINE, { projectId }),
        },
        {
          name: 'Scenarios',
          icon: <ScenariosIcon />,
          route: generateSharedRoute(JoinProjectRoutes.SCENARIOS, { projectId }),
        },
        {
          name: 'Settings',
          icon: <SettingsIcon />,
          route: generateSharedRoute(JoinProjectRoutes.SETTINGS, { projectId }),
        },
        {
          name: 'Team',
          icon: <TeamIcon />,
          route: generateSharedRoute(JoinProjectRoutes.TEAM, { projectId }),
        },
      ]
        .filter((link) => isAccessible(link.route))
        .map((link) => ({
          path: link.route.path,
          name: link.name,
          icon: link.icon,
        }))
    : [];

  return {
    companyLinks,
    projectLinks,
  };
}
