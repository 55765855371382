import { FC, ReactNode, cloneElement, isValidElement, useState } from 'react';

import { PermissionResource } from '../../../../generated/graphql';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import ItemsListItemData from '../ItemsListItemData';

type Props = {
  ctaButton?: ReactNode;
  costImpact: Cost | undefined;
  filteredMilestoneId?: UUID;
  isNested: boolean;
  isSelected: boolean;
  item: ItemsListItem | ItemDataQueryItem; // this could use improved typing
  mainCostImpact?: Cost;
  totalCostImpact?: Cost;
  link: string;
  option: ItemsListItem;
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  sharedUsers: Pick<User, 'id' | 'name'>[];
  showCategories?: boolean;
  showCheckbox?: boolean;
  view: string;
};

const ItemsListOption: FC<Props> = ({
  costImpact,
  ctaButton,
  filteredMilestoneId = undefined,
  isNested,
  isSelected,
  item,
  mainCostImpact,
  totalCostImpact,
  link = '',
  option,
  selectItemLike,
  sharedUsers,
  showCategories = true,
  showCheckbox,
  view,
}) => {
  const { canEdit, canAdd, inPreviewMode, userID } = usePermissions({
    trades: item?.categories,
  });
  // previewMode negates didCreateItem and isAssignedItem, as you are choosing
  // to view permissions separate from your own
  const didCreateItem = 'createdBy' in item && item.createdBy?.id === userID && !inPreviewMode;
  const assigneeID =
    (item as ItemsListItem)?.assigneeID ?? (item as ItemDataQueryItem)?.assignee?.id;
  const isAssignedItem = assigneeID === userID && !inPreviewMode;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));

  const [hover, setHover] = useState(false);

  let ctaButtonHovered = ctaButton;
  if (isValidElement(ctaButton)) {
    const props = { hover };
    ctaButtonHovered = cloneElement(ctaButton, props);
  }

  return (
    <ItemsListItemData
      disabled={false}
      filteredMilestoneId={filteredMilestoneId}
      hasCtaButton={!!ctaButton}
      isNested={isNested}
      isSelected={isSelected}
      item={{
        ...option,
        costImpact,
      }}
      mainCostImpact={mainCostImpact}
      totalCostImpact={totalCostImpact}
      link={link}
      selectItemLike={selectItemLike}
      sharedUsers={sharedUsers}
      showCategories={showCategories}
      showCheckbox={showCheckbox}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      view={view}
      showNoEstimate
    >
      {canEditItemDetails && ctaButtonHovered}
    </ItemsListItemData>
  );
};

const ItemsListItemStyled = ItemsListOption;
export default ItemsListItemStyled;
