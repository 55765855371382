import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Divider, Grid, Typography, createStyles } from '@material-ui/core';

import { JoinCompanyStandardsRoutes } from '../../../api/gqlEnums';
import {
  COMPANY_CATEGORIZATIONS,
  COMPANY_PROJECT_TEMPLATES,
  COMPANY_PROJECT_TYPES,
} from '../../../constants';
import { useIsRouteAccessible } from '../../../hooks/useIsRouteAccessible';
import { SharedRoute } from '../../../routes/routes';
import { KomodoTheme, withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import CompanyTabStyles from '../CompanyTabStyles';

import StandardsSidebar from './StandardsSidebar';

type StandardsProps = {
  classes: Classes<typeof styles>;
};

const Standards: FC<StandardsProps> = ({ classes }) => {
  const { isAccessible } = useIsRouteAccessible();
  const links = companyStandardsLinks(isAccessible);

  return (
    <>
      <div className={classes.titleHeader}>
        <Typography className={classes.titleText} variant="headline">
          Company Standards
        </Typography>
      </div>
      <Divider />
      <Grid container spacing={0} justify="space-between" className={classes.overflowHidden}>
        <Grid item xs={2} className={classes.sidebar}>
          <StandardsSidebar links={links} />
        </Grid>
        <Grid item xs={10} container className={classes.content}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

const companyStandardsLinks = (isAccessible: (route: SharedRoute) => boolean) => {
  const links = [
    {
      name: COMPANY_CATEGORIZATIONS,
      route: {
        path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_CATEGORIZATIONS, {}),
      },
    },
    {
      name: COMPANY_PROJECT_TEMPLATES,
      route: { path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES, {}) },
    },
    {
      name: COMPANY_PROJECT_TYPES,
      route: {
        path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_PROJECT_TYPES, {}),
      },
    },
  ];

  const filteredLinks = links.filter((link) => isAccessible(link.route));
  return filteredLinks;
};

const styles = (theme: KomodoTheme) =>
  createStyles({
    ...CompanyTabStyles(theme),
    sidebar: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      height: '100%',
    },
  });

export default withStyles(styles)(Standards);
