import { useTitleHeight } from '../../api/apollo/reactiveVars';
import {
  ACCEPTED,
  CATEGORIZED,
  PENDING,
  PENDING_ADDS,
  PENDING_DEDUCTS,
  REJECTED,
  RUNNING,
  TARGET,
  TOTAL,
} from '../../constants';
import {
  CostReportColumnKey,
  CostReportColumnType,
  MarkupDisplayType,
} from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { CATEGORY_SEPARATOR, EMPTY_COST } from '../../utilities/string';

interface TextMap {
  [status: string]: string;
}

export const DESCRIPTION = 'Description';

const titles: TextMap = {
  [ACCEPTED]: 'Accepted Items',
  [PENDING]: 'Pending Items',
  [PENDING_ADDS]: 'Pending Adds',
  [PENDING_DEDUCTS]: 'Pending Deducts',
  [REJECTED]: 'Rejected Items and Options',
  [RUNNING]: 'Running Total',
  [TARGET]: 'Target Budget',
};

type valueToggles = {
  value: string | number;
  errorMessage?: string;
  isExact?: boolean;
  isWide?: boolean;
  isSigned?: boolean;
  overrideZeroCostDisplay?: boolean;
  showCents?: boolean;
  settingsOverride?: ProjectSettingStore; // used for testing and costs outside of projects, eg project list.
};

export const getCostReportByType = (
  reports?: MilestoneCostReport | VarianceReport | MilestonesCostTrendsBreakdownReport,
  type?: CostReportColumnType
) => {
  if (reports && 'costReportColumns' in reports && reports?.costReportColumns) {
    const column = reports?.costReportColumns.find((col) => col.type === type);
    if (column) return column.report ?? undefined;
  }
  return undefined;
};

export const getMarkupTotal = (
  markupContributions: MarkupContribution[],
  displayTypes: MarkupDisplayType[],
  canBeIncorporated = false
) => {
  const filteredContributions = markupContributions.filter((m) => {
    if (!canBeIncorporated && m.isIncorporated) return false;

    // if this is a non-incorporated draw then try to find it's parent type
    const parentContingency = getDrawContingency(m, markupContributions, displayTypes);
    if (parentContingency) return true;

    // we only want to include draws if they are incorporated
    // or if we can find their parent contingency in the logic above
    if (m.displayType === MarkupDisplayType.DRAW && !m.isIncorporated) return false;

    return displayTypes.includes(m.displayType);
  });
  return {
    value: filteredContributions.reduce((markupTotal, markupContribution) => {
      return Number((markupContribution.range as CostScalar).value) + markupTotal;
    }, 0),
  };
};

export const getSegmentedMarkupTotals = (
  markupContributions: MarkupContribution[],
  displayTypes = [MarkupDisplayType.MARKUP, MarkupDisplayType.DRAW]
) => {
  const filteredContributions = displayTypes
    ? markupContributions.filter((m) => {
        // if this is a non-incorporated draw then try to find it's parent type
        const parentContingency = getDrawContingency(m, markupContributions, displayTypes);
        if (parentContingency) return true;

        return displayTypes.includes(m.displayType);
      })
    : markupContributions;
  return {
    deducts: filteredContributions.reduce(
      (deductsTotal, { segmented: { deducts } }) => Number(deducts) + deductsTotal,
      0
    ),
    adds: filteredContributions.reduce(
      (addsTotal, { segmented: { adds } }) => Number(adds) + addsTotal,
      0
    ),
  };
};

const getDrawContingency = (
  m: MarkupContribution,
  markupContributions: MarkupContribution[],
  displayTypes = [MarkupDisplayType.MARKUP, MarkupDisplayType.DRAW]
) => {
  const includeContingenciesOrAllowances =
    displayTypes.includes(MarkupDisplayType.CONTINGENCY) ||
    displayTypes.includes(MarkupDisplayType.ALLOWANCE);

  if (
    includeContingenciesOrAllowances &&
    m.displayType === MarkupDisplayType.DRAW &&
    !m.isIncorporated
  ) {
    return markupContributions.find((c) => {
      const isContingency =
        (c.displayType === MarkupDisplayType.CONTINGENCY ||
          c.displayType === MarkupDisplayType.ALLOWANCE) &&
        displayTypes.includes(c.displayType);
      const nameMatches = m.markupName === `${c.markupName} Draw`;
      return isContingency && nameMatches;
    });
  }
  return undefined;
};

export const itemCostToSegmented = (cost: Cost) => {
  if (cost) {
    const { value } = cost as CostScalar;
    const { min, max } = cost as CostRange;
    if (value !== undefined) {
      const numberValue = Number(value);
      if (numberValue > 0) {
        return { adds: numberValue };
      }
      if (numberValue < 0) {
        return { deducts: numberValue };
      }
    }
    if (min !== undefined && max !== undefined) {
      const deducts = Number(min);
      const adds = Number(max);
      if (adds < 0) {
        return { deducts };
      }
      if (deducts > 0) {
        return { adds };
      }
      return { deducts, adds };
    }
  }
  return {};
};

export const getCostValue = (cost: CostReportValue) => {
  if (cost) {
    // RANGE
    const { max, min } = cost as CostRange; // strings
    if (max && Number(max)) return Number(max);
    if (min) return Number(min);
    // SCALAR
    const { value } = cost as CostScalar; // strings
    if (value) return Number(value);
    // ADD DEDUCT -- sometimes a string? Gross. TODO: fix that
    const { adds, deducts } = cost as PartialAddDeduct;
    const addsN = Number(adds);
    const deductsN = Number(deducts); // numbers
    if (addsN > 0) return addsN;
    if (deductsN < 0) return deductsN;
    if (addsN === 0 || deductsN === 0) return 0;
    // NUMBER?
    if (cost !== 0) {
      if (!Number.isNaN(cost)) return Number(cost);
    }
  }
  return 0;
};

// HELPERS
export const renderValueString: (toggles: valueToggles) => string = ({
  value,
  isExact,
  isWide,
  isSigned,
  overrideZeroCostDisplay,
  showCents = true,
  settingsOverride,
}) => {
  if (overrideZeroCostDisplay) {
    if (['', '0', 0].includes(value)) return EMPTY_COST;
  }
  if (isExact) {
    return formatCost(value, {
      showCents,
      signed: isSigned,
      settingsOverride,
      showZeroCents: isExact,
    });
  }
  if (isWide) {
    return formatCost(value, {
      showCents: true,
      rounded: true,
      signed: isSigned,
      settingsOverride,
      showZeroCents: isExact,
    });
  }
  if (isSigned) {
    return formatCost(value, {
      showCents: true,
      short: true,
      signed: true,
      settingsOverride,
      showZeroCents: isExact,
    });
  }
  return formatCost(value, { short: true });
};

export const renderCostString: (toggles: {
  cost: Cost | PartialAddDeduct | number | string | null | undefined;
  isExact?: boolean;
  isWide?: boolean;
  isSigned?: boolean;
  showCents?: boolean;
  showZero?: boolean;
}) => string = ({ cost, isExact, isWide, isSigned, showCents, showZero = true }) => {
  if (!cost) return '';

  if (isCostRange(cost)) {
    if (cost.min) {
      return `${renderValueString({
        value: cost.min,
        isExact,
        isWide,
        isSigned,
        showCents,
      })} to ${renderValueString({
        value: cost.max,
        isExact,
        isWide,
        isSigned,
        showCents,
      })}`;
    }
  }

  if (isCostScalar(cost)) {
    if ((cost.value === 0 || cost.value === '0') && !showZero) return '';
    if (cost.value) {
      return renderValueString({ value: cost.value, isExact, isWide, isSigned, showCents });
    }
  }

  if (isAddDeductCost(cost)) {
    if (
      cost.adds === 0 ||
      cost.deducts === 0 ||
      String(cost.adds) === '0' ||
      String(cost.deducts) === '0'
    )
      return '';
  }

  if (typeof cost === 'number' && !Number.isNaN(cost)) {
    return formatCost(cost, { scale: 1, showCents: false });
  }

  if (typeof cost === 'string') {
    return renderValueString({ value: parseInt(cost, 10), isExact, isWide, isSigned, showCents });
  }

  return '';
};

// TODO: Remove with items list headers
export const statusDescription: (status: string) => string = (status) =>
  `Total cost impact of all ${titles[status].toLowerCase()} in this category:\n`;

export const costDescription = (
  cost: Parameters<typeof renderCostString>[0]['cost'],
  showCents?: boolean
) => ({
  exactCost: `${renderCostString({
    cost,
    isExact: true,
    isWide: true,
    showCents,
  })} (exact cost)`,
  roundedCost: `${renderCostString({
    cost,
    isWide: true,
  })} (rounded cost)`,
});

export const getListHeaderHeight = (zoomLevel: number, height: number) => zoomLevel * height;

export const isCost = (cost: unknown): cost is Cost => {
  return isCostScalar(cost) || isCostRange(cost);
};

export const isCostScalar = (cost: unknown): cost is CostScalar => {
  if (!cost || typeof cost !== 'object') return false;

  if ('value' in cost) {
    if (typeof cost.value === 'string' || typeof cost.value === 'number') {
      return true;
    }
  }

  return false;
};

export const isCostRange = (cost: unknown): cost is CostRange => {
  if (!cost || typeof cost !== 'object') return false;

  if ('min' in cost && (typeof cost.min === 'string' || typeof cost.min === 'number')) {
    if ('max' in cost && (typeof cost.max === 'string' || typeof cost.max === 'number')) {
      return true;
    }
  }

  return false;
};

export const isAddDeductCost = (cost: unknown): cost is AddDeductCost => {
  if (!cost || typeof cost !== 'object') return false;
  if ('adds' in cost && typeof cost.adds === 'number') {
    if ('deducts' in cost && typeof cost.deducts === 'number') {
      return true;
    }
  }

  return false;
};

export const getHeight = (current: HTMLDivElement | null, headerHeight: number) => {
  const clientHeight = current?.clientHeight ?? -Infinity;

  // this happens if we are showing the cost summary
  if (clientHeight > headerHeight) {
    // the client height doesn't include the MSR headers, just the cost summary header
    // so we need to add some padding here
    return clientHeight + 36;
  }
  return headerHeight;
};
export const useScrollHeight = () => {
  const offsetHeight = useTitleHeight(); // height and padding
  return `calc(100vh - ${offsetHeight}px`;
};

// This maps a CostReportColumnKey to its corresponding CostReportColumnType
export const reportTypeMap: Record<string, CostReportColumnType> = {
  // Estimate / Budget Columns
  ESTIMATE_KEY: CostReportColumnType.ESTIMATE_REPORT,
  TARGET_KEY: CostReportColumnType.TARGET_REPORT,
  DELTA_KEY: CostReportColumnType.DELTA_REPORT,
  RUNNINGTOTAL_KEY: CostReportColumnType.RUNNINGTOTAL_REPORT,
  REMAINING_KEY: CostReportColumnType.REMAINING_REPORT,

  // PENDING
  PENDING_KEY: CostReportColumnType.PENDING_REPORT,
  PENDINGDEDUCTS_KEY: CostReportColumnType.PENDING_REPORT,
  PENDINGADDS_KEY: CostReportColumnType.PENDING_REPORT,

  // ACCEPTED
  ACCEPTED_KEY: CostReportColumnType.ACCEPTED_REPORT,
  ACCEPTEDDEDUCTS_KEY: CostReportColumnType.ACCEPTED_REPORT,
  ACCEPTEDADDS_KEY: CostReportColumnType.ACCEPTED_REPORT,

  // REJECTED
  REJECTED_KEY: CostReportColumnType.REJECTED_REPORT,
  REJECTEDDEDUCTS_KEY: CostReportColumnType.REJECTED_REPORT,
  REJECTEDADDS_KEY: CostReportColumnType.REJECTED_REPORT,

  // INCORPORATED
  INCORPORATED_KEY: CostReportColumnType.INCORPORATED_REPORT,

  // OPTIONS
  OPTIONS_KEY: CostReportColumnType.OPTIONS_REPORT,

  // VARIANCE REPORTS
  VARIANCE_KEY: CostReportColumnType.VARIANCE,
  VARIANCEMETRIC_KEY: CostReportColumnType.VARIANCE,

  // METRICS
  METRIC_KEY: CostReportColumnType.METRIC_REPORT,
  CATEGORIZEDMETRIC_KEY: CostReportColumnType.CATEGORIZEDMETRIC_REPORT,

  // NOT APPLICABLE
  NOTAPPLICABLE_KEY: CostReportColumnType.NOTAPPLICABLE_REPORT, // this key doesnt' seem to be used?
};

// get the cost report column input values we'll need to export this MSR to excel
export const getMSRExportColumns = (
  { columns, subcolumns, metrics }: MilestoneCostReportSettings,
  enabledUnits: Unit[]
): CostReportColumnInput[] => {
  // check if we need the total, unit, or categorized unit subcolumns
  const includeTotal = subcolumns.some((c: string) => c === TOTAL);
  const unitColumns = subcolumns.filter((c: string) => /.\/Total.+/.test(c));
  const unitCategorizationColumns = subcolumns.filter((c: string) => /.\/Categorized.+/.test(c));

  const exportColumns: CostReportColumnInput[] = [];

  // Get any metric columns that we might need
  metrics.forEach((m: string) => {
    let unit: Unit | undefined;
    let type: CostReportColumnType | undefined;
    let key: CostReportColumnKey | undefined;
    let isCategorized = false;

    // for total type metrics the setting will be called "Total[Metric abbreviation]"
    // eg: "TotalGSF"
    // we generally display metric columns first in the MSR
    if (m.startsWith(TOTAL)) {
      unit = enabledUnits.find((u) => u.abbreviationSingular === m.replace(TOTAL, ''));
      type = CostReportColumnType.METRIC_REPORT;
      key = CostReportColumnKey.METRIC_KEY;

      // for categozed metrics the setting is called "CategorizedMetricAbbreviation"
      // eg: "CategorizedGSF"
    } else if (m.startsWith(CATEGORIZED)) {
      unit = enabledUnits.find((u) => u.abbreviationSingular === m.replace(CATEGORIZED, ''));
      type = CostReportColumnType.CATEGORIZEDMETRIC_REPORT;
      key = CostReportColumnKey.CATEGORIZEDMETRIC_KEY;
      isCategorized = true;
    }
    // if we found any unit columns then add them
    if (unit && type) {
      exportColumns.push({ type, key, unitID: unit.id, isCategorized });
    }
  });

  // Then get any non-metric columns
  columns.forEach((c: string) => {
    const type = reportTypeMap[c];

    // Ensure that this column hasn't already been added
    if (!exportColumns.some((v) => v.type === type && v.key === c)) {
      const key = c as CostReportColumnKey;

      if (includeTotal) exportColumns.push({ type, key });

      // If this is a metric column then add the appropriate unit and props
      //
      // Note: this doesn't work if multiple units have the same abbreviation
      // we make this assumption in several places
      if (unitColumns.length > 0 || unitCategorizationColumns.length > 0) {
        enabledUnits.forEach((u) => {
          const { id: unitID } = u;
          // is this a unit price column
          if (unitColumns.some((c) => c.includes(u.abbreviationSingular))) {
            exportColumns.push({ type, key, unitID });
          }
          // is this a catgorized cost column
          if (unitCategorizationColumns.some((c) => c.includes(u.abbreviationSingular))) {
            exportColumns.push({ type, key, unitID, isCategorized: true });
          }
        });
      }
    }
  });
  return exportColumns;
};

// to find any report comments that may be associated with a cost report line
// we need to get the commentLineID which is a string concatentation of all
// category ids plus the itemID or itemEstimateLineID
export const getUserReportCommentLineID = (
  categories: Pick<Category, 'id'>[],
  itemID?: UUID,
  itemLineID?: UUID
) => {
  const ids = categories.map((c) => c.id);
  if (itemLineID) ids.push(itemLineID);
  if (itemID) ids.push(itemID);
  return ids.join(CATEGORY_SEPARATOR);
};
