import { FC, useState } from 'react';
import * as React from 'react';

import { isItemVariant } from '../../../actions/gridAnalytics';
import { CostType, MarkupType } from '../../../generated/graphql';
import InputsSelectReference from '../../Inputs/InputsSelectReference/InputsSelectReference';
import { useGetEstimateSourceItemInfos } from '../hooks/estimateQuery';
import { EditorPosition, GridController, GridType } from '../types';
import { getSelectableMarkups, sourceFilteresHaveChanged } from '../utilities/cell';

type ReferenceCellEditorProps = {
  error?: string;
  grid: GridController;
  value: MarkupReferenceCell;
  selectedRow: number;
  updateCell: (newValue: MarkupReferenceCell) => void;
  position: EditorPosition;
  stopEditing: () => void;
};

// 200 is the size of the category menu without the source selector
// 280 (categoryMenuHeight + sourceMenuHeight) is the size with the source selector
// I don't want to hardcode this but useRef refuses to tell me the height of these components
const categoryMenuHeight = 200;
const sourceMenuHeight = 80;

const ReferenceCellEditor: FC<ReferenceCellEditorProps> = ({
  error,
  grid,
  value,
  selectedRow,
  position,
  stopEditing,
  updateCell,
}) => {
  // VARIABLES
  const {
    data: { lines }, // markup lines
    variant,
    s1RefShouldIncludeS2 = false,
    linePrefix,
    projectID,
    estimateID,
    type,
    totalType,
  } = grid;
  const isFixedMarkup = value.type === MarkupType.FIXED;

  const isMilestoneMarkupsGrid = type === GridType.MARKUP_GRID;
  const { data: { estimateSourceItemInfos = [] } = {} } = useGetEstimateSourceItemInfos(
    projectID,
    estimateID
  );
  const sourceFilterOptions = isMilestoneMarkupsGrid ? estimateSourceItemInfos : [];

  // STATE HOOKS
  const [references] = useState(value.references);
  const [categoryFilters] = useState(value.filters);
  const [sourceFilters] = useState(value.sourceFilters);
  const [costTypeFilters] = useState(value.costTypeFilters || []);
  const selectableMarkups = getSelectableMarkups(grid.type, lines, linePrefix, selectedRow);

  const swallowKeys = (event: React.KeyboardEvent) => {
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Enter' ||
      event.key === 'Escape'
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const referencesHaveChanged = (references: string[], newReferences: string[]) => {
    if (references.length !== newReferences.length) {
      return true;
    }
    return references.some(
      (reference) => !newReferences.some((newReference) => newReference === reference)
    );
  };

  const categoryFiltersHaveChanged = (filters: Category[], newFilters: Category[]) => {
    if (filters.length !== newFilters.length) {
      return true;
    }
    return filters.some((filter) => !newFilters.some((newFilter) => newFilter.id === filter.id));
  };
  const costTypeFiltersHaveChanged = (
    costTypes: CostType[] | undefined,
    newCostTypeFilters: CostType[]
  ) => {
    if (!costTypes) return false;
    if (costTypes.length !== newCostTypeFilters.length) {
      return true;
    }
    return costTypes.some(
      (costType) => !newCostTypeFilters.some((newCostTypeFilter) => newCostTypeFilter === costType)
    );
  };
  const hasSourceFilterMenuOptions = !!sourceFilterOptions && sourceFilterOptions.length > 0;

  let top = position.top + (position.height + 4);
  const screenHeight = document.documentElement.clientHeight;

  const subMenuHeight = hasSourceFilterMenuOptions
    ? categoryMenuHeight + sourceMenuHeight
    : categoryMenuHeight;
  const totalHeight = screenHeight - subMenuHeight;

  // if the dropdown is too close to the bottom of the screen then open upwards
  const openDown = top > totalHeight;
  if (openDown) top = position.top + 2;

  return (
    <div onKeyDown={swallowKeys}>
      <InputsSelectReference
        error={error}
        editable
        style={{
          top,
          left: position.left,
          position: 'fixed',
        }}
        filters={categoryFilters}
        references={references}
        appliedSourceFilters={sourceFilters}
        appliedCostTypeFilters={costTypeFilters}
        sourceFilterMenuOptions={sourceFilterOptions}
        inherit={false}
        isJoinGrid
        isItem={isItemVariant(variant)}
        markups={selectableMarkups}
        markupLine={`${linePrefix}${selectedRow + 1}`}
        onClose={(
          selectedReferences: string[],
          selectedFilters: Category[],
          selectedSourceFilters: ItemLink[],
          selectedCostTypeFilters: CostType[]
        ) => {
          if (
            referencesHaveChanged(references, selectedReferences) ||
            categoryFiltersHaveChanged(categoryFilters, selectedFilters) ||
            costTypeFiltersHaveChanged(costTypeFilters, selectedCostTypeFilters) ||
            (sourceFilters && sourceFilteresHaveChanged(sourceFilters, selectedSourceFilters))
          )
            updateCell({
              type: value.type,
              references: selectedReferences,
              filters: selectedFilters,
              sourceFilters: selectedSourceFilters,
              costTypeFilters: selectedCostTypeFilters,
            });
          stopEditing();
        }}
        open
        s1RefShouldIncludeS2={s1RefShouldIncludeS2}
        isFixedMarkup={isFixedMarkup}
        totalType={totalType}
        transformOrigin={openDown ? 'bottom' : 'top'}
      />
    </div>
  );
};

export default ReferenceCellEditor;
