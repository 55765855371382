import {
  commonProjectPropsFields,
  commonReportDistributionFields,
  commonUserReportFields,
  gql,
} from '../../../api/graphqlFragments';

// Combine user reports with project props query
export const userReportsAndProjectPropsQuery = gql`
  query userReportsAndProjectProps($projectID: UUID!) {
    userReports(projectID: $projectID) {
      ...commonUserReportFields
    }
    project(id: $projectID) {
      ...commonProjectPropsFields
    }
  }
  ${commonUserReportFields}
  ${commonProjectPropsFields}
`;

export const userReportsQuery = gql`
  query userReports($projectID: UUID!) {
    userReports(projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${commonUserReportFields}
`;

export const createUserReportMutation = gql`
  mutation createUserReport($userReport: UserReportInput!, $projectID: UUID!) {
    createUserReport(userReport: $userReport, projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${commonUserReportFields}
`;

export const updateUserReportMutation = gql`
  mutation updateUserReport($userReport: UserReportInput!, $projectID: UUID!) {
    updateUserReport(userReport: $userReport, projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${commonUserReportFields}
`;

export const removeUserReportMutation = gql`
  mutation removeUserReport($projectID: UUID!, $reportID: UUID!) {
    removeUserReport(projectID: $projectID, reportID: $reportID)
  }
`;
export const createReportDistributionMutation = gql`
  mutation createReportDistribution($projectID: UUID!, $input: ReportDistributionInput!) {
    createReportDistribution(projectID: $projectID, input: $input)
  }
`;

export const deleteReportDistributionMutation = gql`
  mutation deleteReportDistribution($projectID: UUID!, $reportDistributionID: UUID!) {
    deleteReportDistribution(projectID: $projectID, reportDistributionID: $reportDistributionID)
  }
`;
export const updateReportDistributionMutation = gql`
  mutation updateReportDistribution($projectID: UUID!, $input: ReportDistributionInput!) {
    updateReportDistribution(projectID: $projectID, input: $input)
  }
`;

export const loadReportDistributionsQuery = gql`
  query reportDistribution($projectID: UUID!, $reportID: UUID!) {
    reportDistribution(projectID: $projectID, reportID: $reportID) {
      ...commonReportDistributionFields
    }
  }
  ${commonReportDistributionFields}
`;

export const reportDistributionHistoryQuery = gql`
  query reportDistributionHistory($projectID: UUID!) {
    reportDistributionHistory(projectID: $projectID) {
      sentAt
      sentBy
      reportID
      reportName
      id
      deprecated
      isScheduled
      reports {
        roleID
        assetURL
        sentTo
      }
    }
  }
`;

export const unsubscribeReportDistributionMutation = gql`
  mutation unsubscribeReportDistribution($projectID: UUID, $reportDistributionID: UUID!) {
    unsubscribeReportDistribution(
      projectID: $projectID
      reportDistributionID: $reportDistributionID
    )
  }
`;

export const loadReportIDByReportDistributionAssetIDQuery = gql`
  query reportIDByReportDistributionAssetID($assetID: UUID!) {
    reportIDByReportDistributionAssetID(assetID: $assetID)
  }
`;
