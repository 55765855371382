import { FC, ReactNode, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  ItemsListImportEvent,
  itemsListImportEvent,
} from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import useSendAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { pluralizeCountString } from '../../../utilities/string';
import { useImportItems } from '../../assets/hooks/AssetsMutationHook';
import { Uploader, UploaderLink } from '../../scales';

const DOWNLOAD_TEMPLATE_TEXT = 'Download item import template';

export enum ImportState {
  preview = 'preview',
  loading = 'loading',
  error = 'error',
  single = 'single',
  multi = 'multi',
  none = 'none',
}

type ImportItemsAreaDataProps = {
  addFile?: (file: File, source?: FileSource) => void;
  children?: ReactNode;
  file?: File;
  fileSource?: FileSource;
  header?: string;
  itemsCount: number;
  projectID?: UUID;
  projectName: string;
  // Used to let the parent component know where we uploaded the items
  setImportAssetID?: (assetID: UUID) => void;
  setImportedItemsTotal?: (totalCount: number) => void;
  setImportedItemStatus?: (statuses: string[]) => void;
  // A way for the parent component to know the state of the import component
  handleImportState?: (state: ImportState) => void;
};

function isExcelFile(file: File): boolean {
  return /\.(xls|xlsx)$/i.test(file.name);
}

const ImportItemsAreaData: FC<ImportItemsAreaDataProps> = (props) => {
  const {
    itemsCount,
    projectID,
    handleImportState,
    setImportAssetID,
    setImportedItemsTotal,
    setImportedItemStatus,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState<string | string[]>('');
  const [successText, setSuccessText] = useState('');
  const [importState, setImportState] = useState<ImportState>(ImportState.none);

  useEffectOnce(() => {
    if (props.file) {
      addFile(props.file, props.fileSource || 'fileSystem');
    }
  });

  const importItems = useImportItems();
  const sendAnalytics = useSendAnalyticsEventHook();

  const onFileSelected = (file: File | undefined, source: FileSource) => {
    if (file && isExcelFile(file)) {
      if (props.addFile) {
        props.addFile(file);
        return;
      }
      addFile(file, source);
    } else {
      setErrorText('Selected file is not an accepted file type. Please provide a .xls or .xlsx.');
    }
  };

  const addFile = (file: File, source: FileSource) => {
    setLoading(true);
    setSuccessText('');
    setErrorText('');
    handleImportState?.(ImportState.loading);
    setImportAssetID?.('');
    importItems(
      file,
      projectID,
      (result) => {
        setLoading((prevLoading) => {
          const isCancelled = !prevLoading;
          if (isCancelled) return false;
          if (result.error) {
            handleImportState?.(ImportState.error);
            setErrorText(result.error);
          } else if (result.userErrors.length > 0) {
            handleImportState?.(ImportState.error);
            setErrorText(result.userErrors);
          } else {
            handleImportState?.(ImportState.preview);
            setImportAssetID?.(result.asset.id);
            setSuccessText(
              `${pluralizeCountString(`valid item`, result.items.length)} found in ${
                file.name
              } and ready to import.`
            );
            setImportedItemsTotal?.(result.items.length);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            setImportedItemStatus?.(result.items.map((e: any) => e.Status));
            sendAnalytics(
              itemsListImportEvent(ItemsListImportEvent.IMPORT_FILE, {
                importState: importState === ImportState.none ? 'New' : importState,
                itemsCount,
                source,
              })
            );
          }
          return false;
        });
      },
      () => {
        setLoading(false);
        handleImportState?.(ImportState.error);
        setImportState(ImportState.error);
      }
    );
  };

  const onCancelUploading = () => {
    setLoading(false);
    setSuccessText('');
    setErrorText('');
    handleImportState?.(ImportState.none);
    setImportState(ImportState.none);
    setImportAssetID?.('');
    setImportedItemStatus?.([]);
    sendAnalytics(
      itemsListImportEvent(ItemsListImportEvent.IMPORT_LOADING_CANCEL, {
        importState: importState === ImportState.none ? 'New' : importState,
        itemsCount,
      })
    );
  };

  const onResetImport = () => {
    setLoading(false);
    setSuccessText('');
    setErrorText('');
    handleImportState?.(ImportState.none);
    setImportState(ImportState.none);
    setImportAssetID?.('');
    setImportedItemStatus?.([]);
    sendAnalytics(itemsListImportEvent(ItemsListImportEvent.IMPORT_SUCCESS_RESET));
  };

  useEffect(() => {
    if (errorText) {
      setErrorText(errorText);
    }
    if (errorText.length > 0) {
      handleImportState?.(ImportState.error);
      setImportState(ImportState.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [loading, errorText]);

  const onDownloadTemplate = () => {
    if (projectID) joinAPI.importItemsTemplate(projectID, props.projectName);
    sendAnalytics(
      itemsListImportEvent(ItemsListImportEvent.IMPORT_DOWNLOAD_TEMPLATE, {
        importState: importState === ImportState.none ? 'New' : importState,
        itemsCount,
      })
    );
  };

  const onNeedHelp = () => {
    const needHelpLink = 'https://success.join.build/en/knowledge/importing-items';
    window.open(needHelpLink);
    sendAnalytics(
      itemsListImportEvent(ItemsListImportEvent.IMPORT_HELP, {
        importState: importState === ImportState.none ? 'New' : importState,
        itemsCount,
      })
    );
  };

  return (
    <Uploader
      errorTextDescription={errorText}
      footerLeft={<UploaderLink onClick={onDownloadTemplate} label={DOWNLOAD_TEMPLATE_TEXT} />}
      footerRight={<UploaderLink onClick={onNeedHelp} label="Need help?" />}
      header={props.header}
      loading={loading}
      onCancelUploading={onCancelUploading}
      onFileSelected={onFileSelected}
      onResetImport={onResetImport}
      successText={successText}
    >
      {props.children}
    </Uploader>
  );
};

export default ImportItemsAreaData;
