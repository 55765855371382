import { ReactNode } from 'react';

import { Help } from '@material-ui/icons';

import NormalTooltip from '../../NormalTooltip/NormalTooltip';

export default function UploadHeader(props: {
  label?: string;
  onHelp?: () => void;
  tooltip?: ReactNode;
}) {
  return (
    <div data-cy="import-text-header" className="flex items-center gap-2 type-heading2">
      {props.label}
      {props.tooltip && (
        <NormalTooltip title={props.tooltip}>
          <Help className="text-type-muted" fontSize="inherit" onMouseEnter={props.onHelp} />
        </NormalTooltip>
      )}
    </div>
  );
}
