import { createContext } from 'react';

export const defaultStore = [] as string[];

export const ModuleEntitlementsStore = createContext<string[]>(defaultStore);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const ModuleEntitlementsProvider = (props: any) => {
  const { children, moduleEntitlements } = props;

  return (
    <ModuleEntitlementsStore.Provider value={moduleEntitlements}>
      {children}
    </ModuleEntitlementsStore.Provider>
  );
};
