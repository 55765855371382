import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../analytics/analyticsEventProperties';
import {
  isDownloadingProjectCompsToExcelVar,
  projectCompsLocalSettingsVar,
} from '../../api/apollo/reactiveVars';
import { JoinForecastingRoutes } from '../../api/gqlEnums';
import { CostMode } from '../../api/gqlEnumsBe';
import JoinAPI from '../../api/joinAPI';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { setToast } from '../../hooks/useToastParametersLocalQuery';
import { FORECASTING_REPORTS_SAVE_TOAST_LINK } from '../../tagConstants';
import { generateSharedPath } from '../../utilities/routes/links';
import { getReportIdFromUrl } from '../../utilities/url';
import DialogsForecastingReport from '../Dialogs/DialogsForecastingReport/DialogsForecastingReport';
import DialogsSelectionProjects from '../Dialogs/DialogsSelectionProjects/DialogsSelectionProjects';
import { useCreateProjectComparisonReportMutation } from '../ForecastingTab/ForecastingReports/hooks/useCreateProjectComparisonReportMutation';
import { useProjectComparisonReportQuery } from '../ForecastingTab/ForecastingReports/hooks/useProjectComparisonReportQuery';
import { useProjectCompsSetInputQuery } from '../ForecastingTab/ForecastingReports/hooks/useProjectCompsSetInputQuery';
import { useSaveProjectComparisonReportMutation } from '../ForecastingTab/ForecastingReports/hooks/useSaveProjectComparisonReportMutation';
import RowHighlightProvider from '../shared-widgets/RowHighlight/RowHighlightProvider';

import AddToMilestoneManager from './AddToMilestone/AddToMilestoneManager/AddToMilestoneManager';
import { useProjectCompsSetQuery } from './hooks/useProjectCompsSetQuery';
import ProjectCompsSet from './ProjectCompsSet';
import { useHandleIncomingLink, useInitialSetSettings } from './ProjectCompsSetDataUtils';
import { ProjectCompsSetInputStoreProvider } from './ProjectCompsSetInputStore/ProjectCompsSetInputStoreProvider';
import { useProjectCompsSetInputUpdateFunctions } from './ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { DEFAULT_REPORT_NAME, PC_SAVE_ACTION } from './ProjectCompUtils';
import SyncLocalStorageWithProjectComps from './SyncLocalStorageWithProjectComps';

const ProjectCompsSetData = () => {
  const sendAnalytics = useAnalyticsEventHook();
  const navigate = useNavigate();
  const { state } = useLocation();
  // Get parent project reference when creating a new Forecasting PC report from a project.

  useHandleIncomingLink();

  useReactiveVar(projectCompsLocalSettingsVar); // required to reload on collapse

  const [parentProjectId, setParentProjectId] = useState<UUID>(state?.projectId || '');

  // Determine if report is viewed from Forecasting tab
  const reportID = getReportIdFromUrl();
  const { data: projectCompsSetInputData, loading: loadingProjectCompsSetInput } =
    useProjectCompsSetInputQuery(reportID);
  useInitialSetSettings(projectCompsSetInputData?.projectCompsSetInput);

  const { data: reportData, loading: loadingProjectComparisonReport } =
    useProjectComparisonReportQuery(reportID);

  const projectComparisonReport = reportData?.projectComparisonReport;

  // Initialize the query for Comps Data
  const { data: projectCompsSetData, previousData: projectCompsSetPreviousData } =
    useProjectCompsSetQuery(reportID);

  useEffect(() => {
    // TODO: figure out how we deal with project permissions when you remove yourself from the attached project
    if (projectComparisonReport?.attachedProject?.hasAccess) {
      setParentProjectId(projectComparisonReport?.attachedProject?.id);
    }
  }, [projectComparisonReport]);

  // If the projectCompsSet query takes a while to execute we may get a render pass where `data`
  // is undefined. In this scenario, we don't want to show a blank page so we fall back to
  // the last response's data.
  const projectCompsSet =
    projectCompsSetData?.projectCompsSet ?? projectCompsSetPreviousData?.projectCompsSet;

  const { data: parentProjectData } = useProjectPropsQuery(parentProjectId);
  const parentProject = parentProjectData?.project;

  // Dialogs
  const [dialogsForecastingReportOpen, setDialogsForecastingReportOpen] = useState<boolean>(false);
  const [dialogsSelectionProjectsOpen, setDialogsSelectionProjectsOpen] = useState<boolean>(false);

  // Actions
  const openProjectSelectionDialog = useCallback(() => {
    setDialogsSelectionProjectsOpen(true);
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_VIEW, {
        location: 'project comparison',
      })
    );
  }, [sendAnalytics]);

  const loading = !projectCompsSet || loadingProjectCompsSetInput || loadingProjectComparisonReport;

  const exportProjectCompsReport = useCallback(() => {
    if (isDownloadingProjectCompsToExcelVar()) return;
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_EXPORT, {
        reportName: projectComparisonReport?.name,
      })
    );
    const projectCompsSetInput = projectCompsSet?.input;
    JoinAPI.exportProjectComps({
      costMode: projectCompsSet?.input.costMode ?? CostMode.AllocatedMarkups,
      reportName: projectComparisonReport?.name || DEFAULT_REPORT_NAME,
      projectCompsSetInput,
      reportID,
    });
  }, [sendAnalytics, projectComparisonReport?.name, projectCompsSet?.input, reportID]);

  // Reports
  const [reportInput, setReportInput] = useState({
    name: '',
    description: '',
  });
  const onResetReportInput = useCallback(() => {
    setDialogsForecastingReportOpen(false);
    setReportInput({ name: '', description: '' });
  }, [setDialogsForecastingReportOpen, setReportInput]);

  const handleCreateProjectComparisonReport = useCallback(
    (report: ProjectComparisonReport) => {
      onResetReportInput();
      navigate(
        generateSharedPath(JoinForecastingRoutes.FORECASTING_SAVED_PROJECT_COMPARISON, {
          reportId: report.id,
        })
      );
      setToast({
        message: (
          <div>
            {report.name} saved. View in{' '}
            <Link
              data-cy={FORECASTING_REPORTS_SAVE_TOAST_LINK}
              to={generateSharedPath(JoinForecastingRoutes.FORECASTING_REPORTS, {})}
              style={{ textDecoration: 'underline' }}
            >
              Forecasting Reports List.
            </Link>
          </div>
        ),
      });
    },
    [onResetReportInput, navigate]
  );
  const handleUpdateProjectComparisonReport = useCallback((report: ProjectComparisonReport) => {
    setToast({
      message: (
        <div>
          {report.name} updated. View in{' '}
          <Link
            data-cy={FORECASTING_REPORTS_SAVE_TOAST_LINK}
            to={generateSharedPath(JoinForecastingRoutes.FORECASTING_REPORTS, {})}
            style={{ textDecoration: 'underline' }}
          >
            Forecasting Reports List.
          </Link>
        </div>
      ),
    });
  }, []);

  // SAVING LOGIC
  // Saving logic for PC report from Forecasting
  const [onCreateProjectComparisonReport] = useCreateProjectComparisonReportMutation(
    handleCreateProjectComparisonReport
  );
  const onSaveReportInput = useCallback(
    (reportInput: { name: string; description: string }) => {
      onCreateProjectComparisonReport(reportInput.name, reportInput.description, parentProjectId);
    },
    [onCreateProjectComparisonReport, parentProjectId]
  );

  // Re-Saving logic for PC report from Forecasting
  const [onUpdateProjectComparisonReport] = useSaveProjectComparisonReportMutation(
    handleUpdateProjectComparisonReport
  );
  const reSaveCompReport = useCallback(() => {
    if (reportID) {
      onUpdateProjectComparisonReport(reportID, parentProjectId);
    }
  }, [onUpdateProjectComparisonReport, parentProjectId, reportID]);

  // Actions
  const { addProjectCompInputs } = useProjectCompsSetInputUpdateFunctions();
  const onAddProjects = useCallback(() => {
    openProjectSelectionDialog();
  }, [openProjectSelectionDialog]);

  const onCompsSelectCloseDialog = useCallback(() => {
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_CANCEL)
    );
    setDialogsSelectionProjectsOpen(false);
  }, [sendAnalytics]);

  const onCompsSelectDoneDialog = useCallback(
    (selectedProjectIDs: UUID[]) => {
      setDialogsSelectionProjectsOpen(false);
      addProjectCompInputs(selectedProjectIDs);
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_CTA, {
          selectedProjects: selectedProjectIDs,
          numberSelectedProjects: selectedProjectIDs.length,
        })
      );
    },
    [addProjectCompInputs, sendAnalytics]
  );

  const onSaveAction = useCallback(
    (action: PC_SAVE_ACTION) => {
      switch (action) {
        case PC_SAVE_ACTION.SAVE:
          reSaveCompReport();
          sendAnalytics(
            projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SAVE_REPORT_CTA, {
              saveAction: PC_SAVE_ACTION.SAVE,
            })
          );
          break;
        case PC_SAVE_ACTION.SAVE_AS:
          setDialogsForecastingReportOpen(true);
          sendAnalytics(
            projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SAVE_REPORT_CTA, {
              saveAction: PC_SAVE_ACTION.SAVE_AS,
            })
          );
          break;
        case PC_SAVE_ACTION.SAVE_AS_COPY:
          setReportInput({
            name: `${projectComparisonReport?.name ?? 'Report'} Copy`,
            description: projectComparisonReport?.description ?? '',
          });
          setDialogsForecastingReportOpen(true);
          sendAnalytics(
            projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SAVE_REPORT_CTA, {
              saveAction: PC_SAVE_ACTION.SAVE_AS_COPY,
            })
          );
          break;
        default:
          break;
      }
    },
    [
      projectComparisonReport?.description,
      projectComparisonReport?.name,
      reSaveCompReport,
      sendAnalytics,
    ]
  );

  // we are not going to render until we've got settings
  if (loading) return <div data-cy="project-comps-loading" />;

  return (
    <>
      <ProjectCompsSet
        exportProjectCompsReport={exportProjectCompsReport}
        onAddProjects={onAddProjects}
        onSaveAction={onSaveAction}
        parentProject={parentProject}
        projectComparisonReport={projectComparisonReport}
        projectCompsSet={projectCompsSet}
      />
      {dialogsSelectionProjectsOpen && (
        <DialogsSelectionProjects
          canViewCompanyProjects
          confirmButtonLabel={(count) => `Add to report (${count})`}
          isOpen
          onClose={onCompsSelectCloseDialog}
          onDone={onCompsSelectDoneDialog}
          selectionMode="multiple"
          title="Add projects"
        />
      )}
      <DialogsForecastingReport
        headerText="New Report"
        initialReportInput={reportInput}
        onClose={onResetReportInput}
        onSubmit={onSaveReportInput}
        open={dialogsForecastingReportOpen}
      />
      <AddToMilestoneManager projectId={parentProjectId} />
      <SyncLocalStorageWithProjectComps />
    </>
  );
};

const ProjectCompsSetDataWrapper = () => (
  <RowHighlightProvider>
    <ProjectCompsSetInputStoreProvider>
      <ProjectCompsSetData />
    </ProjectCompsSetInputStoreProvider>
  </RowHighlightProvider>
);

export default ProjectCompsSetDataWrapper;
