import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import ItemsIcons from '../../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import MilestoneMeetingChip from '../../../Milestone/MilestoneMeetingChip/MilestoneMeetingChip';

import FilteredMilestoneCost from './FilteredMilestoneCost';
import FilteredMilestoneInfoStyles from './FilteredMilestoneInfoStyles';

type FilteredMilestoneInfoProps = {
  classes: Classes<typeof FilteredMilestoneInfoStyles>;
  item: ItemsListItem;
};

const FilteredMilestoneInfo: FC<FilteredMilestoneInfoProps> = ({ classes, item }) => {
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const { currentMilestone, currentStatus } = item;

  return (
    <div className={classes.inlineRow}>
      <Typography data-cy="itemNowInChip" variant="caption">
        Now in:{' '}
      </Typography>
      {currentMilestone && <MilestoneMeetingChip {...currentMilestone} inline hideDate />}
      <div className={classes.milestoneStatus}>
        <div data-cy="filteredMilestoneStatusIcon" className={classes.milestoneStatusContainer}>
          <div className={classes.statusIcon}>
            <ItemsIcons status={currentStatus} variant={SMALL} />
          </div>
          {shouldDisplayCosts && <FilteredMilestoneCost item={item} />}
        </div>
      </div>
    </div>
  );
};

export default withStyles(FilteredMilestoneInfoStyles)(FilteredMilestoneInfo);
