import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Select, Typography } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';

import { Uncategorized } from '../../../constants';
import {
  CostType,
  EstimateTotalType,
  MarkupDisplayType,
  MarkupType,
} from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeCountString, removeYear } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import CategoryIcon from '../../Icons/Category';
import FilterIcon from '../../Icons/FilterIcon';
import SourceIcon from '../../Icons/Source';
import SubtotalIcon from '../../Icons/Subtotals';
import { ReferenceDisplay } from '../../JoinGrid/types';
import { formatRefsToNames } from '../../JoinGrid/utilities/cell';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import { generateCategoryFiltersMenuItems } from './CategoryMenuItems';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import ReferencesMenu from './ReferencesMenu';
import { generateReferencesMenuItems } from './ReferencesMenuItems';
import { getSourceFilterMenuItems } from './SourceFilterMenuItems';
import { getReferencesText } from './utils';

type InputsSelectReferenceProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  editable: boolean;
  error?: string;
  filters: Category[];
  inherit?: boolean;
  isItem?: boolean;
  isJoinGrid?: boolean;
  markups: SelectableMarkup[];
  markupLine: string;
  onClose: (
    selectedReferences: string[],
    selectedCategorizationFilters: Category[],
    selectedSourceFilters: ItemLink[],
    selectedCostTypeFilters: CostType[]
  ) => void;
  open?: boolean;
  references: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  style: any;
  s1RefShouldIncludeS2: boolean;
  isFixedMarkup: boolean;
  appliedSourceFilters?: ItemLink[];
  sourceFilterMenuOptions?: ItemLink[];
  appliedCostTypeFilters: CostType[];
  totalType?: EstimateTotalType;
  transformOrigin?: 'top' | 'bottom';
};

const InputsSelectReference: FC<InputsSelectReferenceProps> = ({
  classes,
  editable,
  error = undefined,
  filters,
  inherit = false,
  isItem = false,
  isJoinGrid = false,
  markups,
  markupLine,
  onClose,
  open = false,
  references,
  style,
  s1RefShouldIncludeS2,
  isFixedMarkup,
  appliedSourceFilters = [],
  sourceFilterMenuOptions,
  appliedCostTypeFilters = [],
  totalType,
  transformOrigin = 'top',
}) => {
  const projectID = getProjectIdFromUrl();
  const { data } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const t = useContext(ProjectTermStore);

  // STATE VARIABLES
  const [menuOpen, setMenuOpen] = useState(open);
  const [selectedReferences, setSelectedReferences] = useState(references);
  const [selectedCategorizationFilters, setSelectedCategorizationFilters] = useState(filters);
  const [selectedSourceFilters, setSelectedSourceFilters] = useState(appliedSourceFilters);
  const [newError, setNewErrorState] = useState(error);
  const [selectedCostTypeFilters, setSelectedCostTypeFilters] = useState(appliedCostTypeFilters);
  const currentStateRef = useRef<{
    selectedReferences: string[];
    selectedCategorizationFilters: Category[];
    selectedSourceFilters: ItemLink[];
    selectedCostTypeFilters: CostType[];
  }>({
    selectedReferences,
    selectedCategorizationFilters,
    selectedSourceFilters,
    selectedCostTypeFilters,
  });
  currentStateRef.current = {
    selectedReferences,
    selectedCategorizationFilters,
    selectedSourceFilters,
    selectedCostTypeFilters,
  };

  const handleNavigation = (event: KeyboardEvent) => {
    const { key } = event;
    if (key === 'Escape') {
      setMenuOpen(false);
      if (onClose) {
        const {
          selectedReferences: currentReferences,
          selectedCategorizationFilters: currentFilters,
          selectedSourceFilters: currentSourceFilters,
          selectedCostTypeFilters: currentCostTypeFilters,
        } = currentStateRef.current;
        onClose(currentReferences, currentFilters, currentSourceFilters, currentCostTypeFilters);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  const filterIsSelected = (category: Category) =>
    selectedCategorizationFilters.some(
      (filter) =>
        filter.id === category.id &&
        (filter.categorization && filter.categorization.id) ===
          (category.categorization && category.categorization.id)
    );

  const toggleFilter = (category: Category) => {
    if (filterIsSelected(category)) {
      // filters out the already selected category, effectively removing it
      setSelectedCategorizationFilters(
        selectedCategorizationFilters.filter(
          (f) =>
            f.id !== category.id ||
            (f.categorization && f.categorization.id) !==
              (category.categorization && category.categorization.id)
        )
      );
    } else {
      setSelectedCategorizationFilters([...selectedCategorizationFilters, category]);
    }
  };

  // VARIABLES
  const hasFilters = selectedCategorizationFilters.length > 0;
  const hasSourceFilterMenuOptions = sourceFilterMenuOptions && sourceFilterMenuOptions.length > 0;

  // Creates string of comma separated filter numbers grouped by each categorization
  const filtersText = () => {
    let filtsText = '';
    categorizations.forEach((c: Categorization) => {
      const categorizationFilters = selectedCategorizationFilters
        .filter((f: Category) => (f.categorization && f.categorization.id) === c.id)
        .sort((a, b) => {
          if (a.number === Uncategorized) return -1;
          if (b.number === Uncategorized) return 1;
          return a.number < b.number ? -1 : 1;
        });
      if (categorizationFilters.length === 0) return;
      if (filtsText !== '') filtsText += '\n';
      filtsText += `${removeYear(c.name)}: ${categorizationFilters
        .map((f) => f.number || f.name)
        .join(', ')}`;
    });
    return filtsText;
  };

  const generateCell = (refs: string[]) => (
    <span>
      {hasFilters && (
        <span className={classes.renderFilterIcon} title={filtersText()}>
          <FilterIcon isFilled />
        </span>
      )}
      <span className={classes.renderText}>{getReferencesText(refs, markups, inherit)} HI</span>
    </span>
  );

  const referenceDisplayNames = formatRefsToNames(
    markups,
    {
      type: isFixedMarkup ? MarkupType.FIXED : MarkupType.PERCENT,
      displayType: MarkupDisplayType.MARKUP,
      markupReference: {
        appliesTo: selectedReferences,
      },
      costTypeFilters: selectedCostTypeFilters,
    },
    inherit,
    hasSourceFilterMenuOptions || false
  );

  let subtotalDescription = '';
  if (selectedReferences.length || isFixedMarkup) {
    let reference = referenceDisplayNames;
    if (isFixedMarkup)
      reference = s1RefShouldIncludeS2 ? ReferenceDisplay.S1_S2 : ReferenceDisplay.S1;
    subtotalDescription = `Applied to ${reference}`;
  } else {
    subtotalDescription = ReferenceDisplay.NOT_APPLIED;
  }

  return (
    <Select
      style={style}
      className={classes.root}
      classes={{
        select: classes.select,
        icon: ((!editable || isJoinGrid) && classes.hideIcon) || undefined,
      }}
      MenuProps={{
        transformOrigin: {
          vertical: transformOrigin,
          horizontal: 'left',
        },
      }}
      disabled={!open}
      disableUnderline
      displayEmpty
      multiple
      autoWidth
      onClose={() => {
        setMenuOpen(false);
        if (onClose) {
          onClose(
            selectedReferences,
            selectedCategorizationFilters,
            selectedSourceFilters,
            selectedCostTypeFilters
          );
        }
      }}
      onOpen={() => {
        setMenuOpen(true);
      }}
      open={menuOpen}
      renderValue={(!isJoinGrid ? generateCell : () => null) as SelectProps['renderValue']}
      value={[]}
    >
      <div>
        <Typography
          className={classes.menuCaption}
          variant="caption"
        >{`Apply markup ${markupLine} to...`}</Typography>
      </div>
      <ReferencesMenu
        subMenuItems={
          isFixedMarkup
            ? undefined
            : generateReferencesMenuItems(
                classes,
                isFixedMarkup,
                markups,
                s1RefShouldIncludeS2,
                selectedCostTypeFilters,
                setSelectedCostTypeFilters,
                selectedReferences,
                setSelectedReferences,
                setNewErrorState,
                t,
                totalType,
                isItem,
                newError
              )
        }
        icon={<SubtotalIcon />}
        title="Subtotals and markups"
        subTitle={subtotalDescription}
      />
      <ReferencesMenu
        subMenuItems={generateCategoryFiltersMenuItems(
          classes,
          categorizations,
          selectedCategorizationFilters,
          setSelectedCategorizationFilters,
          toggleFilter,
          isFixedMarkup,
          hasFilters
        )}
        icon={<CategoryIcon />}
        title="Categories"
        subTitle={`Applied to ${
          selectedCategorizationFilters.length
            ? pluralizeCountString('category', selectedCategorizationFilters.length)
            : 'all categories'
        }`}
      />
      {!isFixedMarkup && hasSourceFilterMenuOptions && (
        <ReferencesMenu
          subMenuItems={getSourceFilterMenuItems(
            classes,
            selectedSourceFilters,
            sourceFilterMenuOptions,
            setSelectedSourceFilters,
            markupLine
          )}
          icon={<SourceIcon />}
          title="Sources"
          subTitle={`Applied to lines/markups from ${pluralizeCountString(
            'source',
            selectedSourceFilters.length
          )}`}
        />
      )}
    </Select>
  );
};

export default withStyles(InputsSelectReferenceStyles)(InputsSelectReference);
