import {
  commonInAppNotificationFields,
  commonInAppNotificationsFields,
  gql,
} from '../../../api/graphqlFragments';

export const hasUnreadInAppNotificationsQuery = gql`
  query hasUnreadInAppNotifications {
    hasUnreadInAppNotifications
  }
`;

export const loadInAppNotificationsCountQuery = gql`
  query loadInAppNotificationsCount($projectID: UUID, $maxCount: Int!) {
    loadInAppNotificationsCount(projectID: $projectID, maxCount: $maxCount) {
      allNotificationsCount
      unreadNotificationsCount
      allProjectNotificationsCount
      unreadProjectNotificationsCount
    }
  }
`;

export const loadInAppNotificationsQuery = gql`
  query loadInAppNotifications(
    $projectID: UUID
    $showUnreadOnly: Boolean!
    $pagination: PaginationByOffsetID!
    $costMode: CostMode
  ) {
    inAppNotificationsProjectIdReactive @client @export(as: "projectID")
    inAppNotificationsShowUnreadOnlyReactive @client @export(as: "showUnreadOnly")
    inAppNotificationsPaginationReactive @client @export(as: "pagination")
    loadInAppNotifications(
      projectID: $projectID
      showUnreadOnly: $showUnreadOnly
      pagination: $pagination
      costMode: $costMode
    ) {
      ...commonInAppNotificationsFields
    }
  }
  ${commonInAppNotificationsFields}
`;

export const setInAppNotificationReadMutation = gql`
  mutation setInAppNotificationRead(
    $notificationEventID: UUID!
    $isRead: Boolean!
    $costMode: CostMode
  ) {
    setInAppNotificationRead(
      notificationEventID: $notificationEventID
      isRead: $isRead
      costMode: $costMode
    ) {
      ...commonInAppNotificationFields
    }
  }
  ${commonInAppNotificationFields}
`;

export const setAllInAppNotificationsReadMutation = gql`
  mutation setAllInAppNotificationsRead($projectID: UUID, $allProjects: Boolean!) {
    setAllInAppNotificationsRead(projectID: $projectID, allProjects: $allProjects)
  }
`;
