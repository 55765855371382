import Downshift from 'downshift';
import { FC, KeyboardEvent } from 'react';

import { Divider, MenuItem, Popover, Typography } from '@material-ui/core';

import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import AssigneeChip from '../../Collaborators/AssigneeChip';
import SearchBar from '../../Search/SearchBar/SearchBar';
import DropDownArrow from '../../Select/DropDownArrow/DropDownArrow';

import styles from './InputsSelectAssigneePopoverStyles';
import {
  filterCollaborator,
  getCollaboratorEmail,
  sortCollaborator,
} from './InputsSelectAssigneeUtils';

export const assigneeComp = (
  c: Collaborator | { user: null; role?: null } | undefined,
  isButton = false,
  isHeader = false,
  isCompact = false,
  label: string | undefined,
  classesText: string,
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE
) =>
  isCompact ? (
    <Typography className={classesText}>
      {c && c.user && `${c.user.name} (${c.user.email})`}
      {c && c.role && !isButton && !isHeader && ` - ${c.role.name}`}
    </Typography>
  ) : (
    <AssigneeChip
      assignee={(c || {}).user}
      defaultLabel={variant === BULK_EDIT ? 'Choose Assignee' : label}
      expanded={!isButton && !isHeader}
    />
  );

type InputsSelectAssigneePopoverProps = {
  anchorEl: HTMLElement | null;
  assignableCollaborators: Collaborator[];
  classes: Classes<typeof styles>;
  handleSubmit: (collaborator: Collaborator | { user: null }) => void;
  isCompact?: boolean;
  isReset?: boolean;
  label?: string;
  open: boolean;
  selectedCollaborator: Collaborator | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setAnchorEl: (r: any) => void;
};

const InputsSelectAssigneePopover: FC<InputsSelectAssigneePopoverProps> = ({
  anchorEl,
  assignableCollaborators,
  classes,
  handleSubmit,
  isCompact = false,
  label,
  open,
  selectedCollaborator,
  setAnchorEl,
}) => {
  const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setAnchorEl(null);
    }
  };

  const getMenuItemStyle = (index: number, highlightedIndex: number | null) => {
    const highlightedItemstyle = classes.highlight;
    const menuItemStyle = classes.menuItem;
    return `${highlightedIndex === index ? highlightedItemstyle : ''} ${menuItemStyle}`;
  };

  return (
    <Popover
      classes={{ paper: `${classes.noScroll} ${isCompact && classes.compactLocation}` }}
      onKeyDown={handleKeydown}
      onClose={() => {
        setAnchorEl(null);
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id="assignee-menu"
    >
      <Downshift onChange={handleSubmit} itemToString={getCollaboratorEmail}>
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue }) => (
          <div className={classes.container} style={isCompact ? { width: 'unset' } : {}}>
            <MenuItem
              aria-label="selected"
              className={classes.menuHeadItem}
              disableRipple
              onClick={(event) => setAnchorEl(event.currentTarget)}
              {...getItemProps({
                key: getCollaboratorEmail(selectedCollaborator ?? null),
                index: 0,
                item: selectedCollaborator,
              })}
              onBlur={undefined}
            >
              {assigneeComp(selectedCollaborator, false, true, isCompact, label, classes.text)}
              <DropDownArrow isOpen onClick={() => setAnchorEl(null)} />
            </MenuItem>
            <SearchBar
              autoFocus
              className={classes.search}
              placeholder="Name, email, role, responsibility"
              searchTerm={inputValue}
              {...getInputProps()}
            />
            <div className={classes.scroll} {...getMenuProps()}>
              {assignableCollaborators
                .sort(sortCollaborator)
                .filter(filterCollaborator(inputValue))
                .map((collaborator: Collaborator, i: number) => (
                  <MenuItem
                    key={collaborator.user.id}
                    style={{ height: isCompact ? 32 : 44 }}
                    className={getMenuItemStyle(i + 1, highlightedIndex)}
                    {...getItemProps({
                      key: getCollaboratorEmail(collaborator),
                      index: i + 1,
                      item: collaborator,
                    })}
                    data-cy={`user-${collaborator.user.email}`}
                  >
                    {assigneeComp(collaborator, false, false, isCompact, '', classes.text)}
                    <Divider />
                  </MenuItem>
                ))}
            </div>
          </div>
        )}
      </Downshift>
    </Popover>
  );
};

export default withStyles(styles)(InputsSelectAssigneePopover);
