import { FC, useEffect, useRef, useState } from 'react';
import { useClickAway, useEffectOnce, useKey } from 'react-use';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Search from '@material-ui/icons/Search';

import { withStyles } from '../../../theme/komodo-mui-theme';

import SelectFilterMultiOptionList from './SelectFilterMultiOptionList';
import styles from './SelectFilterStyles';

interface SelectFilterProps {
  adornment: string[];
  classes: Classes<typeof styles>;
  filterOptions: string[];
  labels: string[];
  formatValueForDisplay?: (value: string) => string;
  onSelect: (value: string) => void;
  onSubmit: () => void;
  selectedFilters: string[];
  indeterminate: string[];
  title: string;
}

const SelectFilter: FC<SelectFilterProps> = ({
  adornment,
  classes,
  filterOptions,
  labels,
  formatValueForDisplay,
  onSubmit,
  onSelect,
  selectedFilters,
  indeterminate,
  title,
}) => {
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [highlightedFilter, setHighlightedFilter] = useState<string>('');
  const [isScrollableDropdown, setIsScrollableDropdown] = useState(false);

  const selectFilterRef = useRef(null);
  const dropdownArea = document!.getElementById('listbox-list')!;
  const listInput = document!.getElementById('listbox-input')!;

  const getCurrentSearchResults = () => {
    const current: string[] = [];
    const currentLabels: string[] = [];
    filterOptions.forEach((option, i) => {
      const label = labels[i];
      const optionLabel = label || option;
      const formattedOption = (
        formatValueForDisplay ? formatValueForDisplay(optionLabel) : optionLabel
      )?.toLowerCase();
      if (formattedOption?.includes(searchPattern.toLowerCase())) {
        current.push(option);
        currentLabels.push(labels[i]);
      }
      return current;
    });
    return [current, currentLabels];
  };
  const [filterSearchResultsCurrent, filterLabels] = getCurrentSearchResults();
  const hasSearchResults = filterSearchResultsCurrent.length > 0;

  // HANDLERS
  // handles the dynamic height of the dropdown list based on viewport height
  useEffect(() => {
    if (dropdownArea) {
      dropdownArea.style.maxHeight = `${(
        window.innerHeight - dropdownArea.getBoundingClientRect().top
      ).toString()}px`;
    }
  }, [dropdownArea]);

  // handles scrolling the scrollable list area to the top to reset it
  const handleScrollTop = () => {
    if (dropdownArea) {
      dropdownArea.scrollTop = 0;
    }
  };

  // handles when the search term is updated
  const onUpdate = (value: string) => {
    setSearchPattern(value);
    handleScrollTop();
  };

  // handles adding or removing a filter from the selected filters list
  const handleUpdateFilters = () => {
    if (filterOptions.length > 0 && highlightedFilter) {
      onSelect(highlightedFilter);
    }
  };

  // used to figure out how far to scroll the dropdown area while arrowing through results
  const getcurrentListItemOffsetHeight = (): {
    currentListItemOffsetHeight: number;
  } => {
    const highlightedFilterIndex = filterSearchResultsCurrent.indexOf(highlightedFilter);
    const currentListItem =
      dropdownArea && dropdownArea.getElementsByTagName('li')
        ? dropdownArea.getElementsByTagName('li')[highlightedFilterIndex]
        : null;
    const currentListItemOffsetHeight = currentListItem ? currentListItem.offsetHeight - 0.5 : 0;
    return { currentListItemOffsetHeight };
  };

  // when a user clicks on an option
  const handleClick = (): void => {
    handleUpdateFilters();
  };

  // when a user hovers over an option
  const handleHover = (filter: string): void => {
    setHighlightedFilter(filter);
  };

  // index position floor/ceiling logic
  const getFloorAndCeilingValues = (): {
    filterSearchResultsHasLessItems: boolean;
    filterSearchResultsHasMoreItems: boolean;
    highlightedFilterIndex: number;
  } => {
    const highlightedFilterIndex = filterSearchResultsCurrent.indexOf(highlightedFilter);
    const filterSearchResultsHasMoreItems =
      highlightedFilterIndex < filterSearchResultsCurrent.length - 1;
    const filterSearchResultsHasLessItems = highlightedFilterIndex > 0;

    return {
      filterSearchResultsHasLessItems,
      filterSearchResultsHasMoreItems,
      highlightedFilterIndex,
    };
  };

  // handles all keyboard navigation interractions (except Enter/Escape)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleNavigation = (event: any) => {
    const { key } = event;
    const {
      filterSearchResultsHasLessItems,
      filterSearchResultsHasMoreItems,
      highlightedFilterIndex,
    } = getFloorAndCeilingValues();

    const { currentListItemOffsetHeight } = getcurrentListItemOffsetHeight();

    // GOING DOWN THE LIST
    if (key === 'ArrowDown') {
      event.preventDefault();
      if (filterSearchResultsHasMoreItems) {
        setHighlightedFilter(filterSearchResultsCurrent[highlightedFilterIndex + 1]);

        if (highlightedFilterIndex > 0) {
          dropdownArea.scrollBy(0, currentListItemOffsetHeight);
        }
      } else {
        setHighlightedFilter(filterSearchResultsCurrent[0]);
        dropdownArea.scrollTop = 0;
      }
    }

    // GOING UP THE LIST
    if (key === 'ArrowUp') {
      event.preventDefault();
      if (filterSearchResultsHasLessItems) {
        setHighlightedFilter(filterSearchResultsCurrent[highlightedFilterIndex - 1]);

        dropdownArea.scrollBy(0, currentListItemOffsetHeight * -1);
      } else {
        setHighlightedFilter(filterSearchResultsCurrent[filterSearchResultsCurrent.length - 1]);
        dropdownArea.scrollBy(0, dropdownArea.getBoundingClientRect().bottom);
      }
    }
  };

  // WINDOW LISTENERS
  // watch for keyup events
  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  // Auto highlights the text in the input
  useEffect(() => {
    setSearchPattern(searchPattern);
    const input = listInput as HTMLInputElement;
    if (input) {
      input.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInput]);

  // generates initial search results on load
  useEffectOnce(() => {
    setSearchPattern(searchPattern);
  });

  // HANDLE KEY CLOSE EVENTS
  // ESC key closes the component
  useKey('Escape', onSubmit);
  useKey('Tab', onSubmit);
  // clicking away closes the component
  useClickAway(selectFilterRef, onSubmit);

  // handles updating scroll height when the list results change
  useEffect(() => {
    setIsScrollableDropdown(dropdownArea && dropdownArea.clientHeight < dropdownArea.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearchResultsCurrent]);

  return (
    <div ref={selectFilterRef} className={classes.containerOuter} id="SelectCategory">
      <div
        aria-controls="listbox-input"
        aria-expanded
        aria-haspopup="listbox"
        aria-owns="listbox-list"
        className={classes.containerInput}
        role="combobox"
      >
        <Search classes={{ root: classes.iconSearch }} />
        <input
          aria-autocomplete="list"
          aria-controls="listbox-list"
          autoComplete="off"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={classes.input}
          id="listbox-input"
          onChange={(event) => {
            onUpdate(event.target.value);
          }}
          onKeyDown={(event) => {
            const { key } = event;
            if (key === 'Enter') {
              handleUpdateFilters();
            }
          }}
          placeholder={`Find a ${title.toLowerCase()}...`}
          style={{ minHeight: '40px' }}
          type="text"
          value={searchPattern}
        />
        <div
          title="close"
          role="button"
          className={classes.iconDropUpArrowButton}
          onClick={() => {
            onSubmit();
          }}
          onKeyPress={() => {
            onSubmit();
          }}
          tabIndex={0}
        >
          <ArrowDropUpIcon />
        </div>
      </div>
      <SelectFilterMultiOptionList
        adornment={adornment}
        filterSearchResultsCurrent={filterSearchResultsCurrent}
        filterLabels={filterLabels}
        handleClick={handleClick}
        handleHover={handleHover}
        hasSearchResults={hasSearchResults}
        highlightedFilter={highlightedFilter}
        isScrollableDropdown={isScrollableDropdown}
        selectedFilters={selectedFilters}
        indeterminate={indeterminate}
        formatValueForDisplay={formatValueForDisplay}
      />
    </div>
  );
};

export default withStyles(styles)(SelectFilter);
