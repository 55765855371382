import { FC, useEffect, useMemo, useState } from 'react';

import { ALL_MILESTONES, VIEW_FILTER } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import {
  computeDistinctCreators,
  createItemsAssigneeMap,
  getAssigneeIDs,
} from '../../../utilities/items';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { isNonNullable } from '../../../utilities/types';
import { getProjectIdFromUrl } from '../../../utilities/url';
import useUsersByIDs from '../../Collaborators/hooks/useUsersByIDs';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../ItemsList/ItemsListSettingsUtils';
import { useItemsList, useItemsTree } from '../../ItemsList/ItemsListUtils';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import useMemoWrapper from '../../useMemoWrapper';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import useListDetailedItemsQuery from '../PrintItemsListAndItemDetailsList/hooks/useListDetailedItemsQuery';
import PrintItemsDetailsList from '../PrintItemsListAndItemDetailsList/PrintItemDetailsList';

import PrintItemsList from './PrintItemsList';

const PrintViewItemsListAndItemDetailsList: FC = () => {
  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, addSettingsChange } = settingsManager;

  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    addSettingsChange(VIEW_FILTER, newValue)
  );

  const { filterQueryInput: viewFilter } = filterManager;

  const { currentMilestone } = settings;

  // DATA
  // Fetch relevant project data: categorizations list, project name
  const projectId = getProjectIdFromUrl();
  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name;
  const activeMilestoneId = project?.activeMilestone.id;
  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const { data: { milestones = [] } = {}, loading: milestonesLoading } = useMilestonesQuery(
    projectId,
    false
  );
  const projectLoading = projectPropsLoading || projectCategorizationsLoading || milestonesLoading;

  // TIME LINE
  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;

  // GET ITEMS MEMOS
  const milestoneId = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || null;

  const { itemsTree, loading: loadingItemsTree } = useItemsTree(filterManager, settings);
  const {
    itemsList,
    itemsListMap,
    loading: loadingItemsList,
  } = useItemsList(filterManager, settings, true, true);

  const assigneeIDs = useMemoWrapper(getAssigneeIDs, itemsList);
  const { data: assignees, loading: loadingAssignees } = useUsersByIDs(assigneeIDs);
  const itemsAssigneeMap = useMemoWrapper(createItemsAssigneeMap, itemsList, assignees?.usersByIDs);

  const permissions = usePermissions();
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const { loading: loadingItemDetailsList, data: { items: itemDetailsList = [] } = {} } =
    useListDetailedItemsQuery(projectId, null, viewFilter);

  const itemLikeDetailsList = useMemo(() => {
    const itemAndOptionDetailsList: ItemDataQueryItem[] = [];
    itemDetailsList?.filter(isNonNullable).forEach((item) => {
      itemAndOptionDetailsList.push(item);
      item?.options.forEach((option) => {
        itemAndOptionDetailsList.push(option);
      });
    });
    return itemAndOptionDetailsList;
  }, [itemDetailsList]);

  const filteredItemDetailsList = useMemo(() => {
    return itemLikeDetailsList
      .filter((itemDetails) => itemsTree.orderedItemIDs.includes(itemDetails.id))
      .sort(
        (a, b) => itemsTree.orderedItemIDs.indexOf(a.id) - itemsTree.orderedItemIDs.indexOf(b.id)
      );
  }, [itemsTree, itemLikeDetailsList]);

  const itemDetailsListMap = new Map<UUID, ItemDataQueryItem>();
  itemDetailsList?.filter(isNonNullable).forEach((item) => itemDetailsListMap.set(item.id, item));

  const creators = useMemoWrapper(computeDistinctCreators, itemsList);

  const dataLoading =
    projectLoading ||
    loadingItemsTree ||
    loadingItemsList ||
    loadingItemDetailsList ||
    loadingAssignees;

  const milestoneText =
    milestones.find((milestone) => milestone.id === milestoneId)?.name || ALL_MILESTONES;

  const [listImagesAreReady, setListImagesAreReady] = useState(false);
  const triggerOnCompleteList = () => setListImagesAreReady(true);
  const [detailsImagesAreReady, setDetailsImagesAreReady] = useState(false);
  const triggerOnCompleteDetails = () => setDetailsImagesAreReady(true);

  const hooksLoading = dataLoading || !listImagesAreReady || !detailsImagesAreReady;
  useLoadTimer('PrintViewItemsListAndItemDetailsList', hooksLoading);

  usePrintWindow(!dataLoading, listImagesAreReady, detailsImagesAreReady);

  useEffect(() => {
    if (projectName) document.title = `${projectName} - Items List and Item Details`;
  }, [projectName]);

  return (
    <div
      className="print-root"
      // add a test ID so automated report distribution PDF generation (pdf/lib.go) knows the report has loaded
      data-testid={!hooksLoading ? 'report-distribution-print-hooks-loaded' : undefined}
    >
      <PrintItemsList
        shouldDisplayCosts={shouldDisplayCosts}
        categorizations={categorizations}
        creators={creators}
        itemsAssigneeMap={itemsAssigneeMap}
        itemsListItemsMap={itemsListMap}
        itemsTree={itemsTree}
        loading={dataLoading}
        milestoneText={milestoneText}
        settings={settings}
        triggerOnComplete={triggerOnCompleteList}
      />
      <PrintItemsDetailsList
        items={filteredItemDetailsList}
        permissions={permissions}
        triggerOnComplete={triggerOnCompleteDetails}
      />
    </div>
  );
};

export default PrintViewItemsListAndItemDetailsList;
