import { FC, ReactNode } from 'react';

import { List } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { getItemCost } from '../../../utilities/items';
import { generateSharedPath } from '../../../utilities/routes/links';
import ItemsListOption from '../ItemsListItem/ItemsListOption/ItemsListOption';

type ItemsOptionsListProps = {
  getCtaButton?: (o: Option) => ReactNode;
  getOption?: (
    o: Option | ItemsTreeOption | DetailedOption
  ) => Option | ItemsListItem | DetailedOption | undefined;
  isNested?: boolean;
  isNewItemsList?: boolean;
  item: ItemsListItem | ItemDataQueryItem;
  itemsLikeSelected: UUID[];
  nodeKey?: string;
  options: DetailedOption[] | ItemsTreeOption[];
  projectId: UUID;
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
  showCategories?: boolean;
  showCheckbox?: boolean;
  view: string;
};

const ItemsOptionsList: FC<ItemsOptionsListProps> = ({
  getCtaButton = () => null,
  getOption = (o) => o,
  isNested = false,
  isNewItemsList = true,
  item: parentItem,
  itemsLikeSelected,
  nodeKey,
  options, // this the list of display options, will be treated like an Item(like)
  projectId,
  selectItemLike,
  sharedUsersMap,
  showCategories = true,
  showCheckbox = true,
  view,
}) => (
  // FUNCTIONS

  <List key={`${nodeKey} list`} data-cy="itemsCollapse-optionsList">
    {options &&
      options.map((o) => {
        // If these are provided, they are passed to the item
        const option = getOption(o);

        if (!option) return null;
        const optionID = 'id' in option ? option.id : '';
        const isSelected = itemsLikeSelected.some((i) => i === optionID);
        const costImpact = getItemCost(option as Option);

        const itemsTreeOptionCost = (o as ItemsTreeOption).cost;
        const itemsListOptionCost = (option as ItemsListItem).cost;
        const costsMatch =
          JSON.stringify(itemsTreeOptionCost) === JSON.stringify(itemsListOptionCost);

        const mainCostImpact = isNewItemsList
          ? itemsTreeOptionCost || itemsListOptionCost
          : undefined;
        const totalCostImpact =
          isNewItemsList && itemsTreeOptionCost && !costsMatch ? itemsListOptionCost : undefined;

        if (!option) return null;

        return (
          <ItemsListOption
            costImpact={costImpact as CostRange | CostScalar}
            ctaButton={getCtaButton(option as Option)}
            isNested={isNested}
            isSelected={isSelected}
            item={parentItem}
            mainCostImpact={mainCostImpact}
            totalCostImpact={totalCostImpact}
            key={`${nodeKey} ${optionID}`}
            link={generateSharedPath(JoinProjectRoutes.ITEM_DETAILS, {
              projectId,
              itemId: optionID,
            })}
            option={option as ItemsListItem}
            selectItemLike={selectItemLike}
            sharedUsers={sharedUsersMap.get(optionID) ?? []}
            showCategories={showCategories}
            showCheckbox={showCheckbox}
            view={view}
          />
        );
      })}
  </List>
);
export default ItemsOptionsList;
