import { ReactNode } from 'react';

import Cell from './Cell';

const formatNumber = new Intl.NumberFormat().format;

type Props = {
  bold?: boolean;
  compact?: boolean;
  number?: string | number | null;
};

export default function NumberCell(props: Props) {
  let number = props.number;
  if (typeof number === 'string') number = parseInt(number, 10);

  let content: ReactNode = typeof number === 'number' ? formatNumber(number) : '';
  if (props.bold) content = <strong>{content}</strong>;

  return (
    <Cell compact={props.compact}>
      <div className={props.compact ? 'type-table-number' : 'type-number'}>{content}</div>
    </Cell>
  );
}
