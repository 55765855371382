import { useMutation, useReactiveVar } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import {
  CreateScenarioItemMutation,
  CreateScenarioItemMutationVariables,
  ScenarioItemCreationInput,
} from '../../../generated/graphql';
import { costModeVar } from '../../../utilities/costMode';

import { createScenarioItemMutation } from './queries';

const useCreateScenarioItemMutation = ({
  scenarioID,
  projectID,
  milestoneID,
}: {
  scenarioID: UUID;
  projectID: UUID;
  milestoneID: UUID;
}) => {
  const [createFn] = useMutation<CreateScenarioItemMutation, CreateScenarioItemMutationVariables>(
    createScenarioItemMutation,
    {
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    }
  );

  const costMode = useReactiveVar(costModeVar);

  const createScenarioItem = ({ itemInput }: { itemInput: ScenarioItemCreationInput }) =>
    createFn({
      variables: {
        itemInput,
        scenarioID,
        projectID,
        milestoneID,
        costMode,
      },
    });

  return [createScenarioItem];
};

export default useCreateScenarioItemMutation;
