import { useState } from 'react';

import {
  SettingsEvent,
  setEditRolePermissionsAnalytics,
  settingsEvent,
} from '../../../analytics/analyticsEventProperties';
import { ADMINISTRATOR_ROLE, EDIT_TEMPLATE_ROLE, VIEW_ONLY_ROLE } from '../../../constants';
import { PermissionLevel, PermissionResource } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import useProjectRoleQuery from '../../../hooks/useProjectRoleQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { Button } from '../../scales';
import RoleEditConfirm from '../RoleEditConfirm';

import PermissionsRoleHeader from './PermissionRoleNewHeader';
import useSyncPermissionsWithBackend from './PermissionsHooks';
import PermissionsRoleNameEdit from './PermissionsRoleNameEdit';
import PermissionsRoleStyles from './PermissionsRoleStyles';
import PermissionsRoleTradeEdit from './PermissionsRoleTradeEdit';
import PermissionsTable from './PermissionsTable';

type Props = {
  roleID: string | UUID;
};

const PermissionsRoleNew = (props: Props) => {
  const projectId = getProjectIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();

  const syncPermissionsWithBackend = useSyncPermissionsWithBackend();
  const { canEdit } = usePermissions();

  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [roleName, setRoleName] = useState<string | undefined>(undefined);
  const [hasTrade, setHasTrade] = useState<boolean | undefined>(undefined);
  const [permissionsMap, setPermissionsMap] = useState(new Map<UUID, PermissionLevel>());

  const roleQueryResult = useProjectRoleQuery(projectId, props.roleID);
  const role = roleQueryResult.data?.projectRole ?? roleQueryResult.previousData?.projectRole;

  const canEditPermissionTemplates = canEdit(PermissionResource.PERMISSION_TEMPLATES);
  // TODO DD-843: Create role type to avoid need for special names
  const isSaveDisabled =
    ((!roleName || roleName === role?.name) &&
      (role?.hasTrade === hasTrade || hasTrade === undefined) &&
      permissionsMap.size === Number(0)) ||
    roleName?.trim() === ADMINISTRATOR_ROLE ||
    roleName?.trim() === VIEW_ONLY_ROLE ||
    roleName?.trim() === EDIT_TEMPLATE_ROLE;

  const setMap = (permissionsMap: Map<UUID, PermissionLevel>) =>
    setPermissionsMap(new Map(permissionsMap));

  const localPermissionGroups =
    (role &&
      role.permissionGroups &&
      role.permissionGroups.map((group) => {
        const permissions =
          group &&
          group.permissions.map((permission: Permission) => {
            const level = permissionsMap.get(permission.id);
            if (level) return { ...permission, level };
            return permission;
          });
        return { ...group, permissions };
      })) ||
    [];

  const onChangePermissions = (
    id: UUID,
    { level }: { level: PermissionLevel },
    permission?: string
  ) => {
    const permissionMatch = role?.permissionGroups
      .map((group) => group.permissions)
      .flat()
      .find((permission: Permission) => permission.id === id);
    if (permissionMatch && permissionMatch.level === level) {
      permissionsMap.delete(id);
      setMap(permissionsMap);
    } else {
      setMap(permissionsMap.set(id, level));
    }
    sendAnalytics(
      settingsEvent(SettingsEvent.PERMISSION_CHANGE, {
        projectId,
        role: role?.name,
        permission,
        level,
      })
    );
  };

  const onCancel = () => {
    setOpen(true);
    sendAnalytics(setEditRolePermissionsAnalytics(!editable, role?.name));
  };

  const onReset = () => {
    setEditable(!editable);
    setRoleName(undefined);
    setHasTrade(undefined);
    permissionsMap.clear();
    setMap(permissionsMap);
  };

  const onSave = (
    name?: string,
    hasTrade?: boolean,
    permissionsMap?: Map<string, PermissionLevel> | undefined
  ) => {
    setEditable(!editable);
    const permissionUpdates =
      permissionsMap && permissionsMap.size > 0
        ? Array.from(permissionsMap).map(([id, level]) => ({ id, level }))
        : [];
    syncPermissionsWithBackend({
      roleID: props.roleID,
      name: name !== role?.name ? name : undefined,
      hasTrade: hasTrade !== role?.hasTrade ? hasTrade : undefined,
      permissionUpdates,
    });
    sendAnalytics(setEditRolePermissionsAnalytics(false, roleName));
  };

  return (
    <div className="p-6">
      <PermissionsRoleHeader roleName={roleName ?? role?.name ?? ''}>
        {canEditPermissionTemplates && (
          <div className="flex gap-2">
            {!editable && (
              <Button
                data-cy="button-role-edit"
                disabled={role?.name === ADMINISTRATOR_ROLE}
                label="Edit Role"
                onClick={() => setEditable(true)}
                type="secondary"
              />
            )}
            {editable && (
              <Button
                data-cy="button-role-edit-cancel"
                label="Cancel"
                onClick={onCancel}
                type="secondary"
              />
            )}
            {editable && (
              <Button
                data-cy="button-role-save"
                disabled={isSaveDisabled}
                label="Save"
                onClick={() => onSave(roleName, hasTrade, permissionsMap)}
                type="secondary"
              />
            )}
          </div>
        )}
      </PermissionsRoleHeader>
      <div className="flex w-1/2 flex-row gap-2 pb-6 pt-6">
        <PermissionsRoleNameEdit
          roleName={roleName ?? role?.name ?? ''}
          onChange={setRoleName}
          editable={editable}
        />
        <PermissionsRoleTradeEdit
          hasTrade={hasTrade ?? role?.hasTrade ?? false}
          onChange={setHasTrade}
          editable={editable}
        />
      </div>
      {localPermissionGroups
        .filter((group) => group.permissions.length > 0)
        .map((group) => (
          <PermissionsTable
            editable={editable}
            group={group as PermissionGroup}
            hasTrade={hasTrade}
            key={group.type}
            onChange={onChangePermissions}
          />
        ))}
      <RoleEditConfirm
        name={roleName ?? role?.name ?? ''}
        open={open}
        setConfirmModalOpen={(isOpen: boolean) => setOpen(isOpen)}
        setEditable={(edit: boolean) => setEditable(edit)}
        onReset={onReset}
      />
    </div>
  );
};

export default withStyles(PermissionsRoleStyles)(PermissionsRoleNew);
