import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { searchEventTypes } from '../../../../analytics/analyticsEventProperties';
import { itemSidebarHomeOpenVar } from '../../../../api/apollo/reactiveVars';
import { ForecastingExploreItemsSortKey } from '../../../../generated/graphql';
import { HOME_ITEM_SIDEBAR } from '../../../../tagConstants';
import Button from '../../../ForecastingTab/common/Button';
import CostCell from '../../../ForecastingTab/ForecastingExplore/items/cells/CostCell';
import ItemCell from '../../../ForecastingTab/ForecastingExplore/items/cells/ItemCell';
import ItemStatusCell from '../../../ForecastingTab/ForecastingExplore/items/cells/ItemStatusCell';
import MilestoneDateCell from '../../../ForecastingTab/ForecastingExplore/items/cells/MilestoneDateCell';
import ProjectCell from '../../../ForecastingTab/ForecastingExplore/items/cells/ProjectCell';
import { CloseItemSidebar, OpenItemSidebar } from '../../../Icons/OpenSidebar';
import { SortManager, Table } from '../../../scales';
import useSendSearchAnalytics from '../hooks/useSendSearchAnalytics';

type Props = {
  counts: { current: number; total: number };
  items: ForecastingItem[];
  loading: boolean;
  onFetchMore: () => void;
  onOpenItemSidebar: (id: UUID) => void;
  searchText: string;
  sortManager: SortManager;
};

const SearchItems: FC<Props> = (props) => {
  const sendSearchAnalytics = useSendSearchAnalytics();
  const itemIdHomeReactiveVar = useReactiveVar(itemSidebarHomeOpenVar);

  return (
    <Table
      entries={props.items.map((item) => {
        const key = item.id;

        return [
          { key, component: <ItemCell item={item} searchText={props.searchText} /> },
          { key, component: <ItemStatusCell status={item.status} /> },
          { key, component: <CostCell cost={item.cost} /> },
          { key, component: <CostCell cost={item.project.runningTotal} /> },
          { key, component: <ProjectCell item={item} /> },
          { key, component: <MilestoneDateCell item={item} /> },
          {
            key,
            component: (
              <Button
                icon={
                  itemIdHomeReactiveVar === item.id ? <CloseItemSidebar /> : <OpenItemSidebar />
                }
                onClick={() => {
                  props.onOpenItemSidebar(item.id);
                  sendSearchAnalytics(searchEventTypes.SEARCH_ITEM_DETAIL_CTA, {
                    id: item.id,
                    name: item.name,
                    state: itemIdHomeReactiveVar === item.id ? 'Close' : 'Open',
                  });
                }}
                type="secondary"
                disabled={!item.hasAccess}
                dataCy={HOME_ITEM_SIDEBAR}
              >
                {itemIdHomeReactiveVar === item.id ? 'Close' : 'Details'}
              </Button>
            ),
          },
        ];
      })}
      columnWidths={[
        'minmax(300px, 10fr)',
        'max-content',
        'max-content',
        'max-content',
        'minmax(150px, 3fr)',
        'max-content',
        'max-content',
      ]}
      headerContent={[
        {
          copy: `Items (${new Intl.NumberFormat().format(
            props.counts.current
          )} of ${new Intl.NumberFormat().format(props.counts.total)})`,
          key: 'items',
          headerSortKey: ForecastingExploreItemsSortKey.SCORE,
        },
        {
          copy: 'Status',
          key: 'status',
          headerSortKey: ForecastingExploreItemsSortKey.STATUS,
        },
        {
          copy: 'Cost impact',
          key: 'cost-impact',
          headerSortKey: ForecastingExploreItemsSortKey.COST_IMPACT,
        },
        {
          copy: 'Project total',
          key: 'project-total',
          tooltip: 'Running total',
        },
        {
          copy: 'Project',
          key: 'project',
          headerSortKey: ForecastingExploreItemsSortKey.PROJECT_NAME,
        },
        {
          copy: 'Milestone date',
          key: 'milestone-date',
          headerSortKey: ForecastingExploreItemsSortKey.MILESTONE_DATE,
        },
        { copy: '', key: 'expand', centered: true },
      ]}
      loading={props.loading}
      onNeedMoreData={props.onFetchMore}
      sortManager={props.sortManager}
    />
  );
};

export default SearchItems;
