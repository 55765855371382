import { FC, useEffect, useState } from 'react';

import { IS_EXPANDED, VIEW_FILTER } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import ErrorCode from '../errors/ErrorCode';
import { useFilterManager } from '../FilterPanel/filterUtils';
import { useItemQuery } from '../Items/hooks/useItemQuery';
import { useItemsListSettings } from '../ItemsList/ItemsListSettingsUtils';
import { useItemsList, useItemsTree } from '../ItemsList/ItemsListUtils';

import ItemDetails from './ItemDetails';
import { getTopNavLinks } from './ItemDetailsUtils';

const ItemDetailsData: FC = () => {
  const loadItemCosts = false;
  const projectId = getProjectIdFromUrl();
  const itemID = getItemIdFromUrl();

  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;

  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const setIsExpanded = (isExpanded: boolean) => {
    persistSettingsChange(IS_EXPANDED, isExpanded);
  };
  const { isExpanded } = settings;

  // DATA QUERIES
  const {
    data,
    error,
    loading: itemLoading,
    refetch: refetchItem,
  } = useItemQuery(itemID, MountPolicy.SKIP_ON_MOUNT);
  const item = data?.item;
  const { name: itemName } = item || {};

  const parentID = (item as { parent: UUID })?.parent;
  const { data: parentData, loading: parentLoading } = useItemQuery(parentID);
  const parentItem = parentData?.item ?? null;

  // get activeMilestoneId
  const { data: projectPropsData, loading: projectPropsLoading } = useProjectPropsQuery(projectId);
  const { project } = projectPropsData || {};
  const { name: projectName } = project || {};

  useEffect(() => {
    if (projectName) {
      document.title = `${projectName} - ${itemName}`;
    }
  }, [projectName, itemName]);

  // the default state is undefined while we are still loading the item
  const [optionCount, setOptionCount] = useState<number | undefined>(undefined);
  // if the user adds a new option then expand the option panel
  useEffect(() => {
    if (item && 'options' in item && item.options.length !== optionCount) {
      // if the item has been loaded, and we changed the number of options then expand the panel
      if (optionCount !== undefined) setIsExpanded(true);
      setOptionCount(item.options.length);
    }
  }, [item, optionCount, setIsExpanded]);

  const { canView, canEdit, canAdd, canDelete, inPreviewMode, userID } = usePermissions({
    trades: item?.categories,
  });

  // previewMode negates didCreateItem and isAssignedItem, as you are choosing
  // to view permissions separate from your own
  const didCreateItem = item?.createdBy?.id === userID && !inPreviewMode;
  const isAssignedItem = item?.assignee?.id === userID && !inPreviewMode;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));
  const canEditItemStatus = canEdit(PermissionResource.ITEM_STATUS);
  const canAddItemComments = canAdd(PermissionResource.ITEM_COMMENTS);
  const canDeleteItemDetails = canDelete(PermissionResource.ITEM_DETAILS) || didCreateItem;
  const canViewItemIntegrations = canView(PermissionResource.ITEM_INTEGRATIONS);
  const canDeleteItemIntegration = canDelete(PermissionResource.ITEM_INTEGRATIONS);
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );
  const { itemsTree, isEmpty } = useItemsTree(filterManager, settings, inPreviewMode, true);
  const { itemsList } = useItemsList(filterManager, settings, inPreviewMode, loadItemCosts, true);
  const navigationLinks = !isEmpty
    ? getTopNavLinks(item ?? undefined, itemsTree?.orderedItemIDs, itemsList)
    : undefined;
  const loading = itemLoading || parentLoading || projectPropsLoading;
  if (loading) return null;
  if (error) return <ErrorCode code={404} message="Item not found" />;
  if (!item) return null;

  return (
    <ItemDetails
      key={itemID}
      canAddItemComments={canAddItemComments}
      canDeleteItemIntegration={canDeleteItemIntegration}
      canDeprecateItems={canDeleteItemDetails}
      canEditItemDetails={canEditItemDetails}
      canEditItemStatus={canEditItemStatus}
      canViewItemIntegrations={canViewItemIntegrations}
      isExpanded={!!isExpanded}
      item={item}
      navigationLinks={navigationLinks}
      parentItem={parentItem as ItemLike}
      refetchItem={refetchItem}
      setIsExpanded={setIsExpanded}
    />
  );
};
export default ItemDetailsData;
