import { useState } from 'react';

import { TextInput } from '../../../scales';

type Props = {
  name: string;
  onEditMetadata: (metadata: { name?: string; description?: string }) => void;
};

const ScenarioCardName = (props: Props) => {
  const [name, setName] = useState(props.name);

  return (
    <TextInput
      aria-label="scenario name"
      data-cy="scenario-name"
      value={name}
      onBlur={() => props.onEditMetadata({ name })}
      onChange={setName}
      placeholder="Name this scenario..."
    />
  );
};

export default ScenarioCardName;
