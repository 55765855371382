import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { UserStatus } from '../../../api/gqlEnumsBe';
import { ALL_COLLABORATORS } from '../../../constants';
import { pluralizeCountString } from '../../../utilities/string';
import { onlyUnique } from '../../../utilities/utilities';

import { BoolMap, Selected, ToCollaborator, UsersSortKey } from './types';

export const getSelectedIds = (selected: Selected) =>
  Object.keys(selected).filter((id) => selected[id]);

const getSelectedMap = (ids: UUID[]) => ids.reduce((ac, v) => ({ ...ac, [v]: true }), {});

export const useUsersTableSelection = (
  initialIds: UUID[],
  currentId: UUID,
  initialIndeterminateMap: BoolMap,
  displayIds: UUID[]
) => {
  const [selectedMap, setSelectedMap] = useState<Selected>(getSelectedMap(initialIds));
  const [indeterminateMap, setIndeterminateMap] = useState<BoolMap>(initialIndeterminateMap);
  if (Object.keys(indeterminateMap).length !== Object.keys(initialIndeterminateMap).length) {
    setIndeterminateMap(initialIndeterminateMap);
  }
  useDeepCompareEffect(() => setSelectedMap(getSelectedMap(initialIds)), [initialIds]);
  const selectedIds = getSelectedIds(selectedMap);
  const toggleSelected = useCallback(
    (id: string) => {
      if (id === currentId) return;
      setSelectedMap({ ...selectedMap, [id]: !selectedMap[id] });
      setIndeterminateMap({ ...indeterminateMap, [id]: false });
    },
    [selectedMap, indeterminateMap, currentId]
  );
  const onToggleEntry = useCallback(
    ({ id }: ToCollaborator) => {
      toggleSelected(id);
    },
    [toggleSelected]
  );
  const onSelectAll = (ids: string[]) => {
    const selectedIds = getSelectedIds(selectedMap);
    const selectedNotDisplayedIds = selectedIds.filter((id) => !displayIds.includes(id));
    const withCurrent = [currentId, ...selectedNotDisplayedIds, ...ids];
    const newSelectedMap = getSelectedMap(withCurrent);
    setSelectedMap(newSelectedMap);
  };
  const onUnselectAll = () => {
    const selectedIds = getSelectedIds(selectedMap);
    const selectedNotDisplayedIds = selectedIds.filter((id) => !displayIds.includes(id));
    const withCurrent = [currentId, ...selectedNotDisplayedIds];
    const newSelectedMap = getSelectedMap(withCurrent);
    setSelectedMap(newSelectedMap);
  };
  const resetMaps = (resetIds: UUID[], resetIndeterminateMap: BoolMap) => {
    setSelectedMap(getSelectedMap(resetIds));
    setIndeterminateMap(resetIndeterminateMap);
  };
  return {
    onToggleEntry,
    onSelectAll,
    onUnselectAll,
    selectedIds,
    indeterminateMap,
    resetMaps,
  };
};

export const filterActiveCollaborators = (collaborators: ToCollaborator[]) =>
  collaborators.filter((collaborator) => collaborator.user.status !== UserStatus.DEACTIVATED);

export const filterCollaboratorsByKey = (
  collaborators: ToCollaborator[],
  filter: string,
  key: UsersSortKey
): ToCollaborator[] => {
  if (!filter || filter === ALL_COLLABORATORS) return collaborators;
  return collaborators.filter((collaborator) => get(collaborator, key) === filter);
};

export const filterSharedCollaborators = (
  userIDs: string[],
  allActiveCollaboratorss: ToCollaborator[]
) => {
  const collaborators: string[] = [];
  userIDs.forEach((userID) => {
    const collaborator = allActiveCollaboratorss.find(
      (collaborator) => collaborator.user.id === userID
    );
    if (collaborator) collaborators.push(collaborator.id);
  });
  return collaborators;
};

export const addCompaniesToCollaborators = (
  collaborators: ToCollaborator[],
  companies: ProjectCompany[]
): ToCollaborator[] =>
  collaborators.map((c) => ({
    ...c,
    company: companies.find((company) => c.user.email.endsWith(company.company.domain || '')),
  }));

export const getCollaboratorKeys = (collaborators: ToCollaborator[], key: UsersSortKey) =>
  collaborators
    .map((collaborator) => get(collaborator, key))
    .filter((value, index, arr) => !!value && onlyUnique(value, index, arr));

export const getSharedCount = (
  selectedIds: UUID[],
  currentId: UUID,
  sharedIds: UUID[],
  indeterminateIDs: UUID[]
) => {
  const selectedCount = selectedIds.filter((id) => id !== currentId).length;
  const newCollaboratorIDs = selectedIds
    .filter((id) => id !== currentId)
    .filter((id) => !sharedIds.includes(id));
  const removedCollaboratorIDs = [...sharedIds, ...indeterminateIDs].filter(
    (id) => !selectedIds.includes(id)
  );
  return { selectedCount, newCollaboratorIDs, removedCollaboratorIDs };
};

export const getSelectedText = (
  sharedCollaboratorIDs: UUID[],
  newCollaboratorIDs: UUID[],
  removedCollaboratorIDs: UUID[]
) => {
  const newCount = newCollaboratorIDs.length;
  const removedCount = removedCollaboratorIDs.length;

  const selectedText =
    sharedCollaboratorIDs?.length >= 2
      ? `Shared between ${pluralizeCountString('teammate', sharedCollaboratorIDs?.length)}`
      : 'Not shared';

  const summaryTextNew = newCount ? `Adding ${pluralizeCountString('teammate', newCount)}.` : '';
  const summaryTextRemoved = removedCount
    ? `Removing ${pluralizeCountString('teammate', removedCount)}.`
    : '';

  const summaryText = `${summaryTextNew} ${summaryTextRemoved}`;
  return { summaryText, selectedText };
};
