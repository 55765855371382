import { createStyles } from '@material-ui/core';

import { REPORT_BAR_MIN_HEIGHT, SIDEBAR_MINWIDTH_HALF } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      background: theme.palette.blue,
      borderRadius: '0px 0px 3px 3px',
      marginLeft: SIDEBAR_MINWIDTH_HALF,
      display: 'flex',
      alignItems: 'center',
      minHeight: REPORT_BAR_MIN_HEIGHT,
      maxWidth: 600,
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 72,
      paddingRight: 12,
    },
    iconButton: {
      height: 20,
      width: 20,
      marginTop: 4,
      marginBottom: 4,
      marginLeft: 2,
      marginRight: 2,
      background: theme.palette.common.white,
    },
    icon: {
      fontSize: 'unset',
      height: 16,
      margin: 4,
      width: 16,
    },
    marginLeft: {
      marginLeft: 4,
    },
    titleText: {
      paddingLeft: 4,
      fontWeight: 500,
      color: 'white',
      cursor: 'default',
    },
    buttonPlaceholder: {
      minWidth: 24,
    },
  });
