import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useActiveProjectBannerQuery } from '../../ProjectProperties/PrintHeader/hooks/GetActiveProjectBannerQuery';
import { useProjectBannersQuery } from '../../ProjectProperties/PrintHeader/hooks/GetProjectBannersQuery';

import styles from './TeamSummaryStyles';
import { emptyIsTeamLogos, useTeamSummaryImages } from './TeamSummaryUtils';

type TeamSummaryProps = {
  classes: Classes<typeof styles>;
  projectID: UUID;
};

const TeamSummary: FC<TeamSummaryProps> = ({ classes, projectID }) => {
  const {
    data: { project: { team } = { team: undefined } },
  } = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT);

  const teamData = useTeamSummaryImages(team ?? undefined, projectID);

  const teamImages =
    teamData.length > 0 &&
    teamData.map(({ role, image, name }) => (
      <div key={`${name} ${role}`} className={classes.imgColumn}>
        <img
          alt={`${name} logo (${role})`}
          className={classes.logo}
          key={`${name} ${image} ${role}`}
          src={image}
        />
      </div>
    ));

  // Get active project banner query
  const { data: { activeProjectBanner = null } = { activeProjectBanner: null } } =
    useActiveProjectBannerQuery(projectID);
  const activeBanner = activeProjectBanner &&
    activeProjectBanner.asset &&
    activeProjectBanner.asset.blobUrl && (
      <img
        alt="logo"
        className={classes.banner}
        key={`${activeProjectBanner.asset.blobUrl}`}
        src={activeProjectBanner.asset.blobUrl}
      />
    );

  // Get project banners
  const { data: { projectBanners = null } = { projectBanners: null } } =
    useProjectBannersQuery(projectID);
  const isEmptyTeamLogos = emptyIsTeamLogos(projectBanners);
  const banner = activeBanner || (isEmptyTeamLogos && teamImages);

  return <div className={classes.containerOuter}>{banner}</div>;
};
export default withStyles(styles)(TeamSummary);
