import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../constants';
import { KomodoTheme } from '../../theme/komodo-mui-theme';
import { tableStyles } from '../../theme/table-styles';

export const styles = (theme: KomodoTheme) => {
  const { cell, header } = tableStyles(theme);
  return createStyles({
    avatar: {
      backgroundColor: theme.palette.joinGrey400,
      height: 33,
      width: 33,
    },
    action: {
      marginRight: -16,
      marginTop: 0,
    },
    button: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
        color: 'white',
      },
    },
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      marginBottom: 24,
      overflow: 'visible',
    },
    cardContent: {
      marginBottom: 24,
    },
    cardRoot: {
      padding: '24px 16px 24px 16px',
    },
    cardHeader: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    cell,
    cellLink: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: theme.palette.joinPrimary,
      textDecoration: 'underline',
    },
    cells: {
      ...theme.typography.body1,
      padding: '8px 16px',
      color: theme.palette.primaryGrey,
      border: 'none',
      '&:last-child': {
        float: 'right',
      },
    },
    disabled: {
      opacity: 0.5,
    },
    emailNotificationSwitch: {
      color: theme.palette.primaryGrey,
      margin: 12,
    },
    formControl: {
      color: theme.palette.primaryGrey,
      maxWidth: 300,
      minWidth: 80,
    },
    header,
    info: {
      color: theme.palette.joinGrey400,
      margin: 12,
    },
    label: {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    leftCell: {
      paddingLeft: '24px',
    },
    linkTableCell: {
      display: 'table-cell',
      textDecoration: 'none',
      padding: '8px 8px 8px 4px',
    },
    menuItem: {
      display: 'block',
      height: 42,
    },
    middleAlign: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    previewButton: {
      whiteSpace: 'nowrap',
      padding: '4px 16px 4px 12px',
      minWidth: 36,
      marginLeft: 8,
      paddingRight: 3 * theme.spacing.unit,
      textTransform: 'capitalize',
      width: 124,
      marginRight: '4px',
    },
    root: {
      flexGrow: 1,
      overflow: 'auto',
      paddingBottom: 180,
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
    row: {
      backgroundColor: 'white !important',
      borderTop: theme.border.line,
      '&:last-child': {
        borderBottom: theme.border.line,
        borderTop: theme.border.line,
        paddingLeft: 24,
      },
      '&:nth-child(even)': {
        backgroundColor: 'white',
      },
    },
    rowRightBlock: {
      display: 'inline',
    },
    smallCell: {
      width: 48,
    },
    tableCell: {
      display: 'table-cell',
      paddingLeft: '16px',
    },
    tableGroup: {
      borderCollapse: 'collapse',
      display: 'table',
      width: '100%',
    },
    tableRow: {
      borderTop: theme.border.line,
      display: 'table-row',
    },
    text: {
      marginLeft: 8,
      marginRight: 8,
    },
    viewingLabel: {
      maxWidth: 160,
    },
  });
};

export default styles;
