import { ReactNode, useEffect, useRef, useState } from 'react';

import { ErrorOutline } from '@material-ui/icons';

type Props = {
  children: ReactNode;
  type: 'error' | 'warning';
  'data-cy'?: string;
};

export default function StatusBanner(props: Props) {
  const [isMultiline, setIsMultiline] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // When the text contents fit on a single line, we want to center the icon.
  // Otherwise, we want to pin it to the top. In essence, conditionally set
  // `align-items: center` on a flex container.
  // To test whether the text contents are on multiple lines, we measure the
  // text element's height and pull its computed line-height.
  useEffect(() => {
    if (ref.current) {
      const height = ref.current?.offsetHeight ?? 0;
      const lineHeight = 16; // defined via the `type-label` class

      setIsMultiline(height / lineHeight >= 2);
    }
  }, [props.children]);

  let backgroundClass = '';
  let iconClass = '';
  if (props.type === 'warning') {
    backgroundClass = 'bg-status-banner-default-bg';
    iconClass = 'text-status-banner-warning-icon';
  } else if (props.type === 'error') {
    backgroundClass = 'bg-status-banner-error-bg';
    iconClass = 'text-status-banner-error-icon';
  }

  const containerClassName = `flex overflow-hidden ${
    isMultiline ? '' : 'items-center'
  } gap-2 ${backgroundClass} p-4 text-type-primary type-label`;

  return (
    <div className={containerClassName}>
      <div className={iconClass}>
        <ErrorOutline />
      </div>
      <div data-cy={props['data-cy']} ref={ref}>
        {props.children}
      </div>
    </div>
  );
}
