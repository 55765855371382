import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Typography } from '@material-ui/core';
import { Help, Person } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getUserPicture } from '../../Collaborators/UserUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { SortManager } from '../../scales';
import ProjectTable from '../CompanyTabMembersProfile/ProjectTable';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  CompanyUserSearchManager,
  getCompanyName,
  getIsDeactivated,
  getStatusColor,
  searchCompanyCollaborators,
} from '../CompanyTabUtils';
import StatusTooltipContent from '../StatusTooltipContent';

import CompanyTabCollaboratorsSidebar from './CompanyTabCollaboratorsSidebar';

type CompanyTabCollaboratorsProfileProps = {
  classes: Classes<typeof CompanyTabStyles>;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  companyCollaborators: CompanyCollaborator[];
  companyCollaboratorDetails?: CompanyUserDetails;
  companyCollaboratorsLoading: boolean;
  countTotalCollaborators: number;
  sortManagerProjects: SortManager;
  sortManagerCollaborators: SortManager;
  searchManager: CompanyUserSearchManager;
  userCounts: number[];
};

const CompanyTabCollaboratorsProfile: FC<CompanyTabCollaboratorsProfileProps> = ({
  classes,
  companyCollaborators,
  companyCollaboratorsLoading,
  companyCollaboratorDetails,
  countTotalCollaborators,
  filterStatus,
  setFilterStatus,
  searchManager,
  sortManagerProjects,
  sortManagerCollaborators,
  userCounts,
}) => {
  const { user } = companyCollaboratorDetails || { user: undefined };

  const { searchTerm } = searchManager;

  const searchedCompanyCollaborators = searchCompanyCollaborators(companyCollaborators, searchTerm);

  const currentCompanyID = companyCollaboratorDetails?.role?.companyID;

  const deactivated = user && getIsDeactivated(user);

  const sendAnalytics = useAnalyticsEventHook();

  const userPhoto = user?.picture ? (
    <Avatar className={classes.avatarImage} src={getUserPicture(user)} />
  ) : (
    <Avatar className={`${classes.avatarImage} ${classes.avatarPerson}`}>
      <Person fontSize="inherit" />
    </Avatar>
  );

  return (
    <div className={classes.memberContainer}>
      <CompanyTabCollaboratorsSidebar
        companyCollaborators={searchedCompanyCollaborators}
        countTotalCollaborators={countTotalCollaborators}
        loading={companyCollaboratorsLoading}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        searchManager={searchManager}
        userCounts={userCounts}
        sortManager={sortManagerCollaborators}
      />
      {companyCollaboratorDetails && user && (
        <div className={classes.memberContent}>
          <div className={`${classes.flex} ${classes.marginBottom}`}>
            <Link
              className={classes.linkBreadcrumb}
              to={generateSharedPath(JoinCompanyRoutes.COMPANY_COLLABORATORS, {})}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.COLLABORATORS_BREADCRUMB_CLICK)
                );
              }}
            >
              <Typography>Partners</Typography>
            </Link>
            <Typography className={classes.divider}>/</Typography>
            <Typography>
              {user?.name || ''} - {user?.email || ''}
            </Typography>
          </div>
          <div className={classes.memberData}>
            <div>
              <div className={`${classes.flex} ${classes.marginBottom}`}>
                <div>
                  <div className={`${classes.flex} ${classes.marginRight}`} />
                  <div
                    className={
                      deactivated
                        ? `${classes.avatarProfile} ${classes.deactivatedAvatar}`
                        : `${classes.avatarProfile}`
                    }
                  >
                    {userPhoto}
                  </div>
                </div>
                <div className={classes.flexGrow}>
                  <Typography className={classes.subText} variant="title">
                    Name
                  </Typography>
                  <Typography className={classes.collaboratorText}>{user.name}</Typography>
                  <Typography className={classes.subText} variant="title">
                    Company
                  </Typography>
                  <Typography className={classes.collaboratorText}>
                    {getCompanyName(companyCollaborators, currentCompanyID)}
                  </Typography>

                  <Typography className={classes.subText} variant="title">
                    Job Title
                  </Typography>
                  <Typography className={classes.collaboratorText}>
                    {user.jobTitle || '-'}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.inline}>
                <Typography className={classes.subText} variant="title">
                  Status
                </Typography>
                <NormalTooltip title={StatusTooltipContent}>
                  <Help className={classes.tooltip} />
                </NormalTooltip>
              </div>
              <div className={classes.inline}>
                <div style={{ color: getStatusColor(user.status) }}>{'\u2B24'}&nbsp;</div>
                <div className={classes.text}>{user.status}</div>
              </div>
            </div>
          </div>
          <ProjectTable
            companyUserDetails={companyCollaboratorDetails}
            sortManager={sortManagerProjects}
            isCollaborator
            page={CompanyAdminView.COLLABORATORS_DETAILS}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabCollaboratorsProfile);
