import { CompanyUsersQuery, CompanyUsersQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getCompanyUsersQuery } from './queries';

export const useCompanyUsersQuery = (
  companyID?: UUID,
  sortBy?: CompanyUserSortBy,
  filters?: CompanyUserFilter
) =>
  useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(getCompanyUsersQuery, {
    variables: { companyID, sortBy, filters },
    skip: !companyID,
  });
