import queryString from 'query-string';
import { ChangeEvent, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { TextField } from '@material-ui/core';

import { teamSetCompany } from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { PermissionResource } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import SearchText from '../../Search/SearchText/SearchText';
import styles from '../../Team/TeamLists/TeamListStyles';
import { TeamToggles } from '../../Team/TeamLists/TeamListUtils';
import { useUpsertProjectCompanyRole } from '../hooks/UpsertProjectCompanyRole';

import { ProjectCompanyLocal } from './CompaniesListUtils';

type CompaniesListItemProps = {
  classes: Classes<typeof styles>;
  projectCompany: ProjectCompanyLocal;
  searchTerm: string;
};

const DEBOUNCE_MILLISECONDS = 1500;

const CompaniesListItem: FC<CompaniesListItemProps> = ({ classes, projectCompany, searchTerm }) => {
  const projectId = getProjectIdFromUrl();
  const sendAnalytics = useAnalyticsEventHook();
  const {
    company: { name, asset, domain, id, type },
    filteredCollaborators,
    role,
  } = projectCompany;

  const stringifyParams = queryString.stringify(
    {
      view: TeamToggles.COLLABORATORS,
      search: domain || '',
    },
    { arrayFormat: 'index' }
  );
  const search = `?${stringifyParams}`;
  const [localRole, setRole] = useState(role || '');
  const upsertRole = useUpsertProjectCompanyRole();

  const submit = () => {
    if (localRole !== role) {
      sendAnalytics(teamSetCompany(localRole));
      upsertRole(projectId, id, localRole);
    }
  };

  // here's where we occasionally send back the role to the server on change
  const [, cancel] = useDebounce(
    () => {
      submit();
    },
    DEBOUNCE_MILLISECONDS,
    [projectId, id, localRole, role]
  );

  const onBlur = () => {
    cancel();
    submit();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setRole(e.target.value);
    }
  };

  const collaboratorsHover = filteredCollaborators
    .map((c: Collaborator) => `${c.user.name} (${c.role.name})`)
    .join(' \n');

  const { canEdit } = usePermissions();
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);

  return (
    <div className={classes.companyGridRow} data-cy="Company-row">
      <div className={`${classes.cell} ${classes.image}`} data-cy="Company-logo">
        {asset && asset.blobUrl && (
          <img className={classes.image} src={asset.blobUrl} alt={`${name} logo`} />
        )}
      </div>
      <div className={classes.cell}>
        <p
          className={classes.text}
          data-cy="Company-name"
          title={`${name}${type ? ` (${type})` : ''}`}
        >
          <SearchText searchTerm={searchTerm} text={name} />
        </p>
      </div>
      <div className={classes.cell}>
        <p className={classes.text} data-cy="Company-domain">
          <SearchText searchTerm={searchTerm} text={domain || ''} />
        </p>
      </div>
      <div className={classes.cell} data-cy="Company-people" title={collaboratorsHover}>
        <Link
          data-cy="link-report"
          className={classes.link}
          to={generateSharedPath(JoinProjectRoutes.TEAM, { projectId, search })}
        >
          {pluralizeCountString('User', filteredCollaborators.length)}
        </Link>
      </div>
      <div className={classes.cell} data-cy="Company-role">
        <TextField
          data-cy="CompaniesListItem-role"
          InputProps={{
            disableUnderline: true,
          }}
          disabled={!canEditCollaborators}
          fullWidth
          onBlur={onBlur}
          onChange={onChange}
          placeholder=""
          value={localRole}
        />
      </div>
    </div>
  );
};

const CompanyStyled = withStyles(styles)(CompaniesListItem);

export default CompanyStyled;
