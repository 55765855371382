import { FC, useEffect, useState } from 'react';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { ExecutiveDashboardOpenItemsDueSorting, ItemDueDateTypes } from '../../../api/gqlEnums';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import ChartsHorizontalBarGraph from '../../Charts/ChartsHorizontalBarGraph/ChartsHorizontalBarGraph';
import ChartsLegend from '../../Charts/ChartsLegend/ChartsLegend';
import {
  formatLegendDataFromStrings,
  getLegendElementColorFunction,
} from '../../Charts/ChartsLegend/ChartsLegendUtils';
import InputsToggle, { ToggleOption } from '../../Inputs/InputsToggle/InputsToggle';
import {
  CHART_BAR_LENGTH,
  ProjectMap,
  formatDataForItemDueDate,
  itemsDueDateColorMap,
  sortDataForItemDueDateChart,
} from '../ExecutiveDashboardUtils';

import styles from './ExecutiveDashboardItemDueDateStyles';

type ExecutiveDashboardItemDueDateProps = {
  classes: Classes<typeof styles>;
  itemDueDates?: ItemDueDate[];
  projectMap?: ProjectMap;
};

const ExecutiveDashboardItemDueDate: FC<ExecutiveDashboardItemDueDateProps> = ({
  classes,
  itemDueDates = [],
  projectMap,
}) => {
  // create the 3 sorting options (most open, most due this week, most overdue)
  const toggleOptions = Object.values(ExecutiveDashboardOpenItemsDueSorting).map(
    (key: string) => ({ text: key, key } as ToggleOption)
  ) as ToggleOption[];

  const sendAnalytics = useAnalyticsEventHook();

  const [sortBy, setSortBy] = useState(toggleOptions[0]);
  const [data, setData] = useState(formatDataForItemDueDate(itemDueDates, projectMap));
  const [selected, setSelected] = useState<string | null>(null);

  // get the max number of open items to calculate the chart width
  // itemDueDates should already be sorted by max open items from the backend
  const maxValue = itemDueDates.length > 0 ? itemDueDates[0].openCount : 1;

  useEffect(() => {
    setData(formatDataForItemDueDate(itemDueDates, projectMap));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [itemDueDates, setData]);

  const getLegendElementColor = getLegendElementColorFunction(itemsDueDateColorMap);

  return (
    <div className={classes.content}>
      <div className={classes.toggle}>
        <InputsToggle
          isWide
          options={toggleOptions}
          selected={[sortBy]}
          setSelected={(toggleOption: ToggleOption) => {
            sendAnalytics(
              execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_CHART_VIEW, {
                chart: {
                  toggleOption: toggleOption.text,
                },
              })
            );
            sortDataForItemDueDateChart(itemDueDates, setData, toggleOption, projectMap);
            return setSortBy(toggleOption);
          }}
        />
      </div>
      <div className={classes.chart}>
        <ChartsLegend
          getLegendElementColor={getLegendElementColor}
          legendElements={formatLegendDataFromStrings(Object.values(ItemDueDateTypes))}
        />
        <ChartsHorizontalBarGraph
          data={data}
          formatValue={formatCommas}
          maxValue={maxValue}
          maxBarLength={CHART_BAR_LENGTH}
          projectMap={projectMap}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ExecutiveDashboardItemDueDate);
