import { FC } from 'react';

import { Checkbox } from '../../scales';

type PermissionsTableCheckboxProps = {
  isDisabled: boolean;
  isSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (pID: string, change: any) => void;
  permission: Permission;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  subText?: string;
  text: string;
};

const PermissionsTableCheckbox: FC<PermissionsTableCheckboxProps> = ({
  text,
  subText,
  settings,
  permission,
  onChange,
  isSelected = false,
  isDisabled,
}) => (
  <div className="flex flex-row gap-4 p-2">
    <Checkbox
      aria-label="permission-checkbox"
      isDisabled={isDisabled}
      isSelected={isSelected}
      onChange={(isSelected) => {
        const level = settings.labels[isSelected ? 1 : 0];
        onChange(permission.id, { level });
      }}
    />
    <div className={`${isDisabled ? 'text-type-inactive' : ''} flex flex-col`}>
      <span className="type-heading3">{text}</span>
      {subText && <span className="text-xs font-light">{subText}</span>}
    </div>
  </div>
);

export default PermissionsTableCheckbox;
