import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { ContactSupportOutlined as Support } from '@material-ui/icons';
import { renderToString } from 'react-dom/server';

import {
  emailSupport,
  openSupportMenu,
  viewKeyboardShortcuts,
  visitSuccess,
  visitWhatsNew,
} from '../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';

import SupportMenuStyles from './SupportMenuStyles';

import './SupportMenuStyles.css';

const KEYBOARD_SHORTCUTS = '#keyboard_shortcuts';
const WHATS_NEW = '#whats_new';
const HELP_CENTER = '#help_center';
const CONTACT_SUPPORT = '#contact_support';
const TARGET = '_blank';
const REL = 'noopener,noreferrer';

type SupportMenuProps = {
  classes: Classes<typeof SupportMenuStyles>;
};

const SupportMenu: FC<SupportMenuProps> = ({ classes }) => {
  const location = useLocation();
  const iconRef = useRef(null);
  const sendAnalytics = useAnalyticsEventHook();
  const onOpen = () => sendAnalytics(openSupportMenu());

  useEffect(() => {
    switch (location.hash) {
      case WHATS_NEW:
        window.open('https://success.join.build/en/knowledge/whats-new-in-join', TARGET, REL);
        sendAnalytics(visitWhatsNew());
        break;
      case HELP_CENTER:
        window.open('https://success.join.build', TARGET, REL);
        sendAnalytics(visitSuccess());
        break;
      case CONTACT_SUPPORT:
        window.location.href = 'mailto:support@join.build';
        sendAnalytics(emailSupport());
        break;
      case KEYBOARD_SHORTCUTS:
        window.open('https://success.join.build/en/knowledge/keyboard-shortcuts', TARGET, REL);
        sendAnalytics(viewKeyboardShortcuts());
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [location]);

  const footer = (
    <div className="support">
      <a className="link" id="new-release-icon" href={WHATS_NEW}>
        What&apos;s new
      </a>
      <a className="link" id="newtabicon" href={HELP_CENTER}>
        Visit our help center
      </a>
      <a className="link" id="emailicon" href={CONTACT_SUPPORT}>
        Contact Join support
      </a>
      <a className="link" id="keyboardicon" href={KEYBOARD_SHORTCUTS}>
        View keyboard shortcuts
      </a>
    </div>
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const { Appcues, AppcuesWidget } = window as any;
    if (iconRef.current && Appcues && AppcuesWidget) {
      const widget = AppcuesWidget(Appcues.user());
      widget.init('#appcues-menu', {
        position: 'left',
        footer: renderToString(footer),
      });
    }
  });

  return (
    <div id="appcues-menu" ref={iconRef}>
      <div className="appcues-widget-icon">
        <IconButton aria-label="Support" aria-haspopup="true" onClick={onOpen}>
          <Support className={classes.supportIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(SupportMenuStyles)(SupportMenu);
