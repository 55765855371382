import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import { LOCALSTORAGE_COMPANY_COLLABORATORS_SHOW } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { SortManager, Table } from '../../scales';
import CompanyTabSearch from '../CompanyTabSearch';
import CompanyTabStyles from '../CompanyTabStyles';
import CompanyTabUserSidebarFilter from '../CompanyTabUserSidebarFilter';
import {
  CompanyUserSearchManager,
  companyTabSidebarWidth,
  getStatusColor,
  gridRowHeight,
  sidebarHeaderContent,
} from '../CompanyTabUtils';

type CompanyTabCollaboratorsSidebarProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyCollaborators: CompanyCollaborator[];
  countTotalCollaborators: number;
  loading: boolean;
  sortManager: SortManager;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  userCounts: number[];
  searchManager: CompanyUserSearchManager;
};

const CompanyTabCollaboratorsSidebar: FC<CompanyTabCollaboratorsSidebarProps> = ({
  classes,
  companyCollaborators,
  countTotalCollaborators,
  loading,
  sortManager,
  filterStatus,
  setFilterStatus,
  userCounts,
  searchManager,
}) => {
  const searchStorage = LOCALSTORAGE_COMPANY_COLLABORATORS_SHOW;
  const countSearchedCompanyUsers = companyCollaborators.length ?? 0;
  const entries = useMemo(
    () =>
      companyCollaborators.map(({ companyUser: { user } }) => {
        if (user) {
          const path = generateSharedPath(JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE, {
            userId: user.id,
          });
          const link = (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              to={path}
              onClick={() => {}}
            >
              {user.name}
            </Link>
          );
          const status = (
            <>
              <div style={{ color: getStatusColor(user.status) }}>{'\u2B24'}&nbsp;</div>
              <div className={classes.text}>{user.status}</div>
            </>
          );
          return [
            { component: link, key: user.id },
            { component: status, key: user.id },
          ];
        }
        return [];
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [companyCollaborators]
  );

  return (
    <>
      <div className={classes.memberSidebar} data-cy="company-collaborators-sidebar">
        <div className={classes.sidebarFilter}>
          <CompanyTabUserSidebarFilter
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            userCounts={userCounts}
            searchStorage={searchStorage}
            view={CompanyAdminView.COLLABORATORS_SIDEBAR}
          />
        </div>

        <div className={classes.sidebarFilter}>
          <CompanyTabSearch
            searchManager={searchManager}
            page={CompanyAdminView.COLLABORATORS_SIDEBAR}
          />
        </div>

        <div className={classes.sidebarHeightCollaborators}>
          <Table
            columnWidths={companyTabSidebarWidth}
            entries={entries}
            headerContent={sidebarHeaderContent}
            loading={loading}
            rowHeight={gridRowHeight}
            sortManager={sortManager}
          />
        </div>
        <Typography className={classes.sidebarCount}>
          {countSearchedCompanyUsers} of {countTotalCollaborators} people shown
        </Typography>
      </div>
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabCollaboratorsSidebar);
