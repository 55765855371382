import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const ScrollUp = (props: any) => (
  <SvgIcon
    {...props}
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 18L7 3.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41L5 3.83L5 18H7Z" />
  </SvgIcon>
);
