import { FC } from 'react';

import { withStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';

import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { Export } from '../../Icons/Export';
import { IconMenuButton, MenuEntry } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';

import styles from './ItemDetailExportPanelStyles';

type ItemDetailExportPanelProps = {
  classes: Classes<typeof styles>;
  printOnClick: (hasHistory?: boolean) => void;
};

const ItemDetailExportPanel: FC<ItemDetailExportPanelProps> = ({ classes, printOnClick }) => {
  const isItemSharingFeature = useHasFeature('YC_ITEM_SHARING');
  const iconMenuOptions: MenuEntry[] = [
    {
      id: 'print-without-change-history',
      label: 'Print without change history',
      onClick: printOnClick,
      startAdornment: <Print />,
    },
    {
      id: 'print-with-change-histry',
      label: 'Print with change history',
      onClick: () => printOnClick(true),
      startAdornment: <Print />,
    },
  ];
  const options = [
    {
      callback: () => printOnClick(),
      icon: <Print />,
      name: 'Print without change history',
      startAdornment: <Print />,
      label: 'Print without change history',
    },
    {
      callback: () => printOnClick(true),
      icon: <Print />,
      name: 'Print with change history',
      startAdornment: <Print />,
      label: 'Print with change history',
    },
  ];

  return (
    <div className={classes.padding}>
      {isItemSharingFeature ? (
        <IconMenuButton
          entries={iconMenuOptions}
          icon={<Export />}
          aria-label="Open export options"
          type="secondary"
        />
      ) : (
        <IconMenu icon={<Export />} isBottomOriented options={options} />
      )}
    </div>
  );
};

export default withStyles(styles)(ItemDetailExportPanel);
