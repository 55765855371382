import { useContext } from 'react';

import { Typography } from '@material-ui/core';

import { hasModuleEntitlement } from '../../../hooks/useModuleEntitlementsQuery';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { ModuleEntitlementsStore } from '../../ModuleEntitlementsProvider';
import NewProjectButton from '../../shared-widgets/NewProjectButton/NewProjectButton';

import styles from './NoProjectsPageStyles';

// this component and its accompanying style sheet was created by pulling directly from ProjectsListPage.tsx
const NoProjectsPage = ({ classes }: { classes: Classes<typeof styles> }) => {
  const moduleEntitlementFlags = useContext(ModuleEntitlementsStore);
  const canCreateProject = hasModuleEntitlement(moduleEntitlementFlags, PROJECT_CREATION);

  return (
    <div className={classes.emptyList}>
      <div className={classes.ctaContainer}>
        <Typography variant="headline" className={classes.headline}>
          Get started with
          <br />
          Join
        </Typography>
        <Typography variant="body1" className={classes.valuePropTitle}>
          Streamline your preconstruction decision-making process with more automated calculations
          and insights, powerful presentation capabilities, and increased collaboration.
        </Typography>
        {canCreateProject && <NewProjectButton isLarge />}
      </div>
      <div className={classes.valueProps}>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img className={classes.icon} src="/img/trending.svg" alt="Propose and track ideas" />
          </div>
          <Typography variant="body1" className={classes.valuePropTitle}>
            Automatically track the impact and details for every decision
          </Typography>
        </div>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img className={classes.icon} src="/img/venn.svg" alt="Present with power" />
          </div>
          <Typography variant="body1" className={classes.valuePropTitle}>
            Leverage powerful presentation tools to effectively communicate with stakeholders
          </Typography>
        </div>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img
              className={classes.icon}
              src="/img/stakeholders.svg"
              alt="Invite your project team"
            />
          </div>
          <Typography variant="body1" className={classes.valuePropTitle}>
            Increase transparency and accountability with centralized collaboration
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NoProjectsPage);
