import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonBase, Card, Divider, Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { isNonNullable } from '../../../utilities/types';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ChartsItemsSubtotals from '../../Charts/ChartsItems/ChartsItemsSubtotals';
import { FILTERED_STATUSES } from '../../ItemsList/ItemsListUtils';
import { useMilestoneItemCountQuery } from '../../Milestone/hooks/useMilestoneQuery';
import useMemoWrapper from '../../useMemoWrapper';
import ZeroState from '../../ZeroState/ZeroState';

import FrameSidebarSubtotalChartStyles from './FrameSidebarSubtotalChartStyles';
import { DataPoint, computeStatusData } from './FrameSidebarSubtotalChartUtils';

type Props = {
  activeMilestoneId?: UUID;
  shouldDisplayCosts?: boolean;
  classes: Classes<typeof FrameSidebarSubtotalChartStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  milestone: any;
  costReport: MilestoneCostReport;
  costReportLoading: boolean;
};

const FrameSidebarSubtotalChart: FC<Props> = ({
  activeMilestoneId,
  shouldDisplayCosts = true,
  classes,
  milestone,
  costReport,
  costReportLoading,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();

  const { data: milestoneItemCountData, loading: milestoneLoading } =
    useMilestoneItemCountQuery(activeMilestoneId);
  const itemCounts = milestoneItemCountData?.itemCounts;

  const loading = costReportLoading || milestoneLoading;
  const { name = '' } = milestone || {};

  const statusData: DataPoint[] = useMemoWrapper(computeStatusData, costReport, itemCounts).filter(
    isNonNullable
  );

  const filteredItemCount = statusData
    .map((bar: DataPoint) => bar.itemCount)
    .reduce((sum: number, v?: number) => (v ? sum + v : sum), 0);

  if (!milestone) return null;
  const title = `${name} Adds/Deducts by Status`;
  const search = `?currentMilestone[0]=${activeMilestoneId}`;
  if (!shouldDisplayCosts || (!loading && !costReport) || !projectId) return null;
  const itemsUrl = generateSharedPath(JoinProjectRoutes.ITEMS_LIST, { projectId, search });
  const statusesLink = `${itemsUrl}&groupBy[0]=Status&status[0]=ACCEPTED&status[1]=PENDING&status[2]=REJECTED&status[3]=INCORPORATED`;
  const contents = (
    <>
      <ButtonBase
        className={classes.cardHeader}
        onClick={() => {
          navigate(statusesLink);
        }}
        title={title}
      >
        <Typography variant="subheading" data-cy="addsDeducts-ItemsChartSubtotal">
          {`Adds/Deducts by Status (${pluralizeCountString('Item', filteredItemCount)})`}
        </Typography>
      </ButtonBase>
      <Divider />
      {filteredItemCount ? (
        <ChartsItemsSubtotals
          data-cy="itemsChartSidebarSubtotal"
          itemsUrl={itemsUrl}
          statusData={statusData}
          statusOrder={FILTERED_STATUSES}
        />
      ) : (
        name && (
          <div className={classes.cardBody}>
            <Typography>{`No items yet in ${name}`}</Typography>
          </div>
        )
      )}
    </>
  );

  return (
    <Card square className={classes.card} elevation={0}>
      {loading ? <ZeroState height={326} loading /> : contents}
    </Card>
  );
};

export default withStyles(FrameSidebarSubtotalChartStyles)(FrameSidebarSubtotalChart);
