import {
  TABLE_HEADER_EMAIL,
  TABLE_HEADER_NAME,
  TABLE_HEADER_PERMISSIONS,
  TABLE_HEADER_RESPONSIBILITIES,
  TABLE_HEADER_ROLE,
} from '../../../constants';

// Changing the order of this data changes the order the header columns appear on /collaborators.
export const headerContent = [
  { copy: '', title: '' },
  { copy: TABLE_HEADER_NAME, title: '' },
  { copy: TABLE_HEADER_EMAIL, title: '' },
  { copy: TABLE_HEADER_RESPONSIBILITIES, title: '' },
  {
    copy: TABLE_HEADER_ROLE,
    title:
      'Setting a teammate\'s role determines\nwhat that user can see and do within this project.\nClick the "view roles" button to see more detail\naround each role\'s permissions.',
  },
  {
    copy: TABLE_HEADER_PERMISSIONS,
    title:
      'If you want to limit access for a teammate\nto a specific trade(s), you can set that here.\nClick the "view roles" button to see more detail\nor to enable/disable trade permissions.',
  },
];
