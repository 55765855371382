import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../constants';

export const DASH_STICKY_HEIGHT = 88;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    root: {
      minWidth: 700,
      paddingBottom: 180, // DA: think this is so the bottom scrolls nicely
      flexGrow: 1,
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
    card: {
      marginBottom: 24,
      overflow: 'visible',
      backgroundColor: 'white',
      borderBottom: theme.border.line,
      borderLeft: theme.border.line,
      borderRight: theme.border.line,
      '@media print': {
        border: 'none',
        marginTop: 0,
        marginBottom: 0,
        // dg4: hacky print scaling ahead of better printing
        zoom: '60%',
      },
    },
    containerPanels: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 24px',
      height: DASH_STICKY_HEIGHT,
      position: 'sticky',
      zIndex: 1,
    },
    containerPanelsCentered: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '11px 14px',
      borderBottom: theme.border.line,
    },
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    graphs: {
      padding: 24,
    },
    empty: {
      color: theme.palette.primaryGrey,
      padding: 48,
      textAlign: 'center',
    },
    headline: {
      fontSize: 32,
      margin: 24,
      fontWeight: 'bold',
    },
    hideNotPrint: {
      '@media not print': {
        display: 'none',
      },
    },
    hidePrint: {
      '@media print': {
        display: 'none',
      },
    },
    print: {
      margin: '0px 0px 0px 80px',
    },
    printOnly: {
      display: 'block !important',
      paddingBottom: 24,
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '@media print': {
        borderBottom: theme.border.line,
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    newMilestoneCTA: {
      backgroundColor: theme.palette.joinPrimary,
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      color: 'white',
      padding: 12,
      margin: 24,
    },
  });
