import { useRef } from 'react';

import { InsertDriveFileOutlined } from '@material-ui/icons';

import Button from '../Button/Button';

export default function UploadButton(props: {
  disabled?: boolean;
  onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { disabled = false, onFileSelected } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  // Work around to allow a button to trigger a file input
  const onSelectBtnClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Button
        data-cy="import-btn-file"
        disabled={disabled}
        label="Select a File"
        onClick={onSelectBtnClick}
        startIcon={<InsertDriveFileOutlined />}
        type="secondary"
      />
      <input
        className="hidden"
        data-cy="import-input-file"
        onChange={onFileSelected}
        ref={fileInputRef}
        type="file"
      />
    </>
  );
}
