import { FC, useEffect, useLayoutEffect } from 'react';

import { Card } from '@material-ui/core';

import { PermissionResource } from '../../generated/graphql';
import { PAGE_TITLE_PROJECT_SETTINGS } from '../../tagConstants';
import { withStyles } from '../../theme/komodo-mui-theme';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import CategorizationsList from '../Categorizations/CategorizationsList/CategorizationsList';
import NotificationSettingsData from '../NotificationSettings/NotificationSettingsData';
import DisplaySettingsList from '../ProjectDisplaySettings/DisplaySettingsList';

import PrintHeaderContainer from './PrintHeader/PrintHeaderContainer';
import ProjectDetails from './ProjectDetails';
import ProjectItemTemplate from './ProjectItemTemplate/ProjectItemTemplate';
import styles from './ProjectPropertiesStyles';
import ProjectScheduleImpactSettings from './ProjectScheduleImpact/ProjectScheduleImpactSettings';
import ProjectUnits from './ProjectUnits';

type ProjectPropertiesProps = {
  classes: Classes<typeof styles>;
  project: ProjectProps;
};

const ProjectProperties: FC<ProjectPropertiesProps> = ({ classes, project }) => {
  const { id: projectID = '', templateEstimateID, name } = project;

  // Permissions
  const { canView, canEdit, canAdd, canDelete } = usePermissions();
  const canViewProjectDetails = canView(PermissionResource.PROJECT_DETAILS);
  const canViewCategorizations = canView(PermissionResource.CATEGORIES_AND_TAGS);
  const canViewTerminology = canView(PermissionResource.PROJECT_TERMINOLOGY);
  const canViewItemTemplate = canView(PermissionResource.ITEM_TEMPLATE);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canEditProjectUnits = canEdit(PermissionResource.PROJECT_UNITS);
  const canCreateCategoriesAndTags = canAdd(PermissionResource.CATEGORIES_AND_TAGS);
  const canEditCategoriesAndTags = canEdit(PermissionResource.CATEGORIES_AND_TAGS);
  const canUpdateScheduleImpact = canDelete(PermissionResource.SCHEDULE_IMPACT);
  const canEditCurrency = canEdit(PermissionResource.PROJECT_CURRENCY);
  const canEditDetails = canEdit(PermissionResource.PROJECT_DETAILS);
  const canEditTerminology = canEdit(PermissionResource.PROJECT_TERMINOLOGY);

  // Hook
  useEffect(() => {
    if (name) {
      document.title = `${name} - Project`;
    }
  }, [name]);

  // if we're navigating here from the item details page link
  // then scroll directly to the item template
  useLayoutEffect(() => {
    if (window.location.hash) {
      const elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  return (
    <div className={classes.root}>
      {canViewProjectDetails && (
        <Card square className={classes.card} elevation={0}>
          <header id={PAGE_TITLE_PROJECT_SETTINGS} className="p-4 type-heading1">
            Settings
          </header>
          <ProjectDetails
            editable={canEditDetails}
            canEditCurrency={canEditCurrency}
            project={project}
          />
        </Card>
      )}
      {canViewCategorizations && (
        <CategorizationsList
          canEdit={canEditCategoriesAndTags}
          canCreate={canCreateCategoriesAndTags}
        />
      )}
      {canEditProjectUnits && <ProjectUnits />}
      {canUpdateScheduleImpact && <ProjectScheduleImpactSettings projectID={project.id} />}
      {shouldDisplayCosts && canViewItemTemplate && !!templateEstimateID && (
        <ProjectItemTemplate templateEstimateID={templateEstimateID} />
      )}
      <NotificationSettingsData />
      <PrintHeaderContainer projectID={projectID} canEdit={canEditDetails} />
      {canViewTerminology && (
        <DisplaySettingsList
          canEditCurrency={canEditCurrency}
          canEditTerminolgy={canEditTerminology}
          shouldDisplayCosts={shouldDisplayCosts}
          projectID={projectID}
        />
      )}
    </div>
  );
};

export const ProjectPropertiesStyled = withStyles(styles)(ProjectProperties);
export default ProjectPropertiesStyled;
