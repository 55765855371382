import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine2 } from '../../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    projectContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      padding: 4,
      maxWidth: '500px',
      width: '-webkit-fill-available',
      '&:focus': {
        outline: singleLine2(theme.palette.fillGreen),
        background: theme.palette.backgroundGreen,
      },
      '&:hover': {
        background: theme.palette.backgroundGreen,
      },
    },
    leftAlign: {
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      ...theme.typography.webLink,
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline' },
    },
    label: {
      ...theme.typography.webLabel,
    },
    icon: {
      fontSize: 16,
    },
    textLine: {
      alignItems: 'center',
      display: 'flex',
      padding: 2,
      '&:first-of-type': {
        paddingTop: 0,
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
    titleContainer: {
      overflow: 'hidden',
      display: '-webkit-box',
      lineClamp: 2,
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      textAlign: 'left',
    },
  });
