import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { gridSortDataVar } from '../../../api/apollo/reactiveVars';
import { SortDirection } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './EstimateSortArrowButtonStyles';

type EstimateSortArrowButtonProps = {
  align: string;
  classes: Classes<typeof styles>;
  disabled: boolean;
  columnId: UUID;
};

const EstimateSortArrowButton: FC<EstimateSortArrowButtonProps> = ({
  align,
  classes,
  disabled,
  columnId,
}) => {
  const sortDataLocal = useReactiveVar(gridSortDataVar);

  const { sortKey, sortDirection } = sortDataLocal;
  const isActiveHeader = columnId === sortKey;
  let arrowDirectionClass;
  if (isActiveHeader) {
    arrowDirectionClass =
      sortDirection === SortDirection.SORT_DESCENDING ? classes.iconSortArrowFlipped : '';
  } else {
    arrowDirectionClass = '';
  }

  const toggleArrowAndSort = () => {
    if (!disabled) {
      let newSortDirection: SortDirection = SortDirection.SORT_NONE;
      if (!isActiveHeader) {
        newSortDirection = SortDirection.SORT_ASCENDING;
      } else {
        switch (sortDirection) {
          case SortDirection.SORT_NONE:
            newSortDirection = SortDirection.SORT_ASCENDING;
            break;
          case SortDirection.SORT_ASCENDING:
            newSortDirection = SortDirection.SORT_DESCENDING;
            break;
          case SortDirection.SORT_DESCENDING:
            newSortDirection = SortDirection.SORT_NONE;
            break;
          default:
            newSortDirection = SortDirection.SORT_NONE;
            break;
        }
      }
      gridSortDataVar({
        sortKey: columnId,
        sortDirection: newSortDirection,
      });
    }
  };

  return (
    <div
      data-cy="EstimateSortArrowButton"
      role="button"
      className={`${classes.container} ${
        align === 'LEFT' ? classes.marginLeft : classes.marginRight
      }`}
      onClick={toggleArrowAndSort}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={toggleArrowAndSort}
    >
      {!disabled && (
        <ArrowDownwardIcon
          classes={{
            root:
              isActiveHeader && sortDirection !== SortDirection.SORT_NONE
                ? `${classes.iconSortArrowActive} ${arrowDirectionClass}`
                : `${classes.iconSortArrow} ${arrowDirectionClass}`,
          }}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(EstimateSortArrowButton);
