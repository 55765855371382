import { useNavigate } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { NamedLink } from '../../../utilities/routes/types';
import { Chip } from '../../scales';

import { getTitleBarSelectedFromURL } from './FrameTitleBarUtils';
import styles from './NavigationTabsBarStyles';

type NavigationTabsBarProps = {
  classes: Classes<typeof styles>;
  links: NamedLink[];
};

const NavigationTabsBar = ({ classes, links }: NavigationTabsBarProps) => {
  const navigate = useNavigate();
  const sendAnalytics = useAnalyticsEventHook();

  const selectedValue = getTitleBarSelectedFromURL();

  return (
    <Tabs value={selectedValue} centered fullWidth>
      {links.map((link) => (
        <Tab
          className={classes.tab}
          id={`title-bar-${link.name}`}
          key={link.route.path}
          label={
            <div className={classes.label}>
              <div>{link.name}</div>
              {link.isBeta && (
                <div className={classes.badge}>
                  <Chip text="Beta" />
                </div>
              )}
            </div>
          }
          onClick={() => {
            sendAnalytics(analyticsEvent(`${link.analyticsName || link.name}_menuCTA`));
            navigate(link.route.path);
          }}
          value={link.name}
        />
      ))}
    </Tabs>
  );
};

export default withStyles(styles)(NavigationTabsBar);
