import { FC } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import { Uncategorized } from '../../../constants';
import { categoryLabel } from '../../../utilities/string';
import ErrorTooltip from '../ErrorTooltip';

interface CategoryRendererProps {
  value: CategoryCell;
  error: string;
  readOnly: boolean;
  onSelect: () => void;
}

const CategoryRenderer: FC<CategoryRendererProps> = ({ readOnly, value, error, onSelect }) => {
  const hasDropdown = !readOnly;

  let dropDownClassName = '';
  if (hasDropdown) {
    dropDownClassName = 'join-grid-dropdown-arrow';
  }

  if (!error && (value.category || value.search === '')) {
    const { categorization, name = '', number = '' } = value.category || {};
    const categoryString = (value.category && categoryLabel(name, number, categorization)) || '';
    const isPlaceholder = categoryString === '';

    return (
      <div className="join-grid-renderer join-grid-category">
        <span
          title={name}
          className={`join-grid-trim-text ${isPlaceholder ? 'join-grid-string-placeholder' : ''}`}
        >
          {isPlaceholder ? Uncategorized : categoryString}
        </span>
        <div
          className={dropDownClassName}
          onClick={hasDropdown ? onSelect : () => {}}
          onKeyDown={() => {}}
        />
      </div>
    );
  }
  return (
    <div className={`join-grid-renderer ${error ? 'join-grid-cell-error' : ''}`}>
      <div className="join-grid-trim-text">{value.search}</div>
      {error && (
        <>
          <div className="push-right">
            <ErrorTooltip title={error}>
              <Error />
            </ErrorTooltip>
          </div>
          <div
            className={dropDownClassName}
            onClick={hasDropdown ? onSelect : () => {}}
            onKeyDown={hasDropdown ? onSelect : () => {}}
          />
        </>
      )}
    </div>
  );
};

export default CategoryRenderer;
