import { JoinTeamRoutes } from '../../api/gqlEnums';
import CollaboratorsListData from '../Collaborators/CollaboratorsList/CollaboratorsListData';
import RolesListData from '../Collaborators/Permissions/Roles/RolesListData';
import CompaniesListData from '../Companies/CompaniesList/CompaniesListData';

export type RouteToTeamComponent = {
  [key in JoinTeamRoutes]: { element: JSX.Element };
};
export const routeToTeamComponent: RouteToTeamComponent = {
  [JoinTeamRoutes.TEAM_TEAMMATES]: { element: <CollaboratorsListData /> },
  [JoinTeamRoutes.TEAM_COMPANIES]: { element: <CompaniesListData /> },
  [JoinTeamRoutes.TEAM_ROLES]: {
    element: <RolesListData />,
  },
};
