import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.8,4.7H7.3c-1.4,0-2.6,1.3-2.6,2.8v2.7h1.8V9.4l4.9,2.2c0.7,0.3,1.4,0.5,2.2,0.5c0.7,0,1.5-0.2,2.2-0.5l4.9-2.2v8.1
		c0,0.5-0.4,1-0.8,1H7.3c-0.4,0-0.8-0.4-0.8-1H4.6c0,1.5,1.2,2.8,2.6,2.8h12.6c1.5,0,2.6-1.3,2.6-2.8v-10
		C22.5,5.9,21.3,4.7,19.8,4.7z M15,9.9c-0.9,0.4-2,0.4-2.9,0L6.7,7.5c-0.1,0-0.2,0-0.2-0.1c0-0.5,0.4-0.9,0.8-0.9h12.6
		c0.4,0,0.8,0.4,0.8,0.9c-0.1,0-0.2,0-0.2,0.1L15,9.9z"
      />
      <rect x="0.8" y="11.3" width="7.1" height="1.8" />
      <rect x="1.9" y="14.3" width="6" height="1.8" />
    </g>
  </SvgIcon>
);
