import Thumbnail from '../../../shared-widgets/Thumbnail/Thumbnail';
import { thumbnailSize } from '../../ProjectComp/ProjectComp';
import CategorizationInput from '../../ProjectCompsInputs/ProjectCompsInputsCategorization/CategorizationInput';
import { useProjectCompInputUpdateFunctions } from '../../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { extractProjectCompValues } from '../../ProjectCompsSetUtils';

type Props = {
  projectComp: ProjectComp;
};

const CategorizationDialogProject = (props: Props) => {
  const { input } = props.projectComp;
  const { milestones, projectID, projectName, thumbnail, id } = extractProjectCompValues(
    props.projectComp
  );
  const projectCompInputUpdateFunctions = useProjectCompInputUpdateFunctions(id, milestones);
  const { setProjectCompInputCategorization } = projectCompInputUpdateFunctions;
  return (
    <div className="mb-4 flex items-center gap-2">
      <Thumbnail
        thumbnail={thumbnail}
        dimension={thumbnailSize}
        padding={0}
        size={32} // kinda fussy height, based on the above field heights
      />
      <div className="flex-grow type-label">{projectName}</div>
      <div className="w-64">
        <CategorizationInput
          categorizationID={input.categorizationID ?? undefined}
          categorizationLevel={input.categorizationLevel ?? undefined}
          projectID={projectID}
          setCategorization={setProjectCompInputCategorization}
        />
      </div>
    </div>
  );
};

export default CategorizationDialogProject;
