import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    currentCurrency: {
      padding: `5px 6px 4px 6px`,
      textAlign: 'left',
      wordBreak: 'break-word',
      fontSize: '1rem',
      fontWeight: 400,
    },
    currentMilestoneEdit: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
        borderRadius: 100,
      },
      alignItems: 'center',
      display: 'flex',
      height: 24,
      width: 24,
    },
    currentCurrencyEditIcon: {
      '@media print': {
        display: 'none',
      },
      fontSize: 16,
      margin: 'auto',
    },
    currentCurrencySelector: {
      '@media print': {
        border: 'none',
        padding: 0,
      },
      alignItems: 'center',
      alignSelf: 'flex-start',
      background: 'none',
      backgroundColor: theme.palette.backgroundWhite,
      border: `1px solid ${theme.palette.joinGrey400}`,
      borderRadius: 4,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      padding: '0 5px 0 0',
      width: '100%',
    },
    currentCurrencySelectorDisabled: {
      backgroundColor: 'unset',
      border: 0,
      cursor: 'unset',
      padding: `6px 6px 6px 0px`,
    },
    currentCurrencyViewOnly: {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.primaryGrey,
      flexWrap: 'nowrap',
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
