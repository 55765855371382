import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primaryGrey,
      padding: 8,
    },
  });
