import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Search } from '@material-ui/icons';

import { DD_NEW_NAV } from '../../../features';
import { useHasFeature } from '../../../hooks/useFeatureQuery';
import { HOME_SELECT } from '../../../tagConstants';
import { isEnumValue } from '../../../utilities/types';
import { Select, Switch, TextInput } from '../../scales';
import NewProjectButton from '../../shared-widgets/NewProjectButton/NewProjectButton';

import useCoreSearchPermissions from './hooks/useCoreSearchPermissions';
import SearchToggle, { SearchToggleParams } from './SearchToggle';
import { SearchMode } from './types';

type Props = {
  activeFiltersCount: number;
  isFilterMenuOpen: boolean;
  onChangeSearch: (search: string) => void;
  onChangeSearchMode: (mode: SearchMode) => void;
  onToggleFilterMenu: (open: boolean) => void;
  search: string;
  searchMode: SearchMode;
  toggleParams?: SearchToggleParams;
};

const searchModeEntries = Object.values(SearchMode).map((name) => ({
  id: name,
  label: `${name[0].toLocaleUpperCase()}${name.slice(1)}`,
}));

export default function SearchHeader(props: Props) {
  const hasNewNav = useHasFeature(DD_NEW_NAV);
  const { hasItemsAccess } = useCoreSearchPermissions();

  // Maintain a second store for the search string. We use this local state
  // which is updated immediately for displaying the value to the user.
  const [search, setSearch] = useState(props.search);
  useEffect(() => {
    setSearch(props.search);
  }, [props.search]);

  // At the same time, we have a debounced function which calls the event handler
  // we received in props. The debouncing is to prevent excessive backend searches.
  const { onChangeSearch } = props;

  // We need to memoize the callback for referential equality not to prevent
  // excessive React re-renders (we're not super worried about that here since
  // it's a small component tree). We memoize it so that the debounce function
  // always has the same timer reference internally and works properly.
  const debouncedOnChangeSearch = useMemo(
    () =>
      debounce(onChangeSearch, 200, {
        leading: false,
        trailing: true,
      }),
    [onChangeSearch]
  );

  // Always use this function locally to update the search value so that the local
  // and parent `search` values stay synced.
  const handleChangeSearch = (value: string) => {
    setSearch(value);
    debouncedOnChangeSearch(value);
  };

  return (
    <div className="flex items-center gap-4 p-4">
      {/* 120px is a arbitrary width that I am maintaining from the prior implementation */}
      {hasItemsAccess && (
        <div className="w-[120px]">
          <Select
            aria-label="search mode"
            data-cy={HOME_SELECT}
            entries={searchModeEntries}
            onChange={(value) => {
              if (isEnumValue(SearchMode, value)) props.onChangeSearchMode(value);
            }}
            value={props.searchMode}
          />
        </div>
      )}
      {/* 240px is a arbitrary width that I am maintaining from the prior implementation */}
      <div className="w-[240px]">
        <TextInput
          aria-label="search projects"
          data-cy="SearchBar"
          onChange={handleChangeSearch}
          onClear={() => handleChangeSearch('')}
          placeholder={`Search ${props.searchMode.toLocaleLowerCase()}`}
          startAdornment={<Search />}
          value={search}
        />
      </div>
      {props.toggleParams && (
        <SearchToggle
          counts={props.toggleParams.counts}
          labels={props.toggleParams.labels}
          onChange={props.toggleParams.onChange}
          value={props.toggleParams.value}
        />
      )}
      <Switch
        checked={props.isFilterMenuOpen}
        label={`Filters${props.activeFiltersCount !== 0 ? ` (${props.activeFiltersCount})` : ''}`}
        onChange={props.onToggleFilterMenu}
      />
      {hasNewNav && (
        <div className="ml-auto">
          <NewProjectButton />
        </div>
      )}
    </div>
  );
}
