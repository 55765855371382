import { FC } from 'react';

import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import InputsSelectReferenceCategoryMenuItemStyles from './InputsSelectReferenceCategoryMenuItemStyles';

type SourceFilterMenuItemProps = {
  classes: Classes<typeof InputsSelectReferenceCategoryMenuItemStyles>;
  isChecked: boolean;
  onClick: () => void;
  id: string;
  number: string;
  name: string;
};

const SourceFilterMenuItem: FC<SourceFilterMenuItemProps> = ({
  classes,
  id,
  number,
  name,
  isChecked,
  onClick,
}) => {
  return (
    <MenuItem
      data-cy={`source-filter-menu-item-${name}`}
      classes={{ selected: classes.choice }}
      className={classes.choice}
      key={`${id}-${name}`}
      id={id}
      title={name}
      value={name}
      onClick={onClick}
    >
      <Checkbox checked={isChecked} disabled={false} />
      <Typography noWrap>{`#${number}: ${name}`}</Typography>
    </MenuItem>
  );
};

export default withStyles(InputsSelectReferenceCategoryMenuItemStyles)(SourceFilterMenuItem);
