import { FC } from 'react';

import { setCostModeAnalytics } from '../../../analytics/analyticsEventProperties';
import { REFETCH_NEW_COST_MODE } from '../../../api/refetchSets';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { useRefetch } from '../../../hooks/useRefetch';
import {
  costModeVar,
  getLocalCostModeLocation,
  mapCostModeStringToEnum,
  useAvailableCostModes,
  useCostMode,
} from '../../../utilities/costMode';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { getProjectIdFromUrl } from '../../../utilities/url';

import SelectCostMode from './SelectCostMode';

type SelectCostModeProps = {
  caption?: string;
};

const SelectCostModeData: FC<SelectCostModeProps> = ({ caption }) => {
  const projectId = getProjectIdFromUrl();
  const costMode = useCostMode();
  const sendAnalytics = useAnalyticsEventHook();
  const refetchCostQueries = useRefetch(REFETCH_NEW_COST_MODE);

  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const availableCostModes = useAvailableCostModes();
  const hasMultipleAvailableCostModes = availableCostModes.length > 1;

  const onChange = (projectId: UUID) => (newMode: string) => {
    costModeVar(mapCostModeStringToEnum(newMode)); // apollo store
    localStorage.setItem(getLocalCostModeLocation(projectId), newMode); // local storage
    sendAnalytics(setCostModeAnalytics(newMode));
    setTimeout(() => refetchCostQueries(), 0);
  };

  if (!hasMultipleAvailableCostModes) return <></>;
  return (
    <SelectCostMode
      availableCostModes={availableCostModes}
      isDisabled={!shouldDisplayCosts}
      label={caption}
      onChange={onChange(projectId)}
      value={costMode}
    />
  );
};

export default SelectCostModeData;
