import { gql } from '../../../../api/graphqlFragments';

export const createProjectBannerMutation = gql`
  mutation createProjectBanner($assetID: UUID!, $assetName: String!, $projectID: UUID!) {
    createProjectBanner(assetID: $assetID, assetName: $assetName, projectID: $projectID) {
      id
      bannerName
      asset {
        id
        blobUrl @client
        location
        name
      }
    }
  }
`;

export const getProjectBannersQuery = gql`
  query projectBanners($projectID: UUID!) {
    projectBanners(projectID: $projectID) {
      id
      bannerName
      asset {
        id
        blobUrl @client
        location
        name
      }
    }
  }
`;

export const getActiveProjectBannerQuery = gql`
  query activeProjectBanner($projectID: UUID!) {
    activeProjectBanner(projectID: $projectID) {
      id
      bannerName
      asset {
        id
        blobUrl @client
        location
        name
      }
    }
  }
`;

export const setActiveProjectBannerMutation = gql`
  mutation setActiveProjectBanner($assetID: UUID!, $projectID: UUID!) {
    setActiveProjectBanner(assetID: $assetID, projectID: $projectID) {
      id
      bannerName
      asset {
        id
        blobUrl @client
        location
        name
      }
    }
  }
`;

export const removeActiveProjectBannerMutation = gql`
  mutation removeActiveProjectBanner($projectID: UUID!) {
    removeActiveProjectBanner(projectID: $projectID)
  }
`;

export const removeProjectBannerMutation = gql`
  mutation removeProjectBanner($bannerID: UUID!, $projectID: UUID!) {
    removeProjectBanner(bannerID: $bannerID, projectID: $projectID)
  }
`;
