import { isUUID } from 'validator';

import { TermKey } from '../../../api/gqlEnums';
import { CLEAR_REFERENCES, COST_TYPES, S1, S2, SELECT_ALL, TOTAL_REF } from '../../../constants';
import { CostType, EstimateTotalType } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';

export const filterOutInvalidMarkupRefs = (
  references: string[],
  markups: Pick<SelectableMarkup, 'id'>[]
) =>
  references.filter(
    (r) => markups.find((m) => m.id === r) || isCostType(r) || r === S1 || r === TOTAL_REF
  );

export function isCostType(value: string): value is CostType {
  return Object.values<string>(CostType).includes(value);
}

export const costTypeColors = new Map<CostType, string>([
  [CostType.LABOR_COST, theme.palette.costTypes.labor],
  [CostType.EQUIPMENT_COST, theme.palette.costTypes.equipment],
  [CostType.MATERIAL_COST, theme.palette.costTypes.material],
  [CostType.SUBCONTRACTOR_COST, theme.palette.costTypes.subcontractor],
  [CostType.OTHER_COST, theme.palette.costTypes.other],
  [CostType.USER_COST, theme.palette.costTypes.user],
]);

export const isMarkupChecked = (ref: string, allRefs: string[], totalRefCount?: number) => {
  const included = allRefs.includes(ref) || allRefs.includes(TOTAL_REF);
  if (totalRefCount) return allRefs.length === totalRefCount || included;
  return included;
};

export const markupText = (markup: SelectableMarkup) =>
  `${markup.lineNumber}: ${(markup.name.value as RegularCell).string || '(no description)'}`;

export const markupTitle = (selectedReferences: string[], markup: SelectableMarkup) =>
  selectedReferences.includes(TOTAL_REF)
    ? `Using percent of total, so ${markup.lineNumber} is included`
    : (markup.name.value as RegularCell).string;

export const getDirectCostSubtotalText = (termStore: TermStore, s1RefShouldIncludeS2: boolean) =>
  s1RefShouldIncludeS2
    ? `S1, S2: Subtotal of ${termStore.lowerCase(TermKey.DIRECT_COST)}`
    : `S1: Subtotal of ${termStore.lowerCase(TermKey.DIRECT_COST)}`;

export const getMarkupSubtotalText = (termStore: TermStore, s1RefShouldIncludeS2: boolean) =>
  s1RefShouldIncludeS2
    ? `S3: Subtotal of ${termStore.lowerCase(TermKey.MARKUP)}`
    : `S2: Subtotal of ${termStore.lowerCase(TermKey.MARKUP)}`;

type ReferenceUpdate = {
  costType?: CostType;
  markupReference?: string;
  reference?: typeof S1 | typeof S2 | typeof TOTAL_REF;
};

export type ReferenceUpdates = {
  references: string[];
  costTypeFilters: CostType[];
};

export const updateReferencesAndCostFilters = (
  newRef: string,
  markups: SelectableMarkup[],
  selectedReferences: string[],
  setSelectedReferences: (refs: string[]) => void,
  selectedCostTypeFilters: CostType[],
  setSelectedCostTypeFilters: (refs: CostType[]) => void,
  totalType?: EstimateTotalType
) => {
  const updates = getUpdateReferencesAndCostFilters(
    newRef,
    markups,
    selectedReferences,
    selectedCostTypeFilters,
    totalType
  );
  setSelectedReferences(updates.references);
  setSelectedCostTypeFilters(updates.costTypeFilters);
};

// this separation is mostly for testing purposes
export const getUpdateReferencesAndCostFilters = (
  newRef: string,
  markups: Pick<SelectableMarkup, 'id'>[],
  selectedReferences: string[],
  selectedCostTypeFilters: CostType[],
  totalType?: EstimateTotalType
) => {
  let updates: ReferenceUpdates = {
    references: [...selectedReferences],
    costTypeFilters: [...selectedCostTypeFilters],
  };

  // clear or select all refs
  if (newRef === CLEAR_REFERENCES) {
    return clearReferences();
  }
  if (newRef === SELECT_ALL) {
    return selectAllReferences();
  }

  // check for new references
  if (newRef.startsWith('ADD_')) {
    updates = addReferencesAndCostTypeFilters(
      newRef.replace('ADD_', ''),
      markups,
      updates,
      totalType
    );
    // check for removed references
  } else if (newRef.startsWith('REMOVE_')) {
    updates = removeReferencesAndCostTypeFilters(newRef.replace('REMOVE_', ''), markups, updates);
  }

  // before we apply the updates, if we are selecting
  // everything then ensure that TOTAL is selected
  // and that S1 is selected if any cost types are selected
  return cleanUpUpdates(markups, updates);
};

const getUpdate = (update: string, markups: Pick<SelectableMarkup, 'id'>[]): ReferenceUpdate => {
  if (update === S1 || update === S2 || update === TOTAL_REF) return { reference: update };
  if (isCostType(update)) return { costType: update };

  // filter out any invalid markup references
  if (markups.find((m) => m.id === update)) return { markupReference: update };
  return {};
};

const addReferencesAndCostTypeFilters = (
  ref: string,
  markups: Pick<SelectableMarkup, 'id'>[],
  updates: ReferenceUpdates,
  totalType?: EstimateTotalType
): ReferenceUpdates => {
  const update = getUpdate(ref, markups);

  // add all referecnes
  if (update.reference === TOTAL_REF) {
    return selectAllReferences();
  }

  // add S1
  if (update.reference === S1) {
    // if any cost types are selected then remove them
    if (updates.costTypeFilters.length > 0) {
      return getReferenceUpdates(
        updates.references.filter((r) => r !== S1),
        []
      );
    }
    // otherwise add all cost types and include S1 if need be
    if (!updates.references.includes(S1)) updates.references.push(S1);

    const costTypesToAdd =
      totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES ? [...COST_TYPES] : [];

    return getReferenceUpdates(updates.references, costTypesToAdd);
  }
  // Add S2
  if (update.reference === S2) {
    // if any markups are selected then remove them
    if (updates.references.filter((r) => isUUID(r)).length > 0) {
      return getReferenceUpdates(
        updates.references.filter((r) => !isUUID(r)),
        updates.costTypeFilters
      );
    }
    // othewise add all markups
    return getReferenceUpdates(
      [...updates.references, ...markups.map((m) => m.id)],
      updates.costTypeFilters
    );
  }

  // otherwise just add the value
  if (update.costType) {
    updates.costTypeFilters.push(update.costType);
  } else if (update.markupReference) {
    updates.references.push(update.markupReference);
  }

  return updates;
};

const removeReferencesAndCostTypeFilters = (
  ref: string,
  markups: Pick<SelectableMarkup, 'id'>[],
  updates: ReferenceUpdates
): ReferenceUpdates => {
  const update = getUpdate(ref, markups);

  // remove all references
  if (update.reference === TOTAL_REF) {
    return clearReferences();
  }

  // remove S1
  if (update.reference === S1) {
    // if total is currently selected then keep all markups, and turn off all cost types
    if (updates.references.includes(TOTAL_REF)) {
      return getReferenceUpdates(
        markups.map((m) => m.id),
        []
      );
    }

    // otherwise just remove S1
    return getReferenceUpdates(
      updates.references.filter((r) => r !== S1),
      []
    );
  }

  // remove S2
  if (update.reference === S2) {
    // if total is currently selected then keep all cost types, and turn off all markups
    if (updates.references.includes(TOTAL_REF)) {
      return getReferenceUpdates([S1], [...COST_TYPES]);
    }
    // otherwise remove all markups
    return getReferenceUpdates(
      updates.references.filter((r) => !isUUID(r)),
      updates.costTypeFilters
    );
  }

  // if we are removing a ref / cost type and total is selected
  if (updates.references.includes(TOTAL_REF)) {
    if (update.costType) {
      return getReferenceUpdates(
        [...markups.map((m) => m.id), S1],
        COST_TYPES.filter((c) => c !== update.costType)
      );
    }
    if (update.markupReference) {
      return getReferenceUpdates(
        markups.map((m) => m.id).filter((r) => r !== update.markupReference),
        [...COST_TYPES]
      );
    }
    return getReferenceUpdates(
      updates.references.filter((r) => !isUUID(r)),
      []
    );
  }

  // otherwise remove whatever else is left
  if (update.costType) {
    return getReferenceUpdates(
      updates.references,
      updates.costTypeFilters.filter((c) => c !== update.costType)
    );
  }
  if (update.markupReference) {
    return getReferenceUpdates(
      updates.references.filter((r) => r !== update.markupReference),
      updates.costTypeFilters
    );
  }

  return updates;
};

const cleanUpUpdates = (
  markups: Pick<SelectableMarkup, 'id'>[],
  updates: ReferenceUpdates
): ReferenceUpdates => {
  // if this is a total then do nothing
  if (updates.references.includes(TOTAL_REF)) {
    return updates;
  }

  const areAllCostTypesApplied = updates.costTypeFilters.length === COST_TYPES.length;
  const areAllMarkupsApplied =
    updates.references.filter((r) => markups.find((m) => r === m.id)).length === markups.length;

  // if everything is selected then just set TOTAL_REF
  if (areAllCostTypesApplied && areAllMarkupsApplied) {
    return selectAllReferences();
  }

  // if any cost types are on then ensure S1 is selected
  // ensure S1 is turned on
  // hopefully all this logic is correct previously
  // but this is a stopgap to prevent any errors
  if (!updates.references.includes(S1) && updates.costTypeFilters.length > 0) {
    updates.references.push(S1);
  }

  // if there are no cost types then ensure S1 is turned off
  if (updates.costTypeFilters.length === 0 && updates.references.includes(S1)) {
    return getReferenceUpdates(updates.references, updates.costTypeFilters);
  }

  return updates;
};

export const clearReferences = () => getReferenceUpdates([], []);

export const getReferenceUpdates = (references: string[], costTypeFilters: CostType[]) => {
  return { references, costTypeFilters };
};

export const selectAllReferences = () => getReferenceUpdates([TOTAL_REF], []);

// Error handling: helper function for pasting markups: returns existing markup references after pasting
// TO DO: remap m1, m2, etc ids with existing table
export const checkValidMarkupReferences = (
  markups: SelectableMarkup[],
  selectedReferences: string[]
) => {
  const validReferences: string[] = [];
  if (selectedReferences.includes(TOTAL_REF)) validReferences.push(TOTAL_REF);
  if (selectedReferences.includes('S1')) validReferences.push('S1');
  if (markups)
    markups.map((markup: SelectableMarkup) => {
      if (selectedReferences.includes(markup.id)) validReferences.push(markup.id);
      return validReferences;
    });
  return validReferences;
};

export const getReferenceValue = (value: string, isChecked: boolean) =>
  isChecked ? `REMOVE_${value}` : `ADD_${value}`;

// Creates string of comma separated references for cell value
export const getReferencesText = (
  refs: string[],
  markups: SelectableMarkup[],
  inherit: boolean
) => {
  const refsText = refs
    .map((ref) => {
      if (ref === S1) {
        return inherit ? `${S1}, ${S2}` : ref;
      }
      if (ref === TOTAL_REF) {
        return ref;
      }
      const markup = markups.find((m: SelectableMarkup) => m.id === ref);
      return markup ? markup.lineNumber : '';
    })
    .filter((r: string) => r !== '')
    .sort()
    .join(', ');
  return refs.length === 0 ? 'Not Applied' : refsText;
};

export const toggleReferenceCheckbox = (
  isChecked: boolean,
  sourceFilter: ItemLink,
  selectedSourceFilters: ItemLink[],
  setSelectedSourceFilters: (items: ItemLink[]) => void
) => {
  const updatedSelectedSourceFilters = isChecked
    ? selectedSourceFilters.filter((s) => s.id !== sourceFilter.id)
    : [...selectedSourceFilters, sourceFilter];
  setSelectedSourceFilters(updatedSelectedSourceFilters);
};
