import { ComponentProps } from 'react';
import * as React from 'react';

import { withStyles } from '../../theme/komodo-mui-theme';
import RowHighlightable from '../shared-widgets/RowHighlight/RowHighlightable';

import ProjectCompInformationField from './ProjectCompInformationField';
import styles from './ProjectCompsStyles';

type SidebarLabelProps = Partial<ComponentProps<typeof ProjectCompInformationField>> & {
  children: React.ReactNode | React.ReactNode[];
  classes: Classes<typeof styles>;
};

const SidebarLabel = withStyles(styles)((props: SidebarLabelProps) => {
  const { children, classes, ...fieldProps } = props;
  return (
    <RowHighlightable>
      {({ ref }) => (
        <ProjectCompInformationField
          className={`${classes.field} ${classes.sidebarLabelField}`}
          classNameTypography={classes.labelText}
          containerRef={ref}
          value={children}
          typographyComponent="div"
          {...fieldProps}
        />
      )}
    </RowHighlightable>
  );
});

export default SidebarLabel;
