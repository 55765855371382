import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import theme from '../../theme/komodo-mui-theme';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

const styles = {
  root: {
    borderRadius: theme.border.radius,
    margin: '5px 2px',
    marginLeft: '10px',
    padding: '2px 4px',
    color: 'black',
  },
  editable: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
    },
  },
  text: {
    ...theme.typography.webLabel,
    fontWeight: 300,
  },
};

type TagProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  classes: any;
  name?: string;
  color?: string;
  canEdit?: boolean;
  tooltipText?: string;
  onMouseEnter?: () => void;
};

const Tag: FC<TagProps> = ({
  classes,
  name = 'Tag',
  color = theme.palette.backgroundGrey,
  canEdit = false,
  tooltipText = '',
  onMouseEnter,
}) => (
  <div
    className={`${classes.root} ${canEdit && classes.editable}`}
    title={tooltipText ? '' : name}
    style={{ backgroundColor: color }}
  >
    <NormalTooltip title={tooltipText}>
      <Typography className={classes.text} variant="body2" onMouseEnter={onMouseEnter}>
        {name}
      </Typography>
    </NormalTooltip>
  </div>
);

export default withStyles(styles)(Tag);
