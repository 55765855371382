import { FC } from 'react';

import { ArrowRightAlt } from '@material-ui/icons';

import { ScheduleImpactType } from '../../../api/gqlEnumsBe';
import { REMOVED_DESCRIPTION } from '../../../constants';
import { EventType } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { calendarString, parseDate } from '../../../utilities/dates';
import {
  formatScheduleImpact,
  itemHistoryCategorizationName,
  itemHistoryCategoryLabel,
} from '../../../utilities/itemHistoryEvents';
import { sortedCategoryChanges } from '../../../utilities/sorting';
import { pluralizeString } from '../../../utilities/string';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { TipTapTextAreaVariant } from '../../Inputs/InputsTextAreaStyled/TipTapTextArea/TipTapTextArea';
import { generateCostImpactInfo } from '../../Items/ItemsUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { getScheduleType } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { ItemActivityEvent } from '../utils';

type Props = {
  shouldDisplayCosts: boolean;
  event: ItemActivityEvent;
};
// ToDo: Update this component to use our design system / tailwind
const ItemHistoryEventExpanded: FC<Props> = ({ shouldDisplayCosts, event }) => {
  const { eventContent, created } = event;

  // move the category event to the bottom
  // the design looks better due to how categories are shown
  const eventTypes = [...event.eventTypes].sort((type) =>
    type === EventType.CHANGE_CATEGORY ? 1 : -1
  );
  const {
    name = '',
    description = '',
    assignee,
    oldNumber = '',
    newNumber = '',
    dueDate = '',
    descriptionStyled = '',
    categoryChanges = [],
    costChanges = [],
    drawChanges = [],
    scheduleImpactChanges = [],
  } = eventContent ?? {};

  const contentContainer = `flex items-center`;
  const contentContainerWithPadding = `${contentContainer} mb-0.5`;
  const bottomPadding = `mb-3`;
  const descriptionHasValue = !!description && !!description.trim().length;
  const getDescriptionStyle = (isLast: boolean) => {
    if (isLast && !descriptionHasValue) return '';
    if (isLast) return contentContainer;

    return descriptionHasValue ? bottomPadding : contentContainerWithPadding;
  };
  const nameStyles = name && name.length > 50 ? bottomPadding : contentContainerWithPadding;

  const textStyle = 'type-table-text truncate';

  const catChanges = categoryChanges.length > 0 ? sortedCategoryChanges(categoryChanges) : [];

  const numberOfCostChanges = costChanges?.length || 0;
  const numberOfDrawChanges = drawChanges?.length || 0;

  // Empty description element
  const removedDescriptionElement = !descriptionHasValue ? (
    <div className={textStyle} data-cy="itemHistoryChangeDescription">
      {REMOVED_DESCRIPTION}
    </div>
  ) : undefined;

  const renderEventExpanded = () =>
    eventTypes.map((eventType: EventType, i: number) => {
      const isLast = eventType.length - 1 === i;
      switch (eventType) {
        case EventType.CHANGE_ASSIGNEE:
          return (
            <span
              className={isLast ? contentContainer : contentContainerWithPadding}
              key={`${eventType}${assignee ? `${(assignee as User).name}` : 'removedAssignee'}`}
            >
              <div className={textStyle}>Assignee: </div>
              <div className={textStyle}>
                {assignee ? `${(assignee as User).name}` : 'removed assignee'}
              </div>
            </span>
          );
        case EventType.CHANGE_CATEGORY:
          return catChanges && catChanges.length > 0 ? (
            <div key={`${eventType}${catChanges.length}`}>
              <div className={`${textStyle} mb-1`}>{`${pluralizeString(
                'Category',
                catChanges.length
              )}: `}</div>
              {catChanges.map((change: CategoryChange) => {
                const catName = itemHistoryCategorizationName(change);
                const oldCatLabel = itemHistoryCategoryLabel(change.oldCategory);
                const newCatLabel = itemHistoryCategoryLabel(change.newCategory);
                return (
                  <div
                    key={`${eventType}${catName}`}
                    className="flex min-w-0 flex-wrap items-center gap-x-1"
                  >
                    <div
                      className={`${textStyle} inline-block`}
                      data-cy={`eventDescription-categorizationName${catName}`}
                    >
                      {`${catName}: `}
                    </div>
                    <NormalTooltip title={oldCatLabel}>
                      <div
                        className={`${textStyle} inline-block`}
                        data-cy={`eventDescription-category${oldCatLabel}`}
                      >
                        {itemHistoryCategoryLabel(change.oldCategory, 50)}
                      </div>
                    </NormalTooltip>
                    <div className="overlow-hidden h-4 content-center">
                      <ArrowRightAlt />
                    </div>
                    <NormalTooltip title={newCatLabel}>
                      <div
                        className={`${textStyle} inline-block`}
                        data-cy={`eventDescription-category${newCatLabel}`}
                      >
                        {itemHistoryCategoryLabel(change.newCategory, 50)}
                      </div>
                    </NormalTooltip>
                  </div>
                );
              })}
            </div>
          ) : null;
        case EventType.CHANGE_COST:
          return (
            <div>
              {numberOfCostChanges > 1 &&
                costChanges.map((change) => {
                  const oldCost = generateCostImpactInfo(change.oldCost);
                  const newCost = generateCostImpactInfo(change.newCost);
                  const costContent = shouldDisplayCosts ? (
                    <>
                      <span className="type-table-number">{oldCost}</span> to{' '}
                      <span className="type-table-number">{newCost}</span>
                    </>
                  ) : (
                    <>Changed cost</>
                  );
                  const timestamp = calendarString(change.timestamp, true);
                  return (
                    <div key={`${eventType}${change.timestamp}`} className="block">
                      <div className={textStyle} data-cy="eventDescription-costChange">
                        {costContent} {timestamp}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        case EventType.CHANGE_SCHEDULE_IMPACT: {
          const scheduleType = getScheduleType();
          if (!scheduleType) return null;
          return (
            <div key={`${eventType}${created}`}>
              {scheduleImpactChanges.length > 1 &&
                scheduleImpactChanges.map((change) => {
                  const oldImpact = formatScheduleImpact(
                    change.old ?? { type: ScheduleImpactType.TBD, criticalPath: false, days: 0 },
                    scheduleType
                  );
                  const newImpact = formatScheduleImpact(change.new, scheduleType);
                  const timestamp = calendarString(change.timestamp, true);
                  return (
                    <div key={`${newImpact}${change.timestamp}`} className="block">
                      <div className={textStyle} data-cy="eventDescription-scheduleImpactChange">
                        {oldImpact} to {newImpact} {timestamp}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }
        case EventType.CHANGE_DESCRIPTION:
          return (
            <div
              className={getDescriptionStyle(isLast)}
              data-cy="itemHistory-changeDescription"
              key={`${eventType}${
                descriptionHasValue ? `"${description.trim()}"` : REMOVED_DESCRIPTION
              }`}
            >
              <div className={textStyle}>Description: </div>
              {descriptionHasValue && descriptionStyled && (
                <InputsTextAreaStyled
                  data-cy="itemHistoryChangeDescription"
                  editable={false}
                  key={description}
                  placeholder=""
                  onAttachAsset={() => {}}
                  onChangeComplete={() => {}}
                  textStyled={descriptionStyled}
                  variant={TipTapTextAreaVariant.DESCRIPTION}
                />
              )}
              {!descriptionHasValue && removedDescriptionElement}
            </div>
          );
        case EventType.CHANGE_DUE_DATE:
          return (
            <span
              className={isLast ? contentContainer : contentContainerWithPadding}
              key={`${eventType}${dueDate ? `${parseDate(dueDate)}` : 'removed due date'}`}
            >
              <div className={textStyle}>Due date: </div>
              <div className={textStyle}>
                {dueDate ? `${parseDate(dueDate)}` : 'removed due date'}
              </div>
            </span>
          );
        case EventType.CHANGE_NAME:
          return (
            <div
              className={isLast ? '' : nameStyles}
              key={`${eventType}${name ? `"${name}"` : 'removedName'}`}
            >
              <div className={textStyle}>Title: </div>
              <div className={textStyle}>{name ? `"${name}"` : 'removed name'}</div>
            </div>
          );
        case EventType.CHANGE_NUMBER:
          return (
            <span
              className={isLast ? contentContainer : contentContainerWithPadding}
              key={`${eventType}${newNumber ? `"${newNumber}"` : 'removedNumber'}`}
            >
              <div className={textStyle}>Number:</div>
              <div className={textStyle}>{oldNumber ? `"${oldNumber}"` : 'removed number'}</div>
              <ArrowRightAlt />
              <div className={textStyle}>{newNumber ? `"${newNumber}"` : 'removed number'}</div>
            </span>
          );

        case EventType.ITEM_DRAW_UPDATE:
          return (
            <div>
              {numberOfDrawChanges > 1 &&
                drawChanges.map((change) => {
                  const fromValue = formatCost(change.fromValue);
                  const toValue = formatCost(change.toValue);
                  const costContent = shouldDisplayCosts ? (
                    <>
                      <span className="type-table-number">{fromValue}</span> to{' '}
                      <span className="type-table-number">{toValue}</span>
                    </>
                  ) : (
                    <>Changed draw</>
                  );
                  const timestamp = calendarString(change.timestamp, true);
                  return (
                    <div key={`${eventType}${change.timestamp}`} className="block">
                      <div className={textStyle} data-cy="eventDescription-costChange">
                        {costContent} {timestamp}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        default:
          return null;
      }
    });

  return (
    <>
      <div className="m-w-2xl mt-3 whitespace-pre">{renderEventExpanded()}</div>
    </>
  );
};

export default ItemHistoryEventExpanded;
