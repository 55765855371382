import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 2C6.13 2 3 5.13 3 9C3 14.25 10 22 10 22C10 22 17 14.25 17 9C17 5.13 13.87 2 10 2ZM5 9C5 6.24 7.24 4 10 4C12.76 4 15 6.24 15 9C15 11.88 12.12 16.19 10 18.88C7.92 16.21 5 11.85 5 9Z" />
    <path d="M12 19.555C13.1282 21.0348 14 22 14 22C14 22 21 14.25 21 9C21 5.13 17.87 2 14 2C13.3048 2 12.6336 2.10099 12 2.28913C13.154 2.6318 14.1827 3.26355 15.0027 4.1007C17.2827 4.56541 19 6.58337 19 9C19 11.88 16.12 16.19 14 18.88C13.7532 18.5632 13.4945 18.2225 13.2303 17.8634C12.8066 18.476 12.3881 19.0459 12 19.555Z" />
    <path d="M10 11.5C11.3807 11.5 12.5 10.3807 12.5 9C12.5 7.61929 11.3807 6.5 10 6.5C8.61929 6.5 7.5 7.61929 7.5 9C7.5 10.3807 8.61929 11.5 10 11.5Z" />
  </SvgIcon>
);
