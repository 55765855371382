import { SelectEntry, SelectEntryID } from './types';

export default function defaultEntryFilter(
  entry: SelectEntry,
  searchString: string,
  parentID: SelectEntryID | null
) {
  // If we're searching, search the entire set of entries
  if (searchString) {
    return Boolean(
      entry.label.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        entry.description?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    );
  }

  // Otherwise, include the entry only if it matches our currently-selected parentID
  return (entry.parentID ?? null) === parentID;
}
