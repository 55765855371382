import { UserStatus } from '../../../api/gqlEnumsBe';
import { BULK_EDIT, PREVIEW_ROLE, UNASSIGNED } from '../../../constants';

export const filterCollaborator = (filter: string | null) => (collaborator: Collaborator) => {
  const { user, responsibility, role } = collaborator;
  if (!user) return true; // always include unassigned, no matter the search term
  const { email, name } = user;
  const { name: roleName } = role;
  return (
    !filter ||
    [email, name, responsibility, roleName].some(
      (identifier) =>
        identifier &&
        filter
          .toLowerCase()
          .split(' ')
          .some((filterWord) => identifier.toLowerCase().includes(filterWord))
    )
  );
};

export const sortCollaborator = (a: Collaborator, b: Collaborator) => {
  if (!b.user || !b.user.name) return 1;
  if (!a.user || !a.user.name) return -1;
  return a.user.name.localeCompare(b.user.name as string);
};

export const getCollaboratorEmail = (collaborator: Collaborator | { user: null } | null) => {
  if (!collaborator || (collaborator && !collaborator.user)) return '';
  return (collaborator && collaborator.user && collaborator.user.email) || UNASSIGNED;
};

const nullUser: User = {
  email: '',
  picture: '',
  jobTitle: '',
  pictureAsset: undefined,
} as User;

export const unassignedCollaborator: Collaborator = {
  allTrades: false,
  user: nullUser,
  role: { id: '', name: '', hasTrade: false, permissionGroups: [] },
  trades: [],
  inviter: nullUser,
};

export const getAssignableCollaborators = (
  collaborators: Collaborator[],
  selectedCollaborator: Collaborator | undefined,
  isReset: boolean,
  variant: string | undefined,
  isDraft?: boolean,
  sharedUsersIDs?: UUID[]
) => {
  let filtered = collaborators.filter(
    (collaborator) => collaborator.user.status !== UserStatus.DEACTIVATED
  );
  if (isDraft) {
    filtered = filtered.filter((collaborator) => sharedUsersIDs?.includes(collaborator.user.id));
  }
  const shouldIncludeUnassigned =
    variant === BULK_EDIT || (variant !== PREVIEW_ROLE && !!selectedCollaborator);
  if (shouldIncludeUnassigned) filtered.push(unassignedCollaborator);
  if (isReset) return filtered;
  if (selectedCollaborator) {
    return filtered.filter(
      (collaborator) =>
        getCollaboratorEmail(collaborator) !== getCollaboratorEmail(selectedCollaborator)
    );
  }
  return filtered;
};
