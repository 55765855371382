import { useCallback, useState } from 'react';

export default function useStack<T>() {
  const [stack, setStack] = useState<T[]>([]);

  const push = useCallback((value: T) => {
    setStack((prevState) => [...prevState, value]);
  }, []);

  const pop = useCallback(() => {
    setStack((prevState) => [...prevState.slice(0, -1)]);
  }, []);

  const clear = useCallback(() => {
    setStack([]);
  }, []);

  const top = stack.length ? stack[stack.length - 1] : null;

  return {
    push,
    pop,
    clear,
    stack,
    top,
  };
}
