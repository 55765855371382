import { useState } from 'react';

import { Add } from '@material-ui/icons';

import { setItemsListNewItemDialogOpen } from '../../analytics/analyticsEventProperties';
import useSendAnalyticsEventHook from '../../hooks/useAnalyticsEventHook';
import { DialogsNewItem } from '../Dialogs';
import { Button } from '../scales';

type Props = {
  milestoneID: UUID;
};

export default function ItemsListNewItemButton(props: Props) {
  const sendAnalytics = useSendAnalyticsEventHook();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        data-cy="button-addNewItem"
        disabled={isDialogOpen}
        label="New Item"
        onClick={() => {
          setIsDialogOpen(true);
          sendAnalytics(setItemsListNewItemDialogOpen(true));
        }}
        startIcon={<Add />}
        type="primary"
      />
      <DialogsNewItem
        onClose={() => {
          setIsDialogOpen(false);
          sendAnalytics(setItemsListNewItemDialogOpen(false));
        }}
        milestoneID={props.milestoneID}
        open={isDialogOpen}
      />
    </>
  );
}
