import { Link, useNavigate } from 'react-router-dom';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { searchEventTypes } from '../../../../../../analytics/analyticsEventProperties';
import { JoinRoutes } from '../../../../../../api/gqlEnums';
import { ImageDimension } from '../../../../../../generated/graphql';
import {
  GLOBAL_PROJECT_LOCATION,
  GLOBAL_PROJECT_NAME,
  GLOBAL_PROJECT_TYPE,
} from '../../../../../../tagConstants';
import { withStyles } from '../../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../../utilities/routes/links';
import Thumbnail from '../../../../../shared-widgets/Thumbnail/Thumbnail';
import useSendSearchAnalytics from '../../../hooks/useSendSearchAnalytics';

import styles from './RelevantProjectStyles';

interface RelevantProjectProps {
  classes: Classes<typeof styles>;
  onClick: () => void;
  project: ForecastingProject;
}

const RelevantProject = (props: RelevantProjectProps) => {
  const { classes } = props;

  const sendSearchAnalytics = useSendSearchAnalytics();
  const navigate = useNavigate();

  return (
    <button
      data-cy="test"
      className={classes.projectContainer}
      onClick={() => {
        sendSearchAnalytics(searchEventTypes.SEARCH_SELECT_PROJECT, {
          projectID: props.project.id,
          location: 'Global',
        });
        navigate(
          generateSharedPath(JoinRoutes.PROJECT, {
            projectId: props.project.id,
          })
        );
      }}
    >
      <div className={classes.leftAlign} style={{ flexGrow: 1 }}>
        <Thumbnail
          thumbnail={props.project.thumbnail}
          size={52}
          padding={4}
          dimension={ImageDimension._92}
        />
        <div>
          <div className={`${classes.textLine} ${classes.titleContainer} `}>
            <Link
              className={classes.title}
              onClick={props.onClick}
              to={generateSharedPath(JoinRoutes.PROJECT, {
                projectId: props.project.id,
              })}
              data-cy={`${GLOBAL_PROJECT_NAME}-${props.project.name}`}
            >
              {props.project.name}
            </Link>
          </div>
          <div className={classes.textLine}>
            <div
              className={classes.label}
              data-cy={`${GLOBAL_PROJECT_LOCATION}-${props.project.name}`}
            >
              {props.project.location}
            </div>
            <LocationOnOutlined className={classes.icon} />
          </div>
          <div className={classes.textLine}>
            <div className={classes.label} data-cy={`${GLOBAL_PROJECT_TYPE}-${props.project.name}`}>
              {props.project.type}
            </div>
            <BusinessOutlined className={classes.icon} />
          </div>
        </div>
      </div>
    </button>
  );
};

export default withStyles(styles)(RelevantProject);
