import { NO_DESCRIPTION, Uncategorized } from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import { EMPTY_COST } from '../../../../utilities/string';
import { getStatusIsAcceptedIncorporated } from '../../../Items/ItemsUtils';
import { getCostValue, isCost, renderCostString } from '../../CostReportUtils';
import { NodeData, Subtotal } from '../CostReportCategoriesTree';
import { ReportData, getIsUnitColumn } from '../CostReportList/CostReportListUtils';

import { DESCRIPTION_WIDTH, REPORT_COL_WIDTH } from './CostReportListRowStyles';

export const COST_REPORT = 'cost report';

// TYPES
export enum RowVariants {
  ACCEPTED = 'ACCEPTED',
  RUNNINGTOTAL = 'RUNNINGTOTAL',
  SUBTOTAL = 'SUBTOTAL',
  MARKUP = 'MARKUP',
}

export type Node = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  branches?: any;
  data: ReportData;
  itemLike?: ItemLike;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  leaves?: any;
  parentItem?: Item;
};

export type OptionCost = {
  o?: Cost;
  r?: Cost;
};

export type ItemLikeReportSummary = { id: UUID; name: string; number: string; parent: UUID };
export type ItemTypes =
  | ItemLike
  | ItemLikeSummary
  | ItemLikeReportSummary
  | NodeData
  | ItemsListItem
  | ForecastingItem;

export type RowVariantsString = keyof typeof RowVariants | undefined;

// HELPERS
export const getOptionCost = (contribution?: Subtotal, useDirectCost?: boolean) => {
  if (!contribution) return undefined;
  return useDirectCost ? contribution.directCostRange : contribution.range;
};

// We are going to compare options (a CostScalar, and rejected, AddDeduct or range)
export const costsDiffer = (
  cost1: Cost | AddDeductCost | undefined,
  cost2: Cost | AddDeductCost | undefined
) => {
  if (!cost1 || !cost2) return false;
  return Number(getCostValue(cost1)) !== Number(getCostValue(cost2));
};

export const cellContent = (
  column?: string,
  status?: string, // tells us if the line is ItemLike
  cost?: CostReportValue,
  variant?: RowVariantsString,
  isProjectColumn?: boolean,
  isOption?: boolean,
  addAdds?: boolean,
  addDeducts?: boolean,
  isExact?: boolean,
  hasDate?: boolean,
  isEstimateLine?: boolean,
  isVarianceColumn?: boolean
) => {
  const isVariant = !!variant;
  const isItemLike = !!status;
  const isEmptyCell = (isItemLike || isEstimateLine) && isProjectColumn;
  const costType = (isOption && status) || column;
  let text = '';
  let title = '';

  // format cost
  if (cost) {
    // Despite the types saying we should have a PartialAddDeduct with number fields,
    // we're not getting that here. We're getting adds/deducts as strings instead of numbers.
    // This __needs__ to be disentangled at some point, it's bonkers we can't trust the types here.
    if (typeof cost === 'object' && ('adds' in cost || 'deducts' in cost)) {
      const { adds, deducts } = cost;
      const hasAdds = Boolean(adds && adds > 0);
      const hasDeducts = Boolean(deducts && deducts < 0);
      if (hasDeducts || hasAdds) {
        if (!isOption && (!hasDeducts || !hasAdds)) {
          title += 'Up to ';
        }
        if (hasDeducts) {
          const deduct = formatCost(deducts, { rounded: true, signed: true });
          text += deduct;
          title = `${title + deduct}${addDeducts ? ' additional' : ''} deducts`;
        }
        if (hasDeducts && hasAdds) {
          text += ' to ';
          title += ' to ';
        }
        if (hasAdds) {
          const add = formatCost(adds, { rounded: true, signed: true });
          text += add;
          title = `${title + add}${addAdds ? ' additional' : ''} adds`;
        }
      }
    } else if (typeof cost === 'number' || typeof cost === 'string') {
      // These seem not to be costs (they're generally metric quantities), so we don't show a currency symbol.
      // Ideally, we wouldn't be in this function in this case...
      const asNumber = typeof cost === 'string' ? parseInt(cost, 10) : cost;
      text = formatCost(asNumber, { scale: 1, showCents: false, showCurrencySymbol: false });
      const addl = addAdds || addDeducts ? ' additional' : '';
      title += `${text}${addl}`;
    } else if (isCost(cost)) {
      // Cost Types
      text = renderCostString({
        cost,
        isWide: true,
        isSigned: !isProjectColumn,
        isExact,
        showZero: isVarianceColumn,
      });
      let direction = '';
      if (!isProjectColumn && cost && (cost as CostScalar).value) {
        direction = getCostValue(cost) > 0 ? ' adds' : ' deducts';
      }
      const addl = addAdds || addDeducts ? ' additional' : '';
      title += `${text}${addl}${direction}`;
    }
    if (costType) title += ` ${costType.toLowerCase()}`;
  }
  // handle empty cells
  if (text === '') {
    if (!isEmptyCell) {
      let line = 'category';
      if (isItemLike) {
        line = isOption ? 'option' : 'item';
      }
      if (variant === RowVariants.MARKUP) {
        title = `There is no ${column && column.toLowerCase()} value for this markup`;
      }
      if (!isVariant) {
        title = `There is no ${column && column.toLowerCase()} value for this ${line}${
          hasDate ? ' on this date' : ''
        }`;
        text = EMPTY_COST;
        if (isOption && status && getStatusIsAcceptedIncorporated(status)) {
          title = `This option is ${status.toLowerCase()}`;
        }
      }
    }
    // where no value should be expressed: variant, or project-on-item
  }
  return { text, title };
};

export const cellDescriptionText = ({
  data: { displayUncategorized, number = '', name, lineDescription },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
any) => {
  if (lineDescription) {
    return lineDescription;
  }
  if (!name && !number) {
    return NO_DESCRIPTION;
  }
  if (displayUncategorized || (number === Uncategorized && name === Uncategorized)) {
    return Uncategorized;
  }
  if (number === '' && name) {
    return name;
  }
  return `${number}${name ? ' - ' : ''}${name || ''}`;
};

export const getMetricColumns = (varianceColumns: string[]) =>
  varianceColumns && varianceColumns.length
    ? varianceColumns.filter((unit) => getIsUnitColumn(unit))
    : [];

export const getDescriptionWidth = (metricColumns: string[]) =>
  DESCRIPTION_WIDTH + (metricColumns && metricColumns.length * REPORT_COL_WIDTH);

const MAX_WEIGHT = 600;
const MIN_WEIGHT = 200;
const midWeight = (MAX_WEIGHT + MIN_WEIGHT) / 2;
// return a weidght from MAX to minimum
export const getFontWeightForLevel = (level: number) => {
  const calculatedWeight = MAX_WEIGHT - level * 100;
  if (calculatedWeight < MIN_WEIGHT) return MIN_WEIGHT;
  return calculatedWeight;
};

// This function tries to correct for the width difference among typeface to generally tabularly align numbers
export const getLetterSpacingFromFontWeight = (fontWeight: number) =>
  ((midWeight - fontWeight) / 100) * 0.08; // visially-calculated spacing per weight;
