import { ReactNode } from 'react';
import {
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  TabProps as RACTabProps,
  Tabs as RACTabs,
  TabRenderProps,
} from 'react-aria-components';
import { Link } from 'react-router-dom';

type TabProps = {
  children: ReactNode;
  compact?: boolean;
  'data-cy'?: string;
  id: string;
  isRightAligned?: boolean;
  to?: string;
};

const getTabStyles = (
  { isSelected, isFocusVisible }: TabRenderProps,
  { compact, isRightAligned }: Pick<TabProps, 'compact' | 'isRightAligned'>
) => {
  // We always want to render a border so that heights stay consistent.
  let border = isSelected ? 'border-radio-selected' : 'border-transparent';
  if (isFocusVisible) {
    border = 'border-radio-focused';
  }
  return [
    'flex h-10 cursor-pointer items-center border-b-4 px-4',
    border,
    compact ? 'type-body3' : 'type-body1',
    isRightAligned ? 'ml-auto' : '',
  ].join(' ');
};

export function Tab(props: TabProps) {
  const { children, to, ...rest } = props;
  let tabContents: RACTabProps['children'] = (tabRenderProps) => (
    <div className={getTabStyles(tabRenderProps, props)}>{children}</div>
  );
  // if the `to` prop is present, wrap the Tab children in a <Link />
  if (typeof to === 'string')
    tabContents = (tabRenderProps) => (
      <Link to={to} className={getTabStyles(tabRenderProps, props)}>
        {children}
      </Link>
    );
  return (
    <RACTab {...rest} className="outline-none">
      {tabContents}
    </RACTab>
  );
}

type TabListProps = {
  children: ReactNode;
  'data-cy'?: string;
};

// list of selectable Tab components
export const TabList = (props: TabListProps) => (
  <RACTabList {...props} className="flex h-10 border-b text-type-primary type-body1" />
);

type TabsProps = {
  children: ReactNode;
  'data-cy'?: string;
  defaultValue?: string;
  onChange?: (key: string) => void;
  value?: string;
};

// For rendering a TabsList and the corresponding TabPanels below;
// this handles the visibility of the content based on the selected Tab.
export const Tabs = (props: TabsProps) => (
  <RACTabs
    defaultSelectedKey={props.defaultValue}
    onSelectionChange={(key) => {
      // TabProps only accepts a `string` for the `id`, so even though a react-aria `Key`
      // can also be a number, that won't happen in our component.
      if (typeof key === 'string') {
        props.onChange?.(key);
      } else {
        throw new TypeError(`Expected string Tab key, got ${typeof key}`);
      }
    }}
    selectedKey={props.value}
    {...props}
  />
);

export const TabPanel = (props: Pick<TabProps, 'children' | 'data-cy' | 'id'>) => (
  <RACTabPanel {...props} className="text-type-primary outline-none" />
);
