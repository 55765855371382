import { BrowserHistory, createBrowserHistory } from 'history';
import { useContext } from 'react';

import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import apolloClient from './api/apollo';
import App from './components/App';
import { JoinAuthProvider, joinAuthContext } from './components/JoinAuthProvider';
import LoginPage from './components/login/LoginPage';
import { AUTHORIZE, AUTH_REDIRECT } from './constants';
import registerServiceWorker from './registerServiceWorker';
import AppThemeProvider from './theme/AppThemeProvider';
import './api/gql';
import { tokenUtil } from './utilities/authUtils';
import { initializeSentry } from './utilities/sentry';

if (!process.env.REACT_APP_DISABLE_ANALYTICS) {
  datadogRum.init({
    applicationId: '71b9b620-be13-4ea9-b0b3-4b49acc2b57d',
    clientToken: 'pubf2232997610d5af697b23b207b0d3836',
    site: 'datadoghq.com',
    service: 'komodo-api',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      (url) => url.startsWith('https://app.join.build'),
      (url) => url.startsWith('https://staging.join.build'),
      (url) => url.startsWith('https://api.join.build'),
      (url) => url.startsWith('https://staging-api.join.build'),
    ],
  });
}

const browserHistory: BrowserHistory = createBrowserHistory();

const InnerApp = () => {
  // Sets up auth to be accessible from anywhere within the app
  const auth = useContext(joinAuthContext);
  tokenUtil.setGetAccessTokenSilentlyFunc(auth.getAccessTokenSilently);

  if (auth.isLoading) return <div />;

  const authenticated = auth.isAuthenticated && auth.loggedIn;
  const { pathname, search } = window.location;
  const path = `${pathname}${search}`;
  if (!authenticated) {
    // We will redirect the user off the page, so we need to preserve where they want to go after authentication
    if (path !== '/') sessionStorage.setItem(AUTH_REDIRECT, path);
    return <LoginPage />;
  }

  const historyPush = (path: string) => browserHistory.push(path);

  // Navigate to the last page the user was on before logging in.
  // We do this because url params are lost after authenticating
  const redirect = sessionStorage.getItem(AUTH_REDIRECT);
  if (redirect) {
    sessionStorage.removeItem(AUTH_REDIRECT);
    historyPush(redirect);
  }

  return (
    <ApolloProvider client={apolloClient(historyPush, auth)}>
      <AppThemeProvider>
        <App />
      </AppThemeProvider>
    </ApolloProvider>
  );
};

/* Staging credentials used to test in Auth0 Staging tenant
        domain="joincad-staging.us.auth0.com"
        clientId="Y4GrtwjJ2LPvLCzbW9kpBOnKqDjdDFq6"
        redirectUri={window.location.origin}
        scope="openid profile email read:rules read:current_user"
        skipRedirectCallback={window.location.pathname.includes(`/${AUTHORIZE}/`)} // This is important for authorizing connections
        audience="https://api.joincad.com"
        useRefreshTokens
*/

const renderApp = () =>
  createRoot(document.getElementById('root')!).render(
    <Sentry.ErrorBoundary>
      <Auth0Provider
        domain="login.join.build"
        clientId="K5Malw8PinzOBEn45OfGQsIYpxsvnc6j"
        redirectUri={window.location.origin}
        scope="openid profile email read:rules read:current_user"
        skipRedirectCallback={window.location.pathname.includes(`/${AUTHORIZE}/`)} // This is important for authorizing connections
        audience="https://api.joincad.com"
        useRefreshTokens
      >
        <JoinAuthProvider>
          <InnerApp />
        </JoinAuthProvider>
      </Auth0Provider>
    </Sentry.ErrorBoundary>
  );

initializeSentry();
renderApp();
registerServiceWorker();
