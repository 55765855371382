export default function InAppNotificationIconGeneral() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9.55C6.64 7.35 3.48 6 0 6V17C3.48 17 6.64 18.35 9 20.55C11.36 18.36 14.52 17 18 17V6C14.52 6 11.36 7.35 9 9.55ZM9 6C10.66 6 12 4.66 12 3C12 1.34 10.66 0 9 0C7.34 0 6 1.34 6 3C6 4.66 7.34 6 9 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
