import { FC } from 'react';

import { DASHBOARD, EXECUTIVE_DASHBOARD, FILTER_PANEL, PROJECTS } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeString } from '../../../utilities/string';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  projectFilterStateNumFilters,
} from '../../ProjectsList/ProjectsListUtils';
import {
  AdditionalFilterManager,
  FilterManager,
  useProjectCategorizationsFilterCount,
} from '../filterUtils';

import styles from './ClearFiltersPanelHeaderStyles';

type ClearFiltersPanelHeaderProps = {
  additionalFilters?: AdditionalFilterManager;
  classes: Classes<typeof styles>;
  projectID: UUID;
  filterManager: FilterManager | ProjectFilterManager;
  searchTerm?: string;
  setSettings?: ProjectFilterSetSettings;
  text?: string;
  variant?: typeof FILTER_PANEL | typeof DASHBOARD | typeof PROJECTS | typeof EXECUTIVE_DASHBOARD;
};

const ClearFiltersPanelHeader: FC<ClearFiltersPanelHeaderProps> = ({
  additionalFilters,
  classes,
  projectID,
  filterManager,
  searchTerm,
  setSettings = () => {},
  text = 'clear',
  variant = 'FILTER_PANEL',
}) => {
  const { clearFilters: clearItemFilters, filters } = filterManager as FilterManager;
  const { clearFilters: clearProjectFilters, filterState } = filterManager as ProjectFilterManager;

  const numAdditionalFilters = additionalFilters ? additionalFilters.numFilters : 0;
  const numBaseFiltersProjects = filterState
    ? projectFilterStateNumFilters(filterState, searchTerm || '')
    : 0;
  const numBaseFiltersDefault = useProjectCategorizationsFilterCount(filters, projectID);
  const numBaseFilters = variant === PROJECTS ? numBaseFiltersProjects : numBaseFiltersDefault;

  const numTotal = numBaseFilters + numAdditionalFilters;

  const clearAllFilters = () => {
    if (additionalFilters) {
      filterManager.clearFilters(() => {});
      additionalFilters.clearFilters();
    } else if (variant === PROJECTS) {
      clearProjectFilters(setSettings);
    } else {
      clearItemFilters();
    }
  };

  return (
    <>
      {variant !== DASHBOARD && (
        <div className={classes.filterCount} data-cy="filter-count">{`${numTotal} ${pluralizeString(
          'filter',
          numTotal
        )}`}</div>
      )}
      {(numBaseFilters > 0 || (additionalFilters && additionalFilters.canClear)) && (
        <div
          className={`
            ${classes.link}
            ${variant !== DASHBOARD ? classes.filterPanel : ''}
            ${variant === DASHBOARD ? classes.dashboard : ''}
          `}
          onClick={clearAllFilters}
          onKeyPress={clearAllFilters}
          role="button"
          tabIndex={0}
          data-cy="filter-reset"
        >
          {text}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(ClearFiltersPanelHeader);
