import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { CostMode } from '../../../api/gqlEnumsBe';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { mapCostModeEnumToString } from '../../../utilities/costMode';
import SelectCostMode from '../../Select/SelectCostMode/SelectCostMode';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { DISPLAY_SETTINGS_LABEL } from './ProjectCompsSectionLabels';

type ProjectCompSectionDisplaySettingsProps = {
  classes: Classes<typeof styles>;
};

const ProjectCompSectionDisplaySettings: FC<ProjectCompSectionDisplaySettingsProps> = ({
  classes,
}) => {
  const { costMode } = useReactiveVar(projectCompsSetInputVar);
  const { setCostMode } = useProjectCompsSetInputUpdateFunctions();
  const settingsSection = ProjectCompSectionType.SECTION_SETTINGS;
  const isSettingsCollapsed = useIsProjectCompSectionCollapsed(settingsSection);

  return (
    <div className={classes.fieldsContainer}>
      <ProjectCompSectionHeader
        label={DISPLAY_SETTINGS_LABEL}
        isTogglable
        collapseSection={settingsSection}
      />
      {!isSettingsCollapsed && (
        <div className={classes.settingInputs}>
          <div className={classes.selectorPadding} />
          <SelectCostMode
            availableCostModes={[
              CostMode.AllocatedMarkups,
              CostMode.SeparatedMarkups,
              CostMode.NoMarkups,
            ]}
            value={mapCostModeEnumToString(costMode ?? CostMode.AllocatedMarkups)}
            onChange={setCostMode}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionDisplaySettings);
