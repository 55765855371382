import { FC } from 'react';

import IconMenu from '../../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';

import ChangeCalculationIcon from './ChangeCalculation.svg';
import { getMenuOptions } from './EstimateHeaderCategoryReplacementOptions';
import { MenuOptionsProps } from './EstimateHeaderUtils';

type EstimateHeaderIconMenuProps = {
  id?: string;
  onClick: () => void;
  optionsData: MenuOptionsProps;
  variant: SelectVariants;
};

const EstimateHeaderIconMenu: FC<EstimateHeaderIconMenuProps> = ({
  id,
  onClick,
  optionsData,
  variant,
}) => {
  const { isQuantity, isTotalColumn } = optionsData;
  const menuOptions = getMenuOptions(optionsData);
  const hideIcon = isQuantity && isTotalColumn;

  const hasClickableOptions = menuOptions.some((option) => !option.disabled);
  return hasClickableOptions && !hideIcon ? (
    <IconMenu
      id={id}
      onClick={onClick}
      icon={
        (isTotalColumn && <img alt="Join" src={ChangeCalculationIcon} />) || (
          <div className="join-grid-dropdown-arrow" />
        )
      }
      options={menuOptions}
      variant={variant}
    />
  ) : null;
};

export default EstimateHeaderIconMenu;
