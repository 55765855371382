import { useRef, useState } from 'react';
import { useButton, useMenuTrigger } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { Status } from '../../../../generated/graphql';
import { getItemStatusLabel } from '../../../../utilities/item-status';
import ItemsIconsMap from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import { Menu, Popover } from '../../../scales';

type Props = {
  defaultStatus: Status;
  onChange: (status: Status) => void;
  availableStatuses: Status[];
};

export default function ItemStatusSelect(props: Props) {
  const [status, setStatus] = useState(props.defaultStatus);
  const state = useMenuTriggerState({});
  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps } = useMenuTrigger({}, state, ref);

  const { buttonProps } = useButton(
    {
      onPress: () => {
        state.toggle();
        ref.current?.blur();
      },
      'aria-label': 'change item status',
    },
    ref
  );

  const onStatusChange = (status: Status) => {
    props.onChange(status);
    setStatus(status);
  };

  let bgColor = 'bg-item-status-not-applicable-tint';
  if (status === Status.ACCEPTED) {
    bgColor = 'bg-item-status-accepted-tint';
  } else if (status === Status.INCORPORATED) {
    bgColor = 'bg-item-status-incorporated-tint';
  } else if (status === Status.PENDING) {
    bgColor = 'bg-item-status-pending-tint';
  } else if (status === Status.REJECTED) {
    bgColor = 'bg-item-status-rejected-tint';
  }

  return (
    <div className="relative">
      <button
        data-cy="item-status-select-button"
        {...buttonProps}
        /**
         * This component's styling is tightly related to the ItemOptionSelect since they both
         * render into the same spots. If you're making changes here, consider whether that
         * component should also be updated.
         *
         * At time of writing we're still pre-MVP and unsure of the best abstractions, so
         * despite it being /possible/ to move some of this into a wrapper component that
         * renders the `<button>` with `children` props, I've punted on that.
         */
        className={`flex h-6 w-[130px] items-center gap-1 whitespace-nowrap rounded-sm px-1 focus:outline active:outline-none ${bgColor}`}
        ref={ref}
      >
        <ItemsIconsMap status={status} variant="small" />
        <div className="grow truncate text-left type-body2">
          {getItemStatusLabel(status, { short: true })}
        </div>
        {state.isOpen ? <ArrowDropUp fontSize="inherit" /> : <ArrowDropDown fontSize="inherit" />}
      </button>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom end">
          <Menu
            {...menuProps}
            autoFocus
            data-cy="item-status-select-menu"
            entries={props.availableStatuses.map((status) => ({
              id: status,
              label: getItemStatusLabel(status),
              onClick: () => onStatusChange(status),
              startAdornment: <ItemsIconsMap status={status} variant="medium" />,
            }))}
          />
        </Popover>
      )}
    </div>
  );
}
