import {
  inAppNotificationsProjectIdVar,
  inAppNotificationsShowUnreadOnlyVar,
} from '../../../api/apollo/reactiveVars';
import InAppNotificationIconCompleteFlag from '../../Icons/InAppNotificationIconCompleteFlag';
import InAppNotificationIconNoUnread from '../../Icons/InAppNotificationIconGeneral';
import { ScrollContainer } from '../../scales';
import NotificationEntryData from '../InAppNotificationEntry/InAppNotificationEntryData';
import InAppNotificationsPlaceholder from '../InAppNotificationEntry/InAppNotificationsPlaceholder';

type Props = {
  notificationsData: InAppNotificationWithTime[][] | null;
};

export default function InAppNotificationsList(props: Props) {
  const showUnReadOnlySelected = inAppNotificationsShowUnreadOnlyVar();
  const allTabSelected = inAppNotificationsProjectIdVar() === null;

  if (!props.notificationsData?.length) {
    if (showUnReadOnlySelected) {
      return (
        <InAppNotificationsPlaceholder
          title="Great Job!"
          detail="You have no unread notifications."
          icon={<InAppNotificationIconNoUnread />}
        />
      );
    }
    if (!allTabSelected) {
      return (
        <InAppNotificationsPlaceholder
          title={`That's all your notifications for this project.`}
          detail="Tip: Check All Projects tab to see notifications on all projects."
          icon={<InAppNotificationIconCompleteFlag />}
        />
      );
    }

    return (
      <InAppNotificationsPlaceholder
        title="No notifications to display."
        detail="When notifications are generated they will be displayed here."
        icon={<InAppNotificationIconCompleteFlag />}
      />
    );
  }

  return (
    <ScrollContainer className="max-h-[400px]" direction="vertical">
      {props.notificationsData.map((notificationGroup) => (
        <div key={notificationGroup[0].id}>
          <div className="px-4 pt-2 type-body3">{notificationGroup[0].timeDateDisplay}</div>
          {notificationGroup.map((notification) => (
            <NotificationEntryData key={notification.id} notification={notification} />
          ))}
        </div>
      ))}
    </ScrollContainer>
  );
}
