import { gql } from '../../../../api/graphqlFragments';

export const updatePermissionMutation = gql`
  mutation updatePermission($projectID: UUID!, $id: UUID!, $level: PermissionLevel!) {
    updatePermission(projectID: $projectID, permission: $id, level: $level) {
      id
      level
      description
      resource
      actions {
        view
        add
        delete
        edit
      }
    }
  }
`;

export const updateRoleNameMutation = gql`
  mutation updateRoleName($projectID: UUID!, $roleID: UUID!, $name: String!) {
    setRoleName(projectID: $projectID, roleID: $roleID, name: $name)
  }
`;

export const updateRoleTradeMutation = gql`
  mutation updateRoleTrade($projectID: UUID!, $roleID: UUID!, $hasTrade: Boolean!) {
    setRoleHasTrade(projectID: $projectID, roleID: $roleID, hasTrade: $hasTrade)
  }
`;
