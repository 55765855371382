/* eslint react/prop-types: 0 */
import Menu, { SubMenu } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { NULL_ID, Uncategorized } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import useListTopLevelCategoryQuery from './hooks/useListTopLevelCategoryQuery';
import InputsSelectReferenceCategorizationMenuItem from './InputsSelectReferenceCategorizationMenuItem';
import InputsSelectReferenceCategoryFiltersStyles from './InputsSelectReferenceCategoryFiltersStyles';
import CategoryMenuItem from './InputsSelectReferenceCategoryMenuItem';

type InputsSelectReferenceCategoryFiltersProps = {
  classes: Classes<typeof InputsSelectReferenceCategoryFiltersStyles>;
  categorization: Categorization;
  selectedFilters: Category[];
  toggleCategory: (category: Category) => void;
  setSelectedFilters: (selectedFilters: Category[]) => void;
};

const InputsSelectReferenceCategoryFilters: FC<InputsSelectReferenceCategoryFiltersProps> = ({
  classes,
  categorization,
  selectedFilters,
  toggleCategory,
  setSelectedFilters,
}) => {
  const categorizationFilters = selectedFilters.filter(
    (f) => (f.categorization && f.categorization.id) === categorization.id
  );

  const { data, loading, error } = useListTopLevelCategoryQuery(categorization.id, '', 2000);

  if (loading || error)
    return (
      <InputsSelectReferenceCategorizationMenuItem
        categorization={categorization}
        categorizationFilters={categorizationFilters}
      />
    );

  const uncategorizedCategory = {
    id: NULL_ID,
    number: Uncategorized,
    name: Uncategorized,
    categorization,
  };
  const categories =
    data && data.category ? [uncategorizedCategory, ...data.category] : [uncategorizedCategory];

  // GENERATORS

  const generateCategoryMenuItems = categories.map((category) => {
    const isChecked = selectedFilters.some(
      (s) => s.id === category.id && (s.categorization && s.categorization.id) === categorization.id
    );
    return (
      <CategoryMenuItem
        key={`${category.id}:${category.categorization?.id}`}
        category={category as Category}
        checked={isChecked}
        onClick={() => toggleCategory(category as Category)}
      />
    );
  });
  return (
    <Menu className={classes.filterMenu} expandIcon={<></>} direction="rtl">
      <SubMenu
        title={
          <InputsSelectReferenceCategorizationMenuItem
            categorization={categorization}
            categorizationFilters={categorizationFilters}
          />
        }
        popupOffset={-1}
      >
        <div className={classes.categoryMenuItems}>
          <div className={`${classes.headerRow} ${classes.stickyHeaderRow}`}>
            {categorizationFilters.length !== 0 ? (
              <Typography
                className={classes.clearOrSelectAll}
                onClick={() => {
                  setSelectedFilters(
                    selectedFilters &&
                      selectedFilters.filter(
                        (f) => (f.categorization && f.categorization.id) !== categorization.id
                      )
                  );
                }}
              >
                Clear
              </Typography>
            ) : (
              <Typography
                className={classes.clearOrSelectAll}
                onClick={() => {
                  setSelectedFilters(
                    selectedFilters.concat(
                      (categories as Category[]).filter(
                        (c) => c.categorization?.id === categorization.id
                      )
                    )
                  );
                }}
              >
                Select all
              </Typography>
            )}
            <div className={classes.selectHeaderText}>:Choose categories</div>
          </div>
          {generateCategoryMenuItems}
        </div>
      </SubMenu>
    </Menu>
  );
};

export default withStyles(InputsSelectReferenceCategoryFiltersStyles)(
  InputsSelectReferenceCategoryFilters
);
