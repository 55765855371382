import { Selected } from '../../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../../generated/graphql';

export const getCanAttachFromSelected = (selected: Selected) => {
  const count = Object.values(selected).filter((b) => b).length;
  const canAttach = count > 0;
  return { count, canAttach };
};

export const formatAssetActionData = (
  projectID: UUID,
  itemID?: UUID,
  milestoneID?: UUID,
  sourceSystem?: SourceSystem,
  sourceID?: UUID,
  sourceProjectID?: string,
  hubID?: string
) => ({
  project: { id: projectID, thumbnail: '' },
  hubID,
  itemID,
  milestoneID,
  sourceSystem,
  sourceID,
  sourceProjectID,
});
