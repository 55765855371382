import { CostMode } from '../../../api/gqlEnumsBe';
import {
  extractAverageCompValues,
  extractProjectCompValues,
} from '../../ProjectComps/ProjectCompsSetUtils';
import { PrintSubheaderParams } from '../PrintCostReport/PrintSubheader';
import { PORTRAIT_WIDTH } from '../PrintUtils';

import { TOTAL_COST_ROW_HEIGHT } from './PrintProjectComp/PrintProjectCompStyles';

export enum CompsPrintThumbnailSize {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
  NONE = 'NONE',
}

export const PRINT_PARAMS_KEY = 'forecasting-report-print';
export const PRINT_PARAMS_DEFAULTS = {
  hasNotes: true,
  hasCharts: false,
  imageSize: CompsPrintThumbnailSize.SMALL,
};

const MAX_GROUP_SIZE = 8;
export const COLUMN_FONT_CLASSES =
  '@5xs:text-[5px] @[7rem]:text-[6px] @4xs:text-[7px] @[9rem]:text-[8px] @[11rem]:text-[9px]';

export const getGroupSize = (compsLength: number) => {
  if (compsLength > MAX_GROUP_SIZE) {
    const pagesRequired = Math.ceil(compsLength / MAX_GROUP_SIZE);
    const compsPerPage = Math.ceil(compsLength / pagesRequired);
    return compsPerPage;
  }
  return compsLength;
};

const SHOWING_PROJECT = 'Showing project';
const SHOWING_PROJECTS = `${SHOWING_PROJECT}s`;

export const getPrintSubheaderParametersForColumnGroup = (
  groupIndex: number,
  numProjectCompColumns: number,
  hasAverageCompColumn: boolean
): PrintSubheaderParams => {
  const totalCompColumns = numProjectCompColumns + (hasAverageCompColumn ? 1 : 0);
  const groupSize = getGroupSize(totalCompColumns);
  const remainingProjectsBeforeGroup = totalCompColumns - groupIndex * groupSize;
  const groupLength = Math.min(groupSize, remainingProjectsBeforeGroup); // Note: there are assumptions baked in here based on how we getGroupSize that the first N-1 groups will have the same group size
  const title = groupLength === 1 ? SHOWING_PROJECT : SHOWING_PROJECTS;
  // case 1: single column group - ex. "Showing projects: 2 of 2"
  if (groupLength === totalCompColumns) {
    return {
      title,
      value: `${numProjectCompColumns} of ${numProjectCompColumns}`,
    };
  }
  // case 2: multiple column groups - ex. "Showing projects: 4-7 of 15"
  const isFirstGroup = groupIndex === 0;
  let from = groupIndex * groupSize + 1;
  let to = Math.min(groupIndex * groupSize + groupLength, totalCompColumns);
  if (hasAverageCompColumn) {
    if (!isFirstGroup) from -= 1;
    to -= 1;
  }
  if (from === to) {
    // this happens if the last group only has a single projectCompColumn
    return { title, value: `${from} of ${numProjectCompColumns}` };
  }
  return {
    title,
    value: `${from}-${to} of ${numProjectCompColumns}`,
  };
};

export const getColumnIndex = (groupIndex: number, groupSize: number, indexWithinGroup: number) =>
  groupIndex * groupSize + indexWithinGroup;

export const rowHasBreakpoint = (
  rowIndex: number,
  repeatedHeaderHeight: number,
  initialHeaderOffset: number // initialHeaderHeight - repeatedHeaderHeight
) => {
  const rowHeight = TOTAL_COST_ROW_HEIGHT;
  const availableHeight = PORTRAIT_WIDTH - repeatedHeaderHeight - rowHeight;

  const location = rowHeight * (rowIndex + 1) + initialHeaderOffset;
  const locationOnPage = location % availableHeight;
  return locationOnPage < rowHeight;
};

export const createColumnGroups = (comps: (ProjectComp | AverageComp)[]) => {
  if (comps.length === 0) return [[]];
  // the following divvies our columns into groups of columns
  const groupSize = getGroupSize(comps.length);
  return comps.reduce((groups: (ProjectComp | AverageComp)[][] = [], comp, compIndex) => {
    const groupIndex = Math.floor(compIndex / groupSize);
    // update/create group at group index
    const currGroup = groups[groupIndex] ?? [];
    currGroup.push(comp);
    // return updated groups that includes modified group
    const updatedGroups = groups;
    updatedGroups[groupIndex] = currGroup;
    return updatedGroups;
  }, []);
};

export const getColumnGroups = (projectCompsSet: ProjectCompsSet) => {
  const averageComp =
    projectCompsSet.averageComp && !projectCompsSet.averageComp.isHidden
      ? projectCompsSet.averageComp
      : undefined;
  const projectComps = projectCompsSet.projectComps.filter(
    (projectComp) => !projectComp.input.isExcluded
  );

  const columns = averageComp ? [averageComp, ...projectComps] : projectComps;
  const columnGroups = createColumnGroups(columns);
  return { columns, columnGroups };
};

export const getColumnGroupKey = (columnGroup: (ProjectComp | AverageComp)[]) =>
  columnGroup.map((comp) => (isProjectCompType(comp) ? comp.project.id : 'Average')).join(':');

export const getHasMarkups = (projectCompsSet: ProjectCompsSet) => {
  const {
    input: { costMode },
  } = projectCompsSet;
  return costMode === undefined || costMode === CostMode.SeparatedMarkups;
};

export const isAverageCompType = (compData?: AverageComp | ProjectComp): compData is AverageComp =>
  compData?.__typename === 'AverageComp';
export const isProjectCompType = (compData?: AverageComp | ProjectComp): compData is ProjectComp =>
  compData?.__typename === 'ProjectComp';

export const getHeaderDisplaySettings = (projectCompsSet: ProjectCompsSet) => {
  const {
    projectComps,
    averageComp,
    input: { pinnedUnitID },
    selectedUnits,
  } = projectCompsSet;

  const showLocation = projectComps.some((p) => !!p.input.escalation?.location);
  const showTime = projectComps.some((p) => !!p.input.escalation?.time);
  const showFutureTime = projectComps.some((p) => !!p.input.escalation?.timeFuture);
  const showEscalationSection = showLocation || showTime || showFutureTime;

  const units = selectedUnits.filter((unit) => {
    const isPinnedUnit = unit.id === pinnedUnitID;
    const hasQuantity = (comp?: AverageComp | ProjectComp | null) =>
      comp &&
      comp.input.metrics.some(
        (metric) => metric.unitID === unit.id && Boolean(metric.quantityMagnitude)
      );
    return (
      isPinnedUnit ||
      hasQuantity(averageComp) ||
      projectComps.some((projectComp) => hasQuantity(projectComp))
    );
  });

  return {
    showLocation,
    showTime,
    showFutureTime,
    showEscalationSection,
    units,
  };
};

export const getHeaderDisplayData = (
  compData: AverageComp | ProjectComp,
  parentProject?: ProjectProps
) => {
  const isAverageComp = isAverageCompType(compData);

  const averageCompValues = isAverageComp ? extractAverageCompValues(compData) : undefined;
  const projectCompValues = !isAverageComp ? extractProjectCompValues(compData) : undefined;

  return {
    escalation: projectCompValues?.escalation,
    isAverageComp,
    location:
      projectCompValues?.location ??
      (averageCompValues?.location || parentProject?.location) ??
      '--',
    metrics: projectCompValues?.metrics ?? averageCompValues?.metrics ?? [],
    milestone: projectCompValues?.milestone,
    description: isAverageComp
      ? averageCompValues?.description ?? '--'
      : projectCompValues?.description ?? '--',
    projectDeliveryType: projectCompValues?.projectType ?? projectCompValues?.projectType ?? '--',
    projectStatus: projectCompValues?.projectStatus ?? parentProject?.status.name ?? '--',
  };
};

export const getHeaderTitleDisplayData = (compData: AverageComp | ProjectComp) => {
  const isAverageCompData = isAverageCompType(compData);

  const projectCompValues = !isAverageCompData ? extractProjectCompValues(compData) : undefined;
  const averageCompValues = isAverageCompData ? extractAverageCompValues(compData) : undefined;

  return {
    color: averageCompValues?.color ?? '',
    name: averageCompValues?.name ?? projectCompValues?.name,
    thumbnail: averageCompValues?.thumbnailAssetID ?? projectCompValues?.thumbnail,
  };
};
