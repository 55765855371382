import { FC } from 'react';

import { useTypesIdsToNames } from '../../hooks/useTypesIdsToNames';

import { ProjectFilterManager, getFilterSummaryString } from './ProjectsListUtils';

type TextWithWidthProps = {
  className: string;
  filterManager: ProjectFilterManager;
  includeLabels?: boolean;
};

const FilterSummaryText: FC<TextWithWidthProps> = ({
  className,
  filterManager,
  includeLabels = false,
}) => {
  const filterState = useTypesIdsToNames(filterManager.filterState, 'types');

  return (
    <div className={className}>{getFilterSummaryString(filterState, 2000, includeLabels)}</div>
  );
};

export default FilterSummaryText;
