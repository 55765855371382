import { FC, useMemo, useState } from 'react';

import { Edit } from '@material-ui/icons';
import isEqual from 'lodash/isEqual';

import {
  MILESTONE_COSTREPORT_COLUMNS,
  MILESTONE_COSTREPORT_ESTIMATE_LINES,
  MILESTONE_COSTREPORT_GROUPBY,
  MILESTONE_COSTREPORT_ITEMS,
  MILESTONE_COSTREPORT_VIEWMODE,
} from '../../../actions/actionTypes';
import { costReportSettingsAnalytics } from '../../../analytics/analyticsEventProperties';
import { isSecondPopupVar } from '../../../api/apollo/reactiveVars';
import { DETAILED_ITEM_COSTS, STATUS, VIEW_OPTIONS } from '../../../constants';
import { CostReportColumnKey } from '../../../generated/graphql';
import useAnalyticsEventHook from '../../../hooks/useAnalyticsEventHook';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeOptionsFromCategorizations } from '../../../utilities/categorization';
import { getItemStatusLabel } from '../../../utilities/item-status';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { statuses } from '../../../utilities/sorting';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import FilterPanelWrapper from '../../FilterPanel/FilterPanelWrapper';
import { FilterManager } from '../../FilterPanel/filterUtils';
import FilterSelect from '../../Inputs/InputsFilterSelect/InputsFilterSelect';
import InputsSettingsPanel from '../../Inputs/InputsSettingsPanel/InputsSettingsPanel';
import ReportsManagerMenu from '../../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import SelectValue from '../../Select/SelectValue/SelectValue';
import useMemoWrapper from '../../useMemoWrapper';
import { displaySettings, makeSettingDescription } from '../../VarianceReport/VarianceReportUtils';
import { COSTREPORT_DEFAULTS, COSTREPORT_PARAMS } from '../CostReport/useCostReportParams';
import { ColumnDescription } from '../CostReportColumns/CostReportColumns';
import CostReportColumnSelect from '../CostReportColumnSelect';
import CostReportGroupBySelect from '../CostReportGroupBySelect';
import CostReportSelect from '../CostReportSelect/CostReportSelect';
import CostReportSelectMetrics from '../CostReportSelect/CostReportSelectMetrics';
import CostReportSelectSubcolumns from '../CostReportSelect/CostReportSelectSubcolumns';

import styles from './CostReportSettingsPanelStyles';

// This helper function is used to extract the key string from a Categorization
export const getGroupByString = (selected: { name?: string }[]) =>
  selected.map((s) => s.name || '');

type CostReportSettingsPanelProps = {
  canViewProjectCategories?: boolean;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  displayColumnDescriptions: ColumnDescription[];
  columnDescriptions: ColumnDescription[];
  displayGroupBy: Categorization[];
  filterManager: FilterManager;
  milestoneID: UUID;
  paddingTop?: boolean;
  page: string;
  settings: MilestoneCostReportSettings;
  setSettings?: SetSettingsFunctionType;
  setSetting: (setting: string, value: string[] | string | undefined) => void;
  status: string[];
  statusDisabled?: boolean;
  viewGroupByColumnsDisabled?: boolean;
  viewMode: string;
};

const CostReportSettingsPanel: FC<CostReportSettingsPanelProps> = ({
  canViewProjectCategories = true,
  categorizations,
  classes,
  displayColumnDescriptions,
  columnDescriptions,
  displayGroupBy,
  filterManager,
  paddingTop = false,
  page,
  milestoneID,
  settings,
  setSettings = undefined,
  setSetting,
  status,
  statusDisabled,
  viewGroupByColumnsDisabled,
  viewMode,
}) => {
  // Data
  const sendAnalytics = useAnalyticsEventHook();
  const isChartOnly = viewMode === VIEW_OPTIONS.CHART;
  const { estimateLines, status: statusSetting } =
    (settings as MilestoneCostReportSettings) || COSTREPORT_DEFAULTS;
  const groupByOptions = useMemoWrapper(makeOptionsFromCategorizations, categorizations);

  // Hooks
  const [showSettings, setShowSettings] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const displayColumnKeys = useMemo(() => {
    const keys: CostReportColumnKey[] = [];
    displayColumnDescriptions.forEach((description) => {
      if (description) {
        const { columnKey } = description;
        if (columnKey) {
          keys.push(columnKey);
        }
      }
    });
    return keys;
  }, [displayColumnDescriptions]);

  // Functions
  const setFilterSettings = (show: boolean) => {
    setShowFilterPanel(show);
    if (showSettings) setShowSettings(false);
  };
  const setViewSettings = (show: boolean) => {
    setShowSettings(show);
    if (showFilterPanel) setShowFilterPanel(false);
  };
  const onChangeEstimateLines = (v: string) => {
    isSecondPopupVar(true);
    const idx = displaySettings
      .map((setting) => makeSettingDescription(setting, DETAILED_ITEM_COSTS))
      .findIndex((d) => d === v);
    const newSetting = displaySettings[idx];
    if (newSetting) {
      setSetting(COSTREPORT_PARAMS.estimateLines, newSetting);
    }
    sendAnalytics(costReportSettingsAnalytics(MILESTONE_COSTREPORT_ESTIMATE_LINES, { newSetting }));
  };

  const { isViewOnly } = usePermissions();

  // Components
  const statusFilter = (
    <div data-cy="reportFilter-Status">
      <div
        className="type-label"
        title="Choose which items and options to include in your report by status"
      >
        Status
      </div>
      <FilterSelect
        defaultLabel="No Items"
        disabled={false}
        filterKey={STATUS}
        labelFn={getItemStatusLabel}
        maxChipsShown={3}
        onChange={(selected: { status: string | string[] | undefined }) => {
          // the simple thing is to make each array object a string value we can search for
          setSetting(COSTREPORT_PARAMS.status, selected.status);
          sendAnalytics(
            costReportSettingsAnalytics(MILESTONE_COSTREPORT_ITEMS, {
              statuses: selected.status ?? [],
            })
          );
        }}
        options={statuses}
        selected={status}
      />
    </div>
  );

  const defaultStatuses = COSTREPORT_DEFAULTS.status;
  const statusFilterManager =
    !isChartOnly && !statusDisabled
      ? {
          canClear: !isEqual(statusSetting.sort(), defaultStatuses.sort()),
          clearFiltersCTA: 'reset',
          filters: statusFilter,
          numFilters: isEqual(statusSetting.sort(), statuses.sort()) ? 0 : 1,
          clearFilters: () =>
            setSettings &&
            setSettings({
              viewFilter: JSON.stringify({}),
              [COSTREPORT_PARAMS.status]: defaultStatuses,
            }),
        }
      : undefined;

  return (
    <div className={`${paddingTop ? classes.filterContainerPadding : classes.filterContainer}`}>
      {canViewProjectCategories && (
        <FilterPanelWrapper
          additionalFilters={statusFilterManager}
          filterManager={filterManager}
          page={page}
          show={showFilterPanel}
          setShow={setFilterSettings}
        />
      )}
      {settings && setSettings && (
        <ReportsManagerMenu
          settings={settings}
          setSettings={setSettings || (() => {})}
          variant="msr"
          isViewOnly={isViewOnly}
        />
      )}
      {viewGroupByColumnsDisabled ? null : (
        <InputsSettingsPanel icon={<Edit />} show={showSettings} setShow={setViewSettings}>
          <CostReportSelect
            selected={viewMode}
            onChange={(mode: string) => {
              setSetting(COSTREPORT_PARAMS.viewMode, mode);
              sendAnalytics(costReportSettingsAnalytics(MILESTONE_COSTREPORT_VIEWMODE, { mode }));
            }}
          />
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Metrics</div>
            <CostReportSelectMetrics
              milestoneID={milestoneID}
              settings={settings}
              setSettings={setSettings || (() => {})}
            />
          </div>
          {categorizations && (
            <CostReportGroupBySelect
              onClose={(selected) => {
                // the simple thing is to make each array object a string value we can search for
                setSetting(COSTREPORT_PARAMS.groupBy, getGroupByString(selected));
                sendAnalytics(
                  costReportSettingsAnalytics(MILESTONE_COSTREPORT_GROUPBY, {
                    groupByCategories: selected && selected.length,
                  })
                );
              }}
              options={groupByOptions}
              selected={displayGroupBy}
            />
          )}
          {!isChartOnly && (
            <div className="flex flex-col gap-0.5">
              <div className="type-label">Columns</div>
              <CostReportColumnSelect
                onClose={(selectedKeys: CostReportColumnKey[]) => {
                  setSetting(COSTREPORT_PARAMS.columns, selectedKeys);
                  sendAnalytics(
                    costReportSettingsAnalytics(MILESTONE_COSTREPORT_COLUMNS, {
                      selectedKeys,
                    })
                  );
                }}
                columnDescriptions={columnDescriptions}
                displayColumnKeys={displayColumnKeys}
              />
            </div>
          )}
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Subcolumns</div>
            <CostReportSelectSubcolumns
              milestoneID={milestoneID}
              settings={settings}
              setSettings={setSettings || (() => {})}
            />
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Detailed Item Costs</div>
            <SelectValue
              cySelect="select-itemDetailedCosts"
              onChange={(v: string) => onChangeEstimateLines(v)}
              onClose={() => isSecondPopupVar(false)}
              onOpen={() => isSecondPopupVar(true)}
              value={makeSettingDescription(estimateLines, DETAILED_ITEM_COSTS)}
              values={displaySettings.map((setting) =>
                makeSettingDescription(setting, DETAILED_ITEM_COSTS)
              )}
            />
          </div>
        </InputsSettingsPanel>
      )}
    </div>
  );
};
export default withStyles(styles)(CostReportSettingsPanel);
