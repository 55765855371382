import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
  'data-cy': string;
  title: ReactNode;
};

export default function ModeBar(props: Props) {
  return (
    <div
      className={`flex items-center gap-4 bg-background-primary px-4 py-2 text-type-primary ${
        props.className ?? ''
      }`}
      data-cy={props['data-cy']}
    >
      <div className="type-heading2">{props.title}</div>
      {props.children}
    </div>
  );
}
