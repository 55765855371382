import { FC, KeyboardEvent, useRef } from 'react';
import { Link } from 'react-router-dom';

import { TextField, Typography } from '@material-ui/core';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { UserReportType } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { StatusBanner } from '../../../scales';

import styles from './DialogsReportsContentStyles';
import DialogsReportsSharedSelector from './DialogsReportsSharedSelector';
import DialogsReportsTypeSelector from './DialogsReportsTypeSelector';

type DialogReportsProps = {
  allowableReportTypes: UserReportType[];
  cannotShare?: boolean;
  classes: Classes<typeof styles>;
  onSubmit: () => void;
  projectId: string;
  setUserReport: (report: UserReportInput) => void;
  userReport: UserReportInput;
  variant: 'reports-tab' | 'reports-manager';
  hasDistribution?: boolean;
};

const DialogReports: FC<DialogReportsProps> = ({
  allowableReportTypes,
  cannotShare,
  classes,
  hasDistribution,
  onSubmit,
  projectId,
  setUserReport,
  userReport,
  variant,
}) => {
  // store the initial value of shared to check if it has changed
  const isShared = useRef(userReport.shared);
  const shouldShowDistributionWarning = isShared.current && userReport.shared && hasDistribution;
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // submit the report if it has a name and a type
      if (userReport.name !== '' && userReport.reportType !== UserReportType.NONE) {
        if (event.target instanceof HTMLElement) {
          event.target.blur();
        }
        onSubmit();
      } else {
        // if the report has an invalid name
        // we need to ignore the new line
        // created by the enter key
        event.preventDefault();
      }
    }
  };

  const { isAdmin, isEditTemplate } = usePermissions();

  const isEditing = userReport.id;
  const showReportTypes = allowableReportTypes.length > 1;

  return (
    <div className={classes.root}>
      <div className={classes.setting}>
        <Typography className={classes.header} variant="caption">
          Report Name *
        </Typography>
        <TextField
          data-cy="input-reportName"
          InputProps={{
            className: classes.inputHeight,
            disableUnderline: true,
          }}
          value={userReport.name}
          multiline
          fullWidth
          placeholder="Name this report..."
          onKeyDown={handleKeydown}
          onChange={(evt) => {
            setUserReport({ ...userReport, name: evt.target.value });
          }}
          inputRef={(input) => {
            if (input) {
              input.focus();
            }
          }}
        />
      </div>
      <div className={classes.setting}>
        <Typography className={classes.header} variant="caption">
          Description
        </Typography>
        <TextField
          data-cy="input-reportDescription"
          InputProps={{
            className: classes.inputHeight,
            disableUnderline: true,
          }}
          value={userReport.description}
          multiline
          fullWidth
          placeholder="Describe this report..."
          onKeyDown={handleKeydown}
          onChange={(evt) => {
            setUserReport({ ...userReport, description: evt.target.value });
          }}
          rows={4}
        />
      </div>
      {!isEditing && showReportTypes && (
        // can't change the type for an existing report
        <div className={classes.setting}>
          <Typography className={classes.header} variant="caption">
            Report Type
          </Typography>
          <DialogsReportsTypeSelector
            allowableReportTypes={allowableReportTypes}
            userReport={userReport}
            setUserReport={setUserReport}
          />
        </div>
      )}
      {(isAdmin || isEditTemplate) && !cannotShare && (
        <div className={classes.setting}>
          <Typography className={classes.header} variant="caption">
            Shared With
          </Typography>
          <DialogsReportsSharedSelector userReport={userReport} setUserReport={setUserReport} />
          {shouldShowDistributionWarning && (
            <div className="w-full pt-2">
              <StatusBanner type="warning">
                Changing this report back to &apos;Private to Me&apos; will remove the Scheduled
                Report Distribution
              </StatusBanner>
            </div>
          )}
        </div>
      )}
      {isEditing && variant === 'reports-tab' && (
        <div className={classes.setting}>
          <Typography className={classes.tipHeader}>Tip: </Typography>
          <Typography className={classes.tipText}>
            To edit configuration of the report,
            <Link
              data-cy="link-report"
              className={classes.link}
              to={generateSharedPath(JoinProjectRoutes.REPORT_ROUTER, {
                projectId,
                reportId: userReport.id,
              })}
            >
              click here
            </Link>
            to go to the report view where you can make updates and save the report.
          </Typography>
        </div>
      )}
    </div>
  );
};

const StyledDialogReports = withStyles(styles)(DialogReports);

export default StyledDialogReports;
