/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, SyntheticEvent } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import AssetsUploadAreaControls from './AssetsUploadAreaControls';
import AssetsUploadAreaStyles from './AssetsUploadAreaStyles';

const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

type AssetsUploadAreaProps = {
  addFile: (file: File) => void;
  asset: Asset | null;
  classes: Classes<typeof AssetsUploadAreaStyles>;
  loading: boolean;
  removeAsset: () => void;
};

const AssetsUploadArea: FC<AssetsUploadAreaProps> = ({
  addFile,
  asset,
  classes,
  loading,
  removeAsset,
}) => {
  const addFiles = (files: FileList | null) => files && addFile(files[0]);

  // Handle drang and drop
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;
    addFiles(files);
  };

  const loadingWheel = (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} />
    </div>
  );

  // @ts-ignore TODO: CT-896 check for thumbnailURL, more probably can be dropped
  const assetURL = (asset?.thumbnailURL || asset?.blobUrl) ?? undefined;

  return (
    <div className={classes.assetUploadContainer}>
      <div className={classes.contentContainer}>
        {loading && loadingWheel}
        {!loading && (
          <div
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
          >
            <input
              style={{ display: 'none' }}
              id="file"
              key="file"
              type="file"
              multiple
              onChange={(evt) => {
                addFiles(evt.target.files);
              }}
            />
            <div className={classes.imageContainer}>
              {asset ? (
                <img alt="logo" className={classes.img} key={assetURL} src={assetURL} />
              ) : (
                <div className={classes.placeholderContainer}>
                  <CloudUpload className={classes.cloudUploadIcon} />
                  <Typography className={classes.heading2}>Upload an existing image</Typography>
                </div>
              )}
            </div>
            <AssetsUploadAreaControls asset={asset} addFiles={addFiles} removeAsset={removeAsset} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(AssetsUploadAreaStyles)(AssetsUploadArea);
