import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      display: 'flex',
      paddingLeft: theme.spacing.generic[11],
      paddingRight: theme.spacing.generic[3],
      height: 64,
    },
    containerTitle: {
      width: '50%',
      display: 'flex',
      alignSelf: 'center',
    },
    containerCtas: {
      display: 'flex',
      height: 'fit-content',
      justifyContent: 'flex-end',
      alignSelf: 'center',
      width: '50%',
      '& button:last-of-type': {
        marginLeft: `${theme.spacing.generic[3]}px`,
      },
    },
    title: {
      ...theme.typography.headline,
    },
    subtitle: {
      color: theme.palette.subtitleGrey,
      ...theme.typography.h3,
    },
  });

export default styles;
