type Props = {
  text: string;
  isOverlay?: boolean;
};

const PlaceholderSVG = () => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1754_18849)">
      <path
        d="M43.3337 23.8337H36.667V37.167H23.3337V43.8337H36.667V57.167H43.3337V43.8337H56.667V37.167H43.3337V23.8337ZM40.0003 7.16699C21.6003 7.16699 6.66699 22.1003 6.66699 40.5003C6.66699 58.9003 21.6003 73.8337 40.0003 73.8337C58.4003 73.8337 73.3337 58.9003 73.3337 40.5003C73.3337 22.1003 58.4003 7.16699 40.0003 7.16699ZM40.0003 67.167C25.3003 67.167 13.3337 55.2003 13.3337 40.5003C13.3337 25.8003 25.3003 13.8337 40.0003 13.8337C54.7003 13.8337 66.667 25.8003 66.667 40.5003C66.667 55.2003 54.7003 67.167 40.0003 67.167Z"
        fill="#5CD746"
      />
    </g>
    <defs>
      <clipPath id="clip0_1754_18849">
        <rect width="80" height="80" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const DropTargetPlaceholder = (props: Props) => {
  const placeholderTypeStyles = props.isOverlay
    ? 'absolute left-0 top-0 z-[200] h-[100%] w-[100%] bg-opacity-50'
    : 'flex-grow';
  const styles = `${placeholderTypeStyles} flex flex-col items-center justify-center bg-selection-hover outline-4 !-outline-offset-8 outline gap`;

  return (
    <div className={styles}>
      <PlaceholderSVG />
      <div className=" bg-type-primary p-2 text-type-secondary type-label">{props.text}</div>
    </div>
  );
};

export default DropTargetPlaceholder;
