import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const TILE_LARGE_WIDTH = 674;
export const TILE_SMALL_WIDTH = 332;
export const LEGEND_PADDING = 10;

const ChartsLegendElementStyles = (theme: KomodoTheme) =>
  createStyles({
    boldText: {
      fontWeight: 700,
      color: theme.palette.chartBlack,
    },
    greyText: {
      color: theme.palette.shadedGrey,
    },
    legendElement: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '2px 4px 2px 8px',
      cursor: 'default',
    },
    legendBox: {
      height: LEGEND_PADDING,
      width: LEGEND_PADDING,
    },
    legendComponent: {
      width: LEGEND_PADDING,
      ...theme.typography.label,
      lineHeight: 'unset',
    },
    legendLine: {
      height: 1,
      width: LEGEND_PADDING,
    },
    legendPercentPadding: {
      paddingLeft: '6px !important',
    },
    legendNoPercentPadding: {
      paddingLeft: '4px !important',
    },
    legendText: {
      ...theme.typography.label,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingTop: 3,
    },
    legendTextNarrow: {
      maxWidth: (TILE_SMALL_WIDTH - 2 * LEGEND_PADDING) / 3 - 24,
    },
    legendTextPadding: {
      paddingLeft: '2px !important',
    },
    linkText: {
      paddingLeft: '6px !important',
      display: 'block',
      fontWeight: 400,
      color: theme.palette.primaryBlue,
      '&:link': {
        textDecoration: 'none',
      },
    },
    linkTextHover: {
      paddingLeft: '6px !important',
      display: 'block',
      fontWeight: 700,
      color: theme.palette.primaryBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

export default ChartsLegendElementStyles;
