import {
  ComponentProps,
  ReactNode,
  Ref,
  RefObject,
  cloneElement,
  isValidElement,
  useRef,
} from 'react';
import { mergeProps, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

import composeRefs from '@seznam/compose-react-refs';

import TooltipOverlay from './TooltipOverlay';
import { pickTooltipTriggerProps } from './utils';

type Props = {
  // Why extend the type with the ref? It makes it possible to do the ref composition
  // without a bunch of weird, hacky, and brittle type-narrowing. Alternative approaches
  // are welcome...!
  children: ReactNode & { ref?: RefObject<Element> };
  content: ComponentProps<typeof TooltipOverlay>['children'];
  isDisabled?: Parameters<typeof useTooltipTrigger>[0]['isDisabled'];
  /** @default 8 */
  offset?: ComponentProps<typeof TooltipOverlay>['offset'];
  /** @default "top" */
  onClose?: () => void;
  onOpen?: () => void;
  placement?: ComponentProps<typeof TooltipOverlay>['placement'];
};

/** Wraps an element or component that should act as the trigger for displaying a tooltip. */
export default function Tooltip(props: Props) {
  const state = useTooltipTriggerState({
    closeDelay: 0,
    delay: 0,
    onOpenChange: (isOpen) => {
      if (isOpen) props.onOpen?.();
      else props.onClose?.();
    },
  });
  const triggerRef = useRef<HTMLDivElement>(null);

  // Get props for the trigger and its tooltip
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    {
      isDisabled: props.isDisabled || !props.content,
    },
    state,
    triggerRef
  );

  // Check if children are valid
  if (!isValidElement(props.children)) {
    throw Error('Not valid children.');
  }

  // Compose with children ref if there is one
  let ref: Ref<Element> = triggerRef;
  if (props.children.ref) {
    ref = composeRefs(triggerRef, props.children.ref);
  }

  // Clone children, passing in tooltip trigger props
  const childrenWithTrigger = cloneElement(
    props.children,
    mergeProps(props.children.props, {
      ref,
      ...pickTooltipTriggerProps(triggerProps),
    })
  );

  // Tooltip component
  const tooltipOverlay = (
    <TooltipOverlay state={state} triggerRef={triggerRef} {...mergeProps(props, tooltipProps)}>
      {props.content}
    </TooltipOverlay>
  );
  return (
    <>
      {childrenWithTrigger}
      {state.isOpen && tooltipOverlay}
    </>
  );
}

Tooltip.defaultProps = {
  offset: 8,
  placement: 'top',
} as const;
