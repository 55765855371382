import {
  DateGroup,
  EventType,
  ItemHistoryLink,
  ItemType,
  Status,
  Visibility,
} from '../../generated/graphql';
import { isEnumValue } from '../../utilities/types';
import AssetsCardsWrapper from '../assets/AssetsCards/AssetsCardsWrapper';

export type ItemActivityEventContent = {
  addedAttachments?: React.ComponentProps<typeof AssetsCardsWrapper>['assets'];
  comment?: ItemHistoryComment;
  name?: string;
  description?: string;
  assignee?: User;
  oldNumber?: string;
  newNumber?: string;
  dueDate?: string;
  descriptionStyled?: string; // item description, but with styling. eg bold, italics, etc
  categoryChanges?: CategoryChange[];
  costChanges?: CostChange[];
  drawChanges?: DrawChange[];
  scheduleImpactChanges?: ItemActivityScheduleImpact[];
  itemLikeSummary?: ItemLikeSummary;
  visibility?: Visibility;
};

// We might want to rename this type in the future but
// there is already an ItemHistoryEvent type that we need to maintain
export type ItemActivityEvent = {
  created: Date;
  description: SimpleEventDescription[];
  eventContent: ItemActivityEventContent | null;
  eventTypes: EventType[];
  user?: User;
  id: UUID;
  itemID: UUID;
  deprecated?: boolean;
  eventCount: number;
  itemLinks: {
    item: ItemHistoryLink | undefined;
    options: ItemHistoryLink[];
  };
  date: string;
  dateGroup?: DateGroup | null | undefined;
};

export type ItemEventDetails = {
  id: UUID;
  status: Status;
  number: string;
  name: string;

  attachments?: number;
  comments?: number;
  cost?: Cost;
  schedule?: number;
  dueDate?: Date;
  // ToDo: maybe missing 1 piece of info?
};

export function isEventDescriptionText(
  description?: SimpleEventDescription
): description is Omit<EventDescriptionText, 'kind'> {
  return !!description && 'text' in description;
}

export function isEventDescriptionItemLike(
  description?: SimpleEventDescription
): description is Omit<EventDescriptionItemLikeSummary, 'kind'> {
  return !!description && 'itemLike' in description;
}

/**
 * CopiedItem-specific event descriptions are a bit of one-off currently
 * in the past all item links in a project could rely on the projectID in the url for navigation
 * however, now we have an ability to show an item link that links out of the project
 * short term: I am defining a specific event description kind and kind-specific component to handle this case
 * long term: we should take a harder look at our various ItemLike, ItemLink, ItemSummary types to see how we can consolidate better
 */
export function isEventDescriptionCopiedItemLike(
  description?: SimpleEventDescription
): description is Omit<EventDescriptionCopiedItemLikeSummary, 'kind'> {
  return !!description && 'copiedItemLike' in description;
}

export function isEventDescriptionPrivateItemLike(
  description?: SimpleEventDescription
): description is Omit<EventDescriptionPrivateItemLikeSummary, 'kind'> {
  return !!description && 'count' in description;
}

export function isItemType(value?: string): value is ItemType {
  return !!value && isEnumValue(ItemType, value);
}

const getFirstEventByType = (filteredItemEvents: ItemActivityEvent[], type: EventType) =>
  filteredItemEvents.find((e) => e.eventTypes.includes(type));

export const getPrivateAndPublicItemHistoryEvents = (
  filteredItemEvents: ItemActivityEvent[],
  showDraftItemHistory: boolean,
  showPublishEvent: boolean
) => {
  let privateEvents: ItemActivityEvent[] = [];
  let publicEvents: ItemActivityEvent[] = [];
  const publishedEvent = getFirstEventByType(filteredItemEvents, EventType.CHANGE_VISIBILITY);

  if (publishedEvent) {
    const publishedEventIdx = publishedEvent ? filteredItemEvents.indexOf(publishedEvent) : 0;
    privateEvents = [...filteredItemEvents].splice(0, publishedEventIdx);
    publicEvents = [...filteredItemEvents].splice(
      showPublishEvent ? publishedEventIdx : publishedEventIdx + 1,
      filteredItemEvents.length - 1
    );
  } else {
    const createEvent =
      getFirstEventByType(filteredItemEvents, EventType.CREATE_ITEM) ||
      getFirstEventByType(filteredItemEvents, EventType.CREATE_OPTION) ||
      getFirstEventByType(filteredItemEvents, EventType.ADD_COMMENT);
    if (createEvent?.eventContent?.visibility === Visibility.PRIVATE_DRAFT) {
      // only private items
      privateEvents = filteredItemEvents;
    } else {
      // only public items
      publicEvents = filteredItemEvents;
    }
  }
  return { privateEvents: showDraftItemHistory ? privateEvents : [], publicEvents };
};
